import React, { useState } from "react";
import { ReactComponent as SpamCountIcon } from "Assets/Settings/spamCount.svg";
import { ReactComponent as OutgoingCountIcon } from "Assets/Settings/outgoingEmailCount.svg";
import { ReactComponent as InboxCountIcon } from "Assets/Settings/inboxCount.svg";
import { ReactComponent as EmailDeliverability } from "Assets/Settings/deliverability.svg";
import CustomTooltip from "Components/common/CustomTooltip";
import Utils from "Utils/utils";

const WarmerMetrics = (props = {}) => {
  const { metrics = {} } = props || {};
  const [tooltipIndex, setTooltipIndex] = useState("");

  const calculateDeliverablityStats = () => {
    let totalInbox = metrics?.emailLandedInInbox || 0;
    let totalSent = metrics?.emailSentCount || 0;
    return Utils.getPercentage(totalInbox, totalSent) || 0;
  };

  const config = [
    {
      name: "outgoingCount",
      id: "emailSentCount",
      icon: <OutgoingCountIcon />,
      color: "#3B3B3B",
      tooltipText: "Total warming emails sent"
    },
    {
      name: "inboxCount",
      id: "emailLandedInInbox",
      icon: <InboxCountIcon />,
      color: "#28AD56",
      tooltipText: "Emails landed in inbox"
    },
    {
      name: "spamCount",
      id: "emailLandedInSpam",
      icon: <SpamCountIcon />,
      color: "#F59064",
      tooltipText: "Emails saved from spam"
    },
    {
      name: "emailDeliverability",
      id: "inboxPercentage",
      icon: <EmailDeliverability />,
      color: "#F59064",
      tooltipText: "Email deliverability"
    }
  ];
  const getColor = (value = 0) => {
    if (value >= 0 && value < 25) {
      return "low";
    } else if (value >= 25 && value < 50) {
      return "medium";
    } else if (value >= 50 && value < 75) {
      return "middle";
    } else if (value >= 75 && value <= 100) {
      return "high";
    }
  };

  return (
    <div className="warmerMetricsWrapper">
      {config.map((item, index) => {
        const {
          id = "",
          icon = <></>,
          color = "#28AD56",
          tooltipText = ""
        } = item || {};
        return (
          <div
            className="warmerMetrics"
            key={id}
            onMouseOver={() => setTooltipIndex(index)}
            onMouseLeave={() => setTooltipIndex("")}
          >
            <div
              className={`warmerMetricsIcon ${
                id === "inboxPercentage" &&
                getColor(calculateDeliverablityStats())
              }`}
            >
              {icon}
            </div>
            <div
              className={`warmerMetricsValue ${
                id === "inboxPercentage" &&
                getColor(calculateDeliverablityStats())
              }`}
            >
              {id === "inboxPercentage"
                ? calculateDeliverablityStats() + "%"
                : Utils.formatNumberToStringInUSFormat(metrics?.[id])}
            </div>
            {tooltipIndex === index && <CustomTooltip text={tooltipText} />}
          </div>
        );
      })}
    </div>
  );
};

export { WarmerMetrics };
export default WarmerMetrics;
