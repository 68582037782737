import React from "react";

const DateFilterLoader = () => {
  return (
    <div className="filterByDateTab">
      <div className="dueDateFilterLoader">
        <span className="filerName linear-background" />
        <span className="filterBox linear-background" />
        <span className="filterBox linear-background" />
        <span className="filterBox linear-background" />
      </div>
    </div>
  );
};

export default DateFilterLoader;
