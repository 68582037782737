import React, { useState, createContext, useContext, useEffect } from "react";
import Utils from "Utils/utils";
import { weekDays } from "Static/constant";
import CustomTooltip from "Components/common/CustomTooltip";
import SelectedTimeRangePanel from "./TimeRangePanel";
import { observer } from "mobx-react";
import { calendarState } from "Pages/Dashboard/settings/Calendar/State";
import { toasterState } from "Components/common/toaster";

const SendingWindowContext = createContext({});

const SendingWindow = observer(props => {
  const { scheduleSendingWindowCbk = () => {}, dailyTimeRange = {} } =
    props || {};

  let tempWeekDays = [];

  for (const day in weekDays) {
    tempWeekDays.push({
      long: weekDays[day]?.long
    });
  }

  const [defaultFromTime, setDefaultFromTime] = useState({
    hours: "8",
    minutes: "00",
    metrics: "AM"
  });

  const [defaultToTime, setDefaultToTime] = useState({
    hours: "6",
    minutes: "00",
    metrics: "PM"
  });

  const [addTime, setAddTime] = useState(tempWeekDays);
  const [finalScheduledWindow, setFinalScheduledWindow] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [applyAll, setApplyAll] = useState(true);

  const getModifiedTimeRange = () => {
    const newAddTime = [...tempWeekDays];
    let isCustomOptionSelected = false;
    for (var day in dailyTimeRange) {
      const initialTimeRange = dailyTimeRange[day]?.map(item => {
        if (item) {
          const { fromTime = "", toTime = "" } = item || {};
          return {
            range: `${Utils.getCustomTime(fromTime)} - ${Utils.getCustomTime(
              toTime
            )}`
          };
        }
      });
      newAddTime[parseInt(day) - 1] = {
        ...newAddTime[parseInt(day) - 1],
        timeRange: initialTimeRange
      };
      if (initialTimeRange?.length > 1 && !isCustomOptionSelected) {
        isCustomOptionSelected = true;
      }
      setAddTime([...newAddTime]);
    }
    isCustomOptionSelected && calendarState.setIsCustomWeek(true);
  };

  const handleTimeRangeBlockCbk = (timeRangeItem, index, timeRangeIndex) => {
    let newArr = [...addTime];
    newArr.map((range, idx) => {
      return range?.timeRange?.map((item, index) => {
        if (item?.range) {
          item["isAddTime"] = false;
        } else {
          newArr[idx].timeRange.splice(index, 1); // We are checking length on rendering. So we should splice it
        }
      });
    });

    newArr[index].timeRange[timeRangeIndex]["isAddTime"] = true;
    const [fromTime, toTime] = timeRangeItem?.split("-");
    const [updatedFromHour, updatedFromMinutes, fromTimeMetrics] = fromTime
      ?.trim()
      ?.split(/[ :]/);
    const [updatedToHour, updatedToMinutes, toTimeMetrics] = toTime
      ?.trim()
      ?.split(/[ :]/);
    setDefaultFromTime({
      ...defaultFromTime,
      hours: updatedFromHour,
      minutes: updatedFromMinutes,
      metrics: fromTimeMetrics
    });
    setDefaultToTime({
      ...defaultToTime,
      hours: updatedToHour,
      minutes: updatedToMinutes,
      metrics: toTimeMetrics
    });
    setAddTime(newArr);
  };

  const handleAddTime = (index = 0) => {
    let newArr = [...addTime];
    newArr.map((range, idx) => {
      return range?.timeRange?.map((item, index) => {
        if (item?.range) {
          item["isAddTime"] = false;
        } else {
          newArr[idx].timeRange.splice(index, 1); // We are checking length on rendering. So we should splice it
        }
      });
    });
    newArr[index] = {
      ...newArr[index],
      timeRange: [{ isAddTime: true }]
    };
    setDefaultFromTime({
      ...defaultFromTime,
      hours: "8",
      minutes: "00",
      metrics: "AM"
    });
    setDefaultToTime({
      ...defaultToTime,
      hours: "6",
      minutes: "00",
      metrics: "PM"
    });
    setAddTime(newArr);
  };

  const handleCloseAction = (event, index = 0, timeRangeIndex = 0) => {
    Utils.preventDefaultFn(event);
    let newArr = [...addTime];
    if (newArr?.[index]?.timeRange?.[timeRangeIndex]?.["range"]) {
      // Multiselect slots
      newArr[index].timeRange[timeRangeIndex]["isAddTime"] = false;
    } else {
      // Single select slot
      newArr[index].timeRange = [];
    }
    setApplyAll(index === 0);
    setAddTime(newArr);
  };

  const handleApplyToAll = () => {
    let newArr = Utils.createObjWithoutRef(addTime);
    const updatedObj = {};
    newArr.map((item, index) => {
      const mondayTimeRange = addTime[0]?.timeRange;
      mondayTimeRange.map((timeRangeItem, timeRangeIndex) => {
        item.timeRange = mondayTimeRange;
        const [fromTime, toTime] = timeRangeItem?.range?.split("-");
        const { hours: updatedFromHours } = Utils.getFormattedTime(fromTime);
        const { hours: updatedToHours } = Utils.getFormattedTime(toTime);
        if (updatedObj[index + 1]) {
          updatedObj[index + 1][timeRangeIndex] = {
            fromTime: updatedFromHours,
            toTime: updatedToHours
          };
        } else {
          updatedObj[index + 1] = [
            {
              fromTime: updatedFromHours,
              toTime: updatedToHours
            }
          ];
        }
      });
      return item;
    });
    setFinalScheduledWindow(updatedObj);
    scheduleSendingWindowCbk(updatedObj);
    setAddTime(newArr);
    setApplyAll(false);
  };

  const handleClearAll = () => {
    let newArr = Utils.createObjWithoutRef(addTime);
    addTime.map((item, index) => {
      if (newArr[index]) {
        newArr[index]["timeRange"] = [];
      }
      delete finalScheduledWindow[index + 1];
      setFinalScheduledWindow(finalScheduledWindow);
      scheduleSendingWindowCbk(finalScheduledWindow);
    });
    setApplyAll(true);
    setAddTime(newArr);
  };

  const handleCustomTimeClick = index => {
    setApplyAll(index === 0);
    let newArr = [...addTime];
    const timeRangeLength = newArr?.[index]?.timeRange?.length;
    const currentTimeRange =
      newArr?.[index]?.timeRange?.[timeRangeLength - 1]?.range;
    if (currentTimeRange) {
      const [fromTime, toTime] = currentTimeRange?.split("-");
      const { hours: updatedToTime } = Utils.getFormattedTime(toTime);
      const [updatedToHour, updatedToMinutes] = updatedToTime
        ?.trim()
        ?.split(/[ :]/);
      if (+updatedToHour < 23) {
        // Restricting the time to not exceed today
        let flag = true,
          fromCounter = 1,
          toCounter = 2;
        while (flag) {
          const nextFromHour = +updatedToHour + fromCounter;
          const nextToHour = +updatedToHour + toCounter;
          const nextFromHourStr = nextFromHour + ":" + updatedToMinutes;
          const nextToHourStr = nextToHour + ":" + updatedToMinutes;
          if (nextToHour <= 23) {
            const validateArr = Utils.createObjWithoutRef(
              finalScheduledWindow?.[index + 1]
            );
            validateArr.push({
              fromTime: nextFromHourStr,
              toTime: nextToHourStr
            });
            if (!isTimeOverlaps(validateArr)) {
              flag = false;
              const newTime = `${Utils.getCustomTime(
                `${nextFromHour}:${updatedToMinutes}`
              )} - ${Utils.getCustomTime(`${nextToHour}:${updatedToMinutes}`)}`;
              newArr[index].timeRange.push({ range: newTime });
              const temp = { ...finalScheduledWindow };
              temp[index + 1].push({
                fromTime: `${nextFromHour}:${updatedToMinutes}`,
                toTime: `${nextToHour}:${updatedToMinutes}`
              });
              setFinalScheduledWindow({
                ...temp
              });
              scheduleSendingWindowCbk({
                ...temp
              });
              setAddTime(newArr);
            }
            fromCounter++;
            toCounter++;
          } else {
            toasterState.setToastMsg(
              "Oops! You cannot set working hours past 12 AM for a selected day.",
              "fail"
            );
            break;
          }
        }
      } else {
        toasterState.setToastMsg(
          "Oops! You cannot set working hours past 12 AM for a selected day.",
          "fail"
        );
      }
    }
  };

  const isTimeOverlaps = timeArr => {
    for (let i = 0; i < timeArr.length - 1; i++) {
      for (let j = i + 1; j < timeArr.length; j++) {
        if (Utils.isTimeOverlaps(timeArr[i], timeArr[j])) {
          return true;
        }
      }
    }
  };

  const isEligibleToShowCustomSelect = timeRangeIndex => {
    return timeRangeIndex === 0 && calendarState.isCustomWeek;
  };

  useEffect(() => {
    if (dailyTimeRange && Object.keys(dailyTimeRange)?.length > 0) {
      setFinalScheduledWindow(dailyTimeRange);
      getModifiedTimeRange(dailyTimeRange);
    }
  }, [dailyTimeRange]);

  return (
    <div className="sendingWindowContainer">
      {addTime?.map((item = {}, index = 0) => {
        const { timeRange = [], long = "" } = item;
        return (
          <SendingWindowContext.Provider
            value={{
              addTime,
              setAddTime,
              finalScheduledWindow,
              setFinalScheduledWindow,
              item,
              index,
              closeAction: handleCloseAction,
              scheduleSendingWindowCbk: scheduleSendingWindowCbk,
              handleTimeRangeBlockCbk: handleTimeRangeBlockCbk
            }}
            key={`sendingWindowContainer${index + 1}`}
          >
            <div className="daysWithTimeWrapper">
              <span className="WeekDays">{long}</span>
              <div className="timeRangeWrap">
                <div
                  className={`${
                    calendarState.isCustomWeek || timeRange.length > 0
                      ? "timeRangeContainer flexCenterTop"
                      : "flexCenter"
                  }`}
                >
                  {timeRange?.length > 0 ? (
                    timeRange.map((timeRangeItem, timeRangeIndex) => {
                      return !timeRangeItem?.isAddTime ? (
                        <div className="flexCenterPanel" key={timeRangeIndex}>
                          <TimeRangeBlock
                            timeRangeItem={timeRangeItem}
                            timeRangeIndex={timeRangeIndex}
                            setApplyAll={setApplyAll}
                          />
                          {isEligibleToShowCustomSelect(
                            timeRangeIndex,
                            index
                          ) && (
                            <i
                              className="material-icons addCustomTimeIcon"
                              onClick={e => handleCustomTimeClick(index)}
                            >
                              add
                            </i>
                          )}
                          {index === 0 && timeRangeIndex === 0 && (
                            <div
                              className={
                                applyAll ? "applyAll" : "applyAll clearAll"
                              }
                            >
                              <i
                                className="material-icons swapIcon"
                                onClick={
                                  applyAll ? handleApplyToAll : handleClearAll
                                }
                                onMouseOver={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                              >
                                {applyAll ? "repeat" : "clear_all"}
                              </i>
                              {showTooltip && (
                                <CustomTooltip
                                  text={
                                    applyAll ? "Apply to all days" : "Clear all"
                                  }
                                />
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <SelectedTimeRangePanel
                          defaultFromTime={defaultFromTime}
                          defaultToTime={defaultToTime}
                          timeRangeIndex={timeRangeIndex}
                          key={"range" + timeRangeIndex}
                        />
                      );
                    })
                  ) : (
                    <div
                      className="timeBlock"
                      onClick={e => handleAddTime(index)}
                    >
                      <i className="material-icons timeIcon">access_time</i>
                      <span className="addTimeText">Add time</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </SendingWindowContext.Provider>
        );
      })}
    </div>
  );
});

const TimeRangeBlock = props => {
  const {
    timeRangeIndex = 0,
    timeRangeItem = {},
    setApplyAll = () => {}
  } = props || {};
  const {
    addTime = [],
    setAddTime = () => {},
    item = {},
    finalScheduledWindow = {},
    setFinalScheduledWindow = () => {},
    scheduleSendingWindowCbk = () => {},
    handleTimeRangeBlockCbk = () => {},
    index = 0
  } = useContext(SendingWindowContext);

  const handleCloseTimeBlock = (event, index = 0, timeBlockIndex = 0) => {
    Utils.preventDefaultFn(event);
    setApplyAll(index === 0);
    let newArr = [...addTime];
    const timeBlock = newArr?.[index]?.["timeRange"];
    timeBlock.splice(timeBlockIndex, 1);
    if (finalScheduledWindow[index + 1]?.length > 1) {
      finalScheduledWindow[index + 1].splice(timeBlockIndex, 1);
    } else {
      delete finalScheduledWindow[index + 1];
    }
    setFinalScheduledWindow(finalScheduledWindow);
    scheduleSendingWindowCbk(finalScheduledWindow);
    newArr[index]["timeRange"] = timeBlock;
    setAddTime(newArr);
  };

  const handleTimeBlockClick = (timeRangeItem, index, timeRangeIndex) => {
    handleTimeRangeBlockCbk(timeRangeItem, index, timeRangeIndex);
  };

  return (
    <div className="timeRangeBlockCont">
      <div className="timeRangeBlockWrap" key={`timeRange${timeRangeIndex}`}>
        <div
          key={`TimeRangeBlock${timeRangeIndex}`}
          className="timeRangeBlock"
          onClick={() =>
            handleTimeBlockClick(timeRangeItem?.range, index, timeRangeIndex)
          }
        >
          <span>{timeRangeItem?.range?.toUpperCase() || ""}</span>
        </div>
        <i
          className="material-icons closeIcon"
          onClick={event => handleCloseTimeBlock(event, index, timeRangeIndex)}
        >
          close
        </i>
      </div>
    </div>
  );
};

export { SendingWindow, SendingWindowContext };
export default SendingWindow;
