import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { INSTALL_PLUGIN_URL, ConstantObj } from "Static/constant";
import { MP_EVENT } from "Static/constant";
import { ReactComponent as CloseSvg } from "Assets/svg/close.svg";
import { ReactComponent as InfoSvg } from "Assets/svg/info.svg";

const NotificationBanner = observer(
  ({ emailLimit = "", bannerType = "extension", hideBanner = () => {} }) => {
    let history = useHistory();

    const bannerObj = {
      EMAIL_LIMIT: {
        text: `You have reached your ${emailLimit} email sending limit. All outgoing emails are paused`,
        btnText: "Upgrade to resume",
        btnLink: "/pricing"
      },
      EXTENSION: {
        text: "Get Salesgear on your Gmail and CRM.",
        btnText: "Install",
        btnLink: INSTALL_PLUGIN_URL
      },
      CUSTOM_URL: {
        text: "Improve your email deliverability by setting up a custom URL",
        btnText: "Set up Custom URL",
        btnLink: "/settings/customUrl"
      },
      CONNECT_INBOX: {
        text: "Scheduled emails suspended as access to email account was revoked",
        btnText: "Re-connect to resume",
        btnLink: "/settings/account"
      },
      DAILER_CREDITS: {
        text: "Call credits running low. Top up now to avoid losing your number",
        btnText: "Add credits",
        btnLink: "/settings/dialer/general"
      },
      DHC: {
        text: `Stay on top of domain health by monitoring SPF, DKIM, DMARC 24/7 for all email accounts.`,
        btnText: "View DHC",
        btnLink: "/settings/domain-health-center"
      }
    };

    let ele = bannerObj[bannerType];

    const installPluginRedirect = () => {
      Utils.mixpanelTrack(MP_EVENT.PLUGIN_INSTALL_CLICKED, {
        pageType: window.location.pathname
      });
      userDetail.setInstallNowPluginClicked(true);
      Utils.windowRedirect(ele?.btnLink || INSTALL_PLUGIN_URL);
      hideBanner(false);
    };

    const handleRedirect = () => {
      if (bannerType === "EXTENSION") {
        installPluginRedirect();
      } else {
        if (ele?.btnLink) {
          history.push(ele?.btnLink);
          hideBanner(false);
        }
      }
    };

    useEffect(() => {
      if (bannerType === "EXTENSION") {
        userDetail.setInstallNowPluginClicked(false);
      }
    }, []);

    return (
      bannerType &&
      ele?.text && (
        <div className="notificationBanner">
          <div className="notificationCard">
            <span className="infoSvgIcon">
              <InfoSvg />
            </span>
            <span
              className="text"
              dangerouslySetInnerHTML={{ __html: ele?.text || "" }}
            />
            {ele?.btnText && (
              <span className="btnText" onClick={handleRedirect}>
                {ele?.btnText}
              </span>
            )}
            <span className="closeSvgIcon" onClick={() => hideBanner(false)}>
              <CloseSvg />
            </span>
          </div>
        </div>
      )
    );
  }
);

export default NotificationBanner;
