import React, { useEffect } from "react";
import { observer } from "mobx-react";
import taskStoreData from "Stores/taskData";
import { useParams, useLocation } from "react-router-dom";
import AccountsDetailedRouting from "Routes/AccountsDetailedRouting";
import { accountsState } from "./AccountsState";
import TabNavigation from "Components/common/TabNavigation";
import AccountsInfo from "./AccountInfoCard/AccountsInfo";
import { ACCOUNTS_NAVIGATION } from "Static/constant";
import EditAccountInfoPopup from "./AccountInfoCard/EditAccountInfoPopup";

const AccountDetailedView = observer(() => {
  const { accountId = "" } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname?.indexOf("/tasks") < 0) {
      taskStoreData.setResetState();
    }
  }, [location]);

  useEffect(() => {
    return () => {
      accountsState.setAccountName("");
    };
  }, []);

  return (
    <div className="accountDetailedView">
      <div className="accountDetailedViewWrapper">
        <TabNavigation tabList={ACCOUNTS_NAVIGATION} activeTab={"overview"} />
        <AccountsDetailedRouting />
      </div>
      <AccountsInfo accountId={accountId} />
      <EditAccountInfoPopup />
    </div>
  );
});

export default AccountDetailedView;
export { AccountDetailedView };
