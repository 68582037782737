import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import { AutoCompleteDropdown } from "Components/common/AutoCompleteDropdown";

const OwnersLoader = () => {
  return Array.from({ length: 5 }, (value, index) => (
    <tr key={index}>
      <td className="crmDetailsLoader" style={{ width: "50%" }}>
        <span className="linear-background"></span>
      </td>
      <td className="sgDetailsLoader" style={{ width: "50%" }}>
        <span className="linear-background"></span>
      </td>
    </tr>
  ));
};

const TableHeader = props => {
  const { crmName = "" } = props || {};
  const tableHeader = [
    {
      name: `${crmName} User`,
      key: `crm${crmName}User`,
      style: { width: "50%" }
    },
    {
      name: `Salesgear User`,
      key: "salesgearUsers",
      style: { width: "50%" }
    }
  ];

  return (
    <thead>
      <tr>
        {tableHeader?.map(item => {
          const { name = "", key = "", style = {} } = item || {};
          return (
            <th className="textDark" key={key} style={style}>
              {name}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const TableBody = observer(props => {
  const {
    ownersList = {},
    setOwnersList = () => {},
    config = {},
    setConfig = () => {},
    getAllOwners = () => {}
  } = props;
  const [sgOwnersList, setSgOwnersList] = useState([]);

  const customCbk = (item, index) => {
    const indexValue = Number(index.split("-")[1]);
    const temp = config;
    const crmItem = ownersList?.[indexValue - 1];
    temp[indexValue - 1] = {
      ...config?.[indexValue - 1],
      sgOwnerEmail: item?.email,
      sgOwnerName: item?.name,
      sgOwnerId: item?.memberId,
      crmOwnerName: crmItem?.name,
      crmOwnerEmail: crmItem?.email
    };
    setConfig([...temp]);
  };

  const transformOwnerList = data => {
    let temp = [];
    data?.length &&
      data.map(item => {
        const userInfo = Utils.getFullNameFromUserInfo(item);
        temp.push({
          name: userInfo,
          email: item?.email,
          memberId: item?.memberId
        });
      });
    return temp;
  };

  const getTeamList = async () => {
    const resData = await Utils.getAllMembersInTeam();
    setSgOwnersList(transformOwnerList(resData));
  };

  useEffect(() => {
    getAllOwners();
    getTeamList();
    return () => {
      setOwnersList("");
    };
  }, []);

  return (
    <tbody>
      {ownersList ? (
        ownersList?.length > 0 &&
        ownersList.map((ownerItem, index) => {
          let userInfo = [];
          if (config?.length > 0) {
            userInfo =
              config?.filter(
                item => item?.crmOwnerEmail === ownerItem?.email
              )[0] || [];
            sgOwnersList.map(item => {
              if (
                userInfo?.sgOwnerEmail === item?.email &&
                item?.name !== userInfo?.sgOwnerName
              ) {
                userInfo.sgOwnerName = item?.name;
              }
            });
          }

          return (
            <tr key={`${ownerItem?.name}`}>
              <td className="crmDetails" style={{ width: "50%" }}>
                {ownerItem?.name || ownerItem?.email}
              </td>
              <td className="sgDetails" style={{ width: "50%" }}>
                <AutoCompleteDropdown
                  optionList={sgOwnersList}
                  defaultValue={
                    userInfo?.sgOwnerName || userInfo?.sgOwnerEmail || ""
                  }
                  cbk={customCbk}
                  index={`key-${index + 1}`}
                  loader={!sgOwnersList}
                />
              </td>
            </tr>
          );
        })
      ) : (
        <OwnersLoader />
      )}
    </tbody>
  );
});

const Owners = observer(props => {
  const { mappingConfig = [], crmName = "", ...remainingProps } = props;

  return (
    <div className="ownersWrapper">
      <h6 className="headingText">Field mapping</h6>
      <div className="fieldMappingTable tableView">
        <table>
          <TableHeader crmName={crmName} />
          <TableBody mappingConfig={mappingConfig} {...remainingProps} />
        </table>
      </div>
    </div>
  );
});

export { Owners };
export default Owners;
