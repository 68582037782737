/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { withRouter, Link, useLocation } from "react-router-dom";
import { REQUEST_DEMO_CALENDAR_LINK, WEBSITE_URL } from "Static/constant";
import { ReactComponent as SalesgearLogo } from "Assets/logo/svg/logo.svg";

const RegisterHeader = props => {
  const location = useLocation();

  const centerBrand = props.resetPath ? "center-logo" : "";
  const [menuOpened, setMenuOpened] = useState(false);
  const [registerMenu, setRegistermenu] = useState(false);

  const linkUrl = [
    {
      name: "Login",
      key: "loginRedirectUrl",
      path: "/login",
      showMenu: registerMenu
    },
    {
      name: "Signup",
      key: "signupRedirectUrl",
      path: "/signup",
      showMenu: registerMenu
    },
    {
      name: "Pricing",
      key: "pricingRedirectUrl",
      path: `${WEBSITE_URL}/pricing`,
      showMenu: true
    },
    {
      name: "Request Demo",
      key: "requestDemoRedirectUrl",
      path: REQUEST_DEMO_CALENDAR_LINK,
      showMenu: true,
      isDemoBtn: true
    }
  ];

  useEffect(() => {
    location.pathname.indexOf("/pricing") >= 0 && setRegistermenu(true);
  }, [location]);

  return (
    <nav>
      <div className="headerMenuWrapper">
        <div className={`logo-title ${centerBrand}`}>
          <SalesgearLogo onClick={() => props.history.push("/login")} />
        </div>
        <div className="menuWrapper">
          <div
            className="menuIcon"
            onClick={() => {
              setMenuOpened(!menuOpened);
            }}
          >
            <i className="material-icons">
              {!menuOpened ? "menu" : "menu_open"}
            </i>
          </div>
          <ul className={menuOpened ? "menuListDropdown" : "menuList"}>
            {linkUrl?.map(item => {
              const {
                showMenu = true,
                name = "",
                key = "",
                path = "/",
                isDemoBtn = false
              } = item || {};
              return (
                showMenu && (
                  <li
                    key={key}
                    className={`menuItems ${
                      (isDemoBtn && "requestDemo") || ""
                    }`}
                  >
                    {isDemoBtn ? (
                      <a href={path} target="_blank" rel="noopener noreferrer">
                        {name}
                      </a>
                    ) : (
                      <Link to={path}>{name}</Link>
                    )}
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(RegisterHeader);
export { RegisterHeader };
