import * as Driver from "driver.js";
import "driver.js/dist/driver.min.css";

const DriverMaster = {};

const closeCbk = () => {
  DriverMaster.isDriverActive = false;
};

DriverMaster.killDriver = () => {
  if (DriverMaster.isDriverActive && DriverMaster.driverInstance) {
    DriverMaster.driverInstance.reset();
  }
};

const driverOptions = {
  animate: true,
  allowClose: true,
  onReset: closeCbk
};
DriverMaster.driverInstance = new Driver(driverOptions);

DriverMaster.execute = steps => {
  if (steps?.length) {
    let result = "";
    DriverMaster.isDriverActive = true;
    const { driverInstance } = DriverMaster;
    driverInstance.defineSteps(steps);
    try {
      driverInstance.start();
      result = true;
    } catch (e) {
      console.error("Driver initate failed", e);
      result = false;
    }
    return result;
  }
};

export default DriverMaster;
export { DriverMaster };
