import React, { useState, useEffect } from "react";
import makeApi, { URLS } from "Utils/apiURL";
import ResendButton from "./ResendButton";
import { captchaStoreValue } from "Stores/captcha";

const EmailVerification = props => {
  const { verificationData = {}, loader = false } = props || {};
  const [noOfClicks, setNoOfClicks] = useState(1);

  const resendEmailToken = async token => {
    if (noOfClicks <= 2) {
      setNoOfClicks(noOfClicks => noOfClicks + 1);
      if (verificationData?.email && token) {
        const config = {
          url: `${URLS.signupVerification}/${verificationData?.email}/${token}`,
          method: "GET"
        };
        await makeApi(config);
      }
    }
  };

  const validateCaptchaFromAPi = async token => {
    token && resendEmailToken(token);
  };

  const handleResendBtnClick = async () => {
    captchaStoreValue.executeCaptcha(validateCaptchaFromAPi);
  };

  const resendBtnObj = {
    handleClick: handleResendBtnClick,
    noOfClicks: noOfClicks,
    setNoOfClicks: setNoOfClicks
  };

  useEffect(() => {
    captchaStoreValue.setCaptcha(true);
  }, []);

  return (
    <section
      className={`formInputBox ${loader ? "pointerUserSelectNone" : ""}`}
    >
      <section className={"verificationTextWrapper"}>
        <h3>Verification</h3>
        {verificationData?.email && (
          <p className={"confirmationText"}>
            We have successfully sent <b>verification mail</b> to{" "}
            <a
              className="emailLink"
              href={`mailTo:${verificationData?.email}`}
            >{`${verificationData?.email}.`}</a>{" "}
            Please check your inbox and verify your email address to continue
            using Salesgear.
          </p>
        )}
        <ResendButton {...resendBtnObj} />
      </section>
    </section>
  );
};

export { EmailVerification };
export default EmailVerification;
