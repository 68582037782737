import React, { useContext } from "react";
import { ReactComponent as FilterIcon } from "../images/svg/filter.svg";
import { LiveFeedMainContext } from "../main";
import DropDownMenu from "./DropdownMenu";

const FilterByEvent = () => {
  const { showDropdown, setShowDropdown } = useContext(LiveFeedMainContext);

  const handleFilterClick = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      <div className="filterByEventWrapper" onClick={handleFilterClick}>
        <FilterIcon />
        <div className="filterTextWrapper">
          <span>Filter by</span>
          <i className="material-icons dropdownFilterIcon">
            {showDropdown ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </i>
        </div>
      </div>
      {showDropdown && <DropDownMenu />}
      {showDropdown && (
        <div className="filterOverlay" onClick={() => setShowDropdown(false)} />
      )}
    </>
  );
};

export { FilterByEvent };
export default FilterByEvent;
