import React, { useState } from "react";
import Utils from "Utils/utils";

const CopyContent = (props = {}) => {
  const { copyData = "" } = props || {};
  const [copyContent, setCopyContent] = useState(false);

  const handleCopyButton = event => {
    Utils.preventDefaultFn(event);
    setCopyContent(true);
    const element = document.createElement("div");
    element.innerText = copyData;
    Utils.copyToClipboard(element?.textContent);
    setTimeout(() => {
      setCopyContent(false);
    }, 1500);
  };

  return (
    <div className="copyContentWrapper">
      <div className="copyContentCont">
        <div className="copyContentBody">{copyData}</div>
        <div className="copyContentHeader">
          <div
            className={`${copyContent ? "copiedButtonWrap" : "copyButtonWrap"}`}
            onClick={handleCopyButton}
          >
            <span className="material-icons-outlined copyButton">copy</span>
            <span className="copyText">{copyContent ? "Copied" : "Copy"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CopyContent };
export default CopyContent;
