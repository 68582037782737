import React, { useState, memo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import userDetail, { userObj } from "Stores/userDetail";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import { SequenceUtils } from "Utils/SequenceUtils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { ReactComponent as SettingsIcon } from "./Icons/settings.svg";
import { ReactComponent as LogoutIcon } from "./Icons/logout.svg";
import { ReactComponent as BillingIcon } from "./Icons/billing.svg";
import { ReactComponent as SupportIcon } from "./Icons/support.svg";
import DropdownMenu from "Components/common/DropDownMenu";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { SUPPORT_LINK } from "Static/constant";

const UserIcon = observer(() => {
  let history = useHistory();

  const {
    firstName = "",
    lastName = "",
    email = ""
  } = userDetail?.userInfo || {};

  const [showDropDown, setShowDropDown] = useState(false);

  const getUserProfileIcon = () => {
    let fn = firstName?.trim() || "";
    let ln = lastName?.trim() || "";
    if (fn && ln) {
      return `${fn?.charAt(0)}${ln?.charAt(0)}`;
    } else {
      let name = fn || ln || email || "SG";
      return name?.slice(0, 2);
    }
  };

  const logout = async () => {
    try {
      const config = {
        url: URLS.logout,
        method: "POST"
      };
      let res = await makeApi(config);
      confirmationPopupState.setShowPopup(false);
      if (res) {
        userDetail.setUserInfo(userObj);
        SequenceUtils.removeActiveSequenceList();
        document.cookie = "li=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.href = "/login";
        Utils.mixpanelTrack(MXP_EVENT.LOGOUT_CLICK_HEADER, {
          pageType: PAGE_TYPE.header
        });
      } else {
        toasterState.setToastMsg(
          "Sorry, there was an issue while logging out of your account. Please try again later.",
          "failure"
        );
        console.error(`Salesgear Logout Failed`, res);
      }
    } catch (err) {
      console.error(`Salesgear Logout Failed`, err);
    }
  };

  const logoutConfirmPrompt = () => {
    confirmationPopupState.setPopupValues({
      title: "Sign Out?",
      content: "Are you sure that you want to sign out?",
      actionBtnText: "Yes",
      callback: logout,
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const goToSupportArticle = () => {
    setShowDropDown(false);
    Utils.mixpanelTrack(MXP_EVENT.SUPPORT_CLICK_HEADER, {
      pageType: PAGE_TYPE.header
    });
    window.open(SUPPORT_LINK, "_blank", "noopener");
  };

  const goToUserProfile = () => {
    setShowDropDown(false);
    Utils.mixpanelTrack(MXP_EVENT.SETTINGS_CLICK_HEADER, {
      pageType: PAGE_TYPE.header
    });
    history.push("/settings");
  };

  const goToBilling = () => {
    setShowDropDown(false);
    history.push("/settings/billing");
  };

  const dropdownOptions = [
    {
      icon: {
        ele: <SettingsIcon />,
        style: { width: "12px", height: "12px" }
      },
      value: "Settings",
      key: "myProfile",
      method: goToUserProfile
    },
    {
      icon: {
        ele: <BillingIcon />,
        style: { width: "12px", height: "12px" }
      },
      value: "Billing",
      key: "billing",
      method: goToBilling
    },
    {
      icon: {
        ele: <SupportIcon />,
        style: { width: "12px", height: "12px" }
      },
      value: "Support",
      key: "support",
      method: goToSupportArticle
    },
    {
      icon: {
        ele: <LogoutIcon />,
        style: { width: "12px", height: "12px" }
      },
      value: "Sign out",
      key: "signOut",
      method: logoutConfirmPrompt
    }
  ];

  return (
    <div
      className="userMenuIconAction"
      onMouseEnter={() => setShowDropDown(true)}
      onMouseLeave={() => setShowDropDown(false)}
    >
      <span className="userIcon">{getUserProfileIcon()}</span>
      {showDropDown && (
        <DropdownMenu
          options={dropdownOptions}
          referenceId="userIconSettings"
        />
      )}
    </div>
  );
});

const MemoizedProfile = memo(UserIcon);
export default UserIcon;
export { MemoizedProfile };
