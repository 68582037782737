import React from "react";
import Button from "Components/common/Button";
import SequenceImg from "Assets/png/fileSizeExceeded.png";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { addBulkContactPopupState } from "Components/common/bulkContactPopup/AddBulkContactPopup";

class FileLimitExceedState {
  @observable showPopup = false;
  @observable message = "";
  @observable type = "";

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setMessage = val => {
    this.message = val;
  };

  @action
  setType = (val = "") => {
    this.type = val;
  };
}

const fileLimitExceedState = new FileLimitExceedState();

const FileLimitExceedPopup = () => {
  const closePopup = () => {
    fileLimitExceedState.setShowPopup(false);
    if (fileLimitExceedState?.type?.toLowerCase() === "csv") {
      addBulkContactPopupState.setShowPopup(true);
    }
    fileLimitExceedState.setMessage("");
    fileLimitExceedState.setType("");
  };

  return fileLimitExceedState.showPopup ? (
    <>
      <div className="fileLimitExceedPopup">
        <div className="card animated customzoomIn">
          <div className="cardBody">
            <div className="fileUploadImg">
              <img src={SequenceImg} alt="File Exceed" />
            </div>
            <h4>Upload failed</h4>
            <p
              className="message"
              dangerouslySetInnerHTML={{ __html: fileLimitExceedState.message }}
            />
            <Button
              id="attachmentTryAgainBtn"
              name="attachmentTryAgainBtn"
              btnText="Try Again"
              icon={false}
              iconName=""
              type="button"
              className="attachmentTryAgainBtn"
              loader={false}
              click={closePopup}
            />
          </div>
        </div>
      </div>
      <div className="overlayFileAttachemntExceedMask" />
    </>
  ) : (
    ""
  );
};

export default observer(FileLimitExceedPopup);
export { FileLimitExceedPopup, fileLimitExceedState };
