/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { settingsStoreData } from "Stores/settingsData";
import Button from "Components/common/Button";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";

import { ReactComponent as ThunderBlueIcon } from "Assets/svg/thunderBlue.svg";
import PaywallUtils from "Utils/PaywallUtils";

const CustomFields = () => {
  const {
    customFieldsEnabled = false,
    customFieldsCount = 0,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const [inputVal, setInputVal] = useState(settingsStoreData.customFields);
  const [loader, setBtnLoader] = useState(false);
  const [isBtnEnabled, setBtnEnabled] = useState(false);
  const [inputLoader, setInputLoader] = useState(false);

  const changeInputVal = (id, value) => {
    setBtnEnabled(true);
    const stateObj = { ...inputVal };
    stateObj[id] = value;
    setInputVal(stateObj);
  };

  const saveCustomFields = async () => {
    if (customFieldsEnabled) {
      if (Utils.isAdmin()) {
        setBtnLoader(true);
        if (inputVal) {
          const payload = inputVal;
          await settingsStoreData.updateCustomFields(
            payload,
            customFieldUpdateCbk
          );
        }
      } else {
        showAdminPopup();
      }
    }
  };

  const customFieldUpdateCbk = (flag, res) => {
    setBtnLoader(false);
    if (flag) {
      toasterState.setToastMsg("Custom Fields Updated Successfully", "success");
      setBtnEnabled(false);
    } else {
      if (res?.response?.status === 403) {
        showAdminPopup();
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "failure"
        );
      }
    }
  };

  const showAdminPopup = () => {
    let msg =
      "Please contact your account administrator to change your custom fields";
    Utils.showMessagePopup(msg);
    setBtnEnabled(false);
    setInputVal(settingsStoreData.customFields);
  };

  const hideInputLoader = () => {
    setInputLoader(false);
  };

  const getInitializeCustomFields = () => {
    settingsStoreData.getCustomFields(hideInputLoader);
    Utils.mixpanelTrack(MP_EVENT.CF_PAGE_VIEWED, {
      pageType: window.location.pathname
    });
  };

  useEffect(() => {
    setInputVal(settingsStoreData.customFields);
  }, [settingsStoreData?.customFields]);

  const upgradeCustomFieldsCount = () => {
    PaywallUtils.upgradeUtilsFunction(
      "customFieldsCount",
      false,
      planName,
      inFreePlan,
      { customFieldsCount }
    );
  };

  useEffect(() => {
    if (!customFieldsEnabled) {
      upgradeCustomFieldsCount();
    }
    getInitializeCustomFields();
  }, []);

  const disableCheckFun = (updatedIndex = 1) => {
    return updatedIndex > customFieldsCount;
  };

  return (
    <div className={`customFields ${!customFieldsEnabled ? "blurBg" : ""}`}>
      <div className="cusotmFieldsInnerCont">
        <h5 className="titleHeader">Configure Custom Fields</h5>
        <div className="fieldList">
          {Array.from({ length: customFieldsCount }, (value, index) => {
            const updatedIndex = index + 1;
            return (
              <CustomFieldsInputBox
                id={`customField${updatedIndex}`}
                label={`Custom Field ${updatedIndex} Label`}
                changeValue={changeInputVal}
                value={inputVal?.[`customField${updatedIndex}`]}
                inputLoader={inputLoader}
                disableCheck={disableCheckFun(updatedIndex)}
                planName={planName}
                indexValue={updatedIndex}
              />
            );
          })}
          <div className="addCustomFields" onClick={upgradeCustomFieldsCount}>
            <div>Add more custom fields</div>
            <ThunderBlueIcon />
          </div>
        </div>

        <div className={`actionCont ${!isBtnEnabled ? "btnDisabled" : ""}`}>
          <Button
            id="customField"
            name="customField"
            btnText="Save Changes"
            icon={false}
            iconName=""
            type="button"
            className={`customField ${loader ? "loading" : ""}`}
            loader={loader}
            click={saveCustomFields}
          />
        </div>
      </div>
    </div>
  );
};

const CustomFieldsInputBox = props => {
  const {
    id,
    label,
    changeValue,
    value,
    inputLoader,
    disableCheck = false,
    planName = "free",
    indexValue = 1
  } = props || {};

  const [showUpgradeTp, setShowUpgradeTp] = useState(false);

  const onChange = async event => {
    changeValue && changeValue(id, event.currentTarget.value);
  };

  return (
    <div className="customFieldsInputBox">
      <div className="innerCont">
        <div className="labelCont">
          <label htmlFor={id} className={`inputLabel`}>
            {label}
          </label>
        </div>
        <div
          className="valueCont"
          onMouseEnter={() => setShowUpgradeTp(true)}
          onMouseLeave={() => setShowUpgradeTp(false)}
        >
          <input
            id={id}
            placeholder={"Enter Label Value"}
            autoComplete="off"
            name={id}
            className="inputBox"
            type={"text"}
            onChange={e => onChange(e)}
            value={value ? value : ""}
            disabled={disableCheck}
          />
          {inputLoader && <span className="customFieldInputSpinLoader"></span>}
        </div>
      </div>
    </div>
  );
};

export default observer(CustomFields);
export { CustomFields };
