import React from "react";
import noAccountIcon from "Assets/Settings/accountConnectInbox.png";
import GoogleBtnImg from "Assets/Settings/googleWhite.png";
import MicrosoftBtnImg from "Assets/Settings/microsoft.png";

const EmptyInbox = props => {
  const {
    gmailAuthCbk = () => {},
    microsoftAuthCbk = () => {},
    smtpImapAuthCbk = () => {}
  } = props || {};
  return (
    <div className="emptyInbox">
      <img src={noAccountIcon} alt="No Account Found" />
      <h6>Add your email account</h6>
      <div className="connectInboxButton">
        <img
          id="googleAuthBtn"
          className="googleAuthBtn"
          src={GoogleBtnImg}
          alt="Sign in with Google"
          onClick={gmailAuthCbk}
        />
        <img
          id="outlookAuthBtn"
          className="outlookAuthBtn"
          src={MicrosoftBtnImg}
          alt="Sign in with Microsoft"
          onClick={microsoftAuthCbk}
        />
        <div className="smtpAuthBtn" id="smtpAuthBtn" onClick={smtpImapAuthCbk}>
          <span className="material-icons-outlined smtpImapIcon">email</span>
          <span className="btnCta">Connect SMTP/IMAP</span>
        </div>
      </div>
    </div>
  );
};

export default EmptyInbox;
export { EmptyInbox };
