/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeApi, URLS } from "Utils/apiURL";

const SlackOauth = () => {
  const [isConnected, setConnected] = useState(false);

  const getSlackAuthCode = () => {
    try {
      const url = new URL(window.location.href);
      const code = url?.searchParams.get("code");
      !code
        ? url?.href?.indexOf("access_denied") !== -1
          ? closeWindow()
          : ""
        : "";
      return code;
    } catch (e) {
      closeWindow();
      return null;
    }
  };

  const connectSlackApp = async () => {
    const code = getSlackAuthCode();
    try {
      if (code) {
        const config = {
          url: `${URLS.connectSlack}?code=${code}`,
          method: "POST"
        };
        const res = await makeApi(config);
        const resCode = res?.data?.responseCode?.toString() || "500";
        opener?.postMessage({ code: resCode, type: "SLACK_CONNECT_OAUTH" });
        if (resCode === "200") {
          setConnected(true);
          setTimeout(() => {
            closeWindow();
          }, 1000);
        } else {
          closeWindow();
        }
      } else {
        closeWindow();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const closeWindow = () => {
    window?.close();
  };

  useEffect(() => {
    connectSlackApp();
  }, []);

  return (
    <div className="pageClass">
      <div className="pageCont">
        <div className="connectAppSection">
          {isConnected ? (
            <div>
              <span>Successfully Connected</span>
            </div>
          ) : (
            <div>
              <div>Please wait while we connect your Slack account</div>
              <div style={{ marginTop: "10px" }}> Connecting... </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SlackOauth;
export { SlackOauth };
