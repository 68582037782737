import React from "react";
import { ReactComponent as SearchIcon } from "Assets/svg/search.svg";

const SearchByContactKeyword = props => {
  const {
    className = "",
    searchKeyword = "",
    isEmailClose = false,
    submitForm = () => {},
    clearSearch = () => {},
    searchContact = () => {},
    handleSearchAction = () => {},
    placeHolder = "Search Contacts",
    inputRef = null
  } = props || {};

  return (
    <div className={`searchWrapper ${className}`}>
      <div className="searchInput">
        <span className="searchImage" onClick={e => searchContact(e)}>
          <SearchIcon />
        </span>
        <span className="searchTextBox">
          <input
            name="search"
            ref={inputRef}
            id="searchEmailContact"
            autoComplete="off"
            value={searchKeyword}
            type="text"
            placeholder={placeHolder}
            onKeyUp={e => submitForm(e)}
            onChange={handleSearchAction}
            required
          />
          {isEmailClose && (
            <i className="material-icons" onClick={clearSearch}>
              close
            </i>
          )}
        </span>
      </div>
    </div>
  );
};

export default SearchByContactKeyword;
export { SearchByContactKeyword };
