import React, { useContext } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import Button from "Components/common/Button";
import { ReactComponent as DeleteIcon } from "./img/deleteIcon.svg";
import { ReactComponent as RenameIcon } from "./img/renameIcon.svg";
import CreateTeamImg from "./img/createTeam.png";
import TeamTableLoader from "./TeamTableLoader";
import teamState from "./state";
import { teamMemberState } from "./TeamMember/state";
import { SettingContext } from "Pages/Dashboard/settings/Settings";

const EmptyTeam = props => {
  const { handleCreateTeam = () => {} } = props || {};

  const btnProps = {
    id: "createTeamBtn",
    name: "createTeamBtn",
    btnText: "Create Team",
    type: "button",
    className: "createTeamBtn",
    click: handleCreateTeam
  };

  return (
    <div className="emptyTeamWrapper">
      <img className="createTeamImg" src={CreateTeamImg} alt="Create Team" />
      <Button {...btnProps} />
    </div>
  );
};

const ActionIcons = observer((props = {}) => {
  const { list, index = 0 } = props || {};

  //Rename actions
  const handleRenameIcon = (index, name) => {
    teamState.setEditTeamNameIndex(index);
    teamState.setUpdatedTeamName(name);
  };

  // Delete Actions

  const deleteAction = async (id, name) => {
    await teamState.deleteTeam(id, name);
  };

  const handleDeleteIcon = (list = {}) => {
    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to delete the team ${list?.name}?`,
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      callback: () => deleteAction(list?.id, list?.name),
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  return (
    <div className="editDeleteAction">
      <span
        className="editIcon"
        onClick={e => {
          Utils.preventDefaultFn(e);
          handleRenameIcon(index, list?.["name"], list?.id);
        }}
      >
        <RenameIcon />
      </span>
      <span
        className="deleteIcon"
        onClick={e => {
          Utils.preventDefaultFn(e);
          handleDeleteIcon(list);
        }}
      >
        <DeleteIcon />
      </span>
    </div>
  );
});

const TeamTable = observer(props => {
  const { team = "" } = userDetail?.userInfo || {};
  const { updateBreadCrumb = () => {} } = useContext(SettingContext);
  const { handleCreateTeam = () => {} } = props || {};

  const tableObj = [
    {
      label: "Team Name",
      className: "teamName",
      keyVal: "name"
    },
    {
      label: "Members",
      className: "members",
      keyVal: "count"
    },
    {
      label: "Created on",
      className: "createdOn",
      keyVal: "createTs"
    },
    {
      label: "Updated on",
      className: "updatedOn",
      keyVal: "updateTs"
    },
    {
      label: "Owner",
      className: "owner",
      keyVal: "owner"
    },
    {
      label: "",
      className: "moreOption",
      keyVal: "moreOption"
    }
  ];

  const saveTeamName = (event, groupId) => {
    if (teamState.updatedTeamName) {
      Utils.preventDefaultFn(event);
      teamState.setEditTeamLoader(true);
      teamState.editTeamName(groupId, teamState.updatedTeamName);
    } else {
      confirmationPopupState.setPopupValues({
        content: `Please enter team name`,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => {
          confirmationPopupState.setShowPopup(false);
        }
      });
      confirmationPopupState.setShowPopup(true);
    }
  };

  const handleTeamNameChange = e => {
    Utils.preventDefaultFn(e);
    teamState.setUpdatedTeamName(e?.currentTarget?.value);
  };

  const handleSubmitNewName = (event, groupId) => {
    if (Utils.isSubmitKeyAction(event)) {
      saveTeamName(event, groupId);
    }
  };

  const getTeamName = (index, list, item) => {
    const { className = "", keyVal = "" } = item || {};
    return teamState?.editTeamNameIndex === index ? (
      teamState.editTeamLoader ? (
        <div className="fullName" key={`${keyVal}${index}`}>
          <span className="linear-background" />
        </div>
      ) : (
        <div
          className={className}
          key={`${keyVal}${index}`}
          onClick={e => Utils.preventDefaultFn(e)}
        >
          <div className={`inputWithCloseIcon`}>
            <input
              className="editTeamName"
              value={teamState.updatedTeamName}
              onChange={handleTeamNameChange}
              onKeyUp={e => handleSubmitNewName(e, list?.id)}
              autoFocus
            />
            <i
              className="material-icons closeIcon"
              onClick={e => {
                Utils.preventDefaultFn(e);
                teamState.setEditTeamNameIndex("");
              }}
            >
              close
            </i>
          </div>
        </div>
      )
    ) : (
      <div className={className} key={`${keyVal}${index}`}>
        {list?.[keyVal]}
      </div>
    );
  };

  const handleTeamNameClick = (event, list = {}) => {
    Utils.preventDefaultFn(event);
    updateBreadCrumb(`Users & Teams - ${list?.name}`);
    teamState.setEditTeamNameIndex("");
    teamState.setShowTeamMemberPage(true);
    teamMemberState.setTeamMemberLoading(true);
    teamState.setSelectedGroup(list);
    teamMemberState.getGroupMembers(list?.id);
  };

  return teamState?.tableLoading ? (
    <div className="tableSection">
      <div className="headerPadding">
        <div className="tableHeader loadingData" />
      </div>
      <div className="tableBody">
        <TeamTableLoader count={20} value="multiRowLoader" />
      </div>
    </div>
  ) : teamState.teamData?.groupList?.length > 0 ? (
    <div className="tableSection">
      <div className="headerPadding">
        <div className="tableHeader">
          {tableObj.map((item, index) => {
            const { className = "", label = "" } = item || {};
            return (
              <div className={className} key={`${label?.trim()}${index}`}>
                {label}
              </div>
            );
          })}
        </div>
      </div>
      <div className="tableBody">
        {!teamState?.tableLoading &&
          teamState?.teamData &&
          Object.keys(teamState?.teamData).length > 0 &&
          teamState?.teamData?.groupList?.length > 0 &&
          teamState?.teamData.groupList.map((list, index) => {
            return (
              <div
                className="tableRow"
                key={`teamTableRow${index}`}
                style={{ cursor: "pointer" }}
                onClick={event => handleTeamNameClick(event, list)}
              >
                {tableObj.map(item => {
                  const { className = "", keyVal = "" } = item || {};
                  return keyVal === "owner" ? (
                    <div className={className} key={`${keyVal}${index}`}>
                      {(list?.["ownerDetails"] && (
                        <span className="circleOwner">
                          {Utils.getOwnerInitial(list?.["ownerDetails"])}
                        </span>
                      )) ||
                        "--"}
                    </div>
                  ) : keyVal === "createTs" || keyVal === "updateTs" ? (
                    <div className={className} key={`${keyVal}${index}`}>
                      {Utils.formatDate(list?.[keyVal])}
                    </div>
                  ) : keyVal === "name" ? (
                    getTeamName(index, list, item)
                  ) : (
                    <div className={className} key={`${keyVal}${index}`}>
                      {list?.[keyVal]}
                    </div>
                  );
                })}
                {team && <ActionIcons index={index} list={list} />}
              </div>
            );
          })}
        {teamState.singleLineLoader && <TeamTableLoader count={1} />}
      </div>
    </div>
  ) : (
    <EmptyTeam handleCreateTeam={handleCreateTeam} />
  );
});

export { TeamTable };
export default TeamTable;
