import React from "react";

const FooterAction = ({ actionList = [] }) => {
  return (
    <div className="filterAction">
      {(actionList?.length > 0 &&
        actionList.map((item, index) => {
          const {
            className = "",
            name = "",
            id = "",
            method = () => {}
          } = item || {};
          return (
            <div
              className={className}
              onClick={method}
              key={`${id}Action${index}`}
            >
              {name}
            </div>
          );
        })) ||
        ""}
    </div>
  );
};

export default FooterAction;
