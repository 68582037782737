import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";

const defaultBulkStageUpdateObj = {
  accountCount: 0,
  accountIds: [],
  allAccountsSelectedInView: false
};

class AccountBulkActionsState {
  @observable showBulkUpdateStagePopup = false;
  @observable bulkStageUpdateObj = { ...defaultBulkStageUpdateObj };

  @action
  setShowBulkUpdateStagePopup = (val = false) => {
    this.showBulkUpdateStagePopup = val;
  };

  @action
  setBulkStageUpdateObj = (obj = {}) => {
    this.bulkStageUpdateObj = obj;
  };

  @action
  resetBulkStageUpdateObj = () => {
    this.bulkStageUpdateObj = { ...defaultBulkStageUpdateObj };
  };

  @action
  bulkStageChange = async (
    stageId = "",
    accountStageId = "",
    accountIds = [],
    totalCount = 0
  ) => {
    const config = {
      url: URLS.performBulkAccountOperation,
      method: "POST",
      data: {
        type: "ACCOUNT_STAGE_UPDATE",
        totalCount,
        filter: {
          accountPayload: {
            filterPayload: {
              ...(stageId ? { stageId } : {})
            }
          },
          ...(accountIds?.length > 0 ? { accountIds } : {})
        },
        data: {
          accountStageId
        }
      }
    };
    return await makeApi(config);
  };
}

const accountBulkActionsState = new AccountBulkActionsState();
export { accountBulkActionsState };
