/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useRef,
  createContext,
  useContext
} from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { tagsStoreData } from "Stores/TagsData";
import Utils from "Utils/utils";
import { FilterValueSection, DoneButton } from "./FilterSection";
import SearchInputBox from "../SearchInputBox";
import CheckBoxComponent from "../CheckBox";
import SelectedTagList from "./SelectedTagList";
import ContactFilteredFacets from "./ContactFilteredFacets";

class FilterByTagsState {
  @observable filteredData = [];
  @observable filteredId = [];

  @action
  setFilteredData(val) {
    this.filteredData = val;
  }
  @action
  setFilteredId(val) {
    this.filteredId = val;
  }
}
const filterByTagsState = new FilterByTagsState();
const FilterContext = createContext({});

const BodySection = () => {
  const {
    showTagList,
    setShowTagList,
    renderArray,
    searchBoxRef,
    clearSearchInput,
    contactTableLoading,
    onChangeSearchBox,
    updateRemovedTag,
    getFullName,
    updateTagCheckbox
  } = useContext(FilterContext);

  return (
    <div className="bodyContainer">
      <div className="inputContainer">
        <SelectedTagList
          selectedList={filterByTagsState.filteredData}
          store={filterByTagsState}
          searchBoxRef={searchBoxRef}
          updateRemovedTag={updateRemovedTag}
        />
        <div className="searchFilterWrapper">
          <SearchInputBox
            valueCbk={onChangeSearchBox}
            type="text"
            placeholder={""}
            hasSearchIcon={false}
            hasCloseIcon={false}
            className="inputFilterSearch"
            clearSearchInput={clearSearchInput}
            searchBoxRef={searchBoxRef}
            setShowTagList={setShowTagList}
          />
          {showTagList && renderArray?.length > 0 && (
            <div className={`tagList ${contactTableLoading ? "fade" : ""}`}>
              {renderArray.map((item, index) => {
                const { id, value, checked } = item || {};
                return (
                  <div className="tagItem" key={id + index}>
                    <CheckBoxComponent
                      key={id}
                      name={value ? value : getFullName(item)}
                      id={id}
                      checked={checked}
                      cbk={updateTagCheckbox}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FilterByContactTags = observer(props => {
  const {
    filterContactsByTags = () => {},
    filterAnalyticsByTags = () => {},
    openTagFilter = false,
    setOpenTagFilter = () => {}
  } = props;

  const searchBoxRef = useRef(null);
  const [tagList, setTagList] = useState([]);
  const [showTagList, setShowTagList] = useState(false);
  const [renderArray, setRenderArray] = useState([]);
  const [clearSearchInput, setClearSearchInput] = useState(false);
  const [clearAllFilter, setClearAllFilter] = useState(false);

  const updateData = list => {
    setRenderArray(list);
    setTagList(list);
    setShowTagList(false);
    searchBoxRef && searchBoxRef.current && searchBoxRef.current.focus();
  };

  const updateRemovedTag = removedItem => {
    setClearSearchInput(true);
    const updatedTagList = Utils.createObjWithoutRef(tagList);
    updatedTagList?.length > 0 &&
      updatedTagList.map(
        item => item.value === removedItem && (item.checked = false)
      );
    const filteredItem = updatedTagList.filter(item => item.checked === true);
    filteredItem?.length === 0 && setClearAllFilter(true);
    updatedTagList && updateData(updatedTagList);
  };

  const updateTagCheckbox = (value, id, name) => {
    const newArr = Utils.createObjWithoutRef(tagList);
    if (newArr?.length > 0) {
      newArr.map(item => item.id === id && (item.checked = value));
    }
    newArr && updateData(newArr);
    if (value === true) {
      filterByTagsState.setFilteredData([
        ...filterByTagsState.filteredData,
        name
      ]);
    } else {
      updateRemovedTag(name);
      const updatedFilteredData = filterByTagsState.filteredData.filter(
        filterData => filterData !== name
      );
      filterByTagsState.setFilteredData(updatedFilteredData);
    }
    setClearSearchInput(true);
  };

  const getFullName = (user = {}) => {
    const { firstName = null, lastName = null, email = null } = user || {};
    return (
      (firstName && lastName && `${firstName} ${lastName}`) ||
      firstName ||
      lastName ||
      email
    );
  };

  const onChangeSearchBox = inputValue => {
    setShowTagList(true);
    const lowercasedValue = inputValue?.toLowerCase();
    setClearSearchInput(false);
    if (lowercasedValue && tagList?.length) {
      const filteredData = tagList.filter(item => {
        return item["value"]
          ?.toString()
          ?.toLowerCase()
          ?.includes(lowercasedValue);
      });
      setRenderArray(filteredData);
    }
  };

  const clearSelectedValue = () => {
    filterByTagsState.setFilteredData([]);
    renderArray?.length && renderArray.map(item => (item.checked = false));
    renderArray && updateData(renderArray);
    setClearAllFilter(true);
  };

  const submitAction = () => {
    const checkedValue = tagList
      .map(item => item.checked && item.value)
      .filter(Boolean);
    const checkedTagId = tagList
      .map(item => item.checked && item.id)
      .filter(Boolean);
    filterByTagsState.setFilteredData(checkedValue);
    filterByTagsState.setFilteredId(checkedTagId);
    filterContactsByTags(checkedTagId);
    filterAnalyticsByTags(checkedTagId);
    setOpenTagFilter(false);
    setClearAllFilter(false);
  };

  const escapeButtonClick = event => {
    event.keyCode === 27 && setOpenTagFilter(false);
  };

  const getFilteredData = resData => {
    if (filterByTagsState?.filteredData?.length > 0) {
      resData
        .map(item => {
          item.checked = filterByTagsState?.filteredData?.includes(item.value);
          return item;
        })
        .filter(Boolean);
    }
    return resData;
  };

  useEffect(() => {
    const data = Utils.createObjWithoutRef(tagsStoreData?.tagsData);
    const response = getFilteredData(data);
    response && updateData(response);
  }, [tagsStoreData.tagsData, filterByTagsState.filteredData]);

  useEffect(() => {
    window && window.addEventListener("keydown", escapeButtonClick);
    return () =>
      window && window.removeEventListener("keydown", escapeButtonClick);
  }, []);

  return filterByTagsState?.filteredData?.length > 0 &&
    openTagFilter === false ? (
    <ContactFilteredFacets
      heading={"tags"}
      searchBoxRef={searchBoxRef}
      clearSelectedValue={clearSelectedValue}
      store={filterByTagsState}
      {...props}
    />
  ) : (
    openTagFilter && (
      <>
        <div className="filterWrapper">
          <FilterContext.Provider
            value={{
              renderArray,
              setTagList,
              clearSearchInput,
              showTagList,
              setShowTagList,
              searchBoxRef,
              onChangeSearchBox,
              updateRemovedTag,
              updateTagCheckbox,
              getFullName,
              ...props
            }}
          >
            <FilterValueSection
              filterSelectionValue={"Tags"}
              clearSelectedValue={clearSelectedValue}
              enableClearAll={filterByTagsState?.filteredData?.length > 0}
            />
            <BodySection />
            <DoneButton
              submitAction={submitAction}
              enableClearAll={
                clearAllFilter || filterByTagsState?.filteredData?.length > 0
              }
            />
          </FilterContext.Provider>
        </div>
        <div
          className="filterTagOverlay"
          onClick={() => setOpenTagFilter(false)}
        />
      </>
    )
  );
});

export default FilterByContactTags;
export { FilterByContactTags, filterByTagsState };
