import React from "react";
import { observer } from "mobx-react";

const TrackingDelayBanner = observer(props => {
  return (
    <div className="trackingDelayBanner" id="trackingDelayBanner">
      <i className="material-icons clock">access_time</i>
      <span className="text">
        Due to high usage, we are currently experiencing delay in tracking
        events within Salesgear. <br />
        We are working on upgrading our machines match up to the high usage.
        Thanks for your patience!
      </span>
    </div>
  );
});

export default TrackingDelayBanner;
export { TrackingDelayBanner };
