import { action, observable } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

let calendarNetworkRequest = {
  connectedAccount: null,
  editBaseUrl: null,
  updateBrandToggle: null,
  createEvent: null,
  eventsList: null
};

const setCalendarNetworkRequest = (id = "") => {
  if (id && calendarNetworkRequest?.[id] !== null) {
    calendarNetworkRequest[id]("cancel");
    calendarNetworkRequest[id] = null;
  }
};

const cancelReqCallback = (id = "", value = "") => {
  id && (calendarNetworkRequest[id] = value);
};

class CalendarState {
  @observable connectedAccount = null;
  @observable brandingToggle = false;
  @observable eventsList = null;
  @observable loadingType = "";
  @observable editEventInputValue = "";
  @observable currentPage = 1;
  @observable isCustomWeek = false;

  @action
  setConnectedAccount = (value = "") => {
    this.connectedAccount = value;
  };

  @action
  setBrandingToggle = (value = false) => {
    this.brandingToggle = value;
  };

  @action
  setEventsList = (obj = false) => {
    this.eventsList = obj;
  };

  @action
  setEditEventInputValue = (obj = false) => {
    this.editEventInputValue = obj;
  };

  @action
  setLoadingType = (value = false) => {
    this.loadingType = value;
  };

  @action
  setCurrentPage = (value = 1) => {
    this.currentPage = value;
  };

  @action
  setIsCustomWeek = value => {
    this.isCustomWeek = value;
  };

  @action
  resetState = () => {
    this.eventsList = null;
    this.loadingType = "";
    this.editEventInputValue = "";
    this.connectedAccount = null;
    this.brandingToggle = false;
    this.currentPage = 1;
  };

  @action
  updateEventsList = (data = {}, value = "", type = "") => {
    if (this.eventsList?.list?.length) {
      let list = [...this.eventsList?.list];
      list = list.map(item => {
        if (item?.id === data?.id) {
          item.loading = value;
          if (type === "TOGGLE" && data?.status) {
            item.status = data.status;
          }
        }
        return item;
      });
      this.setEventsList({ ...this.eventsList, list });
    }
  };

  @action
  getCalendarConnnectedAccount = async () => {
    const config = {
      url: URLS.getCalendarConnnectedAccount
    };
    setCalendarNetworkRequest("connectedAccount");
    const cancel = value => cancelReqCallback("connectedAccount", value);
    let res = await makeApi(config, cancel);
    if (res?.message !== "cancel") {
      this.setConnectedAccount(res?.data || "");
      return res?.data;
    }
  };

  @action
  editCalendarBaseUrl = async (data = {}, callback = () => {}) => {
    const config = {
      url: URLS.editCalendarBaseUrl,
      method: "POST",
      data
    };
    setCalendarNetworkRequest("updateBrandToggle");
    const cancel = value => cancelReqCallback("updateBrandToggle", value);
    let res = await makeApi(config, cancel);
    if (res?.message !== "cancel") {
      if (
        res?.data &&
        Object.keys(res?.data).length > 0 &&
        res?.data?.code === 200
      ) {
        this.setConnectedAccount(res?.data || "");
        this.getCalendarEventsList();
        toasterState.setToastMsg(
          "Calendar URL updated successfully",
          "success"
        );
        callback(true);
      } else {
        const errMsgText = {
          411: "Invalid url",
          410: "This calendar url already exists"
        };
        let msg =
          errMsgText?.[res?.data?.code] ||
          "Something went wrong. Please try again later";
        toasterState.setToastMsg(msg, "fail");
        callback(false);
      }
    }
  };

  @action
  updateBrandingToggle = async (data = {}) => {
    const config = {
      url: URLS.updateBrandToggle,
      method: "POST",
      data
    };
    setCalendarNetworkRequest("updateBrandToggle");
    const cancel = value => cancelReqCallback("updateBrandToggle", value);
    let res = await makeApi(config, cancel);
    if (res?.message !== "cancel") {
      if (res?.data && Object.keys(res?.data).length > 0) {
        this.setConnectedAccount(res?.data || "");
        let msg = data?.showBranding
          ? `Salesgear branding included in your calendar.`
          : `Salesgear branding removed from your calendar successfully.`;
        toasterState.setToastMsg(msg, "success");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  };

  @action
  getCalendarEventsList = async (data = { pageNo: 1, limit: 10 }) => {
    const config = {
      url: URLS.getCalendarEventsList,
      method: "POST",
      data
    };
    setCalendarNetworkRequest("eventsList");
    const cancel = value => cancelReqCallback("eventsList", value);
    let res = await makeApi(config, cancel);
    if (res?.message !== "cancel") {
      if (
        res?.data &&
        Object.keys(res?.data).length > 0 &&
        res?.data?.code === 200
      ) {
        this.setEventsList(res.data);
      } else {
        this.setEventsList({});
      }
    }
  };

  @action
  createCalendarEvent = async (data = {}, type = "", callback = () => {}) => {
    const { planName = "", inFreePlan = false } =
      userDetail?.userFeatureFlag || {};
    const config = {
      url: URLS[type === "edit" ? "editCalendarEvent" : "createCalendarEvent"],
      method: "POST",
      data
    };
    let res = await makeApi(config);
    if (
      res?.data &&
      Object.keys(res?.data).length > 0 &&
      res?.data?.code === 200
    ) {
      toasterState.setToastMsg(
        `Event ${type === "edit" ? "updated" : "created"} successfully`,
        "success"
      );
      callback(true);
    } else {
      const errMsgText = {
        426: "Upgrade required",
        410: "This event url already exists",
        411: "Invalid url",
        412: "Event name is mandatory",
        413: "Working hours is mandatory",
        414: "Timezone is mandatory",
        415: "Available days is mandatory",
        416: "Duration is mandatory",
        417: "Url suffix is mandatory",
        418: "Reminder is mandatory",
        422: "This URL suffix already exists. Please try something else.",
        417: "Please enter a URL suffix."
      };
      if (+res?.data?.code === 426) {
        PaywallUtils.upgradeUtilsFunction(
          "calendarUpgrade",
          false,
          planName,
          inFreePlan
        );
      } else {
        let msg =
          errMsgText?.[res?.data?.code] ||
          "Something went wrong. Please try again later";
        toasterState.setToastMsg(msg, "fail");
      }
      callback(false);
    }
  };

  @action
  cloneCalendarEvent = async (data = {}, callback = () => {}) => {
    const { planName = "", inFreePlan = false } =
      userDetail?.userFeatureFlag || {};
    const config = {
      url: URLS.cloneCalendarEvent,
      method: "POST",
      data
    };
    let res = await makeApi(config);
    if (
      res?.data &&
      Object.keys(res?.data).length > 0 &&
      res?.data?.code === 200
    ) {
      await this.getCalendarEventsList({
        pageNo: this.eventsList?.currentPage || 1,
        limit: 10
      });
      toasterState.setToastMsg(`Event cloned successfully`, "success");
      callback("success");
    } else {
      const errMsgText = {
        426: "Upgrade required",
        410: "This event url already exists",
        411: "Invalid url",
        412: "Event name is mandatory",
        413: "Working hours is mandatory",
        414: "Timezone is mandatory",
        415: "Available days is mandatory",
        416: "Duration is mandatory",
        417: "Url suffix is mandatory",
        418: "Reminder is mandatory"
      };
      if (+res?.data?.code === 426) {
        PaywallUtils.upgradeUtilsFunction(
          "calendarUpgrade",
          false,
          planName,
          inFreePlan
        );
      } else {
        let msg =
          errMsgText?.[res?.data?.code] ||
          "Something went wrong. Please try again later";
        toasterState.setToastMsg(msg, "fail");
      }
      callback("fail");
    }
  };

  @action
  toggleCalendarEvent = async (data = {}) => {
    this.updateEventsList(data, "toggle");
    const config = {
      url: URLS.toggleCalendarEvent,
      method: "POST",
      data
    };
    let res = await makeApi(config);
    if (
      res?.data &&
      Object.keys(res?.data).length > 0 &&
      res?.data?.code === 200
    ) {
      this.updateEventsList(data, false, "TOGGLE");
      let msg =
        data?.status?.toLowerCase() === "active"
          ? "Event now available for booking on your calendar."
          : "Event made unavailable for booking on your calendar.";
      toasterState.setToastMsg(msg, "success");
    } else {
      let msg =
        res?.data?.code === 420
          ? "Oops! There should be at least one event available to book a meeting."
          : "Something went wrong. Please try again later";
      toasterState.setToastMsg(msg, "fail");
      this.updateEventsList(data, false);
    }
  };

  @action
  deleteCalendarEvent = async (data = {}, callback = () => {}) => {
    this.updateEventsList(data, "delete");
    const config = {
      url: URLS.deleteCalendarEvent,
      method: "DELETE",
      data
    };
    let res = await makeApi(config);
    if (
      res?.data &&
      Object.keys(res?.data).length > 0 &&
      res?.data?.code === 200
    ) {
      await this.getCalendarEventsList({
        pageNo: this.eventsList.currentPage || "",
        limit: 10
      });
      callback();
      toasterState.setToastMsg("Event deleted successfully", "success");
    } else {
      this.updateEventsList(data, false);
      callback();
      let msg =
        res?.data?.code === 420
          ? "Oops! There should be at least one event available to book a meeting."
          : "Something went wrong. Please try again later";
      toasterState.setToastMsg(msg, "fail");
    }
  };
}

const calendarState = new CalendarState();
export default calendarState;
export { calendarState };
