import React from "react";

const EventsListLoader = (props = {}) => {
  const { count = 10 } = props || {};
  return Array.from({ length: count }, (value, idx) => (
    <div className="eventCardLoader" key={`eventCard${idx}`}>
      <div className="eventToggleBtn">
        <span className="toggleBox linear-background" />
        <span className="toggleText linear-background" />
      </div>
      <div className="durationIntervalPeriod linear-background" />
      <div className="eventActionIcon">
        {Array.from({ length: 4 }, (value, index) => (
          <span
            className="actionIconTooltip"
            key={`eventActionIconLoader${index}`}
          >
            <span className="actionIcon linear-background" />
          </span>
        ))}
      </div>
    </div>
  ));
};

export default EventsListLoader;
