import { writingAssistantStoreData } from "Stores/WritingAssistantData";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import PaywallUtils from "./PaywallUtils";

const WritingAssistantUtils = {};

WritingAssistantUtils.getContent = async (
  content = "",
  symbol = "\n",
  mixpanelProperty = {}
) => {
  if (content?.length) {
    let newLineContent = WritingAssistantUtils.replaceDomWithLineSeperator(
      content,
      symbol
    );
    let plainText = WritingAssistantUtils.convertToPlainText(newLineContent);
    let newContent = WritingAssistantUtils.addDotToString(plainText);
    let contentLength = WritingAssistantUtils.getContentWordLength(newContent);
    if (contentLength > 0) {
      await writingAssistantStoreData.generateSubject(
        newContent,
        mixpanelProperty
      );
    } else {
      WritingAssistantUtils.showEmptyContentConfirmPrompt();
    }
  }
};

WritingAssistantUtils.replaceDomWithLineSeperator = (
  content = "",
  symbol = ""
) => {
  return (
    (content?.length && content.replaceAll(/\s*<div><br><\/div>/g, symbol)) ||
    ""
  );
};

WritingAssistantUtils.addDotToString = (text = "") => {
  if (text?.length) {
    let lastChar = text.charAt(text.length - 1);
    return lastChar === "." ? text : `${text}.`;
  }
  return text;
};

WritingAssistantUtils.convertToPlainText = (content = "", symbol = "\n") => {
  if (content?.length) {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = content;
    let tempDomList = tempDivElement.querySelectorAll(".ql-editor > *");
    let tempDomString = "";
    if (tempDomList?.length > 0) {
      for (let i in tempDomList) {
        if (tempDomList[i]?.innerText) {
          if (tempDomList[i].tagName?.toLowerCase() === "ul") {
            let tempNestedDom = tempDomList[i].querySelectorAll("ul > *");
            let tempNestedDomString = "";
            if (tempNestedDom?.length) {
              for (let j in tempNestedDom) {
                if (tempNestedDom[j]?.innerText) {
                  tempNestedDom[j].innerHTML = `${
                    tempNestedDom[j]?.innerText || ""
                  }${symbol}`;
                }
                tempNestedDomString += tempNestedDom?.[j]?.outerHTML || "";
              }
            }
            tempDomList[i].innerHTML = tempNestedDomString;
          } else {
            tempDomList[i].innerHTML = `${
              tempDomList[i]?.innerText || ""
            }${symbol}`;
          }
        }
        tempDomString += tempDomList?.[i]?.outerHTML || "";
      }
      tempDivElement.innerHTML = tempDomString;
    }
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }
  return "";
};

WritingAssistantUtils.getContentWordLength = (content = "") => {
  if (content?.length) {
    return content
      ?.replaceAll("\n", " ")
      ?.replaceAll(",", " ")
      ?.replaceAll("  ", " ")
      ?.split(" ")
      ?.filter(item => item?.length)?.length;
  }
  return "";
};

WritingAssistantUtils.showEmptyContentConfirmPrompt = (
  content = "Please include a body in your email to generate recommended subject lines."
) => {
  confirmationPopupState.setPopupValues({
    content,
    actionBtnText: "Ok",
    needCancelBtn: false,
    callback: () => {}
  });
  confirmationPopupState.setShowPopup(true);
};

WritingAssistantUtils.showReplyThreadConfirmPrompt = () => {
  confirmationPopupState.setPopupValues({
    content:
      "Please change your email type to new thread to generate recommended subject lines.",
    actionBtnText: "Ok",
    needCancelBtn: false,
    callback: () => {}
  });
  confirmationPopupState.setShowPopup(true);
};

WritingAssistantUtils.constructSubjectLines = (list = []) => {
  const newList = [];
  if (list?.length) {
    list.map(item => {
      let sentence = item?.trim()?.replace(/^"|"$/g, "")?.trim()?.toLowerCase();
      if (!~sentence.indexOf("subject lines") && item?.length > 2) {
        let value = item
          ?.replace(/^"|"$/g, "")
          ?.trim()
          ?.replace(/^(?=[^\s>])([0-9]+)[\.\s\)\s]/g, "")
          ?.trim()
          ?.replace(/^[\)]/, "")
          ?.trim()
          ?.replace(/^-/, "")
          ?.trim()
          ?.replace(/^"|"$/g, "")
          ?.trim();
        value?.length && newList.push(value);
      }
    });
    return newList;
  }
  return list;
};

WritingAssistantUtils.optionsHasFirstContentBody = (
  options = [],
  content = ""
) => {
  if (options?.length && content?.length) {
    let contentArray = content.split("\n");
    return (
      contentArray?.length > 0 &&
      options?.[0]?.trim() === contentArray?.[0]?.trim()
    );
  }
  return false;
};

WritingAssistantUtils.showSupportMessagePopup = () => {
  confirmationPopupState.setPopupValues({
    content:
      "Oops, something went wrong. We're working to fix it as soon as possible. For more information, please contact our support team.",
    actionBtnText: "Ok",
    needCancelBtn: false,
    callback: () => {}
  });
  confirmationPopupState.setShowPopup(true);
};

WritingAssistantUtils.triggerMpEvent = (property = {}) => {
  Utils.mixpanelTrack(MP_EVENT.WA_SLG_API_CALLED, property);
};

WritingAssistantUtils.upgradePopup = (inFreePlan = false, planName = "") => {
  PaywallUtils.upgradeUtilsFunction(
    "subjectLineGenerator",
    false,
    planName,
    inFreePlan
  );
};

WritingAssistantUtils.calculateColor = (avg = 0, reverse = false) => {
  let tempAvg = parseFloat(avg);
  if (reverse) {
    if (tempAvg <= 25) {
      return "#28AD56";
    } else if (tempAvg > 25 && tempAvg <= 50) {
      return "#3953FB";
    } else if (tempAvg > 50 && tempAvg <= 75) {
      return "#F59064";
    } else {
      return "FF0000";
    }
  } else {
    if (tempAvg <= 25) {
      return "#FF0000";
    } else if (tempAvg > 25 && tempAvg <= 50) {
      return "#F59064";
    } else if (tempAvg > 50 && tempAvg <= 75) {
      return "#3953FB";
    } else {
      return "#28AD56";
    }
  }
};

WritingAssistantUtils.getUpdatedObj = (obj = {}) => {
  let tempObj = {};
  if (obj && Object.keys(obj)?.length) {
    for (let key in obj) {
      let newKey = key?.toLowerCase()?.replace(/-/g, " ")?.replace(/ /g, "_");
      tempObj[newKey] = obj[key];
    }
  }
  return tempObj || {};
};

WritingAssistantUtils.getScoreSumValue = (value = 0) => {
  return value > 10 ? value : value * 10;
};

WritingAssistantUtils.calculateScoreAverage = (
  element = {},
  score = {},
  isPercentage = true
) => {
  let scoreLength = 0;
  let avg = 0;
  if (score && Object.keys(score)?.length) {
    for (let key in score) {
      if (element?.nameList.includes(key)) {
        if (key && Object.keys(score?.[key])?.length) {
          let iteratedObj = WritingAssistantUtils.getUpdatedObj(score?.[key]);
          if (iteratedObj && Object.keys(iteratedObj)?.length > 0) {
            for (let ele in element?.keysObj) {
              scoreLength += 1;
              avg += WritingAssistantUtils.getScoreSumValue(iteratedObj?.[ele]);
            }
          }
        } else {
          avg = WritingAssistantUtils.getScoreSumValue(score?.[key]);
        }
      }
    }
  }
  return `${
    scoreLength > 0
      ? `${(avg / scoreLength)?.toFixed(1)}`
      : `${avg?.toFixed(1) || 0}`
  }${isPercentage ? "%" : ""}`;
};

export default WritingAssistantUtils;
export { WritingAssistantUtils };
