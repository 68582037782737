/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
import React from "react";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";

const RatingSmiley = props => {
  const {
    setThankyouMessage = "",
    showThankyouMessage = () => {},
    closePopup = () => {}
  } = props;

  const smileyAction = e => {
    if (e) {
      const { currentTarget } = e;
      const id = currentTarget.id;
      const ratingData = currentTarget.getAttribute("data-value");
      if (ratingData) {
        Utils.mixpanelTrack(MP_EVENT.ON_FEEDBACK, {
          Feedback: ratingData
        });
      }
      currentTarget.classList.add("active");
      addFadeOutClass(e);
      transformElement(id, currentTarget);
    }
  };

  const addFadeOutClass = e => {
    const elements = document.getElementsByClassName("smileyIcon");
    if (elements && elements.length) {
      Array.from(elements).forEach(function (element, index) {
        if (element && !element.classList.contains("active")) {
          element.classList.add("fadeOut");
          hideElement(element, index);
        }
      });
      setThankyouMessage(true);
      setTimeout(() => {
        closePopup();
      }, 2500);
    }
  };

  const transformElement = (id, e) => {
    const parentEle = e && e.parentElement;
    if (id === "happy") {
      parentEle.style.transform = "translateX(calc(50% - 40px))";
    } else if (id === "angry") {
      parentEle.style.transform = "translateX(calc(-50% + 40px))";
    }
  };

  const hideElement = (element, index) => {
    element.addEventListener(
      "animationend",
      ev => {
        if (ev.type === "animationend") {
          element.style.visibility = "hidden";
        }
      },
      false
    );
  };

  const Smiley = () => {
    return (
      <div className="smiley">
        <div className="eyes">
          <div className="eye"></div>
          <div className="eye"></div>
        </div>
        <div className="mouth"></div>
      </div>
    );
  };

  return (
    <>
      <div className="ratingSmileyCont">
        <span
          className="smileyIcon"
          htmlFor="happy"
          data-value="Great"
          id="happy"
          onClick={e => smileyAction(e)}
        >
          <Smiley />
          {!showThankyouMessage && <span className="ratingType">Great</span>}
        </span>
        <span
          className="smileyIcon"
          htmlFor="normal"
          id="normal"
          data-value="Ok"
          onClick={e => smileyAction(e)}
        >
          <Smiley />
          {!showThankyouMessage && <span className="ratingType">Ok</span>}
        </span>
        <span
          className="smileyIcon"
          htmlFor="angry"
          id="angry"
          data-value="Bad"
          onClick={e => smileyAction(e)}
        >
          <Smiley />
          {!showThankyouMessage && <span className="ratingType">Bad</span>}
        </span>
      </div>
      {showThankyouMessage && (
        <span className="thankyouFeedbackCont">
          <span className="thumbsUpIcon">
            <span className="material-icons">thumb_up</span>
          </span>
          <span className="thankyouText">Thank you for the feedback</span>
        </span>
      )}
    </>
  );
};

export { RatingSmiley };
export default RatingSmiley;
