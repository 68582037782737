import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import TabNavigation from "Components/common/TabNavigation";
import ConnectedAccountsRouting from "../Crm/ConnectedAccountsRouting";
import ConnectEmailButton from "./ConnectEmailButton";
import { linkedInReplyState } from "./LinkedInAccounts/state";
import ConnectedLinkedInButton from "./LinkedInAccounts/ConnectedLinkedInButton";

export const CONNECTED_ACCOUNTS_LIST = [
  {
    name: "Email",
    id: "email",
    path: "email"
  },
  {
    name: "LinkedIn",
    id: "linkedin",
    path: "linkedin"
  }
];

const ConnectedAccounts = observer((props = {}) => {
  const [showEmailConfigure, setShowEmailConfigure] = useState(false);
  const [showSmtpImapConnect, setSmtpImapConnect] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();

  const emailProps = {
    showEmailConfigure,
    showSmtpImapConnect,
    setSmtpImapConnect,
    setShowEmailConfigure
  };

  useEffect(() => {
    const tempActiveTab = location?.pathname?.split("/").pop();
    setActiveTab(tempActiveTab);
  }, [location]);

  return (
    <div className="connectedAccountsView">
      <div className="connectedAccountsHeader">
        {!showEmailConfigure &&
          !showSmtpImapConnect &&
          !linkedInReplyState?.showConfigure && (
            <TabNavigation tabList={CONNECTED_ACCOUNTS_LIST} />
          )}
        {activeTab === "email" && <ConnectEmailButton {...emailProps} />}
        {activeTab === "linkedin" && !linkedInReplyState?.showConfigure && (
          <ConnectedLinkedInButton />
        )}
      </div>
      <ConnectedAccountsRouting {...props} {...emailProps} />
    </div>
  );
});

export default ConnectedAccounts;
export { ConnectedAccounts };
