import React from "react";
import GmailImg from "Assets/Pricing/crm/gmail.png";
import HsImg from "Assets/Pricing/crm/hs.jpg";
import PdImg from "Assets/Pricing/crm/pd.png";

const CustomTooltip = props => {
  const {
    text = "",
    crmIcon = false,
    dialer = false,
    className = "",
    redirectText = "",
    redirectLink = "",
    hasRedirectLink = false,
    isNewTabUrl = false,
    dynamicDom = false,
    children = <></>
  } = props;
  const imgArr = [GmailImg, HsImg, PdImg];

  const redirectTopage = () => {
    if (isNewTabUrl) {
      window.open(redirectLink, "_blank", "noopener");
    } else {
      props && props.history && props.history.push(redirectLink);
    }
  };
  return (
    <div className="customTooltip">
      <div
        className={`sharpPointer ${className} ${dialer ? "alignRight" : ""}`}
      />
      <div
        className={`tooltipBody ${className} ${dialer ? "alignRight" : ""} ${
          crmIcon ? "crmToolTipBody" : ""
        }`}
      >
        {!crmIcon && hasRedirectLink && text ? (
          <div className="tooltipText">
            {text}
            <button className="redirectLink" onClick={redirectTopage}>
              {redirectText}
            </button>
          </div>
        ) : (
          text &&
          !dynamicDom && (
            <div
              className="tooltipText"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )
        )}
        {dynamicDom && <div className="tooltipText">{children}</div>}
        {crmIcon && (
          <div className="crmIconList">
            {imgArr.map((item, index) => (
              <img src={item} alt="" key={`crmInt${index + 1}`} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTooltip;
export { CustomTooltip };
