import React, { useEffect, useState } from "react";
import Utils from "Utils/utils";
import { makeApi, URLS } from "Utils/apiURL";

let contactsListNetworkRequest = {
  fetchContacts: null
};

const setContactsListNetworkRequest = (id = "") => {
  if (id && contactsListNetworkRequest?.[id] !== null) {
    contactsListNetworkRequest[id]("cancel");
    contactsListNetworkRequest[id] = null;
  }
};

const cancelReqCallback = (id = "", value = "") => {
  id && (contactsListNetworkRequest[id] = value);
};

const ContactAutoCompleteDropdown = ({
  inputText = "",
  selectedCallbk = () => {}
}) => {
  const [contactList, setContactList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isInit, setIsInit] = useState(true);

  const changeAction = (obj = "") => {
    setShowDropdown(false);
    setKeyword(obj?.email);
    selectedCallbk(obj);
  };

  const renderItem = (obj = {}, isAddOption = false) => {
    const { id = "", email = "", firstName = "", LastName = "" } = obj || {};
    return (
      <li
        key={`${email || id}${new Date()?.getTime()}`}
        id={id}
        className="option"
        onClick={() => changeAction(obj)}
      >
        <div className="listItem">
          <div className="listItemWrap">
            <span className="itemName">
              {Utils.getFullNameFromUserInfo({ firstName, LastName })}
            </span>
            {isAddOption && <span className="addTxt">Add </span>}
            <span className="itemEmail">{isAddOption ? keyword : email}</span>
          </div>
        </div>
      </li>
    );
  };

  const getContactsByKeywordSearch = async (value = "") => {
    if (value && keyword?.toLowerCase() !== value?.toLowerCase()) {
      const config = {
        url: `${URLS.getContactsBySearchKeywords}${value}`,
        method: "GET"
      };
      setContactsListNetworkRequest("fetchContacts");
      const cancel = value => cancelReqCallback("fetchContacts", value);
      let res = await makeApi(config, cancel);
      if (
        res?.message?.message?.toLowerCase() === "cancel" ||
        res?.message?.toLowerCase() === "cancel"
      ) {
        console.error("Request cancelled");
      } else {
        if (+res?.status === 200) {
          setContactList(res?.data || []);
          setKeyword(value);
          setShowDropdown(true);
        }
      }
    }
  };

  const closeAction = () => {
    setShowDropdown(false);
    setKeyword("");
    setContactList([]);
  };

  useEffect(() => {
    if (inputText && !isInit) {
      getContactsByKeywordSearch(inputText);
    } else {
      setContactList([]);
    }
    !inputText && showDropdown && setShowDropdown(false);
    setIsInit(false);
    setKeyword(inputText);
  }, [inputText]);

  useEffect(() => {
    return () => {
      setIsInit(true);
    };
  }, []);

  return (
    showDropdown &&
    keyword?.trim() && (
      <div className="emailAutoCompleteDropdown">
        <div className="drop-down-menu animatedFast animatedDropDown">
          <ul className="dropdownList">
            {contactList?.length === 0 &&
              keyword &&
              renderItem({ email: keyword }, true)}
            {contactList?.length > 0 &&
              contactList.map(item => renderItem(item))}
          </ul>
        </div>
        <div className="dropdownMask" onClick={closeAction} />
      </div>
    )
  );
};

export default ContactAutoCompleteDropdown;
export { ContactAutoCompleteDropdown };
