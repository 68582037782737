/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Utils from "Utils/utils";
import TemplateComponennt from "Pages/Dashboard/templates/TemplateModule";
import ContactsComponent from "Pages/Dashboard/contacts/main";
import TaskRouting from "Routes/TaskRouting";
import SettingsComponent from "Pages/Dashboard/settings/Settings";
import SequencesRouting from "Routes/SequencesRouting";
import { userDetail } from "Stores/userDetail";
import PricingModule from "Pages/Dashboard/pricing/PricingModule";
import ReportsModule from "Pages/Dashboard/reports/ReportsModule";
import ReportsModulev2 from "Pages/Dashboard/reports_v2/main";
import PluginSuccess from "Pages/Dashboard/Plugin/PluginSuccess";
import OutboxComponent from "Pages/Dashboard/outbox/main";
import CallsModule from "Pages/Dashboard/Calls/CallsModule";
import DashboardModule from "Pages/Dashboard/dashboard/main";
import DataSearchModule from "Pages/Dashboard/dataSearch/index";
import WebsiteTrackingModule from "Pages/Tracking/main";
import Accounts from "Pages/Dashboard/Accounts/Main";

export const DashboardRouting = observer(() => {
  const { subscriptionPlanId, newReportsEnabled = false } =
    userDetail?.userFeatureFlag || {};
  const { team } = userDetail?.userInfo || {};
  const [adminTeamUser, setAdminTeamUser] = useState(team);

  let location = useLocation();
  let queryParams = Utils.getQueryParams(location.search);
  let pluginUser = queryParams?.get("source")?.includes("plugin");
  let redirectUrl =
    (location.search &&
      Utils.getQueryParams(location.search).get("redirect")) ||
    "dashboard";

  useEffect(() => {
    setAdminTeamUser(userDetail.userInfo.team);
  }, [userDetail.userInfo.team]);

  useEffect(() => {
    if (userDetail.showUpgradeSettingsPopup) {
      userDetail.showUpgradeSettingsPopup = false;
      setTimeout(() => {
        if (location.pathname === "/settings/autoBcc") {
          Utils.showUpgradeSettingsPopup("autoBcc", true, false);
        } else if (location.pathname === "/settings/customUrl") {
          Utils.showUpgradeSettingsPopup(
            Utils.updateCustomUrlType(),
            true,
            false
          );
        } else if (location.pathname === "/settings/contacts") {
          Utils.showUpgradeSettingsPopup("customFields", true, false);
        }
      }, 50);
    } else if (userDetail.showUpgradePopup) {
      userDetail.showUpgradePopup = false;
      setTimeout(() => {
        location.pathname === "/tasks" &&
          Utils.showUpgradePopup("task", true, false);
      }, 50);
    }
  }, [location.pathname]);

  return (
    <Switch>
      <Route path="/templates" component={TemplateComponennt} />
      <Route path="/pluginSuccess" component={PluginSuccess} />
      {!adminTeamUser && subscriptionPlanId ? (
        ""
      ) : (
        <Route path="/pricing" component={PricingModule} />
      )}
      <Route path="/reports" component={ReportsModule} />
      <Route path="/reports-v2" component={ReportsModulev2} />
      <Route path="/calls" component={CallsModule} />
      <Route path="/contacts" component={ContactsComponent} />
      <Route path="/tasks" component={TaskRouting} />
      <Route path="/settings" component={SettingsComponent} />
      <Route path="/inbox" component={OutboxComponent} />
      <Route path="/sequences" component={SequencesRouting} />
      <Route path="/dashboard" component={DashboardModule} />
      <Route path="/discover" component={DataSearchModule} />
      <Route path="/website-tracking" component={WebsiteTrackingModule} />
      <Route path="/accounts" component={Accounts} />
      <Redirect
        from="/*"
        to={`${pluginUser ? "pluginSuccess" : `/${redirectUrl}`}`}
      />
    </Switch>
  );
});

export default DashboardRouting;
