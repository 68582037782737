/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import SubjectLineGenerator from "Components/WritingAssistant/SubjectLineGenerator";
import { writingAssistantStoreData } from "Stores/WritingAssistantData";

const SubjectInput = props => {
  const {
    setSubjectCursorPosition = () => {},
    setSubject = () => {},
    handleFocus = () => {},
    subject = "Registering Value",
    replyAsThread = false,
    errorMsg = "",
    setInputValue = () => {},
    inputValue = "",
    content = "",
    id = "subject"
  } = props;

  const subjectText = !replyAsThread ? subject : "";

  const handleInputFocus = () => {
    writingAssistantStoreData.setShowSubjectLineGenerator(true);
    handleFocus(true);
  };

  const handleChange = value => {
    setSubject(value?.replace(/(?:\r\n|\r|\n)/g, ""), "subject");
  };

  useEffect(() => {
    setInputValue({ ...inputValue, subject: subjectText });
  }, [replyAsThread]);

  return (
    <div className={`expandLabelInput ${subject?.length ? "filled" : ""}`}>
      <input
        type="text"
        id={id}
        className={`inputBox ${
          replyAsThread ? "disabledInput" : "subjectEnabled"
        }`}
        name="subject"
        value={
          replyAsThread ? "Re: [previous email subject line]" : subjectText
        }
        onSelectCapture={e => setSubjectCursorPosition(e.target.selectionStart)}
        onChange={e => handleChange(e.target.value)}
        autoComplete="off"
        required
        readOnly={replyAsThread}
        onFocus={handleInputFocus}
      />
      {!replyAsThread && <span className="focus-border"></span>}
      <label
        htmlFor="subject"
        className={`inputLabel ${replyAsThread ? "disabledInputLabel" : ""}`}
      >
        Subject<sup>*</sup>
      </label>
      {errorMsg && <span className="errMsgText">{errorMsg}</span>}
      {!replyAsThread && (
        <SubjectLineGenerator
          type="sequences"
          content={content}
          replaceSubjectCbk={text => setSubject(text, "subject")}
        />
      )}
    </div>
  );
};

export { SubjectInput };
export default SubjectInput;
