import React from "react";
import { observer } from "mobx-react";
import { calendarState } from "Pages/Dashboard/settings/Calendar/State";
import EventCard from "./Components/EventCard";
import PaginationComponent from "Components/common/PaginationComponent";
import EventsListLoader from "../EventsList/Components/EventsListLoader";

const EventsTableList = observer((props = {}) => {
  const { loader = false, eventsList = {}, paginate = () => {} } = props || {};

  const { list = [], totalPages = "", currentPage = "" } = eventsList || {};

  return (
    (list?.length > 0 && (
      <>
        <div className="eventsListTable">
          <div
            className={`eventsCardList ${
              totalPages > 1 ? "paginationEnabled" : ""
            }`}
          >
            {calendarState?.loadingType === "clone" && ( // using state for row loader
              <EventsListLoader count={1} key="cloneLoader" />
            )}
            {list.map(item => {
              const { loading = false, id = "" } = item || {};
              return loading === "delete" ? (
                <EventsListLoader count={1} key={id} />
              ) : (
                <EventCard {...item} key={id} />
              );
            })}
          </div>
          <PaginationComponent
            callback={paginate}
            loading={loader}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </>
    )) ||
    ""
  );
});

export default EventsTableList;
