import { observer } from "mobx-react";
import React, { useState, useEffect, useContext } from "react";
import {
  sequenceContactStoreData,
  defaultFilterPayload
} from "./sequenceContactStore";
import userDetail from "Stores/userDetail";
import { sequenceContactData } from "Stores/sequence/Contact";
import { settingsStoreData } from "Stores/settingsData";
import { tagsStoreData } from "Stores/TagsData";
import { sequenceRenderState } from "Pages/Dashboard/sequences/SequencePage/sequenceProperties";
import { hideIntercomChat } from "Utils/Scripts/intercom";
import SequenceUtils from "Utils/SequenceUtils";
import ContactFieldFilter from "Pages/Dashboard/contacts/ContactFilter/ContactFieldFilter";
import ContactMetricsFilter from "Pages/Dashboard/contacts/ContactFilter/ContactMetricsFilter";
import ContactDateFilter from "Pages/Dashboard/contacts/ContactFilter/ContactDateFilter";
import ContactTeamFilter from "Pages/Dashboard/contacts/ContactFilter/ContactTeamFilter";
import MultiSelectDropdown from "Pages/Dashboard/contacts/ContactFilter/MultiSelectDropdown";
import { SeqContactTeamDataFilterContext } from "../sequenceContacts";
import SequenceContactFilterFooter from "./Footer";

const SequenceContactFilter = observer((props = {}) => {
  const { hasTeamMember = false } = userDetail?.userInfo || {};
  const {
    filterArr = [],
    limit = 10,
    filterLoadingFn = () => {},
    tableLoadingFn = () => {},
    pageNo = 1,
    seqId = "",
    data = {},
    setData = () => {},
    resetAll = () => {},
    activeFilter = false,
    setActiveFilter = () => {},
    isSharedSequenceWithUser = false,
    showContactOwner = false
  } = props || {};

  const {
    teamFilterDropDownData = [],
    setTeamCount = () => {},
    teamCount = 0
  } = useContext(SeqContactTeamDataFilterContext);

  const [teamData, setTeamData] = useState("");
  const [disableContactFieldAddBtn, setDisableContactFieldAddBtn] =
    useState(true);
  const [errMsg, setErrMsg] = useState("");

  const handleActiveFilter = (value = "") => {
    sequenceContactStoreData.setResetTeam(false);
    setActiveFilter(value === activeFilter ? "" : value);
  };

  const selectedFilterAction = (type = "", value = "") => {
    sequenceContactStoreData.setResetTeam(false);
    let tempArr = [...filterArr];
    if (type?.toLowerCase() === "remove" && tempArr?.length) {
      tempArr = tempArr.filter(item => item !== value);
    } else if (type?.toLowerCase() === "add") {
      !tempArr?.includes(value) && tempArr.push(value);
    }
    sequenceContactStoreData.setFilteredList(tempArr);
  };

  const validateData = (data = []) => {
    let validData = false;
    const dataLength = data?.length;
    if (dataLength) {
      const { fieldName = "", value = "" } = data?.[dataLength - 1];
      validData = fieldName && value;
    }
    return validData;
  };

  const mergeTwoArrWithoutDuplicate = (firstArr = [], secondArr = []) => {
    if (firstArr?.length > 0) {
      firstArr.map(item => {
        if (secondArr?.includes(item?.id)) {
          item.checked = true;
        }
        return item;
      });
    }
    return firstArr;
  };

  const handleFilterObject = (key = "", value = "", firstTime = false) => {
    let updatedValue = value;
    if (firstTime && ["tags", "owners"]?.includes(key)) {
      const keyValue = data?.[key] || [];
      if (keyValue?.length > 0) {
        const filteredValue =
          keyValue
            .map(item => {
              if (item?.checked) {
                return item?.id;
              }
            })
            ?.filter(Boolean) || [];
        updatedValue = mergeTwoArrWithoutDuplicate(
          updatedValue,
          filteredValue,
          "id"
        );
      }
    }
    let tempObj = { ...data, [key]: updatedValue };
    sequenceContactStoreData.setContactFilterObject(tempObj);
    setData({ ...tempObj });
    if (key === "contactFields") {
      const validData = validateData(tempObj?.[key]);
      setDisableContactFieldAddBtn(!validData);
    }
  };

  const getSelectedItems = (item = []) => {
    if (item?.length) {
      return item.filter(item => item?.checked) || [];
    }
    return [];
  };

  const filterTeamDataBySelectedCbk = (value = [], selectedTeams = []) => {
    let tempObj = { ...data, ["team"]: teamFilterDropDownData };
    sequenceContactStoreData.setContactFilterObject(tempObj);
    let isValid = value?.length > 0 || selectedTeams?.length > 0 || false;
    setTeamData(isValid ? value : []);
    selectedFilterAction(isValid ? "add" : "remove", "team");
  };

  const getSequenceList = (pageNumber = "") => {
    const payload = {
      pageNo: pageNumber || pageNo,
      limit: 500,
      filters: {
        memberIds: [userDetail?.userInfo?.memberId]
      }
    };
    sequenceContactStoreData.listSequences(payload);
  };

  const clearAllFilter = () => {
    setTeamData([]);
    sequenceContactStoreData.setResetTeam(true);
    if (filterArr?.length > 0) {
      resetAll();
      sequenceContactStoreData.setFilteredList([]);
      sequenceContactStoreData.setFilterPayload({ ...defaultFilterPayload });
      tableLoadingFn(true);
      filterLoadingFn(true);
      let filters =
        SequenceUtils.getActiveTabFilterPayload(
          sequenceRenderState.currentStage
        ) || {};
      const payload = {
        filters,
        pageNo: 1,
        limit,
        campaignId: seqId
      };
      sequenceContactData.getSeqAnalyticsTab(seqId, {});
      sequenceContactData.getSeqContacts(payload);
    }
  };

  const getInit = async () => {
    hideIntercomChat();
    getSequenceList();
    settingsStoreData.getCustomFields();
    let tempData = { ...sequenceContactStoreData.contactFilterObject };
    tempData.tags = [...(tagsStoreData?.tagsData || [])];
    tempData.owners = [...(hasTeamMember || [])] || [];
    sequenceContactStoreData.setContactFilterObject(tempData);
  };

  const getTeamStatus = async () => {
    const memberId = userDetail?.userInfo?.memberId || "";
    let tempData = hasTeamMember ? [...hasTeamMember] : [];
    if (!isSharedSequenceWithUser && tempData?.length > 0) {
      tempData = [...tempData]?.filter(item => item?.memberId === memberId);
    }
    let contactFilter = { ...sequenceContactStoreData.contactFilterObject };
    contactFilter.owners = tempData;
    sequenceContactStoreData.setContactFilterObject(contactFilter);
  };

  const getApiCalls = async () => {
    tagsStoreData.getAllTags();
    userDetail?.userInfo?.team && getTeamStatus();
  };

  const getFinalizedValue = () => {
    let tempData = { ...(sequenceContactStoreData?.contactFilterObject || {}) };
    setData(tempData);
  };

  const FilterList = [
    {
      label: "Tags",
      keyValue: "tags",
      placeholder: "Search by tags",
      selected: getSelectedItems(data?.tags),
      data: data?.tags,
      setAction: handleFilterObject,
      activeFilter: activeFilter,
      setActiveFilter: handleActiveFilter,
      selectedFilterCbk: selectedFilterAction
    },
    {
      label: "Owners",
      keyValue: "owners",
      placeholder: "Search by owners",
      selected: getSelectedItems(data?.owners),
      data: data?.owners,
      setAction: handleFilterObject,
      activeFilter: activeFilter,
      setActiveFilter: handleActiveFilter,
      selectedFilterCbk: selectedFilterAction,
      showEmptyText: true,
      show: showContactOwner
    },
    {
      label: "Contact Field",
      keyValue: "contactFields",
      data: data?.contactFields,
      activeFilter: activeFilter,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      selectedFilterCbk: selectedFilterAction,
      errMsg: errMsg,
      setErrMsg: setErrMsg,
      disableAddButton: disableContactFieldAddBtn
    },
    {
      label: "Metrics",
      keyValue: "metrics",
      data: data?.metrics,
      activeFilter: activeFilter,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      selectedFilterCbk: selectedFilterAction,
      errMsg: errMsg,
      setErrMsg: setErrMsg
    },
    {
      label: "Date",
      keyValue: "dates",
      data: data?.dates,
      activeFilter: activeFilter,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      selectedFilterCbk: selectedFilterAction,
      errMsg: errMsg,
      setErrMsg: setErrMsg
    },
    {
      label: "Team",
      keyValue: "team",
      activeFilter: activeFilter,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      selectedFilterCbk: selectedFilterAction,
      updateSelectedCbk: filterTeamDataBySelectedCbk,
      teamCount: teamCount,
      teamFilterDropDownData: teamFilterDropDownData,
      setTeamCount: setTeamCount
    }
  ];

  useEffect(() => {
    getInit();
    getApiCalls();
    getFinalizedValue();
    return () => {
      setActiveFilter("");
      setErrMsg("");
    };
  }, []);

  return (
    <div className="sequenceContactFilter">
      <div className="filterHeader">
        <span className="name">Filter</span>
        <span
          className={`clearBtn ${
            (filterArr?.length === 0 && "disabled") || ""
          }`}
          onClick={clearAllFilter}
        >
          Clear all
        </span>
      </div>
      <div className="filterBody">
        {FilterList.map(item => {
          const { keyValue = "", show = true } = item || {};
          if (keyValue?.toLowerCase() === "tags") {
            return <MultiSelectDropdown {...item} key={keyValue} />;
          } else if (keyValue?.toLowerCase() === "owners" && show) {
            return <MultiSelectDropdown {...item} key={keyValue} />;
          } else if (keyValue?.toLowerCase() === "contactfields") {
            return <ContactFieldFilter {...item} key={keyValue} />;
          } else if (keyValue?.toLowerCase() === "metrics") {
            return <ContactMetricsFilter {...item} key={keyValue} />;
          } else if (keyValue?.toLowerCase() === "dates") {
            return <ContactDateFilter {...item} key={keyValue} />;
          } else if (keyValue?.toLowerCase() === "team") {
            return <ContactTeamFilter {...item} key={keyValue} />;
          }
        })}
      </div>
      <SequenceContactFilterFooter
        data={data}
        setData={setData}
        filterArr={filterArr}
        teamData={teamData}
        setTeamData={setTeamData}
        resetAll={resetAll}
        clearAllFilter={clearAllFilter}
        {...props}
      />
    </div>
  );
});

export { SequenceContactFilter };
export default SequenceContactFilter;
