import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { makeApi, URLS } from "Utils/apiURL";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import domainHealthCenterStore from "./store";
import { ReactComponent as SmileyIcon } from "Assets/svg/smiley.svg";
import {
  GMAIL_INBOX_DHC_OBJ,
  OUTLOOK_INBOX_DHC_OBJ
} from "../DomainHealthCenter/DomainHealthConstants";

const ArrowIconAndComingSoonTxt = (props = {}) => {
  const { isOpen = false, isComingSoon = false } = props || {};
  return !isComingSoon ? (
    <i className={`material-icons arrowIcon ${isOpen ? "rotate" : ""}`}>
      keyboard_arrow_down
    </i>
  ) : (
    <div className="comingSoonTxt">Coming Soon</div>
  );
};

const AccordionContent = observer((props = {}) => {
  const {
    tempContent = [],
    registerCheck = false,
    id = "",
    accordionRef = {},
    accordionIndex = 0,
    title = "",
    name = "",
    type = ""
  } = props || {};

  const updateDnsRecords = async () => {
    domainHealthCenterStore.setLoader(true);
    const config = {
      url: `${URLS.getDomainHealth}?inboxId=${id}`,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response?.data) {
      const { dnsRecords = {} } = response?.data || {};
      const {
        mxRecord = "",
        spfRecord = "",
        dmarcRecord = "",
        dkim = "",
        dkimSelector = ""
      } = dnsRecords || {};
      domainHealthCenterStore.setDnsRecords({
        ...domainHealthCenterStore.dnsRecords,
        ...{ mxRecord, spfRecord, dmarcRecord, dkim, dkimSelector }
      });
    }
    domainHealthCenterStore.setLoader(false);
  };

  const getMxContent = (item = {}) => {
    const header = (
      <div className="mxContentHeader">
        <span>Priority</span>
        <span> | </span>
        <span>Hostname</span>
      </div>
    );

    if (item?.content) {
      const splittedArr = item?.content?.split(",");
      const content =
        splittedArr?.length > 0 ? (
          splittedArr?.map(str => {
            const [priority = 0, hostName = ""] = str?.split(" ");
            return (
              <div className="mxContent" key={hostName}>
                <span>{priority}</span>
                <span> | </span>
                <span>{hostName}</span>
              </div>
            );
          })
        ) : (
          <></>
        );

      return (
        <>
          {header}
          {content}
        </>
      );
    }
    return <></>;
  };

  const DkimUpdateAndVerify = observer((props = {}) => {
    const { updateDnsRecords = () => {} } = props || {};
    const [dkimSelectorValue, setDkimSelectorValue] = useState(
      domainHealthCenterStore?.dnsRecords?.dkimSelector
    );

    const handleDkimLabelChange = e => {
      setDkimSelectorValue(e?.currentTarget?.value);
    };

    const confirmDkimPrompt = () => {
      confirmationPopupState.setPopupValues({
        content: (
          <>
            Failed to verify DKIM selector. Please check your selector name &
            try again. If you’re still facing issues with verification, please
            contact us at{" "}
            <a
              target="_blank"
              className="supportMailLink"
              rel="noopener noreferrer"
              href="mailto:support@salesgear.io"
            >
              support@salesgear.io
            </a>
          </>
        ),
        title: "Are you sure",
        actionBtnText: "Ok",
        needCancelBtn: false,
        dynamicDom: true,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
    };

    const updateDkimLabelAndDnsRecords = async () => {
      if (dkimSelectorValue) {
        domainHealthCenterStore.setLoader(true);
        const config = {
          url: `${URLS.getDkimLabel}/verify?inboxId=${id}&dkimSelector=${dkimSelectorValue}`,
          method: "GET"
        };
        let response = await makeApi(config);
        if (response) {
          const { status = "" } = response?.data || {};
          if (status === "success") {
            updateDnsRecords();
          } else {
            domainHealthCenterStore.setLoader(false);
            setDkimSelectorValue("");
            confirmDkimPrompt();
          }
        }
      } else {
        toasterState.setToastMsg(
          "Please enter a valid selector name",
          "failure"
        );
      }
    };

    const handleAddDkimLabelAction = (event = "") => {
      if (event && Utils.isSubmitKeyAction(event)) {
        updateDkimLabelAndDnsRecords();
      }
    };

    const inputObj = {
      id,
      placeholder: "Enter your DKIM selector Name",
      autoComplete: "off",
      name: id,
      className: "dkimInputBox",
      type: "text",
      onChange: handleDkimLabelChange,
      value: dkimSelectorValue || "",
      onKeyUp: handleAddDkimLabelAction
    };

    return (
      <li className="dkimUpdateAndVerify">
        <div>Already Updated?</div>
        <div className="inputAndVerifyBtn">
          <div className="dkimInputCont">
            <input {...inputObj} />
          </div>
          {!domainHealthCenterStore?.loader ? (
            <span className="verifyBtn" onClick={updateDkimLabelAndDnsRecords}>
              Verify Now
            </span>
          ) : (
            <span className="loaderCont">
              <span className="verifyLoader"></span>
            </span>
          )}
        </div>
      </li>
    );
  });

  useEffect(() => {
    if (accordionRef && accordionRef?.current && accordionIndex === 5) {
      accordionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }, [accordionRef]);

  return (
    <ul className="accordionContent" ref={accordionRef}>
      {!registerCheck && (
        <StepKeyValueContent
          name={name}
          keyList={
            ["gmail_oath", "smtp"].includes(type?.toLowerCase())
              ? GMAIL_INBOX_DHC_OBJ?.[name?.toUpperCase()]
              : OUTLOOK_INBOX_DHC_OBJ?.[name?.toUpperCase()]
          }
        />
      )}
      {(tempContent?.length > 0 &&
        tempContent.map((item, index) => {
          return (
            <li
              className="stepNoAndInfo gridMinMaxWrapper"
              key={`index-${index}`}
            >
              <span className="stepNo">{registerCheck ? item?.steps : ""}</span>
              <span
                className={`stepItem ${item?.isDkim ? "dkimContent" : ""} ${
                  registerCheck ? "registered" : ""
                }`}
              >
                {registerCheck
                  ? item?.isMx
                    ? getMxContent(item)
                    : item?.content
                  : ""}
              </span>
            </li>
          );
        })) ||
        ""}
      {["dkim"].includes(title?.toLowerCase()) && (
        <DkimUpdateAndVerify updateDnsRecords={updateDnsRecords} />
      )}
      {!registerCheck && !["dkim"].includes(title?.toLowerCase()) && (
        <li className="updateAndVerifyBtn">
          <span className="updateBtn">Already Updated?</span>
          {!domainHealthCenterStore?.loader ? (
            <span className="verifyNowBtn" onClick={updateDnsRecords}>
              Verify Now
            </span>
          ) : (
            <span className="loaderCont">
              <span className="verifyNowLoader" />
            </span>
          )}
        </li>
      )}
    </ul>
  );
});

const StepKeyValueContent = ({ name = "", keyList = "" }) => {
  return name
    ? ["SPF", "DKIM", "DMARC"].includes(name?.toUpperCase())
      ? keyList.map((item, childIndex) => {
          return (
            <li
              className="stepNoAndInfo gridWrapper"
              key={`${childIndex}${name}`}
            >
              <span className="stepNo">{`${item?.step} : `}</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: item?.content
                  }}
                />
                {item?.list?.length > 0 && (
                  <ul className="nestedListed">
                    {item?.list.map((ele, listIndex) => {
                      return (
                        <li
                          className="stepNoAndInfo"
                          key={`${childIndex}${name}${listIndex}`}
                        >
                          <span className="stepNo">{`${ele?.key} : `}</span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ele?.value
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
                {item?.text && <p>{item?.text}</p>}
              </span>
            </li>
          );
        })
      : (keyList && <li className="stepNoAndInfo">{keyList?.text}</li>) || ""
    : "";
};

const AccordionTitle = (props = {}) => {
  const {
    isComingSoon = false,
    registerCheck = false,
    isTitleBold = false,
    abbreviatedForm = false,
    title = ""
  } = props || {};

  return (
    <div className="accordionTitle">
      {!isComingSoon ? (
        <i
          className={`material-icons-outlined ${
            registerCheck ? "doneColor" : "errorColor"
          }`}
        >
          {registerCheck ? "done" : "error_outline"}
        </i>
      ) : (
        <SmileyIcon className="smileyIcon" />
      )}
      {isTitleBold ? <strong>{title}</strong> : title}
      {abbreviatedForm && (
        <span className="abbreviatedForm">{`(${abbreviatedForm})`}</span>
      )}
    </div>
  );
};

const Accordion = observer((props = {}) => {
  const {
    id = "",
    content = [],
    cursor = true,
    isVerifyBtn = false,
    abbreviatedForm = "",
    registerContent = [],
    registerCheck = false,
    isComingSoon = false,
    hasArrowIcon = true,
    accordionIndex = 0,
    currentIndex = -1,
    setCurrentIndex = () => {},
    accordionRef = {},
    ...remainingProps
  } = props || {};

  let selectedIndex = currentIndex === accordionIndex;

  const toggleAccordion = () => {
    if (!isComingSoon && hasArrowIcon) {
      setCurrentIndex(currentIndex === accordionIndex ? null : accordionIndex);
    }
  };

  let tempContent = registerContent ? registerContent : content;

  return (
    <div className={`accordion ${selectedIndex ? "expanded" : ""}`}>
      <div
        className={`accordionHeader ${!cursor ? "disableCursor" : ""}`}
        key={accordionIndex}
        onClick={toggleAccordion}
      >
        <AccordionTitle
          isComingSoon={isComingSoon}
          registerCheck={registerCheck}
          abbreviatedForm={abbreviatedForm}
          {...remainingProps}
        />
        {hasArrowIcon && (
          <ArrowIconAndComingSoonTxt
            isOpen={selectedIndex ? true : false}
            isComingSoon={isComingSoon}
          />
        )}
      </div>
      {selectedIndex && (
        <AccordionContent
          tempContent={tempContent}
          isVerifyBtn={isVerifyBtn}
          registerCheck={registerCheck}
          id={id}
          accordionRef={accordionRef}
          accordionIndex={accordionIndex}
          {...remainingProps}
        />
      )}
    </div>
  );
});

export default Accordion;
export { Accordion };
