import React, { useState } from "react";
import { observer } from "mobx-react";
import { accountsState } from "../AccountsState";
import PaginationComponent from "Components/common/PaginationComponent";
import UpdateContactStageDropdown from "Components/ContactCard/UpdateContactStageDropdown";
import CustomTooltip from "Components/common/CustomTooltip";
import Utils from "Utils/utils";
import { ContactUtils } from "Utils/ContactUtils";
import CheckBox from "Components/common/CheckBox";

const headerList = [
  { name: "Title", key: "title", className: "" },
  { name: "Tag", key: "tag", className: "" },
  { name: "Stage", key: "stage", className: "" },
  { name: "Last Contacted", key: "lastContacted", className: "" },
  { name: "Emails", key: "emails", className: "alignCenter" },
  { name: "Calls", key: "calls", className: "alignCenter" },
  { name: "Tasks", key: "tasks", className: "alignCenter" },
  { name: "Owner", key: "owner", className: "alignCenter" }
  // { name: "Actions", key: "actions" }
];

const ContactsDataTable = observer(
  ({
    loader = false,
    tableData = {},
    setTableData = () => {},
    paginate = () => {},
    reloadContactsPage = () => {},
    openContactCard = () => {},
    allAccountsSelectedCheckbox = false,
    setAllAccountsSelectedCheckbox = () => {},
    setAllAccountsSelectedInView = () => {}
  }) => {
    const { contacts = [], totalPages = 0, currentPage = 0 } = tableData || {};
    const [showTooltip, setShowTooltip] = useState({});

    const handleContactSlider = (event = "", contact = {}) => {
      Utils.preventDefaultFn(event);
      openContactCard(contact);
    };

    const handleAllCheckboxAction = (value = false, type = "") => {
      if (!loader) {
        setAllAccountsSelectedCheckbox(value);
        if (!value) {
          setAllAccountsSelectedInView(false);
        }
        setTableData({
          ...tableData,
          contacts: [...tableData?.contacts].map(item => ({
            ...item,
            isChecked: value
          }))
        });
      }
    };

    const handleCheckboxAction = (value = false, type = "") => {
      let tempTableArr = [];
      let count = 0;
      if (tableData?.contacts?.length > 0) {
        tempTableArr = tableData?.contacts.map(item => {
          const tempItem =
            item?.id === type ? { ...item, isChecked: value } : item;
          tempItem.isChecked && count++;
          return tempItem;
        });
      }
      setAllAccountsSelectedCheckbox(tableData?.contacts?.length === count);
      setTableData({
        ...tableData,
        contacts: tempTableArr
      });
    };

    const checkboxObj = {
      id: "all",
      key: "all",
      checked: allAccountsSelectedCheckbox,
      cbk: handleAllCheckboxAction
    };

    return (
      <div
        className={`dataTableCont ${
          totalPages > 1 || loader ? "paginationSection" : ""
        }`}
      >
        {!loader ? (
          <>
            <div
              className="tableContainer"
              style={{
                overflow:
                  !loader && (!contacts || contacts?.length < 1)
                    ? "hidden"
                    : "auto"
              }}
            >
              <table>
                <thead>
                  <tr>
                    {contacts?.length > 0 && (
                      <th scope="row" className="headerCheckbox">
                        <CheckBox {...checkboxObj} />
                      </th>
                    )}
                    <th scope="row">Contact</th>
                    {[...headerList].map(item => {
                      const {
                        key = "",
                        name = "",
                        className = ""
                      } = item || {};
                      return (
                        <th
                          scope="col"
                          key={`${key}ContactRowHeader`}
                          className={className}
                        >
                          {name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {contacts?.length > 0 && (
                  <tbody>
                    {[...contacts]?.map((item, index) => {
                      const {
                        id = "",
                        name = "",
                        firstName = "",
                        lastName = "",
                        email = "",
                        jobTitle = "",
                        company = "",
                        contactTags = [],
                        stage = "",
                        owner = {},
                        lastContacted = "",
                        stageDisplayName = "",
                        task = 0,
                        insights = {},
                        isChecked = false
                      } = item || {};
                      const {
                        callsMade = 0,
                        sentCount = 0,
                        tasksCompleted = 0
                      } = insights || {};
                      let fullName = Utils.getContactFullName(
                        firstName,
                        lastName,
                        name
                      );
                      return (
                        <tr key={`${index}${id}ContactRowBody`}>
                          <th scope="row" className="headerCheckbox">
                            <CheckBox
                              key={id}
                              id={id}
                              checked={isChecked || false}
                              cbk={handleCheckboxAction}
                            />
                          </th>
                          <th scope="row">
                            {fullName && (
                              <p
                                title={fullName}
                                className="name"
                                onClick={event => {
                                  handleContactSlider(event, item);
                                }}
                              >
                                {fullName}
                              </p>
                            )}
                            {email && (
                              <p
                                title={email}
                                className="email"
                                onClick={event => {
                                  handleContactSlider(event, item);
                                }}
                              >
                                {email}
                              </p>
                            )}
                          </th>
                          <td className="companyJobTile">
                            {jobTitle || company ? (
                              <>
                                <p title={jobTitle} className="job">
                                  {jobTitle}
                                </p>
                                <p title={company} className="company">
                                  {company}
                                </p>
                              </>
                            ) : (
                              "--"
                            )}
                          </td>
                          <td className="tag">
                            {ContactUtils.getTagNameDom(contactTags)}
                          </td>
                          <td>
                            <UpdateContactStageDropdown
                              stage={stageDisplayName || stage || "--"}
                              contactStages={accountsState?.contactsStages}
                              reloadCbk={reloadContactsPage}
                              pageType="contacts"
                              entityId={id}
                            />
                          </td>
                          <td>{Utils.formatDate(lastContacted) || "--"}</td>
                          <td className="alignCenter">{sentCount || 0}</td>
                          <td className="alignCenter">{callsMade || 0}</td>
                          <td className="alignCenter">{tasksCompleted || 0}</td>
                          <td className="alignCenter">
                            <div
                              className="ctOwner"
                              onMouseEnter={() =>
                                setShowTooltip({ index, value: true })
                              }
                              onMouseLeave={() =>
                                setShowTooltip({ index, value: false })
                              }
                            >
                              {(owner && Object.keys(owner)?.length && (
                                <>
                                  <span className="circleOwner">
                                    {Utils.getOwnerInitial(owner)}
                                  </span>
                                  {showTooltip?.index === index &&
                                    showTooltip?.value && (
                                      <CustomTooltip
                                        text={`Added by ${Utils.getOwnerName(
                                          owner
                                        )}`}
                                      />
                                    )}
                                </>
                              )) ||
                                "--"}
                            </div>
                          </td>
                          {/* <td className="alignCenter">actions</td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              {!loader && (!contacts || contacts?.length < 1) && (
                <EmptyContacts />
              )}
            </div>
          </>
        ) : (
          <TableLoader count={10} />
        )}
        <PaginationComponent
          callback={paginate}
          loading={loader}
          totalPages={totalPages}
          currentPage={currentPage || 1}
        />
      </div>
    );
  }
);

const TableLoader = ({ count = 0 }) => {
  return (
    <div className="tableContainer accountContactsLoader">
      <table>
        <thead>
          <tr>
            <th scope="col" className={"headerCheckbox"} />
            <th scope="row" />
            {[...headerList].map((item, index) => {
              const { key = "" } = item || {};
              return (
                <th scope="col" key={`${key}${index}ContactRowTitleLoader`} />
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: count }, (value, index) => {
            return (
              <tr key={`${index}ContactRowBodyLoader`}>
                <td className={"headerCheckbox"}>
                  <span className="linear-background" />
                </td>
                <th scope="row" key={`${index}RowDataLoader`}>
                  <span className="linear-background" />
                </th>
                {[...headerList].map((ele, cIdx) => {
                  const { key = "", className = "" } = ele || {};
                  return (
                    <td
                      key={`${key}${index}${cIdx}RowDataLoader`}
                      className={className}
                    >
                      <span className="linear-background" />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const EmptyContacts = () => {
  return (
    <div className="emptyContactsSection">
      <i className="material-icons-outlined">supervisor_account</i>
      <div className="text">No Contacts Found</div>
    </div>
  );
};

export default ContactsDataTable;
