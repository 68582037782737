import React from "react";


const PercentageSvg = ({ progressiveLoader = {} }) => {
  return (
    <div className="singleChart">
      <svg viewBox="0 0 36 36" className="circularChart">
        <path
          className="circleBg"
          d="M18 2.0845
a 15.9155 15.9155 0 0 1 0 31.831
a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          style={{ stroke: progressiveLoader?.color }}
          strokeDasharray={`${progressiveLoader?.avg}, 100`}
          d="M18 2.0845
a 15.9155 15.9155 0 0 1 0 31.831
a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="19" y="24" className="percentage">
          %
        </text>
      </svg>
    </div>
  );
};

export default PercentageSvg;
