/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import InputTextBox from "Components/common/InputTextBox";
import ConstantObj from "Static/constant";
import Utils from "Utils/utils";
import customUrlStoreData from "Stores/Setting/CustomUrlStore";
import { customUrlSuccessPopupState } from "./CustomUrlSuccessPopup";
import Button from "Components/common/Button";

const AddEditCustomUrlPopup = props => {
  const { closeCbk, isEdit = false, data, cnameKey, setDataCbk } = props;
  const { domain = "" } = data;

  const [domainName, setDomainName] = useState(
    domain ? Utils.removeHttpFromString(domain) : ""
  );
  const [loader, setLoader] = useState("");
  const [percentageCompleted, setPercentageCompleted] = useState("0");
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [sudDomainErr, setDomainErr] = useState(false);
  const [domainValid, setDomainValid] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const closePopup = () => {
    if (!loader) {
      closeCbk();
    }
  };

  const checkOldCurrentDomainMatch = () => {
    if (
      Utils.removeHttpFromString(domainName) ===
      Utils.removeHttpFromString(domain)
    ) {
      return true;
    }
    return false;
  };

  const verifyDomain = e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      Utils.removeInputRequired(e);
      verifyBrandUrl();
    }
  };

  const verifyBrandUrl = () => {
    if (
      ConstantObj.SUB_DOMAIN_REGEX.test(
        Utils.removeHttpFromString(domainName)
      ) &&
      !checkOldCurrentDomainMatch()
    ) {
      setLoader(true);
      const statusCallback = async (status, data) => {
        await customUrlStoreData.getBrandUrlData();
        setLoader(false);
        closeCbk(false);
        if (data) {
          setDataCbk(data);
        }
        if (status) {
          customUrlSuccessPopupState.setShowPopup(true);
        }
      };
      customUrlStoreData.verifyBrandUrl(domainName, cnameKey, statusCallback);
    } else if (checkOldCurrentDomainMatch()) {
      setDomainErr(true);
      setErrMsg("Enter a different domain");
      setTimeout(() => {
        setDomainErr(false);
        setErrMsg("");
      }, 2000);
    } else {
      setDomainErr(true);
      setErrMsg("Enter a desired domain e.g. hello.mydomain.com");
      setTimeout(() => {
        setDomainErr(false);
        setErrMsg("");
      }, 2000);
    }
  };

  const onChange = (id, value) => {
    setDomainName(value);
    if (ConstantObj.SUB_DOMAIN_REGEX.test(Utils.removeHttpFromString(value))) {
      setDomainValid(true);
    } else {
      setDomainValid(false);
    }
  };

  const setAnimation = () => {
    setTimeout(() => {
      setPercentageCompleted("66px");
    }, 50);
  };

  const isEditPopup = () => {
    if (isEdit && domain) {
      if (
        ConstantObj.SUB_DOMAIN_REGEX.test(Utils.removeHttpFromString(domain))
      ) {
        setDomainValid(true);
      }
    }
  };

  const copySecret = e => {
    setShowCopiedMessage(true);
    Utils.preventDefaultFn(e);
    Utils.copyToClipboard(cnameKey);
    setInterval(() => {
      setShowCopiedMessage(false);
    }, 1000);
  };

  useEffect(() => {
    setAnimation();
    isEditPopup();
  }, []);

  return (
    <div className="addCustomUrlPopup">
      <div className="card animated customzoomIn">
        <div className="cardTitle">
          <h4>{`${isEdit ? "Edit" : "New"} Custom URL`}</h4>
        </div>
        <div className="cardBody">
          <p className="descText">
            Add the CNAME record below to the DNS configuration of your desired
            domain. You'll need access to your company's hosting service. If you
            don't have access, kindly check with your IT administrator for
            assistance.
          </p>
          <div className="cnameCont">
            <div className="statusBar">
              <div className="statusIcon">
                <i className="material-icons greenTick">check_circle</i>
                <div
                  className="progressBar"
                  style={{ height: percentageCompleted }}
                />
                <i
                  className={`material-icons pendingIcon ${
                    domainValid ? "greenTick" : "greyTick"
                  }`}
                >
                  check_circle
                </i>
              </div>
            </div>
            <div className="textBoxWrapper">
              <div className="target hostDetails">
                <div className="cnameText">CNAME Target</div>
                <InputTextBox
                  name="CNAME Target"
                  id="cNameTarget"
                  type="text"
                  value={cnameKey}
                  readOnly={true}
                />
                <span
                  className="copiedText"
                  style={{
                    visibility: showCopiedMessage ? "visible" : "hidden"
                  }}
                >
                  Copied Successfully
                </span>
                <div className="copy" onClick={e => copySecret(e)}>
                  Copy
                </div>
              </div>
              <div className="host hostDetails">
                <div className="cnameText">CNAME Host</div>
                <InputTextBox
                  name="domain"
                  id="domain"
                  placeHolder="Provide your desired domain. e.g. hello.mydomain.com"
                  type="text"
                  value={domainName}
                  change={onChange}
                  cbk={verifyDomain}
                  readOnly={false}
                />
                {sudDomainErr && (
                  <span className="errMsgDomainText">{errMsg}</span>
                )}
              </div>
            </div>
          </div>
          <div className="actionWrapper">
            <Button
              id="cancelCustomUrl"
              name="cancelCustomUrl"
              btnText="Cancel"
              icon={false}
              iconName=""
              type="button"
              className="cancel"
              loader={false}
              click={closePopup}
            />
            <div className="verifyBtn">
              <Button
                id="verifyCustomUrl"
                name="verifyCustomUrl"
                btnText="Verify"
                icon={false}
                iconName=""
                type="button"
                className={`verify ${loader ? "loading" : ""}`}
                loader={loader}
                click={verifyBrandUrl}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="overlayAddEditCustomUrl" />
    </div>
  );
};

export default observer(AddEditCustomUrlPopup);
export { AddEditCustomUrlPopup };
