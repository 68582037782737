import React, { useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import { ReactComponent as LinkedinIcon } from "./svg/linkedIn.svg";
import { ReactComponent as LinkedinPremiumIcon } from "./svg/liPremiumIcon.svg";
import linkedInUtils from "./common/linkedInUtils";

const ConnectedLinkedInButton = observer((props = {}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const inboxOptions = [
    {
      id: "connectLinkedIn",
      text: "Connect with LinkedIn",
      type: "linkedIn",
      Img: LinkedinIcon
    },
    {
      id: "connectSalesNavigator",
      text: "Connect with Sales Navigator",
      type: "salesNavigator",
      Img: LinkedinPremiumIcon
    }
  ];

  const handleChange = event => {
    Utils.preventDefaultFn(event);
    setShowDropdown(false);
    let optDom = event.target.closest("li") || "";
    if (optDom) {
      let type = optDom.getAttribute("inbox-type") || "";
      if (type) {
        if (type === "linkedIn") {
          linkedInUtils.handleButtonClick();
        } else {
          linkedInUtils.handleSalesNavigatorButtonClick();
        }
      }
    }
  };

  return (
    <div className="dropdownBtn">
      <Button
        id="ConnectedLinkedInButton"
        name="ConnectedLinkedInButton"
        btnText="Connect Account"
        type="button"
        className={`ConnectedLinkedInButton`}
        icon={true}
        iconName="keyboard_arrow_down"
        click={() => setShowDropdown(!showDropdown)}
      />
      {showDropdown && (
        <>
          <ul
            className="inboxTypeOptions connectLinkedIn"
            onClick={event => handleChange(event)}
          >
            {inboxOptions.map(item => {
              const { id = "", text = "", type = "", Img = "" } = item || {};
              return (
                <li
                  className={`option ${id}`}
                  key={id}
                  id={id}
                  inbox-type={type}
                >
                  <>
                    <Img />
                    <span className="btnCta">{text}</span>
                  </>
                </li>
              );
            })}
          </ul>
          <div
            className="inboxTypeOptionsOverlay"
            onClick={() => setShowDropdown(false)}
          />
        </>
      )}
    </div>
  );
});

export { ConnectedLinkedInButton };
export default ConnectedLinkedInButton;
