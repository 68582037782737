import React, { useState, useEffect } from "react";
import CustomRangeSelection from "./CustomRangeSelection";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { observer } from "mobx-react";
import { dataSearchState } from "../state";

export const ContactRangeSelection = observer((props = {}) => {
  const {
    bulkPurchaseEnabled = false,
    allTableContactChecked = false,
    pagedContacts = 0,
    topPurchaseLimit = 0,
    customBulkPurchaseLimit = 1000,
    isCustomRangeSelector = false,
    setCustomRangeSelector = () => {},
    setShowRangeSelector = () => {},
    markSelectedContact = () => {}
  } = props || {};

  const [range, setRange] = useState("");
  const [err, setErr] = useState("");

  const selectedCallback = (id = "", limit = 0) => {
    let checkedValue = allTableContactChecked
      ? dataSearchState?.rangeSelected?.type === id
        ? false
        : true
      : limit >= pagedContacts
      ? true
      : false;
    setShowRangeSelector(false);
    markSelectedContact(checkedValue, id, limit);
  };

  const handleSelection = (id = "") => {
    if (["bulk", "custom"].includes(id) && !bulkPurchaseEnabled) {
      upgradePopupState.setPopupValues({
        type:
          id === "custom"
            ? "data_BulkCustomSelection"
            : "data_BulkTopMaxSelection"
      });
      upgradePopupState.setPopup(true);
      setShowRangeSelector(false);
      return;
    }
    if (id === "custom") {
      setCustomRangeSelector(true);
    } else {
      let limit = id === "all" ? pagedContacts : topPurchaseLimit;
      selectedCallback(id, limit);
    }
  };

  const dropdownList = [
    {
      id: "all",
      keyValue: "limitPerPage",
      name: `Select ${pagedContacts} contact${
        pagedContacts > 1 ? "s" : ""
      } in this page`
    },
    {
      id: "bulk",
      keyValue: "bulkLimit",
      name: `Select top ${topPurchaseLimit} contacts`
    },
    {
      id: "custom",
      keyValue: "customRange",
      name: "Custom Selection"
    }
  ];

  useEffect(() => {
    return () => {
      setRange("");
      setErr("");
    };
  }, []);

  return (
    <div className="contactRangeSelection">
      <div className="rangeSelector">
        {!isCustomRangeSelector ? (
          dropdownList.map(item => {
            const { id = "", name = "", keyValue = "" } = item || {};
            if (
              (dataSearchState?.currentPage === 1 && id === "custom") ||
              ["bulk", "all"].includes(id)
            ) {
              return (
                <div
                  key={keyValue}
                  id={id}
                  onClick={() => handleSelection(id)}
                  className={`itemList ${
                    dataSearchState?.rangeSelected?.type === id ? "active" : ""
                  }`}
                >
                  {name}
                </div>
              );
            }
          })
        ) : (
          <CustomRangeSelection
            range={range}
            setRange={setRange}
            err={err}
            setErr={setErr}
            customAction={selectedCallback}
            setCustomRangeSelector={setCustomRangeSelector}
            customBulkPurchaseLimit={customBulkPurchaseLimit}
          />
        )}
      </div>
      <div
        className="bgOverlay"
        onClick={() => {
          setShowRangeSelector(false);
          isCustomRangeSelector && setCustomRangeSelector(false);
        }}
      />
    </div>
  );
});

export default ContactRangeSelection;
