import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import Utils from "./utils";

const TaskUtils = {};

TaskUtils.getPayload = (data = {}, isEdit = false) => {
  const {
    dueDateString: date,
    content = "",
    subject = "",
    note = "",
    timeZone = "",
    type = "",
    email = "",
    linkedInTaskType = "",
    taskId = "",
    attachments = []
  } = data || {};
  const dueDateString = Utils.createScheduleDate(date);

  const payload = {
    customEmailData: {
      subject,
      content,
      attachments
    },
    dueDateString,
    email,
    note,
    timeZone,
    type: type === "manualEmail" ? "email" : type
  };
  if (type === "linkedin" && linkedInTaskType) {
    payload.linkedInTaskType = linkedInTaskType;
  }

  if (isEdit) {
    payload.id = taskId;
  }

  return payload;
};

TaskUtils.isFileUploadingComplete = () => {
  if (fileAttachmentStore?.taskFileUploadingLoader) {
    confirmationPopupState.setPopupValues({
      content:
        "Please wait until the files are uploaded successfully. This may take only a few seconds.",
      needCancelBtn: false,
      actionBtnText: "Ok",
      callback: () => confirmationPopupState.setShowPopup(false)
    });
    confirmationPopupState.setShowPopup(true);
    return false;
  }
  return true;
};

TaskUtils.getTasktypeFilter = (list = []) => {
  if (!list || !list.length || list?.includes("total")) {
    return null;
  } else {
    if (list.length) {
      let tempSet = new Set(list);
      let filteredArray = Array.from(tempSet)?.filter(
        item => item?.toLowerCase() !== "total"
      );
      if (filteredArray?.includes("email")) {
        filteredArray.push("manualEmail");
      }
      return filteredArray;
    }
    return null;
  }
};
export default TaskUtils;
export { TaskUtils };
