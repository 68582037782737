/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { customUrlStoreData } from "Stores/Setting/CustomUrlStore";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import DeleteIcon from "Assets/png/trash.png";

class DeleteCustomURL {
  @observable showPopup = false;
  @observable callBack = e => {};

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setCallBack = val => {
    this.callBack = val;
  };

  @action
  setPopupValues = obj => {
    this.popupValues = obj;
  };
}

const deleteCustomURLState = new DeleteCustomURL();

const DeleteCustomURLPopup = observer(() => {
  const [loader, setLoader] = useState(false);

  const closePopup = () => {
    deleteCustomURLState.setShowPopup(false);
  };

  const deleteBrandUrl = async () => {
    if (Utils.isAdmin()) {
      setLoader(true);
      await customUrlStoreData.deleteBrandUrl();
      setLoader(false);
      closePopup();
    }
  };

  return deleteCustomURLState.showPopup ? (
    <div className="deletecustomUrl popupWrapper">
      <div className="confirmation-popup common-popup">
        <div className="deleteCustomUrlPopupWrapper popup-wrap animated customzoomIn">
          <div className="deleteImgcont">
            <img src={DeleteIcon} alt="Trash"></img>
          </div>
          <div className="deleteHeader">
            <div className="deleteText">Are you sure you want to delete?</div>
          </div>
          <div className="deleteDesc">
            If the custom URL is removed, we will use Salesgear’s default URLs
            for all tracking
          </div>
          <div className="actionWrapper">
            <Button
              id="addCustomUrl"
              name="addCustomUrl"
              btnText="Cancel"
              icon={false}
              iconName=""
              type="button"
              className="cancel"
              loader={false}
              click={closePopup}
            />
            <div className="confirmBtn">
              <Button
                id="addCustomUrl"
                name="addCustomUrl"
                btnText="Confirm"
                icon={false}
                iconName=""
                type="button"
                className={`confirm ${loader ? "loading" : ""}`}
                loader={loader}
                click={deleteBrandUrl}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

export default DeleteCustomURLPopup;
export { DeleteCustomURLPopup, deleteCustomURLState };
