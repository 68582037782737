import React, { useEffect, useState } from "react";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";

const taskTypeConfig = {
  email: "Manual Email",
  linkedin: "LinkedIn",
  customTask: "Custom Task",
  phone: "Phone"
};

const CreateTaskTriggerPopup = props => {
  const { actionsArray = [], change = () => {}, index = 0, task = {} } = props;
  const [taskType, setTaskType] = useState("");
  const indexValue = index.split("-")[1];

  const triggerCbk = (
    btnType,
    triggerTaskData = {},
    setLoaderCbk = () => {}
  ) => {
    let tempList = [...actionsArray];
    if (btnType === "save") {
      setTaskType(triggerTaskData?.type);
      tempList[indexValue - 1] = {
        actionType: "CREATE_TASK",
        task: triggerTaskData
      };
    } else {
      if (!task || !Object.keys(task).length) {
        setTaskType(triggerTaskData?.type);
        tempList[indexValue - 1].actionType = "";
      }
    }
    change("actions", tempList);
    setLoaderCbk();
    addStagesPopupState.setShowStepEmailEditorPopup(false);
    addStagesPopupState.setShowPopup(false);
  };

  const handleTriggerEdit = () => {
    const actionList = actionsArray[indexValue - 1];
    if (!(actionList?.task && Object.keys(actionList?.task).length)) {
      addStagesPopupState.setPopupValues({
        pageSource: "Triggers",
        isFromTriggers: true,
        stageType: "manualEmail",
        btnText: "Next",
        triggerCbk: triggerCbk
      });
    } else {
      addStagesPopupState.setPopupValues({
        pageSource: "Triggers",
        isFromTriggers: true,
        isEdit: true,
        triggerCbk: triggerCbk,
        ...actionList?.task,
        notes: actionList?.task?.note,
        stageType: ["email", "manualEmail"].includes(actionList?.task?.type)
          ? "manualEmail"
          : actionList?.task?.type,
        defaultTask: actionList?.task?.linkedInTaskType,
        inputVal: {
          ...actionList?.task,
          ...actionList?.task?.customEmailData
        },
        btnText: ["email", "manualEmail"].includes(actionList?.task?.type)
          ? "Next"
          : "Save"
      });
    }
    addStagesPopupState.setShowPopup(true);
  };

  useEffect(() => {
    if (!task || Object.keys(task).length === 0) {
      addStagesPopupState.setPopupValues({
        pageSource: "Triggers",
        isFromTriggers: true,
        stageType: "manualEmail",
        btnText: "Next",
        triggerCbk: triggerCbk
      });
      addStagesPopupState.setShowPopup(true);
    }
  }, []);

  return (
    <div className="taskTypeValue" onClick={handleTriggerEdit}>
      {task && Object.keys(task)?.length
        ? taskTypeConfig?.[task?.type]
        : taskTypeConfig[taskType] || <span className="labelValue">Value</span>}
    </div>
  );
};

export { CreateTaskTriggerPopup };
export default CreateTaskTriggerPopup;
