import React, { useState, useEffect } from "react";
import DropDownMenu from "Components/common/DropDownMenu";
import CustomSingleDrodown from "./CustomSingleDrodown";

export const SingleDropdown = props => {
  const {
    options = [],
    className = "",
    label = "",
    referenceId = "",
    sequenceId = "",
    errorMsg = "",
    errMsgClass = "",
    defaultValue = "",
    isDropdownAllowed = true,
    isCustomDropdown = false,
    sequenceLoader = false,
    parentIndex = "",
    showDropdown = "",
    hasCustomFieldValue = false,
    setActiveDropdownIndex = () => {},
    handleScrollEnd = () => {},
    handleSequenceAction = () => {}
  } = props || {};

  const [dropdownBottom, setDropdownBottom] = useState(true);

  const toggleDropdown = event => {
    if (isDropdownAllowed) {
      let positionY = event.clientY || event.pageY || 0;
      let domPosition =
        30 - event.nativeEvent.layerY || event.nativeEvent.offsetY || 0;
      let innerHeight = window.innerHeight;
      let diffHeight = innerHeight - positionY;
      setDropdownBottom(diffHeight - (domPosition + 70) >= 206);
      setActiveDropdownIndex(`${referenceId}-${parentIndex}`);
    }
  };

  useEffect(() => {
    return () => setDropdownBottom(true);
  }, []);

  return (
    <div className={`singleDropdown ${!dropdownBottom ? "positionTop" : ""}`}>
      <div
        className={className || "selectLabel"}
        onClick={event => toggleDropdown(event)}
      >
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: defaultValue || label
          }}
        />
        <i className={`material-icons arrowIcon`}>keyboard_arrow_down</i>
      </div>
      {showDropdown && (
        <>
          {isCustomDropdown ? (
            <CustomSingleDrodown
              options={options}
              sequenceId={sequenceId}
              parentIndex={parentIndex}
              loader={sequenceLoader}
              handleSequenceAction={handleSequenceAction}
              handleScrollEnd={handleScrollEnd}
            />
          ) : (
            <DropDownMenu
              options={options}
              label={label}
              referenceId={referenceId}
              parentIndex={parentIndex}
              animationClass={
                dropdownBottom ? "animatedDropDown" : "animatedDropDownReverse"
              }
              hasCustomFieldValue={hasCustomFieldValue}
            />
          )}
          <div
            className="dropdownOverlay"
            onClick={() => setActiveDropdownIndex(false)}
          />
        </>
      )}
      {errorMsg && (
        <span
          className={`errMsgText ${errMsgClass}`}
          dangerouslySetInnerHTML={{
            __html: errorMsg || ""
          }}
        />
      )}
    </div>
  );
};

export default SingleDropdown;
