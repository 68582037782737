import React, { useState } from "react";
import { Utils } from "Utils/utils";
import { withRouter } from "react-router-dom";
import Button from "Components/common/Button";
import Toaster, { toasterState } from "Components/common/toaster";
import makeApi, { URLS } from "Utils/apiURL";

const UnsubscribePage = props => {
  let key = props?.id || "";
  const [loader, setLoder] = useState(false);

  const unsubscribe = async () => {
    let code = Utils.getRandomValue();
    try {
      setLoder(true);
      const config = {
        url: URLS.unsubscribeContact ? URLS.unsubscribeContact : "",
        method: "POST",
        data: {
          key: getKeyInValidFormat(key),
          code
        }
      };
      let res = await makeApi(config);
      if (res && res.data === 200) {
        setLoder(false);
        props.history.push("/unsubscribe/success");
      } else if (res && res.data === 103) {
        toasterState.setToastMsg(
          "You were opted out already and will receive no further messages",
          "warning"
        );
        setLoder(false);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
        setLoder(false);
      }
    } catch (e) {
      setLoder(false);
      console.error("Getting Unsubscribe action error ", e);
    }
  };

  const getKeyInValidFormat = key => {
    if (key) {
      const decodedVal = decodeURIComponent(key);
      return decodedVal.replace(/ /g, "+");
    }
    return key;
  };

  return (
    <div className="unsubscribePage">
      <Toaster />
      <h1 className="bigTitleFont">
        Click the below button to unsubscribe
        <br />
        Thanks!
      </h1>

      <Button
        id="unsubscribeBtn"
        name="unsubscribeBtn"
        btnText="Unsubscribe"
        icon={false}
        iconName=""
        type="button"
        className={`unsubscribeBtn ${loader ? "loading" : ""}`}
        loader={loader}
        click={unsubscribe}
      />
    </div>
  );
};

export default withRouter(UnsubscribePage);
export { UnsubscribePage };
