import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import AutoCompleteDropdown from "Components/common/AutoCompleteDropdown";
import InputBox from "Components/Register/InputBox";
import DeleteRow from "../../Triggers/AddEditTrigger/DeleteRow";
import AddItem from "../../Triggers/AddEditTrigger/AddItem";
import { salesforceConnectedData } from "./SalesforceConnectedData";

const ContactCondition = observer(props => {
  const defaultConditionsObject = {
    fieldName: "",
    operator: "EQUALS",
    value: "",
    name: ""
  };

  const { fields = {} } = props || {};
  const [crmOptionList, setCrmOptionList] = useState({});
  const [crmMappedConfig, setCrmMappedConfig] = useState({});

  const constructCrmFields = () => {
    const updatedCrmOptions = fields?.fieldList?.map(item => {
      return {
        value: item?.crmField,
        name: item?.crmFieldLabel,
        dataType: item?.dataType
      };
    });
    const mappedCrmField =
      updatedCrmOptions?.length > 0 &&
      updatedCrmOptions?.reduce(function (accumulator, item) {
        accumulator[item?.value] = item?.name;
        return accumulator;
      }, {});
    setCrmMappedConfig(mappedCrmField);
    return updatedCrmOptions;
  };

  const getDropdownList = () => {
    setCrmOptionList(constructCrmFields());
  };

  const customCbk = (item, index) => {
    const indexValue = Number(index?.split("-")?.[1]);
    const fields = [...salesforceConnectedData?.sfContactConditions];
    fields[indexValue - 1] = {
      ...salesforceConnectedData.sfContactConditions?.[indexValue - 1],
      fieldName: item?.value
    };
    salesforceConnectedData.setSfContactConditions([...fields]);
  };

  const handleInputChange = (value, id) => {
    const indexValue = Number(id?.split("-")?.[1]);
    const fields = [...salesforceConnectedData.sfContactConditions];
    fields[indexValue - 1] = {
      ...salesforceConnectedData?.sfContactConditions?.[indexValue - 1],
      value: value
    };
    salesforceConnectedData.setSfContactConditions([...fields]);
  };

  const handleDelete = (_, index) => {
    let tempList = [...salesforceConnectedData?.sfContactConditions];
    tempList.splice(index, 1);
    salesforceConnectedData.setSfContactConditions(tempList);
  };

  const addNewConditions = () => {
    let tempList = [...salesforceConnectedData?.sfContactConditions];
    tempList.push(defaultConditionsObject);
    salesforceConnectedData.setSfContactConditions(tempList);
  };

  useEffect(() => {
    getDropdownList();
  }, [fields]);

  return (
    <div className="contactConditionWrapper">
      {salesforceConnectedData?.sfContactConditions?.length > 0 &&
        salesforceConnectedData.sfContactConditions.map((item, index) => {
          const { value = "", fieldName = "" } = item;
          const inputObject = {
            name: `${"text"}-${index + 1}`,
            id: `${"text"}-${index + 1}`,
            type: "text",
            value: value || "",
            changeAction: handleInputChange,
            label: "",
            text: "",
            minValue: 0,
            className: "contactInputBox",
            placeholder: "Value"
          };

          return (
            <div className="conditionWrapper" key={`${"text"}-${index + 1}`}>
              <div className="conditionDropdownWrapper">
                <span className="horizontalBorderLine" />
                <div className="conditionDropdownCont">
                  <AutoCompleteDropdown
                    optionList={crmOptionList}
                    defaultValue={crmMappedConfig?.[fieldName]}
                    cbk={customCbk}
                    index={`active-${index + 1}`}
                    loader={!crmOptionList}
                  />
                  <div className="operatorListWrapper">
                    <div className="conditionSymbol">=</div>
                  </div>
                  <InputBox {...inputObject} />
                  <DeleteRow
                    childIndex={index}
                    className="deleteCondition"
                    deleteCbk={handleDelete}
                  />
                </div>
              </div>
            </div>
          );
        })}
      <div className="conditionsFunc">
        <AddItem addCbk={addNewConditions} name="Add condition" />
        <div className="conditionNotesSection">
          Note: Filter values are case-sensitive, please update accordingly.
        </div>
      </div>
    </div>
  );
});

export { ContactCondition };
export default ContactCondition;
