import React, { useContext } from "react";
import { observer } from "mobx-react";
import CustomMultiSelect from "Pages/Dashboard/dataSearch/filterComponent/CustomMultiSelect";
import { TeamMemberFilterContext } from "Components/common/UserDropdown";
import { TeamMemberFilterInCallContext } from "Pages/Dashboard/Calls/UserFilterDropDown";
import { TeamMemberFilterInReportsContext } from "Pages/Dashboard/reports/UserFilterDropdown";

const FilterDropDownTeam = observer(props => {
  let { context = "" } = props;
  let applyContext = "";
  if (context === "TeamMemberFilterContext") {
    applyContext = TeamMemberFilterContext;
  } else if (context === "TeamMemberFilterInCallContext") {
    applyContext = TeamMemberFilterInCallContext;
  } else if (context === "TeamMemberFilterInReportsContext") {
    applyContext = TeamMemberFilterInReportsContext;
  }

  const {
    teamMemberList,
    setTeamMemberList,
    updateSelectedUser = () => {},
    resetTeamWithMembersToInitial = () => {}
  } = useContext(applyContext);

  const handleSelectActionCbk = (checked, uId, name) => {
    let tempData = [...teamMemberList];
    if (tempData?.length > 0) {
      tempData = tempData.map(item => {
        const { id = "" } = item || {};
        if (id === uId || uId?.toLowerCase() === "selectall") {
          item.checked = checked;
          item?.submenu?.length > 0 &&
            item.submenu.map(submenuItem => {
              submenuItem.checked = checked;
              return submenuItem;
            });
        }
        return item;
      });
      const isAllSelected =
        tempData?.length > 0 &&
        tempData.filter(
          selectedItem =>
            selectedItem?.id?.toLowerCase() !== "selectall" &&
            selectedItem?.checked
        );
      tempData[0].checked = isAllSelected?.length === tempData?.length - 1;
    }
    setTeamMemberList(tempData);
  };

  const handleSubmenuSelectActionCbk = (
    checked,
    uId,
    name,
    itemWithSubmenu
  ) => {
    let tempData = [...teamMemberList];
    if (tempData?.length > 0) {
      tempData = tempData.map(item => {
        const { id = "" } = item || {};
        if (id === itemWithSubmenu?.id) {
          item.submenu.map(submenuItem => {
            if (submenuItem?.id === uId) {
              submenuItem.checked = checked;
            }
          });
          const isAllSubmenuSelected = item.submenu.filter(
            selectedItem => selectedItem?.checked
          );
          item.checked = isAllSubmenuSelected?.length === item?.submenu?.length;
        }
        return item;
      });
    }
    const isAllSelected =
      tempData?.length > 0 &&
      tempData.filter(
        selectedItem =>
          selectedItem?.id !== "selectAll" && selectedItem?.checked
      );
    tempData[0].checked = isAllSelected?.length === tempData?.length - 1;
    setTeamMemberList(tempData);
  };

  const searchFilterCbk = (value = "") => {
    const lowercasedValue = value?.toLowerCase()?.trim() || "";
    if (!lowercasedValue) {
      resetTeamWithMembersToInitial();
    } else {
      let filteredData =
        teamMemberList?.length > 0 &&
        teamMemberList?.filter(item => {
          if (item?.id !== "selectAll") {
            let val = item?.value
              ?.toString()
              ?.toLowerCase()
              ?.includes(lowercasedValue);
            item["show"] = val;
            item.submenu?.length > 0 &&
              item.submenu.filter(subMenuItem => {
                let subMenuVal = subMenuItem?.value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(lowercasedValue);
                subMenuItem.show = subMenuVal;
                if (subMenuVal) {
                  item["show"] = subMenuVal;
                }
              });
            return item;
          }
        });
      setTeamMemberList(filteredData);
    }
  };

  const handleApplySelectionCbk = () => {
    let selectedTeam = [];
    let selectedTeamMembers = [];
    let tempTeamMemberList = [...teamMemberList];
    tempTeamMemberList?.length > 0 &&
      tempTeamMemberList.forEach(item => {
        if (item?.id !== "selectAll" && item?.submenu?.length > 0) {
          item.submenu.forEach(subItem => {
            if (subItem?.checked) {
              !selectedTeamMembers.includes(subItem?.id) &&
                selectedTeamMembers.push(subItem?.id);
              !selectedTeam.includes(item?.name) &&
                selectedTeam.push(item.name);
            }
          });
        }
      });
    updateSelectedUser(
      true,
      selectedTeamMembers,
      selectedTeam?.length > 0 ? selectedTeam : false,
      "team"
    );
  };

  return (
    <CustomMultiSelect
      options={teamMemberList}
      handleSelectActionCbk={handleSelectActionCbk}
      handleSubmenuSelectActionCbk={handleSubmenuSelectActionCbk}
      handleApplySelectionCbk={handleApplySelectionCbk}
      searchFilterCbk={searchFilterCbk}
      hasSubMenuAccordion={true}
      {...props}
    />
  );
});

export { FilterDropDownTeam };
export default FilterDropDownTeam;
