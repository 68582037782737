import React from "react";

const LogicalOperator = props => {
  const { value = "", change = () => {}, index = "" } = props || {};

  const operandList = ["AND"];

  const handleClick = operator => {
    if (value !== operator) {
      change(operator, index);
    }
  };

  return (
    <div className="logicalOperator">
      {operandList.map(item => {
        return (
          <div
            key={`${item?.toLowerCase()}Operator`}
            className={`operator ${value === item ? "active" : ""}`}
            onClick={() => (value === item ? handleClick(item) : () => {})}
          >
            <div className="text">{item}</div>
            {value === item && <div className="activeLine" />}
          </div>
        );
      })}
    </div>
  );
};

export default LogicalOperator;
export { LogicalOperator };
