import React, { useState } from "react";
import { makeApi, URLS } from "Utils/apiURL";
import calendarState from "Pages/Dashboard/settings/Calendar/State";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import VariantToggle from "Pages/Dashboard/sequences/SequencePage/VariantToggle";
import { ReactComponent as GoogleCalendarSvg } from "Assets/Settings/googleCalendar.svg";
import Button from "Components/common/Button";
import { toasterState } from "Components/common/toaster";

export const CalenderAccount = (props = {}) => {
  const {
    id: calendarId = "",
    loader = false,
    email = "",
    showBranding = false,
    handleToggleAction = () => {}
  } = props || {};

  const [disconnectloader, setDisconnectLoader] = useState(false);

  const brandingToggle = {
    label: `Remove Salesgear branding on your public calendar shown to the contacts.`,
    id: calendarId,
    action: handleToggleAction,
    isChecked: showBranding || false,
    loading: loader === "toggle"
  };

  const handleDisconnectCalender = () => {
    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to disconnect your calendar? You won't be able to schedule meetings via Salesgear anymore.`,
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      cancelCbk: () => confirmationPopupState.setShowPopup(false),
      callback: () => disconnectCalender()
    });
    confirmationPopupState.setShowPopup(true);
  };

  const disconnectCalender = async () => {
    confirmationPopupState.setShowPopup(false);
    setDisconnectLoader(true);
    let inboxId = calendarState?.connectedAccount?.inboxId || "";
    if (inboxId) {
      const config = {
        url: URLS?.deleteCalender || "",
        method: "POST",
        data: {
          inboxId
        }
      };
      let response = await makeApi(config);
      const { data = {} } = response || {};
      if (data && +data?.code === 200) {
        calendarState.setConnectedAccount("");
        toasterState.setToastMsg(
          "Calendar disconnected successfully.",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later.",
        "failure"
      );
    }
    setDisconnectLoader(false);
  };

  const buttonProps = {
    id: "disconnectCalender",
    name: "disconnectCalender",
    btnText: "Disconnect",
    type: "button",
    className: `disconnectCalender ${disconnectloader ? "loading" : ""}`,
    click: handleDisconnectCalender,
    isLeftSideIcon: true,
    iconName: "visibility_off",
    loader: disconnectloader
  };

  return (
    email && (
      <div className="accountConfigurationCont">
        <div className="connectEmailCont">
          <div className="connectedAccountEmail">
            <span className="calendarImg">
              <GoogleCalendarSvg />
            </span>
            <span className="text">Connected with</span>
            <span className="emailText">{email}</span>
          </div>
          <div className="disconnectBtnCont">
            <Button {...buttonProps} />
          </div>
        </div>
        <div className="brandLogoToggle">
          <VariantToggle {...brandingToggle} />
        </div>
      </div>
    )
  );
};

export default CalenderAccount;
