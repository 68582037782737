import React, { useEffect, useState } from "react";

const CountDownTimer = ({
  minutes = 0,
  seconds = 30,
  onCompleteCbk = () => {}
}) => {
  const [[mins, secs], setTime] = useState([minutes, seconds]);

  const counter = () => {
    if (mins === 0 && secs === 1) {
      onCompleteCbk(true);
    } else if (secs == 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  useEffect(() => {
    const interValId = setInterval(() => counter(), 1000);
    return () => clearInterval(interValId);
  });

  return (
    <div className="timerContainer">
      <span className="countDownTxt">{`${mins?.toString().padStart(2, "0")}
        :
        ${secs?.toString().padStart(2, "0")}`}</span>
    </div>
  );
};

export default CountDownTimer;
export { CountDownTimer };
