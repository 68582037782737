import React from "react";
import { addStagesPopupState } from "./AddStagesPopupState";

const StageStep = props => {
  const { popupValues = {} } = props || {};
  const {
    currentStep = 1,
    isFromTriggers = false,
    isTaskCreation = false,
    stageType = "manualEmail",
    isEdit = false
  } = popupValues;

  const text = isEdit ? "Edit" : "Add";

  const taskObject = {
    manualEmail: "Manual Email Task",
    linkedin: "Manual LinkedIn Task",
    linkedinAuto: "Auto LinkedIn Task",
    phone: "Phone Call Task",
    customTask: "Custom Task"
  };

  return (
    <div className="stepNo">
      {isFromTriggers || isTaskCreation
        ? `${isEdit ? "Edit" : "Create"} ${taskObject[stageType]}`
        : `${text} Step ${currentStep}`}
    </div>
  );
};

export default StageStep;
export { StageStep };
