import React, { useState } from "react";
import DropDownMenu from "Components/common/DropDownMenu";

const ReplyThreadDropdown = props => {
  const {
    handleChange = () => {},
    defaultValue = false,
    currentStep = "",
    isManualTask = false
  } = props || {};

  const [showDropdown, setDropdown] = useState(false);

  const handleAction = (id, item) => {
    setDropdown(false);
    handleChange(item);
  };

  const options = [
    {
      value: "Send as Reply",
      subKey: true,
      key: "sendAsReply",
      method: handleAction
    },
    {
      value: "Send as New Thread",
      subKey: false,
      key: "sendAsNewThread",
      method: handleAction
    }
  ];

  return (
    (options?.length && (
      <div
        className={`replyThreadDropdown ${isManualTask ? "isManualTask" : ""}`}
      >
        <>
          <label
            htmlFor="replyThread"
            onClick={() => setDropdown(!showDropdown)}
          >
            Type
          </label>
          <div
            className="activeDropdownValue"
            onClick={() => setDropdown(!showDropdown)}
          >
            <span className="activeValue">{defaultValue}</span>
            <i className="material-icons-outlined">expand_more</i>
          </div>
        </>
        {showDropdown && (
          <>
            <DropDownMenu options={options} referenceId="taskTypeDropdown" />
            <div className="dropdownMask" onClick={() => setDropdown(false)} />
          </>
        )}
      </div>
    )) ||
    ""
  );
};

export default ReplyThreadDropdown;
export { ReplyThreadDropdown };
