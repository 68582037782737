/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import UpgradeBadge from "Components/Paywall/UpgradeBadge";
import Utils from "Utils/utils";
import ButtonComponent from "Components/common/Button";
import { MP_EVENT } from "Static/constant";

export const UpgradeTooltip = props => {
  const {
    title = "",
    plan = "",
    subTitle = "",
    text = "",
    btnText = "",
    badgeSize = "md",
    color = "",
    history = "",
    alignTooltip = "",
    pageType = "",
    trigger = "",
    backgroundColor = "#262626",
    boxShadow = "",
    displayAsHtml = false,
    type = ""
  } = props || {};

  const redirectToPricing = () => {
    upgradePopupState.showPopup && upgradePopupState.setPopup(false);
    if (type === "chatWithUs") {
      Utils.mixpanelTrack(MP_EVENT.MUTLIPLE_INBOX_CHAT_US_CLICKED, {
        type: "Tooltip",
        pageType,
        trigger
      });
    } else {
      if (!Utils.isAdmin()) {
        Utils.showMessagePopup();
      } else {
        Utils.mixpanelTrack(MP_EVENT.UPGRADE_TOOLTIP_CLICKED, {
          type: "Tooltip",
          pageType,
          trigger
        });
        history && history.push("/pricing");
      }
    }
  };

  useEffect(() => {
    Utils.mixpanelTrack(MP_EVENT.UPGRADE_TOOLTIP_SHOWN, {
      type: "Tooltip",
      pageType,
      trigger
    });
  }, []);

  return (
    (plan && (
      <div className={`upgradeTooltipSection ${plan} ${alignTooltip}`}>
        <span className="pointer" style={{ backgroundColor, boxShadow }} />
        <div className="tooltipCard" style={{ backgroundColor, boxShadow }}>
          <div className="title">
            {type !== "chatWithUs" && (
              <UpgradeBadge plan={plan} badgeSize="md" />
            )}
            <h4 className={badgeSize} style={{ color }}>
              {title}
            </h4>
          </div>
          <h6 className="subTitle">{subTitle}</h6>
          {displayAsHtml ? (
            <p
              className="descText"
              dangerouslySetInnerHTML={{
                __html: text || ""
              }}
            />
          ) : (
            <p className="descText">{text}</p>
          )}
          <div>
            <ButtonComponent
              id={
                type === "chatWithUs" ? "my_custom_link" : "upgradeTooltipBtn"
              }
              name="upgradeTooltipBtn"
              btnText={btnText}
              className={`upgradeTooltipBtn ${
                type === "chatWithUs" ? "my_custom_link" : ""
              }`}
              type="button"
              click={redirectToPricing}
            />
          </div>
        </div>
      </div>
    )) ||
    ""
  );
};

export default withRouter(UpgradeTooltip);
