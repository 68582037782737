import React from "react";
import { observer } from "mobx-react";
import dialerStoreData from "Stores/DialerData";
import CallDataInsights from "Pages/Dashboard/Calls/CallDataInsights";
import CallsTeamSummary from "Pages/Dashboard/Calls/CallsTeamSummary";

export const CallsAnalytics = observer(() => {
  let stageActiveList = [...dialerStoreData?.stageList].filter(
    item => item?.displayEnabled
  );

  return (
    <div className="callsAnalytics">
      <div className="wrapCallsAnalyticsRow">
        <CallDataInsights stageActiveList={stageActiveList} />
        <CallsTeamSummary stageActiveList={stageActiveList} />
      </div>
    </div>
  );
});

export default CallsAnalytics;
