import React from "react";
import { useHistory } from "react-router-dom";
import noAccountIcon from "Assets/Settings/accountConnectInbox.png";
import Utils from "Utils/utils";
import { INBOX_ACCOUNT_LIST } from "Pages/Dashboard/settings/Calendar/Constants";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import connectInboxStore from "Pages/Dashboard/settings/ConnectInbox/store";
import { addDefaultInboxState } from "Pages/Dashboard/settings/ConnectInbox/AddDefaultInbox";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";

const EmptyPageWithNoInbox = observer(() => {
  let history = useHistory();

  const redirectToConnectInbox = (event = "") => {
    let inboxType = Utils.performActionByClosestDom(event, "img", "data-key");
    if (inboxType) {
      Utils.mixpanelTrack(MXP_EVENT.ST_CLR_CONFIG_CONNECT_ACCOUNT_CLICK, {
        pageType: `${PAGE_TYPE["setting"]} - Calender Configuration`,
        pageEvent: "Connect Account",
        inboxType
      });
      if (userDetail?.connectedInboxes?.length) {
        connectInboxStore.getInboxByType(inboxType);
        addDefaultInboxState.setInbox(inboxType);
        addDefaultInboxState.setShowPopup(true);
      } else {
        history.push("/settings/account");
      }
    }
  };

  return (
    <div className="emptyInbox">
      <img src={noAccountIcon} alt="No Account Found" />
      <h6>Connect a Google or Microsoft inbox to access calendar</h6>
      <div
        className="connectInboxButton"
        onClick={e => redirectToConnectInbox(e)}
      >
        {INBOX_ACCOUNT_LIST?.map(item => (
          <img {...item} key={item?.id} data-key={item?.inboxType} />
        ))}
      </div>
    </div>
  );
});

export default EmptyPageWithNoInbox;
