import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import Utils from "Utils/utils";
import DropdownMenu from "Components/common/DropDownMenu";
import ExportContactsToSalesforce from "Components/Salesforce/ExportContactsToSalesforce";
import { ReactComponent as ReactivateIcon } from "Assets/svg/reactivate.svg";
import { ReactComponent as ResumeIcon } from "Assets/svg/resume.svg";
import { ReactComponent as RemoveListIcon } from "Assets/svg/removeList.svg";
import { ReactComponent as LogCallIcon } from "Assets/svg/callLog.svg";
import { sequenceContactData } from "Stores/sequence/Contact";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { successPopupState } from "Components/common/SuccessPopup";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { bulkAddTagsPopupState } from "./BulkOperations/BulkAddTagsPopup";
import { bulkRemoveTagsPopupState } from "./BulkOperations/BulkRemoveTagsPopup";
import customListState from "Pages/Dashboard/dataSearch/CustomList/state";
import { updateContactOwnerState } from "Components/common/UpdateContactOwner";
import { dialerLogsPopupState } from "Components/DailerService/DialerLogsAfterCallEndPopup";

const ContactMoreOption = observer(props => {
  const {
    contactIds = [],
    stage = "",
    tableLoading = false,
    filterLoading = false,
    resetContactCbk = () => {},
    contactCount = 0,
    allContactSelected = false,
    checked = false,
    pageType = "",
    tagIds = [],
    sequenceId = "",
    searchEmail = "",
    selectedEntityCount = 0,
    contactData = [],
    addTagsCbk = () => {},
    removeTagsCbk = () => {},
    retryContactCbk = () => {},
    bulkRemoveSeqContactCbk = () => {},
    bulkReactivateSeqContactCbk = () => {},
    bulkResumeSeqContactCbk = () => {},
    performBulkOperationCbk = () => {},
    removeFromListCbk = () => {},
    updateContactOwnerCbk = () => {},
    selectedContactsNameList = [],
    sequenceToggleBtn = false
  } = props || {};

  const { salesforceIntegrationEnabled = false } =
    userDetail?.userFeatureFlag || {};
  const { hasTeamMember = false } = userDetail?.userInfo || {};

  const [showDropdown, setDropdown] = useState(false);
  const [showSfCard, setSfCard] = useState(false);

  const addToSalesforce = () => {
    setDropdown(false);
    if (!salesforceIntegrationEnabled) {
      upgradePopupState.setPopupType("sequenceContactsAddToSalesforce");
      upgradePopupState.setPopup(true);
    } else {
      setSfCard(true);
    }
  };

  const retryContacts = () => {
    setDropdown(false);
    retryContactCbk();
  };

  const bulkRemoveSeqContact = () => {
    setDropdown(false);
    bulkRemoveSeqContactCbk();
  };

  const bulkReactivateSeqContact = () => {
    setDropdown(false);
    bulkReactivateSeqContactCbk();
  };

  const bulkResumeSeqContact = () => {
    setDropdown(false);
    bulkResumeSeqContactCbk();
  };

  const addTags = () => {
    setDropdown(false);
    bulkAddTagsPopupState.setDefaultValues({
      cbk: addTagsCbk,
      contactsCount: allContactSelected ? contactCount : contactIds?.length,
      name:
        selectedContactsNameList?.length > 0 ? selectedContactsNameList[0] : ""
    });
    bulkAddTagsPopupState.setShowPopup(true);
  };

  const removeTags = () => {
    setDropdown(false);
    bulkRemoveTagsPopupState.setDefaultValues({
      cbk: removeTagsCbk,
      contactsCount: allContactSelected ? contactCount : contactIds?.length,
      name:
        selectedContactsNameList?.length > 0 ? selectedContactsNameList[0] : ""
    });
    bulkRemoveTagsPopupState.setShowPopup(true);
  };

  const removeFromList = () => {
    if (customListState?.activeList?.id) {
      setDropdown(false);
      let count = allContactSelected ? contactCount : contactIds?.length;
      let pluralText = count > 1 ? "s" : "";
      let articleText = count > 0 ? "these" : "this";
      confirmationPopupState.setPopupValues({
        content: `Are you sure you want to remove ${articleText} ${count} contact${pluralText} from the list? ${Utils.firstLetterCapitialize(
          articleText
        )} contact${pluralText} can still accessible from 'All Contacts' list.`,
        actionBtnText: "Yes",
        cancelBtnText: "Cancel",
        cancelCbk: () => confirmationPopupState.setShowPopup(false),
        callback: () => removeFromListCbk("REMOVE_FROM_LIST"),
        loadingMsg: "Please wait..."
      });
      confirmationPopupState.setShowPopup(true);
    }
  };

  const performBulkOperationAction = (event = "", item = {}) => {
    let actionTypePresentText =
      item?.key === "DELETE_CONTACT" ? "delete" : "opt out";
    setDropdown(false);
    let count = allContactSelected ? contactCount : contactIds?.length;
    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to ${actionTypePresentText} ${
        allContactSelected ? contactCount : contactIds?.length
      } contact${count > 1 ? "s" : ""}?`,
      actionBtnText: "Yes",
      cancelBtnText: "Cancel",
      cancelCbk: () => confirmationPopupState.setShowPopup(false),
      callback: () => performBulkOperationCbk(item?.key),
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const constructMarkAsCompletePayload = () => {
    const stageValue =
      stage && !["openCount", "clickCount"].includes(stage)
        ? stage.toUpperCase() === "TOTAL"
          ? null
          : stage.toUpperCase() === "ONGOING"
          ? "ACTIVE"
          : stage.toUpperCase()
        : null;
    const filterKeyValue =
      stage && ["openCount", "clickCount"].includes(stage)
        ? stage.toUpperCase() === "TOTAL"
          ? null
          : stage
        : null;
    const filters = { all: false };
    if (allContactSelected) {
      stageValue && (filters.statuses = [stageValue]);
      filterKeyValue && (filters.filterKey = filterKeyValue);
    }
    tagIds?.length && (filters.contactTags = tagIds);
    const payload = {
      sequenceId
    };
    Object.keys(filters).length && (payload.filters = filters);
    !allContactSelected && (payload.contactIds = contactIds);
    return payload;
  };

  const confirmationPopupForMarkAsComplete = (
    count,
    payload,
    handleResponseCbk
  ) => {
    confirmationPopupState.setPopupValues({
      content: `Are you sure want to mark ${count} contact${
        count > 1 ? "s" : ""
      } as complete. All the remaining task for ${
        count > 1 ? "these" : "this"
      } contact${count > 1 ? "s" : ""} will be skipped`,
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      cancelCbk: () => confirmationPopupState.setShowPopup(false),
      callback: () =>
        sequenceContactData.sequenceMarkAsComplete(payload, handleResponseCbk),
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const handleResponseCbk = res => {
    const count = allContactSelected ? contactCount : contactIds?.length;
    if (res?.data?.status === 200) {
      const { totalContacts = 0, completedContactIds = [] } = res.data;
      resetContactCbk(completedContactIds);
      confirmationPopupState.setShowPopup(false);
      successPopupState.setPopupValues({
        type:
          totalContacts > 0 ? "successMarkAsComplete" : "failedMarkAsComplete",
        title: "Mark as Complete",
        text:
          totalContacts > 0
            ? `Successfully marked ${totalContacts} out of ${count} contact${
                count > 1 ? "s" : ""
              } as Complete`
            : "",
        failedText:
          count - totalContacts > 0
            ? `Cannot mark remaining ${count - totalContacts} contact${
                count - totalContacts > 1 ? "s" : ""
              } 
      <br />to Complete state`
            : ""
      });
      successPopupState.setPopup(true);
    } else {
      confirmationPopupState.setShowPopup(false);
      successPopupState.setPopupValues({
        type: "failedMarkAsComplete",
        title: "Mark as Complete",
        text: `Unable to mark the selected ${count} contact${
          count > 1 ? "s" : ""
        } as complete`,
        failedText: `Oops! something went wrong, please drop us an email at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> to get this resolved`
      });
      successPopupState.setPopup(true);
    }
  };

  const markAsCompleteAction = () => {
    setDropdown(false);
    const payload = constructMarkAsCompletePayload();
    const count = allContactSelected ? contactCount : contactIds?.length;
    confirmationPopupForMarkAsComplete(count, payload, handleResponseCbk);
  };

  const openAssignContactOwnerPopup = () => {
    setDropdown(false);
    const handleCallback = (selectedOwnerId = "") => {
      updateContactOwnerCbk(selectedOwnerId, "bulk");
    };
    updateContactOwnerState.setTotalCount(
      allContactSelected ? contactCount : contactIds?.length
    );
    updateContactOwnerState.setCallback(handleCallback);
    updateContactOwnerState.setShowPopup(true);
  };

  const getSingleContactSelectedList = (id = "") => {
    return (
      (id && [...contactData?.contacts].find(item => item?.id === id)) || {}
    );
  };

  const performBulkLogAction = () => {
    setDropdown(false);
    let count = allContactSelected ? contactCount : selectedEntityCount || 0;
    if (count > 0) {
      if (count === 1 && contactIds?.length === 1) {
        let contact = getSingleContactSelectedList(contactIds?.[0]);
        dialerLogsPopupState.setContactData(contact);
      } else {
        dialerLogsPopupState.setTotalContacts(count);
      }
      dialerLogsPopupState.setActionType("BULK_CONTACTS");
      dialerLogsPopupState.setShowLogCallPopup(true);
    }
  };

  const sequencesDropdownOptions = [
    // {
    //   icon: { ele: <SfIcon />, style: { width: "20px", height: "16px" } },
    //   value: "Add to Salesforce",
    //   key: "addToSalesforce",
    //   method: addToSalesforce,
    //   showUpgrade: !crmSyncEnabled,
    //   type: "sequenceContactsAddToSalesforce",
    //   plan: "proPlan"
    // },
    {
      icon: {
        ele: '<span class="failedIcon material-icons-outlined">refresh</span>',
        style: { width: "20px", height: "16px" }
      },
      value: "Retry",
      key: "retryContacts",
      method: retryContacts,
      type: "seqContactRetryBulk"
    },
    // {
    //   icon: {
    //     ele:
    //       '<i style="font-size: 16px; height: unset;" class="material-icons-outlined list-icon">check_circle_outline</i>',
    //     style: { width: "20px", height: "15px", background: "transparent" }
    //   },
    //   value: "Mark as Complete",
    //   key: "markAsComplete",
    //   method: markAsCompleteAction,
    //   pageType: "sequence"
    // },
    {
      icon: {
        ele: '<span style="font-size: 20px;"  class="material-icons-outlined removeBulkSeqContact">close</span>',
        style: { width: "20px", height: "16px" }
      },
      value: "Remove",
      key: "removeBulkSeqContact",
      method: bulkRemoveSeqContact,
      type: "removeBulkSeqContact"
    },
    {
      icon: {
        ele: <ReactivateIcon className="reactivateBulkSeqContact" />
      },
      value: "Reactivate",
      key: "reactivateBulkSeqContact",
      method: bulkReactivateSeqContact,
      type: "reactivateBulkSeqContact"
    },
    {
      icon: {
        ele: <ResumeIcon className="resumeBulkSeqContact" />
      },
      value: "Resume",
      key: "resumeBulkSeqContact",
      method: bulkResumeSeqContact,
      type: "resumeBulkSeqContact",
      disabled: !sequenceToggleBtn
    }
  ];

  const updateOwnerOpt = {
    icon: {
      ele: '<i style="font-size: 15px" class="material-icons-outlined">person_pin</i>',
      style: { width: "20px", height: "15px" }
    },
    value: "Update Owner",
    key: "updateOwner",
    method: openAssignContactOwnerPopup
  };

  const contactsDropdownOptions = [
    // {
    //   icon: { ele: <SfIcon />, style: { width: "20px", height: "16px" } },
    //   value: "Add to Salesforce",
    //   key: "addToSalesforce",
    //   method: addToSalesforce,
    //   showUpgrade: !crmSyncEnabled,
    //   type: "contactsAddToSalesforce",
    //   plan: "proPlan"
    // },
    {
      icon: {
        ele: '<span class="material-icons-outlined addTagIcon">local_offer</span>',
        style: { width: "20px", height: "16px" }
      },
      value: "Add Tags",
      key: "addTags",
      method: addTags,
      type: "addNewTags"
    },
    {
      icon: {
        ele: '<div class="removeTagIconWrap"><span class="material-icons-outlined closeIcon">close</span><span class="material-icons-outlined removeTagIcon">local_offer</span></div>',
        style: { width: "20px", height: "16px" }
      },
      value: "Remove Tags",
      key: "removeTags",
      method: removeTags,
      type: "removeTags"
    },
    {
      icon: {
        ele: <RemoveListIcon />,
        style: { width: "20px", height: "16px" }
      },
      value: "Remove From List",
      key: "removeFromList",
      method: removeFromList,
      type: "removeFromList",
      disabled: !customListState?.activeList?.id
    },
    {
      icon: {
        ele: <LogCallIcon />,
        style: { width: "20px", height: "16px" }
      },
      value: "Log calls",
      key: "logCallBulk",
      method: performBulkLogAction,
      type: "bulkLogContacts"
    },
    {
      icon: {
        ele: '<i style="font-size: 15px; background: transparent;" class="material-icons-outlined">block</i>',
        style: { width: "20px", height: "16px" }
      },
      value: "Opt out",
      key: "OPT_OUT",
      method: performBulkOperationAction,
      type: "optOut",
      disabled: ["BOUNCED", "OPTED_OUT"].includes(stage?.toUpperCase() || "")
    },
    {
      icon: {
        ele: '<span  style="font-size: 16px; background: transparent;" class="material-icons-outlined deleteContact">delete</span>',
        style: { width: "20px", height: "16px" }
      },
      value: "Delete",
      key: "DELETE_CONTACT",
      method: performBulkOperationAction,
      type: "deleteContacts"
    }
  ];

  const getDropdownOptions = () => {
    if (pageType === "sequence") {
      return sequencesDropdownOptions;
    } else {
      return hasTeamMember
        ? [updateOwnerOpt, ...contactsDropdownOptions]
        : contactsDropdownOptions;
    }
  };

  const expandMenu = async () => {
    checked &&
      !tableLoading &&
      !filterLoading &&
      (await checkTeamSubscriptionActive()) &&
      setDropdown(true);
  };

  useEffect(() => {
    return () => {
      setDropdown(false);
      setSfCard(false);
    };
  }, []);

  return (
    <div className={`moreOptionBtn`}>
      <i
        className={`material-icons  ${contactIds?.length ? "enabled" : ""}`}
        onClick={expandMenu}
      >
        more_vert
      </i>
      {showDropdown && (
        <>
          <DropdownMenu options={getDropdownOptions()} />
          <div
            className="moreOptionOverlay"
            onClick={event => {
              event.preventDefault();
              setDropdown(false);
            }}
          />
        </>
      )}
      <ExportContactsToSalesforce
        showSfCard={showSfCard}
        contactIds={contactIds}
        stage={stage?.toUpperCase() || ""}
        tableLoading={tableLoading}
        filterLoading={filterLoading}
        resetContactCbk={resetContactCbk}
        contactCount={contactCount}
        allContactSelected={allContactSelected}
        checked={checked}
        setSfCard={setSfCard}
        searchEmail={searchEmail}
      />
    </div>
  );
});

export default ContactMoreOption;
export { ContactMoreOption };
