import React from "react";
import Utils from "Utils/utils";
import { INSTALL_PLUGIN_URL } from "Static/constant";
import { confirmationPopupState } from "Components/common/confirmationPopup";

const pluginNotInstalled = () => {
  const ShowInstallExtension = () => {
    return (
      <div className="installExtensionWrapper">
        <div className="headingTitle">Almost there!</div>
        <div className="notification">
          Install the Salesgear Chrome extension to connect your LinkedIn
          account. This will only take a moment.
        </div>
      </div>
    );
  };

  const installExtension = () => {
    confirmationPopupState.setPopupValues({
      content: <ShowInstallExtension />,
      actionBtnText: "Install Extension",
      dynamicDom: true,
      callback: () => {
        Utils.windowRedirect(INSTALL_PLUGIN_URL);
        confirmationPopupState.setShowPopup(false);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  return installExtension();
};

export { pluginNotInstalled };
export default pluginNotInstalled;
