import { action, observable } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import { popupLoaderState } from "../../../dataSearch/PopupLoader";
import { createTeamPopupState } from "./CreateTeamPopup";
import { toasterState } from "Components/common/toaster";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { MXP_EVENT } from "Static/MixPanelEvents";
import Utils from "Utils/utils";

class TeamState {
  @observable teamData = {};
  @observable teamNameError = "";
  @observable tableLoading = true;
  @observable showTeamMemberPage = false;
  @observable selectedGroup = "";
  @observable editTeamNameIndex = "";
  @observable updatedTeamName = "";
  @observable editTeamLoader = false;
  @observable singleLineLoader = false;

  @action
  setSingleLineLoader = val => {
    this.singleLineLoader = val;
  };

  @action
  setTeamNameError = val => {
    this.teamNameError = val;
  };

  @action
  setEditTeamLoader = val => {
    this.editTeamLoader = val;
  };

  @action
  setUpdatedTeamName = val => {
    this.updatedTeamName = val;
  };

  @action
  setTableLoading = val => {
    this.tableLoading = val;
  };

  @action
  setSelectedGroup = val => {
    this.selectedGroup = val;
  };

  @action
  setShowTeamMemberPage = val => {
    this.showTeamMemberPage = val;
  };

  @action
  setEditTeamNameIndex = val => {
    this.editTeamNameIndex = val;
  };

  @action
  resetLoaderToInitialState = () => {
    this.setTableLoading(false);
    this.setEditTeamLoader(false);
    this.setSingleLineLoader(false);
  };

  @action
  createTeam = async () => {
    const config = {
      url: URLS.createTeam,
      method: "POST",
      data: {
        groupName: createTeamPopupState?.teamName
      }
    };
    const res = await makeApi(config);
    if (res?.data) {
      createTeamPopupState.setButtonLoader(false);
      const { code = "" } = res?.data || {};
      if (+code === 200) {
        createTeamPopupState.setTeamName("");
        Utils.mixpanelTrack(MXP_EVENT.TEAM_CREATED, {
          pageType: "users & teams",
          teamName: createTeamPopupState?.teamName
        });
        toasterState.setToastMsg(`Team created successfully`, "success");
        popupLoaderState.setData({
          message: "Creating team. Please wait...",
          type: "addContacts"
        });
        popupLoaderState.setShowPopup(true);
        this.getTeamData();
        createTeamPopupState.setShowPopup(false);
        popupLoaderState.setShowPopup(false);
      } else if (+code === 409) {
        this.setTeamNameError("Team Name already exists", "failure");
      } else if (+code === 401) {
        this.setTeamNameError(
          "Action cannot be performed by this member",
          "failure"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
    }
    this.resetLoaderToInitialState();
  };

  @action
  getTeamData = async (resetState = false) => {
    const config = {
      url: URLS.getTeamGroups,
      method: "POST",
      data: {
        pageNo: 1,
        limit: 500
      }
    };
    const res = await makeApi(config);
    if (res?.data) {
      this.teamData = res?.data;
      resetState && this.resetLoaderToInitialState();
      return res?.data?.groupList?.length > 0;
    }
    return false;
  };

  @action
  deleteTeam = async (groupId, groupName) => {
    const config = {
      url: URLS.deleteGroup,
      method: "DELETE",
      data: {
        groupId
      }
    };
    const res = await makeApi(config);
    if (res?.data) {
      this.setTableLoading(true);
      const { code = "" } = res?.data || {};
      if (+code === 200) {
        this.getTeamData();
        Utils.mixpanelTrack(MXP_EVENT.TEAM_DELETED, {
          pageType: "users & teams",
          teamName: groupName
        });
        confirmationPopupState.setShowPopup(false);
        toasterState.setToastMsg(
          "Team has been deleted successfully",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later."
        );
      }
    }
    this.resetLoaderToInitialState();
  };

  updateTeamName = (groupId = "", obj = {}) => {
    let tempGroupList = [...this.teamData.groupList];
    tempGroupList = tempGroupList.map((item, index) => {
      if (item?.id === groupId) {
        item.name = obj?.name || "";
      }
      return item;
    });
    this.teamData.groupList = tempGroupList;
  };

  @action
  editTeamName = async (groupId, groupName) => {
    const config = {
      url: URLS.editTeamName,
      method: "POST",
      data: {
        groupId,
        groupName
      }
    };

    let res = await makeApi(config);
    if (res?.data) {
      this.setEditTeamNameIndex("");
      const { code = "" } = res?.data || {};
      if (+code === 200 && res?.data?.groupList?.[0]) {
        this.updateTeamName(groupId, res?.data?.groupList?.[0]);
        toasterState.setToastMsg("Team Name updated successfully");
      } else if (+code === 409) {
        toasterState.setToastMsg("Team Name already exists", "failure");
      } else if (+code === 401) {
        toasterState.setToastMsg(
          "Action cannot be performed by this member",
          "failure"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
    }
    this.resetLoaderToInitialState();
  };
}

const teamState = new TeamState();

export { teamState };
export default teamState;
