import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import SearchInputBox from "Components/common/SearchInputBox";
import filterComponentState from "./filterComponentState";
import CustomDropdown from "../../settings/Triggers/AddEditTrigger/CustomDropdown";

const CustomAutoCompleteDropdown = observer(props => {
  const searchBoxRef = useRef(null);
  const selectBoxRef = useRef(null);
  const {
    showSearchPanel = true,
    optionList = [],
    placeHolderLabel = "",
    id = "",
    emptyText = ""
  } = props || {};
  const [showDropdown, setShowDropdown] = useState(false);
  const [clearSearchInput, setClearSearchInput] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState(optionList);

  const searchvalue = value => {
    setShowDropdown(true);
    setClearSearchInput(false);
    const lowercasedValue = value?.toLowerCase() || "";
    if (!lowercasedValue) {
      setUpdatedOptions(optionList);
    } else {
      const filteredData =
        optionList?.length > 0 &&
        optionList?.filter(item => {
          const finalValue = id === "tagIds" ? item?.value : item?.name;
          return finalValue
            ?.toString()
            ?.toLowerCase()
            ?.includes(lowercasedValue);
        });
      setUpdatedOptions(filteredData);
    }
  };

  const filterCbk = item => {
    setShowDropdown(false);
    setClearSearchInput(true);
    let temp = [];
    if (filterComponentState.selectedFilteredList?.[id]) {
      temp = [...filterComponentState.selectedFilteredList[id], item];
    } else {
      temp = [item];
    }
    const updatedFilteredList = temp
      ?.map(item => {
        return item?.id;
      })
      ?.filter(Boolean);
    filterComponentState.setFilterComponentData({
      ...filterComponentState.filterComponentData,
      [id]: updatedFilteredList
    });
    filterComponentState.setSelectedFilteredList({
      ...filterComponentState.selectedFilteredList,
      [id]: temp
    });
  };

  const handleScrollEnd = () => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      clientHeight = 0
    } = selectBoxRef?.current;
    if (
      scrollHeight - scrollTop - 10 < clientHeight &&
      filterComponentState.sequenceCurrentPageNo <
        filterComponentState.sequenceTotalPages
    ) {
      const newPageNumber = filterComponentState.sequenceCurrentPageNo + 1;
      filterComponentState.setSequenceCurrentPageNo(newPageNumber);
    }
  };

  useEffect(() => {
    setUpdatedOptions(optionList);
  }, [optionList]);

  return (
    <>
      <span className="horizontalBorderLine" />
      <div className="autoCompleteDropdownListWrapper customAutoCompleteDropdownWrapper">
        {showSearchPanel && (
          <div className="searchInputBoxWrap">
            <SearchInputBox
              valueCbk={searchvalue}
              type="text"
              placeholder={placeHolderLabel}
              searchBoxRef={searchBoxRef}
              clearSearchInput={clearSearchInput}
              hasSearchIcon={false}
              hasCloseIcon={false}
              disabled={
                filterComponentState.filterComponentData[id]?.length >= 5
              }
              setShowTagList={setShowDropdown}
              hideFocus={true}
            />
          </div>
        )}
        {showDropdown && (
          <>
            <div className="dropdownListWrapper">
              <CustomDropdown
                options={updatedOptions}
                isCustomValue={true}
                customCbk={filterCbk}
                refValue={selectBoxRef}
                label=""
                handleScrollEnd={handleScrollEnd}
                {...props}
                emptyText={emptyText}
                showDropdown={true}
                hasValue={id === "tagIds"}
                hasFolderBadge={true}
                disableOptions={filterComponentState.filterComponentData[id]}
                isFromPageName={"tasks"}
              />
              <div
                className="dropdownOverlay"
                onClick={() => {
                  setShowDropdown(false);
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
});

export { CustomAutoCompleteDropdown };
export default CustomAutoCompleteDropdown;
