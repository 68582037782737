import { tokenElementList, tokenVariableRegex } from "Static/constant";

const TokenUtils = {};

const getUpdatedTokenElementList = (customFieldsValue = {}) => {
  return [
    `{{customVariables}}`,
    ...Array.from({ length: 30 }, (value, index) => {
      const customVal =
        customFieldsValue[`customField${index + 1}`] ||
        `customField${index + 1}`;
      return `{{${customVal}}}`;
    })
  ];
};

TokenUtils.unresolvedTokenHighLight = (str = "", customFieldsValue = {}) => {
  if (str) {
    const unresolvedTokenList = str?.match(tokenVariableRegex) || [];
    const updatedTokenElementList = [
      ...tokenElementList,
      ...getUpdatedTokenElementList(customFieldsValue)
    ];
    unresolvedTokenList?.length &&
      unresolvedTokenList.map(item => {
        str =
          (item &&
            !updatedTokenElementList?.includes(item) &&
            str?.replace(
              item,
              `<span class="unresolvedTokenHighLight">${item}</span>`
            )) ||
          str;
      });
  }
  return str;
};

TokenUtils.hasInvalidTokens = (str = "", customFieldsValue = {}) => {
  let hasInvalidTokens = false;
  const unresolvedTokenList = str?.match(tokenVariableRegex) || [];
  let invalidTokenList = [];
  const updatedTokenElementList = [
    ...tokenElementList,
    ...getUpdatedTokenElementList(customFieldsValue)
  ];

  unresolvedTokenList?.length &&
    unresolvedTokenList.map(item => {
      if (item && !updatedTokenElementList?.includes(item?.trim())) {
        invalidTokenList.push(item);
        hasInvalidTokens = true;
        return true;
      }
    });
  return { invalidTokenList, hasInvalidTokens };
};

TokenUtils.getMissingTokenList = (missingTokens = []) => {
  if (missingTokens?.length) {
    const newLiStr = missingTokens.reduce(
      (acc, value) => acc + `<li key=${value.split(" ").join()}>${value}</li>`,
      ""
    );
    return `
      <ol class="missingTokenPromptList">
        ${newLiStr}
      </ol>`;
  }
  return "";
};

export default TokenUtils;
export { TokenUtils };
