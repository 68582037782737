import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import { tagsStoreData } from "Stores/TagsData";
import { ContactTags } from "Components/common/contact/ContactTags";

const AddTags = observer(props => {
  const {
    config = {},
    selectedTags = [],
    setSelectedTags = () => {},
    dropdownWidth = "",
    type = ""
  } = props;
  const [tagList, setTagList] = useState(null);
  const [showTagDropDown, setShowTagDropDown] = useState(false);

  const addSelectedTagsInTagList = (tagList, existingTags) => {
    tagList?.length > 0 &&
      tagList.map(obj => {
        const { value } = obj;
        obj.checked = value && existingTags?.includes(value);
        return obj;
      });

    setTagList(tagList);
  };

  const updateSelectedTags = updatedTagList => {
    const tempList = updatedTagList?.length > 0 ? [...updatedTagList] : [];
    setTagList([]);
    setSelectedTags(config?.tags);
    addSelectedTagsInTagList(tempList, config?.tags);
  };

  useEffect(() => {
    updateSelectedTags(tagList);
  }, [config.tags]);

  useEffect(() => {
    tagsStoreData.tagsData &&
      addSelectedTagsInTagList(
        Utils.createObjWithoutRef(tagsStoreData?.tagsData),
        null
      );
    config.tags && updateSelectedTags(tagsStoreData?.tagsData);
  }, [tagsStoreData.tagsData]);

  const resetTagSelected = () => {
    const tempArr = [...tagsStoreData.tagsData];
    tempArr.map(item => {
      delete item.checked;
    });
    tagsStoreData.setTagsData(tempArr);
  };

  useEffect(() => {
    return () => {
      resetTagSelected();
      setTagList([]);
    };
  }, []);

  return (
    <div className="addTagsWrapper sectionMarginBottom">
      <div className="flexColumn">
        <span className="headingText">Add Tags</span>
        <span className="headingSubText">
          {`Add tags to all imported ${
            type === "LEADS" ? "leads" : "contacts"
          } from CRM`}
        </span>
      </div>
      <ContactTags
        tagsList={tagList}
        setTagList={setTagList}
        showTagDropDown={showTagDropDown}
        setShowTagDropDown={setShowTagDropDown}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        hasTitle={false}
        style={{ gridTemplateColumns: `${dropdownWidth}px auto` }}
      />
      {showTagDropDown && (
        <div
          className="transparentOverlay"
          onClick={e => setShowTagDropDown(false)}
        ></div>
      )}
    </div>
  );
});

export { AddTags };
export default AddTags;
