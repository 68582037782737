/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ButtonComponent from "Components/common/Button";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import DropdownMenuComponent from "Components/common/DropDownMenu";
import InputTextBox from "Components/common/InputTextBox";
import TeamStore from "Stores/Team";
import Utils from "Utils/utils";

const DropDownElement = props => {
  let { name, err, readOnly, cbk = () => {}, className = "" } = props;

  const selectedRole = (id, value) => {
      setSelectedItem(value);
      cbk("role", value.key);
      setRoleDropdown(false);
    },
    dropdownOptions = [
      {
        value: "Administrator",
        key: "ADMIN",
        method: selectedRole,
        referenceId: "admin"
      },
      {
        value: "User",
        key: "USER",
        method: selectedRole,
        referenceId: "user"
      }
    ];

  const getSelectValue = name => {
    if (name === "Select Role") {
      setSelectedItem("");
    } else {
      dropdownOptions.filter(opt => {
        if (name === opt.key) {
          setSelectedItem(opt);
        }
      });
    }
  };

  const [selectedItem, setSelectedItem] = useState(""),
    [showRoleDropdown, setRoleDropdown] = useState(false);

  const showRoleSelectOption = () => {
    if (!readOnly) setRoleDropdown(true);
  };

  const closeRoleSelectOption = () => {
    setRoleDropdown(false);
  };

  useEffect(() => {
    getSelectValue(name);
  }, []);

  return (
    <div className="selectDropdown">
      <label htmlFor="role" className="labelName">
        Role
        <span className="required">*</span>
      </label>
      <div
        className={`selectBox ${showRoleDropdown ? "activeBorder" : ""}`}
        id="role"
        onClick={showRoleSelectOption}
      >
        <span className="value">
          {selectedItem && selectedItem.value !== ""
            ? selectedItem.value
            : "Select Role"}
        </span>
        {!readOnly && <i className="material-icons">keyboard_arrow_down</i>}
      </div>
      <span className="errMsgText">{err}</span>
      {showRoleDropdown && (
        <>
          <DropdownMenuComponent
            options={dropdownOptions}
            referenceId=""
            direction="center"
          />
          <div
            className="dropdownMask"
            onClick={e => closeRoleSelectOption(e, false)}
          />
        </>
      )}
    </div>
  );
};

const CreateUpdateMemberPopup = props => {
  const userData = userDetail.userFeatureFlag;
  const { value, name, userType, cbk } = props;
  const [inputVal, setInputVal] = useState(value);
  const [errMsgFn, setErrMsgFn] = useState("");
  const [errMsgLn, setErrMsgLn] = useState("");
  const [errMsgEmail, setErrMsgEmail] = useState("");
  const [errMsgJobTitle, setErrMsgJobTitle] = useState("");
  const [errMsgRole, setErrMsgRole] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [errUserField, setErrUserField] = useState([]);

  const validateFormField = async () => {
    let errField = [];
    const { email = "", role = "" } = inputVal || {};
    if (!Utils.emailValidate(email?.trim())) {
      setErrMsgEmail("Please enter a valid email address");
      errField.push("email");
    }
    if (!Utils.CheckElemInArray(role?.toUpperCase(), ["ADMIN", "USER"])) {
      setErrMsgRole("Please select a role");
      errField.push("role");
    }
    setErrUserField(errField);
    return errField?.length || 0;
  };

  const addUser = async () => {
    setBtnLoader(true);
    const closeCallback = (value, field) => {
      if (field === "userAdded") {
        userData.noOfSeatsUsed += 1;
        userDetail.setUserFeatureFlag(userData);
      }
      if (field === "sameTeam" || field === "differentTeam") {
        setErrUserField(["email"]);
        updateEmailError(field);
      } else if (value) {
        setBtnLoader(false);
        cbk(false);
      } else {
        setBtnLoader(false);
      }
    };
    let errCount = await validateFormField();
    if (errCount < 1) {
      TeamStore.addUser(inputVal, closeCallback);
    } else {
      setBtnLoader(false);
    }
  };

  const editUser = async () => {
    setBtnLoader(true);
    const closeCallback = () => {
      setBtnLoader(false);
      cbk(false);
    };
    let errCount = await validateFormField();
    if (errCount < 1) {
      TeamStore.editUser(inputVal, closeCallback);
    } else {
      setBtnLoader(false);
    }
  };

  const updateErrMsgField = id => {
    if (errUserField.length > 0 && errUserField.indexOf(id) !== -1) {
      errUserField.filter(item => item !== id);
      switch (id) {
        case "firstName":
          setErrMsgFn("");
          break;
        case "lastName":
          setErrMsgLn("");
          break;
        case "email":
          setErrMsgEmail("");
          break;
        case "jobTitle":
          setErrMsgJobTitle("");
          break;
        case "role":
          setErrMsgRole("");
          break;
        default:
          break;
      }
    }
  };

  const checkAndUpdateEditField = field => {
    if (name === "Edit") {
      switch (field) {
        case "email":
          return userType ? userType : true;
        case "role":
          return userType;
        default:
          return false;
      }
    }
    return false;
  };

  const triggerBtnEvent = () => {
    if (name === "Add") {
      addUser();
    } else {
      editUser();
    }
  };

  const createUser = event => {
    if (event.keyCode === 13) {
      triggerBtnEvent();
    }
  };

  const changeInputVal = (id, data) => {
    const stateObj = { ...inputVal };
    stateObj[id] = id === "email" ? data.trim() : data;
    updateErrMsgField(id);
    setInputVal(stateObj);
  };

  const updateEmailError = key => {
    switch (key) {
      case "sameTeam":
        setErrMsgEmail(
          `<i class="material-icons-outlined" style="font-size: 12px; color: red;">info</i>User is already part of your team plan`
        );
        break;
      case "differentTeam":
        setErrMsgEmail(
          `<i class="material-icons-outlined" style="font-size: 12px; color: red;">info</i> This user is part of a different team plan`
        );
        break;
      default:
        break;
    }
    setBtnLoader(false);
  };

  return (
    <div className="popupSection" style={{ zIndex: 12 }}>
      <div className="popupContainer animated customzoomIn">
        <div className="popupWrap">
          <div className="popupHeader">
            <h6>{name} User</h6>
            <i className="material-icons" onClick={cbk}>
              close
            </i>
          </div>
          <hr className="seperator" />
          <div className="popupBody">
            <div className="formInputGroup">
              <InputTextBox
                name="firstName"
                id="firstName"
                text="First Name"
                type="text"
                value={inputVal.firstName}
                change={changeInputVal}
                err={errMsgFn}
                cbk={createUser}
                readOnly={checkAndUpdateEditField("firstName")}
              />
              <InputTextBox
                name="lastName"
                id="lastName"
                text="Last Name"
                type="text"
                value={inputVal.lastName}
                change={changeInputVal}
                err={errMsgLn}
                cbk={createUser}
                readOnly={checkAndUpdateEditField("lastName")}
              />
            </div>
            <InputTextBox
              name="emailAddress"
              id="email"
              text="Email Address"
              type="text"
              mandatory={true}
              value={inputVal.email}
              change={changeInputVal}
              err={errMsgEmail}
              cbk={createUser}
              readOnly={checkAndUpdateEditField("email")}
            />
            <InputTextBox
              name="jobTitle"
              id="jobTitle"
              text="Job Title"
              type="text"
              value={inputVal.jobTitle}
              change={changeInputVal}
              err={errMsgJobTitle}
              cbk={createUser}
              readOnly={checkAndUpdateEditField("jobTitle")}
            />
            <DropDownElement
              err={errMsgRole}
              name={value.role}
              cbk={changeInputVal}
              readOnly={checkAndUpdateEditField("role")}
            />
          </div>
          <div className="popupFooter">
            <ButtonComponent
              id="addUser"
              name="addUser"
              type="button"
              className={btnLoader ? "loading" : ""}
              loader={btnLoader}
              btnText={`${name === "Add" ? name : "Update"} User`}
              icon={false}
              iconName="keyboard_arrow_down"
              click={triggerBtnEvent}
            />
          </div>
        </div>
      </div>
      <div className="common-popup-mask" />
    </div>
  );
};

export default observer(CreateUpdateMemberPopup);
