/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { ReactComponent as Downarrow } from "Assets/svg/donw-arrow.svg";
import CustomTooltip from "../CustomTooltip";
import Utils from "Utils/utils";

const FieldMappingDropDown = props => {
  const { data, updateMapping, updateShowOptions, optionsList } = props;

  return (
    <div className="fieldMappingDropDown">
      <div className="selectedName"></div>
      <div className="listCont">
        {data?.length > 0 &&
          data.map((value, index) => {
            return (
              <CSVMappingDropdown
                {...value}
                key={`fieldMappingDropDown ${value.systemMappedField}`}
                updateMapping={updateMapping}
                updateShowOptions={updateShowOptions}
                rowNo={index}
                optionsList={optionsList}
              />
            );
          })}
      </div>
    </div>
  );
};

const CSVMappingDropdown = props => {
  const [showToolTip, setShowTooltip] = useState(false);

  const {
    systemMappedField,
    mappedLabel,
    showOptions,
    updateMapping,
    updateShowOptions,
    invalidMap,
    rowNo,
    optionsList
  } = props;

  const replacesSelectedFieldId = systemMappedField
    ?.toLowerCase()
    ?.split(" ")
    ?.join("");

  return (
    <>
      <div
        className={`dropDownParent columnData ${
          rowNo > 2 ? "dropDownUpPosition" : ""
        }`}
      >
        <div
          id={replacesSelectedFieldId}
          className={`selectedField ${invalidMap ? `invalid` : ""}`}
          onClick={e => updateShowOptions(systemMappedField, true)}
        >
          <span>{mappedLabel} </span>
          <span className="downArrowBlack">
            <Downarrow />
          </span>
        </div>
        {invalidMap && (
          <span
            className="invalidMap"
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <i className="material-icons-outlined">info</i>
            {showToolTip && (
              <CustomTooltip
                text={"This field is already mapped with other one"}
              />
            )}
          </span>
        )}
        {showOptions && (
          <div
            className="drop-down-menu animatedFast"
            id={replacesSelectedFieldId}
          >
            <ul className="dropdownList">
              {optionsList &&
                optionsList.map(value => {
                  const { key, label } = value;
                  return (
                    <li
                      className="csv_dropdown"
                      key={`csv_dropdown${key + Utils.generateRandomNumber()}`}
                      onClick={e =>
                        updateMapping(e, systemMappedField, key, label)
                      }
                    >
                      {label}
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
      {showOptions && (
        <div
          className="dropdown-overlay"
          onClick={e => updateShowOptions(systemMappedField, false)}
        />
      )}
    </>
  );
};

export default FieldMappingDropDown;
export { FieldMappingDropDown };
