import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import scheduleDataStore from "Stores/ScheduleData";
import { weekDays } from "Static/constant";
import Utils from "Utils/utils";
import AutoCompleteDropdown from "Components/common/AutoCompleteDropdown";
import { initialSchedulePopupState } from "./InitialScheduleInfoPopup";

const CreateNewSchedule = () => {
  const history = useHistory();

  const handleScheduleRedirection = () => {
    initialSchedulePopupState.setShowPopup(false);
    history.push("/settings/schedules");
  };

  return (
    <div className="createNewScheduleBtn" onClick={handleScheduleRedirection}>
      <i className="material-icons">add</i>
      <span className="btnText">Create new schedule</span>
    </div>
  );
};

const InitialSchedule = (props = {}) => {
  const { scheduleWindowId = "" } = props || {};

  const [scheduleId, setScheduleId] = useState(scheduleWindowId);
  const [scheduleWindow, setScheduleWindow] = useState({});
  let tempWeekDays = [];
  const history = useHistory();

  const handleSetAction = (id = "", value = "") => {
    initialSchedulePopupState.setScheduleState({
      ...initialSchedulePopupState?.scheduleState,
      [id]: value
    });
  };

  const getDefaultScheduleData = () => {
    const tempData = scheduleDataStore?.scheduleData?.data;
    const filteredData = tempData?.filter(item => item.id === scheduleId)?.[0];
    setScheduleWindow({ ...filteredData });
    handleSetAction("scheduleWindowId", filteredData?.id);
    scheduleDataStore.setDefaultData({ ...filteredData });
  };

  const getUpdatedTimeRange = () => {
    const dailyTimeRange = scheduleWindow?.dailyTimeRange;

    for (var day in weekDays) {
      const initialTimeRange = dailyTimeRange?.[day]?.map(item => {
        const { fromTime = "", toTime = "" } = item;
        return `${Utils.getCustomTime(fromTime)} - ${Utils.getCustomTime(
          toTime
        )}`;
      });

      tempWeekDays.push({
        long: weekDays[day]?.long,
        timeRange: initialTimeRange
      });
    }
    return tempWeekDays;
  };

  const getScheduleWindow = () => {
    const tempWeekDays = getUpdatedTimeRange();

    return tempWeekDays?.map((item, index) => {
      const { long = "", timeRange = [] } = item || {};
      return (
        <div
          className="daysWithTimeWrapper"
          key={`daysWithTimeWrapper${index}`}
        >
          <span className="weekDays">{long}</span>
          <span className={`TimeRange ${timeRange?.[0] ? "" : "emptyResult"}`}>
            {timeRange?.[0] || "--"}
          </span>
        </div>
      );
    });
  };

  const scheduleWindowCbk = item => {
    setScheduleWindow({ ...item });
    handleSetAction("scheduleWindowId", item?.id);
  };

  const redirectToScheduleSettings = () => {
    initialSchedulePopupState.setShowPopup(false);
    history.push("/settings/schedules");
  };

  useEffect(() => {
    getDefaultScheduleData(scheduleDataStore?.scheduleData);
  }, [scheduleId, scheduleDataStore.scheduleData]);

  return (
    <div className="schedulesWrapper">
      {Object.keys(scheduleWindow).length > 0 ? (
        <>
          <div className="scheduleBody">
            <div className="headerTitle">
              Your emails & tasks will be scheduled during these time frames in
              a day
            </div>
            <AutoCompleteDropdown
              optionList={scheduleDataStore?.scheduleData?.data}
              defaultValue={scheduleWindow?.name}
              cbk={scheduleWindowCbk}
            >
              <CreateNewSchedule />
            </AutoCompleteDropdown>
          </div>
          <div className="sendingWindow">
            <div className="timeZone">
              Timezone: {scheduleWindow?.timezoneName || ""}
            </div>
            <div className="flexCenter">
              <div className="scheduleWindowPanel">{getScheduleWindow()}</div>
            </div>
          </div>
        </>
      ) : (
        <section className="sequenceThrottlingSettings">
          <div className="autoCompleteDropdownLoading">
            <div className="inputBoxLoader linear-background" />
          </div>
          <div className="sendingWindowLoading">
            <div className="labelLoader linear-background" />
            {Array.from({ length: 7 }, (value, index) => (
              <div
                className="daysWithTimeWrapper"
                key={`daysWithTimeWrapper${index}`}
              >
                <div className="weekDays linear-background" />
                <div className="TimeRange linear-background" />
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export { InitialSchedule };
export default InitialSchedule;
