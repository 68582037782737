import React from "react";
import { ReactComponent as ScheduledIcon } from "Assets/svg/outbox/scheduled.svg";
import { ReactComponent as DelayedIcon } from "Assets/svg/outbox/delayed.svg";
import { ReactComponent as BouncedIcon } from "Assets/svg/outbox/bounced.svg";
import { ReactComponent as DeliveredIcon } from "Assets/svg/outbox/delivered.svg";
import { ReactComponent as PasueIcon } from "Assets/svg/pause.svg";

export const defaultEmailType = [
  {
    id: "MANUAL_EMAIL",
    name: "Manual Email"
  },
  {
    id: "AUTO_EMAIL",
    name: "Auto Email"
  },
  {
    id: "ONE_OFF_EMAIL",
    name: "One Off Email"
  },
  {
    id: "TIMELINE_EMAIL",
    name: "Timeline Email"
  }
];

export const scheduleConfig = {
  scheduled: {
    svg: <ScheduledIcon />,
    color: "#3953fb"
  },
  delayed: {
    svg: <DelayedIcon />,
    color: "#ff9d00"
  },
  bounced: {
    svg: <BouncedIcon />,
    color: "#d92100"
  },
  delivered: {
    svg: <DeliveredIcon />,
    color: "#019643"
  },
  completed: {
    svg: <DeliveredIcon />,
    color: "#019643"
  },
  paused: {
    color: "#e46833"
  }
};
