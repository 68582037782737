import React from "react";
import { ReactComponent as LinkedinIcon } from "Assets/svg/linkedin.svg";
import { ReactComponent as WebsiteIcon } from "Assets/svg/website.svg";
import { ReactComponent as JobTitleIcon } from "Assets/contactSlider/jobTitle.svg";
import { ReactComponent as CustomTask } from "Assets/svg/customTask.svg";
import { ReactComponent as CallIcon } from "Assets/svg/newPhone.svg";
import { ReactComponent as SequenceIcon } from "Assets/svg/sequence.svg";
import { ReactComponent as ContactOwnerIcon } from "Assets/contactSlider/contactOwner.svg";
import { ReactComponent as ContactStatusIcon } from "Assets/contactSlider/contactStatus.svg";
import { ReactComponent as DataInsightIcon } from "Assets/contactSlider/dataInsight.svg";
import { ReactComponent as PhoneNumberIcon } from "Assets/contactSlider/phoneNumber.svg";
import { ReactComponent as TagIcon } from "Assets/contactSlider/tag.svg";
import { ReactComponent as TimeZoneIcon } from "Assets/contactSlider/timeZone.svg";
import { ReactComponent as DownArrowIcon } from "Assets/svg/donw-arrow.svg";
import { ReactComponent as EmailIcon } from "Assets/contactSlider/email.svg";
import { ReactComponent as TaskIcon } from "Assets/svg/task.svg";
import { ReactComponent as CalendarIcon } from "Assets/svg/calendar.svg";
import { ReactComponent as OptedOutIcon } from "Assets/svg/optedOut.svg";
import { ReactComponent as ClickedIcon } from "Assets/svg/clickRate.svg";
import { ReactComponent as RepliedIcon } from "Assets/svg/replied.svg";
import { ReactComponent as OpenedIcon } from "Assets/svg/eyeOpen.svg";
import { ReactComponent as BouncedIcon } from "Assets/svg/outbox/bounced.svg";
import { ReactComponent as ContactCreatedIcon } from "Assets/svg/addContact2.svg";
import { ReactComponent as AccountCreatedIcon } from "Assets/svg/accCreate.svg";
import { ReactComponent as AccountFieldUpdate } from "Assets/svg/accFieldUpdate.svg";
import { ReactComponent as AccountStageUpdate } from "Assets/svg/accStageUpdate.svg";
import { ReactComponent as ContactUpdate } from "Assets/svg/contacted.svg";
import { ReactComponent as PausedIcon } from "Assets/svg/newPaused.svg";
import { ReactComponent as ResumedIcon } from "Assets/svg/resumed.svg";
import { ReactComponent as VisitorIcon } from "Assets/svg/visitorsIcon.svg";
import { ReactComponent as TwitterX } from "Assets/svg/twitterX.svg";
import { ReactComponent as PersonAdd } from "Assets/svg/person_add.svg";
import { ReactComponent as WarningFiled } from "Assets/svg/warning_filed.svg";
import { ReactComponent as EmailDeliverability } from "Assets/Settings/deliverability.svg";

export const SVG_ICON = {
  email: <EmailIcon className="email" />,
  phoneBox: <PhoneNumberIcon className="phoneBox" />,
  li_replied: <LinkedinIcon className="linkedinAuto" />,
  li_message_sent: <LinkedinIcon className="linkedinAuto" />,
  li_connection_req_sent: <LinkedinIcon className="linkedinAuto" />,
  li_connection_req_accepted: <LinkedinIcon className="linkedinAuto" />,
  linkedin: <LinkedinIcon className="linkedin" />,
  task: <TaskIcon className="task" />,
  website: <WebsiteIcon className="website" />,
  contactStatus: <ContactStatusIcon className="contactStatus" />,
  jobTitle: <JobTitleIcon className="jobTitle" />,
  phone: <CallIcon className="phone" />,
  sequence: <SequenceIcon className="sequence" />,
  contactOwner: <ContactOwnerIcon className="contactOwner" />,
  dataInsight: <DataInsightIcon className="dataInsight" />,
  tag: <TagIcon className="tag" />,
  timeZone: <TimeZoneIcon className="timeZone" />,
  downArrow: <DownArrowIcon className="downArrow" />,
  calendar: <CalendarIcon className="calendar" />,
  opted_out: <OptedOutIcon className="optedOut" />,
  opened: <OpenedIcon className="opened" />,
  clicked: <ClickedIcon className="clicked" />,
  replied: <RepliedIcon className="replied" />,
  sent: <EmailIcon className="sent" />,
  received_email: <EmailIcon className="received_email" />,
  bounced: <BouncedIcon className="bounced" />,
  call_made: <CallIcon className="call_made" />,
  call_log: <CallIcon className="call_log" />,
  task_completed: <TaskIcon className="task_completed" />,
  task_skipped: <TaskIcon className="task_skipped" />,
  marked_as_complete: <TaskIcon className="marked_as_complete" />,
  add_to_sequence: <SequenceIcon className="add_to_sequence" />,
  contact_update: <ContactUpdate className="contact_update" />,
  contact_field_update: <ContactUpdate className="contact_update" />,
  paused: <PausedIcon className="paused" />,
  resumed: <ResumedIcon className="resumed" />,
  skip_to_next_step: <SequenceIcon className="skip_to_next_step" />,
  contact_created: <ContactCreatedIcon className="contact_created" />,
  account_created: <AccountCreatedIcon className="account_created" />,
  account_field_update: <AccountFieldUpdate className="account_field_update" />,
  account_stage_update: <AccountStageUpdate className="account_stage_update" />,
  email_validation_service_bounced: <BouncedIcon className="bounced" />,
  email_validation_service_opted_out: <OptedOutIcon className="optedOut" />,
  remove_contact: <SequenceIcon className="remove_contact" />,
  customTask: <CustomTask className="customTask" />,
  ooto_paused: <PausedIcon className="ooto_paused" />,
  ooto_resumed: <ResumedIcon className="ooto_resumed" />,
  ooto_resume_failed: <ResumedIcon className="ooto_resume_failed" />,
  meeting_booked: <CalendarIcon className="meeting_booked" />,
  contact_owner_updated: <ContactUpdate className="contactOwnerUpdated" />,
  website_visitor: <VisitorIcon />,
  twitter: <TwitterX className="twitterX" />,
  person_add: <PersonAdd className="personAdd" />,
  warning_filed: <WarningFiled className="warningFiled" />,
  email_deliverability: <EmailDeliverability className="emaildeliverability" />
};
