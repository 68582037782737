import React from "react";
import Button from "Components/common/Button";

const TagPopupButtons = props => {
  const { btnAction, nextBtnEnabled } = props;
  return (
    <div className="btnCont">
      <>
        <div
          className="continueBtn"
          id="continueBtn"
          onClick={e => {
            e.preventDefault();
            btnAction();
          }}
        >
          Continue without adding tag
        </div>
        <Button
          id="nextBtn"
          name="nextBtn"
          type="submit"
          className={`nextBtn ${nextBtnEnabled ? "" : "disabled"}`}
          btnText="Next"
          icon={false}
          iconName=""
          click={e => {
            e.preventDefault();
            btnAction();
          }}
        ></Button>
      </>
    </div>
  );
};

export default TagPopupButtons;
export { TagPopupButtons };
