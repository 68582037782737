import React from "react";
import PricingPageTrialBanner from "../pricing/PricingPageTrialBanner";

const TrialUserBilling = props => {
  const { trialEndDate = "", upgradeButton = () => {} } = props;
  return (
    <div className="trialUserBillingWrapper">
      <PricingPageTrialBanner
        trialEndDate={trialEndDate}
        showBelowText={false}
      />
      {upgradeButton()}
    </div>
  );
};

export { TrialUserBilling };
export default TrialUserBilling;
