import React from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import StageVariantCard from "./StageVariantCard";
import AddVariantButton from "./AddVariantButton";

const StageVariants = observer(props => {
  const {
    stepNo = "",
    type = "",
    currentStep = "",
    daysFromStart = 1,
    steps = {},
    seqId = "",
    setShowStageLoader = () => {},
    isTask = false,
    totalContacts = 0,
    getSelectedSeqData = () => {},
    sequenceContactSummary = {},
    analyticStepLoader = false,
    setAnalyticStepLoader = () => {}
  } = props || {};

  const { abTestingEnabled = false } = userDetail?.userFeatureFlag || {};
  const {
    variants = "",
    greatestVariantUniqueId = 1,
    analytics = {}
  } = currentStep || {};
  const { variantAnalytics = {}, ...remainingAnalytics } = analytics || {};

  const isPhoneTask = type === "phone";
  const isLinkedInAutoTask = type?.toLowerCase() === "linkedinauto";
  const variantsIndex = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E"
  };
  const totalVariant = (variants && Object.keys(variants)?.length) || 0;

  const {
    liCrAcceptedCount = 0,
    liCrSentCount = 0,
    liMessageReplyCount = 0,
    liMessageSentCount = 0,
    liProfileViewsCount = 0
  } = remainingAnalytics || {};

  const extendedData = {
    liCrAcceptedCount,
    liCrSentCount,
    liMessageReplyCount,
    liMessageSentCount,
    liProfileViewsCount
  };

  return (
    <div className={`stageVariants ${!isTask ? "emailStage" : ""}`}>
      {variants &&
        Object.keys(variants).map((key, indexValue) => {
          return (
            <StageVariantCard
              key={`stageVariant${stepNo}${key}`}
              variant={variants[key]}
              name={variantsIndex[indexValue + 1]}
              variantStep={key}
              stepNo={stepNo}
              data={{
                ...variantAnalytics[variants?.[key]?.uniqueId],
                ...extendedData
              }}
              daysFromStart={daysFromStart}
              isTask={isTask}
              isPhoneTask={isPhoneTask}
              isLinkedInAutoTask={isLinkedInAutoTask}
              steps={steps}
              seqId={seqId}
              totalVariant={totalVariant}
              setShowStageLoader={setShowStageLoader}
              totalContacts={totalContacts}
              getSelectedSeqData={getSelectedSeqData}
              abTestingEnabled={abTestingEnabled}
              sequenceContactSummary={sequenceContactSummary}
              setAnalyticStepLoader={setAnalyticStepLoader}
              analyticStepLoader={analyticStepLoader}
            />
          );
        })}
      {!isTask && (
        <AddVariantButton
          totalVariant={totalVariant}
          greatestVariantUniqueId={greatestVariantUniqueId}
          stepNo={stepNo}
          steps={steps}
          stageType={type}
          daysLater={currentStep.daysLater}
          seqId={seqId}
          abTestingEnabled={abTestingEnabled}
        />
      )}
    </div>
  );
});

export default StageVariants;
export { StageVariants };
