import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import { DATE_FILTER_LIST } from "Static/constant";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";

let cancelFilterDataRequest = null;
let cancelOverviewDataRequest = null;
let cancelAnalyticsDataRequest = null;
let cancelTeamSummaryDataRequest = null;

export const defaultCustomDate = [
  {
    startDate: new Date(),
    endDate: null,
    key: "selection"
  }
];

export const defaultInput = {
  filterLoader: true,
  overviewLoader: true,
  currentTab: "TOTAL",
  filterObject: {},
  overviewData: {},
  teamSummaryLoader: true,
  teamSummaryData: [],
  analyticsLoader: true,
  analyticsData: []
};

export const defaultFilterObject = {
  dateFilterList: DATE_FILTER_LIST,
  activeDateFilter: "week",
  previousDateFilter: "week",
  selectedUser: "all",
  showDatePicker: false,
  customDateValue: "Custom",
  customDateFormat: "",
  customDate: defaultCustomDate
};

export const defaultFilterPayload = {
  memberIds: "",
  fromDate: "",
  to: "",
  callDispositionId: null,
  pageNo: 1,
  limit: 10,
  timeZone: ""
};

class CallsStore {
  @observable inputValues = { ...defaultInput };
  @observable filterPayload = defaultFilterPayload;
  @observable filterValues = defaultFilterObject;
  @observable isLogUpdated = false;
  @observable resetCheckbox = false;

  @action
  setInputValues = (obj = {}) => {
    this.inputValues = { ...this.inputValues, ...obj };
  };

  @action
  setFilterPayload = (obj = {}) => {
    this.filterPayload = { ...this.filterPayload, ...obj };
  };

  @action
  setFilterValues = (obj = {}) => {
    this.filterValues = { ...this.filterValues, ...obj };
  };

  @action
  setIsLogUpdated = (value = false) => {
    this.isLogUpdated = value;
  };

  @action
  setResetCheckbox = (value = false) => {
    this.resetCheckbox = value;
  };

  @action
  resetValue = () => {
    this.inputValues = { ...defaultInput };
    this.filterPayload = { ...defaultFilterPayload };
    this.filterValues = { ...defaultFilterObject };
  };

  @action
  errorToast = status => {
    if (status === "failed") {
      toasterState.setToastMsg("Something went wrong", "fail");
    }
  };

  @action
  getCallsOutcomeFilterData = async (data = {}, init = false) => {
    try {
      this.setInputValues({
        filterLoader: init
      });
      const config = {
        url: URLS.getCallsOutcomeFilterValue,
        method: "POST",
        data
      };
      if (cancelFilterDataRequest !== null) {
        cancelFilterDataRequest("cancel");
        cancelFilterDataRequest = null;
      }
      const cancelReqCallback = value => {
        cancelFilterDataRequest = value;
      };
      let res = await makeApi(config, cancelReqCallback);
      const { counts = [] } = res?.data || {};
      if (counts?.length > 0) {
        let total = 0;
        let tempObjList =
          counts.reduce((acc, item) => {
            total += item?.count || 0;
            acc[item?.callDispositionId || "total"] = item?.count || 0;
            return acc;
          }, {}) || {};
        this.setInputValues({
          filterObject: { ...tempObjList, total },
          filterLoader: false,
          analyticsData: [],
          teamSummaryData: []
        });
      } else {
        this.setInputValues({
          filterObject: {},
          filterLoader: false,
          analyticsData: [],
          teamSummaryData: []
        });
      }
    } catch (err) {
      console.error("Call Outcome filter data failed", err);
    }
  };

  @action
  getCallsOverviewData = async data => {
    try {
      this.setInputValues({
        overviewLoader: true
      });
      const config = {
        url: URLS.getCallsOverviewData,
        method: "POST",
        data
      };
      if (cancelOverviewDataRequest !== null) {
        cancelOverviewDataRequest("cancel");
        cancelOverviewDataRequest = null;
      }
      const cancelReqCallback = value => {
        cancelOverviewDataRequest = value;
      };
      const res = await makeApi(config, cancelReqCallback);
      Utils.mixpanelTrack(MXP_EVENT.CALLS_OVERVIEW_USED, {
        filterCriteria: data
      });
      const {
        totalCalls = 0,
        calls = [],
        currentPage = 1,
        totalPages = 0
      } = res?.data || {};
      if (totalCalls > 0 && calls?.length) {
        this.setInputValues({
          overviewData: res.data,
          overviewLoader: false,
          analyticsData: [],
          teamSummaryData: []
        });
      } else {
        this.setInputValues({
          overviewData: {
            calls,
            totalCalls,
            currentPage,
            totalPages
          },
          overviewLoader: false,
          analyticsData: [],
          teamSummaryData: []
        });
      }
    } catch (err) {
      console.error("Call Overview list data failed", err);
    }
  };

  @action
  getCallsAnalyticsData = async (data = {}) => {
    try {
      callsStore.setInputValues({
        analyticsLoader: true
      });
      const config = {
        url: URLS.getAnalyticsData,
        method: "POST",
        data
      };
      if (cancelAnalyticsDataRequest !== null) {
        cancelAnalyticsDataRequest("cancel");
        cancelAnalyticsDataRequest = null;
      }
      const cancelReqCallback = value => {
        cancelAnalyticsDataRequest = value;
      };
      const res = await makeApi(config, cancelReqCallback);
      Utils.mixpanelTrack(MXP_EVENT.CALLS_ANALYTICS_USED, {
        filterCriteria: data
      });
      const { status = "", results = [] } = res?.data || {};
      callsStore.setInputValues({
        analyticsLoader: false,
        analyticsData: results,
        overviewData: []
      });
      this.errorToast(status);
    } catch (err) {
      console.error("Call Overview list data failed", err);
    }
  };

  @action
  getTeamSummaryAnalyticsData = async (data = {}) => {
    try {
      callsStore.setInputValues({
        teamSummaryLoader: true
      });
      const config = {
        url: URLS.getAnalyticsTeamData,
        method: "POST",
        data
      };
      if (cancelTeamSummaryDataRequest !== null) {
        cancelTeamSummaryDataRequest("cancel");
        cancelTeamSummaryDataRequest = null;
      }
      const cancelReqCallback = value => {
        cancelTeamSummaryDataRequest = value;
      };
      const res = await makeApi(config, cancelReqCallback);
      Utils.mixpanelTrack(MXP_EVENT.CALLS_SUMMARY_USED, {
        filterCriteria: data
      });
      const { status = "", results = [] } = res?.data || {};
      if (status === "failed") {
        callsStore.setInputValues({
          teamSummaryLoader: false,
          teamSummaryData: results,
          overviewData: []
        });
        toasterState.setToastMsg("Something went wrong", "fail");
        return;
      }
      callsStore.setInputValues({
        teamSummaryData: [...results],
        overviewData: []
      });
      this.errorToast(status);
    } catch (err) {
      console.error("Call Overview list data failed", err);
    }
  };
}

const callsStore = new CallsStore();
export default CallsStore;
export { callsStore };
