import React, { useState, useEffect } from "react";
import SingleSelectDropdown from "./SingleSelectDropdown";
import CheckBox from "Components/common/CheckBox";
import Tooltip from "Components/common/CustomTooltip";
import { Utils } from "Utils/utils";
import { meetingBufferDropdownOption } from "Pages/Dashboard/settings/Calendar/Constants";

const defaultCheckedVal = {
  beforeEventEnabled: false,
  afterEventEnabled: false
};

const meetingbufferIdMapper = {
  beforeEventInMins: "beforeEventEnabled",
  afterEventInMins: "afterEventEnabled"
};

const MeetingBuffer = props => {
  const { inputValue, updateInputState = () => {} } = props || {};
  const [checkedVal, setCheckedVal] = useState(defaultCheckedVal);
  const [showToolTip, setShowToolTip] = useState(false);

  const handleChange = (id = "", name = "", value = "") => {
    const meetingBuffer = inputValue?.meetingBuffer || {};
    meetingBuffer[id] = +value;
    updateInputState("meetingBuffer", meetingBuffer);
  };

  const getDropDownMappedValue = value => {
    for (let i = 0; i < meetingBufferDropdownOption.length; i++) {
      const option = meetingBufferDropdownOption[i];
      if (option?.value == value) {
        return option?.name;
      }
    }
  };

  const getDropdownProps = (id, name) => {
    return {
      name: id,
      type: "select",
      search: true,
      options: meetingBufferDropdownOption,
      text: name,
      id,
      value:
        getDropDownMappedValue(inputValue?.["meetingBuffer"]?.[`${id}`]) || "",
      change: handleChange,
      tabIndex: -1,
      timeZone: true,
      disabled: !checkedVal[meetingbufferIdMapper[id]]
    };
  };

  const getUpdatedDom = (referenceId, name) => {
    return <SingleSelectDropdown {...getDropdownProps(referenceId, name)} />;
  };

  const inputProps = [
    {
      id: "beforeEventInMins",
      name: "Before event",
      checked: checkedVal[meetingbufferIdMapper["beforeEventInMins"]], // to get the value of beforeEventEnabled, a mapper is used
      hasCustomDom: true,
      dom: getUpdatedDom("beforeEventInMins", "Before event")
    },
    {
      id: "afterEventInMins",
      name: "After event",
      checked: checkedVal[meetingbufferIdMapper["afterEventInMins"]], // to get the value of afterEventEnabled, a mapper is used
      hasCustomDom: true,
      dom: getUpdatedDom("afterEventInMins", "After event")
    }
  ];

  const handleCheckedItem = (value = false, id = "") => {
    const meetingBuffer = Utils.createObjWithoutRef(
      inputValue?.meetingBuffer || {}
    );
    meetingBuffer[meetingbufferIdMapper[id]] = value;
    updateInputState("meetingBuffer", meetingBuffer);
  };

  useEffect(() => {
    // Initial value updated in checkedVal
    const tempObj = {
      beforeEventEnabled: inputValue?.meetingBuffer?.["beforeEventEnabled"],
      afterEventEnabled: inputValue?.meetingBuffer?.["afterEventEnabled"]
    };
    setCheckedVal(tempObj);

    return () => {
      setCheckedVal(defaultCheckedVal);
    };
  }, [inputValue?.meetingBuffer]);

  return (
    <div className="addTimeBeforeOrAfterWrapper">
      <div className="labelTitle">
        <span>Add buffer time before or after your events</span>
        <span
          className="workingHoursInfoIcon"
          onMouseOver={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
        >
          <i className="material-icons">info_outline</i>
          {showToolTip && (
            <Tooltip
              text="
              Include break time between your sessions to prevent contacts from scheduling back-to-back meetings."
            />
          )}
        </span>
      </div>
      <div className="addTimeBeforeOrAfterCont">
        {inputProps.map((item, index) => {
          return (
            <CheckBox
              {...item}
              cbk={handleCheckedItem}
              noClick={true}
              key={"meetingBuffer" + index}
            />
          );
        })}
      </div>
    </div>
  );
};

export { MeetingBuffer };
export default MeetingBuffer;
