import React, { memo } from "react";

const TextArea = (props = {}) => {
  const {
    onBlurEvent = () => {},
    onChangeEvent = () => {},
    id = "",
    value = "",
    labelText = "",
    rows = "",
    placeholder = "",
    isRequired = false,
    setCursorPostion = () => {},
    className = "",
    disabled = false
  } = props || {};

  return (
    <div className="inputOptionsHolder notes-section">
      <label htmlFor={id} className="name">
        {labelText}
      </label>
      <textarea
        className={`txt-cont ${className}`}
        placeholder={placeholder}
        required={isRequired}
        id={id}
        rows={rows}
        onBlur={(e) => onBlurEvent(e)}
        onChange={(e) => onChangeEvent(e, id)}
        value={value || ""}
        autoFocus
        onSelectCapture={(e) => setCursorPostion(e.target.selectionStart)}
        disabled={disabled}
      />
    </div>
  );
};

const MemoizedTextArea = memo(TextArea);
export default TextArea;
export { TextArea, MemoizedTextArea };
