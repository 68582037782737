import React, { useState } from "react";
import { observer } from "mobx-react";
import { ReactComponent as MessageSentIcon } from "../svg/messageSent.svg";
import { ReactComponent as LinkedInCrSentIcon } from "../svg/liCrSent.svg";
import { ReactComponent as LinkedInCrAcceptedIcon } from "../svg/liCrAccepted.svg";
import { ReactComponent as ReplyRateIcon } from "Assets/svg/replyRate.svg";
import CustomTooltip from "Components/common/CustomTooltip";
import Utils from "Utils/utils";

const linkedInMetricsObj = [
  {
    name: "crSentCount",
    id: "crSentCount",
    icon: <LinkedInCrSentIcon />,
    tooltipText: "Connection Requests Sent"
  },
  {
    name: "crAcceptedCount",
    id: "crAcceptedCount",
    icon: <LinkedInCrAcceptedIcon />,
    tooltipText: "Connection Requests Accepted"
  },
  {
    name: "messageSentCount",
    id: "messageSentCount",
    icon: <MessageSentIcon />,
    tooltipText: "Messages Sent"
  },
  {
    name: "messageReceivedCount",
    id: "messageReceivedCount",
    icon: (
      <span className="replyRateIcon">
        <ReplyRateIcon />
      </span>
    ),
    tooltipText: "Replies Received"
  },
  {
    name: "profileViewsCount",
    id: "profileViewsCount",
    icon: (
      <i className={`material-icons-outlined visibilityIcon`}>visibility</i>
    ),
    tooltipText: "Profiles Viewed"
  }
];

const LinkedInMetrics = observer(props => {
  const [tooltipIndex, setTooltipIndex] = useState("");

  return (
    <div className="linkedInMetricsWrapper">
      {linkedInMetricsObj.map((item, index) => {
        const { id = "", icon = <></>, tooltipText = "" } = item || {};
        return (
          <div
            className="metrics"
            key={id}
            onMouseOver={() => setTooltipIndex(index)}
            onMouseLeave={() => setTooltipIndex("")}
          >
            <div className="metricsIcon">{icon}</div>
            <div className="metricsValue">
              {Utils.formatNumberToStringInUSFormat(props?.[id]) || 0}
            </div>
            {index === tooltipIndex && <CustomTooltip text={tooltipText} />}
          </div>
        );
      })}
    </div>
  );
});

export { LinkedInMetrics };
export default LinkedInMetrics;
