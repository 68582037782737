import React from "react";
import salesForceIcon from "Assets/png/salesforce.png";
import hubspotIcon from "Assets/png/hubspotIcon.png";
import pipedriveIcon from "Assets/png/pipedriveIcon.png";
import slackIcon from "Assets/png/Slack.png";
import zapierIcon from "Assets/png/zapierIcon.png";
import { ReactComponent as Email } from "Assets/svg/sequences.svg";
import { ReactComponent as Phone } from "Assets/svg/call.svg";
import { ReactComponent as Sms } from "Assets/svg/sms.svg";
import { ReactComponent as LinkedIn } from "Assets/svg/linkedin.svg";
import { ReactComponent as CustomTask } from "Assets/svg/customTask.svg";
import ManualEmail from "Assets/png/manualEmail.png";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";

export const Paywall = (type = "") => {
  switch (type) {
    case "templates":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot create more than ${upgradePopupState?.popupValues?.templateCount} templates in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear templates.",
        btnText: "Upgrade",
        color: "#3b50fa",
        pageType: "Templates",
        trigger: "Template limit reached"
      };
    case "newSequence":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot create more than ${upgradePopupState?.popupValues?.sequenceCount} sequences in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear sequences.",
        btnText: "Upgrade",
        color: "#3b50fa",
        badgeSize: "md",
        pageType: "Sequence",
        trigger: "Sequence limit reached"
      };
    case "taskPage":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot create tasks in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to create and manage tasks.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Task",
        trigger: "Task section viewed"
      };
    case "contactTask":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot create tasks in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to create and manage tasks.",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Contacts",
        trigger: "Create task"
      };
    case "doNotContact":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot block domains in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to get unrestricted access to do not contact domains",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Settings"
      };
    case "contactStages":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot create new contact stages in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to access contact stages.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Contact stages settings",
        trigger: "Contact stages visited"
      };
    case "dialer":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Dialer is not available in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: `Please upgrade to ${upgradePopupState?.popupValues?.planName} Plan.`,
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#ff6057",
        pageType: "Dialer settings",
        trigger: "Upgrade Dialer",
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.26)"
      };
    case "reportsPage":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot access reports in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to access report page.",
        btnText: "Upgrade",
        badgeSize: "lg",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Reports"
      };
    case "userAndTeams":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Users and Teams is not available in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to enable Users and Teams in Salesgear.",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Settings"
      };
    case "customURL":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}proPlan`,
        subTitle: `Oops!  You cannot add custom URL in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear custom URL.",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Settings"
      };
    case "subjectLineGenerator":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! AI Writing assistant is not available in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to our premium plans to get access to Salesgear writing assistant.",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#ff6057",
        pageType: "sequences",
        trigger: "Subject Line Generator"
      };
    case "multipleInboxCustom":
      return {
        title: "Inboxes limit reached",
        plan: "customPlan",
        type: "chatWithUs",
        text: `You have reached the maximum limit for inboxes. Contact us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> or through the chat window below to add more inboxes.`,
        btnText: "Chat with us",
        badgeSize: "lg",
        color: "#ff6057",
        pageType: "Setting Connect Inbox",
        trigger: "Add Inbox"
      };
    case "multipleLinkedInCustom":
      return {
        title: "LinkedIn limit reached",
        plan: "customPlan",
        type: "chatWithUs",
        text: `An active LinkedIn account is already connected. Please delete the account and try adding the new account again.`,
        btnText: "Chat with us",
        badgeSize: "lg",
        color: "#ff6057",
        pageType: "Setting Connect LinkedIn",
        trigger: "Add LinkedIn"
      };
    case "multipleInbox":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot connect more than one inbox in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to our premium plans to connect more inboxes.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#ff6057",
        pageType: "Setting Connect Inbox",
        trigger: "Add Inbox"
      };
    case "data_AddCreditsToDialer":
      return {
        title: "Contact Sales",
        text: `Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer" >sales@salesgear.io</a> or through the chat window below to add more credits.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        pageType: "Settings - dialer page",
        trigger: "Dialer - Add more credits"
      };
    case "data_AddCreditsToDataSearch":
      return {
        title: "Contact Sales",
        text: `Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window below to add more credits.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        pageType: "Settings - credits page",
        trigger: "Purchase contacts - Add more credits"
      };
    case "data_AddCreditsToEV":
      return {
        title: "Contact Sales",
        text: `Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window below to add more credits.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        pageType: "Settings - credits page",
        trigger: "Email validation popup shown - Add more credits"
      };
    case "emailValidationUpgrade":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Email Validation feature is not available in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to enable email validation in Salesgear.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Discover Page",
        trigger: "Email Validation Popup Shown"
      };
    case "triggers":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Triggers are not available in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to access triggers.",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#ff6057",
        pageType: "Setting Triggers",
        trigger: `${upgradePopupState?.popupValues?.triggerType}`
      };
    case "abTesting":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! AB testing is not available in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to enable AB testing",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequences",
        trigger: "Add variants"
      };
    case "calendarUpgrade":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Calendar is not available in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to access Calendar",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Calender"
      };
    case "calendarLimitReached":
      return {
        title: "Upgrade to Pro Plan",
        plan: "proPlan",
        text: "Please upgrade to Pro plan to create more events.",
        subTitle: "Oops! You cannot create more than one event in the trial.",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#ff6057",
        pageType: "Calendar Events",
        trigger: "Event Creation"
      };
    case "calendarBrandLogoUpgrade":
      return {
        title: "Upgrade to Pro Plan",
        plan: "proPlan",
        subTitle:
          "Oops! You cannot remove Salesgear branding in the free plan.",
        text: "Please upgrade to Pro plan to remove branding.",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#ff6057",
        pageType: "Calendar Configuration",
        trigger: "Configuration Toggle"
      };
    case "sequenceAddTaskToSequence":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You can only add "Automatic Email" as a step in sequences in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to add other channels in sequences",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Sequence limit reached"
      };
    case "pauseResumeSequence":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot resume this sequence, as only "Automatic Email" is allowed in sequences in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to resume sequences with multiple channels.",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Resume sequence with task"
      };
    case "contactsExport":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Contact export is not available on your ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: `Please upgrade to ${upgradePopupState?.popupValues?.planName} plan to export contacts`,
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Contacts",
        trigger: "Export contact"
      };
    case "salesforceContactExport":
    case "salesforce":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Salesforce Contact export is not available on your ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: `Please upgrade to ${upgradePopupState?.popupValues?.planName} plan to export alesforce contacts`,
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Contacts",
        trigger: "Salesforce Export contact"
      };
    case "hubspotContactExport":
    case "hubspot":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Hubspot Contact export is not available on your ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: `Please upgrade to ${upgradePopupState?.popupValues?.planName} plan to export hubspot contacts`,
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Contacts",
        trigger: "Hubspot Export contact"
      };
    case "sequenceExport":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Contact export is not available on your ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: `Please upgrade to ${upgradePopupState?.popupValues?.planName} plan to export contacts`,
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Export contact"
      };
    case "callsExport":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot export phone call data in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: `Please upgrade to one of our premium plans to export phone call data.`,
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Calls",
        trigger: "Export call log"
      };
    case "templatesCustomfieldToken":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Custom contact variables are unavailable in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to configure contact custom variables",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Templates",
        trigger: "Insert custom variable"
      };
    case "sequencesCustomfieldToken":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Custom contact variables are unavailable in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to configure contact custom variables",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Insert custom variable"
      };
    case "customFields":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! Custom variables are not available in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to create custom variables.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Custom fields setting",
        trigger: "Custom fields visited"
      };
    case "newSequenceWithTask":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You can create sequences with only "Automatic Email" steps in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to add other channels in sequences",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Sequence limit reached"
      };
    case "data_ContactSearchPage":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot purchase contacts in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to our premium plan to get access to 200 million+ contacts & 10 million+ companies.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Discover Page",
        trigger: "Purchase contacts popup shown - Free plan"
      };
    case "data_BulkCustomSelection":
      return {
        title: "Contact Sales",
        text: `Custom selection is disabled. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to enable custom selection.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        pageType: "Discover Page",
        trigger: "Maximum contact custom selection limit reached popup shown"
      };
    case "data_BulkTopMaxSelection":
      return {
        title: "Contact Sales",
        text: `Maximum contact selection limit reach. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to increase this limit.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        pageType: "Discover Page",
        trigger: "Maximum contact top selection limit reached popup shown"
      };
    case "addContactsToSequence":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot add contacts to sequences with tasks in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear sequences",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Add Contacts to Sequence with task"
      };
    case " callContact":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot make phone calls in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to Pro plan to make phone calls to your contacts.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#ff6057",
        pageType: "Contacts",
        trigger: "Make a Call"
      };
    case " contactActivityPagination":
      return {
        title: "Upgrade to Basic Plan",
        plan: "basicPlan",
        subTitle:
          "Oops! You cannot view the contact activities any further in freemium ",
        text: "Please upgrade to a paid plan to see all the activities",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Contact Timeline Activity",
        trigger: "Contact activity filter"
      };
    case "exportHubspotUpgrade":
      return {
        title: "Upgrade to Unlimited Plan",
        plan: "unlimitedPlan",
        subTitle: `Oops! you cannot export contacts to Hubspot in the ${upgradePopupState?.popupValues?.exisitingPlanName} Plan.`,
        text: `Please upgrade to ${upgradePopupState?.popupValues?.planName} plan to import and export contacts from Hubspot`,
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Contact Export",
        trigger: "Contact Export"
      };
    case "exportSalesforceUpgrade":
      return {
        title: "Upgrade to Unlimited Plan",
        plan: "unlimitedPlan",
        subTitle: `Oops! you cannot export contacts to Salesforce in the ${upgradePopupState?.popupValues?.exisitingPlanName} Plan.`,
        text: `Please upgrade to ${upgradePopupState?.popupValues?.planName} plan to import and export contacts from Salesforce`,
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Contact Export",
        trigger: "Contact Export"
      };
    case "contactActivityFilter":
      return {
        title: "Upgrade to Basic Plan",
        plan: "basicPlan",
        subTitle:
          "Oops! You cannot filter by specific activity type in the free plan.",
        text: "Please upgrade to one of our premium plans to get unrestricted access to contact activities",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Contact Timeline Activity",
        trigger: "Load more activity"
      };
    case "reportsCustomDate":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot view insight on a specific date range in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to view insights by any date range",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Reports",
        trigger: "Report custom date filter"
      };
    case "callsCustomDate":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot view insight on a specific date range in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to view overview and analytics by any date range",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Calls",
        trigger: "Calls custom date filter"
      };
    case "data_PageLimitReachedTrialUser":
      return {
        title: "Upgrade to Basic Plan",
        plan: "basicPlan",
        subTitle: "Oops! You've reached the maximum page limit.",
        text: `Please upgrade to one of our premium plans to increase your page limit.`,
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Discover Page",
        trigger: "Maximum pagination limit reached popup shown"
      };
    case "data_PageLimitReachedPaidUser":
      return {
        title: "Contact Sales",
        plan: "customPlan",
        type: "chatWithUs",
        text: `Maximum page limit reached. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to remove this limit.`,
        btnText: "Chat with us",
        badgeSize: "lg",
        color: "#ff6057",
        pageType: "Discover Page",
        trigger: "Maximum pagination limit reached popup shown"
      };
    case "data_PurchasePhoneFreePlan":
      return {
        title: "Upgrade to Unlimited Plan",
        plan: "unlimitedPlan",
        subTitle: "Oops! You cannot purchase phone numbers in the free plan.",
        text: "Please upgrade to our unlimited plan to get access to direct dials & mobile numbers.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#00cb1b",
        pageType: "Discover Page",
        trigger: "Purchase contacts popup shown - Phone number - Free plan"
      };
    case "data_PurchasePhoneBasicPlan":
      return {
        title: "Upgrade to Unlimited Plan",
        plan: "unlimitedPlan",
        subTitle: "Oops! You cannot purchase phone numbers in the basic plan.",
        text: "Please upgrade to our unlimited plan to get access to direct dials & mobile numbers.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#00cb1b",
        pageType: "Discover Page",
        trigger:
          "Purchase phone number popup shown - Basic plan - Upgrade to unlimited plan"
      };
    case "data_PurchasePhoneProPlan":
      return {
        title: "Upgrade to Unlimited Plan",
        plan: "unlimitedPlan",
        subTitle: "Oops! You cannot purchase phone numbers in the pro plan.",
        text: "Please upgrade to our unlimited plan to get access to direct dials & mobile numbers.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#00cb1b",
        pageType: "Discover Page",
        trigger:
          "Purchase phone number popup shown - Pro plan - Upgrade to unlimited plan"
      };
    case "sequenceAddTaskToSequence":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You can only add "Automatic Email" as a step in sequences in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to one of our premium plans to add other channels in sequences",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Sequence limit reached"
      };
    case "AIWritingSeq":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot access AI writing assistance in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan`,
        text: "Please upgrade to one of our premium plans to access AI writing assistant",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Add task step"
      };
    case "websiteTracking":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot access website tracking in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan`,
        text: "Please upgrade to one of our premium plans to access website tracking",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Settings"
      };
    case "AIWriting":
      return {
        title: "Upgrade to Pro Plan",
        plan: "proPlan",
        subTitle:
          "Oops! You cannot access AI writing assistance in current plan",
        text: "Please upgrade to one of our premium plans to access AI writing assistant",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Add task step"
      };
    case "data_EmailDailyLimitReached":
      return {
        title: "Contact Sales",
        plan: "unlimitedPlan",
        text: `You've reached your daily email credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        color: "#00cb1b",
        pageType: "Discover Page",
        trigger: "Daily email credits limit reached popup shown"
      };
    case "data_EmailMonthlyLimitReached":
      return {
        title: "Contact Sales",
        plan: "unlimitedPlan",
        text: `You've reached your monthly email credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        color: "#00cb1b",
        pageType: "Discover Page",
        trigger: "Monthly email credits limit reached popup shown"
      };
    case "data_PhoneDailyLimitReached":
      return {
        title: "Contact Sales",
        plan: "unlimitedPlan",
        text: `You've reached your daily phone number credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        color: "#00cb1b",
        pageType: "Discover Page",
        trigger: "Daily phone number credits limit reached popup shown"
      };
    case "data_PhoneMonthlyLimitReached":
      return {
        title: "Contact Sales",
        plan: "unlimitedPlan",
        text: `You've reached your monthly phone number credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        color: "#00cb1b",
        pageType: "Discover Page",
        trigger: "Monthly phone number credits limit reached popup shown"
      };
    case "data_PhoneAnnualLimitReached":
      return {
        title: "Contact Sales",
        plan: "unlimitedPlan",
        text: `You've reached your annual phone number credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        color: "#00cb1b",
        pageType: "Discover Page",
        trigger: "Annual phone number credits limit reached popup shown"
      };
    case "data_F_EmailDailyLimitReached":
      return {
        title: "Upgrade to Basic Plan",
        plan: "basicPlan",
        subTitle: "Oops! You've reached your daily email credit limit.",
        text: "Please upgrade to one of our premium plans to increase your daily email credit limit.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Discover Page",
        trigger: "Daily email credits limit reached popup shown - Free plan"
      };
    case "data_F_EmailMonthlyLimitReached":
      return {
        title: "Upgrade to Basic Plan",
        plan: "basicPlan",
        subTitle: "Oops! You've reached your monthly email credit limit.",
        text: "Please upgrade to one of our premium plans to increase your monthly email credit limit.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Discover Page",
        trigger: "Monthly email credits limit reached popup shown - Free plan"
      };
    case "data_F_PhoneNumberLimitReached":
      return {
        title: "Upgrade to Unlimited Plan",
        plan: "unlimitedPlan",
        subTitle: "Oops! You've reached your phone number credit limit.",
        text: "Please upgrade to our unlimited plan to increase your phone number credit limit.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#00cb1b",
        pageType: "Discover Page",
        trigger: "Phone number credits limit reached popup shown - Free plan"
      };
    case "newSequenceCard":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You have reached the limit for sequences on your ${upgradePopupState?.popupValues?.exisitingPlanName} plan`,
        text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear sequences",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Sequence card limit reached"
      };
    case "customFieldsCount":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You can create only ${upgradePopupState?.popupValues?.customFieldsCount} custom fields in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan`,
        text: "Please upgrade to one of our premium plans to create more custom fields",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Custom fields setting",
        trigger: "Custom fields visited"
      };
    // Oops! You cannot add more than 500 contacts in the free plan.

    // Please upgrade to one of our premium plans to add more contacts.
    case "contactsAdded":
      return {
        title: "Contact Sales",
        text: `Contact us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer" >support@salesgear.io</a> or through the chat window below to add more contacts.`,
        btnText: "Chat with us",
        type: "chatWithUs",
        badgeSize: "lg",
        pageType: "Contacts ",
        trigger: "Add Contacts"
      };
    case "domainHealthCenter":
      return {
        title: `Upgrade to ${upgradePopupState?.popupValues?.planName} Plan`,
        plan: `${upgradePopupState?.popupValues?.planName?.toLowerCase()}Plan`,
        subTitle: `Oops! You cannot access domain health center in ${upgradePopupState?.popupValues?.exisitingPlanName} plan`,
        text: `Please upgrade to ${upgradePopupState?.popupValues?.planName} plan to get a 360 view of your inboxes and supercharge your email deliverability.`,
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Settings",
        trigger: "domain health center page visited"
      };
    case "stageRules":
      return {
        title: "Upgrade to Basic Plan",
        plan: "basicPlan",
        subTitle: "Oops! You cannot set contact stage rules in the free plan",
        text: "Please upgrade to one of our premium plans to access stage rules.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "stage rules settings"
      };
    case "emailWarmingCustom":
      return {
        title: "Contact Support",
        type: "chatWithUs",
        text: `Oops! You don't have access to warming. Contact us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> if you have any questions.`,
        btnText: "Chat with us",
        badgeSize: "lg",
        color: "#ff6057",
        pageType: "Settings Connect Inbox"
      };
    case "emailWarming":
      return {
        title: "Inbox warmup limit reached",
        type: "chatWithUs",
        text: `You have reached the maximum limit for warming up inboxes. Contact us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> or through the chat window below to warmup more inboxes.`,
        btnText: "Chat with us",
        badgeSize: "lg",
        color: "#ff6057",
        pageType: "Settings Connect Inbox"
      };
    case "linkedInSettings":
      return {
        title: `Upgrade to Unlimited Plan`,
        plan: `unlimitedPlan`,
        subTitle: `Oops! You cannot connect LinkedIn accounts in the ${upgradePopupState?.popupValues?.exisitingPlanName} plan.`,
        text: "Please upgrade to the Unlimited plan to connect and automate LinkedIn.",
        btnText: "Upgrade",
        badgeSize: "lg",
        color: "#3b50fa",
        pageType: "Settings",
        trigger: "LinkedIn settings viewed"
      };
    case "sequenceLinkedInAddToSequence":
      return {
        title: `Upgrade to Unlimited Plan`,
        plan: `unlimitedPlan`,
        subTitle: `Oops! You can only add LinkedIn (Automatic) as a step in Sequences in the Unlimited plan.`,
        text: "Please upgrade to the Unlimited plan to add the LinkedIn (Automatic) step in Sequences",
        btnText: "Upgrade",
        badgeSize: "md",
        color: "#3b50fa",
        pageType: "Sequence",
        trigger: "Sequence limit reached"
      };
    default:
      break;
  }
};
