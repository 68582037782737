import { linkedInCookiesState } from "Stores/LinkedInCookies";
import userDetail from "Stores/userDetail";
import { toasterState } from "Components/common/toaster";
import { linkedInReplyState } from "../state";
import { pluginNotInstalled } from "../PluginNotInstalled";
import PaywallUtils from "Utils/PaywallUtils";

const linkedInUtils = {};
let count = 0;

const postingData = premium => {
  window.parent.postMessage(
    { data: "message", type: "LINKEDIN_COOKIES_AT" },
    "*"
  );
  window.parent.postMessage(
    { data: "message", type: "LINKEDIN_PROFILE_DATA" },
    "*"
  );
  if (premium) {
    window.parent.postMessage(
      { data: "message", type: "LINKEDIN_COOKIES_A" },
      "*"
    );
  }
};

linkedInUtils.handleSalesNavigatorButtonClick = async () => {
  const {
    planName = "",
    inFreePlan = false,
    liAutomationEnabled = false,
    noOfLiAccountsUsed = 0,
    noOfLiAccountsEligible = 0
  } = userDetail?.userFeatureFlag || {};
  if (noOfLiAccountsUsed < noOfLiAccountsEligible) {
    if (liAutomationEnabled) {
      if (userDetail.pluginInstalled) {
        window.parent.postMessage(
          { reload: true, type: "SALES_NAVIGATOR_RELOAD_PLUGIN" },
          "*"
        );
        const intervalId = setInterval(() => {
          count++;
          postingData(true);
          if (linkedInCookiesState?.profileData !== null) {
            if (
              linkedInCookiesState?.cookieLiA &&
              linkedInCookiesState?.cookieLiAt
            ) {
              clearInterval(intervalId);
              count = 0;
              window.parent.postMessage(
                { reload: true, type: "CLOSE_LINKEDIN" },
                "*"
              );
              linkedInReplyState.setLinkedInConnectionLoading(false);
              linkedInReplyState.setShowLinkedInDetailsPopup(true);
              return;
            } else {
              if (count >= 10) {
                window.parent.postMessage(
                  { reload: true, type: "CLOSE_LINKEDIN" },
                  "*"
                );
                clearInterval(intervalId);
                count = 0;
                linkedInReplyState.setLinkedInMsg(
                  "Unable to find a Sales Navigator account."
                );
                linkedInReplyState.setLinkedInConnectionLoading(false);
                linkedInCookiesState.setIsPremiumAccount(true);
                linkedInReplyState.setShowLinkedInNotConnectedPopup(true);
              }
            }
          } else {
            if (
              count >= 3 &&
              linkedInCookiesState?.profileData === null &&
              !linkedInCookiesState?.cookieLiA &&
              !linkedInCookiesState.cookieLiAt
            ) {
              clearInterval(intervalId);
              window.parent.postMessage(
                { reload: true, type: "CLOSE_LINKEDIN" },
                "*"
              );
              count = 0;
              linkedInReplyState.setLinkedInConnectionLoading(false);
              linkedInReplyState.setLinkedInMsg(
                "No active LinkedIn Sales Navigator account found on this browser."
              );
              linkedInCookiesState.setIsPremiumAccount(true);
              linkedInReplyState.setShowLinkedInNotConnectedPopup(true);
            }
          }
        }, 2000);
        linkedInReplyState.setLinkedInConnectionLoading(true);
      } else {
        pluginNotInstalled();
      }
    }
  } else {
    PaywallUtils.upgradeUtilsFunction(
      "multipleLinkedInCustom",
      false,
      planName,
      inFreePlan
    );
  }
};

linkedInUtils.handleButtonClick = async () => {
  const {
    planName = "",
    inFreePlan = false,
    liAutomationEnabled = false,
    noOfLiAccountsUsed = 0,
    noOfLiAccountsEligible = 0
  } = userDetail?.userFeatureFlag || {};

  if (noOfLiAccountsUsed < noOfLiAccountsEligible) {
    if (liAutomationEnabled) {
      if (userDetail.pluginInstalled) {
        linkedInCookiesState.setIsPremiumAccount(false);
        window.parent.postMessage({ reload: true, type: "RELOAD_PLUGIN" }, "*");
        const intervalId = setInterval(() => {
          count++;
          if (linkedInCookiesState?.profileData !== null) {
            if (linkedInCookiesState?.cookieLiAt) {
              clearInterval(intervalId);
              count = 0;
              window.parent.postMessage(
                { reload: true, type: "CLOSE_LINKEDIN" },
                "*"
              );
              setTimeout(() => {
                linkedInReplyState.setLinkedInConnectionLoading(false);
                if (linkedInCookiesState?.cookieLiAt) {
                  linkedInReplyState.setShowLinkedInDetailsPopup(true);
                }
              }, 2000);
              return;
            } else {
              if (count >= 10) {
                window.parent.postMessage(
                  { reload: true, type: "CLOSE_LINKEDIN" },
                  "*"
                );
                clearInterval(intervalId);
                count = 0;
              }
            }
          } else {
            if (
              count >= 3 &&
              linkedInCookiesState?.profileData === null &&
              !linkedInCookiesState?.cookieLiAt
            ) {
              linkedInReplyState.setLinkedInConnectionLoading(false);
              clearInterval(intervalId);
              count = 0;
              window.parent.postMessage(
                { reload: true, type: "CLOSE_LINKEDIN" },
                "*"
              );
              linkedInReplyState.setLinkedInMsg(
                "No active LinkedIn account found on this browser."
              );
              linkedInReplyState.setShowLinkedInNotConnectedPopup(true);
            }
          }
          postingData(false);
        }, 2000);
        linkedInReplyState.setLinkedInConnectionLoading(true);
      } else {
        pluginNotInstalled();
      }
    }
  } else {
    PaywallUtils.upgradeUtilsFunction(
      "multipleLinkedInCustom",
      false,
      planName,
      inFreePlan
    );
  }
};

export { linkedInUtils };
export default linkedInUtils;
