import React from "react";
import sequenceCard from "Assets/png/SequenceCard.png";
import templateCard from "Assets/png/TemplateCard.png";
import contactCard from "Assets/png/ContactsCard.png";
import taskCard from "Assets/png/TasksCard.png";
import teamCard from "Assets/png/TeamCard.png";
import reportCard from "Assets/png/ReportsCard.png";
import settingsCard from "Assets/png/SettingCard.png";
import pricingCard from "Assets/png/PricingCard.png";
import callsCard from "Assets/png/call_graphics.png";
import DiscoverCard from "Assets/png/discoverCard.png";
import trackingCard from "Assets/png/trackingCard.png";
import accountsCard from "Assets/png/accountsCard.png";
import { ReactComponent as OutboxHoverCard } from "Assets/svg/outbox/outboxHoverCard.svg";

const CardTooltip = (props = {}) => {
  const { name = "", title = "" } = props || {};

  const getCardDesc = (name = "") => {
    const tempObj = {
      sequences:
        "Effortlessly schedule and automate your multi-channel strategies",
      discover: "Access 200M+ B2B contacts’ emails and phone numbers",
      templates:
        "Efficiently save and employ your top-performing email content",
      contacts:
        "Effortlessly add, organize, and manage your contacts’ information",
      tasks:
        "Efficiently organize to-do lists, set reminders, and track overdue tasks",
      team: "Invite and manage your team",
      reports: "Gain valuable insights into your team’s performance",
      calls: "Effortlessly schedule, make, and record all your calls.",
      pricing: "See available plans for your subscription",
      settings: "Manage your account and preferences",
      inbox:
        "Centralise and manage all your email messages received, delivered and scheduled",
      tracking:
        "Seamlessly capture and monitor every website visitor’s activity",
      accounts: "Track target account engagements for effective B2B sales"
    };
    return tempObj[name?.toLowerCase()] || "";
  };

  const getCardImage = (name = "") => {
    const tempObj = {
      sequences: sequenceCard,
      discover: DiscoverCard,
      templates: templateCard,
      contacts: contactCard,
      tasks: taskCard,
      team: teamCard,
      reports: reportCard,
      calls: callsCard,
      pricing: pricingCard,
      settings: settingsCard,
      tracking: trackingCard,
      accounts: accountsCard
    };
    return tempObj[name?.toLowerCase()] || "";
  };

  return name && name !== "dashboardNew" ? (
    <div className="cardTooltipWrapper animated fadeInDown ">
      <div className="cardTooltipInnerCont">
        <div className="cardTooltipTextCont">
          <span className="cardTitle">
            {name === "tracking" ? title : name?.toLowerCase()}
          </span>
          <span className="cardDesc">{getCardDesc(name)}</span>
        </div>
        <div className="cardImgCont">
          {name === "tracking" && <span className="betaBadge">BETA</span>}
          {name === "inbox" ? (
            <OutboxHoverCard />
          ) : (
            <img src={getCardImage(name)} alt={name} />
          )}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default CardTooltip;
export { CardTooltip };
