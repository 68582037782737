import React, {useState} from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import DropDownMenu from "Components/common/DropDownMenu";
import Utils from "Utils/utils";

class SnoozeTaskPopupState {
  @observable showPopup = false;

  defaultPopupValue = {
    loadingFn() {},
    callback() {}
  };

  @observable popupValues = this.defaultPopupValue;

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setPopupValues = val => {
    this.popupValues = val;
  };
}

const snoozeTaskPopupstate = new SnoozeTaskPopupState();

const SnoozeTaskPopup = () => {
  const defaultSnoozeSelected = {
    value: "1 hour from now",
    key: "1"
  };
  const [showSnoozeDropDown, setShowSnoozeDropDown] = useState(false);
  const [selectedSnoozeTime, setSelectedSnoozeTime] = useState(
    defaultSnoozeSelected
  );

  const snoozeTask = () => {
    snoozeTaskPopupstate.popupValues.callback(selectedSnoozeTime);
    snoozeTaskPopupstate.setShowPopup(false);
  };

  const showDropDownMenu = (e, value) => {
    if (e) Utils.preventDefaultFn(e);
    setShowSnoozeDropDown(value);
  };

  const selectSnoozeTime = (reference, item) => {
    if (item) {
      showDropDownMenu(null, false);
      setSelectedSnoozeTime({ key: item.key, value: item.value });
    }
  };

  const dropdownOptions = [
    { value: "1 hour from now", key: "1", method: selectSnoozeTime },
    { value: "2 hours from now", key: "2", method: selectSnoozeTime },
    { value: "3 hours from now", key: "3", method: selectSnoozeTime },
    { value: "4 hours from now", key: "4", method: selectSnoozeTime },
    { value: "6 hours from now", key: "6", method: selectSnoozeTime },
    { value: "8 hours from now", key: "8", method: selectSnoozeTime },
    { value: "1 Day from now", key: "24", method: selectSnoozeTime },
    { value: "2 Days from now", key: "48", method: selectSnoozeTime },
    { value: "3 Days from now", key: "72", method: selectSnoozeTime }
  ];

  const DropDownElement = props => {
    return (
      <div className={"p-rel"} onClick={e => showDropDownMenu(e, true)}>
        <span className="scheduledLabel">Rescheduled for</span>
        <span className="d-flex">
          <span className="value">{selectedSnoozeTime.value}</span>
          <i className="material-icons">keyboard_arrow_down</i>
        </span>
        {showSnoozeDropDown && (
          <>
            <DropDownMenu options={dropdownOptions} direction="center" />
            <div
              className="dropdownMask"
              onClick={e => showDropDownMenu(e, false)}
            />
          </>
        )}
      </div>
    );
  };

  // const AlertMeElement = () => {

  //   const alertMeAction = () => {
  //     setAlertMe(!isAlertMeChecked)
  //   }

  //   return (
  //     <>
  //       <div className="">
  //         <div className="checkbox-tick">
  //           <div className="list-item">
  //             <label htmlFor="storePersonal">
  //               <input
  //                 id="storePersonal"
  //                 name="storePersonal"
  //                 type="checkbox"
  //                 className="checkbox"
  //                 checked={isAlertMeChecked}
  //                 onChange={e => alertMeAction(e)}
  //               />
  //             </label>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   )
  // }

  const CloseIcon = () => {
    const closeAction = () => {
      snoozeTaskPopupstate.setShowPopup(false);
      setSelectedSnoozeTime(defaultSnoozeSelected);
    };

    return (
      <div
        className="popup-close"
        title="Click here to close"
        onClick={closeAction}
      >
        <i className="material-icons">close</i>
      </div>
    );
  };

  return snoozeTaskPopupstate.showPopup ? (
    <div className="popupWrapper snoozeTaskPopup">
      <div className="confirmation-popup common-popup">
        <div className="popup-wrap animated customzoomIn">
          <div className="content-container">
            <div className="title">Snooze Task</div>
            <CloseIcon />
            <div className="reschedulingCont">
              <div className="dropDown">
                <DropDownElement />
              </div>
              {/* <div className="alertMeContainer">
              <AlertMeElement />
            </div> */}
            </div>
            <div className="popup-action-wrap">
              <div
                className="popup-action-btn positive-btn"
                onClick={snoozeTask}
              >
                Snooze
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="confirmation-popup-mask" />
    </div>
  ) : (
    ""
  );
};

export { SnoozeTaskPopup, snoozeTaskPopupstate };
export default observer(SnoozeTaskPopup);
