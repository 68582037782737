import React from "react";

const StatusInfo = (props = {}) => {
  const { count = 0, status = "" } = props || {};

  return (
    <div
      className={`statusInfo ${
        ["completed", "all good"].includes(status?.toLowerCase())
          ? "completed"
          : ["pending"].includes(status?.toLowerCase())
          ? "pending"
          : ""
      }`}
    >
      {count > 0 && ["pending"].includes(status?.toLowerCase()) && (
        <span className="count">{count}</span>
      )}
      <span>{status}</span>
    </div>
  );
};

export default StatusInfo;
export { StatusInfo };
