import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import DateUtils from "Utils/DateUtils";
import ArrayUtils from "Utils/ArrayUtils";
import { linkedInReplyPopupState } from "Components/PreviewPopup/LinkedInReplyContentPopup";

let cancelRequest = null;

const checkAndCancelRequest = () => {
  if (cancelRequest !== null) {
    cancelRequest("cancel");
    cancelRequest = null;
  }
};

const cancelApiRequest = value => {
  cancelRequest = value;
};

class ContactTimelineData {
  @observable contactActivityData = "";
  @observable timelineData = "";
  @observable reloadTaskTimelineActivity = false;
  @observable linkedInReplyData = {};

  @action
  setReloadTaskTimelineActivity = (val = false) => {
    this.reloadTaskTimelineActivity = val;
  };

  @action
  setLinkedInReplyData = (val = {}) => {
    this.linkedInReplyData = val;
  };

  @action
  updateTimelineData = (data = []) => {
    if (data?.timeline?.length) {
      let timelineObj = {};
      let newArr = [];
      let valuesToRemove = [];
      data.timeline.map(item => {
        const { month = "", result = [] } = item || {};
        const monthFullYear = DateUtils.getMonthFullYear(result?.[0]?.createTs);
        timelineObj[`${month}-${monthFullYear}`] = result;
        newArr.push(`${month}-${monthFullYear}`);
      });
      this.timelineData.map(item => {
        const { month = "", result = [] } = item || {};
        const monthFullYear = DateUtils.getMonthFullYear(result?.[0]?.createTs);
        if (timelineObj?.[`${month}-${monthFullYear}`]) {
          item.result = [
            ...result,
            ...timelineObj?.[`${month}-${monthFullYear}`]
          ];
          valuesToRemove.push(`${month}-${monthFullYear}`);
        }
      });
      newArr = ArrayUtils.removeBulkValueFromArray(newArr, valuesToRemove);
      if (newArr?.length) {
        newArr.map(item => {
          this.timelineData.push({
            month: item?.split("-")?.[0] || "",
            result: timelineObj?.[item]
          });
        });
      }
      this.contactActivityData = { ...data, timeline: this.timelineData };
    }
  };

  @action
  setContactActivityData = value => {
    if (value.currentPage > 1) {
      this.updateTimelineData(value);
    } else {
      this.contactActivityData = value;
      this.setTimelineData(value.timeline);
    }
  };

  @action
  setTimelineData = value => {
    this.timelineData = value;
  };

  @action
  setPayload = value => {
    this.payload = value;
  };

  @action
  resetValue = () => {
    this.timelineData = "";
    this.contactActivityData = "";
  };

  @action
  getContactActivity = async (contactId = "", data = {}, accountId = "") => {
    try {
      if (contactId || (accountId && data && Object.keys(data)?.length)) {
        const url = accountId
          ? URLS.getAccountTimeline.replace("<<accountId>>", accountId)
          : URLS.getContactTimelineActivity.replace("<<contactId>>", contactId);
        const config = {
          url,
          method: "POST",
          data
        };
        checkAndCancelRequest();
        let res = await makeApi(config, cancelApiRequest);
        this.setContactActivityData(res?.data || {});
      }
    } catch (e) {
      console.error("Getting contact activity data API Failed", e);
    }
  };

  @action
  getLinkedInReplyForActivity = async (contactId = "") => {
    linkedInReplyPopupState.setShowPopup(true);
    linkedInReplyPopupState.setEditorLoading(true);
    linkedInReplyPopupState.setContactId(contactId);
    try {
      if (contactId) {
        const url = `${URLS.getLinkedInReply}/${contactId}`;
        const config = {
          url,
          method: "GET"
        };
        checkAndCancelRequest();
        let res = await makeApi(config, cancelApiRequest);
        const { data = {} } = res || {};
        if (data && Object.keys(data)?.length > 0) {
          linkedInReplyPopupState.setLiMessages(data?.messages);
          linkedInReplyPopupState.setEditorLoading(false);
        }
      }
    } catch (e) {
      console.error("Getting contact activity data API Failed", e);
    }
  };
}

export const ContactTimelineStore = new ContactTimelineData();
