import React from "react";

export const SequenceTabNav = props => {
  const {
    id = "",
    name = "",
    active = false,
    className = "",
    action = () => {}
  } = props || {};

  return (
    <div
      className={`headerCont ${className} ${active ? "tabSelected" : ""}`}
      onClick={() => action(id)}
    >
      <div className="analyticsTxt propertyTxt">{name}</div>
    </div>
  );
};

export default SequenceTabNav;
