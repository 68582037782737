import React from "react";
import { observer } from "mobx-react";
import VariantToggle from "Pages/Dashboard/sequences/SequencePage/VariantToggle";
import { salesforceConnectedData } from "./SalesforceConnectedData";

const ActivityTab = observer(() => {
  const {
    syncSentEnabled = false,
    syncOpenEnabled = false,
    syncClickEnabled = false,
    syncReplyEnabled = false,
    syncPhoneCallEnabled = false,
    syncLiAcceptRequest = false,
    syncLiSendRequest = false,
    syncLiMessageSent = false,
    syncLiReplyReceived = false,
    syncCustomTask = false
  } = salesforceConnectedData.SfSettingPreference || {};

  const activityCheckList = [
    {
      key: "emailSent",
      id: "emailSent",
      value: syncSentEnabled,
      text: "Emails Sent"
    },
    {
      key: "emailOpened",
      id: "emailOpened",
      value: syncOpenEnabled,
      text: "Email Opens"
    },
    {
      key: "emailReplied",
      id: "emailReplied",
      value: syncReplyEnabled,
      text: "Email Replies"
    },
    {
      key: "emailClicked",
      id: "emailClicked",
      value: syncClickEnabled,
      text: "Email Clicks"
    },
    {
      key: "phoneCall",
      id: "phoneCall",
      value: syncPhoneCallEnabled,
      text: "Phone Calls"
    },
    {
      key: "liRequestSent",
      id: "liRequestSent",
      value: syncLiSendRequest,
      text: "LinkedIn connection request sent"
    },
    {
      key: "liRequestAccepted",
      id: "liRequestAccepted",
      value: syncLiAcceptRequest,
      text: "LinkedIn connection request accepted"
    },
    {
      key: "liMessageSent",
      id: "liMessageSent",
      value: syncLiMessageSent,
      text: "LinkedIn message sent"
    },
    {
      key: "liMessageReceived",
      id: "liMessageReceived",
      value: syncLiReplyReceived,
      text: "LinkedIn reply received"
    },
    {
      key: "customTask",
      id: "customTask",
      value: syncCustomTask,
      text: "Custom Task"
    }
  ];

  const mappedConfig = {
    phoneCall: "syncPhoneCallEnabled",
    emailClicked: "syncClickEnabled",
    emailReplied: "syncReplyEnabled",
    emailOpened: "syncOpenEnabled",
    emailSent: "syncSentEnabled",
    liRequestSent: "syncLiSendRequest",
    liRequestAccepted: "syncLiAcceptRequest",
    liMessageSent: "syncLiMessageSent",
    liMessageReceived: "syncLiReplyReceived",
    customTask: "syncCustomTask"
  };

  const setCheckedValue = (e, value, id) => {
    salesforceConnectedData.setSfSettingPreference({
      ...salesforceConnectedData?.SfSettingPreference,
      [mappedConfig?.[id]]: value
    });
  };

  return (
    <div className="activitySalesforceWrapper">
      <h5 className="headingText">Activity Logging</h5>
      <p className="descText">
        Select the events that you want to log to your Salesforce CRM
      </p>
      <div className="checkListBox">
        {activityCheckList?.length > 0 &&
          activityCheckList?.map(item => {
            const {
              id = "",
              text = "",
              value = "",
              disabled = false
            } = item || {};
            return (
              <div key={`${id}-${text}`} className="activityCheckListWrapper">
                <div
                  className={`variantToggleWrapper ${
                    disabled ? "disabled" : ""
                  }`}
                >
                  <VariantToggle
                    action={setCheckedValue}
                    isChecked={value}
                    id={id}
                  />
                  <span className="toggleText">{text}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
});

export { ActivityTab };
export default ActivityTab;
