import React from "react";
import { confirmationPopupState } from "../confirmationPopup";

const AddBulkContactPopupTitle = props => {
  const { userInteracted, closeCbk, name = "Add Contacts" } = props || {};

  const closeAction = () => {
    if (userInteracted) {
      confirmationPopupState.setPopupValues({
        title: "Are you sure",
        content:
          "Are you sure you want to close this popup ?<br> Any changes made will be lost.",
        actionBtnText: "Yes",
        callback: closeCbk
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      closeCbk();
    }
  };

  return (
    <div className="popupHeader">
      <div className="title">{name}</div>
      <div
        className="popup-close"
        title="Click here to close"
        onClick={() => closeAction()}
      >
        <i className="material-icons">close</i>
      </div>
    </div>
  );
};
export default AddBulkContactPopupTitle;
export { AddBulkContactPopupTitle };
