import React from "react";

const ConfigurationPageLoader = () => {
  return (
    <div className="accountConfigurationContLoader">
      <div className="connectedAccountEmail boxLoader">
        <span className="calendarImg linear-background" />
        <span className="text linear-background" />
        <span className="emailText linear-background" />
      </div>
      <div className="brandLogoToggle">
        <span className="toggleBox linear-background" />
        <span className="toggleText linear-background" />
      </div>
    </div>
  );
};

export default ConfigurationPageLoader;
