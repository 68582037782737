/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import { TemplateDataStore } from "Stores/Template";
import { toasterState } from "Components/common/toaster";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import TemplateFolderList from "./TemplateFolderList";
import FolderLoader from "Components/common/FolderLoader";
import CreateFolder from "Components/common/CreateFolder";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { useDrop } from "react-dnd";

const TemplateFolderComponent = observer(props => {
  const [loader, setLoader] = useState(true),
    [folderList, setFolderList] = useState(TemplateDataStore.folderList),
    [folderLoader, setFolderLoader] = useState(false),
    [showCreateNewFolder, setCreateNewFolder] = useState(
      TemplateDataStore.showCreateNewFolder
    ),
    [folderName, setFolderName] = useState("New Folder");
  const [editedFolderName, setEditedFolderName] = useState("");

  const getAllFolders = () => {
      setLoader(true);
      TemplateDataStore.getAllFolderTemplates();
    },
    updateFolderList = folderData => {
      if (folderData !== "") {
        constructFolderDataWithFlags(folderData);
        setLoader(false);
        setFolderLoader(false);
      }
    },
    createNewFolder = async name => {
      const config = {
        url: URLS.createNewTemplateFolder ? URLS.createNewTemplateFolder : "",
        method: "POST",
        data: {
          name
        }
      };
      let res = await makeApi(config);
      if (res && res.data) {
        const constructedFolders = [{ ...res.data }, ...folderList];
        TemplateDataStore.setFolderList(constructedFolders);
        setFolderLoader(false);
        setFolderName("New Folder");
        toasterState.setToastMsg("Folder created successfully", "success");
        props.history.push(res.data.id);
        TemplateDataStore.showCreateNewFolder = false;
      } else {
        setFolderLoader(false);
        TemplateDataStore.showCreateNewFolder = true;
        toasterState.setToastMsg(
          `${name} is already taken. Please choose a different name`,
          "fail"
        );
      }
    },
    createFolder = event => {
      Utils.preventDefaultFn(event);
      if (folderName && folderName.length) {
        setFolderLoader(true);
        createNewFolder(folderName);
      }
    },
    cancelNewTemplateFolder = () => {
      TemplateDataStore.showCreateNewFolder = false;
      setFolderName("New Folder");
    },
    focusOnFolderName = event => {
      if (event) event.target.select();
    },
    changeOnFolderName = event => {
      if (event !== undefined && event !== null) {
        const { value } = event.currentTarget;
        setFolderName(value);
      }
    };

  const constructFolderDataWithFlags = folderData => {
    if (folderData) {
      folderData &&
        folderData.map(value => {
          value.isEdit = false;
          value.hover = false;
        });
      setFolderList(folderData);
    }
  };

  const editFolderNameAction = event => {
    if (event !== undefined && event !== null) {
      const { value } = event.currentTarget;
      setEditedFolderName(value);
    }
  };

  const cancelEditFolder = (event, id) => {
    Utils.preventDefaultFn(event);
    updateEditInputFlag(id);
    setEditedFolderName("");
  };

  const updateEditInputFlag = (id, updatedData) => {
    if (folderList) {
      const tempData = updatedData ? updatedData : folderList;
      tempData.map(value => {
        if (value && value.id === id) {
          if (value.isEdit) {
            value.isEdit = false;
          } else {
            value.isEdit = true;
          }
        }
      });
      setFolderList(JSON.parse(JSON.stringify(tempData)));
    }
  };

  const updateEditIconFlag = (id, flag, updatedData) => {
    if (folderList) {
      const tempData = updatedData ? updatedData : folderList;
      tempData.map(value => {
        if (value && value.id === id) {
          if (flag === "enter") {
            value.hover = true;
          } else if (flag === "leave") {
            value.hover = false;
          }
        }
      });
      setFolderList(JSON.parse(JSON.stringify(tempData)));
    }
  };

  const editAction = (e, id, name) => {
    Utils.preventDefaultFn(e);
    if (id && name) {
      setEditedFolderName(name);
      updateEditInputFlag(id);
    }
  };

  const toggleEditIcon = (e, id, flag) => {
    Utils.preventDefaultFn(e);
    if (id && flag) {
      updateEditIconFlag(id, flag);
    }
  };

  const saveEditedFolder = async (e, id) => {
    Utils.preventDefaultFn(e);
    setFolderLoader(true);
    const payload = { id, name: editedFolderName };
    if (payload) {
      const updatedData = await TemplateDataStore.updateTemplateFolderName(
        payload,
        editedFolderName
      );
      setFolderLoader(false);
      if (updatedData) {
        constructFolderDataWithFlags(updatedData);
      }
    }
  };

  const deleteFolderAction = (e, id) => {
    Utils.preventDefaultFn(e);
    showDeleteConfirmationPopup(id);
  };

  const deleteFolderSuccess = (deletedFolderId, buttonLoadingFn) => {
    if (deletedFolderId && buttonLoadingFn) {
      const existingFolderList = JSON.parse(
        JSON.stringify(TemplateDataStore.folderList)
      );
      const tempFolder = [];
      if (existingFolderList && existingFolderList.length) {
        existingFolderList.map(value => {
          const { id } = value;
          if (id && id !== deletedFolderId) {
            tempFolder.push(value);
          }
        });
        TemplateDataStore.setFolderList(tempFolder);
      }
      buttonLoadingFn(false);
      confirmationPopupState.setShowPopup(false);
      toasterState.setToastMsg(
        "Templates folder deleted successfully",
        "success"
      );
      props && props.history && props.history.push("/templates/all");
    }
  };

  const showDeleteConfirmationPopup = id => {
    const deleteFolderCbk = buttonLoadingFn => {
      if (id) {
        TemplateDataStore.deleteTemplateFolder(
          id,
          deleteFolderSuccess,
          buttonLoadingFn
        );
      }
    };

    confirmationPopupState.setPopupValues({
      content:
        "This action will also delete all the templates that are present in this folder. Please confirm to proceed",
      actionBtnText: "Yes",
      callback: deleteFolderCbk,
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  useEffect(() => {
    getAllFolders();
  }, []);

  useEffect(() => {
    updateFolderList(TemplateDataStore.folderList);
  }, [TemplateDataStore.folderList]);

  useEffect(() => {
    setCreateNewFolder(TemplateDataStore.showCreateNewFolder);
  }, [TemplateDataStore.showCreateNewFolder]);

  return (
    <aside id="templateFolderSection">
      {!loader ? (
        <>
          <MyTemplatesFolder />
          <SharedTemplatesFolder />
          <ul className="template-folder">
            {showCreateNewFolder && (
              <CreateFolder
                saveNewFolder={createFolder}
                newFolderNameFocus={focusOnFolderName}
                newFolderNameAction={changeOnFolderName}
                newFolderNameCancelAction={cancelNewTemplateFolder}
                newFolderName={folderName}
                folderLoader={folderLoader}
              />
            )}
            <TemplateFolderList
              folderData={folderList}
              editFolder={editAction}
              savedEditFolder={saveEditedFolder}
              newFolderNameFocus={focusOnFolderName}
              editFolderNameAction={editFolderNameAction}
              cancelEditFolder={cancelEditFolder}
              editedFolderName={editedFolderName}
              toggleEditIcon={toggleEditIcon}
              folderLoader={folderLoader}
              deleteFolder={deleteFolderAction}
            />
          </ul>
        </>
      ) : (
        <FolderLoader loadingNumber={15} />
      )}
    </aside>
  );
});

const MyTemplatesFolder = observer(() => {
  const removeTemplateFromList = (obj = "") => {
    const { id = "", isSharedFolder = false } = obj || {};
    TemplateDataStore.setTempTemplateList(TemplateDataStore.templateList);
    let tempList = [...(TemplateDataStore?.templateList || [])];
    if (isSharedFolder) {
      return tempList;
    }
    return tempList.filter(item => item.id !== id && item);
  };

  const moveTemplateToAnotherFolder = async (
    item = {},
    showUndoButton = true
  ) => {
    !showUndoButton && toasterState.close();
    if (item?.id && item?.folderId) {
      let removedList = showUndoButton
        ? removeTemplateFromList(item)
        : TemplateDataStore.tempTemplateList;
      const config = {
        url: URLS.moveTemplateToAnotherFolder
          .replace("<<templateId>>", item.id)
          .replace("<<folderId>>", showUndoButton ? null : item?.folderId)
      };
      const res = await makeApi(config);
      const { status = "", success = false } = res?.data || {};
      Utils.mixpanelTrack(MP_EVENT.TP_DRAG_DROP_PERFORMED, {
        pageType: "Templates"
      });
      if (+status === 200 && success) {
        TemplateDataStore.templateData.templates = removedList;
        TemplateDataStore.setTemplateList(removedList);
        !showUndoButton && TemplateDataStore.setTempTemplateList("");
        toasterState.setPopupValues({
          showUndoButton,
          callback: () => {
            showUndoButton && moveTemplateToAnotherFolder(item, false);
          }
        });
        let name = showUndoButton ? "My Templates" : item?.folderName;
        let msg = showUndoButton
          ? `to ${name} successfully.`
          : `back to ${name}`;
        toasterState.setToastMsg(`Template moved ${msg}`, "success");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: "moveTemplate",
    drop: moveTemplateToAnotherFolder,
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <div
      className={`default-template-folder ${isOver ? "dropOver" : ""}`}
      ref={dropRef}
    >
      <NavLink
        exact={true}
        activeClassName={"active"}
        to="/templates/all"
        className="template-folder-name"
      >
        <i className="material-icons-outlined">folder</i>
        <span className="template-folder-text">My Templates</span>
        {isOver && (
          <span className="material-icons-outlined dropHereText template">
            drag_handle
          </span>
        )}
      </NavLink>
    </div>
  );
});

const SharedTemplatesFolder = () => {
  return (
    <div className="default-template-folder">
      <NavLink
        exact={true}
        activeClassName={"active"}
        to="/templates/shared"
        className="template-folder-name"
      >
        <i className="material-icons-outlined">folder</i>
        <span className="template-folder-text">Shared Templates</span>
      </NavLink>
    </div>
  );
};

export default withRouter(TemplateFolderComponent);
