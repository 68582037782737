import React from "react";
import { observer } from "mobx-react";
import { Switch, Redirect, Route, withRouter } from "react-router-dom";
import Inbox from "../ConnectInbox/Inbox";
import LinkedInAccounts from "../ConnectedAccounts/LinkedInAccounts/main";

const CrmRouting = observer((props = {}) => {
  return (
    <Switch>
      <Route
        exact
        path="/settings/account/email"
        render={() => <Inbox {...props} />}
      />
      <Route
        exact
        path="/settings/account/linkedin"
        render={() => <LinkedInAccounts {...props} />}
      />
      <Redirect from="/settings/account" to="/settings/account/email" />
    </Switch>
  );
});

export default withRouter(CrmRouting);
