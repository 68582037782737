/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import MultiSelectDropdownWithInput from "Components/common/MultiSelectDropDownWithInput";
import { PreviewTags } from "./PreviewTags";
import TagPopupHeader from "./TagPopupHeader";
import TagPopupButtons from "./TagPopupButtons";
import { confirmationPopupState } from "../confirmationPopup";
import { tagsStoreData } from "Stores/TagsData";
import Utils from "Utils/utils";
import ContactTagsAnimation from "Utils/LottieFiles/contactTags.json";

const ContactTagsLottie = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ContactTagsAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div
      className="contactTagAnimationWrapper"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Lottie
        options={defaultOptions}
        width={250}
        height={140}
        style={{ margin: "0" }}
        speed={1.5}
      />
    </div>
  );
};

class TagPopupState {
  @observable showPopup = false;
  @observable popupValues = {};

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setPopupValues = values => {
    this.popupValues = values;
  };
}

const tagPopupState = new TagPopupState();

const TagPopup = () => {
  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const closeAction = () => {
    setTagList(Utils.createObjWithoutRef(tagsStoreData.tagsData));
    setSelectedTags([]);
    setShowOptions(false);
    tagPopupState.setShowPopup(false);
  };

  const showNextPopup = () => {
    const { cbk } = tagPopupState.popupValues;
    cbk && cbk(selectedTags);
    closeAction();
  };

  const updateSelectedTags = () => {
    const updatedTags = JSON.parse(JSON.stringify(tagList));
    if (updatedTags && updatedTags.length) {
      const tempArr = [];
      updatedTags.map(value => {
        const { checked } = value;
        if (checked) {
          tempArr.push(value);
          if (!nextBtnEnabled) {
            // to enable the next button if one tag is selected
            setNextBtnEnabled(true);
          }
        }
      });
      setSelectedTags(tempArr);
      if (tempArr && tempArr.length === 0) {
        // to disable the next button if none of the tags are selected
        setNextBtnEnabled(false);
      }
    }
  };

  const removeTag = removedTagId => {
    const tagListArr = JSON.parse(JSON.stringify(tagList));
    const selectedTagList = JSON.parse(JSON.stringify(selectedTags));

    let tagListIdx = tagListArr.findIndex(x => x.id === removedTagId);
    if (tagListIdx > -1) {
      tagListArr[tagListIdx].checked = false;
      setTagList(tagListArr);
    }

    let selectedTagIdx = selectedTagList.findIndex(x => x.id === removedTagId);
    if (selectedTagIdx > -1) {
      selectedTagList.splice(selectedTagIdx, 1);
      setSelectedTags(selectedTagList);
    }
  };

  const closeSchedulePopup = () => {
    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to close? All added contacts will be removed`,
      actionBtnText: "Yes",
      callback: closeAction
    });
    confirmationPopupState.setShowPopup(true);
  };

  const getPopupDesc = () => {
    return "Adding tags would allow you to easily filter your contacts and perform actions like adding to sequence or exporting them";
  };

  useEffect(() => {
    updateSelectedTags();
  }, [tagList]);

  useEffect(() => {
    if (tagsStoreData.tagsData) {
      setTagList(Utils.createObjWithoutRef(tagsStoreData.tagsData));
    }
  }, [tagsStoreData.tagsData]);

  return tagPopupState.showPopup ? (
    <div className="popupWrapper tagPopup">
      <div className="confirmation-popup common-popup">
        <div className="popup-wrap animated customzoomIn" autoComplete="off">
          <TagPopupHeader
            showOptions={showOptions}
            closeAction={closeSchedulePopup}
            setShowOptions={setShowOptions}
          />
          <div className="bodyCont">
            <ContactTagsLottie />
            <div className="addTagSelectBox">
              <p className="innerText">{getPopupDesc()}</p>
              <MultiSelectDropdownWithInput
                label={"Select Tag"}
                options={tagList}
                setOptions={setTagList}
                showOptions={showOptions}
                setShowOptions={setShowOptions}
              />
            </div>
            <PreviewTags selectedTags={selectedTags} removeTag={removeTag} />
            <TagPopupButtons
              btnAction={showNextPopup}
              nextBtnEnabled={nextBtnEnabled}
            />
          </div>
        </div>
      </div>
      <div className="common-popup-mask"></div>
    </div>
  ) : (
    ""
  );
};

export { TagPopup, tagPopupState };
export default observer(TagPopup);
