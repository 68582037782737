export const DATE_FILTER_LIST = [
  {
    name: "Last Week",
    id: "lastWeek",
    value: "lastWeek"
  },
  {
    name: "Yesterday",
    id: "filterYesterdayDate",
    value: "yesterday"
  },
  {
    name: "Today",
    id: "filterTodayDate",
    value: "today"
  },
  {
    name: "This Month",
    id: "thisMonth",
    value: "thisMonth"
  },
  {
    name: "7D",
    id: "7d",
    value: "7d"
  },
  {
    name: "30D",
    id: "30d",
    value: "30d"
  }
];
