import React from "react";

const DonutChart = ({ data = [], loader = false }) => {
  const total = data.reduce((acc, { value }) => acc + value, 0);

  const onlyOneValueGreaterThanZero =
    [...data].filter(({ value }) => value > 0).length === 1;

  // If there's only one value greater than zero, find that value and color
  let singleValue = null;
  if (onlyOneValueGreaterThanZero) {
    singleValue = [...data].find(({ value }) => value > 0);
  }

  // If total is zero, fill the entire donut with #8d8d8d
  if (!total || onlyOneValueGreaterThanZero) {
    return (
      <div style={{ width: "60%", height: "60%" }}>
        <svg viewBox="-100 -100 200 200" width="100%" height="100%">
          <circle
            cx="0"
            cy="0"
            r="80"
            fill={`${singleValue ? singleValue?.color : "#dddd"}`}
          />
          <circle cx="0" cy="0" r="50" fill="#fff" />
        </svg>
      </div>
    );
  }

  // Initialize variables for calculating angles and radii
  const centerX = 0;
  const centerY = 0;
  const innerRadius = 50; // You can adjust the inner radius as needed
  const outerRadius = 80;

  // Calculate angles for each data point
  let startAngle = -Math.PI / 2;
  const slices = data.map(({ value, color }, index) => {
    const endAngle = startAngle + (value / total) * Math.PI * 2;

    // Construct path data for slice
    const pathData = `
      M ${Math.cos(startAngle) * innerRadius} ${
      Math.sin(startAngle) * innerRadius
    }
      A ${innerRadius} ${innerRadius} 0 ${
      endAngle - startAngle > Math.PI ? 1 : 0
    } 1 ${Math.cos(endAngle) * innerRadius} ${Math.sin(endAngle) * innerRadius}
      L ${Math.cos(endAngle) * outerRadius} ${Math.sin(endAngle) * outerRadius}
      A ${outerRadius} ${outerRadius} 0 ${
      endAngle - startAngle > Math.PI ? 1 : 0
    } 0 ${Math.cos(startAngle) * outerRadius} ${
      Math.sin(startAngle) * outerRadius
    }
      Z`;

    startAngle = endAngle;

    // Return slice element
    return <path key={index} d={pathData} fill={color} />;
  });

  // Return SVG element with slices
  return (
    <div style={{ width: "60%", height: "60%" }}>
      <svg viewBox="-100 -100 200 200" width="100%" height="100%">
        {slices}
      </svg>
    </div>
  );
};

export default DonutChart;
