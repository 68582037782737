import React from "react";
import Utils from "Utils/utils";

const LabelComponent = (props = {}) => {
  const { labelArr = [], loader = false } = props || {};
  const nonEmptyFlag = [...labelArr].some(({ value }) =>
    typeof value === "string" ? parseFloat(value) > 0 : value > 0
  );

  const formatMetricsValue = (label = "", value = "") => {
    return !["opened", "replied", "clicked"].includes(label?.toLowerCase())
      ? Utils.formatNumberToStringInUSFormat(value)
      : value;
  };

  return (
    <div className="labelList">
      {!loader ? (
        labelArr?.length > 0 &&
        labelArr.map((item, index) => {
          const { label = "", value = "", color = "" } = item || {};
          return (
            <div className="legendCont" key={index}>
              <div className="legendNameAndIcon">
                <span
                  className="legendIcon"
                  style={{ background: `${nonEmptyFlag ? color : "#dddd"}` }}
                ></span>
                <span className="labelIcon">{label}</span>
              </div>
              <div className="value">{formatMetricsValue(label, value)}</div>
            </div>
          );
        })
      ) : (
        <LabelLoader />
      )}
    </div>
  );
};

const LabelLoader = () => {
  return Array.from({ length: 3 }).map((_, index) => (
    <div className="legendCont">
      <div className="legendNameAndIconLoader">
        <span className="legendIconLoader linear-background" />
        <span className="labelIconLoader linear-background" />
      </div>
      <div className="valueLoader linear-background" />
    </div>
  ));
};

export default LabelComponent;
export { LabelComponent };
