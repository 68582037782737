/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import SecondaryCheckbox from "Components/common/SecondaryCheckbox";
import makeApi, { URLS } from "Utils/apiURL";
import { MP_EVENT } from "Static/constant";
import { toasterState } from "Components/common/toaster";
import NumberInputBox from "Components/common/NumberInputBox";
import AutoCompleteDropdown from "Components/common/AutoCompleteDropdown";
import Button from "Components/common/Button";
import scheduleDataStore from "Stores/ScheduleData";
import { weekDays, schdeuleWindowMessage } from "Static/constant";
import MultiInboxSelection from "./MultiInboxSelection";
import { sequenceStoreData } from "Stores/sequenceData";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import userDetail from "Stores/userDetail";

const CreateNewSchedule = () => {
  const history = useHistory();

  const handleScheduleRedirection = () => {
    history.push("/settings/schedules");
  };

  return (
    <div className="createNewScheduleBtn" onClick={handleScheduleRedirection}>
      <i className="material-icons">add</i>
      <span className="btnText">Create new schedule</span>
    </div>
  );
};

const SequenceThrottlingSettings = observer(props => {
  const {
    sequenceId = "",
    loader = false,
    setThrottlingData = () => {},
    setSavedChanges = () => {},
    accessType = false,
    dailyEnrollingCount,
    maximumActiveCount,
    scheduleWindowId = "",
    inboxIds: connectedInboxIds,
    isSharedSequence,
    reassignInbox = false,
    excludeContactEnabled = false,
    emailValidationEnabled = false,
    stopEmailOnMeetingBooked = false,
    sequence = {},
    stopEmailOnReplyReceived = false,
    stopEmailOnLinkedInReplyReceived = false,
    ...remainingProps
  } = props || {};

  const { calendarEnabled = false } = userDetail?.userInfo || {};

  const { calendarIntegrationEnabled = false, liAutomationEnabled = false } =
    userDetail?.userFeatureFlag || {};

  const [maxOngoingContacts, setMaxOngoingContacts] =
    useState(maximumActiveCount);
  const [maxContactsAdd, setMaxContactsAdd] = useState(dailyEnrollingCount);
  const [isEnabled, setEnabled] = useState(true);
  const [loading, setLoading] = useState("");
  const [activeErrMsg, setActiveErrMsg] = useState("");
  const [contactsEnrollErrMsg, setContactsEnrollErrMsg] = useState("");
  const [scheduleId, setScheduleId] = useState(scheduleWindowId);
  const [scheduleWindow, setScheduleWindow] = useState({});
  const [reAssignContact, setReAssignContact] = useState(reassignInbox);
  const [isInboxMappedToSequence, setIsInboxMappedToSequence] = useState(false);
  const [isContactExclusive, setContactExclusive] = useState(
    excludeContactEnabled
  );
  const [isEmailValidationEnabled, setEmailValidationEnabled] = useState(
    emailValidationEnabled
  );
  const [isStopEmailOnMeetingBooked, setStopEmailOnMeetingBooked] = useState(
    stopEmailOnMeetingBooked
  );
  const [isStopEmailOnReplyReceived, setStopEmailOnReplyReceived] = useState(
    stopEmailOnReplyReceived
  );
  const [
    isStopEmailOnLinkedInReplyReceived,
    setIsStopEmailOnLinkedInReplyReceived
  ] = useState(stopEmailOnLinkedInReplyReceived);

  let tempWeekDays = [];

  const handleSetAction = (id = "", value = "") => {
    sequenceStoreData.setTempThrottlingData({
      ...sequenceStoreData?.tempThrottlingData,
      [id]: value
    });
  };

  const changeMaxOngoingContacts = value => {
    setEnabled(false);
    setMaxOngoingContacts(value);
    handleSetAction("maximumActiveCount", value);
  };

  const changeMaxContactsAdded = value => {
    setEnabled(false);
    setMaxContactsAdd(value);
    handleSetAction("dailyEnrollingCount", value);
  };

  const validCountRange = () => {
    return (
      maxContactsAdd > 0 &&
      maxContactsAdd <= 100000 &&
      maxOngoingContacts > 0 &&
      maxOngoingContacts <= 100000
    );
  };

  const getSelectedConnectedInboxIds = () => {
    return sequenceStoreData?.selectedConnectedInbox?.map(item => item.id);
  };

  const saveSequenceSetting = async () => {
    if (!loader && sequenceId && validCountRange()) {
      setLoading(true);
      const selectedInboxIds = getSelectedConnectedInboxIds();
      try {
        const config = {
          url: URLS.saveSequenceSetting,
          method: "POST",
          data: {
            id: sequenceId,
            scheduleWindowId: scheduleWindow?.id,
            settings: {
              dailyEnrollingCount: maxContactsAdd,
              maximumActiveCount: maxOngoingContacts
            },
            inboxIds: selectedInboxIds,
            reassignInbox: reAssignContact,
            excludeContactEnabled: isContactExclusive,
            emailValidationEnabled: isEmailValidationEnabled,
            stopEmailOnMeetingBooked: isStopEmailOnMeetingBooked,
            stopEmailOnReplyReceived: isStopEmailOnReplyReceived,
            stopEmailOnLinkedInReplyReceived: isStopEmailOnLinkedInReplyReceived
          }
        };
        const res = await makeApi(config);
        if (res?.data) {
          setEnabled(true);
          setThrottlingData({
            scheduleWindowId,
            ...{
              dailyEnrollingCount: maxContactsAdd,
              maximumActiveCount: maxOngoingContacts,
              inboxIds: selectedInboxIds,
              excludeContactEnabled: isContactExclusive,
              emailValidationEnabled: isEmailValidationEnabled,
              stopEmailOnMeetingBooked: isStopEmailOnMeetingBooked,
              stopEmailOnReplyReceived: isStopEmailOnReplyReceived,
              stopEmailOnLinkedInReplyReceived:
                isStopEmailOnLinkedInReplyReceived
            }
          });
          setSavedChanges(true);
          setScheduleId(scheduleWindow?.id);
          toasterState.setToastMsg(
            "Succesfully updated the settings",
            "success"
          );
          Utils.mixpanelTrack(MP_EVENT.SQ_SETTING_SAVED, {
            pageType: "Sequence - Setting",
            sequenceId
          });
        } else if (res?.response?.status === 403) {
          confirmationPopupState.setPopupValues({
            content:
              "Failed. You do not have sufficient privileges to update this sequence. Please contact the sequence owner.",
            actionBtnText: "Ok",
            needCancelBtn: false,
            callback: () => {
              confirmationPopupState.setShowPopup(false);
            }
          });
          confirmationPopupState.setShowPopup(true);
        } else {
          toasterState.setToastMsg("Failed to update the settings", "fail");
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Failed on updating sequence throttling settings", err);
      }
    } else if (maxOngoingContacts < 1 || maxOngoingContacts > 100000) {
      setActiveErrMsg("Please enter a value between 1 to 100000");
      setTimeout(() => {
        setActiveErrMsg("");
      }, 1500);
    } else if (maxContactsAdd < 1 || maxContactsAdd > 100000) {
      setContactsEnrollErrMsg("Please enter a value between 1 to 100000");
      setTimeout(() => {
        setContactsEnrollErrMsg("");
      }, 1500);
    }
  };

  const submitAction = event => {
    Utils.isSubmitKeyAction(event) && saveSequenceSetting();
  };

  const settingsObjArr = [
    {
      type: "number",
      id: "maxmimumOngoingContacts",
      changeCbk: changeMaxOngoingContacts,
      min: 1,
      max: 100000,
      value: maxOngoingContacts,
      name: "maxmimumOngoingContacts",
      label: "Max contacts ongoing in this sequence",
      placeholder: "Enter the value between 1 to 100000",
      subText:
        "This number determines the maximum number of active contacts that can be in the sequence at a given time.",
      errMsg: activeErrMsg,
      readOnly: !accessType,
      submitCbk: submitAction,
      loader: loader
    },
    {
      type: "number",
      id: "maxmimumContactsAdded",
      changeCbk: changeMaxContactsAdded,
      min: 1,
      max: 100000,
      value: maxContactsAdd,
      name: "maxmimumContactsAdded",
      label: "Max contacts added to this sequence per 24-hour rolling period",
      placeholder: "Enter the value between 1 to 100000",
      subText:
        "This number determines the maximum number of contacts that can be activated and added to the first step of the sequence every 24 hours.",
      errMsg: contactsEnrollErrMsg,
      readOnly: !accessType,
      submitCbk: submitAction,
      loader: loader
    }
  ];

  const scheduleWindowCbk = item => {
    setScheduleWindow({ ...item });
    handleSetAction("scheduleWindowId", item?.id);
  };

  const getUpdatedTimeRange = () => {
    const dailyTimeRange = scheduleWindow?.dailyTimeRange;

    for (var day in weekDays) {
      const initialTimeRange = dailyTimeRange?.[day]?.map(item => {
        const { fromTime = "", toTime = "" } = item;
        return `${Utils.getCustomTime(fromTime)} - ${Utils.getCustomTime(
          toTime
        )}`;
      });

      tempWeekDays.push({
        long: weekDays[day]?.long,
        timeRange: initialTimeRange
      });
    }
    return tempWeekDays;
  };

  const getScheduleWindow = () => {
    const tempWeekDays = getUpdatedTimeRange();

    return tempWeekDays?.map((item, index) => {
      const { long = "", timeRange = [] } = item || {};
      return (
        <div
          className="daysWithTimeWrapper"
          key={`daysWithTimeWrapper${index}`}
        >
          <span className="weekDays">{long}</span>
          <span className={`TimeRange ${timeRange?.[0] ? "" : "emptyResult"}`}>
            {timeRange?.[0] || "--"}
          </span>
        </div>
      );
    });
  };

  const getDefaultScheduleData = () => {
    const tempData = scheduleDataStore?.scheduleData?.data;
    const filteredData = tempData?.filter(item => item.id === scheduleId)?.[0];
    setScheduleWindow({ ...filteredData });
    handleSetAction("scheduleWindowId", filteredData?.id);
    scheduleDataStore.setDefaultData({ ...filteredData });
  };

  const redirectToScheduleSettings = () => {
    Utils.mixpanelTrack(MP_EVENT.SQ_SETTING_HERE_CLICKED, {
      pageType: "Sequence - Setting",
      sequenceId
    });
    props.history.push("/settings/schedules");
  };

  const handleContactExcluded = (value = "", id = "") => {
    if (id) {
      setEnabled(false);
      setContactExclusive(value);
      handleSetAction(id, value);
    }
  };

  const handleEmailValidation = (value = "", id = "") => {
    if (id) {
      setEnabled(false);
      setEmailValidationEnabled(value);
      handleSetAction(id, value);
    }
  };

  const handleStopEmailOnMeetingBooked = (value = "", id = "") => {
    if (id) {
      setEnabled(false);
      setStopEmailOnMeetingBooked(value);
      handleSetAction(id, value);
    }
  };

  const hanldeStopEmailOnReplyReceived = (value = "", id = "") => {
    if (id) {
      setEnabled(false);
      setStopEmailOnReplyReceived(value);
      handleSetAction(id, value);
    }
  };

  const handleStopLinkedInOnReplyReceived = (value = "", id = "") => {
    if (id) {
      setEnabled(false);
      setIsStopEmailOnLinkedInReplyReceived(value);
      handleSetAction(id, value);
    }
  };

  const safetyCheckboxList = [
    {
      label:
        "Do not add contact to this sequence, if it was previously added to another sequence.",
      id: "excludeContactEnabled",
      cbk: handleContactExcluded,
      checked: isContactExclusive,
      show: true
    },
    {
      label:
        "Validate the e-mail addresses before adding them to the sequence.",
      id: "emailValidationEnabled",
      cbk: handleEmailValidation,
      checked: isEmailValidationEnabled,
      show: true
    },
    {
      label: "Stop sequence when meeting booked.",
      id: "stopEmailOnMeetingBooked",
      cbk: handleStopEmailOnMeetingBooked,
      checked: isStopEmailOnMeetingBooked,
      show: calendarEnabled && calendarIntegrationEnabled
    },
    {
      label: "Stop sequence when an email reply is received.",
      id: "stopSequenceWhenReplyIsReceived",
      cbk: hanldeStopEmailOnReplyReceived,
      checked: isStopEmailOnReplyReceived,
      show: true
    },
    {
      label: "Stop sequence when a LinkedIn reply is received.",
      id: "stopLinkedInSequenceWhenReplyIsReceived",
      cbk: handleStopLinkedInOnReplyReceived,
      checked: isStopEmailOnLinkedInReplyReceived,
      show: liAutomationEnabled
    }
  ];

  const constructInboxIds = () => {
    if (sequence?.inboxIds?.length) {
      const tempArr = [];
      let inboxes =
        Utils.createObjWithoutRef(userDetail?.connectedInboxes) || [];
      inboxes?.map((item = {}) => {
        const { id = "", email = "", status = "" } = item;
        if (sequence.inboxIds.includes(id) && status === "ACTIVE") {
          tempArr.push({ id: id, email: email });
        }
      });
      sequenceStoreData.setSelectedConnectedInbox(tempArr);
    } else {
      sequenceStoreData.setSelectedConnectedInbox([]);
    }
    setIsInboxMappedToSequence(true);
  };

  useEffect(() => {
    getDefaultScheduleData(scheduleDataStore?.scheduleData);
  }, [scheduleId, scheduleDataStore.scheduleData]);

  useEffect(() => {
    setMaxOngoingContacts(maximumActiveCount);
    setMaxContactsAdd(dailyEnrollingCount);
    setScheduleId(scheduleWindowId);
    setReAssignContact(reassignInbox);
    setContactExclusive(excludeContactEnabled);
    setEmailValidationEnabled(emailValidationEnabled);
    setStopEmailOnMeetingBooked(stopEmailOnMeetingBooked);
    setStopEmailOnReplyReceived(stopEmailOnReplyReceived);
    setIsStopEmailOnLinkedInReplyReceived(stopEmailOnLinkedInReplyReceived);
    sequenceStoreData.setTempThrottlingData({
      ...sequenceStoreData?.tempThrottlingData,
      dailyEnrollingCount,
      maximumActiveCount,
      scheduleWindowId,
      reassignInbox,
      excludeContactEnabled,
      emailValidationEnabled,
      stopEmailOnMeetingBooked,
      stopEmailOnReplyReceived,
      stopEmailOnLinkedInReplyReceived
    });
  }, [
    dailyEnrollingCount,
    maximumActiveCount,
    scheduleWindowId,
    reassignInbox,
    excludeContactEnabled,
    emailValidationEnabled,
    stopEmailOnMeetingBooked,
    stopEmailOnReplyReceived,
    stopEmailOnLinkedInReplyReceived
  ]);

  useEffect(() => {
    Utils.mixpanelTrack(MP_EVENT.SQ_SETTING_VIEWED, {
      pageType: "Sequence - Setting",
      sequenceId
    });
  }, []);

  useEffect(() => {
    if (typeof sequence?.inboxIds != "undefined") {
      constructInboxIds();
    }
  }, [sequence?.inboxIds, userDetail?.connectedInboxes]);

  return (
    <>
      <MultiInboxSelection
        sequenceMemberId={sequence?.memberId}
        sequenceId={sequenceId}
        connectedInboxIds={connectedInboxIds}
        isSharedSequence={isSharedSequence}
        reAssignContact={reAssignContact}
        setReAssignContact={setReAssignContact}
        isInboxMappedToSequence={isInboxMappedToSequence}
        {...remainingProps}
      />
      <div className="sequenceScheduleSettings">
        <h6>Schedules</h6>
        <p className="subTextForSettings">
          <span dangerouslySetInnerHTML={{ __html: schdeuleWindowMessage }} />
          <button className="hereText" onClick={redirectToScheduleSettings}>
            here.
          </button>
        </p>
        <div className="scheduleForm">
          {Object.keys(scheduleWindow).length > 0 ? (
            <>
              <AutoCompleteDropdown
                optionList={scheduleDataStore?.scheduleData?.data}
                defaultValue={scheduleWindow?.name}
                cbk={scheduleWindowCbk}
              >
                <CreateNewSchedule />
              </AutoCompleteDropdown>
              <div className="sendingWindow">
                <div className="scheduleHeader">Sending window</div>
                <div className="flexCenter">
                  <div className="scheduleWindowPanel">
                    {getScheduleWindow()}
                  </div>
                  <div className="timeZone">
                    Timezone: {scheduleWindow?.timezoneName || ""}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="autoCompleteDropdownLoading">
                <div className="inputBoxLoader linear-background" />
              </div>
              <div className="sendingWindowLoading">
                <div className="labelLoader linear-background" />
                {Array.from({ length: 7 }, (value, index) => (
                  <div
                    className="daysWithTimeWrapper"
                    key={`daysWithTimeWrapper${index}`}
                  >
                    <div className="weekDays linear-background" />
                    <div className="TimeRange linear-background" />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="sequenceExcludeContactSafetySetting"
        id="excludeContactSetting"
      >
        <h6>Safety</h6>
        {safetyCheckboxList.map(
          item => item?.show && <SecondaryCheckbox key={item?.id} {...item} />
        )}
      </div>
      <div className="sequenceThrottlingSettings">
        <h6>Throttling</h6>
        <p className="subTextForSettings">
          Whenever you add contacts to the sequence by default they will be
          moved to pending state. Contacts will be automatically moved from
          pending state to ongoing state based on the throttling settings below.
        </p>
        <div className="throttlingForm">
          <div className="inputForm">
            {settingsObjArr.map(item => {
              return <NumberInputBox {...item} key={item.id} />;
            })}
          </div>
        </div>
      </div>
      <Button
        id="saveSeqSettingsBtn"
        name="saveSeqSettingsBtn"
        btnText="Save Changes"
        type="button"
        className={`saveSeqSettingsBtn ${loading ? "loading" : ""} ${
          isEnabled || loader ? "" : ""
        }`}
        loader={loading}
        click={saveSequenceSetting}
      />
    </>
  );
});

export default withRouter(SequenceThrottlingSettings);
export { SequenceThrottlingSettings };
