import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import DashboardState from "../mainState";
import { DATE_FILTER_LIST } from "./constants";
import reportsModuleState from "./state";
import ActivityInsightsUtils from "./utils";

const ReportsFilter = observer(() => {
  const makeFilterRequest = (payload, obj) => {
    obj &&
      Object.keys(obj).length &&
      reportsModuleState.setFilterPayload({ ...payload, ...obj });
    reportsModuleState.getActivityInsightsData(payload);
  };

  const getFilterPayload = value => {
    const { firstDate = "", lastDate = "" } =
      ActivityInsightsUtils.getInsightsAnalyticsPayload(value);
    const filterPayload = {
      timeZone: Utils.getCurrentUserTimeZone(),
      filterFromDate: firstDate,
      filterToDate: lastDate
    };
    return filterPayload;
  };

  const getTabValue = value => {
    reportsModuleState.setActivityInsightLoader(true);
    reportsModuleState.setActiveActivityInsightTab(value);
    const payload = getFilterPayload(value) || "";
    const today = new Date();
    makeFilterRequest(payload, {
      value,
      days: value?.includes("week")
        ? 7
        : value?.includes("lastMonth")
        ? new Date(today.getFullYear(), today.getMonth(), 0)
        : new Date(today.getFullYear(), today.getMonth() + 1, 0)
    });
  };

  const constructFilterPayload = () => {
    const payload =
      getFilterPayload(reportsModuleState.activeActivityInsightTab) || "";
    return payload || {};
  };

  useEffect(() => {
    reportsModuleState.setActivityInsightLoader(true);
    reportsModuleState.getActivityInsightsData(constructFilterPayload());
  }, [DashboardState?.memberId]);

  return (
    <div className="dateFilter">
      {DATE_FILTER_LIST?.length &&
        DATE_FILTER_LIST.map(item => {
          const { name = "", id = "", value = "" } = item || {};
          return (
            item?.name !== "Custom" && (
              <div
                className={`tabItem ${
                  reportsModuleState?.activeActivityInsightTab === value
                    ? "active"
                    : ""
                }`}
                id={id}
                key={id}
                onClick={() => getTabValue(value)}
              >
                <span>{name}</span>
              </div>
            )
          );
        })}
    </div>
  );
});

export { ReportsFilter };
export default ReportsFilter;
