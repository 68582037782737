import userDetail from "Stores/userDetail";
import { linkedInCookiesState } from "../Stores/LinkedInCookies";

let count = 0;
let isContentScriptResponed = false;

// Check Plugin Install by posting msg
export const validatePluginInstalled = () => {
  window.parent.postMessage(
    { data: "message", type: "IS_PLUGIN_INSTALLED" },
    "*"
  );
};

// Set Interval to check plugin installed
const intervalId = setInterval(() => {
  count++;
  if (count >= 4 || isContentScriptResponed) {
    clearInterval(intervalId);
    return;
  }
  validatePluginInstalled();
}, 2000);

// Check Plugin Install by receiving msg
const webMessageReceiver = event => {
  if (event?.data?.type) {
    const { type = "" } = event.data;
    switch (type) {
      case "AE_PLUGIN_INSTALLED":
        isContentScriptResponed = true;
        userDetail.setPluginInstalled(true);
        break;
      default:
        break;
    }
  }
};

const webMessageReceiverForLinkedIn = event => {
  if (event?.data?.type) {
    const { type = "" } = event.data;
    switch (type) {
      case "LINKEDIN_COOKIES_AT_PLUGIN":
        linkedInCookiesState.setCookieLiAt(event?.data?.data);
        break;
      case "LINKEDIN_COOKIES_A_PLUGIN":
        linkedInCookiesState.setCookieLiA(event?.data?.data);
        linkedInCookiesState.setIsPremiumAccount(true);
        break;
      case "LINKEDIN_PROFILE_DATA_PLUGIN":
        linkedInCookiesState.setProfileData(event?.data?.data);
      default:
        break;
    }
  }
};

window.addEventListener("message", webMessageReceiver, false);
window.addEventListener("message", webMessageReceiverForLinkedIn, false);
