/* eslint-disable array-callback-return */
import React, { useState } from "react";
import FolderAndFileLoader from "./FolderAndFileLoader";

const TemplatesFolder = props => {
  const { loading, folder, changeFolder } = props;
  const [selected, setSelected] = useState(0);

  const selectFolder = (index, folderId) => {
    setSelected(index);
    changeFolder(index, folderId);
  };

  return (
    <>
      {!loading && (
        <div className="folderCont">
          <div className="folderListCont">
            <div className="dynamicFoldersWrapper" style={{ width: "100%" }}>
              {folder &&
                folder.length > 0 &&
                folder.map((item, index) => {
                  if (item) {
                    const { folderName, id, shortName } = item.folder
                      ? item.folder
                      : {};
                    return (
                      <div
                        className={`templateFolder ${
                          selected === index ? "selected" : ""
                        }`}
                        key={`${shortName ? shortName : "myTemplates"}${
                          index + 1
                        }`}
                        onClick={() => selectFolder(index, id ? id : "all")}
                      >
                        <i className="material-icons-outlined folderIcon">
                          folder
                        </i>
                        <span className="folderName name">
                          {folderName ? folderName : "My Templates"}
                        </span>
                        <i className="material-icons rightArrow">
                          arrow_forward_ios
                        </i>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      )}
      {loading && <FolderAndFileLoader count={10} />}
    </>
  );
};

export default TemplatesFolder;
export { TemplatesFolder };
