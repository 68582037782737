import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";
import Utils from "Utils/utils";
import { LEARN_MORE_LINK, WEBSITE_TRACKING_ENV } from "Static/constant";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { ReactComponent as TickIcon } from "Assets/svg/tick.svg";
import trackingStore from "Pages/Tracking/trackingStore";
import KnowMoreButton from "Components/common/KnowMoreButton";

const ScriptInstalled = () => {
  return (
    <div className="scriptInstalledWrapper">
      <div className="successTextWrapper">
        <TickIcon />
        <p className="successText">Successfully installed.</p>
      </div>
      <span className="successSubText">
        The website tracking code has been successfully installed on your
        website. And is now collecting data on your website visitors.
      </span>
    </div>
  );
};

const CopyOptionalScript = () => {
  const [copyOptionalScript, setCopyOptionalScript] = useState(false);
  const scriptData = `sg.identify("USER_EMAIL")`;

  const handleCopyButton = event => {
    Utils.preventDefaultFn(event);
    setCopyOptionalScript(true);
    const element = document.createElement("div");
    element.innerText = scriptData;
    Utils.copyToClipboard(element?.textContent);
    setTimeout(() => {
      setCopyOptionalScript(false);
    }, 1500);
  };

  return (
    <div className="copyOptionalScriptWrapper copyScriptWrapper">
      <div className="copyScriptCont">
        <div className="copyScriptBody">{scriptData}</div>
        <div
          className={`${
            copyOptionalScript ? "copiedButtonWrap" : "copyButtonWrap"
          }`}
          onClick={handleCopyButton}
        >
          <span className="material-icons-outlined copyButton">copy</span>
          <span className="copyText">
            {copyOptionalScript ? "Copied" : "Copy"}
          </span>
        </div>
      </div>
    </div>
  );
};

const OptionalScript = () => {
  return (
    <section className="optionalScriptWrapper">
      <p className="websiteTrackingSubText">
        Optional: If you want to associate the tracking data with a specific
        user.
      </p>
      <CopyOptionalScript />
      <div className="longText">
        <span>Replace </span>
        <span className="headText">{`"USER_EMAIL" `}</span>
        <span>
          with the actual email address of the registered user. This will
          connect any anonymous tracking data and future tracking data to the
          specified email address.
        </span>
      </div>
    </section>
  );
};

const CopyScript = () => {
  const [copyScript, setCopyScript] = useState(false);

  const websiteVisitorTrackingToken =
    userDetail?.userFeatureFlag?.websiteVisitorTrackingToken || "";
  const scriptData =
    WEBSITE_TRACKING_ENV === "prod"
      ? `<script type="text/javascript" 
        src="https://cdn.salesgear.io/js/script.js">
      </script>
      <script type="text/javascript">
        if (window && window.sg) {
          var sg = window.sg;
          sg.init("${websiteVisitorTrackingToken}");
        }
      </script>`
      : `
      <script type="text/javascript" 
        src="https://cdn.salesgear.io/js/script.js">
      </script>
      <script type="text/javascript">
        if (window && window.sg) {
          var sg = window.sg;
          sg.init("${websiteVisitorTrackingToken}", "${WEBSITE_TRACKING_ENV}");
        }
      </script>
  `;

  const handleCopyButton = event => {
    Utils.preventDefaultFn(event);
    setCopyScript(true);
    const element = document.createElement("div");
    element.innerText = scriptData;
    Utils.copyToClipboard(element?.textContent);
    setTimeout(() => {
      setCopyScript(false);
    }, 1500);
  };

  return (
    <div className="copyScriptWrapper">
      <div className="copyScriptCont">
        <div className="copyScriptHeader">
          <p>Code</p>
          <div
            className={`${copyScript ? "copiedButtonWrap" : "copyButtonWrap"}`}
            onClick={handleCopyButton}
          >
            <span className="material-icons-outlined copyButton">copy</span>
            <span className="copyText">{copyScript ? "Copied" : "Copy"}</span>
          </div>
        </div>
        <div className="copyScriptBody">{scriptData}</div>
      </div>
    </div>
  );
};

const WebsiteTracking = observer(() => {
  const {
    websiteVisitorTrackingEnabled = false,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const checkWebsiteTrackingEnabled = () => {
    if (!websiteVisitorTrackingEnabled) {
      PaywallUtils.upgradeUtilsFunction(
        "websiteTracking",
        false,
        planName,
        inFreePlan
      );
    }
    Utils.mixpanelTrack(MXP_EVENT.WEBSITE_TRACKING_SETTINGS_VISITED, {
      pageType: PAGE_TYPE["setting"]
    });
  };

  useEffect(() => {
    checkWebsiteTrackingEnabled();
  }, []);

  return (
    <div className="websiteTrackingWrapper">
      <div
        className={`websiteTrackingCont ${
          websiteVisitorTrackingEnabled ? "" : "blurBg"
        }`}
      >
        <div className="websiteTrackingHeader">
          <h6 className="titleHeader">Website Tracking</h6>
        </div>
        <div className="websiteTrackingBody">
          {trackingStore?.websiteTrackingInstalled && <ScriptInstalled />}
          <div className="bodyHeadingText">
            <p className="websiteTrackingBoldText">
              Install website tracking code on your website
            </p>
            <span className="websiteTrackingSubText">
              Copy and paste the code below into the
            </span>
            <span className="headText">{`<head>`}</span>
            <span className="websiteTrackingSubText">
              tag of every page where you want to track your website visitors.
            </span>
          </div>
          <CopyScript />
          <OptionalScript />
          {/* <div className="copyScriptFooter">
            <p>
              For more information on how to install Salesgear website tracking,
            </p>
            <KnowMoreButton
              redirectUrl={LEARN_MORE_LINK?.["websiteTracking"]}
              text="please click here"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
});

export { WebsiteTracking };
export default WebsiteTracking;
