import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import SearchInputBox from "Components/common/SearchInputBox";
import { action, observable } from "mobx";
import connectInboxStore from "./store";
import RadioButton from "Components/common/radioBtn";
import InboxImage from "./InboxImage";
import { useHistory } from "react-router";

class AddDefaultInboxState {
  @observable showPopup = false;
  @observable cbk = () => {};
  @observable inbox = "";

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
  };

  @action
  setCbk = (val = () => {}) => {
    this.cbk = val;
  };

  @action
  setInbox = (val = "") => {
    this.inbox = val;
  };
}

const addDefaultInboxState = new AddDefaultInboxState();

const AddDefaultInbox = observer(() => {
  const [activeInbox, setActiveInbox] = useState({});
  const [selectedInbox, setSelectedInbox] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [clearSearchInput, setClearSearchInput] = useState(false);
  const [updatedInbox, setUpdatedInbox] = useState({});

  const reset = () => {
    setButtonLoading(false);
    setActiveInbox([]);
    setUpdatedInbox([]);
  };

  const handleConnectBtn = () => {
    setButtonLoading(true);
    const payload = {
      inboxId: selectedInbox
    };
    connectInboxStore.connectCalendarInbox(payload, () => {
      addDefaultInboxState.setShowPopup(false);
      reset();
    });
  };

  const connectBtnProps = {
    id: "connectBtn",
    name: "connectBtn",
    btnText: "Connect",
    type: "button",
    className: `connectBtn ${buttonLoading ? "loading" : ""}`,
    click: handleConnectBtn,
    loader: buttonLoading
  };

  const initCalls = async () => {
    let res = connectInboxStore?.inboxList || [];
    if (res?.length) {
      setActiveInbox(res);
      setSelectedInbox(res?.[0]?.id);
      setUpdatedInbox(res);
    }
  };

  useEffect(() => {
    addDefaultInboxState.showPopup && initCalls();
    return () => {
      reset();
    };
  }, [addDefaultInboxState.showPopup, connectInboxStore?.inboxList]);

  return (
    addDefaultInboxState.showPopup && (
      <>
        <div className="addDefaultInboxPopup">
          <div className="card">
            <div className="popupHeader">
              <div className="flexWrapper">
                <InboxImage type={addDefaultInboxState?.inbox} />
                <div className="title">Connected Inbox</div>
              </div>
              <i
                className="material-icons commonPopupCloseIcon"
                onClick={() => addDefaultInboxState.setShowPopup(false)}
              >
                close
              </i>
            </div>
            <hr className="seperator" />
            <div className="popupBody">
              {!connectInboxStore?.loading && !activeInbox?.length && (
                <EmptyInbox />
              )}
              {!connectInboxStore?.loading && activeInbox?.length > 0 && (
                <SelectList
                  setUpdatedInbox={setUpdatedInbox}
                  activeInbox={activeInbox}
                  clearSearchInput={clearSearchInput}
                >
                  {updatedInbox?.length > 0 ? (
                    <>
                      <div className="inboxListWrap">
                        <InboxList
                          updatedInbox={updatedInbox}
                          setUpdatedInbox={setUpdatedInbox}
                          setSelectedInbox={setSelectedInbox}
                        />
                      </div>
                      <Button {...connectBtnProps} />
                    </>
                  ) : (
                    <div>No Inboxes found</div>
                  )}
                </SelectList>
              )}
              {connectInboxStore?.loading && (
                <SelectList>
                  <SelectListLoader updatedInbox={updatedInbox} />
                </SelectList>
              )}
            </div>
          </div>
          <div
            className="common-popup-mask"
            onClick={() => addDefaultInboxState.setShowPopup(false)}
          />
        </div>
      </>
    )
  );
});

const SelectList = observer((props = {}) => {
  const {
    children = <></>,
    setUpdatedInbox = () => {},
    activeInbox = "",
    clearSearchInput = false
  } = props || {};

  const searchActiveUsers = value => {
    const lowercasedValue = value?.toLowerCase();
    if (lowercasedValue === "") {
      setUpdatedInbox(activeInbox);
    } else {
      const filteredData =
        activeInbox?.length > 0 &&
        activeInbox?.filter(item => {
          return item?.email
            ?.toString()
            ?.toLowerCase()
            ?.includes(lowercasedValue);
        });
      setUpdatedInbox(filteredData);
    }
  };

  return (
    <div className="selectListWrapper">
      <div className="selectFromLists">Select from list</div>
      <div className="selectListCont">
        <SearchInputBox
          valueCbk={searchActiveUsers}
          type="text"
          placeholder="Search lists"
          clearSearchInput={clearSearchInput}
        />
        <div className="scrollUserList">
          <div className="multiSelectSection">
            <div className="checkBoxDropdown">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

const InboxList = observer((props = {}) => {
  const {
    updatedInbox = [],
    setUpdatedInbox = () => {},
    setSelectedInbox = () => {}
  } = props || {};

  const getSelectedUser = async (e, id = "") => {
    const newArr = [...updatedInbox];
    let idArr = "";
    if (newArr?.length) {
      newArr.map((item, index) => {
        item.checked = item?.email === id;
        if (item?.checked) {
          idArr = item?.id;
        }
      });
      setSelectedInbox(idArr);
      setUpdatedInbox(newArr);
    }
  };

  const getUpdatedDom = (user = {}) => {
    return (
      <div className="inboxDetails">
        <div className="inboxEmail">{Utils.getFullNameFromUserInfo(user)}</div>
      </div>
    );
  };

  return updatedInbox?.length > 0 ? (
    updatedInbox.map((user, index) => {
      const { email = "", checked = false } = user || {};
      return (
        <RadioButton
          key={email}
          hasCustomDom={true}
          value={getUpdatedDom(user)}
          id={email}
          defaultChecked={checked || index === 0}
          radioOnchange={getSelectedUser}
          name="email"
        />
      );
    })
  ) : (
    <></>
  );
});

const SelectListLoader = () => {
  return Array.from({ length: 4 }, (value, index) => (
    <div className="radioBtnCont" key={index}>
      <div className="circle linear-background" />
      <div className="radioButtonCont linear-background" />
    </div>
  ));
};

const EmptyInbox = observer(() => {
  const history = useHistory();

  const handleConnectBtn = () => {
    history.push("/settings/account");
    addDefaultInboxState.setShowPopup(false);
  };

  const connectBtnProps = {
    id: "connectBtn",
    name: "connectBtn",
    btnText: "Connect Inbox",
    type: "button",
    className: `connectBtn`,
    click: handleConnectBtn,
    loader: false
  };

  return (
    <div className="emptyInbox">
      <div className="emptyInboxText">
        {addDefaultInboxState?.inbox === "gmail"
          ? "You haven't connected any Google account with Salesgear yet. Please connect your inbox to access calendar."
          : "You haven't connected any Microsoft account with Salesgear yet. Please connect your inbox to access calendar."}
      </div>
      <Button {...connectBtnProps} />
    </div>
  );
});

export { AddDefaultInbox, addDefaultInboxState };
export default AddDefaultInbox;
