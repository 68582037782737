import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import FilterDropdown from "./FilterDropdown";
import DeleteRow from "./DeleteRow";
import { actionObject } from "Static/constant";
import AddItem from "./AddItem";
import LabelText from "./LabelText";
import SelectSequence from "./SelectSequence";
import CreateTaskTriggerPopup from "./CreateTaskTriggerPopup";
import InputBox from "Components/common/InputTextBox";
import UpdateContactsAction from "./UpdateContactsAction";
import { triggerState } from "Pages/Dashboard/settings/Triggers/TriggerState";
import SelectTags from "./SelectTags";
import UpdateStage from "./UpdateStage";
import ActionDomainProspect from "./ActionDomainProspect";
import CreateSlackTriggerPopup from "./CreateSlackTriggerPopup";
import { slackDataStore } from "Stores/SlackDataStore";
import CustomTooltip from "Components/common/CustomTooltip";
import { ReactComponent as WarningField } from "Assets/svg/warning_filed.svg";
import SlackImg from "Assets/slack-logo.png";

const TriggerAction = observer(props => {
  const {
    actionsArray = "",
    change = () => {},
    error = "",
    contactStageList = [],
    triggerType = ""
  } = props;
  const isSlackConnected = slackDataStore?.slackData?.slackConnected || false;

  const [showTooltip, setShowTooltip] = useState(false);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(false);
  const [hoverIndex, setHoverIndex] = useState("");
  const [disableUpdateContactAction, setDisableUpdateContactAction] =
    useState("");
  const [disableUpdateStageAction, setDisableUpdateStageAction] = useState("");
  const newAction = { actionType: "" };

  const addItem = () => {
    const newItem = [...actionsArray, newAction];
    change("actions", newItem);
  };

  const deleteItem = index => {
    let tempList = [...actionsArray];
    if (tempList?.[index].actionType === "UPDATE_CONTACT") {
      setDisableUpdateContactAction("");
    } else if (tempList?.[index].actionType === "UPDATE_STAGE") {
      setDisableUpdateStageAction("");
    }
    tempList.splice(index, 1);
    change("actions", tempList);
  };

  const handleAction = (key, value) => {
    let tempList = [...actionsArray];
    const indexValue = activeDropdownIndex.split("-")[1];
    if (tempList[indexValue - 1]?.actionType !== value?.subKey) {
      tempList[indexValue - 1] = { actionType: value?.subKey };
    }
    setDisableUpdateContactAction("");
    setDisableUpdateStageAction("");
    if (value?.subKey === "UPDATE_CONTACT") {
      setDisableUpdateContactAction("UPDATE_CONTACT");
    } else if (value?.subKey === "UPDATE_STAGE") {
      setDisableUpdateStageAction("UPDATE_STAGE");
    }
    setActiveDropdownIndex(null);
    change("actions", tempList);
  };

  const actionTypeList = [
    {
      value: "Complete all task",
      subKey: "COMPLETE_TASK",
      key: "completeAllTask",
      method: handleAction
    },
    {
      value: "Add to new sequence",
      subKey: "ADD_TO_SEQUENCE",
      key: "contactCreated",
      method: handleAction,
      disabled: ["CONTACT_OPTEDOUT", "EMAIL_BOUNCED"].some(r => {
        return triggerState?.disabledActionFields.includes(r);
      })
    },
    {
      value: "Complete sequence",
      subKey: "COMPLETE_SEQUENCE",
      key: "contactCreated",
      method: handleAction,
      disabled: ["CONTACT_OPTEDOUT", "EMAIL_BOUNCED"].some(r =>
        triggerState?.disabledActionFields.includes(r)
      )
    },
    {
      value: "Create a task",
      subKey: "CREATE_TASK",
      key: "createATask",
      method: handleAction
    },
    {
      value: "Update contact",
      subKey: "UPDATE_CONTACT",
      key: "updateContacts",
      method: handleAction,
      disabled: disableUpdateContactAction === "UPDATE_CONTACT"
    },
    {
      value: "Update Stage",
      subKey: "UPDATE_STAGE",
      key: "updateStage",
      method: handleAction,
      disabled: disableUpdateStageAction === "UPDATE_STAGE"
    },
    {
      value: "Add tags",
      subKey: "ADD_TAG",
      key: "addTags",
      method: handleAction
    },
    {
      value: "Remove tags",
      subKey: "REMOVE_TAG",
      key: "removeTags",
      method: handleAction
    },
    {
      value: "Send slack notification",
      subKey: "SLACK_NOTIFICATION",
      key: "sendSlackNotification",
      method: handleAction
    }
  ];

  const all_shared = {
    name: "All Shared",
    id: "ALL_SHARED"
  };
  const all_private = {
    name: "All Private",
    id: "ALL_PRIVATE"
  };

  const ownerType =
    triggerState?.contactOwner?.filter(
      item => item?.memberId === triggerState?.inputValue?.contactOwner?.value
    )?.[0]?.value || triggerState?.contactOwner?.[0]?.value;

  const handleTagChange = (_, value) => {
    if (value?.id) {
      let tempList = [...actionsArray];
      const indexValue = activeDropdownIndex.split("-")[1];
      tempList[indexValue - 1].contact = {
        tagIds: [value?.id]
      };
      tempList[indexValue - 1].value = value?.value;
      setActiveDropdownIndex(null);
      change("actions", tempList);
    }
  };

  const handleUpdateStage = (_, value) => {
    if (value?.id) {
      let tempList = [...actionsArray];
      const indexValue = activeDropdownIndex.split("-")[1];
      tempList[indexValue - 1].contact = {
        stageId: value?.id
      };
      tempList[indexValue - 1].value = value?.value;
      setActiveDropdownIndex(null);
      change("actions", tempList);
    }
  };

  const emptyInputObject = {
    id: "disableInput",
    type: "text",
    value: "",
    change: () => {},
    label: "Value",
    text: "Value"
  };

  useEffect(() => {
    actionsArray?.length > 0 &&
      actionsArray.map(item => {
        if (item?.actionType === "UPDATE_CONTACT") {
          setDisableUpdateContactAction(item?.actionType);
        } else if (item?.actionType === "UPDATE_STAGE") {
          setDisableUpdateStageAction(item?.actionType);
        } else if (
          item?.actionType === "SLACK_NOTIFICATION" &&
          !isSlackConnected
        ) {
          slackDataStore.getSlackData();
        }
      });
  }, [actionsArray, isSlackConnected]);

  const defaultSeqObj = {
    ALL_SHARED: "All Shared",
    ALL_PRIVATE: "All Private"
  };

  return (
    <div className="triggerActions">
      <LabelText name="Actions" id="actions" />

      <ActionDomainProspect
        checked={triggerState?.inputValue?.executeForAccount}
        change={change}
      />

      <div
        className="triggerActionCard"
        style={{ overflow: activeDropdownIndex ? "unset" : "auto" }}
      >
        {actionsArray?.length ? (
          <div className="triggerActionList">
            {actionsArray?.map((item, index) => {
              const {
                actionType = "",
                sequenceId = "",
                task = {},
                sequenceName = "",
                value = "",
                slackMessage = ""
              } = item || {};
              return (
                <div key={`triggerActionList${index}`}>
                  <div
                    className={`newActionItem ${
                      ["UPDATE_CONTACT", "COMPLETE_TASK"]?.includes(
                        actionType || ""
                      )
                        ? "twoGridItem"
                        : ""
                    }`}
                    key={`actionItem-${actionType}-${Date.now()}-${index}`}
                    onMouseEnter={() => setHoverIndex(index)}
                    onMouseLeave={() => setHoverIndex("")}
                  >
                    <span className="horizontalBorderLine" />
                    <div className="actionType">
                      <FilterDropdown
                        options={actionTypeList}
                        showDropdown={
                          activeDropdownIndex === `key-${index + 1}`
                        }
                        setActiveDropdownIndex={setActiveDropdownIndex}
                        defaultValue={
                          actionType ? actionObject?.[actionType] : ""
                        }
                        label="Select"
                        referenceId="actions"
                        index={`key-${index + 1}`}
                      />
                    </div>
                    <div className={!actionType ? "disableInput" : ""}>
                      {actionType &&
                        ["REMOVE_TAG", "ADD_TAG"].includes(actionType) && (
                          <SelectTags
                            index={`${actionType}-${index + 1}`}
                            showDropdown={
                              activeDropdownIndex ===
                              `${actionType}-${index + 1}`
                            }
                            activeDropdownIndex={activeDropdownIndex}
                            setActiveDropdownIndex={setActiveDropdownIndex}
                            label="Select"
                            referenceId="actionTagList"
                            change={handleTagChange}
                            defaultValue={value}
                          />
                        )}
                      {actionType &&
                        ["ADD_TO_SEQUENCE", "COMPLETE_SEQUENCE"].includes(
                          actionType
                        ) && (
                          <SelectSequence
                            index={`value-${index + 1}`}
                            showDropdown={
                              activeDropdownIndex === `value-${index + 1}`
                            }
                            activeDropdownIndex={activeDropdownIndex}
                            setActiveDropdownIndex={setActiveDropdownIndex}
                            defaultValue={
                              sequenceId && sequenceName
                                ? defaultSeqObj[sequenceName] || sequenceName
                                : ""
                            }
                            label="Select"
                            referenceId="actions"
                            actionsArray={actionsArray}
                            change={change}
                            options={
                              actionType === "COMPLETE_SEQUENCE"
                                ? [
                                    ["Shared"]?.includes(ownerType)
                                      ? all_shared
                                      : all_private,
                                    ...triggerState?.sequenceList
                                  ]
                                : triggerState?.sequenceList || []
                            }
                          />
                        )}
                      {actionType && ["CREATE_TASK"].includes(actionType) && (
                        <div className="taskType">
                          <CreateTaskTriggerPopup
                            actionsArray={actionsArray}
                            change={change}
                            index={`taskObject-${index + 1}`}
                            task={task}
                          />
                        </div>
                      )}
                      {actionType &&
                      ["SLACK_NOTIFICATION"]?.includes(
                        actionType?.toUpperCase()
                      ) &&
                      isSlackConnected ? (
                        <div className="sendSlackNotificationPopup">
                          <CreateSlackTriggerPopup
                            change={change}
                            actionsArray={actionsArray}
                            index={`slackObject-${index + 1}`}
                            pageSource="Triggers"
                            defaultValue={slackMessage}
                            triggerType={triggerType}
                          />
                        </div>
                      ) : (
                        actionType &&
                        ["SLACK_NOTIFICATION"]?.includes(
                          actionType?.toUpperCase()
                        ) && (
                          <div className="slackNotConnected">
                            <div
                              className="slackValue"
                              onClick={() => {
                                window.open("/settings/crm", "_blank");
                              }}
                            >
                              <img
                                className="slackImage"
                                src={SlackImg}
                                alt=""
                              />
                              <span className="labelValue">Connect Slack</span>
                            </div>
                            <div
                              onMouseEnter={() => setShowTooltip(true)}
                              onMouseLeave={() => setShowTooltip(false)}
                            >
                              <span className="svgIcon crossIcon">
                                <WarningField />
                              </span>
                              {showTooltip && (
                                <CustomTooltip dynamicDom={true}>
                                  Please connect Slack to enable this action
                                </CustomTooltip>
                              )}
                            </div>
                          </div>
                        )
                      )}
                      {!actionType && actionType !== "UPDATE_CONTACT" && (
                        <div
                          className={`triggerInputFieldValue ${
                            !actionType ? "disableInput" : ""
                          }`}
                        >
                          <InputBox {...emptyInputObject} />
                        </div>
                      )}
                      {actionType && ["UPDATE_STAGE"].includes(actionType) && (
                        <UpdateStage
                          index={`${actionType}-${index + 1}`}
                          showDropdown={
                            activeDropdownIndex === `${actionType}-${index + 1}`
                          }
                          activeDropdownIndex={activeDropdownIndex}
                          setActiveDropdownIndex={setActiveDropdownIndex}
                          label="Select"
                          referenceId="actionTagList"
                          change={handleUpdateStage}
                          defaultValue={value}
                          contactStageList={contactStageList}
                        />
                      )}
                    </div>
                    {hoverIndex === index && (
                      <DeleteRow deleteCbk={deleteItem} parentIndex={index} />
                    )}
                  </div>
                  {actionType === "UPDATE_CONTACT" && (
                    <UpdateContactsAction
                      actionsArray={actionsArray}
                      change={change}
                    />
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        <AddItem
          addCbk={addItem}
          name="Add action"
          className="addTriggerAction"
        />
      </div>
      {error && <span className="errMsgText">{error}</span>}
    </div>
  );
});

export default TriggerAction;
export { TriggerAction };
