import React from "react";
import Lottie from "react-lottie";
import bulkUploading from "Utils/LottieFiles/bulkUpload.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: bulkUploading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const BulkUploadingLoader = props => {
  const { loadingMessage } = props;

  return (
    <div className="generatingPreview">
      <div className="imageCont">
        <Lottie
          options={defaultOptions}
          width={200}
          height={155}
          style={{ margin: "0 auto" }}
          speed={0.7}
        />
        <div className="generatingPreviewText">{loadingMessage}</div>
      </div>
    </div>
  );
};

export default BulkUploadingLoader;
export { BulkUploadingLoader };
