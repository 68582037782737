/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation, withRouter } from "react-router-dom";
import { callsStore } from "Stores/Calls";
import { userDetail } from "Stores/userDetail";
import EmptyReport from "Pages/Dashboard/reports/EmptyReport";
import MemoizedCallsTeamSummaryLoader from "./CallsTeamSummaryLoader";
import NoActivityFound from "Pages/Dashboard/reports/NoActivityFound";

const CallsTeamSummary = observer(({ stageActiveList = [] }) => {
  let location = useLocation();

  let contactAdded = userDetail?.userFeatureFlag?.noOfContactsAddedInTeam || 0;
  const [data, setData] = useState(callsStore.inputValues || {});

  const hasTableData = () => {
    return (
      !data?.teamSummaryLoader &&
      data?.teamSummaryData?.length > 0 &&
      contactAdded > 0
    );
  };

  const getSummaryObject = (item = {}) => {
    let totalCount = 0;
    let tempObj = {};
    if (Object.keys(item)?.length > 0) {
      Object.keys(item).map(ele => {
        let keyValue = ele?.split(" ")?.join("")?.toLowerCase() || "";
        let textValue = item?.[ele] || 0;
        if (keyValue) {
          tempObj[keyValue] = textValue;
        }
        if (!isNaN(textValue)) {
          totalCount += textValue;
        }
      });
    }
    return { totalCount, tempObj };
  };

  useEffect(() => {
    setData(callsStore.inputValues);
  }, [callsStore.inputValues]);

  return (
    <div className="callsTeamSummary">
      <h4 className="cardTitle">Summary</h4>
      {hasTableData() && (
        <div className="tableContainer">
          <table>
            <thead>
              <tr>
                <th scope="row">Team Members</th>
                <th scope="col" className="alignCenter">
                  Total Calls
                </th>
                {[...stageActiveList].map(item => {
                  const { id = "", displayName = "" } = item || {};
                  return (
                    <th scope="col" key={id} className="alignCenter">
                      {displayName}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data?.teamSummaryData.map((item, index) => {
                let { totalCount = 0, tempObj = {} } = getSummaryObject(item);
                return (
                  <tr key={index}>
                    <th scope="row">{item?.name}</th>
                    <td className="alignCenter">{totalCount}</td>
                    {[...stageActiveList].map(ele => {
                      const { id = "", displayName = "" } = ele || {};
                      return (
                        <td key={`${id}${index}`} className="alignCenter">
                          {tempObj[
                            displayName?.split(" ")?.join("")?.toLowerCase() ||
                              ""
                          ] || 0}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {data?.teamSummaryLoader && (
        <MemoizedCallsTeamSummaryLoader
          stageActiveList={stageActiveList}
          count={7}
        />
      )}
      {!data?.teamSummaryLoader && !contactAdded && (
        <EmptyReport
          pathname={location?.pathname}
          sequenceInsight={true}
          className="emptyCallActivity"
        />
      )}
      {(!data?.teamSummaryLoader && data?.teamSummaryData?.length < 1 && (
        <NoActivityFound />
      )) ||
        ""}
    </div>
  );
});

export default withRouter(CallsTeamSummary);
export { CallsTeamSummary };
