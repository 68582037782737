import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImportNow } from "../common/ImportNow";
import FieldMapping from "../common/FieldMapping";
import AutoImport from "../common/AutoImport";
import AddTags from "../common/AddTags";
import { hubspotStore } from "./HubspotStore";
import UpdateContacts from "../common/UpdateContacts";
import ListView from "../common/ListView";

const Contacts = observer(props => {
  const { contact = {}, fieldMappingRef = null } = props || {};
  const [dropdownWidth, setDropdownWidth] = useState("25%");

  const findWidthOfDropdown = () => {
    return (
      document.querySelector(".crmListView .filterDropdownWrap")?.offsetWidth ||
      "270"
    );
  };

  useEffect(() => {
    hubspotStore.setHubspotContactsList([
      { id: "loading", name: "Loading..." }
    ]);
    hubspotStore.getContactsListView();
    setDropdownWidth(findWidthOfDropdown());
    return () => {
      hubspotStore.resetHubSpotList();
    };
  }, []);

  return (
    <div className="contactsWrapper">
      <div className="autoImportSettings">
        <ListView
          config={hubspotStore?.hubspotContactConfig}
          setConfig={hubspotStore.setHubspotContactConfig}
          list={hubspotStore?.hubspotContactsList}
          crmName="Hubspot"
        />
        <AutoImport
          config={hubspotStore.hubspotContactConfig}
          setConfig={hubspotStore.setHubspotContactConfig}
          data={hubspotStore?.hubspotData}
          crmName="Hubspot"
        />
        <AddTags
          dropdownWidth={dropdownWidth}
          config={hubspotStore.hubspotContactConfig}
          selectedTags={hubspotStore.hubspotContactTags}
          setSelectedTags={hubspotStore.setHubspotContactTags}
          type="CONTACTS"
        />
        <UpdateContacts
          config={hubspotStore.hubspotContactConfig}
          setConfig={hubspotStore.setHubspotContactConfig}
        />
        <ImportNow
          listId={hubspotStore?.hubspotContactConfig?.sourceId}
          type="CONTACTS"
          lastSynced={contact?.lastSynced}
          listChanged={hubspotStore?.hubspotContactConfig?.listChanged}
          crmName="Hubspot"
        />
      </div>
      <div className="fieldMapping" ref={fieldMappingRef}>
        <FieldMapping
          config={hubspotStore.hubspotContactConfig}
          setConfig={hubspotStore.setHubspotContactConfig}
          mappingConfig={contact?.fields}
          type="CONTACTS"
          fields={hubspotStore?.hubspotFields}
          crmName="Hubspot"
        />
      </div>
    </div>
  );
});

export { Contacts };
export default Contacts;
