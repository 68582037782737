import React from "react";
// import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

const ConfettiComponent = () => {
  return (
    <div
      className="confettiWrapper"
      style={{
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight}px`
      }}
    >
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        run={true}
        opacity={0.8}
        numberOfPieces="2500"
        recycle={false}
        initialVelocityY={60}
        initialVelocityX={8}
      />
    </div>
  );
};

export { ConfettiComponent };
export default ConfettiComponent;
