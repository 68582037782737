export const EmailFilterList = [
  {
    value: "Reply Count",
    subKey: "REPLIED",
    key: "emailReplyCount"
  },
  {
    value: "Open Count",
    subKey: "OPENED",
    key: "emailOpenCount"
  },
  {
    value: "Click Count",
    subKey: "CLICKED",
    key: "emailClickCount"
  },
  {
    value: "Sent Count",
    subKey: "SENT",
    key: "emailSentCount"
  }
];

export const CallsFilterList = [
  {
    value: "Calls Made",
    subKey: "CALLMADE",
    key: "callsMadeCount"
  },
  {
    value: "Calls Logged",
    subKey: "CALLLOGGED",
    key: "callsAnsweredCount"
  }
];

export const TasksFilterList = [
  {
    value: "Tasks completed",
    subKey: "Tasks completed",
    key: "tasksCompletedCount"
  },
  {
    value: "Tasks overdue",
    subKey: "Tasks overdue",
    key: "tasksInDueCount"
  }
];

export const ContactsFilterList = [
  {
    value: "Number of Contacts",
    subKey: "contacts",
    key: "contactsCount"
  }
];

export const AccountFieldsList = [
  {
    value: "Name",
    subKey: "NAME",
    key: "name"
  },
  {
    value: "Domain",
    subKey: "DOMAIN",
    key: "domain"
  },
  {
    value: "Industry",
    subKey: "INDUSTRY",
    key: "industry"
  },
  {
    value: "Phone Number",
    subKey: "PHONE_NUMBER",
    key: "phone"
  },
  {
    value: "LinkedIn URL",
    subKey: "LINKEDIN_URL",
    key: "linkedinId"
  },
  {
    value: "Facebook",
    subKey: "FACEBOOK",
    key: "facebook"
  },
  {
    value: "Twitter",
    subKey: "TWITTER",
    key: "twitter"
  },
  {
    value: "Employee Count",
    subKey: "EMPLOYEE_COUNT",
    key: "employeeCount"
  },
  {
    value: "Revenue",
    subKey: "REVENUE",
    key: "revenue"
  }
];

export const DefaultFilterObject = {
  accountFields: [
    {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    }
  ],
  emails: [
    {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    }
  ],
  calls: [
    {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    }
  ],
  tasks: [
    {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    }
  ],
  contacts: [
    {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    }
  ],
  dates: [
    {
      fieldName: "",
      isSet: false,
      isNotSet: false,
      fromDate: new Date(),
      toDate: null,
      operator: "BETWEEN"
    }
  ]
};

export const DefaultAccountsNavbarObj = {
  activeTab: "",
  filterArr: [],
  navBarLoading: false
};

export const DefaultAccountsTableObj = {
  activeTab: "",
  tableArr: [],
  tableLoading: false
};

export const employeeCountDropdown = [
  "0 - 25",
  "25 - 100",
  "100 - 250",
  "250 - 1000",
  "1K - 10K",
  "10K - 50K",
  "50K - 100K",
  "> 100K"
];

export const revenueRange = [
  "$0 to $1M",
  "$1M to $10M",
  "$10M to $50M",
  "$50M to $100M",
  "$100M to $250M",
  "$250M to $500M",
  "$500M to $1B",
  "$1B+"
];
