import React, { useState } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import DropDownMenu from "Components/common/DropDownMenu";
import { updateContactOwnerState } from "Components/common/UpdateContactOwner";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";

const AccountRowAction = observer((props = {}) => {
  const {
    key = "",
    referenceId = "",
    tableLoading = false,
    rowData = {},
    reloadAccountCard = () => {}
  } = props || {};

  const [showRowActionsDropdown, setShowRowActionsDropdown] = useState(false);

  const expandActionMenu = (id = "", e) => {
    if (!tableLoading) {
      Utils.preventDefaultFn(e);
      setShowRowActionsDropdown(referenceId === id);
    }
  };

  const singleOwnerUpdatePayload = (ownerId = "") => {
    if (rowData?.id) {
      return {
        accountIds: [rowData?.id || ""],
        ownerId
      };
    }
    return false;
  };

  const AccountOwnerSingleUpdate = async (ownerId = "") => {
    const data = singleOwnerUpdatePayload(ownerId);
    if (data) {
      const config = {
        url: URLS.updateAccountOwner,
        method: "POST",
        data
      };
      const response = await makeApi(config);
      updateContactOwnerState.setBtnLoader(false);
      if (response?.data?.successCount > 0) {
        toasterState.setToastMsg(
          `Account owner updated successfully`,
          "success"
        );
        reloadAccountCard();
      } else if (response?.response?.status === 401) {
        toasterState.setToastMsg(
          "Oops! You don't have access to update the account owner.",
          "fail"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  };

  const openAssignAccountOwnerPopup = () => {
    updateContactOwnerState.setPopupType("ACCOUNT");
    updateContactOwnerState.setCurrentOwnerEmail(rowData?.owner?.email || "");
    updateContactOwnerState.setTotalCount(1);
    updateContactOwnerState.setCallback(AccountOwnerSingleUpdate);
    updateContactOwnerState.setShowPopup(true);
    setShowRowActionsDropdown(false);
  };

  const { hasTeamMember = false } = userDetail?.userInfo || {};

  const ownerOptions = [
    {
      icon: {
        ele: '<i style="font-size: 15px" class="material-icons-outlined">person_pin</i>',
        style: { width: "15px", height: "15px" }
      },
      value: "Update Owner",
      key: "updateAccountOwner",
      method: openAssignAccountOwnerPopup
    }
  ];

  return (
    <div className="actionDp moreOption" key={key}>
      <i
        className={`material-icons-outlined icon-hover-btn ${
          hasTeamMember ? "enabled" : ""
        }`}
        onClick={e => expandActionMenu(rowData?.id, e)}
      >
        more_vert
      </i>
      {showRowActionsDropdown && (
        <div className="accountDp">
          <DropDownMenu
            options={ownerOptions}
            referenceId={referenceId}
            direction={key >= 0 && key <= 1 ? "topLeft" : "left"}
          />
          <div
            className="dropdownMask"
            onClick={event => {
              Utils.preventDefaultFn(event);
              setShowRowActionsDropdown(false);
            }}
          />
        </div>
      )}
    </div>
  );
});

export default AccountRowAction;
export { AccountRowAction };
