import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import InputBox from "Components/Register/InputBox";
import Button from "Components/common/Button";
import { Utils } from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import { captchaStoreValue } from "Stores/captcha";
import EmailLineImg from "Assets/png/emailLine.png";

const ForgotPasswordComponent = () => {
  const [userEmail, setUserEmail] = useState("");
  const [isForgotPasswordLinkSent, setIsForgotPasswordLinkSent] =
    useState(false);
  const [loader, setLoader] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const emailChange = value => {
    emailErr !== "" && setEmailErr("");
    setUserEmail(value?.trim()?.toLowerCase() || "");
  };

  const submitForm = e => {
    e.preventDefault();
    e.keyCode === 13 && executeCaptcha(e);
  };

  const forgotPasswordInputObj = {
    name: "workEmail",
    id: "workEmail",
    type: "email",
    value: userEmail,
    changeAction: emailChange,
    keyupAction: submitForm,
    errMsg: emailErr || errMsg,
    label: "Work Email",
    placeholder: `eg: john@salesgear.io`,
    focus: true
  };

  const forgotPassword = async captchaToken => {
    try {
      setLoader(true);
      let isEmailValid = Utils.emailValidate(
        userEmail?.trim()?.toLowerCase() || ""
      );
      if (isEmailValid) {
        const config = {
          url: `${URLS.forgotPasswordUser}?email=${
            userEmail?.trim() || ""
          }&token=${captchaToken}`,
          method: "POST"
        };
        let res = await makeApi(config);
        res?.data
          ? setIsForgotPasswordLinkSent(true)
          : setErrMsg("Something went wrong");
        setLoader(false);
      } else {
        setLoader(false);
        setEmailErr("Please enter a valid email address");
      }
    } catch (err) {
      console.error(err);
      setLoader(false);
      setErrMsg("Something went wrong");
    }
  };

  const executeCaptcha = e => {
    e && Utils.removeInputRequired(e);
    captchaStoreValue.executeCaptcha(forgotPassword);
  };

  return (
    <section
      className={`formInputBox ${loader ? "pointerUserSelectNone" : ""}`}
    >
      {isForgotPasswordLinkSent ? (
        <div className="resetPasswordEmailSent">
          <img src={EmailLineImg} alt="Email" />
          <h3>Check Your Email</h3>
          <p className="descText">
            If <a href="">{userEmail}</a> exists, you will receive an email
            there shortly. Please make sure to check your spam folder as well.
          </p>
          <p className="loginRedirect">
            <span className="descText">Go Back to </span>
            <Link to="/login">Log In</Link>
          </p>
        </div>
      ) : (
        <>
          <h3>Reset Password</h3>
          <p className="descText">
            Please enter your email address below to receive a password reset
            link.
          </p>
          <InputBox {...forgotPasswordInputObj} />
          <Button
            id="recoverPasswordBtn"
            name="recoverPasswordBtn"
            btnText="Recover Password"
            type="button"
            className={
              loader
                ? "loading"
                : "recoverPasswordBtn btn-new btn-rounded btn-orange btn-text btn-arrow"
            }
            loader={loader}
            click={e => {
              e.preventDefault();
              executeCaptcha(e);
            }}
          />
        </>
      )}
    </section>
  );
};

export default withRouter(ForgotPasswordComponent);
