const { observable, action } = require("mobx");

class LinkedInCookies {
  @observable cookieLiAt = "";
  @observable cookieLiA = "";
  @observable profileData = null;
  @observable isPremiumAccount = false;

  @action
  setCookieLiAt = (value = "") => {
    this.cookieLiAt = value;
  };

  @action
  setCookieLiA = (value = "") => {
    this.cookieLiA = value;
  };

  @action
  setProfileData = (value = "") => {
    this.profileData = value;
  };

  @action
  setIsPremiumAccount = (value = false) => {
    this.isPremiumAccount = value;
  };
}

const linkedInCookiesState = new LinkedInCookies();

export { LinkedInCookies, linkedInCookiesState };
export default LinkedInCookies;
