import React from "react";
import TabNavigation from "Components/common/TabNavigation";
import Routing from "./Routing";
import { DIALER_SETTINGS_TAB_NAV } from "./Constant";

export const Main = () => {
  return (
    <section className="callSettingContainer">
      <TabNavigation tabList={DIALER_SETTINGS_TAB_NAV} />
      <div className="callSettingContent">
        <Routing />
      </div>
    </section>
  );
};

export default Main;
