import React, { useEffect } from "react";
import { observer } from "mobx-react";
import ConnectLinkedInButton from "./ConnectLinkedInButton";
import { linkedInReplyState } from "./state";
import ConnectedLinkedInList from "./ConnectedLinkedInList";
import ConnectedLinkedInConfigure from "./ConnectedLinkedInConfigure";

const LinkedInAccounts = observer((props = {}) => {
  useEffect(() => {
    linkedInReplyState.setLinkedInDataLoading(true);
    linkedInReplyState.getLinkedInAccounts();
  }, []);

  return (
    <div
      className={`connectLinkedInBody ${
        linkedInReplyState?.linkedInConnected ? "linkedInAccountConnected" : ""
      }`}
    >
      {linkedInReplyState?.linkedInConnected ? (
        linkedInReplyState?.showConfigure ? (
          <ConnectedLinkedInConfigure />
        ) : (
          <div className="connectedLinkedInAccountsBody">
            {/* <ConnectedLinkedInHeader /> */}
            <ConnectedLinkedInList />
          </div>
        )
      ) : (
        <ConnectLinkedInButton />
      )}
    </div>
  );
});

export { LinkedInAccounts };
export default LinkedInAccounts;
