import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import DropDownMenu from "Components/common/DropDownMenu";

const TimeRangeDropdown = observer(props => {
  const {
    time = "",
    setTime = () => {},
    setShowDropdown = () => {}
  } = props || {};
  const [updatedOptions, setUpdatedOptions] = useState([]);

  const handleAction = (id, item) => {
    setShowDropdown(false);
    const { value = "" } = item || {};
    const [hours, minutes] = value?.split(":");
    setTime({ ...time, hours: hours?.trim(), minutes: minutes?.trim() });
  };

  const constructOptions = (timeInterval = 15, startTime = 0) => {
    let times = [];

    for (let i = 0; startTime < 12 * 60; i++) {
      let hours = Math.floor(startTime / 60);
      let minutes = startTime % 60;
      times[i] = {
        method: handleAction,
        value:
          (hours === 0 ? "12" : "0" + (hours % 12)).slice(-2) +
          " : " +
          ("0" + minutes).slice(-2)
      };
      startTime = startTime + timeInterval;
    }

    setUpdatedOptions(times);
  };

  useEffect(() => {
    constructOptions(15);
  }, []);

  return (
    <>
      <DropDownMenu options={updatedOptions} />
      <div className="dropdownMask" onClick={() => setShowDropdown(false)} />
    </>
  );
});

export { TimeRangeDropdown };
export default TimeRangeDropdown;
