import React from "react";
import { userDetail } from "Stores/userDetail";
import { UpgradeBadge } from "Components/Paywall/UpgradeBadge";
import CRMOauthConnect from "./CRMOauthConnect";
import PaywallUtils from "Utils/PaywallUtils";

const CrmType = (props = {}) => {
  const {
    typeList = [],
    isSalesforceConnected = false,
    isZohoConnected = false,
    isSlackConnected = false,
    isHubspotConnected = false,
    isPipedriveConnected = false
  } = props || {};
  const {
    slackIntegrationEnabled = false,
    inFreePlan = false,
    zohoIntegrationEnabled = false,
    zapierIntegrationEnabled = true,
    hubspotIntegrationEnabled = false,
    pipedriveIntegrationEnabled = false,
    salesforceIntegrationEnabled = false,
    planName = ""
  } = userDetail.userFeatureFlag || {};
  return (
    <>
      <div className="crmTypeCont">
        {typeList?.map(value => {
          const {
            title = "",
            icon = "",
            name = "",
            message = "",
            comingSoon = "",
            key = ""
          } = value;

          const isFeatureEnabled =
            key === "salesforce"
              ? salesforceIntegrationEnabled
              : key === "slack"
              ? slackIntegrationEnabled
              : key === "zoho"
              ? zohoIntegrationEnabled
              : key === "zapier"
              ? zapierIntegrationEnabled
              : key === "hubspot"
              ? hubspotIntegrationEnabled
              : key === "pipedrive"
              ? pipedriveIntegrationEnabled
              : !inFreePlan;
          const isConnected =
            key === "salesforce"
              ? isSalesforceConnected
              : key === "zoho"
              ? isZohoConnected
              : key === "slack"
              ? isSlackConnected
              : key === "hubspot"
              ? isHubspotConnected
              : key === "pipedrive"
              ? isPipedriveConnected
              : false;
          const planType = [
            "salesforce",
            "slack",
            "zoho",
            "zapier",
            "hubspot",
            "pipedrive"
          ]?.includes(key);
          return (
            <div className="crmCard" key={key}>
              <div className={`crmCardHeader ${key}`}>
                <div className={"titleHeader"}>
                  <div className="titleHeaderFlex">
                    {isFeatureEnabled && ["zapier"].includes(key) ? (
                      <div className="flexRow">
                        <div className="title">{title}</div>
                        <a
                          href="https://support.salesgear.io/en/articles/5870561-how-to-integrate-salesgear-with-zapier"
                          className="linkToSupportArticle"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="material-icons helpIcon">
                            help_outline
                          </span>
                        </a>
                      </div>
                    ) : (
                      <div className="title">{title}</div>
                    )}
                    {!isFeatureEnabled && (
                      <UpgradeBadge
                        plan={PaywallUtils.getPlanName(planName, inFreePlan)}
                        badgeSize={"sm"}
                      />
                    )}
                  </div>
                  {isConnected &&
                    [
                      "salesforce",
                      "slack",
                      "zoho",
                      "hubspot",
                      "pipedrive"
                    ].includes(key) && (
                      <div className="connectSfText">
                        <i className="material-icons">check_circle</i>
                        <span>Connected</span>
                      </div>
                    )}
                </div>
                <div className="icon">
                  <img src={icon} alt={name} />
                </div>
              </div>
              {!isFeatureEnabled && <div className="desc">{message}</div>}
              {comingSoon ? (
                <div className="comingSoonCont">
                  <div className="comingsoonText">Coming soon</div>
                  <div className="contactUs">
                    <a
                      target="_blank"
                      className="supportMailLink"
                      rel="noopener noreferrer"
                      href={`mailto:support@salesgear.io`}
                    >
                      Contact us
                    </a>
                    &nbsp; to learn more
                  </div>
                </div>
              ) : (
                <CRMOauthConnect
                  data={value}
                  isFeatureEnabled={isFeatureEnabled}
                  {...props}
                  key={key}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CrmType;
export { CrmType };
