import React, { useContext } from "react";
import Utils from "Utils/utils";
import AiContentTags from "./AiContentTags";
import DefaultPrompt from "./DefaultPrompt";
import InputTextBox from "Components/common/InputTextBox";
import Button from "Components/common/Button";
import { RewriteAiContext } from "./RewriteAi";
import { ReactComponent as AiMagicWand } from "Assets/svg/WritingAssistant/AiMagicWand.svg";

const RewriteAiInputCard = () => {
  const {
    loader = false,
    showCard = false,
    text = "",
    inputBoxRef = null,
    resetPromots = () => {},
    handleSubmit = () => {},
    generateContent = () => {},
    setText = () => {},
    resetState = () => {}
  } = useContext(RewriteAiContext);

  const inputObj = {
    name: "rewriteAiInput",
    id: "rewriteAiInput",
    type: "text",
    value: text,
    inputBoxRef,
    change: (_, value) => {
      resetPromots();
      setText(value);
    },
    cbk: handleSubmit,
    className: "rewriteAiInput",
    placeHolder: "eg: Write a cold email to a prospect asking for a meeting",
    autoFocus: true,
    onFocus: e => e.persist()
  };

  const helpMeWriteBtn = {
    id: "helpMeWrite",
    name: "helpMeWrite",
    btnText: "Help me write",
    type: "button",
    svg: <AiMagicWand />,
    className: `helpMeWriteBtn ${text?.trim()?.length > 0 ? "" : "disabled"}`,
    click: e => {
      Utils.preventDefaultFn(e);
      generateContent();
    }
  };

  return (
    <div
      className={`rewriteAiInputCard animatedFast ${
        showCard ? "animatedDropDownReverse" : ""
      }`}
    >
      <i
        className="material-icons-outlined closeAiIcon"
        style={{ cursor: loader ? "unset" : "pointer" }}
        onClick={e => {
          Utils.preventDefaultFn(e);
          !loader && resetState();
        }}
      >
        close
      </i>
      <AiContentTags />
      <div className="inputSearchBotWrapper">
        <DefaultPrompt />
        <InputTextBox {...inputObj} />
        <Button {...helpMeWriteBtn} />
      </div>
    </div>
  );
};

export default RewriteAiInputCard;
