/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Link, withRouter, useLocation } from "react-router-dom";
import { MP_EVENT, WEBSITE_URL } from "Static/constant";
import Button from "Components/common/Button";
import { Utils } from "Utils/utils";
import { checkTeamStatus, getFeatureFlagWithTeam } from "Utils/commonAPI";
import makeApi, { URLS } from "Utils/apiURL";
import { userDetail } from "Stores/userDetail";
import { toasterState } from "Components/common/toaster";
import { captchaStoreValue } from "Stores/captcha";

const TeamInviteSignup = props => {
  let location = useLocation();
  const { email, inviteToken } = userDetail.userTeamInfo;

  const userData = {};
  const utsCookie = Utils.readCookie("uts"),
    utmCookie = Utils.readCookie("utm"),
    utcCookie = Utils.readCookie("utc"),
    utaCookie = Utils.readCookie("uta");
  if (
    utsCookie == null &&
    utmCookie == null &&
    utcCookie == null &&
    utaCookie == null
  ) {
    const utsSource = Utils.getUrlParameter("utm_source"),
      utmSource = Utils.getUrlParameter("utm_medium"),
      utcSource = Utils.getUrlParameter("utm_campaign"),
      utaSource = Utils.getUrlParameter("utm_content");
    if (utmSource != null) {
      Utils.setCookie("uts", utsSource);
      Utils.setCookie("utm", utmSource);
      Utils.setCookie("utc", utcSource);
      Utils.setCookie("uta", utaSource);
    }
  }

  const [userPassword, setUserPassword] = useState(""),
    [isAgreeTC, setIsAgreeTC] = useState(false),
    [isStorePersonal, setIsStorePersonal] = useState(false),
    [isProductAnnouncement, setIsProductAnnouncement] = useState(false),
    [emailErr, setEmailErr] = useState(""),
    [passwordErr, setPasswordErr] = useState(""),
    [errMsg, setErrMsg] = useState(""),
    [agreeTCErr, setAgreeTCErr] = useState(""),
    [storePersonalErr, setStorePersonalErr] = useState(""),
    [loader, setLoader] = useState(false),
    timeZoneValidation = Utils.validateEUTimeZone(),
    userEuChecked = Utils.isEuCheckboxEnabled(isAgreeTC, isStorePersonal);

  // Event Binding
  const checkValidTeamPage = () => {
      if (!email) {
        props.history.push("/login");
      }
    },
    checkEuRegister = (emailValid, passwordValid) => {
      if (timeZoneValidation) {
        let isEuCheckboxEnabled = Utils.isEuCheckboxEnabled(
          isAgreeTC,
          isStorePersonal
        );
        return emailValid && passwordValid && isEuCheckboxEnabled;
      } else {
        return emailValid && passwordValid;
      }
    },
    acceptTeamInvitation = async () => {
      try {
        const config = {
          url: URLS.acceptTeamInvite,
          method: "POST",
          data: {
            email,
            inviteToken
          }
        };
        let res = await makeApi(config);
        if (res?.data?.status === "success") {
          props.history.push("/teamInvite/success");
        } else {
          userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
          userData.team = await checkTeamStatus();
          userData.userLoggedIn = true;
          userData.email = email.trim().toLowerCase();
          toasterState.setToastMsg(
            "Something went wrong. Please contact us at support@salesgear.io",
            "fail"
          );
          userDetail.setUserInfo(userData);
          props.fn(true);
          props.history.push(`/signupSuccess${location.search}`, {
            query: location.search
          });
        }
      } catch (err) {
        console.error("Team invitation signup accept err", err);
      }
    },
    changeTeamStatus = async () => {
      const config = {
        url: URLS.changeTeamStatus ? URLS.changeTeamStatus : "",
        method: "GET"
      };
      await makeApi(config);
      return true;
    },
    createTeam = async () => {
      try {
        const config = {
          url: URLS.initializeTeam ? URLS.initializeTeam : "",
          method: "POST"
        };
        let res = await makeApi(config);
        if (res && res.data && res.data.teamMember) {
          await changeTeamStatus();
          await acceptTeamInvitation();
        } else {
          userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
          userData.team = await checkTeamStatus();
          userData.email = email.trim().toLowerCase();
          userData.userLoggedIn = true;
          toasterState.setToastMsg(
            `Something went wrong. Please contact us at support@salesgear.io`,
            "fail"
          );
          userDetail.setUserInfo(userData);
        }
      } catch (err) {
        console.error("Team create signup err", err);
      }
    },
    signup = async token => {
      try {
        setLoader(true);
        let isEmailValid = Utils.emailValidate(email.trim().toLowerCase());
        let isPasswordValid = Utils.validateStringLength(userPassword, 6);
        if (checkEuRegister(isEmailValid, isPasswordValid)) {
          const config = {
            url: URLS.signupUser,
            method: "POST",
            data: {
              email: email.trim().toLowerCase(),
              password: userPassword,
              emailPreferences: isProductAnnouncement,
              source: "WEB",
              token,
              mixpanelInfo: {
                pageType: "WEB"
              },
              utmSource: "" /* Utils.readCookie("uts") */,
              utmMedium: "" /* Utils.readCookie("utm") */,
              utmCampaign: "" /* Utils.readCookie("utc") */,
              utmContent: "" /* Utils.readCookie("uta")*/,
              userTimezone: {
                zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
                zone_value: Utils.getCurrentUserTimeZone()
              },
              redirectUrl: "/signupSuccess?redirect=dashboard",
              isTeamInvite: true
            }
          };
          let response = await makeApi(config);
          switch (response.data) {
            case "true":
            case true:
              localStorage.setItem("isOnboarding", true);
              Utils.mixpanelTrack(MP_EVENT.USER_REGISTERATION, {
                email,
                pageType: "Salesgear Team Invite Register ",
                source: "WEB"
              });
              toasterState.setToastMsg(
                "Email address verified successfully.",
                "success"
              );
              await createTeam();
              break;
            case "limit":
              setErrMsg(`We have noticed too many registrations from this domain. Please
                <a target="_blank" rel="noopener noreferrer" href="mailto:support@salesgear.io">contact support</a> for further help`);
              setLoader(false);
              break;
            case "business":
              setEmailErr("Please enter a valid work email address");
              resetEmailErr();
              setLoader(false);
              break;
            case "invalid":
              setEmailErr("Please enter a valid email address");
              resetEmailErr();
              setLoader(false);
              break;
            case "false":
            case false:
              setLoader(false);
              setEmailErr("Email address already exists");
              resetEmailErr();
              break;
            default:
              setLoader(false);
              setErrMsg("Something went wrong");
              resetEmailErr();
              break;
          }
        } else if (!isEmailValid) {
          setEmailErr("Please enter a valid email address");
          setLoader(false);
        } else if (!isPasswordValid) {
          setPasswordErr("Password must be at least 6 characters");
          setLoader(false);
        } else if (!userEuChecked) {
          if (!isAgreeTC) {
            setAgreeTCErr("Please accept our terms and privacy policy");
          }
          if (!isStorePersonal) {
            setStorePersonalErr(
              "Please give your consent to store information"
            );
          }
          setLoader(false);
          return false;
        } else {
          setLoader(false);
          setErrMsg("Please check your email address or password");
        }
      } catch (err) {
        setLoader(false);
        console.error("Signup Error", err);
      }
    },
    submitForm = e => {
      e.preventDefault();
      if (e.keyCode === 13) {
        Utils.removeInputRequired(e);
        executeCaptcha();
      }
    },
    passwordChange = e => {
      setUserPassword(e.currentTarget.value);
      setPasswordErr("");
      setErrMsg("");
    },
    agreeTCClick = () => {
      setIsAgreeTC(!isAgreeTC);
      setAgreeTCErr("");
      setErrMsg("");
    },
    storePersonalClick = () => {
      setIsStorePersonal(!isStorePersonal);
      setStorePersonalErr("");
      setErrMsg("");
    },
    productAnnouncementClick = () => {
      setIsProductAnnouncement(!isProductAnnouncement);
    },
    resetEmailErr = () => {
      setTimeout(() => {
        setEmailErr("");
      }, 1500);
    };

  const executeCaptcha = e => {
    if (e) {
      Utils.removeInputRequired(e);
    }
    let isEmailValid = Utils.emailValidate(email.trim().toLowerCase());
    let isPasswordValid = Utils.validateStringLength(userPassword, 6);
    if (checkEuRegister(isEmailValid, isPasswordValid)) {
      setLoader(true);
      captchaStoreValue.executeCaptcha(validateCaptchaFromAPi);
    }
  };

  const validateCaptchaFromAPi = async token => {
    token && signup(token);
  };

  useEffect(() => {
    checkValidTeamPage();
  }, [checkValidTeamPage]);

  const TermsAndContAndPrivacy = () => {
    return (
      <span>
        By signing up, you are accepting our
        <a
          href={`${WEBSITE_URL}/terms-of-service`}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Terms&nbsp;
        </a>{" "}
        and
        <a
          href={`${WEBSITE_URL}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Privacy Policy.
        </a>
      </span>
    );
  };

  return (
    <div id="teamSignup" className="teamSignup">
      <h6>Set a Password and continue</h6>
      <div name="teamSignupForm" id="teamSignupForm">
        <div className="teamEmailPopulate">
          <label htmlFor="emailSignUp">Email Address</label>
          <p className="email">{email !== null ? email : ""}</p>
          <span className="errMsgText">{emailErr}</span>
        </div>
        <div className="input-form">
          <input
            id="passwordSignUp"
            name="passwordSignUp"
            type="password"
            autoFocus
            defaultValue={userPassword}
            onChange={e => passwordChange(e)}
            onKeyUp={e => submitForm(e)}
            required
          />
          <span className="highlight"></span>
          <span className="bar"></span>
          <label htmlFor="passwordSignUp">Password</label>
          <span className="err-msg-text">{passwordErr}</span>
          <span
            className="err-msg-text btm-m-10"
            dangerouslySetInnerHTML={{
              __html: errMsg || ""
            }}
          />
        </div>
        <div className="form-button">
          <Button
            id="signupBtn"
            name="signupBtn"
            btnText="Get Started"
            icon={true}
            iconName="keyboard_backspace"
            type="button"
            className={loader ? "loading" : "signupBtn rotateForwardIcon"}
            loader={loader}
            click={e => {
              e.preventDefault();
              executeCaptcha(e);
            }}
          />
        </div>
      </div>
      <div className="info-text">
        <span>Already have an account? </span>
        <Link to="/teamInvite/login">Log In</Link>
      </div>
      {timeZoneValidation ? (
        <div className="eu-checbox-section">
          <span className="err-eu-check">{agreeTCErr}&nbsp;</span>
          <span className="err-eu-check">{storePersonalErr}&nbsp;</span>
          <ul className="checkbox-tick">
            <li className="list-item">
              <label htmlFor="termCondition">
                <input
                  id="termCondition"
                  name="termCondition"
                  type="checkbox"
                  className="checkbox"
                  checked={isAgreeTC}
                  onChange={agreeTCClick}
                />
                {TermsAndContAndPrivacy()}
              </label>
            </li>
            <li className="list-item">
              <label htmlFor="storePersonal">
                <input
                  id="storePersonal"
                  name="storePersonal"
                  type="checkbox"
                  className="checkbox"
                  checked={isStorePersonal}
                  onChange={storePersonalClick}
                />
                <span>
                  I give my consent to store my personal information outside the
                  European Union.
                </span>
              </label>
            </li>
            <li className="list-item">
              <label htmlFor="productAnnouncement">
                <input
                  id="productAnnouncement"
                  name="productAnnouncement"
                  type="checkbox"
                  className="checkbox"
                  checked={isProductAnnouncement}
                  onChange={productAnnouncementClick}
                />
                <span>
                  I would like to receive email updates from Salesgear on new
                  product announcements and upgrades.
                </span>
              </label>
            </li>
          </ul>
        </div>
      ) : (
        <div className="termsAndPolicyText">{TermsAndContAndPrivacy()}</div>
      )}
    </div>
  );
};

export default withRouter(observer(TeamInviteSignup));
