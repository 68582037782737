import React from "react";
import Utils from "Utils/utils";
import { ReactComponent as AiMagicWand } from "Assets/svg/WritingAssistant/AiMagicWand.svg";

const WandButton = ({ action = () => {} }) => {
  return (
    <div
      className="aiMagicWandBtn"
      onClick={e => {
        Utils.preventDefaultFn(e);
        action();
      }}
    >
      <AiMagicWand />
    </div>
  );
};

export default WandButton;
