import makeApi, { URLS } from "Utils/apiURL";
const { observable, action } = require("mobx");

class SeqTemplatePopupState {
  @observable action = "";
  @observable progressBarState = 1;
  @observable generateAISeqValue = {};
  @observable showLottie = false;
  @observable hasDomainChange = false;

  @action
  setAction = val => {
    this.action = val;
  };

  @action
  setProgressBarState = val => {
    this.progressBarState = val;
  };

  @action
  setGenerateAISeqValue = val => {
    this.generateAISeqValue = val;
  };

  @action
  setShowLottie = val => {
    this.showLottie = val;
  };

  @action
  setHasDomainChange = val => {
    this.hasDomainChange = val;
  };

  @action
  getDomain = async () => {
    const config = {
      url: this.generateAISeqValue?.domain
        ? `${URLS.getWebsiteDomain}?website=${this.generateAISeqValue?.domain}`
        : URLS.getWebsiteDomain,
      method: "GET"
    };
    const res = await makeApi(config);
    if (+res?.status === +200) {
      this.setGenerateAISeqValue({ ...this.generateAISeqValue, ...res?.data });
      return true;
    }
  };
}

const seqTemplatePopupState = new SeqTemplatePopupState();

export { seqTemplatePopupState };
