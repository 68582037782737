/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ContactInputTag from "./ContactInputTag";
import { settingsStoreData } from "Stores/settingsData";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import { PAYWALL_CONTENT } from "Model/model";
import UpgradeTooltip from "../../Paywall/UpgradeTooltip";
import { ReactComponent as ThunderBlueIcon } from "Assets/svg/thunderBlue.svg";
import { Paywall } from "model/revampedModel";
import { upgradePopupState } from "../../Paywall/UpgradePopup";
import PaywallUtils from "Utils/PaywallUtils";

const ContactCustomFields = props => {
  const [showUpgradeTp, setShowUpgradeTp] = useState(false);
  const { customFieldsValue, changeInputVal, tokenList } = props;

  const {
    customFieldsCount = 0,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const [customFields, setCustomFields] = useState(
    settingsStoreData.customFields
  );

  const changeCustomFields = (id, data) => {
    changeInputVal(id, data, "customFields");
  };

  useEffect(() => {
    setCustomFields(settingsStoreData.customFields);
  }, [settingsStoreData?.customFields]);

  const disableCheckFun = (updatedIndex = 1) => {
    return updatedIndex > customFieldsCount;
  };

  useEffect(() => {
    PaywallUtils.upgradeUtilsFunction(
      "customFieldsCount",
      false,
      planName,
      inFreePlan,
      { customFieldsCount },
      true
    );
  }, []);

  return (
    <div className="info customFieldsWrapper">
      <h5 className="sectionTitle">Custom Fields</h5>

      <div className="sectionInputTagsCont">
        {Array.from({ length: customFieldsCount }, (value, index) => {
          const customFieldValue = `customField${index + 1}`;
          return (
            <ContactInputTag
              key={`customField ${index}`}
              name={`customField${index + 1}`}
              id={`customField${index + 1}`}
              value={customFieldsValue?.[customFieldValue] || ""}
              text={
                customFields[`customField${index + 1}`] ||
                `Custom Field ${index + 1}`
              }
              onBlurEvent={changeCustomFields}
              required={Utils.findIsRequiredFieldForSingleContactSequence(
                `customField${index + 1}`,
                tokenList
              )}
              disableCheck={disableCheckFun(index + 1)}
              planName={planName}
            />
          );
        })}
        <div
          className="addCustomFields"
          onMouseEnter={() => setShowUpgradeTp(true)}
          onMouseLeave={() => setShowUpgradeTp(false)}
        >
          <div>Add more custom fields</div>
          <ThunderBlueIcon />
          {showUpgradeTp && (
            <UpgradeTooltip
              {...Paywall("customFieldsCount")}
              alignTooltip="contactCustom"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactCustomFields;
export { ContactCustomFields };
