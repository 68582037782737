import React from "react";
import { observer } from "mobx-react";
import DropDownMenu from "Components/common/DropDownMenu";
import CustomDropdown from "./CustomDropdown";

const FilterDropdown = observer(props => {
  const {
    options = [],
    showDropdown = false,
    defaultValue = "",
    label = "Label",
    referenceId = "",
    index = "",
    setActiveDropdownIndex = () => {},
    isCustomValue = false,
    customCbk = () => {},
    refValue = null,
    handleScrollEnd = () => {},
    errorMsg = "",
    errMsgClass = "",
    hasLabel = true,
    hasValue = false,
    emptyText = "",
    hasCustomFieldValue = false,
    customLoader = false,
    className = "",
    hasDefaultChildren = false,
    accessToEditField = true,
    key = "",
    ...remainingProps
  } = props || {};

  return (
    <div className="filterDropdownWrap">
      <div className="filterDropdown">
        <div
          className="filterHeader"
          onClick={() => accessToEditField && setActiveDropdownIndex(index)}
        >
          {hasLabel && (
            <label
              className={!showDropdown && !defaultValue ? "adjustLabel" : ""}
            >
              {label}
            </label>
          )}
          <div className={`activeDropdownValue ${className}`}>
            {hasDefaultChildren ? (
              <span className="activeValue">{defaultValue}</span>
            ) : (
              <span
                className="activeValue"
                dangerouslySetInnerHTML={{
                  __html: defaultValue
                }}
              />
            )}
            <i className="material-icons-outlined">expand_more</i>
          </div>
        </div>
        {showDropdown && (
          <>
            {isCustomValue ? (
              <CustomDropdown
                {...remainingProps}
                options={options}
                customCbk={customCbk}
                refValue={refValue}
                handleScrollEnd={handleScrollEnd}
                errorMsg={errorMsg}
                hasValue={hasValue}
                emptyText={emptyText}
                hasCustomFieldValue={hasCustomFieldValue}
                loader={customLoader}
              />
            ) : (
              <DropDownMenu
                {...remainingProps}
                options={options}
                referenceId={referenceId}
                isCustomValue={isCustomValue}
                errorMsg={errorMsg}
                hasCustomFieldValue={hasCustomFieldValue}
              />
            )}
            <div
              className="dropdownMask"
              onClick={() => setActiveDropdownIndex(false)}
            />
          </>
        )}
      </div>
      {errorMsg && (
        <span
          className={`errMsgText ${errMsgClass}`}
          dangerouslySetInnerHTML={{
            __html: errorMsg || ""
          }}
        />
      )}
    </div>
  );
});

export default FilterDropdown;
export { FilterDropdown };
