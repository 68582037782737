import { action, observable } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import teamState from "../state";
import { MXP_EVENT } from "Static/MixPanelEvents";

class TeamMemberState {
  @observable teamMemberData = {};
  @observable teamMemberLoading = true;
  @observable showAddMemberPopup = false;

  @action
  setTeamMemberData = val => {
    this.teamMemberData = val;
  };

  @action
  setShowAddMemberPopup = val => {
    this.showAddMemberPopup = val;
  };

  @action
  setTeamMemberLoading = val => {
    this.teamMemberLoading = val;
  };

  @action
  getGroupMembers = async groupId => {
    const config = {
      url: URLS.getGroupMembers,
      method: "POST",
      data: {
        pageNo: 1,
        limit: 500,
        groupId
      }
    };
    const res = await makeApi(config);
    if (res?.data) {
      await teamState.getTeamData(true);
      this.setTeamMemberLoading(false);
      this.setTeamMemberData(res?.data);
    }
  };

  @action
  addMemberToGroup = async (group, memberList, cbk = () => {}) => {
    const config = {
      url: URLS.addMemberToGroup,
      method: "POST",
      data: {
        groupId: group?.id,
        memberList
      }
    };
    const res = await makeApi(config);
    if (res?.data) {
      this.setShowAddMemberPopup(false);
      Utils.mixpanelTrack(MXP_EVENT.TEAM_MEMBER_ADDED, {
        pageType: "users & teams",
        teamName: group?.name
      });
      const { code = "" } = res?.data || {};
      if (+code === 200) {
        this.setTeamMemberLoading(true);
        this.getGroupMembers(group?.id);
      }
    }
    cbk();
  };

  @action
  removeMemberFromGroup = async (list = {}, team = false) => {
    const { groupId = "", memberId = "", owner = false } = list || {};
    const config = {
      url: URLS.removeMemberFromGroup,
      method: "POST",
      data: {
        groupId,
        memberList: [memberId]
      }
    };
    const res = await makeApi(config);
    if (res?.data) {
      confirmationPopupState.setShowPopup(false);
      const { code = "" } = res?.data || {};
      if (+code === 200) {
        if (!team && owner) {
          let value = await teamState.getTeamData();
          userDetail.setUserInfo({
            ...userDetail.userInfo,
            isTeamOwner: value
          });
          value && teamState.setShowTeamMemberPage(false);
        } else {
          this.setTeamMemberLoading(true);
          this.getGroupMembers(groupId);
        }
      }
    }
  };

  @action
  makeOwner = async (groupId, makeOwnerId) => {
    const config = {
      url: URLS.makeOwner,
      method: "POST",
      data: {
        groupId,
        makeOwnerId
      }
    };
    const res = await makeApi(config);
    if (res?.data) {
      const { code = "", memberList = [] } = res?.data || {};
      if (+code === 200) {
        const name = Utils.getFullNameFromUserInfo(memberList?.[0]);
        this.getGroupMembers(groupId);
        if (name) {
          toasterState.setToastMsg(`${name} is the owner of the team`);
        }
      } else if (+code === 401) {
        toasterState.setToastMsg(
          "Oops! You don't have the privilege to make another member as team owner.",
          "failure"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "failure"
        );
      }
    }
  };
}

const teamMemberState = new TeamMemberState();
export { teamMemberState };
export default teamMemberState;
