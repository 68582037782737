import React from "react";

const LiveFeedLoading = props => {
  const { cards = 7 } = props;
  return (
    <div className="liveFeedLoadingWrapper">
      <div className="dateLoadingCard" style={{ marginBottom: "16px" }}>
        <div
          className="linear-background"
          style={{ height: "14px", width: "150px", borderRadius: "8px" }}
        ></div>
      </div>
      {Array.from({ length: cards }, (value, index) => (
        <div
          className={`accordionSection ${index + 1}`}
          key={`loadingcards${index}`}
          style={{ paddingBottom: "10px" }}
        >
          <div className="leftPanel">
            <div
              className="linear-background"
              style={{ height: "100%", width: "100%", borderRadius: "50%" }}
            ></div>
          </div>

          <div className="rightPanel" style={{ margin: "8px" }}>
            <div className="wrapRightPanel">
              <div
                className="linear-background"
                style={{
                  height: "14px",
                  width: "275px",
                  borderRadius: "10px",
                  marginBottom: "8px"
                }}
              ></div>
              <div
                className="linear-background"
                style={{
                  height: "14px",
                  width: "150px",
                  borderRadius: "10px",
                  marginBottom: "8px"
                }}
              ></div>
            </div>
            <div className="additionalInfoWrapper" style={{ padding: "8px" }}>
              <div
                className="linear-background"
                style={{
                  height: "14px",
                  width: "92px",
                  borderRadius: "10px",
                  marginRight: "8px"
                }}
              ></div>
              <div
                className="linear-background"
                style={{
                  height: "14px",
                  width: "92px",
                  borderRadius: "10px"
                }}
              ></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export { LiveFeedLoading };
export default LiveFeedLoading;
