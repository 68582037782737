import React, { useState, useEffect } from "react";
import PaywallUtils from "Utils/PaywallUtils";
import userDetail from "Stores/userDetail";
import DropdownMenu from "Components/common/DropDownMenu";
import { ReactComponent as CvsExportIcon } from "Assets/svg/csvExport.svg";
import { SEQUENCE_STAGE_ICON } from "Model/model";

const AddStageDropDown = ({
  showDropDownMenu,
  setShowDropDownMenu,
  defaultSelected = "email",
  steps = 0,
  stepLen = 0,
  considerStepLen = false,
  cbk = () => {},
  isTaskCreation = false,
  className = ""
}) => {
  const {
    taskEnabled = false,
    planName = "",
    inFreePlan = false,
    liAutomationEnabled = false
  } = userDetail?.userFeatureFlag || {};

  const handleClick = (key = "", dropDownObj = {}) => {
    setShowDropDownMenu(false);
    if (dropDownObj?.showUpgrade) {
      if (dropDownObj?.key?.toLowerCase() === "linkedinauto") {
        PaywallUtils.upgradeUtilsFunction(
          "sequenceLinkedInAddToSequence",
          true,
          planName,
          inFreePlan
        );
      } else {
        PaywallUtils.upgradeUtilsFunction(
          "sequenceAddTaskToSequence",
          true,
          planName,
          inFreePlan
        );
      }
    } else {
      cbk(dropDownObj);
    }
  };

  const stepOptions = [
    {
      ...(!isTaskCreation && {
        icon: {
          ele: SEQUENCE_STAGE_ICON["email"]
        },
        value: "Email (Automatic)",
        key: "email",
        method: handleClick,
        planName: PaywallUtils.getPlanName(planName, inFreePlan),
        type: "sequenceAddTaskToSequence"
      })
    },
    {
      icon: {
        ele: SEQUENCE_STAGE_ICON["manualEmail"]
      },
      value: "Email (Manual)",
      key: "manualEmail",
      method: handleClick,
      planName: PaywallUtils.getPlanName(planName, inFreePlan),
      type: "sequenceAddTaskToSequence",
      showUpgrade: !taskEnabled
    },
    {
      ...(!isTaskCreation && {
        icon: {
          ele: SEQUENCE_STAGE_ICON["linkedin"]
        },
        value: "Linkedin (Automatic)",
        key: "linkedinAuto",
        method: handleClick,
        planName: PaywallUtils.getPlanName(planName, inFreePlan),
        type: "sequenceAddTaskToSequence",
        showUpgrade: !liAutomationEnabled
      })
    },
    {
      icon: {
        ele: SEQUENCE_STAGE_ICON["linkedin"]
      },
      value: "Linkedin (Manual)",
      key: "linkedin",
      method: handleClick,
      planName: PaywallUtils.getPlanName(planName, inFreePlan),
      type: "sequenceAddTaskToSequence",
      showUpgrade: !taskEnabled
    },
    {
      icon: {
        ele: SEQUENCE_STAGE_ICON["phone"]
      },
      value: "Call",
      key: "phone",
      method: handleClick,
      planName: PaywallUtils.getPlanName(planName, inFreePlan),
      type: "sequenceAddTaskToSequence",
      showUpgrade: !taskEnabled
    },
    {
      icon: {
        ele: SEQUENCE_STAGE_ICON["customTask"]
      },
      value: "Custom Task",
      key: "customTask",
      method: handleClick,
      planName: PaywallUtils.getPlanName(planName, inFreePlan),
      type: "sequenceAddTaskToSequence",
      showUpgrade: !taskEnabled
    }
  ].filter(value => Object.keys(value).length !== 0);

  return (
    <div className={className}>
      {showDropDownMenu && (
        <>
          <DropdownMenu
            options={stepOptions}
            direction={
              considerStepLen ? (stepLen > 1 ? "bottomAlign" : "") : ""
            }
          />
          <div
            className="dropdownMask"
            onClick={e => setShowDropDownMenu(false)}
          />
        </>
      )}
    </div>
  );
};

export { AddStageDropDown };
export default AddStageDropDown;
