import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import userDetail from "Stores/userDetail";
import PricingStore from "Stores/Pricing";
import ToggleBtnType from "Components/common/ToggleBtnType";
import Utils from "Utils/utils";
import ChooseSeats from "./ChooseSeats";

const SeatsPurchaseBilling = (props = {}) => {
  const { seats, annual, initTeamSeat, price, annualPrice, annualDiscount } =
    PricingStore.paymentFormInfo || {};

  const [seatCount, setSeatCount] = useState(seats || 1);
  const [toogleType, setToogleType] = useState(annual);
  const [monthlyPrice, setMonthlyPrice] = useState(price);
  const [yearlyPrice, setYearlyPrice] = useState(annualPrice);
  const [discount, setDiscount] = useState(annualDiscount);

  const getTogglePlanType = value => {
    PricingStore.setPaymentFormInfo({ annual: value });
    setToogleType(value);
  };

  const getTotalAmount = () => {
    if (toogleType) {
      return Utils.numberWithCommas(seatCount * yearlyPrice * 12);
    } else {
      return Utils.numberWithCommas(seatCount * monthlyPrice);
    }
  };

  const getSeatPrice = () => {
    if (toogleType) {
      return yearlyPrice;
    } else {
      return monthlyPrice;
    }
  };

  useEffect(() => {
    if (PricingStore.paymentFormInfo.seats !== null) {
      setSeatCount(PricingStore.paymentFormInfo.seats);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PricingStore.paymentFormInfo.seats]);

  useEffect(() => {
    getTotalAmount();
    setMonthlyPrice(PricingStore.paymentFormInfo.price);
    setYearlyPrice(PricingStore.paymentFormInfo.annualPrice);
    setDiscount(PricingStore.paymentFormInfo.annualDiscount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    PricingStore.paymentFormInfo.price,
    PricingStore.paymentFormInfo.annualPrice,
    PricingStore.paymentFormInfo.annualDiscount
  ]);

  useEffect(() => {
    getTotalAmount();
    setMonthlyPrice(PricingStore.paymentFormInfo.price);
    setYearlyPrice(PricingStore.paymentFormInfo.annualPrice);
    setDiscount(PricingStore.paymentFormInfo.annualDiscount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PricingStore.paymentFormInfo.annual]);

  useEffect(() => {
    const { noOfSeatsUsed = 0 } = userDetail?.userFeatureFlag || {};
    PricingStore.setPaymentFormInfo({
      seats: Math.abs(noOfSeatsUsed) || 1,
      initTeamSeat: Math.abs(noOfSeatsUsed) || 1
    });
  }, [userDetail?.userFeatureFlag]);

  useEffect(() => {
    if (
      PricingStore.paymentFormInfo &&
      !PricingStore.paymentFormInfo.displayName
    ) {
      props.history.push("pricing");
    }
  });

  return (
    <div className="seatBillingSection">
      <h3 className="title">Purchase Summary</h3>
      <ChooseSeats
        initTeamSeat={initTeamSeat}
        seatCount={seatCount}
        setSeatCount={setSeatCount}
      />
      <div className="calenderFilterSection">
        <ToggleBtnType
          firstText="Monthly"
          defaultValue={toogleType}
          offer={discount}
          cbk={getTogglePlanType}
          lastText="Annual"
        />
      </div>
      <div className="seatPriceTable">
        <div className="detailCard">
          <span className="text">Seats</span>
          <span className="value">
            {seatCount} Seat{seatCount > 1 ? "s" : ""}
          </span>
        </div>
        <div className="multiplySymbol">
          <i className="material-icons">close</i>
        </div>
        <div className="detailCard">
          <span className="text">Price/Seat</span>
          <span className="value">${getSeatPrice()}/mo</span>
        </div>
        <div className="multiplySymbol">
          <i className="material-icons">close</i>
        </div>
        <div className="detailCard">
          <span className="text">Billing for</span>
          <span className="value">{toogleType ? `12 Months` : `1 Month`}</span>
        </div>
      </div>
      <div className="totalAmount">
        <span className="text">Total</span>
        <span className="priceAmount">${getTotalAmount()}.00</span>
      </div>
    </div>
  );
};

export default withRouter(observer(SeatsPurchaseBilling));
export { SeatsPurchaseBilling };
