import React, { useState, useContext, useEffect } from "react";
import { AccountsListingContext } from "../AccountsList";
import MultiSelectDropdown from "Pages/Dashboard/contacts/ContactFilter/MultiSelectDropdown";
import ContactFieldFilter from "Pages/Dashboard/contacts/ContactFilter/ContactFieldFilter";
import ContactDateFilter from "Pages/Dashboard/contacts/ContactFilter/ContactDateFilter";
import ContactMetricsFilter from "Pages/Dashboard/contacts/ContactFilter/ContactMetricsFilter";
import {
  EmailFilterList,
  CallsFilterList,
  TasksFilterList,
  AccountFieldsList,
  ContactsFilterList
} from "../AccountConstants";
import FooterAction from "./FooterAction";
import HeaderAction from "./HeaderAction";

export const Main = ({
  applyFilterCbk = () => {},
  resetFilterCbk = () => {}
}) => {
  const {
    data = {},
    filterList = [],
    setFilterList = () => {},
    setData = {},
    showAccountFilter = false,
    setShowAccountFilter = () => {}
  } = useContext(AccountsListingContext);

  const [activeDropdown, setActiveDropdown] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [disableContactFieldAddBtn, setDisableContactFieldAddBtn] =
    useState(true);

  const clearAllFilter = async () => {
    if (filterList?.length > 0) {
      let value = resetFilterCbk();
      if (value) {
        applyFilterCbk({
          pageNo: 1,
          searchTerm: "",
          limit: 10,
          filterPayload: {
            stageId: ""
          }
        });
      }
    }
  };

  const cancelFilter = () => {
    clearAllFilter();
    setShowAccountFilter(!showAccountFilter);
  };

  const applyFilter = async () => {
    activeDropdown && setActiveDropdown("");
    if (filterList?.length > 0) {
      applyFilterCbk();
    }
  };

  const handleActiveFilter = (value = "") => {
    setActiveDropdown(value === activeDropdown ? "" : value);
  };

  const actionList = [
    {
      className: "cancelFilter",
      method: cancelFilter,
      name: "Cancel",
      id: "cancelFilter"
    },
    {
      className: `applyFilter ${filterList?.length > 0 ? "" : "disabled"}`,
      method: applyFilter,
      name: "Apply Filter",
      id: "applyFilter"
    }
  ];

  const getSelectedItems = (item = []) => {
    if (item?.length > 0) {
      return item.filter(item => item?.checked) || [];
    }
    return [];
  };

  // Handle Change Action
  const setFiterUsed = (isValidList = false, key = "") => {
    if (key) {
      let tempList = [...filterList];
      if (isValidList && !tempList.includes(key)) {
        tempList.push(key);
      } else if (!isValidList && filterList?.length > 0) {
        tempList = tempList.filter(
          item => item?.toLowerCase() !== key?.toLowerCase()
        );
      }
      setFilterList(tempList);
    }
  };

  const getSelectedFilterKey = (key = "", value = []) => {
    if (key && value?.length > 0) {
      let arr = [];
      switch (key.toLowerCase()) {
        case "accountfields":
        case "tasks":
        case "calls":
        case "emails":
        case "contacts":
          arr = value.filter(
            item =>
              item?.fieldName && item?.label && item?.operator && item?.value
          );
          break;
        case "owners":
          arr = value.filter(item => item?.checked);
          break;
        case "dates":
          arr = value.filter(
            item => item?.fieldName && item?.fromDate && item?.toDate
          );
          break;
        default:
          arr = value;
          break;
      }
      setFiterUsed(arr.length > 0, key);
    }
  };

  const validateData = (data = []) => {
    let validData = false;
    const dataLength = data?.length;
    if (dataLength) {
      const { fieldName = "", value = "" } = data?.[dataLength - 1] || {};
      validData = fieldName && value;
    }
    return validData;
  };

  const handleFilterObject = (key = "", value = []) => {
    if (key) {
      getSelectedFilterKey(key, value);
      let tempObj = { ...data, [key]: value };
      setData({ ...tempObj });
      const validData = validateData(tempObj?.[key]);
      setDisableContactFieldAddBtn(!validData);
    }
  };

  const filterOption = [
    {
      label: "Account Field",
      keyValue: "accountFields",
      data: data?.accountFields,
      activeFilter: activeDropdown,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      errMsg: errMsg,
      setErrMsg: setErrMsg,
      type: "multiFieldSelect",
      customConditionalFieldList: [...AccountFieldsList],
      disableAddButton: disableContactFieldAddBtn
    },
    {
      label: "Owners",
      keyValue: "owners",
      placeholder: "Select",
      selected: getSelectedItems(data?.owners),
      data: data?.owners || [],
      setAction: handleFilterObject,
      activeFilter: activeDropdown,
      setActiveFilter: handleActiveFilter,
      type: "multiSelectApply",
      count: 2
    },
    {
      label: "Date",
      keyValue: "dates",
      data: data?.dates || [],
      activeFilter: activeDropdown,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      errMsg: errMsg,
      setErrMsg: setErrMsg,
      type: "multiDateSelect"
    },
    {
      label: "Contacts",
      keyValue: "contacts",
      data: data?.contacts,
      activeFilter: activeDropdown,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      errMsg: errMsg,
      setErrMsg: setErrMsg,
      type: "multiFieldNumberSelect",
      customConditionFieldnameList: [...ContactsFilterList],
      singleFieldWithoutDropdown: true
    },
    {
      label: "Emails",
      keyValue: "emails",
      data: data?.emails,
      activeFilter: activeDropdown,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      errMsg: errMsg,
      setErrMsg: setErrMsg,
      type: "multiFieldNumberSelect",
      customConditionFieldnameList: [...EmailFilterList],
      disableAddButton: disableContactFieldAddBtn
    },
    {
      label: "Calls",
      keyValue: "calls",
      data: data?.calls,
      activeFilter: activeDropdown,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      errMsg: errMsg,
      setErrMsg: setErrMsg,
      type: "multiFieldNumberSelect",
      customConditionFieldnameList: [...CallsFilterList],
      disableAddButton: disableContactFieldAddBtn
    },
    {
      label: "Tasks",
      keyValue: "tasks",
      data: data?.tasks,
      activeFilter: activeDropdown,
      setActiveFilter: handleActiveFilter,
      setAction: handleFilterObject,
      errMsg: errMsg,
      setErrMsg: setErrMsg,
      type: "multiFieldNumberSelect",
      customConditionFieldnameList: [...TasksFilterList],
      disableAddButton: disableContactFieldAddBtn
    }
  ];

  useEffect(() => {
    return () => {
      setActiveDropdown("");
      setErrMsg("");
    };
  }, []);

  return (
    <div className="accountFilterByOption">
      <HeaderAction clearAllFilter={clearAllFilter} />
      <div className="filterBody">
        {filterOption.map((item, index) => {
          const { keyValue = "", type = "" } = item || {};
          if (type === "multiSelectApply") {
            return <MultiSelectDropdown key={keyValue} {...item} />;
          } else if (type === "multiDateSelect") {
            return <ContactDateFilter key={keyValue} {...item} />;
          } else if (type === "multiFieldSelect") {
            return <ContactFieldFilter key={keyValue} {...item} />;
          } else if (type === "multiFieldNumberSelect") {
            return <ContactMetricsFilter key={keyValue} {...item} />;
          }
        })}
      </div>
      <FooterAction actionList={actionList} />
    </div>
  );
};

export default Main;
