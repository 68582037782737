import React, { useState, useEffect } from "react";
import PricingStore from "Stores/Pricing";

const ChooseSeats = props => {
  const {
    initTeamSeat = 0,
    seatCount = 0,
    setSeatCount = () => {}
  } = props || {};

  const [initSeats, setInitSeats] = useState(initTeamSeat);
  const [showReduceSeats, setShowReduceSeats] = useState(false);

  const reduceSeats = () => {
    if (seatCount > initSeats) {
      setShowReduceSeats(true);
      let count = seatCount - 1;
      if (count === initSeats) {
        setShowReduceSeats(false);
      }
      PricingStore.setPaymentFormInfo({ seats: count });
      setSeatCount(count);
    } else {
      setShowReduceSeats(false);
    }
  };

  const addSeats = () => {
    setShowReduceSeats(true);
    let count = seatCount + 1;
    PricingStore.setPaymentFormInfo({ seats: count });
    setSeatCount(count);
  };

  const checkSeatDisabled = () => {
    if (seatCount > initSeats) {
      setShowReduceSeats(true);
    }
  };

  useEffect(() => {
    if (PricingStore.paymentFormInfo.initTeamSeat !== null) {
      checkSeatDisabled();
      setInitSeats(PricingStore.paymentFormInfo.initTeamSeat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PricingStore.paymentFormInfo.initTeamSeat]);

  return (
    <div className="chooseSeats">
      <span className="labelName">Choose Seats</span>
      <div className="seatAddMinus">
        <i
          className={`material-icons ${showReduceSeats ? "" : "disableMinus"}`}
          onClick={reduceSeats}
        >
          remove_circle_outline
        </i>
        <span className="seatCount">{seatCount}</span>
        <i className="material-icons" onClick={addSeats}>
          control_point
        </i>
      </div>
    </div>
  );
};

export default ChooseSeats;
export { ChooseSeats };
