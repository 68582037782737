import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { makeApi, URLS } from "Utils/apiURL";
import { MP_EVENT } from "Static/constant";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { scheduleDataStore } from "Stores/ScheduleData";
import { toasterState } from "Components/common/toaster";
import SendingWindow from "./SendingWindow";
import FormActionButton from "../ConnectInbox/Configure/FormActionButton";
import SingleSelectDropdown from "../Calendar/Pages/Events/CreateEvent/Components/SingleSelectDropdown";

const NameField = props => {
  const {
    scheduleNameChange = () => {},
    scheduleNameErr = "",
    scheduleObj = {}
  } = props;
  return (
    <>
      <label className="labelText">
        Name <span className="required">*</span>
      </label>
      <input
        id="scheduleName"
        name="scheduleName"
        className="scheduleName"
        type="text"
        defaultValue={scheduleObj?.name}
        onChange={e => scheduleNameChange(e.currentTarget.value)}
        autoFocus
        required
      />
      <span className="err-msg-text">{scheduleNameErr}</span>
    </>
  );
};

const CreateOrEditSchedules = observer(props => {
  const { hideCbk = () => {}, item = {}, isFromEdit = false } = props || {};
  const {
    name = "",
    timezone = "",
    timezoneName = "",
    id = "",
    dailyTimeRange = {}
  } = item || {};
  const [scheduleObj, setScheduleObj] = useState({
    name,
    timezone: timezone || userDetail?.globalTimeZone?.[0]?.name,
    timezoneName: timezoneName || userDetail?.globalTimeZone?.[0]?.displayName,
    timerange: dailyTimeRange
  });
  const [scheduleNameErr, setScheduleNameErr] = useState(false);
  const [loader, setLoader] = useState(false);

  const scheduleNameChange = value => {
    setScheduleNameErr(value === "" ? "Please enter a valid name." : "");
    setScheduleObj({ ...scheduleObj, name: value });
  };

  const selectedTimezoneCbk = (key, value, uniqueId) => {
    setScheduleObj({
      ...scheduleObj,
      timezone: value,
      timezoneName: uniqueId
    });
  };

  const scheduleSendingWindowCbk = value => {
    setScheduleObj({ ...scheduleObj, timerange: value });
  };

  const handleCancelAction = () => {
    hideCbk();
  };

  const checkForValidValues = () => {
    const validTimeRange = Object.keys(scheduleObj?.timerange)?.length;
    let isValidValues = false;
    if (scheduleObj?.name === "") {
      setScheduleNameErr("Please enter a valid name.");
    } else if (scheduleObj?.name && !validTimeRange) {
      setScheduleNameErr("");
      toasterState.setToastMsg(
        "Please specify atleast 1 sending window",
        "fail"
      );
    } else {
      isValidValues = true;
    }
    return isValidValues;
  };

  const handleSaveChangesAction = async () => {
    const isValidValues = checkForValidValues();
    if (isValidValues) {
      setScheduleNameErr("");
      setLoader(true);
      const config = {
        url: isFromEdit
          ? `${URLS.saveScheduleTimeWindow}/${id}`
          : URLS.saveScheduleTimeWindow,
        method: "POST",
        data: { ...scheduleObj }
      };
      let response = await makeApi(config);
      if (response?.status === 200) {
        setLoader(false);
        toasterState.setToastMsg(
          `Successfully ${
            isFromEdit ? "updated" : "created"
          } schedules with time window`,
          "success"
        );
        Utils.mixpanelTrack(MP_EVENT.ST_SCHEDULE_SAVED, {
          pageType: "Schedule Page"
        });
        hideCbk();
        scheduleDataStore.setScheduleDataLoading(true);
        scheduleDataStore.getScheduleData();
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  };

  const timeZoneObj = {
    name: "timeZone",
    type: "select",
    text: "Timezone",
    id: "timeZone",
    value: scheduleObj?.timezoneName || "",
    change: selectedTimezoneCbk,
    options: userDetail?.globalTimeZone,
    timeZone: true,
    search: true,
    displayName: "displayName",
    tabIndex: -1
  };

  return (
    <div className="schedulesWrapper">
      <div className="schedulesCont">
        <div className="createScheduleWrapper">
          <div className="fieldContainer">
            <div className="fieldWrapper">
              <NameField
                scheduleObj={scheduleObj}
                scheduleNameErr={scheduleNameErr}
                scheduleNameChange={scheduleNameChange}
              />
            </div>
            <div className="fieldWrapper timeZoneWrapper">
              <SingleSelectDropdown {...timeZoneObj} />
            </div>
          </div>
          <div className="fieldWrapper sendingWindowWrapper">
            <label className="labelText">
              Sending window <span className="required">*</span>
            </label>
            <SendingWindow
              scheduleSendingWindowCbk={scheduleSendingWindowCbk}
              dailyTimeRange={scheduleObj.timerange}
            />
          </div>
          <FormActionButton
            cancel={handleCancelAction}
            save={handleSaveChangesAction}
            saveBtnText="Save changes"
            loader={loader}
            className={`saveSchedule ${loader ? "loading" : ""}`}
          />
        </div>
      </div>
    </div>
  );
});

export { CreateOrEditSchedules };
export default CreateOrEditSchedules;
