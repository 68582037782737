import React from "react";
import Utils from "Utils/utils";
import TaskUtils from "Utils/TaskUtils";
import taskStoreData from "Stores/taskData";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";

const NavTaskButton = (props = {}) => {
  const {
    pageType = "",
    taskType = "",
    isPreviousBtnEnabled = false,
    isNextBtnEnabled = false,
    setSelectedTaskFn = () => {}
  } = props || {};

  const allowPrevNext = () => {
    if (
      pageType?.toLowerCase() === "perform_task" &&
      ["manualemail", "email"].includes(taskType?.toLowerCase())
    ) {
      let value = TaskUtils.isFileUploadingComplete();
      if (value) {
        taskStoreData.setTaskAttachments([]);
        fileAttachmentStore.setTaskTotalFileSizeUploaded(0);
        fileAttachmentStore.setPerformTaskFileAttachments([]);
        return true;
      }
      return false;
    }
    return true;
  };

  const handleNavAction = (event = "") => {
    let dataValue = Utils.performActionByClosestDom(event, "span", "data-key");
    if (
      dataValue &&
      ((dataValue === "previous" && isPreviousBtnEnabled) ||
        (dataValue === "next" && isNextBtnEnabled))
    ) {
      if (allowPrevNext()) {
        setSelectedTaskFn(dataValue);
      }
    }
  };

  const navTaskList = [
    {
      name: "prevTaskTop",
      icon: "navigate_before",
      iconClassName: "prevTask",
      value: isPreviousBtnEnabled,
      className: "navToPrev",
      keyValue: "previous"
    },
    {
      name: "nextTaskTop",
      icon: "navigate_next",
      iconClassName: "nextTask",
      value: isNextBtnEnabled,
      className: "navToNext",
      keyValue: "next"
    }
  ];

  return (
    <div className="navTaskBtnCont" onClick={e => handleNavAction(e)}>
      {navTaskList.map(item => {
        const {
          name = "",
          icon = "",
          iconClassName = "",
          value = false,
          className = "",
          keyValue = ""
        } = item || {};
        return (
          <span
            key={name}
            id={name}
            data-key={keyValue}
            className={`${className} ${value ? "" : "disableNavigation"}`}
          >
            <i className={`material-icons ${iconClassName}`}>{icon}</i>
          </span>
        );
      })}
    </div>
  );
};

export default NavTaskButton;
