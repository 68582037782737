import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ReactComponent as TaskIcon } from "Assets/svg/task.svg";
import { ReactComponent as ReplyRateIcon } from "Assets/svg/replyRate.svg";
import reportsModuleState from "./state";
import Utils from "Utils/utils";

const analyticsTableConfig = [
  {
    name: "Emails",
    id: "sentCount",
    icon: <i className="material-icons-outlined">email</i>
  },
  {
    name: "Replies",
    id: "replyCount",
    icon: <ReplyRateIcon />
  },
  {
    name: "Calls Made",
    id: "totalCallsMade",
    icon: <i className="material-icons-outlined">call</i>
  },
  {
    name: "Calls Connected",
    id: "callConnectedCount",
    icon: <i className="material-icons-outlined">call</i>
  },
  {
    name: "Tasks Done",
    id: "taskCompleted",
    icon: <TaskIcon />
  },
  {
    name: "Contacts Added",
    id: "contactsAdded",
    icon: <i className="material-icons-outlined">perm_contact_calendar</i>
  }
];

const ActivityInsightsAnalytics = observer(props => {
  const [analyticsData, setAnalyticsData] = useState({});

  const constructAnalyticsData = () => {
    const data = reportsModuleState?.activityInsightData;
    let tempData = {
      sentCount: 0,
      replyCount: 0,
      totalCallsMade: 0,
      callConnectedCount: 0,
      taskCompleted: 0,
      contactsAdded: 0
    };
    if (data && Object.keys(data)?.length) {
      for (let item in data) {
        const {
          sentCount = 0,
          replyCount = 0,
          contactsAdded = 0,
          totalCallsMade = 0,
          callConnectedCount = 0,
          taskCompleted = 0
        } = data?.[item];
        tempData["sentCount"] =
          tempData["sentCount"] + Math.ceil(sentCount) || 0;
        tempData["replyCount"] =
          tempData["replyCount"] + Math.ceil(replyCount) || 0;
        tempData["totalCallsMade"] =
          tempData["totalCallsMade"] + Math.ceil(totalCallsMade) || 0;
        tempData["callConnectedCount"] =
          tempData["callConnectedCount"] + Math.ceil(callConnectedCount) || 0;
        tempData["taskCompleted"] =
          tempData["taskCompleted"] + Math.ceil(taskCompleted) || 0;
        tempData["contactsAdded"] =
          tempData["contactsAdded"] + Math.ceil(contactsAdded) || 0;
      }
    }
    setAnalyticsData(tempData);
  };

  useEffect(() => {
    constructAnalyticsData();
  }, [reportsModuleState.activityInsightData]);

  return (
    <div className="activityInsightsAnalyticsWrapper">
      {!reportsModuleState?.activityInsightLoader ? (
        analyticsTableConfig?.map(item => {
          return (
            <div className="activityAnalyticsWrap" key={`${item?.name}`}>
              <section className="activityAnalyticsCont">
                <div className="activityCount" style={{ color: item?.color }}>
                  {Utils.formatNumberToStringInUSFormat(
                    analyticsData?.[item?.id]
                  )}
                </div>
                <div className="activityTitle">{item?.name}</div>
              </section>
            </div>
          );
        })
      ) : (
        <>
          {Array.from({ length: 6 }, (value, index) => (
            <div
              className="activityAnalyticsWrap activityAnalyticsLoader"
              key={`${value}-${index}`}
            >
              <section className="activityAnalyticsCont">
                <div className="activityCount linear-background" />
                <div className="activityTitle linear-background" />
              </section>
            </div>
          ))}
        </>
      )}
    </div>
  );
});

export { ActivityInsightsAnalytics };
export default ActivityInsightsAnalytics;
