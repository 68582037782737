/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PricingTableContent from "./PricingTableContent";
import ConstantObj, { REQUEST_DEMO_CALENDAR_LINK } from "Static/constant";
import Utils from "Utils/utils";
import { ReactComponent as RightArrow } from "Assets/svg/rightArrow.svg";
import Button from "Components/common/Button";

const PaymentSuccess = props => {
  const isTrialPurchase =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.isTrialPurchase;

  useEffect(() => {
    if (!isTrialPurchase) {
      setTimeout(() => {
        if (!Utils.isAdmin()) {
          Utils.showMessagePopup();
        } else {
          localStorage.removeItem("proceedToPay");
          props.history.push("/pricing");
        }
      }, 3000);
    }
  }, []);

  return (
    <div className="pricingBody">
      <div className="pricingCard">
        <PricingTableContent />
        <div className="paymentSuccess">
          <div className="iconShadow">
            <i className="material-icons success">check_circle</i>
            <span className="shadow"></span>
          </div>
          {isTrialPurchase ? (
            <TrialSuccess {...props} />
          ) : (
            <>
              <div className="thankYouMsgText">Payment Successful</div>
              <hr />
              <p className="infoText">You will be redirected to pricing page</p>
              <div className="spinnerLoader">
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const TrialSuccess = props => {
  const navigateToDashboard = () => {
    props && props.history && props.history.push("/sequences/all");
  };

  const bookMeeting = () => {
    if (REQUEST_DEMO_CALENDAR_LINK) {
      Utils.windowRedirect(REQUEST_DEMO_CALENDAR_LINK, "_blank", true);
      navigateToDashboard();
    }
  };

  return (
    <div className="trialSuccess">
      <div className="descCont">
        <div className="successMsgText">Success!</div>
        <p className="desc">
          We are thrilled to get you started with your {ConstantObj.trialDays}
          -day free trial
        </p>
      </div>
      <div className="demo">
        <p className="demoText">
          Would you need a walk-through to help you get started with the trial?
        </p>
        <div className="btnCont">
          <Button
            id="notNow"
            name="notNow"
            btnText="Not now"
            icon={false}
            iconName=""
            type="button"
            className={"notNow"}
            loader={false}
            click={e => {
              navigateToDashboard(e);
            }}
          />
          <Button
            id="bookMeeting"
            name="bookMeeting"
            btnText="Yes, book a meeting"
            icon={false}
            iconName=""
            type="button"
            className={"bookMeeting"}
            loader={false}
            click={e => {
              bookMeeting(e);
            }}
          />
          <RightArrow />
        </div>
      </div>
    </div>
  );
};

export default withRouter(PaymentSuccess);
