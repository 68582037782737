import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import reportsStore from "Pages/Dashboard/reports_v2/reportsStore";
import MetricsTable from "Components/common/MetricsTable";
import { NAVIGATION_TAB_LIST } from "Pages/Dashboard/reports_v2/constants";

const ReportsActivity = observer((props = {}) => {
  const { tabType = "", singleTabName = "" } = props || {};
  const [reportsData, setReportsData] = useState([]);
  const [fullReportsData, SetFullReportsData] = useState([]);

  const getType = (navTabType = "user") => {
    const tableType = {
      user: "REPORTS_USER_ACTIVITY",
      team: "REPORTS_TEAM_ACTIVITY",
      sequence: "REPORTS_SEQUENCE"
    };
    return tableType[navTabType];
  };

  const resetReportsState = () => {
    reportsStore.setPageNo(1);
    reportsStore.setPageLimit(10);
    reportsStore.setSortParam("contactsAddedCount");
    reportsStore.setSortType("ASC");
  };

  const getReportsData = async (type = "user") => {
    if (type) {
      reportsStore.setLoading(true);
      await reportsStore.fetchReportsData({}, type);
      reportsStore.setLoading(false);
    }
  };

  const constructHeadersArr = (tempArr = []) => {
    return [...tempArr].map(item => {
      if (
        [
          "callsanswered",
          "callsnotanswered",
          "avgcalldurationinsecs",
          "status",
          "type",
          "owner"
        ].includes(item?.name?.toLowerCase())
      ) {
        return { ...item, isSort: false };
      } else {
        return { ...item, isSort: true };
      }
    });
  };

  const saveHeaderList = (updatedArr = []) => {
    reportsStore.setHeaders(constructHeadersArr(updatedArr));
  };

  const handleTabNavigation = async (tabType = "") => {
    if (tabType?.toLowerCase() !== reportsStore?.tabType?.toLowerCase()) {
      resetReportsState();
      reportsStore.setLoading(true);
      await reportsStore.fetchHeaderList(
        getType(tabType?.toLowerCase()),
        saveHeaderList
      );
      reportsStore.setTabType(tabType);
      getReportsData(tabType);
    }
  };

  const saveHeaders = async (dynamicHeaderArr = []) => {
    reportsStore.setSaveLoader(true);
    await reportsStore.saveDynamicHeaders(
      getType(reportsStore?.tabType?.toLowerCase()),
      saveHeaderList,
      dynamicHeaderArr
    );
    reportsStore.setSaveLoader(false);
    reportsStore.setSaveLoader("");
  };

  const sortUserReports = () => {
    if (reportsStore?.reportsData?.length) {
      const sortedReports = [...reportsStore?.reportsData].sort((a, b) => {
        const valueA = reportsStore?.sortParam
          ? a[reportsStore?.sortParam]
          : "";
        const valueB = reportsStore?.sortParam
          ? b[reportsStore?.sortParam]
          : "";
        if (typeof valueA === "number" && typeof valueB === "number") {
          return reportsStore?.sortType?.toLowerCase() === "asc"
            ? valueA - valueB
            : valueB - valueA;
        } else if (typeof valueA === "string" && typeof valueB === "string") {
          return reportsStore?.sortType?.toLowerCase() === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else {
          return 0;
        }
      });
      SetFullReportsData([...sortedReports]);
      const totalPage = Math.ceil(
        sortedReports?.length / reportsStore?.pageLimit
      );
      reportsStore.setTotalPage(totalPage);
      setReportsData([...sortedReports].slice(0, 10));
    }
  };

  const metrisTableCallBack = (type = "", obj = {}) => {
    switch (type?.toLowerCase()) {
      case "tabnavigation":
        handleTabNavigation(obj?.tabType?.toLowerCase());
        break;
      case "setpage":
        reportsStore.setPageNo(obj?.pageNo);
        break;
      case "setsortparam":
        reportsStore.setSortParam(obj?.sortParam);
        sortUserReports();
        break;
      case "setsorttype":
        reportsStore.setSortType(obj?.sortType);
        sortUserReports();
        break;
      case "setlimit":
        reportsStore.setPageLimit(obj?.pageLimit);
        break;
      case "draganddropsave":
        saveHeaders(obj?.dynamicHeaderArr);
        break;
      case "saveloader":
        reportsStore.setSaveLoader(obj?.saveLoader);
        break;
    }
  };

  const getPaginatedReportsData = () => {
    const startIndex = (reportsStore?.pageNo - 1) * reportsStore?.pageLimit;
    const endIndex = startIndex + reportsStore?.pageLimit;
    setReportsData([...fullReportsData].slice(startIndex, endIndex));
  };

  const handleResetReports = () => {
    reportsStore.setMemberIds([]);
    reportsStore.resetReports();
  };

  const getInit = async () => {
    reportsStore.setLoading(true);
    reportsStore.setTabType(tabType || "user");
    await reportsStore.fetchHeaderList(
      getType(tabType?.toLowerCase() || reportsStore?.tabType?.toLowerCase()),
      saveHeaderList
    );
    getReportsData(reportsStore?.tabType);
  };

  useEffect(() => {
    if (reportsStore?.memberIds !== null && !reportsStore?.loading) {
      getReportsData(reportsStore?.tabType);
    }
  }, [reportsStore?.memberIds]);

  useEffect(() => {
    getPaginatedReportsData();
  }, [reportsStore?.pageNo, reportsStore?.reportsData]);

  useEffect(() => {
    const totalPage = Math.ceil(
      fullReportsData?.length / reportsStore?.pageLimit
    );
    reportsStore.setTotalPage(totalPage);
    getPaginatedReportsData();
  }, [reportsStore?.pageLimit]);

  useEffect(() => {
    setReportsData([...reportsStore?.reportsData].slice(0, 10));
    SetFullReportsData(reportsStore?.reportsData);
  }, [reportsStore?.reportsData]);

  useEffect(() => {
    getInit();
    return () => {
      handleResetReports();
    };
  }, []);

  return (
    <div
      className={`reportsActivity ${
        reportsStore?.newVersionReportsFlag ? "switchVersionDisplay" : ""
      } `}
    >
      <MetricsTable
        tabList={singleTabName ? [] : [...NAVIGATION_TAB_LIST]}
        singleTabName={singleTabName}
        isLoading={reportsStore?.loading}
        pageNo={reportsStore?.pageNo}
        totalPages={reportsStore?.totalPage}
        tabType={reportsStore?.tabType}
        limit={reportsStore?.pageLimit}
        dateFilter={reportsStore?.dateFilter}
        searchText={reportsStore?.searchText}
        tableData={reportsData}
        entireReportsData={fullReportsData}
        setTableData={setReportsData}
        headers={reportsStore?.headers}
        sortType={reportsStore?.sortType}
        sortParam={reportsStore?.sortParam}
        pageLimit={reportsStore?.pageLimit}
        saveLoader={reportsStore?.saveLoader}
        cbk={metrisTableCallBack}
        totalCount={reportsStore?.reportsData?.length}
      />
    </div>
  );
});

export default ReportsActivity;
export { ReportsActivity };
