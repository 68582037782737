import React from "react";
import TextArea from "Components/common/TextArea";
import Utils from "Utils/utils";

const ContactNotes = props => {
  const { inputVal = "", changeInputVal = () => {} } = props;

  const changeNotes = e => {
    if (e) {
      Utils.preventDefaultFn(e);
      changeInputVal("contactNotes", e.target.value);
    }
  };

  return (
    <div className="info contactNotes">
      <h5 className="sectionTitle">Contact Notes</h5>
      <div className="sectionInputTagsCont">
        <div className="rowBreaker">
          <TextArea
            id="contactNotes"
            placeholder="Notes..."
            value={inputVal?.contactNotes || ""}
            isRequired={false}
            onChangeEvent={changeNotes}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactNotes;
export { ContactNotes };
