import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import InboxUtils from "Utils/InboxUtils";
import { useHistory } from "react-router-dom";
import SecondaryCheckbox from "Components/common/SecondaryCheckbox";
import AddInboxButton from "./AddInboxButton";
import { MP_EVENT } from "Static/constant";
import Utils from "Utils/utils";
import { sequenceStoreData } from "Stores/sequenceData";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import userDetail from "Stores/userDetail";
import { editSequencePopupState } from "Components/common/EditSequencePopup";
import PaywallUtils from "Utils/PaywallUtils";
import { SequenceUtils } from "Utils/SequenceUtils";

const MultiInboxSelection = observer(props => {
  const history = useHistory();
  const {
    title = "Connected Inbox",
    reAssignContact = false,
    setReAssignContact = () => {},
    sequenceId = "",
    isSharedSequence = false,
    isInboxMappedToSequence = false,
    getSelectedSeqData = () => {},
    sequenceMemberId = "",
    hasSubLabel = true,
    showReassignInbox = true,
    className = "",
    cbk = () => {},
    showLimit = false
  } = props || {};

  const { planName = "", inFreePlan = false } =
    userDetail?.userFeatureFlag || {};

  const { selectedConnectedInbox = [], setSelectedConnectedInbox = () => {} } =
    sequenceStoreData;

  const disabledClassName =
    selectedConnectedInbox?.length > 1 ? "" : "disabled";

  const [showDropdown, setShowDropdown] = useState(false);
  const [inboxes, setInboxes] = useState([]);
  const [dropdownLoader, setDropdownLoader] = useState(true);
  const [showMore, setShowMore] = useState(true);
  const [itemsToShow, setItemsToShow] = useState(0);
  const [inboxLoaderId, setInboxLoaderId] = useState(false);

  const redirectToConnectInbox = () => {
    Utils.mixpanelTrack(MP_EVENT.SQ_MULTI_INBOX_LEARN_MORE_CLICKED, {
      pageType: "Sequence Settings"
    });
    cbk();
    history.push("/settings/account");
  };

  const HandleConnectInboxAction = () => {
    if (InboxUtils.connectInboxAllowed()) {
      redirectToConnectInbox();
    } else {
      PaywallUtils.upgradeUtilsFunction(
        "multipleInbox",
        false,
        planName,
        inFreePlan
      );
    }
  };

  const removeInboxFromSequence = async (id = "", cbk = () => {}) => {
    const confirmationCbk = async () => {
      await sequenceStoreData.removeInboxFromSequence(
        {
          inboxId: id,
          sequenceId
        },
        cbk
      );
    };

    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to remove this inbox from the sequence?
        All the scheduled emails from this inbox will be suspended.`,
      actionBtnText: "Proceed",
      callback: () => confirmationCbk(),
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const handleChange = async (value = "", id = "", label = "") => {
    const obj = { id, email: label };
    const cbk = (value = "") => {
      if (value) {
        confirmationPopupState.setShowPopup(false);
        setSelectedConnectedInbox([...selectedConnectedInbox, obj]);
        getSelectedSeqData();
      }
      setInboxLoaderId("");
    };
    if (value) {
      setInboxLoaderId(id);
      confirmationPopupState.setPopupValues({
        loading: true,
        loadingMsg: "Please wait..."
      });
      confirmationPopupState.setShowPopup(true);
      await sequenceStoreData.addInboxToSequence(
        {
          inboxId: id,
          sequenceId,
          email: label
        },
        cbk
      );
    }
  };

  const removeEmail = async (email = "", id = "") => {
    const cbk = () => {
      let temp = [...selectedConnectedInbox];
      temp = temp.filter(item => item?.email !== email);
      setSelectedConnectedInbox([...temp]);
      confirmationPopupState.setShowPopup(false);
    };
    if (editSequencePopupState?.totalContacts > 0) {
      removeInboxFromSequence(id, cbk);
    } else {
      await sequenceStoreData.removeInboxFromSequence(
        {
          inboxId: id,
          sequenceId
        },
        cbk
      );
    }
  };

  const handleReassignContactChange = (value = "") => {
    setReAssignContact(value);
    sequenceStoreData.setTempThrottlingData({
      ...sequenceStoreData.tempThrottlingData,
      reassignInbox: value
    });
  };

  const constructDropDownData = () => {
    setInboxes(userDetail?.connectedInboxes);
    setDropdownLoader(false);
  };

  const isReassignInboxDisabled = () => {
    return isSharedSequence && !SequenceUtils.isSequenceOwner(sequenceMemberId);
  };

  const inputObj = {
    label:
      "Automatically reassign contacts to available inboxes if the assigned inbox is disconnected or revoked.",
    id: "autoReassignContact",
    cbk: handleReassignContactChange,
    checked: reAssignContact
  };

  const clickShowMore = () => {
    setShowMore(!showMore);
    let itemsToShow = selectedConnectedInbox?.length;
    if (showLimit) {
      itemsToShow = showMore ? selectedConnectedInbox?.length : 2;
    }
    setItemsToShow(itemsToShow);
  };

  useEffect(() => {
    if (showLimit) {
      const itemsToShow = !showMore ? selectedConnectedInbox?.length : 2;
      setItemsToShow(itemsToShow);
    }
  }, [selectedConnectedInbox]);

  useEffect(() => {
    constructDropDownData();
    return () => {
      setInboxes([]);
    };
  }, [isSharedSequence, userDetail?.connectedInboxes]);

  return (
    <div className={`sequenceMultiInboxSelection ${className}`}>
      <h6>{title}</h6>
      {hasSubLabel && (
        <p className="subTextForSettings">
          Contacts added to the sequence will be distributed equally among the
          connected inboxes below.{" "}
          <button className="hereText" onClick={redirectToConnectInbox}>
            Learn more.
          </button>
        </p>
      )}
      {!showDropdown && (
        <div
          className="addInboxBtn"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <i className="material-icons">add</i>
          <span className="btnText">Add Inbox</span>
        </div>
      )}
      {showDropdown && (
        <>
          <div className="dropdownInboxList">
            <div className="selectLabel">
              <span className="selectText">Select</span>
              <i className="material-icons arrowDownIcon">
                keyboard_arrow_down
              </i>
            </div>
            <div className="dropdownOptions">
              <div className="inboxList">
                {dropdownLoader ? (
                  <DropDownLoader />
                ) : (
                  inboxes?.map(item => {
                    const { id = "", email = "", status = "" } = item || {};
                    return (
                      status === "ACTIVE" && (
                        <AddInboxButton
                          inboxLoaderId={inboxLoaderId}
                          key={id}
                          id={id}
                          label={email}
                          cbk={handleChange}
                          checked={selectedConnectedInbox?.some(
                            ele => email === ele?.email
                          )}
                          disabled={
                            selectedConnectedInbox?.length === 1 &&
                            selectedConnectedInbox?.[0]?.email === email
                          }
                        />
                      )
                    );
                  })
                )}
              </div>
              <div
                className="connectInboxBtn"
                onClick={HandleConnectInboxAction}
              >
                <i className="material-icons">add</i>
                <span className="btnText"> Connect new inbox</span>
              </div>
            </div>
          </div>
          <div
            className="multiInboxDropdownOverlay"
            onClick={() => setShowDropdown(false)}
          />
        </>
      )}
      {selectedConnectedInbox?.length > 0 ? (
        <div
          className={`selectedInboxWrapper ${
            showMore ? "showMore" : "showLess"
          }`}
        >
          <ul className="selectedInboxEmailList">
            {isInboxMappedToSequence ? (
              <>
                {selectedConnectedInbox?.map((item = {}, index = 0) => {
                  return (
                    (!showLimit || index < itemsToShow) && (
                      <li key={`selected${item.id}-${item?.email}`}>
                        <span className="email">{item?.email}</span>
                        <i
                          className={`material-icons removeIcon ${disabledClassName}`}
                          onClick={
                            disabledClassName
                              ? () => {}
                              : () => removeEmail(item?.email, item.id)
                          }
                        >
                          close
                        </i>
                      </li>
                    )
                  );
                })}
              </>
            ) : (
              <SelectedInboxLoader />
            )}
          </ul>
          {showLimit && selectedConnectedInbox?.length > 2 && (
            <div className="viewMoreBtn" onClick={() => clickShowMore()}>
              <span>{showMore ? "View More" : "View less"}</span>
              <i className="material-icons arrowDownIcon">
                {showMore ? "keyboard_arrow_down" : "keyboard_arrow_up"}
              </i>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      {showReassignInbox && (
        <div
          className={`TriggerProspect ${
            isReassignInboxDisabled() ? "disabled" : ""
          }`}
        >
          <SecondaryCheckbox
            key={inputObj?.id}
            {...inputObj}
            disabled={isReassignInboxDisabled()}
          />
        </div>
      )}
    </div>
  );
});

const DropDownLoader = () => {
  return (
    <div className="inboxLoader">
      {Array.from({ length: 3 }, (value, index) => (
        <div key={`${index}-${value}`} className="linear-background" />
      ))}
    </div>
  );
};

const SelectedInboxLoader = () => {
  return Array.from({ length: 6 }, (value, index) => (
    <li key={`${index}-${value}`} className="linear-background" />
  ));
};

export default MultiInboxSelection;
export { MultiInboxSelection };
