import React from "react";
import { useState, useEffect } from "react";
import { makeApi, URLS } from "Utils/apiURL";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import connectedInboxStore from "./store";
import { toasterState } from "Components/common/toaster";
import EmailWarmingSettings from "./EmailWarmingSettings";
import DeliverablityAnalytics from "./DeliverabilityAnalytics";
import EmailSentPerDayChart from "./EmailSentPerDay";

const EmailWarming = observer((props = {}) => {
  const {
    configureData = {},
    dateObj,
    setActiveDateFilter = () => {},
    activeDateFilter,
    setShowConfigure = () => {}
  } = props || {};
  const { id = "" } = configureData || {};
  const { inboxCount = 0, spamCount = 0 } =
    connectedInboxStore?.deliverabilityStats || {};
  const [warmingData, setWarmingData] = useState({});

  const calculateDeliverablityStats = (emailMetricsByDate = []) => {
    let totalInbox = 0;
    let totalSpam = 0;
    let totalSent = 0;
    let totalreceived = 0;
    !!emailMetricsByDate?.length &&
      emailMetricsByDate.forEach(metric => {
        totalInbox += metric.emailLandedInInbox;
        totalSpam += metric.emailLandedInSpam;
        totalSent += metric.emailSentCount;
        totalreceived += metric.emailReceivedCount;
      });
    let totalCount = totalSent;
    return {
      inboxCount: totalInbox,
      spamCount: totalSpam,
      totalCount,
      inboxPercentage: Utils.getPercentage(totalInbox, totalSent) || 0,
      spamPercentage: Utils.getPercentage(totalSpam, totalSent) || 0,
      receivedCount: totalreceived
    };
  };

  const getEmailWarmingData = async (customLoader = true) => {
    customLoader && connectedInboxStore.setWarmupLoader(true);
    const config = {
      url: URLS?.getWarmingData,
      method: "POST",
      data: { inboxId: id, filter: dateObj || {} }
    };
    let response = await makeApi(config);
    const { data = {}, status = "" } = response || {};
    const { inboxes = {} } = data || {};
    if (+status === 200) {
      setWarmingData(inboxes || {});
      const { emailWarming = {}, emailMetricsByDate = [] } = inboxes || {};
      const { warmupSettings = {} } = emailWarming || {};
      connectedInboxStore.setDeliverabilityStats(
        calculateDeliverablityStats(emailMetricsByDate) || []
      );
      connectedInboxStore.setWarmupSettings(warmupSettings);
    } else {
      calculateDeliverablityStats([]);
      toasterState.setToastMsg(
        "Something went wrong,Please try again later",
        "failure"
      );
    }
    connectedInboxStore.setInboxScreenType("");
    customLoader && connectedInboxStore.setWarmupLoader(false);
  };

  useEffect(() => {
    setActiveDateFilter("month");
    return () => {
      connectedInboxStore.setEmailWarmingToogle("");
    };
  }, []);

  useEffect(() => {
    if (connectedInboxStore?.inboxScreentype?.toLowerCase() === "two") {
      getEmailWarmingData(false);
    }
  }, [connectedInboxStore?.inboxScreentype]);

  useEffect(() => {
    dateObj && getEmailWarmingData();
  }, [dateObj]);

  useEffect(() => {
    if (connectedInboxStore?.emailwarmingToogle !== "") {
      getEmailWarmingData(false);
    }
  }, [connectedInboxStore?.emailwarmingToogle]);

  return (
    <div className="emailWarmingSection">
      <DeliverablityAnalytics
        {...props}
        inboxId={id}
        warmingData={warmingData}
      />
      {(inboxCount > 0 || spamCount > 0) && (
        <EmailSentPerDayChart {...props} warmingData={warmingData} />
      )}
      <EmailWarmingSettings
        configureData={configureData}
        inboxId={id}
        setShowConfigure={setShowConfigure}
        warmingData={warmingData}
      />
    </div>
  );
});

export default EmailWarming;
export { EmailWarming };
