import React from "react";
import Utils from "Utils/utils";
import PreviewEditorTemplate from "Components/common/PreviewEditorTemplate";
import { observer } from "mobx-react";

const PreviewSequenceTemplate = observer(props => {
  const {
    contentBody = "",
    attachments = [],
    subject = "",
    currentStage = 1,
    replyAsThread = false,
    hasTitle = false,
    dynamicInboxId = false,
    fromAddress = ""
  } = props || {};

  const previewDom = dynamicInboxId
    ? Utils.getContentWithSignature(
        contentBody,
        dynamicInboxId,
        fromAddress?.email
      )
    : Utils.getContentWithSignature(contentBody);

  return (
    <div className="previewContentWrap">
      <PreviewEditorTemplate
        subject={replyAsThread ? "Re: [Previous email subject line]" : subject}
        contentBody={Utils.contentReadyOnly(previewDom?.outerHTML)}
        attachments={attachments || []}
        currentStage={currentStage}
        titleText="Preview Sequence"
        hasTitle={hasTitle}
      />
    </div>
  );
});

export default PreviewSequenceTemplate;
export { PreviewSequenceTemplate };
