import React from "react";
import EditActionIcon from "Components/common/EditIcon";
import DeleteIcon from "Components/common/DeleteIcon";
import Utils from "Utils/utils";

const CustomUrlDataTable = (props = {}) => {
  const { data = "", edit = () => {}, remove = () => {} } = props || {};
  const { domain = "", cname = "" } = data || {};

  return (
    <>
      <div className="customUrlRow">
        <span className="domainName" data-cname={cname}>
          {Utils.removeHttpFromString(domain)}
        </span>
        <div className="wrapActionIcon">
          <EditActionIcon
            action={edit}
            id="editCustomUrl"
            itemValue={""}
            title="Edit Custom Url"
            spaceRight={5}
          />
          <DeleteIcon
            action={remove}
            id="deleteCustomUrl"
            itemValue={""}
            title="Delete Custom Url"
          />
        </div>
      </div>
      <div className="customUrlInfoText">
        <i className="material-icons-outlined">info</i>
        <span className="text">
          This URL will be used for unsubscribe, open and click tracking
        </span>
      </div>
    </>
  );
};

export default CustomUrlDataTable;
export { CustomUrlDataTable };
