import React from "react";
import FolderLoader from "Components/common/FolderLoader";
const CreateFolder = props => {
  const {
    saveNewFolder,
    newFolderNameAction,
    newFolderNameCancelAction,
    newFolderNameFocus,
    newFolderName,
    id,
    folderLoader
  } = props;

  return (
    <>
      {folderLoader ? (
        <FolderLoader loadingNumber={1} />
      ) : (
        <form
          className="nameInputcont animated customzoomIn"
          autoComplete="off"
          onSubmit={e => {
            saveNewFolder(e, id);
          }}
        >
          <div className="nameInputInnercont">
            <div className="inputWrapper">
              <input
                type="text"
                autoComplete="off"
                id="newFolderName"
                autoFocus
                defaultValue={newFolderName}
                onFocus={e => newFolderNameFocus(e)}
                onChange={e => newFolderNameAction(e)}
                className="nameInputBox"
              />
            </div>
            <div className="cancelWrapper">
              <div
                className="cancelNameInput"
                onClick={e => newFolderNameCancelAction(e, id)}
              >
                Cancel
              </div>
            </div>
          </div>
        </form>
      )}
      <div className="dropdownMask" onClick={e => saveNewFolder(e, id)} />
    </>
  );
};

export default CreateFolder;
export { CreateFolder };
