import React from "react";
import Utils from "Utils/utils";
import NumberInputBox from "Components/common/NumberInputBox";

const AccountEmailLimitConfiguration = props => {
  const { inputList = [] } = props;

  return (
    <div className="emailDailyLimit">
      <h3 className="title">Email Daily Limit</h3>
      {inputList?.length
        ? inputList?.map(item => <NumberInputBox {...item} key={item.id} />)
        : ""}
    </div>
  );
};

export default AccountEmailLimitConfiguration;
export { AccountEmailLimitConfiguration };
