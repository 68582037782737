/* eslint-disable array-callback-return */
import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import sequenceContactStoreData, {
  defaultFilterPayload
} from "Pages/Dashboard/sequences/SequenceContact/SequenceContactFilter/sequenceContactStore";

let cancelTabFilter = null;
let cancelContactFilter = null;
let cancelContactMarkAsComplete = null;
let cancelDeleteSequenceContact = null;
class SequenceContactStore {
  // Declare value
  @observable contactsData = "";
  @observable contactsTabCount = {};

  // Set/Change Value
  @action
  setEmptyContacts() {
    this.contactsData = {};
  }

  @action
  setContactTabCount() {
    this.contactsTabCount = {};
  }

  @action
  getSeqContacts = async (data = {}) => {
    try {
      const campaignId = data?.campaignId || "";
      let targetUrl = URLS.getSeqStatisticsContacts;
      targetUrl && (targetUrl = targetUrl.replace("<<seqId>>", campaignId));
      delete data?.campaignId;
      const config = {
        url: targetUrl,
        method: "POST",
        data
      };
      if (cancelContactFilter !== null) {
        cancelContactFilter("cancel");
        cancelContactFilter = null;
      }
      const advCancelCallback = (value) => {
        cancelContactFilter = value;
      };
      let res = await makeApi(config, advCancelCallback);
      
      if (res?.data) {
        this.contactsData = res.data;
        if (sequenceContactStoreData?.isOverviewToContactsPage) {
          sequenceContactStoreData.setIsOverviewToContactsPage(false);
          sequenceContactStoreData.setFilterPayload({
            ...defaultFilterPayload
          });
          sequenceContactStoreData.setTopFilter({});
          sequenceContactStoreData.setOverallPayload({});
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  getSeqAnalyticsTab = async (id = "", payload = {}, searchEmail = null) => {
    try {
      if (id) {
        this.contactsTabCount = {};
        searchEmail && (payload = { ...payload, searchTerm: searchEmail });
        const config = {
          url: `${URLS.getSeqStatistics}${id}`,
          method: "POST",
          data: payload
        };
        if (cancelTabFilter !== null) {
          cancelTabFilter("cancel");
          cancelTabFilter = null;
        }
        const advCancelCallback = (value) => {
          cancelTabFilter = value;
        };
        let res = await makeApi(config, advCancelCallback);
        if (res?.data) {
          this.contactsTabCount = res?.data || {};
        }
      }
    } catch (err) {
      console.error(err, "Getting Analytics Failed");
    }
  };

  @action
  sequenceMarkAsComplete = async (data = {}, cbk) => {
    try {
      const config = {
        url: URLS.sequenceMarkAsComplete,
        method: "POST",
        data
      };
      if (cancelContactMarkAsComplete !== null) {
        cancelContactMarkAsComplete("cancel");
        cancelContactMarkAsComplete = null;
      }
      const advCancelCallback = (value) => {
        cancelContactMarkAsComplete = value;
      };
      let res = await makeApi(config, advCancelCallback);
      cbk(res);
    } catch (err) {
      console.error(err, "Sequence contacts mark as complete failed");
    }
  };

  @action
  sequenceBulkContactDelete = async (data = {}, cbk) => {
    try {
      const config = {
        url: URLS.performContactsBulkOperation,
        method: "POST",
        data
      };
      if (cancelDeleteSequenceContact !== null) {
        cancelDeleteSequenceContact("cancel");
        cancelDeleteSequenceContact = null;
      }
      const advCancelCallback = (value) => {
        cancelDeleteSequenceContact = value;
      };
      let res = await makeApi(config, advCancelCallback);
      cbk(res);
    } catch (err) {
      console.error(err, "Sequence contacts mark as complete failed");
    }
  };
}

const sequenceContactData = new SequenceContactStore();

export { sequenceContactData, SequenceContactStore };
export default SequenceContactStore;
