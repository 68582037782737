import React from "react";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import { toasterState } from "Components/common/toaster";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import { Utils } from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const AddVariantButton = props => {
  const {
    totalVariant = 0,
    greatestVariantUniqueId = 1,
    steps = {},
    stageType = "",
    stepNo = "",
    daysLater = "",
    seqId = "",
    name = "",
    abTestingEnabled = false
  } = props || {};

  const { planName = "", inFreePlan = false } =
    userDetail?.userFeatureFlag || {};

  const addNewVariant = e => {
    Utils.preventDefaultFn(e);
    if (abTestingEnabled) {
      Utils.mixpanelTrack(MP_EVENT.AB_TESTING_ADD_VARIANT_CLICKED, {
        trigger: "Add Variant"
      });
      if (totalVariant < 5) {
        addStagesPopupState.setPopupValues({
          pageSource: "Sequences",
          variantStep: greatestVariantUniqueId + 1,
          stageType,
          steps,
          currentStep: stepNo,
          isVariantChange: true,
          daysLater,
          seqId,
          name
        });
        addStagesPopupState.setShowStepEmailEditorPopup(true);
      } else {
        toasterState.setToastMsg(
          "Limit reached: you can only add up to 5 variants per step",
          "info"
        );
      }
    }
  };

  return totalVariant < 5 ? (
    <div
      className="addVariant"
      onClick={abTestingEnabled ? addNewVariant : () => {}}
    >
      <i className="material-icons-outlined">add_circle_outline</i>
      {!abTestingEnabled && (
        <UpgradeIconTooltip
          plan={PaywallUtils.getPlanName(planName, inFreePlan)}
          type={"abTesting"}
          enableTooltip={true}
          alignTooltip="leftTop"
        />
      )}
      <div className="tpText">Add Variant</div>
    </div>
  ) : (
    <></>
  );
};

export default AddVariantButton;
export { AddVariantButton };
