import React from "react";
import salesForceIcon from "Assets/png/salesforce.png";
import zohoIcon from "Assets/png/zohoCrm.png";
import hubspotIcon from "Assets/png/hubspotIcon.png";
import pipedriveIcon from "Assets/png/pipedriveIcon.png";
import slackIcon from "Assets/png/Slack.png";
import zapierIcon from "Assets/png/zapierIcon.png";
import { ReactComponent as Email } from "Assets/svg/sequences.svg";
import { ReactComponent as Phone } from "Assets/svg/call.svg";
import { ReactComponent as Sms } from "Assets/svg/sms.svg";
import { ReactComponent as LinkedIn } from "Assets/svg/linkedin.svg";
import { ReactComponent as CustomTask } from "Assets/svg/customTask.svg";
import ManualEmail from "Assets/png/manualEmail.png";

export const CONTACTS_EXPORT_HEADERS = {
  fname: "First Name",
  lName: "Last Name",
  email: "Email Address",
  title: "Job Title",
  phone: "Phone Number",
  mobilePhone: "Mobile Number",
  otherPhone: "Other Number",
  company: "Company",
  industry: "Industry",
  notes: "Contact Notes",
  street: "Street",
  city: "City",
  state: "State",
  country: "Country",
  zipCode: "Zip Code",
  website: "Website",
  linkedIn: "LinkedIn URL",
  facebook: "Facebook",
  twitter: "Twitter",
  contactTags: "Contact Tags",
  customField1: "Custom Field 1",
  customField2: "Custom Field 2",
  customField3: "Custom Field 3",
  customField4: "Custom Field 4",
  customField5: "Custom Field 5",
  customField6: "Custom Field 6",
  customField7: "Custom Field 7",
  customField8: "Custom Field 8",
  customField9: "Custom Field 9",
  customField10: "Custom Field 10",
  customField11: "Custom Field 11",
  customField12: "Custom Field 12",
  customField13: "Custom Field 13",
  customField14: "Custom Field 14",
  customField15: "Custom Field 15",
  customField16: "Custom Field 16",
  customField17: "Custom Field 17",
  customField18: "Custom Field 18",
  customField19: "Custom Field 19",
  customField20: "Custom Field 20",
  customField21: "Custom Field 21",
  customField22: "Custom Field 22",
  customField23: "Custom Field 23",
  customField24: "Custom Field 24",
  customField25: "Custom Field 25",
  customField26: "Custom Field 26",
  customField27: "Custom Field 27",
  customField28: "Custom Field 28",
  customField29: "Custom Field 29",
  customField30: "Custom Field 30",
  companyIndustry: "Company Industry",
  companySector: "Company Sector",
  companyrevenue: "Company Revenue",
  companyHeadCount: "Company Head Count",
  companyHqNumber: "Company HQ Number",
  companyStreet: "Company Street",
  companycity: "Company City",
  companyState: "Company State",
  companyCountry: "Company Country",
  companyzip: "Company Zip",
  companyLinkedin: "Company LinkedIn",
  companyTwitter: "Company Twitter",
  companyFacebook: "Company Facebook",
  owner: "Owner Name",
  addedDate: "Added Date",
  lastContactedDate: "Last Contacted Date",
  stage: "Stage",
  sequences: "Sequences",
  emailsSent: "Emails Sent",
  emailOpensCount: "Email Opens Count",
  emailClicksCount: "Email Clicks Count",
  EmailRepliesCount: "Email Replies Count"
};

export const WELCOME_FEATURES_LIST = [
  {
    title: "Sequences",
    key: "sequences",
    message: "2X your prospecting by automating follow ups"
  },
  {
    title: "Templates",
    key: "templates",
    message:
      "Save your best emails as templates and import them to send emails in 1-click"
  },
  {
    title: "Tasks",
    key: "tasks",
    message: "Set reminders for managing your LinkedIn follow-ups"
  },
  {
    title: "Reports",
    key: "reports",
    message:
      "Get performance insights of each member of your team by time duration"
  }
];

export const CRM_INTEGRATION_TYPES = [
  {
    title: "Salesforce",
    uniqueKey: "salesforce",
    key: "salesforce",
    message:
      "Upgrade to Pro plan to export and imports contacts from your Salesforce CRM",
    icon: salesForceIcon,
    youtubeLink: "u6pQmU9qtbs",
    toolTipMsg:
      "Please upgrade to Pro plan to enable export and import contacts from your salesforce CRM."
  },
  {
    title: "Zoho",
    uniqueKey: "zohoCRM",
    key: "zoho",
    message: "Upgrade to Pro plan to import contacts from your Zoho CRM",
    icon: zohoIcon,
    youtubeLink: "PNENxzEN4ys",
    toolTipMsg:
      "Please upgrade to one of our premium plans to add contacts to your sequence directly from your Zoho CRM"
  },
  {
    title: "HubSpot",
    uniqueKey: "hubspotCRM",
    key: "hubspot",
    message:
      "Please upgrade to Pro plan to import contacts from your Hubspot CRM",
    icon: hubspotIcon,
    youtubeLink: "dmFZ0-V0GTQ",
    toolTipMsg:
      "Please upgrade to one of our premium plans to add contacts to your sequence directly from your Hubspot CRM"
  },
  {
    title: "Pipedrive",
    uniqueKey: "pipedriveCRM",
    key: "pipedrive",
    message:
      "Please upgrade to Pro plan to import contacts from your Pipedrive CRM",
    icon: pipedriveIcon,
    youtubeLink: "O-jNwhue_vw",
    toolTipMsg:
      "Please upgrade to one of our premium plans to add contacts to your sequence directly from your Pipedrive CRM"
  }
];

export const CRM_INTEGRATION_TYPES_UNLIMITED = [
  {
    title: "Salesforce",
    uniqueKey: "salesforce",
    key: "salesforce",
    message:
      "Upgrade to Unlimited plan to export and imports contacts from your Salesforce CRM",
    icon: salesForceIcon,
    youtubeLink: "u6pQmU9qtbs",
    toolTipMsg:
      "Please upgrade to Unlimited plan to enable export and import contacts from your salesforce CRM."
  },
  {
    title: "Zoho",
    uniqueKey: "zohoCRM",
    key: "zoho",
    message: "Upgrade to Unlimited plan to import contacts from your Zoho CRM",
    icon: zohoIcon,
    youtubeLink: "PNENxzEN4ys",
    toolTipMsg:
      "Please upgrade to one of our premium plans to add contacts to your sequence directly from your Zoho CRM"
  },
  {
    title: "HubSpot",
    uniqueKey: "hubspotCRM",
    key: "hubspot",
    message:
      "Please upgrade to Unlimited plan to import contacts from your Hubspot CRM",
    icon: hubspotIcon,
    youtubeLink: "dmFZ0-V0GTQ",
    toolTipMsg:
      "Please upgrade to one of our premium plans to add contacts to your sequence directly from your Hubspot CRM"
  },
  {
    title: "Pipedrive",
    uniqueKey: "pipedriveCRM",
    key: "pipedrive",
    message:
      "Please upgrade to Unlimited plan to import contacts from your Pipedrive CRM",
    icon: pipedriveIcon,
    youtubeLink: "O-jNwhue_vw",
    toolTipMsg:
      "Please upgrade to one of our premium plans to add contacts to your sequence directly from your Pipedrive CRM"
  }
];

export const ADD_CSV_CONTACT_HEADERS = [
  "firstname",
  "lastname",
  "jobtitle",
  "companyname",
  "email"
];

export const CSV_MAPPING_FIELDS = [
  { key: "firstname", label: "First Name" },
  { key: "companyname", label: "Company Name" },
  { key: "email", label: "Email Address" },
  { key: "phonenumber", label: "Phone Number" },
  { key: "jobtitle", label: "Job Title" },
  { key: "lastname", label: "Last Name" },
  { key: "mobilenumber", label: "Mobile Number" },
  { key: "city", label: "City" },
  { key: "state", label: "State" },
  { key: "country", label: "Country" },
  { key: "industry", label: "Industry" },
  { key: "website", label: "Website" },
  { key: "othernumber", label: "Other Number" },
  { key: "street", label: "Street" },
  { key: "zipcode", label: "Zip Code" },
  { key: "linkedinid", label: "LinkedIn URL" },
  { key: "facebook", label: "Facebook" },
  { key: "twitter", label: "Twitter" },
  { key: "contactnotes", label: "Contact notes" }
];

export const OTHER_INTEGRATION_TYPES = [
  {
    title: "Slack",
    key: "slack",
    message:
      "Upgrade to Pro plan to enable notifications to your slack workspace.",
    icon: slackIcon,
    toolTipMsg:
      "Please upgrade to Pro plan to enable notifications to your slack workspace."
  },
  {
    title: "Zapier",
    key: "zapier",
    message: "Upgrade to pro plan to integrate with 3,000+ apps via Zapier.",
    icon: zapierIcon,
    toolTipMsg: "Upgrade to pro plan to integrate with 3,000+ apps via Zapier"
  }
];

export const OTHER_INTEGRATION_TYPES_UNLIMITED = [
  {
    title: "Slack",
    key: "slack",
    message:
      "Upgrade to Unlimited plan to enable notifications to your slack workspace.",
    icon: slackIcon,
    toolTipMsg:
      "Please upgrade to Unlimited plan to enable notifications to your slack workspace."
  },
  {
    title: "Zapier",
    key: "zapier",
    message:
      "Upgrade to Unlimited plan to integrate with 3,000+ apps via Zapier.",
    icon: zapierIcon,
    toolTipMsg:
      "Upgrade to Unlimited plan to integrate with 3,000+ apps via Zapier"
  }
];

export const SENDER_TOKENS = [
  {
    key: "firstName",
    value: "{{sender.firstName}}",
    text: "First Name"
  },
  {
    key: "lastName",
    value: "{{sender.lastName}}",
    text: "Last Name"
  },
  {
    key: "email",
    value: "{{sender.email}}",
    text: "Email"
  },
  {
    key: "phone",
    value: "{{sender.phoneNumber}}",
    text: "Phone"
  },
  {
    key: "title",
    value: "{{sender.jobTitle}}",
    text: "Title"
  },
  {
    key: "calendarUrl",
    value: "{{sender.calendarUrl}}",
    text: "Calendar Url"
  },
  {
    key: "company",
    value: "{{sender.companyName}}",
    text: "Company"
  }
];

export const MATCH_SENDER_TOKEN = {
  "sender.firstname": "Sender Firstname",
  "sender.lastname": "Sender Lastname",
  "sender.calendarurl": "Sender Calendar URL",
  "sender.companyname": "Sender CompanyName",
  "sender.jobtitle": "Sender JobTitle",
  "sender.phonenumber": "Sender Phone",
  "sender.email": "Sender Email"
};

export const SEQUENCE_STAGE_ICON = {
  email: <Email className="autoEmail" />,
  manualEmail: <img src={ManualEmail} alt="Manual Email" />,
  phone: <Phone className="phone" />,
  sms: <Sms className="sms" />,
  linkedin: <LinkedIn className="linkedin" />,
  customTask: <CustomTask className="customTask" />
};

export const PAYWALL_CONTENT = {
  reportsPage: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: `Oops! You cannot access reports in the free plan.`,
    text: "Please upgrade to one of our premium plans to access report page",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Reports"
  },
  newSequence: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You have reached the limit for sequences on your free plan.",
    text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear sequences",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequence",
    trigger: "Sequence limit reached"
  },
  newSequenceWithTask: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: `Oops! You can create sequences with only "Automatic Email" steps in the free plan.`,
    text: "Please upgrade to one of our premium plans to add other channels in sequences",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequence",
    trigger: "Sequence limit reached"
  },
  addContactsToSequence: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You cannot add contacts to sequences with tasks in the free plan.",
    text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear sequences",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequence",
    trigger: "Add Contacts to Sequence with task"
  },
  sequenceAddTaskToSequence: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle:
      'Oops! You can only add "Automatic Email" as a step in sequences in the free plan.',
    text: "Please upgrade to one of our premium plans to add other channels in sequences",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequence",
    trigger: "Add task step"
  },
  AIWriting: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! You cannot access AI writing assistance in current plan",
    text: "Please upgrade to one of our premium plans to add other channels in sequences",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequence",
    trigger: "Add task step"
  },

  sequencesCustomfieldToken: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! Custom variables are not available in the free plan.",
    text: "Please upgrade to one of our premium plans to create custom variables.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequence",
    trigger: "Insert custom variable"
  },
  sequenceExport: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You cannot export contacts in the free plan.",
    text: "Please upgrade to one of our premium plans to export contacts from Salesgear",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequence",
    trigger: "Export contact"
  },
  sequenceContactsAddToSalesforce: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Export to Salesforce is available only on the Pro plan.",
    text: "Please upgrade to Pro plan to export contacts to your Salesforce account.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Sequence",
    trigger: "Add to Salesforce"
  },
  pauseResumeSequence: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: `Oops! You cannot resume this sequence, as only "Automatic Email" is allowed in sequences in the free plan.`,
    text: "Please upgrade to one of our premium plans to resume sequences with multiple channels.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequence",
    trigger: "Resume sequence with task"
  },
  emailDailyLimit: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You cannot update email sending limit in the free plan.",
    text: "Please upgrade to one of our premium plans to send up-to 1000 emails/day",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Email Settings",
    trigger: "Update email settings"
  },
  emailDailyLimitForTrial: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You cannot update email sending limit during the trial period.",
    text: "Please upgrade to one of our premium plans to send up-to 1000 emails/day",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Email Settings",
    trigger: "Update email settings"
  },
  emailIntervalInSeconds: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You cannot update interval between emails in the free plan.",
    text: " Please upgrade to one of our premium plans to configure email interval.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Email Settings",
    trigger: "Update email settings"
  },
  emailIntervalInSecondsForTrial: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You cannot update interval between emails during the trial period.",
    text: " Please upgrade to one of our premium plans to configure email interval.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Email Settings",
    trigger: "Update email settings"
  },
  autoBccSettings: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You cannot add Auto BCC email address  in the free plan.",
    text: "Please upgrade to one of our premium plans to add Auto BCC to your CRM and ATS system",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Auto BCC settings",
    trigger: "Update Auto BCC"
  },
  autoBccSettingsBasic: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! You cannot add Auto BCC email address  in the basic plan.",
    text: "Please upgrade to one of our premium plans to add Auto BCC to your CRM and ATS system",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Auto BCC settings",
    trigger: "Update Auto BCC"
  },
  autoBccSettingsPro: {
    title: "Upgrade to Unlimited Plan",
    plan: "unlimitedPlan",
    subTitle: "Oops! You cannot add Auto BCC email address  in the pro plan.",
    text: "Please upgrade to one of our premium plans to add Auto BCC to your CRM and ATS system",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Auto BCC settings",
    trigger: "Update Auto BCC"
  },
  dialerSettingsForUnlimitedDisabled: {
    title: "Contact Support",
    plan: "unlimitedPlan",
    subTitle: "Oops! Dialer is disabled for your account",
    text: `Contact us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> or via the chat box below to activate the dialer.`,
    btnText: "Chat with us",
    badgeSize: "md",
    color: "#00cb1b",
    pageType: "Dialer settings",
    trigger: "Contact Support to Upgrade UnlimitedPlan Dialer",
    backgroundColor: "#ffffff",
    boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.26)",
    displayAsHtml: true,
    type: "chatWithUs",
    showUpgradePopup: true
  },
  dialerSettingsForProDisabled: {
    title: "Contact Support",
    plan: "proPlan",
    subTitle: "Oops! Dialer is disabled for your account",
    text: `Contact us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> or via the chat box below to activate the dialer.`,
    btnText: "Chat with us",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Dialer settings",
    trigger: "Contact Support to Upgrade ProPlan Dialer",
    backgroundColor: "#ffffff",
    boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.26)",
    displayAsHtml: true,
    type: "chatWithUs",
    showUpgradePopup: true
  },
  dialerSettings: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Dialer is not available on your current plan.",
    text: "Please upgrade to Pro to use dialer.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Dialer settings",
    trigger: "Upgrade Dialer",
    backgroundColor: "#ffffff",
    boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.26)"
  },
  customFields: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Custom variables are not available in the free plan.",
    text: "Please upgrade to one of our premium plans to create custom variables.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Custom fields setting",
    trigger: "Custom fields visited"
  },
  customFieldsBasic: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Custom variables are not available in the basic plan.",
    text: "Please upgrade to one of our premium plans to create custom variables.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Custom fields setting",
    trigger: "Custom fields visited"
  },
  contactStages: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! You cannot create new contact stages in the free plan",
    text: "Please upgrade to one of our premium plans to access contact stages.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Contact stages settings",
    trigger: "Contact stages visited"
  },
  contactStagesBasic: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! You cannot create new contact stages in the basic plan",
    text: "Please upgrade to one of our premium plans to access contact stages.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Contact stages settings",
    trigger: "Contact stages visited"
  },
  stageRules: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You cannot set contact stage rules in the free plan",
    text: "Please upgrade to one of our premium plans to access stage rules.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "stage rules settings"
  },
  unsubscribeSettings: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You cannot update unsubscription text in the free plan.",
    text: "Please upgrade to one of our premium plans to update unsubscription text.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Unsubscription settings",
    trigger: "Update unsubscription url"
  },
  slackSettings: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Slack integration is not available on your current plan.",
    text: "Upgrade to Pro plan to enable Slack integration.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Slack Settings",
    trigger: "Slack connect"
  },
  slackSettingsUnlimited: {
    title: "Upgrade to Unlimited Plan",
    plan: "unlimitedPlan",
    subTitle: "Oops! Slack integration is not available on your current plan.",
    text: "Upgrade to Unlimited plan to enable Slack integration.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Slack Settings",
    trigger: "Slack connect"
  },
  zohoSettings: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Zoho integration is not available on your current plan.",
    text: "Upgrade to Pro plan to enable Zoho integration.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Zoho Settings",
    trigger: "Zoho connect"
  },
  zohoSettingsUnlimited: {
    title: "Upgrade to Unlimited Plan",
    plan: "unlimitedPlan",
    subTitle: "Oops! Zoho integration is not available on your current plan.",
    text: "Upgrade to Pro plan to enable Zoho integration.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Zoho Settings",
    trigger: "Zoho connect"
  },
  salesforceSettings: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle:
      "Oops! Salesforce integration is not available on your current plan",
    text: "Upgrade to Pro plan to enable Salesforce integration.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Salesforce Settings",
    trigger: "Salesforce connect"
  },
  salesforceSettingsUnlimited: {
    title: "Upgrade to Unlimited Plan",
    plan: "unlimitedPlan",
    subTitle:
      "Oops! Salesforce integration is not available on your current plan",
    text: "Upgrade to Unlimited plan to enable Salesforce integration.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Salesforce Settings",
    trigger: "Salesforce connect"
  },
  zohoCRM: {
    title: "Zoho",
    plan: "basicPlan",
    subTitle: "Oops! Zoho integration is not available on your current plan",
    text: "Please upgrade to one of  the paid plan to add contacts to your sequence directly from your Zoho CRM",
    btnText: "Upgrade",
    color: "#3b50fa",
    alignTooltip: "topRight"
  },
  hubspotCRM: {
    title: "Hubspot",
    plan: "basicPlan",
    subTitle: "Oops! Hubspot integration is not available on your current plan",
    text: "Please upgrade to one of  the paid plan to add contacts to your sequence directly from your Hubspot CRM",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    alignTooltip: "topLeft"
  },
  hubspotSettings: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle:
      "Oops! Hubspot integration is not available on your current plan.",
    text: "Upgrade to Pro plan to enable Hubspot integration.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Hubspot Settings"
  },
  hubspotSettingsUnlimited: {
    title: "Upgrade to Unlimited Plan",
    plan: "unlimitedPlan",
    subTitle:
      "Oops! Hubspot integration is not available on your current plan.",
    text: "Upgrade to Unlimited plan to enable Hubspot integration.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Hubspot Settings"
  },
  pipedriveCRM: {
    title: "Pipedrive",
    plan: "basicPlan",
    subTitle:
      "Oops! Pipedrive integration is not available on your current plan",
    text: "Please upgrade to one of  the paid plan to add contacts to your sequence directly from your Pipedrive CRM",
    btnText: "Upgrade",
    color: "#3b50fa",
    alignTooltip: "topLeft"
  },
  reportsCustomDate: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You cannot view insight on a specific date range in the free plan.",
    text: "Please upgrade to one of our premium plans to view insights by any date range",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Reports",
    trigger: "Report custom date filter"
  },
  callsCustomDate: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You cannot view insight on a specific date range in the free plan.",
    text: "Please upgrade to one of our premium plans to view overview and analytics by any date range",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Calls",
    trigger: "Calls custom date filter"
  },
  tasksPage: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! You cannot create tasks in the free plan.",
    text: "Please upgrade to one of our premium plans to create and manage tasks",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Task",
    trigger: "Task section viewed"
  },
  tasksPageBasic: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! You cannot create tasks in the basic plan.",
    text: "Please upgrade to one of our premium plans to create and manage tasks",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Task",
    trigger: "Task section viewed"
  },
  templatesLimitReached: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You cannot create more than 3 templates in the free plan.",
    text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear templates.",
    btnText: "Upgrade",
    color: "#3b50fa",
    pageType: "Templates",
    trigger: "Template limit reached"
  },
  templatesLimitReachedBasic: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle:
      "Oops! You cannot create more than 10 templates in the basic plan.",
    text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear templates.",
    btnText: "Upgrade",
    color: "#3b50fa",
    pageType: "Templates",
    trigger: "Template limit reached"
  },

  customURL: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You cannot add custom URL in the free plan.",
    text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear custom URL.",
    btnText: "Upgrade",
    color: "#3b50fa",
    pageType: "Templates",
    trigger: "Template limit reached"
  },
  templatesCustomfieldToken: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! Custom contact variables are unavailable in the free plan.",
    text: "Please upgrade to one of our premium plans to configure contact custom variables",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Templates",
    trigger: "Insert custom variable"
  },
  callContact: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! You cannot make phone calls in your current plan.",
    text: "Please upgrade to Pro plan to make phone calls to your contacts.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#ff6057",
    pageType: "Contacts",
    trigger: "Make a Call"
  },
  contactsExport: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! Contact export is not available on your free plan.",
    text: "Please upgrade to Basic plan to export contacts",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Contacts",
    trigger: "Export contact"
  },
  contactsAddToSalesforce: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Export to Salesforce is available only on the Pro plan.",
    text: "Please upgrade to Pro plan to export contacts to your Salesforce account.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#ff6057",
    pageType: "Contacts",
    trigger: "Add to Salesforce"
  },
  contactTask: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You cannot create tasks in the free plan.",
    text: "Please upgrade to one of  our premium plan to create and manage tasks",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Contacts",
    trigger: "Create task"
  },
  contactsAddToSequence: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You cannot add contacts to sequences with tasks in the free plan.",
    text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear sequences",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Contacts",
    trigger: "Add Contacts to Sequence with task"
  },
  abTesting: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! AB testing is not available in your current plan",
    text: "Please upgrade to one of our premium plans to enable AB testing",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequences",
    trigger: "Add variants"
  },
  triggers: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Triggers are not available in your current plan.",
    text: "Please upgrade to Pro plan to access triggers.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Setting Triggers",
    trigger: "Add Trigger"
  },
  cloneTrigger: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Triggers are not available in your current plan",
    text: "Please upgrade to Pro plan to access triggers.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Setting Triggers",
    trigger: "Clone Trigger"
  },
  activateTrigger: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Triggers are not available in your current plan",
    text: "Please upgrade to Pro plan to access triggers.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Setting Triggers",
    trigger: "Activate Trigger"
  },
  multipleInbox: {
    title: "Inboxes limit reached",
    plan: "customPlan",
    type: "chatWithUs",
    text: `You have reached the maximum limit for inboxes. Contact us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> or through the chat window below to add more inboxes.`,
    btnText: "Chat with us",
    badgeSize: "lg",
    color: "#ff6057",
    pageType: "Setting Connect Inbox",
    trigger: "Add Inbox"
  },
  subjectLineGenerator: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Writing assistant is not available in your current plan",
    text: "Please upgrade to Pro plan to get access to Salesgear writing assistant.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "sequences",
    trigger: "Subject Line Generator"
  },
  newSequenceCard: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You have reached the limit for sequences on your free plan",
    text: "Please upgrade to one of our premium plans to get unrestricted access to Salesgear sequences",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Sequence",
    trigger: "Sequence card limit reached"
  },
  contactActivityFilter: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You cannot filter by specific activity type in the free plan.",
    text: "Please upgrade to one of our premium plans to get unrestricted access to contact activities",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Contact Timeline Activity",
    trigger: "Load more activity"
  },
  contactActivityPagination: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! You cannot view the contact activities any further in freemium ",
    text: "Please upgrade to a paid plan to see all the activities",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#3b50fa",
    pageType: "Contact Timeline Activity",
    trigger: "Contact activity filter"
  },
  data_ContactSearchPage: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You cannot purchase contacts in the free plan.",
    text: "Please upgrade to our basic plan to get access to 200 million+ contacts & 10 million+ companies.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Discover Page",
    trigger: "Purchase contacts popup shown - Free plan"
  },
  data_PageLimitReachedPaidUser: {
    title: "Contact Sales",
    plan: "customPlan",
    type: "chatWithUs",
    text: `Maximum page limit reached. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to remove this limit.`,
    btnText: "Chat with us",
    badgeSize: "lg",
    color: "#ff6057",
    pageType: "Discover Page",
    trigger: "Maximum pagination limit reached popup shown"
  },
  data_PageLimitReachedTrialUser: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You've reached the maximum page limit.",
    text: `Please upgrade to one of our premium plans to increase your page limit.`,
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Discover Page",
    trigger: "Maximum pagination limit reached popup shown"
  },
  data_PurchasePhoneFreePlan: {
    title: "Upgrade to Unlimited Plan",
    plan: "unlimitedPlan",
    subTitle: "Oops! You cannot purchase phone numbers in the free plan.",
    text: "Please upgrade to our unlimited plan to get access to direct dials & mobile numbers.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#00cb1b",
    pageType: "Discover Page",
    trigger: "Purchase contacts popup shown - Phone number - Free plan"
  },
  data_PurchasePhoneBasicPlan: {
    title: "Upgrade to Unlimited Plan",
    plan: "unlimitedPlan",
    subTitle: "Oops! You cannot purchase phone numbers in the basic plan.",
    text: "Please upgrade to our unlimited plan to get access to direct dials & mobile numbers.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#00cb1b",
    pageType: "Discover Page",
    trigger:
      "Purchase phone number popup shown - Basic plan - Upgrade to unlimited plan"
  },
  data_PurchasePhoneProPlan: {
    title: "Upgrade to Unlimited Plan",
    plan: "unlimitedPlan",
    subTitle: "Oops! You cannot purchase phone numbers in the pro plan.",
    text: "Please upgrade to our unlimited plan to get access to direct dials & mobile numbers.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#00cb1b",
    pageType: "Discover Page",
    trigger:
      "Purchase phone number popup shown - Pro plan - Upgrade to unlimited plan"
  },
  data_F_EmailDailyLimitReached: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You've reached your daily email credit limit.",
    text: "Please upgrade to one of our premium plans to increase your daily email credit limit.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Discover Page",
    trigger: "Daily email credits limit reached popup shown - Free plan"
  },
  data_F_EmailMonthlyLimitReached: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! You've reached your monthly email credit limit.",
    text: "Please upgrade to one of our premium plans to increase your monthly email credit limit.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Discover Page",
    trigger: "Monthly email credits limit reached popup shown - Free plan"
  },
  data_F_PhoneNumberLimitReached: {
    title: "Upgrade to Unlimited Plan",
    plan: "unlimitedPlan",
    subTitle: "Oops! You've reached your phone number credit limit.",
    text: "Please upgrade to our unlimited plan to increase your phone number credit limit.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#00cb1b",
    pageType: "Discover Page",
    trigger: "Phone number credits limit reached popup shown - Free plan"
  },
  data_EmailDailyLimitReached: {
    title: "Contact Sales",
    plan: "unlimitedPlan",
    text: `You've reached your daily email credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    color: "#00cb1b",
    pageType: "Discover Page",
    trigger: "Daily email credits limit reached popup shown"
  },
  data_EmailMonthlyLimitReached: {
    title: "Contact Sales",
    plan: "unlimitedPlan",
    text: `You've reached your monthly email credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    color: "#00cb1b",
    pageType: "Discover Page",
    trigger: "Monthly email credits limit reached popup shown"
  },
  data_PhoneMonthlyLimitReached: {
    title: "Contact Sales",
    plan: "unlimitedPlan",
    text: `You've reached your monthly phone number credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    color: "#00cb1b",
    pageType: "Discover Page",
    trigger: "Monthly phone number credits limit reached popup shown"
  },
  data_PhoneDailyLimitReached: {
    title: "Contact Sales",
    plan: "unlimitedPlan",
    text: `You've reached your daily phone number credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    color: "#00cb1b",
    pageType: "Discover Page",
    trigger: "Daily phone number credits limit reached popup shown"
  },
  data_PhoneAnnualLimitReached: {
    title: "Contact Sales",
    plan: "unlimitedPlan",
    text: `You've reached your annual phone number credit limit. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to add more credits.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    color: "#00cb1b",
    pageType: "Discover Page",
    trigger: "Annual phone number credits limit reached popup shown"
  },
  data_BulkCustomSelection: {
    title: "Contact Sales",
    text: `Custom selection is disabled. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to enable custom selection.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    pageType: "Discover Page",
    trigger: "Maximum contact custom selection limit reached popup shown"
  },
  data_BulkTopMaxSelection: {
    title: "Contact Sales",
    text: `Maximum contact selection limit reach. Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window to increase this limit.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    pageType: "Discover Page",
    trigger: "Maximum contact top selection limit reached popup shown"
  },
  emailValidationUpgrade: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle:
      "Oops! Email Validation feature is not available in your current plan.",
    text: "Please upgrade to one of our premium plans to enable email validation in Salesgear.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Discover Page",
    trigger: "Email Validation Popup Shown"
  },
  data_AddCreditsToDataSearch: {
    title: "Contact Sales",
    text: `Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window below to add more credits.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    pageType: "Settings - credits page",
    trigger: "Purchase contacts - Add more credits"
  },
  usersAndTeams: {
    title: "Upgrade to Basic Plan",
    plan: "basicPlan",
    subTitle: "Oops! Users and Teams is not available in your current plan.",
    text: "Please upgrade to one of our premium plans to enable Users and Teams in Salesgear.",
    btnText: "Upgrade",
    badgeSize: "lg",
    color: "#3b50fa",
    pageType: "Settings"
  },
  data_AddCreditsToDialer: {
    title: "Contact Sales",
    text: `Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window below to add more credits.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    pageType: "Settings - dialer page",
    trigger: "Dialer - Add more credits"
  },
  data_AddCreditsToEV: {
    title: "Contact Sales",
    text: `Contact us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or through the chat window below to add more credits.`,
    btnText: "Chat with us",
    type: "chatWithUs",
    badgeSize: "lg",
    pageType: "Settings - credits page",
    trigger: "Email validation popup shown - Add more credits"
  },
  calendarUpgrade: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! Calendar is not available in your current plan.",
    text: "Please upgrade to Pro plan to access calendar.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Calendar"
  },
  calendarLimitReached: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    text: "Please upgrade to Pro plan to create more events.",
    subTitle: "Oops! You cannot create more than one event in the trial.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Calendar Events",
    trigger: "Event Creation"
  },
  calendarLimitReached: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    text: "Please upgrade to Pro plan to create more events.",
    subTitle: "Oops! You cannot create more than one event in the trial.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Calendar Events",
    trigger: "Event Creation"
  },
  calendarBrandLogoTrialUpgrade: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! You cannot remove Salesgear branding in the trial plan.",
    text: "Please upgrade to Pro plan to remove branding.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Calendar Configuration",
    trigger: "Configuration Toggle"
  },
  calendarBrandLogoUpgrade: {
    title: "Upgrade to Pro Plan",
    plan: "proPlan",
    subTitle: "Oops! You cannot remove Salesgear branding in the free plan.",
    text: "Please upgrade to Pro plan to remove branding.",
    btnText: "Upgrade",
    badgeSize: "md",
    color: "#ff6057",
    pageType: "Calendar Configuration",
    trigger: "Configuration Toggle"
  }
};

export const SEQ_BACKGROUND_MSG = {
  pause:
    "All the contacts in this sequence are being paused, it may take few to several minutes based on the number of contacts in the sequence.",
  paused:
    "All the contacts in this sequence are being paused, it may take few to several minutes based on the number of contacts in the sequence.",
  resume:
    "All the contacts in this sequence are being resumed, it may take few to several minutes based on the number of contacts in the sequence.",
  active:
    "All the contacts in this sequence are being resumed, it may take few to several minutes based on the number of contacts in the sequence."
};

export const RECHARGE_ERR_MSG = {
  401: "Access to purchase credits is denied. Please contact your administrator.",
  202: "Please upgrade to Pro plan",
  427: "Insufficient phone call credits. Please add more credits to purchase phone numbers.",
  429: `Failed to process your payment. Please contact us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> for more information`,
  201: `Failed to process your payment. Please contact us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> for more information`
};

export const TASK_EVENT_TYPE = {
  linkedin: "LinkedIn (Manual)",
  customtask: "Custom",
  phone: "Phone",
  manualemail: "Email (Manual)",
  email: "Email (Manual)"
};

export const CONTACT_PREVIEW_EVENT_TYPE = [
  "SENT",
  "OPENED",
  "CLICKED",
  "REPLIED",
  "CALL_LOG",
  "TASK_COMPLETED",
  "RECEIVED_EMAIL",
  "LI_MESSAGE_SENT"
];

export const CONTACT_FIELD_VALUE = {
  contact: {
    email: "Email Address",
    phone: "Phone Number",
    mobilePhone: "Mobile Number",
    otherPhone: "Other Number",
    companyPhone: "Company Number",
    owner: "Owner",
    stageDisplayName: "Stage",
    task: "Tasks",
    taggedSequences: "Sequences",
    insights: "Email Insights",
    contactTags: "Tags",
    contactNotes: "Contact Notes"
  },
  account: {
    name: "Name",
    // owner: "Owner",
    industry: "Industry",
    revenue: "Revenue",
    employeeCount: "No Of Employees",
    phone: "Phone Number",
    otherPhone: "Other Number",
    stage: "Accounts Stage"
    // accountEmailInsights: "Email Insights",
    // accountOverallInsights: "Tasks"
  },
  // companyDetails: {
  //   company: "Company Name",
  //   industry: "Industry",
  //   revenue: "Revenue",
  //   employeeCount: "Employee Count",
  //   sector: "Sector",
  //   address: "Location",
  //   linkedIn: "LinkedIn",
  //   facebook: "Facebook",
  //   twitter: "Twitter"
  // },
  location: {
    street: "Street",
    city: "City",
    state: "State",
    country: "Country",
    zipCode: "Zip Code"
  },
  social: {
    website: "Website",
    linkedinId: "LinkedIn URL",
    facebook: "Facebook",
    twitter: "Twitter"
  },
  customFields: {}
};

export const SEQ_CONTACT_TAB_FILTER_OBJECT = {
  meeting_booked: "meetingBookedCount",
  replied: "totalReplyCount",
  bounced: "bounceCount"
};

export const PAST_BULK_TASKS_TYPE = {
  SKIP_TASK: "skipped",
  COMPLETE_TASK: "completed",
  DELETE_TASK: "deleted",
  EDIT_TASK_DUE_DATE: "edited"
};

export const PRESENT_BULK_TASKS_TYPE = {
  SKIP_TASK: "skip",
  COMPLETE_TASK: "complete",
  DELETE_TASK: "delete",
  EDIT_TASK_DUE_DATE: "edit"
};

export const SINGLE_TASK_TOAST_MESSAGE = {
  success: {
    SKIP_TASK: "Task skipped successfully",
    COMPLETE_TASK: "Task has been completed successfully",
    DELETE_TASK: "Task deleted successfully",
    EDIT_TASK_DUE_DATE: "Task has been updated successfully"
  },
  fail: {
    SKIP_TASK: "Failed to skip task",
    COMPLETE_TASK: "Failed to complete task",
    DELETE_TASK: "Failed to delete task",
    EDIT_TASK_DUE_DATE: "Failed to update task"
  }
};
