import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import AutoCompleteDropdown from "Components/common/AutoCompleteDropdown";
import { hubspotStore } from "../hubspot/HubspotStore";

const listViewHeadingObj = {
  Pipedrive: "Filter",
  Hubspot: "Contact List",
  Zoho: "List View"
};

const listViewSubHeadingObj = {
  Pipedrive: "Choose a Pipedrive filter to import",
  Hubspot: "Choose a Hubspot contact list to import",
  Zoho: "Choose a Zoho list view to import"
};

const ListView = observer(props => {
  const {
    list = {},
    config = {},
    setConfig = () => {},
    crmName = "zoho"
  } = props;

  const [scrollEnd, setScrollEnd] = useState(false);

  const customCbk = item => {
    const { name = "", id = "" } = item;
    setConfig({
      ...config,
      sourceId: id,
      sourceName: name,
      listChanged: id !== config?.sourceId
    });
  };

  useEffect(() => {
    if (scrollEnd && hubspotStore?.hasMoreFlag) {
      hubspotStore.setHubspotContactsList([
        ...hubspotStore.hubspotContactsList,
        { id: "loading", name: "Loading..." }
      ]);
      hubspotStore.getContactsListView(() => setScrollEnd(false));
    } else {
      setScrollEnd(false);
    }
  }, [scrollEnd]);

  return (
    <div className="crmListView sectionMarginBottom">
      <div className="flexColumn">
        <span className="headingText">{listViewHeadingObj?.[crmName]}</span>
        <span className="headingSubText">
          {listViewSubHeadingObj?.[crmName]}
        </span>
      </div>
      <AutoCompleteDropdown
        optionList={list}
        defaultValue={config?.sourceName || ""}
        cbk={customCbk}
        index={0}
        loader={!list}
        setScrollEnd={setScrollEnd}
        loaderEnabled={hubspotStore?.hasMoreFlag}
      />
    </div>
  );
});

export { ListView };
export default ListView;
