/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { observer } from "mobx-react";
import { ReactComponent as SequenceIcon } from "Assets/svg/sequence.svg";
import SequenceTemplateData from "Stores/sequence/SequenceTemplateData";
import CursorClick from "Utils/LottieFiles/CursorClick.json";
import { seqTemplatePopupState } from "./SeqTemplatePopupState";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: CursorClick,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const PredefinedTemplates = () => {
  const [activeTemplateNo, setActiveTemplateNo] = useState(0);
  const [showCursorIcon, setCursorIcon] = useState(false);
  const [predefinedTemplate, setPredefinedTemplate] = useState(
    SequenceTemplateData.predefinedTemplateList
  );

  const getSeqTemplateAndLoad = (item, value) => {
    if (showCursorIcon) {
      setCursorIcon(false);
      localStorage.setItem("clickCursorIconActive", true);
    }
    seqTemplatePopupState.setProgressBarState(2);
    setActiveTemplateNo(value);
    SequenceTemplateData.setSelectedPredefinedTemplate(item);
    SequenceTemplateData.setSeqTemmplateClicked(true);
  };

  const getClickCursorIcon = () => {
    if (!localStorage.getItem("clickCursorIconActive")) {
      setCursorIcon(true);
      setActiveTemplateNo(2);
    } else {
      setActiveTemplateNo(0);
    }
  };

  const activeTemplate = index => {
    return (showCursorIcon && index === 2) || index === activeTemplateNo
      ? "activeTemplate"
      : "";
  };

  useEffect(() => {
    SequenceTemplateData.getSeqPredefinedTemplate();
  }, []);

  useEffect(() => {
    getClickCursorIcon();
  }, []);

  useEffect(() => {
    setPredefinedTemplate(SequenceTemplateData.predefinedTemplateList);
  }, [SequenceTemplateData.predefinedTemplateList]);

  return (
    <div
      className={`predefinedTemplatesSection ${
        SequenceTemplateData.seqTemplateClicked ? "" : "bor-rad-rt"
      }`}
    >
      <div className="TemplateList">
        {(predefinedTemplate?.length &&
          predefinedTemplate.map((item, index) => {
            return (
              <div
                className={`templateItem ${activeTemplate(index + 1)}`}
                id={item.id}
                key={`usePredefinedTemplate${index + 1}`}
                onClick={() => getSeqTemplateAndLoad(item, index + 1)}
              >
                <div className="iconSection">
                  <SequenceIcon />
                </div>
                <div className="templateDetailInfo">
                  <h6 className="title">{item.name}</h6>
                  <p className="descText">{item.description}</p>
                </div>
                {showCursorIcon && index + 1 === activeTemplateNo && (
                  <div
                    className="clickIcon"
                    onClick={() => getSeqTemplateAndLoad(item, index + 1)}
                  >
                    <Lottie
                      options={defaultOptions}
                      width={120}
                      height={63}
                      style={{ margin: "0 auto" }}
                      speed={1}
                    />
                  </div>
                )}
              </div>
            );
          })) ||
          ""}
        {(!predefinedTemplate || predefinedTemplate.length < 1) &&
          Array.from({ length: 3 }, (value, index) => (
            <div
              className="templateItem templateCardLoader"
              key={`usePredefinedTemplateLoader${index + 1}`}
            >
              <div className="iconSection linear-background"></div>
              <div className="templateDetailInfo">
                <h6 className="title linear-background">&nbsp;</h6>
                <div className="descTextLoader linear-background"></div>
                <div className="descTextLoader linear-background"></div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default observer(PredefinedTemplates);
export { PredefinedTemplates };
