import React, { useState } from "react";
import Utils from "Utils/utils";
import DropdownButton from "Pages/Dashboard/contacts/ContactFilter/DropdownButton";
import ContactAutoCompleteDropdown from "Components/PreviewPopup/ContactAutoCompleteDropdown";

const AddMultiInputEmail = (props = {}) => {
  const {
    label = "",
    keyValue = "",
    data = [],
    activeFilter = "",
    selected = [],
    autoComplete = false,
    setActiveFilter = () => {},
    setAction = () => {}
  } = props || {};

  let toggleDropdown = activeFilter === keyValue;

  const [searchInput, setSearchInput] = useState("");

  const btnLabel = {
    label,
    count: selected?.length,
    keyValue,
    toggleDropdown,
    callback: setActiveFilter
  };

  const handleReset = () => {
    data?.length > 0 && setAction(keyValue, []);
  };

  const handleSelectedContactAction = (contact = {}) => {
    if (contact?.email && !data.includes(contact?.email)) {
      setAction(keyValue, [...data, contact?.email]);
      setSearchInput("");
    }
  };

  return (
    <div className="addMultiInputEmail">
      <DropdownButton {...btnLabel} />
      {(toggleDropdown && (
        <div className="emailInputBoxWrapper">
          <span className="horizontalBorderLine" />
          <AddInputEmailBox
            {...props}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          {autoComplete && (
            <ContactAutoCompleteDropdown
              inputText={searchInput}
              selectedCallbk={handleSelectedContactAction}
            />
          )}
          {(selected?.length > 0 && (
            <div className="selectedItemsCont">
              <SelectedItemList {...props} />
              <i
                className="material-icons-outlined clearAll"
                onClick={handleReset}
              >
                clear_all
              </i>
            </div>
          )) ||
            ""}
        </div>
      )) ||
        ""}
    </div>
  );
};

const AddInputEmailBox = ({
  data = [],
  placeholder = "",
  keyValue = "",
  searchInput = "",
  setSearchInput = () => {},
  setAction = () => {}
}) => {
  const [emailInvalid, setEmailInvalid] = useState(false);

  const handleInputChange = (text = "") => {
    setSearchInput(text?.trim() || "");
    emailInvalid && setEmailInvalid(false);
  };

  const addInputEmail = () => {
    let emailText = searchInput?.trim() || "";
    if (emailText?.length > 0) {
      if (Utils.emailValidate(emailText) && !data.includes(emailText)) {
        setAction(keyValue, [...data, emailText]);
        setSearchInput("");
      } else {
        setEmailInvalid(true);
      }
    }
  };

  const handleAddEmailAction = (event = "") => {
    if (event && Utils.isSubmitKeyAction(event)) {
      addInputEmail();
    }
  };

  const inputObj = {
    type: "text",
    className: "emailInput",
    onChange: e => handleInputChange(e.currentTarget.value),
    value: searchInput,
    placeholder: placeholder,
    onKeyUp: e => handleAddEmailAction(e)
  };

  return (
    <div
      className={`inputEmailBox ${searchInput?.length > 0 ? "emailText" : ""} ${
        emailInvalid ? "invalidEmail" : ""
      }`}
    >
      <input autoFocus {...inputObj} />
      {searchInput?.length > 0 && (
        <i
          className="material-icons clearIpIcon"
          onClick={e => {
            Utils.preventDefaultFn(e);
            setSearchInput("");
          }}
        >
          close
        </i>
      )}
      <i
        className={`material-icons-outlined addIpIcon ${
          searchInput?.length > 0 ? "" : "disabled"
        }`}
        onClick={addInputEmail}
      >
        add_circle_outline
      </i>
    </div>
  );
};

const SelectedItemList = ({
  count = 3,
  keyValue = "",
  selected = [],
  data = [],
  setAction = () => {}
}) => {
  const [expandCollapse, setExpandCollapse] = useState(false);

  const removeSelectedItem = (event = "") => {
    if (event) {
      let emailId = Utils.performActionByClosestDom(event, "i", "data-key");
      let emailIndex = Utils.performActionByClosestDom(
        event,
        "i",
        "data-index"
      );
      if (emailId && +emailIndex >= 0) {
        let tempArr = [...data];
        tempArr.splice(emailIndex, 1);
        setAction(keyValue, tempArr);
      }
    }
  };

  return (
    <ul className="selectedList" onClick={e => removeSelectedItem(e)}>
      {selected.map((email, index) => {
        if (index < count || expandCollapse) {
          return (
            <li className="chip" key={email}>
              <span className="name" title={email}>
                {email}
              </span>
              <i
                className="material-icons removeIcon"
                data-key={email}
                data-index={index}
              >
                close
              </i>
            </li>
          );
        }
      })}
      {selected?.length > count && (
        <div
          className="expandCollapse"
          onClick={() => setExpandCollapse(!expandCollapse)}
        >
          <span className="count">
            {expandCollapse ? "less" : `+${selected?.length - count}`}
          </span>
          <i
            className={`material-icons arrowIcon ${
              expandCollapse ? "rotate" : ""
            }`}
          >
            keyboard_arrow_down
          </i>
        </div>
      )}
    </ul>
  );
};

export default AddMultiInputEmail;
