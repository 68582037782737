/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Button from "Components/common/Button";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import UserProfileLoader from "./UserProfileLoader";
import ChangePassowrdComponent from "Components/common/ChangePassword";
import InputTextBox from "Components/common/InputTextBox";
import CheckBoxComponent from "Components/common/CheckBox";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import ApiKeyWithCopyButton from "Components/common/ApiKeyWithCopyButton";
import { settingsStoreData } from "Stores/settingsData";
import { captchaStoreValue } from "Stores/captcha";
import userDetail from "Stores/userDetail";
import SingleSelectDropdown from "./Calendar/Pages/Events/CreateEvent/Components/SingleSelectDropdown";

export const UserProfile = observer(() => {
  const [inputVal, setInputVal] = useState(settingsStoreData.userProfileData);
  const [productAnnouncement, setProductAnnouncement] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [enableSaveBtn, setEnableSaveBtn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [fetchingInfo, setFetchingInfo] = useState(true);
  const [checkboxLoader, setCheckboxLoader] = useState(false);

  const changeInputVal = (id = "", value = "", uniqueId = "") => {
    !enableSaveBtn && setEnableSaveBtn(true);
    const stateObj = { ...inputVal };
    if (id && ["timeZone"].includes(id)) {
      stateObj.timeZoneDisplayName = uniqueId;
      stateObj.timeZoneId = value;
    } else {
      stateObj[id] = value;
    }
    setInputVal({ ...inputVal, ...stateObj });
  };

  const updateProfileOnEnter = event => {
    if (event.keyCode === 13) {
      Utils.preventDefaultFn(event);
      updateUserProfileWithCaptcha();
    }
  };
  const updateUserProfileWithCaptcha = () => {
    captchaStoreValue.executeCaptcha(updateUserProfile);
  };

  const updateUserProfile = async captchaToken => {
    if (enableSaveBtn) {
      setLoader(true);
      const callback = (value, status) => {
        setLoader(false);
        setEnableSaveBtn(value);
        status === "success" && settingsStoreData.setUserProfileData(inputVal);
      };
      settingsStoreData.updateUserProfile(inputVal, callback, captchaToken);
    }
  };

  const deactivateYourAccount = () => {
    confirmationPopupState.setPopupValues({
      title: "Pricing Purchase Request?",
      content: `Write to us <a href="mailto:support@salesgear.io?Subject=Deactivate Account"  target="_blank" rel="noopener noreferrer">support@salesgear.io</a> to disable your account and remove all your personal data associated with Salesgear.io`,
      actionBtnText: "Ok",
      needCancelBtn: false,
      callback: () => {
        confirmationPopupState.setShowPopup(false);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  const checkProductAnnounceMent = (value, id) => {
    setCheckboxLoader(true);
    const updateStateValue = response => {
      setCheckboxLoader(false);
      if (response) {
        const stateObj = { ...inputVal };
        stateObj.emailPreferences &&
          Object.keys(stateObj.emailPreferences).length > 0 &&
          (stateObj.emailPreferences[id] = value);
        setInputVal(stateObj);
        setProductAnnouncement(value);
      }
    };
    updateEmailPreferenceWithCaptcha(value, id, updateStateValue);
  };

  const updateEmailPreferenceWithCaptcha = (value, id, updateStateValue) => {
    const updateEmailPreference = async token => {
      settingsStoreData.updateEmailPreference(
        value,
        id,
        updateStateValue,
        token
      );
    };
    captchaStoreValue.executeCaptcha(updateEmailPreference);
  };

  const constructProfileData = () => {
    if (settingsStoreData.userProfileData) {
      setInputVal(settingsStoreData.userProfileData);
      const { emailPreferences } = settingsStoreData.userProfileData;
      emailPreferences &&
        setProductAnnouncement(emailPreferences.PRODUCT_ANNOUNCEMENTS);
      setFetchingInfo(false);
    }
  };

  const inputObj = {
    name: "timeZone",
    type: "select",
    search: true,
    options: userDetail?.globalTimeZone,
    timeZone: true,
    text: "Timezone",
    id: "timeZone",
    value: inputVal?.timeZoneDisplayName || "",
    change: changeInputVal,
    displayName: "displayName"
  };

  const buttonObj = {
    id: "updateProfile",
    name: "updateProfile",
    btnText: "Save",
    icon: false,
    type: "button",
    className: `updateProfileBtn ${loader ? "loading" : ""} `,
    disabled: !enableSaveBtn || false,
    loader: loader,
    click: e => {
      updateUserProfileWithCaptcha(e);
    }
  };
  useEffect(() => {
    settingsStoreData.getUserProfile();
  }, []);

  useEffect(() => {
    constructProfileData();
  }, [settingsStoreData.userProfileData]);

  return (
    <div className="userProfileSection">
      {showChangePassword && (
        <ChangePassowrdComponent
          closeCbk={e => {
            setShowChangePassword(false);
          }}
        />
      )}
      {!fetchingInfo && (
        <div className="popupBody">
          <div className="formInputGroup">
            <InputTextBox
              name="firstName"
              id="firstName"
              text="First Name"
              type="text"
              value={inputVal.firstName || ""}
              change={changeInputVal}
              err=""
              cbk={e => updateProfileOnEnter(e)}
              readOnly={false}
            />
            <InputTextBox
              name="lastName"
              id="lastName"
              text="Last Name"
              type="text"
              value={inputVal.lastName || ""}
              change={changeInputVal}
              err=""
              cbk={e => updateProfileOnEnter(e)}
              readOnly={false}
            />
          </div>
          <div className="formInputGroup">
            <InputTextBox
              name="emailAddress"
              id="emailAddress"
              text="Email Address"
              type="text"
              value={inputVal.email || ""}
              change={changeInputVal}
              err=""
              cbk={e => updateProfileOnEnter(e)}
              readOnly={true}
            />
            <div className="changeWrap">
              <InputTextBox
                name="password"
                id="password"
                text="Password"
                type="text"
                value="*********"
                change={changeInputVal}
                err=""
                cbk={e => updateProfileOnEnter(e)}
                readOnly={false}
              />
              <span
                className="changeBtn"
                onClick={e => {
                  setShowChangePassword(true);
                }}
              >
                Change
              </span>
            </div>
          </div>
          <div className="formInputGroup">
            <InputTextBox
              name="phone"
              id="phone"
              text="Phone Number"
              type="text"
              value={inputVal.phone || ""}
              change={changeInputVal}
              err=""
              cbk={e => updateProfileOnEnter(e)}
              readOnly={false}
            />
            <InputTextBox
              name="title"
              id="title"
              text="Job Title"
              type="text"
              value={inputVal.title || ""}
              change={changeInputVal}
              err=""
              cbk={e => updateProfileOnEnter(e)}
              readOnly={false}
            />
          </div>

          <div className="formInputGroup">
            <SingleSelectDropdown {...inputObj} />

            <InputTextBox
              name="calendarUrl"
              id="calendarUrl"
              text="Calendar Url"
              type="text"
              value={inputVal.calendarUrl || ""}
              change={changeInputVal}
              err=""
              cbk={e => updateProfileOnEnter(e)}
              readOnly={false}
            />
          </div>
          <div className="formInputGroup">
            <InputTextBox
              name="company"
              id="company"
              text="Company"
              type="text"
              value={inputVal.company || ""}
              change={changeInputVal}
              err=""
              cbk={e => updateProfileOnEnter(e)}
              readOnly={false}
            />
            <ApiKeyWithCopyButton
              className="userProfileApiKeyWrapper"
              apiKey={inputVal?.apiKey}
            />
          </div>

          {/* <div className="emailPreference">
            <h6>Email Preferences</h6>
            <div className="chkBoxSection">
              <CheckBoxComponent
                key="PRODUCT_ANNOUNCEMENTS"
                name="Monthly product announcements and other newsletters"
                id="PRODUCT_ANNOUNCEMENTS"
                checked={productAnnouncement}
                cbk={checkProductAnnounceMent}
              />
              {checkboxLoader && <i className="fa fa-spinner fa-spin"></i>}
            </div>
          </div> */}
          <div className="btnSection">
            <Button {...buttonObj} />
            <Button
              id="deactivateAccount"
              name="deactivateAccount"
              btnText="Deactivate Your Account"
              icon={false}
              iconName=""
              type="button"
              className="deactiveBtn"
              loader=""
              click={e => {
                e.preventDefault();
                deactivateYourAccount(e);
              }}
            />
          </div>
        </div>
      )}

      {fetchingInfo && <UserProfileLoader />}
    </div>
  );
});

export default UserProfile;
