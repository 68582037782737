import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { RewriteAiContext } from "./RewriteAi";
import PercentageSvg from "./PercentageSvg";
import Score from "./Score";
import Utils from "Utils/utils";
import WritingAssistantUtils from "Utils/WritingAssistantUtils";
import { AI_SCORE_CONSTANT } from "Static/constant";

const ScoreBot = observer(() => {
  const {
    score = {},
    scoreLoader = false,
    showReadabilityScore = false,
    generateScore = () => {},
    setShowReadabilityScore = () => {}
  } = useContext(RewriteAiContext);

  const [progressiveLoader, setProgressiveLoader] = useState({});

  const getInit = () => {
    const avg = WritingAssistantUtils.calculateScoreAverage(
      AI_SCORE_CONSTANT?.[0],
      score,
      false
    );
    const color = WritingAssistantUtils.calculateColor(avg);
    setProgressiveLoader({ avg: parseFloat(avg) || 0, color });
  };

  useEffect(() => {
    getInit();
  }, [score]);

  return (
    <div className="scoreSection">
      <div
        className="scoreBtn"
        onClick={e => {
          Utils.preventDefaultFn(e);
          generateScore();
        }}
      >
        {scoreLoader ? (
          <div className="scoreLoader" />
        ) : (
          <PercentageSvg progressiveLoader={progressiveLoader} />
        )}
      </div>
      {showReadabilityScore && (
        <>
          <Score score={score} />
          <div
            className="bgOverlay"
            onClick={e => {
              Utils.preventDefaultFn(e);
              setShowReadabilityScore(false);
            }}
          />
        </>
      )}
    </div>
  );
});

export { ScoreBot };
export default ScoreBot;
