import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import Button from "Components/common/Button";

class DeselectCheckedPopupState {
  defaultPopupObj = {
    callback() {}
  };

  @observable showPopup = false;
  @observable popupValues = this.defaultPopupObj;

  @action
  setPopup = value => {
    this.showPopup = value;
  };

  @action
  setPopupValues = value => {
    this.popupValues = value;
  };
}

const deselectCheckedPopupState = new DeselectCheckedPopupState();

const DeselectCheckedPopup = () => {
  const cancelAction = () => {
    deselectCheckedPopupState.setPopup(false);
    deselectCheckedPopupState.setPopupValues(
      deselectCheckedPopupState.defaultPopupObj
    );
  };

  const clearSelection = () => {
    deselectCheckedPopupState.popupValues.callback(false);
    cancelAction();
  };

  return (
    (deselectCheckedPopupState.showPopup && (
      <div className="deselectCheckedPopup">
        <div className="card animated customzoomIn">
          <div className="cardTitle">
            <h4 className="title">Unable to deselect</h4>
          </div>
          <div className="cardBody">
            <p className="textDesc">
              Unable to deselect individual items while using "Select all". To
              deselect an item, clear the selection and select items
              individually.
            </p>
            <div className="actionBtn">
              <Button
                id="deselectCancelBtn"
                name="deselectCancelBtn"
                btnText="Cancel"
                type="button"
                className="deselectCancelBtn"
                click={cancelAction}
              />
              <Button
                id="deselectClearSelectionBtn"
                name="deselectClearSelectionBtn"
                btnText="Clear Selection"
                type="button"
                className="deselectClearSelectionBtn"
                click={clearSelection}
              />
            </div>
          </div>
        </div>
        <div className="deselectPopupOverlay" />
      </div>
    )) ||
    ""
  );
};

export default observer(DeselectCheckedPopup);
export { DeselectCheckedPopup, deselectCheckedPopupState };
