import React from "react";
import Utils from "Utils/utils";

const CustomDropdown = props => {
  const {
    options = [],
    customCbk = () => {},
    refValue = null,
    handleScrollEnd = () => {},
    hasValue = false,
    emptyText = "",
    hasCustomFieldValue: hasCustomText = false,
    loader = false,
    parentIndex = "",
    childIndex = "",
    hasFolderBadge = false,
    disableOptions = [],
    isFromPageName = ""
  } = props;

  const dropdownCbkmethod = (e, item) => {
    if (e) Utils.preventDefaultFn(e);
    customCbk(item, parentIndex, childIndex);
  };

  return loader ? (
    <CustomDropdownLoader />
  ) : (
    <div className={`drop-down-menu animatedFast`}>
      <ul className="dropdownList" onScroll={handleScrollEnd} ref={refValue}>
        {options?.length ? (
          options?.map((item, index) => {
            const {
              name = "",
              email = "",
              key = "",
              shareType = "",
              accessType = "",
              owner = {},
              memberId = "",
              value = "",
              hasCustomFieldValue = "",
              Icon = <></>,
              hasIcon = false,
              disabled = false,
              folderName = ""
            } = item || {};
            const disableOption = disableOptions?.includes(item?.id);
            return (
              <li
                key={`menu_${index}`}
                id={key}
                onClick={
                  disabled || disableOption
                    ? () => {}
                    : e => dropdownCbkmethod(e, item)
                }
                className={`option ${
                  disabled || disableOption ? "disabled" : ""
                }`}
                title={name}
              >
                <div className="listItem">
                  <div className="listItemWrap">
                    {hasIcon && Icon && (
                      <span className="itemIcon">
                        <Icon />
                      </span>
                    )}
                    <span className="itemName">
                      {hasValue
                        ? value
                        : isFromPageName === "tasks"
                        ? Utils.getEntityNameUpdated(
                            shareType,
                            accessType,
                            owner,
                            name || email,
                            memberId,
                            hasFolderBadge ? folderName : ""
                          )
                        : Utils.getEntityName(
                            shareType,
                            accessType,
                            owner,
                            name || email,
                            memberId
                          )}
                    </span>
                    {hasCustomText && hasCustomFieldValue && (
                      <span className="customText">Custom</span>
                    )}
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <span className="noSequencesMsg">{emptyText}</span>
        )}
      </ul>
    </div>
  );
};

const CustomDropdownLoader = () => {
  return (
    <div className={`drop-down-menu`}>
      <ul className="dropdownList">
        {Array.from({ length: 10 }, (value, index) => (
          <li className="option optionLoader" key={`${index}`}>
            <div className="listItem linear-background" />
          </li>
        ))}
      </ul>
    </div>
  );
};

export { CustomDropdown };
export default CustomDropdown;
