import React from "react";

export const SubjectLineDropdownList = props => {
  const { data = [], action = () => {} } = props || {};
  return (
    (data?.length && (
      <ul className="dropdownList">
        {data.map((item, index) => {
          return (
            <li
              key={`subjectLinesData${index}`}
              className="dropdownOption"
              onClick={() => action(item)}
            >
              {item}
            </li>
          );
        })}
      </ul>
    )) ||
    ""
  );
};

export default SubjectLineDropdownList;
