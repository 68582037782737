/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { observer } from "mobx-react";
import CustomTooltip from "Components/common/CustomTooltip";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { ReactComponent as BulkIcon } from "Assets/svg/bulkAction.svg";
import DropDownMenu from "Components/common/DropDownMenu";
import { userDetail } from "Stores/userDetail";
import { showPhonePurchasePopup } from "../DataSearchUtils";
import { Utils } from "Utils/utils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";

const BulkPurchaseButton = props => {
  const { enable = false, cbk = () => {} } = props || {};

  let userData = userDetail?.userFeatureFlag || {};
  let phoneEnabled = userData?.phoneEnabled;

  const [showDropdown, setShowDropdown] = useState(false);
  const [showTooltip, setTooltip] = useState(false);

  const bulkPurchase = async (event, elem = {}) => {
    const { key = "" } = elem;
    setShowDropdown(false);
    if ("phone" === key) {
      if (phoneEnabled) {
        enable && (await checkTeamSubscriptionActive()) && cbk(key);
      } else {
        showPhonePurchasePopup();
      }
    } else {
      enable && (await checkTeamSubscriptionActive()) && cbk(key);
    }
  };

  const dropdownOptions = [
    {
      value: "View emails",
      key: "email",
      method: bulkPurchase
    },
    {
      value: "View emails & phone",
      key: "phone",
      method: bulkPurchase,
      showUpgrade: !phoneEnabled,
      type: "callContact",
      plan: "unlimitedPlan"
    }
  ];

  return (
    <div
      className={`bulkPurchaseBtn ${enable ? "enabled" : ""}`}
      onClick={e => {
        if (enable) {
          Utils.mixpanelTrack(MXP_EVENT.BULK_PURCHASE_EMAIL_PHONE, {
            pageType: PAGE_TYPE.discover
          });
          setShowDropdown(!showDropdown);
          setTooltip(false);
        }
      }}
      onMouseEnter={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
    >
      <BulkIcon className="icon" />
      {showTooltip && !showDropdown && (
        <CustomTooltip text="Bulk view contacts" />
      )}
      {showDropdown && (
        <>
          <DropDownMenu options={dropdownOptions} direction={"topLeft"} />
          <div className="dropdownMask" onClick={e => setShowDropdown(false)} />
        </>
      )}
    </div>
  );
};

export { BulkPurchaseButton };
export default observer(BulkPurchaseButton);
