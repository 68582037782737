import React, { useEffect, useState, useReducer } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import PaginationComponent from "Components/common/PaginationComponent";
import CustomTooltip from "Components/common/CustomTooltip";
import { MXP_EVENT } from "Static/MixPanelEvents";
import Utils from "Utils/utils";
import { BULK_TASKS_OPERATION_DATE } from "Static/constant";
import { PAST_BULK_TASKS_TYPE, PRESENT_BULK_TASKS_TYPE } from "Model/model";
import makeApi, { URLS } from "Utils/apiURL";

const initialState = {
  logs: {},
  pageNo: 1,
  showTooltip: false,
  loader: true
};

const logReducer = (state={}, action=()=>{}) => {
  switch (action?.type) {
    case "UPDATE_LOG_STATE":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const TasksOperations = observer(
  withRouter(() => {
    const [logState, dispatch] = useReducer(logReducer, initialState);
    const totalPages = logState?.logs?.totalPages;
    const updateLogState = (newState = {}) => {
      dispatch({
        type: "UPDATE_LOG_STATE",
        payload: newState
      });
    };

    const statusField = {
      TO_BE_PROCESSED: "Pending",
      IN_PROGRESS: "In Progress",
      PROCESSED: "Completed",
      FAILED: "Failed"
    };

    const headerList = [
      { name: "Type", key: "type" },
      { name: "Status", key: "status" },
      { name: "Total", key: "totalCount" },
      { name: "Success", key: "successCount" },
      { name: "Failed", key: "failedCount" },
      { name: "Created", key: "createdDate" },
      { name: "Owner", key: "ownerName" }
    ];

    const getTasksOperationLogs = async (pageNo = 1) => {
      try {
        const config = {
          url: URLS.tasksOperationLogs || "",
          method: "POST",
          data: { limit: 10, pageNo, date: BULK_TASKS_OPERATION_DATE }
        };
        const res = await makeApi(config);
        const { jobs = [], totalCount = 0 } = res?.data || {};
        if (res?.data) {
          if (totalCount > 0 && jobs?.length > 0) {
            updateLogState({
              ...logState,
              logs: res?.data || {},
              loader: false
            });
            return;
          } else if (res?.response?.status === 401) {
            Utils.showLoggedOutPopup();
          }
        } else {
          Utils.showApiFailureResponse(res);
        }
        updateLogState({ ...logState, loader: false });
      } catch (err) {
        console.error("Fetching bulk tasks log error", err);
      }
    };

    const paginate = (obj = {}) => {
      if (!logState?.loader) {
        let pageNo = obj?.selected + 1 || 1;
        updateLogState({ ...logState, pageNo, loader: true });
        getTasksOperationLogs(pageNo);
      }
    };

    const getSplittedFailedObj = (FailureCounts = {}) => {
      let skipCount = 0;
      let manualEmailCount = 0;
      let phoneCallCount = 0;
      if (FailureCounts && Object.keys(FailureCounts)?.length > 0) {
        for (const property in FailureCounts) {
          const value = FailureCounts?.[property] || 0;
          if ([101, "101"].includes(property)) {
            skipCount = value;
          } else if ([102, "102"].includes(property)) {
            manualEmailCount = value;
          } else if ([103, "103"].includes(property)) {
            phoneCallCount = value;
          }
        }
      }
      return {
        skipCount,
        manualEmailCount,
        phoneCallCount
      };
    };

    useEffect(() => {
      updateLogState({ ...initialState, loader: true });
      Utils.mixpanelTrack(MXP_EVENT.TASKS_BULK_OPERATION_VIEWED);
      getTasksOperationLogs();
      return () => {
        updateLogState({ ...initialState });
      };
    }, []);

    return (
      <div className="tasksOperation">
        <div className="titleHeader">
          <h3>Bulk Actions</h3>
        </div>
        <div className="tableList">
          <div className="tableHeader">
            {headerList?.map((item, index) => {
              const { name = "", key = "" } = item || {};
              return (
                <div
                  className={`titleRow ${key}`}
                  key={`tasksOperationTitle${index}`}
                >
                  {name}
                </div>
              );
            })}
          </div>
          {!logState?.loader ? (
            <BulkActionLogsTable
              logState={logState}
              statusField={statusField}
              getSplittedFailedObj={getSplittedFailedObj}
              updateLogState={updateLogState}
            />
          ) : (
            <LoaderComp headerList={headerList} />
          )}
        </div>
        <PaginationComponent
          callback={paginate}
          loading={logState?.loader}
          totalPages={totalPages}
          currentPage={logState?.logs?.currentPage || 1}
        />
      </div>
    );
  })
);

const BulkActionLogsTable = (props = {}) => {
  const {
    logState = [],
    statusField = {},
    getSplittedFailedObj = () => {},
    updateLogState = () => {}
  } = props || {};

  const getDate = (createTs = "") => {
    return createTs ? Utils.getDateFromString(createTs || "--") : "--";
  };

  return (
    <>
      {(logState?.logs?.jobs?.length > 0 && (
        <div className="tableRecordHeightAlign">
          {logState?.logs?.jobs?.map((item, index) => {
            const {
              id = "",
              FailureCounts = {},
              status = "",
              type = "",
              createTs = "",
              owner = {},
              totalCount = 0,
              successCount = 0,
              failedCount = 0
            } = item || {};

            let inProgress = ["TO_BE_PROCESSED", "IN_PROGRESS"]?.includes(
              status
            );

            return (
              <div
                className={`tableBody ${id}`}
                key={`tasksOperationLogs${index}`}
              >
                <div className={`type titleRow`}>
                  {PRESENT_BULK_TASKS_TYPE[type]} Task
                  {totalCount ? "s" : ""}
                </div>
                <div className={`status titleRow`}>
                  {statusField[status] || "--"}
                </div>
                <div className={`totalCount titleRow`}>
                  {inProgress ? "--" : totalCount}
                </div>
                <div className={`successCount titleRow`}>
                  {inProgress ? "--" : successCount}
                </div>
                <div className={`failedCount titleRow`}>
                  {inProgress
                    ? "--"
                    : (failedCount > 0 && (
                        <FailureCountsComp
                          {...getSplittedFailedObj(FailureCounts)}
                          total={failedCount}
                        />
                      )) ||
                      0}
                </div>
                <div className={`createdDate titleRow`}>
                  {getDate(createTs)}
                </div>
                <div className={`ownerName titleRow`}>
                  {(owner && (
                    <div
                      className={`contactOwner`}
                      onMouseEnter={() =>
                        updateLogState({ ...logState, showTooltip: id })
                      }
                      onMouseLeave={() =>
                        updateLogState({
                          ...logState,
                          showTooltip: false
                        })
                      }
                    >
                      <span className="circleOwner">
                        {Utils.getOwnerInitial(owner)}
                      </span>
                      {logState?.showTooltip === id && (
                        <CustomTooltip
                          text={`Added by ${Utils.getOwnerName(owner)}`}
                        />
                      )}
                    </div>
                  )) ||
                    "--"}
                </div>
              </div>
            );
          })}
        </div>
      )) || (
        <div className="noDataFound">
          <i className="material-icons-outlined">list_alt</i>
          <span className="text">No bulk actions found</span>
        </div>
      )}
    </>
  );
};

const FailureCountsComp = ({
  skipCount = 0,
  manualEmailCount = 0,
  phoneCallCount = 0,
  total = 0
}) => {
  const [showTooltip, setTooltip] = useState(false);
  const [styleCss, setStyleCss] = useState();

  const toggleTooltip = (event = "") => {
    let domPositionY =
      event?.nativeEvent?.layerY || event?.nativeEvent.offsetY || 0;
    let diffHeight = window.innerHeight - event?.clientY || event?.pageY;
    let styleObject = {};
    if (diffHeight - domPositionY + event?.currentTarget?.clientHeight >= 144) {
      styleObject.top =
        event?.clientY -
        event?.nativeEvent.layerY +
        event?.currentTarget?.clientHeight;
    } else {
      styleObject.bottom = diffHeight + event?.nativeEvent?.layerY;
    }
    styleObject.right = window.innerWidth - event?.clientX;
    setStyleCss(styleObject);
    setTooltip(true);
  };

  const hideTooltip = () => {
    setStyleCss({});
    setTooltip(false);
  };

  return (
    <div className="tasksFailedOperation">
      <span
        className="count"
        onMouseOver={event => (total > 0 && toggleTooltip(event, true)) || {}}
        onMouseOut={() => (total > 0 && hideTooltip(false)) || {}}
      >
        {total}
      </span>
      {showTooltip && (
        <div className="cardTooltip animatedFast" style={styleCss}>
          <div className="cardTitle">
            Failed to upload {total} contact{total > 1 ? "s" : ""}
          </div>
          <div className="cardBody">
            {(skipCount && (
              <div className="detailedInfoCount">
                <span className="material-icons-outlined listIcon">
                  radio_button_checked
                </span>
                <span className="text">
                  {skipCount} Skip task{skipCount > 1 ? "s" : ""} for reply
                  thread
                </span>
              </div>
            )) ||
              ""}
            {(phoneCallCount && (
              <div className="detailedInfoCount">
                <span className="material-icons-outlined listIcon">
                  radio_button_checked
                </span>
                <span className="text">
                  {phoneCallCount} phone call task
                  {phoneCallCount > 1 ? "s" : ""}
                </span>
              </div>
            )) ||
              ""}
            {(manualEmailCount && (
              <div className="detailedInfoCount">
                <span className="material-icons-outlined listIcon">
                  radio_button_checked
                </span>
                <span className="text">
                  {manualEmailCount} manual email task
                  {manualEmailCount > 1 ? "s" : ""}
                </span>
              </div>
            )) ||
              ""}
          </div>
        </div>
      )}
    </div>
  );
};

const LoaderComp = (props = {}) => {
  const { headerList = [] } = props || {};
  return (
    <div className="adjustHt">
      {Array.from({ length: 8 }, (value, index) => (
        <div className="tableBodyLoader" key={`tableBodyLoader${index + 1}`}>
          {headerList?.length &&
            headerList?.map((item, idx) => {
              const { key = "" } = item || {};
              return (
                <div className={`${key}`} key={`${key}${index}${idx}`}>
                  <span className={`colLoader linear-background`} />
                </div>
              );
            })}
        </div>
      ))}
    </div>
  );
};
export default TasksOperations;
