import React from "react";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import Disposition from "./Disposition/Main";
import Dialer from "../Dialer";

const Routing = () => {
  let { path = "" } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/general`} component={Dialer} />
      <Route exact path={`${path}/callDisposition`} component={Disposition} />
      <Redirect from={path} to={`${path}/general`} />
    </Switch>
  );
};

export default Routing;
