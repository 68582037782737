import React from "react";

const RadioButton = props => {
  const {
    name = "",
    id = "",
    value = "",
    defaultChecked = false,
    disable = false,
    radioOnchange = () => {}
  } = props || {};

  return (
    <div className="radioBtnCont" key={id}>
      <div className="radioButtonCont">
        <input
          type="radio"
          name={name}
          id={id}
          onChange={e => {
            !disable && radioOnchange(e, id);
          }}
          defaultChecked={defaultChecked}
          className="radioBtn"
          disabled={disable}
        />
        <span className="contentClass" />
        <label htmlFor={id}>{value}</label>
      </div>
    </div>
  );
};

export default RadioButton;
