/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { observer } from "mobx-react";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerComponent = props => {
  const {
    prefilledDate = "",
    id = "",
    isReadOnly = false,
    value = new Date(),
    getDateCbk = () => {},
    onClickOutside = () => {},
    inline = false
  } = props || {};

  const [selectedData, setSelectedDate] = useState(value);

  const handleChange = (date = new Date()) => {
    setSelectedDate(date);
    getDateCbk(date, id);
  };

  const getMinTime = () => {
    const today = new Date();
    const todayTime = today?.getTime();
    const selectedDateTime = selectedData?.getTime();
    if (selectedDateTime > todayTime) {
      return selectedData?.setHours(0, 0, 0, 0);
    } else if (selectedDateTime < todayTime) {
      return today;
    } else {
      return selectedData;
    }
  };

  useEffect(() => {
    prefilledDate && setSelectedDate(new Date(prefilledDate));
  }, [prefilledDate]);

  return (
    <DatePicker
      selected={Date.parse(selectedData)}
      id={id}
      onChange={date => handleChange(date)}
      minDate={new Date()}
      minTime={getMinTime()}
      maxTime={new Date().setHours(23, 59, 59)}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={5}
      timeCaption="Time"
      dateFormat="MMMM d, yyyy h:mm aa"
      readOnly={isReadOnly ? true : false}
      onClickOutside={onClickOutside}
      inline={inline}
    />
  );
};

export default observer(DatePickerComponent);
export { DatePickerComponent };
