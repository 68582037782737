import React from "react";
import NoTemplateIcon from "Assets/png/TemplateCard.png";

const NoTemplates = props => {
  const { add } = props;

  return (
    <div className="noTemplates">
      <div className="emptySequence">
        <img src={NoTemplateIcon} alt="" />
        <span>You haven't created any Templates in this folder</span>
        <button
          id="newTemplates"
          name="newTemplates"
          type="button"
          className="createTemplateBtn"
          onClick={add}
        >
          <span>Create a Template</span>
        </button>
      </div>
    </div>
  );
};

export default NoTemplates;
export { NoTemplates };
