import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import userDetail from "Stores/userDetail";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { ReactComponent as KnightIcon } from "../img/knightIcon.svg";
import { ReactComponent as RemoveMemberIcon } from "../img/removeMemberIcon.svg";
import teamMemberState from "./state";
import teamState from "../state";
import EmptyAddMembers from "./EmptyAddMembers";
import TeamMemberTableLoader from "./TeamMemberTableLoader";
import CustomTooltip from "Components/common/CustomTooltip";
import { SettingContext } from "Pages/Dashboard/settings/Settings";

const TeamMemberTable = observer(() => {
  const { updateBreadCrumb = () => {} } = useContext(SettingContext);
  const { team = "" } = userDetail?.userInfo || {};

  const [tooltipIndex, setTooltipIndex] = useState(false);
  const tableObj = [
    {
      label: "Full Name",
      className: "fullName",
      keyVal: "name"
    },
    {
      label: "Email Address",
      className: "emailAddress",
      keyVal: "email"
    },
    {
      label: "Job Title",
      className: "jobTitle",
      keyVal: "title"
    },
    {
      label: "Role",
      className: "role",
      keyVal: "role"
    }
  ];

  const handleAddTeamMembers = () => {
    teamMemberState.setShowAddMemberPopup(true);
  };

  const addTeamMemberBtnProps = {
    id: "addTeamMemberBtn",
    name: "addTeamMemberBtn",
    btnText: "Add members",
    type: "button",
    className: "addTeamMemberBtn",
    click: handleAddTeamMembers
  };

  const handleMakeOwner = (list = {}) => {
    const { groupId = "", memberId = "", owner = false } = list || {};
    if (!owner) {
      setTooltipIndex("");
      teamMemberState.makeOwner(groupId, memberId);
    }
  };

  const handleBackBtn = () => {
    updateBreadCrumb(`Users & Teams`);
    teamState.setShowTeamMemberPage(false);
  };

  const removeMemberAction = async (list = {}) => {
    await teamMemberState.removeMemberFromGroup(list, team);
  };

  const handleRemoveMember = (list = {}) => {
    const {
      groupId = "",
      firstName = "",
      lastName = "",
      email = "",
      owner = false
    } = list || {};
    let currentTeamObj = {};
    if (teamState?.teamData?.groupList?.length) {
      currentTeamObj = teamState.teamData.groupList.find(
        item => item?.id === groupId && item
      );
    }
    let content = owner
      ? `Are you sure you want to remove yourself as team owner? You will no longer have access to the team.`
      : `Are you sure you want to remove ${Utils.getName(
          firstName,
          lastName,
          "",
          email
        )} from ${currentTeamObj?.name || ""} team?`;
    confirmationPopupState.setPopupValues({
      content,
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      callback: () => removeMemberAction(list),
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  return teamMemberState?.teamMemberLoading ? (
    <TeamMemberTableLoader />
  ) : teamMemberState?.teamMemberData?.memberList?.length > 0 ? (
    <>
      <div className="teamHeaderSection">
        <div className="backBtn" onClick={handleBackBtn}>
          <span className="material-icons forwardArrow">arrow_forward</span>
        </div>
        <Button {...addTeamMemberBtnProps} />
      </div>
      <div className="teamMemberSection">
        <div className="headerPadding">
          <div className="tableHeader">
            {tableObj.map(item => {
              const { className = "", label = "" } = item || {};
              return (
                <div className={className} key={label}>
                  {label}
                </div>
              );
            })}
          </div>
        </div>
        <div className="tableBody">
          {!teamMemberState?.teamMemberLoading &&
            teamMemberState?.teamMemberData &&
            Object.keys(teamMemberState?.teamMemberData).length > 0 &&
            teamMemberState?.teamMemberData?.memberList?.length > 0 &&
            teamMemberState?.teamMemberData.memberList.map((list, index) => {
              return (
                <div className="tableRow" key={`teamMemberTable${index}`}>
                  {tableObj.map(item => {
                    const { className = "", keyVal = "" } = item || {};
                    if (keyVal === "name") {
                      const updatedName = Utils.getFullNameFromUserInfo(list);
                      return (
                        <div className={className} key={keyVal}>
                          {updatedName}
                        </div>
                      );
                    } else {
                      return (
                        <div className={className} key={keyVal}>
                          {list?.[keyVal]}
                        </div>
                      );
                    }
                  })}
                  <span
                    className={`makeOwnerIcon ${
                      !list?.owner ? "notAOwner" : ""
                    }`}
                    onClick={() => handleMakeOwner(list)}
                    onMouseEnter={() => !list.owner && setTooltipIndex(index)}
                    onMouseLeave={() => !list.owner && setTooltipIndex("")}
                  >
                    <KnightIcon />
                    {tooltipIndex === index && (
                      <CustomTooltip text={"Make team owner"} />
                    )}
                  </span>
                  <span
                    className="removeMemberIcon"
                    onClick={() => handleRemoveMember(list)}
                  >
                    <RemoveMemberIcon />
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </>
  ) : (
    <EmptyAddMembers handleAddTeamMembers={handleAddTeamMembers} />
  );
});

export { TeamMemberTable };
export default TeamMemberTable;
