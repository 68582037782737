import React, { useState } from "react";
import TokenVariable from "Components/common/TokenVariable";
import TextArea from "Components/common/TextArea";

export const NotesInput = props => {
  const {
    notes = "",
    action = () => {},
    pageType = "Sequences",
    setCursorPostion = () => {},
    labelText = "Notes",
    errorMsg = false
  } = props || {};

  const [showTokenVariable, setShowTokenVariable] = useState(false);

  const addNewTokenVariable = (value = "") => {
    window &&
      window.getSelection() &&
      window.getSelection().deleteFromDocument();
    action(value, true);
    setShowTokenVariable(false);
  };

  return (
    <div className="notesInput">
      <TextArea
        id="taskNotes"
        rows={5}
        value={notes}
        onChangeEvent={e => action(e.target.value)}
        labelText={labelText}
        setCursorPostion={setCursorPostion}
      />
      <div className="insertVariableBtn">
        <span onClick={() => setShowTokenVariable(!showTokenVariable)}>
          {`{ X } Insert Variable`}
        </span>
        {showTokenVariable && (
          <TokenVariable
            tokenCbk={addNewTokenVariable}
            closeCbk={() => setShowTokenVariable(false)}
            pageType={pageType}
          />
        )}
      </div>
      {errorMsg && (
        <span className={`errMsgText`}>{"Message cannot be empty"}</span>
      )}
    </div>
  );
};

export default NotesInput;
