import React from "react";

const SchedulesLoading = () => {
  const classNameList = [
    "scheduleNameWrapper",
    "scheduleDates",
    "scheduleTimeZone"
  ];

  return Array.from({ length: 5 }, (value, index) => (
    <div
      className="individualScheduleWrapper"
      key={`individualScheduleWrapper${index}`}
    >
      {classNameList?.map((item, idx) => {
        return (
          <div className={item} key={`${item}${idx}`}>
            <span className="d-block h-12p b-r-20 w-100 linear-background" />
          </div>
        );
      })}
    </div>
  ));
};

export { SchedulesLoading };
export default SchedulesLoading;
