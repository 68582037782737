import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import SequenceTemplateData from "Stores/sequence/SequenceTemplateData";
import userDetail from "Stores/userDetail";
import InputTextBox from "Components/common/InputTextBox";
import { toasterState } from "Components/common/toaster";
import TextArea from "Components/common/TextArea";
import Button from "Components/common/Button";
import FilterDropdown from "../../settings/Triggers/AddEditTrigger/FilterDropdown";
import { seqTemplatePopupState } from "./SeqTemplatePopupState";
import GenerateAISequenceLottie from "./GenerateAISequenceLottie";
import ProgressBar from "./ProgressBar";
import TitleBadge from "./TitleBadge";
import { MP_EVENT } from "Static/constant";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";

const GenerateAISequenceFirstScreen = observer(props => {
  const { setShowFirstScreen = () => {} } = props || {};
  const [activeDropdownIndex, setActiveDropdownIndex] = useState("");
  const [errorValues, setErrorvalues] = useState({});
  const [loader, setLoader] = useState(false);

  const handleDropdownChange = (key, value) => {
    if (value) {
      let tempErrvalues = { ...errorValues };
      delete tempErrvalues?.[key];
      setErrorvalues({ ...tempErrvalues });
    }
    seqTemplatePopupState.setGenerateAISeqValue({
      ...seqTemplatePopupState?.generateAISeqValue,
      [key]: {
        ...value
      }
    });
    setActiveDropdownIndex("");
  };

  const handleInputChange = (key, value) => {
    if (key === "domain") {
      seqTemplatePopupState.setHasDomainChange(true);
    }
    seqTemplatePopupState.setGenerateAISeqValue({
      ...seqTemplatePopupState?.generateAISeqValue,
      [key]: value
    });
  };

  const handleNextClick = async () => {
    const { type = "", emailTone = "" } =
      seqTemplatePopupState?.generateAISeqValue || {};
    if (!type) {
      setErrorvalues({
        ...errorValues,
        ["type"]: "Please select an option."
      });
    } else if (!emailTone) {
      setErrorvalues({
        ...errorValues,
        ["emailTone"]: "Please select an option."
      });
    } else {
      if (seqTemplatePopupState?.hasDomainChange) {
        setLoader(true);
        setErrorvalues({});
        seqTemplatePopupState.setGenerateAISeqValue({
          ...seqTemplatePopupState.generateAISeqValue,
          description: "",
          name: ""
        });
        seqTemplatePopupState.setHasDomainChange(false);
        const res = await seqTemplatePopupState.getDomain();
        if (res) {
          seqTemplatePopupState.setProgressBarState(2);
          setShowFirstScreen(false);
        }
      } else {
        seqTemplatePopupState.setProgressBarState(2);
        setShowFirstScreen(false);
      }
    }
  };

  const generateAISequenceObj = [
    {
      label: "Sequence Type",
      keyVal: "type",
      type: "dropdown",
      className: seqTemplatePopupState?.generateAISeqValue?.type
        ? "activeBorder"
        : "",
      placeholderValue: "Select Sequence Type",
      options: [
        {
          value: "Outbound cold outreach (Email only)",
          subLabel:
            "An automated email-only sequence with 9 steps over 20 days",
          key: "OUTBOUND_EMAIL_ONLY",
          sequenceName: "Outbound sequence - email only",
          method: handleDropdownChange,
          hasSubLabel: true,
          showSubmenu: false
        },
        {
          value: "Outbound cold outreach (Multi-channel)",
          subLabel:
            "An automated sequence with multiple channels like email, call, and task with 16 steps over 21 days",
          key: "OUTBOUND_MULTI",
          sequenceName: "Outbound sequence - multi channel",
          method: handleDropdownChange,
          hasSubLabel: true,
          showSubmenu: false
        }
      ]
    },
    {
      label: "Sequence Tone",
      keyVal: "emailTone",
      type: "dropdown",
      className: seqTemplatePopupState?.generateAISeqValue?.emailTone
        ? "activeBorder"
        : "",
      placeholderValue: "Select Sequence Tone",
      options: [
        {
          value: "Persuasive",
          key: "persuasive",
          method: handleDropdownChange,
          showSubmenu: false
        },
        {
          value: "Witty",
          key: "witty",
          method: handleDropdownChange,
          showSubmenu: false
        },
        {
          value: "Empathetic",
          key: "empathetic",
          method: handleDropdownChange,
          showSubmenu: false
        },
        {
          value: "Professional",
          key: "professional",
          method: handleDropdownChange,
          showSubmenu: false
        },
        {
          value: "Friendly",
          key: "friendly",
          method: handleDropdownChange,
          showSubmenu: false
        }
      ]
    },
    {
      type: "input",
      keyVal: "domain",
      itemProps: {
        name: "domain",
        id: "domain",
        text: "",
        type: "text",
        label: "Website",
        value: seqTemplatePopupState?.generateAISeqValue?.domain || "",
        change: handleInputChange,
        readOnly: false,
        placeHolder: "Enter Website URL",
        className: seqTemplatePopupState?.generateAISeqValue?.domain
          ? "activeBorder"
          : ""
      }
    },
    {
      type: "button",
      itemProps: {
        id: "next",
        name: "nextBtn",
        btnText: "Next",
        icon: false,
        iconName: "",
        type: "button",
        className: `nextBtn ${loader ? "loading" : ""}`,
        loader,
        click: handleNextClick,
        disabled: errorValues && Object.keys(errorValues)?.length > 0
      }
    }
  ];

  return (
    <div className="generateAIItemWrapper">
      {generateAISequenceObj?.map((item, index) => {
        const {
          type = "",
          options = [],
          label = "",
          keyVal = "",
          itemProps = {},
          className = "",
          placeholderValue = ""
        } = item || {};

        return (
          <div className="generateAISeqItem" key={keyVal}>
            {type === "dropdown" ? (
              <>
                <div className="dropdownHeaderLabel">{label}</div>
                <FilterDropdown
                  className={className}
                  options={options}
                  showDropdown={activeDropdownIndex === `${index}`}
                  setActiveDropdownIndex={setActiveDropdownIndex}
                  defaultValue={
                    seqTemplatePopupState?.generateAISeqValue?.[keyVal]
                      ?.value || placeholderValue
                  }
                  label={""}
                  referenceId={keyVal}
                  index={`${index}`}
                />
              </>
            ) : type === "input" ? (
              <>
                <div className="dropdownHeaderLabel">{itemProps?.label}</div>
                <InputTextBox {...itemProps} />
              </>
            ) : type === "button" ? (
              <Button {...itemProps} />
            ) : (
              <></>
            )}
            {errorValues?.[keyVal] && (
              <span className="errMsgText">{errorValues?.[keyVal]}</span>
            )}
          </div>
        );
      })}
    </div>
  );
});

const GenerateAISequenceSecondScreen = observer(props => {
  const { closePopup = () => {} } = props || {};
  const history = useHistory();
  const userData = userDetail.userFeatureFlag;
  const [errorValues, setErrorvalues] = useState({});

  const handleInputChange = (key, value) => {
    if (value) {
      let tempErrvalues = { ...errorValues };
      delete tempErrvalues?.[key];
      setErrorvalues({ ...tempErrvalues });
    }
    seqTemplatePopupState.setGenerateAISeqValue({
      ...seqTemplatePopupState?.generateAISeqValue,
      [key]: value
    });
  };

  const handleTextAreaChange = (e, key) => {
    if (e?.currentTarget?.value) {
      let tempErrvalues = { ...errorValues };
      delete tempErrvalues?.[key];
      setErrorvalues({ ...tempErrvalues });
    }
    seqTemplatePopupState.setGenerateAISeqValue({
      ...seqTemplatePopupState?.generateAISeqValue,
      [key]: e?.currentTarget?.value
    });
  };

  const resetState = async () => {
    SequenceTemplateData.setSeqTemmplateClicked(false);
    seqTemplatePopupState.setProgressBarState(1);
    seqTemplatePopupState.setGenerateAISeqValue({});
    seqTemplatePopupState.setHasDomainChange(false);
    await seqTemplatePopupState.getDomain();
  };

  const createAISequence = async data => {
    const payload = { ...data };
    const folderDetailsObj = Utils.getFolderDetails();
    const folderName = folderDetailsObj?.folderName;
    const folderId = folderDetailsObj?.folderId;
    payload.folderName = folderName || null;
    payload.folderId = folderId || null;
    payload.timezone = Utils.getCurrentUserTimeZone();
    resetState();

    const config = {
      url: `${URLS.createAISequence}`,
      method: "POST",
      data: payload
    };
    const res = await makeApi(config);
    if (res?.data?.id) {
      Utils.mixpanelTrack(MP_EVENT.SQ_CREATED, {
        sequenceName: payload?.sequenceName,
        sequenceType: "AI",
        pageType: "Sequence Page"
      });
      seqTemplatePopupState.setShowLottie(false);
      userData.noOfSequencesUsed += 1;
      userDetail.setUserFeatureFlag(userData);
      toasterState.setToastMsg("Sequence created successfully", "success");
      closePopup();
      Utils.getOnboardingData();
      history.push(
        `/sequences/${payload.folderId === null ? "all" : payload.folderId}/${
          res.data.id
        }`
      );
    } else if (res.response.status === 426) {
      seqTemplatePopupState.setShowLottie(false);
      closePopup();
      Utils.showUpgradePopup("sequences", true, true);
    } else {
      seqTemplatePopupState.setShowLottie(false);
      closePopup();
    }
  };

  const handleGenerateSequence = () => {
    const { description = "", name = "" } =
      seqTemplatePopupState.generateAISeqValue || {};
    const descriptionLength = description?.length || 0;
    const nameLength = name?.length || 0;
    if (
      descriptionLength >= 50 &&
      descriptionLength <= 500 &&
      nameLength >= 2 &&
      nameLength <= 50
    ) {
      const payload = {
        type: seqTemplatePopupState?.generateAISeqValue?.["type"]?.key,
        sequenceName:
          seqTemplatePopupState?.generateAISeqValue?.["type"]?.sequenceName,
        emailTone:
          seqTemplatePopupState?.generateAISeqValue?.["emailTone"]?.key,
        companyName: seqTemplatePopupState?.generateAISeqValue?.name,
        description: seqTemplatePopupState?.generateAISeqValue?.description
      };
      seqTemplatePopupState.setShowLottie(true);
      createAISequence(payload);
    } else if (nameLength === 0) {
      setErrorvalues({
        ...errorValues,
        ["name"]: "Please enter the name of a product / service."
      });
    } else if (nameLength < 2) {
      setErrorvalues({
        ...errorValues,
        ["name"]: "Please enter a name with at least 2 characters."
      });
    } else if (nameLength > 50) {
      setErrorvalues({
        ...errorValues,
        ["name"]:
          "Please make sure the product/ service name is less than 50 characters."
      });
    } else if (descriptionLength === 0) {
      setErrorvalues({
        ...errorValues,
        ["description"]:
          "Please enter a short description about the product / service."
      });
    } else if (descriptionLength < 50) {
      setErrorvalues({
        ...errorValues,
        ["description"]:
          "Please enter a description with at least 50 characters."
      });
    } else if (descriptionLength > 500) {
      setErrorvalues({
        ...errorValues,
        ["description"]:
          "Please make sure the description is less than 500 characters."
      });
    }
  };

  const generateAISequenceObj = [
    {
      type: "input",
      keyVal: "name",
      itemProps: {
        name: "name",
        id: "name",
        text: "",
        label: "Name of the Product/Service",
        type: "text",
        value: seqTemplatePopupState?.generateAISeqValue?.["name"] || "",
        className: seqTemplatePopupState?.generateAISeqValue?.["name"]
          ? "activeBorder"
          : "",
        change: handleInputChange,
        readOnly: false
      }
    },
    {
      type: "textArea",
      keyVal: "description",
      itemProps: {
        name: "description",
        id: "description",
        label: "Description",
        value: seqTemplatePopupState?.generateAISeqValue?.["description"] || "",
        onChangeEvent: handleTextAreaChange,
        className: seqTemplatePopupState?.generateAISeqValue?.["description"]
          ? "activeBorder"
          : ""
      }
    },
    {
      type: "button",
      itemProps: {
        id: "next",
        name: "generateSequenceBtn",
        btnText: "Generate Sequence",
        icon: false,
        iconName: "",
        type: "button",
        className: "nextBtn",
        loader: false,
        click: handleGenerateSequence,
        disabled: errorValues && Object.keys(errorValues)?.length > 0
      }
    }
  ];

  return (
    <div className="generateAIItemWrapper generateAISecondScreen">
      {generateAISequenceObj?.map(item => {
        const {
          type = "",
          itemProps = {},
          keyVal = "",
          label = ""
        } = item || {};

        return (
          <div className="generateAISeqItem" key={keyVal}>
            {type === "textArea" ? (
              <>
                <div className="dropdownHeaderLabel">{itemProps?.label}</div>
                <TextArea {...itemProps} />
              </>
            ) : type === "input" ? (
              <>
                <div className="dropdownHeaderLabel">{itemProps?.label}</div>
                <InputTextBox {...itemProps} />
              </>
            ) : type === "button" ? (
              <Button {...itemProps} />
            ) : (
              <></>
            )}
            {errorValues?.[keyVal] && (
              <span className="errMsgText">{errorValues?.[keyVal]}</span>
            )}
          </div>
        );
      })}
    </div>
  );
});

const GenerateAISequence = observer(props => {
  const { closePopup = () => {} } = props || {};
  const [showFirstScreen, setShowFirstScreen] = useState(true);
  const { inFreePlan = false, planName = "" } =
    userDetail.userFeatureFlag || {};

  const handleProgressBarClick = index => {
    if (index === 1) {
      SequenceTemplateData.setSeqTemmplateClicked(false);
      setShowFirstScreen(true);
      seqTemplatePopupState.setProgressBarState(1);
    }
  };

  return (
    <>
      <div className="sequenceTemplatePopupHeader">
        <TitleBadge title="Generate AI Sequence" showDesc={true}>
          <span>Start with AI writing assistant sequence</span>
        </TitleBadge>
        <i
          id="closeSequenceTemplatePopup"
          className="material-icons"
          onClick={() => closePopup()}
        >
          close
        </i>
      </div>
      {seqTemplatePopupState?.showLottie ? (
        <GenerateAISequenceLottie />
      ) : (
        <>
          {showFirstScreen ? (
            <>
              <ProgressBar cbk={handleProgressBarClick} />
              <GenerateAISequenceFirstScreen
                setShowFirstScreen={setShowFirstScreen}
              />
            </>
          ) : (
            <>
              <ProgressBar cbk={handleProgressBarClick} />
              <GenerateAISequenceSecondScreen
                setShowFirstScreen={setShowFirstScreen}
                closePopup={closePopup}
              />
            </>
          )}
        </>
      )}
    </>
  );
});

export { GenerateAISequence };
export default GenerateAISequence;
