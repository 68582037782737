import React, { useState, useEffect, useContext, useCallback } from "react";
import { observer } from "mobx-react";
import { withRouter, useParams } from "react-router-dom";
import { accountsState } from "../AccountsState";
import globalCallState from "Stores/GlobalCallState";
import { AccountsContext } from "../Main";
import InsertContactCard from "Components/ContactCard/InsertContactCard";
import ContactsDataTable from "./ContactsDataTable";
import FilterNavBar from "../FilterNavBar";
import SearchByContactKeyword from "Pages/Dashboard/contacts/SearchByContactKeyword";
import Utils from "Utils/utils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { toasterState } from "Components/common/toaster";
import ContactSelectedCount from "Components/common/ContactSelectedCount";
import SequenceUtils from "Utils/SequenceUtils";
import { MP_EVENT } from "Static/constant";
import { settingsStoreData } from "Stores/settingsData";
import { addBulkContactPopupState } from "Components/common/bulkContactPopup/AddBulkContactPopup";
import contactStoreData from "Stores/contactData";
import FieldValidatorMsgPopup from "../../contacts/ContactToSequence/FieldValidatorMsgPopup";
import ContactUploading from "../../contacts/ContactToSequence/ContactUploading";
import ContactsHeaderActions from "./ContactsHeaderActions";

const defaultFilterTabObj = [
  {
    name: "All",
    id: "TOTAL"
  },
  {
    name: "Contacted",
    id: "CONTACTED"
  },
  {
    name: "Not Contacted",
    id: "NOT_CONTACTED"
  },
  {
    name: "Bounced",
    id: "BOUNCED"
  },
  {
    name: "Opted Out",
    id: "OPTED_OUT"
  },
  {
    name: "No stage",
    id: "NOSTAGE"
  }
];

const defaultSearchInputObj = {
  searchTerm: "",
  isEmailClose: ""
};

const Main = withRouter(
  observer(() => {
    const { accountId = "" } = useParams();
    const { updateBreadCumb = () => {} } = useContext(AccountsContext);

    const [filterTabData, setFilterTabData] = useState([
      ...defaultFilterTabObj
    ]);
    const [tableData, setTableData] = useState({});
    const [filterTabLoading, setFilterTabLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [searchInput, setSearchInput] = useState({
      ...defaultSearchInputObj
    });
    const [searchPerformed, setSearchPerformed] = useState(false);
    // Contact card state
    const [selectedContact, setSelecedContact] = useState({});
    const [showContactCard, setShowContactCard] = useState(false);
    const [slideAnim, setSlideAnim] = useState(false);
    const [allAccountsSelectedCheckbox, setAllAccountsSelectedCheckbox] =
      useState(false);
    const [allAccountsSelectedInView, setAllAccountsSelectedInView] =
      useState(false);
    const [showContactUploadingLoader, setContactUploadingLoader] =
      useState(false);
    const [fieldErrorDetails, setFieldErrorDetails] = useState("");
    const [uploadingText, setUploadingText] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [dailyLimitContactData, setDailyLimitContactData] = useState("");

    // Make API REQUEST
    const getStageAnalyticsPayload = () => {
      const { stageId = "", searchTerm = "" } =
        accountsState?.contactsState || {};
      let payload = {
        filter: {
          accountId: [accountId]
        },
        stageId: stageId?.toUpperCase() === "TOTAL" ? null : stageId,
        searchTerm
      };
      return payload;
    };

    const getFilterTabData = async () => {
      setFilterTabLoading(true);
      let payload = getStageAnalyticsPayload();
      let stagesList = await accountsState.getAccountContactsStages();
      let response = await accountsState.getAccountContactsFilterAnalytics(
        payload
      );
      if (
        response?.message?.message === "cancel" ||
        response?.message === "cancel"
      ) {
        console.error("Request cancelled");
      } else {
        const { status = "", data = {} } = response || {};
        if (
          +status === 200 &&
          data?.contactAnlaytics &&
          Object.keys(data?.contactAnlaytics)?.length > 0 &&
          stagesList?.length > 0
        ) {
          let tempStagesList = [];
          [
            {
              name: "TOTAL",
              displayName: "All",
              id: "TOTAL",
              displayEnabled: true
            },
            ...stagesList
          ].map(item => {
            if (item?.displayEnabled) {
              tempStagesList.push({
                ...item,
                count: data?.contactAnlaytics?.[item?.name] || 0
              });
            }
          });
          setFilterTabData(tempStagesList);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later.",
            "fail"
          );
        }
        setFilterTabLoading(false);
      }
    };

    const getContactPayload = () => {
      const {
        limit = 10,
        pageNo = 1,
        stageId = "",
        searchTerm = ""
      } = accountsState?.contactsState || {};
      let payload = {
        filter: {
          accountId: [accountId]
        },
        searchTerm,
        stageId: stageId?.toUpperCase() === "TOTAL" ? null : stageId,
        limit,
        pageNo
      };
      return payload;
    };

    const getTableData = async () => {
      setTableLoading(true);
      let payload = getContactPayload();
      let response = await accountsState.getAccountContactsList(payload);
      if (
        response?.message?.message === "cancel" ||
        response?.message === "cancel"
      ) {
        console.error("Request cancelled");
      } else {
        const { status = "", data = {} } = response || {};
        if (+status === 200 && data && Object.keys(data)?.length > 0) {
          setTableData(data);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later.",
            "fail"
          );
        }
        setTableLoading(false);
      }
    };

    const reloadContactsPage = () => {
      getFilterTabData();
      getTableData();
      resetAllStateValue();
    };

    const handleTabFilterAction = (event = "") => {
      event && Utils.preventDefaultFn(event);
      resetAllStateValue();
      if (event) {
        let listElem = event?.target?.closest("li") || "";
        if (listElem) {
          let name = listElem?.getAttribute("data-key") || "";
          let id = listElem?.getAttribute("data-id") || "";
          accountsState.setContactsState({
            activeTab: name,
            stageId: id,
            pageNo: 1
          });
        } else {
          getTableData();
        }
      }
    };

    const paginate = (pageObj = {}) => {
      let pageNo = pageObj?.selected + 1 || 1;
      accountsState.setContactsState({ pageNo });
      resetAllStateValue();
    };

    // Search ACTION
    const handleInputChange = (event = "") => {
      event && Utils.preventDefaultFn(event);
      let value = event?.currentTarget?.value || "";
      setSearchInput({
        ...searchInput,
        searchTerm: value,
        isEmailClose: !value?.length < 1
      });
    };

    const performSearch = () => {
      setSearchPerformed(searchInput?.searchTerm ? true : false);
      accountsState.setContactsState({
        searchTerm: searchInput?.searchTerm,
        pageNo: 1
      });
      resetAllStateValue();
    };

    const searchContact = (event = "") => {
      if (!filterTabLoading && !tableLoading) {
        event && Utils.preventDefaultFn(event);
        performSearch();
      }
    };

    const submitForm = (event = "") => {
      if (!filterTabLoading && !tableLoading) {
        event && Utils.preventDefaultFn(event);
        if (event.keyCode === 13) {
          performSearch();
        }
      }
    };

    const clearSearch = () => {
      if (!filterTabLoading && !tableLoading) {
        setSearchInput({ ...defaultSearchInputObj });
        if (searchPerformed) {
          searchPerformed && setSearchPerformed(false);
          accountsState.setContactsState({
            searchTerm: "",
            pageNo: 1
          });
          resetAllStateValue();
        }
      }
    };

    // Contact Card

    const openContactCard = (contact = {}) => {
      if (contact && Object.keys(contact)?.length) {
        setSlideAnim(true);
        setShowContactCard(true);
        setSelecedContact(contact);
      }
    };

    const closeContactCard = () => {
      if (globalCallState.popupValues?.isContactCard) {
        globalCallState.setPopupValues({
          ...globalCallState.popupValues,
          isContactCard: false
        });
      }
      setSlideAnim(false);
      setTimeout(() => {
        setShowContactCard(false);
      }, 300);
    };

    //End Contact card

    const getInit = async () => {
      Utils.mixpanelTrack(MXP_EVENT.ACCOUNTS.CONTACTS_TAB_VIEWED, {
        pageType: PAGE_TYPE["accounts"]
      });
      updateBreadCumb("contacts");
      reloadContactsPage();
    };

    const clearSelectedAccounts = (isClearAccounts = false) => {
      setAllAccountsSelectedCheckbox(!isClearAccounts);
      setAllAccountsSelectedInView(!isClearAccounts);
      if (tableData?.contacts?.length > 0) {
        setTableData({
          ...tableData,
          contacts: [...tableData?.contacts].map(item => ({
            ...item,
            isChecked: !isClearAccounts
          }))
        });
      }
    };

    const getSelectedCount = useCallback(() => {
      if (allAccountsSelectedInView) {
        return tableData?.totalContacts || 0;
      } else {
        if (tableData?.contacts?.length > 0) {
          return [...tableData?.contacts].reduce(
            (count, item) => count + (item?.isChecked ? 1 : 0),
            0
          );
        } else return 0;
      }
    }, [tableData?.contacts]);

    const startSequence = async data => {
      let settingsData = settingsStoreData?.settingsData || {};
      const { title = "", payload = {}, contacts = [] } = data || {};
      const { sequenceId = "", filters = {} } = payload || {};
      let contactTags = filters?.tagIds || [];
      const contactsArr = Utils.getBulkSequencePayload(contacts, true);
      const response = await Utils.scheduleSequenceForContacts(
        settingsData,
        contactsArr,
        sequenceId,
        contactTags
      );
      const {
        data: { responseCode = 500 } = {},
        response: { status = 500 } = {}
      } = response || {};
      if (+responseCode === 200) {
        Utils.mixpanelTrack(MP_EVENT.SQ_STARTED, {
          sequenceName: title,
          emailCount: contacts.length,
          pageType: "Contact"
        });
        toasterState.setToastMsg("Sequence successfully scheduled", "success");
      } else if (+responseCode === 105) {
        SequenceUtils.showNoInboxconnectedPopup();
      } else if (+responseCode === 509) {
        Utils.showContactsUploadLimitReached();
      } else if (+status === 426) {
        Utils.showUpgradePopup("contacts", true, true);
      } else {
        Utils.showApiFailureResponse(response);
      }
      addBulkContactPopupState.setFileName("");
    };

    const clearSelectedContacts = () => {
      setAllAccountsSelectedCheckbox(false);
      setAllAccountsSelectedInView(false);
      setTableData({
        ...tableData,
        contacts: [...tableData?.contacts].map(item => ({
          ...item,
          isChecked: false
        }))
      });
    };

    const skipAndAddContactToSequence = async (data = {}) => {
      setDailyLimitContactData(data);
      setShowMessage(false);
      startSequence(data);
      clearSelectedContacts();
    };

    const resetAllStateValue = () => {
      setAllAccountsSelectedCheckbox(false);
      setAllAccountsSelectedInView(false);
    };

    const closeFieldValidatorPopup = () => {
      setShowMessage(false);
      setFieldErrorDetails("");
      setDailyLimitContactData("");
      contactStoreData.setValidateContactData("");
    };

    // object goes here
    const tabFilterObj = {
      type: "accountContacts",
      filterArr: [...filterTabData],
      navBarLoading: filterTabLoading,
      activeTab: accountsState?.contactsState?.activeTab || "TOTAL",
      filterAccountsCbk: handleTabFilterAction
    };

    const searchObj = {
      isEmailClose: searchInput?.isEmailClose || false,
      contactLimit: accountsState?.contactsState?.limit || 10,
      searchKeyword: searchInput?.searchTerm || "",
      searchContact,
      submitForm,
      handleSearchAction: handleInputChange,
      clearSearch: clearSearch
    };

    const contactTableObj = {
      loader: tableLoading,
      tableData,
      setTableData,
      paginate,
      openContactCard,
      reloadContactsPage,
      allAccountsSelectedCheckbox,
      setAllAccountsSelectedCheckbox,
      allAccountsSelectedInView,
      setAllAccountsSelectedInView
    };

    const contactCardObj = {
      pageType: "Account Contacts Page",
      contact: selectedContact,
      isContactSlider: true,
      isReload: true,
      contactData: tableData,
      isReloadCbk: reloadContactsPage,
      closeCbk: closeContactCard,
      handleSuccessCallback: getTableData,
      setContactData: setTableData
    };

    const headerActionsObj = {
      tableData,
      accountId,
      tableLoading,
      allAccountsSelectedInView,
      getSelectedCount,
      startSequence,
      clearSelectedContacts,
      setDailyLimitContactData,
      setFieldErrorDetails,
      setUploadingText,
      setShowMessage,
      setContactUploadingLoader,
      reloadContactsPage
    };
    // End

    useEffect(() => {
      getFilterTabData();
    }, [accountsState?.contactsState?.searchTerm]);

    useEffect(() => {
      getTableData();
    }, [accountsState?.contactsState]);

    useEffect(() => {
      getInit();
      return () => {
        setSearchInput({ ...defaultSearchInputObj });
        accountsState.setResetContactsState();
        setFilterTabData({});
        setTableData({});
        setSearchPerformed(false);
        setSearchInput({ ...defaultSearchInputObj });
        setTableLoading(true);
        setFilterTabLoading(true);
      };
    }, []);

    return (
      <>
        {showContactUploadingLoader && (
          <ContactUploading msg={uploadingText} title="Add to sequence" />
        )}
        {showMessage && (
          <FieldValidatorMsgPopup
            fieldResponse={fieldErrorDetails}
            close={closeFieldValidatorPopup}
            skipAndAddContactCbk={skipAndAddContactToSequence}
            setSkipLoader={false}
            data={dailyLimitContactData}
            title="Add to sequence"
          />
        )}
        <InsertContactCard
          animate={slideAnim}
          show={showContactCard}
          data={contactCardObj}
        />
        <div className="accountContacts">
          <FilterNavBar {...tabFilterObj} />
          <div className="actionFilterCont">
            <div className="actionLeftCont">
              <SearchByContactKeyword {...searchObj} />
              <div className="accountCount">
                {getSelectedCount() > 0 && (
                  <ContactSelectedCount
                    selectedCount={getSelectedCount()}
                    totalCount={tableData?.totalContacts}
                    loading={tableLoading}
                    allContactSelected={allAccountsSelectedInView}
                    setAllContactSelected={setAllAccountsSelectedInView}
                    resetCbk={() => clearSelectedAccounts(true)}
                    selectAllCbk={() => clearSelectedAccounts(false)}
                    selectedType="Contact"
                  />
                )}
              </div>
            </div>
            <div className="actionRightCont">
              <ContactsHeaderActions {...headerActionsObj} />
            </div>
          </div>
          <ContactsDataTable {...contactTableObj} />
        </div>
      </>
    );
  })
);

export default Main;
