import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImportNow } from "../common/ImportNow";
import FieldMapping from "../common/FieldMapping";
import AddTags from "../common/AddTags";
import AutoImport from "../common/AutoImport";
import UpdateContacts from "../common/UpdateContacts";
import { zohoDataStore } from "./ZohoStoreData";
import ListView from "../common/ListView";

const Leads = observer(props => {
  const { lead = {} } = props;
  const [dropdownWidth, setDropdownWidth] = useState("25%");

  const findWidthOfDropdown = () => {
    return (
      document.querySelector(".crmListView .filterDropdownWrap")?.offsetWidth ||
      "270"
    );
  };

  useEffect(() => {
    zohoDataStore.getLeadsListView();
    setDropdownWidth(findWidthOfDropdown());
    return () => {
      zohoDataStore.setZohoLeadsList("");
    };
  }, []);

  return (
    <div className="leadsWrapper">
      <div className="autoImportSettings">
        <ListView
          config={zohoDataStore.zohoLeadConfig}
          setConfig={zohoDataStore.setZohoLeadConfig}
          list={zohoDataStore?.zohoLeadsList}
          crmName="Zoho"
        />
        <AutoImport
          config={zohoDataStore.zohoLeadConfig}
          setConfig={zohoDataStore.setZohoLeadConfig}
          data={zohoDataStore?.zohoData}
          crmName="Zoho"
        />
        <AddTags
          type="LEADS"
          dropdownWidth={dropdownWidth}
          config={zohoDataStore?.zohoLeadConfig}
          selectedTags={zohoDataStore?.zohoLeadTags}
          setSelectedTags={zohoDataStore.setZohoLeadTags}
        />
        <UpdateContacts
          config={zohoDataStore.zohoLeadConfig}
          setConfig={zohoDataStore.setZohoLeadConfig}
        />
        <ImportNow
          listId={zohoDataStore?.zohoLeadConfig?.sourceId}
          type="LEADS"
          lastSynced={lead?.lastSynced}
          listChanged={zohoDataStore?.zohoLeadConfig?.listChanged}
          crmName="Zoho"
        />
      </div>
      <FieldMapping
        config={zohoDataStore.zohoLeadConfig}
        setConfig={zohoDataStore.setZohoLeadConfig}
        mappingConfig={lead?.fields}
        type="LEADS"
        fields={zohoDataStore?.zohoFields}
        crmName="Zoho"
      />
    </div>
  );
});

export { Leads };
export default Leads;
