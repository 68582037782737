import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { SalesForceOauth } from "Pages/Dashboard/connectApps/salesForceOauth";
import { OutlookOauth } from "Pages/Dashboard/connectApps/outlookOauth";
import { GoogleOAuth } from "Pages/Dashboard/connectApps/GoogleOAuth";
import SlackOauth from "Pages/Dashboard/connectApps/SlackOauth";
import ZohoOauth from "Pages/Dashboard/connectApps/ZohoOauth";
import HubspotOAuth from "Pages/Dashboard/connectApps/hubspotOAuth";
import PipedriveOAuth from "Pages/Dashboard/connectApps/pipedriveOAuth";
import GoogleWarmOAuth from "Pages/Dashboard/connectApps/GoogleWarmOAuth";

const ConnectApps = () => {
  return (
    <Switch>
      <Route path="/connect/gAuth" render={() => <GoogleOAuth />} />
      <Route
        path="/connect/google/warming"
        render={() => <GoogleWarmOAuth />}
      />
      <Route path="/connect/sf/callback" render={() => <SalesForceOauth />} />
      <Route path="/connect/ol/callback" render={() => <OutlookOauth />} />
      <Route path="/connect/sl/callback" render={() => <SlackOauth />} />
      <Route path="/connect/zoho/callback" render={() => <ZohoOauth />} />
      <Route path="/connect/hubspot/callback" render={() => <HubspotOAuth />} />
      <Route
        path="/connect/pipedrive/callback"
        render={() => <PipedriveOAuth />}
      />
      <Redirect from="/connect" to="/connect/gAuth" />
    </Switch>
  );
};

export default withRouter(ConnectApps);
