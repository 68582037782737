import React from "react";
import Lottie from "react-lottie";
import GenerateAILottie from "Utils/LottieFiles/generateAI.json";

const GenerateAISequenceLottie = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: GenerateAILottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div
      className="generateAISeqLottie"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Lottie
        options={defaultOptions}
        width={320}
        height={300}
        style={{ margin: "0" }}
        speed={1.5}
      />
    </div>
  );
};

export { GenerateAISequenceLottie };
export default GenerateAISequenceLottie;
