import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import reportsStore from "../reportsStore";
import AnalyticsCard, { AnalyticsCardLoader } from "./AnalyticsCard";
import SequenceGraphComponent from "./SequenceGraphComponent";
import ReportsActivity from "../activity/ReportsActivity";

const ReportsSequence = observer(() => {
  const [analyticsLoader, setAnalyticsLoader] = useState(false);
  const [updatedAnalyticsData, setUpdatedAnalyticsData] = useState([]);
  const [graphSequenceData, setGraphSequenceData] = useState([]);
  const [sequenceRenderFlag, setSequenceRenderFlag] = useState(false);

  const getSequenceAnalytics = async () => {
    setSequenceRenderFlag(true);
    setAnalyticsLoader(true);
    await reportsStore.fetchSequenceAnalytics();
    setAnalyticsLoader(false);
  };

  const constructAnalyticsData = () => {
    const {
      sequenceAnalyticsData: {
        contactsAddedCount,
        contactedCount,
        emailsReplyCount,
        meetingBookedCount,
        accountsCount,
        accountStageCount
      } = {}
    } = reportsStore || {};

    const tempArr = [
      {
        cardName: "Contacts In Sequences",
        cardCount: contactsAddedCount,
        dataArr: [
          {
            name: "Contacted",
            count: contactedCount || 0,
            color: "#00A358"
          },
          {
            name: "Replied",
            count: emailsReplyCount || 0,
            color: "#F59064"
          },
          {
            name: "Meeting Booked",
            count: meetingBookedCount || 0,
            color: "#FF584E"
          }
        ]
      },
      {
        cardName: "Accounts In Sequences",
        cardCount: accountsCount || 0,
        dataArr: [
          {
            name: "New",
            count: accountStageCount?.["NEW"] || 0,
            color: "#00A358"
          },
          {
            name: "Working",
            count: accountStageCount?.["WORKING"],
            color: "#F59064"
          },
          {
            name: "Meeting Booked",
            count: accountStageCount?.["MEETING_BOOKED"],
            color: "#3953FB"
          }
        ]
      }
    ];
    setUpdatedAnalyticsData(tempArr);
  };

  const constructGraphSequenceData = () => {
    const {
      emailsSentCount = 0,
      emailsOpenCount = 0,
      emailsClickCount = 0,
      callsMadeCount = 0,
      callsLoggedCount = 0,
      callsAnsweredCount = 0,
      tasksCompletedCount = 0,
      totalTasksInDue = 0,
      skippedTaskCount = 0,
      emailsReplyCount = 0
    } = reportsStore?.sequenceAnalyticsData || {};

    const graphData = [
      {
        header: "Emails",
        totalCount: emailsSentCount ?? 0,
        dataArr: [
          { label: "Opened", value: emailsOpenCount ?? 0, color: "#228799" },
          { label: "Clicked", value: emailsClickCount ?? 0, color: "#67BFE5" },
          { label: "Replied", value: emailsReplyCount ?? 0, color: "#4288DD" }
        ]
      },
      {
        header: "Tasks",
        totalCount: tasksCompletedCount + totalTasksInDue + skippedTaskCount,
        dataArr: [
          {
            label: "Tasks Completed",
            value: tasksCompletedCount ?? 0,
            color: "#228799"
          },
          {
            label: "Tasks Overdue",
            value: totalTasksInDue ?? 0,
            color: "#67BFE5"
          },
          {
            label: "Tasks Skipped",
            value: skippedTaskCount ?? 0,
            color: "#4288DD"
          }
        ]
      },
      {
        header: "Calls",
        totalCount: callsMadeCount + callsLoggedCount + callsAnsweredCount,
        dataArr: [
          { label: "Calls Made", value: callsMadeCount ?? 0, color: "#228799" },
          {
            label: "Calls Logged",
            value: callsLoggedCount ?? 0,
            color: "#67BFE5"
          },
          {
            label: "Calls Answered",
            value: callsAnsweredCount ?? 0,
            color: "#4288DD"
          }
        ]
      }
    ];
    setGraphSequenceData(graphData);
  };

  useEffect(() => {
    constructAnalyticsData();
    constructGraphSequenceData();
  }, [reportsStore?.sequenceAnalyticsData]);

  useEffect(() => {
    if (sequenceRenderFlag && !analyticsLoader) {
      getSequenceAnalytics();
    }
  }, [reportsStore?.dateFilter, reportsStore?.memberIds]);

  useEffect(() => {
    getSequenceAnalytics();
    reportsStore.setTabType("sequence");
    return () => {
      reportsStore.resetReports();
      setSequenceRenderFlag(false);
    };
  }, []);

  return (
    <div
      className={`reportsSequence ${
        reportsStore?.newVersionReportsFlag ? "switchVersionDisplay" : ""
      } `}
    >
      <div className="analyticsCardCont">
        {!analyticsLoader ? (
          updatedAnalyticsData?.length > 0 &&
          updatedAnalyticsData.map((item, index) => {
            return <AnalyticsCard key={index} {...item} />;
          })
        ) : (
          <AnalyticsCardLoader />
        )}
      </div>
      <SequenceGraphComponent
        loader={analyticsLoader}
        graphDataArr={graphSequenceData}
      />
      <ReportsActivity tabType="Sequence" singleTabName="Sequence Overview" />
    </div>
  );
});

export default ReportsSequence;
export { ReportsSequence };
