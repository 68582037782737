import React from "react";

const ConnectedLinkedInLoader = props => {
  const { count = 1 } = props || {};

  return Array.from({ length: count }, (value, index) => (
    <div
      className="connectedLinkedInCard inboxRowloader"
      key={`inboxRowloader${index}`}
    >
      <div className="profileInformation">
        <div className="leftLayout">
          <div className="imgType linear-background" />
        </div>
        <div className="wrapConnectedEmail">
          <div className="userName linear-background" />
          <div className="connectedEmail linear-background" />
        </div>
      </div>
      <div className="warmerMetricsWrapper linear-background" />
      <div className="btnInboxActions">
        <div className="configureBtn linear-background" />
        <div className="deleteInboxIcon linear-background" />
      </div>
    </div>
  ));
};

export { ConnectedLinkedInLoader };
export default ConnectedLinkedInLoader;
