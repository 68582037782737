import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Tooltip from "Components/common/Tooltip";
import filterComponentState from "./filterComponentState";

const AddRemoveValue = observer(props => {
  const {
    defaultValue = [],
    store = [],
    id = "",
    setTableLoading = () => {}
  } = props || {};

  const [updatedDefaultValue, setUpdatedDefaultValue] = useState(defaultValue);

  const onCloseIconClick = item => {
    let tempData = [...filterComponentState?.selectedFilteredList?.[id]];
    const updatedSelectedFilterData = tempData.filter(filterData => {
      return filterData?.id !== item?.id;
    });
    filterComponentState.setSelectedFilteredList({
      ...filterComponentState.selectedFilteredList,
      [id]: updatedSelectedFilterData
    });
    const updatedFilteredData = store?.filterComponentData?.[id].filter(
      filterData => filterData !== item?.id
    );
    store.setFilterComponentData({
      ...store.filterComponentData,
      [id]: updatedFilteredData
    });
  };

  const handleClearAll = () => {
    filterComponentState.setSelectedFilteredList({
      ...filterComponentState.selectedFilteredList,
      [id]: []
    });
    filterComponentState.setFilterComponentData({
      ...filterComponentState.filterComponentData,
      [id]: []
    });
  };

  useEffect(() => {
    setUpdatedDefaultValue(defaultValue);
  }, [defaultValue]);

  return (
    updatedDefaultValue?.length > 0 && (
      <section className="addRemoveValue">
        <span className="horizontalBorderLine" />
        <div className="flexCenterWithJustifyContent">
          <div className="addRemoveValueWrapper">
            <div className="addRemoveValueCont">
              {updatedDefaultValue?.map(item => {
                const value =
                  id === "contactStages"
                    ? item?.displayName
                    : id === "tagIds"
                    ? item?.value
                    : item?.name;
                return (
                  <div className="tagItem" key={value}>
                    <span className="selectedTag">{value}</span>
                    <i
                      className="material-icons selectedTagCloseIcon"
                      onClick={() => onCloseIconClick(item)}
                    >
                      close
                    </i>
                  </div>
                );
              })}
              {filterComponentState?.selectedFilteredList?.[id]?.length >= 5 &&
                id !== "contactField" && (
                  <div className="maxValueReached">
                    {"Maximum limit reached"}
                  </div>
                )}
            </div>
          </div>

          <div className="clearAll">
            <Tooltip tooltip="Clear all" placement="left">
              <i
                className="material-icons clearAllIcon"
                onClick={handleClearAll}
              >
                clear_all
              </i>
            </Tooltip>
          </div>
        </div>
      </section>
    )
  );
});

export { AddRemoveValue };
export default AddRemoveValue;
