/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter, useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { TemplateDataStore } from "Stores/Template";
import TemplateTableComponent from "./TemplateTable";
import TemplateEmptyComponent from "Pages/Dashboard/templates/TemplateEmpty";
import TemplatePopupComponent from "./TemplatePopup";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import TemplateOverviewLoader from "Pages/Dashboard/templates/TemplateOverviewLoader";
import PaginationComponent from "Components/common/PaginationComponent";
import Utils from "Utils/utils";
import NoSharedEntity from "Components/common/NoSharedEntity";
import ConstantObj from "Static/constant";
import { writingAssistantStoreData } from "Stores/WritingAssistantData";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";

let totalPages;
const TemplateOverViewComponent = props => {
  const { sharedTemplates } = props;
  let { id } = useParams(),
    limit = 10,
    defaultPageNo = 1;

  const [loader, setLoader] = useState(true),
    [templateData, setTemplateData] = useState(TemplateDataStore.templateData),
    [templateList, setTemplateList] = useState(TemplateDataStore.templateList),
    [showTemplatePopup, setTemplatePopup] = useState(false),
    [paginationLoader, setPaginationLoader] = useState(true),
    [title, setTitle] = useState("New");
  const [editTemplateLoader, setEditTemplateLoader] = useState(false);
  const [showNoSharedTemplates, setShowNoSharedTemplates] = useState(false);
  const [sortIconStatus, setSortIconStatus] = useState("DESC");
  const [sortParam, setSortParam] = useState("NAME");
  const [currentSortedType, setCurrentSortedType] = useState("ASC");

  const templateHeader = [
    { key: "name", name: "Name", className: "template-name" },
    { key: "subject", name: "Subject", className: "template-subject" },
    { key: "created", name: "Created", className: "template-created" },
    { key: "updated", name: "Updated", className: "template-updated" },
    { key: "options", name: "", className: "template-options" }
  ];

  const getTemplates = () => {
    setLoader(true);
    if (
      TemplateDataStore.folderId !== "" &&
      TemplateDataStore.folderId !== "all"
    ) {
      TemplateDataStore.getFolderDetails(TemplateDataStore.folderId);
    } else {
      TemplateDataStore.folderName = "My Templates";
    }
    let payload = {
      pageNo: defaultPageNo,
      limit
    };
    if (id !== undefined) {
      payload.folderId = TemplateDataStore.folderId;
    }
    payload = getSortPayload(payload);
    TemplateDataStore.getAllTemplates(payload);
  };

  const getSharedTemplates = () => {
    let payload = {
      pageNo: defaultPageNo,
      limit
    };
    const sharedTemplatesCbk = flag => {
      if (!flag) {
        setShowNoSharedTemplates(true);
      } else {
        setShowNoSharedTemplates(false);
      }
    };
    payload = getSortPayload(payload);
    TemplateDataStore.getSharedTemplates(payload, sharedTemplatesCbk);
  };

  const updateTemplatesData = data => {
      if (data && Object.keys(data).length > 0 && data?.statusCode !== 404) {
        totalPages = data.totalPages ? data.totalPages : 1;
        setTemplateData(JSON.parse(JSON.stringify(data)));
        setLoader(false);
        setPaginationLoader(false);
      } else if (data?.statusCode === 404) {
        props.history.push("all");
      }
    },
    updateTemplateList = data => {
      if (data) {
        setTemplateList(JSON.parse(JSON.stringify(data)));
        setLoader(false);
        setPaginationLoader(false);
      }
    },
    paginate = (pageNoObj, limitParam = limit) => {
      let payload = {};
      setLoader(true);
      let pageNo = pageNoObj.selected ? pageNoObj.selected + 1 : 1;
      payload.pageNo = pageNo;
      payload.limit = limitParam;
      if (id !== undefined) {
        payload.folderId = TemplateDataStore.folderId;
      }
      payload = getPaginateSortPayload(payload);
      if (sharedTemplates) {
        TemplateDataStore.getSharedTemplates(payload);
      } else {
        TemplateDataStore.getAllTemplates(payload);
      }
    },
    viewTemplatePopup = text => {
      setTitle(text);
      checkForEligibleTemplate();
    },
    editTemplate = (value, text) => {
      TemplateDataStore.setOverviewSelectedTemplate(
        JSON.parse(JSON.stringify(value))
      );
      setTitle(text);
      setTemplatePopup(true);
    },
    cloneTemplate = (template, templateShareType) => {
      const { folderId, name, subject, content, attachments } = template;
      let payload = {};
      if (folderId && !sharedTemplates) {
        payload.folderId = folderId;
      }
      payload.parent = "";
      payload.templates = [
        {
          name: `${name}`,
          subject,
          content,
          shareType: templateShareType
            ? Utils.getSequenceShareType(templateShareType)
            : ConstantObj.sequenceType.private,
          accessType: templateShareType
            ? Utils.getSequenceType(templateShareType)
            : ConstantObj.sequenceType.shared.rw,
          attachments
        }
      ];
      TemplateDataStore.saveTemplates(payload, false);
    },
    confirmDelete = async tId => {
      TemplateDataStore.templateId = tId;
      confirmationPopupState.setPopupValues({
        title: "Are you sure?",
        content:
          "Are you sure you want to delete this template? <br/>The changes you made will be lost.",
        actionBtnText: "Yes",
        callback: deleteTemplate,
        loadingMsg: "Deleting template. Please wait..."
      });
      confirmationPopupState.setShowPopup(true);
    },
    deleteTemplate = async buttonLoadingFn => {
      setLoader(true);
      TemplateDataStore.deleteTemplate(deletedConfirmation, buttonLoadingFn);
      setLoader(false);
    },
    deletedConfirmation = buttonLoadingFn => {
      buttonLoadingFn(false);
      confirmationPopupState.setShowPopup(false);
    },
    closeTemplatePopup = text => {
      setTitle(text);
      TemplateDataStore.setOverviewSelectedTemplate("");
      writingAssistantStoreData.resetSubjectLinesGenerator();
      setTemplatePopup(false);
    };

  const changeShareType = async (
    selectedTemplate,
    newShareType,
    newTemplateName,
    closeTemplateEditOverlay
  ) => {
    const templateUpdateSuccessCbk = (flag, data) => {
      closeTemplateEditOverlay();
      const duplicateTemplateData = JSON.parse(JSON.stringify(templateList));
      duplicateTemplateData.map((templateObj, key) => {
        if (templateObj && data && templateObj.id === data.id) {
          templateObj.accessType = data.accessType;
          templateObj.shareType = data.shareType;
          templateObj.name = data.name;
        }
      });
      setEditTemplateLoader(false);
      setTemplateList(duplicateTemplateData);
    };

    let {
      id,
      createTs,
      updateTs,
      status,
      memberId,
      subject,
      content,
      folderId,
      shareType,
      accessType,
      name,
      attachments
    } = TemplateDataStore?.overviewSelectedTemplate || {};
    let payload = {
      id,
      name: newTemplateName ? newTemplateName : name,
      subject,
      content,
      createTs,
      updateTs,
      folderId: folderId,
      memberId,
      attachments,
      shareType: newShareType
        ? Utils.getSequenceShareType(newShareType)
        : shareType
        ? shareType
        : ConstantObj.sequenceType.private,
      accessType: newShareType
        ? Utils.getSequenceType(newShareType)
        : accessType
        ? accessType
        : ConstantObj.sequenceType.shared.rw,
      status
    };
    await TemplateDataStore.updateTemplates(payload, templateUpdateSuccessCbk);
  };

  const sortByTemplateName = (sortParam, toSort) => {
    setLoader(true);
    setSortParam(sortParam);
    setCurrentSortedType(toSort);

    const sortPayload = {
      direction: toSort,
      sortParam
    };

    const payload = {
      pageNo: defaultPageNo,
      limit: limit
    };

    if (id !== undefined) {
      payload.folderId = TemplateDataStore.folderId;
    }

    payload.sortPayload = sortPayload;

    const resCbk = () => {
      if (toSort === "ASC") {
        setSortIconStatus("DESC");
      } else if (toSort === "DESC") {
        setSortIconStatus("ASC");
      }
      setLoader(false);
    };

    if (sharedTemplates) {
      TemplateDataStore.getSharedTemplates(payload, resCbk);
    } else {
      TemplateDataStore.getAllTemplates(payload, resCbk);
    }
  };

  const getSortPayload = payload => {
    if (currentSortedType && sortParam) {
      const sortPayload = {
        direction: currentSortedType,
        sortParam
      };
      payload.sortPayload = sortPayload;
    }
    return payload;
  };

  const getPaginateSortPayload = payload => {
    if (currentSortedType && sortParam) {
      const sortPayload = {
        direction: currentSortedType,
        sortParam
      };
      payload.sortPayload = sortPayload;
    }
    return payload;
  };

  const checkForEligibleTemplate = () => {
    if (Utils.checkEligibleTemplateCount()) {
      setTemplatePopup(true);
    } else {
      setTemplatePopup(false);
      Utils.showUpgradePopup("templates", true, true);
    }
  };

  useEffect(() => {
    setLoader(true);
    updateTemplatesData(TemplateDataStore.templateData);
  }, [TemplateDataStore.templateData]);

  useEffect(() => {
    setLoader(true);
    updateTemplateList(TemplateDataStore.templateList);
  }, [TemplateDataStore.templateList]);

  useEffect(() => {
    setLoader(true);
    setShowNoSharedTemplates(false);
    TemplateDataStore.folderId = id !== undefined ? id : "all";
    if (id !== undefined) {
      TemplateDataStore.getFolderDetails(id);
      getTemplates();
    } else if (sharedTemplates) {
      getSharedTemplates();
    } else {
      getTemplates();
    }
  }, [useLocation()]);

  useEffect(() => {
    if (
      !upgradePopupState.showPopup &&
      upgradePopupState.isTemplateEditor &&
      showTemplatePopup
    ) {
      closeTemplatePopup();
      upgradePopupState.setIsTemplateEditor(false);
    }
  }, [upgradePopupState.showPopup]);

  return (
    <>
      {showTemplatePopup && (
        <TemplatePopupComponent
          cbk={closeTemplatePopup}
          title={title || TemplateDataStore?.overviewSelectedTemplate?.title}
          value={TemplateDataStore?.overviewSelectedTemplate}
          setSelectedTemplateFn={TemplateDataStore.setOverviewSelectedTemplate}
          openPopup={() => setTemplatePopup(true)}
        />
      )}
      <div className="template-overview-section">
        {!loader ? (
          templateList?.length > 0 ? (
            <>
              <TemplateTableComponent
                templates={templateList}
                editCbk={editTemplate}
                cloneCbk={cloneTemplate}
                deleteCbk={confirmDelete}
                total={templateData?.totalTemplates}
                setTemplateList={setTemplateList}
                changeShareType={changeShareType}
                editTemplateLoader={editTemplateLoader}
                setEditTemplateLoader={setEditTemplateLoader}
                sortIconStatus={sortIconStatus}
                sortByTemplateName={sortByTemplateName}
                templateHeader={templateHeader}
              />
              <PaginationComponent
                callback={paginate}
                loading={paginationLoader}
                totalPages={totalPages}
                currentPage={templateData?.currentPage || 1}
              />
            </>
          ) : showNoSharedTemplates ? (
            <NoSharedEntity title={"No shared templates found"} />
          ) : (
            !templateList?.length && (
              <TemplateEmptyComponent cbk={viewTemplatePopup} />
            )
          )
        ) : (
          <TemplateOverviewLoader templateHeader={templateHeader} />
        )}
      </div>
    </>
  );
};

export default withRouter(observer(TemplateOverViewComponent));
export { TemplateOverViewComponent };
