/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { withRouter, NavLink, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { TutorialSlider } from "Components/tutorial/TutorialSlider";
import { toasterState } from "Components/common/toaster";
import { teamInvitedStore } from "Stores/TeamInvitedData";
import { onboardingStoreData } from "Stores/onBoardingData";
import userDetail from "Stores/userDetail";
import { MP_EVENT } from "Static/constant";
import {
  getFeatureFlagWithTeam,
  checkTeamStatus,
  fetchConnectedEmails
} from "Utils/commonAPI";
import Utils from "Utils/utils";
import CardTooltip from "./cardTooltip";
import OnboardingComponent from "Components/onBoarding/OnBoarding";
import { getSideBarList } from "./icons";
import { outboxStoreData } from "Pages/Dashboard/outbox/outboxStore";

const SideBar = () => {
  let location = useLocation();
  const {
    team = {},
    email = "",
    isOnBoardingCompleted = ""
  } = userDetail.userInfo || {};
  const {
    subscriptionPlanId = "",
    taskEnabled = false,
    dialerEnabled = false,
    searchEnabled = false,
    newReportsEnabled = false
  } = userDetail?.userFeatureFlag || {};

  let taskPage = Utils.checkLocationPathname(location.pathname, "/tasks");
  let callPage = Utils.checkLocationPathname(location.pathname, "/calls");
  let dataSearchPage = Utils.checkLocationPathname(
    location.pathname,
    "/discover"
  );
  /* -------- Hotjar Code Starts --------- */

  var userId = email || null;

  window.hj("identify", userId, {
    email
  });

  /* -------- Hotjar Code Ends --------- */

  const [adminTeamUser, setAdminTeamUser] = useState(team);
  const [sideBarList, setSideBarList] = useState(getSideBarList());
  const [sideNavMenu, setNavMenu] = useState(sideBarList);
  const [showTutorial, setShowTutorial] = useState(false);
  const notificationFlag = localStorage.getItem("TutotialNotificationClicked");
  const [tutorialNotification, setTutorialNotification] = useState(
    notificationFlag !== "true"
  );
  const [onBoardingFlagValues, setOnBoardingFlagValues] = useState(
    onboardingStoreData.onBoardingFlags
  );
  const [remainigFeatureCount, setRemainingFeatureCount] = useState(0);
  const [isOnboardingCompleted, setOnboardingCompleted] = useState(true);

  const toggleTutorial = () => {
    Utils.mixpanelTrack(MP_EVENT.NAV_TUTORIAL_CLICKED);
    localStorage.setItem("TutotialNotificationClicked", true);
    setTutorialNotification(false);
    setShowTutorial(!showTutorial);
  };

  const acceptInvite = async buttonLoadingFn => {
    try {
      const token = teamInvitedStore?.teamInvitedData?.token;
      const { email: userEmail = "" } = userDetail?.userInfo || {};
      const response = await teamInvitedStore.acceptInvite(userEmail, token);
      buttonLoadingFn(false);
      confirmationPopupState.setShowPopup(false);
      if (response) {
        const respData = response?.data || {};
        if (respData?.status === "success") {
          handleAcceptInviteSuccess();
        } else {
          Utils.showApiFailureResponse(response);
        }
      } else {
        Utils.showApiFailureResponse(response);
      }
    } catch (err) {
      console.error(
        "Team invitation accept error from confirmation popup",
        err
      );
    }
  };

  const updateFeatureFlags = async () => {
    let userData = {};
    let userFlags = await getFeatureFlagWithTeam();
    userData.email = userFlags.email;
    userData.userLoggedIn = true;
    userData.team = await checkTeamStatus();
    userDetail.setUserFeatureFlag(userFlags);
    userDetail.setUserInfo(userData);
  };

  const rejectInvite = async buttonLoadingFn => {
    try {
      const response = await teamInvitedStore.rejectInvite();
      buttonLoadingFn(false);
      confirmationPopupState.setShowPopup(false);
      if (response) {
        const respData = response?.data;
        if (respData && respData?.success) {
          handleRejectInviteSuccess();
        } else {
          Utils.showApiFailureResponse(response);
        }
      } else {
        Utils.showApiFailureResponse(response);
      }
    } catch (err) {
      console.error(
        "Team invitation reject - error from confirmation popup",
        err
      );
    }
  };

  const handleRejectInviteSuccess = () => {
    const {
      adminEmail = "",
      adminFirstName = "",
      adminLastName = ""
    } = teamInvitedStore.teamInvitedData || {};
    updateFeatureFlags();
    toasterState.setToastMsg(
      `You have rejected the invitation to join ${getAdminName(
        adminFirstName,
        adminLastName,
        adminEmail
      )} team`,
      "success"
    );
  };

  const handleAcceptInviteSuccess = () => {
    updateFeatureFlags();
    const {
      adminEmail = "",
      adminFirstName = "",
      adminLastName = ""
    } = teamInvitedStore.teamInvitedData || {};
    toasterState.setToastMsg(
      `You have successfully joined ${getAdminName(
        adminFirstName,
        adminLastName,
        adminEmail
      )} team`,
      "success"
    );
  };

  const triggerInvitedPopup = (invitedDetails = {}) => {
    const {
      memberInvited = false,
      adminFirstName = "",
      adminLastName = "",
      adminEmail = ""
    } = invitedDetails || {};
    if (memberInvited) {
      confirmationPopupState.setPopupValues({
        content: getPopupContent(adminFirstName, adminLastName, adminEmail),
        actionBtnText: "Accept Invitation",
        cancelBtnText: "Reject Invitation",
        cancelCbk: rejectInvite,
        callback: acceptInvite,
        cancelLoadingMsg: "Rejecting invitation...",
        loadingMsg: "Accepting invitation..."
      });

      confirmationPopupState.setShowPopup(true);
    }
  };

  const getPopupContent = (
    adminFirstName = "",
    adminLastName = "",
    adminEmail = ""
  ) => {
    return `You have been invited to join ${getAdminName(
      adminFirstName,
      adminLastName,
      adminEmail
    )} team account. Please accept the invite before proceeding`;
  };

  const getAdminName = (
    adminFirstName = "",
    adminLastName = "",
    adminEmail = ""
  ) => {
    let adminName = "";
    if (adminFirstName && adminLastName) {
      adminName += `${adminFirstName} ${adminLastName}`;
    } else if (adminFirstName) {
      adminName += adminFirstName;
    } else if (adminLastName) {
      adminName += adminLastName;
    } else if (adminEmail) {
      adminName = adminEmail;
    }
    return adminName;
  };

  const mouseEnter = (e, name = "") => {
    const tempArr = newReportsEnabled
      ? [...sideBarList].map(item =>
          item.path === "/reports" ? { ...item, path: "/reports-v2" } : item
        )
      : [...sideBarList];
    tempArr.map(value => {
      if (value && value.name && value.name === name) {
        value.showCard = true;
      } else {
        value.showCard = false;
      }
    });
    setNavMenu(tempArr);
  };

  const mouseLeave = () => {
    const tempArr = newReportsEnabled
      ? [...sideBarList].map(item =>
          item.path === "/reports" ? { ...item, path: "/reports-v2" } : item
        )
      : [...sideBarList];
    tempArr.map(value => {
      if (value?.name) {
        value.showCard = false;
      }
    });
    setNavMenu(tempArr);
  };

  const getClassName = (name = "") => {
    return `${name} ${
      name === "dashboard" &&
      isOnBoardingCompleted !== "" &&
      isOnBoardingCompleted
        ? "d-none"
        : ""
    } ${name === "team" && !adminTeamUser ? "d-none" : ""} ${
      name === "pricing" && subscriptionPlanId && !adminTeamUser ? "d-none" : ""
    }`;
  };

  const constructOnboardingData = flagValues => {
    const { isSequenceCreated, isEmailAccountConnected, isContactsAdded } =
      flagValues || {};
    const tempFlagObj = {};
    tempFlagObj.isSequenceCreated = isSequenceCreated;
    tempFlagObj.isEmailAccountConnected = isEmailAccountConnected;
    tempFlagObj.isContactsAdded = isContactsAdded;
    calculateRemainingCount(tempFlagObj);
    setOnBoardingFlagValues(flagValues);
    setOnboardingCompleted(flagValues.isOnboardingCompleted);
  };

  const calculateRemainingCount = flagObj => {
    if (flagObj && Object.keys(flagObj)?.length) {
      const remainingCount = Object.values(flagObj).reduce(
        (a, item) => a + (item === false ? 1 : 0),
        0
      );
      setRemainingFeatureCount(remainingCount);
    }
  };

  const getClassByFeatureFlags = () => {
    return (taskPage && !taskEnabled) ||
      (callPage && !dialerEnabled) ||
      (dataSearchPage && !searchEnabled)
      ? "adjustZindex"
      : "";
  };

  useEffect(() => {
    if (onboardingStoreData?.onBoardingFlags?.isSequenceCreated != null) {
      constructOnboardingData(onboardingStoreData.onBoardingFlags);
    }
  }, [onboardingStoreData.onBoardingFlags]);

  useEffect(() => {
    fetchConnectedEmails();
    teamInvitedStore.getInvitedDetails();
  }, []);

  useEffect(() => {
    setAdminTeamUser(userDetail?.userInfo?.team);
  }, [userDetail.userInfo]);

  useEffect(() => {
    if (teamInvitedStore?.teamInvitedData?.memberInvited) {
      triggerInvitedPopup(teamInvitedStore?.teamInvitedData);
    }
  }, [teamInvitedStore.teamInvitedData]);

  useEffect(() => {
    const tempSideBarList = getSideBarList();
    setSideBarList(tempSideBarList);
    setNavMenu(tempSideBarList);
  }, [outboxStoreData?.unreadOutboxCount]);

  return (
    <>
      <div className={`sideBar ${getClassByFeatureFlags()}`}>
        {showTutorial && <TutorialSlider close={toggleTutorial} />}
        <div className="menuList">
          {sideNavMenu.map(item => {
            const { path = "", name = "", showCard = false } = item || {};
            return (
              <NavLink
                to={path}
                key={name}
                activeClassName="selected-path"
                className={`${getClassName(name)} headerIcons`}
                onMouseEnter={e => name !== "dashboard" && mouseEnter(e, name)}
                onMouseLeave={e => name !== "dashboard" && mouseLeave()}
              >
                <div className="svgIcon" id={name}>
                  {item?.icon}
                </div>
                {showCard && <CardTooltip name={name} {...item} />}
              </NavLink>
            );
          })}
        </div>
        <div className="bottomNav">
          {(remainigFeatureCount > 0 || !isOnboardingCompleted) && (
            <OnboardingComponent
              onBoardingFlagValues={onBoardingFlagValues}
              remainigFeatureCount={remainigFeatureCount}
              isOnboardingCompleted={isOnboardingCompleted}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(observer(SideBar));
