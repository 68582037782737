/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import RadioButton from "Components/common/radioBtn";
import { STRIPE_KEY, EMAIL_VALIDATION_CREDITS_PRICE } from "Static/constant";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Utils from "Utils/utils";

const stripePromise = loadStripe(STRIPE_KEY);

const defaultPopupValue = {
  type: ""
};

class BuyCreditsPopupState {
  @observable showPopup = false;
  @observable newPayment = false;
  @observable popupValue = defaultPopupValue;
  @observable callBack = () => {};

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setCallBack = val => {
    this.callBack = val;
  };

  @action
  setNewPayment = val => {
    this.newPayment = val;
  };

  @action
  setPopupValue = (val = {}) => {
    this.popupValue = val;
  };
}

const buyCreditsPopupState = new BuyCreditsPopupState();

const BuyCreditsPopup = observer(props => {
  const [amount, setAmount] = useState(25);
  const [stripetoken, setStripeToken] = useState("");

  const closeAction = () => {
    setAmount(25);
    setStripeToken("");
    buyCreditsPopupState.setNewPayment(false);
    buyCreditsPopupState.setPopupValue(defaultPopupValue);
    buyCreditsPopupState.setShowPopup(false);
  };

  const purchaseCredits = () => {
    if (buyCreditsPopupState.callBack) {
      if (buyCreditsPopupState.newPayment) {
        document.querySelector(".newCardDetails .stripeElem").blur();
        setTimeout(() => {
          if (stripetoken) {
            buyCreditsPopupState.callBack(amount, stripetoken);
            closeAction();
          }
        }, 1000);
      } else {
        buyCreditsPopupState.callBack(amount);
        closeAction();
      }
    }
  };

  const cardContentList = {
    EV_CREDITS: {
      title: "Buy Email Validation Credits",
      description: `You will be charged $${amount} and ${Utils.formatNumberToString(
        amount / EMAIL_VALIDATION_CREDITS_PRICE
      )} email validation credits will be added to your account`,
      btnText: "Purchase Credits",
      btnInfoText: ""
    },
    DIALER: {
      title: "Buy Phone Credits",
      description: `Recharge Amount`,
      btnText: "Purchase Phone Credits",
      btnInfoText: `You’ll be charged $${amount}`
    }
  };

  return buyCreditsPopupState.showPopup ? (
    <div className="buycreditsPopup popupWrapper">
      <div className="confirmation-popup common-popup">
        <div className="buyCreditsWrapper popup-wrap animated customzoomIn">
          <div className="creditsCont">
            <div className="popupHeader">
              <div className="popupTitle">
                {cardContentList?.[buyCreditsPopupState?.popupValue?.type]
                  ?.title || "Buy Credits"}
              </div>
              <div
                className="popup-close"
                title="Click here to close"
                onClick={closeAction}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
            <div className="popupBody">
              <div className="rechargeAmountWrapper">
                <RechargeAmount
                  text={
                    cardContentList[buyCreditsPopupState?.popupValue?.type]
                      ?.description || ""
                  }
                  setAmount={setAmount}
                  {...buyCreditsPopupState?.popupValue}
                />
              </div>
              {buyCreditsPopupState.newPayment && (
                <div className="cardDetailsWrapper">
                  <div className="newCardDetails">
                    <NewPaymentForm setStripeToken={setStripeToken} />
                  </div>
                </div>
              )}
              <div className="actionWrapper">
                <FooterData
                  {...(cardContentList?.[
                    buyCreditsPopupState?.popupValue?.type
                  ] || {})}
                  amount={amount}
                  purchaseCreditsCbk={purchaseCredits}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

const RechargeAmount = props => {
  const { text = "", setAmount = () => {} } = props || {};

  const rechargePack = [10, 25, 50, 100];

  const radioOnchangeAction = (e, value = 0) => {
    value && setAmount(value);
  };

  return (
    <div className="rechargeAmountCont">
      {text && <span className="rechargeTitle">{text}</span>}
      <div className="amountData">
        {(rechargePack?.length &&
          rechargePack.map((item, index) => {
            return (
              <div className="rechargeCard" key={`rechargeData${item + index}`}>
                <RadioButton
                  id={item}
                  value={`$${item}`}
                  name="rechargeAmount"
                  defaultChecked={index === 1}
                  radioOnchange={radioOnchangeAction}
                />
              </div>
            );
          })) ||
          ""}
      </div>
    </div>
  );
};

const FooterData = props => {
  const {
    btnText = "",
    btnInfoText = "",
    purchaseCreditsCbk = () => {}
  } = props || {};

  return (
    <div className="footerWrapper">
      <div className="actionBtn">
        <div className="btn purchaseCredits" onClick={purchaseCreditsCbk}>
          {btnText}
        </div>
      </div>
      {btnInfoText && (
        <div className="selectedChargeCont">
          <div className="chargeText">{btnInfoText}</div>
        </div>
      )}
    </div>
  );
};

const NewPaymentForm = props => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm {...props} />
    </Elements>
  );
};

const PaymentForm = props => {
  const { setStripeToken = "" } = props || {};
  const [cardErr, setCardErr] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const onBlurAction = async value => {
    const card = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(card, {});
    if (!error && token?.id) {
      setStripeToken(token.id);
      setCardErr("");
    } else if (error && error.message) {
      setCardErr(error.message);
    }
  };

  const focus = instance => {
    instance && instance.focus();
  };

  return (
    <div className="cardWrapper">
      <CardElement
        className="stripeElem"
        onBlur={onBlurAction}
        onReady={focus}
      />
      <span className="errMsgText">{cardErr}</span>
    </div>
  );
};

export default BuyCreditsPopup;
export { BuyCreditsPopup, buyCreditsPopupState };
