import React, { useState } from "react";
import CheckBox from "Components/common/CheckBox";
import DropdownButton from "./DropdownButton";
import ActionButton from "./ActionButton";
import { ContactCardFunction } from "Components/ContactCard/ContactCardFunction";

export const MultiSelectDropdown = (props = {}) => {
  const {
    label = "",
    keyValue = "",
    placeholder = "",
    data = [],
    activeFilter = "",
    selected = [],
    setAction = () => {},
    setActiveFilter = () => {},
    selectedFilterCbk = () => {},
    showSelectedList = true,
    showEmptyText = false
  } = props || {};

  let toggleDropdown = activeFilter === keyValue;

  const [searchInput, setSearchInput] = useState("");
  const [showList, setShowList] = useState(true);
  const [expandCollapse, setExpandCollapse] = useState(false);

  const handleSearchInput = value => {
    if (keyValue === "owners") {
      let tempData = [...data];
      let count = 0;
      tempData.map(item => {
        let val = ContactCardFunction.getName(item)
          ?.toString()
          ?.toLowerCase()
          ?.includes(value?.toLowerCase());
        val === false && count++;
        item["show"] = val;
        return item;
      });
      setShowList(count !== tempData?.length);
      setAction(keyValue, tempData);
      setSearchInput(value);
    } else {
      let tempData = [...data];
      let count = 0;
      tempData.map(item => {
        let val = item?.["value"]
          ?.toString()
          ?.toLowerCase()
          ?.includes(value?.toLowerCase());
        val === false && count++;
        item["show"] = val;
        return item;
      });
      setShowList(count !== tempData?.length);
      setAction(keyValue, tempData);
      setSearchInput(value);
    }
  };

  const handleSelectAction = (checked = "", uId = "", name = "") => {
    if (checked) {
      selectedFilterCbk("add", keyValue);
    } else {
      selected?.length === 1 && selectedFilterCbk("remove", keyValue);
    }
    let tempData = [...data];
    tempData = tempData.map(item => {
      const { id = "" } = item || {};
      if (id === uId) {
        item.checked = checked;
      }
      return item;
    });
    setAction(keyValue, tempData);
  };

  const handleReset = () => {
    let tempData = [...data];
    tempData.map(item => {
      item.checked = false;
      return item;
    });
    selectedFilterCbk("remove", keyValue);
    setAction(keyValue, tempData);
  };

  const getLabelText = (item = {}) => {
    const { value = "" } = item || {};
    return keyValue === "owners" ? ContactCardFunction.getName(item) : value;
  };

  return (
    <div className="multiSelectDropdown" id={keyValue}>
      {showSelectedList && (
        <DropdownButton
          label={label}
          count={selected?.length}
          keyValue={keyValue}
          toggleDropdown={toggleDropdown}
          callback={setActiveFilter}
        />
      )}
      {toggleDropdown && data?.length > 0 && (
        <div className="dropdownSearchBox">
          <span className="horizontalBorderLine" />
          <div className="searchBox">
            <div
              className={`searchInputList ${
                searchInput?.length > 0 ? "twoColLayout" : ""
              }`}
            >
              <input
                type="text"
                className="inputBox"
                autoFocus
                onChange={e => handleSearchInput(e.currentTarget.value)}
                value={searchInput}
                placeholder={placeholder}
              />
              {searchInput?.length > 0 && (
                <i
                  className="material-icons closeIcon"
                  onClick={() => handleSearchInput("")}
                >
                  close
                </i>
              )}
            </div>
            {(showList && searchInput?.length && data?.length ? (
              <div className="dropdownList">
                {data.map((item, index) => {
                  const { id = "", checked = false, show = "" } = item || {};
                  if (typeof show === "string" || show) {
                    return (
                      <div className="dropdownItems" key={id + index}>
                        <CheckBox
                          key={id}
                          name={getLabelText(item)}
                          id={id}
                          checked={checked}
                          cbk={handleSelectAction}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              searchInput?.length > 0 &&
              showEmptyText && (
                <div className="dropdownList">
                  <span className="noSequencesMsg">No data available</span>
                </div>
              )
            )) || ""}
          </div>
          {(showSelectedList && selected?.length && (
            <>
              <div className="selectedItems">
                {selected.map((item, index) => {
                  const { id = "", name = "" } = item || {};
                  if (index < 2 || expandCollapse) {
                    return (
                      <div className="chip" key={id}>
                        <span className="name">{getLabelText(item)}</span>
                        <i
                          className="material-icons closeIcon"
                          onClick={() => handleSelectAction(false, id, name)}
                        >
                          close
                        </i>
                      </div>
                    );
                  }
                })}
                {selected?.length > 2 && (
                  <div
                    className="expandCollapse"
                    onClick={() => setExpandCollapse(!expandCollapse)}
                  >
                    <span className="count">
                      {expandCollapse ? "less" : `+${selected?.length - 2}`}
                    </span>
                    <i
                      className={`material-icons arrowIcon ${
                        expandCollapse ? "rotate" : ""
                      }`}
                    >
                      keyboard_arrow_down
                    </i>
                  </div>
                )}
              </div>
              <ActionButton resetCbk={handleReset} isAdd={false} />
            </>
          )) ||
            ""}
        </div>
      )}
      {(searchInput?.length && (
        <div
          className="dropdownBgOverlay"
          onClick={() => handleSearchInput("")}
        />
      )) ||
        ""}
      {toggleDropdown && !data?.length && (
        <div className="emptyDropdown">No data available</div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
