import React, { memo } from "react";

const StageTitle = props => {
  const { type = "" } = props || {};

  const stageTitle = {
    email:
      "Auto Email <span class='infoText'>(Emails are delivered automatically)</span>",
    manualEmail: "Manual Email",
    linkedin: "LinkedIn",
    phone: "Phone Call",
    customTask: "Custom Task"
  };

  return (
    <div
      className="stageTitle"
      dangerouslySetInnerHTML={{ __html: stageTitle[type] }}
    />
  );
};

const MemoizedStageTitle = memo(StageTitle);
export default MemoizedStageTitle;
export { MemoizedStageTitle };
