import Utils from "Utils/utils";
import InboxUtils from "Utils/InboxUtils";
import PaywallUtils from "Utils/PaywallUtils";
import DriverMaster from "Components/driver/Driver";
import { toasterState } from "Components/common/toaster";
import {
  AUTH_CLIENT_ID,
  AUTH_REDIRECT_URL,
  AUTH_SCOPES,
  AUTH_SCOPES_CALENDAR,
  OUTLOOK_CLIENT_ID,
  OUTLOOK_REDIRECT_URL
} from "Static/constant";
import userDetail from "Stores/userDetail";
import connectedInboxStore from "../ConnectInbox/store";
import { gmailMessagePopupState } from "../ConnectInbox/GmailMessagePopup";

const connectedEmailUtils = {};

const handleGoogleOauthCbk = async (cbkCode = "") => {
  const { noOfInboxesUsed = 0 } = userDetail?.userFeatureFlag || {};

  switch (cbkCode) {
    case "200":
      connectedInboxStore.setLoading(true);
      userDetail.setUserFeatureFlag({
        ...userDetail.userFeatureFlag,
        noOfInboxesUsed: noOfInboxesUsed + 1
      });
      await connectedInboxStore.getAccountDetails(true);
      Utils.getOnboardingData();
      Utils.trackOnboardingMixpanel("account_connected");
      break;
    case "400":
      gmailMessagePopupState.setPopupValues({
        title: "Insufficient Permission",
        subTitle: `Unable to connect your Google account with Salesgear due to insufficient permissions.
      Please retry connecting by enabling all the required permissions on the consent screen 
      <br>(Please refer to the video below)`
      });
      gmailMessagePopupState.setShowPopup(true);
      break;
    case "409":
      gmailMessagePopupState.setPopupValues({
        title: "Connection Failed",
        subTitle: `Unable to connect your Google account with Salesgear as its already connected to a different Salesgear account. Please try re-connecting a different Google account`,
        additionalLink: `<a class="errorLink" href="https://support.salesgear.io/en/articles/5660382-why-do-i-get-the-connection-failed-error-when-i-try-to-connect-my-gmail-with-salesgear" target="_blank" rel="noopener noreferrer"><span>Why do I get the connection failed error?</span><i class="fa fa-external-link openInNewTabIcon" aria-hidden="true"></i></a>`
      });
      gmailMessagePopupState.setShowPopup(true);
      break;
    case "412":
      gmailMessagePopupState.setPopupValues({
        title: "Connection Failed",
        subTitle: `Please connect the same email account`
      });
      gmailMessagePopupState.setShowPopup(true);
      break;
    case "413":
      toasterState.setToastMsg(
        `Oops! You cannot connect personal email addresses.`,
        "fail"
      );
      break;
    case "500":
    default:
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
  }
};

const closeGmailMessagePopup = () => {
  gmailMessagePopupState.setPopupValues({});
  gmailMessagePopupState.setShowPopup(false);
};

connectedEmailUtils.openGmailConnectPopup = () => {
  if (InboxUtils.connectInboxAllowed()) {
    DriverMaster.killDriver();
    gmailMessagePopupState.setConnectBtnAction(
      connectedEmailUtils.openGoogleAuth
    );
    gmailMessagePopupState.setPopupValues({
      title: `Connect your Google account with Salesgear`,
      subTitle: ""
    });
    gmailMessagePopupState.setShowPopup(true);
  } else {
    upgradeMulitpleInbox();
  }
};

connectedEmailUtils.openGoogleAuth = async () => {
  const { calendarEnabled = false } = userDetail?.userInfo || {};
  const { calendarIntegrationEnabled = false } =
    userDetail?.userFeatureFlag || {};

  closeGmailMessagePopup();
  let gmailAuthScope =
    calendarEnabled && calendarIntegrationEnabled
      ? AUTH_SCOPES_CALENDAR
      : AUTH_SCOPES;
  const authUrl =
    "https://accounts.google.com/o/oauth2/auth?client_id=" +
    AUTH_CLIENT_ID +
    "&scope=" +
    gmailAuthScope +
    "&access_type=offline&response_type=code&redirect_uri=" +
    AUTH_REDIRECT_URL +
    "&prompt=select_account" +
    "&hd=*";
  const w = 600;
  const h = 800;
  const left = window.screen.width / 2 - w / 2;
  const top = window.screen.height / 2 - h / 2;
  let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
  window.open(authUrl, "Salesgear", params);
  const oauthCbk = async ev => {
    if (ev?.data?.type === "GOOGLE_CONNECT_INBOX") {
      handleGoogleOauthCbk(ev?.data?.code?.toString());
      window.removeEventListener("message", oauthCbk);
    }
  };
  window.addEventListener("message", oauthCbk);
};

connectedEmailUtils.microsoftAuth = async () => {
  const { noOfInboxesUsed = 0 } = userDetail?.userFeatureFlag || {};

  if (InboxUtils.connectInboxAllowed()) {
    DriverMaster.killDriver();
    const authUrl =
      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?" +
      "client_id=" +
      OUTLOOK_CLIENT_ID +
      "&response_type=code" +
      "&redirect_uri=" +
      OUTLOOK_REDIRECT_URL +
      "&response_mode=query" +
      "&scope=offline_access openid Mail.ReadWrite Contacts.Read email Mail.Read Mail.Send profile User.Read Calendars.ReadWrite Calendars.ReadBasic Calendars.Read" +
      "&state=12345" +
      "&consent=prompt";
    const w = 600;
    const h = 800;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
    const oAuthWindow = window.open(authUrl, "Salesgear", params);
    const oauthSuccess = async ev => {
      let { code = "", type = "" } = ev.data || ev.key;
      if (type === "MICROSOFT_CONNECT_INBOX") {
        switch (code) {
          case "200":
          case 200:
            userDetail.setUserFeatureFlag({
              ...userDetail.userFeatureFlag,
              noOfInboxesUsed: noOfInboxesUsed + 1
            });
            connectedInboxStore.setLoading(true);
            await connectedInboxStore.getAccountDetails(true);
            oAuthWindow.close();
            Utils.getOnboardingData();
            Utils.trackOnboardingMixpanel("account_connected");
            break;
          case "409":
          case 409:
            oAuthWindow.close();
            setTimeout(() => {
              toasterState.setToastMsg(
                `Failed. This inbox is already connected to a different Salesgear account. Try with a different one.`,
                "fail"
              );
            }, 200);
            break;
          case "500":
          default:
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
        }
      }
    };
    window.addEventListener("message", oauthSuccess);
  } else {
    upgradeMulitpleInbox();
  }
};

const upgradeMulitpleInbox = () => {
  const { planName = "", inFreePlan = false } =
    userDetail?.userFeatureFlag || {};
  let tempPlanName = planName?.toLowerCase();
  let inboxModel =
    inFreePlan || ["starter", "basic"].includes(tempPlanName)
      ? "multipleInbox"
      : ["pro", "unlimited", "growth"].includes(tempPlanName)
      ? "multipleInboxCustom"
      : "";

  inboxModel &&
    PaywallUtils.upgradeUtilsFunction(inboxModel, false, planName, inFreePlan);
};

export { connectedEmailUtils };
