import React, { useState } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import { SEQUENCE_STAGE_ICON } from "Model/model";
import PaywallUtils from "Utils/PaywallUtils";
import AddStageDropDown from "../SequencePage/AddStageDropDown";

const StagesType = observer(props => {
  const { taskEnabled = false } = userDetail?.userFeatureFlag || {};
  const [showDropDownMenu, setShowDropDownMenu] = useState(false);
  const [selectedStage, setSelectedStage] = useState("Email (Automatic)");

  let stagesList = [
    "email",
    "manualEmail",
    "linkedin",
    "phone",
    "customTask"
  ].filter(Boolean);

  const { changeStepType = () => {}, popupValues = {} } = props || {};
  const {
    stageType = "",
    isEdit = false,
    isFromTriggers = false,
    isTaskCreation = false
  } = popupValues || {};

  (isFromTriggers || isTaskCreation) && stagesList.shift();

  const lockStep = type => type !== "email" && !taskEnabled;
  const { inFreePlan = false, planName = "" } =
    userDetail?.userFeatureFlag || {};

  const handleClickStage = (obj = {}) => {
    setSelectedStage(obj?.value);
    changeStepType(obj?.key);
  };

  const getSelectedStage = () => {
    const obj = {
      email: "Email (Automatic)",
      manualEmail: "Email (Manual)",
      linkedinAuto: "LinkedIn (Automatic)",
      linkedin: "LinkedIn (Manual)",
      phone: "Call",
      customTask: "Custom Task"
    };
    return obj[stageType] || obj["email"];
  };

  return (
    !isEdit && (
      <div className="stagesType">
        <div
          className="dropdownHeader"
          onClick={() => setShowDropDownMenu(true)}
        >
          <span className="headerName">{getSelectedStage()}</span>
          <i className="material-icons arrowIcon">keyboard_arrow_down</i>
        </div>
        {showDropDownMenu && (
          <AddStageDropDown
            showDropDownMenu={showDropDownMenu}
            setShowDropDownMenu={setShowDropDownMenu}
            cbk={handleClickStage}
            defaultSelected={stageType}
            isTaskCreation={isTaskCreation || isFromTriggers}
            className={"addStepDropdown"}
          />
        )}
      </div>
    )
  );
});

export { StagesType };
export default StagesType;
