/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import QuilEditor from "Components/common/quillEditor";
import { TEST_EMAIL_CONNECT_INBOX_MSG } from "Static/constant";
import { writingAssistantStoreData } from "Stores/WritingAssistantData";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import PreviewSequenceTemplate from "Components/common/PreviewSequenceTemplate";
import SubjectInput from "./SubjectInput";
import AddTemplatesInputDropdown from "./AddTemplatesInputDropdown";
import ReplyThreadDropdown from "./ReplyThreadDropdown";
import SequenceUtils from "Utils/SequenceUtils";
import InsertAsHTML, {
  insertAsHTMLState
} from "Components/common/InsertAsHTML";
import { userDetail } from "Stores/userDetail";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { testEmailPopupState } from "./TestEmailPopup";

const defaultSelectedTemplate = "No Template Selected";

const StepEmailCreateEditorPopup = observer((props = {}) => {
  const {
    currentStep,
    stageType,
    daysLater,
    variantStep,
    inputVal,
    steps,
    isVariantChange,
    seqId,
    isEdit,
    isFromTriggers = false,
    isTaskCreation = false
  } = addStagesPopupState.popupValues || {};

  const { userInfo: { email: userEmailAddress = "" } = {} } = userDetail;
  const isAugrayUser = userEmailAddress?.includes("@augray.com");

  const {
    greatestUniqueId: tempGreatestUniqueId = 0,
    steps: selectedSteps = {}
  } = addStagesPopupState.selectedSequence;

  const greatestUniqueId =
    tempGreatestUniqueId ||
    (steps &&
      Object.keys(steps).length &&
      Math.max.apply(
        Math,
        Utils.convertObjectToArray(steps).map(function (item) {
          return item.uniqueId;
        })
      )) ||
    0;
  const [loader, setLoader] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [subjectCursorPosition, setSubjectCursorPosition] = useState(0);
  const [isSubjectFocus, setSubjectFocus] = useState(false);
  const [isTemplateInserted, setTemplateInserted] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [editorInsertCounter, setEditorInsertCounter] = useState(0);
  const [showTemplateDropDown, setShowTemplateDropDown] = useState(false);
  const [inputValue, setInputValue] = useState(inputVal);
  const [selectedTemplate, setSelectedTemplate] = useState(
    defaultSelectedTemplate
  );
  const [subjectErrMsg, setSubjectErrMsg] = useState("");
  const [hasChangeInHTMLEditor, setHasChangeInHTMLEditor] = useState(false);

  const handleInputFocus = value => {
    setSubjectFocus(value);
  };

  const handleInputChange = (value, id) => {
    setInputValue({ ...inputValue, [id]: value });
  };

  const closeEditorPopup = value => {
    setLoader(false);
    if (value) {
      return;
    }
    clearTemplateCbk();
    resetEditorCount();
    setAttachments([]);
    addStagesPopupState.setResetValue();
    writingAssistantStoreData.resetSubjectLinesGenerator();
    fileAttachmentStore.clearAttachmentList();
    addStagesPopupState.setShowStepEmailEditorPopup(false);
    testEmailPopupState.setShowPopup(false);
    testEmailPopupState.setFromAddress({});
    testEmailPopupState.setToAddress("");
    insertAsHTMLState.setActiveInsertHTML(false);
  };

  const createEmailStage = () => {
    const newSteps = { ...selectedSteps };
    const {
      variants: currentVariants = {},
      greatestVariantUniqueId: tempGreatestVariantUniqueId = 0
    } = newSteps[currentStep] || {};

    const greatestVariantUniqueId = SequenceUtils.getGreatestVariantUniqueId(
      tempGreatestVariantUniqueId,
      currentVariants
    );

    const emailStepObj = {
      [currentStep]: {
        greatestVariantUniqueId: greatestVariantUniqueId + 1,
        daysLater,
        variants: {
          [variantStep]: {
            ...inputValue,
            content: editorValue,
            type: stageType,
            daysLater,
            uniqueId: SequenceUtils.getUniqueVariantId(
              greatestVariantUniqueId,
              currentVariants?.length
            )
          }
        }
      }
    };
    return emailStepObj;
  };

  const addUpdateVariant = () => {
    const afterUpdateCbk = () => {
      let actionType = isVariantChange
        ? isEdit
          ? "editVariant"
          : "updateStage"
        : "addVariant";
      if (["updateStage", "addVariant"].includes(actionType)) {
        Utils.mixpanelTrack(MXP_EVENT.AB_TESTING_VARIATION_CREATED);
      }
      addStagesPopupState.setAction(actionType);
      closeEditorPopup();
    };
    const newSteps = { ...steps };
    const {
      variants: currentVariants = {},
      greatestVariantUniqueId: tempGreatestVariantUniqueId = 0
    } = newSteps[currentStep];

    const greatestVariantUniqueId = SequenceUtils.getGreatestVariantUniqueId(
      tempGreatestVariantUniqueId,
      currentVariants
    );
    const currentUniqueId = currentVariants?.[variantStep]?.uniqueId;

    newSteps[currentStep].greatestVariantUniqueId =
      isVariantChange && isEdit
        ? greatestVariantUniqueId
        : greatestVariantUniqueId + 1;
    newSteps[currentStep]["variants"][variantStep] = {
      ...inputValue,
      content: editorValue,
      type: stageType,
      timeZone: Utils.getCurrentUserTimeZone(),
      daysLater,
      status:
        newSteps?.[currentStep]?.["variants"]?.[variantStep]?.status ||
        "ACTIVE",
      attachments,
      uniqueId: isEdit
        ? currentUniqueId
        : SequenceUtils.getUniqueVariantId(
            greatestVariantUniqueId,
            currentVariants?.length || 0
          )
    };
    (seqId &&
      addStagesPopupState.createUpdateStep(
        {
          id: seqId,
          steps: newSteps,
          greatestUniqueId,
          firstStageIsReply: Utils.getFirstEmailStatus(newSteps)
        },
        afterUpdateCbk,
        "PUT"
      )) ||
      closeEditorPopup();
  };

  const createStage = () => {
    const { subject = "", replyAsThread = false } = inputValue || {};
    const afterUpdateCbk = () => {
      addStagesPopupState.setAction("createStage");
      closeEditorPopup();
    };
    if (subject || (replyAsThread && !subject)) {
      const hasInvalidTokens = SequenceUtils.getInvalidTokensPrompt(
        subject,
        editorValue
      );
      if (!hasInvalidTokens) {
        setLoader(true);
        if (isVariantChange) {
          addUpdateVariant();
        } else {
          addStagesPopupState.popupValues.createCbk(
            createEmailStage(),
            afterUpdateCbk
          );
        }
      }
      writingAssistantStoreData.resetSubjectLinesGenerator();
    } else if (!subject && !replyAsThread) {
      setSubjectErrMsg("Please enter a subject");
      setTimeout(() => setSubjectErrMsg(""), 1500);
    }
  };

  const createManualTriggerTask = () => {
    const { subject = "" } = inputValue || {};
    if (subject) {
      const hasInvalidTokens = SequenceUtils.getInvalidTokensPrompt(
        subject,
        editorValue
      );
      if (!hasInvalidTokens) {
        const triggerTaskPayload = {
          note: addStagesPopupState.popupValues.notes,
          timeZone: Utils.getCurrentUserTimeZone(),
          daysLater,
          type: "email",
          customEmailData: {
            subject,
            content: editorValue,
            attachments
          }
        };
        addStagesPopupState.popupValues.triggerCbk(
          "save",
          {
            ...triggerTaskPayload
          },
          closeEditorPopup
        );
      }
    } else {
      setSubjectErrMsg("Please enter a subject");
      setTimeout(() => setSubjectErrMsg(""), 1500);
    }
  };

  const changeReplyThread = item => {
    const { subKey = false } = item || {};
    if (!subKey) {
      inputValue.subject = "";
    }
    writingAssistantStoreData.setIsReplyThread(subKey);
    setInputValue({ ...inputValue, replyAsThread: subKey });
  };

  const bodyOnchangeEvnt = (value = "", source = "") => {
    if (value) {
      writingAssistantStoreData.setBodyContent(value);
      setEditorValue(value);
      setEditorInsertCounter(editorInsertCounter + 1);
    }
  };

  const updateEditorContent = () => {
    let { content = "", subject = "" } =
      writingAssistantStoreData?.inputValue || {};
    if (content) {
      let tempObj = { ...inputValue, content };
      if ((!inputValue?.replyAsThread || isTemplateInserted) && subject) {
        tempObj.subject = subject;
      }
      setInputValue(tempObj);
      setEditorValue(content);
      setEditorInsertCounter(0);
    }
  };

  const handleBodyChangeOnInsertHTML = event => {
    setHasChangeInHTMLEditor(true);
    const value = event?.currentTarget?.value;
    const updatedValue =
      value?.replace(
        /ql-editor|contenteditable="true"|data-gramm="false"/gi,
        ""
      ) || "";
    setInputValue({
      ...inputValue,
      content: updatedValue
    });
    setEditorValue(updatedValue);
    setEditorInsertCounter(editorInsertCounter + 1);
  };

  const setSubjectStateFromEditor = value => {
    let tempSubject =
      inputValue?.subject?.substr(0, subjectCursorPosition) +
      value +
      inputValue?.subject?.substr(
        subjectCursorPosition,
        inputValue?.subject?.length
      );
    setInputValue({ ...inputValue, subject: tempSubject });
    Utils.focusInputBox("taskSubjectInput");
  };

  const getEntityObj = () => {
    return {
      pageType: "sequences",
      attachments,
      updateState: setAttachments
    };
  };

  const templateDropDownAction = (value, event) => {
    event && Utils.preventDefaultFn(event);
    setShowTemplateDropDown(value);
  };

  const clearTemplateCbk = () => {
    setSelectedTemplate("No Template Selected");
    setAttachments([]);
    fileAttachmentStore.clearAttachmentList();
    writingAssistantStoreData.setAttachmentUpdated(true);
    setTemplateInserted(false);
    const stateObj = { ...inputValue, attachments: [] };
    if (!stateObj.replyAsThread || currentStep <= 1) {
      stateObj.subject = "";
      let subjectDom = document.getElementById("subject");
      subjectDom && subjectDom.focus();
    }
    stateObj.content = "";
    setEditorInsertCounter(0);
    setInputValue(stateObj);
  };

  const clearSelectedTemplate = () => {
    if (editorInsertCounter > 1) {
      confirmationPopupState.setPopupValues({
        title: "Clear Template?",
        content:
          "Are you sure you want to remove this template?<br>All changes made to this step will be lost",
        actionBtnText: "Yes",
        callback: clearTemplateCbk
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      clearTemplateCbk();
    }
  };

  const resetEditorCount = () => {
    setTimeout(() => {
      setEditorInsertCounter(0);
    }, 500);
  };

  const closePopup = () => {
    if (
      !fileAttachmentStore?.fileUploadingLoader &&
      !fileAttachmentStore?.taskFileUploadingLoader
    ) {
      writingAssistantStoreData.resetSubjectLinesGenerator();
      if (!loader) {
        const closeCbk = () => {
          setAttachments([]);
          setEditorValue("");
          addStagesPopupState?.popupValues?.isFromTriggers &&
            addStagesPopupState.popupValues.triggerCbk("cancel", {}, () => {});
          resetEditorCount();
          addStagesPopupState.setResetValue();
          writingAssistantStoreData.setBodyContent("");
          writingAssistantStoreData.setInputValue({ content: "", subject: "" });
          testEmailPopupState.setDefaultValues({});
          if (!isTaskCreation) {
            testEmailPopupState.setFromAddress({});
            testEmailPopupState.setToAddress({});
          }
          closeEditorPopup();
        };

        if (editorInsertCounter > 1) {
          confirmationPopupState.setPopupValues({
            title: "Are you sure",
            content:
              "Are you sure you want to close this popup ?<br>  Any changes made will be lost.",
            actionBtnText: "Yes",
            callback: closeCbk
          });
          confirmationPopupState.setShowPopup(true);
        } else {
          closeCbk();
        }
      }
    }
  };

  const getAttachments = (value = []) => {
    if (addStagesPopupState?.StepEmailCreateEditorPopup) {
      setAttachments(value);
    }
  };

  const updateInputValueState = (newAttList = []) => {
    let stateObj = { ...inputValue };
    stateObj.attachments = newAttList;
    setInputValue(stateObj);
  };

  const getAttachmentFromStep = () => {
    let attachment = [];
    const { steps = {} } = addStagesPopupState.selectedSequence || {};
    const currentVariantStep =
      steps?.[currentStep]?.["variants"]?.[variantStep] || {};
    const { attachments = [] } = currentVariantStep;
    if (testEmailPopupState?.defaultValues?.attachments) {
      attachment = testEmailPopupState?.defaultValues?.attachments;
      setAttachments(testEmailPopupState?.defaultValues?.attachments);
    } else if (attachments?.length) {
      attachment = attachments;
      setAttachments(attachments);
    } else {
      setAttachments([]);
    }
    return attachment;
  };

  const updateAttachmentsInLocalState = () => {
    let newAttList = Utils.getAttachmentsArr(
      fileAttachmentStore.fileAttachmentList,
      attachments
    );
    updateInputValueState(newAttList);
    setAttachments(newAttList);
  };

  const sendEmailBtnEnabled = () => {
    const templateSubjectValidate =
      inputValue?.replyAsThread ||
      Utils.validateStringLength(inputValue?.subject || "", 1);
    return !(
      templateSubjectValidate && Utils.contentBodyHasTextOrImg(editorValue)
    );
  };

  const sendTestEmail = (sendTestEmailLoader = () => {}) => {
    const templateSubjectValidate =
      inputValue?.replyAsThread ||
      Utils.validateStringLength(inputValue?.subject || "", 1);
    if (templateSubjectValidate && Utils.contentBodyHasTextOrImg(editorValue)) {
      sendTestEmailLoader(true);
      const payload = {
        subject: Utils.replaceTokenWithValue(
          inputValue?.replyAsThread
            ? `Re : [Previous email subject line]`
            : inputValue?.subject || "",
          false
        ),
        attachmentId: Utils.getAttachmentIds(attachments || []),
        content: Utils.replaceTokenWithValue(editorValue, false),
        fromInboxId: testEmailPopupState?.fromAddress?.id,
        toAddress: testEmailPopupState?.toAddress?.value
      };
      Utils.sendTestEmail(
        payload,
        {
          pageType: "Sequence",
          emailType: stageType
        },
        () => {
          sendTestEmailLoader(false);
          testEmailPopupState.setShowPopup(false);
          testEmailPopupState.setToAddress({});
          addStagesPopupState.setShowStepEmailEditorPopup(true);
        },
        props,
        closePopup
      );
    }
  };

  const constructManualTaskPayload = () => {
    const {
      dueDateString = "",
      email = "",
      taskId = ""
    } = addStagesPopupState.popupValues || {};
    return {
      subject: inputValue?.subject || "",
      note: inputValue?.notes,
      email,
      dueDateString,
      content: editorValue,
      attachments,
      type: stageType,
      timeZone: Utils.getCurrentUserTimeZone(),
      taskId
    };
  };

  const handleEmailAction = () => {
    if (isFromTriggers) {
      createManualTriggerTask();
    } else if (isTaskCreation) {
      if (
        ["email", "manualemail"].includes(stageType?.toLowerCase()) &&
        !inputValue?.subject
      ) {
        setSubjectErrMsg("Please enter a subject");
        setTimeout(() => setSubjectErrMsg(""), 1500);
        return;
      }
      setLoader(true);
      const payload = constructManualTaskPayload();
      addStagesPopupState.popupValues.createCbk(payload, closeEditorPopup);
    } else {
      createStage();
    }
  };

  const sendTestEmailInit = () => {
    if (
      !fileAttachmentStore?.fileUploadingLoader &&
      !fileAttachmentStore?.taskFileUploadingLoader
    ) {
      if (Utils.isAccountConnected()) {
        addStagesPopupState.setShowStepEmailEditorPopup(false);
        let tempPopupValues = { ...addStagesPopupState.popupValues };
        tempPopupValues.inputVal = {
          ...inputVal,
          ...inputValue,
          content: editorValue
        };
        tempPopupValues.timeStamp = new Date().getTime();
        addStagesPopupState.setPopupValues(tempPopupValues);
        testEmailPopupState.setDefaultValues({
          currentStep,
          attachments,
          subject: inputValue?.subject,
          replyAsThread: inputValue?.replyAsThread,
          hasChangeInHTMLEditor,
          contentBody: insertAsHTMLState?.activeInsertHTML
            ? getUpdatedInsertHTMLVal()
            : editorValue
        });
        testEmailPopupState.setCbk(sendTestEmail);
        testEmailPopupState.setShowPopup(true);
      } else {
        Utils.showAddAccountPopup(
          props,
          TEST_EMAIL_CONNECT_INBOX_MSG,
          closePopup
        );
      }
    }
  };

  const buttonList = [
    {
      id: "sendTestEmailBtn",
      name: "sendTestEmailBtn",
      btnText: "Send Test Email",
      className: `sendTestEmailBtn ${
        sendEmailBtnEnabled() ? "disableSentEmailBtn" : ""
      }`,
      type: "button",
      click: sendTestEmailInit,
      loader: false,
      show: true
    },
    {
      id: "createStageBtn",
      name: "createStageBtn",
      btnText:
        isTaskCreation || isFromTriggers
          ? `${isEdit ? "Update" : "Create"} Task`
          : "Save",
      loader: loader,
      className: `createStageBtn ${loader ? "loading" : ""}`,
      type: "button",
      click: handleEmailAction,
      show: true
    }
  ];

  const checkForInsertHTMLOrQuillType = () => {
    if (inputValue?.content) {
      if (inputValue?.content?.includes("ql-editor")) {
        insertAsHTMLState.setActiveInsertHTML(false);
      } else {
        setEditorValue(inputValue?.content);
        insertAsHTMLState.setActiveInsertHTML(true);
      }
    }
  };

  const getUpdatedInsertHTMLVal = () => {
    let updatedValue = hasChangeInHTMLEditor
      ? inputValue?.content
      : editorValue;

    return (
      updatedValue?.replace(
        /ql-editor|contenteditable="true"|data-gramm="false"/gi,
        ""
      ) || ""
    );
  };

  useEffect(() => {
    isAugrayUser && checkForInsertHTMLOrQuillType();
  }, [inputValue?.content]);

  useEffect(() => {
    if (addStagesPopupState?.showStepEmailEditorPopup) {
      fileAttachmentStore?.fileAttachmentList &&
        updateAttachmentsInLocalState();
    }
  }, [fileAttachmentStore.fileAttachmentList]);

  useEffect(() => {
    if (
      addStagesPopupState?.showStepEmailEditorPopup ||
      testEmailPopupState.showPopup
    ) {
      if (inputVal && Object.keys(inputVal)?.length) {
        let attachment = [];
        if (addStagesPopupState?.showStepEmailEditorPopup && +currentStep > 0) {
          attachment = getAttachmentFromStep();
        }
        let isReplyThread =
          !isEdit && +currentStep <= 1 ? false : inputVal?.replyAsThread;
        setInputValue({
          ...inputVal,
          attachments: attachment,
          replyAsThread: isReplyThread
        });
        setEditorValue(inputVal?.content);
        writingAssistantStoreData.setIsReplyThread(isReplyThread);
        writingAssistantStoreData.setBodyContent(inputVal.content);
      }
    }
  }, [addStagesPopupState.popupValues]);

  useEffect(() => {
    setHasChangeInHTMLEditor(false);
    if (insertAsHTMLState.activeInsertHTML && isAugrayUser) {
      const updatedVal = getUpdatedInsertHTMLVal();
      setInputValue({ ...inputValue, content: updatedVal });
    }
  }, [insertAsHTMLState.activeInsertHTML]);

  useEffect(() => {
    updateEditorContent();
  }, [writingAssistantStoreData?.inputValue]);

  useEffect(() => {
    return () => {
      writingAssistantStoreData.setBodyContent("");
      writingAssistantStoreData.setInputValue({ content: "", subject: "" });
      setLoader(false);
      setAttachments([]);
      setEditorInsertCounter(0);
      setSelectedTemplate(defaultSelectedTemplate);
      closeEditorPopup();
    };
  }, []);

  return (
    (addStagesPopupState?.showStepEmailEditorPopup && (
      <div className="stepEmailCreateEditorPopup">
        <div
          className={`emailCreateEditorCard animated customzoomIn ${
            isFromTriggers || isTaskCreation ? "isManualTask" : ""
          } ${loader ? "disabledUserAction" : ""}`}
        >
          <span className="material-icons closePopup" onClick={closePopup}>
            close
          </span>
          <div className="editorPreviewSection">
            <div id="reactEditor">
              <h4 className="stageTitle">
                {isFromTriggers || isTaskCreation
                  ? `${isEdit ? "Edit" : "Create"} Manual Email Task`
                  : `${isEdit ? "Edit" : "Add"} step ${currentStep}`}
              </h4>
              <>
                <div
                  className={`wrapInputFieldSection ${
                    isFromTriggers || isTaskCreation ? "manualTask" : ""
                  }`}
                >
                  <SubjectInput
                    setSubjectCursorPosition={setSubjectCursorPosition}
                    setSubject={handleInputChange}
                    handleFocus={handleInputFocus}
                    subject={inputValue?.subject || ""}
                    replyAsThread={inputValue?.replyAsThread || ""}
                    errorMsg={subjectErrMsg}
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                    content={inputValue?.content}
                    id="taskSubjectInput"
                  />
                  {!(isFromTriggers || isTaskCreation) && (
                    <ReplyThreadDropdown
                      defaultValue={
                        inputValue?.replyAsThread
                          ? "Send as Reply"
                          : "Send as New Thread"
                      }
                      handleChange={changeReplyThread}
                      currentStep={currentStep}
                      isManualTask={isFromTriggers || isTaskCreation}
                    />
                  )}
                  {!isFromTriggers && !isTaskCreation && (
                    <AddTemplatesInputDropdown
                      isTemplateInserted={isTemplateInserted}
                      templateDropDownAction={templateDropDownAction}
                      selectedTemplate={selectedTemplate}
                      clearSelectedTemplate={clearSelectedTemplate}
                      showTemplateDropDown={showTemplateDropDown}
                      editorInsertCounter={editorInsertCounter}
                      setEditorValue={setEditorValue}
                      inputValue={inputValue}
                      setInputValue={setInputValue}
                      currentStage={currentStep}
                      setSelectedTemplate={setSelectedTemplate}
                      setTemplateInserted={setTemplateInserted}
                      popupCloseAction={closePopup}
                      setAttachmentCbk={getAttachments}
                      stageType={stageType}
                      setEditorInsertCounter={setEditorInsertCounter}
                    />
                  )}
                </div>
                <div id="sequenceAutomaticQuilEditor">
                  {isAugrayUser ? (
                    <>
                      <InsertAsHTML />
                      {!insertAsHTMLState?.activeInsertHTML ? (
                        <QuilEditor
                          bodyOnChange={bodyOnchangeEvnt}
                          setsubjectFocus={handleInputFocus}
                          isSubjectFocused={isSubjectFocus}
                          setSubject={setSubjectStateFromEditor}
                          prefilledValue={inputValue?.content}
                          entityObj={getEntityObj()}
                          attachments={attachments || []}
                          stageType={stageType}
                          replyAsThread={inputValue?.replyAsThread || ""}
                          type="sequences"
                          showRewriteAI={true}
                          updateContentCbk={false}
                          pageType="sequences"
                        />
                      ) : (
                        <div className="insertHTMLWrap">
                          <textarea
                            className="insertHTMLArea"
                            onChange={handleBodyChangeOnInsertHTML}
                            value={getUpdatedInsertHTMLVal()}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <QuilEditor
                      bodyOnChange={bodyOnchangeEvnt}
                      setsubjectFocus={handleInputFocus}
                      isSubjectFocused={isSubjectFocus}
                      setSubject={setSubjectStateFromEditor}
                      prefilledValue={inputValue?.content}
                      entityObj={getEntityObj()}
                      attachments={attachments || []}
                      stageType={stageType}
                      replyAsThread={inputValue?.replyAsThread || ""}
                      type="sequences"
                      showRewriteAI={true}
                      updateContentCbk={false}
                      pageType="sequences"
                    />
                  )}
                </div>
              </>
            </div>
            <PreviewSequenceTemplate
              contentBody={
                insertAsHTMLState?.activeInsertHTML
                  ? getUpdatedInsertHTMLVal()
                  : editorValue
              }
              attachments={attachments || []}
              subject={inputValue?.subject}
              currentStage={currentStep}
              replyAsThread={inputValue?.replyAsThread || false}
              hasChangeInHTMLEditor={hasChangeInHTMLEditor}
            />
          </div>
          <div className="footerAction">
            {buttonList.map(
              item => item.show && <Button key={item.id} {...item} />
            )}
          </div>
        </div>
        <div className="overlayStagesPopup" />
      </div>
    )) || <></>
  );
});

export default withRouter(StepEmailCreateEditorPopup);
export { StepEmailCreateEditorPopup };
