import React from "react";
import { withRouter } from "react-router-dom";
import PointingArrowIcon from "Assets/png/pointingArrow.png";
import DialerIcon from "Assets/png/dialer.png";
import { UpgradeTooltip } from "Components/Paywall/UpgradeTooltip";
import { PAYWALL_CONTENT } from "Model/model";
import Utils from "Utils/utils";

const DialerUpgradePopup = props => {
  const paywallContent = Utils.dialerSettingsType();

  return (
    <div className="dialerUpgrade animated customzoomIn">
      <div className="dialerUpgradeCont">
        <div className="dialerImageCont">
          <img src={DialerIcon} alt="Dialer Icon" className="dialerImg"></img>
        </div>
        <div className="upgradeCont">
          <UpgradeTooltip {...PAYWALL_CONTENT[paywallContent]} {...props} />
          <div className="arrowIcon">
            <img src={PointingArrowIcon} alt="Arrow"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(DialerUpgradePopup);
export { DialerUpgradePopup };
