import React, { useEffect, useState, createContext, useContext } from "react";
import { makeApi, URLS } from "Utils/apiURL";
import RadioBtnComponent from "Components/common/radioBtn";
import CheckBoxComponent from "Components/common/CheckBox";
import { ReactComponent as UserIcon } from "Assets/svg/userOutlined.svg";
import { ReactComponent as DownArrow } from "Assets/svg/donw-arrow.svg";
import { ContactCardFunction } from "Components/ContactCard/ContactCardFunction";
import Utils from "Utils/utils";
import ButtonComponent from "Components/common/Button";
import FilterDropDownTeam from "./FilterDropDownTeam";

const TeamMemberFilterContext = createContext({});

const UserDropdown = props => {
  const {
    team = false,
    teamList = [],
    userFilterList,
    isContactsAdded = false,
    selectedUserCbk = () => {}
  } = props || {};

  let defaultSelectAllTeamMemberObject = [
    {
      id: "selectAll",
      value: "Select All",
      show: true
    }
  ];

  const [selectedUser, setSeletedUser] = useState(team ? "all" : "you");
  const [showFilter, setFilter] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [initialTeamMemberList, setInitialTeamMemberList] = useState([]);
  const [teamLoader, setTeamLoader] = useState(true);

  const getHiddenTagCount = (value = "") => {
    if (value?.length && typeof value === "object") {
      let countValue = filterType === "team" ? 1 : 3 || 0;
      let count =
        (teamList && value?.length > countValue && value.length - countValue) ||
        "";
      return (count > 0 && `+${count}`) || "";
    }
  };

  const updateSelectedUser = (
    toggleValue = false,
    value,
    selected = false,
    type = ""
  ) => {
    setFilterType(type?.toLowerCase());
    setSeletedUser(
      selected
        ? selected
        : type?.toLowerCase() === "team"
        ? team
          ? "all"
          : "you"
        : value
    );
    setFilter(type?.toLowerCase() === "team" ? false : toggleValue);
    selectedUserCbk(value);
  };

  const hasMultipleUser = () => {
    return selectedUser?.length && typeof selectedUser === "object";
  };

  const constructTeamMemberList = (list = []) => {
    let tempList = [];
    if (list?.length > 0) {
      list.forEach(item => {
        const { group = {}, members = [] } = item || {};
        if (members?.length > 0) {
          const { name = "", id = "" } = group || {};
          tempList.push({
            name,
            id,
            value: name,
            show: true,
            showSubmenu: true,
            submenu: members?.map(item => {
              const { memberId = "" } = item || {};
              return {
                name: Utils.getFullNameFromUserInfo(item),
                value: Utils.getFullNameFromUserInfo(item),
                show: true,
                id: memberId
              };
            })
          });
        }
      });
    }
    return tempList;
  };

  const getTeamMemberList = async () => {
    setTeamLoader(true);
    let teamMemberResponse = [];
    const config = {
      url: URLS.getTeamData,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response?.status === 200 && response?.data?.groups) {
      teamMemberResponse = response?.data.groups;
      teamMemberResponse = constructTeamMemberList(teamMemberResponse);
    }
    let newArr = defaultSelectAllTeamMemberObject.concat(teamMemberResponse);
    setTeamMemberList(newArr);
    setInitialTeamMemberList([...newArr]);
    setTeamLoader(false);
  };

  const resetTeamWithMembersToInitial = (unCheck = "") => {
    let tempList = [...initialTeamMemberList];
    if (tempList?.length > 0) {
      tempList = tempList.map(item => {
        unCheck && (item.checked = false);
        item.show = true;
        item.submenu?.length > 0 &&
          item.submenu.map(submenuItem => {
            unCheck && (submenuItem.checked = false);
            return submenuItem;
          });
        return item;
      });
    }
    setTeamMemberList([defaultSelectAllTeamMemberObject, ...tempList]);
  };

  useEffect(() => {
    getTeamMemberList();
    return () => {
      setFilter(false);
      resetTeamWithMembersToInitial(true);
    };
  }, []);

  return (
    <TeamMemberFilterContext.Provider
      value={{
        teamMemberList,
        setTeamMemberList,
        teamLoader,
        updateSelectedUser,
        resetTeamWithMembersToInitial
      }}
    >
      <div className="userFilterDropDown">
        <div
          className={`userFilterBtn ${!isContactsAdded ? "disabledBtn" : ""}`}
          onClick={e => (isContactsAdded ? setFilter(!showFilter) : () => {})}
        >
          <span className="icon">
            <UserIcon />
          </span>
          <span className="name">
            {(hasMultipleUser() &&
              selectedUser
                .slice(0, filterType === "team" ? 1 : 3)
                .map((key, index) => {
                  return (
                    <span
                      className={`shortName ${
                        filterType === "team" ? "teamNameSelected" : ""
                      }`}
                      key={key + index}
                    >
                      {key}
                    </span>
                  );
                })) ||
              (selectedUser === "all" && "Everyone") ||
              "Only you"}
          </span>
          <span className="hiddenCount">
            {hasMultipleUser() ? getHiddenTagCount(selectedUser) : ""}
          </span>
          <span className={`arrow ${showFilter ? "activeArrow" : ""}`}>
            <DownArrow />
          </span>
        </div>
        {showFilter && (
          <UserDropdownMenu
            team={teamList}
            selectedUserCbk={updateSelectedUser}
            userFilterList={Utils.filterByUserTeamFilterList(userFilterList)}
            teamMemberList={teamMemberList}
            teamLoader={teamLoader}
            resetTeamWithMembersToInitial={resetTeamWithMembersToInitial}
          />
        )}
        {showFilter && (
          <div
            className="overlayUserFilterMask"
            onClick={e => setFilter(!showFilter)}
          />
        )}
      </div>
    </TeamMemberFilterContext.Provider>
  );
};

const UserDropdownMenu = props => {
  const {
    team = [],
    userFilterList = [],
    selectedUserCbk = () => {},
    resetTeamWithMembersToInitial = () => {}
  } = props || {};

  const { teamMemberList, teamLoader } = useContext(TeamMemberFilterContext);

  const [radioBtnOptions, setRadioBtnOptions] = useState(userFilterList);
  const [teamList, setTeamList] = useState(team);
  const [showTeamUserDropdown, setTeamUserDropdown] = useState(false);
  const [showTeamDropDown, setShowTeamDropDown] = useState(false);

  const initDropdownValue = () => {
    radioBtnOptions.map(
      item =>
        item?.id === "selectOwner" &&
        item["checked"] &&
        setTeamUserDropdown(true)
    );
    radioBtnOptions.map(
      item =>
        item?.id === "Team" && item["checked"] && setShowTeamDropDown(true)
    );
  };

  const resetCheckedUser = () => {
    const newArr = [...teamList];
    teamList?.length &&
      teamList.map((item, index) => {
        if (item?.checked) {
          item.checked = false;
          newArr[index] = item;
        }
      });

    setTeamList(newArr);
  };

  const setCheckedValue = (event, id) => {
    resetTeamWithMembersToInitial(true);
    if (id?.toLowerCase() === "team") {
      setShowTeamDropDown(true);
      setTeamUserDropdown(false);
      const newArr = [...radioBtnOptions];
      radioBtnOptions?.length > 0 &&
        radioBtnOptions.map(item => (item["checked"] = item?.id === id));
      setRadioBtnOptions(newArr);
    } else {
      setShowTeamDropDown(false);
      resetCheckedUser();
      const newArr = [...radioBtnOptions];
      radioBtnOptions?.length > 0 &&
        radioBtnOptions.map(item => (item["checked"] = item?.id === id));
      id === "selectOwner"
        ? setTeamUserDropdown(true)
        : setTeamUserDropdown(false);
      id !== "selectOwner" && selectedUserCbk(false, id);
      setRadioBtnOptions(newArr);
    }
  };

  const getSelectedUser = async (value, id) => {
    const newArr = [...teamList];
    const idArr = [];
    const selected = [];
    if (teamList?.length) {
      teamList.map((item, index) => {
        if (item?.memberId === id) {
          item.checked = value;
          newArr[index] = item;
        }
        if (item?.checked) {
          idArr.push(item?.memberId);
          selected.push(ContactCardFunction.getUserLetterIcon(item));
        }
      });
      setTeamList(newArr);
      selectedUserCbk(
        true,
        (idArr?.length && idArr) || "all",
        (selected?.length && selected) || "all"
      );
    }
  };

  useEffect(() => {
    initDropdownValue();
  }, []);

  return (
    <div className="dropdownSection">
      <div className="dropdownContent">
        <div className="singleSelectSection">
          {radioBtnOptions?.length &&
            radioBtnOptions.map(item => {
              const { id = "", value = "", checked = "" } = item || {};
              let hasUserList = id !== "selectOwner" ? true : teamList?.length;
              return (
                (hasUserList && (
                  <RadioBtnComponent
                    key={id}
                    name="selectUserRadioBtn"
                    id={id}
                    value={value}
                    defaultChecked={checked}
                    radioOnchange={setCheckedValue}
                  />
                )) ||
                ""
              );
            })}
        </div>
        {showTeamUserDropdown && (
          <div className="multiSelectSection">
            <div className="checkBoxDropdown">
              {teamList?.length &&
                teamList.map(user => {
                  const { email, memberId, checked } = user;
                  return (
                    <CheckBoxComponent
                      key={email}
                      name={Utils.getFullNameFromUserInfo(user)}
                      id={memberId}
                      checked={checked}
                      cbk={getSelectedUser}
                    />
                  );
                })}
            </div>
          </div>
        )}
        {showTeamDropDown && (
          <div className="multiSelectSection">
            <div
              className={
                !teamLoader && teamMemberList?.length > 0
                  ? "teamdropDown"
                  : "errorTeamdropDown"
              }
            >
              {teamLoader && (
                <div className="loaderAndErrorPositioning">
                  <ButtonComponent
                    type="button"
                    className="loading overrideColor"
                    btnText=""
                    icon={false}
                    loader={true}
                    iconName="keyboard_arrow_down"
                  />
                </div>
              )}
              {!teamLoader && !teamMemberList?.length > 0 && (
                <div className="emptyDropdown">No teams available</div>
              )}
              {!teamLoader &&
                showTeamDropDown &&
                teamMemberList?.length > 0 && (
                  <FilterDropDownTeam
                    className="dashboard"
                    id={"team"}
                    showDropdown={true}
                    context="TeamMemberFilterContext"
                  />
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { UserDropdown, TeamMemberFilterContext };
export default UserDropdown;
