/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Utils from "Utils/utils";
import { sequenceRenderState } from "./sequenceProperties";
import { ReactComponent as ClickRateIcon } from "Assets/svg/clickRate.svg";
import { ReactComponent as ReplyRateIcon } from "Assets/svg/replyRate.svg";
import { ReactComponent as CalendarIcon } from "Assets/svg/calendar.svg";
import { sequenceContactData } from "Stores/sequence/Contact";
import { observer } from "mobx-react";
import { MP_EVENT } from "Static/constant";
import userDetail from "Stores/userDetail";

const SequenceStat = observer(props => {
  const {
    seqId = "",
    sequence = "",
    updateSequenceContacts = false,
    isContactUploading = false,
    analyticsLoader: loading = true,
    setAnalyticsLoader: setLoading = () => {},
    renderAnalyticsData: renderData = {},
    setUpdateSequenceContacts = () => {}
  } = props || {};
  const { calendarEnabled = false } = userDetail?.userInfo || {};

  const { calendarIntegrationEnabled = false } =
    userDetail?.userFeatureFlag || {};

  const contactStatsList = [
    {
      name: "Total",
      key: "total",
      value: Utils.formatNumberToString(renderData.contactsAdded),
      icon: "assignment_ind",
      bgColor: "#ffe6b5",
      show: true
    },
    {
      name: "Pending",
      key: "pending",
      value: Utils.formatNumberToString(renderData.pending),
      icon: "hourglass_top",
      bgColor: "#ffd982",
      show: true
    },
    {
      name: "Ongoing",
      key: "ongoing",
      value: Utils.formatNumberToString(renderData.ongoing),
      icon: "play_arrow",
      bgColor: "#c9fdf6",
      show: true
    },
    {
      name: "Completed",
      key: "completed",
      value: Utils.formatNumberToString(renderData.completedCampaigns),
      icon: "check",
      bgColor: "#c7c5f8",
      show: true
    },
    {
      name: "Replied",
      key: "replied",
      value: Utils.formatNumberToString(renderData.noOfCampaignsGotReply),
      svg: <ReplyRateIcon />,
      iconColor: "reply",
      bgColor: "#c1f9d3",
      show: true
    },
    {
      name: "Paused",
      key: "paused",
      value: Utils.formatNumberToString(renderData.paused),
      icon: "pause",
      iconColor: "paused",
      bgColor: "#efb79c",
      show: true
    },
    {
      name: "Failed",
      key: "failed",
      value: Utils.formatNumberToString(renderData.emailsFailed),
      icon: (
        <span className="material-icons-outlined errorOutlineIcon">
          error_outline
        </span>
      ),
      iconColor: "failed",
      bgColor: "#fa8282",
      show: true
    },
    {
      name: "Bounced",
      key: "bounced",
      value: Utils.formatNumberToString(renderData.emailsBounced),
      icon: "call_missed_outgoing",
      bgColor: "#f7cbcd",
      show: true
    },
    {
      name: "Opted Out",
      key: "optedOut",
      value: Utils.formatNumberToString(renderData.optedOut),
      icon: "not_interested",
      bgColor: "#f5d1ff",
      show: true
    },
    {
      name: "Meetings Booked",
      key: "meetingBooked",
      value: Utils.formatNumberToString(renderData.meetingBookedCount),
      svg: <CalendarIcon />,
      iconColor: "meetingBooked",
      bgColor: "#bdb8e0",
      show: calendarEnabled && calendarIntegrationEnabled
    }
  ];

  const emailStatsList = [
    {
      name: "Emails Sent",
      key: "emailSent",
      value: Utils.formatNumberToString(renderData.emailsSent),
      icon: "send",
      bgColor: "#e7e7e7",
      show: true
    },
    {
      name: "Open Rate",
      key: "openRate",
      value:
        typeof renderData.emailsOpened !== "undefined" &&
        renderData.emailsOpened
          ? Utils.getPercentage(
              renderData.emailsOpened,
              renderData.emailsSent,
              1
            ) + "%"
          : "0%",
      icon: "visibility",
      bgColor: "#f9dec1",
      show: true
    },
    {
      name: "Click Rate",
      key: "clickRate",
      value:
        typeof renderData.emailsClicked !== "undefined" &&
        renderData.emailsClicked
          ? Utils.getPercentage(
              renderData.emailsClicked,
              renderData.emailsSent,
              1
            ) + "%"
          : "0%",
      svg: <ClickRateIcon />,
      bgColor: "#d3ffc4",
      show: true
    },
    {
      name: "Reply Rate",
      key: "replyRate",
      value:
        typeof renderData.noOfCampaignsGotReply !== "undefined" &&
        renderData.noOfCampaignsGotReply
          ? Utils.getPercentage(
              renderData.noOfCampaignsGotReply,
              renderData.emailsSent,
              1
            ) + "%"
          : "0%",
      svg: <ReplyRateIcon />,
      bgColor: "#f5d1ff",
      show: true
    }
  ];

  const analyticsList = [
    {
      title: "Contacts",
      processingText: isContactUploading === "INPROGRESS" || "",
      list: contactStatsList,
      loader: loading,
      className: "seqContacts",
      loaderCount: calendarEnabled && calendarIntegrationEnabled ? 10 : 9,
      key: "seqContactsStatsCard"
    },
    {
      title: "Emails",
      processingText: false,
      list: emailStatsList,
      loader: loading,
      className: "seqEmails",
      loaderCount: 4,
      key: "seqEmailStatsCard"
    }
  ];

  useEffect(() => {
    sequenceContactData.getSeqAnalyticsTab(seqId);
  }, []);

  useEffect(() => {
    setLoading(true);
    sequenceContactData.getSeqAnalyticsTab(seqId);
    updateSequenceContacts && setUpdateSequenceContacts(false);
  }, [
    sequenceRenderState.contactOwner.all,
    sequenceRenderState.contactOwner.idArr,
    updateSequenceContacts
  ]);

  useEffect(() => {
    if (seqId) {
      Utils.mixpanelTrack(MP_EVENT.SQ_ANAL_VIEWED, {
        pageType: "Sequence Analytics",
        totalContactcs: sequence?.contactsAdded || 0,
        sequenceName: sequence?.name || seqId
      });
    }
  }, []);

  return (
    <div className="seqAnalyticsSection">
      {analyticsList?.length &&
        analyticsList?.map(item => <StatsCardValue {...item} key={item.key} />)}
    </div>
  );
});

const StatsCardValue = props => {
  const {
    title = "",
    processingText = false,
    list = [],
    loader = true,
    className = "",
    loaderCount = 1
  } = props || {};

  return (
    <div className={`${className}Section`} key={className}>
      <h6>
        {title} {processingText ? <ProcessingTextLoader /> : ""}
      </h6>
      <ul className={className}>
        {!loader &&
          list?.length &&
          list?.map(item => {
            const {
              key = "",
              name = "",
              value = 0,
              icon = "",
              bgColor = "",
              svg = "",
              show = false
            } = item || {};
            return (
              show && (
                <li key={key}>
                  <p>{name}</p>
                  <h4>{value}</h4>
                  <span className="badgeIcon" style={{ background: bgColor }}>
                    {(icon && (
                      <i className="material-icons-outlined">{icon}</i>
                    )) ||
                      svg}
                  </span>
                </li>
              )
            );
          })}
        {loader &&
          Array.from({ length: loaderCount }, (value, index) => (
            <li className="linear-background" key={`${className}${index}`} />
          ))}
      </ul>
    </div>
  );
};

const ProcessingTextLoader = () => {
  return (
    <span className="contactUploadMsg">
      <span className="circleLoader animatedButton loading">
        <span className="load-text" />{" "}
      </span>
      &nbsp;We are processing your contacts
    </span>
  );
};

export default SequenceStat;
export { SequenceStat };
