import React from "react";

const ContactFilteredFacets = props => {
  const {
    store,
    searchBoxRef,
    clearSelectedValue = () => {},
    filterContactsByTags = () => {},
    filterAnalyticsByTags = () => {},
    heading,
    setOpenTagFilter = () => {},
    setOpenOwnerFilter = () => {},
    contactCount = 0
  } = props;

  const onFilteredValueClick = () => {
    setOpenTagFilter(heading === "tags");
    setOpenOwnerFilter(heading === "owner");
    searchBoxRef && searchBoxRef.current && searchBoxRef.current.focus();
  };

  const onClickCloseIcon = () => {
    store.setFilteredData([]);
    store.setFilteredId([]);
    filterContactsByTags();
    filterAnalyticsByTags();
    clearSelectedValue();
  };

  return (
    <div
      className={`filteredValueWrapper ${contactCount > 0 ? "" : "disabled"}`}
    >
      <div className={`filteredValueContainer`} onClick={onFilteredValueClick}>
        <span className="filterSelectionValue">{heading}</span>
        <span className="filteredValues">{store?.filteredData?.join(",")}</span>
      </div>
      <i className="material-icons closeIcon" onClick={onClickCloseIcon}>
        close
      </i>
    </div>
  );
};

export default ContactFilteredFacets;
export { ContactFilteredFacets };
