/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import TeamStore from "Stores/Team";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import TeamTableLoader from "./TeamTableLoader";
import { ReactComponent as RejectedIcon } from "Assets/svg/notContacted.svg";
import { ReactComponent as InactiveIcon } from "Assets/svg/Inactive.svg";
import { ReactComponent as ActiveIcon } from "Assets/svg/contacted.svg";
import { ReactComponent as InviteIcon } from "Assets/svg/sequence.svg";
import { ReactComponent as NotContactedIcon } from "Assets/svg/notContacted.svg";
import DropdownMenuComponent from "Components/common/DropDownMenu";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";

export const TeamMemberTableComponent = observer(props => {
  const userData = userDetail?.userFeatureFlag || {};

  const { loader = false, teamData = {} } = props || {};
  const [teamMember, setTeamMember] = useState(teamData);
  const [selectedUser, setSelectedUser] = useState({});

  const showDropdownOptions = (event, user, value) => {
    const { members = [] } = teamMember || {};
    members?.length > 0 &&
      members.map(item => {
        if (item?.id === user?.id) {
          item.parentPosition = event && event.pageY;
          if (value) {
            setSelectedUser({});
            item.teamOptions = false;
          } else {
            setSelectedUser(user);
            item.teamOptions = !item.teamOptions;
          }
          return true;
        }
      });
  };
  const showTableRow = (status = "") => {
    if (status) {
      updateSeatCount(status);
    }
    const { members = [] } = teamMember || {};
    const { email = "" } = selectedUser || {};
    members?.length > 0 &&
      members.map(item => {
        if (item?.email === email) {
          item.rowLoader = !item.rowLoader;
          return true;
        }
      });
  };
  const updateSeatCount = status => {
    if (status === "ACTIVE") {
      userData.noOfSeatsUsed += 1;
      userDetail.setUserFeatureFlag(userData);
    } else if (status === "INACTIVE" || status === "CANCEL") {
      userData.noOfSeatsUsed -= 1;
      userDetail.setUserFeatureFlag(userData);
    }
  };
  const resendInivite = () => {
    showTableRow();
    showDropdownOptions("", selectedUser, false);
    const { email } = selectedUser;
    let name =
      getFullName(selectedUser) === "--" ? email : getFullName(selectedUser);
    TeamStore.resendInvite(email, name, showTableRow);
  };
  const editMember = async () => {
    showDropdownOptions("", selectedUser, false);
    if (await checkTeamSubscriptionActive()) {
      props.editCbk(
        selectedUser,
        checkAdminUser(selectedUser.email, getEmail())
      );
    }
  };
  const getEmail = () => {
    return userDetail?.userInfo?.email;
  };
  const activeUser = () => {
    showDropdownOptions("", selectedUser, false);
    confirmPopupAction("ACTIVE");
  };
  const deactiveUser = () => {
      showDropdownOptions("", selectedUser, false);
      confirmPopupAction("INACTIVE");
    },
    cancelInvite = () => {
      showDropdownOptions("", selectedUser, false);
      confirmPopupAction("CANCEL");
    };
  const inviteRejectedUser = () => {
    showDropdownOptions("", selectedUser, false);
    confirmPopupAction("INVITE");
  };
  const checkAdminUser = (teamUser, loginUser) => {
    return teamUser === loginUser;
  };
  const getFullName = member => {
    const { firstName = "", lastName = "" } = member || {};
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return "--";
  };
  const getJobTitle = title => {
    return title || "--";
  };
  const dropdownOption = list => {
    const { role = "", status = " ", email = "" } = list || {};
    if (
      (role === "Administrator" || role === "ADMIN") &&
      checkAdminUser(email, getEmail())
    ) {
      return adminOptions;
    } else {
      switch (status.trim()) {
        case "ACTIVE":
          return activeOptions;
        case "INACTIVE":
          return deactiveOptions;
        case "INVITED":
          return invitedOptions;
        case "REJECTED":
          return rejectedOptions;
        case "CANCEL":
          return cancelInvite;
        default:
          return adminOptions;
      }
    }
  };
  const getStatusText = status => {
    switch (status.toLowerCase()) {
      case "inactive":
        return "deactivate";
      case "active":
        return "reactive";
      case "cancel":
        return "cancel the invitation sent to";
      case "invite":
        return "invite again";
      default:
        break;
    }
  };
  const triggerStatusFn = (status, name, email) => {
    switch (status.toLowerCase()) {
      case "inactive":
        TeamStore.deactiveUser(email, name, showTableRow);
        break;
      case "active":
        TeamStore.activeUser(email, name, showTableRow);
        break;
      case "cancel":
        TeamStore.cancelInvite(email, name, showTableRow);
        break;
      case "invite":
        TeamStore.inviteRejectedUser(email, name, showTableRow);
        break;
      default:
        break;
    }
    confirmationPopupState.setShowPopup(false);
  };
  const confirmPopupAction = status => {
    const { email = "" } = selectedUser || {};
    let name =
      getFullName(selectedUser) === "--" ? email : getFullName(selectedUser);
    confirmationPopupState.setPopupValues({
      title: "Are you sure?",
      content: `Are you sure you want to ${getStatusText(status)} ${name}?`,
      actionBtnText: "Confirm",
      callback: () => {
        showTableRow();
        triggerStatusFn(status, name, email);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  // property binding
  const adminOptions = [
    {
      icon: {
        ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">edit</i>',
        style: {}
      },
      value: "Edit",
      key: "editMember",
      method: editMember
    }
  ];

  const activeOptions = [
    {
      icon: {
        ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">edit</i>',
        style: {}
      },
      value: "Edit",
      key: "editMember",
      method: editMember
    },
    {
      icon: {
        ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">airplanemode_inactive</i>',
        style: {}
      },
      value: "Deactivate",
      key: "deactiveUser",
      method: deactiveUser
    }
  ];

  const deactiveOptions = [
    {
      icon: {
        ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">airplanemode_active</i>',
        style: {}
      },
      value: "Activate",
      key: "activeUser",
      method: activeUser
    }
  ];

  const rejectedOptions = [
    {
      icon: {
        ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">edit</i>',
        style: {}
      },
      value: "Edit",
      key: "editMember",
      method: editMember
    },
    {
      icon: {
        ele: <InviteIcon />,
        style: { width: "14px", height: "14px" }
      },
      value: "Invite",
      key: "inviteRejectedUser",
      method: inviteRejectedUser
    }
  ];

  const invitedOptions = [
      {
        icon: {
          ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">edit</i>',
          style: {}
        },
        value: "Edit",
        key: "editMember",
        method: editMember
      },
      {
        icon: {
          ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">email</i>',
          style: {}
        },
        value: "Resend Invite",
        key: "resendInivite",
        method: resendInivite
      },
      {
        icon: {
          ele: <NotContactedIcon />,
          style: {}
        },
        value: "Cancel Invite",
        key: "cancelInvite",
        method: cancelInvite
      }
    ],
    selectedOption = "";

  const getTeamNames = groups => {
    const groupLength = groups?.length || 0;
    if (groupLength > 0) {
      return (
        <div className="groupNameDetails">
          <div className="groupName">{groups?.[0]?.name || ""}</div>
          {groupLength > 1 && (
            <div className="remainingGroupCount">{`+${groupLength - 1}`}</div>
          )}
        </div>
      );
    } else {
      return <div className="groupName">--</div>;
    }
  };

  useEffect(() => {
    teamData && setTeamMember(teamData);
  }, [teamData]);

  const headerObject = {
    fullName: "Full Name",
    emailAddress: "Email Address",
    jobTitle: "Job Title",
    teamName: "Team",
    role: "Role",
    status: "Status",
    moreOptions: ""
  };

  return (
    <div className="tableSection">
      <div className="headerPadding">
        <div className={`tableHeader ${loader ? "loadingData" : ""}`}>
          {!loader &&
            Object.keys(headerObject).map(keyVal => (
              <div key={keyVal} className={keyVal}>
                {headerObject[keyVal] || ""}
              </div>
            ))}
        </div>
      </div>
      <div className="tableBody">
        {!loader &&
          teamMember &&
          Object.keys(teamMember)?.length > 0 &&
          teamMember?.members?.length > 0 &&
          teamMember.members.map(list => {
            const {
              rowLoader = false,
              email = "",
              role = "",
              jobTitle = "",
              status = "",
              id = "",
              groups = []
            } = list || {};
            return list && !rowLoader ? (
              (status !== "INACTIVE" && (
                <div className="tableRow" key={email}>
                  <div className="fullName">{getFullName(list)}</div>
                  <div className="emailAddress">{email || ""}</div>
                  <div className="jobTitle">{getJobTitle(jobTitle || "")}</div>
                  <div className="teamName">{getTeamNames(groups)}</div>
                  <div className="role">
                    {role === "ADMIN" ? "Admin" : "User"}
                    {checkAdminUser(email, getEmail()) && (
                      <span className="profileUser">&nbsp;(Me)</span>
                    )}
                  </div>
                  <div
                    className={`status ${
                      status === "ACTIVE"
                        ? "active"
                        : status === "INVITED"
                        ? "invited"
                        : status === "REJECTED"
                        ? "rejected"
                        : "inactive"
                    }`}
                  >
                    {status === "ACTIVE" ? (
                      <ActiveIcon />
                    ) : status === "INVITED" ? (
                      <InviteIcon />
                    ) : status === "REJECTED" ? (
                      <RejectedIcon />
                    ) : (
                      <InactiveIcon />
                    )}
                    {
                      <span className="statusName">
                        {status?.toLowerCase() || ""}
                      </span>
                    }
                  </div>
                  <div className="moreOptions">
                    {
                      <i
                        className="material-icons-outlined icon-hover-btn"
                        onClick={e => showDropdownOptions(e, list)}
                        title="More Options"
                        id={id}
                      >
                        more_vert
                      </i>
                    }
                    <>
                      {list.teamOptions && (
                        <>
                          <DropdownMenuComponent
                            options={dropdownOption(list)}
                            selected={selectedOption}
                            referenceId={id}
                            direction="left"
                            parentPosition={list.parentPosition}
                          />
                          <div
                            className="dropdown-overlay"
                            onClick={e => showDropdownOptions(e, list, false)}
                          />
                        </>
                      )}
                    </>
                  </div>
                </div>
              )) ||
                ""
            ) : (
              <TeamTableRowLoader />
            );
          })}
        {!loader && teamMember?.members?.length < 1 && (
          <div className="noTeamMember">
            <i className="material-icons-outlined">people_outline</i>
            No user found
          </div>
        )}
        {loader && <TeamTableLoader count={20} value="multiRowLoader" />}
      </div>
    </div>
  );
});

const TeamTableRowLoader = () => {
  const columnList = [
    "fullName",
    "emailAddress",
    "jobTitle",
    "role",
    "status",
    "moreOptions"
  ];
  return (
    <div className="tableRowLoader" key="singleRowLoader">
      {columnList.map(item => (
        <div className={item} key={item}>
          {item === "status" ? (
            <>
              <span className="linear-background icon" />
              <span className="linear-background name" />
            </>
          ) : (
            <span className="linear-background" />
          )}
        </div>
      ))}
    </div>
  );
};

export default TeamMemberTableComponent;
