import React, { useState } from "react";
import { observer } from "mobx-react";
import DateUtils from "Utils/DateUtils";
import makeApi, { URLS } from "Utils/apiURL";
import Button from "Components/common/Button";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import { salesforceConnectedData } from "../salesforce/SalesforceConnectedData";

const ImportNow = observer(props => {
  const {
    lastSynced = "",
    listId = "",
    type = "CONTACTS",
    listChanged = false,
    crmName = "",
    hasConditions = false,
    resumeDate = ""
  } = props;

  const [loading, setLoading] = useState(false);
  const { apiCallsUsed = 0 } = salesforceConnectedData?.salesForceData || {};

  const showResumeMsg =
    salesforceConnectedData?.apiLimitPerDay <= apiCallsUsed && resumeDate;
  const isSalesforce =
    crmName?.toLowerCase() === "salesforce" && (hasConditions || showResumeMsg);

  const getLastSyncedDate = () => {
    if (lastSynced) {
      const { date: lastSyncedDate = "", time: lastSyncedTime = "" } =
        DateUtils.getFormattedDateAndTimeForCrm(lastSynced) || {};
      return `${lastSyncedDate}, ${lastSyncedTime}`;
    }
    return null;
  };

  const handleImportNowAction = async () => {
    if ((listId && !listChanged) || isSalesforce) {
      setLoading(true);
      const config = {
        url:
          crmName?.toLowerCase() === "salesforce"
            ? `${URLS.syncSfNow}/${type}`
            : crmName === "Zoho"
            ? `${URLS.syncNow}/${type}`
            : crmName === "Pipedrive"
            ? `${URLS.syncPipedriveNow}`
            : `${URLS.syncHubspotNow}`,
        method: "POST"
      };
      const response = await makeApi(config);
      if (response?.data) {
        const { responseCode = "" } = response?.data || {};
        setLoading(false);
        responseCode === 200
          ? toasterState.setToastMsg(
              "Contact sync has been initiated successfully.",
              "success"
            )
          : toasterState.setToastMsg(
              "Failed import contacts. Please try again later.",
              "failure"
            );
      }
    } else {
      confirmationPopupState.setPopupValues({
        content: listChanged
          ? "Please save your changes before trying to sync contacts"
          : `Please select a ${crmName} list view from which you would like to import contacts.`,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => {
          confirmationPopupState.setShowPopup(false);
        }
      });
      confirmationPopupState.setShowPopup(true);
    }
  };

  const lastFetchedDate = getLastSyncedDate();

  const getResumeMsg = () => {
    if (showResumeMsg) {
      const { date = "", time = "" } =
        DateUtils.getFormattedDateAndTimeInFormat(resumeDate) || {};
      const expectedDateGTM = DateUtils.getTimeZone(resumeDate);
      return (
        <div className="resumeDateText">
          {`Polling has been paused temporarily as the maximum of ${salesforceConnectedData.apiLimitPerDay} requests
      per 24 hour limit reached. It will resume automatically on ${date}
      at ${time} (${expectedDateGTM}).`}
        </div>
      );
    }
  };

  return (
    <div className="importNowWrapper">
      <Button
        id="importNowBtn"
        name="importNowBtn"
        type="button"
        className={`importNowBtn ${loading ? "loading" : ""}`}
        btnText="Sync Now"
        icon={false}
        loader={loading}
        iconName=""
        click={handleImportNowAction}
        disabled={
          crmName?.toLowerCase() === "salesforce" ? !isSalesforce : false
        }
      />
      {showResumeMsg
        ? getResumeMsg()
        : lastFetchedDate && (
            <div className="lastSynced">
              <span>Last fetched: </span>
              <span>{lastFetchedDate}</span>
            </div>
          )}
    </div>
  );
});

export { ImportNow };
export default ImportNow;
