import React from "react";
import { observer } from "mobx-react";

const ContactInputTag = observer(props => {
  const {
    required,
    value,
    name,
    type,
    id,
    text,
    onBlurEvent,
    readOnly = false,
    errMsg,
    disableCheck = false,
    planName = "free"
  } = props;

  const getClassName = () => {
    let className = "inputCont ";
    if (value && value.length) {
      className += "filled ";
    }
    if (readOnly) {
      className += "readOnlyInput";
    }
    return className;
  };

  return (
    <div className="inputWrapper">
      <div className={getClassName()}>
        <input
          required={required ? true : false}
          className="input-box"
          name={name}
          id={id}
          readOnly={readOnly}
          type={type ? type : "text"}
          value={value ? value : ""}
          onChange={e => onBlurEvent(id, e.target.value)}
          disabled={disableCheck}
          planname={planName}
        />
        <span className="focus-border"></span>
        <label htmlFor={name} className="inputLabel">
          {text} {required && <sup>*</sup>}
        </label>
      </div>
      {errMsg && <span className="errMsg">{errMsg}</span>}
    </div>
  );
});

export default ContactInputTag;
export { ContactInputTag };
