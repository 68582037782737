/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router-dom";
import { salesforceConnectedData } from "Pages/Dashboard/settings/Crm/salesforce/SalesforceConnectedData";
import Button from "Components/common/Button";
import { sequenceRenderState } from "Pages/Dashboard/sequences/SequencePage/sequenceProperties";
import RadioBtnComponent from "Components/common/radioBtn";
import SalesforceLoginCard from "Components/Salesforce/SalesforceLoginCard";
import ExportSuccessCard from "Components/Salesforce/ExportSuccessCard";
import JumpBallLoader from "Components/common/JumpBallLoader";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import { MP_EVENT } from "Static/constant";
import Utils from "Utils/utils";
import { filterByTagsState } from "Components/common/ContactFilter/FilterByContactTags";
import { filterByOwnerState } from "Components/common/ContactFilter/FilterByContactOwner";

const ExportContactsToSalesforce = props => {
  let location = useLocation();
  let { id, sharedId } = useParams();

  const {
    showSfCard,
    contactIds = [],
    stage = "TOTAL",
    resetContactCbk = () => {},
    allContactSelected = false,
    setSfCard = () => {},
    searchEmail = null
  } = props || {};

  const [sfConnect, setSfConnect] = useState(false);
  const [showExportSuccessCard, setExportSuccessCard] = useState(false);
  const [exportType, setExportType] = useState("LEAD");
  const [leadChecked, setLeadChecked] = useState(true);
  const [contactChecked, setContactChecked] = useState(false);
  const [doNothing, setDoNothing] = useState(true);
  const [showExpProgressLoader, setExpProgressLoader] = useState(false);
  const [exportResStatus, setExportResStatus] = useState(null);

  const getSelectedValue = (e, value) => {
    if (value === "exportTypeLead") {
      setLeadChecked(true);
      setExportType("LEAD");
      setContactChecked(false);
    } else if (value === "exportTypeContact") {
      setContactChecked(true);
      setExportType("CONTACT");
      setLeadChecked(false);
    } else if (value === "updateContSf") {
      setDoNothing(true);
    }
  };

  const resetAndClosePopup = () => {
    setExportType("LEAD");
    setLeadChecked(true);
    setContactChecked(false);
    setDoNothing(true);
  };

  const contructPayload = () => {
    const payload = {
      type: exportType,
      doNothing
    };
    // Sequences Filter
    const { all, idArr } = sequenceRenderState.contactOwner;
    if (sharedId || id) {
      payload.sequenceId = sharedId ? sharedId : id;
      payload.sequenceFilter = {};
      if (idArr.length > 0) {
        payload.sequenceFilter.memberIds = idArr;
      } else {
        payload.sequenceFilter.all = all;
      }
      if (stage && stage !== "TOTAL") {
        if (stage === "OPENCOUNT") {
          payload.sequenceFilter = { filterKey: "openCount" };
        } else if (stage === "CLICKCOUNT") {
          payload.sequenceFilter = { filterKey: "clickCount" };
        } else {
          payload.sequenceFilter.statuses =
            stage === "ONGOING" ? ["ACTIVE"] : [stage];
        }
      }
      searchEmail && (payload.sequenceFilter = { email: searchEmail });
    } else {
      // Contacts Filter
      if (stage && stage !== "TOTAL") {
        if (stage === "OPENCOUNT") {
          payload.filter = { filterKey: "openCount", stage: null };
        } else if (stage === "CLICKCOUNT") {
          payload.filter = { filterKey: "clickCount", stage: null };
        } else {
          payload.filter = {
            stage: stage === "ONGOING" ? "ACTIVE" : stage,
            filterKey: null
          };
        }
      }
      payload.filter = {
        ...payload.filter,
        ...(allContactSelected && { tagIds: filterByTagsState.filteredId }),
        ...(allContactSelected && { memberIds: filterByOwnerState.filteredId })
      };
    }
    !allContactSelected && (payload.contactIds = contactIds);
    return payload;
  };

  const exportContactToSalesforce = async () => {
    try {
      setSfCard(false);
      setExpProgressLoader(true);
      const data = contructPayload();
      const config = {
        url: URLS.bulkExpContToSF ? URLS.bulkExpContToSF : "",
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (
        res?.data?.responseCode !== 403 &&
        Object.keys(res?.data).length > 0
      ) {
        setExportResStatus(res.data);
        resetContactCbk();
        resetAndClosePopup();
        Utils.mixpanelTrack(MP_EVENT.ADD_TO_SALESFORCE, {
          contacts: res.data.total,
          emailCount: res.data.success,
          pageType: location.pathname
        });
        setExpProgressLoader(false);
        setExportSuccessCard(true);
      } else if (
        res?.data?.responseCode === 403 &&
        Object.keys(res?.data).length > 0
      ) {
        setExpProgressLoader(false);
        toasterState.setToastMsg(
          "Sorry, we don't have API access to export contacts. Please contact your Salesforce administrator",
          "failure"
        );
      } else {
        setExpProgressLoader(false);
        toasterState.setToastMsg(
          "Something went wrong. please try again later",
          "failure"
        );
      }
    } catch (e) {
      console.error("Export Contact to Salesforce Failed", e);
    }
  };

  const closeSuccessPopup = () => {
    setExportSuccessCard(false);
  };

  const hideCard = () => {
    if (showSfCard) {
      setSfCard(false);
      resetAndClosePopup();
    }
  };

  const isSalesforceConnected = () => {
    if (
      salesforceConnectedData?.salesForceData &&
      Object.keys(salesforceConnectedData?.salesForceData).length
    ) {
      const { salesforceConnected = false } =
        salesforceConnectedData?.salesForceData || {};
      setSfConnect(salesforceConnected);
    }
  };

  useEffect(() => {
    isSalesforceConnected();
  }, [salesforceConnectedData.salesForceData]);

  useEffect(() => {
    isSalesforceConnected();
  }, []);

  return (
    <div className={`exportToSalesforceSection`}>
      {showSfCard && <div className="overlaySFBg" onClick={hideCard} />}
      {!sfConnect && showSfCard && <SalesforceLoginCard cbk={hideCard} />}
      {showExportSuccessCard && sfConnect && (
        <ExportSuccessCard data={exportResStatus} cbk={closeSuccessPopup} />
      )}
      {showSfCard && sfConnect && (
        <div className="exportSalesforceCard">
          <div className="cardTitle">
            <h6>Add to Salesforce</h6>
          </div>
          <div className="cardBody">
            <div className="exportTypeList">
              <p className="radioTitle">New records</p>
              <RadioBtnComponent
                key="exportTypeLead"
                name="sfExportType"
                id="exportTypeLead"
                value="Add as a Lead"
                defaultChecked={leadChecked}
                radioOnchange={getSelectedValue}
              />
              <RadioBtnComponent
                key="exportTypeContact"
                name="sfExportType"
                id="exportTypeContact"
                value="Add as a Contact"
                defaultChecked={contactChecked}
                radioOnchange={getSelectedValue}
              />
            </div>
            <div className="exportUpdateList">
              <p className="radioTitle">Existing records</p>
              <RadioBtnComponent
                key="updateContSf"
                name="updateExportContSf"
                id="updateContSf"
                value="Do nothing"
                defaultChecked={doNothing}
                radioOnchange={getSelectedValue}
              />
            </div>
            {/* <div className="exportUpcomingList">
              <div className="commingSoonBadge">Coming Soon</div>
              <RadioBtnComponent
                key="pdSfToCont"
                name="pullContactBtwApp"
                id="pdSfToCont"
                value="Pull data from Salesforce to Salesgear"
                defaultChecked={false}
                radioOnchange={() => {}}
                disable={true}
              />
              <RadioBtnComponent
                key="pdContToSf"
                name="pullContactBtwApp"
                id="pdContToSf"
                value="Pull data from Salesgear to Salesforce"
                defaultChecked={false}
                radioOnchange={() => {}}
                disable={true}
              />
            </div> */}
          </div>
          <div className="cardFooter">
            <Button
              id="expContToSfBtn"
              name="expContToSfBtn"
              btnText="Submit"
              icon={false}
              iconName=""
              type="button"
              className=""
              loader={false}
              click={exportContactToSalesforce}
            />
          </div>
        </div>
      )}
      {showExpProgressLoader && (
        <>
          <div className="sfExportSuccessSection">
            <div className="card animated customzoomIn">
              <h6>Export to Salesforce</h6>
              <div className="expContactInfoBoxLoader">
                <JumpBallLoader />
              </div>
            </div>
          </div>
          <div className="overlayExpMask"></div>
        </>
      )}
    </div>
  );
};

export default observer(ExportContactsToSalesforce);
export { ExportContactsToSalesforce };
