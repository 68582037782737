export const initialScreenConfig = [
  {
    subLabel: true,
    subLabelContent: `Click on the <i class="material-icons-outlined blueSettings">settings</i> in the top right corner.`,
    content: `On Gmail, go to Settings.`
  },
  {
    subLabel: false,
    content: `Click on '<strong>See all Settings</strong>'.`
  },
  {
    subLabel: false,
    content: `Click on '<a class="hasLink" target="_blank" href="https://mail.google.com/mail/u/0/#settings/fwdandpop">Forwarding and POP/IMAP</a>'.`
  },
  {
    subLabel: false,
    content: `Find '<strong>IMAP access</strong>' and select '<strong>Enable IMAP</strong>'`
  },
  {
    subLabel: false,
    content: `Click <strong>Save Changes.</strong>`
  }
];

export const ChooseToConnectConfig = [
  {
    label: "Option 1: OAuth",
    subLabel: "Allow Salesgear to access your Google Workspace",
    recommended: true,
    id: "oAuth"
  },
  {
    id: "appPassword",
    label: "Option 2: App Password",
    subLabel: "Enable 2-step verification & generate App password"
  }
];

export const OAuthScreenConfig = [
  {
    content: `Go to your <a class="hasLink" target="_blank" href="https://admin.google.com/u/1/ac/owl/list?tab=configuredApps">G-Suite Admin</a>.`
  },
  {
    content: `Click "<strong>Add App</strong>" and select "<strong>OAuth App Name or Client ID</strong>"`
  },
  {
    content: `Copy the below client ID to search for Salesgear`,
    hasCopyContent: true
  },
  {
    content: `Select Salesgear and approve`
  }
];

export const appPasswordScreenConfig = [
  {
    content: `Go to your Google Account's <a class="hasLink" target="_blank" href="https://myaccount.google.com/u/1/signinoptions/two-step-verification/enroll-welcome">Security Settings</a>.`
  },
  {
    content: `Enable <span class="highlightBlue">2-Step Verification</a>`
  },
  {
    content: `Create an <span class="highlightBlue">App Password</a>`,
    subLabel: true,
    subLabelContent: `Select 'Other' for both App and Device`
  }
];
