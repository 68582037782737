import { observable, action } from "mobx";
import { URLS, makeApi } from "Utils/apiURL";
import { linkedInCookiesState } from "Stores/LinkedInCookies";
import { toasterState } from "Components/common/toaster";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { connectedLinkedInHeaderState } from "./state/connectedLinkedInHeaderState";
import userDetail from "Stores/userDetail";

class LinkedInReplyState {
  @observable linkedInConnected = true;
  @observable linkedInAccounts = {};
  @observable linkedInConnectionLoading = false;
  @observable showLinkedInNotConnectedPopup = false;
  @observable showLinkedInDetailsPopup = false;
  @observable linkedInConnectBtnLoading = false;
  @observable showConfigure = false;
  @observable configureData = {};
  @observable linkedInDataLoading = true;
  @observable linkedInMsg = "";

  @action
  setLinkedInConnected = (value = false) => {
    this.linkedInConnected = value;
  };

  @action
  setShowLinkedInNotConnectedPopup = (value = false) => {
    this.showLinkedInNotConnectedPopup = value;
  };

  @action
  setLinkedInConnectionLoading = (value = false) => {
    this.linkedInConnectionLoading = value;
  };

  @action
  setShowLinkedInDetailsPopup = (value = false) => {
    this.showLinkedInDetailsPopup = value;
  };

  @action
  setLinkedInAccounts = (value = {}) => {
    this.linkedInAccounts = value;
  };

  @action
  setLinkedInConnectBtnLoading = (value = false) => {
    this.linkedInConnectBtnLoading = value;
  };

  @action
  setShowConfigure = (value = false) => {
    this.showConfigure = value;
  };

  @action
  setConfigureData = (value = false) => {
    this.configureData = value;
  };

  @action
  setLinkedInDataLoading = (value = false) => {
    this.linkedInDataLoading = value;
  };

  @action
  setLinkedInMsg = (value = "") => {
    this.linkedInMsg = value;
  };

  @action
  connectLinkedInAccount = async () => {
    const { noOfLiAccountsUsed = 0 } = userDetail?.userFeatureFlag || {};
    const data = {
      licookie: linkedInCookiesState?.cookieLiAt,
      publicId: linkedInCookiesState?.profileData?.publicId || "",
      userAgent: navigator?.userAgent || ""
    };
    if (linkedInCookiesState?.isPremiumAccount) {
      data.liPremiumCookie = linkedInCookiesState?.cookieLiA;
    }
    try {
      const config = {
        url: URLS.connectLinkedInAccount,
        method: "POST",
        data
      };
      let res = await makeApi(config);
      linkedInReplyState.setShowLinkedInDetailsPopup(false);
      linkedInReplyState.setLinkedInConnectBtnLoading(false);
      if (res?.data) {
        const { responseCode = "" } = res.data || {};
        if (+responseCode === 200) {
          this.setLinkedInDataLoading(true);
          this.getLinkedInAccounts();
          toasterState.setToastMsg(
            "LinkedIn account connected successfully.",
            "success"
          );
          userDetail.setUserFeatureFlag({
            ...userDetail.userFeatureFlag,
            noOfLiAccountsUsed: noOfLiAccountsUsed + 1
          });
        } else if (+responseCode === 400) {
          confirmationPopupState.setPopupValues({
            content: `We are unable to connect your LinkedIn account. Please contact our support team for assistance. You can reach us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or use the chat window below.`,
            actionBtnText: "Chat with Support",
            cancelBtnText: "Close",
            id: "my_custom_link",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        } else if (+responseCode === 409) {
          confirmationPopupState.setPopupValues({
            content: `Failed to connect your LinkedIn. This LinkedIn account is already connected to a different Salesgear account. Please try connecting a different account.`,
            actionBtnText: "Ok",
            cancelBtnText: "Close",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        } else if (+responseCode === 500) {
          confirmationPopupState.setPopupValues({
            content: `Oops! Something went wrong on our end. Please contact our support team for assistance. You can reach us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or use the chat window below.`,
            actionBtnText: "Chat with Support",
            cancelBtnText: "Close",
            id: "my_custom_link",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
      }
    } catch (e) {
      console.error("LinkedIn connection failed error", e);
    }
  };

  @action
  getLinkedInAccounts = async () => {
    try {
      const config = {
        url: URLS.getConnectedLinkedInAccounts,
        method: "POST",
        data: {
          pageNo: 1,
          limit: 5,
          searchTerm: connectedLinkedInHeaderState?.searchTerm || "",
          ...connectedLinkedInHeaderState?.memberIds,
          ...connectedLinkedInHeaderState?.dateFilter
        }
      };
      let res = await makeApi(config);
      this.setLinkedInDataLoading(false);
      if (res?.data) {
        const { accounts } = res?.data || {};
        this.setLinkedInConnected(accounts?.length > 0);
        this.setLinkedInAccounts(res?.data);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (e) {
      console.error("LinkedIn accounts get failed error", e);
    }
  };

  @action
  deleteLinkedInAccounts = async (accountId = "") => {
    const { noOfLiAccountsUsed = 0 } = userDetail?.userFeatureFlag || {};
    if (accountId) {
      try {
        const config = {
          url: `${URLS.deleteLinkedInAccounts}/${accountId}`,
          method: "POST",
          data: {}
        };
        let res = await makeApi(config);
        if (res?.data) {
          confirmationPopupState.setShowPopup(false);
          const { responseCode = "" } = res?.data || {};
          if (+responseCode === 200) {
            toasterState.setToastMsg(
              "LinkedIn account deleted successfully",
              "success"
            );
            userDetail.setUserFeatureFlag({
              ...userDetail.userFeatureFlag,
              noOfLiAccountsUsed: noOfLiAccountsUsed - 1
            });
            this.setLinkedInDataLoading(true);
            this.getLinkedInAccounts();
          } else {
            if (res?.status === 401) {
              toasterState.setToastMsg("UNAUTHORIZED Access to delete", "fail");
            } else if (res?.status === 500) {
              confirmationPopupState.setPopupValues({
                content: `Oops! Something went wrong on our end. Please contact our support team for assistance. You can reach us at <a href="mailto:sales@salesgear.io" target="_blank" rel="noopener noreferrer">sales@salesgear.io</a> or use the chat window below.`,
                actionBtnText: "Chat with Support",
                cancelBtnText: "Close",
                id: "my_custom_link",
                callback: () => confirmationPopupState.setShowPopup(false)
              });
              confirmationPopupState.setShowPopup(true);
            } else {
              toasterState.setToastMsg(
                "Something went wrong. Please try again later",
                "fail"
              );
            }
          }
        }
      } catch (e) {
        console.error("LinkedIn accounts delete failed error", e);
      }
    }
  };
}

const linkedInReplyState = new LinkedInReplyState();

export { linkedInReplyState };
