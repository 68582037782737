import React, { useContext } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import TabNavigation from "../Crm/common/TabNavigation";
import UserModule from "../../team/TeamModule";
import usersAndTeamsState from "./state";
import TeamModule from "./Teams/TeamModule";
import { SettingContext } from "Pages/Dashboard/settings/Settings";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import PaywallUtils from "Utils/PaywallUtils";

const UsersAndTeams = observer(() => {
  const { updateBreadCrumb = () => {} } = useContext(SettingContext);
  const { team = "", isTeamOwner = false } = userDetail?.userInfo || {};
  const {
    inFreePlan = false,
    teamsEnabled = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const handleTabNavigation = (id = "") => {
    if (id === "teams" && !teamsEnabled) {
      upgradeTeams();
    } else {
      id !== "teams" && updateBreadCrumb(`Users & Teams`);
      usersAndTeamsState.setActiveTab(id);
    }
  };

  const upgradeTeams = () => {
    PaywallUtils.upgradeUtilsFunction(
      "userAndTeams",
      true,
      planName,
      inFreePlan
    );
  };

  const TEAM_MANAGEMENT_TABS = [
    {
      id: "users",
      value: "Users",
      active: true,
      path: "users",
      show: team
    },
    {
      id: "teams",
      value: "Teams",
      active: (!team && isTeamOwner) || false,
      path: "teams",
      show: true
    }
  ];

  return (
    <>
      <TabNavigation
        tabList={TEAM_MANAGEMENT_TABS}
        handleTabNavigation={handleTabNavigation}
        activeTab={
          !team && isTeamOwner ? "teams" : usersAndTeamsState?.activeTab
        }
      />
      {usersAndTeamsState?.activeTab === "users" && team ? (
        <UserModule />
      ) : (
        <TeamModule />
      )}
    </>
  );
});

export { UsersAndTeams };
export default UsersAndTeams;
