import React from "react";
import userDetail from "Stores/userDetail";
import { observer } from "mobx-react";
import ContactCard from "Components/ContactCard/ContactCard";

const InsertContactCard = observer(
  ({ animate = false, show = false, data = {} }) => {
    const { pluginInstalled = false, installNowPluginClicked = false } =
      userDetail || {};

    return (
      show && (
        <div
          className={`contactCardSection ${
            animate ? "slideInFromBottom" : "slideOutToBottom"
          } ${
            !pluginInstalled && !installNowPluginClicked
              ? "installPluginExtBannerShown"
              : ""
          }`}
          id="contactCard"
          key="accountContactCard"
        >
          <ContactCard {...data} />
        </div>
      )
    );
  }
);

export default InsertContactCard;
