import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import { OutboxContext } from "./main";
import userDetail from "Stores/userDetail";
import { outboxStoreData } from "./outboxStore";
import ContactCard from "Components/ContactCard/ContactCard";
import CheckBox from "Components/common/CheckBox";
import PaginationComponent from "Components/common/PaginationComponent";
import OutboxCardLoader from "./OutboxCard/OutboxCardLoader";
import OutboxCard from "./OutboxCard/OutboxCard";
import globalCallState from "Stores/GlobalCallState";
import { OutboxHeaderArr } from "Static/constant";

const OutboxCardList = observer((props = {}) => {
  const { getOutboxData = () => {} } = props || {};
  const { pluginInstalled = false, installNowPluginClicked = false } =
    userDetail || {};
  const { inputValues = {}, showFilter = false } = useContext(OutboxContext);
  const { outboxData = {}, dataLoading = false } = inputValues || {};
  const { totalPages = 0, currentPage = 0, result = [] } = outboxData || {};

  const [showContactCard, setShowContactCard] = useState(false);
  const [slideAnim, setSlideAnim] = useState(false);
  const [selectedContact, setSelecedContact] = useState({});

  const paginate = (pageNoObj = {}) => {
    if (!dataLoading) {
      outboxStoreData.setActiveOutboxId("");
      let pageNo = pageNoObj?.selected ? pageNoObj?.selected + 1 : 1;
      outboxStoreData.setCurrentPage(pageNo);
      getOutboxData();
    }
  };

  const openContactCard = (contact = {}) => {
    if (contact && Object.keys(contact)?.length) {
      setSlideAnim(true);
      setShowContactCard(true);
      setSelecedContact(contact);
    }
  };

  const closeContactCard = () => {
    if (globalCallState.popupValues?.isContactCard) {
      globalCallState.setPopupValues({
        ...globalCallState.popupValues,
        isContactCard: false
      });
    }
    setSlideAnim(false);
    setTimeout(() => {
      setShowContactCard(false);
    }, 300);
  };

  return (
    <div className="outBoxEmailList">
      {showContactCard && (
        <div
          className={`contactCardSection ${
            slideAnim ? "slideInFromBottom" : "slideOutToBottom"
          } ${
            !pluginInstalled && !installNowPluginClicked
              ? "installPluginExtBannerShown"
              : ""
          }`}
          id="contactCard"
          key="contactCard"
        >
          <ContactCard
            cardType="inbox"
            pageType="Inbox Page"
            contact={selectedContact}
            closeCbk={closeContactCard}
            isContactSlider={true}
            isReloadCbk={getOutboxData}
          />
        </div>
      )}
      <div className="outboxCardList adjustPaginationSpace">
        <OutboxHeader />
        {dataLoading ? (
          Array.from({ length: 10 }, (_, index) => (
            <OutboxCardLoader key={`loader${index + 1}`} />
          ))
        ) : result?.length > 0 ? (
          result.map((item, index) => (
            <OutboxCard
              openContactCard={openContactCard}
              {...item}
              key={`${new Date().getTime()}-${index}-${item?.contact?.id}-${
                item?.email?.taskDue
              }`}
              index={index}
            />
          ))
        ) : (
          <div className="outboxNoData">No data found</div>
        )}
      </div>
      <PaginationComponent
        callback={paginate}
        loading={dataLoading}
        totalPages={totalPages}
        currentPage={currentPage}
      />
    </div>
  );
});

const OutboxHeader = observer(() => {
  const {
    allEmailsSelectedCheckbox = false,
    setAllEmailsSelectedCheckbox = () => {},
    setInputValues = () => {},
    inputValues = {}
  } = useContext(OutboxContext);

  const { name = "" } = outboxStoreData?.activeFilter || {};
  const { outboxData = {}, dataLoading = false } = inputValues || {};
  const isScheduledorAllFilter =
    ["all", "scheduled"].includes(name?.toLowerCase()) ||
    !outboxData?.result ||
    false;

  const handleAllCheckboxAction = (value = false, type = "") => {
    if (outboxData?.result?.length > 0) {
      setAllEmailsSelectedCheckbox(value);
      setInputValues({
        ...inputValues,
        outboxData: {
          ...outboxData,
          result: outboxData?.result.map(item => ({
            ...item,
            isChecked: value
          }))
        }
      });
    }
  };

  return (
    <div
      className={`outboxHeader ${
        isScheduledorAllFilter ? "adjustCheckboxWidth" : ""
      }`}
    >
      {!dataLoading
        ? !isScheduledorAllFilter && (
            <CheckBox
              id="all"
              key="all"
              checked={allEmailsSelectedCheckbox}
              cbk={handleAllCheckboxAction}
            />
          )
        : !isScheduledorAllFilter && <span />}
      {OutboxHeaderArr.map((item, index) => (
        <div key={index} className={item?.classname}>
          {item?.name}
        </div>
      ))}
    </div>
  );
});

export { OutboxCardList };
export default OutboxCardList;
