import React from "react";
import BulkUploadingLoader from "Components/common/bulkContactPopup/BulkUploadingLoader";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

const defaultPopupValues = {
  msg: "",
  title: "Add Contact"
};

class ContactUploadingToSequencePopupState {
  @observable showPopup = false;
  @observable popupValues = defaultPopupValues;

  @action
  setShowPopup = value => {
    this.showPopup = value;
  };

  @action
  setPopupValues = obj => {
    this.popupValues = { ...this.popupValues, ...obj };
  };

  @action
  resetPopup = () => {
    this.showPopup = false;
    this.popupValues = defaultPopupValues;
  };
}

const contactUploadingToSequencePopupState =
  new ContactUploadingToSequencePopupState();

const ContactUploadingToSequence = observer(props => {
  const { msg = "", title = "Add Contact" } =
    contactUploadingToSequencePopupState?.popupValues || {};
  return (
    (contactUploadingToSequencePopupState?.showPopup && (
      <div className="sequenceContactUploader">
        <div className="contactUploader">
          <h3>{title}</h3>
          <BulkUploadingLoader loadingMessage={msg} />
        </div>
        <div className="contactUploaderOverlay" />
      </div>
    )) ||
    ""
  );
});

export default ContactUploadingToSequence;
export { ContactUploadingToSequence, contactUploadingToSequencePopupState };
