import React from "react";

const TaskFilterLoader = () => {
  return Array.from({ length: 5 }, (value, index) => (
    <li
      className="filterDetails linear-background"
      key={`filterDetails${index}`}
    ></li>
  ));
};

export { TaskFilterLoader };
export default TaskFilterLoader;
