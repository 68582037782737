import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { ACCOUNTS_HEADER_ARR } from "Static/constant";
import CheckBox from "Components/common/CheckBox";
import DropDownMenu from "Components/common/DropDownMenu";
import PaginationComponent from "Components/common/PaginationComponent";
import CustomTooltip from "Components/common/CustomTooltip";
import UpdateContactStageDropdown from "Components/ContactCard/UpdateContactStageDropdown";
import PageLimit from "Components/common/PageLimit";
import { ReactComponent as UpdateIcon } from "Assets/svg/update.svg";
import { ReactComponent as Link } from "Assets/svg/link.svg";
import { AccountsListingContext } from "./AccountsList";
import { accountsState } from "./AccountsState";
import ContactSelectedCount from "Components/common/ContactSelectedCount";
import { accountBulkActionsState } from "./AccountBulkActionsState";
import BulkStageChangePopup from "./BulkStageChangePopup";
import FilterBtn from "./AccountFilter/FilterBtn";
import { updateContactOwnerState } from "Components/common/UpdateContactOwner";
import { toasterState } from "Components/common/toaster";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import makeApi, { URLS } from "Utils/apiURL";
import SequenceUtils from "Utils/SequenceUtils";
import AccountRowAction from "./AccountRowAction";
import SearchByContactKeyword from "Pages/Dashboard/contacts/SearchByContactKeyword";
import useDebounce from "Components/common/hooks/useDebounce";

const AccountsTable = observer((props = {}) => {
  const { hasTeamMember = false } = userDetail?.userInfo || {};

  const { accountsTableObj = false, setAccountsTableObj = () => {} } =
    useContext(AccountsListingContext) || {};
  const {
    allAccountsSelectedCheckbox = false,
    setAllAccountsSelectedCheckbox = () => {},
    allAccountsSelectedInView = false,
    setAllAccountsSelectedInView = () => {},
    getAccountStagesAnalytics = () => {},
    getAccountsList = () => {}
  } = props || {};

  const { tableArr = [], tableLoading = false } = accountsTableObj || {};
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const paginate = (pageObj = {}) => {
    const selectedPageNo = pageObj?.selected + 1 || 1;
    accountsState.setPageNo(selectedPageNo);
    setAllAccountsSelectedCheckbox(false);
    setAllAccountsSelectedCheckbox(false);
  };

  const handleAllCheckboxAction = (value = false, type = "") => {
    if (!tableLoading) {
      setAllAccountsSelectedCheckbox(value);
      if (!value) {
        setAllAccountsSelectedInView(false);
      }
      setAccountsTableObj({
        ...accountsTableObj,
        tableArr: [...tableArr].map(item => ({
          ...item,
          isChecked: value
        }))
      });
    }
  };

  const clearSelectedAccounts = (isClearAccounts = false) => {
    setAllAccountsSelectedCheckbox(!isClearAccounts);
    setAllAccountsSelectedInView(!isClearAccounts);
    if (tableArr?.length > 0) {
      setAccountsTableObj({
        ...accountsTableObj,
        tableArr: [...tableArr].map(item => ({
          ...item,
          isChecked: !isClearAccounts
        }))
      });
    }
  };

  const reloadAccountCard = (noLoader = true) => {
    getAccountsList(noLoader);
    let tempAccountStageArr = [
      { name: "All", id: "TOTAL" },
      ...[...accountsState?.accountStages].map(item => {
        return {
          name: item?.displayName || "",
          id: item?.id || "",
          count: 0
        };
      })
    ];
    getAccountStagesAnalytics(tempAccountStageArr);
  };

  const checkboxObj = {
    id: "all",
    key: "all",
    checked: allAccountsSelectedCheckbox,
    cbk: handleAllCheckboxAction
  };

  return (
    <>
      <HeaderContents
        allAccountsSelectedInView={allAccountsSelectedInView}
        setAllAccountsSelectedInView={setAllAccountsSelectedInView}
        clearSelectedAccounts={clearSelectedAccounts}
        showActionsDropdown={showActionsDropdown}
        setShowActionsDropdown={setShowActionsDropdown}
        getAccountsList={getAccountsList}
        reloadAccountCard={reloadAccountCard}
        hasTeamMember={hasTeamMember}
      />
      <div className="accountsTable">
        <div className="accountListHeader">
          <ul>
            <div className="headerCheckbox">
              {!tableLoading && tableArr?.length > 0 && (
                <CheckBox {...checkboxObj} />
              )}
            </div>
            {ACCOUNTS_HEADER_ARR.map((item, index) => (
              <li
                key={index}
                className={item?.split(" ")?.join("")?.toLowerCase() || ""}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="accountsListBody">
          {tableLoading ? (
            <AccountsListLoader />
          ) : tableArr?.length > 0 ? (
            <AccountsListTableBody
              tableArr={tableArr}
              reloadAccountCard={reloadAccountCard}
              loading={tableLoading}
              hasTeamMember={hasTeamMember}
              allAccountsSelectedCheckbox={allAccountsSelectedCheckbox}
              allAccountsSelectedInView={allAccountsSelectedInView}
            />
          ) : (
            <div className="noAccountFoundCont"> No Accounts Found</div>
          )}
          <PaginationComponent
            callback={paginate}
            loading={tableLoading}
            totalPages={accountsState?.totalPage || 1}
            currentPage={accountsState?.pageNo || 1}
          />
        </div>
      </div>
    </>
  );
});

const AccountsListTableBody = observer((props = {}) => {
  const {
    reloadAccountCard = () => {},
    tableArr = [],
    expandActionMenu = () => {},
    loading,
    hasTeamMember = false,
    allAccountsSelectedCheckbox = false,
    allAccountsSelectedInView = false
  } = props || {};
  const { accountsTableObj = false, setAccountsTableObj = () => {} } =
    useContext(AccountsListingContext) || {};

  const history = useHistory();

  const [showTooltip, setShowTooltip] = useState({});

  const redirectToAccountsDetails = (item = {}) => {
    const { id = "" } = item || {};
    history.push(`/accounts/${id}/overview`);
  };

  const getAccountName = (name = "", website = "") => {
    return name ? name : website ? website.split(".")[0] || "--" : "--";
  };

  const handleDomainLinkClick = (e, domainLink = "") => {
    Utils.preventDefaultFn(e);
    if (domainLink) {
      Utils.windowRedirect(
        Utils.removeHttpFromString(domainLink),
        "_blank",
        true,
        true
      );
    }
  };

  const handleCheckboxAction = (value = false, type = "") => {
    let tempTableArr = [];
    if (accountsTableObj?.tableArr?.length > 0) {
      tempTableArr = accountsTableObj?.tableArr.map(item =>
        item?.id === type ? { ...item, isChecked: value } : item
      );
    }
    setAccountsTableObj({
      ...accountsTableObj,
      tableArr: tempTableArr
    });
  };

  const handleAccountChange = (stageName = "", accountId = "") => {
    let tempTableArr = [];
    if (accountsTableObj?.tableArr?.length > 0) {
      tempTableArr = accountsTableObj?.tableArr.map(item =>
        item?.id === accountId ? { ...item, stage: stageName } : item
      );
    }
    setAccountsTableObj({
      ...accountsTableObj,
      tableArr: tempTableArr
    });
  };

  const getSelectedAccountIds = useCallback(() => {
    return [...tableArr].filter(item => item?.isChecked).map(item => item?.id);
  }, [tableArr]);

  return (
    <div
      className={`accountsListTableBody ${
        accountsState?.totalPage > 1 ? "" : "noPaginationCont"
      } `}
    >
      {tableArr.map((item, index) => (
        <div
          key={item?.id}
          className="accountRow"
          onClick={() => redirectToAccountsDetails(item)}
          id={item?.id}
        >
          <CheckBox
            id={item?.id}
            key={item?.id}
            checked={item?.isChecked}
            cbk={handleCheckboxAction}
          />
          <span className="name">
            <span className="accountName">
              {getAccountName(item?.name, item?.domain)}
            </span>
          </span>
          <span className="website">
            <Link className="linkIcon" />
            <span
              className="websiteLink"
              onClick={e => handleDomainLinkClick(e, item?.domain)}
            >
              {item?.domain}
            </span>
          </span>
          <span className="stage">
            <UpdateContactStageDropdown
              stage={item?.stage}
              setStage={param => handleAccountChange(param, item?.id)}
              contactStages={accountsState?.accountStages}
              reloadCbk={reloadAccountCard}
              pageType="accounts"
              entityId={item?.id}
            />
          </span>
          <span className="contacts">
            {Utils.formatNumberToStringInUSFormat(item?.contactsCount)}
          </span>
          <span
            className="owner"
            onMouseEnter={() => setShowTooltip({ index, value: true })}
            onMouseLeave={() => setShowTooltip({ index, value: false })}
          >
            {(item?.owner && Object.keys(item?.owner)?.length && (
              <>
                <span className="circleOwner">
                  {Utils.getOwnerInitial(item?.owner)}
                </span>
                {showTooltip?.index === index && showTooltip?.value && (
                  <CustomTooltip
                    text={`Added by ${Utils.getOwnerName(item?.owner)}`}
                  />
                )}
              </>
            )) ||
              "--"}
          </span>
          <span className="lastContacted">
            {Utils.formatDate(item?.lastContacted) || "--"}
          </span>
          {hasTeamMember ? (
            allAccountsSelectedCheckbox ||
            allAccountsSelectedInView ||
            getSelectedAccountIds()?.length > 0 || (
              <AccountRowAction
                expandActionMenu={expandActionMenu}
                referenceId={item?.id}
                tableLoading={loading}
                rowData={item}
                reloadAccountCard={reloadAccountCard}
              />
            )
          ) : (
            <div className="noAction">--</div>
          )}
        </div>
      ))}
    </div>
  );
});

const AccountsListLoader = () => {
  let tempHeaderArr = ["checkbox", ...ACCOUNTS_HEADER_ARR];
  return (
    <div className="accountLoaderBody">
      {Array.from({ length: 10 }, (_, i) => (
        <div className="accountsListLoader" key={i}>
          <ul>
            {tempHeaderArr.map((item, index) => (
              <li
                className={`${
                  item?.toLowerCase() === "last contacted"
                    ? "lastContacted"
                    : item?.toLowerCase()
                } linear-background`}
                key={index}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

const HeaderContents = observer((props = {}) => {
  const {
    accountsTableObj = false,
    setAccountsNavbarObj = () => {},
    accountsNavbarObj = {}
  } = useContext(AccountsListingContext) || {};

  const [searchKeyword, setSearchKeyword] = useState(null);
  const debouncedValue = useDebounce(searchKeyword, 700);
  const [isEmailClose, setEmailClose] = useState(false);
  const searchInputRef = useRef(null);

  const { tableArr = [], tableLoading = false } = accountsTableObj || {};

  const {
    allAccountsSelectedInView = false,
    setAllAccountsSelectedInView = () => {},
    clearSelectedAccounts = () => {},
    showActionsDropdown = false,
    setShowActionsDropdown = () => {},
    reloadAccountCard = () => {},
    hasTeamMember = false
  } = props || {};

  const expandMenu = () => {
    if (!tableLoading) {
      setShowActionsDropdown(true);
    }
  };

  const getSelectedCount = useCallback(() => {
    return [...tableArr].reduce(
      (count, item) => count + (item?.isChecked ? 1 : 0),
      0
    );
  }, [tableArr]);

  const getSelectedAccountIds = useCallback(() => {
    return [...tableArr].filter(item => item?.isChecked).map(item => item?.id);
  }, [tableArr]);

  const handleBulkUpdateStage = () => {
    setShowActionsDropdown(false);
    accountBulkActionsState.setBulkStageUpdateObj({
      filteredStageId: accountsState?.activeId || "",
      accountCount: getSelectedCount(),
      accountIds: getSelectedAccountIds(),
      allAccountsSelectedInView: allAccountsSelectedInView
    });
    accountBulkActionsState.setShowBulkUpdateStagePopup(true);
  };

  const getContactsByLimit = (limit = 10) => {
    accountsState.setLimit(limit);
    accountsState.setPageNo(1);
  };

  const pageLimitObj = {
    limitPerPage: accountsState?.limit || 10,
    text: "Showing",
    totalCount: accountsState?.totalAccounts || 0,
    cbk: getContactsByLimit
  };

  const updateAccountPayload = (ownerId = "") => {
    let payload = {
      type: "ACCOUNT_OWNER_UPDATE",
      totalCount: allAccountsSelectedInView
        ? accountsState?.totalAccounts
        : getSelectedCount(),
      filter: {},
      data: { accountOwnerId: ownerId }
    };

    if (!allAccountsSelectedInView) {
      payload.filter.accountIds = getSelectedAccountIds();
    }

    return payload;
  };

  const bulkAccountChangeAction = async (ownerId = "") => {
    updateContactOwnerState.setBtnLoader(true);
    const payload = updateAccountPayload(ownerId);

    const config = {
      url: URLS.performBulkAccountOperation,
      method: "POST",
      data: payload
    };
    const response = await makeApi(config);
    const { status = "", data = {} } = response || {};
    const {
      background = false,
      activeJob = false,
      bulkOperationResult = {}
    } = data || {};
    const { success = "", failure = "" } = bulkOperationResult || {};
    if (+status === 200) {
      if (activeJob) {
        SequenceUtils.showActiveJobPopupForBulkActions();
      } else {
        if (background) {
          confirmationPopupState.setPopupValues({
            content:
              "Accounts are currently being updated. This process may take a few to several minutes, depending on the total number of accounts selected.",
            needCancelBtn: false,
            actionBtnText: "Ok",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        } else if (activeJob) {
          SequenceUtils.showActiveJobPopupForBulkActions();
        } else if (success > 0) {
          toasterState.setToastMsg(
            `${success} account${success > 1 ? "s" : ""} ${
              success > 1 ? "have" : "has"
            } been updated successfully`,
            "success"
          );
          reloadAccountCard();
        } else if (failure > 0) {
          confirmationPopupState.setPopupValues({
            content: ` Failed to update ${failure} account${
              failure > 1 ? "s" : ""
            } `,
            needCancelBtn: false,
            actionBtnText: "Ok",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        }
      }
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
    clearSelectedAccounts(true);
    accountBulkActionsState.setShowBulkUpdateStagePopup(false);
    updateContactOwnerState.setBtnLoader(false);
  };

  const openBulkUpdateOwnerPopup = () => {
    setShowActionsDropdown(false);
    updateContactOwnerState.setPopupType("ACCOUNT");
    updateContactOwnerState.setTotalCount(
      allAccountsSelectedInView
        ? accountsState.totalAccounts
        : getSelectedCount()
    );
    updateContactOwnerState.setCallback(bulkAccountChangeAction);
    updateContactOwnerState.setShowPopup(true);
  };

  const searchContact = () => {
    if (searchKeyword !== null) {
      accountsState.setSearchTerm(debouncedValue);
      setAccountsNavbarObj({ ...accountsNavbarObj, navBarLoading: true });
      reloadAccountCard(false);
    }
    if (searchKeyword?.length) {
      setEmailClose(true);
    }
    searchInputRef.current.blur();
  };

  const handleSearchAction = (event = "") => {
    Utils.preventDefaultFn(event);
    const currenrTargetVal = event?.currentTarget?.value;
    setSearchKeyword(currenrTargetVal);
    setEmailClose(!currenrTargetVal.length < 1);
  };

  const submitForm = (e = "") => {
    e.preventDefault();
    if (e?.keyCode === 13) {
      searchContact();
    }
  };

  const clearSearch = () => {
    accountsState.setSearchTerm("");
    setAccountsNavbarObj({ ...accountsNavbarObj, navBarLoading: true });
    setSearchKeyword("");
    setEmailClose(false);
    searchInputRef.current.focus();
  };

  const searchBykeywordObj = {
    isEmailClose: isEmailClose,
    placeHolder: "Search Account by Name or Website",
    searchKeyword: searchKeyword,
    inputRef: searchInputRef,
    searchContact: searchContact,
    submitForm: submitForm,
    handleSearchAction: handleSearchAction,
    clearSearch: clearSearch
  };

  useEffect(() => {
    searchContact();
  }, [debouncedValue]);

  useEffect(() => {
    return () => {
      accountsState.setSearchTerm("");
    };
  }, []);

  return (
    <>
      {accountBulkActionsState?.showBulkUpdateStagePopup && (
        <BulkStageChangePopup
          clearSelectedAccounts={clearSelectedAccounts}
          reloadAccountsPage={reloadAccountCard}
        />
      )}
      <div className="headerContents">
        <div className="leftHeaderContents">
          <SearchByContactKeyword {...searchBykeywordObj} />
          <div className="accountCount">
            {getSelectedCount() > 0 && (
              <ContactSelectedCount
                selectedCount={getSelectedCount()}
                totalCount={accountsState?.totalAccounts}
                loading={tableLoading}
                allContactSelected={allAccountsSelectedInView}
                setAllContactSelected={setAllAccountsSelectedInView}
                resetCbk={() => clearSelectedAccounts(true)}
                selectAllCbk={() => clearSelectedAccounts(false)}
                selectedType="Account"
              />
            )}
          </div>
        </div>
        <div className="rightHeaderContents">
          <PageLimit {...pageLimitObj} />
          <FilterBtn />
          <AccountMoreOptionsComp
            showActionsDropdown={showActionsDropdown}
            setShowActionsDropdown={setShowActionsDropdown}
            expandMenu={expandMenu}
            updateAccountStage={handleBulkUpdateStage}
            getSelectedCount={getSelectedCount}
            updateAccountOwner={openBulkUpdateOwnerPopup}
            hasTeamMember={hasTeamMember}
          />
        </div>
      </div>
    </>
  );
});

const AccountMoreOptionsComp = observer((props = {}) => {
  const {
    showActionsDropdown = false,
    setShowActionsDropdown = () => {},
    expandMenu = () => {},
    updateAccountStage = () => {},
    updateAccountOwner = () => {},
    getSelectedCount = () => {},
    hasTeamMember = false
  } = props || {};

  const ownerOptions = {
    icon: {
      ele: '<i style="font-size: 15px;cursor:pointer" class="material-icons-outlined">person_pin</i>',
      style: { width: "15px", height: "15px" }
    },
    value: "Update Owner",
    key: "updateAccountOwner",
    method: updateAccountOwner
  };

  const dropdownOptions = [
    {
      icon: { ele: <UpdateIcon />, style: { width: "15px", height: "15px" } },
      value: "Update Stage",
      key: "updateAccountStage",
      method: updateAccountStage
    }
  ];

  const getAccountsDropdownOptions = () => {
    return hasTeamMember ? [ownerOptions, ...dropdownOptions] : dropdownOptions;
  };

  return (
    <div className="moreOptionBtn">
      <i
        className={`material-icons ${getSelectedCount() > 0 ? "enabled" : ""}`}
        onClick={expandMenu}
      >
        more_vert
      </i>
      {showActionsDropdown && getSelectedCount() > 0 && (
        <>
          <DropDownMenu
            options={getAccountsDropdownOptions()}
            referenceId="accountMoreOption"
          />
          <div
            className="moreOptionOverlay"
            onClick={event => {
              event.preventDefault();
              setShowActionsDropdown(false);
            }}
          />
        </>
      )}
    </div>
  );
});

export default AccountsTable;
export { AccountsTable };
