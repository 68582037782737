import { observable, action } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";

const defaultInputValue = {
  name: "",
  triggerType: "",
  actions: [],
  status: "ACTIVE",
  executeOnce: true,
  executeForAccount: false,
  groups: []
};

let networkRequest = {
  listTriggerSequence: null
};

const setNetworkRequest = (id = "") => {
  if (id && networkRequest?.[id] !== null) {
    networkRequest[id]("cancel");
    networkRequest[id] = null;
  }
};

const cancelReqCallback = (id = "", value = "") => {
  id && (networkRequest[id] = value);
};

class TriggerState {
  @observable inputValue = { ...defaultInputValue };
  @observable isEdit = false;
  @observable sequenceList = [];
  @observable editInputData = {};
  @observable sequenceTotalPages = 1;
  @observable sequenceCurrentPageNo = 1;
  @observable contactOwner = [];
  @observable disabledActionFields = "";
  @observable sequenceReload = false;

  @action
  setSequenceReload = (val = false) => {
    this.sequenceReload = val;
  };

  @action
  setInputValue(value) {
    this.inputValue = value;
  }

  @action
  setIsEdit(value) {
    this.isEdit = value;
  }

  @action
  setSequenceList(value) {
    this.sequenceList = value;
  }

  @action
  setEditInputData(value) {
    this.editInputData = value;
  }

  @action
  setResetInputValue() {
    this.inputValue = defaultInputValue;
  }

  @action
  setSequenceTotalPages(value) {
    this.sequenceTotalPages = value;
  }

  @action
  setSequenceCurrentPageNo(value) {
    this.sequenceCurrentPageNo = value;
  }

  @action
  setContactOwner(value) {
    this.contactOwner = value;
  }

  @action
  setDisabledActionFields(value) {
    this.disabledActionFields = value;
  }

  @action
  listSequences = async (data = {}) => {
    try {
      const config = {
        url: URLS.listAllTriggerSequences,
        method: "POST",
        data
      };
      setNetworkRequest("listTriggerSequence");
      const cancel = value => cancelReqCallback("listTriggerSequence", value);
      let response = await makeApi(config, cancel);
      if (response?.data) {
        if (data?.pageNo <= 1) {
          this.setSequenceList(
            response?.data?.campaignSequenceResponseList || []
          );
        } else {
          this.setSequenceList([
            ...this.sequenceList,
            ...response?.data?.campaignSequenceResponseList
          ]);
        }
        this.setSequenceTotalPages(response?.data?.totalPages);
      } else {
        Utils.showApiFailureResponse(response);
      }
      this.sequenceReload && this.setSequenceReload(false);
    } catch (err) {
      console.error("Creating a new step erro", err);
    }
  };
}

const triggerState = new TriggerState();
export { triggerState };
