import React from "react";
import { observer } from "mobx-react";
import { withRouter, useHistory } from "react-router-dom";
import { observable, action } from "mobx";
import Button from "Components/common/Button";
import { scheduleDataStore } from "Stores/ScheduleData";
import { SequenceUtils } from "Utils/SequenceUtils";
import { weekDays } from "Static/constant";
import { Utils } from "Utils/utils";

class ContactsSchedulePopupState {
  @observable showPopup = false;
  @observable popupValues = { cbk: () => {} };

  @action
  setShowPopup = value => {
    this.showPopup = value;
  };

  @action
  setPopupValues = values => {
    this.popupValues = values;
  };
}

const contactsSchedulePopupState = new ContactsSchedulePopupState();

const CloseIcon = () => {
  return (
    <div
      className="popup-close closeIcon"
      title="Click here to close"
      onClick={() => contactsSchedulePopupState.setShowPopup(false)}
    >
      <i className="material-icons">close</i>
    </div>
  );
};

const ContactsSchedulePopup = observer(() => {
  let tempWeekDays = [];
  const history = useHistory();

  const redirectToScheduleSettings = () => {
    contactsSchedulePopupState.setShowPopup(false);
    const redirectLink = contactsSchedulePopupState.popupValues
      .constructRedirectLink
      ? SequenceUtils.getSequencePath(
          contactsSchedulePopupState.popupValues.selectedSeq,
          contactsSchedulePopupState.popupValues.folderId
        )
      : "#settings";
    history.push(redirectLink);
  };

  const continueEditAction = () => {
    contactsSchedulePopupState.setShowPopup(false);
  };

  const confirmBtnAction = () => {
    contactsSchedulePopupState.setShowPopup(false);
    contactsSchedulePopupState.popupValues &&
      contactsSchedulePopupState.popupValues.cbk();
  };

  const getUpdatedTimeRange = () => {
    const dailyTimeRange = scheduleDataStore.defaultData?.dailyTimeRange;

    for (var day in weekDays) {
      const initialTimeRange = dailyTimeRange?.[day]?.map(item => {
        const { fromTime = "", toTime = "" } = item;
        return `${Utils.getCustomTime(fromTime)} - ${Utils.getCustomTime(
          toTime
        )}`;
      });

      tempWeekDays.push({
        long: weekDays[day]?.long,
        timeRange: initialTimeRange
      });
    }
    return tempWeekDays;
  };

  const getScheduleWindow = () => {
    const tempWeekDays = getUpdatedTimeRange();
    return tempWeekDays?.map((item, index) => {
      const { long = "", timeRange = [] } = item || {};
      return (
        <div
          className="daysWithTimeWrapper"
          key={`daysWithTimeWrapper${index}`}
        >
          <span className="weekDays">{long}</span>
          <span className={`TimeRange ${timeRange?.[0] ? "" : "emptyResult"}`}>
            {timeRange?.[0] || "--"}
          </span>
        </div>
      );
    });
  };

  return (
    (contactsSchedulePopupState.showPopup && (
      <div className="contactsSchedulePopup">
        <div className="upgradeCard animated customzoomIn">
          <CloseIcon />
          <h4 className="headingText">Schedule Sequence</h4>
          <div className="contactScheduleWrap">
            <div className="scheduleWindowText">
              <div className="smallText">
                You are almost there. Just a couple of things to make sure
                before you proceed!
              </div>
              <div className="smallText">
                <b>Note:</b>
                <span>
                  {" "}
                  Once you've added the contacts to the sequence, you cannot
                  modify the content & steps for these contacts.
                </span>
              </div>
              <div className="smallText">
                <span>
                  Your emails will be scheduled in{" "}
                  <b>{scheduleDataStore.defaultData?.timezoneName}</b> timezone
                  with the sending window as shown.
                </span>
              </div>
              <div className="smallText">
                You can configure it in{" "}
                <button
                  className="hereText"
                  onClick={redirectToScheduleSettings}
                >
                  here.
                </button>
              </div>
            </div>
            <div className="scheduleWindowPanel">
              <h5>Sending Window</h5>
              {getScheduleWindow()}
            </div>
          </div>
          <div className="buttonWrapper">
            <Button
              id={"continueEditingBtn"}
              name="continueEditingBtn"
              btnText={"Continue Editing"}
              className="continueEditingBtn"
              type="button"
              click={continueEditAction}
            />
            <Button
              id={"confirmBtn"}
              name="confirmBtn"
              btnText={"Confirm"}
              className="confirmBtn"
              type="button"
              click={confirmBtnAction}
            />
          </div>
        </div>
        <div
          className="upgradeOverlay"
          onClick={() => contactsSchedulePopupState.setShowPopup(false)}
        />
      </div>
    )) ||
    ""
  );
});

export default withRouter(ContactsSchedulePopup);
export { ContactsSchedulePopup, contactsSchedulePopupState };
