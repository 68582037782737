import React from "react";

const ContactLoader = props => {
  const { count = 10, filterEnabled = false } = props;
  return (
    <>
      {Array.from({ length: count }, (value, index) => (
        <div
          className={`ctTableBodyLoader ${
            filterEnabled ? "filterEnabled" : ""
          }`}
          key={`ctTableBodyLoader${index}`}
        >
          <div className="ctSelectAll">
            <span className="linear-background"></span>
          </div>
          <div className="nameEmail">
            <span className="name linear-background"></span>
            <span className="email linear-background"></span>
          </div>
          <div className="companyJobTile" title="Job Title & Company">
            <span className="job linear-background"></span>
            <span className="company linear-background"></span>
          </div>
          {!filterEnabled && (
            <div className="tag">
              <span className="tagNameTable linear-background"></span>
              <span className="moreTags linear-background"></span>
            </div>
          )}
          <div className="status">
            <span className="icon linear-background"></span>
            <span className="text linear-background"></span>
          </div>
          <div className="stageInsight insight-loader">
            {Array.from({ length: 4 }, (value, index) => (
              <div
                key={`ctTableBodyInsightLoader${index}`}
                className="linear-background"
              ></div>
            ))}
          </div>
          <div className="lastContacted">
            <span className="linear-background"></span>
          </div>
          {!filterEnabled && (
            <div className="ctOwner">
              <span className="linear-background"></span>
            </div>
          )}
          <div className="actionDp">
            <span className="linear-background"></span>
          </div>
        </div>
      ))}
    </>
  );
};

export default ContactLoader;
export { ContactLoader };
