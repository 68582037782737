import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { Button } from "Components/common/Button";
import contactStagesState from "./state";
import InputTextBox from "Components/common/InputTextBox";
import Utils from "Utils/utils";

// Common contact and call stages
class CreateContactStagePopupState {
  @observable showPopup = false;
  @observable stageName = "";
  @observable buttonLoader = false;
  @observable stageNameError = "";

  @action
  setStageNameError = (val = "") => {
    this.stageNameError = val;
  };

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
  };

  @action
  setStageName = (val = "") => {
    this.stageName = val;
  };

  @action
  setButtonLoader = (val = false) => {
    this.buttonLoader = val;
  };
}

const createContactStagePopupState = new CreateContactStagePopupState();

const CreateContactStagePopup = () => {
  const inputBoxRef = useRef();

  const handleStageNameChange = (id = "", val = "") => {
    createContactStagePopupState.stageNameError &&
      createContactStagePopupState.setStageNameError("");
    createContactStagePopupState.setStageName(val);
  };

  const handleCreateStageAction = async () => {
    if (createContactStagePopupState?.stageName?.length > 0) {
      createContactStagePopupState.setButtonLoader(true);
      await contactStagesState.createNewStage(
        createContactStagePopupState?.stageName
      );
    } else {
      createContactStagePopupState.setStageNameError(
        "Please enter a call state name"
      );
    }
  };

  const handleCreateStage = (event = "") => {
    Utils.preventDefaultFn(event);
    if (Utils.isSubmitKeyAction(event)) {
      handleCreateStageAction();
    }
  };

  const close = () => {
    createContactStagePopupState.setStageNameError("");
    createContactStagePopupState.setStageName("");
    createContactStagePopupState.setShowPopup(false);
  };

  const inputProps = {
    name: "createStageInputBox",
    id: "createStageInputBox",
    text: "Create new stage",
    type: "text",
    value: createContactStagePopupState?.stageName,
    change: handleStageNameChange,
    err: createContactStagePopupState.stageNameError,
    cbk: handleCreateStage,
    readOnly: false,
    inputBoxRef: inputBoxRef,
    className: `${
      createContactStagePopupState?.stageName ? "activeBorder" : ""
    }`
  };

  const btnProps = {
    id: "createStageButton",
    name: "createStageButton",
    btnText: "Create Stage",
    type: "button",
    className: `createStageButton ${
      createContactStagePopupState?.buttonLoader ? "loading" : ""
    }`,
    loader: createContactStagePopupState?.buttonLoader,
    click: e => {
      handleCreateStageAction(e);
    },
    disabled: !createContactStagePopupState?.stageName
  };

  useEffect(() => {
    if (createContactStagePopupState?.showPopup) {
      inputBoxRef && inputBoxRef.current && inputBoxRef.current.focus();
    }
  }, [createContactStagePopupState?.showPopup]);

  return (
    createContactStagePopupState?.showPopup && (
      <section className="createStagePopupWrapper">
        <div className="card animated customzoomIn">
          <div className="popupHeader">
            <div className="title">Create contact stage</div>
            <i className="material-icons commonPopupCloseIcon" onClick={close}>
              close
            </i>
          </div>
          <hr className="seperator" />
          <InputTextBox {...inputProps} />
          <Button {...btnProps} />
        </div>
        <div className="common-popup-mask" />
      </section>
    )
  );
};

export { CreateContactStagePopup, createContactStagePopupState };
export default observer(CreateContactStagePopup);
