import React from "react";
import Button from "Components/common/Button";

export const BulkActionButton = ({ action = () => {} }) => {
  const buttonList = [
    {
      id: "bulkActionEdit",
      name: "bulkActionEdit",
      btnText: "Edit",
      isLeftSideIcon: true,
      iconName: "edit",
      type: "button",
      className: `bulkActionEdit`,
      click: () => action("EDIT_TASK_DUE_DATE")
    },
    {
      id: "bulkActionSkip",
      name: "bulkActionSkip",
      btnText: "Skip",
      isLeftSideIcon: true,
      iconName: "skip_next",
      type: "button",
      className: `bulkActionSkip`,
      click: () => action("SKIP_TASK")
    },
    {
      id: "bulkActionComplete",
      name: "bulkActionComplete",
      btnText: "Complete",
      isLeftSideIcon: true,
      iconName: "check_circle",
      type: "button",
      className: `bulkActionComplete`,
      click: () => action("COMPLETE_TASK")
    },
    {
      id: "bulkActionDelete",
      name: "bulkActionDelete",
      btnText: "Delete",
      isLeftSideIcon: true,
      iconName: "delete",
      type: "button",
      className: `bulkActionDelete`,
      click: () => action("DELETE_TASK")
    }
  ];
  return (
    <div className="formGroupButton">
      {buttonList?.map((item, index) => (
        <Button key={`bulkTaskActionBtn${index}`} {...item} />
      ))}
    </div>
  );
};

export default BulkActionButton;
