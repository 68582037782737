import Utils from "./utils";
import userDetail from "Stores/userDetail";

const CallsUtils = {};

CallsUtils.getFullName = data => {
  if (data && Object.keys(data)?.length) {
    return Utils.getFullName(data);
  }
  return "";
};

CallsUtils.getFromToDateWithTimeZoneByDaysCount = day => {
  let dateValue =
    (day === 0 && new Date()) || Utils.getPreviousDateByNumber(day);
  return {
    fromDate: Utils.convertFromDateToLocalString(dateValue),
    to: Utils.convertToDateToLocalString((day > 1 && new Date()) || dateValue),
    timeZone: Utils.getCurrentUserTimeZone()
  };
};

CallsUtils.secondsToTime = (duration = 0) => {
  if (duration) {
    const h = Math.floor(duration / 3600)
      .toString()
      .padStart(2, "0");
    const m = Math.floor((duration % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const s = Math.floor(duration % 60)
      .toString()
      .padStart(2, "0");
    return h !== "00" ? `${h}:${m}:${s}` : `${m}:${s}`;
  }
  return "--";
};

CallsUtils.getPayload = (data = {}, isFilter = false) => {
  if (data && Object.keys(data)?.length) {
    const {
      memberIds = "",
      fromDate = "",
      to = "",
      pageNo = 1,
      limit = 1,
      callDispositionId = "",
      timeZone = "",
      totalCount = 0
    } = data || {};
    const { team = false, memberId = "" } = userDetail?.userInfo || {};
    let filter = {
      all: !callDispositionId && (!memberIds || memberIds?.length === 0),
      fromDate,
      to,
      timeZone
    };
    if (memberIds?.length) {
      filter.memberIds = memberIds;
    } else {
      !team && (filter.memberIds = [memberId]);
    }
    if (!isFilter && callDispositionId) {
      filter.callDispositionId = callDispositionId;
    }
    const payload = { pageNo, limit, filter, totalCount };
    return payload;
  }
};

CallsUtils.getAnalyticsPayload = (data = {}) => {
  if (data && Object.keys(data)?.length) {
    const {
      memberIds = "",
      fromDate = "",
      to = "",
      timeZone = "",
      pageNo = 1,
      limit = 1
    } = data || {};
    const filter = { fromDate, to, timeZone };
    if (memberIds || memberIds?.length) {
      filter.memberIds = memberIds;
    }
    const payload = { filter };
    return payload;
  }
};

CallsUtils.getStageColorCodes = (stageList = []) => {
  let tempCode = [];
  if (stageList?.length > 0) {
    [...stageList].forEach(ele => {
      if (ele?.color) {
        tempCode.push(ele?.color);
      }
    });
  }
  return tempCode;
};

CallsUtils.getLogOutcomeDropdown = (stageList = [], method = () => {}) => {
  let list = [];
  if (stageList?.length > 0) {
    for (let item of [...stageList]) {
      const {
        id,
        displayEnabled = false,
        name: key = "",
        displayName: value = ""
      } = item || {};
      displayEnabled &&
        list.push({
          id,
          value,
          key,
          method
        });
    }
  }
  return list;
};

export default CallsUtils;
export { CallsUtils };
