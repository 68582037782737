import React, { createContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import EmailPreferenceCard from "../Component/EmailPreferenceCard";
import makeApi, { URLS } from "Utils/apiURL";
import emailPreferenceStore from "./Store";
import AddEmailPreferencesPopup, {
  addEmailPreferencesState
} from "../Component/AddEmailPreferencesPopup";

const EmailReportsContext = createContext({});

const defaultInputValues = {
  USER_USAGE_EMAIL: {
    title: "User Activity Summary",
    text: "Receive your activity summary reports across all channels—email, phone, LinkedIn—directly in your inbox.",
    enabled: false,
    notificationInterval: {},
    group: "EMAIL",
    type: "USER_USAGE_EMAIL",
    toAddress: []
  },
  TEAM_USAGE_EMAIL: {
    title: "Team Activity Summary",
    text: "Receive your team's activity summary report directly in your inbox—a view from the top.",
    enabled: false,
    notificationInterval: {},
    group: "EMAIL",
    type: "TEAM_USAGE_EMAIL",
    toAddress: []
  }
};

const EmailPreference = observer(() => {
  const [emailPreference, setEmailPreference] = useState({
    ...defaultInputValues
  });
  const [loading, setLoading] = useState(false);
  const [toggleLoader, setToggleLoader] = useState("");

  const handleToggleChange = async (value = false, type = "") => {
    if (type) {
      setToggleLoader(type);
      let tempEmailList = { ...emailPreference };
      tempEmailList[type].enabled = value;
      let res = await emailPreferenceStore.editNotification(
        tempEmailList[type]
      );
      if (+res?.status === 200) {
        setEmailPreference(tempEmailList);
      }
      setToggleLoader("");
    }
  };

  const getEmailReportsData = async (loader = true) => {
    if (!loading) {
      setLoading(loader);
      const config = {
        url: URLS.getEmailpreferences,
        method: "GET"
      };
      let res = await makeApi(config);
      if (+res?.status === 200 && res?.data?.length > 0) {
        let tempList = res?.data || [];
        let tempEmailPreference = { ...emailPreference };
        tempList?.length > 0 &&
          tempList.forEach(item => {
            let type = item?.type || "";
            if (type) {
              let obj = tempEmailPreference[type] || "";
              if (obj && Object.keys(obj)?.length > 0) {
                tempEmailPreference[type] = {
                  ...tempEmailPreference[type],
                  ...item,
                  show: true
                };
              }
            }
          });
        setEmailPreference(tempEmailPreference);
      } else {
        setEmailPreference({});
      }
      setLoading(false);
    }
  };

  const handleEditTeamList = (type = "") => {
    if (type) {
      addEmailPreferencesState.setType(type);
      addEmailPreferencesState.setShowPopup(true);
    }
  };

  useEffect(() => {
    getEmailReportsData();
  }, []);

  return (
    <div className="emailPreferenceWrapper">
      {loading ? (
        <Loader count={2} />
      ) : (
        <EmailReportsContext.Provider
          value={{
            setEmailPreference,
            emailPreference,
            getEmailReportsData
          }}
        >
          <div className="epCardTemplate">
            {emailPreference && Object.keys(emailPreference)?.length ? (
              Object.keys(emailPreference)?.map(ele => {
                if (emailPreference?.[ele]?.show) {
                  return (
                    <EmailPreferenceCard
                      toggleLoader={toggleLoader}
                      key={ele}
                      {...emailPreference[ele]}
                      handleToggleChange={handleToggleChange}
                      handleEditTeamList={handleEditTeamList}
                    />
                  );
                }
              })
            ) : (
              <div>No Email Reports Found</div>
            )}
            {addEmailPreferencesState.showPopup &&
              addEmailPreferencesState.type && (
                <AddEmailPreferencesPopup
                  reloadPage={getEmailReportsData}
                  {...emailPreference[addEmailPreferencesState.type]}
                />
              )}
          </div>
        </EmailReportsContext.Provider>
      )}
    </div>
  );
});

const Loader = ({ count = 2 }) => {
  return (
    <div className="epCardTemplate">
      {Array.from({ length: count }, (item, index) => (
        <div className="loaderContainer" key={index}>
          <div className="toggleFlex">
            <div className="cardToggleLoader linear-background" />
            <div className="toggleContent linear-background" />
          </div>
          <div className="contentLoader linear-background" />
          <div className="subContentLoader linear-background" />
          <div className="dropdownLoader linear-background" />
        </div>
      ))}
    </div>
  );
};

export default EmailPreference;
export { EmailReportsContext };
