import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import {
  emailPreviewContentPopupState,
  defaultEditorInputValue
} from "Components/PreviewPopup/EmailPreviewContentPopup";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import TemplateUtils from "Utils/TemplateUtils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import TemplateCard from "Components/TemplateCard/TemplateCardModule";
import QuilEditor from "Components/common/quillEditor";
import InputTextBox from "Components/common/InputTextBox";
import AddRemoveEmailAddress from "./AddRemoveEmailAddress";
import ScheduleLaterSendEmailBtn from "./ScheduleLaterSendEmailBtn";
import Button from "Components/common/Button";
import { toolbarTrackingIconState } from "Components/common/ToolbarTrackingIcon";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import InboxUtils from "Utils/InboxUtils";
import { outboxStoreData } from "Pages/Dashboard/outbox/outboxStore";
import { writingAssistantStoreData } from "Stores/WritingAssistantData";
import ScheduleLaterDateTimeBanner from "./ScheduleLaterDateTimeBanner";
import ScheduleLaterMessagePrompt from "./ScheduleLaterMessagePrompt";

export const EmailComposeEditor = observer(() => {
  const [inputValue, setInputValue] = useState(
    JSON.parse(JSON.stringify(emailPreviewContentPopupState.editorInputValue))
  );
  const [editorValue, setEditorValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [attachments, setAttachments] = useState(
    emailPreviewContentPopupState.editorInputValue?.emailData?.attachments || []
  );
  const [isSubjectFocused, setIsSubjectFocused] = useState(false);
  const [subjectCursorPosition, setSubjectCursorPosition] = useState(0);
  const { isOutboxPage = false, pageType = "" } =
    emailPreviewContentPopupState?.popupValues || {};

  const handleStateAction = (key = "", value = "", type = "") => {
    let tempObj = { ...inputValue };
    if (key?.toLowerCase() === "all") {
      setInputValue(value);
      emailPreviewContentPopupState.setEditorInputValue(value);
      return;
    }
    if (key && type) {
      if (type === "emailData") {
        let emailData = { ...tempObj.emailData, [key]: value };
        tempObj = { ...tempObj, emailData };
      } else {
        let localState = { ...tempObj.localState, [key]: value };
        tempObj = { ...tempObj, localState };
      }
      setInputValue(tempObj);
      emailPreviewContentPopupState.setEditorInputValue(tempObj);
      return;
    }
  };

  const closePopup = () => {
    setLoader(false);
    fileAttachmentStore.clearAttachmentList();
    setInputValue(JSON.parse(JSON.stringify({ ...defaultEditorInputValue })));
    toolbarTrackingIconState.resetValue();
    emailPreviewContentPopupState.setResetPopup();
  };

  const carbonCopyList = [
    {
      enabled: inputValue?.localState?.showCcInputAddress,
      emailList: inputValue?.emailData?.cc || [],
      label: "Cc:",
      keyName: "cc",
      objKey: "emailData",
      updateState: handleStateAction
    },
    {
      enabled: inputValue?.localState?.showBccInputAddress,
      emailList: inputValue?.emailData?.bcc || [],
      label: "Bcc:",
      keyName: "bcc",
      objKey: "emailData",
      updateState: handleStateAction
    }
  ];

  const handleErrorStatusCode = (statusCode = "", errorInbox = "") => {
    const codeTextMsg = {
      407: errorInbox?.length
        ? `Oops! Looks like the inbox ${errorInbox} is not connected. Please connect this inbox to send the email.`
        : "Please connect an inbox to send emails.",
      401: "Oops! You don't have access to send emails to this contact.",
      408: "Oops! you cannot reply or forward emails from an inbox connected via SMTP.",
      410: "Oops! you cannot send one-off emails from an inbox connected via SMTP."
    };
    let msg = codeTextMsg?.[statusCode] || "";
    toasterState.setToastMsg(
      msg || "Something went wrong. Please try again later",
      "fail"
    );
  };

  const hasToAddress = () => {
    if (inputValue?.emailData?.["to"]?.length) {
      let toLen = inputValue?.emailData?.["to"]?.length;
      let invalidToCount =
        toLen &&
        inputValue.emailData.to.reduce(
          (acc, item) => (item.invalid ? acc + 1 : acc),
          0
        );
      return invalidToCount !== toLen;
    }
    return false;
  };

  const getActualContent = () => {
    if (~editorValue.indexOf("<blockquote>")) {
      let splittedDom = editorValue.split("<blockquote>")?.[0] || "";
      if (splittedDom) {
        let tempDom = document.createElement("div");
        tempDom.innerHTML = splittedDom;
        let childElementCount =
          tempDom.querySelectorAll(".ql-editor")?.[0]?.childElementCount || 0;
        if (childElementCount > 1) {
          let tempDomList = [];
          let selectedDom = tempDom.querySelectorAll(".ql-editor div");
          if (selectedDom?.length) {
            tempDom.innerHTML = selectedDom?.forEach((item, index) => {
              if (index < childElementCount - 2) {
                tempDomList.push(`<div>${item.innerHTML}</div>`);
              }
            });
            tempDom.innerHTML =
              tempDomList?.map(item => item.trim())?.join("") || editorValue;
            return tempDom.innerHTML;
          }
        }
      }
    }
    return editorValue;
  };

  const setSubjectStateFromEditor = value => {
    let existingSubject =
      emailPreviewContentPopupState?.editorInputValue?.emailData?.subject || "";
    let tempSubject =
      existingSubject?.substring(0, subjectCursorPosition) +
      value +
      existingSubject?.substring(
        subjectCursorPosition,
        existingSubject?.length || 0
      );
    let tempObj = { ...inputValue };
    let emailData = { ...tempObj?.emailData, subject: tempSubject };
    tempObj = { ...tempObj, emailData };
    setInputValue(tempObj);
    emailPreviewContentPopupState.setEditorInputValue(tempObj);
    Utils.focusInputBox("subject");
  };

  const constructSendTimelimeEmailPayload = () => {
    const {
      sequenceId = "",
      activityId = "",
      emailType = "",
      emailData = {},
      taskId = "",
      isEnableReplyFrwd = false
    } = inputValue || {};
    const { clickTrackingEnabled = false, openTrackingEnabled = false } =
      toolbarTrackingIconState.inputValue || {};
    const {
      subject = "",
      from = "",
      to = [],
      cc = [],
      bcc = [],
      threadId = ""
    } = emailData || {};
    let payload = {
      emailData: {
        subject: subject,
        content: editorValue,
        from: { email: from },
        to: InboxUtils.getListOfEmailAndName(to),
        cc: InboxUtils.getListOfEmailAndName(cc),
        bcc: InboxUtils.getListOfEmailAndName(bcc),
        attachments: attachments
      },
      openTrackingEnabled,
      clickTrackingEnabled,
      emailType: ["REPLY_ALL"].includes(emailType) ? "REPLY" : emailType
    };
    if (emailType !== "ONE_OFF_EMAIL") {
      if (isEnableReplyFrwd) {
        taskId && (payload.taskId = taskId);
      } else {
        activityId && (payload.activityId = activityId);
      }
      sequenceId && (payload.sequenceId = sequenceId);
      payload.actualContent = getActualContent();
      threadId && (payload.emailData.threadId = threadId);
    }
    let dueDateString = Utils.createScheduleDate(
      emailPreviewContentPopupState?.scheduleLaterDateTime || new Date()
    );
    let timeZone = Utils.getCurrentUserTimeZone();
    let splittedTimezone = timeZone?.replace("UTC", "") || "";
    dueDateString = `${dueDateString}${splittedTimezone}`;
    payload = {
      ...payload,
      dueDateString,
      timeZone
    };
    if (emailPreviewContentPopupState?.scheduleLaterDateTime) {
      payload.scheduledOnComplete = true;
    }
    return payload;
  };

  const sendEmail = async () => {
    if (
      !loader &&
      !fileAttachmentStore?.fileUploadingLoader &&
      hasToAddress() &&
      inputValue?.emailData?.subject?.trim() &&
      inputValue?.emailData?.from
    ) {
      let isSmtpEmailAccount = InboxUtils.smtpConnectedAccount(
        inputValue?.emailData?.from
      );
      if (!isSmtpEmailAccount) {
        setLoader(true);
        let data = constructSendTimelimeEmailPayload();
        if (data?.emailData?.to?.length > 0) {
          const url = inputValue?.isEnableReplyFrwd
            ? URLS.sendReplyEmail
            : URLS.sendTimelineEmail;
          const config = {
            url,
            method: "POST",
            data
          };
          let res = await makeApi(config);
          if (res?.data?.statusCode === 200) {
            if (["gloabalheader"].includes(pageType?.toLowerCase())) {
              Utils.mixpanelTrack(MXP_EVENT.ONE_OFF_EMAIL_SENT_HEADER, {
                pageType: PAGE_TYPE.header,
                userEmail: inputValue?.emailData?.from
              });
            } else {
              Utils.mixpanelTrack(MXP_EVENT.ONE_OFF_EMAIL_SENT, {
                pageType: "contacts page",
                userEmail: inputValue?.emailData?.from
              });
            }
            if (emailPreviewContentPopupState?.scheduleLaterDateTime) {
              emailPreviewContentPopupState.setScheduleLaterDateTime("");
              confirmationPopupState.setPopupValues({
                content: <ScheduleLaterMessagePrompt />,
                actionBtnText: "Ok",
                needCancelBtn: false,
                dynamicDom: true,
                callback: () => confirmationPopupState.setShowPopup(false)
              });
              confirmationPopupState.setShowPopup(true);
            } else {
              toasterState.setToastMsg("Email sent successfully", "success");
            }
          } else {
            handleErrorStatusCode(
              res?.data?.statusCode || 500,
              res?.data?.errorInbox
            );
          }
          closePopup();
        } else {
          toasterState.setToastMsg(
            "Please ensure there's a recipient before sending the email",
            "fail"
          );
        }
      } else {
        handleErrorStatusCode(
          inputValue?.emailType === "ONE_OFF_EMAIL" ? 410 : 408,
          null
        );
      }
    }
  };

  const getOutboxSendEditEmailPayload = () => {
    let { subject = "", id = "" } =
      emailPreviewContentPopupState?.editorInputValue?.emailData || {};
    let payload = {
      subject,
      content: editorValue,
      taskId: id,
      attachments
    };
    return payload;
  };

  const outboxSendEditEmailAction = async () => {
    setLoader(true);
    const config = {
      url: URLS.outboxSendEditEmail,
      method: "POST",
      data: getOutboxSendEditEmailPayload()
    };
    let res = await makeApi(config);
    if (+res?.status === 200) {
      outboxStoreData.setRelaodOutboxPage(true);
      toasterState.setToastMsg(
        "Email content updated successfully.",
        "success"
      );
    } else {
      toasterState.setToastMsg(
        "Oops! Failed to update the email content. Please try again later.",
        "failure"
      );
    }
    setLoader(false);
    emailPreviewContentPopupState.setShowPopup(false);
    fileAttachmentStore.clearAttachmentList();
    writingAssistantStoreData.setBodyContent("");
    writingAssistantStoreData.setInputValue({ content: "", subject: "" });
    emailPreviewContentPopupState.setResetPopup();
    outboxStoreData.setActiveDataInPopup("");
    outboxStoreData.setOutboxPopupContent("");
  };

  const updateEditorContent = () => {
    let {
      content = "",
      subject = "",
      insertSignature = false
    } = writingAssistantStoreData?.inputValue || {};
    if (content) {
      isSubjectFocused && setIsSubjectFocused(false);
      let tempContent = insertSignature
        ? Utils.insertSignature(inputValue?.emailData?.from, true, content)
        : content;
      setEditorValue(tempContent);
      let emailData = {
        ...(inputValue?.emailData || {}),
        content: tempContent
      };
      let tempSubject = inputValue?.emailData?.subject?.toLowerCase() || "";
      if (tempSubject?.indexOf("re:") < 0 && subject) {
        emailData.subject = subject;
      }
      let localState = {
        ...inputValue.localState,
        editorInsertCounter: inputValue?.localState?.editorInsertCounter + 1
      };
      handleStateAction("all", { ...inputValue, emailData, localState });
    }
  };

  const handleFocus = (e = "") => {
    if (!isOutboxPage) {
      Utils.focusInputInsideDOM(e);
    }
  };

  useEffect(() => {
    updateEditorContent();
  }, [writingAssistantStoreData?.inputValue]);

  return (
    <>
      <div className={`emailComposeEditor ${loader ? "disabled" : ""}`}>
        <FromAddress
          inboxList={InboxUtils.getConnectedInboxWithoutSmtp()}
          from={inputValue.emailData.from}
          isOneOffEmail={inputValue.emailType === "ONE_OFF_EMAIL"}
          updateState={handleStateAction}
          isOutboxPage={isOutboxPage}
        />
        <div
          className={`senderAddressInputSection ${
            isOutboxPage ? "disabled" : ""
          } ${
            inputValue?.localState?.showCcInputAddress &&
            inputValue?.localState?.showBccInputAddress
              ? "fullRowWidth"
              : ""
          }`}
          onClick={e => handleFocus(e)}
        >
          <AddRemoveEmailAddress
            emailList={inputValue?.emailData?.to || []}
            label="To:"
            keyName="to"
            objKey="emailData"
            updateState={handleStateAction}
            isOutboxPage={isOutboxPage}
            pageType={pageType}
          />
          {!isOutboxPage && (
            <CarbonCopyEmailAddressToggler
              {...inputValue.localState}
              updateState={handleStateAction}
            />
          )}
        </div>
        <div className="columnLayout">
          {carbonCopyList.map((item, index) => {
            if (item?.enabled) {
              return (
                <AddRemoveEmailAddress
                  key={`${item.keyName}${index}`}
                  {...item}
                  pageType={pageType}
                />
              );
            }
          })}
        </div>
        <div
          className={`subjectTemplateWrap ${
            isOutboxPage ? "itemListBlock" : ""
          }`}
        >
          <SubjectInputBox
            setSubjectCursorPosition={setSubjectCursorPosition}
            inputValue={inputValue}
            updateState={handleStateAction}
            handleFocus={setIsSubjectFocused}
            isOutboxPage={isOutboxPage}
          />
          {!isOutboxPage && (
            <InsertTemplate
              setAttachments={setAttachments}
              inputValue={inputValue}
              closePopup={closePopup}
              updateState={handleStateAction}
            />
          )}
        </div>
        <ComposeEditor
          attachments={attachments}
          setAttachments={setAttachments}
          editorValue={editorValue}
          setEditorValue={setEditorValue}
          inputValue={inputValue}
          updateState={handleStateAction}
          isOutboxPage={isOutboxPage}
          isSubjectFocused={isSubjectFocused}
          setIsSubjectFocused={setIsSubjectFocused}
          subjectCursorPosition={subjectCursorPosition}
          setSubjectStateFromEditor={setSubjectStateFromEditor}
        />
      </div>
      <ActionButton
        loader={loader}
        isOutboxPage={isOutboxPage}
        enabled={
          !loader &&
          !fileAttachmentStore?.fileUploadingLoader &&
          hasToAddress() &&
          inputValue?.emailData?.subject?.trim() &&
          inputValue?.emailData?.from
        }
        sendEmail={sendEmail}
        outboxSendEditEmailAction={outboxSendEditEmailAction}
        close={closePopup}
        setLoader={setLoader}
        handleDateChange={value => {
          emailPreviewContentPopupState.setScheduleLaterDateTime(
            new Date(value)
          );
        }}
      />
    </>
  );
});

const FromAddress = (props = {}) => {
  const {
    from = "",
    isOneOffEmail = false,
    updateState = () => {},
    inboxList = [],
    isOutboxPage = false
  } = props || {};

  let fromEmail = from?.name
    ? `${from?.name}<${from?.email || from}>`
    : `${from?.email || from}`;

  const [showDropdown, setShowDropdown] = useState(false);

  const changeFromAddress = (email = "") => {
    if (email) {
      setShowDropdown(false);
      updateState("from", email, "emailData");
    }
  };

  return (
    <div className={`fromAddress ${isOutboxPage ? "disabled" : ""}`}>
      <span className="labelName">From:</span>
      <a
        className="fromEmailText highlightBlue"
        href={`mailto:${fromEmail}`}
        target="_blank"
        rel="noreferrer noopener nofollow"
      >
        {fromEmail}
      </a>
      {isOneOffEmail && inboxList?.length > 0 && (
        <span className="selectInboxDropdownSection">
          <i
            className="material-icons-outlined dropdownArrow"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {showDropdown ? "expand_less" : "expand_more"}
          </i>
          {showDropdown && (
            <>
              <div className="drop-down-menu animatedFast">
                <ul
                  className="dropdownList selectInboxDropdown"
                  id="selectInboxDropdown"
                >
                  {inboxList.map((item, index) => {
                    const { email = "" } = item || {};
                    return (
                      <li
                        key={`connectedInboxList${index}`}
                        id={`${email}${index}`}
                        onClick={() => changeFromAddress(email)}
                        className={`option ${
                          email === fromEmail ? "alreadySelected" : ""
                        }`}
                      >
                        {email}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div
                className="connectedInboxOverlay"
                onClick={() => setShowDropdown(!showDropdown)}
              />
            </>
          )}
        </span>
      )}
    </div>
  );
};

const CarbonCopyEmailAddressToggler = (props = {}) => {
  const {
    showCcInputAddress = false,
    showBccInputAddress = false,
    updateState = () => {}
  } = props || {};

  const diffAddressList = [
    {
      name: "Cc",
      id: "showCcInputAddress",
      value: showCcInputAddress,
      action: updateState
    },
    {
      name: "Bcc",
      id: "showBccInputAddress",
      value: showBccInputAddress,
      action: updateState
    }
  ];

  return (
    (!showCcInputAddress || !showBccInputAddress) && (
      <div className="diffAddressList">
        {diffAddressList?.map(item => {
          const { id = "", name = "", value = false } = item || {};
          if (!value) {
            return (
              <span
                key={id}
                id={id}
                className={`addressType`}
                onClick={() => updateState(id, !value, "localState")}
              >
                {name}
              </span>
            );
          }
        })}
      </div>
    )
  );
};

const SubjectInputBox = (props = {}) => {
  const {
    isOutboxPage = false,
    inputValue = {},
    setSubjectCursorPosition = () => {},
    updateState = () => {},
    handleFocus = () => {}
  } = props || {};

  const [errMsg, setErrMsg] = useState("");

  const changeAction = (id, value) => {
    errMsg && setErrMsg("");
    updateState(id, value, "emailData");
  };

  const checkReplyOrFrwdSubject = () => {
    const normalizedSubject =
      inputValue?.emailData?.subject?.toLowerCase() || "";
    if (normalizedSubject) {
      return (
        normalizedSubject?.startsWith("re:") ||
        normalizedSubject?.startsWith("fwd:")
      );
    }
    return false;
  };

  const inputObj = {
    name: "subject",
    id: "subject",
    type: "text",
    value: inputValue.emailData.subject,
    change: changeAction,
    err: errMsg,
    placeHolder: "Subject",
    onSelectCapture: e => {
      handleFocus(true);
      setSubjectCursorPosition(e.target.selectionStart);
    },
    onfocus: e => handleFocus(true)
  };

  return (
    <div
      className={`subjectSection ${isOutboxPage ? "outboxHighlight" : ""} ${
        checkReplyOrFrwdSubject() ? "disableSubject" : ""
      }`}
    >
      {isOutboxPage && <div className="label">Subject : </div>}
      <InputTextBox {...inputObj} />
    </div>
  );
};

const InsertTemplate = (props = {}) => {
  const {
    inputValue = {},
    updateState = () => {},
    closePopup = () => {},
    setAttachments = () => {}
  } = props || {};

  const history = useHistory();

  const { localState = {} } = inputValue || {};
  const {
    isTemplateInserted = false,
    templateName = "",
    editorInsertCounter = ""
  } = localState || {};

  const [showDropdown, setShowDropdown] = useState(false);

  const clearTemplate = () => {
    let tempObj = {};
    fileAttachmentStore.clearAttachmentList();
    writingAssistantStoreData.setAttachmentUpdated(true);
    setAttachments([]);
    const {
      emailType = "",
      emailData = {},
      replyForwardData = {},
      localState = {}
    } = emailPreviewContentPopupState.editorInputValue || {};
    let isOneOffEmail = emailType === "ONE_OFF_EMAIL";
    const {
      forwardSubject = "",
      replySubject = "",
      forwardContent = "",
      replyContent = ""
    } = replyForwardData || {};
    let newContent = "";
    if (!isOneOffEmail) {
      newContent = Utils.addEmptySpaceAboveThreadEmail(
        emailType === "FORWARD" ? forwardContent : replyContent
      );
    }
    let emailDataObj = {
      ...emailData,
      ...{
        subject: isOneOffEmail
          ? ""
          : emailType === "FORWARD"
          ? forwardSubject
          : replySubject,
        attachments: isOneOffEmail ? [] : replyForwardData?.attachments || [],
        content: isOneOffEmail
          ? Utils.insertSignature(emailData?.from, true)
          : Utils.splitAndJoinDomWithoutSpace(newContent)
      }
    };
    let localStateObj = {
      ...localState,
      ...{
        isTemplateInserted: false,
        templateName: "",
        editorInsertCounter: 0
      }
    };
    tempObj = {
      ...emailPreviewContentPopupState.editorInputValue,
      ...{ emailData: emailDataObj, localState: localStateObj }
    };

    updateState("all", tempObj);
  };

  const createTemplate = (folderId = "all") => {
    setShowDropdown(false);
    closePopup();
    history.push(`/templates/${folderId}`);
  };

  const insertTemplateAction = (obj = {}) => {
    const {
      name = "",
      subject = "",
      content = "",
      attachments = []
    } = obj || {};
    if (subject && content) {
      let tempObj = {};
      const {
        emailType = "",
        replyForwardData = {},
        emailData = {},
        localState = {},
        pageType = ""
      } = emailPreviewContentPopupState.editorInputValue || {};
      let isOneOffEmail = emailType === "ONE_OFF_EMAIL";
      const { forwardContent = "", replyContent = "" } = replyForwardData || {};
      let newContent = "";
      if (!isOneOffEmail) {
        newContent = Utils.joinThreadedEmailAndTemplate(
          emailType === "FORWARD" ? forwardContent : replyContent,
          content
        );
      }
      let emailDataObj = {
        ...emailData,
        ...{
          subject,
          attachments,
          content: isOneOffEmail
            ? Utils.insertSignature(emailData.from, true, content)
            : Utils.splitAndJoinDomWithoutSpace(newContent)
        }
      };
      let localStateObj = {
        ...localState,
        ...{
          isTemplateInserted: true,
          templateName: name,
          editorInsertCounter: editorInsertCounter + 1
        }
      };
      tempObj = {
        ...emailPreviewContentPopupState.editorInputValue,
        ...{ emailData: emailDataObj, localState: localStateObj }
      };
      const cbk = () => {
        if (attachments?.length > 0) {
          let tempAtt = attachments.map(item => {
            return {
              attachments: item
            };
          });
          fileAttachmentStore.setFileAttachmentList(tempAtt);
        }
        updateState("all", tempObj);
      };
      editorInsertCounter > 1
        ? TemplateUtils.insertTemplateConfirmationPrompt(cbk)
        : cbk();
      Utils.mixpanelTrack(MXP_EVENT.CT_TL_TP_INSERTED, {
        pageType: isOneOffEmail ? pageType : PAGE_TYPE["contactTimeline"],
        template: obj
      });
      setShowDropdown(false);
    } else {
      toasterState.setToastMsg(
        "Sorry, you cannot insert an empty template",
        "failure"
      );
    }
  };

  return (
    <div className="insertTemplateSection">
      <div
        className={`templateCont ${
          isTemplateInserted ? "templateInserted" : ""
        }`}
      >
        <div className="inputWrapper">
          <div className="inputCont">
            <input
              type="text"
              className="inputBox template"
              id="selectTemplate"
              placeholder="Select Template"
              readOnly
              onClick={() => {
                !isTemplateInserted && setShowDropdown(true);
              }}
              value={templateName}
            />
            {isTemplateInserted && (
              <i className="material-icons sgCloseIcon" onClick={clearTemplate}>
                close
              </i>
            )}
          </div>
        </div>
        {!isTemplateInserted && (
          <i className="material-icons-outlined upDownArrow">
            {showDropdown ? "expand_less" : "expand_more"}
          </i>
        )}
        {showDropdown && (
          <TemplateCard
            insertTemplateCbk={insertTemplateAction}
            createTemplate={createTemplate}
            close={() => setShowDropdown(false)}
          />
        )}
      </div>
    </div>
  );
};

const ComposeEditor = observer((props = {}) => {
  const {
    inputValue = {},
    attachments = [],
    setAttachments = () => {},
    setEditorValue = () => {},
    updateState = () => {},
    isOutboxPage = false,
    isSubjectFocused = false,
    setIsSubjectFocused = () => {},
    setSubjectStateFromEditor = () => {}
  } = props || {};

  let editorContent = inputValue?.emailData?.content || "";

  const getEntityObj = () => {
    return {
      pageType: "contactTimeline",
      attachments,
      updateState: (attList = []) => {
        updateState("attachments", attList, "emailData");
        setAttachments(attList);
      }
    };
  };

  const bodyOnchangeEvnt = (val = "") => {
    isSubjectFocused && setIsSubjectFocused(false);
    if (val) {
      setEditorValue(val);
      updateState(
        "editorInsertCounter",
        inputValue?.localState?.editorInsertCounter + 1,
        "localState"
      );
      writingAssistantStoreData.setBodyContent(val);
    }
  };

  const updateAttachmentsInLocalState = () => {
    let newAttList = Utils.getAttachmentsArr(
      fileAttachmentStore?.fileAttachmentList,
      attachments
    );
    updateState("attachments", newAttList, "emailData");
    setAttachments(newAttList);
  };

  useEffect(() => {
    fileAttachmentStore?.fileAttachmentList && updateAttachmentsInLocalState();
  }, [fileAttachmentStore.fileAttachmentList]);

  return (
    <div
      className={`editor ${isOutboxPage ? "outboxHighlight" : ""} ${
        emailPreviewContentPopupState?.scheduleLaterDateTime
          ? "scheduleLaterDateTimeBanner"
          : ""
      }`}
      id="emailComposeEditor"
    >
      {emailPreviewContentPopupState?.scheduleLaterDateTime && (
        <ScheduleLaterDateTimeBanner
          closeCbk={() =>
            emailPreviewContentPopupState?.setScheduleLaterDateTime("")
          }
          scheduledDate={emailPreviewContentPopupState?.scheduleLaterDateTime}
        />
      )}
      <QuilEditor
        bodyOnChange={bodyOnchangeEvnt}
        prefilledValue={editorContent}
        entityObj={getEntityObj()}
        attachments={inputValue?.emailData?.attachments || []}
        stageType="email"
        type="reply"
        isOutboxPage={isOutboxPage}
        setsubjectFocus={setIsSubjectFocused}
        isSubjectFocused={isSubjectFocused}
        showRewriteAI={true}
        setSubject={setSubjectStateFromEditor}
      />
    </div>
  );
});

const ActionButton = observer((props = {}) => {
  const {
    loader = false,
    enabled = false,
    isOutboxPage = false,
    close = () => {},
    sendEmail = () => {},
    outboxSendEditEmailAction = () => {},
    handleDateChange = () => {}
  } = props || {};

  const handleCancelAction = () => {
    fileAttachmentStore.clearAttachmentList();
    writingAssistantStoreData.setBodyContent("");
    writingAssistantStoreData.setInputValue({ content: "", subject: "" });
    emailPreviewContentPopupState.setShowPopup(false);
    emailPreviewContentPopupState.setResetPopup(false);
    outboxStoreData.setOutboxCardPopup(true);
  };

  const sendEmailObj = {
    id: "sendEmailBtn",
    name: "sendEmailBtn",
    btnText: isOutboxPage
      ? "Save"
      : emailPreviewContentPopupState?.scheduleLaterDateTime
      ? "Schedule"
      : "Send",
    type: "button",
    className: `actionBtn sendEmailBtn ${loader ? "loading" : ""} ${
      !enabled ? "disabled" : ""
    } ${!isOutboxPage && !loader ? "btnRoundedLeft" : ""}`,
    click: () => {
      if (!fileAttachmentStore?.fileUploadingLoader) {
        isOutboxPage ? outboxSendEditEmailAction() : !loader && sendEmail();
      }
    },
    loader
  };

  const cancelObj = {
    id: "cancelBtn",
    name: "cancelBtn",
    btnText: "Cancel",
    type: "button",
    className: `actionBtn cancelBtn`,
    click: () => {
      if (!fileAttachmentStore?.fileUploadingLoader) {
        isOutboxPage ? handleCancelAction() : close();
      }
    }
  };

  return (
    <div className="actionBtnList">
      <Button {...cancelObj} />
      <ScheduleLaterSendEmailBtn
        isOutboxPage={isOutboxPage}
        sendEmailObj={sendEmailObj}
        enabled={enabled}
        handleDateChange={handleDateChange}
        scheduledDate={new Date()}
      />
    </div>
  );
});

export default EmailComposeEditor;
