import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import ConstantObj from "Static/constant";
import JumpBallLoader from "./JumpBallLoader";

const YoutubeTutorial = props => {
  const { closeCbk, videoId } = props;
  const [isVideoloaded, setVideoLoaded] = useState(false);

  const options = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 1,
      rel: 0,
      modestbranding: 1,
      iv_load_policy: 0
    }
  };

  useEffect(() => {
    return () => {
      setVideoLoaded(false);
    };
  }, []);

  return (
    <div className="youtubePlayer">
      <div
        className={`youtubeVideoCont ${!isVideoloaded ? "loadingVideo" : ""}`}
      >
        <YouTube
          videoId={videoId}
          id={videoId}
          className="youtubeVideo  customzoomIn"
          opts={options}
          onReady={() => setVideoLoaded(true)}
          containerClassName="videoParent"
          origin={ConstantObj.CLIENT_URL}
        />
        {isVideoloaded && (
          <div
            className="btn closeButton animated customzoomIn"
            onClick={closeCbk}
          >
            Close
          </div>
        )}
        {!isVideoloaded && <JumpBallLoader color="white" />}
      </div>
    </div>
  );
};

export default YoutubeTutorial;
