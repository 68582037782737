import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ReactComponent as LinkedinIcon } from "Assets/svg/linkedin.svg";
import { ReactComponent as TaskIcon } from "Assets/svg/task.svg";
import { ReactComponent as PhoneIcon } from "Assets/svg/call.svg";
import { ReactComponent as CustomTaskIcon } from "Assets/svg/customTask.svg";
import Utils from "Utils/utils";
import TaskFilterLoader from "./TaskFilterLoader";
import taskModuleState from "./state";

const TaskFilter = observer(() => {
  const [taskFilteredData, setTaskFilteredData] = useState([]);

  const getEmailCount = (manualEmail, email) => {
    let count = 0;
    if (typeof manualEmail != "undefined") {
      count += parseInt(manualEmail);
    }
    if (typeof email != "undefined") {
      count += parseInt(email);
    }
    return count;
  };

  const constructFilterRenderData = filteredDataArray => {
    if (filteredDataArray?.length > 0) {
      const filterData = filteredDataArray.reduce(
        (obj, item) => Object.assign(obj, { [item?.taskType]: item.count }),
        {}
      );
      const {
        email = 0,
        linkedin = 0,
        manualEmail = 0,
        phone = 0,
        customTask = 0
      } = filterData;
      setTaskFilteredData([
        {
          name: "Custom Task",
          id: "customTask",
          iconClass: "customTask",
          count: customTask ? customTask : 0,
          iconType: "svg",
          svg: <CustomTaskIcon />
        },
        {
          name: "Phone Call",
          id: "phone",
          iconClass: "phone",
          count: phone ? phone : 0,
          iconType: "svg",
          svg: <PhoneIcon />
        },
        {
          name: "LinkedIn Task",
          id: "linkedin",
          iconClass: "linkedin",
          count: linkedin ? linkedin : 0,
          iconType: "svg",
          svg: <LinkedinIcon />
        },
        {
          name: "Manual Email",
          id: "email",
          iconClass: "email",
          count: getEmailCount(manualEmail, email),
          iconType: "icon"
        },
        {
          name: "All",
          id: "total",
          iconClass: "person_add",
          count: filterData.total,
          iconType: "svg",
          svg: <TaskIcon />
        }
      ]);
    }
  };

  const filterAction = filterId => {
    taskModuleState.setFilter(filterId);
    taskModuleState.setActiveTab(filterId);
    taskModuleState.setTaskDataLoading(true);
    const dateFilterValue = taskModuleState?.dateFilterValue;
    if (dateFilterValue) {
      if (filterId === "total") {
        taskModuleState.getTaskData(null, null, true, null, dateFilterValue);
      } else {
        taskModuleState.getTaskData(
          filterId,
          null,
          null,
          null,
          dateFilterValue
        );
      }
    } else {
      if (filterId === "total") {
        taskModuleState.getTaskData(null, null, true, null, null);
      } else {
        taskModuleState.getTaskData(filterId, null, null, null, null);
      }
    }
  };

  useEffect(() => {
    constructFilterRenderData(taskModuleState?.taskFilterData);
  }, [taskModuleState?.taskFilterData]);

  return (
    <section className="taskFilterSection">
      {!taskModuleState?.filterLoading ? (
        taskFilteredData?.length > 0 &&
        taskFilteredData?.map(value => {
          const {
            id = "",
            name = "",
            count = 0,
            iconClass = "",
            svg = "",
            iconType = ""
          } = value;
          return (
            <div
              className={`filterDetails ${
                value?.id === taskModuleState?.activeTab ? "active" : ""
              }`}
              onClick={e => {
                filterAction(value?.id, value?.count);
              }}
              key={`${value?.id}`}
            >
              <div className="filterOption">
                <div className="filterValueCont">
                  {iconType === "svg" ? (
                    <span className={`svgIcon ${iconClass}`}>{svg}</span>
                  ) : (
                    <i className="material-icons-outlined ">{iconClass}</i>
                  )}
                  <span className="filterLabel">{name}</span>
                </div>
                <span
                  className={`filterValue ${
                    taskModuleState?.activeTab === id ? "active" : ""
                  }`}
                >
                  {Utils.formatNumberToStringInUSFormat(count)}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <TaskFilterLoader />
      )}
    </section>
  );
});

export { TaskFilter };
export default TaskFilter;
