import React from "react";

const KnowMoreButton = props => {
  const { redirectUrl = "", text = "Learn more" } = props;

  const handleRedirectLink = () => {
    window.open(redirectUrl, "_blank", "noopener");
  };

  return (
    <div className="knowMoreBtnWrapper" onClick={handleRedirectLink}>
      <span className="knowMoreText">{text}</span>
      <span className="material-icons-outlined launchIcon">launch</span>
    </div>
  );
};

export { KnowMoreButton };
export default KnowMoreButton;
