import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SequencesComponent from "Pages/Dashboard/sequences/main";

const SequencesRouting = () => {
  return (
    <Switch>
      <Route path="/sequences/all/:id?" render={() => <SequencesComponent />} />
      <Route
        path="/sequences/shared/:sharedId?"
        render={() => <SequencesComponent shared />}
      />
      <Route
        path="/sequences/shared"
        render={() => <SequencesComponent shared />}
      />
      <Route path="/sequences/all" render={() => <SequencesComponent />} />
      <Route
        path="/sequences/:folderId/:id?"
        render={() => <SequencesComponent />}
      />
      <Redirect from="/sequences" to="/sequences/all" />
    </Switch>
  );
};

export default SequencesRouting;
