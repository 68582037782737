import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import FilterDropdown from "./FilterDropdown";
import { tagsStoreData } from "Stores/TagsData";

const SelectTags = observer(props => {
  const {
    setActiveDropdownIndex = () => {},
    change = () => {},
    defaultValue = ""
  } = props || {};
  const selectBoxRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState({});

  const filterCbk = item => {
    change("tagValue", item);
    setSelectedItem(item);
    setActiveDropdownIndex(null);
  };

  return (
    <div className="selectedTagWrapper">
      <FilterDropdown
        options={tagsStoreData?.tagsData}
        isCustomValue={true}
        customCbk={filterCbk}
        refValue={selectBoxRef}
        hasValue={true}
        {...props}
        defaultValue={selectedItem?.name ? selectedItem?.name : defaultValue}
        emptyText="There are no Contact tags"
      />
    </div>
  );
});

export { SelectTags };
export default SelectTags;
