import React from "react";
import { ReactComponent as EditSvg } from "Assets/svg/edit.svg";

const EditActionIcon = props => {
  const { action, id, itemValue, title, spaceRight = 0 } = props;

  return (
    <span
      className="editActionIcon"
      title={title}
      onClick={e => {
        action(e, id, itemValue);
      }}
      style={{ marginRight: spaceRight + "px" }}
    >
      <EditSvg />
    </span>
  );
};

export default EditActionIcon;
export { EditActionIcon };
