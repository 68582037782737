import React, { useState, useEffect } from "react";
import { ReactComponent as ThunderBasicIcon } from "Assets/svg/thunderBasic.svg";
import { ReactComponent as ThunderProIcon } from "Assets/svg/thunderPro.svg";
import { ReactComponent as ThunderUnlimitedIcon } from "Assets/svg/thunderUnlimited.svg";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import UpgradeTooltip from "Components/Paywall/UpgradeTooltip";
import Utils from "Utils/utils";
import { Paywall } from "model/revampedModel";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

export const UpgradeIconTooltip = props => {
  const {
    type = "",
    enableTooltip = false,
    alignTooltip = "",
    closeDropdown = () => {},
    showIcon = false,
    plan = "basicPlan",
    customToggle = false,
    toggleCbkVal = false
  } = props || {};

  const {
    noOfSequencesEligible = 0,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const [showTooltip, setShowTooltip] = useState(false);

  const showUpgradePopup = event => {
    Utils.preventDefaultFn(event);
    if (!enableTooltip && !showTooltip) {
      closeDropdown && closeDropdown(false);
      upgradePopupState.setPopupType(type);
      upgradePopupState.setPopupValues({});
      upgradePopupState.setPopup(true);
    }
  };

  const toggleTooltip = value => {
    if (!customToggle & enableTooltip) {
      setShowTooltip(value);
    }
  };

  useEffect(() => {
    if (showTooltip || type || enableTooltip) {
      PaywallUtils.upgradeUtilsFunction(
        type,
        false,
        planName,
        inFreePlan,
        { sequenceCount: noOfSequencesEligible },
        true
      );
    }
  }, [showTooltip, type, enableTooltip]);

  return type ? (
    <div
      className={`freemiumIcon`}
      onClick={e => !showIcon && showUpgradePopup(e)}
      onMouseEnter={() => toggleTooltip(true)}
      onMouseLeave={() => toggleTooltip(false)}
    >
      {plan === "basicPlan" ? (
        <ThunderBasicIcon className="thunderSvg" />
      ) : plan === "proPlan" ? (
        <ThunderProIcon className="thunderSvg" />
      ) : (
        <ThunderUnlimitedIcon className="thunderSvg" />
      )}
      {!customToggle
        ? enableTooltip && showTooltip
        : toggleCbkVal && (
            <UpgradeTooltip
              {...Paywall(upgradePopupState?.popupValues?.type)}
              alignTooltip={alignTooltip}
            />
          )}
    </div>
  ) : (
    ""
  );
};

export default UpgradeIconTooltip;
