import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import { linkedInReplyPopupState } from "Components/PreviewPopup/LinkedInReplyContentPopup";

let liveFeedNetworkRequest = {
  getPreviewEmailData: null
};

const setLiveFeedNetworkRequest = (id = "") => {
  if (id && liveFeedNetworkRequest?.[id] !== null) {
    liveFeedNetworkRequest[id]("cancel");
    liveFeedNetworkRequest[id] = null;
  }
};

const cancelLiveFeedReqCbk = (id = "", value = "") => {
  id && (liveFeedNetworkRequest[id] = value);
};

let cancelRequest = null;

const checkAndCancelRequest = () => {
  if (cancelRequest !== null) {
    cancelRequest("cancel");
    cancelRequest = null;
  }
};

const cancelApiRequest = value => {
  cancelRequest = value;
};

class liveFeedState {
  @observable isLiveFeedLoading = false;

  @action
  setLiveFeedLoading(val) {
    this.isLiveFeedLoading = val;
  }

  @action
  getPreviewEmailData = async (id = "") => {
    if (id) {
      const config = {
        url: URLS.getEmailActivityData.replace("<<id>>", id)
      };
      setLiveFeedNetworkRequest("getPreviewEmailData");
      const cancel = value =>
        cancelLiveFeedReqCbk("getPreviewEmailData", value);
      return await makeApi(config, cancel);
    }
    return false;
  };

  @action
  getLinkedInReplyForActivity = async (contactId = "") => {
    linkedInReplyPopupState.setShowPopup(true);
    linkedInReplyPopupState.setEditorLoading(true);
    linkedInReplyPopupState.setContactId(contactId);
    try {
      if (contactId) {
        const url = `${URLS.getLinkedInReply}/${contactId}`;
        const config = {
          url,
          method: "GET"
        };
        checkAndCancelRequest();
        let res = await makeApi(config, cancelApiRequest);
        const { data = {} } = res || {};
        if (data && Object.keys(data)?.length > 0) {
          linkedInReplyPopupState.setLiMessages(data?.messages);
          linkedInReplyPopupState.setEditorLoading(false);
        }
      }
    } catch (e) {
      console.error("Getting contact activity data API Failed", e);
    }
  };
}

const LiveFeedState = new liveFeedState();

export { LiveFeedState };
export default LiveFeedState;
