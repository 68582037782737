/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import PageLimit from "Components/common/PageLimit";
import SearchInputByKeyword from "Components/common/SearchInputByKeyword";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import TeamHeader from "./TeamHeader";
import TeamMemberTableComponent from "./TeamMemberTable";
import CreateUpdateMemberPopupComponent from "./CreateUpdateMemberPopup";
import FilterDropdownByTeamMemberStatus from "./FilterDropdownByTeamMemberStatus";
import TeamStore from "Stores/Team";
import Utils from "Utils/utils";

const TeamModule = () => {
  const { noOfSeatsEligible, noOfSeatsUsed } =
    userDetail?.userFeatureFlag || {};

  const [showTeamCreateUpdatePopup, setTeamCreateUpdatePopup] = useState(false);
  const [loader, setLoader] = useState(true);
  const [teamData, setTeamData] = useState(TeamStore?.teamData || {});
  const [popupName, setPopupName] = useState("Add");
  const [adminUser, setAdminUser] = useState(false);
  const [popupValues, setPopupValues] = useState(TeamStore?.userFormObj || {});
  // const [limit, setLimit] = useState(10);
  // const [pageNo, setPageNo] = useState(1);

  const closePopup = () => {
    setTeamCreateUpdatePopup(false);
    setPopupValues(TeamStore.userFormObj);
  };

  const getElgibleSeatCount = () => {
    if (noOfSeatsEligible && noOfSeatsUsed) {
      let seats = noOfSeatsEligible - noOfSeatsUsed;
      return seats > 0;
    }
    return false;
  };

  const showInviteTeamMemberPopup = async () => {
    if (await checkTeamSubscriptionActive()) {
      if (getElgibleSeatCount()) {
        setTeamCreateUpdatePopup(true);
        setPopupValues(TeamStore.userFormObj);
        setPopupName("Add");
      } else {
        Utils.showUpgradeSettingsPopup("team", true, true);
      }
    }
  };

  const selectedValue = (value, isSuperAdmin) => {
    const {
      firstName = "",
      lastName = "",
      email = "",
      jobTitle = "",
      role = "",
      teamId = ""
    } = value || {};
    const user = {
      firstName,
      lastName,
      email,
      jobTitle,
      role,
      teamId
    };
    setPopupValues(user);
    setPopupName("Edit");
    setAdminUser(isSuperAdmin);
    setTeamCreateUpdatePopup(true);
  };

  const handleSearchByKeyword = (text = "") => {
    if (text) {
      let tempData = { ...TeamStore?.teamData };
      if (tempData?.members?.length) {
        let tempMembers = tempData.members.filter(item => {
          const {
            firstName = "",
            lastName = "",
            email = "",
            jobTitle = ""
          } = item || {};
          let fullName =
            firstName && lastName
              ? `${firstName} ${lastName}`
              : firstName || lastName || "";
          if (
            (email && email?.toLowerCase().includes(text?.toLowerCase())) ||
            (firstName &&
              firstName?.toLowerCase().includes(text?.toLowerCase())) ||
            (lastName &&
              lastName?.toLowerCase().includes(text?.toLowerCase())) ||
            (jobTitle &&
              jobTitle?.toLowerCase().includes(text?.toLowerCase())) ||
            (fullName && fullName?.toLowerCase().includes(text?.toLowerCase()))
          ) {
            return item;
          }
        });
        setTeamData({ ...teamData, members: tempMembers });
      }
    } else {
      setTeamData(TeamStore?.teamData);
    }
  };

  const resetState = () => {
    TeamStore.teamData = "";
    setTeamCreateUpdatePopup(false);
    setTeamData({});
    setLoader(true);
    setPopupValues({});
    setAdminUser(false);
    setPopupName("Add");
  };

  const getTeamByStatus = (status = "") => {
    setLoader(true);
    let payload = {
      limit: 500,
      pageNo: 1
    };
    if (status?.toLowerCase() !== "all") {
      payload.filterByStatus = status?.toUpperCase();
    }
    TeamStore.getTeamMember(payload);
  };

  useEffect(() => {
    setLoader(true);
    TeamStore.getTeamMember();
    return () => {
      TeamStore.teamData = "";
      resetState();
    };
  }, []);

  useEffect(() => {
    if (TeamStore?.teamData) {
      setTeamData(TeamStore?.teamData || {});
      setLoader(false);
    }
  }, [TeamStore.teamData]);

  return (
    <>
      {showTeamCreateUpdatePopup && (
        <CreateUpdateMemberPopupComponent
          cbk={closePopup}
          value={popupValues}
          name={popupName}
          userType={adminUser}
        />
      )}
      <div className="teamSectionCont">
        <TeamHeader cbk={showInviteTeamMemberPopup} />
        <div className={`teamContainer ${loader ? "loadingTeam" : ""}`}>
          {!loader && (
            <div className="teamFilterOption">
              <SearchInputByKeyword
                id="teamMemberSearch"
                placeholder="Search here"
                handleSearch={handleSearchByKeyword}
              />
              <div className="wrapLimitStatusFilter">
                {/* <PageLimit
                limitPerPage={limit}
                cbk={getTeamMemberByLimit}
                totalCount={teamData?.totalCount || 0}
              /> */}
                <FilterDropdownByTeamMemberStatus callback={getTeamByStatus} />
              </div>
            </div>
          )}
          <TeamMemberTableComponent
            loader={loader}
            teamData={teamData}
            editCbk={selectedValue}
          />
        </div>
      </div>
    </>
  );
};

export default observer(TeamModule);
