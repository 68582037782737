import React, { useState, useRef, useEffect } from "react";
import InputTextBox from "Components/common/InputTextBox";
import { Button } from "Components/common/Button";
import AddBulkContactPopupTitle from "Components/common/bulkContactPopup/AddBulkContactPopupTitle";
import CsvUpload from "Components/common/bulkContactPopup/CsvUpload";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import DoNotContactDomainRender from "./DoNotContactDomainRender";
import { toasterState } from "Components/common/toaster";
import { observer } from "mobx-react";
import BulkUploadingLoader from "Components/common/bulkContactPopup/BulkUploadingLoader";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import userDetail from "Stores/userDetail";

const DoNotContactDomainSearchBar = observer(props => {
  const inputBoxRef = useRef();
  const [singleDomainName, setSingleDomainName] = useState("");
  const [bulkUploadFlag, setBulkUploadFlag] = useState(false);
  const [showCsvUploader, setShowCsvUploader] = useState(true);
  const [userInteracted, setUserInteracted] = useState(false);
  const [errorDetails, setErrorDetails] = useState("");
  const [domainRenderList, setDomainRenderList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [singleLoader, setSingleLoader] = useState(false);
  const [activeBorderState, setActiveBorderState] = useState(false);

  const handleDoNotContactDomain = async () => {
    Utils.mixpanelTrack(MXP_EVENT.SINGLE_DOMAIN_BLOCKED, {
      pageType: PAGE_TYPE["Do Not Contact Single upload"]
    });
    setSingleLoader(true);
    let arr = [singleDomainName];
    if (!Utils.domainCheck(singleDomainName)) {
      setErrorDetails("Enter a valid domain name");
      setSingleLoader(false);
    } else if (domainRenderList?.includes(singleDomainName)) {
      setErrorDetails("Domain already blocked");
      setSingleLoader(false);
    } else {
      let response = await handleApiCall(arr);
      if (response?.status === 200 && response?.data?.domains?.length >= 0) {
        if (!domainRenderList.includes(response?.data?.domains[0])) {
          let tempSingleBlockedList = [
            ...response?.data?.domains,
            ...domainRenderList
          ];
          setDomainRenderList(tempSingleBlockedList);
          userDetail.setBlockedDomainList(tempSingleBlockedList);
          setSingleDomainName("");
          toasterState.setToastMsg("Domain uploaded successfully", "success");
        }
      }
      setSingleLoader(false);
    }
  };

  const handleDoNotContactDomainAction = event => {
    setActiveBorderState(false);
    setErrorDetails("");
    Utils.preventDefaultFn(event);
    if (Utils.isSubmitKeyAction(event)) {
      handleDoNotContactDomain();
    }
  };

  const handleBulkUpload = () => {
    Utils.mixpanelTrack(MXP_EVENT.MULTIPLE_DOMAIN_BLOCKED, {
      pageType: PAGE_TYPE["Do not Contact multiple upload"]
    });
    setBulkUploadFlag(true);
    setShowCsvUploader(true);
    setUserInteracted(false);
  };

  const closeAction = () => {
    setUserInteracted(false);
    setBulkUploadFlag(false);
    setShowCsvUploader(false);
  };

  const csvUploadCbk = async fileData => {
    if (fileData) {
      setShowCsvUploader(false);
      setUserInteracted(true);
      Utils.processBulkDomainsFromCsvFile(fileData, uploadAction);
    }
  };

  const handleApiCall = (arr = []) => {
    const config = {
      url: URLS.addDomain,
      method: "POST",
      data: {
        domains: arr
      }
    };
    return makeApi(config);
  };

  const uploadAction = async (domainNamesFromCSV = []) => {
    setLoader(true);
    if (domainNamesFromCSV.length >= 100) {
      toasterState.setToastMsg(
        "Oops! You can only upload upto 100 domains.",
        "failure"
      );
      closeAction();
    } else {
      let response = await handleApiCall(domainNamesFromCSV);
      if (response?.status === 200) {
        if (response?.data?.domains?.length > 0) {
          setShowCsvUploader(false);
          let tempBulkBlockedList = [
            ...new Set([
              ...(domainRenderList || []),
              ...(response?.data?.domains || [])
            ])
          ];
          setDomainRenderList(tempBulkBlockedList);
          userDetail.setBlockedDomainList(tempBulkBlockedList);
          toasterState.setToastMsg("Domains uploaded successfully", "success");
          closeAction();
        } else {
          closeAction();
          toasterState.setToastMsg("Invalid csv file", "failure");
        }
      } else {
        closeAction();
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
    setLoader(false);
  };

  const bulkUploadCsvBtnObj = {
    id: "Bulk",
    name: "Bulk",
    btnText: "Click here to upload a CSV file",
    type: "button",
    className: "bulkUploadCsvBtn",
    click: handleBulkUpload
  };

  const addDomainBtnObj = {
    id: "AddDomain",
    name: "Add Domain",
    btnText: "Add",
    type: "button",
    className: `addDomainBtn ${singleLoader ? "loading" : ""}`,
    click: handleDoNotContactDomain,
    disabled: !singleDomainName,
    loader: singleLoader
  };

  const inputDomainObj = {
    name: "addDomainInputBox",
    id: "addDomainInputBox",
    text: "Email domain",
    type: "text",
    value: singleDomainName,
    change: (id, val) => {
      setActiveBorderState(true);
      setSingleDomainName(val);
    },
    err: errorDetails,
    readOnly: false,
    inputBoxRef: inputBoxRef,
    className: `addDomainInputBox ${activeBorderState ? "activeBorder" : ""}`,
    cbk: handleDoNotContactDomainAction
  };

  const styleObj = {
    borderBottom: `${domainRenderList?.length > 0 ? "1px solid #e0e0e0" : ""}`,
    paddingBottom: "30px",
    marginTop: "-15px"
  };

  return (
    <>
      <div className="addDomainInputBtnCont">
        <InputTextBox {...inputDomainObj} />
        <Button {...addDomainBtnObj} />
      </div>
      {bulkUploadFlag && (
        <div className="popupWrapper addBulkContactWrapper">
          <div className="confirmation-popup common-popup">
            <form
              className="popup-wrap animated customzoomIn"
              autoComplete="off"
            >
              <AddBulkContactPopupTitle
                userInteracted={userInteracted}
                closeCbk={closeAction}
                name="Bulk Domain Upload"
              />
              <div className="popup-content-wrap">
                {showCsvUploader && (
                  <div className="uploadContainer">
                    <CsvUpload
                      csvUploadCbk={csvUploadCbk}
                      settingsFlag={true}
                      keyText="doNotContact"
                    />
                  </div>
                )}
                {loader && (
                  <BulkUploadingLoader loadingMessage="Uploading domains" />
                )}
              </div>
            </form>
          </div>
          <div className="common-popup-mask" />
        </div>
      )}
      <Button {...bulkUploadCsvBtnObj} />
      <hr className="domainSeperator" />
      <DoNotContactDomainRender
        domainRenderList={domainRenderList}
        setDomainRenderList={setDomainRenderList}
      />
    </>
  );
});

export default DoNotContactDomainSearchBar;
