import React from "react";
import EmailInsightImg from "Assets/png/noEmailInsightsReport.png";
import summaryReportImg from "Assets/png/reportTableScreenshot.png";
import sequenceInsightImg from "Assets/png/sequenceInsight.png";

const EmptyReport = (props = {}) => {
  const { pathname, sequenceInsight = false, className = "" } = props;
  let isSummary = pathname?.indexOf("summary") !== -1;
  const imgPath = isSummary
    ? summaryReportImg
    : (sequenceInsight && sequenceInsightImg) || EmailInsightImg;

  return (
    <div className={`reportTableInitialScreen ${className}`}>
      <img src={imgPath} alt="" />
      {!sequenceInsight ? (
        <span className="textDesc">
          Start sending emails using Salesgear and get insights on emails
          activity of all your team members in real-time
        </span>
      ) : (
        <span className="textDesc">
          Start adding contacts to your sequences and get insights on the
          performance of all the active sequences here
        </span>
      )}
    </div>
  );
};

export default EmptyReport;
export { EmptyReport };
