import React from "react";

export const ActionButton = props => {
  const {
    resetCbk = () => {},
    addCbk = {},
    isReset = true,
    isAdd = true,
    disableAddButton = false
  } = props || {};

  const actionList = [
    {
      className: "resetButton",
      method: resetCbk,
      icon: "refresh",
      iconClassName: "refreshIcon",
      name: "Reset",
      id: "reset",
      show: isReset
    },
    {
      className: "addNewButton",
      method: disableAddButton ? () => {} : addCbk,
      icon: "add_circle_outline",
      iconClassName: "addIcon",
      name: "Add",
      id: "Add",
      show: isAdd
    }
  ];

  return (
    <div className="actionButton">
      {(actionList?.length > 0 &&
        actionList.map((item, index) => {
          const {
            className = "",
            method = () => {},
            icon = "",
            iconClassName = "",
            name = "",
            id = "",
            show = false
          } = item || {};
          return (
            show && (
              <div
                className={`${
                  disableAddButton && id === "Add" ? "disabled" : ""
                } ${className}`}
                onClick={method}
                key={`${id}Action${index}`}
              >
                <i className={`material-icons ${iconClassName}`}>{icon}</i>
                <span className="text">{name}</span>
              </div>
            )
          );
        })) ||
        ""}
    </div>
  );
};

export default ActionButton;
