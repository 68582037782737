window.showIntercom = false;

export const initIntercomChat = appId => {
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.nonce = "00deadbeef";
        s.src = "https://widget.intercom.io/widget/" + appId;
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
};

const updateIntercomSettings = () => {
  window.showIntercom = !window.showIntercom;
  window.Intercom("update", {
    hide_default_launcher: window.showIntercom
  });
  const buttonElem = document.getElementsByClassName("showIntercomButton")[0];
  if (buttonElem) {
    if (buttonElem?.innerText === "Hide") {
      buttonElem.innerText = "Chat";
    } else {
      buttonElem.innerText = "Hide";
    }
  }
};

export const initIntercomShowHideOption = () => {
  const bodyElement = document.getElementsByTagName("body")?.[0];
  const checkHideElementAvailability = document.querySelector(
    ".showIntercomButton"
  );
  if (!checkHideElementAvailability) {
    const hideButtonElem = document.createElement("button");
    if (hideButtonElem) {
      hideButtonElem.className = "showIntercomButton";
      hideButtonElem.innerText = "Hide";
      hideButtonElem.onclick = updateIntercomSettings;
    }
    if (window?.Intercom?.booted && bodyElement) {
      bodyElement.appendChild(hideButtonElem, bodyElement);
    }
  }
};

export const hideIntercomChat = () => {
  window.showIntercom = true;
  window.Intercom("update", {
    hide_default_launcher: window.showIntercom
  });
  const checkHideElementAvailability = document.querySelector(
    ".showIntercomButton"
  );
  if (checkHideElementAvailability) {
    checkHideElementAvailability.innerText = "chat";
  }
};
