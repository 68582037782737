import React, { useEffect, useState } from "react";
import DropdownButton from "./DropdownButton";
import ActionButton from "./ActionButton";
import SingleDropdown from "./SingleDropdown";
import { conditionsOperatorObject } from "Static/constant";
import DateRangePicker from "./DateRangePicker";
import { DateUtils } from "Utils/DateUtils";
import contactStoreData from "Stores/contactData";

export const ContactDateFilter = props => {
  const {
    label = "",
    keyValue = "",
    activeFilter = "",
    errMsg = "",
    data = [],
    setActiveFilter = () => {},
    setAction = () => {},
    selectedFilterCbk = () => {},
    setErrMsg = () => {}
  } = props || {};

  let toggleDropdown = activeFilter === keyValue;

  const [activeDropdownIndex, setActiveDropdownIndex] = useState(false);
  const [dateStyleCss, setDateStyleCss] = useState({});

  const validateFieldNameAlreadyExist = (newFieldName = "") => {
    let count = 0;
    if (data?.length > 1) {
      data.some(element => {
        if (element?.fieldName === newFieldName) {
          count++;
        }
      });
    }
    return !count > 0;
  };

  const handleAction = (refId = "", item = "", index = "") => {
    if (refId === "fieldName") {
      if (!validateFieldNameAlreadyExist(item?.subKey)) {
        return;
      }
      selectedFilterCbk("add", keyValue);
    }
    errMsg && setErrMsg();
    let tempData = [...data];
    tempData[index][refId] = item?.subKey || "";
    if (refId === "operator") {
      let isBetween = item?.subKey === "BETWEEN";
      let isSet = item?.subKey === "IS_SET";
      let isNotSet = item?.subKey === "IS_NOT_SET";
      tempData[index].isNotSet = isBetween ? false : isNotSet;
      tempData[index].isSet = isBetween ? false : isSet;
      if (!isBetween) {
        tempData[index].fromDate = "";
        tempData[index].toDate = "";
      }
    }
    setActiveDropdownIndex(false);
    setAction(keyValue, tempData);
  };

  const handleDateAction = (type = "", index = "", dateObj = {}) => {
    if (type !== "apply") {
      let tempData = [...data];
      const { startDate = new Date(), endDate = null } = dateObj || {};
      tempData[index].fromDate = startDate;
      tempData[index].toDate = endDate;
      setAction(keyValue, tempData);
    }
    if (["apply", "cancel"].includes(type)) {
      setActiveDropdownIndex(false);
      setDateStyleCss({});
    }
    errMsg && setErrMsg("");
  };

  const resetOrAddNewItem = (isPush = false) => {
    let newObj = {
      fieldName: "",
      isSet: false,
      isNotSet: false,
      fromDate: new Date(),
      toDate: null,
      operator: "BETWEEN"
    };
    if (isPush) {
      return newObj;
    }
    errMsg && setErrMsg();
    selectedFilterCbk("remove", keyValue);
    setAction(keyValue, [newObj]);
  };

  const removeItem = index => {
    let tempData = [...data];
    let len = tempData?.length;
    if (len) {
      if (len === 1) {
        errMsg && setErrMsg("");
        resetOrAddNewItem();
      } else {
        tempData.splice(index, 1);
        tempData.filter(item => item?.fieldName)?.length < 1 &&
          selectedFilterCbk("remove", keyValue);
        setAction(keyValue, tempData);
      }
    }
    errMsg && setErrMsg();
  };

  const addItem = () => {
    errMsg && setErrMsg();
    let tempData = [...data];
    tempData.push(resetOrAddNewItem(true));
    setAction(keyValue, tempData);
  };

  const resetItem = () => {
    errMsg && setErrMsg("");
    data?.filter(item => item?.fieldName)?.length && resetOrAddNewItem();
  };

  const optionList = [
    {
      value: "Created",
      subKey: "CREATED",
      key: "created",
      method: handleAction
    },
    {
      value: "Updated",
      subKey: "UPDATED",
      key: "updated",
      method: handleAction
    },
    {
      value: "First contacted",
      subKey: "FIRST_CONTACTED",
      key: "firstContacted",
      method: handleAction
    },
    {
      value: "Last contacted",
      subKey: "LAST_CONTACTED",
      key: "lastContacted",
      method: handleAction
    }
  ];

  const operatorList = [
    {
      value: "<span class='conditionSymbol'>&lt;&gt;</span> (Between)",
      subKey: "BETWEEN",
      key: "between",
      method: handleAction
    },
    {
      value:
        "<span class='conditionSymbol customSymbol' style='font-Size:17px;'>&lcub;&rcub;</span> (Is Set)",
      subKey: "IS_SET",
      key: "isSet",
      method: handleAction
    },
    {
      value:
        "<span class='conditionSymbol customSymbol strikeThrough' style='font-Size:17px;'>&lcub;&rcub;</span> (Is Not Set)",
      subKey: "IS_NOT_SET",
      key: "isNotSet",
      method: handleAction
    }
  ];

  const fieldnameObject = {
    CREATED: "Created",
    UPDATED: "Updated",
    FIRST_CONTACTED: "First contacted",
    LAST_CONTACTED: "Last contacted"
  };

  const toggleDatePicker = (event, index) => {
    let positionY = event.clientY || event.pageY || 0;
    let domPosition =
      30 - event.nativeEvent.layerY || event.nativeEvent.offsetY || 0;
    let innerHeight = window.innerHeight;
    let diffHeight = innerHeight - positionY;
    let styleCss = {};
    if (diffHeight + domPosition >= 366) {
      styleCss.top = positionY + domPosition;
    } else {
      let topHt = positionY - 366;
      styleCss.top = topHt - domPosition;
    }
    setDateStyleCss(styleCss);
    setActiveDropdownIndex(`dateRangePicker-${index}`);
  };

  const formateDate = (fromDate, toDate) => {
    let startDate = DateUtils.formatDateByMonthText(fromDate);
    let endDate = DateUtils.formatDateByMonthText(toDate);
    if (DateUtils.isFromToDateEqual(fromDate, toDate)) {
      return startDate;
    }
    return `${startDate} to ${endDate}`;
  };

  useEffect(() => {
    if (errMsg) {
      let tempData = { ...contactStoreData.contactFilterObject };
      tempData.dates = [
        {
          fieldName: "",
          isSet: false,
          isNotSet: false,
          fromDate: new Date(),
          toDate: null,
          operator: "BETWEEN"
        }
      ];
      contactStoreData.setContactFilterObject(tempData);
    }
  }, [errMsg]);

  return (
    <div className="contactDateFilter">
      <DropdownButton
        label={label}
        count={data?.filter(item => item?.fieldName)?.length || 0}
        keyValue={keyValue}
        toggleDropdown={toggleDropdown}
        callback={setActiveFilter}
      />
      {toggleDropdown && (
        <div className="dropdownSearchBox">
          <span className="horizontalBorderLine" />
          {(data?.length > 0 && (
            <div className="dateRangeConditionCard">
              {data.map((item, index) => {
                let {
                  fieldName = "",
                  fromDate = new Date(),
                  toDate = null,
                  isSet = false,
                  isNotSet = false,
                  operator = "BETWEEN"
                } = item || {};
                fromDate = new Date(fromDate);

                return (
                  <div
                    className="dateRangeCondition"
                    key={`dateRangeCondition${index}`}
                  >
                    <div className="fieldnameDropdown">
                      <SingleDropdown
                        isDropdownAllowed={true}
                        label={"Select date type"}
                        options={optionList}
                        defaultValue={fieldnameObject?.[fieldName] || ""}
                        referenceId="fieldName"
                        parentIndex={index}
                        hasCustomFieldValue={true}
                        showDropdown={
                          activeDropdownIndex === `fieldName-${index}`
                        }
                        setActiveDropdownIndex={setActiveDropdownIndex}
                      />
                      <i
                        className="material-icons-outlined deleteIcon"
                        onClick={() => removeItem(index)}
                      >
                        delete
                      </i>
                    </div>
                    {fieldName && (
                      <div
                        className={`fieldOperatorValue ${
                          !fieldName ? "disableInput" : ""
                        }`}
                      >
                        <SingleDropdown
                          isDropdownAllowed={
                            !["CREATED", "UPDATED"].includes(fieldName)
                          }
                          options={operatorList}
                          defaultValue={
                            conditionsOperatorObject?.[operator] || ""
                          }
                          showDropdown={
                            activeDropdownIndex === `operator-${index}`
                          }
                          setActiveDropdownIndex={setActiveDropdownIndex}
                          parentIndex={index}
                          referenceId="operator"
                        />
                        <div
                          className={`selectDateRangePicker ${
                            operator !== "BETWEEN" ? "disabled" : ""
                          }`}
                        >
                          <div
                            className="selectDateLabel"
                            onClick={event => toggleDatePicker(event, index)}
                          >
                            <span className="title">
                              {fromDate && toDate
                                ? formateDate(fromDate, toDate)
                                : "Choose date"}
                            </span>
                            <i
                              className={`material-icons arrowIcon ${
                                false ? "rotate" : ""
                              }`}
                            >
                              keyboard_arrow_down
                            </i>
                          </div>
                          {activeDropdownIndex === `dateRangePicker-${index}` &&
                            operator === "BETWEEN" && (
                              <>
                                <div
                                  className="dropdownOverlay"
                                  onClick={() =>
                                    handleDateAction("cancel", index)
                                  }
                                />
                                <div
                                  className="datePickerDropdown"
                                  style={dateStyleCss}
                                >
                                  <DateRangePicker
                                    index={index}
                                    customDate={[
                                      {
                                        startDate: fromDate,
                                        endDate: toDate,
                                        key: "selection"
                                      }
                                    ]}
                                    pageType="contactFilter"
                                    handleAction={handleDateAction}
                                  />
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )) ||
            ""}
          <ActionButton resetCbk={resetItem} addCbk={addItem} />
          {errMsg === keyValue && (
            <span className="errMessage">Please select a value</span>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactDateFilter;
