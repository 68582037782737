import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import InputTextBox from "Components/common/InputTextBox";
import Button from "Components/common/Button";
import CustomTooltip from "Components/common/CustomTooltip";
import customListState from "Pages/Dashboard/dataSearch/CustomList/state";
import { toasterState } from "Components/common/toaster";

const CreateNewList = observer((props = {}) => {
  const {
    listName = "",
    loader = false,
    err = "",
    showCreateList = false,
    setShowCreateList = () => {},
    changeAction = () => {},
    setListName = () => {},
    action = () => {}
  } = props || {};

  const toggleListCreation = () => {
    setShowCreateList(!showCreateList);
    showCreateList && setListName("");
  };

  const createListInputObj = {
    name: "createNewList",
    id: "createNewList",
    type: "text",
    value: listName,
    change: changeAction,
    err,
    placeHolder: "Enter the list name",
    focus: true,
    cbk: event => (Utils.isSubmitKeyAction(event) && action()) || {}
  };

  const createListBtn = {
    id: "addNewListBtn",
    name: "addNewListBtn",
    btnText: "",
    type: "button",
    loader,
    icon: true,
    iconName: "playlist_add",
    className: `addNewListBtn ${listName?.trim()?.length ? "" : "disabled"} ${
      loader ? "loading" : ""
    }`,
    click: action,
    iconCbk: () => {}
  };

  return (
    <div className="createNewListButton">
      <div className="createListBtn" onClick={toggleListCreation}>
        <i className="material-icons-outlined add">control_point</i>
        <span className="text">Create a new list</span>
      </div>
      {showCreateList && (
        <div className="wrapInputAction">
          <InputTextBox {...createListInputObj} />
          <Button {...createListBtn} />
        </div>
      )}
    </div>
  );
});

export const AddContactsToList = observer((props = {}) => {
  const {
    contactIds = [],
    loading = false,
    addToListCbk = () => {}
  } = props || {};

  const [showCreateList, setShowCreateList] = useState(false);
  const [listName, setListName] = useState("");
  const [err, setErr] = useState("");
  const [selectedListId, setSelectedListId] = useState("");
  const [showList, setShowList] = useState(false);
  const [showTooltip, setTooltip] = useState(false);
  const [loader, setLoader] = useState(false);
  const [newListLoader, setNewListLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState(
    customListState.totalContactList || []
  );

  const resetToInitial = () => {
    setShowList(false);
    setSearchText("");
    setSelectedListId("");
    setListName("");
    setErr("");
  };

  const closePopup = () => {
    !loader && !newListLoader && resetToInitial();
  };

  const changeListNameAction = (id = "", value = "") => {
    err && setErr("");
    setListName(value);
    setSearchText("");
    setSelectedListId("");
  };

  const handleSearchText = (id, value) => {
    setSearchText(value);
    setSelectedListId("");
    setListName("");
    err && setErr("");
    if (value) {
      let tempOpt = [...customListState.totalContactList];
      tempOpt = tempOpt.filter(item => {
        let name = item?.name?.toLowerCase()?.trim() || "";
        if (name !== "all" && name.includes(value)) {
          return item;
        }
      });
      setOptions(tempOpt);
    } else {
      setOptions(customListState.totalContactList);
    }
  };

  const searchInputObj = {
    name: "searchListInput",
    id: "searchListInput",
    type: "text",
    value: searchText,
    change: handleSearchText,
    placeHolder: "Search lists",
    focus: true
  };

  const handleChange = (id = "") => {
    setSelectedListId(id);
    setShowCreateList(false);
    setListName("");
    err && setErr("");
  };

  const addToListBtn = {
    id: "addContactToSelectedListBtn",
    name: "addContactToSelectedList",
    btnText: "Add to list",
    type: "button",
    loader,
    className: `addContactToSelectedListBtn ${
      selectedListId ? "" : "disabled"
    } ${loader ? "loading" : ""}`,
    click: () => {
      setLoader(true);
      addToListCbk("ADD_TO_LIST", selectedListId, () => {
        setLoader(false);
        closePopup();
      });
    }
  };

  const createNewListAction = async () => {
    if (!newListLoader && listName?.trim()?.length) {
      if (listName?.trim()?.toLowerCase() !== "all") {
        try {
          setNewListLoader(true);
          const config = {
            url: URLS.createContactList,
            method: "POST",
            data: { listName }
          };
          const res = await makeApi(config);
          if (res?.data?.statusCode === 200) {
            const { listResponse = {} } = res?.data || {};
            if (listResponse && Object.keys(listResponse)?.length > 0) {
              const {
                statusCode = "",
                success = false,
                list = []
              } = listResponse || {};
              if (statusCode === 409) {
                setErr("List name already exists.");
              } else if (statusCode === 200 && success) {
                Utils.mixpanelTrack(MXP_EVENT.CT_NEW_LIST_CREATED, {
                  pageType: PAGE_TYPE["contact"]
                });
                await customListState.getContactList();
                setShowCreateList(false);
                setListName("");
                setSelectedListId(list?.[0]?.id);
                toasterState.setToastMsg(
                  "List created successfully",
                  "success"
                );
              } else {
                toasterState.setToastMsg(
                  "Failed to update list name. Please try again later",
                  "failure"
                );
              }
            }
          } else {
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
          setNewListLoader(false);
        } catch (err) {
          console.error(err);
        }
      } else {
        setErr("List name already exists.");
      }
    }
  };

  useEffect(() => {
    return () => {
      resetToInitial();
    };
  }, []);

  useEffect(() => {
    setOptions(customListState.totalContactList || []);
  }, [customListState.totalContactList]);

  return (
    <div className="wrapAddTolistBtnPopup">
      <div
        className={`addContactsToList ${
          contactIds?.length > 0 ? "enabled" : ""
        }`}
        onMouseEnter={() => setTooltip(true)}
        onMouseLeave={() => setTooltip(false)}
        onClick={e => {
          if (!loading && contactIds?.length > 0) {
            Utils.mixpanelTrack(MXP_EVENT.CT_ADD_TO_LIST_POPUP_VIEWED, {
              pageType: PAGE_TYPE["contact"]
            });
            setShowList(true);
          }
        }}
      >
        <i className="material-icons">playlist_add</i>
        {showTooltip && (
          <CustomTooltip
            text={`${
              contactIds?.length > 0
                ? "Add to List"
                : "Select one or more contacts to add to list."
            }`}
            className={`${contactIds?.length > 0 ? "" : "bulkAddToList"}`}
          />
        )}
      </div>
      {showList && (
        <div className="addListPopup">
          <div className="card animated customzoomIn">
            <div className="cardHeader">
              <div className="title">Add to List</div>
              <i className="material-icons close" onClick={closePopup}>
                close
              </i>
            </div>
            <div
              className={`cardBody ${newListLoader || loader ? "loading" : ""}`}
            >
              <CreateNewList
                showCreateList={showCreateList}
                setShowCreateList={setShowCreateList}
                listName={listName}
                err={err}
                loader={newListLoader}
                setListName={setListName}
                setErr={setErr}
                changeAction={changeListNameAction}
                setLoader={setNewListLoader}
                action={createNewListAction}
              />
              {(customListState?.totalContactList?.length > 1 && (
                <div className="selectListDropdown">
                  <div className="listHeading">Select a list</div>
                  <div className="selectOptions">
                    <div className="searchInputBox">
                      <i className="material-icons search">search</i>
                      <InputTextBox {...searchInputObj} />
                    </div>
                    <div className="optionList">
                      {options?.length > 0 ? (
                        options?.map((item, index) => {
                          const {
                            count = 0,
                            createTs = "",
                            name = "",
                            id = ""
                          } = item || {};
                          if (name?.toLowerCase() !== "all") {
                            return (
                              <div
                                className={`options ${
                                  selectedListId === id ? "disableSelect" : ""
                                }`}
                                key={`listId_${id}${index}`}
                              >
                                <div className="radioBtnCont">
                                  <div className="radioButtonCont">
                                    <input
                                      type="radio"
                                      name="addToListRadioCheckbox"
                                      id={id}
                                      onChange={() => {
                                        handleChange(id);
                                      }}
                                      checked={selectedListId === id}
                                      className="radioBtn"
                                    />
                                    <span className="contentClass" />
                                  </div>
                                </div>
                                <label className="listDetails" htmlFor={id}>
                                  <div
                                    className={`listInfo ${
                                      !createTs ? "alignSelfCenter" : ""
                                    }`}
                                  >
                                    <span className="name">{name}</span>
                                    {createTs && (
                                      <span className="createdInfo">
                                        Created on {Utils.formatDate(createTs)}
                                      </span>
                                    )}
                                  </div>
                                  <div className="listContactsCount">
                                    <span className="count">{count}</span>
                                    <span className="text">
                                      Contact{count > 1 ? "s" : ""}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="noListFound">No list found</div>
                      )}
                    </div>
                  </div>
                </div>
              )) ||
                ""}
              <Button {...addToListBtn} />
            </div>
          </div>
          <div className="bgOverlay" />
        </div>
      )}
    </div>
  );
});

export default AddContactsToList;
