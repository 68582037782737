/* eslint-disable default-case */
import React, { useState, createRef, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import ToggleBtnType from "Components/common/ToggleBtnType";
import { filterComponentData } from "./filterComponentData";
import { dataSearchState } from "../state";
import { CustomAccordion } from "./CustomAccordion";
import SecondaryCheckbox from "Components/common/SecondaryCheckbox";
import InputBox from "Components/Register/InputBox";
import { AddRemoveValue } from "./AddRemoveValue";
import { CustomMultiSelect } from "./CustomMultiSelect";
import { AutoCompleteMultiSelectDropdown } from "./AutoCompleteMultiSelectDropdown";
import CustomDropdownWithSubmenu from "./CustomDropdownWithSubmenu";
import ArrayUtils from "Utils/ArrayUtils";
import Utils from "Utils/utils";
import { verifyBulkActionPerformedAndAllow } from "../DataSearchUtils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import makeApi, { URLS } from "Utils/apiURL";
import AutoCompleteDropdown from "Components/common/AutoCompleteDropdown";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import { ReactComponent as EditIcon } from "Assets/svg/icn-rename1.svg";
import { ReactComponent as DeleteIconDiscover } from "Assets/svg/icn-delete1.svg";
import { ReactComponent as ClearAllSvg } from "Assets/svg/clearAll1.svg";
import CustomTooltip from "Components/common/CustomTooltip";

const FilterComponentAction = observer(props => {
  const {
    action = "",
    id = "",
    name = "",
    index = "",
    activeIndex = "",
    setActiveIndex = () => {},
    setTableLoading = () => {},
    filterCount = 0
  } = props;

  const [nameValue, setNameValue] = useState("");
  const inputBoxRef = createRef();

  const handleToggleAction = checked => {
    const performAction = () => {
      Utils.mixpanelTrack(MXP_EVENT.SKIP_CONTACTS_CLICKED, {
        pageType: PAGE_TYPE.discover,
        value: checked
      });
      dataSearchState.setFilterComponentData({
        ...dataSearchState.filterComponentData,
        [id]: checked
      });
      setTableLoading(true);
      dataSearchState.setCurrentPage(1);
      dataSearchState.setIsFromSeoPage(false);
      dataSearchState.searchPageData();
    };
    if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
      verifyBulkActionPerformedAndAllow(performAction);
    } else {
      performAction();
    }
  };

  const handleInputChange = value => {
    setNameValue(value);
  };

  const handleKeyPress = (event, nameValue) => {
    if (Utils.isSubmitKeyAction(event)) {
      handleInputAdd(nameValue);
    }
  };

  const handleInputAdd = nameValue => {
    if (nameValue?.length > 0) {
      const performAction = () => {
        let tempList = dataSearchState?.filterComponentData?.[id] || [];
        if (tempList?.length < 5) {
          if (tempList?.length > 0) {
            if (ArrayUtils.isUniqueArray(tempList, nameValue)) {
              dataSearchState.setFilterComponentData({
                ...dataSearchState.filterComponentData,
                [id]: [
                  ...new Set([
                    ...dataSearchState?.filterComponentData?.[id],
                    nameValue
                  ])
                ]
              });
            }
          } else {
            dataSearchState.setFilterComponentData({
              ...dataSearchState.filterComponentData,
              [id]: [nameValue]
            });
          }
          if (
            ArrayUtils.isUniqueArray(tempList, nameValue) ||
            !tempList?.length
          ) {
            setTableLoading(true);
            dataSearchState.setCurrentPage(1);
            dataSearchState.setIsFromSeoPage(false);
            dataSearchState.searchPageData();
          }
          setNameValue("");
        }
      };
      if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
        verifyBulkActionPerformedAndAllow(performAction, () =>
          setNameValue("")
        );
      } else {
        performAction();
      }
    } else {
      inputBoxRef && inputBoxRef.current && inputBoxRef.current.focus();
    }
  };

  const getActionComponent = () => {
    switch (action) {
      case "autoCompleteMultiSelectDropdown":
        return (
          <AutoCompleteMultiSelectDropdown
            {...props}
            showDropdown={index === activeIndex}
            setActiveIndex={setActiveIndex}
            showApplybtn={["keywords", "location", "companydomain"].includes(
              id?.toLowerCase()
            )}
          />
        );
      case "multiSelectDropdown":
        return id === "industry" ? (
          <CustomDropdownWithSubmenu
            showDropdown={index === activeIndex}
            id={"industry"}
            setActiveIndex={setActiveIndex}
            setTableLoading={setTableLoading}
            className="industryMultiDropdown"
            discoverPage={true}
          />
        ) : (
          <CustomMultiSelect
            {...props}
            showSearchPanel={false}
            showDropdown={index === activeIndex}
            setActiveIndex={setActiveIndex}
            discoverPage={true}
          />
        );
      case "toggle":
        return (
          <div className="toggleActionSection">
            <ToggleBtnType
              id={id}
              defaultValue={dataSearchState?.filterComponentData?.[id]}
              lastText={""}
              pageType={name}
              isAllowed={true}
              cbk={handleToggleAction}
            />
          </div>
        );
      case "inputBox":
        const inputObject = {
          name: id,
          id: id,
          type: "text",
          value: nameValue || "",
          changeAction: handleInputChange,
          keyupAction: e => handleKeyPress(e, nameValue),
          label: "",
          text: "",
          placeholder: name,
          inputBoxRef
        };

        return (
          <>
            <div className="filterComponentInputHeader">
              <InputBox className="filterComponentTitle" {...inputObject} />
              <div className="flexCenter">
                {filterCount > 0 && (
                  <div className="filterCount">{filterCount}</div>
                )}
                <span
                  className={`material-icons ${
                    dataSearchState?.filterComponentData?.[id]?.length >= 5
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() =>
                    dataSearchState?.filterComponentData?.[id]?.length >= 5
                      ? ""
                      : handleInputAdd(nameValue)
                  }
                >
                  control_point
                </span>
              </div>
            </div>
          </>
        );
    }
  };
  return getActionComponent();
});

const FilterComponent = observer(props => {
  const {
    setTableLoading = () => {},
    setShowSavePopup = () => {},
    editCallBack = () => {},
    minimumSelectionMade = false,
    savedListOptions = [],
    setSavedListOptions = () => {}
  } = props;

  const [checkboxVal, setCheckboxVal] = useState(
    dataSearchState.filterComponentData.titleExactMatch
  );
  const [activeIndex, setActiveIndex] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [savedSearchListLoader, setSavedSearchListLoader] = useState(false);
  const [selectedValueId, setSelectedValueId] = useState("");
  const [clearToolTip, setClearToolTip] = useState(false);

  const setSelectedValueCbk = (str = "") => {
    setSelectedValueId(str);
  };

  const getIndustryFilterCount = (id = "") => {
    let count = 0;
    if (dataSearchState?.industriesList?.length) {
      let tempData = dataSearchState?.industriesList?.forEach(item => {
        const { name = "", mapped_industries = [] } = item || {};
        if (dataSearchState?.filterComponentData?.[id]?.includes(name)) {
          count += mapped_industries?.length || 0;
        }
      });
    } else {
      count = dataSearchState?.filterComponentData?.[id]?.length || 0;
    }
    return (
      count + dataSearchState?.filterComponentData?.["sector"]?.length || 0
    );
  };

  const getFilterCount = (id = "") => {
    if (["companydomain", "location", "keywords"].includes(id?.toLowerCase())) {
      return dataSearchState?.selectedFilteredList?.[id]?.length;
    } else {
      if (id === "industry") {
        return getIndustryFilterCount(id);
      } else {
        return dataSearchState?.filterComponentData?.[id]?.length;
      }
    }
  };

  const resetFilterData = (id = "", checked = false) => {
    if (id) {
      filterComponentData.map(item => {
        if (item?.id === id) {
          item.options.map(options => {
            options.checked = checked;
          });
        }
      });
    }
  };

  const clearAllAction = () => {
    setSelectedValueId("");
    setCheckboxVal(false);
    const performClearAll = () => {
      Utils.mixpanelTrack(MXP_EVENT.CLEAR_ALL_CLICKED, {
        pageType: PAGE_TYPE.discover
      });
      dataSearchState.setClearAll(true);
      dataSearchState.setFilterComponentData({
        confidenceScore: [],
        excludeTeamOwns: true
      });
      dataSearchState.setSelectedFilteredList([]);
      setTableLoading(true);
      resetFilterData("level");
      resetFilterData("confidenceScore");
      dataSearchState.setIsFromSeoPage(false);
      dataSearchState.setShowInitialPage(true);
      dataSearchState.setContactTableData({
        totalContacts: 0
      });
    };
    if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
      verifyBulkActionPerformedAndAllow(performClearAll);
    } else {
      performClearAll();
    }
  };

  const getListOfSavedSearch = () => {
    const config = {
      url: URLS.getSavedSearch,
      method: "GET"
    };
    return makeApi(config);
  };

  const deleteSavedSearch = id => {
    const config = {
      url: URLS.deleteSearch,
      method: "POST",
      data: { id }
    };
    return makeApi(config);
  };

  const handleGetSearch = async () => {
    setShowDropdown(!showDropdown);
    Utils.mixpanelTrack(MXP_EVENT.SEARCH_DELETED, {
      pageType: PAGE_TYPE.discover
    });
    setSavedSearchListLoader(true);
    let response = await getListOfSavedSearch();
    if (response?.status === 200) {
      setSavedListOptions(response?.data);
    }
    setSavedSearchListLoader(false);
  };

  const handleDeleteSearch = async (id = "") => {
    let response = await deleteSavedSearch(id);
    if (response?.status === 200) {
      Utils.mixpanelTrack(MXP_EVENT.SEARCH_DELETED, {
        pageType: PAGE_TYPE.discover
      });
      toasterState.setToastMsg("Saved search deleted successfully", "success");
      let temp = savedListOptions.filter(item => item.id !== id);
      setSavedListOptions(temp);
    } else {
      toasterState.setToastMsg(
        "Deletion was unsuccussful,please try again later",
        "success"
      );
    }
    if (
      id === selectedValueId ||
      (setSavedListOptions &&
        setSavedListOptions?.length === 1 &&
        savedListOptions[0].id === id)
    ) {
      clearAllAction();
    }
    confirmationPopupState.setShowPopup(false);
    setShowDropdown(false);
  };

  const handleDeletePopup = (id = "") => {
    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to delete the saved search?`,
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      loadingMsg: "Please wait...",
      callback: () => {
        handleDeleteSearch(id);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  const handleEditSearch = (id = "", searchName = "") => {
    editCallBack(id, searchName);
    setShowDropdown(false);
  };

  const listOfSavedSearchBody = props => {
    const {
      searchName = "",
      createTs = "",
      id = "",
      dropDownValue = ""
    } = props;
    const createdDate = Utils.formatDate(createTs);
    let tempDropDownValue = dataSearchState.clearAll ? "" : selectedValueId;

    return (
      <>
        <div className="customListLeftSection">
          <span
            className={`${
              id === tempDropDownValue ? "updatedName" : "nonUpdatedName"
            }`}
          >
            {searchName}
          </span>
          <span className="createdDate">{`Created on ${createdDate}`}</span>
        </div>
        <div className="customListHorizontalDisplay">
          <span
            onClick={e => {
              Utils.preventDefaultFn(e);
              handleEditSearch(id, searchName);
            }}
          >
            <EditIcon />
          </span>
          <span
            onClick={e => {
              Utils.preventDefaultFn(e);
              handleDeletePopup(id);
            }}
          >
            <DeleteIconDiscover />
          </span>
        </div>
      </>
    );
  };

  const CustomSavedSearchLoader = () => {
    return Array.from({ length: 10 }, (value, index) => (
      <li className="dropdownOptions optionLoader" key={`${index}`}>
        <>
          <div className="customListLeftSection">
            <span
              className="updatedName linear-background"
              style={{ width: "150px" }}
            />
            <span
              className="createdDate linear-background"
              style={{ width: "150px" }}
            />
          </div>
          <div className="customListHorizontalDisplay">
            <span
              className="iconBackground linear-background"
              style={{ width: "20px" }}
            />
            <span
              className="iconBackground linear-background"
              style={{ width: "20px" }}
            />
          </div>
        </>
      </li>
    ));
  };

  const LoaderHeader = () => {
    return (
      <span
        onClick={() => {
          handleGetSearch();
        }}
      >
        Load
      </span>
    );
  };

  const savedListCbk = item => {
    setTableLoading(true);
    const {
      confidenceScore = [],
      titleExactMatch = false,
      industry = [],
      sector = [],
      employeeCount = [],
      revenue = [],
      department = [],
      level = [],
      title = [],
      excludeTeamOwns = false,
      keywords = [],
      companyDomain = [],
      country = [],
      state = [],
      city = [],
      name = "",
      companies = [],
      locations = []
    } = item;
    let filterAttr = {
      confidenceScore,
      titleExactMatch,
      industry,
      sector,
      employeeCount,
      revenue,
      department,
      level,
      title,
      excludeTeamOwns,
      companyDomain,
      keywords,
      country,
      state,
      city,
      name
    };
    dataSearchState.setSelectedFilteredList({
      location: [],
      companyDomain: [],
      keywords: []
    });
    dataSearchState.setFilterComponentData({
      ...filterAttr
    });
    if (dataSearchState.filterComponentData.titleExactMatch) {
      setCheckboxVal(true);
    }
    let tempCompanyArr = [];
    if (companies && companies?.length > 0) {
      for (const iterator of companies) {
        tempCompanyArr.push({ ...iterator, checked: true });
      }
    }
    let tempLocationArr = [];
    if (locations && locations?.length > 0) {
      for (const iterator of locations) {
        tempLocationArr.push({ ...iterator, checked: true });
      }
    }
    let tempKewordsArr = [];
    if (keywords?.length > 0) {
      for (const iterator of keywords) {
        tempKewordsArr.push({
          name: iterator,
          checked: true,
          id: iterator,
          group: "keywords",
          searchText: [iterator]
        });
      }
    }
    dataSearchState.setSelectedFilteredList({
      companyDomain: tempCompanyArr,
      location: tempLocationArr,
      keywords: tempKewordsArr
    });
    setShowDropdown(false);
    dataSearchState.setCurrentPage(1);
    dataSearchState.setIsFromSeoPage(false);
    dataSearchState.searchPageData();
  };

  return (
    <section className="filterComponentWrapper">
      <div className="filterComponentHeader">
        <span className="filterComponentTitle">Filters</span>
        <span className="savedSearchSection">
          <section className="customListWrapper">
            <div className="customListContainer">
              <AutoCompleteDropdown
                HeaderChildren={<LoaderHeader />}
                optionList={savedListOptions}
                cbk={savedListCbk}
                loader={savedSearchListLoader}
                updateListBody={true}
                Body={listOfSavedSearchBody}
                loaderComponent={<CustomSavedSearchLoader />}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                discoverPage={true}
                setSelectedValueCbk={setSelectedValueCbk}
                handleGetSearch={handleGetSearch}
              />
            </div>
          </section>
          <span className="seperator">|</span>
          <span
            className="saveText"
            onClick={() => {
              if (minimumSelectionMade) {
                toasterState.setToastMsg(
                  "Oops! You cannot save a search without applying any filters.",
                  "failure"
                );
              } else {
                setShowSavePopup(true);
              }
            }}
          >
            Save Search
          </span>
          <span className="seperator">|</span>
          <span className="clearAllBtn" onClick={clearAllAction}>
            <span
              onMouseOver={() => setClearToolTip(true)}
              onMouseLeave={() => setClearToolTip(false)}
            >
              <span className="iconBackground " style={{ background: "white" }}>
                <ClearAllSvg />
                {clearToolTip && <CustomTooltip text={"Clear All"} />}
              </span>
            </span>
          </span>
        </span>
      </div>

      <div className="filterComponentBody">
        {filterComponentData.map((item, index) => {
          const {
            name = "",
            action = "",
            hasAccordion = true,
            id = ""
          } = item || {};

          const updateCheckbox = (value, id) => {
            const performExactMacthAction = () => {
              const titleValue =
                dataSearchState?.filterComponentData?.["title"] || "";
              setCheckboxVal(value);
              dataSearchState.setFilterComponentData({
                ...dataSearchState.filterComponentData,
                titleExactMatch: value
              });
              if (titleValue?.length > 0) {
                setTableLoading(true);
                dataSearchState.setCurrentPage(1);
                dataSearchState.setIsFromSeoPage(false);
                dataSearchState.searchPageData();
              }
            };
            if (
              ["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)
            ) {
              verifyBulkActionPerformedAndAllow(performExactMacthAction);
            } else {
              performExactMacthAction();
            }
          };

          const toggleDropdown = () => {
            const perfromToggle = () => {
              setActiveIndex(index !== activeIndex ? index : null);
            };
            if (
              ["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)
            ) {
              verifyBulkActionPerformedAndAllow(perfromToggle);
            } else {
              perfromToggle();
            }
          };

          const filterCount = getFilterCount(id);
          return (
            <section className="filterCompContainer" key={id}>
              <div className="filterComponentBodyHeader">
                {hasAccordion ? (
                  <CustomAccordion
                    title={name}
                    filterCount={filterCount}
                    action={action}
                    index={index}
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                  >
                    {
                      <>
                        <FilterComponentAction
                          {...item}
                          activeIndex={activeIndex}
                          index={index}
                          setActiveIndex={setActiveIndex}
                          setTableLoading={setTableLoading}
                        />
                        {action === "autoCompleteMultiSelectDropdown" && (
                          <AddRemoveValue
                            defaultValue={
                              dataSearchState?.selectedFilteredList?.[id] || []
                            }
                            id={id}
                            store={dataSearchState}
                            setTableLoading={setTableLoading}
                          />
                        )}
                      </>
                    }
                  </CustomAccordion>
                ) : (
                  <>
                    {action === "inputBox" ? (
                      <FilterComponentAction
                        {...item}
                        activeIndex={activeIndex}
                        index={index}
                        setTableLoading={setTableLoading}
                        setActiveIndex={setActiveIndex}
                        filterCount={filterCount}
                      />
                    ) : action === "multiSelectDropdown" ? (
                      <div
                        className="filterComponentTitleWithWrapper"
                        onClick={toggleDropdown}
                      >
                        <div className="filterComponentTitle">{name}</div>
                        <div className="flexCenter">
                          {filterCount > 0 && (
                            <div className="filterCount">{filterCount}</div>
                          )}
                          <span className="material-icons arrowIcon">
                            {index === activeIndex
                              ? "keyboard_arrow_up"
                              : "keyboard_arrow_down"}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="filterComponentTitle"
                          style={{ padding: "14px 0" }}
                          onClick={() => {
                            setActiveIndex(
                              index !== activeIndex ? index : null
                            );
                          }}
                        >
                          {name}
                        </div>
                        {action === "toggle" && (
                          <FilterComponentAction
                            {...item}
                            activeIndex={activeIndex}
                            index={index}
                            setTableLoading={setTableLoading}
                            setActiveIndex={setActiveIndex}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              {action === "inputBox" ? (
                <>
                  {id === "title" && (
                    <div className="exactMatch">
                      <span className="horizontalBorderLine" />
                      <SecondaryCheckbox
                        key={"exactMatchOnly"}
                        label={"Exact Match only"}
                        id={"exactMatchOnly"}
                        checked={checkboxVal}
                        cbk={updateCheckbox}
                      />
                    </div>
                  )}
                  <AddRemoveValue
                    defaultValue={
                      [
                        ...new Set(dataSearchState?.filterComponentData?.[id])
                      ] || []
                    }
                    id={id}
                    store={dataSearchState}
                    setTableLoading={setTableLoading}
                  />
                </>
              ) : (
                ![
                  "inputBox",
                  "toggle",
                  "dropdown",
                  "autoCompleteMultiSelectDropdown"
                ].includes(action) && (
                  <FilterComponentAction
                    {...item}
                    activeIndex={activeIndex}
                    index={index}
                    setTableLoading={setTableLoading}
                    setActiveIndex={setActiveIndex}
                  />
                )
              )}
            </section>
          );
        })}
      </div>
    </section>
  );
});

export { FilterComponent };
export default FilterComponent;
