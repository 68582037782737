import React from "react";
import { SVG_ICON } from "Model/SvgIcon";

export const IconType = props => {
  const { group = "", eventType = "", taskType = "" } = props || {};

  return (
    <div className={`svgIconType ${eventType || taskType || "inheritDot"}`}>
      {((eventType || taskType) && SVG_ICON[taskType || eventType]) || ""}
    </div>
  );
};

export default IconType;
