/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { toasterState } from "Components/common/toaster";
import CheckBox from "Components/common/CheckBox";
import Button from "Components/common/Button";
import { settingsStoreData } from "Stores/settingsData";
import QuilEditor from "Components/common/quillEditor";
import userDetail from "Stores/userDetail";

const defaultLinkDom = `<div class="ql-editor" data-gramm="false" contenteditable="true"><div>Don't want to hear from me again? <a href="{{unsubscribeLink}}" rel="noopener noreferrer" target="_blank">Click here</a></div></div>`;

const Unsubscribe = observer(props => {
  const [editorValue, setEditorOnchangeValue] = useState("");
  const [checkUnSubscribeLink, setUnSubscribeLink] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [loader, setLoader] = useState(false);
  const { unsubscribeEnabled = false } = userDetail?.userFeatureFlag || {};
  const defaultDomLink =
    userDetail?.userInfo?.unsubLinkDefaultDom || defaultLinkDom;

  const bodyOnchangeEvnt = (content = "") => {
    setEditorOnchangeValue(content);
  };

  const getDefaultValue = () => {
    setSubscriptionData({ insertUnsubLink: false, unsubLink: defaultDomLink });
    setEditorOnchangeValue(defaultDomLink);
  };

  const constructUnsubscribeData = () => {
    const { insertUnsubLink = false, unsubLink } =
      settingsStoreData.unsubscribeData;
    if (unsubLink && unsubLink !== "" && unsubLink.length > 0) {
      setEditorOnchangeValue(unsubLink);
      setSubscriptionData(settingsStoreData.unsubscribeData);
    } else {
      setSubscriptionData({ insertUnsubLink, unsubLink: defaultDomLink });
      setEditorOnchangeValue(defaultDomLink);
    }
    if (insertUnsubLink) {
      setUnSubscribeLink(insertUnsubLink);
    }
  };

  const checkAnchorLink = domStr => {
    let linkDom = document.createRange().createContextualFragment(domStr);
    let anchorLen = linkDom.querySelectorAll("a");
    if (anchorLen && anchorLen.length < 2) {
      linkDom.querySelector("a").setAttribute("href", "{{unsubscribeLink}}");
      linkDom.querySelector("a").setAttribute("target", "_blank");
      let div = document.createElement("div");
      div.appendChild(linkDom.cloneNode(true));
      return div.innerHTML;
    } else {
      return false;
    }
  };

  const checkDomHasAnchorAndLink = domStr => {
    let linkDom = document.createRange().createContextualFragment(domStr);
    if (
      linkDom &&
      linkDom.textContent &&
      linkDom.textContent.trim().length > 0
    ) {
      if (domStr && domStr !== "" && domStr.indexOf(`href`) !== -1) {
        return true;
      } else {
        toasterState.setToastMsg(
          "Please insert the unsubscribe link and save",
          "fail"
        );
        setLoader(false);
        return false;
      }
    } else {
      toasterState.setToastMsg("Unsubscribe text cannot be empty", "fail");
      setLoader(false);
      return false;
    }
  };

  const saveUnsubscribe = async () => {
    setLoader(true);
    if (checkDomHasAnchorAndLink(editorValue)) {
      let newDom = checkAnchorLink(editorValue);
      if (newDom) {
        const payload = {
          unsubLink: checkUnSubscribeLink ? newDom : editorValue,
          insertUnsubLink: checkUnSubscribeLink
        };
        const cbk = value => {
          if (value) {
            settingsStoreData.setUnsubscribeData(payload);
          }
          setLoader(false);
        };
        settingsStoreData.saveUnsubscribeChanges(payload, cbk);
      } else {
        toasterState.setToastMsg(
          "You can insert only one unsubscription link",
          "fail"
        );
        setLoader(false);
      }
    } else if (!checkUnSubscribeLink) {
      const payload = {
        unsubLink: editorValue,
        insertUnsubLink: checkUnSubscribeLink
      };
      const cbk = value => {
        if (value) {
          settingsStoreData.setUnsubscribeData(payload);
        }
        setLoader(false);
      };
      settingsStoreData.saveUnsubscribeChanges(payload, cbk);
    }
  };

  const updateCheckBoxValue = (value, id) => {
    setUnSubscribeLink(value);
  };

  useEffect(() => {
    if (
      settingsStoreData?.unsubscribeData &&
      Object.keys(settingsStoreData.unsubscribeData)?.length > 0
    ) {
      constructUnsubscribeData();
    } else {
      getDefaultValue();
    }
  }, [settingsStoreData.unsubscribeData]);

  useEffect(() => {
    settingsStoreData.getUnsubscribeData();
  }, []);

  return (
    <div className={`unsubscribeWrapper ${unsubscribeEnabled ? "" : "blurBg"}`}>
      <div className="unsubscribeCont">
        <div className="editor" id="reactunsubscribeEditor">
          <div className="unsubscribeTitle">Unsubscribe</div>
          <QuilEditor
            bodyOnChange={bodyOnchangeEvnt}
            prefilledValue={subscriptionData.unsubLink}
            unSubLink={true}
            showRewriteAI={false}
          />
        </div>
      </div>
      <div className="unsubscribeCheck">
        <CheckBox
          key="addUnsubscribeLink"
          name="Add an unsubscribe link to my sequence emails"
          id="addUnsubscribeLink"
          checked={checkUnSubscribeLink}
          cbk={updateCheckBoxValue}
        />
      </div>
      <div className="unsubscribeBtnSection">
        <Button
          id="addUnsubscribeBtn"
          name="addUnsubscribeBtn"
          btnText="Save Changes"
          icon={false}
          iconName=""
          type="button"
          className={`addUnsubscribeBtn ${loader ? "loading" : ""}`}
          loader={loader}
          click={saveUnsubscribe}
        />
      </div>
    </div>
  );
});

export default withRouter(Unsubscribe);
export { Unsubscribe };
