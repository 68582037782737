import React, { useState, useRef } from "react";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import CustomTooltip from "Components/common/CustomTooltip";
import InputTextBox from "Components/common/InputTextBox";
import { observer } from "mobx-react";
import ContactAutoCompleteDropdown from "./ContactAutoCompleteDropdown";

const AddRemoveEmailAddress = observer((props = {}) => {
  const {
    emailList = [],
    label = "",
    keyName = "",
    objKey = "",
    placeHolder = "",
    updateState = () => {},
    isOutboxPage = false,
    pageType = ""
  } = props || {};

  const inputRef = useRef(null);

  const { blockedDomainList = [] } = userDetail || {};

  const [email, setEmail] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [showToolTip, setShowToolTip] = useState("");

  const tooltipErrorInfoText = {
    BOUNCED: "Bounced contact",
    OPTED_OUT: "Opted out contact",
    INVALID: "Invalid email address",
    DONOTCONTACT: "Domain blocked"
  };

  const removeEmail = (event, email = "") => {
    Utils.preventDefaultFn(event);
    if (email) {
      let tempList = emailList ? [...emailList] : [];
      if (tempList?.length) {
        tempList = tempList.filter(item => {
          if (item?.email !== email) {
            return item;
          }
        });
      }
      updateState(keyName, tempList, objKey);
    }
  };

  const checkBlockedDomain = (tempEmail = "") => {
    let splitEmail = tempEmail?.split("@") || [];
    return (
      splitEmail?.length > 1 && blockedDomainList.includes(splitEmail?.[1])
    );
  };

  const isUniqueEmailList = (value = "") => {
    let tempList = [...emailList] || [];
    if (tempList?.length > 0) {
      let eList = tempList.map(item => item?.email?.toLowerCase()?.trim());
      return !eList.includes(value?.toLowerCase()?.trim());
    }
    return true;
  };

  const addEmail = async (contactEmail = "") => {
    let tempEmail = contactEmail ? contactEmail : email || "";
    if (tempEmail?.trim()) {
      let isValidEmail = Utils.emailValidate(
        tempEmail?.trim()?.toLowerCase() || ""
      );
      if (isUniqueEmailList(tempEmail)) {
        let emailStatusObj =
          (isValidEmail &&
            (await Utils.validateEmailAddressStatus([tempEmail]))) ||
          "";
        let value = !isValidEmail
          ? "INVALID"
          : checkBlockedDomain()
          ? "DONOTCONTACT"
          : (emailStatusObj &&
              Object.keys(emailStatusObj)?.length > 0 &&
              ["BOUNCED", "OPTED_OUT"].includes(emailStatusObj[tempEmail]) &&
              emailStatusObj[tempEmail]) ||
            "";
        let tempList = emailList ? [...emailList] : [];
        tempList.push({
          email: tempEmail?.trim(),
          invalid: value
        });
        updateState(keyName, tempList, objKey);
      }
      inputRef && inputRef.current && inputRef.current.focus();
      setEmail("");
    }
  };

  const handleEnter = (event = "") => {
    Utils.isSubmitKeyAction(event) && addEmail();
  };

  const changeAction = (_, value = "") => {
    errMsg && setErrMsg("");
    value?.includes(",") ? addEmail() : setEmail(value);
  };

  const addEmailViaAutoComplete = (obj = {}) => {
    const { stage = "" } = obj || {};
    let tempEmail = obj?.email ? obj?.email : email || "";
    if (tempEmail?.trim()) {
      let isValidEmail = Utils.emailValidate(
        tempEmail?.trim()?.toLowerCase() || ""
      );
      if (isUniqueEmailList(tempEmail)) {
        let emailStatus = stage?.toUpperCase() || "";
        let value = !isValidEmail
          ? "INVALID"
          : checkBlockedDomain(tempEmail)
          ? "DONOTCONTACT"
          : emailStatus && ["BOUNCED", "OPTED_OUT"].includes(emailStatus)
          ? emailStatus
          : "";
        let tempList = emailList ? [...emailList] : [];
        tempList.push({
          email: tempEmail?.trim(),
          invalid: value
        });
        updateState(keyName, tempList, objKey);
      }
      inputRef && inputRef.current && inputRef.current.focus();
      setEmail("");
    }
  };

  const inputObj = {
    name: `emailAddress${keyName}Input`,
    id: `emailAddress${keyName}Input`,
    type: "text",
    value: email,
    cbk: handleEnter,
    change: changeAction,
    err: errMsg,
    placeHolder,
    inputBoxRef: inputRef,
    autoFocus: true
  };

  const handleFocus = (e = "") =>
    keyName?.toLowerCase() !== "to" ? Utils.focusInputInsideDOM(e) : {};

  return (
    <div
      className={`addRemoveEmailAddress ${keyName}Section`}
      onClick={e => handleFocus(e)}
    >
      <span className="labelName">{label}</span>
      {(emailList?.length > 0 &&
        emailList.map((item, index) => {
          const { name = "", email = "", invalid = "" } = item || {};
          return (
            <div
              className={`chipEmail ${invalid ? "invalidEmail" : ""}`}
              key={`${email}${index}`}
            >
              <a
                className="email"
                href={`mailto:${email}`}
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                {name || email}
              </a>
              <i
                className="material-icons remove"
                onClick={e => removeEmail(e, email)}
              >
                close
              </i>
              {invalid && (
                <span className="tooltipInfoWrap">
                  <i
                    className="material-icons infoIcon"
                    onMouseOver={() => setShowToolTip(email)}
                    onMouseLeave={() => setShowToolTip("")}
                  >
                    info_outline
                  </i>
                  {showToolTip === email && (
                    <CustomTooltip text={tooltipErrorInfoText[invalid]} />
                  )}
                </span>
              )}
            </div>
          );
        })) ||
        ""}
      <div className="emailInputAndDropdownCont">
        {!isOutboxPage && <InputTextBox {...inputObj} />}
        <ContactAutoCompleteDropdown
          inputText={email}
          selectedCallbk={addEmailViaAutoComplete}
        />
      </div>
    </div>
  );
});

export default AddRemoveEmailAddress;
