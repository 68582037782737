import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import {
  Switch,
  Redirect,
  Route,
  withRouter,
  useHistory
} from "react-router-dom";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import SalesforceConfigure from "./salesforce/SalesforceConfigure";
import PipedriveConfigure from "./pipedrive/PipedriveConfigure";
import HubspotConfigure from "./hubspot/HubspotConfigure";
import SlackConfigure from "./slack/SlackConfigure";
import ZohoConfigure from "./zoho/ZohoConfigure";
import CrmIntegration from "./CrmIntegration";
import { zohoDataStore } from "./zoho/ZohoStoreData";
import { hubspotStore } from "./hubspot/HubspotStore";
import { slackDataStore } from "Stores/SlackDataStore";
import { pipedriveStore } from "./pipedrive/PipedriveStore";
import { salesforceConnectedData } from "./salesforce/SalesforceConnectedData";

const CrmRouting = observer(() => {
  const history = useHistory();

  const {
    salesforceIntegrationEnabled = false,
    slackIntegrationEnabled = false,
    zohoIntegrationEnabled = false,
    hubspotIntegrationEnabled = false,
    pipedriveIntegrationEnabled = false,
    inFreePlan = false,
    planName = ""
  } = userDetail.userFeatureFlag || {};

  const hideCrm = () => {
    history.push("/settings/crm");
  };

  const showAdminPopup = (key = "slack") => {
    let msg = `Please contact your account administrator to configure ${key} configuration.`;
    Utils.showMessagePopup(msg);
  };

  const commonProps = {
    hideCbk: hideCrm,
    showAdminPopup,
    planName,
    inFreePlan
  };

  const getInit = () => {
    salesforceConnectedData.getSalesForceOauthData();
    slackDataStore.getSlackData();
    zohoDataStore.getZohoData();
    hubspotStore.getHubspotData();
    pipedriveStore.getPipedriveData();
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path="/settings/crm/salesforce"
        render={() => (
          <SalesforceConfigure
            {...commonProps}
            isFeatureEnabled={salesforceIntegrationEnabled}
          />
        )}
      />
      <Route
        exact
        path="/settings/crm/hubspot"
        render={() => (
          <HubspotConfigure
            {...commonProps}
            isFeatureEnabled={hubspotIntegrationEnabled}
          />
        )}
      />
      <Route
        exact
        path="/settings/crm/pipedrive"
        render={() => (
          <PipedriveConfigure
            {...commonProps}
            isFeatureEnabled={pipedriveIntegrationEnabled}
          />
        )}
      />
      <Route
        exact
        path="/settings/crm/zoho"
        render={() => (
          <ZohoConfigure
            {...commonProps}
            isFeatureEnabled={zohoIntegrationEnabled}
          />
        )}
      />
      <Route
        exact
        path="/settings/crm/slack"
        render={() => (
          <SlackConfigure
            {...commonProps}
            isFeatureEnabled={slackIntegrationEnabled}
          />
        )}
      />
      <Route exact path="/settings/crm" render={() => <CrmIntegration />} />
      <Redirect from="/settings/crm" to="/settings/crm" />
    </Switch>
  );
});

export default withRouter(CrmRouting);
