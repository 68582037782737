import React, { useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import InboxUtils from "Utils/InboxUtils";
import userDetail from "Stores/userDetail";
import { OUTLOOK_CLIENT_ID, OUTLOOK_REDIRECT_URL } from "Static/constant";
import { ReactComponent as DeleteIcon } from "Assets/Settings/deleteInboxIcon.svg";
import { ReactComponent as ConfigureInboxIcon } from "Assets/Settings/configureInbox.svg";
import { ReactComponent as FlameIcon } from "Assets/Settings/flame.svg";
import { ReactComponent as SpamCountIcon } from "Assets/Settings/spamCount.svg";
import ConnectSmtpImap from "./ConnectSmtpImap";
import DriverMaster from "Components/driver/Driver";
import Button from "Components/common/Button";
import { toasterState } from "Components/common/toaster";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { googleOAuthPopupState } from "./googleOAuthPopup";
import ConnectedInboxLoader from "./ConnectedInboxLoader";
import WarmerMetrics from "./WarmerMetrics";
import InboxImage from "./InboxImage";
import CustomTooltip from "Components/common/CustomTooltip";
import connectedInboxStore from "./store";
import { ReactComponent as CampaignCountIcon } from "Assets/Settings/campaignCount.svg";

const ConnectedInboxList = observer((props = {}) => {
  const {
    deleteCbk = () => {},
    setConfigureData = () => {},
    setShowConfigure = () => {},
    setActiveTab = () => {}
  } = props || {};
  const [tooltipIndex, setTooltipIndex] = useState("");
  const [smtpData, setSmtpData] = useState({});

  const btnProps = {
    id: "warmingEnableBtn",
    name: "warmingEnableBtn",
    icon: false,
    svg: <FlameIcon />,
    type: "button",
    className: "warmingEnableBtn"
  };

  const handleConfigureAction = data => {
    setConfigureData(data);
    setShowConfigure(true);
    setActiveTab("settings");
  };

  const getButtonText = (status = "", emailWarming = {}) => {
    if (status?.toLowerCase() === "active" && emailWarming?.warmingError) {
      return "Warming Error";
    } else if (["access_revoked", "deleted"].includes(status?.toLowerCase())) {
      return emailWarming?.warmingError ? "Warming Error" : "Error";
    }
    return "";
  };

  const handleReconnectInbox = (
    e,
    data = {},
    warmingEnabled = false,
    emailWarming = {}
  ) => {
    const { type = "" } = data || {};
    const { warmingError = "" } = emailWarming || {};
    if (type === "gmail_oath") {
      if (warmingError) {
        let tempData = { ...data, warmingEnabled: false };
        googleOAuthPopupState.setInboxData(tempData);
        googleOAuthPopupState.setScreenNo("one");
        googleOAuthPopupState.setConnectConfig("oAuth");
        googleOAuthPopupState.setShowPopup(true);
      } else {
        connectedInboxStore.openGoogleAuth(
          data?.email,
          data,
          connectedInboxStore.handleWarming
        );
      }
    } else {
      if (type === "outlook_oauth") {
        connectedInboxStore.microsoftReconnectAuth(data?.email, data);
      } else if (type === "smtp") {
        setSmtpData(data);
        connectedInboxStore.setShowSmptpViaWarming(true);
      }
    }
  };

  const smptInboxAction = (connected = false) => {
    connectedInboxStore.setShowSmptpViaWarming(false);
    if (connectedInboxStore?.inboxScreentype === "one") {
      connectedInboxStore.getAccountDetails(false, true);
    } else {
      connectedInboxStore.setInboxScreenType("two");
    }
  };

  const handleWarmingAction = (e, item = {}, warmingEnabled = false) => {
    confirmationPopupState?.showPopup &&
      confirmationPopupState.setShowPopup(false);
    Utils.preventDefaultFn(e);
    if (!connectedInboxStore?.warmingLoaderId) {
      connectedInboxStore.handleWarmingAction(e, item, warmingEnabled);
    }
  };

  const handleActiveButtonClick = (e, item = {}, warmingEnabled = false) => {
    {
      Utils.preventDefaultFn(e);
      if (warmingEnabled) {
        confirmationPopupState.setPopupValues({
          content: `Are you sure you want to stop email warming for this inbox?`,
          actionBtnText: "Yes",
          cancelBtnText: "Cancel",
          cancelCbk: () => confirmationPopupState.setShowPopup(false),
          callback: () => handleWarmingAction(e, item, warmingEnabled),
          loadingMsg: "Loading..."
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        handleWarmingAction(e, item, warmingEnabled);
      }
    }
  };

  return (
    <>
      {connectedInboxStore?.accountData?.length > 0 &&
      !connectedInboxStore?.showSmtpViaWarming
        ? connectedInboxStore?.accountData.map((item, index) => {
            const {
              id = "",
              type = "",
              email = "",
              status = "",
              userName = "",
              loader = false,
              smtp = {},
              warmingEnabled = false,
              emailWarming = {},
              accessRevokedBySystem = false
            } = item || {};

            const inboxType =
              type === "smtp"
                ? "SMTP"
                : type === "gmail_oath"
                ? "Gmail"
                : "Microsoft";
            const errorCheck = getButtonText(status, emailWarming || {});
            return !loader ? (
              <div
                className={`connectedInboxCard ${
                  status !== "ACTIVE" ? "reconnectCard" : ""
                }`}
                key={`connectedInboxAccountCard${inboxType}${index}`}
                onClick={e => {
                  Utils.preventDefaultFn(e);
                  handleConfigureAction(item);
                }}
              >
                <InboxImage type={type} />
                <span className="wrapConnectedEmail">
                  {(type === "smtp" && (
                    <span className="userName">
                      {smtp?.senderName || smtp?.userName || smtp?.name || ""}
                    </span>
                  )) ||
                    (userName && (
                      <span className="userName">{userName || ""}</span>
                    ))}
                  <span className="connectedEmail">
                    {type === "smtp" ? smtp?.email : email}
                  </span>
                </span>
                <div>
                  <CampaignMatrics metrics={item?.overallEmailMetrics} />
                </div>
                <div
                  className={`${
                    warmingEnabled
                      ? "groupWarmingFields"
                      : "notGroupWarmingFields"
                  }`}
                >
                  {warmingEnabled && (
                    <div className="warmingMetrics">
                      <WarmerMetrics metrics={item?.overallEmailMetrics} />
                    </div>
                  )}
                  {!["error", "warming error"].includes(
                    errorCheck?.toLowerCase()
                  ) ? (
                    <div className="warmingEnabledOrDisabledBtn">
                      <Button
                        {...btnProps}
                        btnText={
                          warmingEnabled
                            ? "Warming In Progress"
                            : "Enable Warming"
                        }
                        className={`${
                          warmingEnabled ? "warmingEnabled" : "enableWarming"
                        } ${
                          connectedInboxStore?.warmingLoaderId === id
                            ? `${
                                warmingEnabled ? "disabled" : "enabled"
                              } loading`
                            : ""
                        }
                      `}
                        onClick={e =>
                          handleActiveButtonClick(e, item, warmingEnabled)
                        }
                        onMouseOver={() => setTooltipIndex(index)}
                        onMouseLeave={() => setTooltipIndex("")}
                        loader={connectedInboxStore?.warmingLoaderId === id}
                      />
                      {tooltipIndex === index && (
                        <CustomTooltip
                          text={
                            warmingEnabled
                              ? "Click here to turn off the warming for this email account"
                              : "Click here to turn on the warming for this email account"
                          }
                        />
                      )}
                    </div>
                  ) : (
                    <div className="warmingEnabledOrDisabledBtn errorBtn">
                      <Button
                        {...btnProps}
                        btnText="Connection Failed"
                        className={"warmingError"}
                        onMouseOver={() => setTooltipIndex(index)}
                        onMouseLeave={() => setTooltipIndex("")}
                        loader={false}
                        svg={<SpamCountIcon />}
                      />
                      {tooltipIndex === index && (
                        <CustomTooltip
                          text={getButtonText(status, emailWarming)}
                        />
                      )}
                    </div>
                  )}
                </div>
                {!["error", "warming error"].includes(
                  errorCheck?.toLowerCase()
                ) ? (
                  <div className="btnInboxActions">
                    <span
                      className="configureBtn"
                      onClick={() => handleConfigureAction(item)}
                    >
                      <ConfigureInboxIcon />
                    </span>
                    <span
                      className="deleteInboxIcon"
                      onClick={e => {
                        Utils.preventDefaultFn(e);
                        deleteCbk(item, index);
                      }}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                ) : (
                  <div className="btnInboxActions">
                    <span
                      className="reconnectInbox"
                      onClick={e => {
                        Utils.preventDefaultFn(e);
                        handleReconnectInbox(
                          e,
                          item,
                          warmingEnabled,
                          emailWarming
                        );
                      }}
                    >
                      Reconnect
                    </span>
                    <span
                      className="deleteInboxIcon"
                      onClick={e => {
                        Utils.preventDefaultFn(e);
                        deleteCbk(item, index);
                      }}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <ConnectedInboxLoader
                count={1}
                key={`connectedInboxAccountCardLoader${index}`}
              />
            );
          })
        : (!connectedInboxStore?.showSmtpViaWarming && (
            <div className="noInboxFound">No Inbox found</div>
          )) ||
          ""}
      {connectedInboxStore?.showSmtpViaWarming && (
        <ConnectSmtpImap
          setSmtpImapConnect={connectedInboxStore.setShowSmptpViaWarming}
          reloadInbox={smptInboxAction}
          email={smtpData?.email}
          reconnectInboxId={smtpData?.id}
        />
      )}
    </>
  );
});

export default ConnectedInboxList;
export { ConnectedInboxList };

const CampaignMatrics = (props = {}) => {
  const { metrics = {} } = props || {};
  const [tooltipIndex, setTooltipIndex] = useState("");

  const config = [
    {
      name: "campaignCount",
      id: "campaignEmailSentCount",
      icon: <CampaignCountIcon />,
      color: "#3953FB",
      tooltipText: "Emails sent via sequences"
    }
  ];

  return (
    <div className="warmerMetricsWrapper">
      {config.map((item, index) => {
        const {
          id = "",
          icon = <></>,
          color = "#28AD56",
          tooltipText = ""
        } = item || {};
        return (
          <div
            className="warmerMetrics"
            key={id}
            onMouseOver={() => setTooltipIndex(index)}
            onMouseLeave={() => setTooltipIndex("")}
          >
            <div className="warmerMetricsIcon">{icon}</div>
            <div className="warmerMetricsValue">
              {Utils.formatNumberToStringInUSFormat(metrics?.[id])}
            </div>
            {tooltipIndex === index && <CustomTooltip text={tooltipText} />}
          </div>
        );
      })}
    </div>
  );
};
