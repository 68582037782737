import React, { useEffect, useState } from "react";
import { action, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import { ReactComponent as LinkedInIcon } from "Pages/Dashboard/settings/ConnectedAccounts/LinkedInAccounts/svg/linkedIn.svg";
import LiveFeedUtils from "Pages/Dashboard/dashboard/LiveFeed/utils";
import { months } from "Pages/Dashboard/dashboard/LiveFeed/constants";
import Utils from "Utils/utils";
import { ReactComponent as ReplySvg } from "Assets/svg/emailPreview/reply.svg";
import TextArea from "../common/TextArea";
import Button from "../common/Button";
import ScheduleLaterSendEmailBtn from "./ScheduleLaterSendEmailBtn";
import { sequenceContactData } from "Stores/sequence/Contact";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";

class LinkedInReplyPopup {
  @observable showPopup = false;
  @observable liMessages = [];
  @observable editorLoading = true;
  @observable contactSequenceId = "";
  @observable contactId = "";

  @action
  setContactSequenceId = (value = "") => {
    this.contactSequenceId = value;
  };

  @action
  setContactId = (value = "") => {
    this.contactId = value;
  };

  @action
  setShowPopup = (value = "") => {
    this.showPopup = value;
  };

  @action
  setLiMessages = (value = []) => {
    this.liMessages = value;
  };

  @action
  setEditorLoading = (value = false) => {
    this.editorLoading = value;
  };
}

const linkedInReplyPopupState = new LinkedInReplyPopup();

const formatDate = (value, replaceAtByComma = false) => {
  const date = new Date(value);
  const mon = months[date.getMonth()];
  const year = date.getFullYear();
  const dateValue = date.getDate();
  const hours = ((date.getHours() + 11) % 12) + 1;
  const mins = date.getMinutes();
  const meridiem = date.getHours() >= 12 ? "PM" : "AM";
  let convertedHoursText = hours === 12 && meridiem === "AM" ? "00" : hours;
  if (LiveFeedUtils.isYesterday(date)) {
    return `Yesterday - ${convertedHoursText}:${
      mins > 9 ? mins : "0" + mins
    } ${meridiem}`;
  } else if (LiveFeedUtils.isToday(date)) {
    return `Today - ${convertedHoursText}:${
      mins > 9 ? mins : "0" + mins
    } ${meridiem}`;
  } else {
    return `${mon} ${dateValue}, ${year} - ${convertedHoursText}:${
      mins > 9 ? mins : "0" + mins
    } ${meridiem}`;
  }
};

const actionButton = [
  {
    id: "reply",
    name: "Reply",
    svg: <ReplySvg />,
    show: true
  }
];

const LinkedInReplyContentPopup = observer(() => {
  const [isReply, setIsReply] = useState(false);
  const [liMessageNotes, setLiMessageNotes] = useState("");
  const [sequenceId, setSequenceId] = useState(
    linkedInReplyPopupState?.contactSequenceId
  );
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [liMessageContactId, setLiMessageContactId] = useState(
    linkedInReplyPopupState?.contactId
  );

  const changeNotes = event => {
    if (isMessageSent) return;
    Utils.preventDefaultFn(event);
    setLiMessageNotes(event.target.value || "");
  };

  const handleAction = (id = "") => {
    id?.toLowerCase() === "reply" ? setIsReply(true) : setIsReply(false);
  };

  const sendLinkedInMessage = async () => {
    if (isSendDisabled) return;
    setIsSendDisabled(true);
    setIsMessageSent(true);

    linkedInReplyPopupState.setEditorLoading(true);
    const timeZone = Utils.getCurrentUserTimeZone();
    let dueDate = Utils.createScheduleDate(new Date());
    let splittedTimezone = timeZone?.replace("UTC", "") || "";
    dueDate = `${dueDate}${splittedTimezone}`;

    try {
      let requestBody;
      if (liMessageContactId) {
        requestBody = {
          cid: liMessageContactId,
          text: liMessageNotes,
          dueDateString: dueDate
        };
      } else {
        requestBody = {
          ssId: sequenceId,
          text: liMessageNotes,
          dueDateString: dueDate
        };
      }

      const config = {
        url: `${URLS?.sendLinkedInMessage}`,
        method: "POST",
        data: requestBody
      };

      const response = await makeApi(config);
      const { data = {} } = response || {};
      const responseCode = +response?.data?.responseCode;

      if (responseCode === 200) {
        linkedInReplyPopupState.setEditorLoading(false);
        setIsReply(false);
        setLiMessageNotes("");
        let newObj = {
          senderName: data.senderName,
          updateTs: data.dueDate,
          imageUrl: data.profileImage,
          message: data.text
        };

        linkedInReplyPopupState.setLiMessages([
          newObj,
          ...toJS(linkedInReplyPopupState?.liMessages)
        ]);
        toasterState.setToastMsg("Message sent", "success");
      } else if (responseCode === 100) {
        toasterState.setToastMsg(
          "No active LinkedIn connection found. Please connect and try again.",
          "failure"
        );
      } else if (responseCode === 101) {
        toasterState.setToastMsg(
          "You cannot send a message to this contact. Please contact your admin.",
          "failure"
        );
      } else if (responseCode === 102) {
        toasterState.setToastMsg(
          "This contact can no longer receive messages from you.",
          "failure"
        );
      } else if (responseCode === 103) {
        toasterState.setToastMsg(
          "You've reached the LinkedIn daily message limit. Please try sending later.",
          "failure"
        );
      } else {
        Utils.showApiFailureResponse(response?.data);
      }
    } catch (error) {
      console.error("Error in sendLinkedInMessage:", error);
    } finally {
      linkedInReplyPopupState.setEditorLoading(false);
      setIsSendDisabled(false);
      setIsMessageSent(false);
      linkedInReplyPopupState.setContactId("");
      linkedInReplyPopupState.setContactSequenceId("");
    }
  };

  const buttonLists = [
    {
      id: "cancelBtn",
      name: "cancelBtn",
      btnText: "Cancel",
      type: "button",
      disabled: false,
      className: `actionBtn cancelBtn`,
      click: () => {
        if (isReply) {
          setIsReply(false);
        }
        setLiMessageNotes("");
      }
    },
    {
      id: "sendEmailBtn",
      name: "sendEmailBtn",
      btnText: "Send",
      type: "button",
      disabled: liMessageNotes.length >= 1 ? false : true,
      knobs: linkedInReplyPopupState.editorLoading,
      className: `actionBtn sendEmailBtn ${
        linkedInReplyPopupState.editorLoading ? "loading" : ""
      }`,
      click: () => {
        sendLinkedInMessage();
      }
    }
  ];

  useEffect(() => {
    if (linkedInReplyPopupState.contactSequenceId !== sequenceId) {
      setSequenceId(linkedInReplyPopupState.contactSequenceId);
    }
  }, [linkedInReplyPopupState.contactSequenceId]);

  useEffect(() => {
    if (linkedInReplyPopupState.contactId !== liMessageContactId) {
      setLiMessageContactId(linkedInReplyPopupState.contactId);
    }
  }, [linkedInReplyPopupState.contactId]);

  return (
    linkedInReplyPopupState?.showPopup && (
      <>
        <div className="linkedInReplyPopupWrapper">
          <div className={`card`}>
            <div className="cardHeader">
              <div className={`cardTitle`}>
                <LinkedInIcon />
                <div className="linkedInTitle">LinkedIn</div>
              </div>

              <div className="toLinkedInDetails">
                <div className="linkedInActionListBtn">
                  <ul className="actionList">
                    {actionButton.map(item => {
                      const {
                        id = "",
                        name = "",
                        svg = "",
                        show = ""
                      } = item || {};
                      if (show)
                        return (
                          <li
                            data-key={id}
                            key={id}
                            className={id}
                            onClick={() => handleAction(id)}
                          >
                            {svg}
                            <span className={`name ${svg ? "icon" : ""}`}>
                              {name}
                            </span>
                          </li>
                        );
                    })}
                  </ul>
                </div>
              </div>

              <div className="closeIcon">
                <i
                  className="material-icons"
                  onClick={() => {
                    linkedInReplyPopupState.setShowPopup(false);
                    setIsReply(false);
                    setLiMessageNotes("");
                    linkedInReplyPopupState.setLiMessages([]);
                    linkedInReplyPopupState.setContactId("");
                  }}
                >
                  close
                </i>
              </div>
            </div>
            {isReply && (
              <>
                <TextArea
                  id="linkedInReplyNotes"
                  placeholder="Enter your message..."
                  onChangeEvent={changeNotes}
                  value={liMessageNotes}
                  isRequired={false}
                  disabled={isMessageSent}
                />
                {isReply && (
                  <div className="actionBtnList">
                    {buttonLists.map(item => (
                      <Button {...item} key={item.id} />
                    ))}
                  </div>
                )}
              </>
            )}
            <div className="cardBody">
              {linkedInReplyPopupState?.editorLoading ? (
                <div className="linkedInContentWrapper composeEditorLoader">
                  <div className="emailContentSpinner" />
                </div>
              ) : (
                linkedInReplyPopupState?.liMessages?.length > 0 &&
                linkedInReplyPopupState.liMessages.map(item => {
                  const {
                    senderName = "",
                    updateTs = "",
                    imageUrl = "",
                    message = ""
                  } = item || {};

                  return (
                    <div className="linkedInReplyContentWrapBody">
                      <div className="linkedInIntroDetails">
                        <span className="imgPlaceholder">
                          {imageUrl ? (
                            <img className="imagePic" src={imageUrl}></img>
                          ) : (
                            <span className="material-icons">
                              account_circle
                            </span>
                          )}
                        </span>

                        <span className="linkedInSenderName">
                          {senderName?.toLowerCase()}
                        </span>
                        <span className="linkedInDate">
                          {formatDate(updateTs, true)}
                        </span>
                      </div>
                      <div className="linkedInBodyDetails">
                        <p
                          className="linkedInMessage"
                          dangerouslySetInnerHTML={{
                            __html: Utils.contentReadyOnly(message)
                          }}
                        ></p>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div
            className="linkedInReplyPopupOverlay"
            onClick={() => {
              linkedInReplyPopupState.setShowPopup(false);
              setIsReply(false);
              setLiMessageNotes("");
              linkedInReplyPopupState.setLiMessages([]);
              linkedInReplyPopupState.setContactId("");
            }}
          ></div>
        </div>
      </>
    )
  );
});

export { LinkedInReplyContentPopup, linkedInReplyPopupState };
export default LinkedInReplyContentPopup;
