import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
const { observable, action } = require("mobx");

class DomainHealthCenterStore {
  @observable allConnectedAccounts = [];
  @observable connectedAccountsLoading = true;
  @observable currentPage = 1;
  @observable totalPages = 1;
  @observable domainHealthDetailedInfo = false;
  @observable dnsRecords = {};
  @observable currentEmailId = "";
  @observable loadRefreshAccountId = "";
  @observable domainScoreData = [];
  @observable domainScoreDataLoading = true;
  @observable userFilter = {};
  @observable loader = false;
  @observable pageLimit = 10;
  @observable totalCount = 0;

  @action
  setLoader = (val = false) => {
    this.loader = val;
  };

  @action
  setCurrentEmailId = (val = "") => {
    this.currentEmailId = val;
  };

  @action
  setAllConnectedAccounts(val = []) {
    this.allConnectedAccounts = val;
  }

  @action
  setConnectedAccountsLoading = (val = false) => {
    this.connectedAccountsLoading = val;
  };

  @action
  setLoadRefreshAccountId = (val = "") => {
    this.loadRefreshAccountId = val;
  };

  @action
  setCurrentPage = (val = 1) => {
    this.currentPage = val;
  };

  @action
  setTotalPages = (val = 1) => {
    this.totalPages = val;
  };

  @action
  setTotalCount = (count = 0) => {
    this.totalCount = count;
  };

  @action
  setDomainHealthDetailedInfo = (value = false) => {
    this.domainHealthDetailedInfo = value;
  };

  @action
  setDnsRecords = (value = {}) => {
    this.dnsRecords = value;
  };

  @action
  setDomainScoreData = (value = []) => {
    this.domainScoreData = value;
  };

  @action
  setDomainScoreDataLoading = (val = false) => {
    this.domainScoreDataLoading = val;
  };

  @action
  setUserFilter = (val = {}) => {
    this.userFilter = val;
  };

  @action
  setPageLimit = (limit = 10) => {
    this.pageLimit = limit;
  };

  @action
  fetchAllConnectedEmails = async () => {
    const data = {
      limit: this.pageLimit,
      pageNo: this.currentPage,
      ...(this.userFilter &&
        Object.keys(this.userFilter).length && {
          filter: {
            ...this.userFilter
          }
        })
    };
    try {
      const config = {
        url: URLS.getAllConnectedInboxes,
        method: "POST",
        data
      };
      const response = await makeApi(config);
      const { data: responseData = {} } = response || {};
      if (responseData) {
        const {
          inboxes = [],
          totalPages = 0,
          currentPage = 0,
          totalCount = 0
        } = responseData || {};
        this.setConnectedAccountsLoading(false);
        this.setTotalPages(totalPages);
        this.setTotalCount(totalCount);
        this.setCurrentPage(currentPage);
        this.setAllConnectedAccounts(inboxes);
      }
    } catch (err) {
      console.error("Fetch Connected Email", err);
    }
  };

  @action
  getDomainHealthScore = async () => {
    const data = {
      ...this.userFilter
    };
    try {
      const config = {
        url: URLS.getDomainScore,
        method: "POST",
        data
      };
      const res = await makeApi(config);
      if (res) {
        const { data = [] } = res || {};
        this.setDomainScoreData(data);
        this.setDomainScoreDataLoading(false);
      } else {
        toasterState.setToastMsg("Something went wrong", "failure");
      }
    } catch (e) {
      console.error("Fetch domain score error", e);
    }
  };
}

const domainHealthCenterStore = new DomainHealthCenterStore();
export { domainHealthCenterStore };
export default domainHealthCenterStore;
