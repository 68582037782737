import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter, useHistory } from "react-router-dom";
import { observable, action } from "mobx";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import { emailPreviewContentPopupState } from "Components/PreviewPopup/EmailPreviewContentPopup";
import UpgradeBadge from "./UpgradeBadge";
import Button from "Components/common/Button";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { Paywall } from "model/revampedModel";

const defaultObject = {
  type: "",
  planName: "",
  exisitingPlanName: "",
  templateCount: 0,
  sequenceCount: 0,
  triggerType: "",
  customFieldsCount: 0,
  contactCount: 0
};

class UpgradePopupState {
  @observable showPopup = false;
  @observable popupType = "";
  @observable clickOutsideClose = true;
  @observable isTemplateEditor = false;
  @observable popupValues = defaultObject;

  @action
  setPopup(value) {
    this.showPopup = value;
  }

  @action
  setPopupType(value) {
    this.popupType = value;
  }

  @action
  setPopupValues(value = {}) {
    this.popupValues = value;
  }

  @action
  setClickOutsideClose(value) {
    this.clickOutsideClose = value;
  }

  @action
  setIsTemplateEditor(value) {
    this.isTemplateEditor = value;
  }
}

const upgradePopupState = new UpgradePopupState();

export const UpgradePopup = observer(() => {
  const history = useHistory();
  const {
    title = "",
    plan = "",
    subTitle = "",
    text = "",
    btnText = "",
    badgeSize = "",
    color = "",
    pageType = "",
    trigger = "",
    type = "",
    showUpgradePopup = false
  } = Paywall(upgradePopupState?.popupValues?.type) || {};

  let isIntercomChatPopup = type === "chatWithUs";

  const closeEditorPopup = () => {
    let value = pageType?.toLowerCase() || "";
    if (value) {
      fileAttachmentStore.setAddFileAttachmentList("");
      fileAttachmentStore.clearAttachmentList();
      if (["sequences", "sequence"].includes(value)) {
        addStagesPopupState.setResetValue();
        addStagesPopupState.setShowStepEmailEditorPopup(false);
      } else if (["templates", "template"].includes(value)) {
        upgradePopupState.setIsTemplateEditor();
      }
      emailPreviewContentPopupState?.showPopup &&
        emailPreviewContentPopupState.setResetPopup();
    }
  };

  const redirectAction = () => {
    if (isIntercomChatPopup) {
      closeEditorPopup();
      Utils.mixpanelTrack(MP_EVENT.CHAT_US_CLICKED, {
        type: "Popup",
        pageType,
        trigger
      });
      !showUpgradePopup && resetPopup();
    } else {
      if (!Utils.isAdmin()) {
        upgradePopupState.setPopup(false);
        Utils.showMessagePopup(
          "Please contact your account administrator to upgrade",
          true
        );
      } else {
        resetPopup();
        closeEditorPopup();
        Utils.mixpanelTrack(MP_EVENT.UPGRADE_TOOLTIP_CLICKED, {
          type: "Popup",
          pageType,
          trigger
        });
        history.push("/pricing");
      }
    }
  };

  const resetPopup = () => {
    upgradePopupState.setPopupType("");
    upgradePopupState.setPopupValues({});
    upgradePopupState.setPopup(false);
  };

  const closePopup = () => {
    upgradePopupState?.clickOutsideClose && resetPopup();
  };

  useEffect(() => {
    upgradePopupState.showPopup &&
      upgradePopupState?.popupValues &&
      Utils.mixpanelTrack(MP_EVENT.UPGRADE_TOOLTIP_SHOWN, {
        type: "Popup",
        pageType,
        trigger
      });
  }, [upgradePopupState.showPopup]);

  return (
    (upgradePopupState?.showPopup && upgradePopupState?.popupValues && (
      <div
        className={`upgradePopup ${
          addStagesPopupState?.showPopup ? "showAddStage" : ""
        } ${plan}`}
      >
        <div className="upgradeCard animated customzoomIn">
          <div className="upgradePopupCloseIcon">
            <i
              className="material-icons closeIcon"
              onClick={() => upgradePopupState.setPopup(false)}
            >
              close
            </i>
          </div>
          <div className="title">
            {!isIntercomChatPopup && (
              <UpgradeBadge plan={plan} badgeSize="lg" />
            )}
            <h4
              className={badgeSize}
              style={{ color: isIntercomChatPopup ? "#3953fb" : color }}
            >
              {title}
            </h4>
          </div>
          <h6 className="subTitle">{subTitle}</h6>
          <div
            className="descText"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <Button
            id={isIntercomChatPopup ? "my_custom_link" : "upgradeBtn"}
            name={isIntercomChatPopup ? "my_custom_link" : "upgradeBtn"}
            btnText={btnText}
            className={`upgradeBtn ${
              isIntercomChatPopup ? "my_custom_link" : ""
            }`}
            type="button"
            click={redirectAction}
          />
        </div>
        <div className="upgradeOverlay" onClick={closePopup} />
      </div>
    )) ||
    ""
  );
});

export default withRouter(UpgradePopup);
export { upgradePopupState };
