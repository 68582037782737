import React from "react";

const FolderAndFileLoader = props => {
  const { count } = props;
  return Array.from({ length: count }, (value, index) => (
    <div className="newTemplateFolderLoader" key={`folderLoader${index}`}>
      <span className="folderIcon linear-background" />
      <div className="folderName linear-background" />
      <div className="arrowIcon linear-background" />
    </div>
  ));
};

export default FolderAndFileLoader;
