import React from "react";
import InputTextBox from "Components/common/InputTextBox";
import Button from "Components/common/Button";
import Utils from "Utils/utils";

export const CustomRangeSelection = (props = {}) => {
  const {
    customBulkPurchaseLimit = 1000,
    range = "",
    setRange = () => {},
    err = "",
    setErr = () => {},
    customAction = () => {},
    setCustomRangeSelector = () => {}
  } = props || {};

  const handleChange = (_, value) => {
    err && setErr("");
    setRange((value && parseInt(value)) || "");
  };

  const applyRange = () => {
    if (range > 0 && range <= customBulkPurchaseLimit) {
      setCustomRangeSelector(false);
      customAction("custom", range);
    } else {
      setErr(`Maximum selection is limited to ${customBulkPurchaseLimit}`);
    }
  };

  const handleSubmit = event => {
    Utils.isSubmitKeyAction(event) && applyRange();
  };

  const customInputObject = {
    name: "customRangeInputBox",
    id: "customRangeInputBox",
    type: "number",
    minValue: 0,
    value: range,
    change: handleChange,
    cbk: handleSubmit,
    err,
    placeHolder: "Number of contact to select",
    focus: true
  };

  const buttonObject = {
    id: "applyCustomRange",
    name: "applyCustomRange",
    btnText: "Apply",
    type: "button",
    className: "applyCustomRange",
    click: applyRange
  };

  return (
    <div className="customRangeSelector">
      <div className="cntHeader">
        <i
          className="material-icons"
          onClick={() => setCustomRangeSelector(false)}
        >
          keyboard_arrow_left
        </i>
        <span className="text">Custom Selection</span>
      </div>
      <div className="cntBody">
        <InputTextBox {...customInputObject} />
        <Button {...buttonObject} />
      </div>
      <div className="cntFooter">
        <i className="material-icons">info_outline</i>
        <span className="text">
          Maximum selection is limited to {customBulkPurchaseLimit}
        </span>
      </div>
    </div>
  );
};

export default CustomRangeSelection;
