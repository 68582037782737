import React, { useEffect, useState } from "react";

const CopyText = ({ value = "" }) => {
  const [showCopied, setShowCopied] = useState(false);

  const handleCopy = () => {
    if (value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          setShowCopied(true);
          setTimeout(() => {
            setShowCopied(false);
          }, 2500);
        })
        .catch(error => {
          console.error("Error copying text to clipboard: ", error);
        });
    }
  };

  useEffect(() => {
    return () => {
      setShowCopied(false);
    };
  }, []);

  return (
    <div className="copyTextSection">
      <i className="material-icons copyIcon" onClick={handleCopy}>
        content_copy
      </i>
      {showCopied && <div className="copyToast">Copied</div>}
    </div>
  );
};

export default CopyText;
