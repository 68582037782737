import React, { useEffect, useState } from "react";
import { observable, action } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import SignupSuccess from "Assets/png/signupSuccess.png";
import ResendButton from "./Signup/ResendButton";
import userDetail, { userObj } from "Stores/userDetail";
import { loginValidate } from "Utils/commonAPI";
import { captchaStoreValue } from "Stores/captcha";
import { toasterState } from "Components/common/toaster";
import SequenceUtils from "Utils/SequenceUtils";

class EmailVerificationLoggedInState {
  @observable showPopup = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };
}

const emailVerificationLoggedInState = new EmailVerificationLoggedInState();

const EmailVerificationLoggedIn = () => {
  const [noOfClicks, setNoOfClicks] = useState(0);
  const [loader, setLoader] = useState(false);

  const resendEmailToken = async token => {
    if (noOfClicks <= 3) {
      setNoOfClicks(noOfClicks => noOfClicks + 1);
      setLoader(true);
      if (userDetail?.userInfo?.email && token) {
        const config = {
          url: `${URLS.signupVerification}/${userDetail?.userInfo?.email}/${token}`,
          method: "GET"
        };
        await makeApi(config);
      }
      await setLoader(false);
    }
  };

  const validateCaptchaFromAPi = async token => {
    token && resendEmailToken(token);
  };

  const handleResendBtnClick = async () => {
    captchaStoreValue.executeCaptcha(validateCaptchaFromAPi);
  };

  const resendBtnObj = {
    className: "editSeqOkBtn",
    handleClick: handleResendBtnClick,
    isLoggedIn: noOfClicks === 0,
    noOfClicks: noOfClicks,
    setNoOfClicks: setNoOfClicks,
    loader: loader
  };

  const loginCheck = async () => {
    await loginValidate();
  };

  const handleLogout = async () => {
    try {
      const config = {
        url: URLS.logout,
        method: "POST"
      };
      let res = await makeApi(config);
      if (res) {
        userDetail.setUserInfo(userObj);
        SequenceUtils.removeActiveSequenceList();
        document.cookie = "li=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.href = "/login";
      } else {
        toasterState.setToastMsg(
          "Sorry, there was an issue while logging out of your account. Please try again later.",
          "failure"
        );
        console.error("Salesgear Logout Failed", res);
      }
    } catch (err) {
      console.error("Salesgear Logout Failed", err);
    }
  };

  useEffect(() => {
    if (emailVerificationLoggedInState.showPopup) {
      loginCheck();
      captchaStoreValue.setCaptcha(true);
    }
  }, []);

  return (
    emailVerificationLoggedInState.showPopup && (
      <>
        <div className="emailVerificationLoggedInWrapper">
          <div className="card animated customzoomIn">
            <div className="cardBody">
              <div className="fileUploadImg">
                <img src={SignupSuccess} alt="File Exceed" />
              </div>
              <div className="textWrapper">
                <p className="message" style={{ marginBottom: "20px" }}>
                  Check your inbox and verify your email address{" "}
                  <a
                    className="emailLink"
                    href={`mailTo:${userDetail?.userInfo?.email}`}
                  >{`${userDetail?.userInfo?.email}`}</a>{" "}
                  to continue using Salesgear. If you haven't received the email
                  yet, click Resend.
                </p>
                <p className="message">
                  In case of any difficulties, please contact{" "}
                  <a className="emailLink" href={`mailto:support@salesgear.io`}>
                    support@salesgear.io
                  </a>
                  .
                </p>
              </div>
              <ResendButton {...resendBtnObj} />
              <div className="logoutButton" onClick={handleLogout}>
                Sign out
              </div>
            </div>
          </div>
        </div>
        <div className="overlayFileExceedMask" />
      </>
    )
  );
};

export { EmailVerificationLoggedIn, emailVerificationLoggedInState };
export default EmailVerificationLoggedIn;
