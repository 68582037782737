import React from "react";

const TaskDetailLoader = () => {
  return (
    <>
      {Array.from({ length: 10 }, (value, index) => (
        <div
          className="taskTableBodyLoader"
          key={`taskTableBodyLoader${index}`}
        >
          <span className="taskEmail linear-background" />
          {/* <div className="contactOwner linear-background" /> */}
          <div className="taskType flexItem">
            <span className="circle linear-background" />
            <span className="line linear-background" />
          </div>
          <div className="taskNotesWrapper">
            <div className="taskNotes linear-background"></div>
          </div>
          <div className="taskDueDate">
            <span className="linear-background" />
          </div>
        </div>
      ))}
    </>
  );
};

export { TaskDetailLoader };
export default TaskDetailLoader;
