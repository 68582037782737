import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import { PAYWALL_CONTENT } from "Model/model";
import { PIPEDRIVE_OAUTH_URL } from "Static/constant";
import PipeDriveImg from "Assets/Pricing/pipedrive.png";
import { ReactComponent as ThunderOrangeIcon } from "Assets/svg/thunderOrange.svg";
import { toasterState } from "Components/common/toaster";
import UpgradeTooltip from "Components/Paywall/UpgradeTooltip";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import FormActionButton from "../../ConnectInbox/Configure/FormActionButton";
import ConnectOrDisconnectCard from "../ConnectOrDisconnectCard";
import PipedriveTabFields from "./PipedriveTabFields";
import { tagsStoreData } from "Stores/TagsData";
import { pipedriveStore } from "./PipedriveStore";
import { MXP_EVENT } from "Static/MixPanelEvents";

const defaultFunc = () => {};
const PipedriveConfigure = observer(props => {
  const {
    hideCbk = () => {},
    isFeatureEnabled = true,
    showAdminPopup = defaultFunc,
    planName = "",
    infreePlan = false
  } = props || {};
  const {
    pipedriveData: { pipedriveConnected = false, crmSettings = {} } = {}
  } = pipedriveStore || {};
  const { instanceUrl = "" } = crmSettings || {};
  const updatedPipedriveConnected =
    pipedriveConnected && crmSettings && Object.keys(crmSettings).length > 0;

  const [removeAccountLoader, setRemoveAccountLoader] = useState(false);
  const [showUpgradeTp, setShowUpgradeTp] = useState(false);
  const [saveChangesLoader, setSaveChangesLoader] = useState(false);

  const openPipedriveOauthWindow = () => {
    if (PIPEDRIVE_OAUTH_URL) {
      const w = 600;
      const h = 800;
      const left = window.screen.width / 2 - w / 2;
      const top = window.screen.height / 2 - h / 2;
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
      window.open(PIPEDRIVE_OAUTH_URL, "Pipedrive Login", params);
      const oauthCbk = async ev => {
        if (ev?.data?.type === "PIPEDRIVE_CONNECT_OAUTH") {
          window.removeEventListener("message", oauthCbk);
          if (ev?.data?.code?.toString() === "200") {
            toasterState.setToastMsg(
              "Pipedrive account connected successfully",
              "success"
            );
            Utils.mixpanelTrack(MXP_EVENT.CRM_CONNECTED, {
              type: "Pipedrive"
            });
            pipedriveStore.getPipedriveData();
          } else {
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
        }
      };
      window.addEventListener("message", oauthCbk);
    }
  };

  const removeAccount = () => {
    const cbk = async () => {
      setRemoveAccountLoader(true);
      await pipedriveStore.removePipedriveAccount();
      setRemoveAccountLoader(false);
    };

    if (Utils.isAdmin()) {
      confirmationPopupState.setPopupValues({
        content: "Are you sure you want to disconnect your Pipedrive CRM?",
        actionBtnText: "Yes",
        cancelBtnText: "No",
        callback: () => {
          cbk();
        }
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      showAdminPopup("pipedrive");
    }
  };

  const makeAction = () => {
    !updatedPipedriveConnected ? openPipedriveOauthWindow() : removeAccount();
  };

  const removeUnwantedFields = (config = {}, setConfig = () => {}) => {
    const fields = [...config?.fields];
    fields?.length > 0 &&
      fields.map((item, index) => {
        if (!item?.sgField && !item?.crmField) {
          fields.splice(index, 1);
        }
        return fields;
      });
    setConfig({ ...config, fields });
    return {
      ...config,
      fields
    };
  };

  const constructPipedriveConfig = (isFromSave = false) => {
    const extractedContactTags =
      Utils.extractTagNameFromList(pipedriveStore.pipedriveContactTags) || [];
    const updatedPipedriveContactConfig = isFromSave
      ? removeUnwantedFields(
          pipedriveStore?.pipedriveContactConfig,
          pipedriveStore.setPipedriveContactConfig
        )
      : pipedriveStore?.pipedriveContactConfig;
    const payload = {
      config: {
        contact: {
          ...updatedPipedriveContactConfig,
          tags: extractedContactTags
        }
      },
      owner: pipedriveStore?.pipedriveOwnerConfig,
      preference: pipedriveStore?.pipedrivePreferences
    };
    return payload;
  };

  const savePipedriveConfigure = async () => {
    if (Utils.isAdmin()) {
      const saveChangesBtnDisabled =
        !(updatedPipedriveConnected && isFeatureEnabled) ||
        pipedriveStore.disableSaveButton;
      if (!saveChangesBtnDisabled) {
        setSaveChangesLoader(true);
        const payload = constructPipedriveConfig(true);
        try {
          const config = {
            url: URLS.updatePipedriveConfig,
            method: "POST",
            data: payload
          };
          let res = await makeApi(config);
          if (res?.data) {
            tagsStoreData.getAllTags();
            pipedriveStore.setPipedriveData(res?.data);
            setSaveChangesLoader(false);
            toasterState.setToastMsg(
              "Pipedrive Integration settings updated successfully.",
              "success"
            );
          } else {
            Utils.showApiFailureResponse(res);
          }
        } catch (err) {
          console.error("Pipedrive save error", err);
        }
      }
    } else {
      showAdminPopup();
    }
  };

  useEffect(() => {
    const {
      config: { contact = {} } = {},
      owner = [],
      syncClickEnabled = false,
      syncOpenEnabled = false,
      syncPhoneCallEnabled = true,
      syncReplyEnabled = false,
      syncSentEnabled = false,
      syncLiAcceptRequest = false,
      syncLiSendRequest = false,
      syncLiMessageSent = false,
      syncLiReplyReceived = false,
      syncCustomTask = false
    } = crmSettings || {};
    if (contact && Object.keys(contact).length > 0) {
      const contactConfig = Utils.createObjWithoutRef(contact);
      pipedriveStore.setPipedriveContactConfig(contactConfig);
    }
    pipedriveStore.setPipedriveOwnerConfig(owner);
    pipedriveStore.setPipedrivePreferences({
      syncClickEnabled,
      syncOpenEnabled,
      syncPhoneCallEnabled,
      syncReplyEnabled,
      syncSentEnabled,
      syncLiAcceptRequest,
      syncLiSendRequest,
      syncLiMessageSent,
      syncLiReplyReceived,
      syncCustomTask
    });
  }, [crmSettings?.config]);

  return (
    <div className="crmConfigurePage pipedrive">
      <ConnectOrDisconnectCard
        type="Pipedrive"
        icon={PipeDriveImg}
        addOrRemoveAccount={makeAction}
        connectedEmail={instanceUrl?.replace("https://", "")}
        isConnected={updatedPipedriveConnected}
        removeLoader={removeAccountLoader}
      />
      {updatedPipedriveConnected && (
        <div
          className="activityLogWrapper"
          onMouseEnter={() => !isFeatureEnabled && setShowUpgradeTp(true)}
          onMouseLeave={() => !isFeatureEnabled && setShowUpgradeTp(false)}
        >
          <PipedriveTabFields
            className={`${!isFeatureEnabled ? "disabledConfigure" : ""}`}
            crmSettings={crmSettings}
            constructPipedriveConfig={constructPipedriveConfig}
          />
          {!isFeatureEnabled && <ThunderOrangeIcon className="thunderOrange" />}

          {showUpgradeTp &&
            !isFeatureEnabled &&
            (infreePlan ||
              ["pro", "basic"].includes(planName?.toLowerCase())) && (
              <UpgradeTooltip
                {...PAYWALL_CONTENT[
                  infreePlan || planName?.toLowerCase() === "basic"
                    ? "pipedriveSettings"
                    : "pipedriveSettingsUnlimited"
                ]}
              />
            )}
        </div>
      )}
      <FormActionButton
        cancel={() => hideCbk()}
        save={savePipedriveConfigure}
        saveBtnText="Save changes"
        loader={saveChangesLoader}
        disabled={
          !(updatedPipedriveConnected && isFeatureEnabled) ||
          pipedriveStore.disableSaveButton
        }
      />
    </div>
  );
});

export { PipedriveConfigure };
export default PipedriveConfigure;
