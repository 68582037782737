import React from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import Button from "Components/common/Button";

class EditSequencePopupState {
  @observable showPopup = false;
  @observable messageList = "";
  @observable totalContacts = 0;
  @observable image = "";
  @observable pendingContacts = 0;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setMessage = val => {
    this.messageList = val;
  };

  @action
  setTotalContacts = val => {
    this.totalContacts = val;
  };

  @action
  setPendingContacts = val => {
    this.pendingContacts = val;
  };

  @action
  setImage = val => {
    this.image = val;
  };

  @action
  setPendingContacts = val => {
    this.pendingContacts = val;
  };
}

const editSequencePopupState = new EditSequencePopupState();

const EditSequencePopup = () => {
  const closePopup = () => {
    editSequencePopupState.setShowPopup(false);
  };
  return (
    editSequencePopupState.showPopup && (
      <>
        <div className="editSequencePopupWrapper">
          <div className="card animated customzoomIn">
            <div className="cardBody">
              <div className="fileUploadImg">
                <img src={editSequencePopupState.image} alt="File Exceed" />
              </div>
              <div className="textWrapper">
                <p
                  className="message"
                  dangerouslySetInnerHTML={{
                    __html: editSequencePopupState.messageList
                  }}
                />
              </div>
              <Button
                name="editSeqOkBtn"
                btnText="Ok"
                icon={false}
                iconName=""
                type="button"
                className="editSeqOkBtn"
                loader={false}
                click={closePopup}
              />
            </div>
          </div>
        </div>
        <div className="overlayFileExceedMask" />
      </>
    )
  );
};

export default observer(EditSequencePopup);
export { EditSequencePopup, editSequencePopupState };
