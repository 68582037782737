/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation, withRouter } from "react-router-dom";
import { reportStore } from "Stores/Reports";
import { userDetail } from "Stores/userDetail";
import SequenceUtils from "Utils/SequenceUtils";
import { toasterState } from "Components/common/toaster";
import PaginationComponent from "Components/common/PaginationComponent";
import EmptyReport from "./EmptyReport";
import ReportsTableLoader from "./ReportsTableLoader";
import NoActivityFound from "./NoActivityFound";
import Utils from "Utils/utils";

const tableHeaderList = [
  {
    name: "Sequence Name",
    id: "sequenceName",
    className: "sequenceName",
    sort: false
  },
  {
    name: "Contacts Added",
    id: "contactsAdded",
    className: "contactsAdded",
    sort: false,
    sortKey: "CONTACTS_ADDED"
  },
  {
    name: "Emails Sent",
    id: "emailsSent",
    className: "emailsSent",
    sort: true,
    sortKey: "SENT_COUNT"
  },
  {
    name: "Opens",
    id: "openRate",
    className: "openRate",
    sort: true,
    sortKey: "OPEN_COUNT"
  },
  {
    name: "Clicks",
    id: "clickRate",
    className: "clickRate",
    sort: true,
    sortKey: "CLICK_COUNT"
  },
  {
    name: "Replies",
    id: "replyRate",
    className: "replyRate",
    sort: true,
    sortKey: "REPLY_COUNT"
  },
  {
    name: "Bounces",
    id: "bouncesCount",
    className: "bouncesCount",
    sort: true,
    sortKey: "BOUNCE_COUNT"
  },
  {
    name: "Tasks Completed",
    id: "tasksCompleted",
    className: "tasksCompleted",
    sort: false
  }
];

const ReportSequenceInsights = observer(props => {
  let location = useLocation();

  const { calendarEnabled = false, email: userEmail = "" } =
    userDetail?.userInfo || {};

  let {
    noOfContactsAddedInTeam: contactAdded = 0,
    calendarIntegrationEnabled = false
  } = userDetail?.userFeatureFlag || {};

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [sortParam, setSortParam] = useState(
    reportStore?.sortParam || "SENT_COUNT"
  );
  const [sortType, setSortType] = useState("DESC");

  const constructData = () => {
    if (reportStore?.sequenceInsightData) {
      const {
        totalPages: total,
        currentPage: page,
        result
      } = reportStore.sequenceInsightData;
      setTotalPages(total || 0);
      setCurrentPage(page || 0);
      setData(result);
      setLoader(false);
      reportStore.setSequenceInsightLoader(false);
    }
  };

  const paginate = (pageNo, limit) => {
    reportStore.setCurrentPage(pageNo.selected + 1);
  };

  const hasActivityCount = () => {
    return (
      data?.length &&
      data.filter(
        item =>
          item.contactsAdded > 0 ||
          item.emailsSent > 0 ||
          item.emailsClicked > 0 ||
          item.emailsOpened > 0 ||
          item.emailsBounced > 0 ||
          item.noOfCampaignsGotReply > 0 ||
          item.taskCompleted > 0 ||
          item.meetingBookedCount > 0
      ).length
    );
  };

  const sortSequenceInsight = key => {
    if (data?.length) {
      reportStore.setCurrentPage(0);
      let direction = sortParam === key && sortType === "DESC" ? "ASC" : "DESC";
      reportStore.setSortObj({
        sortParam: key,
        direction
      });
      setSortParam(key);
      setSortType(direction);
    }
  };

  const hasTableData = () => {
    return !loader && data?.length && hasActivityCount();
  };

  const redirectToSequence = (seqId, folderId, sharedType, email) => {
    userEmail === email || sharedType
      ? props.history.push(
          `/sequences/${
            sharedType && userEmail !== email ? "shared" : folderId || "all"
          }/${seqId}`
        )
      : toasterState.setToastMsg(
          "Sorry, you cannot access this private sequence",
          "fail"
        );
  };

  useEffect(() => {
    constructData();
  }, [reportStore.sequenceInsightData]);

  useEffect(() => {
    setLoader(reportStore.sequenceInsightLoader);
  }, [reportStore.sequenceInsightLoader]);

  useEffect(() => {
    setLoader(true);
    reportStore.setSequenceInsightLoader(true);
  }, [location?.pathname]);

  return (
    <div className="sequenceInsights">
      <h4>Sequence Insights</h4>
      <div className="reportsSeqInsightsTableSection">
        <div className="tableHeader">
          {tableHeaderList.map(item => {
            const { className, id, name, sort, sortKey } = item || {};
            return (
              <div
                className={`${className} headerName`}
                key={`${id}TableHeader`}
              >
                <span className="name">{name}</span>
                {(sort && (
                  <i
                    className={`material-icons sortIcon ${
                      sortParam === sortKey
                        ? `activeSort ${sortType === "ASC" ? "ascending" : ""}`
                        : ""
                    } ${data?.length ? "" : "pointerUserSelectNone"}`}
                    onClick={() => sortSequenceInsight(sortKey)}
                  >
                    sort
                  </i>
                )) ||
                  ""}
              </div>
            );
          })}
        </div>
        <div className="tableBody">
          {(hasTableData() &&
            data.map((item, index) => {
              const {
                contactsAdded = 0,
                emailsSent = 0,
                emailsOpened = 0,
                emailsClicked = 0,
                noOfCampaignsGotReply = 0,
                emailsBounced = 0,
                meetingBookedCount = 0,
                taskCompleted = 0,
                sequenceShareType = "",
                firstName = "",
                lastName = "",
                campaignId = "",
                folderId = "",
                sequenceName = "",
                email = ""
              } = item || {};

              let sharedType = sequenceShareType === "SHARED";
              return (
                <div className="tableRow" key={`sequenceInsight${index}`}>
                  <div className="sequenceName">
                    <span
                      className={`name ${sharedType ? "sharedText" : ""}`}
                      title={sequenceName}
                      onClick={() =>
                        redirectToSequence(
                          campaignId,
                          folderId,
                          sharedType,
                          email
                        )
                      }
                    >
                      {sequenceName}
                    </span>
                    {sharedType && <span className="shared">shared</span>}
                    {SequenceUtils.getOwnerName({ firstName, lastName, email })}
                  </div>
                  <div className="contactsAdded">
                    {Utils.formatNumberToStringInUSFormat(contactsAdded)}
                  </div>
                  <div className="emailsSent">
                    {Utils.formatNumberToStringInUSFormat(emailsSent)}
                  </div>
                  <div className="openRate">
                    {emailsOpened
                      ? Utils.getPercentage(emailsOpened, emailsSent, 1) + "%"
                      : "0%"}
                  </div>
                  <div className="clickRate">
                    {emailsClicked
                      ? Utils.getPercentage(emailsClicked, emailsSent, 1) + "%"
                      : "0%"}
                  </div>
                  <div className="replyRate">
                    {noOfCampaignsGotReply
                      ? Utils.getPercentage(
                          noOfCampaignsGotReply,
                          emailsSent,
                          1
                        ) + "%"
                      : "0%"}
                  </div>
                  <div className="bouncesCount">
                    {Utils.formatNumberToStringInUSFormat(emailsBounced)}
                  </div>
                  <div className="tasksCompleted">
                    {Utils.formatNumberToStringInUSFormat(taskCompleted)}
                  </div>
                </div>
              );
            })) ||
            ""}
          {loader && (
            <ReportsTableLoader
              calendarEnabled={calendarEnabled && calendarIntegrationEnabled}
              count={10}
              isInsight={true}
            />
          )}
          {!loader && !contactAdded && (
            <EmptyReport
              pathname={location?.pathname}
              sequenceInsight={true}
              className="emptySequenceInsight"
            />
          )}
          {(!loader && contactAdded && !hasActivityCount() && (
            <NoActivityFound />
          )) ||
            ""}
        </div>
        <PaginationComponent
          callback={paginate}
          loading={loader}
          totalPages={totalPages}
          currentPage={currentPage || 1}
        />
      </div>
    </div>
  );
});

export default withRouter(ReportSequenceInsights);
export { ReportSequenceInsights };
