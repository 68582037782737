import React from "react";
import Utils from "Utils/utils";
import { observer } from "mobx-react";

const AnalyticsCard = observer((props = {}) => {
  const { cardName = "", cardCount = 0, dataArr = [] } = props || {};

  return (
    <div className="analyticsCard">
      <div className="cardHeader">
        <span className="cardName">{cardName}</span>
        <span className="totalNo">
          {Utils.formatNumberToStringInUSFormat(cardCount)}
        </span>
      </div>
      <div className="detailedAnalyticsSection">
        <div className="detailedAnalyticsCont">
          {dataArr?.length > 0 &&
            dataArr.map((item, index) => {
              const { name = "", count = 0, color = "" } = item || {};
              return (
                <div className="nameAndCount">
                  <div className="name">{name}</div>
                  <div className="count" style={{ color }}>
                    {Utils.formatNumberToStringInUSFormat(count)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
});

const AnalyticsCardLoader = () => {
  return Array.from({ length: 2 }).map((_, index) => (
    <div key={index} className="analyticsCard">
      <div className="cardHeader">
        <span className="cardNameLoader linear-background"></span>
        <span className="totalNoLoader linear-background"></span>
      </div>
      <div className="detailedAnalyticsSection">
        <div className="detailedAnalyticsCont">
          {Array.from({ length: 3 }).map((_, innerIndex) => (
            <div key={innerIndex} className="nameAndCount">
              <div className="nameLoader linear-background"></div>
              <div className="countLoader linear-background"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ));
};

export default AnalyticsCard;
export { AnalyticsCard, AnalyticsCardLoader };
