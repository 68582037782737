import React, { useEffect } from "react";
import DoNotContactDomainSearchBar from "./DoNotContactDomainSearchBar";
import userDetail from "Stores/userDetail";
import { PaywallUtils } from "Utils/PaywallUtils";

const DoNotContactDomain = () => {
  const {
    inFreePlan = false,
    planName = "",
    donotContactEnabled = false
  } = userDetail?.userFeatureFlag || {};

  useEffect(() => {
    if (!donotContactEnabled) {
      PaywallUtils.upgradeUtilsFunction(
        "doNotContact",
        false,
        planName,
        inFreePlan
      );
    }
  }, []);
  return (
    <div
      style={{ height: "100%" }}
      className={` ${!donotContactEnabled ? "blurBg" : ""}`}
    >
      <section className="contactStagesWrapper">
        <div className="contactStagesCont">
          <div className="headerCont">
            <div className="headerTitle" style={{ fontSize: "16px" }}>
              Do Not Contact Domains
            </div>
            <div className="infoText">
              Add a list of email domains that you want to prevent your team
              from contacting.
            </div>
          </div>
          <DoNotContactDomainSearchBar />
        </div>
      </section>
    </div>
  );
};

export default DoNotContactDomain;
