/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { URLS, makeApi } from "Utils/apiURL";
import JumpBallLoader from "Components/common/JumpBallLoader";
import { userDetail } from "Stores/userDetail";

const TeamMemberInvite = props => {

  let { id } = useParams();
  const [loading, setLoading] = useState(true);

  const getFullName = (firstName, lastName, email) => {
    if (
      firstName !== null &&
      lastName !== null &&
      firstName !== undefined &&
      lastName !== undefined &&
      firstName !== "" &&
      lastName !== ""
    ) {
      return `${firstName} ${lastName}`;
    } else {
      return email;
    }
  };

  const validateTeamInviteToken = async () => {
    const config = {
      url: URLS.validateInviteToken,
      method: "POST",
      data: { inviteToken: id }
    };
    let res = await makeApi(config);
    if (res && res.data && res.data !== "") {
      const {
        valid,
        newUser,
        email,
        firstName,
        lastName,
        adminFirstName,
        adminLastName,
        adminEmail
      } = res.data;
      if (valid && adminEmail) {
        let name = getFullName(firstName, lastName, email);
        let adminName = getFullName(adminFirstName, adminLastName, adminEmail);
        let teamInvite = {
          name,
          email,
          adminName,
          adminEmail,
          inviteToken: id,
          newUser
        };
        localStorage.setItem("inviteTeam", JSON.stringify(teamInvite));
        userDetail.setUserTeamInfo(teamInvite);
        if (newUser) {
          setLoading(false);
          props.history.push("/teamInvite/signup");
        } else {
          setLoading(false);
          props.history.push("/teamInvite/login");
        }
      } else {
        setLoading(false);
        props.history.push("/login");
      }
    } else {
      setLoading(false);
      props.history.push("/login");
    }
  };

  useEffect(() => {
    validateTeamInviteToken();
  }, []);

  return (
    <div className="teamInviteSection">
      {loading && <JumpBallLoader />}
    </div>
  );
};

export default withRouter(observer(TeamMemberInvite));
