import React, { useState } from "react";
import { observer } from "mobx-react";
import CallsUtils from "Utils/CallsUtils";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import { callsStore } from "Stores/Calls";
import { ReactComponent as LogCallIcon } from "Assets/svg/callLog.svg";
import DialerLogsAfterCallEndPopup, {
  dialerLogsPopupState
} from "Components/DailerService/DialerLogsAfterCallEndPopup";
import CustomTooltip from "Components/common/CustomTooltip";

const BulkLogCalls = observer((props = {}) => {
  const {
    selectedLogIds = 0,
    allLogsSelected = false,
    loader = false,
    resetCbk = () => {}
  } = props || {};

  const [logBtnLoader, setLogBtnLoader] = useState(false);

  let tooltipText =
    selectedLogIds?.length > 0
      ? "Log calls"
      : "Select one or more calls to log";

  const [showTooltip, setShowTooltip] = useState(false);

  const openLogCallsPopup = () => {
    if (selectedLogIds?.length > 0) {
      showTooltip && setShowTooltip(false);
      dialerLogsPopupState.setTotalContacts(selectedLogIds?.length);
      dialerLogsPopupState.setActionType("BULK_CONTACTS");
      dialerLogsPopupState.setShowLogCallPopup(true);
    }
  };

  const constructPayload = (inputObj = {}) => {
    const { callDispositionId = "", notes = "" } = inputObj || {};
    let filterObj = CallsUtils.getPayload(callsStore.filterPayload);
    const {
      fromDate = "",
      timeZone = "",
      to: toDate = "",
      memberIds = []
    } = filterObj?.filter || {};
    const payload = {
      filter: {
        fromDate,
        timeZone,
        toDate,
        all: allLogsSelected
      },
      notes,
      dispositionIdToUpdate: callDispositionId
    };
    if (memberIds?.length) {
      payload.filter.memberIds = memberIds;
    }
    if (!allLogsSelected) {
      payload.filter.ids = selectedLogIds;
    }
    return payload;
  };

  const handleReponseMessage = (
    isUpdated = false,
    totalCount = 0,
    successCount = 0,
    failedCount = 0
  ) => {
    if (isUpdated && successCount > 0) {
      if (successCount > 0 && failedCount <= 0) {
        toasterState.setToastMsg(
          `${successCount} call${
            successCount > 0 ? "s" : ""
          } logged successfully`,
          "success"
        );
      } else if (failedCount > 0) {
        toasterState.setToastMsg(
          `${successCount} call${
            successCount > 0 ? "s" : ""
          } logged successfully. Failed to log ${failedCount} call${
            failedCount > 0 ? "s" : ""
          }. Try again later.`,
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "fail"
        );
      }
    } else {
      toasterState.setToastMsg(
        `Oops! Failed to log ${totalCount} call${
          totalCount > 0 ? "s" : ""
        }. Try again later.`
      );
    }
  };

  const handleBulkLogCallAction = async (type = "", phoneCall = {}) => {
    if (selectedLogIds?.length > 0) {
      setLogBtnLoader(true);
      const config = {
        url: URLS.bulkLogCalls,
        method: "POST",
        data: constructPayload(phoneCall)
      };
      let res = await makeApi(config);
      if (
        res?.data &&
        res?.status === 200 &&
        res?.data?.status?.toLowerCase() === "success"
      ) {
        const {
          status = "",
          successCount = 0,
          totalCount = 0
        } = res?.data || {};
        let failedCount = totalCount - successCount;
        let isUpdated = status === "success";
        setLogBtnLoader(false);
        dialerLogsPopupState.setShowLogCallPopup(false);
        resetCbk(isUpdated);
        handleReponseMessage(isUpdated, totalCount, successCount, failedCount);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "fail"
        );
      }
    }
  };

  return (
    <>
      {dialerLogsPopupState?.showLogCallPopup && (
        <DialerLogsAfterCallEndPopup
          submitCallback={handleBulkLogCallAction}
          type="bulkCallLogs"
          logBtnLoader={logBtnLoader}
        />
      )}
      <div
        className={`bulkLogCalls ${
          selectedLogIds?.length > 0 ? "active" : ""
        } ${loader ? "loading" : ""}`}
        onClick={openLogCallsPopup}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <LogCallIcon />
        {showTooltip && (
          <CustomTooltip
            text={tooltipText}
            className={selectedLogIds?.length > 0 ? "minContent" : "maxContent"}
          />
        )}
      </div>
    </>
  );
});

export default BulkLogCalls;
