import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { ReactComponent as DeleteIcon } from "Assets/svg/deleteIcon.svg";
import contactStagesState from "./state";
import { createContactStagePopupState } from "./CreateContactStagePopup";
import userDetail from "Stores/userDetail";
import CustomTooltip from "Components/common/CustomTooltip";
import PaywallUtils from "Utils/PaywallUtils";

const ContactStageCardListLoader = ({ length = 9 }) => {
  return Array.from({ length }, (value, index) => (
    <div className="contactStageCard contactStageCardLoader" key={index}>
      <div className="contactStageName  linear-background" />
      <div className="actionIcons">
        {index >= 2 && <div className="iconBackground linear-background" />}
        <div className="iconBackground linear-background" />
      </div>
    </div>
  ));
};

const InputWithCloseIcon = observer(props => {
  const { stageId = "", stageName = "" } = props || {};
  const [updatedStageName, setUpdatedStageName] = useState(stageName);

  const saveStageName = (event, stageId) => {
    if (updatedStageName) {
      Utils.preventDefaultFn(event);
      contactStagesState.setCurrentEditedFieldLoader(true);
      contactStagesState.updateStageName(stageId, updatedStageName);
    } else {
      confirmationPopupState.setPopupValues({
        content: `Please enter a call state name`,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => {
          confirmationPopupState.setShowPopup(false);
        }
      });
      confirmationPopupState.setShowPopup(true);
    }
  };

  const handleStageNameChange = e => {
    Utils.preventDefaultFn(e);
    setUpdatedStageName(e?.currentTarget?.value);
  };

  const handleSubmitName = (event, stageId) => {
    if (Utils.isSubmitKeyAction(event)) {
      saveStageName(event, stageId);
    }
  };

  const inputProps = {
    className: "editStageName",
    value: updatedStageName,
    onChange: handleStageNameChange,
    onKeyUp: e => handleSubmitName(e, stageId),
    autoFocus: true
  };

  return (
    <div className={`inputWithCloseIcon`}>
      {contactStagesState.currentEditedFieldLoader ? (
        <div className="contactStageCardLoader">
          <div className="contactStageName linear-background" />
        </div>
      ) : (
        <input {...inputProps} />
      )}

      <span className="iconBackground">
        {!contactStagesState.currentEditedFieldLoader && (
          <i
            className="material-icons closeIcon"
            onClick={e => {
              Utils.preventDefaultFn(e);
              contactStagesState.setCurrentEditedField("");
            }}
          >
            close
          </i>
        )}
      </span>
    </div>
  );
});

const ActionIcons = observer((props = {}) => {
  const {
    id = "",
    name = "",
    displayName = "",
    displayEnabled = false,
    deleteAllowed = false
  } = props || {};

  const { team = "" } = userDetail?.userInfo || {};

  const restrictActions = ["OPTED_OUT", "BOUNCED", "DO_NOT_CONTACT"].includes(
    name?.toUpperCase()
  );

  const [showVisibilityTooltip, setShowVisibilityTooltip] = useState(false);
  const [showDeleteTooltip, setShowDeleteTooltip] = useState(false);
  const [showEditTooltip, setShowEditTooltip] = useState(false);

  const handleDeleteIcon = (stageName, stageId) => {
    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to delete ${stageName} stage? Contacts in this stage will be categorized as 'No stage'.`,
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      loadingMsg: "Please wait...",
      callback: () => {
        contactStagesState.deleteAction(stageName, stageId);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  const editAction = (name = "") => {
    contactStagesState.setCurrentEditedField(name?.trim());
  };

  return (
    <div className="actionIcons">
      {deleteAllowed && team && (
        <span
          className="iconBackground deleteIcon"
          onMouseOver={() => setShowDeleteTooltip(true)}
          onMouseLeave={() => setShowDeleteTooltip(false)}
          onClick={() => handleDeleteIcon(displayName, id)}
        >
          <DeleteIcon />
          {showDeleteTooltip && <CustomTooltip text={"Delete stage"} />}
        </span>
      )}
      {team && (
        <span
          className={`iconBackground ${
            restrictActions ? "cursorNotAllowed" : ""
          }`}
          onClick={() => !restrictActions && editAction(name)}
          onMouseOver={() => !restrictActions && setShowEditTooltip(true)}
          onMouseLeave={() => !restrictActions && setShowEditTooltip(false)}
        >
          <i className="material-icons renameIcon">drive_file_rename_outline</i>
          {showEditTooltip && <CustomTooltip text={"Edit stage"} />}
        </span>
      )}
      <span
        className={`iconBackground ${
          restrictActions ? "cursorNotAllowed" : ""
        }`}
        onMouseOver={() => !restrictActions && setShowVisibilityTooltip(true)}
        onMouseLeave={() => !restrictActions && setShowVisibilityTooltip(false)}
        onClick={() =>
          !restrictActions &&
          contactStagesState.updateVisibility(id, displayName, displayEnabled)
        }
      >
        <i
          className={`material-icons-outlined visibilityIcon ${
            displayEnabled ? "visible" : ""
          }`}
        >
          {displayEnabled ? "visibility" : "visibility_off"}
        </i>
        {showVisibilityTooltip && (
          <CustomTooltip
            className="visibilityFix"
            text={
              displayEnabled
                ? "Visible on contacts page"
                : "Not visible on contacts page"
            }
          />
        )}
      </span>
    </div>
  );
});

const CreateNewStage = observer(() => {
  const {
    inFreePlan = false,
    planName = "",
    contactStagesEnabled = false
  } = userDetail?.userFeatureFlag || {};

  const { team = "" } = userDetail?.userInfo || {};

  const handleCreateNewStage = () => {
    if (!contactStagesEnabled) {
      PaywallUtils.upgradeUtilsFunction(
        "contactStages",
        false,
        planName,
        inFreePlan
      );
    } else {
      createContactStagePopupState.setShowPopup(true);
    }
  };

  return (
    team && (
      <div
        className="contactStageCard createNewStageCard"
        onClick={handleCreateNewStage}
      >
        <div className="contactStageName">Create new stage</div>
        <span className="iconBackground">
          <i className="material-icons">add</i>
        </span>
      </div>
    )
  );
});

const ContactStageCardList = observer(() => {
  useEffect(() => {
    contactStagesState.getContactStageData();
  }, []);

  return (
    <section className="contactStagesWrapper">
      <div className="contactStagesCont">
        <div className="headerCont">
          <div className="headerTitle">Contact Stages</div>
        </div>
        <div className="contactStageContentBody">
          <div className="contactStageCardList">
            {contactStagesState?.contactStageDataLoading ? (
              <ContactStageCardListLoader length={9} />
            ) : (
              <>
                {contactStagesState?.contactStageData?.length > 0 &&
                  contactStagesState?.contactStageData.map(item => {
                    const {
                      id = "",
                      name = "",
                      displayName = "",
                      editable = false,
                      deleteAllowed = false,
                      displayEnabled = false
                    } = item || {};
                    return (
                      <div
                        key={id}
                        className={`contactStageCard ${
                          !editable && !deleteAllowed ? "nonEditable" : ""
                        } ${!displayEnabled ? "nonActive" : ""}`}
                      >
                        {contactStagesState?.currentEditedField === name ? (
                          <InputWithCloseIcon
                            stageId={id}
                            stageName={displayName}
                          />
                        ) : (
                          <>
                            <span className="contactStageName">
                              {displayName}
                            </span>
                            <ActionIcons {...item} />
                          </>
                        )}
                      </div>
                    );
                  })}
                {contactStagesState?.contactStageSingleDataLoading && (
                  <ContactStageCardListLoader length={1} />
                )}
                <CreateNewStage />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
});

export { ContactStageCardList };
export default ContactStageCardList;
