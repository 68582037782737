import React, { useState, useRef, useEffect } from "react";
import InputTextBox from "Components/common/InputTextBox";
import { toasterState } from "Components/common/toaster";
import { MP_EVENT } from "Static/constant";
import { Utils } from "Utils/utils";

const ApiKeyWithCopyButton = props => {
  const { apiKey = "", className = "" } = props;
  const copyInputRef = useRef(null);
  const [showApiKey, setShowApiKey] = useState(false);
  const [maskedApiKey, setMaskedApiKey] = useState("");

  const toggleShowApiKey = () => {
    setShowApiKey(!showApiKey);
    maskApiKey(!showApiKey);
  };

  const copyToClipboard = () => {
    Utils.copyToClipboard(apiKey);
    Utils.mixpanelTrack(MP_EVENT.ZAPIER_COPY_CLICKED, {
      pageType: Utils.getPageType() || ""
    });
    toasterState.setToastMsg(
      "API key has been copied successfully.",
      "success"
    );
  };

  const maskApiKey = (showApiKey = true) => {
    let str = apiKey;
    str = showApiKey ? str : str?.replace(/.(?=.{4})/g, "*");
    setMaskedApiKey(str);
  };

  useEffect(() => {
    apiKey && maskApiKey(showApiKey);
  }, [apiKey]);

  return (
    <div className={`apiKeyWrapper ${className}`}>
      <div className="apiKeyContainer">
        <InputTextBox
          name="apiKey"
          id="apiKey"
          text="API Key"
          type="text"
          value={maskedApiKey || ""}
          err=""
          readOnly={true}
          inputBoxRef={copyInputRef}
        />
        <i className="material-icons visibilityIcon" onClick={toggleShowApiKey}>
          {showApiKey ? "visibility" : "visibility_off"}
        </i>
        <i className="material-icons copyIcon" onClick={copyToClipboard}>
          content_copy
        </i>
      </div>
    </div>
  );
};

export { ApiKeyWithCopyButton };
export default ApiKeyWithCopyButton;
