/* eslint-disable array-callback-return */
import React from "react";
import ReactTelephoneInput from "react-telephone-input";
import "react-telephone-input/css/default.css";
import Flags from "Assets/png/flags.png";
import { userDetail } from "Stores/userDetail";

const PhoneNumberInput = props => {
  const {
    setPhNumber,
    setExtensionCode,
    extensionCode,
    allowExtension = false,
    inputProps = { autoFocus: false }
  } = props;

  const handleInputChange = telNumber => {
    setPhNumber(telNumber);
  };

  return (
    <div className="telephonicInputCont">
      <ReactTelephoneInput
        defaultCountry={userDetail?.defaultUserCountry || "us"}
        flagsImagePath={Flags}
        onChange={handleInputChange}
        inputProps={inputProps}
      />
      {allowExtension && (
        <Extension
          setExtensionCode={setExtensionCode}
          extensionCode={extensionCode}
        />
      )}
    </div>
  );
};

const Extension = props => {
  const { setExtensionCode, extensionCode } = props;

  const changeInputVal = e => {
    if (e) {
      const { value } = e.currentTarget;
      setExtensionCode(value);
    }
  };

  return (
    <div className="extensionWrapper">
      <div className="formInputGroupd">
        <input
          id="ext"
          placeholder="Ext"
          name="ext"
          className="inputBox"
          type="text"
          onChange={e => changeInputVal(e)}
          value={extensionCode}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
export { PhoneNumberInput };
