import React from "react";
import noActivityFoundImg from "../images/png/noActivityFound.png";

const NoActivityFound = () => {
  return (
    <div className="noActivityFound">
      <img src={noActivityFoundImg} alt="No activity found" />
      <span className="noActivityFoundText">
        No activity yet. Keep track of contacts who open your email, click,
        reply, or bounce
      </span>
    </div>
  );
};

export { NoActivityFound };
export default NoActivityFound;
