import { observable, action } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import DateUtils from "Utils/DateUtils";
import PaywallUtils from "Utils/PaywallUtils";
import InboxUtils from "Utils/InboxUtils";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import DriverMaster from "Components/driver/Driver";
import { initialWelcomePopupState } from "Components/common/InitialWelcomePopup";
import {
  AUTH_CLIENT_ID,
  AUTH_SCOPES,
  AUTH_SCOPES_CALENDAR,
  AUTH_REDIRECT_URL,
  OUTLOOK_CLIENT_ID,
  OUTLOOK_REDIRECT_URL
} from "Static/constant";
import { successPopupState } from "Components/common/SuccessPopup";
import { gmailMessagePopupState } from "./GmailMessagePopup";
import { googleOAuthPopupState } from "./googleOAuthPopup";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { getFeatureFlagWithTeam, fetchConnectedEmails } from "Utils/commonAPI";
import calendarState from "../Calendar/State";

let networkRequest = {
  searchInbox: null
};

const setNetworkRequest = (id = "") => {
  if (id && networkRequest?.[id] !== null) {
    networkRequest[id]("cancel");
    networkRequest[id] = null;
  }
};

const cancelReqCallback = (id = "", value = "") => {
  id && (networkRequest[id] = value);
};

class ConnectedInboxStore {
  @observable accountData = [];
  @observable loading = true;
  @observable warmupSettings = {};
  @observable warmupLoader = {};
  @observable deliverabilityStats = {};
  @observable activeDateTab = "today";
  @observable dateFilter = DateUtils.getMonthTimeStamp();
  @observable warmingFilter = {};
  @observable filterChanged = false;
  @observable isInboxConnected = false;
  @observable pageNo = 1;
  @observable pageLimit = 10;
  @observable totalPages = 1;
  @observable totalCount = 0;
  @observable searchTerm = "";
  @observable firstTimeEnableWarming = false;
  @observable showSmtpViaWarming = false;
  @observable warmingLoaderId = "";
  @observable emailwarmingToogle = "";
  @observable inboxScreentype = "one";
  @observable inboxList = [];
  @observable loading = true;
  @observable membersFilter = {};

  @action
  setInboxList = (val = []) => {
    this.inboxList = val;
  };

  @action
  setLoading = (val = false) => {
    this.loading = val;
  };

  @action
  setInboxScreenType = (val = "") => {
    this.inboxScreentype = val;
  };

  @action
  setEmailWarmingToogle = (val = "") => {
    this.emailwarmingToogle = val;
  };

  @action
  setWarmingLoaderId = (val = "") => {
    this.warmingLoaderId = val;
  };

  @action
  setShowSmptpViaWarming = (val = false) => {
    this.showSmtpViaWarming = val;
  };

  @action
  setSearchTerm = (val = "") => {
    this.searchTerm = val;
  };

  @action
  setTotalCount = (val = 0) => {
    this.totalCount = val;
  };

  @action
  setPageLimit = (val = 10) => {
    this.pageLimit = val;
  };

  @action
  setDeliverabilityStats = (val = {}) => {
    this.deliverabilityStats = val;
  };

  @action
  setWarmupLoader = (val = {}) => {
    this.warmupLoader = val;
  };

  @action
  setWarmupSettings = (val = {}) => {
    this.warmupSettings = val;
  };

  @action
  setAccountData = (val = []) => {
    this.accountData = val;
  };

  @action
  setLoading = (val = false) => {
    this.loading = val;
  };

  @action
  setActiveDateTab = (val = "") => {
    this.activeDateTab = val;
  };

  @action
  setDateFilter = (val = {}) => {
    this.dateFilter = val;
  };

  @action
  setWarmingFilter = (val = {}) => {
    this.warmingFilter = val;
  };

  @action
  setFilterChanged = (val = false) => {
    this.filterChanged = val;
  };

  @action
  setInboxConnected = (val = false) => {
    this.isInboxConnected = val;
  };

  @action
  setPageNo = (val = 1) => {
    this.pageNo = val;
  };

  @action
  setTotalPages = (val = "") => {
    this.totalPages = val;
  };

  @action
  setFirstTimeEnableWarming = (val = false) => {
    this.firstTimeEnableWarming = val;
  };

  @action
  setMembersFilter = (val = {}) => {
    this.membersFilter = val;
  };

  @action
  getInboxByType = async (inboxType = "gmail") => {
    this.setLoading(true);
    const config = {
      url: `${URLS.getCalendarInboxByType}${inboxType}`,
      method: "GET"
    };
    let response = await makeApi(config);
    this.setLoading(false);
    const { data = {} } = response || {};
    const { inboxes = [] } = data || {};
    this.setInboxList(inboxes);
  };

  @action
  connectCalendarInbox = async (data, cbk) => {
    try {
      const config = {
        url: URLS.connectCalendar,
        method: "POST",
        data
      };
      const res = await makeApi(config);
      const { data: respData = {} } = res || {};
      const { code = "" } = respData || {};
      cbk();
      if (+code === 200) {
        await calendarState.getCalendarConnnectedAccount();
        toasterState.setToastMsg("Calendar connected successfully", "success");
      } else {
        if (+code === 423) {
          toasterState.setToastMsg(
            "Please disconnect your inbox and connect again",
            "failure"
          );
        } else {
          toasterState.setToastMsg("Something went wrong", "failure");
        }
      }
    } catch (e) {
      toasterState.setToastMsg("Connect calendar inbox error", "fail");
    }
  };

  @action
  getWarmingCommunityMsgData = async () => {
    try {
      const config = {
        url: URLS.getWarmingCommunityMsgData,
        method: "GET"
      };
      const res = await makeApi(config);
      const { data = {} } = res || {};
      const { result = "", warmingCommunityMessageShown = false } = data || {};
      if (result?.toString() === "true") {
        return warmingCommunityMessageShown;
      }
    } catch (e) {
      console.error("Get first time warming enabling error", e);
    }
  };

  @action
  getWarmingCommunityMsgShown = async (dataShown = true) => {
    try {
      const config = {
        url: `${URLS.getWarmingCommunityMsgShown}/${dataShown}`,
        method: "GET"
      };
      const res = await makeApi(config);
      const { data = {} } = res || {};
      const { result = "" } = data || {};
      if (result?.toString() === "true") {
        this.getWarmingCommunityMsgData();
      }
    } catch (e) {
      console.error("Get first time warming enabling error", e);
    }
  };

  @action
  searchConnectedInbox = async (searchedKeyword = "") => {
    this.setLoading(true);
    try {
      const config = {
        url: `${URLS.searchConnectedInbox}${searchedKeyword}`,
        method: "GET"
      };
      setNetworkRequest("searchInbox");
      const cancel = value => cancelReqCallback("searchInbox", value);
      const res = await makeApi(config, cancel);
      if (res) {
        const { data = [] } = res || {};
        this.setAccountData(data);
        this.setLoading(false);
      } else {
        toasterState.setToastMsg("Something went wrong", "failure");
      }
    } catch (e) {
      console.error("Fetching search result for Inbox error", e);
    }
  };

  @action
  emptyAccount = (accounts = []) => {
    this.setInboxConnected(false);
    this.setAccountData([]);
  };

  @action
  getAccountDetails = async (
    allowMxpEventEntry = false,
    isFilterChanged = false
  ) => {
    this.setLoading(true);
    try {
      const config = {
        url: URLS.getConnectedInboxesWarming,
        method: "POST",
        data: {
          pageNo: this.pageNo,
          limit: this.pageLimit,
          filter: {
            searchTerm: this.searchTerm,
            ...this.dateFilter,
            ...this.warmingFilter,
            ...this.membersFilter
          }
        }
      };
      setNetworkRequest("searchInbox");
      const cancel = value => cancelReqCallback("searchInbox", value);
      let res = await makeApi(config, cancel);
      if (res?.message !== "cancel") {
        const { data = {} } = res || {};
        if (!isFilterChanged) {
          await getFeatureFlagWithTeam(false);
        }
        await fetchConnectedEmails();
        this.setLoading(true);
        if (data && Object.keys(data)?.length) {
          const {
            inboxes = [],
            totalCount = 0,
            accessRevokedCount = 0,
            totalPages = 1
          } = data || {};
          this.setTotalCount(totalCount);
          let activeAccount = [];
          if (inboxes?.length > 0) {
            activeAccount = inboxes.filter(item => item.status === "ACTIVE");
          }
          if (
            +accessRevokedCount !== 0 &&
            +totalCount !== 0 &&
            +accessRevokedCount === +totalCount
          ) {
            InboxUtils.showConnectInboxBanner();
            this.emptyAccount(activeAccount);
          } else if (inboxes?.length > 0) {
            this.setAccountData(inboxes);
            this.setTotalPages(totalPages);
            this.setInboxConnected(true);
            InboxUtils.removeConnectInboxBanner();
            initialWelcomePopupState.setRestrictOnboardingPopup(false);
            if (activeAccount?.length > 0) {
              allowMxpEventEntry &&
                Utils.mixpanelTrack(MXP_EVENT.INBOX_ADDED, {
                  count: activeAccount?.length
                });
              if (userDetail.connectInboxAccessRevoke) {
                successPopupState.setPopupValues({
                  type: "inboxReconnected",
                  text: `Great!, now that you are connected back. You can resume all your sequences manually.`
                });
                successPopupState.setPopup(true);
              }
            }
          } else if (isFilterChanged) {
            this.setAccountData([]);
            this.setTotalPages(totalPages);
          } else {
            this.emptyAccount(inboxes);
          }
        } else {
          this.emptyAccount([]);
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
      }
      this.setLoading(false);
    } catch (err) {
      console.error("Get Account Data error ", err);
    }
  };

  @action
  enableOrDisableWarming = async (data = {}, pageName = "one") => {
    connectedInboxStore.setWarmingLoaderId(data?.id);
    try {
      const config = {
        url: URLS.enableOrDisableWarming,
        method: "POST",
        data: {
          warmingEnabled: !data?.warmingEnabled,
          id: data?.id
        }
      };
      const res = await makeApi(config);
      const { code = "", inbox = {} } = res?.data || {};
      const { email = "", smtp = {}, type = "" } = inbox || {};
      let tempEmail = type?.toLowerCase() === "smtp" ? smtp?.email : email;
      if (+code === 200) {
        await fetchConnectedEmails();
        if (pageName === "two") {
          connectedInboxStore.setEmailWarmingToogle(inbox?.warmingEnabled);
        }
        if (pageName === "one") {
          this.getAccountDetails(false, true);
        }
        if (inbox?.warmingEnabled) {
          toasterState.setToastMsg(
            `Warming enabled for ${tempEmail} successfully.`,
            "success"
          );
        } else {
          toasterState.setToastMsg(
            `Warming disabled for ${tempEmail} successfully.`,
            "success"
          );
        }
      } else if (+code === 426) {
        PaywallUtils.upgradeUtilsFunction("emailWarming");
      } else {
        toasterState.setToastMsg(
          "Oops! Failed to enable warming. Please try again.",
          "failure"
        );
      }
      connectedInboxStore.setWarmingLoaderId("");
      return true;
    } catch (e) {
      console.error("Enable or disable warming error", e);
    }
  };

  @action
  resetConnectedInbox = (pageNo = 1, pageLimit = 10) => {
    connectedInboxStore.setWarmingFilter({});
    connectedInboxStore.setSearchTerm("");
    connectedInboxStore.setActiveDateTab("month");
    connectedInboxStore.setDateFilter(DateUtils.getMonthTimeStamp());
    connectedInboxStore.setPageLimit(pageLimit);
    connectedInboxStore.setPageNo(pageNo);
    connectedInboxStore.setMembersFilter([]);
  };

  @action
  openGoogleAuth = async (
    loginHint = "",
    data = {},
    handleWarming = () => {}
  ) => {
    const { calendarIntegrationEnabled = false } =
      userDetail?.userFeatureFlag || {};
    const { calendarEnabled = false } = userDetail?.userInfo || {};
    gmailMessagePopupState.setPopupValues({});
    gmailMessagePopupState.setShowPopup(false);
    let gmailAuthScope =
      calendarEnabled && calendarIntegrationEnabled
        ? AUTH_SCOPES_CALENDAR
        : AUTH_SCOPES;
    const authUrl =
      "https://accounts.google.com/o/oauth2/auth?client_id=" +
      AUTH_CLIENT_ID +
      "&scope=" +
      gmailAuthScope +
      "&access_type=offline&response_type=code&redirect_uri=" +
      AUTH_REDIRECT_URL +
      `&state=reconnectInbox_${data?.id}${
        loginHint ? `&login_hint=${loginHint}` : ""
      }&consent=prompt&hd=*`;
    const w = 600;
    const h = 800;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
    window.open(authUrl, "Salesgear", params);
    const oauthCbk = async (ev = {}) => {
      if (ev?.data?.type === "GOOGLE_CONNECT_INBOX") {
        connectedInboxStore.handleGoogleOauthCbk(
          ev?.data?.code?.toString(),
          data
        );
        window.removeEventListener("message", oauthCbk, handleWarming);
      }
    };
    window.addEventListener("message", oauthCbk);
  };

  @action
  handleGoogleOauthCbk = async (cbkCode = "") => {
    const { noOfInboxesUsed = 0 } = userDetail?.userFeatureFlag || {};
    switch (cbkCode) {
      case "200":
        await fetchConnectedEmails();
        userDetail.setUserFeatureFlag({
          ...userDetail.userFeatureFlag,
          noOfInboxesUsed: noOfInboxesUsed + 1
        });
        if (connectedInboxStore?.inboxScreentype === "one") {
          connectedInboxStore.getAccountDetails(false, true);
        } else {
          connectedInboxStore.setInboxScreenType("two");
        }
        break;
      case "400":
        gmailMessagePopupState.setPopupValues({
          title: "Insufficient Permission",
          subTitle: `Unable to connect your Google account with Salesgear due to insufficient permissions.
        Please retry connecting by enabling all the required permissions on the consent screen 
        <br>(Please refer to the video below)`
        });
        gmailMessagePopupState.setShowPopup(true);
        break;
      case "409":
        gmailMessagePopupState.setPopupValues({
          title: "Connection Failed",
          subTitle: `Unable to connect your Google account with Salesgear as its already connected to a different Salesgear account. Please try re-connecting a different Google account`,
          additionalLink: `<a class="errorLink" href="https://support.salesgear.io/en/articles/5660382-why-do-i-get-the-connection-failed-error-when-i-try-to-connect-my-gmail-with-salesgear" target="_blank" rel="noopener noreferrer"><span>Why do I get the connection failed error?</span><i class="fa fa-external-link openInNewTabIcon" aria-hidden="true"></i></a>`
        });
        gmailMessagePopupState.setShowPopup(true);
        break;
      case "412":
        gmailMessagePopupState.setPopupValues({
          title: "Connection Failed",
          subTitle: `Please connect the same email account`
        });
        gmailMessagePopupState.setShowPopup(true);
        break;
      case "413":
        toasterState.setToastMsg(
          `Oops! You cannot connect personal email addresses.`,
          "fail"
        );
        break;
      case "500":
      default:
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
    }
  };

  @action
  handleWarming = async (data = {}, pageName = "one") => {
    connectedInboxStore.setWarmingLoaderId(data?.id);
    const warmingCommunityMsgShown =
      await connectedInboxStore.getWarmingCommunityMsgData();
    if (warmingCommunityMsgShown) {
      connectedInboxStore.enableOrDisableWarming(data, pageName);
      googleOAuthPopupState.setShowPopup(false);
      googleOAuthPopupState.setScreenNo("one");
      googleOAuthPopupState.setConnectConfig("oAuth");
      connectedInboxStore.setFirstTimeEnableWarming(false);
    } else {
      connectedInboxStore.setWarmingLoaderId("");
      googleOAuthPopupState.setScreenNo("");
      connectedInboxStore.setFirstTimeEnableWarming(true);
      googleOAuthPopupState.setInboxData(data);
      googleOAuthPopupState.setShowPopup(true);
    }
  };

  @action
  microsoftScopeAuth = async (loginHint = "", data = {}, pageName = "one") => {
    if (InboxUtils.connectInboxAllowed()) {
      DriverMaster.killDriver();
      const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${OUTLOOK_CLIENT_ID}&response_type=code&redirect_uri=${OUTLOOK_REDIRECT_URL}&response_mode=query&scope=offline_access openid Mail.ReadWrite Contacts.Read email Mail.Read Mail.Send profile User.Read Calendars.ReadWrite Calendars.ReadBasic Calendars.Read&state=outlookAddScope_${
        data?.id
      }${loginHint ? `&login_hint=${loginHint}` : ""}&consent=prompt`;
      const w = 600;
      const h = 800;
      const left = window.screen.width / 2 - w / 2;
      const top = window.screen.height / 2 - h / 2;
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
      const oAuthWindow = window.open(authUrl, "Salesgear", params);
      const oauthSuccess = async ev => {
        let { code = "", type = "" } = ev.data || ev.key;
        if (type === "MICROSOFT_ADDSCOPE_INBOX") {
          switch (code) {
            case "200":
            case 200:
              await fetchConnectedEmails();
              connectedInboxStore.handleWarming(data, pageName);
              if (connectedInboxStore?.inboxScreentype === "one") {
                connectedInboxStore.getAccountDetails(false, true);
              } else {
                connectedInboxStore.setInboxScreenType("two");
              }
              break;
            case "409":
            case 409:
              oAuthWindow.close();
              setTimeout(() => {
                toasterState.setToastMsg(
                  `Failed. This inbox is already connected to a different Salesgear account. Try with a different one.`,
                  "fail"
                );
              }, 200);
              break;
            case "500":
            default:
              toasterState.setToastMsg(
                "Something went wrong. Please try again later",
                "fail"
              );
          }
        }
      };
      window.addEventListener("message", oauthSuccess);
    }
  };

  @action
  handleWarmingAction = async (
    e,
    data = {},
    warmingEnabled = false,
    pageName = "one"
  ) => {
    const {
      warmingAccountsEligible = 0,
      warmingAccountsUsed = 0,
      emailWarmingEnabled = false
    } = userDetail?.userFeatureFlag || {};
    if (emailWarmingEnabled) {
      if (warmingAccountsUsed === warmingAccountsEligible && !warmingEnabled) {
        PaywallUtils.upgradeUtilsFunction("emailWarming");
      } else {
        const { scope = "" } = data || {};
        const { type = "", emailWarming = {}, email = "" } = data || {};
        const { googleWarmingInbox = {} } = emailWarming || {};
        const { googleOauthConnection = false, googleSmtpConnection = false } =
          googleWarmingInbox || {};
        Utils.preventDefaultFn(e);
        if (!warmingEnabled) {
          if (type !== "gmail_oath") {
            if (type === "outlook_oauth") {
              if (scope && scope.includes("Mail.ReadWrite")) {
                connectedInboxStore.handleWarming(data, pageName);
              } else {
                connectedInboxStore.microsoftScopeAuth(email, data, pageName);
              }
            } else {
              connectedInboxStore.handleWarming(data, pageName);
            }
          } else {
            if (
              !emailWarming ||
              !googleWarmingInbox ||
              (!googleOauthConnection && !googleSmtpConnection) ||
              data?.emailWarming?.status === "ACCESS_REVOKED"
            ) {
              googleOAuthPopupState.setShowPopup(true);
              googleOAuthPopupState.setInboxData(data);
            } else {
              connectedInboxStore.handleWarming(data, pageName);
            }
          }
        } else {
          connectedInboxStore.enableOrDisableWarming(data, pageName);
        }
      }
    } else {
      if (!warmingEnabled) {
        PaywallUtils.upgradeUtilsFunction("emailWarmingCustom");
      } else {
        connectedInboxStore.enableOrDisableWarming(data, pageName);
      }
    }
  };

  @action
  microsoftReconnectAuth = async (
    loginHint = "",
    data = {},
    pageName = "one"
  ) => {
    const { noOfInboxesUsed = 0 } = userDetail?.userFeatureFlag || {};
    if (InboxUtils.connectInboxAllowed()) {
      DriverMaster.killDriver();
      const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${OUTLOOK_CLIENT_ID}&response_type=code&redirect_uri=${OUTLOOK_REDIRECT_URL}&response_mode=query&scope=offline_access openid Mail.ReadWrite Contacts.Read email Mail.Read Mail.Send profile User.Read Calendars.ReadWrite Calendars.ReadBasic Calendars.Read&
      state=reconnectInbox_${data?.id}${
        loginHint ? `&login_hint=${loginHint}` : ""
      }&consent=prompt`;
      const w = 600;
      const h = 800;
      const left = window.screen.width / 2 - w / 2;
      const top = window.screen.height / 2 - h / 2;
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
      const oAuthWindow = window.open(authUrl, "Salesgear", params);
      const oauthSuccess = async (ev = {}) => {
        let { code = "", type = "" } = ev.data || ev.key || {};
        if (type === "MICROSOFT_CONNECT_INBOX") {
          switch (code) {
            case "200":
            case 200:
              await fetchConnectedEmails();
              userDetail.setUserFeatureFlag({
                ...userDetail.userFeatureFlag,
                noOfInboxesUsed: noOfInboxesUsed + 1
              });
              if (connectedInboxStore?.inboxScreentype === "one") {
                connectedInboxStore.getAccountDetails(false, true);
              } else {
                connectedInboxStore.setInboxScreenType("two");
              }
              oAuthWindow.close();
              Utils.trackOnboardingMixpanel("account_connected");
              break;
            case "409":
            case 409:
              oAuthWindow.close();
              setTimeout(() => {
                toasterState.setToastMsg(
                  `Failed. This inbox is already connected to a different Salesgear account. Try with a different one.`,
                  "fail"
                );
              }, 200);
              break;
            case "412":
            case 412:
              oAuthWindow.close();
              setTimeout(() => {
                toasterState.setToastMsg(
                  `Please connect the same email account`,
                  "fail"
                );
              }, 200);
              break;
            case "500":
            default:
              toasterState.setToastMsg(
                "Something went wrong. Please try again later",
                "fail"
              );
          }
        }
      };
      window.addEventListener("message", oauthSuccess);
    }
  };
}

const connectedInboxStore = new ConnectedInboxStore();

export { connectedInboxStore };
export default connectedInboxStore;
