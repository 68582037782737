import React, { useState } from "react";
import Utils from "Utils/utils";
import { ReactComponent as PhoneNoCallIcon } from "Assets/svg/phoneNoCallIcon.svg";
import { ReactComponent as MobileCallIcon } from "Assets/svg/mobileCallIcon.svg";
import { ReactComponent as OtherCallIcon } from "Assets/svg/otherCallIcon.svg";
import { ReactComponent as CompanyCallIcon } from "Assets/svg/companyCallIcon.svg";

const icons = {
  Phone: <PhoneNoCallIcon />,
  Mobile: <MobileCallIcon />,
  Other: <OtherCallIcon />,
  Company: <CompanyCallIcon />
};

const PhoneNumberDropDown = (props = {}) => {
  const {
    phoneNumberList = [],
    showPhoneNumberDropDown,
    setShowPhoneNumberDropDown = () => {},
    makeACallAction = () => {}
  } = props || {};

  const changeAction = (event = "") => {
    let selectedNumber = Utils.performActionByClosestDom(
      event,
      "li",
      "data-value"
    );
    makeACallAction("", "call", selectedNumber);
    setShowPhoneNumberDropDown(false);
  };

  const getPhoneTypeIcon = (type = "") => {
    return icons[type];
  };

  return (
    phoneNumberList?.length > 0 &&
    showPhoneNumberDropDown && (
      <>
        <div className="drop-down-menu animatedFast animatedDropDown">
          <div className="sharpPointer" />
          <ul className="dropdownList" onClick={changeAction}>
            {phoneNumberList?.map((item, index) => {
              const { number = "", type = "" } = item || {};
              return (
                <li
                  id={index}
                  key={index}
                  data-key={index}
                  data-value={number}
                  className="option"
                >
                  <div className="listItem">
                    <span className="callIconType">
                      {getPhoneTypeIcon(type)}
                    </span>
                    <div className="listItemWrap">
                      <span className="numberType">{type}</span>
                      <span className="itemName">{number}</span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    )
  );
};

export default PhoneNumberDropDown;
