import React from "react";

const ConfigureButton = props => {
  const { configureCbk = () => {}, value = "" } = props || {};

  return (
    <div className={`connectBtnCont btn `} onClick={configureCbk}>
      <i className="material-icons-outlined">settings</i>
      <span>{value}</span>
    </div>
  );
};

export default ConfigureButton;
export { ConfigureButton };
