import React from "react";
import { withRouter } from "react-router-dom";
import Button from "Components/common/Button";

const SalesforceLoginCard = (props = {}) => {
  const { cbk } = props;
  const redirectToSfCRM = () => {
    props.history.push("/settings/crm");
  };

  return (
    <>
      <div className="sfLoginCard">
        <p>Connect your Salesforce account in Salesgear</p>
        <div className="sfConnectBtn">
          <Button
            id="redirectToCRM"
            name="redirectToCRM"
            btnText="Connect"
            icon={false}
            iconName=""
            type="button"
            className=""
            loader={false}
            click={redirectToSfCRM}
          />
        </div>
      </div>
      <div className="overlaySFBg" onClick={cbk}></div>
    </>
  );
};

export default withRouter(SalesforceLoginCard);
export { SalesforceLoginCard };
