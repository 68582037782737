import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import userDetail from "Stores/userDetail";
import contactStoreData from "Stores/contactData";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import SequenceUtils from "Utils/SequenceUtils";
import PageLimit from "Components/common/PageLimit";
import { toasterState } from "Components/common/toaster";
import AddToSequenceButton from "Components/common/AddToSequenceButton";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { updateContactOwnerState } from "Components/common/UpdateContactOwner";
import { addSequenceInContactPopupState } from "../../contacts/addSeqInContactPopup";
import { accountsState } from "../AccountsState";
import ContactsMoreOptions from "./ContactsMoreOptions";

const ContactsHeaderActions = observer((props = {}) => {
  const {
    tableData = {},
    accountId = "",
    tableLoading = false,
    allAccountsSelectedInView = false,
    startSequence = () => {},
    setShowMessage = () => {},
    getSelectedCount = () => {},
    setUploadingText = () => {},
    reloadContactsPage = () => {},
    resetAllStateValue = () => {},
    setFieldErrorDetails = () => {},
    clearSelectedContacts = () => {},
    setDailyLimitContactData = () => {},
    setContactUploadingLoader = () => {}
  } = props || {};
  const [showActionsDropdown, setShowActionsDropdown] = useState(false);

  const history = useHistory();

  const { emailValidationCredits = 0, emailValidationEnabled = false } =
    userDetail?.userFeatureFlag || {};
  const { hasTeamMember = false } = userDetail?.userInfo || {};

  // page limit

  const getContactsByLimit = (limit = 10) => {
    resetAllStateValue();
    accountsState.setContactsState({ limit, pageNo: 1 });
  };

  // Add to sequence - start
  const hasContactChecked = () => {
    return getSelectedCount();
  };

  const getSelectedContactIds = useCallback(() => {
    return [...tableData?.contacts]
      .filter(item => item?.isChecked)
      .map(item => item?.id);
  }, [tableData?.contacts]);

  const getContactToSequencePayload = () => {
    let payload = {
      source: "ACCOUNTS",
      type: "ADD_TO_SEQUENCE",
      totalCount: getSelectedCount()
    };

    if (accountsState?.contactsState?.activeTab) {
      const { stageId = "", searchTerm = "" } =
        accountsState?.contactsState || {};
      payload.search = {
        searchTerm,
        stageId,
        filter: {
          accountId: [accountId]
        }
      };
      if (!allAccountsSelectedInView) {
        payload.contactIds = getSelectedContactIds();
      }
    }
    return payload;
  };

  const validateDailyLimitForContacts = async (payload = {}, title = "") => {
    let res = await contactStoreData.validateDailyLimitForContacts(payload);
    if (res && Object.keys(res)?.length) {
      let dataObj = res;
      dataObj = { ...dataObj, payload, title };
      setDailyLimitContactData(dataObj);
      const { contacts = [], fieldValidationResponse = {} } = res || {};
      const { missingFields = [], validCount = 0 } =
        fieldValidationResponse || {};
      if (
        fieldValidationResponse &&
        (missingFields?.length > 0 || !validCount)
      ) {
        fieldValidationResponse.contacts = contacts;
        setFieldErrorDetails(fieldValidationResponse);
        setContactUploadingLoader(false);
        setUploadingText("");
        setShowMessage(true);
      } else {
        setContactUploadingLoader(false);
        setUploadingText("");
        startSequence(dataObj);
        clearSelectedContacts();
      }
    }
  };
  const checkAndAddContactToSequence = (objProp = {}) => {
    let { title = "", payload = {}, sequenceId = "" } = objProp || {};
    payload = {
      ...payload,
      sequenceId
    };
    setUploadingText(`We are processing your contacts. Please wait.`);
    setContactUploadingLoader(true);
    validateDailyLimitForContacts(payload, title);
  };

  const addToSequenceAction = () => {
    if (hasContactChecked()) {
      const payload = getContactToSequencePayload();
      Utils.reloadFeatureFlag();
      const validateContactBeforeAddingToSequence = (
        data = {},
        sequenceId = "",
        currentSequence = {}
      ) => {
        if (currentSequence?.emailValidationEnabled) {
          let count = payload?.contactIds?.length
            ? payload?.contactIds?.length
            : payload?.totalCount || 0;
          if (emailValidationEnabled && emailValidationCredits < count) {
            SequenceUtils.promptEmailValidationNoCredits(
              count - emailValidationCredits,
              history,
              () =>
                checkAndAddContactToSequence({
                  ...data,
                  payload,
                  sequenceId
                }),
              () => {},
              "contact"
            );
            return;
          }
        }
        checkAndAddContactToSequence({ ...data, payload, sequenceId });
      };
      addSequenceInContactPopupState.setCallback(
        validateContactBeforeAddingToSequence
      );
      addSequenceInContactPopupState.setShowPopup(true);
    }
  };
  // end

  // Update owner - Starts here

  const expandMenu = () => {
    if (!tableLoading) {
      setShowActionsDropdown(true);
    }
  };

  const handleBulkUpdateStage = () => {};

  const getBulkPerformActionPayload = (ownerId, type = "") => {
    let payload = {
      source: "ACCOUNTS",
      type,
      totalCount: getSelectedCount(),
      newContactOwnerId: ownerId,
      filter: {}
    };
    const { stageId = "", searchTerm = "" } =
      accountsState?.contactsState || {};
    payload.search = {
      searchTerm,
      stageId
    };
    if (allAccountsSelectedInView) {
      payload.search.filter = {
        accountId: [accountId]
      };
    } else {
      payload.filter = {
        contactIds: getSelectedContactIds()
      };
    }
    return payload;
  };

  const showConfirmationPopupForBulkActions = (
    background,
    type = "",
    actionType = ""
  ) => {
    if (background) {
      confirmationPopupState.setPopupValues({
        content: `${type} are being updated; this may take a few to several minutes.`,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      toasterState.setToastMsg(
        `${type} have been ${actionType} successfully`,
        "success"
      );
    }
  };

  const bulkUpdateContactOwner = async (ownerId = "") => {
    const data = getBulkPerformActionPayload(ownerId, "CONTACT_OWNER_UPDATE");
    if (ownerId && data) {
      const config = {
        url: URLS.performContactsBulkOperation,
        method: "POST",
        data
      };
      const response = await makeApi(config);
      updateContactOwnerState.setBtnLoader(false);
      if (response?.data) {
        const {
          success = false,
          background = false,
          activeJob = false
        } = response?.data || {};
        if (activeJob) {
          clearSelectedContacts();
          SequenceUtils.showActiveJobPopupForBulkActions();
        } else if (success) {
          clearSelectedContacts();
          if (!background) {
            reloadContactsPage();
          }
          showConfirmationPopupForBulkActions(
            background,
            "Contact owner",
            "updated"
          );
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } else {
      updateContactOwnerState.setBtnLoader(false);
    }
  };

  const openBulkUpdateOwnerPopup = () => {
    setShowActionsDropdown(false);
    updateContactOwnerState.setPopupType("CONTACT");
    updateContactOwnerState.setTotalCount(
      allAccountsSelectedInView
        ? accountsState.totalAccounts
        : getSelectedCount()
    );
    updateContactOwnerState.setCallback(bulkUpdateContactOwner);
    updateContactOwnerState.setShowPopup(true);
  };

  //obj goes here

  const pageLimitObj = {
    limitPerPage: accountsState?.contactsState?.limit || 10,
    text: "Showing",
    totalCount: tableData?.totalContacts || 0,
    cbk: getContactsByLimit
  };

  return (
    <>
      <PageLimit {...pageLimitObj} />
      {tableData?.contacts?.length > 0 && (
        <AddToSequenceButton
          selectedEntityCount={getSelectedCount() || 0}
          cbk={addToSequenceAction}
          loading={tableLoading}
          checked={hasContactChecked()}
        />
      )}
      {tableData?.contacts?.length > 0 && hasTeamMember && (
        <ContactsMoreOptions
          showActionsDropdown={showActionsDropdown}
          setShowActionsDropdown={setShowActionsDropdown}
          expandMenu={expandMenu}
          updateAccountStage={handleBulkUpdateStage}
          getSelectedCount={getSelectedCount}
          updateAccountOwner={openBulkUpdateOwnerPopup}
          hasTeamMember={hasTeamMember}
        />
      )}
    </>
  );
});

export { ContactsHeaderActions };
export default ContactsHeaderActions;
