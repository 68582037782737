import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import userDetail from "Stores/userDetail";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import DateUtils from "Utils/DateUtils";
import InputTextBox from "Components/common/InputTextBox";
import Button from "Components/common/Button";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { buyCreditsPopupState } from "Pages/Dashboard/settings/BuyCreditsPopup";
import { transactionAnimationState } from "Components/common/TransactionAnimation";
import { toasterState } from "Components/common/toaster";
import { RECHARGE_ERR_MSG } from "Model/model";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import PaywallUtils from "Utils/PaywallUtils";

const defaultObject = {
  annualPhoneCreditsUsed: 0,
  dailyEmailCreditsUsed: 0,
  dailyLimit: false,
  dailyPhoneCreditsUsed: 0,
  emailCreditsDailyLimit: 0,
  emailCreditsMonthlyLimit: 0,
  emailCreditsReduced: 0,
  monthlyCreditsResetDate: "",
  monthlyEmailCreditsUsed: 0,
  monthlyPhoneCreditsUsed: 0,
  phoneCreditsAnnualLimit: 0,
  phoneCreditsDailyLimit: 0,
  phoneCreditsMonthlyLimit: 0,
  phoneCreditsReduced: 0
};

export const CreditsUsage = withRouter(
  observer(props => {
    const {
      planName = "",
      inFreePlan = false,
      trialEnabled = false,
      emailValidationCredits = 0,
      emailValidationEnabled = false
    } = userDetail?.userFeatureFlag || {};

    const isFreeUser = inFreePlan && !trialEnabled;

    const [data, setData] = useState(defaultObject);
    const [loader, setLoader] = useState(false);
    const [addCreditsLoader, setAddCreditsLoader] = useState(false);

    const getCreditsUsage = async () => {
      if (await checkTeamSubscriptionActive()) {
        if (!loader) {
          try {
            const config = {
              url: URLS.getCreditsUsage
            };
            const res = await makeApi(config);
            if (res?.data && Object.keys(res?.data)?.length > 0) {
              setData(res?.data);
            }
          } catch (err) {
            console.error(err, "Getting Credit Usage ERROR!");
          }
        }
      }
      return;
    };

    const getMontlyResetDate = () => {
      return data?.monthlyCreditsResetDate
        ? `Next renewal resets on ${DateUtils.getDateAndMonth(
            data.monthlyCreditsResetDate
          )} at ${DateUtils.getTimeByMerdian(
            data.monthlyCreditsResetDate
          )} (${DateUtils.getTimeZone(data.monthlyCreditsResetDate)})`
        : "--";
    };

    const getDailyResetTime = () => {
      return `Next renewal resets on ${DateUtils.getDateAndMonth(
        +new Date() + 86400000
      )} at 12:00 AM (UTC)`;
    };

    const emailCreditsInputBox = [
      {
        name: "emailMonthlyCredits",
        id: "emailMonthlyCredits",
        text: "Monthly Credits",
        type: "text",
        value: Utils.formatNumberToString(data.emailCreditsMonthlyLimit) || 0,
        readOnly: true,
        dynamicText: data?.monthlyCreditsResetDate
          ? getMontlyResetDate(data?.monthlyCreditsResetDate)
          : ""
      },
      {
        name: "emailMonthlyCreditsRemaining",
        id: "emailMonthlyCreditsRemaining",
        text: "Monthly Credits remaining",
        type: "text",
        value:
          Utils.formatNumberToString(
            data.emailCreditsMonthlyLimit - data.monthlyEmailCreditsUsed
          ) || 0,
        readOnly: true
      },
      {
        name: "emailCreditsPerDay",
        id: "emailCreditsPerDay",
        text: "Credits/Day",
        type: "text",
        value:
          planName?.toLowerCase() === "unlimited" && !trialEnabled
            ? "No daily limit"
            : Utils.formatNumberToString(data.emailCreditsDailyLimit),
        readOnly: true,
        dynamicText:
          (data?.monthlyCreditsResetDate &&
            planName?.toLowerCase() !== "unlimited" &&
            getDailyResetTime()) ||
          ""
      },
      {
        name: "emailCreditsRemaining",
        id: "emailCreditsRemaining",
        text: "Credits/Day remaining",
        type: "text",
        value: Utils.formatNumberToString(
          data.emailCreditsDailyLimit - data.dailyEmailCreditsUsed
        ),
        readOnly: true,
        showDom: planName?.toLowerCase() !== "unlimited"
      }
    ];

    const phoneNumberCreditsInputBox = [
      {
        name: `phoneNumberCreditsPer${
          data.phoneCreditsAnnualLimit > 0 ? "Yearly" : "Monthly"
        }`,
        id: `phoneNumberCreditsPer${
          data.phoneCreditsAnnualLimit > 0 ? "Yearly" : "Monthly"
        }`,
        text: `Credits/${data.phoneCreditsAnnualLimit > 0 ? "Year" : "Month"}`,
        type: "text",
        value: Utils.formatNumberToString(
          data.phoneCreditsAnnualLimit > 0
            ? data.phoneCreditsAnnualLimit
            : data.phoneCreditsMonthlyLimit
        ),
        readOnly: true,
        dynamicText: data?.monthlyCreditsResetDate
          ? getMontlyResetDate(data?.monthlyCreditsResetDate)
          : ""
      },
      {
        name: `phoneNumberCreditsRemaining${
          data.phoneCreditsAnnualLimit > 0 ? "Yearly" : "Monthly"
        }`,
        id: `phoneNumberCreditsRemaining${
          data.phoneCreditsAnnualLimit > 0 ? "Yearly" : "Monthly"
        }`,
        text: `Credits/${
          data.phoneCreditsAnnualLimit > 0 ? "Year" : "Month"
        } remaining`,
        type: "text",
        value: Utils.formatNumberToString(
          data.phoneCreditsAnnualLimit > 0
            ? data.phoneCreditsAnnualLimit - data.annualPhoneCreditsUsed
            : data.phoneCreditsMonthlyLimit - data.monthlyPhoneCreditsUsed
        ),
        readOnly: true
      }
    ];

    const emailValidationCreditsInput = {
      name: `emailValidationCreditsMonthly`,
      id: `emailValidationCreditsMonthly`,
      text: `Credits remaining`,
      type: "text",
      value: Utils.formatNumberToString(emailValidationCredits),
      readOnly: true
    };

    const addEmailPhoneNumberCredits = () => {
      if (isFreeUser) {
        props.history.push("/pricing");
      } else {
        PaywallUtils.upgradeUtilsFunction(
          "data_AddCreditsToDataSearch",
          true,
          planName,
          inFreePlan
        );
      }
    };

    const showRechargeErrCodeMsg = response => {
      let code = response?.data?.responseCode || response?.data?.status;
      if (code === 207) {
        toasterState.setToastMsg("UNAUTHORIZED Access", "fail");
      } else {
        let content = RECHARGE_ERR_MSG[code] || "";
        if (content) {
          confirmationPopupState.setPopupValues({
            content,
            actionBtnText: "Ok",
            needCancelBtn: false,
            dynamicContent: true,
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "failure"
          );
        }
      }
    };

    const purchaseEmailValidationCredits = async (amount = 0) => {
      if (amount) {
        try {
          const config = {
            url: URLS.purchaseEmailValidationCredits,
            method: "POST",
            data: { amount }
          };
          let response = await makeApi(config);
          if (response?.data?.responseCode === 200) {
            await Utils.reloadFeatureFlag(false);
            toasterState.setToastMsg(
              "Credits added to your account successfully",
              "success"
            );
          } else {
            showRechargeErrCodeMsg(response);
          }
        } catch (e) {
          console.error("Recharge email validation credits failed", e);
        }
      }
    };

    const purchaseCreditsCbk = async (amount = 0, stripeToken = "") => {
      if (amount && !stripeToken) {
        transactionAnimationState.setShowPopup(true);
        await purchaseEmailValidationCredits(amount);
        transactionAnimationState.setShowPopup(false);
      } else {
        showRechargeErrCodeMsg(201);
      }
    };

    const addEmailValidationCredits = async () => {
      if (await checkTeamSubscriptionActive()) {
        if (emailValidationEnabled && !isFreeUser && !trialEnabled) {
          setAddCreditsLoader(true);
          const cardResponse = Utils.checkCustomerIdAvailable();
          if (cardResponse === 200) {
            buyCreditsPopupState.setNewPayment(false);
            buyCreditsPopupState.setCallBack(purchaseCreditsCbk);
            buyCreditsPopupState.setPopupValue({
              type: "EV_CREDITS"
            });
            buyCreditsPopupState.setShowPopup(true);
          } else if (Utils.isEligibleToShowPaymentPopup(cardResponse)) {
            buyCreditsPopupState.setNewPayment(true);
            buyCreditsPopupState.setCallBack(purchaseCreditsCbk);
            buyCreditsPopupState.setPopupValue({
              type: "EV_CREDITS"
            });
            buyCreditsPopupState.setShowPopup(true);
          } else {
            PaywallUtils.upgradeUtilsFunction(
              cardResponse === 203
                ? "data_AddCreditsToEV"
                : "emailValidationUpgrade",
              true,
              planName,
              inFreePlan
            );
          }
        }
      }
    };

    const buttonObject = {
      refresh: {
        id: "refreshCreditsUsage",
        name: "refreshCreditsUsage",
        btnText: "Refresh",
        type: "button",
        loader,
        icon: true,
        iconName: "refresh",
        className: `refreshCreditsUsage ${loader ? "loading" : ""}`,
        click: () => getInitPage(true)
      },
      addEmailCreditsBtn: {
        id: "addEmailCreditsBtn",
        name: "addEmailCreditsBtn",
        btnText: isFreeUser ? "Upgrade" : "Add Credits",
        type: "button",
        className: "addEmailCreditsBtn",
        click: addEmailPhoneNumberCredits
      },
      addPhoneCreditsBtn: {
        id: "addPhoneCreditsBtn",
        name: "addPhoneCreditsBtn",
        btnText: isFreeUser ? "Upgrade" : "Add Credits",
        type: "button",
        className: "addPhoneCreditsBtn",
        click: addEmailPhoneNumberCredits
      },
      addEmailValidationCreditsBtn: {
        id: "addEmailValidationCreditsBtn",
        name: "addEmailValidationCreditsBtn",
        btnText:
          isFreeUser || trialEnabled || !emailValidationEnabled
            ? "Upgrade"
            : "Add Credits",
        type: "button",
        loader: addCreditsLoader,
        className: `addEmailValidationCreditsBtn ${
          addCreditsLoader ? "loading" : ""
        }`,
        click: addEmailValidationCredits
      }
    };

    const getInitPage = async (refresh = false) => {
      refresh && setLoader(true);
      await Utils.reloadFeatureFlag(false);
      await getCreditsUsage();
      refresh && setLoader(false);
    };

    useEffect(() => {
      getInitPage();
    }, []);

    return (
      <div className="creditsUsageSection">
        <div className="creditsUsageHeader">
          <h6 className="titleHeader">Credit Usage</h6>
          <div className="flexEnd">
            <Button {...buttonObject["refresh"]} />
          </div>
        </div>
        <div className="creditsUsageBody">
          <div className="emailCredits">
            <div className="titleHeader">
              <div className="subTitleHead">
                <h6>Email Credits</h6>
                {!isFreeUser && (
                  <p>
                    Email credits are used to view the email addresses of
                    contacts in the <Link to="/discover">discover section</Link>
                    . Your current billing cycle offers{" "}
                    {Utils.formatNumberToString(data.emailCreditsMonthlyLimit)}{" "}
                    email credits/month with{" "}
                    {planName?.toLowerCase() === "unlimited" && !trialEnabled
                      ? "no daily limit"
                      : `a limit of ${Utils.formatNumberToString(
                          data.emailCreditsDailyLimit
                        )} email credits/day`}
                    .
                  </p>
                )}
                {isFreeUser && (
                  <p>
                    You're currently in the free plan. Please upgrade to one of
                    our premium plans to add email credits.
                  </p>
                )}
              </div>
              <Button {...buttonObject["addEmailCreditsBtn"]} />
            </div>
            <div className="formBody">
              {emailCreditsInputBox.map((item, index) => {
                return (
                  <InputTextBox
                    {...item}
                    key={`emailCreditsInputBox${index}`}
                  />
                );
              })}
            </div>
          </div>
          <div className="phoneNumberCredits">
            <div className="titleHeader">
              <div className="subTitleHead">
                <h6>Phone Number Credits</h6>
                {!isFreeUser && (
                  <p>
                    Phone number credits are used to view the direct dial or
                    mobile numbers of contacts in the{" "}
                    <Link to="/discover">discover section</Link>. Your current
                    billing cycle offers{" "}
                    {Utils.formatNumberToString(
                      data.phoneCreditsAnnualLimit > 0
                        ? data.phoneCreditsAnnualLimit
                        : data.phoneCreditsMonthlyLimit
                    )}{" "}
                    phone number credits/
                    {data.phoneCreditsAnnualLimit > 0 ? "year" : "month"}.
                  </p>
                )}
                {isFreeUser && (
                  <p>
                    You're currently in the free plan. Please upgrade to one of
                    our premium plans to add phone number credits.
                  </p>
                )}
              </div>
              <Button {...buttonObject["addPhoneCreditsBtn"]} />
            </div>
            <div className="formBody">
              {phoneNumberCreditsInputBox.map((item, index) => {
                return (
                  <InputTextBox
                    {...item}
                    key={`emailCreditsInputBox${index}`}
                  />
                );
              })}
            </div>
          </div>
          <div className="emailValidationSection">
            <div className="titleHeader">
              <div className="subTitleHead">
                <h6>Email Validation Credits</h6>
                <p>
                  Email validation credits are used to validate the email
                  addresses added to sequences. You will be charged 1 validation
                  credit to validate each email address.
                </p>
              </div>
              <Button {...buttonObject["addEmailValidationCreditsBtn"]} />
            </div>
            <div className="formBody">
              <InputTextBox
                {...emailValidationCreditsInput}
                key={`emailValidationCreditsInputBox`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default CreditsUsage;
