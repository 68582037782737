import React from "react";

export const SubjectLineTitleReload = props => {
  const { action = () => {}, loader = false } = props || {};
  return (
    <div className="wrapTitleReload">
      <span className="title">Recommended subject lines</span>
      <div
        className={`reloadSubjectLineBtn ${loader ? "disableBtn" : ""}`}
        onClick={() => (!loader ? action() : () => {})}
      >
        <i
          className={`fa fa-refresh spinnerReload ${loader ? "fa-spin" : ""}`}
        ></i>
        <span className="reloadText">Reload</span>
      </div>
    </div>
  );
};

export default SubjectLineTitleReload;
