import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import EllipseImage from "Assets/png/ellipse.png";
import MailOpenerImage from "Assets/png/mailOpener.png";
import Button from "Components/common/Button";

class DealifyPopupState {
  @observable showPopup = false;
}

const dealifyPopupStore = new DealifyPopupState();

const DealifyPopup = observer(props => {
  return (
    (dealifyPopupStore.showPopup && (
      <section className="dealifyPopupWrapper">
        <div className="dealifyPopupCard">
          <i
            className="material-icons closeIcon"
            onClick={() => {
              dealifyPopupStore.showPopup = false;
            }}
          >
            close
          </i>
          <div className="imageWrapper">
            <img className="mailOpenerImage" alt="" src={MailOpenerImage} />
            <img className="ellipsisImage" alt="" src={EllipseImage} />
          </div>
          <div className="dealifyContent">
            Please write to us at{" "}
            <a href={`mailto:support@salesgear.io`}>support@salesgear.io</a>{" "}
            from your registered email address to claim your lifetime deal
          </div>
          <Button
            btnText="Ok"
            icon={true}
            iconName="keyboard_backspace"
            type="button"
            className={"dealifyButton"}
            click={() => {
              dealifyPopupStore.showPopup = false;
            }}
          />
        </div>
        <div className="overlayDealifyMask"></div>
      </section>
    )) || <></>
  );
});

export { DealifyPopup, dealifyPopupStore };
export default DealifyPopup;
