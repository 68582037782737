import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import FilterDropdown from "../../Triggers/AddEditTrigger/FilterDropdown";
import { NumberTypeContainer } from "../../Schedules/TimeRangePanel";

const TimeIntervalForPolling = observer(props => {
  const { config = {}, setConfig = () => {}, data = {} } = props;
  const { crmSettings = {} } = data;

  const [timeUnits, setTimeUnits] = useState("minutes");
  const [timeDuration, setTimeDuration] = useState("10");
  const [activeDropdownIndex, setActiveDropdownIndex] = useState("");
  const [minMaxValues, setMinMaxValues] = useState({ min: 10, max: 59 });

  const autoImportTimeList = [
    { name: "minutes" },
    { name: "days" },
    { name: `hours` }
  ];

  const defaultTimingConfig = {
    minutes: {
      minMaxValues: { min: 10, max: 59 }
    },
    days: {
      minMaxValues: { min: 1, max: 30 }
    },
    hours: {
      minMaxValues: { min: 1, max: 23 }
    }
  };

  const handleBlur = event => {
    let updatedValue = timeDuration;
    const { min = "", max = "" } = minMaxValues;
    if (updatedValue > max || updatedValue < min) {
      updatedValue = updatedValue > max ? max : min;
    }
    setTimeDuration(updatedValue);
    constructPayloadConfig(updatedValue, timeUnits);
  };

  const handleTimeChange = event => {
    let updatedValue = parseInt(event?.currentTarget?.value);
    setTimeDuration(updatedValue);
  };

  const handleTimeIncrement = () => {
    const { max = "" } = minMaxValues;
    let updatedInputValue = parseInt(timeDuration);
    updatedInputValue =
      updatedInputValue < max ? updatedInputValue + 1 : updatedInputValue;
    setTimeDuration(updatedInputValue);
    constructPayloadConfig(updatedInputValue, timeUnits);
  };

  const handleTimeDecrement = () => {
    const { min = "" } = minMaxValues;
    let updatedInputValue = parseInt(timeDuration);
    updatedInputValue =
      updatedInputValue > min ? updatedInputValue - 1 : updatedInputValue;
    setTimeDuration(updatedInputValue);
    constructPayloadConfig(updatedInputValue, timeUnits);
  };

  const setTimeUnitsForPolling = item => {
    const { name = "" } = item;
    const { min = "", max = "" } =
      defaultTimingConfig?.[name]?.minMaxValues || {};
    setTimeUnits(name);
    setMinMaxValues(defaultTimingConfig?.[name]?.minMaxValues);
    let updatedValue = parseInt(timeDuration);
    updatedValue =
      updatedValue < min ? min : updatedValue > max ? max : updatedValue;
    setTimeDuration(updatedValue);
    constructPayloadConfig(updatedValue, name);
    setActiveDropdownIndex("");
  };

  const constructPayloadConfig = (updatedTimeDuration, updatedTimeUnits) => {
    const configPending = Object.assign(
      {},
      ...[{ days: 0 }, { minutes: 0 }, { hours: 0 }].filter(item => {
        const key = item && Object.keys(item)?.[0];
        return key !== updatedTimeUnits;
      })
    );
    setConfig({
      ...config,
      pollingInterval: {
        ...configPending,
        [updatedTimeUnits]: updatedTimeDuration
      }
    });
  };

  const assignInitialValues = () => {
    const pollingInterval = config.pollingInterval;
    for (let key in pollingInterval) {
      if (pollingInterval[key] !== 0) {
        setTimeUnits(key);
        setTimeDuration(pollingInterval[key]);
        setMinMaxValues(defaultTimingConfig?.[key]?.minMaxValues);
        break;
      }
    }
  };

  useEffect(() => {
    assignInitialValues();
  }, [crmSettings?.config, config]);

  return (
    <div
      className={`autoImportTime ${config.autoSyncEnabled ? "" : "disabled"} `}
    >
      <div className="timeCont">
        <input
          className="timeRange"
          type="number"
          value={timeDuration}
          min={minMaxValues?.min}
          max={minMaxValues?.max}
          onChange={handleTimeChange}
          onBlur={handleBlur}
        />
        <NumberTypeContainer
          handleIncrement={handleTimeIncrement}
          handleDecrement={handleTimeDecrement}
        />
      </div>
      <FilterDropdown
        label=""
        index="0"
        referenceId="leads"
        isCustomValue={true}
        defaultValue={timeUnits}
        options={autoImportTimeList}
        customCbk={setTimeUnitsForPolling}
        activeDropdownIndex={activeDropdownIndex}
        showDropdown={activeDropdownIndex === "0"}
        setActiveDropdownIndex={setActiveDropdownIndex}
      />
    </div>
  );
});

export { TimeIntervalForPolling };
export default TimeIntervalForPolling;
