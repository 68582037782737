import React, { useEffect, useContext } from "react";
import Utils from "Utils/utils";
import { LiveFeedMainContext } from "../main";

const DropDownMenu = () => {
  const { filterList, setFilterList, dropdownOptions, setDropdownOptions } =
    useContext(LiveFeedMainContext);

  const handleChange = value => {
    if (filterList?.includes(value)) {
      const newList =
        filterList?.length > 0 && filterList.filter(item => item !== value);
      setFilterList(newList);
    } else {
      filterList?.length
        ? setFilterList([value, ...filterList])
        : setFilterList([value]);
    }
  };

  useEffect(() => {
    dropdownOptions?.length > 0 &&
      dropdownOptions.map(item => {
        item.checked = filterList?.includes(item?.key);
      });
    setDropdownOptions(Utils.createObjWithoutRef(dropdownOptions));
  }, [filterList]);

  return (
    <div className="filterDropdown">
      <div className="sharpPointer" />
      {dropdownOptions?.length > 0 &&
        dropdownOptions.map((item, index) => {
          const { key = "", label = "", checked = false } = item || {};
          return (
            <div
              className="dropDownItem"
              onClick={() => handleChange(key)}
              key={`${key}-${index}`}
            >
              <div
                className={`commonFilledCheckBox ${checked ? "checked" : ""}`}
              >
                <div className="checkBoxCard">
                  <div className="emptyCheckBox" />
                  <div className="checkSvgIcon">
                    <svg width="16" height="14" viewBox="0 0 16 14">
                      <path d="M2 8.5L6 12.5L14 1.5" />
                    </svg>
                  </div>
                </div>
              </div>
              <span className="multiSelectLabel">{label}</span>
            </div>
          );
        })}
    </div>
  );
};

export { DropDownMenu };
export default DropDownMenu;
