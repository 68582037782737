import React, { useContext } from "react";
import NotesHeaderAction from "./NotesHeaderAction";
import { AccountsNotesContext } from "./Main";
import Utils from "Utils/utils";

const AccountNotesview = () => {
  const { state = {} } = useContext(AccountsNotesContext);
  const { updateTs = "", title = "", text = "" } = state?.editNotesItem || {};
  return (
    <div className="accountNotesList">
      <div className="accountViewHeader">
        <div>
          <div className="notesTitle">{title}</div>
          {updateTs && (
            <div className="notesCreatedOn">
              Created on {updateTs && Utils.formatDate(updateTs)}
            </div>
          )}
        </div>
        <div>{(title || text) && <NotesHeaderAction />}</div>
      </div>
      <div className="notesAnserView">
        <div className="preview-content-body" disabled>
          <div
            dangerouslySetInnerHTML={{
              __html: text || ""
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountNotesview;
