import React from "react";

export const DropdownButton = props => {
  const {
    label = "",
    count = "",
    keyValue = "",
    toggleDropdown = false,
    callback = () => {}
  } = props || {};
  return (
    <div className="dropdownButton" onClick={() => callback(keyValue)}>
      <span className="label">{label}</span>
      <div className="wrapCountArrow">
        {count > 0 && <span className="selectedCount">{count}</span>}
        <i
          className={`material-icons arrowIcon ${
            toggleDropdown ? "rotate" : ""
          }`}
        >
          keyboard_arrow_down
        </i>
      </div>
    </div>
  );
};

export default DropdownButton;
