/* eslint-disable array-callback-return */
import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import WritingAssistantUtils from "Utils/WritingAssistantUtils";
import userDetail from "./userDetail";
import PaywallUtils from "../Utils/PaywallUtils";

let cancelGenerateSubject = null;
let cancelGenerateContent = null;
let cancelGenerateScore = null;
let cancelPromptContent = null;

export const defaultInputValue = { content: "", subject: "" };
class WritingAssistantData {
  @observable showSubjectLineGenerator = false;
  @observable subjectLinesList = null;
  @observable bodyContent = "";
  @observable loader = false;
  @observable isInvalidSubjectLine = false;
  @observable isReplyThread = false;
  @observable emotions = [];
  @observable prompts = [];
  @observable promptsLoader = false;
  @observable inputValue = { ...defaultInputValue };
  @observable attachmentUpdated = false;
  @observable taskBodyContent = "";
  @observable typewriteAnimation = false;
  @observable hideChatGpt = false;

  @action
  setShowSubjectLineGenerator(value) {
    this.showSubjectLineGenerator = value;
  }

  @action
  setSubjectLineList(value) {
    this.subjectLinesList = value;
  }

  @action
  setBodyContent(value = "") {
    this.bodyContent = value;
  }
  @action
  setTaskBodyContent(value = "") {
    this.taskBodyContent = value;
  }

  @action
  setLoader(value) {
    this.loader = value;
  }

  @action
  setIsInvalidSubjectLine(value) {
    this.isInvalidSubjectLine = value;
  }

  @action
  setIsReplyThread(value) {
    this.isReplyThread = value;
  }

  @action
  setPrompts = (list = []) => {
    this.prompts = list;
  };

  @action
  setEmotions = (list = []) => {
    this.emotions = list;
  };

  @action
  setPromptsLoader = (val = false) => {
    this.promptsLoader = val;
  };

  @action
  setInputValue = (obj = {}) => {
    this.inputValue = { ...obj };
  };

  @action
  setAttachmentUpdated = (value = "") => {
    this.attachmentUpdated = value;
  };

  @action
  setTypewriteAnimation = (value = false) => {
    this.typewriteAnimation = value;
  };

  @action
  setHideChatGpt = (value = false) => {
    this.hideChatGpt = value;
  };

  @action
  resetSubjectLinesGenerator() {
    if (!this.loader) {
      this.subjectLinesList = null;
      this.showSubjectLineGenerator = false;
      this.bodyContent = "";
      this.loader = false;
      this.isInvalidSubjectLine = false;
      this.isReplyThread = false;
    }
  }

  @action
  generateSubject = async (content = "", mixpanelProperty = {}) => {
    const { inFreePlan = false, planName = "" } =
      userDetail?.userFeatureFlag || {};
    if (content?.length) {
      let prefixValue = `Generate 3 simple subject lines for the below email?\n\n`;
      try {
        const config = {
          url: URLS.generateSubject,
          method: "POST",
          data: {
            content: prefixValue + content
          }
        };
        let response = await makeApi(config);
        if (response?.data?.options?.length) {
          const options = WritingAssistantUtils.constructSubjectLines(
            response?.data?.options
          );
          let isNotValid = WritingAssistantUtils.optionsHasFirstContentBody(
            options,
            content
          );
          if (isNotValid) {
            this.setSubjectLineList([]);
            this.setIsInvalidSubjectLine(true);
          } else {
            this.setIsInvalidSubjectLine(false);
            this.setSubjectLineList(options);
          }
          WritingAssistantUtils.triggerMpEvent({
            ...mixpanelProperty,
            content,
            status: "Success",
            options,
            response: response?.data?.options
          });
          this.setLoader(false);
          return;
        } else if (response?.response?.status === 401) {
          WritingAssistantUtils.triggerMpEvent({
            ...mixpanelProperty,
            content,
            status: "Failed",
            errorType: "401 - UNAUTHORIZED API TOKEN"
          });
          WritingAssistantUtils.showSupportMessagePopup();
        } else if (response?.response?.status === 403) {
          PaywallUtils.upgradeUtilsFunction(
            "subjectLineGenerator",
            false,
            planName,
            inFreePlan
          );
        } else {
          WritingAssistantUtils.triggerMpEvent({
            ...mixpanelProperty,
            content,
            status: "failed",
            errorType: "500"
          });
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "failure"
          );
        }
        this.setLoader(false);
      } catch (err) {
        console.error("Get AI subject keyword failed", err);
      }
    }
  };

  @action
  generateContent = async (data = {}) => {
    try {
      const config = {
        url: URLS.generateContent,
        method: "POST",
        data
      };
      if (cancelGenerateSubject !== null) {
        cancelGenerateContent("cancel");
        cancelGenerateContent = null;
      }
      const cancelReqCallback = value => {
        cancelGenerateContent = value;
      };
      return await makeApi(config, cancelReqCallback);
    } catch (err) {
      console.error("Generate content error", err);
    }
  };

  @action
  generateScore = async (data = {}) => {
    try {
      const config = {
        url: URLS.generateScore,
        method: "POST",
        data
      };
      if (cancelGenerateSubject !== null) {
        cancelGenerateScore("cancel");
        cancelGenerateScore = null;
      }
      const cancelReqCallback = value => {
        cancelGenerateScore = value;
      };
      return await makeApi(config, cancelReqCallback);
    } catch (err) {
      console.error("Generate score error", err);
    }
  };

  @action
  getPrompts = async () => {
    try {
      this.setPromptsLoader(true);
      const config = {
        url: URLS.getRewriteAiPrompts
      };
      if (cancelGenerateSubject !== null) {
        cancelPromptContent("cancel");
        cancelPromptContent = null;
      }
      const cancelReqCallback = value => {
        cancelPromptContent = value;
      };
      let res = await makeApi(config, cancelReqCallback);
      const { emotions = [], quickSelection = [] } = res?.data || {};
      this.setPrompts(quickSelection);
      this.setEmotions(emotions);
      this.setPromptsLoader(false);
    } catch (err) {
      console.error("Generate prompts error", err);
    }
  };
}

const writingAssistantStoreData = new WritingAssistantData();
export { writingAssistantStoreData };
export default writingAssistantStoreData;
