import React, { useState, useEffect, createContext } from "react";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import DateUtils from "Utils/DateUtils";
import userDetail from "Stores/userDetail";
import { accountsState } from "./AccountsState";
import FilterNavBar from "./FilterNavBar";
import AccountsTable from "./AccountsTable";
import AccountFilterByOption from "./AccountFilter/Main";
import { makeApi, URLS } from "Utils/apiURL";
import {
  DefaultFilterObject,
  DefaultAccountsNavbarObj,
  DefaultAccountsTableObj
} from "./AccountConstants";

const AccountsListingContext = createContext({});

const AccountsList = observer(() => {
  const [accountsNavbarObj, setAccountsNavbarObj] = useState({
    ...Utils.createObjWithoutRef(DefaultAccountsNavbarObj)
  });

  const [accountsTableObj, setAccountsTableObj] = useState({
    ...Utils.createObjWithoutRef(DefaultAccountsTableObj)
  });

  const [showAccountFilter, setShowAccountFilter] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState({
    ...Utils.createObjWithoutRef({ ...DefaultFilterObject }),
    owners: Utils.createObjWithoutRef(userDetail?.userInfo?.hasTeamMember) || []
  });
  const [filterList, setFilterList] = useState([]);
  const [allAccountsSelectedCheckbox, setAllAccountsSelectedCheckbox] =
    useState(false);
  const [allAccountsSelectedInView, setAllAccountsSelectedInView] =
    useState(false);

  const filterAccountsCbk = (e = "") => {
    let listElem = e?.target?.closest("li") || "";
    if (listElem) {
      let name = listElem?.getAttribute("data-key") || "";
      let id = listElem?.getAttribute("data-id") || "";
      setAccountsNavbarObj({
        ...accountsNavbarObj,
        activeTab: name,
        activeId: ""
      });
      setAccountsTableObj({
        ...accountsTableObj,
        activeTab: name,
        tableArr: [...accountsTableObj?.tableArr].map(item => ({
          ...item,
          isChecked: false
        }))
      });
      accountsState.setActiveId(id);
    }
    accountsState.setPageNo("");
    setAllAccountsSelectedCheckbox(false);
    setAllAccountsSelectedInView(false);
  };

  const getValidateList = (
    updatedFilterArr = [],
    updatedData = {},
    paramKey = ""
  ) => {
    if (paramKey) {
      return (
        updatedFilterArr?.length &&
        updatedFilterArr.includes(paramKey) &&
        updatedData?.[paramKey]?.length > 0
      );
    }
  };

  const getMemberIds = (updatedFilterArr = filterList, updatedData = data) => {
    let memberIds = [];
    const validateKey = getValidateList(
      updatedFilterArr,
      updatedData,
      "owners"
    );
    if (validateKey) {
      updatedData.owners.forEach(ele => {
        if (ele?.checked && !memberIds?.includes(ele?.memberId)) {
          memberIds.push(ele.memberId);
        }
      });
    }
    return memberIds;
  };

  const getFields = (updatedFilterArr = filterList, updatedData = data) => {
    return updatedFilterArr
      .filter(iterator =>
        ["accountfields", "emails", "tasks", "calls", "contacts"].includes(
          iterator?.toLowerCase()
        )
      )
      .flatMap(iterator => updatedData[iterator] ?? []);
  };

  const getDates = (updatedFilterArr = filterList, updatedData = data) => {
    const validateKey = getValidateList(updatedFilterArr, updatedData, "dates");
    if (validateKey) {
      return DateUtils.getDateFilterObj(data?.dates || []);
    }
    return "";
  };

  const getAccountFilterPayload = () => {
    let payload = {
      searchTerm: accountsState?.searchTerm || "",
      filterPayload: {
        memberIds: getMemberIds(filterList, data) || [],
        fields: getFields(filterList, data) || []
      }
    };
    let dates = getDates(filterList, data);
    if (dates && Object.keys(dates)?.length) {
      payload.filterPayload = { ...payload.filterPayload, ...dates };
    }
    return payload;
  };

  const getAccountStagesAnalytics = async (
    accountStageArr = [],
    tempPayload = {}
  ) => {
    let payload = Object.keys(tempPayload)?.length
      ? tempPayload
      : getAccountFilterPayload();
    let response = await accountsState.getAccountStagesAnalytics(payload);
    if (
      +response?.status === 200 &&
      response?.data &&
      accountStageArr?.length > 0
    ) {
      const { accountAnalytics = {} } = response?.data || {};
      let tempAccountAnalytics = accountStageArr.map(item => {
        return { ...item, count: accountAnalytics[item?.id] || 0 };
      });
      setAccountsNavbarObj({
        ...accountsNavbarObj,
        navBarLoading: false,
        filterArr: tempAccountAnalytics,
        activeTab: accountsNavbarObj?.activeTab || "All"
      });
    } else {
      setAccountsNavbarObj({ ...accountsNavbarObj, navBarLoading: false });
    }
  };

  const getAccountStages = async (payload = {}) => {
    setAccountsNavbarObj({ ...accountsNavbarObj, navBarLoading: true });
    const { status = "", data = [] } = await accountsState.getAccountStages();
    accountsState.setAccountStages(data || []);
    let accountStageArr = [];
    if (+status === 200 && data?.length > 0) {
      accountStageArr = [
        { name: "All", id: "TOTAL" },
        ...data.map(item => {
          return {
            name: item?.displayName || "",
            id: item?.id || "",
            count: 0
          };
        })
      ];
    } else {
      accountStageArr = [{ name: "All", id: "TOTAL" }];
    }
    getAccountStagesAnalytics(accountStageArr, payload);
  };

  const getAccountTablePayload = () => {
    let payload = {
      pageNo: accountsState?.pageNo || 1,
      searchTerm: accountsState?.searchTerm || "",
      limit: accountsState?.limit || 10,
      filterPayload: {
        stageId:
          accountsState?.activeId?.toLowerCase() === "total"
            ? ""
            : accountsState?.activeId || "",
        memberIds: getMemberIds(filterList, data),
        fields: getFields(filterList, data)
      }
    };
    let dates = getDates(filterList, data);
    if (dates && Object.keys(dates)?.length) {
      payload.filterPayload = { ...payload.filterPayload, ...dates };
    }
    return payload;
  };

  const getAccountsList = async (noLoader = false, tempPayload = {}) => {
    if (!noLoader) {
      setAccountsTableObj({
        ...accountsTableObj,
        tableLoading: true
      });
    }
    let payload = Object.keys(tempPayload)?.length
      ? tempPayload
      : getAccountTablePayload() || {};
    let response = await accountsState.getAccountsList(payload);
    if (
      response?.message?.message === "cancel" ||
      response?.message === "cancel"
    ) {
      console.error("Request cancelled");
    } else {
      if (+response?.status === 200 && response?.data) {
        const { totalAccounts = 1, totalPages = 1 } = response?.data || {};
        let tempAccounts = response?.data?.accounts || [];
        if (tempAccounts?.length > 0) {
          tempAccounts = tempAccounts.map(item => ({
            ...item,
            isChecked: false
          }));
        }
        setAccountsTableObj({
          ...accountsTableObj,
          tableArr: tempAccounts,
          tableLoading: false
        });
        accountsState.setTotalPage(totalPages);
        accountsState.setTotalAccounts(totalAccounts);
      } else {
        if (!noLoader) {
          setAccountsTableObj({
            ...accountsTableObj,
            tableLoading: false
          });
        }
      }
    }
  };

  const resetFilterCbk = () => {
    if (!filterList || filterList?.length === 0) {
      return false;
    }
    const tempObj = { ...data };
    setFilterList([]);
    const tempDefaultFilterObject = Utils.createObjWithoutRef({
      ...DefaultFilterObject
    });
    setData({
      ...tempDefaultFilterObject,
      owners: tempObj?.owners.map(item => ({ ...item, checked: false }))
    });
    accountsState?.pageNo !== 1 && accountsState.setPageNo(1);
    return true;
  };

  const applyFilterCbk = (payload = {}) => {
    accountsState?.pageNo !== 1
      ? accountsState.setPageNo(1)
      : getAccountsList(false, payload);
    getAccountStages(payload);
  };

  useEffect(() => {
    if (accountsState?.pageNo) {
      getAccountsList();
    }
  }, [accountsState?.pageNo]);

  useEffect(() => {
    if (accountsState?.activeId) {
      getAccountsList();
    }
  }, [accountsState?.activeId]);

  useEffect(() => {
    if (accountsState?.limit) {
      getAccountsList();
    }
  }, [accountsState?.limit]);

  useEffect(() => {
    getAccountStages();
    getAccountsList();
  }, []);

  return (
    <div
      className={`accountSection ${
        showAccountFilter ? "showAccountFilter" : ""
      }`}
    >
      <AccountsListingContext.Provider
        value={{
          accountsNavbarObj,
          setAccountsNavbarObj,
          accountsTableObj,
          setAccountsTableObj,
          data,
          setData,
          showAccountFilter,
          setShowAccountFilter,
          showFilter,
          setShowFilter,
          filterList,
          setFilterList
        }}
      >
        <div className="accountsList">
          <FilterNavBar
            type="accounts"
            {...accountsNavbarObj}
            filterAccountsCbk={filterAccountsCbk}
          />
          <AccountsTable
            getAccountsList={getAccountsList}
            allAccountsSelectedCheckbox={allAccountsSelectedCheckbox}
            setAllAccountsSelectedCheckbox={setAllAccountsSelectedCheckbox}
            allAccountsSelectedInView={allAccountsSelectedInView}
            setAllAccountsSelectedInView={setAllAccountsSelectedInView}
            getAccountStagesAnalytics={getAccountStagesAnalytics}
          />
        </div>
        {showAccountFilter && (
          <AccountFilterByOption
            applyFilterCbk={applyFilterCbk}
            resetFilterCbk={resetFilterCbk}
          />
        )}
      </AccountsListingContext.Provider>
    </div>
  );
});

export default AccountsList;
export { AccountsList, AccountsListingContext };
