import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { verifyBulkActionPerformedAndAllow } from "../DataSearchUtils";
import { dataSearchState } from "../state";

const CustomAccordion = observer(props => {
  const {
    title = "",
    children = "",
    index = "",
    activeIndex = "",
    setActiveIndex = () => {},
    filterCount = 0,
    action = "",
    setMaxHeight = false
  } = props || {};

  const [prevHeight, setHeightState] = useState("0px");
  const [activeClass, setActiveState] = useState("");

  const toggleAccordion = event => {
    Utils.preventDefaultFn(event);
    const perfromToggle = () => {
      setActiveIndex(index !== activeIndex ? index : null);
      setActiveState(activeClass === "" ? "active" : "");
      setHeightState(activeClass === "active" ? "0px" : `200px`);
    };
    if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
      verifyBulkActionPerformedAndAllow(perfromToggle);
    } else {
      perfromToggle();
    }
  };

  const addStyleCss = () => {
    return { padding: index === activeIndex ? "12px 0 6px" : "12px 0" };
  };

  useEffect(() => {
    if (index === activeIndex) {
      setActiveState(activeClass === "" ? "active" : "");
      setHeightState(`200px`);
    } else {
      setHeightState("0px");
    }
  }, [activeIndex]);

  return (
    <div
      className="customAccordion"
      style={{
        paddingBottom: index === activeIndex ? "5px" : 0,
        transition: "padding-bottom 0.4s ease"
      }}
    >
      <div className="customAccordionItem">
        <div
          className="customAccordionTitle"
          onClick={e => toggleAccordion(e)}
          style={addStyleCss()}
        >
          <div>{title}</div>
          <div className="flexCenter">
            {filterCount > 0 && action !== "dropdown" && (
              <div className="filterCount">{filterCount}</div>
            )}
            <span className="material-icons arrowIcon">
              {index === activeIndex
                ? "keyboard_arrow_up"
                : "keyboard_arrow_down"}
            </span>
          </div>
        </div>
        <div
          className="accordionContent"
          style={{
            maxHeight: `${
              setMaxHeight
                ? prevHeight === "0px"
                  ? prevHeight
                  : "max-content"
                : prevHeight
            }`,
            overflow: prevHeight === "200px" ? "unset" : "hidden"
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
});

export { CustomAccordion };
export default CustomAccordion;
