import React, { useContext } from "react";
import Utils from "Utils/utils";
import VariantToggle from "Pages/Dashboard/sequences/SequencePage/VariantToggle";
import ActionIcon from "Pages/Dashboard/settings/Calendar/Components/ActionIcon";
import { CalendarEventsContext } from "Pages/Dashboard/settings/Calendar/Pages/Events/Main";
import {
  EVENT_ACTION_ICON_LIST,
  DAYS
} from "Pages/Dashboard/settings/Calendar/Constants";
import { weekDays } from "Static/constant";

const EventCard = (props = {}) => {
  const {
    id = "",
    name = "",
    status = "INACTIVE",
    duration = "",
    loading = false,
    workingHours = []
  } = props || {};

  const { toggleEventAction = () => {}, handleEventAction = () => {} } =
    useContext(CalendarEventsContext);

  const handleIconAction = (event = "") => {
    let actionType = Utils.performActionByClosestDom(event, "i", "data-type");
    handleEventAction(actionType?.toLowerCase(), { ...props });
  };

  const handleToggleAction = (e, val, id) => {
    Utils.preventDefaultFn(e);
    toggleEventAction(val, id);
  };

  const getWorkingHoursKey = () => {
    let tempList = [];
    let keysList = Object.keys(workingHours);
    keysList.forEach(key => {
      tempList.push(weekDays[key]?.short);
    });
    return tempList?.length > 0 ? tempList?.join(", ") : "--";
  };

  return (
    <div
      className="eventCard"
      onClick={() => handleEventAction("edit", { ...props })}
    >
      <div onClick={e => Utils.preventDefaultFn(e)}>
        <VariantToggle
          id={id}
          label={name}
          loading={loading === "toggle"}
          action={handleToggleAction}
          isChecked={status === "ACTIVE"}
          callback={() => handleEventAction("edit", { ...props })}
        />
      </div>
      <span className="durationIntervalPeriod">
        {`${duration} min${duration > 1 ? "s" : ""} (${getWorkingHoursKey()})`}
      </span>
      <div className="eventActionIcon" onClick={e => handleIconAction(e)}>
        {EVENT_ACTION_ICON_LIST?.map(item => {
          if (
            item?.type?.toLowerCase() !== "link" ||
            (item?.type?.toLowerCase() === "link" && status === "ACTIVE")
          ) {
            return <ActionIcon {...item} key={item?.id} />;
          }
        })}
      </div>
    </div>
  );
};

export default EventCard;
