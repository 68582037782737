import React, { useState } from "react";
import { ReactComponent as DownArrow } from "Assets/svg/donw-arrow.svg";

let limits = [10, 25, 50, 100];

const PageLimit = props => {
  const {
    text = "Showing",
    limitPerPage = 10,
    cbk = () => {},
    totalCount = 0,
    customLimit = false
  } = props || {};

  let limitList = customLimit || limits;
  console.log("limitList: ", limitList);

  const [showDropdown, setDropdown] = useState(false);

  const changeLimit = value => {
    if (totalCount > 10) {
      cbk(value);
      setDropdown(false);
    }
  };

  return (
    (totalCount > 10 && (
      <>
        <div className="pageLimit">
          <span className="text">{text}</span>
          <span className="wrapIconValue" onClick={() => setDropdown(true)}>
            <span className="value">{limitPerPage}</span>
            <span className={`arrow ${showDropdown ? "activeArrow" : ""}`}>
              <DownArrow />
            </span>
          </span>
          {showDropdown && (
            <ul className="limitDropdown">
              {(limitList?.length > 0 &&
                limitList?.map(value => {
                  return (
                    <li
                      key={`limit${value}`}
                      onClick={() => changeLimit(value)}
                      className={`limitValue ${
                        (limitPerPage === value && "activeLimit") || ""
                      }`}
                    >
                      {value}
                    </li>
                  );
                })) ||
                ""}
            </ul>
          )}
        </div>
        {showDropdown && (
          <div
            className="pageLimitOverlay"
            onClick={() => setDropdown(false)}
          />
        )}
      </>
    )) ||
    ""
  );
};

export default PageLimit;
export { PageLimit };
