/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import MultiSelectDropdownWithInput from "Components/common/MultiSelectDropDownWithInput";
import { PreviewTags } from "Components/common/tags/PreviewTags";

const ContactTags = observer(props => {
  const {
    selectedTags,
    tagsList,
    showTagDropDown,
    setShowTagDropDown,
    setTagList,
    setSelectedTags,
    style = {}
  } = props;

  const updateSelectedTags = () => {
    const updatedTags = JSON.parse(JSON.stringify(tagsList));
    if (updatedTags?.length) {
      const tempArr = [];
      updatedTags.map(value => {
        const { checked } = value;
        if (checked) {
          tempArr.push(value);
        }
      });
      setSelectedTags(tempArr);
    }
  };

  const removeTag = removedTagId => {
    const tagListArr = JSON.parse(JSON.stringify(tagsList));
    const selectedTagList = JSON.parse(JSON.stringify(selectedTags));
    let tagListIdx = tagListArr.findIndex(x => x.id === removedTagId);
    if (tagListIdx > -1) {
      tagListArr[tagListIdx].checked = false;
      setTagList(tagListArr);
    }
    let selectedTagIdx = selectedTagList.findIndex(x => x.id === removedTagId);
    if (selectedTagIdx > -1) {
      selectedTagList.splice(selectedTagIdx, 1);
      setSelectedTags(selectedTagList);
    }
  };

  useEffect(() => {
    updateSelectedTags();
  }, [tagsList]);

  useEffect(() => {
    return () => {
      tagsList?.length > 0 && tagsList.map(item => (item.checked = false));
      setTagList(tagsList);
    };
  }, []);

  return (
    <div className="info contactTags">
      <h5 className="sectionTitle">Contact Tags</h5>
      <div className="parentWrapper" style={style}>
        <div className="sectionInputTagsCont">
          <MultiSelectDropdownWithInput
            label={"Select Tag"}
            options={tagsList}
            setOptions={setTagList}
            showOptions={showTagDropDown}
            setShowOptions={setShowTagDropDown}
          />
        </div>
        {selectedTags?.length > 0 && (
          <div className="previewTagWrapper">
            <PreviewTags selectedTags={selectedTags} removeTag={removeTag} />
          </div>
        )}
      </div>
    </div>
  );
});

export default ContactTags;
export { ContactTags };
