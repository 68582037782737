/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { STRIPE_KEY } from "Static/constant";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(STRIPE_KEY);

class NewCardPaymentState {
  @observable showPopup = false;
  @observable phoneNumber = "";
  @observable callBack = e => {};

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setCallBack = val => {
    this.callBack = val;
  };

  @action
  setPhoneNumber = val => {
    this.phoneNumber = val;
  };
}

const newCardPaymentState = new NewCardPaymentState();

const NewCardPaymentPopup = observer(props => {
  const [stripeToken, setStripeToken] = useState("");

  const closeAction = () => {
    setStripeToken("");
    newCardPaymentState.setPhoneNumber(false);
    newCardPaymentState.setShowPopup(false);
  };

  const purchaseBtnAction = (e, key) => {
    if (key === "back") {
      newCardPaymentState.callBack(null, null, key);
      closeAction();
    } else {
      document.querySelector(".newCardDetails .stripeElem").blur();
      setTimeout(() => {
        if (newCardPaymentState.callBack) {
          newCardPaymentState.callBack(
            newCardPaymentState.phoneNumber,
            stripeToken,
            key
          );
          closeAction();
        }
      }, 1000);
    }
  };

  return newCardPaymentState.showPopup ? (
    <div className="buycreditsPopup ccPopup popupWrapper">
      <div className="confirmation-popup common-popup">
        <div className="buyCreditsWrapper ccPopupWrapper popup-wrap animated customzoomIn">
          <div className="creditsCont">
            <div className="popupHeader">
              <div className="popupTitle">Enter Card Details</div>
              <div
                className="popup-close"
                title="Click here to close"
                onClick={closeAction}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
            <div className="popupBody">
              <div className="cardDetailsWrapper">
                <div className="newCardDetails">
                  <NewPaymentForm setStripeToken={setStripeToken} />
                </div>
              </div>
              <div className="actionWrapper">
                <div className="btncont">
                  <ActionBtn
                    phNumber={newCardPaymentState.phoneNumber}
                    actionCbk={purchaseBtnAction}
                  />
                </div>
                <span className="chargeText">
                  You will be charged $1.15 for purchasing a phone number
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

const NewPaymentForm = props => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm {...props} />
    </Elements>
  );
};

const PaymentForm = props => {
  const { setStripeToken } = props;
  const [cardErr, setCardErr] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const onBlurAction = async () => {
    const card = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(card, {});
    if (!error && token?.id) {
      setStripeToken(token.id);
      setCardErr("");
    } else if (error && error.message) {
      setCardErr(error.message);
    }
  };

  const focus = instance => {
    if (instance) {
      instance.focus();
    }
  };

  return (
    <div className="cardWrapper">
      <CardElement
        className="stripeElem"
        onBlur={onBlurAction}
        onReady={focus}
      />
      <span className="errMsgText">{cardErr}</span>
    </div>
  );
};

const ActionBtn = props => {
  const { actionCbk, phNumber } = props;
  return (
    <>
      <div className="backCont btn" onClick={e => actionCbk(e, "back")}>
        Back
      </div>
      <div
        className={`purchaseNumberBtn btn ${phNumber ? "" : "disabled"}`}
        onClick={e => actionCbk(e, "purchase")}
      >
        Buy Phone Number
      </div>
    </>
  );
};

export default NewCardPaymentPopup;
export { NewCardPaymentPopup, newCardPaymentState };
