import React, { useEffect } from "react";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export const DateRangePicker = props => {
  const {
    index = "",
    maxDate = 0,
    customDate = {},
    handleAction = () => {}
  } = props || {};

  const getMaxDate = () => {
    if (!maxDate) {
      return new Date();
    } else {
      return addDays(new Date(), parseInt(maxDate));
    }
  };

  const handleChange = item => {
    const { startDate = false, endDate = false } = item?.selection;
    let applyBtn = document.getElementById("applyCustomDateFilter");
    applyBtn && endDate && startDate && (applyBtn.disabled = false);
    handleAction("set", index, item?.selection);
  };

  const createApplyCancelButton = () => {
    const dom = document.querySelector(`.dateRangePicker`);
    const parentDom = document.createElement("div");
    parentDom.setAttribute("class", "dateBtnSection");
    const cancelBtn = document.createElement("input");
    cancelBtn.setAttribute("type", "button");
    cancelBtn.setAttribute("value", "Cancel");
    cancelBtn.setAttribute("class", "cancelCustomDateFilter");
    cancelBtn.setAttribute("id", "cancelCustomDateFilter");
    cancelBtn.addEventListener("click", e => {
      handleAction("cancel", index);
    });
    parentDom.appendChild(cancelBtn);
    const applyBtn = document.createElement("input");
    applyBtn.setAttribute("type", "button");
    applyBtn.setAttribute("value", "Apply");
    applyBtn.setAttribute("class", "applyCustomDateFilter");
    applyBtn.setAttribute("id", "applyCustomDateFilter");
    const { startDate = false, endDate = false } = customDate?.[0] || {};
    (!startDate || !endDate) && applyBtn.setAttribute("disabled", true);
    applyBtn.addEventListener("click", e => {
      handleAction("apply", index);
    });
    parentDom.appendChild(applyBtn);
    dom.appendChild(parentDom);
  };

  useEffect(() => {
    createApplyCancelButton();
  }, []);

  return (
    <DateRange
      className="dateRangePicker"
      editableDateInputs={false}
      onChange={handleChange}
      moveRangeOnFirstSelection={false}
      ranges={customDate}
      maxDate={getMaxDate()}
    />
  );
};

export default DateRangePicker;
