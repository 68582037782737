import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { FilterComponent } from "./filterComponent/FilterComponent";
import ContactSearchTable from "./contactTable/ContactSearchTable";
import { dataSearchState } from "./state";
import customListState from "./CustomList/state";
import InputTextBox from "Components/common/InputTextBox";
import Button from "Components/common/Button";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";

const DataSearch = observer(props => {
  const [tableLoading, setTableLoading] = useState(false);
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchId, setSearchId] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [savedListOptions, setSavedListOptions] = useState([]);
  const inputBoxRef = useRef();

  useEffect(() => {
    return () => {
      dataSearchState.setFilterComponentData({
        confidenceScore: [],
        excludeTeamOwns: true
      });
      dataSearchState.setSelectedFilteredList([]);
      dataSearchState.setContactTableData({});
      dataSearchState.setCurrentPage(1);
      dataSearchState.setFilterApplied(false);
      dataSearchState.setRangeSelected("");
      customListState.setActiveList("");
    };
  }, []);

  useEffect(() => {
    saveSearchConfirmationPopup();
  }, [editPopup]);

  const saveSearch = (savedpayload = {}) => {
    const { companyDomainArr = [], locationArr = [] } = savedpayload || {};
    let companies = [];
    if (dataSearchState?.selectedFilteredList?.companyDomain) {
      for (const iterator of dataSearchState?.selectedFilteredList
        ?.companyDomain) {
        let { id = "", name = "", primaryDomain = "" } = iterator;
        companies.push({ id, name, primaryDomain });
      }
    }
    let locations = [];
    if (dataSearchState?.selectedFilteredList?.location) {
      for (const iterator of dataSearchState?.selectedFilteredList?.location) {
        let { id = "", name = "", group = "", searchText = [] } = iterator;
        locations.push({ id, name, group, searchText });
      }
    }

    const config = {
      url: URLS.saveSearch,
      method: "POST",
      data: {
        searchName: searchInput,
        ...dataSearchState.filterComponentData,
        locationIds: locationArr,
        companyId: companyDomainArr,
        locations,
        companies
      }
    };
    return makeApi(config);
  };

  const locationAndCompanyDomainPayloadCreation = () => {
    const { companyDomain = [], location = [] } =
      dataSearchState?.selectedFilteredList || {};
    let companyDomainArr = [],
      locationArr = [];
    if (companyDomain && companyDomain?.length > 0) {
      for (const iterator of companyDomain) {
        companyDomainArr.push(iterator?.id);
      }
    }
    if (location && location?.length > 0) {
      for (const iterator of location) {
        locationArr.push(iterator?.id);
      }
    }
    return { companyDomainArr, locationArr };
  };

  const handleSavedSearch = async event => {
    setSaveLoader(true);
    let savedpayload = locationAndCompanyDomainPayloadCreation();
    let response = await saveSearch(savedpayload);
    if (response?.status === 200) {
      Utils.mixpanelTrack(MXP_EVENT.SAVE_SEARCH, {
        pageType: PAGE_TYPE.discover
      });
      toasterState.setToastMsg("Search saved succesfully", "success");
    } else {
      toasterState.setToastMsg(
        "save operation was unsuccessful,please try again later",
        "failure"
      );
    }
    setSaveLoader(false);
    setShowSavePopup(false);
  };

  const handleInputBoxEvent = event => {
    Utils.preventDefaultFn(event);
    if (Utils.isSubmitKeyAction(event)) {
      handleSavedSearch();
    }
  };

  const minimumSelectionMade =
    (dataSearchState?.selectedFilteredList &&
      Object.keys(dataSearchState?.selectedFilteredList).length === 0 &&
      dataSearchState?.filterComponentData &&
      Object.keys(dataSearchState?.filterComponentData).length <= 2) ||
    false;

  const inputProps = {
    name: showSavePopup ? "Save Search" : "Edit Search",
    id: showSavePopup ? "saveSearch" : "editsearch",
    placeHolder: showSavePopup ? "Save Search" : "Edit Search",
    type: "text",
    value: searchInput,
    change: (id, val) => {
      setErrorMessage("");
      setSearchInput(val);
    },
    cbk: e => {
      if (!checkDuplicateName()) {
        if (searchInput) {
          showSavePopup ? handleInputBoxEvent(e) : saveSearchAfterEditText(e);
        }
      } else {
        if (Utils.isSubmitKeyAction(e)) {
          setErrorMessage("Entered search already exists");
        }
      }
    },
    err: errorMessage,
    readOnly: false,
    inputBoxRef: inputBoxRef,
    className: `${searchInput ? "activeBorder" : ""}`
  };

  const btnProps = {
    id: showSavePopup ? "Saved Search Button" : "Edit Search Button",
    name: showSavePopup ? "savedSearchButton" : "editSearchButton",
    btnText: showSavePopup ? "Save Search" : "Edit search",
    type: "button",
    className: "saveButton",
    className: `saveButton ${saveLoader ? "loading" : ""}`,
    loader: saveLoader,
    click: e => {
      if (!checkDuplicateName()) {
        if (searchInput) {
          setErrorMessage("");
          showSavePopup ? handleSavedSearch(e) : editSavedSearch();
        }
      } else {
        setErrorMessage("Entered search already exists");
      }
    },
    disabled: !searchInput || (minimumSelectionMade && !editPopup)
  };

  const getSavedSearch = () => {
    const config = {
      url: URLS.getSavedSearch,
      method: "GET"
    };
    return makeApi(config);
  };

  const initialGetSearch = async () => {
    let response = await getSavedSearch();
    if (response?.status === 200) {
      setSavedListOptions(response?.data);
    }
  };

  const checkDuplicateName = () => {
    if (Array.isArray(savedListOptions)) {
      return savedListOptions?.some(item => {
        return item?.searchName === searchInput;
      });
    }
  };

  const editSavedSearch = async () => {
    let response = await editApiCall();
    setEditPopup(false);
    if (response?.status === 200) {
      Utils.mixpanelTrack(MXP_EVENT.EDIT_SEARCH, {
        pageType: PAGE_TYPE.discover
      });
      toasterState.setToastMsg("saved search edited successfully", "success");
    } else {
      toasterState.setToastMsg("saved search edit was unsuccessful", "failure");
    }
  };

  const editApiCall = () => {
    const config = {
      url: URLS.editSearch,
      method: "POST",
      data: { id: searchId, name: searchInput }
    };
    return makeApi(config);
  };

  const saveSearchAfterEditText = e => {
    Utils.preventDefaultFn(e);
    if (Utils.isSubmitKeyAction(e)) {
      editSavedSearch();
    }
  };

  const editCallBack = (id = "", name = "") => {
    setErrorMessage("");
    setSearchInput(name);
    setSearchId(id);
    setEditPopup(true);
  };

  useEffect(() => {
    if (showSavePopup) {
      setSearchInput("");
      setErrorMessage("");
    }
  }, [showSavePopup]);

  useEffect(() => {
    if (showSavePopup || editPopup) {
      initialGetSearch();
    }
  }, [showSavePopup, editPopup]);

  const saveSearchConfirmationPopup = () => {
    let displayText = editPopup ? "Edit Search" : "Save Search";
    return (
      <section className="createSavedSearchPopupWrapper">
        <div className="card">
          <div className="popupHeader">
            <div className="title">{displayText}</div>
            <i
              className="material-icons commonPopupCloseIcon"
              onClick={() =>
                editPopup ? setEditPopup(false) : setShowSavePopup(false)
              }
            >
              close
            </i>
          </div>
          <InputTextBox {...inputProps} />
          <Button {...btnProps} />
        </div>
        <div
          className="common-popup-mask"
          onClick={() => setShowSavePopup(false)}
        />
      </section>
    );
  };

  return (
    <section className="dataSearchWrapper">
      <div className="dataSearchFilter">
        <FilterComponent
          setTableLoading={setTableLoading}
          setShowSavePopup={setShowSavePopup}
          saveSearchConfirmationPopup={saveSearchConfirmationPopup}
          setEditPopup={setEditPopup}
          setSearchId={setSearchId}
          searchInput={searchInput}
          editCallBack={editCallBack}
          minimumSelectionMade={minimumSelectionMade}
          savedListOptions={savedListOptions}
          setSavedListOptions={setSavedListOptions}
        />
      </div>

      {(showSavePopup || editPopup) && saveSearchConfirmationPopup()}

      <div className="dataSearchTable">
        <ContactSearchTable
          setTableLoading={setTableLoading}
          tableLoading={tableLoading}
        />
      </div>
    </section>
  );
});

export { DataSearch };
export default DataSearch;
