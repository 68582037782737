import React from "react";
import Button from "Components/common/Button";
import NoTriggerImg from "Assets/png/noTrigger.png";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import userDetail from "Stores/userDetail";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import PaywallUtils from "Utils/PaywallUtils";

const EmptyTrigger = props => {
  const { triggersEnabled = false, createCbk = () => {} } = props || {};
  const { planName = "", inFreePlan = false } =
    userDetail?.userFeatureFlag || {};

  return (
    <div className="emptyTrigger">
      <img src={NoTriggerImg} alt="" />
      <h6>Create your first Trigger</h6>
      <div className="createBtn">
        <Button
          id="newTriggerBtn"
          name="newTriggerBtn"
          btnText="New Trigger"
          type="button"
          className={`addTrigger`}
          click={triggersEnabled ? createCbk : () => {}}
        />
        {!triggersEnabled && (
          <span className="freemiumIconTooltip">
            <UpgradeIconTooltip
              plan={PaywallUtils.getPlanName(planName, inFreePlan)}
              type="triggers"
              enableTooltip={true}
              alignTooltip="btmRight"
              showIcon={true}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default EmptyTrigger;
export { EmptyTrigger };
