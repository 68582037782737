import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Tooltip from "Components/common/CustomTooltip";
import SendingWindow from "Pages/Dashboard/settings/Schedules/SendingWindow";

export const ScheduleWorkingHours = observer((props = {}) => {
  const {
    workingHours = [],
    err = "",
    updateInputState = () => {}
  } = props || {};

  const [showToolTip, setShowToolTip] = useState(false);

  const scheduleActionCallback = (data = {}) => {
    updateInputState("workingHours", data);
  };

  return (
    <div className="scheduleWorkingHour">
      <div className="labelTitle">
        <span>Set Working Hours</span>
        <span
          className="workingHoursInfoIcon"
          onMouseOver={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
        >
          <i className="material-icons">info_outline</i>
          {showToolTip && (
            <Tooltip text="Let your contacts book meetings only during your work hours." />
          )}
        </span>
      </div>
      <SendingWindow
        scheduleSendingWindowCbk={scheduleActionCallback}
        dailyTimeRange={workingHours}
      />
      {err && <span className="errMsgText">{err}</span>}
    </div>
  );
});

export default ScheduleWorkingHours;
