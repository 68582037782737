import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import FilterDropdown from "Pages/Dashboard/settings/Triggers/AddEditTrigger/FilterDropdown";
import PreviewSequenceTemplate from "Components/common/PreviewSequenceTemplate";
import InputTextBox from "Components/common/InputTextBox";
import Button from "Components/common/Button";
import TemplateDataStore from "Stores/Template";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { addStagesPopupState } from "./AddStagesPopupState";

class TestEmailPopupState {
  @observable showPopup = false;
  @observable cbk = () => {};
  @observable defaultValues = {};
  @observable fromAddress = {};
  @observable toAddress = {};

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
  };

  @action
  setCbk = (val = () => {}) => {
    this.cbk = val;
  };

  @action
  setDefaultValues = (val = {}) => {
    this.defaultValues = val;
  };

  @action
  setFromAddress = (val = {}) => {
    this.fromAddress = val;
  };

  @action
  setToAddress = (val = {}) => {
    this.toAddress = val;
  };
}

const testEmailPopupState = new TestEmailPopupState();

const FromAddress = observer(() => {
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(0);

  const handleFilterClick = (key = "", value = {}) => {
    testEmailPopupState.setFromAddress(value);
    setActiveDropdownIndex(0);
  };

  const getCustomOptions = () => {
    const connectedInboxes = userDetail?.connectedInboxes || [];
    if (connectedInboxes?.length > 0) {
      connectedInboxes.map(item => {
        item.value = item?.email;
        item.method = handleFilterClick;
        return item;
      });
    }
  };

  useEffect(() => {
    testEmailPopupState.setFromAddress(userDetail?.connectedInboxes?.[0]);
  }, [userDetail?.connectedInboxes]);

  useEffect(() => {
    getCustomOptions();
  }, []);

  return (
    <div className="fromAddressWrapper">
      <span className="fromFieldHeader">From</span>
      <FilterDropdown
        options={userDetail?.connectedInboxes}
        hasLabel={false}
        defaultValue={testEmailPopupState?.fromAddress?.email}
        emptyText="There are no contact stages"
        showDropdown={activeDropdownIndex === `testEmailPopup_0`}
        index={`testEmailPopup_0`}
        setActiveDropdownIndex={setActiveDropdownIndex}
      />
    </div>
  );
});

const ToAddress = observer(() => {
  const changeInputVal = (id = "", value = "") => {
    testEmailPopupState.setToAddress({
      ...testEmailPopupState?.toAddress,
      err: "",
      value
    });
  };

  const inputProps = {
    name: "toField",
    id: "toField",
    text: "",
    type: "text",
    value: testEmailPopupState?.toAddress?.value || "",
    change: changeInputVal,
    err: testEmailPopupState?.toAddress?.err || "",
    readOnly: false
  };

  return (
    <div className="toAddressWrapper">
      <span className="toFieldHeading">To</span>
      <InputTextBox {...inputProps} />
    </div>
  );
});

const TestEmailPopup = observer(() => {
  const [sentBtnLoader, setSentBtnLoader] = useState(false);

  const closePopup = () => {
    if (testEmailPopupState?.defaultValues?.isFromTemplates) {
      testEmailPopupState.defaultValues.backBtnCbk();
      setDefaultValueForTemplates();
    } else {
      addStagesPopupState.setShowStepEmailEditorPopup(true);
      setDefaultValues();
    }
    testEmailPopupState.setShowPopup(false);
    testEmailPopupState.setFromAddress({});
    testEmailPopupState.setToAddress({});
  };

  const setDefaultValues = () => {
    const inputVal = {
      ...addStagesPopupState.popupValues.inputVal,
      ...testEmailPopupState?.defaultValues,
      content: testEmailPopupState?.defaultValues?.contentBody
    };
    addStagesPopupState.setPopupValues({
      ...addStagesPopupState?.popupValues,
      inputVal
    });
  };

  const setDefaultValueForTemplates = () => {
    const inputVal = {
      ...testEmailPopupState?.defaultValues,
      content: testEmailPopupState?.defaultValues?.contentBody
    };
    TemplateDataStore.setOverviewSelectedTemplate({
      ...TemplateDataStore?.overviewSelectedTemplate,
      ...inputVal
    });
  };

  const handleBackBtn = () => {
    testEmailPopupState.setShowPopup(false);
    if (testEmailPopupState?.defaultValues?.isFromTemplates) {
      testEmailPopupState.defaultValues.backBtnCbk();
      setDefaultValueForTemplates();
    } else {
      addStagesPopupState.setShowStepEmailEditorPopup(true);
      setDefaultValues();
    }
  };

  const sendTestEmail = () => {
    const toAddress = testEmailPopupState?.toAddress?.value || "";
    let isValidEmail = Utils.emailValidate(
      toAddress?.trim()?.toLowerCase() || ""
    );
    if (isValidEmail) {
      testEmailPopupState.setToAddress({
        ...testEmailPopupState?.toAddress,
        err: ""
      });
      setSentBtnLoader(true);
      if (testEmailPopupState?.defaultValues?.isFromTemplates) {
        setDefaultValueForTemplates();
      } else {
        setDefaultValues();
      }
      testEmailPopupState.cbk(setSentBtnLoader);
    } else {
      testEmailPopupState.setToAddress({
        ...testEmailPopupState?.toAddress,
        err: "Please provide valid email address"
      });
    }
  };

  const buttonList = [
    {
      id: "manualEmailBackBtn",
      name: "manualEmailBackBtn",
      btnText: "Back",
      className: `manualEmailBackBtn`,
      type: "button",
      click: handleBackBtn,
      loader: false,
      value: "backBtn",
      show: true
    },
    {
      id: "sendTestEmailBtn",
      name: "sendTestEmailBtn",
      btnText: "Send Test Email",
      className: `sendTestEmailBtn ${sentBtnLoader ? "loading" : ""}`,
      type: "button",
      click: sendTestEmail,
      loader: sentBtnLoader,
      show: true
    }
  ];

  return (
    testEmailPopupState.showPopup && (
      <>
        <div className="testEmailPopupWrapper">
          <div className={`testEmailCard animated customzoomIn`}>
            <div className="testEmailHeader">
              <p>Send Test Email</p>
              <span className="material-icons closePopup" onClick={closePopup}>
                close
              </span>
            </div>
            <div className="testEmailBody">
              <FromAddress />
              <ToAddress />
              <PreviewSequenceTemplate
                contentBody={testEmailPopupState?.defaultValues?.contentBody}
                attachments={
                  testEmailPopupState?.defaultValues?.attachments || []
                }
                subject={testEmailPopupState?.defaultValues?.subject}
                currentStage={testEmailPopupState?.defaultValues?.currentStep}
                replyAsThread={
                  testEmailPopupState?.defaultValues?.replyAsThread || false
                }
                hasChangeInHTMLEditor={
                  testEmailPopupState?.defaultValues?.hasChangeInHTMLEditor ||
                  false
                }
                hasTitle={false}
                dynamicInboxId={true}
                fromAddress={testEmailPopupState?.fromAddress}
              />
            </div>
            <div className="footerAction">
              {buttonList.map(
                item => item.show && <Button key={item.id} {...item} />
              )}
            </div>
          </div>
        </div>
        <div className="testEmailPopupOverlay" />
      </>
    )
  );
});

export { TestEmailPopup, testEmailPopupState };
export default TestEmailPopup;
