import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import { toasterState } from "Components/common/toaster";
import InputBox from "Components/Register/InputBox";
import makeApi, { URLS } from "Utils/apiURL";
import { googleOAuthPopupState } from "../googleOAuthPopup";
import connectedInboxStore from "../store";

const AppPasswordCredentials = observer(() => {
  const [changeValue, setChangeValue] = useState({
    userName: googleOAuthPopupState?.inboxData?.userName,
    email: googleOAuthPopupState?.inboxData?.email
  });
  const [error, setError] = useState({});
  const [appPwd, setAppPwd] = useState({
    password: "",
    showPassword: false
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const validAppPwd = async () => {
    let config = {
      url: `${URLS.connectAppPwd}?inboxId=${googleOAuthPopupState?.inboxData?.id}`,
      method: "POST",
      data: {
        senderName: "",
        email: changeValue?.email,
        senderName: changeValue?.userName,
        password: appPwd?.password
      }
    };
    const res = await makeApi(config);
    const { data = {} } = res || {};
    const code = data?.code || "";
    const warmingCommunityMsgShown =
      await connectedInboxStore.getWarmingCommunityMsgData();
    if (+code === 200) {
      if (warmingCommunityMsgShown) {
        connectedInboxStore.enableOrDisableWarming(
          googleOAuthPopupState?.inboxData,
          connectedInboxStore?.inboxScreentype === "one" ? "one" : "two"
        );
        googleOAuthPopupState.setShowPopup(false);
        googleOAuthPopupState.setScreenNo("one");
        googleOAuthPopupState.setConnectConfig("oAuth");
      } else {
        googleOAuthPopupState.setScreenNo("");
        connectedInboxStore.setFirstTimeEnableWarming(true);
      }
    } else {
      setBtnLoading(false);
      if (+code === 424) {
        toasterState.setToastMsg(
          "Invalid credentials. Please try again.",
          "failure"
        );
      } else if (+code === 425) {
        toasterState.setToastMsg(
          "Unable to connect. Did you complete all previous steps?",
          "failure"
        );
      } else if (+code === 412) {
        toasterState.setToastMsg(
          "Please connect the same email account",
          "failure"
        );
      } else {
        toasterState.setToastMsg("Something went wrong", "failure");
      }
    }
  };

  const formData = [
    {
      name: "userName",
      id: "userName",
      label: "Sender Name",
      placeholder: "Enter sender name"
    },
    {
      name: "email",
      id: "email",
      label: "Email address",
      placeholder: "Email address to connect",
      required: true,
      readOnly: true
    },
    {
      name: "password",
      id: "password",
      type: "password",
      label: "App Password",
      placeholder: "Enter your 16 character app password",
      required: true,
      isPassword: true,
      showPasswordAction: () => {
        setAppPwd({
          ...appPwd,
          showPassword: !appPwd?.showPassword
        });
      }
    }
  ];

  const clickLoginBtn = async () => {
    if (!changeValue?.email) {
      setError({ ...error, email: "Please provide valid email address" });
    }
    if (!appPwd?.password) {
      setError({ ...error, password: "Please provide valid password" });
    } else if (changeValue?.email && appPwd?.password) {
      setError("");
      setBtnLoading(true);
      validAppPwd();
    }
  };

  const btnProps = [
    {
      id: "back",
      name: "backBtn",
      icon: false,
      type: "button",
      className: "backBtn",
      loader: false,
      btnText: "Back",
      click: () => googleOAuthPopupState.setAppPasswordScreen("two")
    },
    {
      id: "proceedBtn",
      name: "proceedBtn",
      icon: false,
      type: "button",
      className: `proceedBtn ${btnLoading ? "loading" : ""}`,
      loader: btnLoading,
      btnText: "Connect",
      click: () => clickLoginBtn()
    }
  ];

  const handleChangeAction = (value, id) => {
    if (changeValue?.email) {
      setError({ ...error, email: "" });
    }
    if (appPwd?.password) {
      setError({ ...error, password: "" });
    }
    if (id === "password") {
      setAppPwd({ ...appPwd, password: value });
    } else {
      setChangeValue({ ...changeValue, [id]: value });
    }
  };

  useEffect(() => {
    if (
      googleOAuthPopupState?.inboxData &&
      Object.keys(googleOAuthPopupState?.inboxData)?.length > 0
    ) {
      setChangeValue({
        userName: googleOAuthPopupState?.inboxData?.userName,
        email: googleOAuthPopupState?.inboxData?.email
      });
    }
  }, [googleOAuthPopupState?.inboxData]);

  return (
    <>
      <div className="initialScreenBody">
        {formData.map((item, index) => {
          const { id = "" } = item || {};
          return (
            <InputBox
              changeAction={handleChangeAction}
              className={`inputBoxForAppPwd ${
                id?.toLowerCase() === "email" ? "emailInput" : ""
              } `}
              {...item}
              value={id === "password" ? appPwd : changeValue?.[item?.name]}
              errMsg={error?.[item?.name]}
              key={index}
            />
          );
        })}
      </div>
      <div className="oAuthFooter">
        <div className="actionBtns">
          {btnProps.map((item, index) => {
            return <Button {...item} key={index} />;
          })}
        </div>
      </div>
    </>
  );
});

export { AppPasswordCredentials };
export default AppPasswordCredentials;
