/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import EmailOutlineImg from "Assets/Pricing/emailOutline.png";
import { Utils } from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { ReactComponent as SalesgearLogo } from "Assets/logo/svg/logo.svg";
import EmailShapeImg from "Assets/png/signupSuccess.png";
import { ENTERPRISE_FEATURE as supportContact } from "Static/constant";
import PhoneOutlineImg from "Assets/Pricing/phoneOutline.png";
import QuestionOutlineImg from "Assets/Pricing/questionOutline.png";
import userDetail from "Stores/userDetail";

const SignupSuccessPage = observer(props => {
  let location = useLocation();
  const { state } = location;

  const redirectUrlObj = {
    pricing: "/pricing",
    sequences: "/sequences/all",
    plugin: "/pluginSuccess"
  };

  const getSupportImg = id => {
    if (id === "phone") {
      return PhoneOutlineImg;
    } else if (id === "email") {
      return EmailOutlineImg;
    } else if (id === "questions") {
      return QuestionOutlineImg;
    }
  };

  const btnObj = {
    // phone: "tel:+1(415)8532917",
    email: `mailto:sales@salesgear.io`
  };

  const constructLandingRedirect = state => {
    if (userDetail.isTeamCreated) {
      localStorage.removeItem("isWelcomePopupShown");
      if (state) {
        let queryParams = Utils.getQueryParams(state);
        let pathValue =
          redirectUrlObj[queryParams.get("redirect") || "dashboard"] ||
          "/dashboard";
        localStorage.removeItem("isWelcomePopupShown");
        localStorage.removeItem("newUserSignUp");
        props.history.push(
          `${pathValue}${
            location.search
              ? location.search.concat("&type=signup")
              : "?type=signup"
          }`
        );
      } else {
        if (location?.search?.includes("redirect=discover")) {
          props.history.push("/discover");
        } else {
          props.history.push("/dashboard");
        }
      }
    }
  };

  useEffect(() => {
    constructLandingRedirect(state);
    Utils.mixpanelTrack(MP_EVENT.SIGNUP_SUCCESS);
  }, []);

  useEffect(() => {
    constructLandingRedirect(state);
  }, [userDetail?.isTeamCreated]);

  useEffect(() => {
    state && constructLandingRedirect(state);
  }, [state]);

  return (
    <div className="signupSuccessPage">
      <div className="pageHeader">
        <div className="container logoImgText">
          <SalesgearLogo />
        </div>
      </div>
      <div className="pageBody container">
        <div className="cardBox">
          <img src={EmailShapeImg} alt="Success" />
          <h4>Thank you for verifying your email!</h4>
          <i className="fa fa-spinner fa-spin spinnerFillIcon"></i>
          <p>We are setting up your account.</p>
        </div>
        <div className="supportContactSignup">
          <p className="supportText">
            Can't wait to close deals faster? Talk to us
          </p>
          <div className="supportList">
            {supportContact.map(item => {
              if (item.id !== "questions") {
                return (
                  <div
                    className="card"
                    id={item.id}
                    key={`supportContact${item.id}`}
                    onClick={() => (window.location.href = btnObj[item.id])}
                  >
                    <img src={getSupportImg(item.id)} alt="" />
                    <div>
                      <h6 className="name">{item.name}</h6>
                      <p className="value">{item.value}</p>

                      <span className="material-icons hoverArrowForward">
                        arrow_right_alt
                      </span>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default withRouter(SignupSuccessPage);
