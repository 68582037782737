/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import FieldMappingDropDown from "./FieldMappingDropDown";
import Utils from "Utils/utils";
import { CONTACT_BULK_DROPDOWN_DEFAULT_OPTION } from "Static/constant";

const BulkContactTable = props => {
  const {
    contactTableData,
    setUserInteracted,
    mappingFields,
    setMappingFields,
    dropDownoptionsList,
    retainUserMappedFields
  } = props;
  const { actualCsvHeader = {} } = contactTableData || {};
  const [extractedContactData, setExtractedContactData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);

  const constructContactDataFields = contactData => {
    const finalArr = [];
    if (contactData?.data) {
      let contactFields = contactData.data || [];
      let headerMap = contactData.headerMap || {};
      Object.keys(headerMap).map(objectKey => {
        const tempObj = {};
        const tempArr = [];
        contactFields.map(value => {
          tempArr.push(value[objectKey] || "");
        });
        tempObj[objectKey] = tempArr;
        finalArr.push(tempObj);
      });
    }
    setExtractedContactData(finalArr);
  };

  const mapCustomFieldsInContactData = contactTableData => {
    const contactData = Utils.createObjWithoutRef(contactTableData);
    if (Object.keys(contactData).length > 0) {
      let headerMap = contactData?.headerMap || {};
      let contactFields = contactData?.data || [];
      let customMapping = contactData?.customMapping || {};
      if (customMapping && Object.keys(customMapping)?.length) {
        Object.keys(customMapping).map(objectKey => {
          if (objectKey) {
            const loweredObjectKey = objectKey.toLowerCase();
            if (headerMap[objectKey]) {
              headerMap = changeKeyName(
                headerMap,
                headerMap[objectKey],
                customMapping[objectKey]
              );
            } else if (headerMap[loweredObjectKey]) {
              headerMap = changeKeyName(
                headerMap,
                headerMap[loweredObjectKey],
                customMapping[objectKey]
              );
            }
            contactFields.map((contactObj, index) => {
              if (contactObj) {
                if (contactObj[objectKey]) {
                  const updatedContactObj = changeKeyName(
                    contactObj,
                    objectKey,
                    customMapping[objectKey]
                  );
                  contactFields[index] = updatedContactObj;
                } else if (contactObj[loweredObjectKey]) {
                  const updatedContactObj = changeKeyName(
                    contactObj,
                    loweredObjectKey,
                    customMapping[objectKey]
                  );
                  contactFields[index] = updatedContactObj;
                }
              }
            });
          }
        });
        contactData.headerMap = headerMap;
        if (!retainUserMappedFields) {
          //  *******************
          //  We should retain the user mapped fields if back button is clicked. If we allow here, then user mapped fields will not be retained and default data will be populated in table
          // ********************
          constrcutMappingFields(contactData);
        }
      } else {
        contactData.headerMap = headerMap;
        if (!retainUserMappedFields) {
          //  *******************
          //  We should retain the user mapped fields if back button is clicked. If we allow here, then user mapped fields will not be retained and default data will be populated in table
          // ********************
          constrcutMappingFields(contactData);
        }
      }
      setCsvHeader(headerMap);
    }
    return contactData;
  };

  const changeKeyName = (oldObj, oldKey, newKey) => {
    if (oldObj && oldKey && newKey) {
      const keys = Object.keys(oldObj);
      const newObj = keys.reduce((acc, val) => {
        if (val === oldKey) {
          acc[newKey] = oldObj[oldKey];
        } else {
          acc[val] = oldObj[val];
        }
        return acc;
      }, {});
      return newObj;
    }
  };

  const constrcutMappingFields = contactData => {
    if (Object.keys(contactData).length > 0) {
      const tempArr = [];
      let headerMap = contactData?.headerMap || {};
      let contactFields = contactData?.data || [];
      Object.keys(headerMap).map(keyVal => {
        let isValidField = false;
        contactFields.map(value => {
          if (value[keyVal]?.length) {
            isValidField = true; // Only show dropdown if data field is present
          }
        });

        const tempObj = {
          systemMappedField: keyVal,
          userMappedField: keyVal,
          mappedLabel: findIsMappingField(keyVal)
            ? headerMap[keyVal]
            : CONTACT_BULK_DROPDOWN_DEFAULT_OPTION,
          isValidField,
          showOptions: false
        };
        if (!isValidField) {
          tempObj.mappedLabel = CONTACT_BULK_DROPDOWN_DEFAULT_OPTION;
        }
        tempArr.push(tempObj);
      });
      setMappingFields(tempArr);
    }
  };

  const findIsMappingField = keyVal => {
    return Utils.extractObjectKeysFromArrayOfObject(
      dropDownoptionsList
    ).includes(keyVal);
  };

  const isMappedAlready = (e, selectedFieldLabel) => {
    if (selectedFieldLabel) {
      const { currentTarget } = e && e;
      let isMapped = false;
      const dropDownId =
        currentTarget &&
        currentTarget.parentElement &&
        currentTarget.parentElement.parentElement &&
        currentTarget.parentElement.parentElement.getAttribute("id");
      let totalCsvCol = document.querySelectorAll(".selectedField");
      const specialCharEscapedId = CSS.escape(dropDownId);
      const selectedFields =
        totalCsvCol?.length > 1
          ? document.querySelectorAll(
              `.selectedField:not([id$='${specialCharEscapedId}'])`
            )
          : [];
      selectedFields?.length &&
        Array.from(selectedFields).map(value => {
          if (value) {
            const innerText = value.innerText.trim();
            if (innerText.toLowerCase() === selectedFieldLabel.toLowerCase()) {
              isMapped = true;
            }
          }
        });

      return isMapped;
    }
  };

  const revisitInvalidMappedFields = tempList => {
    if (tempList) {
      const mappedLabelArray = [];
      const fields = Utils.createObjWithoutRef(tempList);
      const selectedFields = document.querySelectorAll(".selectedField");
      const arrayDom = Array.from(selectedFields);

      arrayDom.map(value => {
        if (value && value.innerText !== CONTACT_BULK_DROPDOWN_DEFAULT_OPTION) {
          const replacedInnerText = value.innerText
            .toLowerCase()
            .split(" ")
            .join("");
          mappedLabelArray.push(replacedInnerText);
        }
      });

      const duplicates = Utils.findDuplicatesInArray(mappedLabelArray);

      if (duplicates && duplicates.length) {
        fields.map(value => {
          const { invalidMap, mappedLabel } = value;
          if (invalidMap && mappedLabel) {
            if (
              duplicates.includes(mappedLabel.toLowerCase().split(" ").join(""))
            ) {
              value.invalidMap = true;
              toggleDisableUploadBtn("add");
            } else {
              value.invalidMap = false;
              toggleDisableUploadBtn("remove");
            }
          }
        });
      } else {
        fields.map(value => {
          value.invalidMap = false;
          toggleDisableUploadBtn("remove");
        });
      }
      setMappingFields(fields);
    }
  };

  const updateMapping = (
    e,
    systemMappedField,
    selectedFieldId,
    userMappedLabel
  ) => {
    setUserInteracted(true);
    const tempList = Utils.createObjWithoutRef(mappingFields);
    let isMapped = false;
    if (selectedFieldId !== "empty") {
      isMapped = isMappedAlready(e, userMappedLabel);
    }
    tempList &&
      tempList.map(value => {
        if (value && value.systemMappedField === systemMappedField) {
          value.showOptions = false;
          value.userMappedField = selectedFieldId;
          value.mappedLabel = userMappedLabel;
          if (isMapped) {
            toggleDisableUploadBtn("add");
            value.invalidMap = true;
          } else {
            value.invalidMap = false;
            toggleDisableUploadBtn("remove");
          }
        }
      });
    setMappingFields(tempList);
    setTimeout(() => {
      revisitInvalidMappedFields(tempList);
    }, 100);
  };

  const toggleTableScroll = booleanValue => {
    if (booleanValue) {
      document.querySelector(".bulkContactTable").classList.add("scrollHidden");
    } else {
      document
        .querySelector(".bulkContactTable")
        .classList.remove("scrollHidden");
    }
  };

  const updateShowOptions = (systemMappedField, booleanValue) => {
    toggleTableScroll(booleanValue);
    const tempList = Utils.createObjWithoutRef(mappingFields);
    tempList &&
      tempList.map(value => {
        if (value && value.systemMappedField === systemMappedField) {
          value.showOptions = booleanValue;
        }
      });
    setMappingFields(tempList);
  };

  const toggleDisableUploadBtn = flag => {
    if (flag === "add") {
      document.querySelector("#uploadBulkContact").classList.add("disabled");
    } else {
      document.querySelector("#uploadBulkContact").classList.remove("disabled");
    }
  };

  useEffect(() => {
    const updatedContactData = mapCustomFieldsInContactData(contactTableData);
    constructContactDataFields(updatedContactData);
  }, [contactTableData]);

  return (
    <div className="bulkContactTable">
      <CSVFields headerMap={csvHeader} actualCsvHeader={actualCsvHeader} />
      <MappingFields
        data={mappingFields}
        updateMapping={updateMapping}
        updateShowOptions={updateShowOptions}
        optionsList={dropDownoptionsList}
      />
      <DataFields data={extractedContactData} headerMap={csvHeader} />
    </div>
  );
};

const CSVFields = props => {
  const { headerMap = "", actualCsvHeader = {} } = props || {};

  return Object.keys(headerMap).length > 0 ? (
    <div className="csvFieldsColumn">
      <div className="columnTitle">CSV Header</div>
      {Object.keys(headerMap).map(ObjectKey => {
        const value =
          Object.keys(actualCsvHeader).find(
            key => actualCsvHeader[key] === ObjectKey
          ) || headerMap[ObjectKey];
        return (
          <div
            className="csvFieldColumn columnData"
            id={ObjectKey}
            key={`csvFields ${ObjectKey}`}
          >
            {value}
            {["email", "emailaddress"].includes(
              value?.toLowerCase()?.replace(/\s/g, "")
            ) && <span className="required">*</span>}
          </div>
        );
      })}
    </div>
  ) : (
    ""
  );
};

const MappingFields = props => {
  return (
    <div className="csvFieldsColumn">
      <div className="columnTitle">Salesgear Contact Field</div>
      <FieldMappingDropDown {...props} />
    </div>
  );
};

const DataFields = props => {
  const { data, headerMap } = props || {};

  const getFieldValueString = (fieldValueArray = []) => {
    let finalString = "";
    const arrayLen = fieldValueArray.length;
    if (fieldValueArray) {
      fieldValueArray.map((value, index) => {
        finalString += value;
        if (index + 1 !== arrayLen) {
          if (fieldValueArray[index + 1].length) {
            finalString += ", ";
          }
        }
      });
    }
    return finalString;
  };

  return data.length > 0 ? (
    <div className="csvDataColumn">
      <div className="columnTitle">Data Preview</div>

      {Object.keys(headerMap).map(headerObjectKey => {
        return data.map((ObjectValue, index) => {
          const valueArr = ObjectValue[headerObjectKey];
          if (valueArr) {
            return (
              <div
                className="csvFieldDataColumn columnData"
                id={headerObjectKey}
                key={`csvFields ${headerObjectKey + index}`}
              >
                {getFieldValueString(valueArr)}
              </div>
            );
          }
        });
      })}
    </div>
  ) : (
    ""
  );
};

export { BulkContactTable };
export default BulkContactTable;
