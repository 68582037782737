/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import TemplateDataStore from "Stores/Template";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { TemplatesContext } from "./TemplateModule";
import Utils from "Utils/utils";
import CloneTemplate from "./CloneTemplate";
import TemplateList from "./TemplateList";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const TemplateTableComponent = props => {
  const {
    templates,
    total,
    deleteCbk,
    cloneCbk,
    editCbk,
    setTemplateList,
    changeShareType,
    editTemplateLoader,
    setEditTemplateLoader,
    sortIconStatus,
    sortByTemplateName,
    templateHeader
  } = props;
  const { isTemplatesCreationAllowed } = useContext(TemplatesContext);

  const {
    noOfTemplatesEligible = 0,
    noOfTemplatesUsed = 0,
    inFreePlan,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templateName, setTemplateName] = useState();
  const [loader, setLoader] = useState(TemplateDataStore.rowLoader);
  const [cloneTemplateFlag, setCloneTemplateFlag] = useState(false);
  const [templateShareType, setTemplateShareType] = useState("");

  const editTemplate = async (e, value) => {
    Utils.preventDefaultFn(e);
    if (await checkTeamSubscriptionActive()) {
      editCbk(value, "Edit");
    }
  };

  const deleteTemplate = async () => {
    showTemplateOptions("", selectedTemplate, false);
    if (await checkTeamSubscriptionActive()) {
      deleteCbk(selectedTemplate?.id);
    }
  };

  const cloneTemplate = async () => {
    showTemplateOptions("", selectedTemplate, false);
    if (await checkTeamSubscriptionActive()) {
      if (isTemplatesCreationAllowed) {
        const { accessType = "", shareType = "" } = selectedTemplate || {};
        setTemplateName(`Copy - ${selectedTemplate.name}`);
        setCloneTemplateFlag(true);
        setTemplateShareType(shareType === "PRIVATE" ? shareType : accessType);
      } else {
        PaywallUtils.upgradeUtilsFunction("templates", planName, inFreePlan, {
          templateCount: noOfTemplatesEligible
        });
      }
    }
  };

  const showTemplateOptions = (event, template, value, templatesData) => {
    Utils.preventDefaultFn(event);
    const templateObj = templatesData || templates;
    templateObj.map(res => {
      if (res?.id === template?.id) {
        res.parentPosition = event && event.pageY;
        if (value) {
          setSelectedTemplate({});
          res.templateOptions = false;
        } else {
          res.templateOptions = !res.templateOptions;
        }
        setSelectedTemplate(res);
      }
    });
    setTemplateList(JSON.parse(JSON.stringify(templateObj)));
  };

  const singleTableLoader = () => {
    return (
      <div className="template-table-row-loader" key="singleTemplateLoader">
        {templateHeader.map(item => {
          const { key = "", className = "" } = item || {};
          return (
            <div key={key} className={className}>
              <div className="linear-background" />
            </div>
          );
        })}
      </div>
    );
  };

  const changeOnTemplateName = event => {
    if (event !== undefined && event !== null) {
      const { value } = event.currentTarget;
      // eslint-disable-next-line no-undef
      setTemplateName(value);
    }
  };

  const createTemplate = () => {
    setCloneTemplateFlag(false);
    setTemplateShareType("");
    TemplateDataStore.setRowLoader(true);
    cloneCbk(
      {
        ...selectedTemplate,
        name: templateName
      },
      templateShareType
    );
  };

  const cancelTemplate = () => {
    setTemplateShareType("");
    setCloneTemplateFlag(false);
  };

  const focusOnInput = event => {
    if (event) event.target.select();
  };

  const updateEditFlagByTemplateId = templateId => {
    if (templates && templateId) {
      templates.map(value => {
        if (value && value.id === templateId) {
          const { isEdit } = value;
          if (isEdit) {
            value.isEdit = false;
          } else {
            value.isEdit = true;
          }
        }
      });
      setTemplateList(JSON.parse(JSON.stringify(templates)));
      return templates;
    }
  };

  const closeTemplateEditOverlay = () => {
    if (templates) {
      templates.map(value => {
        if (value && value.id) {
          value.isEdit = false;
          value.templateOptions = false;
          value.parentPosition = 0;
        }
      });
      setTemplateList(JSON.parse(JSON.stringify(templates)));
    }
  };

  const radioOnchangeAction = (e, type) => {
    setTemplateShareType(type);
  };

  const editTemplateCancelAction = e => {
    Utils.preventDefaultFn(e);
    closeTemplateEditOverlay();
    setTemplateShareType("");
    setTemplateName("");
  };

  const changeTemplateType = templateId => {
    const updatedTemplatesData = updateEditFlagByTemplateId(templateId);
    showTemplateOptions("", selectedTemplate, false, updatedTemplatesData);
  };

  const updateShareType = e => {
    Utils.preventDefaultFn(e);
    setEditTemplateLoader(true);
    TemplateDataStore.setOverviewSelectedTemplate(selectedTemplate);
    changeShareType(
      selectedTemplate,
      templateShareType,
      templateName,
      closeTemplateEditOverlay
    );
    setTemplateShareType("");
    setTemplateName("");
  };

  useEffect(() => {
    TemplateDataStore.getFolderDetails(TemplateDataStore.folderId);
  }, []);

  useEffect(() => {
    setLoader(TemplateDataStore.rowLoader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TemplateDataStore.rowLoader]);

  return (
    <div
      className={`template-table-section ${total > 10 ? "tempPageWithHt" : ""}`}
    >
      <div className="template-table-header">
        {templateHeader.map(value => {
          const { name = "", key = "", className = "" } = value || {};
          return (
            <div key={key} className={className}>
              <span>{name}</span>
              {name === "Name" && templates?.length > 1 && (
                <i
                  className={`material-icons activeSort ${
                    sortIconStatus === "ASC"
                      ? "sortAscending"
                      : "sortDescending"
                  }`}
                  onClick={() => {
                    sortByTemplateName("NAME", sortIconStatus);
                  }}
                >
                  sort
                </i>
              )}
            </div>
          );
        })}
      </div>
      <div className="template-table-body">
        {cloneTemplateFlag && (
          <CloneTemplate
            cloneTemplate={createTemplate}
            focusOnInput={focusOnInput}
            changeOnTemplateName={changeOnTemplateName}
            cancelTemplate={cancelTemplate}
            templateName={templateName}
            radioOnchangeAction={radioOnchangeAction}
            showCreateSeqLoader={false}
            templateShareType={templateShareType}
          />
        )}
        {loader && singleTableLoader()}
        <TemplateList
          templates={templates}
          editTemplate={editTemplate}
          showTemplateOptions={showTemplateOptions}
          changeTemplateType={changeTemplateType}
          cloneTemplate={cloneTemplate}
          deleteTemplate={deleteTemplate}
          updateTemplate={updateShareType}
          focusOnInput={focusOnInput}
          radioOnchangeAction={radioOnchangeAction}
          editTemplateLoader={editTemplateLoader}
          changeOnTemplateName={changeOnTemplateName}
          editTemplateCancelAction={editTemplateCancelAction}
        />
      </div>
    </div>
  );
};

export default observer(TemplateTableComponent);
