import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import QuilEditor from "Components/common/quillEditor";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import DatePickerComponent from "Components/common/dateTimePicker";
import taskStoreData from "Stores/taskData";
import writingAssistantStoreData from "Stores/WritingAssistantData";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import { settingsStoreData } from "Stores/settingsData";
import { MP_EVENT } from "Static/constant";
import ButtonComponent from "Components/common/Button";
import {
  checkTeamSubscriptionActive,
  fetchConnectedEmails
} from "Utils/commonAPI";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import SiblingButton from "./SiblingButton";
import InputTag from "./InputTag";
import { DateUtils } from "Utils/DateUtils";
import ScheduleLaterDateTimeBanner from "Components/PreviewPopup/ScheduleLaterDateTimeBanner";
import TaskUtils from "Utils/TaskUtils";
import NavTaskButton from "./NavTaskButton";

export const EmailTaskComponent = observer((props = {}) => {
  const {
    fromAddress = "",
    sequenceEmail = false,
    inboxId = "",
    data = {},
    toAddress = "",
    skipConfirmation = false,
    currentTaskId = "",
    inboxList = [],
    isCompletedTask = false,
    isSkippedTask = false,
    showSnoozeTaskPopup = false,
    isPreviousBtnEnabled = false,
    isNextBtnEnabled = false,
    setSkipConfirmation = () => {},
    validateBlockedDomain = () => {},
    completeTaskThroughApi = () => {},
    handleTaskFailure = () => {},
    markSkippedTaskFn = () => {},
    setSelectedTaskFn = () => {},
    handleTaskSuccess = () => {}
  } = props || {};

  const { taskDue = "" } = data || {};
  const defaultInputState = {
    notes: data.note,
    toAddress: toAddress,
    fromAddress: fromAddress,
    bodyContent: data?.bodyContent || data?.content || "",
    subject: data?.subject || "",
    attachments: data?.attachments || [],
    inboxId: ""
  };

  const [inputVal, setInputVal] = useState({ ...defaultInputState });
  const [btnLoader, setBtnLoader] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [showScheduledDate, setShowScheduledDate] = useState(false);
  const [updatedContent, setUpdatedContent] = useState("");
  const [isSubjectFocused, setSubjectFocused] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const markAsComplete = async (event, inputVal) => {
    Utils.preventDefaultFn(event);
    if (!validateBlockedDomain()) {
      if (await checkTeamSubscriptionActive()) {
        if (!btnLoader) {
          if (TaskUtils.isFileUploadingComplete()) {
            if (!fromAddress && !Utils.isAccountConnected()) {
              const message = "Please connect your inbox before sending emails";
              Utils.showAddAccountPopup(props, message);
              return false;
            } else {
              const tempObj = {
                id: currentTaskId,
                type: data && data.taskType,
                email: toAddress,
                userEdit: true,
                scheduledOnComplete: showScheduledDate
              };
              if (
                Object.keys(tempObj)?.length > 0 &&
                ["email", "manualemail"].includes(
                  tempObj?.type?.toLowerCase() || ""
                )
              ) {
                if (!Utils.isAccountConnected()) {
                  const message =
                    "Please connect your email before starting a task.";
                  Utils.showAddAccountPopup(props, message);
                  return false;
                }
                const taskDueDate = showScheduledDate
                  ? scheduledDate
                  : new Date();
                const customEmailData = {
                  subject: inputVal.subject,
                  content: inputVal.bodyContent,
                  attachments: taskStoreData?.taskAttachments || [],
                  trackerId: data.trackerId
                };
                tempObj.timeZone = Utils.getCurrentUserTimeZone();
                tempObj.dueDate = Utils.createScheduleDate(taskDueDate);
                tempObj.dueDateString = Utils.createScheduleDate(taskDueDate);
                tempObj.customEmailData = customEmailData;
                tempObj.inboxId = sequenceEmail ? inboxId : inputVal?.inboxId;
              }
              let splittedTimezone =
                tempObj?.timeZone?.replace("UTC", "") || "";
              tempObj.dueDateString = `${tempObj.dueDateString}${splittedTimezone}`;
              try {
                setBtnLoader(true);
                window.loading = true;
                const response = await completeTaskThroughApi(tempObj);
                setBtnLoader(false);
                window.loading = false;
                if (
                  response?.status === 200 ||
                  response?.response?.status === 200
                ) {
                  Utils.mixpanelTrack(MP_EVENT.TK_COMPLETED, {
                    taskType: tempObj.type,
                    pageType: "Start Task"
                  });
                  handleTaskSuccess(inputVal);
                } else if (response?.response?.status === 401) {
                  toasterState.setToastMsg(
                    "Failed to send email. Please reconnect your inbox and try again.",
                    "fail"
                  );
                  await fetchConnectedEmails();
                } else if (response?.response?.status === 404) {
                  toasterState.setToastMsg(
                    "Please assign your inbox to the sequence to send the manual email.",
                    "fail"
                  );
                  await fetchConnectedEmails();
                } else {
                  handleTaskFailure(response);
                  await fetchConnectedEmails();
                }
              } catch (e) {
                console.error("Task email sending failed", e);
              }
            }
          }
        }
      }
    }
  };

  const changeInputVal = (id = "", value = "") => {
    const stateObj = { ...inputVal };
    stateObj[id] = value;
    if (id?.toLowerCase() === "subject" && !isSubjectFocused) {
      setSubjectFocused(true);
    }
    setInputVal(stateObj);
  };

  const bodyOnchangeEvnt = (content = "") => {
    const stateObj = { ...inputVal };
    isSubjectFocused && setSubjectFocused(false);
    stateObj.bodyContent = content;
    writingAssistantStoreData.setTaskBodyContent(content);
    setInputVal(stateObj);
  };

  const getNotesBoxHeight = () => {
    let box = document.getElementById("notesBox");
    let more = document.getElementById("readMore");
    let noteHt =
      box.clientHeight !== undefined ? box.clientHeight : box.offsetHeight;
    if (noteHt > 18) {
      box.style.overflow = "hidden";
      box.style.width = "calc(100% - 70px)";
      box.style.height = "16px";
      box.style.opacity = 1;
      box.style.visibility = "unset";
      more.style.display = "block";
    } else {
      box.style.opacity = 1;
      box.style.visibility = "unset";
      box.style.width = "100%";
    }
  };

  const readMore = () => {
    let box = document.getElementById("notesBox");
    let less = document.getElementById("readLess");
    let more = document.getElementById("readMore");
    let noteCont = document.getElementById("notesCont");
    noteCont.style.alignItems = "flex-start";
    box.style.width = "100%";
    box.style.height = "auto";
    noteCont.style.transition = "height 0.3s ease";
    less.style.display = "inline-block";
    box.style.opacity = 1;
    more.style.display = "none";
  };

  const readLess = () => {
    let noteCont = document.getElementById("notesCont");
    let box = document.getElementById("notesBox");
    let less = document.getElementById("readLess");
    let more = document.getElementById("readMore");
    noteCont.style.alignItems = "center";
    less.style.display = "none";
    box.style.width = "calc(100% - 70px)";
    box.style.height = "14px";
    noteCont.style.transition = "height 0.3s ease";
    more.style.display = "block";
  };

  const checkAndRenderOptOutIcon = () => {
    if (data?.stage?.toLowerCase() === "opted_out") {
      return <i className="material-icons optOutIcon">block</i>;
    }
  };

  const isManualEmailSkipEnabled = () => {
    return !btnLoader && data?.taskType === "manualEmail";
  };

  const handleManualEmailSkipSuccess = (response = {}) => {
    if (TaskUtils.isFileUploadingComplete()) {
      const { nextTask = false } = response || {};
      markSkippedTaskFn(currentTaskId, null);
      setSelectedTaskFn("next");
      if (!data.eligibleToSkipStage) {
        toasterState.setToastMsg(
          "Task skipped and the sequence has been stopped for this contact",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          nextTask
            ? "Step skipped and next step in sequence scheduled"
            : "Step skipped and sequence completed",
          "success"
        );
      }
    }
  };

  const skipManualEmailTask = async () => {
    if (await checkTeamSubscriptionActive()) {
      if (TaskUtils.isFileUploadingComplete()) {
        const tempObj = {
          id: currentTaskId
        };
        try {
          const config = {
            url: URLS.skipSequenceStage,
            method: "POST",
            data: tempObj
          };
          const response = await makeApi(config);
          if (response) {
            confirmationPopupState.setShowPopup(false);
            const { success = false } = response?.data || {};
            if (response?.status === 200) {
              success
                ? handleManualEmailSkipSuccess(response.data)
                : toasterState.setToastMsg(
                    "Failed to move to next step. Please try again later",
                    "failure"
                  );
            } else {
              Utils.showApiFailureResponse(response);
            }
          }
        } catch (e) {
          handleTaskFailure();
        }
      }
    }
  };

  const showConfirmationPrompt = () => {
    if (!data.eligibleToSkipStage) {
      confirmationPopupState.setShowPopup(false);
      setSkipConfirmation(true);
    } else {
      skipManualEmailTask();
    }
  };

  const confirmSkipManualTask = () => {
    if (TaskUtils.isFileUploadingComplete()) {
      confirmationPopupState.setPopupValues({
        content: skipConfirmation
          ? "Skipping this task will stop the sequence for this contact as the next step in this sequence is a threaded email. Please confirm to proceed."
          : "Are you sure you want to skip this step?",
        actionBtnText: skipConfirmation ? "Confirm" : "Yes",
        callback: skipConfirmation
          ? skipManualEmailTask
          : showConfirmationPrompt,
        loadingMsg: "Skipping steps. Please wait..."
      });
      setSkipConfirmation("");
      confirmationPopupState.setShowPopup(true);
    }
  };

  const handleDateChange = (date = "") => {
    if (!validateBlockedDomain() && !btnLoader) {
      let value = DateUtils.compareInputDateTimeWithCurrentDateTime(
        new Date(date)
      );
      setScheduledDate(!value ? new Date(taskDue) : new Date(date));
      setShowScheduledDate(value);
    }
  };

  const removeScheduledDateToExist = () => {
    setShowScheduledDate(false);
    setScheduledDate(data?.taskDue || new Date());
  };

  const constructFullContent = (content = "", signature = "") => {
    const mainDom = document.createElement("div");
    mainDom.innerHTML = content || "";
    if (data?.status?.toLowerCase() === "active") {
      const signatureDom = document.createElement("div");
      signatureDom.innerHTML = signature || data?.signature || "";
      const unSubDom = document.createElement("div");
      const unSubLink = settingsStoreData?.unsubscribeData?.unsubLink || "";
      const insertUnsubLink =
        settingsStoreData?.unsubscribeData?.insertUnsubLink || false;
      if (unSubLink && insertUnsubLink) {
        unSubDom.innerHTML = `${unSubLink}`;
      }
      if (mainDom?.firstChild) {
        mainDom.firstChild.insertAdjacentHTML(
          "beforeend",
          `<div><br></div><div>${
            signatureDom.outerHTML
          }</div><div><br></div><div>${
            unSubLink && insertUnsubLink ? unSubDom.innerHTML : ""
          }</div>`
        );
      } else {
        mainDom.innerHTML = signature || data?.signature || "";
        if (mainDom?.firstChild) {
          mainDom.firstChild.insertAdjacentHTML(
            "beforeend",
            `<div><br></div><div>${
              unSubLink && insertUnsubLink ? unSubDom?.innerHTML || "" : ""
            }</div>`
          );
        }
      }
    }
    setUpdatedContent(mainDom);
  };

  const changeFromAddress = emailObj => {
    const { email = "", signature = "", id = "" } = emailObj || {};
    taskStoreData.setUpdatedFromAddressObj({ email, signature, id });
    let tempInputVal = {
      ...inputVal,
      fromAddress: email,
      inboxId: id
    };
    setInputVal({ ...tempInputVal });
    setShowDropdown(!showDropdown);
  };

  const updateEditorContent = ({ subject = "", content = "" }) => {
    let updatedContent = `<div className="ql-editor" contenteditable="true">
          ${content}
        </div>`;
    let stateObj = { ...inputVal };
    let tempSubject = inputVal?.subject?.toLowerCase() || "";
    if (tempSubject?.indexOf("re:") < 0 && subject?.trim()?.length) {
      stateObj.subject = subject;
    }
    isSubjectFocused && setSubjectFocused(false);
    setInputVal({
      ...stateObj,
      content: updatedContent
    });
    constructFullContent(updatedContent);
  };

  const updateAttachments = (uploadedAttachments = []) => {
    if (uploadedAttachments?.length > 0) {
      let newAttachments = [];
      let existingAttIdList = [];
      if (taskStoreData?.taskAttachments?.length > 0) {
        [...taskStoreData?.taskAttachments].forEach(item => {
          existingAttIdList.push(item?.id);
        });
      }
      [...uploadedAttachments].forEach(item => {
        if (
          existingAttIdList?.length === 0 ||
          !existingAttIdList.includes(item?.attachments?.id)
        ) {
          newAttachments.push(item?.attachments);
        }
      });
      let attachments = [...taskStoreData.taskAttachments, ...newAttachments];
      let totalSize = 0;
      [...attachments].forEach(item => {
        totalSize += item?.attachmentSizeInKB || 0;
      });
      fileAttachmentStore.setTaskTotalFileSizeUploaded(
        Math.ceil(totalSize / 1024) || 0
      );
      taskStoreData.setTaskAttachments(attachments);
      setInputVal({ ...inputVal, attachments });
    }
  };

  const updateFileAttachementStore = (removedAttId = "") => {
    let tempFileStoreAtt = [
      ...fileAttachmentStore.performTaskFileAttachments
    ].filter(item => item?.attachments?.id !== removedAttId);
    fileAttachmentStore.setPerformTaskFileAttachments(tempFileStoreAtt);
  };

  const removeAttachment = (removedAttId = "") => {
    if (taskStoreData?.taskAttachments?.length > 0) {
      let totalSize = 0;
      taskStoreData.setRemovedAttachmentId("");
      let tempList = [...taskStoreData.taskAttachments].filter(item => {
        const { id = "", attachmentSizeInKB = "" } = item || {};
        if (id !== removedAttId) {
          totalSize += attachmentSizeInKB || 0;
          return item;
        }
      });
      fileAttachmentStore.setTaskTotalFileSizeUploaded(
        Math.ceil(totalSize / 1024) || 0
      );
      taskStoreData.setTaskAttachments([...tempList]);
      setInputVal({ ...inputVal, attachments: tempList });
      updateFileAttachementStore(removedAttId);
    }
  };

  useEffect(() => {
    getNotesBoxHeight();
  }, [inputVal.notes]);

  useEffect(() => {
    skipConfirmation && confirmSkipManualTask();
  }, [skipConfirmation]);

  useEffect(() => {
    setScheduledDate(new Date(taskDue) || new Date());
    setShowScheduledDate(false);
    constructFullContent(data?.bodyContent || data?.content);
  }, [data, settingsStoreData.unsubscribeData]);

  useEffect(() => {
    const { signature = "", id = "" } =
      taskStoreData?.updatedFromAddressObj || {};
    if (signature) {
      constructFullContent(data?.bodyContent || data?.content, signature);
    }
  }, [taskStoreData.updatedFromAddressObj]);

  useEffect(() => {
    updateAttachments(fileAttachmentStore?.performTaskFileAttachments || []);
  }, [fileAttachmentStore?.performTaskFileAttachments]);

  useEffect(() => {
    taskStoreData?.removedAttachmentId &&
      removeAttachment(taskStoreData.removedAttachmentId);
  }, [taskStoreData?.removedAttachmentId]);

  useEffect(() => {
    if (!sequenceEmail) {
      let updatedInbox = {};
      if (
        taskStoreData?.updatedFromAddressObj &&
        Object.keys(taskStoreData?.updatedFromAddressObj)?.length > 0
      ) {
        updatedInbox = taskStoreData?.updatedFromAddressObj;
      } else if (inboxList?.length > 0) {
        updatedInbox = inboxList?.[0];
      }
      const { email = "", signature = "", id = "" } = updatedInbox || {};
      setInputVal({
        ...inputVal,
        fromAddress: email,
        inboxId: id,
        signature: signature
      });
      constructFullContent(data?.bodyContent || data?.content, signature);
      taskStoreData.setUpdatedFromAddressObj({ email, signature, id });
    }
  }, []);

  return (
    <form
      className={`emailTask ${
        showScheduledDate ? "adjustScheduledDateToase" : ""
      }`}
      autoComplete="off"
    >
      {showScheduledDate && (
        <ScheduleLaterDateTimeBanner
          scheduledDate={scheduledDate}
          closeCbk={removeScheduledDateToExist}
        />
      )}
      <div className="userInputCont notesCont" id="notesCont">
        <label className="notesLabel">Notes :</label>
        <div className="notesWrap">
          <div className="notesText animated" id="notesBox">
            {inputVal.notes}{" "}
            <div onClick={e => readLess(e)} className="readLess" id="readLess">
              Read less
            </div>
          </div>
          <div onClick={e => readMore(e)} className="readMore" id="readMore">
            Read more
          </div>
        </div>
      </div>
      <div className="fromAddressCont userInputCont">
        <label className="toAddressLabel">From :</label>
        {inboxList?.length > 0 && !sequenceEmail && (
          <>
            <a
              className="fromEmailText highlightBlue"
              href={`mailto:${taskStoreData?.updatedFromAddressObj?.email}`}
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              {taskStoreData?.updatedFromAddressObj?.email}
            </a>
            <span className="selectInboxDropdownSection">
              <i
                className="material-icons-outlined dropdownArrow"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                {showDropdown ? "expand_less" : "expand_more"}
              </i>
              {showDropdown && (
                <>
                  <div className="drop-down-menu animatedFast">
                    <ul
                      className="dropdownList selectInboxDropdown"
                      id="selectInboxDropdown"
                    >
                      {inboxList.map((item, index) => {
                        const { email = "" } = item || {};
                        return (
                          <li
                            key={`connectedInboxList${index}`}
                            id={`${email}${index}`}
                            onClick={() => changeFromAddress(item)}
                            className={`option ${
                              email === inputVal?.fromAddress
                                ? "alreadySelected"
                                : ""
                            }`}
                          >
                            {email}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div
                    className="connectedInboxOverlay"
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                </>
              )}
            </span>
          </>
        )}
        {sequenceEmail && (
          <InputTag
            name="fromAddress"
            id="fromAddress"
            placeholder="From Email Address"
            type="email"
            value={fromAddress}
            readOnly={true}
          />
        )}
      </div>
      <div className="toAddressCont userInputCont">
        <label className="toAddressLabel">To :</label>
        {checkAndRenderOptOutIcon()}
        <InputTag
          name="toAddress"
          id="toAddress"
          isReadOnly={true}
          placeholder="Prospect Email Address"
          type="email"
          value={inputVal.toAddress}
          onBlurEvent={changeInputVal}
        />
      </div>
      <div className="subjectCont userInputCont">
        <label className="subjectLabel">Subject :</label>
        <InputTag
          name="subject"
          type="text"
          id="subject"
          placeholder="Subject"
          value={inputVal.subject}
          onBlurEvent={changeInputVal}
          keyUp={e => {
            Utils.isSubmitKeyAction(e) && markAsComplete(e, inputVal);
          }}
        />
      </div>
      <div
        className={`performTaskManualEmailEditor contentCont editorParent reactunsubscribeEditor ${
          inputVal.fromAddress ? "emailEditorAdHt" : ""
        } ${
          taskStoreData?.taskAttachments?.length > 0 ? "taskAttachments" : ""
        }`}
        id="reactEditor"
      >
        <QuilEditor
          isSubjectFocused={isSubjectFocused}
          bodyOnChange={bodyOnchangeEvnt}
          attachments={inputVal?.attachments || []}
          prefilledValue={updatedContent?.innerHTML || ""}
          unSubLink={true}
          fromTaskPage={true}
          showRewriteAI={true}
          updateContentCbk={updateEditorContent}
          pageType="task"
          stageType="manualEmail"
        />
      </div>
      <div className="actionCont">
        <div className="btnWrapper">
          <div className={`sendbtnCont ${btnLoader ? "loading" : ""}`}>
            {isCompletedTask ? (
              <div className="completedBtn">
                <i className="material-icons tickIcon">check</i>
                <span className="completedButton">Completed</span>
              </div>
            ) : isSkippedTask ? (
              <div className="completedBtn">
                <i className="material-icons tickIcon">check</i>
                <span className="completedButton">Skipped</span>
              </div>
            ) : (
              <>
                <ButtonComponent
                  id="sendComplete"
                  name="sendComplete"
                  type="button"
                  className={`taskPerformActionBtn ${
                    isManualEmailSkipEnabled() ? "hasSiblingBtn" : ""
                  }`}
                  btnText={`${
                    btnLoader
                      ? ""
                      : `${showScheduledDate ? "Schedule" : "Send"} & Complete`
                  }`}
                  click={e => markAsComplete(e, inputVal)}
                />
                {btnLoader && !isSkippedTask && (
                  <div className="knobs scheduledDateLoader" />
                )}
                {isManualEmailSkipEnabled() && (
                  <SiblingButton cbk={confirmSkipManualTask} />
                )}
                <div className="wrapScheduleDateIconSymbol">
                  <span className="material-icons-outlined scheduleDatePicekerIcon">
                    schedule
                  </span>
                  <DatePickerComponent
                    getDateCbk={handleDateChange}
                    id="scheduleDatePicker"
                    value={scheduledDate}
                  />
                </div>
              </>
            )}
          </div>
          {!isCompletedTask && !isSkippedTask && (
            <>
              <div
                className="snoozeCont whiteButton btn"
                onClick={showSnoozeTaskPopup}
              >
                <i className="material-icons">snooze</i>
                <span className="snoozeTxt">Snooze</span>
              </div>
            </>
          )}
        </div>
        <NavTaskButton
          setSelectedTaskFn={setSelectedTaskFn}
          isPreviousBtnEnabled={isPreviousBtnEnabled}
          isNextBtnEnabled={isNextBtnEnabled}
          id={currentTaskId}
          pageType="PERFORM_TASK"
          taskType="manualemail"
        />
      </div>
    </form>
  );
});

export default EmailTaskComponent;
