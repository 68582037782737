import { observable, action } from "mobx";
import Constant from "Static/constant";
import { MP_EVENT } from "Static/constant";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";

const defaultPopupValue = {
  btnText: "Next",
  currentStep: 0,
  isEdit: false,
  inputVal: {
    attachments: [],
    content: "",
    daysLater: 1,
    notes: "",
    replyAsThread: true,
    subject: "",
    timeZone: "",
    status: "ACTIVE"
  },
  notes: "",
  text: "",
  subject: "",
  daysLater: 1,
  previousDaysLater: 0,
  stageType: "email",
  defaultTask: Constant.linkedinInSubmenu[0].subKey,
  defaultLiAutomaticTask: Constant.liAutomationObj?.[1]?.subKey,
  variantStep: 1,
  isVariantChange: false,
  daysFromStart: 0,
  steps: {},
  seqId: "",
  name: "",
  isFromTriggers: false,
  pageSource: "",
  email: "",
  linkedInTaskType: "",
  dueDateString: new Date(),
  isTaskCreation: false,
  contactIds: [],
  allContactSelected: false,
  taskId: "",
  createCbk: () => {}
};

class AddStagesPopupState {
  @observable showPopup = false;
  @observable selectedSequence = "";
  @observable showStepEmailEditorPopup = false;
  @observable popupValues = { ...defaultPopupValue };
  @observable sequenceScheduled = false;
  @observable sequenceUpdated = false;
  @observable action = "";
  @observable stageIntervalUpdated = false;

  @action
  setShowPopup(value) {
    this.showPopup = value;
  }

  @action
  setShowStepEmailEditorPopup(value) {
    this.showStepEmailEditorPopup = value;
  }

  @action
  setSelectedSequence = data => {
    this.selectedSequence = data;
  };

  @action
  setPopupValues(obj) {
    this.popupValues = { ...this.popupValues, ...obj };
  }

  @action
  setResetValue() {
    this.popupValues = { ...defaultPopupValue };
  }

  @action
  setSequenceScheduled(value) {
    this.sequenceScheduled = value;
  }

  @action
  setSequenceUpdated(value) {
    this.sequenceUpdated = value;
  }

  @action
  setAction(value) {
    this.action = value;
  }

  @action
  setStageIntervalUpdated(value) {
    this.stageIntervalUpdated = value;
  }

  @action
  isStageIntervalUpdated = () => {
    if (
      this.popupValues.isEdit &&
      +this.popupValues.previousDaysLater > 0 &&
      +this.popupValues.daysLater > 0 &&
      +this.popupValues.previousDaysLater !== +this.popupValues.daysLater
    ) {
      this.setStageIntervalUpdated(true);
    }
  };

  @action
  createUpdateStep = async (
    data = {},
    cbk = () => {},
    method = "PUT",
    isVariantAction = false
  ) => {
    if (data?.id) {
      try {
        Object.keys(data?.steps).map(key => {
          delete data?.steps[key].analytics;
        });
        const config = {
          url: URLS.updateSequence + data?.id,
          method,
          data
        };
        let response = await makeApi(config);
        if (response?.data?.requestStatus) {
          Utils.mixpanelTrack(MP_EVENT.SQ_UPDATED, {
            pageType: "Sequence Overview Page"
          });
          response?.data?.campaignSequence &&
            this.setSelectedSequence(response.data.campaignSequence);
          this.setSequenceScheduled(response.data.scheduled);
          cbk(response);
          !isVariantAction && this.setSequenceUpdated(true);
        } else {
          cbk(false);
          Utils.showApiFailureResponse(response);
        }
        this.setShowPopup(false);
        this.setResetValue();
      } catch (err) {
        console.error("Creating a new step erro", err);
      }
    }
  };
}

const addStagesPopupState = new AddStagesPopupState();
export { addStagesPopupState };
