import { observable, action } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";

let accountNetworkRequest = {
  accounts: null,
  accountsFilter: null,
  accountContacts: null,
  accountContactsFilter: null,
  accountTasks: null,
  accountTasksFilter: null
};

const setAccountsNetworkRequest = (id = "") => {
  if (id && accountNetworkRequest?.[id] !== null) {
    accountNetworkRequest[id]("cancel");
    accountNetworkRequest[id] = null;
  }
};

const cancelAccountsReqCallback = (id = "", value = "") => {
  id && (accountNetworkRequest[id] = value);
};

export const initialContactsState = {
  searchTerm: "",
  limit: 10,
  activeTab: "TOTAL",
  stageId: null,
  pageNo: 1
};

class AccountsState {
  @observable pageNo = 1;
  @observable limit = 10;
  @observable totalPage = 1;
  @observable activeId = "";
  @observable accountName = "";
  @observable accountStages = [];
  @observable totalAccounts = "";
  @observable contactsStages = [];
  @observable searchTerm = "";
  @observable contactsState = { ...initialContactsState };
  @observable reloadTasksPage = false;
  @observable reloadTasksTable = false;

  @action
  setSearchTerm = (val = "") => {
    this.searchTerm = val;
  };

  @action
  setTotalAccounts = (count = "") => {
    this.totalAccounts = count;
  };

  @action
  setAccountStages = (list = []) => {
    this.accountStages = list;
  };

  @action
  setAccountName = (name = "") => {
    this.accountName = name;
  };

  @action
  setPageNo = (no = "") => {
    this.pageNo = no;
  };

  @action
  setLimit = (val = "") => {
    this.limit = val;
  };

  @action
  setTotalPage = (page = 1) => {
    this.totalPage = page;
  };

  @action
  setActiveId = (activeId = "") => {
    this.activeId = activeId;
  };

  @action
  resetAccountListing = () => {
    this.activeId = "";
    this.totalPage = 1;
    this.pageNo = "";
    this.accountName = "";
    this.totalAccounts = "";
  };

  @action
  setContactsStages = (list = []) => {
    this.contactsStages = list;
  };

  @action
  setContactsState = (obj = {}) => {
    this.contactsState = { ...this.contactsState, ...obj };
  };

  @action
  setResetContactsState = () => {
    this.contactsState = { ...initialContactsState };
  };

  @action
  resetAccountListing = () => {
    this.activeId = "";
    this.totalPage = 1;
    this.pageNo = "";
    this.accountName = "";
    this.totalAccounts = "";
    this.searchTerm = "";
    this.limit = 10;
  };

  @action
  setReloadTasksPage = (val = false) => {
    this.reloadTasksPage = val;
  };

  @action
  setReloadTasksTable = (val = false) => {
    this.reloadTasksTable = val;
  };

  // Accounts
  @action
  getAccountStages = async () => {
    const config = {
      url: URLS.getAccountStages,
      method: "GET"
    };
    return await makeApi(config);
  };

  @action
  getAccountStagesAnalytics = async (data = {}) => {
    const config = {
      url: URLS.getAccountAnalytics,
      method: "POST",
      data
    };
    setAccountsNetworkRequest("accountsFilter");
    const cancel = (value = null) =>
      cancelAccountsReqCallback("accountsFilter", value);
    return await makeApi(config, cancel);
  };

  @action
  getAccountsList = async (data = {}) => {
    const config = {
      url: URLS.getAccountList,
      method: "POST",
      data
    };
    setAccountsNetworkRequest("accounts");
    const cancel = (value = null) =>
      cancelAccountsReqCallback("accounts", value);
    return await makeApi(config, cancel);
  };

  // Contacts
  @action
  getAccountContactsStages = async () => {
    const config = {
      url: URLS.getContactStage,
      method: "GET"
    };
    let { data = [] } = await makeApi(config);
    data?.length && this.setContactsStages(data);
    return data;
  };

  @action
  getAccountContactsFilterAnalytics = async (data = {}) => {
    const config = {
      url: URLS.getContactFilter,
      method: "POST",
      data
    };
    setAccountsNetworkRequest("accountContactsFilter");
    const cancel = (value = null) =>
      cancelAccountsReqCallback("accountContactsFilter", value);
    return await makeApi(config, cancel);
  };

  @action
  getAccountContactsList = async (data = {}) => {
    const config = {
      url: URLS.getAllContacts,
      method: "POST",
      data
    };
    setAccountsNetworkRequest("accountContacts");
    const cancel = (value = null) =>
      cancelAccountsReqCallback("accountContacts", value);
    return await makeApi(config, cancel);
  };

  // Tasks
  @action
  getAccountTasksFilterAnalytics = async (data = {}) => {
    const config = {
      url: URLS.getAccountTasksAnalytics,
      method: "POST",
      data
    };
    setAccountsNetworkRequest("accountTasksFilter");
    const cancel = (value = null) =>
      cancelAccountsReqCallback("accountTasksFilter", value);
    return await makeApi(config, cancel);
  };

  @action
  getAccountTasksList = async (data = {}) => {
    const config = {
      url: URLS.getTaskFilter,
      method: "POST",
      data
    };
    setAccountsNetworkRequest("accountTasks");
    const cancel = (value = null) =>
      cancelAccountsReqCallback("accountTasks", value);
    return await makeApi(config, cancel);
  };
}

const accountsState = new AccountsState();
export { accountsState };
