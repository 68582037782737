import React from "react";
import { withRouter } from "react-router-dom";
import globalCallState from "Stores/GlobalCallState";
import Button from "Components/common/Button";
import PhoneCreditsImg from "Assets/png/phoneCredits.png";
import { CLIENT_URL } from "Static/constant";

const DialerUpgradeCreditsPopup = props => {
  const { hide = () => {} } = props;

  const addPhoneCredits = () => {
    props.history.push("/settings/dialer/general");
    hide();
    globalCallState.setShowCallSlider(false);
  };

  return (
    <div className="dsUpgradeCreditsPopup">
      <div className="upgradeCreditsCard animated customzoomIn">
        <img src={PhoneCreditsImg} alt="" />
        <h6>Insufficient credits</h6>
        <p>
          You do not have enough phone call credits to make any calls. Please
          add more to continue.
        </p>
        <div className="creditsBtn">
          <Button
            id="addPhoneCreditsBtn"
            name="addPhoneCreditsBtn"
            btnText="Add Credits"
            icon={false}
            iconName=""
            type="button"
            className="addPhoneCreditsBtn"
            loader={false}
            click={addPhoneCredits}
          />
          <Button
            id="cancelUpgradeBtn"
            name="cancelUpgradeBtn"
            btnText="Cancel"
            icon={false}
            iconName=""
            type="button"
            className="cancelUpgradeBtn"
            loader={false}
            click={hide}
          />
        </div>
      </div>
      <div className="overlayUCP" onClick={hide}></div>
    </div>
  );
};

export default withRouter(DialerUpgradeCreditsPopup);
export { DialerUpgradeCreditsPopup };
