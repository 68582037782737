import React, { useState } from "react";
import { observer } from "mobx-react";
import { createCallStagePopupState } from "./CreateCallStagePopup";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { ReactComponent as DeleteIcon } from "Assets/svg/deleteIcon.svg";
import { dialerStoreData } from "Stores/DialerData";
import CustomTooltip from "Components/common/CustomTooltip";

const ActionIcons = observer((props = {}) => {
  const {
    id = "",
    editable = false,
    mapTo = "",
    name = "",
    color = "",
    displayName = "",
    displayEnabled = false,
    deletedAllowed = false,
    colorCodes = []
  } = props || {};

  const restrictActions = ["NOT_LOGGED", "CONNECTED"].includes(
    name?.toUpperCase()
  );

  const [tooltip, setTooltip] = useState("");

  const handleDeleteIcon = () => {
    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to delete ${displayName} state? Call data in this state will be moved to 'Not logged'.`,
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      loadingMsg: "Please wait...",
      callback: () => {
        dialerStoreData.deleteCallStage({ name: displayName, id });
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  const actionList = [
    {
      id: "deleteStage",
      show: deletedAllowed,
      name: "delete",
      className: "deleteIcon",
      hoverIn: () => setTooltip("delete"),
      click: handleDeleteIcon,
      svg: <DeleteIcon />,
      icon: false,
      tooltipText: "Delete state"
    },
    {
      id: "editStage",
      name: "edit",
      show: editable,
      hoverIn: () => !restrictActions && setTooltip("edit"),
      click: () => !restrictActions && updateState(),
      svg: false,
      icon: "edit",
      iconClassName: `renameIcon ${restrictActions ? "cursorNotAllowed" : ""}`,
      tooltipText: "Edit state"
    }
  ];

  const visibilityObj = {
    id: "visibilityStage",
    name: "visible",
    show: true,
    hoverIn: () => !restrictActions && setTooltip("visible"),
    click: () => {
      !restrictActions &&
        dialerStoreData.updateCallStageVisiblity({
          id,
          name: displayName,
          displayEnabled: !displayEnabled
        });
    },
    svg: false,
    icon: displayEnabled ? "visibility" : "visibility_off",
    iconClassName: `visibilityIcon ${displayEnabled ? "visible" : ""} ${
      restrictActions ? "cursorNotAllowed" : ""
    }`,
    tooltipText: displayEnabled
      ? "Visible on calls section"
      : "Not visible on calls section"
  };

  const updateState = () => {
    if (!restrictActions) {
      createCallStagePopupState.setPopupValues({
        id,
        name: displayName,
        mapTo,
        type: "update",
        color: color,
        colorCodes,
        btnText: "Update"
      });
      createCallStagePopupState.setShowPopup(true);
    }
  };

  return (
    <div className="actionIcons">
      <span
        className={`iconBg badge ${
          mapTo?.toLowerCase() === "answered" ? "answered" : "notAnswered"
        }`}
      >
        {mapTo || "Not Answered"}
      </span>
      <div className="hoverWrapIcon">
        {actionList?.map(item => {
          const { id = "", show = false } = item || {};
          if (show) {
            return (
              <IconButton
                key={id}
                {...item}
                tooltip={tooltip}
                setTooltip={setTooltip}
              />
            );
          }
        })}
      </div>
      <IconButton
        key={"visibilityObj"}
        {...visibilityObj}
        tooltip={tooltip}
        setTooltip={setTooltip}
      />
    </div>
  );
});

const IconButton = ({
  id = "",
  name = "",
  className = "",
  svg = false,
  icon = false,
  iconClassName = "",
  tooltip = "",
  tooltipText = "",
  hoverIn = () => {},
  setTooltip = () => {},
  click = () => {}
}) => {
  return (
    <span
      className={`iconBg ${className}`}
      onMouseOver={hoverIn}
      onMouseLeave={() => setTooltip("")}
      onClick={click}
      key={id}
    >
      {svg ? (
        svg
      ) : (
        <i className={`material-icons-outlined ${iconClassName}`}>{icon}</i>
      )}
      {tooltip === name && <CustomTooltip text={tooltipText} />}
    </span>
  );
};

export default ActionIcons;
