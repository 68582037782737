import React from "react";
import { observer } from "mobx-react";
import SingleSelectDropdown from "Pages/Dashboard/settings/Calendar/Pages/Events/CreateEvent/Components/SingleSelectDropdown";
import userDetail from "Stores/userDetail";

export const TimeZoneSelector = observer((props = {}) => {
  const {
    inputValue = "",
    setInputValue = () => {},
    getFreeSlotByDate = () => {},
    setTimezoneErr = () => {}
  } = props || {};

  const updateInputStateLocal = (key = "", value = "", uniqueId = "") => {
    let tempObj = { [key]: value };
    if (["timeZone"].includes(key)) {
      tempObj.timezoneName = uniqueId;
      tempObj.timeZone = value;
    }
    setInputValue({
      ...inputValue,
      slotDateTime: "",
      ...tempObj
    });
    getFreeSlotByDate();
    setTimezoneErr("");
  };

  const timeZoneObj = {
    name: "timeZone",
    type: "select",
    search: true,
    options: userDetail?.globalTimeZone,
    timeZone: true,
    text: "Timezone",
    id: "timeZone",
    value: inputValue?.timezoneName || "",
    mandatory: true,
    change: updateInputStateLocal,
    tabIndex: -1
  };

  return (
    <div className="timezoneInputForm">
      <SingleSelectDropdown
        {...timeZoneObj}
        displayName="displayName"
        {...props}
        showLabel={false}
        defaultLabel={"Select Timezone"}
      />
    </div>
  );
});

export default TimeZoneSelector;
