import React from "react";
import { useLocation } from "react-router-dom";
import Utils from "Utils/utils";
import DropdownMenu from "Components/common/DropDownMenu";
import ChangeShareType from "Components/common/ChangeShareType";
import ConstantObj from "Static/constant";
import { observer } from "mobx-react";
import { useDrag } from "react-dnd";
import { TemplateDataStore } from "Stores/Template";

const TemplateList = observer((props = {}) => {
  const {
    templates,
    editTemplate,
    showTemplateOptions,
    changeTemplateType,
    cloneTemplate,
    deleteTemplate,
    updateTemplate,
    focusOnInput,
    radioOnchangeAction,
    editTemplateLoader,
    changeOnTemplateName,
    editTemplateCancelAction
  } = props || {};

  const location = useLocation();
  let isSharedFolder = location.pathname.indexOf("shared") !== -1;

  const dropdownOptions = [
    {
      icon: {
        ele: '<i style="font-size: "16px" class="material-icons-outlined list-icon editIcon">edit</i>',
        style: {}
      },
      value: "Edit",
      key: "editTemplate",
      method: changeTemplateType
    },
    {
      icon: {
        ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">file_copy</i>',
        style: {}
      },
      value: "Clone",
      key: "cloneTemplate",
      method: cloneTemplate
    },
    {
      icon: {
        ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">delete</i>',
        style: {}
      },
      value: "Delete",
      key: "deleteTemplate",
      method: deleteTemplate
    }
  ];

  return templates?.length
    ? templates.map((item, index) => {
        const {
          name = "",
          id = "",
          isEdit = false,
          accessType = "",
          shareType = ""
        } = item || {};
        return isEdit ? (
          <ChangeShareType
            key={index}
            showCreateSeqLoader={editTemplateLoader}
            save={updateTemplate}
            seqName={name}
            seqNameFocus={focusOnInput}
            sequenceNameAction={changeOnTemplateName}
            seqNameCancelAction={editTemplateCancelAction}
            sequenceAccessType={
              accessType || ConstantObj.sequenceType.shared.rw
            }
            radioOnchangeAction={radioOnchangeAction}
            sequenceShareType={shareType || ConstantObj.sequenceType.private}
            seqId={id}
            placeHolder={"Template Name"}
          />
        ) : (
          <TemplateRecord
            isSharedFolder={isSharedFolder}
            record={item}
            key={index}
            {...{ dropdownOptions, editTemplate, showTemplateOptions, index }}
          />
        );
      })
    : "";
});

const TemplateRecord = (props = {}) => {
  const {
    index = "",
    record = {},
    isSharedFolder = false,
    dropdownOptions = [],
    editTemplate = () => {},
    showTemplateOptions = () => {}
  } = props || {};

  const {
    name = "",
    id = "",
    parentPosition = "",
    templateOptions = false,
    accessType = "",
    shareType = "",
    owner = {},
    memberId = "",
    subject = "",
    createTs = "",
    updateTs = "",
    folderId = ""
  } = record || {};

  const getTemplateFolderName = (id = "") => {
    let tempList = [...(TemplateDataStore.folderList || [])];
    if (tempList?.length) {
      tempList = tempList.filter(item => {
        if (id === item?.id) {
          return item;
        }
      });
      return tempList?.[0];
    }
    return {};
  };
  let folderName = isSharedFolder
    ? "Shared Templates"
    : getTemplateFolderName(folderId)?.folderName || "My Templates";
  const [{ isDragging }, dragRef] = useDrag({
    type: "moveTemplate",
    item: { id, folderId, isSharedFolder, folderName },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  let opacity = isDragging ? 0.4 : 1;

  return (
    <div
      className="template-table-row"
      key={index}
      onClick={e => editTemplate(e, record)}
      ref={dragRef}
      style={{
        cursor: isDragging ? "grabbing" : "pointer",
        boxShadow: isDragging
          ? "0px 5px 12.4px 0.7px rgb(59 59 59 / 7%)"
          : "unset",
        opacity
      }}
    >
      <span className="material-icons-outlined dragHolder template">
        drag_indicator
      </span>
      <div className="template-name show-ellipsis">
        {Utils.getEntityName(shareType, accessType, owner, name, memberId)}
      </div>
      <div className="template-subject show-ellipsis">{subject}</div>
      <div className="template-created">
        {(createTs && Utils.formatDate(createTs)) || "--"}
      </div>
      <div className="template-updated">
        {(updateTs && Utils.formatDate(updateTs)) || "--"}
      </div>
      <div className="template-options">
        <i
          className="material-icons-outlined icon-hover-btn"
          onClick={e => showTemplateOptions(e, record)}
          title="More Options"
        >
          more_vert
        </i>
        <>
          {templateOptions ? (
            <>
              <DropdownMenu
                options={dropdownOptions}
                referenceId={id}
                direction="left"
                parentPosition={parentPosition}
              />
              <div
                className="dropdown-overlay"
                onClick={e => showTemplateOptions(e, record, false)}
              />
            </>
          ) : (
            ""
          )}
        </>
      </div>
    </div>
  );
};

export default TemplateList;
export { TemplateList };
