/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { observer } from "mobx-react";
import { ContactSearchTableContext } from "./ContactSearchTable";

const ContactsFound = () => {
  const { contactData = {} } = useContext(ContactSearchTableContext);

  let totalCount = contactData?.totalContacts || 0;

  const getText = () => {
    if (totalCount > 1) {
      return (
        <div>
          <span style={{ color: "#3953fb" }}>
            {totalCount > 1000 ? "1,000+" : totalCount}{" "}
          </span>
          Contacts found
        </div>
      );
    } else {
      return (
        <div>
          <span style={{ color: "#3953fb" }}>{totalCount}</span> Contact found
        </div>
      );
    }
  };

  return totalCount > 0 && <div className="contactsFound">{getText()}</div>;
};

export { ContactsFound };
export default observer(ContactsFound);
