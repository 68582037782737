/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import BirthdayCone from "Assets/png/birthday.png";
import RatingSmiley from "./RatingSmiley";
import Utils from "Utils/utils";
import ConfettiComponent from "../common/Confetti";
import makeApi, { URLS } from "Utils/apiURL";
import { MP_EVENT } from "Static/constant";

const OnBoardingCompleted = (props = {}) => {
  const { closeOnboardingCompletePopupCbk } = props;
  const [showThankyouMessage, setThankyouMessage] = useState(false);

  const closePopup = e => {
    Utils.preventDefaultFn(e);
    closeOnboardingCompletePopupCbk();
  };

  const completeOnboarding = async () => {
    let response = await markOnboardingCompleteThroughApi();
    if (response && response.data) {
      const respCode = response.data.responseCode;
      if (respCode === 200) {
        Utils.mixpanelTrack(MP_EVENT.ON_COMPLETED);
      }
    }
  };

  const markOnboardingCompleteThroughApi = async () => {
    const config = {
      url: URLS.markOnboardingCompleted,
      method: "GET"
    };
    return await makeApi(config);
  };

  useEffect(() => {
    completeOnboarding();
  }, []);

  return (
    <>
      <ConfettiComponent />
      <div className="popupWrapper onBoardingCompletedPopupWrapper">
        <div className="onboardingCompletedPopup">
          <div className="popup-wrap animatedFast slideInDownTranslate onBoardingcompletedInnerCont">
            <div
              className="popup-close"
              title="Click here to close"
              onClick={e => closePopup(e)}
            >
              <i className="material-icons">close</i>
            </div>
            <div className="title">
              <div className="titleName">
                That's Awesome!{" "}
                <img src={BirthdayCone} alt="Birthday Cone"></img>
              </div>
              <div className="subTitle">
                You are all set to grow your pipeline with Salesgear.
              </div>
            </div>
            <div className="ratingCont">
              {!showThankyouMessage && (
                <div className="ratingText">
                  How would rate the onboarding experience?
                </div>
              )}
              <RatingSmiley
                setThankyouMessage={setThankyouMessage}
                showThankyouMessage={showThankyouMessage}
                closePopup={closePopup}
              />
            </div>
          </div>
        </div>
        <div className="common-popup-mask"></div>
      </div>
    </>
  );
};

export { OnBoardingCompleted };
export default OnBoardingCompleted;
