import React, { useState, useEffect } from "react";
import { slackDataStore } from "Stores/SlackDataStore";
import AutoCompleteDropdown from "Components/common/AutoCompleteDropdown";
import { observer } from "mobx-react";

const SelectChannel = observer(props => {
  const {
    setSelectedChannel = () => {},
    selectedChannel,
    selectedChannelError,
    setSelectedChannelError,
    setEnableSaveChangeBtn = () => {}
  } = props;

  const [scrollEnd, setScrollEnd] = useState(false);

  const selectChannelCbk = item => {
    const { id = "", name = "" } = item;
    const tempObj = { channelId: id, channelName: name };
    setSelectedChannel(tempObj);
    setSelectedChannelError(false);
    setEnableSaveChangeBtn(true);
  };

  const getErrorStyle = () => {
    return selectedChannelError ? { borderColor: "#ff0000" } : {};
  };

  useEffect(() => {
    slackDataStore.setSlackChannels([{ id: "loading", name: "Loading..." }]);
    slackDataStore.getSlackChannels();
    return () => {
      slackDataStore.resetSlackChannelList();
    };
  }, []);

  useEffect(() => {
    if (scrollEnd && slackDataStore?.nextCursor !== null) {
      slackDataStore.setSlackChannels([
        ...slackDataStore.slackChannels,
        { id: "loading", name: "Loading..." }
      ]);
      slackDataStore.getSlackChannels(() => setScrollEnd(false));
    } else {
      setScrollEnd(false);
    }
  }, [scrollEnd]);

  return (
    <section className="config">
      <div className="selectChannel">
        <div className="textCont">
          <span className="textDark">Select Channel</span>
          <span className="textNormal">
            (Notifications will be sent to this channel)
          </span>
        </div>
        <div className="channelList" style={getErrorStyle()}>
          <AutoCompleteDropdown
            optionList={slackDataStore?.slackChannels}
            defaultValue={selectedChannel?.channelName}
            cbk={selectChannelCbk}
            setScrollEnd={setScrollEnd}
            loaderEnabled={slackDataStore?.nextCursor !== null}
          />
        </div>
      </div>
    </section>
  );
});

export default SelectChannel;
export { SelectChannel };
