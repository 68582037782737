import React, { useState, useReducer, useEffect } from "react";
import { observer } from "mobx-react";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import NumberInputBox from "Components/common/NumberInputBox";
import ToggleBtnType from "Components/common/ToggleBtnType";
import CustomTooltip from "Components/common/CustomTooltip";
import { toasterState } from "Components/common/toaster";
import Button from "Components/common/Button";
import connectedInboxStore from "./store";

const initialState = {
  dailyEmailLimit: 0,
  emailIncPerDayLimit: 0,
  replyRate: 0,
  sendEmailOnWeekDaysOnly: true,
  warmupCustomDomainEnabled: false,
  readEmulationEnabled: false
};

const formReducer = (state = {}, action = {}) => {
  const { type = "", payload = {}, value = "", field = "" } = action || {};
  switch (type) {
    case "SET_ENTIRE_STATE":
      return payload;
    case "CHANGE_FIELD":
      return {
        ...state,
        [field]: value
      };
    case "TOGGLE_FIELD":
      return {
        ...state,
        [field]: !state[field]
      };
    default:
      return state;
  }
};

const initialFormFieldsConfig = {
  dailyEmailLimit: {
    label: "Daily warmup email limit",
    subLabel: "(Recommended : 20-40)",
    type: "number",
    required: true,
    min: 1,
    max: 200,
    displayFlag: true
  },
  emailIncPerDayLimit: {
    label: "Daily increase",
    subLabel: "(Recommended : 1-4)",
    type: "number",
    required: true,
    min: 1,
    max: 4,
    displayFlag: true
  },
  replyRate: {
    label: "Reply Rate",
    subLabel: "(Recommended : 30%)",
    type: "number",
    required: true,
    min: 1,
    max: 50,
    displayFlag: true
  },
  readEmulationEnabled: {
    label: "Reading Emulation",
    type: "checkbox",
    subLabel:
      "Emulate human reading behaviour by interacting with your warmup emails",
    required: false,
    displayFlag: true
  },
  sendEmailOnWeekDaysOnly: {
    label: "Weekdays Only",
    subLabel: "Send warmup emails only during weekdays",
    type: "checkbox",
    required: false,
    displayFlag: true
  },
  warmupCustomDomainEnabled: {
    label: "Warmup Custom Domain",
    subLabel:
      "Include custom domain to warming emails and warm it up with your account",
    type: "checkbox",
    required: false,
    displayFlag: true
  },
  emailWarmingTag: {
    label: "Email Warming Tag",
    type: "tag",
    readOnly: true,
    required: false,
    hasCopyIcon: true,
    displayFlag: true
  }
};

const EmailWarmingSettings = observer((props = {}) => {
  const {
    inboxId = "",
    configureData = {},
    setShowConfigure = () => {},
    warmingData = {}
  } = props || {};

  const { emailWarming = {}, status = "", type = "" } = warmingData || {};
  const { googleWarmingInbox = {} } = emailWarming || {};

  const [state, dispatch] = useReducer(formReducer, initialState);
  const [errors, setErrors] = useState({});
  const [saveLoader, setSaveLoader] = useState(false);
  const [formFieldsConfig, setFormFieldsConfig] = useState(
    initialFormFieldsConfig
  );
  const handleChange = (value = "", field = "") => {
    dispatch({ type: "CHANGE_FIELD", field, value });
  };

  const handleToggle = (field = "") => {
    dispatch({ type: "TOGGLE_FIELD", field });
  };

  const checkError = () => {
    const newErrors = {};
    let hasError = false;
    Object.entries(formFieldsConfig).forEach(([field, config]) => {
      if (config?.required) {
        if (state[field] === "") {
          newErrors[field] = "Field is empty";
          hasError = true;
        } else if (
          field === "emailIncPerDayLimit" &&
          (state[field] < 1 || state[field] > 4)
        ) {
          newErrors[field] = "Emails/day limit should be between 1 and 4";
          hasError = true;
        } else if (
          field === "dailyEmailLimit" &&
          (state[field] < 1 || state[field] > 200)
        ) {
          newErrors[field] =
            "Daily warmup limit should less than or equal to 200";
          hasError = true;
        } else if (
          field === "replyRate" &&
          (state[field] < 1 || state[field] > 50)
        ) {
          newErrors[field] = "Reply rate should be only up to 50";
          hasError = true;
        }
      }
    });
    setErrors(newErrors);
    if (hasError) {
      return true;
    }
    return false;
  };

  const saveSettings = async () => {
    setSaveLoader(true);
    const config = {
      url: URLS?.saveWarmupSettings,
      method: "POST",
      data: { id: inboxId, emailWarming: { warmupSettings: state } }
    };
    let response = await makeApi(config);
    const { code = "" } = response?.data || {};
    if (+code === 200) {
      const { warmupSettings = {} } = response?.data?.inbox?.emailWarming || {};
      if (warmupSettings) {
        dispatch({
          type: "SET_ENTIRE_STATE",
          payload: warmupSettings
        });
        toasterState.setToastMsg(
          "Warmup settings saved successfully.",
          "success"
        );
      }
    } else if (+code === 401) {
      toasterState.setToastMsg(
        "You don't have access to warmup settings.",
        "failure"
      );
    } else if (+code === 403) {
      toasterState.setToastMsg(
        "You don't have access to warmup settings.",
        "failure"
      );
    } else if (+code === 406) {
    } else if (+code === 500) {
    } else {
      toasterState.setToastMsg(
        "Something went wrong, please try again later",
        "failure"
      );
    }
    setSaveLoader(false);
  };

  const handleSave = () => {
    if (!checkError()) {
      saveSettings();
    }
  };

  const WarmingSettingsLoader = () => {
    return (
      <div className="warmingSettingsLoader">
        <div className="label linear-background" />
        <div className="inputType linear-background" />
      </div>
    );
  };

  useEffect(() => {
    setErrors({});
    dispatch({
      type: "SET_ENTIRE_STATE",
      payload: connectedInboxStore?.warmupSettings
    });
  }, [connectedInboxStore?.warmupSettings]);

  return (
    <div className="emailWarmingSettings">
      <div className="warmingSettingsCont">
        <div className="warmingSettingsHeader">Settings</div>
        <div
          className={`warmingSettingsBody ${
            connectedInboxStore?.warmupLoader ? "settingsLoader" : ""
          }`}
        >
          {Object.entries(formFieldsConfig).map(
            ([
              field,
              {
                label,
                type,
                subLabel,
                min,
                max,
                hasCopyIcon = false,
                displayFlag
              }
            ]) =>
              !connectedInboxStore?.warmupLoader && displayFlag ? (
                <>
                  {type === "tag" && configureData?.emailWarmingTag && (
                    <TagComponent
                      label={label}
                      hasCopyIcon={hasCopyIcon}
                      configureData={configureData}
                    />
                  )}
                  {type === "checkbox" && (
                    <CheckBoxComponent
                      label={label}
                      subLabel={subLabel}
                      field={field}
                      handleToggle={handleToggle}
                      state={state}
                    />
                  )}
                  {type === "number" && (
                    <>
                      <NumberInputBox
                        type={type}
                        changeCbk={value => handleChange(value, field)}
                        min={min}
                        max={max}
                        value={state[field]}
                        errMsg={errors[field]}
                        loader={false}
                        readOnly={false}
                        label={label}
                        subLabel={subLabel}
                      />
                    </>
                  )}
                </>
              ) : (
                displayFlag && <WarmingSettingsLoader key={label} />
              )
          )}
        </div>
        <ButtonComponent
          setShowConfigure={setShowConfigure}
          handleSave={handleSave}
          saveLoader={saveLoader}
          status={status}
          emailWarming={emailWarming}
          configureData={configureData}
        />
      </div>
    </div>
  );
});

const CheckBoxComponent = (props = {}) => {
  const {
    label = "",
    subLabel = "",
    handleToggle = () => {},
    field,
    state = {}
  } = props || {};

  return (
    <div className="checkBoxInput">
      <div className="checkboxlabel">{label}</div>
      {subLabel && <div className="checkboxSubLabel">{subLabel}</div>}
      <ToggleBtnType
        defaultValue={state[field]}
        isAllowed={true}
        cbk={() => handleToggle(field)}
      />
    </div>
  );
};

const TagComponent = observer((props = {}) => {
  const { label = "", hasCopyIcon = false, configureData = {} } = props || {};
  const [showToolTip, setShowToolTip] = useState(false);

  const copyToClipboard = (warmingTag = "") => {
    Utils.copyToClipboard(warmingTag);
    toasterState.setToastMsg(
      "Warming tag has been copied successfully.",
      "success"
    );
  };

  return (
    <div className="warmingTagWrapper">
      <div className="label">
        {label}
        <div className="infoIcon">
          <i
            className="material-icons iIcon"
            onMouseOver={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          >
            info_outline
          </i>
          {showToolTip && (
            <CustomTooltip text="A unique tag to be included in all warmup emails to easily spot them in your inbox" />
          )}
        </div>
      </div>
      <div className="emailWarmingTagWrapper">
        <div className="emailWarmingTag">{configureData?.emailWarmingTag}</div>
        {hasCopyIcon && (
          <i
            className="material-icons copyIcon"
            onClick={() => copyToClipboard(configureData?.emailWarmingTag)}
          >
            content_copy
          </i>
        )}
      </div>
    </div>
  );
});

const ButtonComponent = (props = {}) => {
  const [showSaveButton, setShowSaveButton] = useState(true);
  const {
    setShowConfigure = () => {},
    saveLoader = false,
    status = "",
    emailWarming = {},
    handleSave = () => {}
  } = props || {};

  const checkErrorInbox = () => {
    const tempEmailWarming = emailWarming || {};
    setShowSaveButton(
      status?.toLowerCase() === "active" && !tempEmailWarming?.warmingError
    );
  };

  useEffect(() => checkErrorInbox(), [status]);

  const buttonList = [
    {
      id: "back",
      name: "back",
      btnText: "Back",
      type: "button",
      className: "cancelButton",
      click: () => {
        connectedInboxStore.resetConnectedInbox(connectedInboxStore?.pageNo);
        setShowConfigure(false);
      }
    },
    {
      id: "save",
      name: "save",
      btnText: "Save",
      type: "button",
      className: `saveButton ${saveLoader ? "loading" : ""}`,
      click: handleSave,
      loader: saveLoader,
      show: showSaveButton
    }
  ];

  return (
    <div className="buttonActionList">
      {buttonList.map(item => (
        <Button {...item} key={`${item?.id}Btn`} />
      ))}
    </div>
  );
};

export default EmailWarmingSettings;
export { EmailWarmingSettings };
