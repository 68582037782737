/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import { userDetail } from "Stores/userDetail";
import Lottie from "react-lottie";
import { reportStore } from "Stores/Reports";
import Utils from "Utils/utils";
import EmptyReport from "./EmptyReport";
import NoActivityFound from "./NoActivityFound";
import BarChartLoader from "Utils/LottieFiles/BarChart.json";

const series = [
  {
    name: "Email Sent",
    data: []
  },
  {
    name: "Opens",
    data: []
  },
  {
    name: "Clicks",
    data: []
  },
  {
    name: "Replies",
    data: []
  },
  {
    name: "Email Bounces",
    data: []
  }
];

const options = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  colors: ["#00D2AD", "#0177FF", "#FFCC3F", "#545864", "#f59064"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  yaxis: {
    labels: {
      formatter: value => {
        return Math.floor(value);
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      horizontal: false
    }
  },
  legend: {
    position: "top",
    horizontalAlign: "right"
  },
  fill: {
    opacity: 1
  }
};

const ReportEmailInsights = () => {
  let location = useLocation();

  let contactAdded = userDetail?.userFeatureFlag?.noOfContactsAddedInTeam;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BarChartLoader
  };

  const [loader, setLoader] = useState(true);
  const [chartData, setChartData] = useState(series);
  const [chartOptions, setChartOptions] = useState(options);

  const constructChartData = () => {
    const data = reportStore.emailInsightData;
    if (data) {
      const tempData = [...chartData];
      tempData.map(item => (item.data = []));
      const tempXaxisDate = [];
      data?.length &&
        data.map((item, index) => {
          tempData[0].data.push(Math.ceil(item.sentCount) || 0);
          tempData[1].data.push(Math.ceil(item.openCount) || 0);
          tempData[2].data.push(Math.ceil(item.clickCount) || 0);
          tempData[3].data.push(Math.ceil(item.replyCount) || 0);
          tempData[4].data.push(Math.ceil(item.bounceCount) || 0);
          tempXaxisDate.push(item.sentDate);
        });
      const { filterFromDate, filterToDate, days } =
        reportStore?.filterPayload || {};
      if (filterFromDate || filterToDate) {
        let startDate = Utils.formatDateHypen(filterFromDate);
        let endDate = Utils.formatDateHypen(filterToDate);
        const hasStartDate = tempXaxisDate.indexOf(startDate) !== -1;
        const hasEndDate = tempXaxisDate.indexOf(endDate) !== -1;
        data?.length &&
          days > 1 &&
          !hasStartDate &&
          tempXaxisDate.push(startDate);
        data?.length &&
          days > 1 &&
          !hasEndDate &&
          tempXaxisDate.splice(0, 0, endDate);
        tempData.map(item => {
          data?.length && days > 1 && !hasStartDate && item.data.push(0);
          data?.length && days > 1 && !hasEndDate && item.data.splice(0, 0, 0);
        });
      }
      setChartOptions({
        ...options,
        xaxis: {
          type: days > 1 ? "datetime" : "category",
          categories:
            (days > 1 && tempXaxisDate) ||
            tempXaxisDate.map(item => Utils.formatDate(item)),
          labels: {
            datetimeFormatter: { month: "dd MMM" }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: (days > 20 && "40%") || "20%"
          }
        }
      });
      setChartData(tempData);
      reportStore.setEmailInsightLoader(false);
    }
  };

  useEffect(() => {
    constructChartData(reportStore.summaryData);
  }, [reportStore.emailInsightData]);

  useEffect(() => {
    setLoader(reportStore.emailInsightLoader);
  }, [reportStore.emailInsightLoader]);

  useEffect(() => {
    setLoader(true);
    reportStore.setEmailInsightLoader(true);
  }, [location?.pathname]);

  return (
    <div className="chartCard">
      <h4>Email Insights</h4>
      <div className="chartContent">
        {(!loader &&
          contactAdded &&
          chartData?.length &&
          chartData[0]?.data?.length && (
            <div className="stackedBarChart">
              <Chart
                options={chartOptions}
                series={chartData}
                type="bar"
                height={350}
              />
            </div>
          )) ||
          ""}
        {loader && (
          <div className="chartLoader">
            <Lottie
              options={defaultOptions}
              width={200}
              style={{ margin: "0 auto" }}
              speed={1}
            />
          </div>
        )}
        {!loader && !contactAdded && (
          <EmptyReport
            pathname={location?.pathname}
            className="emptyEmailInsight"
          />
        )}
        {(!loader &&
          contactAdded &&
          chartData?.length &&
          !chartData[0]?.data?.length && <NoActivityFound />) ||
          ""}
      </div>
    </div>
  );
};

export default observer(ReportEmailInsights);
export { ReportEmailInsights };
