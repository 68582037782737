import React, { useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import CustomTooltip from "Components/common/CustomTooltip";
import InputTextBox from "Components/common/InputTextBox";
import ContactAutoCompleteDropdown from "Components/PreviewPopup/ContactAutoCompleteDropdown";

const EmailInputBox = observer((props = {}) => {
  const {
    email = "",
    setEmail = () => {},
    setBackupEmail = () => {},
    emailList = [],
    label = "",
    keyName = "",
    objKey = "",
    placeHolder = "",
    updateState = () => {},
    isOutboxPage = false,
    pageType = ""
  } = props || {};

  const { blockedDomainList = [] } = userDetail || {};

  const [errMsg, setErrMsg] = useState("");
  const [showToolTip, setShowToolTip] = useState("");

  const tooltipErrorInfoText = {
    BOUNCED: "Bounced contact",
    OPTED_OUT: "Opted out contact",
    INVALID: "Invalid email address",
    DONOTCONTACT: "Domain blocked"
  };

  const removeEmail = (event, email = "") => {
    Utils.preventDefaultFn(event);
    if (email) {
      let tempList = emailList ? [...emailList] : [];
      if (tempList?.length) {
        tempList = tempList.filter(item => {
          if (item?.email !== email) {
            return item;
          }
        });
      }
      updateState(keyName, tempList, objKey);
    }
  };

  const checkBlockedDomain = () => {
    let splitEmail = email?.split("@") || [];
    return (
      splitEmail?.length > 1 && blockedDomainList?.includes(splitEmail?.[1])
    );
  };

  const addEmail = async (contactEmail = "") => {
    let tempEmail = contactEmail ? contactEmail : email || "";
    let isValidEmail = Utils.emailValidate(
      tempEmail?.trim()?.toLowerCase() || ""
    );
    if (tempEmail?.trim()) {
      let emailStatusObj =
        (isValidEmail &&
          (await Utils.validateEmailAddressStatus([tempEmail]))) ||
        "";
      let value = !isValidEmail
        ? "INVALID"
        : checkBlockedDomain()
        ? "DONOTCONTACT"
        : (emailStatusObj &&
            Object.keys(emailStatusObj)?.length > 0 &&
            ["BOUNCED", "OPTED_OUT"].includes(emailStatusObj?.[tempEmail]) &&
            emailStatusObj?.[tempEmail]) ||
          "";
      let tempList = emailList ? [...emailList] : [];
      tempList.push({
        email: tempEmail?.trim(),
        invalid: value
      });
      setEmail("");
      updateState(keyName, tempList, objKey);
    }
  };

  const handleEnter = (event = "") => {
    if (Utils.isSubmitKeyAction(event)) {
      addEmail();
    }
  };

  const changeAction = (id = "", value = "") => {
    errMsg && setErrMsg("");
    if (value?.includes(",")) {
      addEmail();
    } else {
      setEmail(value);
      setBackupEmail(value);
    }
  };

  const getSelectedEmail = (contact = {}) => {
    addEmail(contact?.email);
  };

  const inputObj = {
    name: `emailAddress${label}Input`,
    id: `emailAddress${label}Input`,
    type: "text",
    value: email,
    cbk: handleEnter,
    change: changeAction,
    onBlur: () => {
      !["globalheader"].includes(pageType?.toLowerCase()) && addEmail();
    },
    err: errMsg,
    placeHolder
  };

  return (
    <div className={`addRemoveEmailAddress ${keyName}Section`}>
      <span className="labelName">{label}</span>
      {(emailList?.length > 0 &&
        emailList.map((item, index) => {
          const { name = "", email = "", invalid = "" } = item || {};
          return (
            <div
              className={`chipEmail ${invalid ? "invalidEmail" : ""}`}
              key={`${email}${index}`}
            >
              <a
                className="email"
                href={`mailto:${email}`}
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                {name || email}
              </a>
              <i
                className="material-icons remove"
                onClick={e => removeEmail(e, email)}
              >
                close
              </i>
              {invalid && (
                <span className="tooltipInfoWrap">
                  <i
                    className="material-icons infoIcon"
                    onMouseOver={() => setShowToolTip(email)}
                    onMouseLeave={() => setShowToolTip("")}
                  >
                    info_outline
                  </i>
                  {showToolTip === email && (
                    <CustomTooltip text={tooltipErrorInfoText[invalid]} />
                  )}
                </span>
              )}
            </div>
          );
        })) ||
        ""}
      <div className="emailAndInputBoxCont">
        {!isOutboxPage && <InputTextBox {...inputObj} />}
        <ContactAutoCompleteDropdown
          inputText={email}
          selectedCallbk={getSelectedEmail}
        />
      </div>
    </div>
  );
});

export default EmailInputBox;
