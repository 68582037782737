import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { Button } from "Components/common/Button";
import customListState from "./state";
import InputTextBox from "Components/common/InputTextBox";
import { dataSearchState } from "Pages/Dashboard/dataSearch/state";
import { popupLoaderState } from "../PopupLoader";

class CreateEditListPopupState {
  @observable showPopup = false;
  @observable action = "";
  @observable listName = "";
  @observable buttonLoader = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setAction = val => {
    this.action = val;
  };

  @action
  setListName = val => {
    this.listName = val;
  };

  @action
  setButtonLoader = val => {
    this.buttonLoader = val;
  };
}

const createEditListPopupState = new CreateEditListPopupState();

const CreateEditListPopup = () => {
  const [error, setError] = useState("");
  const inputBoxRef = useRef();

  const handleListNameChange = (id, val) => {
    error && setError("");
    createEditListPopupState.setListName(val);
  };

  const handleCreateListAction = async () => {
    if (createEditListPopupState?.listName?.length > 0) {
      if (createEditListPopupState?.listName?.toLowerCase() !== "all") {
        setError("");
        if (createEditListPopupState?.action === "create") {
          popupLoaderState.setData({
            message: "Adding contacts to your list. Please wait...",
            type: "addContacts"
          });
          popupLoaderState.setShowPopup(true);
          await customListState.createContactList(
            createEditListPopupState?.listName
          );
          createEditListPopupState.setShowPopup(false);
        } else {
          customListState.editContactList(
            customListState?.activeList?.id,
            createEditListPopupState?.listName
          );
          createEditListPopupState.setButtonLoader(true);
        }
        popupLoaderState.setShowPopup(false);
        dataSearchState.setSelectedContactData([]);
      } else {
        setError("List name already exists");
      }
    } else {
      setError("Please enter the list name");
    }
  };

  const close = () => {
    setError("");
    createEditListPopupState.setAction("");
    createEditListPopupState.setListName("");
    createEditListPopupState.setShowPopup(false);
  };

  const getPopupText = () => {
    return createEditListPopupState?.action === "create"
      ? "Create List"
      : "Update List Name";
  };

  const getPopupButton = () => {
    return createEditListPopupState?.action === "create" ? "Create" : "Update";
  };

  useEffect(() => {
    if (createEditListPopupState?.showPopup) {
      inputBoxRef && inputBoxRef.current && inputBoxRef.current.focus();
    }
  }, [createEditListPopupState?.showPopup]);

  return (
    createEditListPopupState?.showPopup && (
      <section className="createEditListPopupWrapper">
        <div className="card">
          <div className="popupHeader">
            <div className="title">{getPopupText()}</div>
            <i className="material-icons commonPopupCloseIcon" onClick={close}>
              close
            </i>
          </div>
          <InputTextBox
            name="CreateListInputBox"
            id="CreateListInputBox"
            text={"Enter List Name"}
            type="text"
            value={createEditListPopupState?.listName}
            change={handleListNameChange}
            err={error}
            readOnly={false}
            inputBoxRef={inputBoxRef}
          />
          <Button
            id="createListButton"
            name="createListButton"
            btnText={getPopupButton()}
            type="button"
            className={`createListButton ${
              createEditListPopupState?.buttonLoader ? "loading" : ""
            }`}
            loader={createEditListPopupState?.buttonLoader}
            click={e => {
              handleCreateListAction(e);
            }}
          />
        </div>
        <div
          className="common-popup-mask"
          onClick={() => createEditListPopupState.setShowPopup(false)}
        />
      </section>
    )
  );
};

export { CreateEditListPopup, createEditListPopupState };
export default observer(CreateEditListPopup);
