import React, { useEffect, useState } from "react";
import VariantToggle from "Pages/Dashboard/sequences/SequencePage/VariantToggle";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

const defaultInputValue = {
  trackingEnabled: true,
  clickTrackingEnabled: true,
  openTrackingEnabled: true
};

class ToolbarTrackingIconState {
  @observable inputValue = defaultInputValue;

  @action
  setInputValue = (obj = {}) => {
    this.inputValue = obj || defaultInputValue;
  };

  @action
  resetValue = () => {
    this.inputValue = JSON.parse(JSON.stringify(defaultInputValue));
  };
}

const toolbarTrackingIconState = new ToolbarTrackingIconState();

const ToolbarTrackingIcon = observer(() => {
  const [trackingState, setTrackingState] = useState(
    toolbarTrackingIconState.inputValue
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const updateState = (obj = {}) => {
    setTrackingState(obj);
    toolbarTrackingIconState.setInputValue(obj);
  };

  const handleAction = (_, value = "", id = "") => {
    let tempObj = { ...trackingState };
    if (id === "all") {
      tempObj["openTrackingEnabled"] = value;
      tempObj["clickTrackingEnabled"] = value;
      tempObj["trackingEnabled"] = value;
    } else {
      if (id === "clickTrackingEnabled" && value) {
        tempObj["openTrackingEnabled"] = value;
        tempObj["clickTrackingEnabled"] = value;
        tempObj["trackingEnabled"] = value;
      } else if (id === "openTrackingEnabled" && value) {
        tempObj["openTrackingEnabled"] = value;
        tempObj["trackingEnabled"] = value;
      } else {
        tempObj["openTrackingEnabled"] = value;
        tempObj["clickTrackingEnabled"] = value;
        tempObj["trackingEnabled"] = value;
      }
    }
    updateState(tempObj);
  };

  const allTracking = {
    label: "Track",
    id: "all",
    className: "allTrack",
    action: handleAction,
    isChecked: trackingState?.trackingEnabled || false,
    callback: () => setShowDropdown(!showDropdown)
  };

  const trackingOption = [
    {
      label: "Open Tracking",
      id: "openTrackingEnabled",
      className: "openTrackingEnabled",
      action: handleAction,
      isChecked: trackingState?.openTrackingEnabled || false
    },
    {
      label: "Link Tracking",
      id: "clickTrackingEnabled",
      className: "clickTrackingEnabled",
      action: handleAction,
      isChecked: trackingState?.clickTrackingEnabled || false,
      showInfoIcon: true,
      tooltipText:
        "When link tracking is enabled, open tracking will also be enabled"
    }
  ];

  useEffect(() => {
    return () => {
      toolbarTrackingIconState.resetValue();
    };
  }, []);

  return (
    <>
      <div className="trackIcon">
        <VariantToggle {...allTracking} />
      </div>
      {showDropdown && (
        <>
          <div className="trackingOptionCard">
            <span className="pointerTriangle" />
            <div className="trackingOption">
              <p className="descText">What would you like to track?</p>
              {trackingOption?.map(item => (
                <VariantToggle key={item?.id} {...item} />
              ))}
            </div>
          </div>
          <div
            className="overlayTracking"
            onClick={() => setShowDropdown(!showDropdown)}
          />
        </>
      )}
    </>
  );
});

export { toolbarTrackingIconState, ToolbarTrackingIcon };
export default ToolbarTrackingIcon;
