import React from "react";
import connectedInboxStore from "./store";

const Header = props => {
  const {
    showConfigure = false,
    configureData = {},
    setShowConfigure = () => {}
  } = props || {};

  return (
    showConfigure && (
      <div className="sectionHeader">
        <div className="backBtnAndHeader">
          <span
            className="navigateToInbox"
            onClick={() => {
              connectedInboxStore.resetConnectedInbox(
                connectedInboxStore?.pageNo
              );
              setShowConfigure(false);
            }}
          >
            <i className="material-icons backBtn">keyboard_backspace</i>
          </span>
          <h6>Connected Inbox</h6>
        </div>
      </div>
    )
  );
};

export default Header;
export { Header };
