import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Button from "./Button";
import DropdownMenu from "Components/common/DropDownMenu";
import DriverMaster from "../driver/Driver";
import Utils from "Utils/utils";
import { addSingleContactPopupState } from "./AddSingleContactPopup";
import userDetail, { brandUrlObj } from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const AddContactDropDown = observer(props => {
  const {
    addSingleContact,
    addBulkContact,
    showButton,
    icon,
    id,
    btnId,
    disabled = false,
    addContactLoading = false,
    pageType = "",
    allowContactsUpload = false
  } = props || {};

  const { inFreePlan = false, planName = "" } =
    userDetail?.userFeatureFlag || {};
  const [showDropDown, setShowDropDown] = useState(false);

  const addContactAction = (bulk = false) => {
    Utils.reloadFeatureFlag(false);
    const cbk = () => {
      bulk ? addBulkContact(id) : addSingleContact(id);
    };
    setShowDropDown(false);
    cbk();
  };

  const uploadContact = event => {
    event.preventDefault();
    DriverMaster.killDriver();
    if (allowContactsUpload || pageType === "contacts") {
      setShowDropDown(true);
    } else {
      PaywallUtils.upgradeUtilsFunction(
        "addContactsToSequence",
        false,
        planName,
        inFreePlan
      );
    }
  };

  const dropdownOptions = [
    {
      icon: {
        ele: '<span class="material-icons-outlined"> person</span>'
      },
      value: "Single",
      key: "singleContact",
      method: () => addContactAction()
    },
    {
      icon: {
        ele: '<span class="material-icons-outlined"> people</span>'
      },
      value: "Multiple",
      key: "bulkUpload",
      method: () => addContactAction(true)
    }
  ];

  useEffect(() => {
    addSingleContactPopupState.setIsContactPage(pageType === "contacts");
  }, [pageType]);

  return (
    <div className="add-new-dropdown addContactDropDown">
      {showButton ? (
        <Button
          id={btnId || disabled ? "addContactDisableBtn" : "addContactBtn"}
          name="addContactBtn"
          type="button"
          className={`addContactCont ${addContactLoading ? "loading" : ""}`}
          btnText="Add Contacts"
          icon={true}
          loader={false}
          iconName="keyboard_arrow_down"
          click={e => uploadContact(e)}
          disabled={disabled}
          knobs={addContactLoading}
        />
      ) : (
        <span onClick={e => uploadContact(e)}>{icon}</span>
      )}
      <>
        {showDropDown ? (
          <>
            <DropdownMenu options={dropdownOptions} referenceId="" />
            <div
              className="dropdown-overlay"
              onClick={e => {
                e.preventDefault();
                setShowDropDown(false);
              }}
            />
          </>
        ) : (
          ""
        )}
      </>
    </div>
  );
});

export default AddContactDropDown;
export { AddContactDropDown };
