import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import DialerServiceData from "Stores/DialerServiceStore";
import TextArea from "Components/common/TextArea";

const DialerCallNotes = observer(({ cbk = () => {} }) => {
  const [callNotes, setCallNotes] = useState(
    DialerServiceData?.dialerCallNotes || ""
  );

  const changeInputVal = (e = "") => {
    DialerServiceData.setDialerCallNotes(e.currentTarget.value);
    setCallNotes(e.currentTarget.value);
  };

  useEffect(() => {
    setCallNotes(DialerServiceData?.dialerCallNotes || "");
  }, [DialerServiceData?.dialerCallNotes]);

  return (
    <div className="callNotesSection">
      <i className="material-icons" onClick={() => cbk(false)}>
        close
      </i>
      <TextArea
        id="dialerCallNotes"
        labelText="Call Notes"
        value={callNotes}
        onChangeEvent={changeInputVal}
      />
    </div>
  );
});

export default DialerCallNotes;
export { DialerCallNotes };
