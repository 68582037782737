import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { userDetail } from "Stores/userDetail";
import Utils from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import Button from "Components/common/Button";
import { URLS, makeApi } from "Utils/apiURL";
import { MP_EVENT } from "Static/constant";
import InputBox from "Components/Register/InputBox";
import SequenceUtils from "Utils/SequenceUtils";
import { sequenceStoreData } from "Stores/sequenceData";

const payload = {
  accessType: "READ_WRITE",
  bulk: false,
  connectedEmail: null,
  currentStage: null,
  folderId: null,
  folderName: "My Sequences",
  shareType: "PRIVATE",
  stages: {},
  type: "New Sequence"
};

const CreateSequence = props => {
  const userData = userDetail?.userFeatureFlag || {};
  const {
    close = () => {},
    showUpgrade = false,
    sequenceName = "",
    setSequenceName = () => {},
    className = ""
  } = props || {};

  const [errMsg, setErrMsg] = useState("");
  const [seqLoader, setSeqLoader] = useState(false);

  const createNewSeqAndNavToOverview = async () => {
    if (!showUpgrade) {
      try {
        if (sequenceName.length > 0) {
          setSeqLoader(true);
          payload.name = sequenceName;
          const folderDetailsObj = Utils.getFolderDetails();
          const folderName = folderDetailsObj?.folderName;
          const folderId = folderDetailsObj?.folderId;
          payload.folderName = folderName || null;
          payload.folderId = folderId || null;
          const config = {
            url: URLS.createSequence,
            method: "POST",
            data: payload
          };
          let res = await makeApi(config);
          let resData = [];
          resData.push(res?.data);
          let finalData = SequenceUtils.getProccessedResponseData(resData);
          if (finalData?.length) {
            sequenceStoreData.setSelectedTemplateSequence(finalData);
          }
          if (res?.data?.id) {
            Utils.mixpanelTrack(MP_EVENT.SQ_CREATED, {
              sequenceName: sequenceName,
              sequenceType: "Manual",
              pageType: "Sequence Page"
            });
            userData.noOfSequencesUsed += 1;
            userDetail.setUserFeatureFlag(userData);
            toasterState.setToastMsg(
              "Sequence created successfully",
              "success"
            );
            setSeqLoader(false);
            close();
            props.history.push(
              `/sequences/${
                payload.folderId === null ? "all" : payload.folderId
              }/${res.data.id}`
            );
          } else if (res.response.status === 426) {
            setSeqLoader(false);
            close();
            Utils.showUpgradePopup("sequences", true, true);
          } else {
            setSeqLoader(false);
          }
        } else {
          setErrMsg("Sequence name is required");
          setTimeout(() => {
            setErrMsg("");
          }, 1500);
        }
      } catch (e) {
        setSeqLoader(false);
        close();
        console.error("Getting error on create new sequence", e);
      }
    } else {
      setSeqLoader(false);
      close();
      Utils.showUpgradePopup("sequences", true, true);
    }
  };

  const changeSeqName = value => {
    setSequenceName(value);
    if (value) {
      setErrMsg("");
    }
  };

  const onEnterCreateSeq = e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      createNewSeqAndNavToOverview();
    }
  };

  const inputObj = {
    name: "sequenceName",
    id: "sequenceName",
    label: "Sequence Name",
    type: "text",
    value: sequenceName,
    errMsg,
    errMsgClass: "inputError",
    changeAction: changeSeqName,
    keyupAction: onEnterCreateSeq,
    placeholder: "Enter Sequence Name"
  };

  const btnProps = {
    id: "createNewSequenceBtn",
    name: "createNewSequenceBtn",
    btnText: "Create Sequence",
    icon: false,
    iconName: "",
    type: "button",
    className: seqLoader ? "loading" : "",
    loader: seqLoader,
    click: createNewSeqAndNavToOverview,
    disabled: !sequenceName?.length
  };

  return (
    <div className={`createSequenceSection ${className}`}>
      <div className="createSequenceBox">
        <div className="inputTextBox">
          <InputBox autoFocus {...inputObj} />
        </div>
      </div>
      <Button {...btnProps} />
    </div>
  );
};

export default withRouter(CreateSequence);
export { CreateSequence };
