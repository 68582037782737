/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { CAPTCHA_INIT_PAGES } from "Static/constant";
import BreadCrumbComponent from "Components/common/breadcrumb";
import { ReactComponent as RightArrow } from "Assets/svg/rightArrow.svg";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import SettingsRouting from "Routes/SettingsRouting";
import { FreeTrial } from "Components/common/FreeTrial";
import { captchaStoreValue } from "Stores/captcha";
import Utils from "Utils/utils";
import PricingDiscountBanner from "Components/common/PricingDiscountBanner";
import UpgradeBadge from "Components/Paywall/UpgradeBadge";
import PaywallUtils from "Utils/PaywallUtils";

const settingsWithSubMenu = {
  groups: ["accountSettings", "contactSettings"],
  pages: [
    "dialer",
    "schedules",
    "imports",
    "triggers",
    "stages",
    "customUrl",
    "customFields",
    "emailSettings",
    "billing",
    "csvList",
    "creditUsage"
  ]
};

const SettingContext = createContext({});

const SettingsComponent = observer(props => {
  const { location = "" } = props || {};
  const {
    team = "",
    isTeamOwner = false,
    calendarEnabled = false
  } = userDetail?.userInfo || {};

  const {
    calendarIntegrationEnabled = false,
    inFreePlan = false,
    teamsEnabled = false,
    customFieldsEnabled = false,
    autoBccEnabled = false,
    contactStagesEnabled = false,
    donotContactEnabled = false,
    brandedUrlEnabled = false,
    dialerEnabled = false,
    triggersEnabled = false,
    planName = "",
    domainHealthCenterEnabled = false
  } = userDetail?.userFeatureFlag || {};

  const displayPlanName = (isHierachy = false) => {
    return PaywallUtils.getPlanName(planName, inFreePlan, isHierachy, "");
  };

  let settingsTabList = [
    {
      name: "My Profile",
      id: "general",
      path: "/settings/general"
    },
    { name: "Connected Accounts", id: "account", path: "/settings/account" },
    {
      name: "Users & Teams",
      id: "team",
      upgradePlan: teamsEnabled ? "" : displayPlanName(true), //usersEnabled
      path: "/settings/team"
    },
    {
      name: "Integrations",
      id: "crm",
      upgradePlan: "pro",
      path: "/settings/crm"
    },
    {
      name: "Auto Bcc",
      id: "autoBcc",
      upgradePlan: autoBccEnabled ? "" : displayPlanName(false), //autobcc,
      path: "/settings/autoBcc"
    },
    {
      name: "Unsubscribe",
      id: "unsubscribe",
      upgradePlan: "",
      path: "/settings/unsubscribe"
    },
    {
      name: "Contact Settings",
      id: "contactSettings",
      subMenu: [
        {
          name: "Contact Stages",
          id: "stages",
          upgradePlan: contactStagesEnabled ? "" : displayPlanName(false),
          path: "/settings/stages"
        },
        {
          name: "Do Not Contact Domains",
          id: "doNotContact",
          upgradePlan: donotContactEnabled ? "" : displayPlanName(false),
          path: "/settings/doNotContact",
          showBadgeIcon: false
        },
        {
          name: "Custom Fields",
          id: "customFields",
          upgradePlan: customFieldsEnabled ? "" : displayPlanName(false),
          path: "/settings/customFields"
        }
      ]
    },
    {
      name: "Account Settings",
      id: "accountSettings",
      subMenu: [
        {
          name: "Email Settings",
          id: "emailSettings",
          path: "/settings/emailSettings",
          upgradePlan: "basic"
        },
        {
          name: "Custom URL",
          id: "customUrl",
          path: "/settings/customUrl",
          upgradePlan: brandedUrlEnabled ? "" : displayPlanName(false)
        },
        {
          name: "Dialer",
          id: "dialer",
          path: "/settings/dialer",
          upgradePlan: dialerEnabled ? "" : displayPlanName(false)
        },
        {
          name: "Calendar",
          id: "calendar",
          path: "/settings/calendar",
          upgradePlan: calendarEnabled ? "" : displayPlanName(false)
        },
        {
          name: "Triggers",
          id: "triggers",
          path: "/settings/triggers",
          upgradePlan: triggersEnabled ? "" : displayPlanName(false)
        },
        {
          name: "Website Tracking",
          id: "websiteTracking",
          path: "/settings/website-tracking",
          hasBetaBadge: true
        },
        {
          name: "Domain Health Center",
          id: "domainHealthCenter",
          path: "/settings/domain-health-center",
          upgradePlan: domainHealthCenterEnabled ? "" : displayPlanName(false),
          showBadgeIcon: false
        },
        { name: "Schedules", id: "schedules", path: "/settings/schedules" },
        { name: "Imports", id: "imports", path: "/settings/imports" },
        {
          name: "Bulk Actions",
          id: "bulkActions",
          path: "/settings/bulk-actions"
        },
        {
          name: "Credit Usage",
          id: "creditUsage",
          path: "/settings/creditUsage"
        },
        { name: "Billing", id: "billing", path: "/settings/billing" }
      ]
    }
  ];

  const defaultBreadValue = [
    { name: "Settings", link: "/settings/general/profile" }
  ];

  const [breadcrumbArr, setBreadCrumbArr] = useState(defaultBreadValue);
  const [productDiscount, setProductDiscount] = useState(
    Utils.showProductDiscountBanner()
  );
  const [settingsTabArr, setSettingsTabArr] = useState(settingsTabList);
  const [isTeamPage, setIsTeamPage] = useState(false);

  const captchaInitialize = () => {
    captchaStoreValue.setCaptcha(
      CAPTCHA_INIT_PAGES.includes(location?.pathname)
    );
  };

  const getUpdateBreadcrumb = () => {
    const pathName = location?.pathname || "";
    setIsTeamPage(false);
    if (pathName.indexOf("/settings/crm/salesforce") !== -1) {
      updateBreadCrumb("Integrations - Salesforce Settings");
    } else if (pathName.indexOf("/settings/crm/zoho") !== -1) {
      updateBreadCrumb("Integrations - Zoho settings");
    } else if (pathName.indexOf("/settings/crm/hubspot") !== -1) {
      updateBreadCrumb("Integrations - Hubspot Settings");
    } else if (pathName.indexOf("/settings/crm/pipedrive") !== -1) {
      updateBreadCrumb("Integrations - Pipedrive Settings");
    } else if (pathName.indexOf("/settings/crm/slack") !== -1) {
      updateBreadCrumb("Integrations - Slack Settings");
    } else if (pathName.indexOf("/settings/crm") !== -1) {
      updateBreadCrumb("Integrations");
    } else if (pathName.indexOf("/settings/schedules") !== -1) {
      updateBreadCrumb("Schedules");
    } else if (pathName.indexOf("/settings/general/profile") !== -1) {
      updateBreadCrumb("My Profile");
    } else if (pathName.indexOf("/settings/general/email-reports") !== -1) {
      updateBreadCrumb("Email Reports");
    } else if (pathName.indexOf("/settings/autoBcc") !== -1) {
      updateBreadCrumb("Auto Bcc");
    } else if (pathName.indexOf("/settings/billing") !== -1) {
      updateBreadCrumb("Billing");
    } else if (team && pathName.indexOf("/settings/creditUsage") !== -1) {
      updateBreadCrumb("Credit Usage");
    } else if (team && pathName.indexOf("/settings/website-tracking") !== -1) {
      updateBreadCrumb("Website Tracking", true);
    } else if (pathName.indexOf("/settings/domain-health-center") !== -1) {
      updateBreadCrumb("Domain Health Center");
    } else if (pathName.indexOf("/settings/unsubscribe") !== -1) {
      updateBreadCrumb("Unsubscribe");
    } else if (pathName.indexOf("/settings/dialer") !== -1) {
      updateBreadCrumb("Dialer");
    } else if (pathName.indexOf("/settings/customUrl") !== -1) {
      updateBreadCrumb("Custom Url");
    } else if (team && pathName.indexOf("/settings/imports") !== -1) {
      updateBreadCrumb("Imports");
    } else if (team && pathName.indexOf("/settings/bulk-actions") !== -1) {
      updateBreadCrumb("Bulk Actions");
    } else if (pathName.indexOf("/settings/customFields") !== -1) {
      updateBreadCrumb("Custom Fields");
    } else if (pathName.indexOf("/settings/emailSettings") !== -1) {
      updateBreadCrumb("Email Settings");
    } else if (team && pathName.indexOf("/settings/triggers") !== -1) {
      updateBreadCrumb("Triggers");
    } else if (
      calendarEnabled &&
      calendarIntegrationEnabled &&
      pathName.indexOf("/settings/calendar/events") !== -1
    ) {
      updateBreadCrumb("Calendar Events");
    } else if (
      calendarEnabled &&
      calendarIntegrationEnabled &&
      pathName.indexOf("/settings/calendar/configuration") !== -1
    ) {
      updateBreadCrumb("Calendar Configuration");
    } else if (pathName.indexOf("/settings/stages") !== -1) {
      updateBreadCrumb("Contact Stages");
    } else if (team && pathName.indexOf("/settings/doNotContact") !== -1) {
      updateBreadCrumb("Do Not Contact Domains");
    } else if (
      (team || isTeamOwner) &&
      pathName.indexOf("/settings/team") !== -1
    ) {
      updateBreadCrumb("Users & Teams");
      setIsTeamPage(true);
    } else {
      updateBreadCrumb("Connected Accounts");
    }
  };

  const updateBreadCrumb = (name, hasBetaBadge = false) => {
    let settingPath = defaultBreadValue;
    settingPath.push({
      name,
      link: location?.pathname || "/settings/general/profile",
      hasBetaBadge
    });
    settingPath?.length && setBreadCrumbArr([...settingPath]);
  };

  const showTabsubMenu = id => {
    const tempArr = Utils.createObjWithoutRef(settingsTabList);
    if (tempArr) {
      tempArr &&
        tempArr.map(value => {
          value?.id?.toLowerCase() === id?.toLowerCase() &&
            (value.showSubMenu =
              typeof value.showSubMenu !== undefined
                ? !value.showSubMenu
                : true);
        });
      setSettingsTabArr(tempArr);
    }
  };

  const checkUrlAndShowSubMenu = () => {
    const settingsTabArr = Utils.createObjWithoutRef(settingsTabList);
    if (settingsTabArr?.length) {
      settingsTabArr.map(value => {
        if (value?.id && settingsWithSubMenu.groups.includes(value?.id)) {
          value.showSubMenu = true;
        }
      });
      setSettingsTabArr(settingsTabArr);
    }
  };

  useEffect(() => {
    captchaInitialize();
    getUpdateBreadcrumb();
  }, [props.location.pathname]);

  useEffect(() => {
    setProductDiscount(Utils.showProductDiscountBanner());
  }, [Utils.showProductDiscountBanner()]);

  useEffect(() => {
    document.body.classList.add("settingsPage");
    checkUrlAndShowSubMenu();
    return () => {
      document.body.classList.remove("settingsPage");
    };
  }, []);

  return (
    <div className={`settingsPage pageClass`}>
      <div className="settingsCont pageCont">
        <section className="headerSection">
          <BreadCrumbComponent breadCrumbValue={breadcrumbArr} />
          <div className="add-new-dropdown settingsPageHeader">
            <FreeTrial {...props} />
          </div>
        </section>
        {!productDiscount && <PricingDiscountBanner />}
        <section
          className={`settingSection ${
            !productDiscount ? "productDiscount" : ""
          }`}
        >
          <div className="leftSection">
            <div className="tabContainer">
              {settingsTabArr?.length
                ? settingsTabArr.map(item => {
                    const {
                      id = "",
                      subMenu = [],
                      showSubMenu = false
                    } = item || {};
                    return subMenu?.length ? (
                      <TabWithSubMenu
                        key={`settingsTabSubmenu${id}`}
                        {...item}
                        showTabsubMenu={showTabsubMenu}
                        showSubMenu={showSubMenu}
                        team={team}
                        calendarEnabled={calendarEnabled}
                        calendarIntegrationEnabled={calendarIntegrationEnabled}
                      />
                    ) : (
                      (id !== "team" ||
                        ((team || isTeamOwner) && id === "team")) && (
                        <Tab key={`settingsUrlPath${id}`} {...item} />
                      )
                    );
                  })
                : ""}
            </div>
          </div>
          <div className={`rightSection  ${isTeamPage ? "isTeamPage" : ""}`}>
            <SettingContext.Provider
              value={{
                updateBreadCrumb,
                getUpdateBreadcrumb,
                breadcrumbArr,
                setBreadCrumbArr
              }}
            >
              <SettingsRouting />
            </SettingContext.Provider>
          </div>
        </section>
      </div>
    </div>
  );
});

const Tab = observer(props => {
  let location = useLocation();

  const {
    id = "",
    name = "",
    upgradePlan = "",
    path = "",
    showBadgeIcon = true
  } = props || {};

  const { inFreePlan = false, planName = "" } =
    userDetail?.userFeatureFlag || {};

  const multipleRouteConditions = {
    calendar: "/settings/calendar",
    stages: "/settings/stages",
    dialer: "/settings/dialer",
    general: "settings/general",
    crm: "/settings/crm",
    account: "/settings/account"
  };

  const checkActiveTabForMultipleRoutes = () => {
    const path = multipleRouteConditions[id];
    return path && location?.pathname.includes(path);
  };

  return (
    <NavLink
      to={path}
      exact={true}
      activeClassName="selected"
      className={`tabValue ${
        ["calendar", "stages", "dialer", "general", "crm", "account"].includes(
          id
        ) && checkActiveTabForMultipleRoutes()
          ? "selected"
          : ""
      }`}
    >
      <div className="navWrapper">
        <div id={id} className="tab">
          {name}
        </div>
        {((planName?.toLowerCase() === "basic" &&
          planName?.toLowerCase() !== upgradePlan) ||
          inFreePlan) && (
          <UpgradeBadge
            plan={upgradePlan && `${upgradePlan}Plan`}
            badgeSize={"md"}
            showBadgeIcon={showBadgeIcon}
          />
        )}
      </div>
      <span className="rightArrow">
        <RightArrow />
      </span>
    </NavLink>
  );
});

const TabWithSubMenu = props => {
  const {
    id = "",
    name = "",
    subMenu = "",
    showTabsubMenu = "",
    showSubMenu = "",
    team = false,
    calendarEnabled = false,
    calendarIntegrationEnabled = false
  } = props || {};

  return subMenu?.length ? (
    <div
      className={`tabValue subMenu ${showSubMenu ? "openSubMenu" : ""}`}
      id={id}
    >
      <div id={id} className="tab" onClick={e => showTabsubMenu(id)}>
        <span className="tabText"> {name}</span>
        <i className="material-icons subMenuDownArrow">keyboard_arrow_down</i>
      </div>
      {showSubMenu && (
        <div className="subMenuList">
          {subMenu.map(item => {
            if (
              item?.id?.toLowerCase() !== "calendar" ||
              (["calendar"].includes(item?.id) && calendarEnabled)
            ) {
              let isNonAdmin =
                !team &&
                [
                  "triggers",
                  "imports",
                  "creditUsage",
                  "stages",
                  "doNotContact"
                ].includes(item?.id);
              return !isNonAdmin ? (
                <Tab
                  key={`settingSubmenuAccountSettings${item?.id}`}
                  {...item}
                />
              ) : (
                ""
              );
            }
          })}
        </div>
      )}
    </div>
  ) : (
    ""
  );
};

export default withRouter(SettingsComponent);
export { SettingsComponent, SettingContext };
