import React from "react";
import ToggleBtnType from "Components/common/ToggleBtnType";
import DropdownWithCheckBox from "./DropdownWithCheckBox";
import Utils from "Utils/utils";
import { ReactComponent as PersonAdd } from "Assets/svg/person_add.svg";

const EmailPreferenceCard = (props = {}) => {
  const {
    toggleLoader = "",
    title = "",
    text = "",
    toAddress = [],
    type = "",
    enabled = false,
    handleToggleChange = () => {},
    handleEditTeamList = () => {}
  } = props || {};

  const toggleObj = {
    id: type,
    defaultValue: enabled,
    lastText: title,
    cbk: handleToggleChange,
    enableLoading: toggleLoader === type
  };

  return (
    <div className="epCardBody">
      <div className="cardCBody">
        <div className="cBody">
          <ToggleBtnType {...toggleObj} />
          <p>{text}</p>
        </div>
        <div className="avatarFlex">
          <div
            className={`avatar ${!enabled ? "disabled" : ""} `}
            onClick={() => {
              enabled && handleEditTeamList(type);
            }}
          >
            <span>
              <PersonAdd />
            </span>
          </div>
          {toAddress?.length > 0 &&
            toAddress?.map(item => {
              return (
                <div
                  className={`avatar ${!enabled ? "disabled" : ""} `}
                  key={item?.email}
                >
                  <span>
                    {Utils.getUserProfileIcon(item?.name, "", item?.email)}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <DropdownWithCheckBox {...props} />
    </div>
  );
};

export default EmailPreferenceCard;
