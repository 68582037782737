/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { userDetail } from "Stores/userDetail";
import Utils from "Utils/utils";
import AddBulkContactPopupTitle from "./AddBulkContactPopupTitle";
import CsvUpload from "./CsvUpload";
import BulkContactTable from "./BulkContactTable";
import BulkPopupMessage from "./BulkPopupMessage";
import makeApi, { URLS } from "Utils/apiURL";
import BulkUploadingLoader from "./BulkUploadingLoader";
import Button from "../Button";
import { toasterState } from "../toaster";
import { CSV_MAPPING_FIELDS } from "Model/model";
import { settingsStoreData } from "Stores/settingsData";
import { SequenceUtils } from "Utils/SequenceUtils";
import { CONTACT_BULK_DROPDOWN_DEFAULT_OPTION } from "Static/constant";

class AddBulkContactPopupState {
  @observable showPopup = false;
  @observable submitCallback = () => {};
  @observable scheduleSequenceCbk = () => {};
  @observable tokenList = "";
  @observable sequenceData = {};
  @observable pageType = "";
  @observable fileName = "";

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setSubmitCallback = val => {
    this.submitCallback = val;
  };

  @action
  setScheduleSequenceCbk = val => {
    this.scheduleSequenceCbk = val;
  };

  @action
  setTokenList = val => {
    this.tokenList = val;
  };

  @action
  setSequenceData = val => {
    this.sequenceData = val;
  };

  @action
  setPageType = val => {
    this.pageType = val;
  };

  @action
  setFileName = value => {
    this.fileName = value;
  };
}

const addBulkContactPopupState = new AddBulkContactPopupState();

let optionsList = CSV_MAPPING_FIELDS;
optionsList.unshift({
  key: "empty",
  label: CONTACT_BULK_DROPDOWN_DEFAULT_OPTION
});

const AddBulkContactPopup = observer(() => {
  const history = useHistory();

  const [showCsvUploader, setShowCsvUploader] = useState(true);
  const [showBulkUploadingLoader, setShowBulkUploadingLoader] = useState(false);
  const [showContactTable, setShowContactTable] = useState(false);
  const [contactTableData, setContactTableData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [errorDetails, setErrorDetails] = useState({});
  const [showSkipContactsBtn, setShowSkipContactsBtn] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const [mappingFields, setMappingFields] = useState([]);
  const [dropDownoptionsList, setDropDownOptionsList] = useState(optionsList);
  const [retainUserMappedFields, setRetainUserMappedFields] = useState(false);

  const {
    id: sequenceId = "",
    emailValidationEnabled: sequenceEmailValidationEnabled = false
  } = addBulkContactPopupState?.sequenceData || "";
  const {
    emailValidationCredits = 0,
    emailValidationEnabled = false,
    customFieldsCount = 0
  } = userDetail?.userFeatureFlag || {};

  const csvUploadCbk = async fileData => {
    if (fileData) {
      setShowCsvUploader(false);
      setShowBulkUploadingLoader(true);
      setLoadingMessage("Generating Preview...");
      Utils.processContactFromCsvFile(
        fileData,
        sequenceId,
        addBulkContactPopupState.pageType,
        renderContact,
        closeAction
      );
    }
  };

  const renderContact = (flag, message, contactData, filename = "") => {
    if (flag === "success") {
      setShowBulkUploadingLoader(false);
      setShowCsvUploader(false);
      setContactTableData(contactData);
      addBulkContactPopupState.setFileName(filename);
      setShowContactTable(true);
    } else if (flag === "error") {
      closeAction();
      toasterState.setToastMsg("Invalid csv file", "failure");
    } else if (flag === "limitReaced") {
      setErrorDetails({ limitReachedMessage: message });
      setShowBulkUploadingLoader(false);
      setShowContactTable(false);
      setShowSkipContactsBtn(false);
      setShowMessage(true);
    } else {
      closeAction();
    }
  };

  const uploadAction = e => {
    Utils.preventDefaultFn(e);
    const payload = constructBulkApiPayload();
    if (addBulkContactPopupState.pageType === "contacts") {
      validateMandatoryFields(payload);
    } else if (addBulkContactPopupState.pageType === "sequence") {
      let contactsUploadLen = contactTableData?.data?.length || 0;
      if (sequenceEmailValidationEnabled) {
        if (
          emailValidationEnabled &&
          emailValidationCredits < contactsUploadLen
        ) {
          SequenceUtils.promptEmailValidationNoCredits(
            contactsUploadLen - emailValidationCredits,
            history,
            () => validateMandatoryFields(payload, sequenceId),
            closeAction,
            "sequence"
          );
          return;
        }
      }
      validateMandatoryFields(payload, sequenceId);
    }
  };

  const constructBulkApiPayload = () => {
    const userMappedFields = Utils.createObjWithoutRef(mappingFields);
    const contactData = Utils.createObjWithoutRef(contactTableData.data);
    const payloadArr = [];
    contactData &&
      contactData.map(value => {
        const tempObj = {};
        userMappedFields &&
          userMappedFields.map(obj => {
            if (Object.keys(obj)?.length > 0) {
              const {
                systemMappedField,
                userMappedField,
                isValidField,
                mappedLabel
              } = obj;
              // empty id used for non mapped fields which contains data fields
              if (
                isValidField &&
                userMappedField !== "empty" &&
                systemMappedField !== "empty"
              ) {
                if (systemMappedField === userMappedField) {
                  if (
                    systemMappedField.toLowerCase().indexOf("customfield") !==
                    -1
                  ) {
                    tempObj[systemMappedField.toLowerCase()] =
                      value[mappedLabel];
                  } else {
                    tempObj[systemMappedField.toLowerCase()] =
                      value[systemMappedField];
                  }
                } else if (systemMappedField !== userMappedField) {
                  const systemMappedLabel =
                    getCustomFieldMappedLabel(systemMappedField);
                  if (
                    systemMappedField.toLowerCase().indexOf("customfield") !==
                    -1
                  ) {
                    tempObj[userMappedField.toLowerCase()] =
                      value[systemMappedLabel];
                  } else {
                    tempObj[userMappedField.toLowerCase()] =
                      value[systemMappedField];
                  }
                }
              }
            }
          });
        payloadArr.push(tempObj);
      });
    return payloadArr;
  };

  const getCustomFieldMappedLabel = systemMappedField => {
    if (contactTableData && contactTableData.customMapping) {
      const customMapping = Utils.createObjWithoutRef(
        contactTableData.customMapping
      );

      return Object.keys(customMapping).find(
        key => customMapping[key] === systemMappedField
      );
    }
  };

  const closeAction = () => {
    setShowCsvUploader(true);
    setShowBulkUploadingLoader(false);
    setShowContactTable(false);
    setContactTableData([]);
    setShowMessage(false);
    setErrorDetails("");
    setUserInteracted(false);
    setShowSkipContactsBtn(false);
    setRetainUserMappedFields(false);
    addBulkContactPopupState.setShowPopup(false);
  };

  const validateMandatoryFields = async (payload, sequenceId) => {
    if (payload) {
      setShowContactTable(false);
      setShowMessage(false);
      setShowBulkUploadingLoader(true);
      setLoadingMessage("We are processing your contacts. Please wait...");
      const mappedStringVal = JSON.stringify(payload);
      const config = {
        url: URLS.validateBulkContact,
        method: "POST",
        data: { jsonString: mappedStringVal, sequenceId }
      };
      let response = await makeApi(config);
      if (response?.data) {
        const { validCount, uploadedCount, missingFields, validEmails } =
          response.data;
        setTimeout(() => {
          setShowBulkUploadingLoader(false);
          if (
            validCount === uploadedCount ||
            (!sequenceId && missingFields?.length === 0)
          ) {
            addBulkContactPopupState.submitCallback(
              payload,
              addBulkContactPopupState.scheduleSequenceCbk,
              sequenceId
            );
            closeAction();
          } else {
            handleUploadContactError(
              validCount,
              uploadedCount,
              missingFields,
              validEmails,
              payload
            );
          }
        }, 500);
      } else {
        closeAction();
        response?.request?.status === 413
          ? Utils.contactUploadLimitExceedPopup()
          : toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
      }
    }
  };

  const constructErrorDetails = (
    validCount,
    uploadedCount,
    missingFields,
    validEmails,
    originalContactPayload
  ) => {
    const tempObj = {
      validCount,
      uploadedCount,
      missingFields,
      validEmails,
      cbk: skipAndUploadContact,
      originalContactPayload,
      closeAction
    };
    return tempObj;
  };

  const handleUploadContactError = (
    validCount,
    uploadedCount,
    missingFields,
    validEmails,
    originalContactPayload
  ) => {
    setShowBulkUploadingLoader(false);
    setShowCsvUploader(false);
    setShowContactTable(false);
    setShowMessage(true);
    if (validCount === 0) {
      setShowSkipContactsBtn(false);
    } else {
      setShowSkipContactsBtn(true);
    }
    setErrorDetails(
      constructErrorDetails(
        validCount,
        uploadedCount,
        missingFields,
        validEmails,
        originalContactPayload
      )
    );
  };

  const skipAndUploadContact = (validEmails, originalContactPayload) => {
    const skippedContactPayload = getSkippedContactPayload(
      validEmails,
      originalContactPayload
    );
    addBulkContactPopupState.submitCallback(
      skippedContactPayload,
      addBulkContactPopupState.scheduleSequenceCbk
    );
    closeAction();
  };

  const getSkippedContactPayload = (validEmails, originalContactPayload) => {
    if (originalContactPayload && originalContactPayload.length) {
      const tempArr = [];
      originalContactPayload.map(value => {
        const { email } = value;
        if (validEmails.includes(email)) {
          tempArr.push(value);
        }
      });
      return tempArr;
    }
  };

  const backToContactTable = () => {
    setShowBulkUploadingLoader(false);
    setShowCsvUploader(false);
    setShowMessage(false);
    setRetainUserMappedFields(true);
    setShowContactTable(true);
  };

  useEffect(() => {
    setDropDownOptionsList(
      Utils.addUserUpdatedCusotmFields(dropDownoptionsList, customFieldsCount)
    );
  }, [settingsStoreData?.customFields]);

  return addBulkContactPopupState.showPopup ? (
    <div className="popupWrapper addBulkContactWrapper">
      <div className="confirmation-popup common-popup">
        <form
          className="popup-wrap animated customzoomIn"
          onSubmit={e => nextAction(e)}
          autoComplete="off"
        >
          <AddBulkContactPopupTitle
            userInteracted={userInteracted}
            closeCbk={closeAction}
          />
          <div className="popup-content-wrap">
            {showBulkUploadingLoader && (
              <BulkUploadingLoader loadingMessage={loadingMessage} />
            )}
            {showCsvUploader && (
              <div className="uploadContainer">
                <CsvUpload csvUploadCbk={csvUploadCbk} />
              </div>
            )}
            {showContactTable && (
              <>
                <BulkContactTable
                  contactTableData={contactTableData}
                  setUserInteracted={setUserInteracted}
                  mappingFields={mappingFields}
                  setMappingFields={setMappingFields}
                  dropDownoptionsList={dropDownoptionsList}
                  retainUserMappedFields={retainUserMappedFields}
                />
                <UploadButton uploadAction={uploadAction} />
              </>
            )}
            {showMessage && (
              <BulkPopupMessage
                closeCbk={closeAction}
                errorDetails={errorDetails}
                showSkipContacts={showSkipContactsBtn}
                backToContactTable={backToContactTable}
              />
            )}
          </div>
        </form>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

const UploadButton = props => {
  const { uploadAction = () => {} } = props || {};
  return (
    <div className="actionCont">
      <Button
        id="uploadBulkContact"
        name="addContactBtn"
        type="button"
        className="addContactCont"
        btnText="Upload Contacts"
        icon={true}
        loader={false}
        click={e => {
          uploadAction(e);
        }}
      />
    </div>
  );
};

export { AddBulkContactPopup, addBulkContactPopupState };
export default AddBulkContactPopup;
