import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import TasksComponent from "Pages/Dashboard/tasks/main";
import PerformTaskComponent from "Pages/Dashboard/tasks/PerformTask/main.js";

const TaskRouting = () => {
  return (
    <Switch>
      <Route exact path="/tasks" component={TasksComponent} />
      <Route exact path="/tasks/start" children={<PerformTaskComponent />} />
      <Route
        exact
        path="/tasks/start/:id?"
        children={<PerformTaskComponent />}
      />
      <Redirect from="/tasks" to="/tasks" />
    </Switch>
  );
};
export default TaskRouting;
