import React from "react";
import { DateUtils } from "Utils/DateUtils";

const PricingPageTrialBanner = props => {
  const { trialEndDate = "", showBelowText = true } = props;

  const calculateTrialEndDate = () => {
    return DateUtils.getDateAndMonth(trialEndDate, true);
  };

  return (
    <div className="pricingPageTrialBanner">
      <h6 className="trialMessaging">
        You are currently on the 14-Day Unlimited plan trial
      </h6>
      <div className="upgradeMessaging">
        {`On ${calculateTrialEndDate()}, you will be automatically downgraded to the Free plan. To continue unrestricted access to Salesgear, upgrade to one of our premium plans ${
          showBelowText ? "below" : ""
        }`}
      </div>
    </div>
  );
};

export { PricingPageTrialBanner };
export default PricingPageTrialBanner;
