import React, { useState, useEffect } from "react";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "Assets/svg/website/logo.svg";
import { WEBSITE_URL } from "Static/constant";

const LogoutHeader = (props = {}) => {
  let location = useLocation();
  const {
    isRegisterPage = false,
    isDealify = false,
    isLogin = false,
    isSignup = false,
    isPluginLogin = false,
    isPluginSignup = false,
    redirectLoginUrl = "/login",
    redirectSignupUrl = "/signup",
    isTeamPage = false
  } = props;

  const [showmenu, setShowMenu] = useState(false);
  const leftNavBar = [
    {
      name: "Pricing",
      url: `${WEBSITE_URL}/pricing`,
      className: "btn-link active-nav",
      id: "pricing"
    },
    {
      name: "About",
      url: `${WEBSITE_URL}/about-us`,
      className: "btn-link",
      id: "aboutUs"
    },
    {
      name: "Blog",
      url: `${WEBSITE_URL}/blog`,
      className: "btn-link",
      id: "blog"
    }
  ];

  const rightNavBar = [
    {
      name: "Log In",
      url: redirectLoginUrl,
      className: "btn-link",
      id: "login",
      text: "Already have an account?",
      show: isRegisterPage && (isLogin || isPluginLogin) ? false : true || true
    },
    {
      name: isRegisterPage ? "Sign Up" : "TRY FOR FREE",
      url: redirectSignupUrl,
      className: `${
        isRegisterPage
          ? "btn-link"
          : "btn-new btn-rounded btn-orange btn-text btn-arrow"
      }`,
      id: "signup",
      show:
        isRegisterPage && (isSignup || isPluginSignup) ? false : true || true
    }
  ];

  const mobileNavBar = [
    {
      name: "Pricing",
      url: `${WEBSITE_URL}/pricing`,
      className: "btn-link active-nav",
      id: "pricingMobile",
      show: isRegisterPage ? false : true
    },
    {
      name: "About",
      url: `${WEBSITE_URL}/about-us`,
      className: "btn-link",
      id: "aboutUsMobile",
      show: isRegisterPage ? false : true
    },
    {
      name: "Blog",
      url: `${WEBSITE_URL}/blog`,
      className: "btn-link",
      id: "blogMobile",
      show: isRegisterPage ? false : true
    },
    {
      name: "Log In",
      url: redirectLoginUrl,
      className:
        isRegisterPage &&
        !isSignup &&
        !isLogin &&
        !isPluginLogin &&
        !isPluginSignup
          ? "btn-link"
          : "btn-link-rounded",
      id: "loginMobile",
      show: !(isRegisterPage && (isLogin || isPluginLogin))
    },
    {
      name: isRegisterPage ? "Sign Up" : "TRY FOR FREE",
      url: redirectSignupUrl,
      className: "btn-link-rounded",
      id: "signupMobile",
      show:
        isSignup || isPluginSignup
          ? false
          : (isRegisterPage ? true : false) || false
    }
  ];

  const navbarLeft = () => {
    return (
      !isRegisterPage &&
      !isDealify && (
        <ul className="navbar-left">
          {leftNavBar?.map(item => {
            const { id = "", name = "", url = "", className = "" } = item || {};
            return (
              <li key={id}>
                <a className={className} href={url}>
                  {name}
                </a>
              </li>
            );
          })}
        </ul>
      )
    );
  };

  const navbarRight = () => {
    return (
      !isDealify &&
      !isTeamPage && (
        <ul className="navbar-right">
          {rightNavBar?.map(item => {
            const {
              id = "",
              name = "",
              url = "",
              className = "",
              text = "",
              show = ""
            } = item || {};
            return (
              show && (
                <li key={id}>
                  {isRegisterPage && text && (
                    <span className="loginText">{text}</span>
                  )}
                  <NavLink
                    to={url}
                    key={name}
                    className={className}
                    activeClassName={id !== "signup" ? "active-nav" : ""}
                  >
                    {name}
                  </NavLink>
                </li>
              )
            );
          })}
        </ul>
      )
    );
  };

  const mobileDropdownMenu = () => {
    return (
      !isDealify &&
      !isTeamPage &&
      showmenu && (
        <div className="navbar-dropdown-menu">
          <div className="container">
            <ul className="mobile-navbar-menu-items">
              {mobileNavBar?.map(item => {
                const {
                  id = "",
                  name = "",
                  url = "",
                  className = "",
                  show = false
                } = item || {};
                return (
                  show && (
                    <li key={id}>
                      <NavLink
                        to={url}
                        key={name}
                        activeClassName={"active-nav"}
                        href={isLogin ? "#" : url}
                        className={className}
                      >
                        {name}
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </div>
      )
    );
  };

  useEffect(() => {
    return () => {
      setShowMenu(false);
    };
  }, [location]);

  return (
    <header className="website-header">
      <div className="container">
        <nav>
          <div className="headerLogo">
            <a href={WEBSITE_URL} target="_self" className="d-flex">
              <Logo />
            </a>
          </div>
          <div className={`heaader-navbar ${isRegisterPage ? "flex-end" : ""}`}>
            {navbarLeft()}
            {navbarRight()}
          </div>
          {!isDealify && !isTeamPage && (
            <span
              id="mobileDropdownMenu"
              className="material-icons-outlined open-close-menu"
              onClick={() => setShowMenu(!showmenu)}
            >
              {showmenu ? "close" : "menu"}
            </span>
          )}
        </nav>
      </div>
      {mobileDropdownMenu()}
    </header>
  );
};

export default withRouter(LogoutHeader);
