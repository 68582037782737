/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { userDetail } from "Stores/userDetail";
import Utils from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import { URLS, makeApi } from "Utils/apiURL";
import { MP_EVENT } from "Static/constant";
import SequenceTemplateData from "Stores/sequence/SequenceTemplateData";
import Button from "Components/common/Button";
import StagesCard from "./StagesCard";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import SequenceUtils from "Utils/SequenceUtils";
import TitleBadge from "./TitleBadge";
import { seqTemplatePopupState } from "./SeqTemplatePopupState";
import ProgressBar from "./ProgressBar";
import PaywallUtils from "Utils/PaywallUtils";
import { sequenceStoreData } from "Stores/sequenceData";

const ActionButtons = observer(props => {
  const { loader = false, action = () => {} } = props;
  const backBtnObj = {
    id: "backBtn",
    name: "backBtn",
    btnText: "Back",
    icon: false,
    iconName: "",
    type: "button",
    className: "backBtn",
    click: () => {
      SequenceTemplateData.setSeqTemmplateClicked(false);
      seqTemplatePopupState.setProgressBarState(1);
    }
  };

  const createAndCustomizeBtnObj = {
    id: "createCustomizeSeqBtn",
    name: "createCustomizeSeqBtn",
    btnText: "Create And Customize",
    icon: false,
    iconName: "",
    type: "button",
    className: loader ? "loading" : "",
    loader: loader,
    click: () => action()
  };

  return (
    <div className="btnSection">
      <Button {...backBtnObj} />
      <Button {...createAndCustomizeBtnObj} />
    </div>
  );
});

const TemplateSelectedStages = props => {
  const { close } = props || {};
  const userData = userDetail?.userFeatureFlag || {};
  const { inFreePlan = false, planName = "" } =
    userDetail?.userFeatureFlag || {};
  const [selectedTemplate, setSelectedTemplate] = useState(
    SequenceTemplateData.selectedPredefinedTemplate
  );
  const [loader, setLoader] = useState(false);
  const [seqName, setSeqName] = useState("");
  const [stagesLen, setStagesLen] = useState(0);
  const [totalDays, setTotalDays] = useState(0);

  const constructPayload = () => {
    if (selectedTemplate && Object.keys(selectedTemplate).length > 0) {
      delete selectedTemplate.id;
      delete selectedTemplate.memberId;
      const folderDetailsObj = Utils.getFolderDetails();
      const folderName = folderDetailsObj.folderName;
      const folderId = folderDetailsObj.folderId;
      selectedTemplate.folderName = folderName || null;
      selectedTemplate.folderId = folderId || null;
      const tempStages = selectedTemplate.steps;
      for (let key in tempStages) {
        tempStages[key].uniqueId = key;
      }
      selectedTemplate.steps = tempStages;
      const greatestUniqueId = Math.max.apply(
        Math,
        Utils.convertObjectToArray(tempStages).map(function (item) {
          return item.uniqueId;
        })
      );
      selectedTemplate.greatestUniqueId = greatestUniqueId;
      setSelectedTemplate(selectedTemplate);
    }
    return selectedTemplate;
  };

  const createCustomizeSeqAndNavToOverview = async () => {
    const eligibleCount = SequenceUtils.checkEligibleSequenceCount();
    const isStagesHasTask = SequenceUtils.hasTask(selectedTemplate?.steps);
    if (
      eligibleCount &&
      ((isStagesHasTask && userData?.taskEnabled) || !isStagesHasTask)
    ) {
      try {
        if (selectedTemplate && Object.keys(selectedTemplate).length > 0) {
          setLoader(true);
          const config = {
            url: URLS.updateSequence,
            method: "POST",
            data: constructPayload()
          };
          let res = await makeApi(config);
          let resData = [];
          resData.push(res?.data);
          let finalData = SequenceUtils.getProccessedResponseData(resData);
          if (finalData?.length) {
            sequenceStoreData.setSelectedTemplateSequence(finalData);
          }
          SequenceTemplateData.setSeqTemmplateClicked(false);
          seqTemplatePopupState.setProgressBarState(1);
          if (res?.data?.id) {
            Utils.mixpanelTrack(MP_EVENT.SQ_CREATED, {
              sequenceName: seqName,
              sequenceType: "Template",
              pageType: "Sequence Page"
            });
            setLoader(false);
            userData.noOfSequencesUsed += 1;
            userDetail.setUserFeatureFlag(userData);
            toasterState.setToastMsg(
              "Sequence created successfully",
              "success"
            );
            close();
            Utils.getOnboardingData();
            props.history.push(
              `/sequences/${
                selectedTemplate.folderId === null
                  ? "all"
                  : selectedTemplate.folderId
              }/${res.data.id}`
            );
          } else if (res.response.status === 426) {
            setLoader(false);
            close();
            Utils.showUpgradePopup("sequences", true, true);
          } else {
            setLoader(false);
          }
        }
      } catch (e) {
        setLoader(false);
        close();
        console.error(
          "Getting error on create predefined template sequence",
          e
        );
      }
    } else {
      setLoader(false);
      close();
      PaywallUtils.upgradeUtilsFunction(
        "newSequenceWithTask",
        true,
        planName,
        inFreePlan
      );
    }
  };

  const getSeqTotalDays = steps => {
    let totalDays = 0;
    for (const [key, value] of Object.entries(steps)) {
      totalDays += value.daysLater;
    }
    setTotalDays(totalDays);
  };

  const getNameAndStagesLength = () => {
    if (SequenceTemplateData.selectedPredefinedTemplate) {
      const { steps, name } = SequenceTemplateData.selectedPredefinedTemplate;
      setSelectedTemplate(SequenceTemplateData.selectedPredefinedTemplate);
      if (steps && Object.keys(steps).length > 0) {
        setStagesLen(Object.keys(steps).length);
        getSeqTotalDays(steps);
      }
      setSeqName(name);
    }
  };

  const handleProgressBarClick = index => {
    if (index === 1) {
      seqTemplatePopupState.setProgressBarState(index);
      SequenceTemplateData.setSeqTemmplateClicked(false);
    }
  };

  useEffect(() => {
    getNameAndStagesLength();
  }, [SequenceTemplateData.selectedPredefinedTemplate]);

  return (
    <div className="sequenceStagesSection">
      <TitleBadge title={seqName} showDesc={true}>
        <div className="seqStepsDaysWrapper">
          <span className="totalStages">{stagesLen} total steps</span>{" "}
          <span className="totalDays">{totalDays} days in sequence</span>
        </div>
      </TitleBadge>
      <ProgressBar cbk={handleProgressBarClick} />
      <StagesCard />
      <ActionButtons
        loader={loader}
        action={createCustomizeSeqAndNavToOverview}
      />
    </div>
  );
};

export default withRouter(observer(TemplateSelectedStages));
export { TemplateSelectedStages };
