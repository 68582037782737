import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import ConstantObj from "Static/constant";
import { fileLimitExceedState } from "Components/FileAttachment/FileLimitExceedPopup";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";

export const FileAttachment = observer(({ fromTaskPage = false }) => {
  const [totalSizeUploaded, setTotalSizeUploaded] = useState(
    fileAttachmentStore.totalSizeUploaded
  );
  const [taskTotalFileSizeUploaded, setTaskTotalFileSizeUploaded] = useState(
    fileAttachmentStore?.totalTaskSizeUploaded || 0
  );

  const checkTotalFileSize = (totalSizeInMb, total = 20) => {
    if (Math.ceil(totalSizeInMb) <= total) {
      if (fromTaskPage) {
        setTaskTotalFileSizeUploaded(totalSizeInMb);
        fileAttachmentStore.setTaskTotalFileSizeUploaded(totalSizeInMb);
      } else {
        setTotalSizeUploaded(totalSizeInMb);
        fileAttachmentStore.setTotalSizeUploaded(totalSizeInMb);
      }
      return true;
    } else {
      return false;
    }
  };

  const checkFileSize = (fileList = {}) => {
    let allowFileUpload = true;
    let totalFileSizeInMb = fromTaskPage
      ? taskTotalFileSizeUploaded
      : totalSizeUploaded;
    const { total = 0, single = 0 } = ConstantObj?.attachmentFileSize || {};
    Object.values(fileList).forEach(file => {
      let fileSize = (file?.size || 0) / 1024 / 1024 || 0;
      if (fileSize > single) {
        allowFileUpload = false;
      } else {
        totalFileSizeInMb += fileSize;
      }
    });
    return (
      (allowFileUpload && checkTotalFileSize(totalFileSizeInMb, total)) || false
    );
  };

  const uploadFile = (event = "") => {
    if (
      fileAttachmentStore?.fileUploadingLoader ||
      fileAttachmentStore?.taskFileUploadingLoader
    ) {
      return;
    }
    const fileList = event && event.target && event.target.files;
    let allowFileUpload = checkFileSize(fileList);
    if (allowFileUpload) {
      let formData = new FormData();
      for (let file of fileList) {
        formData.append("file", file);
      }
      let totalFileCount = (fileList && Object.keys(fileList)?.length) || 0;
      if (fromTaskPage) {
        fileAttachmentStore.setTaskFileUploadingLoader(true);
        fileAttachmentStore.setTaskTotalFileUploading(totalFileCount);
      } else {
        fileAttachmentStore.setFileUploadingLoader(true);
        fileAttachmentStore.setTotalFileUploading(totalFileCount);
      }
      fileAttachmentStore.uploadFileAttachment(formData, fromTaskPage);
    } else {
      fileLimitExceedState.setMessage(`Each File in the attachment <b>should not be more than 3MB</b> and
      total size of attachments <b>should not exceed 20MB</b>. Please
      reduce the file size and try again.`);
      fileLimitExceedState.setType("attachment");
      fileLimitExceedState.setShowPopup(true);
    }
  };

  useEffect(() => {
    setTaskTotalFileSizeUploaded(
      fileAttachmentStore?.taskTotalFileSizeUploaded || 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileAttachmentStore?.taskTotalFileSizeUploaded]);

  useEffect(() => {
    setTotalSizeUploaded(fileAttachmentStore.totalSizeUploaded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileAttachmentStore?.totalSizeUploaded]);

  useEffect(() => {
    return () => {
      setTaskTotalFileSizeUploaded(0);
      setTotalSizeUploaded(0);
    };
  }, []);

  return (
    <>
      <input
        type="file"
        id="attachmentUploadFile"
        className="attachmentUploadFile"
        onChange={e => uploadFile(e)}
        multiple
        value=""
      />
      <i className="material-icons attachmentIcon">attach_file</i>
    </>
  );
});

export default FileAttachment;
