import React from "react";

const OverviewTableLoader = () => {
  return (
    <div className="overviewCardList">
      {Array.from({ length: 10 }, (value, index) => (
        <div className="overviewCardLoader" key={`overviewCardLoader${index}`}>
          <div className="toggleBtnSection linear-background" />
          <div className="name linear-background" />
          <div className="date linear-background" />
          <div className="contactOwner linear-background" />
          <div className="moreOptionSlide linear-background" />
        </div>
      ))}
    </div>
  );
};

export default OverviewTableLoader;
