import React from "react";
import TeamInviteImg from "Assets/Register/teamInviteIcon.png";
import { userDetail } from "Stores/userDetail";
import { observer } from "mobx-react";

const TeamContentBody = () => {
  let { adminName = "" } = userDetail.userTeamInfo || {};

  return (
    <div className="teamContentSection">
      <h1
        className="teamInvitedName"
        dangerouslySetInnerHTML={{
          __html:
            `<b>${
              adminName || "Admin"
            }</b> has invited you to join Salesgear.` || ""
        }}
      />
      <p className="teamDescText">
        Salesgear allows you to automate your sales followups at scale
      </p>
      <div className="inviteImg">
        <img src={TeamInviteImg} alt="" />
      </div>
    </div>
  );
};

export default observer(TeamContentBody);
