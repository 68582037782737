import ConstantObj, { fullMonthNames } from "Static/constant";
import { days, months } from "../Pages/Dashboard/dashboard/LiveFeed/constants";
import Utils from "Utils/utils";

const DateUtils = {};

DateUtils.getDateAndMonth = (
  dateStr = "",
  reverseString = false,
  isFullDate = false
) => {
  if (dateStr) {
    const dateValue = new Date(dateStr);
    const day = dateValue?.getDate();
    const month = dateValue?.getMonth();
    const year = dateValue?.getFullYear();
    if (isFullDate) {
      return `${DateUtils.getDateSuffix(day)} ${DateUtils.getMonthName(
        month
      )}, ${year}`;
    } else {
      return reverseString
        ? `${DateUtils.getMonthName(month)} ${DateUtils.getDateSuffix(day)}`
        : `${DateUtils.getDateSuffix(day)} ${DateUtils.getMonthName(month)}`;
    }
  }
  return "--";
};

//format - month,date year  (ex - apr 10,2023)
DateUtils.getDateMonthAndYear = (dateString = "") => {
  let date = new Date(dateString);
  let options = { month: "short", day: "numeric", year: "numeric" };
  let formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

//format - month date year  (ex - apr 10 2023)
DateUtils.getMonthDateYear = (dateString = "") => {
  let date = new Date(dateString);
  let options = { month: "short", day: "numeric", year: "numeric" };
  let formattedDate = date.toLocaleDateString("en-US", options);
  formattedDate = formattedDate.replace(",", "");
  return formattedDate;
};

DateUtils.getTimeByMerdian = (dateStr = "", inSmallLetter = false) => {
  if (dateStr) {
    const date = new Date(dateStr);
    const hour = DateUtils.getHourValue(date?.getHours() || "00");
    const minutes = DateUtils.getMinutesValue(date?.getMinutes() || "00");
    const merdian = DateUtils.getMerdians(
      date?.getHours() || "00",
      inSmallLetter
    );
    return hour && minutes && merdian ? `${hour}:${minutes} ${merdian}` : "--";
  }
  return "--";
};

DateUtils.getFormattedDateAndTime = (dateAndTime = "") => {
  const targetDate = new Date(dateAndTime);
  const yearInTwoDigit = targetDate?.getFullYear()?.toString()?.substr(-2);
  return {
    date: `${targetDate?.getDate()} ${
      ConstantObj?.months[targetDate.getMonth()]
    }'${yearInTwoDigit}`,
    time: DateUtils.getTimeByMerdian(targetDate)
  };
};

DateUtils.getFormattedDateAndTimeInFormat = dateAndTime => {
  const targetDate = new Date(dateAndTime);
  return {
    date: `${DateUtils.getDateSuffix(targetDate?.getDate())} ${
      ConstantObj?.months[targetDate.getMonth()]
    }`,
    time: DateUtils.getTimeByMerdian(targetDate)
  };
};

//Tue, 05 Sep 2023 08:18:34	- sample format
DateUtils.convertTimestampToDateAndTime = (inputTimestamp = "") => {
  const daysOfWeek = days;
  const monthsList = months;
  const timestampDate = new Date(inputTimestamp);
  const dayOfWeek = daysOfWeek[timestampDate.getUTCDay()];
  const day = timestampDate.getUTCDate();
  const monthName = monthsList[timestampDate.getUTCMonth()];
  const year = timestampDate.getUTCFullYear();
  const hours = timestampDate.getUTCHours();
  const minutes = timestampDate.getUTCMinutes();
  const seconds = timestampDate.getUTCSeconds();
  const formattedOutput = `${dayOfWeek} ${day} ${monthName} ${year} ${hours}:${minutes}:${seconds}`;
  return formattedOutput || "";
};

DateUtils.getHourValue = (hour = "00") => {
  if (hour === "00") return 12;
  let newHour = (hour > 12 && hour - 12) || hour;
  return newHour > 9 ? newHour : `0${newHour}`;
};

DateUtils.getMinutesValue = (minutes = "00") => {
  return minutes === "00" || minutes > 9 ? minutes : `0${minutes}`;
};

DateUtils.getMerdians = (hours = "00", inSmallLetter = false) => {
  const meridian = inSmallLetter ? ["pm", "am"] : ["PM", "AM"];
  return hours >= 12 ? meridian[0] : meridian[1];
};

DateUtils.getDateSuffix = (dayNo = 0) => {
  const thExpections = dayNo =>
    [11, 12, 13].some(exception => exception === dayNo);
  const lastDigit = dayNo % 10;
  if (thExpections(dayNo) || lastDigit === 0 || lastDigit > 3)
    return `${dayNo}th`;
  if (lastDigit === 1) return `${dayNo}st`;
  if (lastDigit === 2) return `${dayNo}nd`;
  return `${dayNo}rd`;
};

DateUtils.getMonthName = (monthIndex = "") => {
  return (monthIndex !== "" && fullMonthNames[monthIndex]) || "--";
};

DateUtils.getTimeZone = (dateStr = "") => {
  const date = new Date(dateStr);
  return date?.toString()?.match(/([A-Z]+[\+-][0-9]+)/)[1];
};

DateUtils.getFormattedDateAndTimeForCrm = dateAndTime => {
  const targetDate = new Date(dateAndTime);
  return {
    date: `${DateUtils.getDateSuffix(targetDate?.getDate())} ${
      ConstantObj?.months[targetDate.getMonth()]
    }`,
    time: DateUtils.getTimeByMerdian(targetDate, true)
  };
};

DateUtils.compareInputDateTimeWithCurrentDateTime = (inputDate = "") => {
  if (inputDate) {
    const curentDateTime = new Date().getTime();
    const inputDateTime = new Date(inputDate).getTime();
    return inputDateTime > curentDateTime;
  }
  return false;
};

DateUtils.getFormatedDateAndTime = date => {
  return `${Utils.formatDate(date)} at ${DateUtils.getTimeByMerdian(date)}`;
};

DateUtils.getMonthFullYear = (date = "") => {
  if (date) {
    return new Date(date).getFullYear();
  }
};

DateUtils.isFromToDateEqual = (fromDate = "", toDate = "") => {
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);
  return (
    startDate?.getDate() === endDate?.getDate() &&
    startDate?.getMonth() === endDate?.getMonth() &&
    startDate?.getFullYear() === endDate?.getFullYear()
  );
};

DateUtils.formatDateByMonthText = (date = "") => {
  if (date) {
    const dateValue = new Date(date);
    const day = dateValue?.getDate();
    const month = dateValue?.getMonth();
    const year = dateValue?.getFullYear();
    return `${ConstantObj?.months[month]} ${day}, ${year}`;
  }
};

DateUtils.getTimeByMerdianAndTimeZone = (dateStr = "", timeZone = "") => {
  if (dateStr) {
    if (timeZone) {
      const date = new Date(+dateStr).toLocaleTimeString("en-US", {
        timeZone
      });
      const merdian = date?.split(" ")?.[1];
      const [hour = "00", minutes = "00"] = date?.split(":");
      return hour && minutes && merdian
        ? `${hour}:${minutes} ${merdian}`
        : "--";
    } else {
      return DateUtils.getTimeByMerdian(dateStr);
    }
  }
  return "--";
};

DateUtils.convertMinutesToMilliSeconds = (minutes = 0) => {
  return minutes * 60000;
};

DateUtils.getTimeByMerdianWithEndTime = (
  dateStr = "",
  duration = 0,
  timeZone = ""
) => {
  if (dateStr) {
    const durationInMS = DateUtils.convertMinutesToMilliSeconds(duration);
    const firstValue = DateUtils.getTimeByMerdianAndTimeZone(dateStr, timeZone);
    const secondValue = DateUtils.getTimeByMerdianAndTimeZone(
      +dateStr + durationInMS,
      timeZone
    );
    return `${firstValue} - ${secondValue}`;
  }
  return "--";
};

DateUtils.getTimeStamp = (date = "", type = "") => {
  if (date) {
    let dateValue = new Date(date);
    if (type === "start") {
      dateValue.setHours(0, 0, 0, 0);
    } else {
      dateValue.setHours(23, 59, 59, 999);
    }
    return dateValue?.getTime() || "";
  }
  return "";
};

DateUtils.getDayAndMonth = (dateTime = "") => {
  if (dateTime) {
    let dayList = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    let dateValue = new Date(dateTime);
    let day = dateValue?.getDay();
    let month = DateUtils?.getMonthName(dateValue.getMonth());
    let number = dateValue?.getDate();
    return `${dayList[day]}, ${month} ${number}`;
  }
  return "";
};

DateUtils.getYearMonthDateBySymbol = (date = "", symbol = "-") => {
  if (date) {
    const dateValue = new Date(date);
    const day = dateValue?.getDate();
    const month = dateValue?.getMonth() + 1;
    const year = dateValue?.getFullYear();
    return `${year}${symbol}${month > 9 ? month : `0${month}`}${symbol}${
      day > 9 ? day : `0${day}`
    }`;
  }
  return "";
};

DateUtils.getYearMonthDateBySymbol = (date = "", symbol = "-") => {
  if (date) {
    const dateValue = new Date(date);
    const day = dateValue?.getDate();
    const month = dateValue?.getMonth() + 1;
    const year = dateValue?.getFullYear();
    return `${year}${symbol}${month > 9 ? month : `0${month}`}${symbol}${
      day > 9 ? day : `0${day}`
    }`;
  }
  return "";
};

DateUtils.getDateFullFormat = (date = "") => {
  let dataValue = new Date(date);
  return (
    `${DateUtils.getDayAndMonth(dataValue)}, ${dataValue.getFullYear()}` || ""
  );
};

DateUtils.getTimeByDurationDiff = (
  dateTime = 0,
  duration = 0,
  timezone = ""
) => {
  if (duration) {
    let date = new Date(+dateTime);
    let dateTimeDuration = +dateTime + +duration * 60 * 1000;
    return `${DateUtils.getTimeByMerdianAndTimeZone(
      date,
      timezone
    )} - ${DateUtils.getTimeByMerdianAndTimeZone(dateTimeDuration, timezone)}`;
  }
  return "";
};

DateUtils.getCurrentDateByTimeZone = (timeZone = "") => {
  if (timeZone) {
    return new Date().toLocaleString("en-US", {
      timeZone
    });
  }
  return new Date();
};

DateUtils.getTimeByMerdianTimeZone = (timeStamp = "", timeZone = "") => {
  if (timeStamp && timeZone) {
    let dateByTimeZone = new Date(+timeStamp).toLocaleString("en-US", {
      timeZone
    });
    return DateUtils.getTimeByMerdian(new Date(dateByTimeZone));
  }
};

// in HH : MM : SS format
DateUtils.getTimeFromSeconds = (seconds = 0) => {
  if (seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;
    let hoursStr = hours < 10 ? "0" + hours : hours;
    let minutesStr = minutes < 10 ? "0" + minutes : minutes;
    let secondsStr =
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }
};

DateUtils.getMinutesFromSeconds = (seconds = 0) => {
  if (seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    let minutesStr = minutes < 10 ? "0" + minutes : minutes;
    let secondsStr =
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
    return minutesStr + ":" + secondsStr;
  }
};

DateUtils.getTimeFromTimestamp = (timestamp = "") => {
  if (timestamp) {
    const dateObj = new Date(timestamp);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const period = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;

    return formattedTime;
  }
};

DateUtils.getTodayTimeStamp = () => {
  const today = new Date();
  let fromDate =
    DateUtils.getTimeStamp(
      Utils.convertFromDateToLocalString(today),
      "start"
    ) || "";
  let toDate =
    DateUtils.getTimeStamp(Utils.convertToDateToLocalString(today), "end") ||
    "";
  return { fromDate, toDate };
};

DateUtils.getMonthTimeStamp = (fromToDate = true) => {
  const filterFromDate = new Date();
  const filterToDate = new Date(
    filterFromDate.getFullYear(),
    filterFromDate.getMonth() - 1,
    1
  );
  let fromDate =
    DateUtils.getTimeStamp(
      Utils.convertFromDateToLocalString(filterToDate),
      "start"
    ) || "";
  let toDate =
    DateUtils.getTimeStamp(
      Utils.convertToDateToLocalString(filterFromDate),
      "end"
    ) || "";
  return {
    fromDate,
    toDate,
    ...(fromToDate && { filterFromDate, filterToDate })
  };
};

DateUtils.isEventExpired = (datestr = "") => {
  var givenDate = new Date(datestr);
  var currentDate = new Date();
  return givenDate < currentDate;
};

DateUtils.getDateFilterObj = (dateArr = []) => {
  let dateObj = {};
  const fieldKey = {
    CREATED: "created",
    UPDATED: "updated",
    FIRST_CONTACTED: "firstContacted",
    LAST_CONTACTED: "lastContacted"
  };
  dateArr.forEach(item => {
    const {
      fieldName = "",
      operator = "BETWEEN",
      fromDate = "",
      toDate = "",
      isSet = false,
      isNotSet = false
    } = item || {};
    if (
      fieldName &&
      ((operator === "BETWEEN" && fromDate && toDate) || isSet || isNotSet)
    ) {
      if (operator === "BETWEEN") {
        dateObj[fieldKey[fieldName]] = {
          fromDate: DateUtils.getTimeStamp(fromDate, "start"),
          toDate: DateUtils.getTimeStamp(toDate, "end")
        };
      } else {
        isSet && (dateObj[fieldKey[fieldName]] = { isSet });
        isNotSet && (dateObj[fieldKey[fieldName]] = { isNotSet });
      }
    }
  });
  return dateObj;
};

export default DateUtils;
export { DateUtils };
