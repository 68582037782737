import React from "react";
import { ReactComponent as PieChartIcon } from "Assets/svg/pieChartIcon.svg";
import { ReactComponent as BulbPointerIcon } from "Assets/svg/bulbPointerIcon.svg";

const keyInfoList = [
  {
    content: `By implementing <strong>SPF</strong>, <strong>DMARC</strong>, and
    <strong>DKIM</strong> email authentication protocols, the
    authenticity of the sender's email is ensured, which prevents email
    spoofing and phishing attacks, and increases email deliverability.`
  },
  {
    content: `Including a <strong>Profile Picture</strong> and
    <strong>Profile Name</strong> in your email account can help
    recipients recognize and trust your email, increasing the chances of
    it being opened and read.`
  },
  {
    content: `Setting up <strong>Custom Tracking Domains</strong> can help
    preserve sender reputation and increase email deliverability,
    resulting in better click-through rates.`
  },
  {
    content: `A solid <strong>MX record</strong> reputation is essential for maintaining seamless email communications and safeguarding against potential cyberattacks.`
  }
];

const KeyInformation = ({ keyList = {} }) => {
  return (
    <div className="keyInfoCont">
      <div className="keyInfoHeader">
        <span>Key Informations</span>
        <PieChartIcon />
      </div>
      <ul className="keyInfoBody">
        {keyInfoList.map((item, index) => (
          <li className="keyInfoPoint" key={index}>
            <BulbPointerIcon className="pointerIcon" />
            <span dangerouslySetInnerHTML={{ __html: item?.content }}></span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default KeyInformation;
export { KeyInformation };
