import React from "react";
import { observer } from "mobx-react";
import { ReactComponent as LinkedinIcon } from "../svg/fullLinkedInImg.svg";
import { ReactComponent as MiniLogoIcon } from "../svg/linkedIn.svg";
import { ReactComponent as MiniLiPremiumIcon } from "../svg/liPremiumIcon.svg";
import LinkedInSalesNavigatorIcon from "../png/LinkedInSalesNavigatorIcon.png";

const LinkedInProfileCard = observer(props => {
  const {
    firstName = "",
    lastName = "",
    publicId = "",
    occupation = "",
    profilePictureUrl = "",
    followersCount = "",
    hasLinkedInIcon = false,
    showTitle = false,
    linkedinAccountType = "classic"
  } = props || {};

  const isPremiumType = linkedinAccountType
    ? !(linkedinAccountType?.toLowerCase() === "classic")
    : false;

  return (
    <div className="profileInformation">
      <div className="leftLayout">
        {hasLinkedInIcon && (
          <div className="absoluteIcon">
            {isPremiumType ? <MiniLiPremiumIcon /> : <MiniLogoIcon />}
          </div>
        )}
        <img className="imgUrl" src={profilePictureUrl} />
      </div>
      <div className="rightLayout">
        <div className="flexRow">
          {(firstName || lastName) && (
            <span className="fullName">{`${firstName?.toLowerCase()}${
              firstName ? ` ` : ""
            }${lastName?.toLowerCase()}`}</span>
          )}
          {isPremiumType ? (
            <img
              className="salesNavigatorIcon"
              src={LinkedInSalesNavigatorIcon}
              alt={"LinkedIn Sales Navigator"}
            />
          ) : (
            <LinkedinIcon />
          )}
        </div>
        {showTitle && occupation && (
          <span className="jobDetails">{occupation}</span>
        )}
        {!showTitle && (
          <div className="publicUrl">{`www.linkedin.com/in/${publicId}`}</div>
        )}
        <span className="followersCount">{followersCount}</span>
      </div>
    </div>
  );
});

export { LinkedInProfileCard };
export default LinkedInProfileCard;
