import React from "react";
import { DateUtils } from "Utils/DateUtils";

export const ScheduleLaterDateTimeBanner = ({
  scheduledDate = new Date(),
  closeCbk = () => {}
}) => {
  return (
    (scheduledDate && (
      <div className="scheduledDateToast">
        <div className="wrapScheduleTimeBanner">
          <span className="text">
            Email is scheduled to be sent on{" "}
            {DateUtils.getDateAndMonth(scheduledDate, false, true)} at{" "}
            {DateUtils.getTimeByMerdian(scheduledDate)} (
            {DateUtils.getTimeZone(scheduledDate)})
          </span>
          <i className="material-icons" onClick={closeCbk}>
            close
          </i>
        </div>
      </div>
    )) || <></>
  );
};

export default ScheduleLaterDateTimeBanner;
