import React, { useState } from "react";
import CustomTooltip from "Components/common/CustomTooltip";

const VariantToggle = props => {
  const {
    action = () => {},
    isChecked = false,
    loading = false,
    className = "",
    id = "",
    label = "",
    showInfoIcon = false,
    callback = () => {}
  } = props;

  const [showToolTip, setShowToolTip] = useState(false);

  const checkBoxAction = e => {
    action(e, !isChecked, id);
  };

  return (
    <div className="toggleBtnSection">
      <div className="toogleSequence">
        <div
          className={`toogleButton ${loading ? "loading" : ""} ${className}`}
        >
          <input
            type="checkbox"
            className="toggleCheckbox"
            checked={isChecked}
            onChange={e => checkBoxAction(e)}
          />
          <div className="knobs" />
          <div className="layer" />
        </div>
      </div>
      {label && (
        <span
          className={`toggleText leftPad ${isChecked ? "activeToogle" : ""}`}
          onClick={callback}
        >
          {label}
        </span>
      )}
      {showInfoIcon && (
        <span className="tooltipWrap">
          <i
            className="material-icons iIcon"
            onMouseOver={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          >
            info_outline
          </i>
          {showToolTip && (
            <CustomTooltip text="When link tracking is enabled, open tracking will also be enabled" />
          )}
        </span>
      )}
    </div>
  );
};

export default VariantToggle;
export { VariantToggle };
