import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import AddItem from "./AddItem";
import FilterDropdown from "./FilterDropdown";
import DeleteRow from "./DeleteRow";
import { settingsStoreData } from "Stores/settingsData";
import TriggerConditionInputValue from "./TriggerConditionInputValue";
import userDetail from "Stores/userDetail";

const ContactFieldKey = observer(props => {
  const {
    contactFields = [],
    parentIndex = "",
    childIndex = "",
    firstItemKey = "",
    activeDropdownIndex = "",
    setActiveDropdownIndex = () => {}
  } = props;
  const [contactFieldsObject, setContactsFieldsObject] = useState({});

  const { customFieldsCount = 0 } = userDetail?.userFeatureFlag || {};

  const getContactFieldsObject = () => {
    let conditionFieldsObject = {};
    contactFields?.length > 0 &&
      contactFields.map(item => {
        conditionFieldsObject = {
          ...conditionFieldsObject,
          [item?.key]: item?.value
        };
      });
    return conditionFieldsObject;
  };

  useEffect(() => {
    setContactsFieldsObject(getContactFieldsObject());
  }, [settingsStoreData?.customFields]);

  return (
    <div className="conditionsFields">
      <FilterDropdown
        options={contactFields}
        showDropdown={
          activeDropdownIndex ===
          `fieldName-${parentIndex + 1}-${childIndex + 1}`
        }
        setActiveDropdownIndex={setActiveDropdownIndex}
        defaultValue={contactFieldsObject?.[firstItemKey]}
        label="Select"
        referenceId="fieldName"
        index={`fieldName-${parentIndex + 1}-${childIndex + 1}`}
        hasCustomFieldValue={true}
        parentIndex={parentIndex}
        childIndex={childIndex}
      />
    </div>
  );
});

const ContactFieldValue = observer(props => {
  const {
    parentIndex = "",
    childIndex = "",
    firstItemKey = "",
    firstItemValue = "",
    actionsArray = [],
    change = () => {},
    setActiveDropdownIndex = () => {}
  } = props;

  const handleInputChange = (key, value, parentIndex, childIndex) => {
    let tempList = [...actionsArray];
    const objectKey = Object.keys(tempList[parentIndex].contact[childIndex])[0];
    tempList[parentIndex].contact[childIndex] = {
      [objectKey]: ["phone", "mobilePhone", "otherPhone"]?.includes(objectKey)
        ? [value]
        : value
    };
    setActiveDropdownIndex(false);
    change("value", value, parentIndex, childIndex);
  };

  return (
    <div
      className={`triggerInputFieldValue ${
        !firstItemKey ? "disableInput" : ""
      }`}
    >
      <TriggerConditionInputValue
        parentIndex={parentIndex}
        type={firstItemKey}
        childIndex={childIndex}
        change={handleInputChange}
        defaultValue={firstItemValue}
      />
    </div>
  );
});

const UpdateContactsAction = observer(props => {
  const { actionsArray = [], change = () => {} } = props;
  const { customFieldsCount = 0 } = userDetail?.userFeatureFlag || {};

  const [hoverIndex, setHoverIndex] = useState("");
  const [activeDropdownIndex, setActiveDropdownIndex] = useState("");
  const [disabledContactFields, setDisabledContactFields] = useState([]);
  let customFieldData = [];

  const handleAction = (key, value, parentIndex, childIndex) => {
    change(key, value?.subKey);
    let tempList = [...actionsArray];
    tempList[parentIndex].contact[childIndex] = {
      [value.key]: ""
    };
    setDisabledContactFields([...disabledContactFields, value?.key]);
    setActiveDropdownIndex(null);
    change("actions", tempList);
  };

  const getCustomContactFields = () => {
    Array.from({ length: customFieldsCount }, (value, index) =>
      customFieldData.push({
        value: `Custom Field ${index + 1}`,
        subKey: `CUSTOM_FIELD_${index + 1}`,
        key: `customField${index + 1}`,
        method: handleAction
      })
    );
    return customFieldData;
  };

  const constructCustomFieldsWithValue = () => {
    const customContactFields = getCustomContactFields();
    return customContactFields.map(item => {
      item.value = settingsStoreData?.customFields?.[item?.key] || item?.value;
      item.hasCustomFieldValue = settingsStoreData?.customFields?.[item?.key]
        ? true
        : false;
      item.disabled = disabledContactFields?.includes(item?.key);
      return item;
    });
  };

  const contactFields = [
    {
      value: "First Name",
      subKey: "FIRST_NAME",
      key: "firstName",
      method: handleAction,
      disabled: disabledContactFields?.includes("firstName")
    },
    {
      value: "Last Name",
      subKey: "LAST_NAME",
      key: "lastName",
      method: handleAction,
      disabled: disabledContactFields?.includes("lastName")
    },
    {
      value: "Job Title",
      subKey: "JOB_TITLE",
      key: "jobTitle",
      method: handleAction,
      disabled: disabledContactFields?.includes("jobTitle")
    },
    {
      value: "Phone Number",
      subKey: "PHONE_NUMBER",
      key: "phone",
      method: handleAction,
      disabled: disabledContactFields?.includes("phone")
    },
    {
      value: "Mobile Number",
      subKey: "MOBILE_NUMBER",
      key: "mobilePhone",
      method: handleAction,
      disabled: disabledContactFields?.includes("mobilePhone")
    },
    {
      value: "Other Number",
      subKey: "OTHER_NUMBER",
      key: "otherPhone",
      method: handleAction,
      disabled: disabledContactFields?.includes("otherPhone")
    },
    {
      value: "Company",
      subKey: "COMPANY",
      key: "company",
      method: handleAction,
      disabled: disabledContactFields?.includes("company")
    },
    {
      value: "Industry",
      subKey: "INDUSTRY",
      key: "industry",
      method: handleAction,
      disabled: disabledContactFields?.includes("industry")
    },
    {
      value: "Street",
      subKey: "STREET",
      key: "street",
      method: handleAction,
      disabled: disabledContactFields?.includes("street")
    },
    {
      value: "City",
      subKey: "CITY",
      key: "city",
      method: handleAction,
      disabled: disabledContactFields?.includes("city")
    },
    {
      value: "State",
      subKey: "STATE",
      key: "state",
      method: handleAction,
      disabled: disabledContactFields?.includes("state")
    },
    {
      value: "Country",
      subKey: "COUNTRY",
      key: "country",
      method: handleAction,
      disabled: disabledContactFields?.includes("country")
    },
    {
      value: "Zip Code",
      subKey: "ZIP_CODE",
      key: "zipCode",
      method: handleAction,
      disabled: disabledContactFields?.includes("zipCode")
    },
    {
      value: "Website",
      subKey: "WEBSITE",
      key: "website",
      method: handleAction,
      disabled: disabledContactFields?.includes("website")
    },
    {
      value: "LinkedIn URL",
      subKey: "LINKEDIN_URL",
      key: "linkedinId",
      method: handleAction,
      disabled: disabledContactFields?.includes("linkedinId")
    },
    {
      value: "Facebook",
      subKey: "FACEBOOK",
      key: "facebook",
      method: handleAction,
      disabled: disabledContactFields?.includes("facebook")
    },
    {
      value: "Twitter",
      subKey: "TWITTER",
      key: "twitter",
      method: handleAction,
      disabled: disabledContactFields?.includes("twitter")
    },
    ...constructCustomFieldsWithValue()
  ];

  const getCustomFieldObj = (item, key, disabledList) => {
    const customFields = Object.keys(item?.contact[key]).map(itemKey => {
      const value = item?.contact?.[key]?.[itemKey];
      if (value) {
        disabledList.push(itemKey);
        return { [itemKey]: value };
      }
    });
    return customFields;
  };

  const addNewContactField = index => {
    const tempList = [...actionsArray];
    const temp = tempList?.[index];
    if (temp?.actionType === "UPDATE_CONTACT") {
      if (!tempList?.[index]?.contact) {
        tempList[index].contact = [];
      }
      tempList[index].contact.push({ "": "" });
    }
    change("actions", tempList);
  };

  const deleteContactField = (parentIndex, childIndex) => {
    let tempList = [...actionsArray];
    const deletedValue = Object.keys(
      tempList?.[parentIndex]?.contact?.[childIndex]
    )[0];
    const tempDisabledField = disabledContactFields.filter(
      item => item !== deletedValue
    );
    setDisabledContactFields(tempDisabledField);
    delete tempList[parentIndex].contact.splice(childIndex, 1);
    change("actions", tempList);
  };

  const transFormUpdateContact = actionsArray => {
    const disabledList = [];
    actionsArray.length > 0 &&
      actionsArray.map(item => {
        if (item?.actionType === "UPDATE_CONTACT" && item?.contact) {
          const contact = Object.keys(item?.contact).map(key => {
            if (item?.contact[key]) {
              if (key?.includes("customField")) {
                const customFields = getCustomFieldObj(item, key, disabledList);
                if (item?.contact?.constructor?.name === "Object") {
                  return [...customFields];
                }
              } else {
                if (item?.contact?.constructor?.name === "Object") {
                  if (item?.contact[key]) {
                    disabledList.push(key);
                  }
                  return { [key]: item?.contact[key] };
                } else {
                  const tempKey = Object.keys(item?.contact[key])[0];
                  if (item?.contact[key][tempKey]) {
                    disabledList.push(tempKey);
                  }
                  return { [tempKey]: item?.contact[key][tempKey] };
                }
              }
            }
          });
          const mergedContact = [].concat.apply([], contact);
          item.contact = mergedContact.filter(Boolean);
          setDisabledContactFields([...disabledList]);
        }
      });
  };

  useEffect(() => {
    transFormUpdateContact(actionsArray);
  }, []);

  return (
    <div className="updateContactsActionWrapper">
      {actionsArray?.map((item, index) => {
        let { actionType = "", contact = [] } = item || {};
        return (
          actionType === "UPDATE_CONTACT" && (
            <div key={`triggerActionUpdateContact${index}`}>
              {contact?.length > 0 &&
                contact?.map((contactItem, contactIndex) => {
                  const firstItemKey =
                    contactItem && Object.keys(contactItem)?.[0];
                  const firstItemValue = contactItem?.[firstItemKey];
                  const config = {
                    parentIndex: index,
                    childIndex: contactIndex,
                    firstItemKey,
                    firstItemValue,
                    activeDropdownIndex,
                    setActiveDropdownIndex
                  };
                  return (
                    firstItemValue !== null && (
                      <div
                        className="updateContactsActionItem conditionsCard"
                        key={`triggerActionUpdateContactField${index}${contactIndex}`}
                        onMouseEnter={() => setHoverIndex(contactIndex)}
                        onMouseLeave={() => setHoverIndex("")}
                      >
                        <span className="verticalDotLine" />
                        <div className="conditionsInputRow">
                          <span className="horizontalDotLine" />
                          <span className="horizontalBorderLine" />
                          <ContactFieldKey
                            contactFields={contactFields}
                            {...config}
                          />
                          <ContactFieldValue {...props} {...config} />
                          {hoverIndex === contactIndex && (
                            <DeleteRow
                              parentIndex={index}
                              childIndex={contactIndex}
                              deleteCbk={deleteContactField}
                            />
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
              <AddItem
                name="Add"
                index={index}
                addCbk={addNewContactField}
                className="addItemUpdateContact"
              />
            </div>
          )
        );
      })}
    </div>
  );
});

export { UpdateContactsAction };
export default UpdateContactsAction;
