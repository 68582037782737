import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import LoginComponent from "Pages/Register/Login/Login";
import SignupComponent from "Pages/Register/Signup/Signup";
import DealifyComponent from "Pages/Register/Dealify/Dealify";
import ForgotPasswordComponent from "Pages/Register/ForgotPassword/ForgotPassword";
import ResetPasswordComponent from "Pages/Register/ResetPassword/ResetPassword";
import TeamMemberInvite from "Pages/Register/TeamOnboard/TeamMemberInvite";
import TeamInviteSignup from "Pages/Register/TeamOnboard/TeamInviteSignup";
import TeamInviteSignIn from "Pages/Register/TeamOnboard/TeamInviteSignin";
import TeamInviteSuccess from "Pages/Register/TeamOnboard/TeamInviteSuccess";

const RegisterRouting = props => {
  return (
    <Switch>
      <Route
        path="/teamInvite/success"
        exact
        render={() => <TeamInviteSuccess {...props} />}
      />
      <Route path="/login" render={() => <LoginComponent {...props} />} />
      <Route path="/signup" render={() => <SignupComponent {...props} />} />
      <Route exact path="/forgotPassword" component={ForgotPasswordComponent} />
      <Route
        path="/resetPassword"
        exact
        render={() => <ResetPasswordComponent {...props} />}
      />
      <Route
        path="/resetPassword/:id/:code"
        exact
        render={() => <ResetPasswordComponent {...props} />}
      />
      <Route
        path="/teamInvite/signup"
        exact
        render={() => <TeamInviteSignup {...props} />}
      />
      <Route
        path="/teamInvite/login"
        exact
        render={() => <TeamInviteSignIn {...props} />}
      />
      <Route
        path="/teamInvite/:id"
        exact
        render={() => <TeamMemberInvite {...props} />}
      />
      <Route
        path="/dealify-signup"
        exact
        render={() => <DealifyComponent {...props} />}
      />
      <Redirect from="/pluginSignup" to="/signup?source=plugin" />
      <Redirect from="/pluginLogin" to="/login?source=plugin" />
      <Redirect from="/" to="/login" />
    </Switch>
  );
};

export default withRouter(RegisterRouting);
