import React from "react";

const TemplateOverviewLoader = props => {
  const { templateHeader = [] } = props || {};
  return (
    <>
      <div className="template-table-section tempPageWithHt">
        <div className="template-table-header">
          <div
            className="headerInnerCont"
            style={{ width: "calc(100% - 0.4em)", display: "inherit" }}
          >
            {templateHeader.map(item => {
              const { key = "", className = "" } = item || {};
              return <div key={key} className={className} />;
            })}
          </div>
        </div>
        <div className="template-table-body">
          {Array.from({ length: 10 }, (value, index) => (
            <div
              className="template-table-row-loader"
              key={`templateBody${index}`}
            >
              {templateHeader.map(item => {
                const { key = "", className = "" } = item || {};
                return (
                  <div key={key} className={className}>
                    <div className="linear-background" />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <div className="pagination-section">
        <ul className="pagination">
          {Array.from({ length: 5 }, (value, index) => (
            <li
              className="page-number linear-background"
              key={`paginationLoader${index}`}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default TemplateOverviewLoader;
export { TemplateOverviewLoader };
