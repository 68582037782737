import React from "react";
import { withRouter } from "react-router-dom";

const UnsubscribeSuccess = () => {
  return (
    <div className="unsubscribeSuccess">You've successfully unsubscribed!</div>
  );
};

export default withRouter(UnsubscribeSuccess);
export { UnsubscribeSuccess };
