import React, { useState, memo, useEffect } from "react";
import { observable, action } from "mobx";
import Utils from "Utils/utils";
import { observer } from "mobx-react";
import SearchInputByKeyword from "Components/common/SearchInputByKeyword";
import Button from "Components/common/Button";

class UpdateContactOwnerState {
  @observable showPopup = false;
  @observable currentOwnerEmail = "";
  @observable btnLoader = false;
  @observable totalCount = 0;
  @observable callback = () => {};
  @observable popupType = "";

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
  };

  @action
  setBtnLoader = (value = false) => {
    this.btnLoader = value;
  };

  @action
  setCallback = (func = () => {}) => {
    this.callback = func;
  };

  @action
  setCurrentOwnerEmail = (val = "") => {
    this.currentOwnerEmail = val;
  };

  @action
  setTotalCount = (val = 0) => {
    this.totalCount = val;
  };

  @action
  setPopupType = (val = "") => {
    this.popupType = val;
  };

  @action
  setResetPopup = () => {
    this.showPopup = false;
    this.btnLoader = false;
    this.currentOwnerEmail = "";
    this.totalCount = 0;
    this.callback = () => {};
    this.popupType = "";
  };
}

const updateContactOwnerState = new UpdateContactOwnerState();

const UpdateContactOwner = observer(() => {
  const [selectedId, setSelectedId] = useState("");
  const [loader, setLoader] = useState(true);
  const [teamList, setTeamList] = useState([]);
  const [optList, setOptList] = useState([]);

  let count = ` ${Utils.formatNumberToString(
    updateContactOwnerState?.totalCount
  )}${
    updateContactOwnerState?.popupType?.toUpperCase() === "ACCOUNT"
      ? " account"
      : " contact"
  }${updateContactOwnerState?.totalCount > 1 ? "s" : ""}`;

  const resetState = () => {
    setTeamList([]);
    setLoader(true);
    setOptList([]);
    setSelectedId("");
  };

  const closePopup = () => {
    if (!updateContactOwnerState?.btnLoader) {
      updateContactOwnerState.setResetPopup();
      resetState();
    }
  };

  const handleUpdateAction = () => {
    if (selectedId) {
      updateContactOwnerState.callback(selectedId);
      updateContactOwnerState.setBtnLoader(true);
    }
  };

  const handleSearchText = (value = "") => {
    if (!loader) {
      selectedId && setSelectedId("");
      if (value) {
        let tempOpt = [...teamList];
        tempOpt = tempOpt.filter(item => {
          const { firstName = "", lastName = "", email = "" } = item || {};
          let name = Utils.getName(firstName, lastName, "", email);
          if (name?.toLowerCase().includes(value?.toLowerCase())) {
            return item;
          }
        });
        setOptList(tempOpt);
      } else {
        setOptList(teamList);
      }
    }
  };

  const updateContactOwnerBtn = {
    id: "updateContactOwnerBtn",
    name: "updateContactOwnerBtn",
    btnText: "Update Owner",
    type: "button",
    loader: updateContactOwnerState?.btnLoader || false,
    className: `updateContactOwnerBtn ${selectedId ? "" : "disabled"} ${
      updateContactOwnerState?.btnLoader ? "loading" : ""
    }`,
    click: handleUpdateAction
  };

  const getTeamMember = async () => {
    setLoader(true);
    const res = (await Utils.getTeamMember()) || [];
    let teamMemberList = res?.data || [];
    setTeamList(teamMemberList);
    setOptList(teamMemberList);
    teamMemberList.find(item => {
      if (updateContactOwnerState?.currentOwnerEmail === item?.email) {
        setSelectedId(item?.memberId);
      }
    });
    setLoader(false);
  };

  useEffect(() => {
    updateContactOwnerState?.showPopup && getTeamMember();
  }, [updateContactOwnerState.showPopup]);

  useEffect(() => {
    !updateContactOwnerState?.btnLoader && closePopup();
  }, [updateContactOwnerState.btnLoader]);

  return updateContactOwnerState.showPopup ? (
    <div className="updateContactOwnerPopup">
      <div
        className={`card animated customzoomIn ${
          updateContactOwnerState?.btnLoader ? "disableClick" : ""
        }`}
      >
        <div className="cardHeader">
          <div className="textWrapper">
            <div className="title">{`Update ${
              updateContactOwnerState?.popupType?.toUpperCase() === "ACCOUNT"
                ? "account"
                : "contact"
            } owner`}</div>
            <p className="desc">
              This action will update the owner for the selected {count}
            </p>
          </div>
          <i className="material-icons close" onClick={closePopup}>
            close
          </i>
        </div>
        <div className="cardBody">
          <SearchInputByKeyword
            id="searchTeamMemberInput"
            placeholder="Search here"
            handleSearch={handleSearchText}
          />
          <div className="contactOwnerOptList">
            {loader ? (
              <TeamMemberLoader />
            ) : !loader && teamList?.length > 0 && optList?.length > 0 ? (
              optList.map(member => (
                <MemberList
                  key={`teamMember${member?.id}`}
                  {...member}
                  selectedId={selectedId}
                  handleChange={setSelectedId}
                />
              ))
            ) : (
              <div className="noUserFound">No user found</div>
            )}
          </div>
          <Button {...updateContactOwnerBtn} />
        </div>
      </div>
      <div className="overlay" />
    </div>
  ) : (
    ""
  );
});

const MemberList = memo(
  observer((props = {}) => {
    const {
      selectedId = "",
      id = "",
      firstName: fn = "",
      lastName: ln = "",
      email = "",
      memberId = "",
      handleChange = () => {}
    } = props || {};

    const isCurrentOwner = () => {
      return updateContactOwnerState?.currentOwnerEmail === email;
    };

    const handleSelectAction = () => {
      if (!isCurrentOwner()) {
        handleChange(memberId);
      }
    };

    return (
      <div
        className={`options ${isCurrentOwner() ? "disableOpt" : ""} ${
          selectedId === memberId ? "activeMember" : ""
        }`}
      >
        <div className="radioBtnCont">
          <div className="radioButtonCont">
            <input
              type="radio"
              name="addToListRadioCheckbox"
              id={memberId}
              onChange={handleSelectAction}
              checked={selectedId === memberId}
              className="radioBtn"
            />
            <span className="contentClass" />
          </div>
        </div>
        <label className="teamMemberName" htmlFor={memberId}>
          {Utils.getName(fn, ln, "", email)}
          {isCurrentOwner() && <span className="currentOwner">Owner</span>}
        </label>
      </div>
    );
  })
);

const TeamMemberLoader = memo(() => {
  return Array.from({ length: 10 }, (value, index) => (
    <div className="options" key={`memberList${index}`}>
      <div className="radioBtn linear-background" />
      <div className="teamMemberName linear-background" />
    </div>
  ));
});

export default UpdateContactOwner;
export { updateContactOwnerState };
