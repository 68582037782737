import React from "react";
import Button from "../Button";

const FilterValueSection = props => {
  const {
    filterSelectionValue = "",
    clearSelectedValue = () => {},
    enableClearAll = false
  } = props;

  return (
    <div className="headerContainer">
      <span className="headerFilterValue">{filterSelectionValue}</span>
      <span
        className={`headerClearValue ${enableClearAll ? "" : "disabled"}`}
        onClick={clearSelectedValue}
      >
        Clear all
      </span>
    </div>
  );
};

const DoneButton = props => {
  const { submitAction = () => {}, enableClearAll = false } = props;

  return (
    <div className="footerContainer">
      <Button
        name="doneButton"
        btnText="Done"
        icon={false}
        iconName=""
        type="button"
        className={`doneButton ${enableClearAll ? "" : "disabled"}`}
        loader={false}
        click={submitAction}
      />
    </div>
  );
};

export { FilterValueSection, DoneButton };
export default FilterValueSection;
