import React, { useState, useEffect, useMemo, createContext } from "react";
import { observer } from "mobx-react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { StepSortableItem } from "./StepSortableItem";
import { toasterState } from "Components/common/toaster";
import { sequenceStoreData } from "Stores/sequenceData";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { editSequencePopupState } from "Components/common/EditSequencePopup";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import StageLoader from "./StageLoader";
import ConstantObj, { MP_EVENT } from "Static/constant";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { ReactComponent as EmailIcon } from "Assets/svg/sequences.svg";
import ManualEmailWhite from "Assets/png/manualEmailWhite.png";
import { ReactComponent as LinkedinIcon } from "Assets/svg/linkedin.svg";
import { ReactComponent as PhoneIcon } from "Assets/svg/call.svg";
import { ReactComponent as CustomTaskIcon } from "Assets/svg/customTask.svg";

export const StepsContext = createContext({});

const Steps = observer((props = {}) => {
  const {
    showStageLoader = false,
    steps = {},
    seqId = "",
    currentSeqData = "",
    setSteps = () => {},
    setShowStageLoader = () => {},
    getStepsAnalytics = () => {},
    getSelectedSeqData = () => {},
    stepBadgeAction = () => {},
    handleMoveStageAction = () => {},
    setAnalyticStepLoader = () => {},
    analyticStepLoader = false
  } = props || {};

  const [cards, setCards] = useState(Object.values(steps));

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  let daysLaterCount = 0;

  const getStageType = (stepNo = 1) => {
    let variantFirstKey =
      (steps?.[stepNo]?.variants &&
        Object.keys(steps?.[stepNo]?.variants)[0]) ||
      1;
    return steps?.[stepNo]?.variants?.[variantFirstKey]?.type || "";
  };

  const stageIcon = {
    email: <EmailIcon />,
    manualEmail: <img src={ManualEmailWhite} alt="Manual Email" />,
    linkedin: <LinkedinIcon />,
    linkedinAuto: <LinkedinIcon />,
    phone: <PhoneIcon />,
    customTask: <CustomTaskIcon />
  };

  const createCommonPayload = (payload = {}, duplicateCurrentSeqData = {}) => {
    if (payload && duplicateCurrentSeqData) {
      const { folderName = null } = Utils.getFolderDetails();
      const { name = "", folderId = null } = duplicateCurrentSeqData || {};
      payload = {
        ...payload,
        type: "Edit Sequence",
        bulk: false,
        sequenceId: seqId,
        name,
        folderName,
        folderId
      };
    }
    return payload;
  };

  const updateSequencePayload = (updatedSteps = []) => {
    let payload = JSON.parse(JSON.stringify(ConstantObj.manipulateSeqPayload));
    const greatestUniqueId = Object.values(steps).reduce((acc, item) => {
      const { uniqueId = 0 } = item || {};
      return +uniqueId > +acc ? +uniqueId : +acc;
    }, 0);
    return {
      ...createCommonPayload(payload),
      steps: updatedSteps,
      greatestUniqueId,
      firstStageIsReply: Utils.getFirstEmailStatus(updatedSteps)
    };
  };

  const hasAnalyticsDataToMoveStages = (
    stageLevelAnalytics = {},
    stepId = 1,
    type = "",
    actionType = ""
  ) => {
    const {
      scheduledCount = 0,
      pausedCount = 0,
      failedCount = 0
    } = stageLevelAnalytics || {};
    if (scheduledCount || pausedCount || failedCount) {
      let content = `You cannot move step #${stepId} as there are contacts associated with it.`;
      if (actionType) {
        content = `You cannot move step #${stepId} ${type} because step #${
          type === "up" ? +stepId - 1 : +stepId + 1
        } has contacts associated with it.`;
      }
      confirmationPopupState.setPopupValues({
        content,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
      return false;
    }
    return true;
  };

  const deleteStepConfirmationPrompt = (
    stageStats = {},
    stepId = "",
    deleteConfirmationCbk = () => {}
  ) => {
    const {
      scheduledCount = 0,
      pausedCount = 0,
      failedCount = 0
    } = stageStats || {};
    const stageLength = Object.keys(steps)?.length || 0;
    let showPrompt = scheduledCount || pausedCount || failedCount;
    let promptContent = showPrompt
      ? `Are you sure you want to delete this step? ${
          stageLength === +stepId
            ? `All the contacts in step #${stepId} will be completed in the sequence.`
            : `All the contacts scheduled in step #${stepId} will be skipped & moved to the next step.`
        }`
      : "Are you sure you want to delete this step?";
    confirmationPopupState.setPopupValues({
      content: promptContent,
      actionBtnText: "Yes",
      loadingMsg: "Please wait...",
      callback: deleteConfirmationCbk
    });
    confirmationPopupState.setShowPopup(true);
  };

  const deleteStep = async (e, id = "", stepId = "", stageStats = {}) => {
    Utils.preventDefaultFn(e);
    if (await checkTeamSubscriptionActive()) {
      let newStepsData = "";
      const deleteConfirmationCbk = (buttonLoadingFn = () => {}) => {
        const deleteCbk = () => {
          setShowStageLoader(false);
          newStepsData && setSteps(newStepsData);
          toasterState.setToastMsg("Step deleted successfully", "success");
        };
        const deleteStepCbk = (id = "") => {
          buttonLoadingFn(false);
          confirmationPopupState.setShowPopup(false);
          if (id?.length && +stepId > 0) {
            setShowStageLoader(true);
            const duplicateCurrentSeqData = JSON.parse(
              JSON.stringify(currentSeqData)
            );
            if (duplicateCurrentSeqData?.id === id) {
              delete duplicateCurrentSeqData.steps[stepId];
              newStepsData = reviseStage(duplicateCurrentSeqData.steps);
              if (
                Object.keys(newStepsData)?.length &&
                [1, "1"].includes(stepId)
              ) {
                newStepsData[1].daysLater = 0;
              }
              duplicateCurrentSeqData.steps = newStepsData;
              duplicateCurrentSeqData.firstStageIsReply =
                Utils.getFirstEmailStatus(newStepsData);
            }
            deleteSequenceStepAction(
              steps?.[stepId]?.uniqueId,
              deleteCbk,
              editSequencePopupState.totalContacts > 0
            );
          }
        };

        const activeStatusCbk = () => {
          buttonLoadingFn && buttonLoadingFn(false);
          confirmationPopupState.setShowPopup(false);
        };

        activeStatusCbk();
        deleteStepCbk(id);
      };
      deleteStepConfirmationPrompt(stageStats, stepId, deleteConfirmationCbk);
    }
  };

  const reviseStage = oldSteps => {
    if (oldSteps) {
      const newObj = {};
      const steps = oldSteps;
      const toReviseDataKey = Object.keys(steps);
      const toReviseDataLen = toReviseDataKey.length;
      for (let i = 1; i <= toReviseDataLen; i++) {
        newObj[i] = steps[toReviseDataKey[i - 1]];
      }
      return newObj;
    }
  };

  const deleteSequenceStepAction = async (
    stepId = "",
    cbk = () => {},
    makeStatsApi = false
  ) => {
    try {
      let targetURL = URLS.deleteSequenceStage
        .replace("<<sequenceId>>", seqId)
        .replace("<<stepId>>", stepId);
      const config = {
        url: targetURL,
        method: "DELETE"
      };
      const response = await makeApi(config);
      if (response?.status === 200) {
        if (makeStatsApi) {
          await getStepsAnalytics();
        }
        setShowStageLoader(false);
        setAnalyticStepLoader(false);
        cbk && cbk();
        getSelectedSeqData();
        toasterState.setToastMsg(
          "Sequence step deleted successfully",
          "success"
        );
      } else Utils.showApiFailureResponse(response);
    } catch (error) {
      console.error("Get Folder Data error ", error);
    }
  };

  const checkContactStatus = (
    stepId = "1",
    seqType = "email",
    action = "moveUp",
    moveStageCbk = () => {}
  ) => {
    const {
      hasModifyAccess = false,
      message = "",
      sequenceId = ""
    } = sequenceStoreData.sequenceStatus || {};
    if (sequenceId === seqId) {
      hasModifyAccess
        ? moveStageCbk(stepId, seqType, action)
        : toasterState.setToastMsg(message, "failure");
    }
  };

  const moveStagesAction = (
    stepId = "1",
    seqType = "email",
    action = "moveUp",
    seqId = ""
  ) => {
    const moveStageCbk = (stepId, seqType, action) => {
      if (steps) {
        const stepIdInNumber = +stepId;
        const currentStage = steps[stepIdInNumber];
        const updatedStages = steps;
        if (action === "moveUp") {
          const currentDaysLater = updatedStages[stepIdInNumber].daysLater;
          const updatedDaysLater = updatedStages[stepIdInNumber - 1].daysLater;
          updatedStages[stepIdInNumber] = updatedStages[stepIdInNumber - 1];
          updatedStages[stepIdInNumber - 1] = currentStage;
          if (stepIdInNumber === 2) {
            updatedStages[stepIdInNumber].daysLater = currentDaysLater;
            const currentVariants = updatedStages[stepIdInNumber]?.variants;
            for (let property in currentVariants) {
              currentVariants[property].daysLater = currentDaysLater;
            }
            updatedStages[stepIdInNumber - 1].daysLater = updatedDaysLater;
            const updatedVariants = updatedStages[stepIdInNumber - 1]?.variants;
            for (let property in updatedVariants) {
              updatedVariants[property].daysLater = updatedDaysLater;
            }
          }
        } else {
          const currentDaysLater = updatedStages[stepIdInNumber].daysLater;
          const updatedDaysLater = updatedStages[stepIdInNumber + 1].daysLater;
          updatedStages[stepIdInNumber] = updatedStages[stepIdInNumber + 1];
          updatedStages[stepIdInNumber + 1] = currentStage;
          if (stepIdInNumber === 1) {
            updatedStages[stepIdInNumber].daysLater = currentDaysLater;
            const currentVariants = updatedStages[stepIdInNumber]?.variants;
            for (let property in currentVariants) {
              currentVariants[property].daysLater = currentDaysLater;
            }
            updatedStages[stepIdInNumber + 1].daysLater = updatedDaysLater;
            const updatedVariants = updatedStages[stepIdInNumber + 1]?.variants;
            for (let property in updatedVariants) {
              updatedVariants[property].daysLater = updatedDaysLater;
            }
          }
        }
        const constructedPayload = updateSequencePayload(updatedStages);

        constructedPayload.id = seqId;
        setShowStageLoader(true);
        addStagesPopupState.createUpdateStep(
          constructedPayload,
          handleMoveStageAction,
          "PUT",
          true
        );
      }
    };
    checkContactStatus(stepId, seqType, action, moveStageCbk);
  };

  const toggleVariants = (
    newSteps,
    seqId,
    afterUpdateCbk,
    isVariantAction = true
  ) => {
    const constructedPayload = updateSequencePayload(newSteps);
    constructedPayload.id = seqId;
    addStagesPopupState.createUpdateStep(
      constructedPayload,
      afterUpdateCbk,
      "PUT",
      isVariantAction
    );
  };

  const getPropsForSortableItem = (item = "", index = 0) => {
    const type = getStageType(index + 1);
    const isTask = ["linkedin", "phone", "customTask", "linkedinAuto"].includes(
      type
    );

    daysLaterCount =
      index === 0 ? 1 : daysLaterCount + parseInt(item?.daysLater);
    let params = {
      stepNo: index + 1,
      type,
      currentStep: item,
      name: ConstantObj?.stageTypeObj[type],
      steps,
      daysLaterCount,
      seqId
    };

    return {
      parentProps: { ...props },
      icon: stageIcon[type] || <EmailIcon />,
      params,
      isTask,
      toggleVariants,
      moveStagesAction,
      deleteStep
    };
  };
  const itemIds = useMemo(() => cards.map(item => item.uniqueId), [cards]);

  function handleDragEnd(event) {
    const { active = {}, over = {} } = event || {};
    if (active?.id !== over?.id) {
      setShowStageLoader(true);
      const activeIndex = [...cards].findIndex(
        item => item.uniqueId === active.id
      );
      const overIndex = [...cards].findIndex(item => item.uniqueId === over.id);
      let sortedSteps = arrayMove(cards, activeIndex, overIndex);
      if (sortedSteps?.length > 0) {
        let tempSteps = {};
        sortedSteps.forEach((item, index) => {
          tempSteps[index + 1] = item;
        });
        if (tempSteps && Object.keys(tempSteps)?.length) {
          const payload = updateSequencePayload(tempSteps);
          payload.id = seqId;
          const afterUpdateCbk = async (data = {}) => {
            if ([200, "200"]?.includes(data?.status)) {
              Utils.mixpanelTrack(MP_EVENT.SQ_STAGE_MOVED, {
                sequenceName: currentSeqData?.name,
                type: "Drag & Drop",
                pageType: "Sequence"
              });
            }
          };
          addStagesPopupState.createUpdateStep(payload, afterUpdateCbk, "PUT");
        }
      }
    }
  }

  useEffect(() => {
    setCards(Object.values(steps));
  }, [steps]);

  return cards?.length > 0 ? (
    <div className="stagesCont">
      <StepsContext.Provider
        value={{
          toggleVariants,
          moveStagesAction,
          deleteStep,
          stepBadgeAction
        }}
      >
        <DndContext
          collisionDetection={closestCenter}
          sensors={sensors}
          onDragEnd={handleDragEnd}
          autoScroll={{
            layoutShiftCompensation: false
          }}
        >
          <SortableContext
            items={itemIds}
            strategy={verticalListSortingStrategy}
          >
            {cards.map((ele, index) => {
              let objProps = getPropsForSortableItem(ele, index);
              return (
                <StepSortableItem
                  key={index}
                  id={ele?.uniqueId}
                  lastStepIndex={cards?.length - 1 - index} // 0 = Last Step Index
                  items={objProps}
                  setAnalyticStepLoader={setAnalyticStepLoader}
                  analyticStepLoader={analyticStepLoader}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </StepsContext.Provider>
      {showStageLoader &&
        Array.from({ length: 1 }, (value, index) => (
          <StageLoader
            key={"stageLoader" + index}
            line={1 - index > 1 ? true : false}
          />
        ))}
    </div>
  ) : (
    <div className="noStagesCont">
      <div className="noStgtitle">No steps added to this sequence</div>
    </div>
  );
});

export default Steps;
