import Utils from "Utils/utils";
import { months } from "../LiveFeed/constants";

const ActivityInsightsUtils = {};
const today = new Date();

ActivityInsightsUtils.getFirstAndLastDayOfWeek = () => {
  const weekStartingDate = Utils.getStartingDateOfthisWeek(today);
  const firstDateOfWeek = new Date(
    weekStartingDate.getFullYear(),
    weekStartingDate.getMonth(),
    weekStartingDate.getDate(),
    0
  );
  const lastDateOfWeek = new Date(
    weekStartingDate.getFullYear(),
    weekStartingDate.getMonth(),
    weekStartingDate.getDate() + 6,
    0
  );
  return { firstDateOfWeek, lastDateOfWeek };
};

ActivityInsightsUtils.getFirstAndLastDayOfPrevWeek = () => {
  const previousWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000),
    clonePreviousWeek = new Date(previousWeek),
    day = previousWeek.getDay(),
    diffToMonday = previousWeek.getDate() - day + (day === 0 ? -6 : 1),
    firstDateOfPrevWeek = new Date(previousWeek.setDate(diffToMonday)),
    lastDateOfPrevWeek = new Date(clonePreviousWeek.setDate(diffToMonday + 6));
  return { firstDateOfPrevWeek, lastDateOfPrevWeek };
};

ActivityInsightsUtils.getFirstAndLastDayOfMonth = () => {
  const firstDateOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  return { firstDateOfMonth, lastDateOfMonth: today };
};

ActivityInsightsUtils.getFirstAndLastDayOfPrevMonth = () => {
  const firstDateofPrevMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const lastDateOfPrevMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    0
  );
  return { firstDateofPrevMonth, lastDateOfPrevMonth };
};

ActivityInsightsUtils.getInsightsAnalyticsPayload = value => {
  switch (value) {
    case "thisWeek":
      const { firstDateOfWeek, lastDateOfWeek } =
        ActivityInsightsUtils.getFirstAndLastDayOfWeek();
      return {
        firstDate: Utils.convertFromDateToLocalString(firstDateOfWeek),
        lastDate: Utils.convertFromDateToLocalString(lastDateOfWeek)
      };
    case "lastWeek":
      const { firstDateOfPrevWeek, lastDateOfPrevWeek } =
        ActivityInsightsUtils.getFirstAndLastDayOfPrevWeek();
      return {
        firstDate: Utils.convertFromDateToLocalString(firstDateOfPrevWeek),
        lastDate: Utils.convertFromDateToLocalString(lastDateOfPrevWeek)
      };
    case "thisMonth":
      const { firstDateOfMonth, lastDateOfMonth } =
        ActivityInsightsUtils.getFirstAndLastDayOfMonth();
      return {
        firstDate: Utils.convertFromDateToLocalString(firstDateOfMonth),
        lastDate: Utils.convertFromDateToLocalString(lastDateOfMonth)
      };
    case "lastMonth":
      const { firstDateofPrevMonth, lastDateOfPrevMonth } =
        ActivityInsightsUtils.getFirstAndLastDayOfPrevMonth();
      return {
        firstDate: Utils.convertFromDateToLocalString(firstDateofPrevMonth),
        lastDate: Utils.convertFromDateToLocalString(lastDateOfPrevMonth)
      };
    case "7d":
      const sevenDateObj = Utils.getFromToDateWithTimeZoneByDaysCount(6);
      return {
        firstDate: sevenDateObj?.filterFromDate,
        lastDate: sevenDateObj?.filterToDate
      };
    case "30d":
      const thirtyDateObj = Utils.getFromToDateWithTimeZoneByDaysCount(29);
      return {
        firstDate: thirtyDateObj?.filterFromDate,
        lastDate: thirtyDateObj?.filterToDate
      };
    case "yesterday":
      const yesterdayObj = Utils.getFromToDateWithTimeZoneByDaysCount(1);
      return {
        firstDate: yesterdayObj?.filterFromDate,
        lastDate: yesterdayObj?.filterToDate
      };
    case "today":
    default:
      return {
        firstDate: Utils.convertFromDateToLocalString(today),
        lastDate: Utils.convertToDateToLocalString(today)
      };
  }
};

ActivityInsightsUtils.getOrdinalSuffix = date => {
  var j = date % 10,
    k = date % 100;
  if (j == 1 && k != 11) {
    return date + "st";
  }
  if (j == 2 && k != 12) {
    return date + "nd";
  }
  if (j == 3 && k != 13) {
    return date + "rd";
  }
  return date + "th";
};

ActivityInsightsUtils.getDateFormat = value => {
  const date = new Date(value);
  const mon = months[date.getMonth()];
  const dateValue = date.getDate();
  const updatedDateFormat = `${mon} ${ActivityInsightsUtils.getOrdinalSuffix(
    dateValue
  )}`;
  return updatedDateFormat;
};

export { ActivityInsightsUtils };
export default ActivityInsightsUtils;
