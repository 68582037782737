import React from "react";

const ConnectedInboxLoader = props => {
  const { count = 1 } = props || {};
  return Array.from({ length: count }, (value, index) => (
    <div
      className="connectedInboxCard inboxRowloader"
      key={`inboxRowloader${index}`}
    >
      <div className="imgType linear-background" />
      <div className="wrapConnectedEmail">
        <div className="userName linear-background" />
        <div className="connectedEmail linear-background" />
      </div>
      <div className="warmerMetricsWrapper linear-background" />
      <div className="warmingEnabledOrDisabledBtn linear-background" />
      <div className="configureBtn linear-background" />
      <div className="deleteInboxIcon linear-background" />
    </div>
  ));
};

export default ConnectedInboxLoader;
export { ConnectedInboxLoader };
