import React, { memo } from "react";
import { callSummaryHeader } from "./CallsConstant";

export const CallsOverviewLoader = props => {
  const {
    loader = false,
    count = 0,
    currentTab = "",
    getWidth = () => {}
  } = props || {};

  return (
    <>
      {Array.from({ length: count }, (value, index) => (
        <div className="tableRowLoader" key={`callsOverviewLoader${index}`}>
          {currentTab === "NO_OUTCOME" && (
            <div className="checkboxBtn">
              <span className="linear-background" />
            </div>
          )}
          {callSummaryHeader.map(item => {
            const {
              name = "",
              className = "",
              id = "",
              width = "auto",
              textAlign = "left",
              showHeader = false,
              value = ""
            } = item || {};
            return (
              (currentTab === value || showHeader) && (
                <div
                  name={name}
                  key={id}
                  className={className}
                  style={{
                    width: getWidth(width),
                    textAlign
                  }}
                >
                  <span className="linear-background" />
                </div>
              )
            );
          })}
        </div>
      ))}
    </>
  );
};

const MemoizedCallsOverviewLoader = memo(CallsOverviewLoader);
export default CallsOverviewLoader;
export { MemoizedCallsOverviewLoader };
