import React, {
  useEffect,
  useContext,
  useState,
  createContext,
  useRef
} from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { AccountsContext } from "../Main";
import AccountNotesEditor from "./AccountNotesEditor";
import AccountNotesview from "./AccountNotesview";
import makeApi, { URLS } from "Utils/apiURL";
import AccountNotesList from "./AccountNotesList";
import SearchByContactKeyword from "../../contacts/SearchByContactKeyword";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import useDebounce from "Components/common/hooks/useDebounce";
import accountNotes from "Assets/png/accountNotes.png";

class EditAccountNotesState {
  @observable showEdit = false;
  @observable isAddBtn = false;
  @observable notes = false;
  @observable isEdit = false;
  @observable notesNewUser = false;

  @action
  setIsAddBtn = (val = false) => {
    this.isAddBtn = val;
  };

  @action
  setShowEdit = (val = false) => {
    this.showEdit = val;
  };

  @action
  setNotes = (val = {}) => {
    this.notes = val;
  };

  @action
  setIsEdit = (val = false) => {
    this.isEdit = val;
  };

  @action
  setNotesNewUser = (val = false) => {
    this.notesNewUser = val;
  };

  @action
  resetState = () => {
    this.showEdit = false;
    this.isAddBtn = false;
    this.notes = "";
    this.isEdit = false;
    this.notesNewUser = false;
  };
}

const editAccountNotesState = new EditAccountNotesState();

const AccountsNotesContext = createContext({});

const Main = () => {
  let { accountId } = useParams();
  const { updateBreadCumb = () => {} } = useContext(AccountsContext);

  const searchInputRef = useRef(null);

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [searchLoader, setSearchLoader] = useState(false);
  const [isEmailClose, setIsEmailClose] = useState(false);

  const [state, setState] = useState({
    buttonLoader: false,
    inputVal: { ...editAccountNotesState?.notes },
    accountNotesData: [],
    loading: false,
    editNotesItem: {},
    notesCurrentPageNo: 1,
    notesTotalPage: 1,
    accountNotesSearchData: []
  });

  const debouncedValue = useDebounce(searchKeyword, 700);

  const addNotesInitialFn = () => {
    editAccountNotesState.setShowEdit(true);
    editAccountNotesState.setIsAddBtn(true);
    editAccountNotesState.setIsEdit(false);
  };

  const addNotes = {
    id: "addAccountNotes",
    name: "addAccountNotes",
    btnText: "Add Notes",
    type: "button",
    className: "addNotesInitialBtn",
    click: addNotesInitialFn
  };

  const getAccountNotes = async (newPageNumber = "", loader = true) => {
    setState({ ...state, loading: loader });
    try {
      let config = {
        url: URLS.getAccountNotes,
        method: "POST",
        data: {
          type: "ACCOUNT",
          referenceId: accountId || "",
          pageNo: newPageNumber || 1,
          limit: 15,
          currentPage: state?.notesCurrentPageNo || 1
        }
      };
      let response = await makeApi(config);
      let { responseCode = "" } = response?.data || {};
      if (+responseCode === 200) {
        setState({
          ...state,
          accountNotesData: response?.data?.result?.notes || [],
          loading: false,
          notesTotalPage: response?.data?.result?.pages || 1
        });

        editAccountNotesState.setNotesNewUser(
          response?.data?.result?.notes?.length > 0 ? true : false
        );
        return response?.data?.result?.notes;
      } else {
        setState({
          ...state,
          accountNotesData: [],
          loading: false
        });
        return [];
      }
    } catch (err) {
      setState({ ...state, loading: false });
    }
  };

  const handleScrollEnd = async event => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      clientHeight = 0
    } = event?.currentTarget;

    if (
      scrollHeight - scrollTop - 10 < clientHeight &&
      state?.notesCurrentPageNo < state?.notesTotalPage
    ) {
      setPaginationLoading(true);
      const newPageNumber = state?.notesCurrentPageNo + 1;
      const response = await getAccountNotes(newPageNumber, false);
      setState(prev => ({
        ...prev,
        notesCurrentPageNo: newPageNumber,
        accountNotesData: [...state?.accountNotesData, ...response],
        loading: false
      }));
      searchKeyword?.length > 1 && searchByTitle(newPageNumber);
      setPaginationLoading(false);
    }
  };

  const searchByTitle = async (newPageNumber = "") => {
    setSearchLoader(true);
    try {
      let config = {
        url: URLS.accountNotesSearch,
        method: "POST",
        data: {
          type: "ACCOUNT",
          referenceId: accountId || "",
          searchText: searchKeyword || "",
          pageNo: newPageNumber || 1,
          limit: 20
        }
      };
      let response = await makeApi(config);
      let { responseCode = "" } = response?.data || {};
      let { notes = [], pages = "" } = response?.data?.result || {};
      if (+responseCode === 200) {
        setState(prev => ({
          ...prev,
          accountNotesSearchData: notes || [],
          loading: false,
          notesTotalPage: pages || 1
        }));
      } else {
        setState(prev => ({
          ...prev,
          accountNotesData: [],
          loading: false
        }));
      }
      setSearchLoader(false);
    } catch (error) {}
  };

  const searchNotes = () => {
    searchKeyword !== null && searchByTitle();
    searchKeyword?.length && setIsEmailClose(true);
    searchInputRef && searchInputRef.current && searchInputRef.current.blur();
  };

  const submitForm = (e = "") => {
    Utils.isSubmitKeyAction(e) && searchNotes();
  };

  const handleSearchAction = (event = "") => {
    Utils.preventDefaultFn(event);
    setSearchLoader(true);
    const currentTargetVal = event?.currentTarget?.value || "";
    setSearchKeyword(currentTargetVal);
    setIsEmailClose(!currentTargetVal?.length < 1);
    if (!currentTargetVal) {
      getAccountNotes(1);
      setState(prev => ({
        ...prev,
        notesCurrentPageNo: prev?.notesCurrentPageNo,
        accountNotesSearchData: []
      }));
      setSearchLoader(false);
    }
  };

  const clearSearch = () => {
    setSearchKeyword("");
    setIsEmailClose(false);
    searchInputRef.current.focus();
    searchByTitle("");
    setState(prev => ({
      ...prev,
      notesCurrentPageNo: 1,
      accountNotesSearchData: []
    }));
    getAccountNotes(1);
  };

  const searchBykeywordObj = {
    isEmailClose: isEmailClose,
    placeHolder: "Search",
    searchKeyword: searchKeyword,
    inputRef: searchInputRef,
    searchNotes: searchNotes,
    submitForm: submitForm,
    handleSearchAction: handleSearchAction,
    clearSearch: clearSearch
  };

  const updateEditNotesItem = () => {
    if (
      state?.accountNotesData?.length > 0 &&
      state?.notesCurrentPageNo === 1
    ) {
      setState(prevState => ({
        ...prevState,
        editNotesItem:
          prevState.notesCurrentPageNo === 1
            ? state.accountNotesData?.[0]
            : prevState.accountNotesData?.[0]
      }));
    }
  };

  useEffect(() => {
    updateBreadCumb("notes");
    getAccountNotes();
    return () => editAccountNotesState.resetState();
  }, []);

  useEffect(() => {
    updateEditNotesItem();
  }, [state?.accountNotesData, state?.notesCurrentPageNo]);

  useEffect(() => {
    debouncedValue && searchByTitle();
  }, [debouncedValue]);

  return (
    <div
      className={`${
        state?.accountNotesData?.length > 0
          ? "accountNotes"
          : "initialUserNotes"
      }`}
    >
      <AccountsNotesContext.Provider
        value={{
          state,
          setState,
          getAccountNotes,
          accountId,
          searchKeyword
        }}
      >
        {state?.accountNotesData?.length > 0 ? (
          <>
            <div>
              <SearchByContactKeyword {...searchBykeywordObj} />
              <div
                className="accountAllNotesList"
                onScroll={event => handleScrollEnd(event)}
              >
                <div className="accountNotesListHeader">Notes</div>
                {!state?.loading && !searchLoader && (
                  <AccountNotesList handleScrollEnd={handleScrollEnd} />
                )}
                {(state?.loading || searchLoader || paginationLoading) && (
                  <NotesListLoader count={10} />
                )}
              </div>
            </div>
            <div className="accountNotesListBody">
              {!editAccountNotesState?.showEdit ? (
                <AccountNotesview />
              ) : (
                <AccountNotesEditor />
              )}
            </div>
          </>
        ) : state?.loading ? (
          <div className="initialBody">
            <NotesListNewLoader count={10} />
          </div>
        ) : (
          <div className="initialBody">
            {!editAccountNotesState?.showEdit ? (
              <div className="initialAddNotes">
                <img
                  className="accountNotesImg"
                  src={accountNotes}
                  alt=""
                  loading="lazy"
                />
                <Button {...addNotes} />
              </div>
            ) : (
              <AccountNotesEditor />
            )}
          </div>
        )}
      </AccountsNotesContext.Provider>
    </div>
  );
};

const NotesListLoader = ({ count = 10 }) => {
  return (
    <>
      {Array.from({ length: count }, (item, index) => (
        <div className="accountNotesListHeaderLoader" key={index}>
          <div className="accountNotesTitleLoader linear-background" />
          <div className="accountNotesCreatedByLoader">
            <div className="notesCreatedByLoader linear-background" />
            <div className="notesCreatedDateLoader linear-background" />
          </div>
        </div>
      ))}
    </>
  );
};

const NotesListNewLoader = ({ count = 10 }) => {
  return (
    <>
      {Array.from({ length: count }, (item, index) => (
        <div className="accountNotesListHeaderLoader" key={index}>
          <div className="accountNotesTitleLoader linear-background" />
          <div className="accountNotesCreatedByLoader">
            <div className="notesCreatedByLoader linear-background" />
          </div>
        </div>
      ))}
    </>
  );
};

export { Main, editAccountNotesState, AccountsNotesContext };
export default observer(Main);
