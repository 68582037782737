import React from "react";
import Utils from "Utils/utils";

const FilterNavBar = (props = {}) => {
  const {
    type = "",
    filterArr = [],
    navBarLoading = false,
    activeTab = "",
    filterAccountsCbk = () => {}
  } = props || {};

  return (
    <div className="filterNavBar seq-contact-nav-section">
      <ul
        className="seq-contact-list"
        id="filter"
        style={{
          gridTemplateColumns: `repeat(${
            navBarLoading ? 8 : filterArr?.length
          }, 150px)`
        }}
        onClick={e => filterAccountsCbk(e)}
      >
        {!navBarLoading
          ? filterArr?.length > 0 &&
            filterArr.map((item, index) => {
              const {
                name = "",
                displayName = "",
                count = 0,
                id = ""
              } = item || {};
              return (
                <li
                  key={`${type}${name}${index}${id}`}
                  className={`seq-contact-card ${
                    activeTab?.toLowerCase() === name?.toLowerCase()
                      ? "active"
                      : ""
                  }`}
                  data-key={name}
                  data-id={id}
                >
                  <div className="seq-contact-body">
                    <div className="seq-contact-content">
                      <h6
                        className={
                          activeTab?.toLowerCase() === name?.toLowerCase()
                            ? "activeNo"
                            : ""
                        }
                      >
                        {Utils.formatNumberToStringInUSFormat(count) || 0}
                      </h6>
                      <p>{displayName || name}</p>
                    </div>
                  </div>
                </li>
              );
            })
          : Array.from({ length: 8 }, (value, index) => (
              <li
                className="linear-background"
                key={`filter${type}NavLoader${index}`}
              />
            ))}
      </ul>
    </div>
  );
};

export default FilterNavBar;
export { FilterNavBar };
