import React, { useState } from "react";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import CustomRadioButton from "Components/common/customRadioBtn";
import { ChooseToConnectConfig } from "./googleOAuthConfig";
import { googleOAuthPopupState } from "../googleOAuthPopup";

const ChooseToConnectScreen = observer(() => {
  const [loading, setLoading] = useState(false);

  const clickProceedBtn = () => {
    setLoading(true);
    googleOAuthPopupState.setScreenNo("two");
    googleOAuthPopupState.setAppPasswordScreen("one");
  };

  const btnProps = [
    {
      id: "proceedBtn",
      name: "proceedBtn",
      icon: false,
      type: "button",
      className: "proceedBtn",
      loader: loading,
      btnText: "Next",
      click: () => clickProceedBtn(),
      show: true
    }
  ];

  const radioOnchangeAction = (e, id = "") => {
    googleOAuthPopupState.setConnectConfig(id);
  };

  return (
    <div>
      <div className="chooseToConnectHeading">Choose how to connect</div>
      <div className="initialScreenBody">
        {ChooseToConnectConfig.map((item, index) => {
          const {
            label = "",
            subLabel = "",
            id = "",
            recommended = false
          } = item || {};

          return (
            <div
              className={`chooseConnectWrapper ${
                recommended ? "recommended" : ""
              } ${
                googleOAuthPopupState.connectConfig === id ? "selected" : ""
              }`}
              onClick={e => radioOnchangeAction(e, id)}
              key={id}
            >
              {recommended && (
                <div className="recommendedText">Recommended</div>
              )}
              <div className="radioWrapper">
                <CustomRadioButton
                  id={id}
                  value={label}
                  name="connectConfig"
                  radioOnchange={radioOnchangeAction}
                  checked={
                    googleOAuthPopupState.connectConfig === id ? "checked" : ""
                  }
                />
              </div>
              <div className="subLabelContent">{subLabel}</div>
            </div>
          );
        })}
      </div>
      <div className="oAuthFooter">
        <div className="actionBtns">
          {btnProps.map((item, index) => {
            return item?.show && <Button {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
});

export { ChooseToConnectScreen };
export default ChooseToConnectScreen;
