import React, { useState, useEffect } from "react";
import Tooltip from "Components/common/CustomTooltip";

const SecondaryCheckbox = props => {
  const {
    id = "",
    label = "",
    cbk = () => {},
    checked = false,
    allChecked = false,
    showInfoIcon = false,
    tooltipText = "",
    cbkData = "",
    disabled = false
  } = props;

  const [tickMark, setTickMark] = useState(checked);
  const [showToolTip, setShowToolTip] = useState(false);

  const resetMultipleInbox = () => {
    setTickMark(true);
  };

  const setCheckBoxSelected = e => {
    if (!allChecked) {
      setTickMark(!tickMark);
      cbk(!tickMark, id, label, cbkData, resetMultipleInbox);
    } else {
      cbk(tickMark, id, label, cbkData);
    }
  };

  useEffect(() => {
    setTickMark(checked);
  }, [checked]);

  return (
    <div
      className="checkboxWrapper"
      onClick={disabled ? () => {} : e => setCheckBoxSelected(e)}
    >
      <div
        className={`commonFilledCheckBox ${checked ? "checked" : ""} ${
          disabled ? "disabled" : ""
        }`}
        id={id}
      >
        <div className="checkBoxCard">
          <div className="emptyCheckBox" />
          <div className="checkSvgIcon">
            <svg width="16" height="14" viewBox="0 0 16 14">
              <path d="M2 8.5L6 12.5L14 1.5" />
            </svg>
          </div>
        </div>
      </div>
      {label && (
        <label className="checkBoxName" htmlFor={label}>
          {label}
        </label>
      )}
      {showInfoIcon && (
        <span className="tooltipWrap">
          <i
            className="material-icons iIcon"
            onMouseOver={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          >
            info_outline
          </i>
          {showToolTip && <Tooltip text={tooltipText} />}
        </span>
      )}
    </div>
  );
};

export { SecondaryCheckbox };
export default SecondaryCheckbox;
