import React, { useEffect, useState } from "react";
import Tooltip from "Components/common/CustomTooltip";

const ToggleBtnType = props => {
  const {
    id = "",
    defaultValue = false,
    offer = "",
    firstText = "",
    lastText = "",
    isAllowed = true,
    showInfoIcon = false,
    enableLoading = false,
    pageType = "",
    popupCbk = () => {},
    cbk = () => {}
  } = props || {};

  const [checked, setChecked] = useState(defaultValue);
  const [showToolTip, setShowToolTip] = useState(false);

  const getTooglePlan = () => {
    setChecked(isAllowed ? !checked : checked);
    cbk(isAllowed ? !checked : checked, id);
    !isAllowed && pageType === "dialer" && popupCbk();
  };

  useEffect(() => {
    defaultValue && setChecked(defaultValue);
  }, [defaultValue]);
  return (
    <div className={`toggleBtnSection ${!isAllowed ? "disabledToggle" : ""}`}>
      <span className={`toggleText rightPad ${!checked ? "activeToogle" : ""}`}>
        {firstText}
      </span>
      <div className="toogleSequence">
        <div className={`toogleButton ${enableLoading ? "loading" : ""}`}>
          <input
            type="checkbox"
            className="toggleCheckbox"
            checked={isAllowed ? defaultValue : checked}
            onChange={getTooglePlan}
          />
          <div className="knobs" />
          <div className="layer" />
        </div>
      </div>
      <span className={`toggleText leftPad ${checked ? "activeToogle" : ""}`}>
        {lastText}
      </span>
      {showInfoIcon && (
        <span className="tooltipWrap">
          <i
            className="material-icons iIcon"
            onMouseOver={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          >
            info_outline
          </i>
          {showToolTip && (
            <Tooltip text="When link tracking is enabled, open tracking will also be enabled" />
          )}
        </span>
      )}
      {offer && <span className="offerBadge">{offer}% OFF</span>}
    </div>
  );
};

export default ToggleBtnType;
