import React, { useState, useEffect } from "react";
import Utils from "Utils/utils";

const CheckBox = (props = {}) => {
  const {
    id = "",
    dotColor = "",
    name = "",
    cbk = () => {},
    checked = false,
    allContactsChecked = false,
    disabled = false,
    hasSubMenu = false,
    hasCustomDom = false,
    dom = <></>,
    noClick = false,
    itemWithSubmenu
  } = props || {};

  const [tickMark, setTickMark] = useState(checked);

  const handleChange = e => {
    Utils.preventDefaultFn(e);
    if (hasSubMenu) {
      cbk(!tickMark, id, name, itemWithSubmenu);
    } else if (!allContactsChecked) {
      setTickMark(!tickMark);
      cbk(!tickMark, id, name);
    } else {
      cbk(tickMark, id, name);
    }
  };

  useEffect(() => {
    setTickMark(checked);
  }, [checked]);

  return (
    <div className={`checkBoxSection ${disabled ? "disabled" : ""}`}>
      <div
        className={`commonFilledCheckBox ${checked ? "checked" : ""}`}
        id={id}
        onClick={e => handleChange(e)}
      >
        <div className="checkBoxCard">
          <div className="emptyCheckBox" />
          <div className="checkSvgIcon">
            <svg width="16" height="14" viewBox="0 0 16 14">
              <path d="M2 8.5L6 12.5L14 1.5" />
            </svg>
          </div>
        </div>
      </div>
      {hasCustomDom ? (
        <label
          className="checkBoxName"
          onClick={e => (noClick ? () => {} : handleChange(e))}
        >
          {dom}
        </label>
      ) : (
        name && (
          <label
            className="checkBoxName"
            htmlFor={name}
            onClick={e => handleChange(e)}
          >
            {name}
            {dotColor && (
              <span className="dot" style={{ background: dotColor }} />
            )}
          </label>
        )
      )}
    </div>
  );
};

export default CheckBox;
