import React from "react";
import { observer } from "mobx-react";
import ProgressBarSlide from "Components/FileAttachment/ProgressBar";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";

const FileUploadingLoader = () => {
  return (
    <div className="fileUploadingLoader">
      <div className="fileUploading">
        <span className="uplaodText">
          {`Uploading Attachment${
            fileAttachmentStore.totalFileUploading > 1 ? "s" : ""
          }`}
        </span>
        <span className="uploadCount">
          ({fileAttachmentStore.totalFileUploading})
        </span>
        <ProgressBarSlide />
      </div>
    </div>
  );
};

export default observer(FileUploadingLoader);
export { FileUploadingLoader };
