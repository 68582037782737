import { observable, action } from "mobx";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import { SortParamObj } from "./constants";
import { HEADERS } from "./constants";

let cancelRequest = null;
let cancelAnalyticsRequest = null;

const checkAndCancelRequest = () => {
  if (cancelRequest !== null) {
    cancelRequest("cancel");
    cancelRequest = null;
  }
};

const checkAndCancelAnalyticsRequest = () => {
  if (cancelAnalyticsRequest !== null) {
    cancelAnalyticsRequest("cancel");
    cancelAnalyticsRequest = null;
  }
};

const cancelApiRequest = value => {
  cancelRequest = value;
};

const cancelAnalyticsApiRequest = value => {
  cancelAnalyticsRequest = value;
};

class ReportsStore {
  @observable reportsData = [];
  @observable dateFilter = Utils.getDateWithTimestamp(
    Utils.getFromToDateWithTimeZoneByDaysCount(29)
  );
  @observable userheadersId = "";
  @observable teamHeaderId = "";
  @observable sequenceHeaderId = "";
  @observable tabType = "";
  @observable loading = false;
  @observable openDragAndDrop = false;
  @observable saveLoader = null;
  @observable pageNo = 1;
  @observable totalPage = 1;
  @observable pageLimit = 10;
  @observable searchText = "";
  @observable export = false;
  @observable headers = [...HEADERS];
  @observable sortParam = "contactsAddedCount";
  @observable sortType = "ASC";
  @observable memberIds = null;
  @observable newVersionReportsFlag = false;
  @observable activeDateFilter = "month";
  @observable sequenceAnalyticsData = [];

  @action
  setActiveDateFilter = (filter = "") => {
    this.activeDateFilter = filter;
  };

  @action
  setSequenceAnalyticsData = (data = []) => {
    this.sequenceAnalyticsData = data;
  };

  @action
  setNewVersionReportsFlag = (flag = false) => {
    this.newVersionReportsFlag = flag;
  };

  @action
  setUserHeadersId = (id = "") => {
    this.userheadersId = id;
  };

  @action
  setTeamHeaderId = (id = "") => {
    this.teamHeaderId = id;
  };

  @action
  setSequenceHeaderId = (id = "") => {
    this.sequenceHeaderId = id;
  };

  @action
  setMemberIds = (ids = []) => {
    this.memberIds = ids;
  };

  @action
  setDateFilter = (date = {}) => {
    this.dateFilter = date;
  };

  @action
  setReportsData = (val = {}) => {
    this.reportsData = val;
  };

  @action
  setTabType = (type = "") => {
    this.tabType = type;
  };

  @action
  setLoading = (loading = false) => {
    this.loading = loading;
  };

  @action
  setOpenDragAndDrop = (value = false) => {
    this.openDragAndDrop = value;
  };

  @action
  setSaveLoader = (saveLoader = false) => {
    this.saveLoader = saveLoader;
  };

  @action
  setPageNo = (pageNo = 0) => {
    this.pageNo = pageNo;
  };

  @action
  setTotalPage = (totalPage = 0) => {
    this.totalPage = totalPage;
  };

  @action
  setPageLimit = (limit = 10) => {
    this.pageLimit = limit;
  };

  @action
  setSearchText = (text = "") => {
    this.searchText = text;
  };

  @action
  setExport = (exportVal = "") => {
    this.export = exportVal;
  };

  @action
  setHeaders = (headersVal = []) => {
    this.headers = headersVal;
  };

  @action
  setSortParam = (param = "") => {
    this.sortParam = param;
  };

  @action
  setSortType = (type = "") => {
    this.sortType = type;
  };

  @action
  transformedGroupReport = (groupReportData = []) => {
    return groupReportData.map(item => {
      const { report, group } = item;
      return {
        ...report,
        ...group,
        user: {
          memberId: group?.memberId,
          firstName: group?.name
        }
      };
    });
  };

  @action
  fetchReportsData = async (payload = {}, type = "", cbk = () => {}) => {
    const tempPayload = {
      pageNo: this?.pageNo || 1,
      limit: this?.pageLimit,
      sortParam: SortParamObj[this?.sortParam],
      direction: this?.sortType,
      fromDate: this.dateFilter?.fromDate,
      toDate: this.dateFilter?.toDate,
      memberIds: this.memberIds || []
    };
    const URLObj = {
      user: URLS.getUserReports,
      team: URLS.getGroupReports,
      sequence: URLS.getSequenceReports
    };
    const config = {
      url: URLObj[type?.toLowerCase()],
      method: "POST",
      data: tempPayload
    };
    const response = await makeApi(config, cancelApiRequest);
    checkAndCancelRequest();
    const { data = {}, status = "" } = response || {};
    if (+status === 200) {
      let tempData =
        type === "user"
          ? data?.userReport
          : type === "sequence"
          ? data?.sequenceReport
          : this.transformedGroupReport(data?.groupReport);
      this.setReportsData(tempData || []);
      let totalPages = Math.ceil(tempData?.length / 10);
      this.setTotalPage(totalPages);
    }
  };

  @action
  fetchSequenceAnalytics = async () => {
    const tempPayload = {
      fromDate: this.dateFilter?.fromDate,
      toDate: this.dateFilter?.toDate,
      memberIds: this.memberIds || []
    };
    const config = {
      url: URLS.getSequenceAnalytics,
      method: "POST",
      data: tempPayload
    };
    const response = await makeApi(config, cancelAnalyticsApiRequest);
    checkAndCancelAnalyticsRequest();
    const { data = {}, status = "" } = response || {};
    if (+status === 200) {
      reportsStore.setSequenceAnalyticsData(data);
    }
  };

  @action
  fetchHeaderList = async (type = "REPORTS_USER_ACTIVITY", cbk = () => {}) => {
    const config = {
      url: `${URLS.getTableHeaders}${type}`,
      method: "GET"
    };
    const response = await makeApi(config);
    const { data = {} } = response || {};
    this.setDragAndDropTableId(data?.id, type);
    let filteredColumns = data?.columns.filter(column => {
      const displayName = column.displayName.toLowerCase();
      return !["user", "users", "team", "teams", "sequence name"].includes(
        displayName?.toLowerCase()
      );
    });
    cbk(filteredColumns);
  };

  @action
  saveDynamicHeaders = async (
    type = "REPORTS_USER_ACTIVITY",
    cbk = () => {},
    headerArr = []
  ) => {
    const config = {
      url: URLS.editTableHeaders,
      method: "POST",
      data: {
        id:
          type?.toUpperCase() === "REPORTS_USER_ACTIVITY"
            ? this.userheadersId
            : type?.toUpperCase() === "REPORTS_TEAM_ACTIVITY"
            ? this.teamHeaderId
            : this.sequenceHeaderId,
        tableName: type,
        columns: headerArr
      }
    };
    const response = await makeApi(config);
    const { data = {} } = response || {};
    if (data?.columns) {
      this.setDragAndDropTableId(data?.id, type);
      let filteredColumns = data?.columns.filter(column => {
        const displayName = column?.displayName?.toLowerCase();
        return !["user", "users", "team", "teams", "sequence name"].includes(
          displayName?.toLowerCase()
        );
      });
      cbk(filteredColumns);
      toasterState.setToastMsg(
        "Table column order has been updated successfully",
        "success"
      );
    }
  };

  @action
  setDragAndDropTableId = (id = "", type = "") => {
    return type?.toUpperCase() === "REPORTS_USER_ACTIVITY"
      ? this.setUserHeadersId(id)
      : type?.toUpperCase() === "REPORTS_TEAM_ACTIVITY"
      ? this.setTeamHeaderId(id)
      : this.setSequenceHeaderId(id);
  };

  @action
  resetReports = () => {
    this.tabType = "";
    this.pageNo = 1;
    this.pageLimit = 10;
    this.sortParam = "contactsAddedCount";
    this.sortType = "ASC";
    this.reportsData = [];
  };
}

const reportsStore = new ReportsStore();
export { reportsStore };
export default reportsStore;
