import React from "react";
import {
  Switch,
  Redirect,
  Route,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import ContactTimeline from "Pages/Dashboard/contacts/ContactTimeline/ContactTimeline";
import ContactModule from "Pages/Dashboard/contacts/ContactModule";

const ContactsRouting = () => {
  return (
    <Switch>
      <Route
        exact
        path="/contacts/:contactId/activity"
        component={ContactTimeline}
      />
      <Route exact path="/contacts" component={ContactModule} />
      <Redirect from="/contacts" to="/contacts" />
    </Switch>
  );
};

export default withRouter(ContactsRouting);
export { ContactsRouting };
