import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import userDetail from "Stores/userDetail";
import BreadCrumbComponent from "Components/common/breadcrumb";
import { FreeTrial } from "Components/common/FreeTrial";
import UserFilterDropdown from "./LiveFeed/Components/UserFilterDropdown";
import ReportsModule from "./ReportsModule/main";
import TasksModule from "./TasksModule/main";
import DashboardState from "./mainState";
import LiveFeed from "./LiveFeed/main";
import { initialWelcomePopupState } from "Components/common/InitialWelcomePopup";

const Dashboard = observer(props => {
  const breadcrumbArr = [{ name: "Dashboard", link: "/dashboard" }];
  const {
    team = false,
    memberId = "",
    hasTeamMember = false,
    isTeamOwner = false
  } = userDetail?.userInfo || {};

  const { noOfSequencesUsed = 0, isOnBoardingCompleted = false } =
    userDetail.userFeatureFlag || {};

  const getSelectedUsers = obj => {
    const memberIds =
      obj && typeof obj === "object" && Object.keys(obj)?.length > 0
        ? obj
        : obj?.length && obj?.toLowerCase() === "you"
        ? new Array(memberId)
        : "";
    return memberIds;
  };

  const getSelectedUser = value => {
    const selectedIds = getSelectedUsers(value);
    DashboardState.setMemberId(selectedIds);
  };

  useEffect(() => {
    const isWelcomePopupShown = localStorage.getItem("isWelcomePopupShown");
    if (
      !isOnBoardingCompleted &&
      !isWelcomePopupShown &&
      !noOfSequencesUsed > 0
    ) {
      localStorage.setItem("isWelcomePopupShown", true);
      initialWelcomePopupState.setShowPopup(true);
    }
    return () => {
      DashboardState.setMemberId("");
    };
  }, []);

  return (
    <section className="dashboardPage">
      <div className="headerSection">
        <BreadCrumbComponent breadCrumbValue={breadcrumbArr} />
        <div className="headerBtnContainer">
          <FreeTrial {...props} />
        </div>
      </div>
      <div className="dashboardWrapper">
        <div className="filterSectionWrap">
          <div>Overview</div>
          <div className="topFilter">
            {((team && hasTeamMember?.length > 1) || isTeamOwner) && (
              <UserFilterDropdown
                team={team}
                selectedUserCbk={getSelectedUser}
              />
            )}
          </div>
        </div>
        <div className="dashboardContainer">
          <div className="leftSection">
            <ReportsModule />
            <TasksModule />
          </div>
          <div className="rightSection liveFeedSettingBarWeb">
            <LiveFeed />
          </div>
        </div>
      </div>
    </section>
  );
});

export default withRouter(Dashboard);
export { Dashboard };
