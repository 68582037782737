/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ChooseSeats from "./ChooseSeats";
import PricingStore from "Stores/Pricing";
import Utils from "Utils/utils";
import ConstantObj from "Static/constant";
import SequenceInfinityIcon from "Assets/png/sequenceInfinity.png";
import SyncInfinityIcon from "Assets/png/syncInfinity.png";
import ContactInifintyIcon from "Assets/png/contactInifinty.png";

const trialAdvantages = [
  {
    key: "sequences",
    image: SequenceInfinityIcon,
    text: "Unlimited Sequences"
  },
  {
    key: "contacts",
    image: ContactInifintyIcon,
    text: "Unlimited Contacts"
  },
  {
    key: "crmSync",
    image: SyncInfinityIcon,
    text: "CRM Sync"
  }
];

const TrialDetails = props => {
  const { displayName } = props;
  const { seats, initTeamSeat, price } = PricingStore.paymentFormInfo;

  const [seatCount, setSeatCount] = useState(seats);

  const getDisplayName = () => {
    if (displayName) {
      return `${displayName} - ${ConstantObj.trialDays} Day Trial`;
    }
  };

  const getSeatsPrice = () => {
    return Utils.numberWithCommas(seatCount * price);
  };

  const getTrialEndDate = () => {
    var date = new Date();
    date.setDate(date.getDate() + ConstantObj.trialDays);
    return Utils.formatDate(date);
  };

  useEffect(() => {
    if (PricingStore.paymentFormInfo.seats !== null) {
      setSeatCount(PricingStore.paymentFormInfo.seats);
    }
  }, [PricingStore.paymentFormInfo.seats]);

  return (
    <div className="trialDetailsWrapper">
      <div className="trialDetailsInnerCont">
        <div className="planName">{getDisplayName()}</div>
        <ChooseSeats
          initTeamSeat={initTeamSeat}
          seatCount={seatCount}
          setSeatCount={setSeatCount}
        />
        <div className="totalChargeText">
          You’ ll be charged{" "}
          <span className="highlight">${getSeatsPrice()} per month </span>for{" "}
          <span className="highlight">
            {seatCount} {seatCount > 1 ? "seats" : "seat"}
          </span>{" "}
          at the end of your trial on{" "}
          <span className="highlight">{getTrialEndDate()}</span>
        </div>
        <TrialAdvantage />
      </div>
    </div>
  );
};

const TrialAdvantage = () => {
  return (
    <div className="advantageWrapper">
      <h4 className="advantageText">
        Go ahead. Your Salesgear trial gives you:
      </h4>
      <div className="advantagesList">
        {trialAdvantages &&
          trialAdvantages.map((value, index) => {
            const { key, image, text } = value ? value : {};
            return (
              <div key={`advantage${index}`} className={`${key} advantage`}>
                <img src={image} alt={text} />
                <span className="text">{text}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TrialDetails;
export { TrialDetails };
