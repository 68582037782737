import React from "react";
import Lottie from "react-lottie";
import NoReportsFound from "Utils/LottieFiles/noReportsFound.json";

const NoActivityFound = ({ hasNoActivitySVG = true }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: NoReportsFound
  };

  return (
    <div className="noReportsFound">
      {hasNoActivitySVG && (
        <Lottie
          options={defaultOptions}
          width={120}
          height={100}
          style={{ margin: "0" }}
          speed={0.01}
        />
      )}
      <p className="textDesc">
        No activities found for the selected date range
      </p>
    </div>
  );
};

export default NoActivityFound;
export { NoActivityFound };
