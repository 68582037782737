import React, { useState, useEffect } from "react";
import { scheduleConfig } from "Static/outboxConstant";
import CustomTooltip from "Components/common/CustomTooltip";
import OutboxUtils from "Utils/OutboxUtils";
import { Utils } from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { ReactComponent as DateHoverIcon } from "Assets/svg/outbox/hover/dateHover.svg";
import { ReactComponent as TimeHoverIcon } from "Assets/svg/outbox/hover/timeHover.svg";
import { ReactComponent as DateIcon } from "Assets/svg/outbox/date.svg";
import { ReactComponent as TimeIcon } from "Assets/svg/outbox/time.svg";
import { ReactComponent as ScheduledIconTimer } from "Assets/svg/outbox/scheduledIconTimer.svg";
import { ReactComponent as ScheduledIconDate } from "Assets/svg/outbox/scheduledIconDate.svg";
import { DateUtils } from "Utils/DateUtils";

const EmailStatusButton = props => {
  const {
    status = "",
    scheduleCode = "",
    expectedScheduleDate = "",
    taskDue = "",
    ...remainingProps
  } = props || {};

  const [showDelayedTooltip, setShowDelayedTooltip] = useState(false);
  const emailStatus = OutboxUtils.getEmailStatus(status, scheduleCode);

  const { message: delayedMessage = "", reason: delayedReason = "" } =
    emailStatus === "delayed" &&
    OutboxUtils.getDelayedTooltip(
      scheduleCode,
      expectedScheduleDate,
      taskDue,
      remainingProps
    );

  useEffect(() => {
    if (emailStatus === "delayed") {
      Utils.mixpanelTrack(MP_EVENT.OUTBOX_DELAYED_VIEWED, {
        pageType: "Inbox",
        reason: delayedReason
      });
    }
  }, []);

  return (
    <div
      className="outboxEmailStatusWrapper"
      style={{
        color: `${scheduleConfig?.[emailStatus]?.color}`
      }}
      onMouseEnter={() =>
        emailStatus === "delayed" && setShowDelayedTooltip(true)
      }
      onMouseLeave={() =>
        emailStatus === "delayed" && setShowDelayedTooltip(false)
      }
    >
      {scheduleConfig?.[emailStatus]?.svg || ""}
      <span className="scheduledText">{emailStatus}</span>
      {emailStatus === "delayed" && showDelayedTooltip && (
        <CustomTooltip text={delayedMessage} />
      )}
    </div>
  );
};

const EmailScheduleDateAndTime = props => {
  const { taskDue = "", showHoverCard = false, emailStatus = "" } = props;
  const { date = "", time = "" } = DateUtils.getFormattedDateAndTime(taskDue);
  const scheduledEmail = emailStatus?.toLowerCase() === "delayed";
  return (
    <>
      <div
        className={`scheduledDate ${scheduledEmail ? "scheduledEmail" : ""}`}
      >
        {scheduledEmail ? (
          <ScheduledIconDate />
        ) : showHoverCard ? (
          <DateHoverIcon />
        ) : (
          <DateIcon />
        )}
        <div className="flexCenter">{date}</div>
      </div>
      <div
        className={`scheduledTime ${scheduledEmail ? "scheduledEmail" : ""}`}
      >
        {scheduledEmail ? (
          <ScheduledIconTimer />
        ) : showHoverCard ? (
          <TimeHoverIcon />
        ) : (
          <TimeIcon />
        )}
        <div className="flexCenter">{time}</div>
      </div>
    </>
  );
};

export { EmailStatusButton, EmailScheduleDateAndTime };
export default EmailStatusButton;
