import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { teamMemberState } from "./state";
import { teamState } from "../state";
import CheckBox from "Components/common/CheckBox";
import Button from "Components/common/Button";
import SearchInputBox from "Components/common/SearchInputBox";

const AddMembersPopup = observer(() => {
  const [activeTeamMembers, setActiveTeamMembers] = useState({});
  const [selectedMemberList, setSelectedMemberList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [clearSearchInput, setClearSearchInput] = useState(false);
  const [updatedTeamMembers, setUpdatedTeamMembers] = useState({});

  const resetUpdatedTeamMembers = () => {
    let tempMemberList = [...activeTeamMembers];
    tempMemberList = tempMemberList.map(user => {
      user.checked = false;
      return user;
    });
    setClearSearchInput("");
    setUpdatedTeamMembers(tempMemberList);
    setButtonLoading(false);
  };

  const handleAddTeamMembers = () => {
    setButtonLoading(true);
    teamMemberState.addMemberToGroup(
      teamState.selectedGroup,
      selectedMemberList,
      () => resetUpdatedTeamMembers()
    );
  };

  const searchActiveUsers = value => {
    const lowercasedValue = value?.toLowerCase();
    if (lowercasedValue === "") {
      setUpdatedTeamMembers(activeTeamMembers);
    } else {
      const filteredData =
        activeTeamMembers?.length &&
        activeTeamMembers?.filter(item => {
          return (
            item?.firstName
              ?.toString()
              ?.toLowerCase()
              ?.includes(lowercasedValue) ||
            item?.lastName?.toString()?.toLowerCase()?.includes(lowercasedValue)
          );
        });
      setUpdatedTeamMembers(filteredData);
    }
  };

  const addMembersBtnProps = {
    id: "addTeamMemberBtn",
    name: "addTeamMemberBtn",
    btnText: "Add members",
    type: "button",
    className: `addTeamMemberBtn ${buttonLoading ? "loading" : ""}`,
    click: handleAddTeamMembers,
    loader: buttonLoading,
    disabled: !selectedMemberList?.length
  };

  const getSelectedUser = async (value, id) => {
    const newArr = [...updatedTeamMembers];
    const filteredTeamMembers = activeTeamMembers
      .filter(item => item?.checked)
      .map(item => item?.id);
    const idArr = [];
    if (updatedTeamMembers?.length) {
      updatedTeamMembers.map((item, index) => {
        if (item?.memberId === id) {
          item.checked = value;
          newArr[index] = item;
        }
        if (item?.checked) {
          idArr.push(item?.memberId);
        }
      });
      setSelectedMemberList([...idArr, ...filteredTeamMembers]);
      setUpdatedTeamMembers(newArr);
    }
  };

  const getUpdatedDom = (user = {}) => {
    return (
      <div className="teamDetails">
        <div className="flexColumn">
          <div className="teamMemberName">
            {Utils.getFullNameFromUserInfo(user)}
          </div>
          <div className="teamMemberJobTitle">{user?.jobTitle || "--"}</div>
        </div>
        <p className="teamMemberRole">{user?.role?.toLowerCase() || "--"}</p>
      </div>
    );
  };

  const initCalls = async () => {
    setLoading(true);
    let res = await Utils.getTeamMember();
    if (res?.data?.length) {
      setActiveTeamMembers(res?.data);
      setUpdatedTeamMembers(res?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    teamMemberState.showAddMemberPopup && initCalls();
    return () => {
      setLoading(true);
      setButtonLoading(false);
      setActiveTeamMembers([]);
      setUpdatedTeamMembers([]);
    };
  }, [teamMemberState.showAddMemberPopup]);

  return (
    teamMemberState.showAddMemberPopup && (
      <>
        <div className="addMembersPopup">
          <div className="card">
            <div className="popupHeader">
              <div className="title">Add Members</div>
              <i
                className="material-icons commonPopupCloseIcon"
                onClick={() => teamMemberState.setShowAddMemberPopup(false)}
              >
                close
              </i>
            </div>
            <hr className="seperator" />
            <div className="popupBody">
              {!loading ? (
                <>
                  <SearchInputBox
                    valueCbk={searchActiveUsers}
                    type="text"
                    placeholder="Search Active Users"
                    clearSearchInput={clearSearchInput}
                  />
                  <div className="scrollUserList">
                    <div className="multiSelectSection">
                      <div className="checkBoxDropdown">
                        {updatedTeamMembers?.length ? (
                          updatedTeamMembers.map(user => {
                            const {
                              email = "",
                              memberId = "",
                              checked = false
                            } = user || {};
                            return (
                              <CheckBox
                                key={email}
                                hasCustomDom={true}
                                dom={getUpdatedDom(user)}
                                id={memberId}
                                checked={checked}
                                cbk={getSelectedUser}
                              />
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <Button {...addMembersBtnProps} />
                </>
              ) : (
                <div className="progressingLoader">
                  <div className="loadingSpinner" />
                </div>
              )}
            </div>
          </div>
          <div
            className="common-popup-mask"
            onClick={() => teamMemberState.setShowAddMemberPopup(false)}
          />
        </div>
      </>
    )
  );
});

export { AddMembersPopup };
export default AddMembersPopup;
