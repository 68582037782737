import React from "react";
import { observer } from "mobx-react";
import ProgressBarSlide from "Components/FileAttachment/ProgressBar";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";

export const PerformTaskFileUploadingLoader = observer(() => {
  return fileAttachmentStore?.taskFileUploadingLoader ? (
    <div className="fileUploadingLoader">
      <div className="fileUploading">
        <span className="uplaodText">
          {`Uploading Attachment${
            fileAttachmentStore?.taskTotalFileUploading > 1 ? "s" : ""
          }`}
        </span>
        <span className="uploadCount">
          ({fileAttachmentStore?.taskTotalFileUploading || 1})
        </span>
        <ProgressBarSlide />
      </div>
    </div>
  ) : (
    <></>
  );
});

export default PerformTaskFileUploadingLoader;
