/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import ConstantObj from "Static/constant";
import Rocket from "Utils/LottieFiles/Rocket.json";

const totalSteps = ConstantObj.onBoardingTotalSteps;

const PopupHeader = props => {
  return (
    <div className="popupHeader">
      <div className="leftSection">
        <div className="headerText">
          The ultimate checklist to get your account ready:
        </div>
        <CompletedStaus {...props} />
      </div>
    </div>
  );
};

const CompletedStaus = props => {
  const { remainingCount } = props;
  const [completedPercentage, setCompletedPercentage] = useState(0);

  const getPercentageCompleted = () => {
    if (remainingCount != null && totalSteps) {
      setTimeout(() => {
        const completedCount = totalSteps - remainingCount;
        const percentageForOneStep = 100 / totalSteps;
        setCompletedPercentage(
          (completedCount * percentageForOneStep).toFixed(0)
        );
      }, 10);
    }
  };

  useEffect(() => {
    getPercentageCompleted();
  }, []);

  return (
    <div className="completedStatus">
      <div className="statusBar">
        <div className="progress">
          <span
            className="progress-value progressAnimationStrike"
            style={{ width: `${completedPercentage}%` }}
          ></span>
        </div>
      </div>
      <div className="statusCompletedText">
        {completedPercentage}% Completed
      </div>
    </div>
  );
};

const HeaderIcon = props => {
  const { width = "60", height = "79", style = { margin: "0 auto" } } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Rocket,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Lottie
      options={defaultOptions}
      width={width}
      height={height}
      style={style}
      speed={0.1}
    />
  );
};

export { PopupHeader, HeaderIcon };
export default PopupHeader;
