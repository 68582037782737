import React, { useState } from "react";
import Utils from "Utils/utils";
import { ReactComponent as SequenceIcon } from "Assets/svg/sequence.svg";
import { ReactComponent as ClickRateIcon } from "Assets/svg/clickRate.svg";
import { ReactComponent as ReplyRateIcon } from "Assets/svg/replyRate.svg";
import { ReactComponent as ClickRateWhiteIcon } from "Assets/svg/clickRateWhite.svg";
import { ReactComponent as ReplyRateWhiteIcon } from "Assets/svg/replyRateWhite.svg";
import CustomTooltip from "Components/common/CustomTooltip";

const EmailInsightsComponent = props => {
  const { emailInsight = {} } = props || {};
  const {
    sentCount = 0,
    openCount = 0,
    clickCount = 0,
    replyCount = 0
  } = emailInsight || {};

  const [showTooltip, setShowTooltip] = useState({});

  const insightData = [
    {
      icon: <SequenceIcon />,
      svg: true,
      name: "sendIcon",
      key: "sendIcon",
      value: sentCount || 0,
      tooltipText: "Sent Count"
    },
    {
      icon: "visibility",
      svg: false,
      name: "viewIcon",
      key: "viewIcon",
      value: openCount || 0,
      tooltipText: "Open Count"
    },
    {
      icon: clickCount > 0 ? <ClickRateWhiteIcon /> : <ClickRateIcon />,
      svg: true,
      name: "clickRateIcon",
      key: "clickRateIcon",
      value: clickCount || 0,
      tooltipText: "Click Count"
    },
    {
      icon: replyCount > 0 ? <ReplyRateWhiteIcon /> : <ReplyRateIcon />,
      svg: true,
      name: "replyRateIcon",
      key: "replyRateIcon",
      value: replyCount || 0,
      tooltipText: "Reply Count"
    }
  ];
  return !emailInsight || !Object.keys(emailInsight)?.length ? (
    ""
  ) : (
    <ul className="email-insight-stage">
      {insightData.map((item, index) => {
        const { icon, svg, name, key, value, tooltipText } = item || {};
        return (
          <li
            key={key}
            onMouseEnter={() => {
              setShowTooltip({ [name]: true });
            }}
            onMouseLeave={() => {
              setShowTooltip({ [name]: false });
            }}
          >
            {svg ? (
              <span
                className={`icon-tag ${name} ${
                  (value < 1 && "icon-tag-empty") || ""
                }`}
              >
                {icon}
              </span>
            ) : (
              <i
                className={`material-icons-outlined ${
                  (value < 1 && "icon-tag-empty") || ""
                }`}
              >
                {icon}
              </i>
            )}
            {value > 0 && (
              <div className="number-count" title={tooltipText}>
                {Utils.formatNumberToString(value)}
              </div>
            )}
            {showTooltip[key] && (
              <CustomTooltip text={`${tooltipText}: ${value}`} />
            )}
            <span className="line-dot" />
          </li>
        );
      })}
    </ul>
  );
};

export default EmailInsightsComponent;
