/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { addStagesPopupState } from "./AddStagesPopupState";
import Constant from "Static/constant";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import ContactAutoCompleteDropdown from "Components/PreviewPopup/ContactAutoCompleteDropdown";
import StagesType from "./StagesType";
import StageStep from "./StageStep";
import DatePicker from "./DatePicker";
import TaskTypeDropdown from "./TaskTypeDropdown";
import MemoizedStageTitle from "./StageTitle";
import StepSummary from "./StepSummary";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import InputBox from "Components/Register/InputBox";
import SequenceUtils from "Utils/SequenceUtils";
import ContactsSelectedCount from "./ContactsSelectedCount";
import NotesInput from "./NotesInput";
import userDetail from "Stores/userDetail";

const AddStagesPopup = observer(() => {
  const {
    btnText,
    currentStep,
    isEdit,
    stageType,
    variantStep,
    daysLater,
    defaultTask,
    notes: note,
    text: linkedInAutoText,
    daysFromStart,
    steps,
    pageSource,
    isTaskCreation,
    isFromTriggers,
    dueDateString,
    contactIds,
    allContactSelected,
    email,
    taskId,
    subject
  } = addStagesPopupState.popupValues || {};

  const [loader, setLoader] = useState(false);
  const [days, setDays] = useState(daysLater);
  const [daysErrorMsg, setDaysErrorMsg] = useState("");
  const [linkedInTaskType, setLinkedInTaskType] = useState(defaultTask);
  const [notes, setNotes] = useState(note);
  const [subjectText, setSubjectText] = useState(subject);
  const [isInputEdited, setIsInputEdited] = useState(false);
  const [date, setDate] = useState(dueDateString);
  const [emailAddress, setEmailAddress] = useState(email);
  const [emailErr, setEmailErr] = useState("");
  const [noteInputCursorPostion, setNoteInputCursorPostion] = useState(0);
  const [isNoteInputFocussed, setNoteInputFocussed] = useState(false);
  const [hasMsgEmptyErr, setHasMsgEmptyErr] = useState(false);
  const [subjectErr, setSubjectErr] = useState(false);

  const timeZone = Utils.getCurrentUserTimeZone();
  const history = useHistory();
  const isLinkedInType = ["linkedin", "linkedinauto"].includes(
    stageType?.toLowerCase()
  );
  const isLinkedInAutoStage = stageType?.toLowerCase() === "linkedinauto";
  const changeDaysLater = value => {
    value = value > 180 ? 180 : !value ? 0 : value;
    addStagesPopupState.setPopupValues({
      ...addStagesPopupState.popupValues,
      daysLater: parseInt(value),
      notes,
      defaultTask: linkedInTaskType,
      daysFromStart: calculateDaysFromStep(value)
    });
    setDays(value);
    !isNoteInputFocussed && setNoteInputFocussed(false);
    setIsInputEdited(true);
  };

  const calculateDaysFromStep = currentStepDaysLaterValue => {
    const { daysFromStart = 1, daysLater = 0 } = Utils.createObjWithoutRef(
      addStagesPopupState.popupValues || {}
    );
    const subtractedValue = parseInt(daysFromStart) - parseInt(daysLater);
    return parseInt(currentStepDaysLaterValue) + subtractedValue;
  };

  const labelMatchObj = {
    email: "Send email if no reply in",
    linkedinAuto: {
      LI_SEND_MESSAGE: "Send message if no reply in",
      LI_SEND_CONNECTION_REQUEST: "Send invite if no reply in"
    }
  };

  const getLabel = () => {
    return stageType === "email"
      ? labelMatchObj?.[stageType]
      : addStagesPopupState?.popupValues?.isFromTriggers
      ? "Interval between the trigger and when the task is due"
      : stageType?.toLowerCase() === "linkedinauto"
      ? labelMatchObj?.[stageType]?.[linkedInTaskType] ||
        "Create task if no reply in"
      : "Create task if no reply in";
  };

  const daysInput = {
    name: "daysLater",
    id: "daysLater",
    type: "number",
    labelClassName: "daysLater",
    value: days,
    changeAction: changeDaysLater,
    keyupAction: () => {},
    errMsg: daysErrorMsg,
    label: getLabel(),
    isPassword: false,
    placeholder: 0,
    focus: true,
    descText: days > 1 ? "days" : "day",
    minValue: 0,
    maxValue: 180
  };

  const handleLinkedinTaskChange = item => {
    const { subKey = "" } = item || {};
    setLinkedInTaskType(subKey);
    !isNoteInputFocussed && setNoteInputFocussed(false);
    addStagesPopupState.setPopupValues({
      ...addStagesPopupState.popupValues,
      linkedInTaskType: subKey
    });
    setIsInputEdited(true);
  };

  const changeNotes = (value = "", isToken = false) => {
    if (linkedInTaskType?.toUpperCase() === "LI_SEND_MESSAGE") {
      setHasMsgEmptyErr(value ? false : true);
    }
    setNoteInputFocussed(true);
    let tempNotes = value;
    if (isToken) {
      tempNotes =
        notes?.substr(0, noteInputCursorPostion) +
        value +
        notes?.substr(noteInputCursorPostion, notes?.length);
    }
    setNotes(tempNotes);
    addStagesPopupState.popupValues.inputVal.notes = tempNotes;
    addStagesPopupState.setPopupValues({
      ...addStagesPopupState.popupValues,
      notes: tempNotes,
      text: tempNotes
    });
    isToken && Utils.focusInputBox("taskNotes");
    setIsInputEdited(true);
  };

  const closePopup = value => {
    setLoader(false);
    setNoteInputFocussed(false);
    if (value) {
      return;
    }
    addStagesPopupState.setShowPopup(false);
    addStagesPopupState.setResetValue();
    setDays(+currentStep <= 1 ? 0 : 1);
    setLinkedInTaskType(
      stageType?.toLowerCase() === "linkedinauto"
        ? addStagesPopupState.popupValues.defaultLiAutomaticTask
        : addStagesPopupState.popupValues.defaultTask
    );
    setNotes("");
    setSubjectText("");
    setDate(new Date());
    setEmailErr("");
  };

  const triggerCloseCbk = () => {
    addStagesPopupState.popupValues.triggerCbk("cancel", {}, () => {});
    closePopup();
  };

  const conformClosePopup = () => {
    if (isInputEdited) {
      confirmationPopupState.setPopupValues({
        title: "Are you sure",
        content:
          "Are you sure you want to close this popup ?<br>  Any changes made will be lost.",
        actionBtnText: "Yes",
        callback: isFromTriggers ? triggerCloseCbk : closePopup
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      isFromTriggers ? triggerCloseCbk() : closePopup();
    }
  };

  const getUniqueId = (greatestUniqueId, stageLen) => {
    return greatestUniqueId
      ? (greatestUniqueId + 1)?.toString()
      : (stageLen + 1)?.toString();
  };

  const constructPayloadAndMakeAPICall = (
    dataObject,
    emailEditorCbk = false
  ) => {
    const {
      steps = {},
      id = "",
      greatestUniqueId: tempGreatestUniqueId = 0
    } = addStagesPopupState.selectedSequence;

    const greatestUniqueId =
      tempGreatestUniqueId ||
      (steps &&
        Object.keys(steps).length &&
        Math.max.apply(
          Math,
          Utils.convertObjectToArray(steps).map(function (item) {
            return item.uniqueId;
          })
        )) ||
      0;

    if ((+currentStep <= 1 || days >= 0) && id) {
      const updatedSteps = {
        ...steps,
        ...dataObject
      };
      const uniqueId = isEdit
        ? steps?.[currentStep]?.uniqueId
        : getUniqueId(
            tempGreatestUniqueId,
            steps ? Object.keys(steps).length : 0
          );
      updatedSteps[currentStep] = {
        ...updatedSteps[currentStep],
        uniqueId
      };
      const data = {
        steps: updatedSteps,
        id,
        greatestUniqueId: isEdit ? greatestUniqueId : greatestUniqueId + 1,
        firstStageIsReply: Utils.getFirstEmailStatus(updatedSteps)
      };
      const closePopupCbk = () => {
        setLoader(false);
        closePopup();
        emailEditorCbk && emailEditorCbk();
      };
      !isTaskCreation &&
        !isFromTriggers &&
        addStagesPopupState.isStageIntervalUpdated();
      addStagesPopupState.createUpdateStep(data, closePopupCbk, "PUT");
    } else if (+currentStep > 1 && days < 1) {
      setDaysErrorMsg("Pleae enter a days");
      setTimeout(() => {
        setDaysErrorMsg("");
      }, 1500);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const updateEmailSteps = () => {
    const tempSteps = { ...steps };
    if (+currentStep > 1) {
      tempSteps[currentStep].daysLater = days;
    }
    Object.keys(tempSteps?.[currentStep]?.["variants"]).map(key => {
      tempSteps[currentStep]["variants"][key].daysLater = days;

      if (stageType === "manualEmail") {
        tempSteps[currentStep]["variants"][key].notes = notes;
      }
    });
    return tempSteps;
  };

  const isValidEmail = () => {
    let strArr = emailAddress?.split("@") || [];
    if (emailErr && emailErr?.length > 0) {
      return false;
    } else if (
      strArr?.length > 1 &&
      userDetail?.blockedDomainList?.includes(strArr?.[1])
    ) {
      setEmailErr("The entered domain is blocked");
      return false;
    } else if (isTaskCreation && pageSource?.toLowerCase() === "tasks") {
      if (Utils.emailValidate(emailAddress?.trim()?.toLowerCase() || "")) {
        emailErr && setEmailErr("");
        return true;
      } else {
        setEmailErr("Please enter a valid email address");
      }
      return false;
    }
    return true;
  };

  const handleCreateEmailStep = () => {
    if (!isEdit || (isEdit && (isTaskCreation || isFromTriggers))) {
      const inputVal = {
        ...addStagesPopupState.popupValues.inputVal,
        daysLater: isFromTriggers ? days || 0 : +currentStep <= 1 ? 0 : days,
        notes,
        stageNumber: currentStep,
        timeZone,
        replyAsThread: +currentStep <= 1 ? false : true
      };
      if (isTaskCreation) {
        if (!isValidEmail()) {
          setLoader(false);
          return;
        }
        addStagesPopupState.setPopupValues({
          ...addStagesPopupState.popupValues,
          inputVal,
          dueDateString: date,
          email: emailAddress
        });
      } else {
        addStagesPopupState.setPopupValues({
          ...addStagesPopupState.popupValues,
          inputVal,
          daysLater: isFromTriggers ? days || 0 : +currentStep <= 1 ? 0 : days,
          createCbk: constructPayloadAndMakeAPICall
        });
      }
      addStagesPopupState.setShowPopup(false);
      addStagesPopupState.setShowStepEmailEditorPopup(true);
      setLoader(false);
    } else {
      const newStepObj = updateEmailSteps();
      constructPayloadAndMakeAPICall(newStepObj);
    }
  };

  const createTaskStep = () => {
    const newSteps = { ...steps };

    const {
      variants: currentVariants = {},
      greatestVariantUniqueId: tempGreatestVariantUniqueId = 0
    } = newSteps[currentStep] || {};

    const greatestVariantUniqueId = SequenceUtils.getGreatestVariantUniqueId(
      tempGreatestVariantUniqueId,
      currentVariants
    );
    const newStepObj = {
      [currentStep]: {
        greatestVariantUniqueId: isEdit
          ? greatestVariantUniqueId
          : greatestVariantUniqueId + 1,
        variants: {
          [variantStep]: {
            notes,
            text: isLinkedInAutoStage ? linkedInAutoText : notes,
            subject: subjectText,
            timeZone,
            daysLater: +currentStep <= 1 ? 0 : days,
            type: stageType,
            status: "ACTIVE",
            uniqueId: isEdit
              ? variantStep
              : SequenceUtils.getUniqueVariantId(
                  greatestVariantUniqueId,
                  currentVariants?.length || 0
                )
          }
        },
        daysLater: +currentStep <= 1 ? 0 : days
      }
    };

    if (["linkedin", "linkedinauto"].includes(stageType?.toLowerCase())) {
      newStepObj[currentStep]["variants"][variantStep].linkedInTaskType =
        linkedInTaskType;
    }
    constructPayloadAndMakeAPICall(newStepObj);
  };

  const constructTriggerTaskPayload = () => {
    const triggerTaskPayload = {
      timeZone,
      daysLater: days,
      type: stageType
    };
    if (isLinkedInAutoStage) {
      triggerTaskPayload.text = notes;
      triggerTaskPayload.linkedInTaskType =
        linkedInTaskType ||
        addStagesPopupState.popupValues.defaultLiAutomaticTask;
    } else {
      triggerTaskPayload.note = notes;
    }
    if (["linkedin"].includes(stageType?.toLowerCase())) {
      triggerTaskPayload.linkedInTaskType =
        linkedInTaskType || addStagesPopupState.popupValues.defaultTask;
    }
    addStagesPopupState.popupValues.triggerCbk(
      "save",
      {
        ...triggerTaskPayload
      },
      closePopup
    );
  };

  const validateTokenVariable = () => {
    const hasInvalidTokens =
      notes && SequenceUtils.getInvalidTokensPrompt(notes);
    if (hasInvalidTokens) {
      setLoader(false);
      return false;
    }
    return true;
  };

  const triggersAndTaskAction = () => {
    if (isFromTriggers) {
      constructTriggerTaskPayload();
    } else if (isTaskCreation) {
      if (!isValidEmail()) {
        setLoader(false);
        return;
      }
      const taskObject = {
        timeZone,
        dueDateString: date,
        type: stageType,
        email: emailAddress,
        taskId
      };
      if (isLinkedInAutoStage) {
        taskObject.text = notes;
      } else {
        taskObject.note = notes;
      }
      if (["linkedin", "linkedinauto"].includes(stageType?.toLowerCase())) {
        taskObject.linkedInTaskType =
          linkedInTaskType || addStagesPopupState.popupValues.defaultTask;
      }
      addStagesPopupState.popupValues.createCbk(taskObject, closePopup);
    }
  };

  const handleStepAction = () => {
    if (
      ["linkedinauto"].includes(stageType?.toLowerCase()) &&
      linkedInTaskType?.toUpperCase() === "LI_SEND_MESSAGE"
    ) {
      if (linkedInAutoText || notes) {
        setHasMsgEmptyErr(false);
      } else {
        setHasMsgEmptyErr(true);
        return;
      }
    } else if (
      ["linkedinauto"].includes(stageType?.toLowerCase()) &&
      linkedInTaskType?.toUpperCase() === "LI_SEND_INMAIL"
    ) {
      setSubjectErr(!subjectText);
    }
    !isNoteInputFocussed && setNoteInputFocussed(false);
    if (validateTokenVariable()) {
      setLoader(true);
      if (
        !["email", "manualEmail"].includes(stageType) &&
        (isFromTriggers || isTaskCreation)
      ) {
        triggersAndTaskAction();
      } else {
        stepActionObj[stageType]();
      }
    }
  };

  const stepActionObj = {
    email: handleCreateEmailStep,
    manualEmail: handleCreateEmailStep,
    linkedin: createTaskStep,
    customTask: createTaskStep,
    phone: createTaskStep,
    linkedinAuto: createTaskStep
  };

  const changeStepCbk = (type = "") => {
    let isTask = ["linkedin", "phone", "customtask", "linkedinauto"].includes(
      type?.toLowerCase()
    );
    !isNoteInputFocussed && setNoteInputFocussed(false);
    addStagesPopupState.setPopupValues({
      pageSource,
      btnText: isTask
        ? isTaskCreation || isFromTriggers
          ? "Create Task"
          : "Save"
        : "Next",
      currentStep,
      stageType: type,
      daysLater: isFromTriggers ? 1 : +currentStep <= 1 ? 0 : days
    });
  };

  const changeStepType = (type = "") => {
    if (type?.toLowerCase() === "linkedinauto") {
      if (SequenceUtils.isLinkedinAccountConnected()) {
        changeStepCbk(type);
      } else {
        Utils.showAddLinkedInAccountPopup(history);
      }
    } else {
      changeStepCbk(type);
    }
  };

  const handleDateChange = (pickedDate, id) => {
    setDate(new Date(pickedDate));
    !isNoteInputFocussed && setNoteInputFocussed(false);
    addStagesPopupState.setPopupValues({
      dueDateString: new Date(pickedDate)
    });
  };

  const updateStateOnChange = () => {
    const {
      daysLater = 0,
      linkedInTaskType = "email",
      notes = "",
      dueDateString = "",
      email = "",
      defaultTask = "",
      defaultLiAutomaticTask = "",
      stageType = "",
      subject = ""
    } = addStagesPopupState.popupValues || {};
    setDate(new Date(dueDateString));
    setDays(daysLater);
    const updatedDefaultTaskType = isLinkedInAutoStage
      ? defaultLiAutomaticTask
      : defaultTask;
    const tempTaskType = linkedInTaskType
      ? linkedInTaskType
      : updatedDefaultTaskType;
    setLinkedInTaskType(tempTaskType);
    setNotes(notes);
    setSubjectText(subject);
    setEmailAddress(email);
    setIsInputEdited(false);
  };

  const getSelectedContactObj = (contactObj = {}) => {
    if (contactObj?.email) {
      const { email = "", stage = "" } = contactObj || {};
      addStagesPopupState.setPopupValues({
        ...addStagesPopupState.popupValues,
        email
      });
      setEmailAddress(email);
      const stageMapObj = {
        bounced: "bounced",
        opted_out: "opted out"
      };
      if (["bounced", "opted_out"].includes(stage?.toLowerCase())) {
        setEmailErr(`Entered email is ${stageMapObj[stage?.toLowerCase()]}`);
      }
    }
  };

  const emailChange = value => {
    const email = value?.trim().toLowerCase() || "";
    setEmailAddress(email);
    isNoteInputFocussed && setNoteInputFocussed(false);
    addStagesPopupState.setPopupValues({
      ...addStagesPopupState.popupValues,
      email
    });
    emailErr && setEmailErr("");
  };

  const emailInputObj = {
    name: "taskEmailAddress",
    id: "taskEmailAddress",
    type: "email",
    value: emailAddress,
    changeAction: emailChange,
    errMsg: emailErr,
    label: "Email Address",
    placeholder: `eg: john@salesgear.io`
  };

  const buttonList = [
    {
      id: "closeAddStageBtn",
      name: "closeAddStageBtn",
      btnText: "Cancel",
      className: "closeAddStageBtn",
      type: "button",
      click: conformClosePopup
    },
    {
      id: "createStageBtn",
      name: "createStageBtn",
      btnText: btnText,
      loader: loader,
      className: `createStageBtn ${loader ? "loading" : ""}`,
      type: "button",
      click: handleStepAction
    }
  ];

  const getHasNotes = () => {
    let hasNotes = true;
    if (stageType?.toLowerCase() === "email") {
      hasNotes = false;
    } else if (
      (stageType?.toLowerCase() === "linkedin" || isLinkedInAutoStage) &&
      linkedInTaskType?.toUpperCase() === "LI_VIEW_PROFILE"
    ) {
      hasNotes = false;
    }
    return hasNotes;
  };

  const handleSubjectChange = e => {
    setSubjectText(e?.target?.value);
    addStagesPopupState.setPopupValues({
      ...addStagesPopupState.popupValues,
      subject: e?.target?.value || ""
    });
  };

  useEffect(() => {
    setHasMsgEmptyErr(false);
    if (linkedInTaskType?.toUpperCase() === "LI_VIEW_PROFILE") {
      setNotes("");
      addStagesPopupState.setPopupValues({
        ...addStagesPopupState.popupValues,
        text: "",
        notes: ""
      });
    }
    if (linkedInTaskType?.toUpperCase() !== "LI_SEND_INMAIL") {
      setSubjectText("");
      setSubjectErr(false);
      addStagesPopupState.setPopupValues({
        ...addStagesPopupState.popupValues,
        subject: ""
      });
    }
  }, [linkedInTaskType]);

  useEffect(() => {
    updateStateOnChange();
  }, [addStagesPopupState?.popupValues]);

  return (
    (addStagesPopupState.showPopup && (
      <div className={`addStagesPopup`}>
        <div
          className={`addStagesPopupCard animated customzoomIn ${
            loader ? "disabledUserAction" : ""
          }`}
        >
          <div className="stepIconAction">
            <StageStep popupValues={addStagesPopupState?.popupValues} />
          </div>
          <div className="stageContentBody">
            <StagesType
              popupValues={addStagesPopupState?.popupValues}
              changeStepType={changeStepType}
            />
            {isTaskCreation && (
              <div
                className={`wrapInputTaskBox ${isEdit ? "disableEdit" : ""}`}
              >
                {pageSource?.toLowerCase() === "tasks" && (
                  <div className="inputBoxAndAutoCompleteDropDown">
                    <InputBox {...emailInputObj} />
                    <ContactAutoCompleteDropdown
                      inputText={emailAddress}
                      selectedCallbk={getSelectedContactObj}
                    />
                  </div>
                )}
                <DatePicker
                  label="Due On"
                  date={dueDateString ? new Date(dueDateString) : new Date()}
                  handleChange={handleDateChange}
                />
              </div>
            )}

            {(!isTaskCreation || isLinkedInType) && (
              <div className="wrapInputBox">
                {isLinkedInType && (
                  <TaskTypeDropdown
                    handleChange={handleLinkedinTaskChange}
                    options={
                      isLinkedInAutoStage
                        ? Constant.liAutomationObj
                        : Constant.linkedinInSubmenu
                    }
                    defaultValue={linkedInTaskType}
                    label="Task Type"
                    id="linkedinTaskType"
                    stageType={stageType}
                    labelClassName={"linkedinTaskType"}
                  />
                )}

                {!isTaskCreation && +currentStep > 1 && (
                  <InputBox {...daysInput} />
                )}
              </div>
            )}
            {isFromTriggers && (
              <div
                className={`wrapInputBox ${
                  ["linkedin", "linkedinauto"].includes(
                    stageType?.toLowerCase()
                  )
                    ? "wrapLinkedIn"
                    : ""
                }`}
              >
                <InputBox {...daysInput} />
              </div>
            )}
            {isLinkedInAutoStage &&
              linkedInTaskType?.toUpperCase() === "LI_SEND_INMAIL" && (
                <div
                  className={`expandLabelInput ${
                    subjectText?.length ? "filled" : ""
                  }`}
                >
                  <input
                    type="text"
                    id={"li_send_inMail"}
                    className={`inputBox`}
                    name="subject"
                    value={subjectText}
                    onChange={handleSubjectChange}
                    autoComplete="off"
                    required
                  />
                  <label
                    htmlFor="subject"
                    className={`inputLabel li_send_inMail`}
                  >
                    Subject<sup>*</sup>
                  </label>
                  {subjectErr && (
                    <span className="errMsgText">
                      {"Please enter a subject"}
                    </span>
                  )}
                </div>
              )}
            {getHasNotes() && (
              <NotesInput
                notes={isLinkedInAutoStage ? linkedInAutoText : notes}
                stageType={stageType}
                action={changeNotes}
                pageType={pageSource}
                setCursorPostion={setNoteInputCursorPostion}
                labelText={isLinkedInAutoStage ? "Message" : "Notes"}
                errorMsg={hasMsgEmptyErr}
              />
            )}
            {isTaskCreation && (
              <ContactsSelectedCount
                count={contactIds?.length}
                type="contacts"
                allSelected={allContactSelected}
              />
            )}
            {!(isFromTriggers || isTaskCreation) && (
              <StepSummary
                stageType={stageType}
                days={days}
                currentStep={currentStep}
                daysFromStart={daysFromStart > 0 ? daysFromStart : 1}
                linkedInTaskType={linkedInTaskType}
              />
            )}
          </div>
          <div className="footerAction">
            {buttonList.map(item => (
              <Button {...item} key={item.id} />
            ))}
          </div>
        </div>
        <div className="overlayStagesPopup" />
      </div>
    )) ||
    ""
  );
});

export { AddStagesPopup };
export default AddStagesPopup;
