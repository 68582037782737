import React, { useState } from "react";
import YoutubeTutorial from "Components/common/YoutubeTutorial";
import ConfigureButton from "../ConfigureButton";
import InstallButton from "../InstallButton";

const HubspotAction = props => {
  const {
    hubspotConnected = true,
    data: { youtubeLink = "" },
    toolTipPopup,
    hubspotConfigureCbk = () => {},
    isFeatureEnabled = false
  } = props;
  const [showVideo, setVideo] = useState(false);

  const closeVideo = () => {
    setVideo(false);
  };

  return !isFeatureEnabled ? (
    <InstallButton {...props} />
  ) : hubspotConnected ? (
    <div className="buttonCont wrapConnectedBtn">
      <div
        className={`connectedBtnCont btn ${isFeatureEnabled ? "" : "disabled"}`}
        onClick={hubspotConfigureCbk}
      >
        <i className="material-icons-outlined">settings</i>
        Configure
      </div>
      {showVideo && (
        <YoutubeTutorial videoId={youtubeLink} closeCbk={closeVideo} />
      )}
    </div>
  ) : (
    <div className="buttonCont">
      <ConfigureButton
        toolTipPopup={toolTipPopup}
        value={"Configure"}
        configureCbk={hubspotConfigureCbk}
        isFeatureEnabled={isFeatureEnabled}
      />
    </div>
  );
};

export default HubspotAction;
export { HubspotAction };
