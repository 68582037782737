import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { Button } from "Components/common/Button";
import { teamState } from "./state";
import InputTextBox from "Components/common/InputTextBox";
import Utils from "Utils/utils";

class CreateTeamPopupState {
  @observable showPopup = false;
  @observable teamName = "";
  @observable buttonLoader = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setTeamName = val => {
    this.teamName = val;
  };

  @action
  setButtonLoader = val => {
    this.buttonLoader = val;
  };
}

const createTeamPopupState = new CreateTeamPopupState();

const CreateTeamPopup = () => {
  const inputBoxRef = useRef();

  const handleTeamNameChange = (id, val) => {
    teamState.teamNameError && teamState.setTeamNameError("");
    createTeamPopupState.setTeamName(val);
  };

  const handleCreateTeamAction = async () => {
    if (createTeamPopupState?.teamName?.length > 0) {
      teamState.setSingleLineLoader(true);
      createTeamPopupState.setButtonLoader(true);
      await teamState.createTeam();
    } else {
      teamState.setTeamNameError("Please enter the team name");
    }
  };

  const handleCreateTeamSubmit = event => {
    Utils.preventDefaultFn(event);
    if (event?.keyCode === 13) {
      handleCreateTeamAction();
    }
  };

  const close = () => {
    teamState.setTeamNameError("");
    createTeamPopupState.setTeamName("");
    createTeamPopupState.setShowPopup(false);
  };

  useEffect(() => {
    if (createTeamPopupState?.showPopup) {
      inputBoxRef && inputBoxRef.current && inputBoxRef.current.focus();
    }
  }, [createTeamPopupState?.showPopup]);

  return (
    createTeamPopupState?.showPopup && (
      <section className="createTeamPopupWrapper">
        <div className="card">
          <div className="popupHeader">
            <div className="title">Create Team</div>
            <i className="material-icons commonPopupCloseIcon" onClick={close}>
              close
            </i>
          </div>
          <hr className="seperator" />
          <InputTextBox
            name="createTeamInputBox"
            id="createTeamInputBox"
            text="Team Name"
            type="text"
            value={createTeamPopupState?.teamName}
            change={handleTeamNameChange}
            err={teamState.teamNameError}
            cbk={e => handleCreateTeamSubmit(e)}
            readOnly={false}
            inputBoxRef={inputBoxRef}
            className={`${
              createTeamPopupState?.teamName ? "activeBorder" : ""
            }`}
          />
          <Button
            id="createTeamButton"
            name="createTeamButton"
            btnText="Create Team"
            type="button"
            className={`createTeamButton ${
              createTeamPopupState?.buttonLoader ? "loading" : ""
            }`}
            loader={createTeamPopupState?.buttonLoader}
            click={e => {
              handleCreateTeamAction(e);
            }}
            disabled={!createTeamPopupState?.teamName}
          />
        </div>
        <div
          className="common-popup-mask"
          onClick={() => createTeamPopupState.setShowPopup(false)}
        />
      </section>
    )
  );
};

export { CreateTeamPopup, createTeamPopupState };
export default observer(CreateTeamPopup);
