import React, { useContext } from "react";
import { observer } from "mobx-react";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { AccountsNotesContext, editAccountNotesState } from "./Main";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";

const NotesHeaderAction = () => {
  const {
    state = {},
    setState = () => {},
    getAccountNotes = () => {}
  } = useContext(AccountsNotesContext);
  const deleteNotes = async () => {
    confirmationPopupState.setPopupValues({
      title: "Are you sure",
      content: "Are you sure you want to delete this Notes?",
      actionBtnText: "Yes",
      callback: deleteAction
    });
    confirmationPopupState.setShowPopup(true);
  };

  const delAccountItemFn = (id = "") => {
    return state?.accountNotesData?.forEach((item, index, array) => {
      if (item?.id === id) {
        array.splice(index, 1);
      }
    });
  };

  const deleteAction = async () => {
    try {
      const config = {
        url: URLS.delAccountNotes,
        method: "POST",
        data: {
          id: state?.editNotesItem?.id
        }
      };
      let response = await makeApi(config);
      if (+response?.data?.responseCode === 200) {
        toasterState.setToastMsg(
          "Account Notes deleted successfully",
          "success"
        );
        delAccountItemFn(state?.editNotesItem?.id);

        const updateTotalPage =
          state?.notesTotalPage * 15 > state?.accountNotesData?.length;

        updateTotalPage && getAccountNotes(state?.notesCurrentPageNo, false);

        setState({
          ...state,
          notesCurrentPageNo: state?.notesCurrentPageNo,
          accountNotesData: state?.accountNotesData,
          editNotesItem: {}
        });
        editAccountNotesState.setNotesNewUser(
          state?.accountNotesData?.length > 0 ? true : false
        );
      } else {
        toasterState.setToastMsg("Failed to delete account notes", "failure");
      }
    } catch (error) {}
  };

  const editNotes = () => {
    editAccountNotesState.setShowEdit(true);
    editAccountNotesState.setIsAddBtn(true);
    editAccountNotesState.setIsEdit(true);
  };

  const iconList = [
    {
      icon: {
        ele: '<i style="font-size: 15px" class="material-icons-outlined list-icon">delete</i>',
        style: { width: "12px", height: "12px" }
      },
      key: "Delete",
      method: deleteNotes
    },
    {
      icon: {
        ele: '<i style="font-size: 15px" class="material-icons-outlined list-icon">edit</i>',
        style: { width: "12px", height: "12px" }
      },
      key: "Edit",
      method: editNotes
    }
  ];

  return (
    <div className="NotesHeaderAction">
      {iconList?.map((item, key) => (
        <div className="editAccountActionIcon" onClick={item?.method} key={key}>
          <span dangerouslySetInnerHTML={{ __html: item.icon.ele }} />
        </div>
      ))}
    </div>
  );
};

export default observer(NotesHeaderAction);
