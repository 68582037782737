import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import SequenceTemplateData from "Stores/sequence/SequenceTemplateData";
import CreateNewSeqImg from "Assets/png/createNewSeq.png";
import SelectTemplateImg from "Assets/png/selectTemplate.png";
import GenerateAISeqImg from "Assets/png/generateAI.png";
import CreateSequence from "./CreateSequence";
import { seqTemplatePopupState } from "./SeqTemplatePopupState";
import PredefinedTemplates from "./PredefinedTemplates";
import TitleBadge from "./TitleBadge";
import TemplateSelectedStages from "./TemplateSelectedStages";
import ProgressBar from "./ProgressBar";
import GenerateAISequence from "./GenerateAISequence";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const SeqTemplateSection = props => {
  const {
    className = "",
    label = "",
    subLabel = "",
    img = "",
    children = <></>,
    showPanel = false,
    ...remainingProps
  } = props || {};

  return (
    <section className={className} {...remainingProps}>
      <div className={`${className}Item ${showPanel ? "showPanel" : ""}`}>
        <img src={img} alt={label} className="seqTemplateImg" />
        <p className={"seqTemplateLabel"}>{label}</p>
        <p className={"seqTemplateSubLabel"}>{subLabel}</p>
      </div>
      {children}
    </section>
  );
};

const SeqTemplateItem = observer(props => {
  const {
    closePopup = "",
    showUpgrade = "",
    keyVal = "",
    ...remainingProps
  } = props || {};
  const [showPanel, setShowPanel] = useState(false);
  const [sequenceName, setSequenceName] = useState("");
  const {
    inFreePlan = false,
    planName = "",
    writingAssistantEnabled = false
  } = userDetail.userFeatureFlag || {};

  const onMouseEnter = () => {
    setShowPanel(true);
  };

  const onMouseLeave = () => {
    setShowPanel(false);
  };

  const onClick = () => {
    if (keyVal === "generateAISequence" && !writingAssistantEnabled) {
      PaywallUtils.upgradeUtilsFunction(
        "AIWritingSeq",
        false,
        planName,
        inFreePlan
      );
    } else {
      seqTemplatePopupState.setAction(keyVal);
    }
  };

  return keyVal === "createNewSeq" ? (
    <SeqTemplateSection
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...remainingProps}
      key={keyVal}
      showPanel={showPanel || sequenceName}
    >
      <CreateSequence
        close={closePopup}
        showUpgrade={showUpgrade}
        setSequenceName={setSequenceName}
        sequenceName={sequenceName}
        className={showPanel || sequenceName ? "showPanel" : ""}
      />
    </SeqTemplateSection>
  ) : (
    <SeqTemplateSection onClick={onClick} {...remainingProps} />
  );
});

const SequenceTemplatePopup = observer(props => {
  const { seqNameCancelAction = () => {}, showUpgrade = () => {} } =
    props || {};

  const seqTemplateConfig = [
    {
      keyVal: "createNewSeq",
      label: "Create new",
      subLabel: "Create a new sequence from scratch",
      className: "createNewSeq",
      img: CreateNewSeqImg
    },
    {
      keyVal: "selectTemplates",
      label: "Select Templates",
      subLabel: `Start with Salesgear's Pre-built templates`,
      className: "selectTemplate",
      img: SelectTemplateImg
    },
    {
      keyVal: "generateAISequence",
      label: "Generate AI Sequence",
      subLabel: "Use AI to generate a sequence automatically",
      className: "generateAISequence",
      img: GenerateAISeqImg
    }
  ];

  const closePopup = async () => {
    seqNameCancelAction();
    seqTemplatePopupState.setAction("");
    SequenceTemplateData.setSeqTemmplateClicked(false);
    seqTemplatePopupState.setProgressBarState(1);
    seqTemplatePopupState.setShowLottie(false);
    seqTemplatePopupState.setGenerateAISeqValue({});
    seqTemplatePopupState.setHasDomainChange(false);
    await seqTemplatePopupState.getDomain();
  };

  const handleProgressBarClick = index => {
    if (index === 1) {
      SequenceTemplateData.setSeqTemmplateClicked(false);
    }
  };

  return seqTemplatePopupState?.action === "selectTemplates" ? (
    <div className="predefinedSequenceSection">
      <div className="card animated customzoomIn">
        {SequenceTemplateData.seqTemplateClicked ? (
          <>
            <i
              id="closeSequenceTemplatePopup"
              className="material-icons"
              onClick={() => closePopup()}
            >
              close
            </i>
            <TemplateSelectedStages
              close={closePopup}
              showUpgrade={showUpgrade}
            />
          </>
        ) : (
          <>
            <div className="sequenceTemplatePopupHeader">
              <TitleBadge title="Predefined Sequence" showDesc={true}>
                <span>Select from our predefined sequences and customize</span>
              </TitleBadge>
              <i
                id="closeSequenceTemplatePopup"
                className="material-icons"
                onClick={() => closePopup()}
              >
                close
              </i>
            </div>
            <ProgressBar cbk={handleProgressBarClick} />
            <PredefinedTemplates />
          </>
        )}
      </div>
      <div className="overlayMaskPopup"></div>
    </div>
  ) : seqTemplatePopupState?.action === "generateAISequence" ? (
    <div className="generateAISequenceSection">
      <div className="card animated customzoomIn">
        <GenerateAISequence closePopup={closePopup} />
      </div>
      <div className="overlayMaskPopup"></div>
    </div>
  ) : (
    <div
      className="sequenceTemplatePopupSection"
      id="sequencePredefinedTemplatePopup"
    >
      <div className="card animated customzoomIn">
        <div className="sequenceTemplatePopupHeader">
          <h2 className="createSequenceTitle">Create sequence</h2>
          <i
            id="closeSequenceTemplatePopup"
            className="material-icons"
            onClick={() => closePopup()}
          >
            close
          </i>
        </div>
        <div className="sequenceTemplatePopupContent">
          {seqTemplateConfig?.map(item => (
            <SeqTemplateItem
              {...item}
              key={item?.key}
              closePopup={closePopup}
              showUpgrade={showUpgrade}
            />
          ))}
        </div>
      </div>
      <div className="overlayMaskPopup"></div>
    </div>
  );
});

export { SequenceTemplatePopup };
export default withRouter(SequenceTemplatePopup);
