import React from "react";
import { ReactComponent as DownArrow } from "Assets/svg/donw-arrow.svg";
import DatePickerComponent from "Components/common/dateTimePicker";

export const DatePicker = ({
  date = "",
  handleChange = () => {},
  label = "Due On"
}) => {
  return (
    <div name="taskDatePicker" id="taskDatePicker" className="taskDatePicker">
      <label htmlFor="dueDateString">
        {label}
        <div className="wrapDatePickerInput">
          <DatePickerComponent
            getDateCbk={handleChange}
            id="dueDateString"
            value={date}
            prefilledDate={date}
          />
          <DownArrow />
        </div>
      </label>
    </div>
  );
};

export default DatePicker;
