import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import TemplateUtils from "Utils/TemplateUtils";
import { MP_EVENT } from "Static/constant";
import TemplateCard from "Components/TemplateCard/TemplateCardModule";
import { toasterState } from "Components/common/toaster";

const AddTemplatesInputDropdown = observer(props => {
  const history = useHistory();

  const {
    popupCloseAction,
    isTemplateInserted,
    templateDropDownAction,
    selectedTemplate,
    clearSelectedTemplate,
    showTemplateDropDown,
    editorInsertCounter,
    setEditorValue,
    inputValue,
    setInputValue,
    currentStage,
    setSelectedTemplate,
    setTemplateInserted,
    setAttachmentCbk,
    stageType = "",
    setEditorInsertCounter = () => {}
  } = props;

  const hideTemplateCard = () => {
    templateDropDownAction(false);
  };

  const createTemplate = (folderId = "all") => {
    templateDropDownAction(false);
    popupCloseAction();
    history.push(`/templates/${folderId}`);
  };

  const templateClickAction = item => {
    const {
      name: templateName = "",
      subject = "",
      content = "",
      attachments = []
    } = item || {};
    if (subject && content) {
      const linkCbk = () => {
        setEditorValue(content);
        const stateObj = { ...inputValue };
        stateObj.content = content;
        if (currentStage > 1) {
          stateObj.subject = !stateObj.replyAsThread
            ? subject
            : stateObj.subject;
        } else {
          stateObj.subject = subject;
        }
        if (attachments?.length) {
          stateObj.attachments = attachments;
          setAttachmentCbk(attachments);
        }
        Utils.mixpanelTrack(MP_EVENT.SQ_TP_INSERTED, {
          pageType: "Sequence",
          templateName
        });
        Utils.mixpanelTrack(MP_EVENT.SQ_TP_UPDATED, {
          pageType: "Sequence",
          templateName
        });
        setInputValue(stateObj);
        setTemplateInserted(true);
        setSelectedTemplate(templateName);
        templateDropDownAction(false);
        setEditorInsertCounter(editorInsertCounter + 1);
      };
      editorInsertCounter > 1
        ? TemplateUtils.insertTemplateConfirmationPrompt(linkCbk)
        : linkCbk();
    } else {
      toasterState.setToastMsg(
        "Sorry, you cannot insert an empty template",
        "failure"
      );
    }
  };

  return (
    <div
      className={`templateCont ${isTemplateInserted ? "templateInserted" : ""}`}
    >
      <div className="inputLabel">Select Template</div>
      <input
        type="text"
        className="input-box"
        id="selectTemplateInput"
        readOnly
        onClick={e => !isTemplateInserted && templateDropDownAction(true, e)}
        value={selectedTemplate}
      />
      {isTemplateInserted && (
        <i
          className="material-icons sgCloseIcon"
          onClick={clearSelectedTemplate}
        >
          close
        </i>
      )}
      {showTemplateDropDown && (
        <TemplateCard
          insertTemplateCbk={templateClickAction}
          createTemplate={createTemplate}
          close={hideTemplateCard}
        />
      )}
    </div>
  );
});

export default AddTemplatesInputDropdown;
