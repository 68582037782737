import React, { memo } from "react";

export const CallsTeamSummaryLoader = ({ count = 0, stageActiveList = [] }) => {
  return (
    <div className="tableContainer teamSummaryLoader">
      <table>
        <thead>
          <tr>
            <th scope="row" />
            <th scope="col" />
            {[...stageActiveList].map(item => {
              const { id = "" } = item || {};
              return <th scope="col" key={id} />;
            })}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: count }, (value, index) => {
            return (
              <tr key={index}>
                <th scope="row">
                  <span className="linear-background" />
                </th>
                <td className="alignCenter">
                  <span className="linear-background" />
                </td>
                {[...stageActiveList].map(ele => {
                  const { id = "" } = ele || {};
                  return (
                    <td key={`${id}${index}`} className="alignCenter">
                      <span className="linear-background" />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const MemoizedCallsTeamSummaryLoader = memo(CallsTeamSummaryLoader);
export default CallsTeamSummaryLoader;
export { MemoizedCallsTeamSummaryLoader };
