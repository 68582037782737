import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImportNow } from "../common/ImportNow";
import FieldMapping from "./FieldMapping";
import AutoImport from "../common/AutoImport";
import AddTags from "../common/AddTags";
import { pipedriveStore } from "./PipedriveStore";
import UpdateContacts from "../common/UpdateContacts";
import ListView from "../common/ListView";

const Contacts = observer(props => {
  const { contact = {} } = props;
  const [dropdownWidth, setDropdownWidth] = useState("25%");

  const findWidthOfDropdown = () => {
    return (
      document.querySelector(".crmListView .filterDropdownWrap")?.offsetWidth ||
      "270"
    );
  };

  useEffect(() => {
    pipedriveStore.getContactsListView();
    setDropdownWidth(findWidthOfDropdown());
  }, []);

  return (
    <div className="contactsWrapper">
      <div className="autoImportSettings">
        <ListView
          config={pipedriveStore?.pipedriveContactConfig}
          setConfig={pipedriveStore.setPipedriveContactConfig}
          list={pipedriveStore?.pipedriveContactsList}
          crmName="Pipedrive"
        />
        <AutoImport
          config={pipedriveStore.pipedriveContactConfig}
          setConfig={pipedriveStore.setPipedriveContactConfig}
          data={pipedriveStore?.pipedriveData}
          crmName="Pipedrive"
        />
        <AddTags
          dropdownWidth={dropdownWidth}
          config={pipedriveStore.pipedriveContactConfig}
          selectedTags={pipedriveStore.pipedriveContactTags}
          setSelectedTags={pipedriveStore.setPipedriveContactTags}
          type="CONTACTS"
        />
        <UpdateContacts
          config={pipedriveStore.pipedriveContactConfig}
          setConfig={pipedriveStore.setPipedriveContactConfig}
        />
        <ImportNow
          listId={pipedriveStore?.pipedriveContactConfig?.sourceId}
          type="CONTACTS"
          lastSynced={contact?.lastSynced}
          listChanged={pipedriveStore?.pipedriveContactConfig?.listChanged}
          crmName="Pipedrive"
        />
      </div>
      <div className="fieldMapping">
        <FieldMapping
          config={pipedriveStore.pipedriveContactConfig}
          setConfig={pipedriveStore.setPipedriveContactConfig}
          mappingConfig={contact?.fields}
          type="CONTACTS"
          fields={pipedriveStore?.pipedriveFields}
          crmName="Pipedrive"
        />
      </div>
    </div>
  );
});

export { Contacts };
export default Contacts;
