import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

class InsertAsHTMLState {
  @observable activeInsertHTML = false;

  @action
  setActiveInsertHTML = val => {
    this.activeInsertHTML = val;
  };
}

const insertAsHTMLState = new InsertAsHTMLState();

const InsertAsHTML = observer(() => {
  return (
    <div
      className={`insertHTMLIcon ${
        insertAsHTMLState?.activeInsertHTML ? "active" : ""
      }`}
      title="Insert as HTML"
      onClick={() =>
        insertAsHTMLState?.setActiveInsertHTML(
          !insertAsHTMLState?.activeInsertHTML
        )
      }
    >
      <span className={`icon`}>{`< >`}</span>
    </div>
  );
});

export { InsertAsHTML, insertAsHTMLState };
export default InsertAsHTML;
