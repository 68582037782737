/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { dataSearchState } from "../state";
import Tooltip from "Components/common/Tooltip";
import { verifyBulkActionPerformedAndAllow } from "../DataSearchUtils";

const AddRemoveValue = observer(props => {
  const {
    defaultValue = [],
    store = [],
    id = "",
    setTableLoading = () => {}
  } = props || {};

  const [updatedDefaultValue, setUpdatedDefaultValue] = useState(defaultValue);

  const constructLocationModelPayload = updatedFilteredList => {
    const country = [];
    const state = [];
    const city = [];
    const temp = [...updatedFilteredList];
    temp?.length > 0 &&
      temp.map(item => {
        const key = Object.keys(item)[0];
        if (key === "state") {
          state.push(item?.[key]?.[0]);
        } else if (key === "country") {
          country.push(item?.[key]?.[0]);
        } else {
          city.push(item?.[key]?.[0]);
        }
      });
    const finalList = {
      country: country.filter(Boolean),
      state: state.filter(Boolean),
      city: city.filter(Boolean)
    };
    return finalList;
  };

  const constructKeywordModelPayload = (updatedFilteredList = []) => {
    const keywords = [];
    const temp = [...updatedFilteredList];
    temp?.length > 0 &&
      temp.map(item => {
        const key = Object.keys(item)?.[0];
        if (key === "keywords") {
          keywords.push(item?.[key]?.[0]);
        }
      });
    const finalList = {
      keywords: keywords.filter(Boolean)
    };
    return finalList;
  };

  const constructAutoCompletePayload = item => {
    const performAction = () => {
      let tempData = [...dataSearchState?.selectedFilteredList?.[id]];
      const updatedFilteredData = tempData.filter(filterData => {
        const updatedFilterData =
          id === "companyDomain"
            ? filterData?.name || filterData?.primaryDomain
            : filterData.name;
        return updatedFilterData !== item;
      });

      dataSearchState.setSelectedFilteredList({
        ...dataSearchState.selectedFilteredList,
        [id]: updatedFilteredData
      });

      const updatedFilteredList = updatedFilteredData
        ?.map(item => {
          if (item.checked) {
            return id === "companyDomain"
              ? item?.primaryDomain
              : { [item?.group]: item?.searchText };
          }
        })
        ?.filter(Boolean);

      if (id === "companyDomain") {
        dataSearchState.setFilterComponentData({
          ...dataSearchState.filterComponentData,
          [id]: updatedFilteredList
        });
      } else {
        const finalList =
          id?.toLowerCase() === "location"
            ? constructLocationModelPayload(updatedFilteredList)
            : constructKeywordModelPayload(updatedFilteredList);
        dataSearchState.setFilterComponentData({
          ...dataSearchState.filterComponentData,
          ...finalList
        });
      }
      setTableLoading(true);
      dataSearchState.setCurrentPage(1);
      dataSearchState.setIsFromSeoPage(false);
      dataSearchState.searchPageData();
    };
    if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
      verifyBulkActionPerformedAndAllow(performAction);
    } else {
      performAction();
    }
  };

  const onCloseIconClick = item => {
    const performAction = () => {
      if (
        ["location", "companydomain", "keywords"]?.includes(id?.toLowerCase())
      ) {
        constructAutoCompletePayload(item);
      } else {
        const updatedFilteredData = store?.filterComponentData?.[id].filter(
          filterData => filterData !== item
        );
        store.setFilterComponentData({
          ...store.filterComponentData,
          [id]: updatedFilteredData
        });
        setTableLoading(true);
        dataSearchState.setCurrentPage(1);
        dataSearchState.setIsFromSeoPage(false);
        dataSearchState.searchPageData();
      }
    };
    if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
      verifyBulkActionPerformedAndAllow(performAction);
    } else {
      performAction();
    }
  };

  const handleClearAll = () => {
    const performAction = () => {
      if (id?.toLowerCase() === "location") {
        dataSearchState.setSelectedFilteredList({
          ...dataSearchState.selectedFilteredList,
          [id]: []
        });
        dataSearchState.setFilterComponentData({
          ...dataSearchState.filterComponentData,
          country: [],
          state: [],
          city: []
        });
      } else if (id?.toLowerCase() === "keywords") {
        dataSearchState.setSelectedFilteredList({
          ...dataSearchState.selectedFilteredList,
          [id]: []
        });
        dataSearchState.setFilterComponentData({
          ...dataSearchState.filterComponentData,
          [id]: []
        });
      } else if (id === "companyDomain") {
        dataSearchState.setSelectedFilteredList({
          ...dataSearchState.selectedFilteredList,
          [id]: []
        });
        dataSearchState.setFilterComponentData({
          ...dataSearchState.filterComponentData,
          [id]: []
        });
      } else {
        dataSearchState.setFilterComponentData({
          ...dataSearchState.filterComponentData,
          [id]: []
        });
      }
      setTableLoading(true);
      dataSearchState.setCurrentPage(1);
      dataSearchState.setIsFromSeoPage(false);
      dataSearchState.searchPageData();
    };
    if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
      verifyBulkActionPerformedAndAllow(performAction);
    } else {
      performAction();
    }
  };

  useEffect(() => {
    if (["companydomain", "location", "keywords"].includes(id?.toLowerCase())) {
      if (defaultValue?.length > 0) {
        const updatedDefaultValue = defaultValue.map(item => {
          if (id === "companyDomain") {
            return item?.checked && (item?.name || item?.primaryDomain);
          } else {
            return item?.checked && item?.name;
          }
        });
        setUpdatedDefaultValue(updatedDefaultValue);
      } else {
        setUpdatedDefaultValue(defaultValue);
      }
    } else {
      setUpdatedDefaultValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    updatedDefaultValue?.length > 0 && (
      <section className="addRemoveValue">
        <span className="horizontalBorderLine" />
        <div className="flexCenterWithJustifyContent">
          <div className="addRemoveValueWrapper">
            {updatedDefaultValue?.map(item => {
              return (
                <div className="tagItem" key={item}>
                  <span className="selectedTag">{item}</span>
                  <i
                    className="material-icons selectedTagCloseIcon"
                    onClick={() => onCloseIconClick(item)}
                  >
                    close
                  </i>
                </div>
              );
            })}
          </div>
          <div className="clearAll">
            <Tooltip tooltip="Clear all" placement="left">
              <i
                className="material-icons clearAllIcon"
                onClick={handleClearAll}
              >
                clear_all
              </i>
            </Tooltip>
          </div>
        </div>
      </section>
    )
  );
});

export { AddRemoveValue };
export default AddRemoveValue;
