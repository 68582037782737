import React from "react";
import {
  Switch,
  Redirect,
  Route,
  withRouter,
  useRouteMatch
} from "react-router-dom";
import ReportsActivity from "Pages/Dashboard/reports_v2/activity/ReportsActivity";
import ReportsSequence from "Pages/Dashboard/reports_v2/sequence/ReportsSequence";

const ReportsRouting = () => {
  let { path = "" } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/activity`} component={ReportsActivity} />
      <Route exact path={`${path}/sequence`} component={ReportsSequence} />
      <Redirect from={path} to={`${path}/activity`} />
    </Switch>
  );
};

export default withRouter(ReportsRouting);
