import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { callsStore } from "Stores/Calls";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import PaginationComponent from "Components/common/PaginationComponent";
import CallsUtils from "Utils/CallsUtils";
import { userDetail } from "Stores/userDetail";
import dialerStoreData from "Stores/DialerData";
import MemoizedCallsOverviewLoader from "./CallsOverviewLoader";
import EmptyReport from "Pages/Dashboard/reports/EmptyReport";
import NoActivityFound from "Pages/Dashboard/reports/NoActivityFound";
import { callSummaryHeader } from "./CallsConstant";
import FilterByCardTab from "./FilterByCardTab";
import DropDownMenu from "Components/common/DropDownMenu";
import { dialerLogsPopupState } from "Components/DailerService/DialerLogsAfterCallEndPopup";
import { exportProcessingState } from "Components/popup/ExportProcessing";
import { exportEmailNotifyState } from "Components/popup/ExportEmailNotify";
import { toasterState } from "Components/common/toaster";
import globalCallState from "Stores/GlobalCallState";
import ContactCard from "Components/ContactCard/ContactCard";
import CheckBox from "Components/common/CheckBox";
import InsertBulkFilterOptions from "./InsertBulkFilterOptions";
import { ReactComponent as LogCallIcon } from "Assets/svg/callLog.svg";

const CallsOverview = observer(() => {
  let contactAdded = userDetail?.userFeatureFlag?.noOfContactsAddedInTeam || 0;
  let location = useLocation();
  const contactCardRef = useRef();
  const { pluginInstalled = false, installNowPluginClicked = false } =
    userDetail || {};

  const [data, setData] = useState(callsStore.inputValues);
  const [showContactCard, setShowContactCard] = useState(false);
  const [selectedContact, setSelecedContact] = useState({});
  const [slideAnim, setSlideAnim] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedLogIds, setSelectedLogIds] = useState([]);
  const [allLogsSelected, setAllLogsSelected] = useState(false);

  const enableAutoplay = audio => {
    audio.autoplay = true;
    audio.load();
  };

  const disableAutoplay = audio => {
    audio.autoplay = false;
    audio.load();
  };

  const toggleAudioPlayer = (index = "", value = false) => {
    if (data?.overviewData?.calls) {
      const tempData = [...data?.overviewData?.calls];
      tempData[index].showAudioPlayer = value;
      const player = document.getElementById(`audioControlId${index + 1}`);
      if (value) {
        player && enableAutoplay(player);
      } else {
        player && disableAutoplay(player);
      }
      const obj = { ...(data?.overviewData || {}), calls: tempData };
      setData({ ...data, overviewData: obj });
    }
  };

  const filterAction = (value = "", callDispositionId = "") => {
    let objValue = {
      ...data,
      ...{
        currentTab: value,
        callDispositionId,
        overviewLoader: true,
        filterLoader: true
      }
    };
    setData(objValue);
    const activeTab = value?.toUpperCase() === "TOTAL" ? "" : value;
    callsStore.setInputValues(objValue);
    callsStore.setFilterPayload({
      callDispositionId: activeTab ? callDispositionId : null,
      pageNo: 1
    });
    const newObj = {
      ...callsStore.filterPayload,
      pageNo: 1,
      callDispositionId: activeTab ? callDispositionId : null
    };
    callsStore.setResetCheckbox(true);
    callsStore.getCallsOutcomeFilterData(CallsUtils.getPayload(newObj, true));
    callsStore.getCallsOverviewData(CallsUtils.getPayload(newObj));
  };

  const hasTableData = () => {
    return (
      !data.overviewLoader && contactAdded && data?.overviewData?.calls?.length
    );
  };

  const paginate = (pageNo = {}) => {
    let tempData = {
      ...data,
      ...{ overviewLoader: true, filterLoader: true }
    };
    setData(tempData);
    callsStore.setFilterPayload({
      pageNo: pageNo?.selected + 1
    });
    const newObj = {
      ...callsStore.filterPayload,
      pageNo: pageNo?.selected + 1
    };
    callsStore.setResetCheckbox(true);
    callsStore.getCallsOutcomeFilterData(CallsUtils.getPayload(newObj, true));
    callsStore.getCallsOverviewData(CallsUtils.getPayload(newObj));
  };

  const editCallLog = (contact = {}) => {
    if (data?.overviewData?.calls) {
      const tempData = [...data?.overviewData?.calls];
      tempData.map(ele => (ele.showDropdown = false));
      const obj = { ...(data?.overviewData || {}), calls: tempData };
      setData({ ...data, overviewData: obj });
    }
    dialerLogsPopupState.setShowEditCallLogs(true);
    dialerLogsPopupState.setDialerLogSettings({
      phoneNumber: contact?.toNumber || "",
      ...contact
    });
    globalCallState.setShowCallSlider(true);
  };

  const showDropDownMenu = (index, value = false) => {
    if (data?.overviewData?.calls) {
      const tempData = [...data?.overviewData?.calls];
      tempData[index].showDropdown = !value;
      const obj = { ...(data?.overviewData || {}), calls: tempData };
      setData({ ...data, overviewData: obj });
    }
  };

  const dropdownOptions = [
    {
      icon: {
        ele: <LogCallIcon />,
        style: { width: "14px", height: "14px" }
      },
      value: "Log Call",
      key: "Call", // This key used in DROPDOWN component
      method: editCallLog
    }
  ];

  const showNoOutcomeAction = (memberId = "") => {
    return (
      data?.currentTab === "NOT_LOGGED" &&
      (Utils.isAdmin() || memberId === userDetail?.userInfo?.memberId)
    );
  };

  const getContactDetails = (contact = {}) => {
    let { contactId = "" } = contact || {};
    if (contactId) {
      setSlideAnim(true);
      setShowContactCard(true);
      setSelecedContact({ contactId });
    }
  };

  const closeContactCard = () => {
    if (globalCallState.popupValues?.isContactCard) {
      globalCallState.setPopupValues({
        ...globalCallState.popupValues,
        isContactCard: false
      });
    }
    setSlideAnim(false);
    setTimeout(() => {
      setShowContactCard(false);
    }, 300);
  };

  const getColumnWidthBasedOnCurrentTab = (width = 0) => {
    if (data?.currentTab === "NOT_LOGGED" && width > 16) {
      // currrent tab NO_OUTCOME => Reduce 2% to enable action column
      let wt = `${width - 2}%`;
      if (width > 20) {
        // currrent tab NO_OUTCOME => checkbox by reduce width32px
        return `calc(${wt} - 32px)`;
      }
      return wt;
    }
    return `${width}%`;
  };

  const handleSelectRecord = (type = "", value = false) => {
    let tempData = { ...data } || {};
    let totalLen = tempData?.overviewData?.calls?.length || 0;
    let count = 0;
    if (tempData?.overviewData?.calls?.length > 0) {
      tempData.overviewData.calls.map(item => {
        if (type?.toLowerCase() === "all") {
          item.isSelected = value;
        } else if (item?.id === type) {
          item.isSelected = value;
        }
        if (item?.isSelected) {
          count += 1;
        }
        return item;
      });
    }
    setData({ ...data, ...tempData });
    return +totalLen === +count; // verifying all records are selected return boolen value
  };

  const getSelectedLogsId = () => {
    if (data?.overviewData.calls?.length > 0) {
      let tempList = [];
      let tempCallList = [...data?.overviewData.calls];
      tempCallList.filter(item => {
        if (item?.isSelected) {
          tempList.push(item?.id);
        }
      });
      setSelectedLogIds(tempList);
    }
  };

  const isNotOutcomeEnabled = () => {
    return (
      !data?.overviewLoader &&
      data?.overviewData?.calls?.length > 0 &&
      data?.currentTab === "NOT_LOGGED"
    );
  };

  const isCallLogsSelected = () => {
    if (data?.overviewData?.calls?.length > 0) {
      return data.overviewData.calls.some(item =>
        [true].includes(item?.isSelected)
      );
    }
    return false;
  };

  const handleCheckboxAction = (value = false, type = "", isReset = false) => {
    let isSelectAll = type?.toLowerCase() === "all" || false;
    if (isSelectAll) {
      handleSelectRecord("all", value);
      !isReset && setSelectAll(value);
    } else {
      let isAllRecordSelected = handleSelectRecord(type, value);
      setSelectAll(isAllRecordSelected);
    }
    !isReset && getSelectedLogsId();
  };

  const reloadCallsOverviewPage = () => {
    let tempData = {
      ...data,
      ...{ overviewLoader: true, filterLoader: true }
    };
    setData(tempData);
    callsStore.setFilterPayload({
      pageNo: 1
    });
    const newObj = {
      ...callsStore.filterPayload,
      pageNo: 1
    };
    callsStore.getCallsOutcomeFilterData(CallsUtils.getPayload(newObj, true));
    callsStore.getCallsOverviewData(CallsUtils.getPayload(newObj));
  };

  const resetSelectedCheckboxLogs = (reloadPage = false) => {
    setSelectAll(false);
    handleCheckboxAction(false, "all", true);
    setSelectedLogIds([]);
    setAllLogsSelected(false);
    reloadPage && reloadCallsOverviewPage();
  };

  const getExportPayload = () => {
    const {
      memberIds = [],
      fromDate = "",
      to = "",
      callDispositionId = "",
      timeZone = ""
    } = callsStore?.filterPayload || {};
    const { totalCalls = 0 } = data?.overviewData || {};
    return {
      filter: {
        all:
          !callDispositionId &&
          (!memberIds || memberIds?.length === 0) &&
          allLogsSelected,
        memberIds,
        fromDate,
        to,
        callDispositionId,
        timeZone,
        ids: !allLogsSelected ? selectedLogIds : []
      },
      totalCount: allLogsSelected ? totalCalls : selectedLogIds?.length || 0
    };
  };

  const clearSelectedLogs = () => {
    setSelectAll(false);
    handleCheckboxAction(false, "all", true);
    setSelectedLogIds([]);
    setAllLogsSelected(false);
  };

  const handleExportResponse = downloadLink => {
    if (!downloadLink) {
      exportProcessingState.setShowPopup(false);
      toasterState.setToastMsg(
        "Something went wrong. Please try again",
        "failure"
      );
    } else {
      exportProcessingState.completeAnimation();
      setTimeout(() => {
        Utils.downloadExportedContact(downloadLink);
        exportProcessingState.setShowPopup(false);
        clearSelectedLogs();
        toasterState.setToastMsg(
          "Call logs have been exported successfully.",
          "success"
        );
      }, 1000);
    }
  };

  const exportCbk = async () => {
    exportProcessingState.setShowPopup(true);
    let payload = getExportPayload() || {};
    const config = {
      url: URLS?.exportCallLogs,
      method: "POST",
      data: payload
    };
    const res = await makeApi(config);
    const { status = "", type = "", callData = [] } = res?.data || {};
    const typeToCompare = "limit_reached";
    if (+status === 200 && type?.toLowerCase() === typeToCompare) {
      exportProcessingState.completeAnimation();
      exportProcessingState.setShowPopup(false);
      exportEmailNotifyState.setShowText("call logs");
      exportEmailNotifyState.setShowPopup(true);
      clearSelectedLogs();
    } else if (+status === 200 && callData?.length > 0) {
      Utils.constructCallLogsExportToCsvData(callData, handleExportResponse);
    } else {
      clearSelectedLogs();
      exportProcessingState.setShowPopup(false);
      toasterState.setToastMsg(
        "Something went wrong. Please try again",
        "failure"
      );
    }
  };

  useEffect(() => {
    setData(callsStore.inputValues);
  }, [callsStore.inputValues]);

  useEffect(() => {
    return () => {
      callsStore.resetValue();
    };
  }, []);

  useEffect(() => {
    if (callsStore?.resetCheckbox) {
      resetSelectedCheckboxLogs();
      callsStore.setResetCheckbox(false);
    }
  }, [callsStore.resetCheckbox]);

  return (
    <div className="callsOverview">
      {showContactCard && (
        <div
          className={`contactCardSection ${
            slideAnim ? "slideInFromBottom" : "slideOutToBottom"
          } ${
            !pluginInstalled && !installNowPluginClicked
              ? "installPluginExtBannerShown"
              : ""
          }`}
          id="contactCard"
          ref={contactCardRef}
          key="contactCard"
        >
          <ContactCard
            cardType="calls"
            pageType="calls"
            contact={selectedContact}
            closeCbk={closeContactCard}
            isContactSlider={true}
            totalPages={data?.overviewData?.totalPages}
          />
        </div>
      )}
      <FilterByCardTab
        loader={data?.filterLoader || false}
        filterCbk={filterAction}
        filterStats={callsStore?.inputValues?.filterObject || {}}
        filterList={[
          {
            id: "total",
            name: "TOTAL",
            displayName: "Total",
            displayEnabled: true
          },
          ...(dialerStoreData?.stageList || [])
        ]}
        currentTab={data?.currentTab}
        pageType="calls"
      />
      <div className="callsTableCard">
        <InsertBulkFilterOptions
          loader={data?.filterLoader || data?.overviewLoader}
          selected={isCallLogsSelected()}
          data={data?.overviewData || {}}
          allLogsSelected={allLogsSelected}
          selectedLogIds={selectedLogIds}
          setAllLogsSelected={setAllLogsSelected}
          resetCbk={resetSelectedCheckboxLogs}
          selectAllCbk={() => handleCheckboxAction(true, "all")}
          exportCbk={exportCbk}
          bulkLogCallsEnabled={isNotOutcomeEnabled()}
        />
        <div
          className={`callsOverviewSection ${
            data?.overviewData?.totalPages > 1 ? "showPagination" : ""
          }`}
        >
          <div className="tableHeader">
            {data?.overviewLoader && data?.currentTab === "NOT_LOGGED" ? (
              <div className="checkboxBtn">
                <span className="linear-background" />
              </div>
            ) : (
              <div className="checkboxBtn">
                <CheckBox
                  key="selectMultipleCallLogsCheckbox"
                  id="all"
                  checked={selectAll}
                  cbk={handleCheckboxAction}
                  allContactsChecked={allLogsSelected}
                />
              </div>
            )}
            {callSummaryHeader.map(item => {
              const {
                name = "",
                className = "",
                id = "",
                width = "auto",
                textAlign = "left",
                showHeader = false
              } = item || {};
              return (
                (data?.currentTab === "NOT_LOGGED" || showHeader) && (
                  <div
                    className={className}
                    key={id}
                    style={{
                      width: getColumnWidthBasedOnCurrentTab(width),
                      textAlign
                    }}
                  >
                    {name}
                  </div>
                )
              );
            })}
          </div>
          <div
            className={`tableBody ${
              data?.overviewData?.totalPages > 1 ? "showPagination" : ""
            }`}
          >
            {(hasTableData() && (
              <>
                {data.overviewData.calls.map((item, index) => {
                  const {
                    id = "",
                    contactName = "",
                    createTs = "",
                    duration = "",
                    fromNumber = "",
                    memberName = "",
                    notes = "",
                    outcome = "",
                    recordingUrl = "",
                    toNumber = "",
                    showAudioPlayer = false,
                    showDropdown = false,
                    memberId = "",
                    isSelected = false
                  } = item || "";
                  return (
                    <div
                      className="tableRow"
                      key={`callsOverviewRecord${index}`}
                    >
                      <div className={`dataTableList`}>
                        <div className="checkboxBtn">
                          <CheckBox
                            key="singleSelectCallLogCheckbox"
                            id={id}
                            checked={isSelected}
                            cbk={handleCheckboxAction}
                          />
                        </div>
                        <div
                          className="name"
                          style={{ width: getColumnWidthBasedOnCurrentTab(18) }}
                        >
                          <p
                            className="callerName"
                            onClick={() => getContactDetails(item)}
                          >
                            {contactName}
                          </p>
                          {toNumber && (
                            <p className="phoneNumber">{toNumber}</p>
                          )}
                        </div>
                        <div
                          className="calledBy"
                          style={{ width: getColumnWidthBasedOnCurrentTab(18) }}
                        >
                          <p className="calledName">{memberName}</p>
                          <p className="phoneNumber">{fromNumber}</p>
                        </div>
                        <div
                          className="callOutcome"
                          style={{ width: getColumnWidthBasedOnCurrentTab(12) }}
                        >
                          {outcome}
                        </div>
                        <div
                          className="callNotes"
                          style={{ width: getColumnWidthBasedOnCurrentTab(22) }}
                        >
                          <div className="descText">{notes || "--"}</div>
                        </div>
                        <div
                          className="date"
                          style={{ width: getColumnWidthBasedOnCurrentTab(8) }}
                        >
                          {Utils.formatDate(createTs)}
                        </div>
                        <div
                          className="callDuration"
                          style={{
                            width: getColumnWidthBasedOnCurrentTab(12),
                            textAlign: "center"
                          }}
                        >
                          {CallsUtils.secondsToTime(duration)}
                        </div>
                        <div
                          className="recording"
                          style={{ width: getColumnWidthBasedOnCurrentTab(10) }}
                        >
                          {(recordingUrl && (
                            <span
                              className={`material-icons ${
                                showAudioPlayer ? "pause" : "play"
                              }`}
                              onClick={() =>
                                toggleAudioPlayer(
                                  index,
                                  showAudioPlayer ? false : true
                                )
                              }
                            >
                              {showAudioPlayer ? "close" : "play_arrow"}
                            </span>
                          )) ||
                            "--"}
                        </div>
                        {showNoOutcomeAction(memberId) && (
                          <div
                            className="actions"
                            style={{
                              width: getColumnWidthBasedOnCurrentTab(6),
                              textAlign: "center"
                            }}
                          >
                            <i
                              className="material-icons-outlined icon-hover-btn"
                              onClick={() =>
                                showDropDownMenu(index, showDropdown)
                              }
                              title="More Options"
                            >
                              more_vert
                            </i>
                            {showDropdown && (
                              <>
                                <DropDownMenu
                                  options={dropdownOptions}
                                  parentIndex={index}
                                  contact={item}
                                />
                                <div
                                  className="dropdownMask"
                                  onClick={() =>
                                    showDropDownMenu(index, showDropdown)
                                  }
                                />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      {(recordingUrl && (
                        <div
                          className="playRecordedAudio"
                          style={{
                            display: showAudioPlayer ? "flex" : "none"
                          }}
                        >
                          <audio
                            controls="controls"
                            id={`audioControlId${index + 1}`}
                          >
                            <source src={recordingUrl} type="audio/mpeg" />
                          </audio>
                          <span
                            className="hideAudio"
                            onClick={() => toggleAudioPlayer(index, false)}
                          >
                            close
                          </span>
                        </div>
                      )) ||
                        ""}
                    </div>
                  );
                })}
              </>
            )) ||
              ""}
            {!data?.overviewLoader && !contactAdded && (
              <EmptyReport pathname={location?.pathname} />
            )}
            {(!data?.overviewLoader &&
              contactAdded &&
              data?.overviewData?.calls?.length < 1 && <NoActivityFound />) ||
              ""}
            {data?.overviewLoader && (
              <MemoizedCallsOverviewLoader
                loader={data?.overviewLoader}
                count={10}
                currentTab={data.currentTab}
                getWidth={getColumnWidthBasedOnCurrentTab}
              />
            )}
          </div>
          {data?.overviewData?.totalPages > 1 && (
            <PaginationComponent
              callback={paginate}
              loading={data.overviewLoader}
              totalPages={data.overviewData.totalPages}
              currentPage={data.overviewData.currentPage || 1}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default CallsOverview;
export { CallsOverview };
