import React, { useContext } from "react";
import Utils from "Utils/utils";
import { RewriteAiContext } from "./RewriteAi";
import WritingAssistantUtils from "Utils/WritingAssistantUtils";
import { AI_SCORE_CONSTANT } from "Static/constant";

export const Score = (props = {}) => {
  const {
    showReadabilityScore = false,
    score = {},
    handleRewriteAIAction = () => {}
  } = useContext(RewriteAiContext);

  return (
    <div
      className={`scoreWrapper animatedFast ${
        showReadabilityScore ? "animatedDropDownReverse" : ""
      }`}
    >
      {[...AI_SCORE_CONSTANT].map(element => {
        let avgValue = WritingAssistantUtils.calculateScoreAverage(
          element,
          score
        );
        let detailedScoreObj = {};
        let scoreKeyList =
          (score && Object.keys(score)?.length > 0 && Object.keys(score)) || [];
        if (scoreKeyList?.length > 0) {
          scoreKeyList.map(item => {
            if (element?.nameList.includes(item?.toLowerCase())) {
              detailedScoreObj = score[item];
            }
          });
        }
        return (
          <div className="readabilityScoreWrapper" key={element?.id}>
            <div className="titleWrapper">
              <div className="scoreTitle">{element?.name}</div>
              <div
                className={`percent`}
                style={{
                  color: WritingAssistantUtils.calculateColor(
                    avgValue,
                    element?.id?.toLowerCase() === "spam_score"
                  )
                }}
              >
                {avgValue}
              </div>
            </div>
            {detailedScoreObj && Object.keys(detailedScoreObj)?.length > 0 && (
              <div className="contentWrapper">
                {Object.keys(detailedScoreObj).map(key => {
                  let tempKey = key
                    ?.toLowerCase()
                    ?.replace(/-/g, " ")
                    ?.replace(/ /g, "_");
                  if (element?.keyList?.includes(tempKey)) {
                    let value = detailedScoreObj?.[key] || 0;
                    let scorePercentage = value > 10 ? value / 10 : value;
                    return (
                      <div className="individualScore" key={tempKey}>
                        <p>{element?.keysObj?.[tempKey]}</p>
                        <p>
                          {scorePercentage
                            ? `${Math.ceil(scorePercentage.toFixed(1))} / 10`
                            : 0}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        );
      })}
      <div
        className="rewriteWithAI"
        onClick={e => {
          Utils.preventDefaultFn(e);
          handleRewriteAIAction();
        }}
      >
        Rewrite with AI
      </div>
    </div>
  );
};

export default Score;
