import React, { useState, useContext, createContext, useEffect } from "react";
import { observer } from "mobx-react";
import { calendarState } from "Pages/Dashboard/settings/Calendar/State";
import { SettingContext } from "Pages/Dashboard/settings/Settings";
import Button from "Components/common/Button";
import EventsTableList from "./EventsList/Main";
import EventsListLoader from "./EventsList/Components/EventsListLoader";
import CreateEventImg from "Assets/png/createEvent.png";
import Utils from "Utils/utils";
import { CalendarContext } from "Pages/Dashboard/settings/Calendar/Main";
import { toasterState } from "Components/common/toaster";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { confirmationPopupState } from "Components/common/confirmationPopup";

const CalendarEventsContext = createContext({});
const limit = 10;

const Main = observer(() => {
  const {
    isCalendarEnabled = () => {},
    isCalendarHasLimit = () => {},
    setCreateEvent = () => {}
  } = useContext(CalendarContext);
  const { updateBreadCrumb = () => {} } = useContext(SettingContext);

  const [eventsList, setEventsList] = useState("");
  const [loader, setLoader] = useState(true);

  const isActionAllowed = () => {
    return !loader && isCalendarEnabled() && isCalendarHasLimit();
  };

  const openCreateEventPage = () => {
    if (isActionAllowed()) {
      updateBreadCrumb("Calendar - Create Event");
      calendarState.setCurrentPage(eventsList?.currentPage || 1);
      Utils.mixpanelTrack(MXP_EVENT.ST_CLR_CREATE_EVENT_CLICKED, {
        pageType: `${PAGE_TYPE["setting"]} - Calender Events`
      });
      setCreateEvent(true);
    }
  };

  const editEvent = (event = {}) => {
    calendarState.setCurrentPage(eventsList?.currentPage || 1);
    calendarState.setEditEventInputValue(event);
    updateBreadCrumb("Calendar - Edit Event");
    setCreateEvent(true);
  };

  const cloneEvent = (event = {}) => {
    if (isActionAllowed()) {
      setLoader(true);
      calendarState.cloneCalendarEvent({ id: event?.id }, () =>
        setLoader(false)
      );
    }
  };

  const copyEventLink = (event = {}) => {
    Utils.copyToClipboard(event?.eventUrl);
    Utils.mixpanelTrack(MXP_EVENT.ST_CLR_EVENT_URL_COPIED, {
      pageType: `${PAGE_TYPE["setting"]} - Calender Events`
    });
    toasterState.setToastMsg("Event URL copied successfully.", "success");
  };

  const deleteEvent = (event = {}) => {
    const callback = () => {
      confirmationPopupState.setShowPopup(false);
    };
    calendarState.deleteCalendarEvent({ id: event.id }, callback);
  };

  const deleteEventConfirmPrompt = (event = {}) => {
    confirmationPopupState.setPopupValues({
      content:
        "Are you sure you want to delete the calendar event? Once deleted, it will no longer be available for your contacts to book meetings.",
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      loadingMsg: "Please wait...",
      callback: () => deleteEvent(event)
    });
    confirmationPopupState.setShowPopup(true);
  };

  const toggleEventAction = (val = false, id = "") => {
    let payload = { id, status: val ? "ACTIVE" : "INACTIVE" };
    calendarState.toggleCalendarEvent(payload);
  };

  const handleEventAction = (type = "", obj = {}) => {
    switch (type) {
      case "edit":
        editEvent(obj);
        break;
      case "clone":
        cloneEvent(obj);
        break;
      case "delete":
        deleteEventConfirmPrompt(obj);
        break;
      case "link":
        copyEventLink(obj);
        break;
      default:
        break;
    }
  };

  const createNewEventBtnObj = {
    id: "createNewEventBtn",
    btnText: "Create Event",
    className: "createNewEventBtn",
    type: "button",
    click: openCreateEventPage
  };

  const paginate = (pageNo = {}) => {
    let payload = { pageNo: (pageNo?.selected || 0) + 1, limit };
    getEventsList(payload);
  };

  const getEventsList = (payload = {}) => {
    setLoader(true);
    calendarState.getCalendarEventsList(payload);
  };

  useEffect(() => {
    getEventsList({
      limit,
      pageNo: calendarState?.currentPage || 1
    });
    return () => {
      setEventsList("");
      setLoader(true);
      calendarState.setIsCustomWeek(false);
    };
  }, []);

  useEffect(() => {
    if (calendarState?.eventsList) {
      setLoader(false);
      setEventsList(calendarState.eventsList);
    }
  }, [calendarState.eventsList]);

  return (
    <CalendarEventsContext.Provider
      value={{ loader, handleEventAction, toggleEventAction }}
    >
      <div className={`eventsContainer ${loader ? "emptyLoader" : ""}`}>
        {loader ? (
          <EventsListLoader count={10} />
        ) : eventsList?.list?.length > 0 ? (
          <>
            <Button {...createNewEventBtnObj} />
            <EventsTableList
              eventsList={eventsList}
              loader={loader}
              paginate={paginate}
            />
          </>
        ) : (
          <EmptyEventPage />
        )}
      </div>
    </CalendarEventsContext.Provider>
  );
});

const EmptyEventPage = () => {
  return (
    <div className="emptyEventSection">
      <img src={CreateEventImg} alt="" />
      <div className="text">No events found</div>
    </div>
  );
};

export default Main;
export { CalendarEventsContext };
