import { observable, action } from "mobx";
import DateUtils from "Utils/DateUtils";
import { makeApi, URLS } from "Utils/apiURL";

class TrackingStore {
  @observable visitorsData = [];
  @observable dataloader = true;
  @observable dateFilter = DateUtils.getTodayTimeStamp();
  @observable totalPages = 1;
  @observable currentPage = 1;
  @observable viewDetailedVisitorsInfo = false;
  @observable isWebsiteTrackingEnabled = true;
  @observable websiteTrackingInstalled = false;
  @observable sessionDetails = {};
  @observable currentUser = 0;
  @observable activeDateTab = "today";
  @observable customDate = "Custom";

  @action
  setCustomDate = value => {
    this.customDate = value;
  };

  @action
  setActiveDateTab = value => {
    this.activeDateTab = value;
  };

  @action
  setCurrentUser = value => {
    this.currentUser = value;
  };

  @action
  setSessionDetails = value => {
    this.sessionDetails = value;
  };

  @action
  setIsWebsiteTrackingEnabled = value => {
    this.isWebsiteTrackingEnabled = value;
  };

  @action
  setWebsiteTrackingInstalled = value => {
    this.websiteTrackingInstalled = value;
  };

  @action
  setViewDetailedVisitorsInfo = value => {
    this.viewDetailedVisitorsInfo = value;
  };

  @action
  setCurrentPage = value => {
    this.currentPage = value;
  };

  @action
  setTotalPages = value => {
    this.totalPages = value;
  };

  @action
  setDateFilter = value => {
    this.dateFilter = value;
  };

  @action
  setDataLoader = value => {
    this.dataloader = value;
  };

  @action
  setVisitorsData = value => {
    this.visitorsData = value;
  };

  @action
  getTableData = async (payload = {}) => {
    this.setDataLoader(true);
    const config = {
      url: URLS.getVisitorsData,
      method: "POST",
      data: payload
    };
    let response = await makeApi(config);
    const { data = {}, totalPages = 1, currentPage = 1 } = response?.data || [];
    if (+response?.status === 200) {
      trackingStore.setVisitorsData(data);
      trackingStore.setTotalPages(totalPages);
      trackingStore.setCurrentPage(currentPage);
      if (trackingStore?.viewDetailedVisitorsInfo) {
        if (data?.length >= 1) {
          trackingStore.setSessionDetails(data[0]);
        } else {
          trackingStore.setSessionDetails([]);
        }
      }
    }
    this.setDataLoader(false);
  };

  @action
  getInstalledScriptDetails = async () => {
    const config = {
      url: URLS.getInstalledScriptDetails,
      method: "GET"
    };
    let response = await makeApi(config);
    if (+response?.status === 200) {
      const responseData = response?.data || [];
      if (responseData?.length > 0) {
        const { scriptInstalled = false } = responseData?.[0] || {};
        this.setIsWebsiteTrackingEnabled(scriptInstalled);
        this.setWebsiteTrackingInstalled(scriptInstalled);
      } else {
        this.setIsWebsiteTrackingEnabled(false);
      }
    } else {
      this.setIsWebsiteTrackingEnabled(false);
    }
  };
}

const trackingStore = new TrackingStore();
export default trackingStore;
export { trackingStore };
