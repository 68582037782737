import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import KeyInformation from "./KeyInformation";
import StatusInfo from "./StatusInfo";
import Accordion from "./Accordion";
import domainHealthCenterStore from "./store";
import {
  domainHealthDetailedInfoObj,
  spfContentObj,
  dmarcContentObj,
  dkimContentObj,
  mxContentObj
} from "./DomainHealthConstants";

const DomainHealthDetailedInfo = observer(() => {
  const [spfContent, setSpfContent] = useState([]);
  const [dkimContent, setDkimContent] = useState([]);
  const [dmarcContent, setDmarcContent] = useState([]);
  const [mxExchangeContent, setMxExchangeContent] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const accordionRefs = Array.from({ length: 8 }, () => useRef(null));

  const {
    mxRecord = [],
    spfRecord = "",
    dmarcRecord = "",
    dkim = "",
    picture = "",
    userName = "",
    id = "",
    type = ""
  } = domainHealthCenterStore?.dnsRecords || {};

  const backButtonObj = {
    type: "button",
    btnText: "Back",
    id: "backButton",
    name: "backButton",
    className: "backButton",
    click: () => {
      domainHealthCenterStore.fetchAllConnectedEmails();
      domainHealthCenterStore.setDomainHealthDetailedInfo(false);
      domainHealthCenterStore.setDnsRecords({});
    }
  };

  const getDefaultCount = (count = 0, defaultCount = 0) => {
    return {
      status: count === defaultCount ? "Completed" : "Pending",
      count: defaultCount - count
    };
  };

  const getStatusInfo = (header = "") => {
    let count = 0;
    switch (header) {
      case "Authentication Status":
        count = (spfRecord ? 1 : 0) + (dmarcRecord ? 1 : 0) + (dkim ? 1 : 0);
        return getDefaultCount(count, 3);
      case "Profile Update":
        count = (picture ? 1 : 0) + (userName ? 1 : 0);
        return getDefaultCount(count, 2);
      case "Deliverability":
        count = mxRecord?.length > 0 ? 1 : 0;
        return getDefaultCount(count, 1);
    }
  };

  const setDomainHealthContents = () => {
    if (
      domainHealthCenterStore?.dnsRecords &&
      Object.keys(domainHealthCenterStore?.dnsRecords)?.length > 0
    ) {
      for (const key in domainHealthCenterStore?.dnsRecords) {
        if (key === "spfRecord") {
          if (spfRecord) {
            setSpfContent([
              ...spfContentObj.registeredContent,
              { steps: "Record :", content: `${spfRecord}` }
            ]);
          } else {
            setSpfContent(spfContentObj.content);
          }
        } else if (key === "dmarcRecord") {
          if (dmarcRecord) {
            setDmarcContent([
              ...dmarcContentObj.registeredContent,
              { steps: "RUA :", content: `${dmarcRecord}` }
            ]);
          } else {
            setDmarcContent(dmarcContentObj.content);
          }
        } else if (key === "dkim") {
          if (dkim) {
            setDkimContent([
              ...dkimContentObj.registeredContent,
              { steps: "Record :", content: `${dkim}`, isDkim: true }
            ]);
          } else {
            setDkimContent(dkimContentObj.content);
          }
        } else if (key === "mxRecord") {
          if (mxRecord?.length > 0) {
            setMxExchangeContent([
              ...mxContentObj.registeredContent,
              { content: `${mxRecord}`, isMx: true }
            ]);
          } else {
            setMxExchangeContent(mxContentObj?.content);
          }
        }
      }
    }
  };

  const contentKeyObj = {
    spf: spfContent,
    dkim: dkimContent,
    dmarc: dmarcContent,
    mxExchange: mxExchangeContent
  };

  const registerCheckObj = {
    spf: spfRecord,
    dkim,
    dmarc: dmarcRecord,
    mxExchange: mxRecord?.length > 0,
    profileName: userName,
    profilePicture: picture
  };

  const StatusUpdateAndDeliverability = (props = {}) => {
    const { id = "" } = props || {};

    return (
      <div className="statusUpdateAndDeliverability">
        {Object.values(domainHealthDetailedInfoObj).map(item => {
          const { header = "", body = [] } = item || {};
          return (
            <div className="dnsInfoBody" key={header}>
              {header && (
                <div className="headerContent">
                  <span className="headerTitle">{header}</span>
                  <StatusInfo {...getStatusInfo(header)} />
                </div>
              )}
              {body.length > 0 ? (
                body.map((iterator, index) => {
                  const { name = "", accordionIndex = 0 } = iterator || {};
                  return (
                    <Accordion
                      {...iterator}
                      id={id}
                      key={index}
                      type={type}
                      name={name}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      registerContent={contentKeyObj[name]}
                      registerCheck={registerCheckObj[name]}
                      accordionRef={accordionRefs[accordionIndex]}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    setDomainHealthContents();
  }, [domainHealthCenterStore?.dnsRecords]);

  return (
    <div className="detailedDomainHealthInfo">
      <div className="leftSection">
        <StatusUpdateAndDeliverability id={id} />
        <div className="backButtonCont">
          <Button {...backButtonObj} />
        </div>
      </div>
      <div className="rightSection">
        <KeyInformation />
      </div>
    </div>
  );
});

export default DomainHealthDetailedInfo;
export { DomainHealthDetailedInfo };
