import React, { useEffect } from "react";
import HeaderLayout from "Components/LoggedInLayout/HeaderLayout";
import { DataSearch } from "./DataSearch";
import { LEARN_MORE_LINK } from "Static/constant";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { userDetail } from "Stores/userDetail";
import Utils from "Utils/utils";

const defaultBreadcrumbValue = [{ name: "Discover", link: "/discover" }];

const Discover = props => {
  const { searchEnabled = false } = userDetail?.userFeatureFlag || {};

  useEffect(() => {
    Utils.mixpanelTrack(MXP_EVENT.SEARCH_SEC_VIEWED, {
      pageType: PAGE_TYPE.discover
    });
  }, []);

  return (
    <div
      className={`dataSearchPage pageClass ${!searchEnabled ? "blurBg" : ""}`}
    >
      <div className="sequenceCont pageCont">
        <HeaderLayout
          className="discover"
          redirectUrl={LEARN_MORE_LINK.discover}
          breadcrumbArr={defaultBreadcrumbValue}
          {...props}
        />
        <div className="bodySection">
          <DataSearch />
        </div>
      </div>
    </div>
  );
};

export { Discover };
export default Discover;
