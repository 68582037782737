import React from "react";
import userDetail from "Stores/userDetail";
import BulkLogCalls from "./BulkLogCalls";
import ExportBtn from "Components/common/ExportBtn";
import ContactSelectedCount from "Components/common/ContactSelectedCount";

const InsertBulkFilterOptions = (props = {}) => {
  const {
    data = {},
    loader = "",
    selectedLogIds = [],
    allLogsSelected = "",
    setAllLogsSelected = () => {},
    resetCbk = () => {},
    selectAllCbk = () => {},
    exportCbk = () => {},
    bulkLogCallsEnabled = false
  } = props || {};

  const { dialerEnabled = false } = userDetail?.userFeatureFlag || {};
  const { totalCalls = 0 } = data || {};

  return (
    <div className="bulkFilterOptWrapper">
      <div className="selectCallLogsWrapper">
        {!loader && selectedLogIds?.length > 0 && (
          <ContactSelectedCount
            selectedCount={selectedLogIds?.length || 0}
            totalCount={totalCalls}
            loading={loader}
            allContactSelected={allLogsSelected}
            setAllContactSelected={setAllLogsSelected}
            resetCbk={resetCbk}
            selectAllCbk={selectAllCbk}
            selectedType="Call"
          />
        )}
      </div>
      <div className="filterOptList">
        {bulkLogCallsEnabled && (
          <BulkLogCalls
            selectedLogIds={selectedLogIds}
            allLogsSelected={allLogsSelected}
            loader={loader}
            resetCbk={resetCbk}
          />
        )}
        <ExportBtn
          selectedEntityCount={selectedLogIds?.length || 0}
          cbk={exportCbk}
          loading={data?.filterLoader || data?.overviewLoader}
          isExportEnabled={dialerEnabled}
          checked={selectedLogIds?.length > 0}
          pageType="calls"
          tooltipText="call logs"
        />
      </div>
    </div>
  );
};

export default InsertBulkFilterOptions;
