import { observable, action } from "mobx";

export const userObj = {
  name: null,
  email: null,
  timeZone: null,
  userLoggedIn: "",
  team: null,
  hasTeam: null,
  memberId: "",
  isOnBoardingCompleted: "",
  hasTeamMember: null,
  firstName: "",
  lastName: "",
  intercomEncryptKey: "",
  isTeamOwner: false,
  calendarEnabled: true,
  unsubLinkDefaultDom: ""
};

export const feaureFlag = {
  noOfSeatsEligible: null,
  noOfSeatsUsed: null,
  noOfSequencesEligible: null,
  noOfSequencesUsed: null,
  noOfContactsEligible: 0,
  noOfContactsUsed: null,
  teamReportEnabled: null,
  autoBccEnabled: null,
  crmSyncEnabled: null,
  noOfTemplatesEligible: null,
  noOfTemplatesUsed: null,
  email: null,
  memberIds: null,
  teamStatus: null,
  subscriptionPlanId: null,
  exportEnabled: false,
  dialerEnabled: "",
  dialerCredits: 0,
  brandedUrlEnabled: false,
  trialEnabled: false,
  trialUsed: false,
  createTs: "",
  noOfContactsAddedInTeam: 0,
  lifeTimeDeal: false,
  customFieldsEnabled: false,
  taskEnabled: false,
  inFreePlan: false,
  abTestingEnabled: false,
  triggersEnabled: false,
  multipleInboxEnabled: false,
  noOfInboxesEligible: 1,
  noOfInboxesUsed: 0,
  noOfLiAccountsEligible: 1,
  noOfLiAccountsUsed: 0,
  zohoIntegrationEnabled: false,
  writingAssistantEnabled: false,
  zapierIntegrationEnabled: false,
  hubspotIntegrationEnabled: false,
  recordingEnabled: "",
  callReportEnabled: true,
  teamId: "",
  pipedriveIntegrationEnabled: false,
  searchEnabled: false,
  paginationLimit: 0,
  phoneEnabled: false,
  customContactStageLimit: 0,
  salesforceIntegrationEnabled: false,
  websiteVisitorTrackingToken: "",
  newReportsEnabled: false,
  liAutomationEnabled: false
};

export const inviteTeam = {
  name: null,
  email: null,
  adminName: null,
  adminEmail: null,
  token: null,
  newUser: null
};

export const upgradePopupContent = {
  page: null,
  show: null,
  overlay: null
};

export const brandUrlObj = {
  cname: "",
  domain: ""
};

export const featureFlag = {};

class UserDetail {
  @observable userInfo = { ...userObj };
  @observable userTeamInfo = { ...inviteTeam };
  @observable userFeatureFlag = { ...feaureFlag };
  @observable upgradePopup = { ...upgradePopupContent };
  @observable upgradeSettingsPopup = { ...upgradePopupContent };
  @observable showUpgradePopup = false;
  @observable showUpgradeSettingsPopup = false;
  @observable brandUrlInfo = { ...brandUrlObj };
  @observable pluginInstalled = false;
  @observable connectInboxAccessRevoke = false;
  @observable defaultUserCountry = "us";
  @observable installNowPluginClicked = false;
  @observable userStripeCode = "";
  @observable isTeamCreated = false;
  @observable globalTimeZone = [];
  @observable eventRegistered =
    localStorage.getItem("outboundStrategyStartupMultiple") === "true" || false;
  @observable blockedDomainList = [];
  @observable connectedInboxes = [];
  @observable activeLinkedInAccounts = [];

  @action
  setBlockedDomainList = val => {
    this.blockedDomainList = val;
  };

  @action
  setUserInfo(obj) {
    this.userInfo = { ...userObj, ...obj };
  }

  @action
  setUserTeamInfo(obj) {
    this.userTeamInfo = { ...inviteTeam, ...obj };
  }

  @action
  setUserFeatureFlag(obj) {
    this.userFeatureFlag = { ...feaureFlag, ...obj };
  }

  @action
  setUpgradePopupContent(obj) {
    this.showUpgradePopup = obj.show;
    this.upgradePopup = { ...upgradePopupContent, ...obj };
  }

  @action
  setUpgradeSettingsPopupContent(obj) {
    this.showUpgradeSettingsPopup = obj.show;
    this.upgradeSettingsPopup = { ...upgradePopupContent, ...obj };
  }

  @action
  setBrandUrlInfo(obj = {}) {
    this.brandUrlInfo = { ...brandUrlObj, ...obj };
  }

  @action
  setPluginInstalled(value) {
    this.pluginInstalled = value;
  }

  @action
  setConnectInboxAccessRevoke(value) {
    this.connectInboxAccessRevoke = value;
  }

  @action
  setDefaultUserCountry(value) {
    this.defaultUserCountry = value;
  }

  @action
  setInstallNowPluginClicked(value) {
    this.installNowPluginClicked = value;
  }

  @action
  setUserStripeCode = val => {
    this.userStripeCode = val;
  };

  @action
  setIsTeamCreated = val => {
    this.isTeamCreated = val;
  };

  @action
  setGlobalTimeZone = val => {
    this.globalTimeZone = val;
  };

  @action
  setEventRegistered = val => {
    this.eventRegistered = val;
  };

  @action
  setConnectedInboxes = (list = []) => {
    this.connectedInboxes = list;
  };

  @action
  setActiveLinkedInAccounts = (list = []) => {
    this.activeLinkedInAccounts = list;
  };
}

const userDetail = new UserDetail();
export { userDetail };
export default userDetail;
