import React, { useState } from "react";
import ContactFieldFilter from "../../contacts/ContactFilter/ContactFieldFilter";
import filterComponentState from "./filterComponentState";
import { observer } from "mobx-react";

const CustomContactField = observer(props => {
  const { filterArr = "", id = "", errMsg = "", setErrMsg } = props || {};
  const [disableAddButton, setDisableAddButton] = useState(true);

  const selectedFilterAction = (type, value) => {
    let tempArr = [...filterArr];
    if (type === "remove" && tempArr?.length) {
      tempArr = tempArr.filter(item => item !== value);
    } else if (type === "add") {
      !tempArr.includes(value) && tempArr.push(value);
    }
    if (!tempArr?.length) {
      filterComponentState.setFilterComponentData({
        ...filterComponentState.filterComponentData,
        [id]: []
      });
    }
    filterComponentState.setFilteredContactList(tempArr);
  };

  const validateData = data => {
    let validData = false;
    const dataLength = data?.length || 0;
    if (dataLength) {
      const { fieldName = "", value = "" } = data?.[dataLength - 1];
      validData = fieldName && value;
    }
    return validData;
  };

  const handleFilterObject = (key = "", value = "") => {
    let tempObj = { ...filterComponentState.contactFieldsData, [key]: value };
    filterComponentState.setContactFilterObject(tempObj);
    filterComponentState.setContactFieldsData(tempObj);
    const validData = validateData(tempObj?.[key]);
    if (validData) {
      filterComponentState.setFilterComponentData({
        ...filterComponentState.filterComponentData,
        [id]: tempObj?.[key]
      });
    }
    setDisableAddButton(!validData);
  };

  return (
    <ContactFieldFilter
      label="Contact Field"
      keyValue="contactFields"
      data={filterComponentState.contactFieldsData?.contactFields}
      setAction={handleFilterObject}
      selectedFilterCbk={selectedFilterAction}
      errMsg={errMsg}
      setErrMsg={setErrMsg}
      hasDropdown={false}
      disableAddButton={
        disableAddButton ||
        filterComponentState.filterComponentData?.[id]?.length >= 5
      }
    />
  );
});

export { CustomContactField };
export default CustomContactField;
