import React, { useState } from "react";
import PricingStore from "Stores/Pricing";
import { Utils } from "Utils/utils";
import { userDetail } from "Stores/userDetail";
import { withRouter, useHistory } from "react-router-dom";

const FreeTrial = () => {
  const history = useHistory();

  const {
    trialEnabled,
    trialEndDate,
    inFreePlan = false
  } = userDetail.userFeatureFlag || {};

  let proPlan = PricingStore?.activePlanData.filter(
    item => item?.displayName === "Unlimited"
  )[0];

  const [showUpgradeNow, setUpgradeNow] = useState(false);

  const redirectTopricing = () => {
    if (history) {
      if (showUpgradeNow) {
        PricingStore.setPaymentFormInfo(proPlan);
        localStorage.setItem("proceedToPay", true);
        history.push("/pricing/payment");
      } else {
        if (!Utils.isAdmin()) {
          Utils.showMessagePopup();
        } else {
          history.push("/pricing");
        }
      }
    }
  };

  const getParsedTrialEndDate = () => {
    if (trialEndDate) {
      const endDate = new Date(trialEndDate);
      const currentDate = new Date();
      const diffTime = endDate - currentDate;
      const diffInDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffInDays > 0
        ? `${diffInDays} ${diffInDays > 1 ? "Days" : "Day"} Remaining`
        : "Upgrade Now";
    }
  };

  const TrialEndDate = () => {
    return (
      <div
        className={`add-new-dropdown trialEndingDate ${
          showUpgradeNow ? "upgradeBtnOverlay" : ""
        }`}
        onClick={redirectTopricing}
        onMouseOver={() => setUpgradeNow(true)}
        onMouseLeave={() => setUpgradeNow(false)}
      >
        {inFreePlan
          ? "Upgrade Now"
          : showUpgradeNow
          ? `Upgrade Now -`
          : `Free Trial -`}
        {!inFreePlan && (
          <>
            <span className="material-icons clockIcon">schedule</span>
            <span className="endingDate">{getParsedTrialEndDate()}</span>
          </>
        )}
      </div>
    );
  };

  return trialEnabled || inFreePlan ? <TrialEndDate /> : "";
};

export { FreeTrial };
export default withRouter(FreeTrial);
