/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { LEARN_MORE_LINK } from "Static/constant";
import HeaderLayout from "Components/LoggedInLayout/HeaderLayout";
import { reportStore, defaultCustomDate, defaultSortObj } from "Stores/Reports";
import ReportFilter from "./ReportsFilter";
import { USER_SELECT_DROPDOWN_LIST as userObjList } from "Static/constant";
import ReportTabRouting from "Routes/ReportTabRouting";
import Utils from "Utils/utils";
import PricingDiscountBanner from "Components/common/PricingDiscountBanner";
import TabNavigation from "Components/common/TabNavigation";
import { REPORTS_TAB_NAVIGATION } from "Static/constant";
import SwitchReportsVersion from "../reports_v2/SwitchReportsVersion";
import PaywallUtils from "Utils/PaywallUtils";

const ReportFilterContext = createContext({});

const ReportsModule = observer(props => {
  const breadcrumbArr = [{ name: "Reports", link: "/reports" }];
  const {
    noOfContactsAddedInTeam = 0,
    inFreePlan = false,
    trialEnabled = false,
    teamReportEnabled = false,
    newReportsEnabled = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  let location = useLocation();
  let isSummary = location?.pathname?.indexOf("summary") !== -1;
  let pageType = isSummary ? "Summary Page" : "Insights Page";

  const [isContactsAdded, setContactAdded] = useState(
    noOfContactsAddedInTeam > 0
  );
  const [teamLoader, setTeamLoader] = useState(true);
  const [teamList, setTeamList] = useState("");
  const [productDiscount, setProductDiscount] = useState(
    Utils.showProductDiscountBanner()
  );
  const [userFilterList, setUserFilterList] = useState(userObjList);

  const getTeamList = async () => {
    const resData = await Utils.userTeamList();
    userDetail.setUserInfo({
      ...userDetail.userInfo,
      hasTeamMember: resData?.length ? resData : false
    });
    setTeamList(resData);
    setTeamLoader(false);
  };

  const resetUserFilterOption = () => {
    const newArr = [...userFilterList];
    newArr.map(item => (item.checked = newArr.id === "you" ? true : false));
    setUserFilterList(newArr);
  };

  const resetToInitalState = () => {
    reportStore.setSummaryLoader(true);
    reportStore.setEmailInsightLoader(true);
    reportStore.setSequenceInsightLoader(true);
    reportStore.setFilterPayload("");
    reportStore.setCustomDate(defaultCustomDate);
    reportStore.setCurrentPage(0);
    reportStore.setSortObj(defaultSortObj);
    reportStore.summaryData = null;
    reportStore.emailInsightData = null;
    reportStore.sequenceInsightData = null;
    resetUserFilterOption();
  };

  useEffect(() => {
    setProductDiscount(Utils.showProductDiscountBanner());
  }, [Utils.showProductDiscountBanner()]);

  useEffect(() => {
    if (userDetail?.userFeatureFlag) {
      const { noOfContactsAddedInTeam = 0 } = userDetail?.userFeatureFlag;
      setContactAdded(noOfContactsAddedInTeam > 0);
    }
  }, [userDetail.userFeatureFlag]);

  useEffect(() => {
    if (!teamReportEnabled) {
      PaywallUtils.upgradeUtilsFunction(
        "reportsPage",
        true,
        planName,
        inFreePlan
      );
    } else {
      getTeamList();
      return () => {
        resetToInitalState();
      };
    }
  }, []);

  return (
    <div className="reportsSection">
      <HeaderLayout
        className="report"
        redirectUrl={LEARN_MORE_LINK.reports}
        breadcrumbArr={breadcrumbArr}
        {...props}
      />
      {!productDiscount && <PricingDiscountBanner />}
      {newReportsEnabled && <SwitchReportsVersion />}
      <div
        className={`reportsContainer ${teamReportEnabled ? "" : "blurBg"} ${
          !productDiscount ? "productDiscount" : ""
        }`}
      >
        <div
          className={`reportsBody ${
            newReportsEnabled ? "isNewReportsEnabled" : ""
          }`}
        >
          <div className="reportsCard">
            <div className="filterNaviagtionCard">
              <TabNavigation tabList={REPORTS_TAB_NAVIGATION} />
              <ReportFilterContext.Provider
                value={{
                  userFilterList,
                  teamLoader,
                  teamList,
                  isContactsAdded,
                  inFreePlan,
                  trialEnabled,
                  isSummary,
                  pageType,
                  teamReportEnabled
                }}
              >
                <ReportFilter />
              </ReportFilterContext.Provider>
            </div>
            <ReportTabRouting />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ReportsModule;
export { ReportFilterContext, ReportsModule };
