import { observable, action } from "mobx";
import DateUtils from "Utils/DateUtils";

class ConnectedLinkedInHeaderState {
  @observable filterChanged = false;
  @observable membersFilter = false;
  @observable dateFilter = DateUtils.getMonthTimeStamp(false);
  @observable activeDateTab = "today";
  @observable searchTerm = "";

  @action
  setFilterChanged = (val = false) => {
    this.filterChanged = val;
  };

  @action
  setMembersFilter = (val = {}) => {
    this.membersFilter = val;
  };

  @action
  setDateFilter = (val = {}) => {
    this.dateFilter = val;
  };

  @action
  setActiveDateTab = (val = "") => {
    this.activeDateTab = val;
  };

  @action
  setSearchTerm = (val = "") => {
    this.searchTerm = val;
  };
}

const connectedLinkedInHeaderState = new ConnectedLinkedInHeaderState();
export { connectedLinkedInHeaderState };
