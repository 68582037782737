import { observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import makeApi, { URLS } from "Utils/apiURL";

class ZohoDataStore {
  @observable zohoData = "";
  @observable zohoLeadsList = "";
  @observable zohoContactsList = "";
  @observable zohoOwnersList = "";
  @observable zohoLeadConfig = {};
  @observable zohoContactConfig = {};
  @observable zohoOwnerConfig = {};
  @observable disableSaveButton = false;
  @observable zohoLeadTags = [];
  @observable zohoContactTags = [];
  @observable zohoFields = [];
  @observable zohoPreferences = {};
  @observable syncLogs = {};

  @action
  setZohoData = value => {
    this.zohoData = value;
  };

  @action
  setZohoLeadsList = value => {
    this.zohoLeadsList = value;
  };

  @action
  setZohoContactsList = value => {
    this.zohoContactsList = value;
  };

  @action
  setZohoOwnersList = value => {
    this.zohoOwnersList = value;
  };

  @action
  setZohoLeadConfig = value => {
    this.zohoLeadConfig = value;
  };

  @action
  setZohoContactConfig = value => {
    this.zohoContactConfig = value;
  };

  @action
  setZohoOwnerConfig = value => {
    this.zohoOwnerConfig = value;
  };

  @action
  setDisableSaveButton = value => {
    this.disableSaveButton = value;
  };

  @action
  setZohoLeadTags = value => {
    this.zohoLeadTags = value;
  };

  @action
  setZohoContactTags = value => {
    this.zohoContactTags = value;
  };

  @action
  setZohoPreferences = value => {
    this.zohoPreferences = value;
  };

  @action
  getZohoData = async () => {
    try {
      const config = {
        url: URLS.getZohoAccountDetails,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response?.data?.responseCode === 200) {
        this.setZohoData(response.data);
      } else {
        this.setZohoData("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  removeZohoAccount = async () => {
    try {
      const config = {
        url: URLS.removeZohoAccount,
        method: "DELETE"
      };
      let res = await makeApi(config);
      if (res?.data?.responseCode === 200) {
        await this.getZohoData();
        toasterState.setToastMsg(
          "Zoho account disconnected successfully",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Zoho failed to remove error", err);
    }
  };

  @action
  getLeadsListView = async () => {
    try {
      const config = {
        url: URLS.getLeadsView,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.setZohoLeadsList(res?.data);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Zoho Leads listing error", err);
    }
  };

  @action
  getContactsListView = async () => {
    try {
      const config = {
        url: URLS.getContactsView,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.setZohoContactsList(res?.data);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Zoho Contacts listing error", err);
    }
  };

  @action
  getAllOwners = async () => {
    try {
      const config = {
        url: URLS.getAllZohoOwners,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.zohoOwnersList = res?.data;
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Zoho Owners listing error", err);
    }
  };

  @action
  getAllZohoFields = async type => {
    try {
      const config = {
        url: `${URLS.getAllZohoFields}/${type}`,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.zohoFields = res?.data;
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Zoho Owners listing error", err);
    }
  };

  @action
  getSyncLogs = async (pageNo, limit) => {
    const url = URLS.getZohoSyncLogs
      .replace("<<pageNo>>", pageNo)
      .replace("<<limit>>", limit);
    try {
      const config = {
        url: url,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        return res?.data;
      }
    } catch (err) {
      console.error("Zoho logs listing error", err);
    }
  };
}

const zohoDataStore = new ZohoDataStore();
export { ZohoDataStore, zohoDataStore };
export default ZohoDataStore;
