import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import Lottie from "react-lottie";
import Chart from "react-apexcharts";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import BarChartLoader from "Utils/LottieFiles/BarChart.json";
import EmptyReport from "../../reports/EmptyReport";
import NoActivityFound from "./NoActivityFound";
import reportsModuleState from "./state";

const series = [
  {
    name: "Email Sent",
    data: []
  },
  {
    name: "Email Replied",
    data: []
  },
  {
    name: "Calls Made",
    data: []
  },
  {
    name: "Calls Connected",
    data: []
  },
  {
    name: "Task completed",
    data: []
  },
  {
    name: "Contacts added",
    data: []
  }
];

const options = {
  chart: {
    type: "bar",
    height: "100px",
    stacked: true,
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  colors: ["#00D2AD", "#02977d", "#0177FF", "#065fc5", "#FFCC3F", "#f59064"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  yaxis: {
    labels: {
      formatter: value => {
        return Math.floor(value);
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      horizontal: false
    }
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
    show: false
  },
  fill: {
    opacity: 1
  }
};

const ActivityInsights = observer(props => {
  const [chartData, setChartData] = useState(series);
  const [chartOptions, setChartOptions] = useState(options);
  let contactAdded = userDetail?.userFeatureFlag?.noOfContactsAddedInTeam;
  let location = useLocation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BarChartLoader
  };

  const constructChartData = () => {
    const data = reportsModuleState?.activityInsightData;
    if (data) {
      const tempData = [...chartData];
      tempData.map(item => (item.data = []));
      const tempXaxisDate = [];
      if (data && Object.keys(data)?.length) {
        for (let item in data) {
          const {
            sentCount = 0,
            replyCount = 0,
            contactsAdded = 0,
            totalCallsMade = 0,
            callConnectedCount = 0,
            taskCompleted = 0,
            activityDate = ""
          } = data?.[item];
          tempData[0].data.push(Math.ceil(sentCount) || 0);
          tempData[1].data.push(Math.ceil(replyCount) || 0);
          tempData[2].data.push(Math.ceil(totalCallsMade) || 0);
          tempData[3].data.push(Math.ceil(callConnectedCount) || 0);
          tempData[4].data.push(Math.ceil(taskCompleted) || 0);
          tempData[5].data.push(Math.ceil(contactsAdded) || 0);
          tempXaxisDate.push(activityDate);
        }
      }
      const { filterFromDate, filterToDate, days } =
        reportsModuleState.filterPayload;
      let startDate = Utils.formatDateHypen(filterFromDate);
      let endDate = Utils.formatDateHypen(filterToDate);
      const hasStartDate = tempXaxisDate.indexOf(startDate) !== -1;
      const hasEndDate = tempXaxisDate.indexOf(endDate) !== -1;
      if (data && Object.keys(data)?.length && days > 1) {
        !hasStartDate && tempXaxisDate.push(startDate);
        !hasEndDate && tempXaxisDate.splice(0, 0, endDate);
        tempData.map(item => {
          !hasStartDate && item.data.push(0);
          !hasEndDate && item.data.splice(0, 0, 0);
        });
      }
      setChartOptions({
        ...options,
        xaxis: {
          type: days > 1 ? "datetime" : "category",
          categories:
            (days > 1 && tempXaxisDate) ||
            tempXaxisDate.map(item => Utils.formatDate(item)),
          labels: {
            datetimeFormatter: { month: "dd MMM" }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: (days > 20 && "40%") || "20%"
          }
        }
      });
      setChartData(tempData);
      reportsModuleState.setActivityInsightLoader(false);
    }
  };

  useEffect(() => {
    constructChartData();
  }, [reportsModuleState?.activityInsightData]);

  return (
    <>
      {!reportsModuleState?.activityInsightLoader &&
        contactAdded > 0 &&
        chartData?.length > 0 &&
        chartData[0]?.data?.length > 0 && (
          <div className="stackedBarChart">
            <Chart
              options={chartOptions}
              series={chartData}
              type="bar"
              height={300}
            />
          </div>
        )}
      {reportsModuleState?.activityInsightLoader && (
        <div className="chartLoader">
          <Lottie
            options={defaultOptions}
            width={200}
            height={250}
            style={{ margin: "0 auto" }}
            speed={1}
          />
        </div>
      )}
      {!reportsModuleState?.activityInsightLoader && !contactAdded && (
        <NoActivityFound />
      )}
      {(!reportsModuleState?.activityInsightLoader &&
        contactAdded &&
        chartData?.length > 0 &&
        !chartData[0]?.data?.length && <NoActivityFound />) ||
        ""}
    </>
  );
});

export { ActivityInsights };
export default ActivityInsights;
