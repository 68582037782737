import React, { useState } from "react";
import KnowMoreButton from "Components/common/KnowMoreButton";
import Button from "Components/common/Button";
import { initialScreenConfig } from "./googleOAuthConfig";
import { googleOAuthPopupState } from "../googleOAuthPopup";

const InitialScreen = () => {
  const [loading, setLoading] = useState(false);

  const clickProceedBtn = () => {
    setLoading(true);
    googleOAuthPopupState.setAppPasswordScreen("two");
  };

  const btnProps = [
    {
      id: "back",
      name: "backBtn",
      icon: false,
      type: "button",
      className: "backBtn",
      loader: false,
      btnText: "Back",
      click: () => {
        googleOAuthPopupState.setScreenNo("one");
      }
    },
    {
      id: "proceedBtn",
      name: "proceedBtn",
      icon: false,
      type: "button",
      className: "proceedBtn",
      loader: loading,
      btnText: "Proceed",
      click: () => clickProceedBtn()
    }
  ];

  return (
    <div>
      <div className="initialScreenHeading">
        To start warming your Google account, you need to first enable IMAP.
        Here are the steps:
      </div>
      <div className="initialScreenBody">
        <ol>
          {initialScreenConfig.map((item, index) => {
            const {
              subLabel = false,
              content = "",
              subLabelContent = ""
            } = item || {};
            return (
              <li key={index}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                {subLabel && (
                  <div
                    className="subLabelContent"
                    dangerouslySetInnerHTML={{ __html: `(${subLabelContent})` }}
                  />
                )}
              </li>
            );
          })}
        </ol>
        {/* <div className="tutorialLink">
          <KnowMoreButton text="Show me how" redirectUrl="" />
        </div> */}
      </div>
      <div className="oAuthFooter">
        <div className="actionBtns">
          {btnProps.map((item, index) => {
            return <Button {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export { InitialScreen };
export default InitialScreen;
