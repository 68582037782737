import React from "react";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import TabNavigation from "Components/common/TabNavigation";
import UserProfile from "../UserProfile";
import EmailPreference from "./EmailPreferences";

const ProfileAndEmailPreferences = () => {
  let { path = "" } = useRouteMatch();

  const MY_PROFILE_TABS = [
    { name: "Profile", id: "profile", path: "profile" },
    {
      name: "Email Reports",
      id: "emailReports",
      path: "email-reports"
    }
  ];
  return (
    <div className="profileAndEmailPreference">
      <TabNavigation tabList={MY_PROFILE_TABS} />
      <Switch>
        <Route exact path={`${path}/profile`} component={UserProfile} />
        <Route
          exact
          path={`${path}/email-reports`}
          component={EmailPreference}
        />
        <Redirect from={path} to={`${path}/profile`} />
      </Switch>
    </div>
  );
};

export { ProfileAndEmailPreferences };
export default ProfileAndEmailPreferences;
