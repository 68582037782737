import React from "react";

const TeamTableLoader = props => {
  const { count, value } = props;
  return Array.from({ length: count }, (item, index) => (
    <div className="tableRowLoader" key={`${value}${index}`}>
      <div className="fullName">
        <span className="linear-background"></span>
      </div>
      <div className="emailAddress">
        <span className="linear-background"></span>
      </div>
      <div className="jobTitle">
        <span className="linear-background"></span>
      </div>
      <div className="role">
        <span className="linear-background"></span>
      </div>
      <div className="status">
        <span className="linear-background icon"></span>
        <span className="linear-background name"></span>
      </div>
      <div className="moreOptions">
        <span className="linear-background"></span>
      </div>
    </div>
  ));
};

export default TeamTableLoader;
