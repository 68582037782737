import React from "react";
import BulkUploadingLoader from "Components/common/bulkContactPopup/BulkUploadingLoader";

const ContactUploading = props => {
  const { msg = "", title = "Add Contacts" } = props;
  return (
    <div className="sequenceContactUploader">
      <div className="contactUploader">
        <h3>{title}</h3>
        <BulkUploadingLoader loadingMessage={msg} />
      </div>
      <div className="contactUploaderOverlay" />
    </div>
  );
};

export default ContactUploading;
