import React from "react";
import { observer } from "mobx-react";
import DialerServiceData from "Stores/DialerServiceStore";
import Button from "Components/common/Button";
import PhoneLogImg from "Assets/png/phoneLog.png";

const DialerCancelLogPopup = props => {
  const { hide } = props;

  const yesCloseAction = () => {
    DialerServiceData.setDialerCallLogs(true);
  };

  return (
    <div className="dsCancelLogPopup">
      <div className="upgradeCreditsCard animated customzoomIn">
        <img src={PhoneLogImg} alt="" />
        <h6>Cancel call log?</h6>
        <p>Are you sure you want to close? This call will not be logged.</p>
        <div className="cancelLogBtn">
          <Button
            id="yesClose"
            name="yesClose"
            btnText="Yes Close"
            icon={false}
            iconName=""
            type="button"
            className="yesCloseBtn"
            loader={false}
            click={yesCloseAction}
          />
          <Button
            id="noDontCancelBtn"
            name="noDontCancelBtn"
            btnText="No, Dont Cancel"
            icon={false}
            iconName=""
            type="button"
            className="noDontCancelBtn"
            loader={false}
            click={hide}
          />
        </div>
      </div>
      <div className="overlayCLP" onClick={hide}></div>
    </div>
  );
};

export default observer(DialerCancelLogPopup);
export { DialerCancelLogPopup };
