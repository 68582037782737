import React from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react";
import DateUtils from "Utils/DateUtils";
import Utils from "Utils/utils";
import "flag-icons/css/flag-icons.min.css";
import { ReactComponent as CompanyIcon } from "Assets/svg/companyIcon.svg";
import IllustrationCard from "Assets/png/illustration.png";
import { trackingStore } from "./trackingStore";
import { ReactComponent as TimerIcon } from "Assets/svg/timer.svg";
import { ReactComponent as ScheduledIconDate } from "Assets/svg/outbox/scheduledIconDate.svg";

const WebsiteTrackingDisabled = () => {
  const history = useHistory();

  const redirectToSettings = () => {
    history.push(`/settings/website-tracking`);
  };

  return (
    <div className="navigateToSettings">
      <div className="illustrationImg">
        <img src={IllustrationCard} />
      </div>
      <div className="infoText">
        Unlock Powerful Insights: Install the tracking code on your website to
        analyze visitors and unlock valuable insights. Click below to get
        started.
      </div>
      <div className="navigateToSettingsBtn" onClick={redirectToSettings}>
        Install tracking code
      </div>
    </div>
  );
};

const VisitorsDataTable = observer((props = {}) => {
  const getVisitorInitialFromName = (visitData = {}) => {
    const {
      contactFirstName = "",
      contactLastName = "",
      contactEmail = "",
      userEmail = ""
    } = visitData || {};
    let tempInitial = Utils.getOwnerInitial({
      firstName: contactFirstName,
      lastName: contactLastName,
      email: userEmail || contactEmail
    });
    return tempInitial || "AN";
  };

  const setSelectedVisitorInfo = (selectedVisitorData = {}, index = 0) => {
    trackingStore.setViewDetailedVisitorsInfo(true);
    trackingStore.setSessionDetails(selectedVisitorData);
    trackingStore.setCurrentUser(index);
  };

  return !trackingStore?.isWebsiteTrackingEnabled ? (
    <WebsiteTrackingDisabled />
  ) : !trackingStore?.dataloader && trackingStore?.visitorsData?.length > 0 ? (
    trackingStore?.visitorsData.map((item, index) => {
      const {
        countryDetails = {},
        totalDurationInSecs = 0,
        userEmail = "",
        companyName = "",
        sessionStartTs = 0
      } = item || {};
      const { code = "", name = "" } = countryDetails || {};
      const { date: sessionDate = "" } =
        DateUtils.getFormattedDateAndTime(sessionStartTs);

      return (
        <tr
          className="vistorsTableBodyRow"
          onClick={() => setSelectedVisitorInfo(item, index)}
          key={index}
        >
          <td className="name">
            <span className="circleOwner">
              {getVisitorInitialFromName(item)}
            </span>
            <span className="userName">{Utils.getVisitorFullName(item)}</span>
          </td>
          <td className="domain">
            <CompanyIcon />
            <span className="domainName">{companyName || "Anonymous"}</span>
          </td>
          <td className="country">
            <span
              className={`countryCode fi fi-${code?.toLowerCase() || "us"} fis`}
            ></span>
            <span className="countryName">
              {name?.toUpperCase() || "UNITED STATES"}
            </span>
          </td>
          <td className="sessionDate">
            <ScheduledIconDate />
            <span className="startDate">{sessionDate}</span>
          </td>
          <td className="sessionStartTime">
            <TimerIcon />
            <span className="startTime">
              {DateUtils.getTimeFromTimestamp(sessionStartTs)}
            </span>
          </td>
          <td className="time">
            {totalDurationInSecs
              ? DateUtils.getTimeFromSeconds(totalDurationInSecs)
              : "00:00:00"}
          </td>
          <td className="nextIcon">
            <i className="material-icons">keyboard_arrow_right</i>
          </td>
        </tr>
      );
    })
  ) : (
    <tbody className="emptyDataMsg">
      <p>No data found</p>
    </tbody>
  );
});

export { VisitorsDataTable };
export default VisitorsDataTable;
