import React, { useState, useEffect } from "react";
import { conditionsOperatorObject } from "Static/constant";
import FilterDropdown from "./FilterDropdown";
import LabelText from "./LabelText";
import AddItem from "./AddItem";
import LogicalOperator from "./LogicalOperator";
import DeleteRow from "./DeleteRow";
import TriggerConditionInputValue from "./TriggerConditionInputValue";
import ContactAutoCompleteDropdown from "Components/PreviewPopup/ContactAutoCompleteDropdown";
import { settingsStoreData } from "Stores/settingsData";
import { triggerState } from "Pages/Dashboard/settings/Triggers/TriggerState";
import userDetail from "Stores/userDetail";
import trackingStore from "Pages/Tracking/trackingStore";

const TriggerConditions = props => {
  const {
    groups = [],
    change = () => {},
    error = "",
    defaultGroupObject = {},
    contactStageList = []
  } = props || {};

  const { customFieldsCount = 0 } = userDetail?.userFeatureFlag || {};
  let customFieldData = [];

  const defaultConditionsObject = {
    fieldName: "",
    operator: "EQUALS",
    value: "",
    name: ""
  };

  const [conditionFieldsObject, setConditionFieldsObject] = useState({});
  const [groupsList, setGroupsList] = useState({});
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(false);
  const [hoverIndex, setHoverIndex] = useState("");

  const addNewConditions = index => {
    let tempList = [...groupsList];
    tempList[index].triggerConditions.conditions.push(defaultConditionsObject);
    setGroupsList(tempList);
  };

  const handleAction = (key, obj) => {
    let tempList = [...groupsList];
    const splitArr = activeDropdownIndex?.split("-") || false;
    if (splitArr?.length) {
      let parentIndex = +splitArr[1];
      let childIndex = +splitArr[2];
      if (key === "fieldName") {
        tempList[parentIndex].triggerConditions.conditions[childIndex] = {
          [key]: obj?.subKey,
          operator: "EQUALS",
          value: "",
          name: ""
        };
      } else {
        tempList[parentIndex].triggerConditions.conditions[childIndex][key] =
          obj?.subKey;
        if (["IS_SET", "IS_NOT_SET"]?.includes(obj?.subKey)) {
          tempList[parentIndex].triggerConditions.conditions[childIndex][
            "value"
          ] = "";
        }
      }
    }
    setGroupsList(tempList);
    setActiveDropdownIndex(false);
    change("groups", tempList);
  };

  const handleValueChange = (
    key = "",
    value = "",
    parentIndex = 0,
    childIndex = 0
  ) => {
    let tempList = [...groupsList];
    let isDropdownValue = [
      "sequenceObject",
      "tagValue",
      "callOutcomeObject",
      "contactStage",
      "hasVisitedWebsite"
    ].includes(key);
    let inputValue = isDropdownValue ? value?.id : value;
    tempList[parentIndex].triggerConditions.conditions[childIndex][
      isDropdownValue ? "value" : key
    ] = inputValue;
    if (isDropdownValue) {
      let inputName = [
        "callOutcomeObject",
        "tagValue",
        "contactStage",
        "hasVisitedWebsite"
      ].includes(key)
        ? value?.value
        : value?.name;
      tempList[parentIndex].triggerConditions.conditions[childIndex]["name"] =
        inputName;
    }
    setGroupsList(tempList);
    change("groups", tempList);
  };

  const getCustomContactFields = () => {
    Array.from({ length: customFieldsCount }, (value, index) =>
      customFieldData.push({
        value: `Custom Field ${index + 1}`,
        subKey: `CUSTOM_FIELD_${index + 1}`,
        key: `customField${index + 1}`,
        method: handleAction
      })
    );
    return customFieldData;
  };

  const constructCustomFieldsWithValue = () => {
    const customContactFields = getCustomContactFields();
    return customContactFields.map(item => {
      item.value = settingsStoreData?.customFields?.[item?.key] || item?.value;
      item.hasCustomFieldValue = settingsStoreData?.customFields?.[item?.key]
        ? true
        : false;
      return item;
    });
  };

  const isActionDisabled = (actionType = "") => {
    return (
      !trackingStore?.websiteTrackingInstalled ||
      !triggerState?.disabledActionFields.includes(actionType)
    );
  };

  const websiteVisitedFields = [
    {
      value: "Website Visited URL",
      subKey: "WEBSITE_VISITED_URL",
      key: "websiteVisitedUrl",
      method: handleAction,
      disabled: isActionDisabled("WEBSITE_VISITED")
    },
    {
      value: "Website Visited Count",
      subKey: "WEBSITE_VISITED_COUNT",
      key: "websiteVisitedCount",
      method: handleAction,
      disabled: isActionDisabled("WEBSITE_VISITED")
    },
    {
      value: "Has Visited Website",
      subKey: "HAS_VISITED_WEBSITE",
      key: "hasVisitedWebsite",
      method: handleAction,
      disabled: isActionDisabled("WEBSITE_VISITED")
    }
  ];

  const contactFields = [
    {
      value: "First Name",
      subKey: "FIRST_NAME",
      key: "firstName",
      method: handleAction
    },
    {
      value: "Last Name",
      subKey: "LAST_NAME",
      key: "lastName",
      method: handleAction
    },
    {
      value: "Job Title",
      subKey: "JOB_TITLE",
      key: "jobTitle",
      method: handleAction
    },
    {
      value: "Phone Number",
      subKey: "PHONE_NUMBER",
      key: "phoneNumber",
      method: handleAction
    },
    {
      value: "Mobile Number",
      subKey: "MOBILE_NUMBER",
      key: "mobileNumber",
      method: handleAction
    },
    {
      value: "Other Number",
      subKey: "OTHER_NUMBER",
      key: "otherNumber",
      method: handleAction
    },
    {
      value: "Company",
      subKey: "COMPANY",
      key: "company",
      method: handleAction
    },
    {
      value: "Industry",
      subKey: "INDUSTRY",
      key: "industry",
      method: handleAction
    },
    {
      value: "Street",
      subKey: "STREET",
      key: "street",
      method: handleAction
    },
    {
      value: "City",
      subKey: "CITY",
      key: "city",
      method: handleAction
    },
    {
      value: "State",
      subKey: "STATE",
      key: "state",
      method: handleAction
    },
    {
      value: "Country",
      subKey: "COUNTRY",
      key: "country",
      method: handleAction
    },
    {
      value: "Zip Code",
      subKey: "ZIP_CODE",
      key: "zipCode",
      method: handleAction
    },
    {
      value: "Website",
      subKey: "WEBSITE",
      key: "website",
      method: handleAction
    },
    {
      value: "LinkedIn URL",
      subKey: "LINKEDIN_URL",
      key: "linkedInUrl",
      method: handleAction
    },
    {
      value: "Facebook",
      subKey: "FACEBOOK",
      key: "facebook",
      method: handleAction
    },
    {
      value: "Twitter",
      subKey: "TWITTER",
      key: "twitter",
      method: handleAction
    },
    {
      value: "Contact Tags",
      subKey: "CONTACT_TAGS",
      key: "contactTag",
      method: handleAction
    },
    {
      value: "Contact Stage",
      subKey: "STAGE_ID",
      key: "contactStage",
      method: handleAction
    },
    ...websiteVisitedFields,
    ...constructCustomFieldsWithValue()
  ];

  const conditionsFields = [
    {
      value: "Email address",
      subKey: "EMAIL_ADDRESS",
      key: "emailAddress",
      method: handleAction
    },
    {
      value: "Sent count",
      subKey: "SENT_COUNT",
      key: "sentCount",
      method: handleAction
    },
    {
      value: "Open count",
      subKey: "OPEN_COUNT",
      key: "emailDeliveredToTheContact",
      method: handleAction
    },
    {
      value: "Click count",
      subKey: "CLICK_COUNT",
      key: "clickCount",
      method: handleAction
    },
    {
      value: "Reply count",
      subKey: "REPLY_COUNT",
      key: "replyCount",
      method: handleAction
    },
    {
      value: "Active in sequence",
      subKey: "ACTIVE_IN_SEQUENCE",
      key: "activeInASequence",
      method: handleAction,
      disabled: ["CONTACT_OPTEDOUT", "EMAIL_BOUNCED"].some(r => {
        return triggerState?.disabledActionFields.includes(r);
      })
    },
    {
      value: "Completed in sequence",
      subKey: "COMPLETED_IN_SEQUENCE",
      key: "completedInASequence",
      method: handleAction,
      disabled: ["CONTACT_OPTEDOUT", "EMAIL_BOUNCED"].some(r => {
        return triggerState?.disabledActionFields.includes(r);
      })
    },
    {
      value: "Call outcome",
      subKey: "CALL_OUTCOME",
      key: "callOutcome",
      method: handleAction
    },
    ...contactFields
  ];

  const getConditionFieldsObject = () => {
    let conditionFieldsObject = {};
    conditionsFields?.length > 0 &&
      conditionsFields.map(item => {
        conditionFieldsObject = {
          ...conditionFieldsObject,
          [item?.subKey]: item?.value
        };
      });
    return conditionFieldsObject;
  };

  const contains = {
    value: "<span class='conditionSymbol'>&#8717;</span> (Contains)",
    subKey: "CONTAINS",
    key: "triggerOperatorSymbol",
    method: handleAction
  };

  const equals = {
    value: "<span class='conditionSymbol'>&equals;</span> (Equals)",
    subKey: "EQUALS",
    key: "triggerOperatorSymbol",
    method: handleAction
  };

  const notEquals = {
    value: "<span class='conditionSymbol'>&ne;</span> (Not equals)",
    subKey: "NOT_EQUALS",
    key: "triggerOperatorSymbol",
    method: handleAction
  };

  const setOperatorList = [
    {
      value:
        "<span class='conditionSymbol' style='font-size:13px;line-height:20px;vertical-align:bottom;'>{}</span> (Is Set)",
      subKey: "IS_SET",
      key: "triggerOperatorSymbol",
      method: handleAction
    },
    {
      value:
        "<span class='conditionSymbol strikeThrough' style='font-size:13px;line-height:20px;vertical-align:bottom;'>{}</span> (Is Not Set)",
      subKey: "IS_NOT_SET",
      key: "triggerOperatorSymbol",
      method: handleAction
    }
  ];

  const withOperatorList = [
    {
      value:
        "<span class='conditionSymbol' style='font-size:13px;line-height:20px;vertical-align:bottom;'>&#94;</span> (Starts With)",
      subKey: "STARTS_WITH",
      key: "triggerOperatorSymbol",
      method: handleAction
    },
    {
      value:
        "<span class='conditionSymbol' style='font-size:13px;line-height:20px;vertical-align:bottom;'>&#36;</span> (Ends With)",
      subKey: "ENDS_WITH",
      key: "triggerOperatorSymbol",
      method: handleAction
    }
  ];

  const angleOperatorList = [
    {
      value: "<span class='conditionSymbol'>&gt;</span> (Greater than)",
      subKey: "GREATER_THAN",
      key: "triggerOperatorSymbol",
      method: handleAction
    },
    {
      value: "<span class='conditionSymbol'>&lt;</span> (Less than)",
      subKey: "LESS_THAN",
      key: "triggerOperatorSymbol",
      method: handleAction
    }
  ];

  const getContactOperatorList = () => {
    let contactFieldsOperatorList = {};
    contactFields?.length &&
      contactFields.map(item => {
        if (
          ["PHONE_NUMBER", "MOBILE_NUMBER", "OTHER_NUMBER"]?.includes(
            item?.subKey
          )
        ) {
          contactFieldsOperatorList = {
            ...contactFieldsOperatorList,
            [item.subKey]: [contains, equals, ...setOperatorList]
          };
        } else {
          contactFieldsOperatorList = {
            ...contactFieldsOperatorList,
            [item.subKey]: [contains, equals, notEquals, ...setOperatorList]
          };
        }
      });
    return contactFieldsOperatorList;
  };

  const operatorList = {
    EMAIL_ADDRESS: [contains, equals, notEquals, ...setOperatorList],
    SENT_COUNT: [equals, ...angleOperatorList],
    OPEN_COUNT: [equals, ...angleOperatorList],
    CLICK_COUNT: [equals, ...angleOperatorList],
    REPLY_COUNT: [equals, ...angleOperatorList],
    ACTIVE_IN_SEQUENCE: [equals, notEquals],
    COMPLETED_IN_SEQUENCE: [equals, notEquals],
    ...getContactOperatorList(),
    CONTACT_TAGS: [equals, notEquals, ...setOperatorList],
    CALL_OUTCOME: [equals, notEquals, ...setOperatorList],
    STAGE_ID: [equals, notEquals, ...setOperatorList],
    WEBSITE_VISITED_URL: [contains, equals, notEquals, ...withOperatorList],
    HAS_VISITED_WEBSITE: [equals],
    WEBSITE_VISITED_COUNT: [equals, ...angleOperatorList]
  };

  const handleOperatorChange = (key, value, index) => {
    let tempList = [...groupsList];
    tempList[index].conditionGroups.operator = value;
    setGroupsList(tempList);
    change("groups", tempList);
  };

  const handleDelete = (parentIndex, childIndex) => {
    let tempList = [...groupsList];
    tempList[parentIndex].triggerConditions.conditions.splice(childIndex, 1);
    setGroupsList(tempList);
    change("groups", tempList);
  };

  useEffect(() => {
    let newGroupsList = groups?.length ? groups : [defaultGroupObject];
    setGroupsList(newGroupsList);
  }, [groups]);

  useEffect(() => {
    setConditionFieldsObject(getConditionFieldsObject());
  }, [settingsStoreData?.customFields]);

  useEffect(() => {
    settingsStoreData.getCustomFields();
  }, []);

  return (
    <div className="triggerConditions">
      <LabelText name="Conditions" id="conditions" />
      <div className="condtionsBox">
        {(groupsList?.length && (
          <div className="conditionsList">
            {groupsList.map((item, index) => {
              const { triggerConditions = {}, conditionGroups = {} } =
                item || {};
              const { conditions = [] } = triggerConditions || {};
              return (
                <div
                  className="conditionsInputBox"
                  key={`conditionsInputBox${index}`}
                >
                  <LogicalOperator
                    value={conditionGroups.operator}
                    change={handleOperatorChange}
                    index={index}
                  />
                  {(conditions?.length &&
                    conditions.map((list, idx) => {
                      const {
                        fieldName = "",
                        operator = "EQUALS",
                        value = "",
                        name = ""
                      } = list || {};

                      return (
                        fieldName !== "CONTACT_OWNER" && (
                          <div
                            className="conditionsCard"
                            key={`conditionsCard-${index}-${fieldName}-${idx}`}
                            onMouseEnter={() => setHoverIndex(idx)}
                            onMouseLeave={() => setHoverIndex("")}
                          >
                            <span className="verticalDotLine" />
                            <div className="conditionsInputRow">
                              <span className="horizontalDotLine" />
                              <span className="horizontalBorderLine" />
                              <div className="conditionsFields">
                                <FilterDropdown
                                  options={conditionsFields}
                                  showDropdown={
                                    activeDropdownIndex ===
                                    `fieldName-${index}-${idx}`
                                  }
                                  setActiveDropdownIndex={
                                    setActiveDropdownIndex
                                  }
                                  defaultValue={
                                    fieldName
                                      ? conditionFieldsObject?.[fieldName]
                                      : ""
                                  }
                                  label="Select"
                                  referenceId="fieldName"
                                  index={`fieldName-${index}-${idx}`}
                                  hasCustomFieldValue={true}
                                />
                              </div>
                              <div
                                className={`operatorList ${
                                  !fieldName ? "disableInput" : ""
                                }`}
                              >
                                <FilterDropdown
                                  options={operatorList[fieldName]}
                                  showDropdown={
                                    activeDropdownIndex ===
                                    `operator-${index}-${idx}`
                                  }
                                  setActiveDropdownIndex={
                                    setActiveDropdownIndex
                                  }
                                  defaultValue={
                                    operator
                                      ? conditionsOperatorObject?.[operator]
                                      : ""
                                  }
                                  label="Select"
                                  referenceId="operator"
                                  index={`operator-${index}-${idx}`}
                                />
                              </div>
                              {!["IS_SET", "IS_NOT_SET"].includes(operator) && (
                                <div
                                  className={`triggerInputFieldValue ${
                                    !fieldName ? "disableInput" : ""
                                  }`}
                                >
                                  <TriggerConditionInputValue
                                    type={fieldName}
                                    change={handleValueChange}
                                    defaultValue={
                                      [
                                        "ACTIVE_IN_SEQUENCE",
                                        "COMPLETED_IN_SEQUENCE",
                                        "CONTACT_TAGS",
                                        "CALL_OUTCOME",
                                        "STAGE_ID",
                                        "HAS_VISITED_WEBSITE"
                                      ].includes(fieldName)
                                        ? name
                                        : value
                                    }
                                    parentIndex={index}
                                    childIndex={idx}
                                    groups={groups}
                                    contactStageList={contactStageList}
                                  />
                                  {["emailaddress", "email_address"]?.includes(
                                    fieldName?.toLowerCase()
                                  ) && (
                                    <ContactAutoCompleteDropdown
                                      inputText={value}
                                      selectedCallbk={(contact = {}) =>
                                        handleValueChange(
                                          "value",
                                          contact?.email,
                                          index,
                                          idx
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              )}
                              {hoverIndex === idx && (
                                <DeleteRow
                                  deleteCbk={handleDelete}
                                  parentIndex={index}
                                  childIndex={idx}
                                />
                              )}
                            </div>
                          </div>
                        )
                      );
                    })) ||
                    ""}
                  <AddItem name="Add" addCbk={addNewConditions} index={index} />
                </div>
              );
            })}
          </div>
        )) ||
          ""}
      </div>
      {error && <span className="errMsgText">{error}</span>}
    </div>
  );
};

export default TriggerConditions;
export { TriggerConditions };
