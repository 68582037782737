import React, { useState, useContext, useEffect, createContext } from "react";
import { observer } from "mobx-react";
import { MP_EVENT } from "Static/constant";
import Utils from "Utils/utils";
import { weekDays } from "Static/constant";
import { scheduleDataStore } from "Stores/ScheduleData";
import { SettingContext } from "../Settings";
import CreateOrEditSchedules from "./CreateOrEditSchedules";
import SchedulesLoading from "./SchedulesLoading";
import { getGlobalTimeZones } from "Utils/commonAPI";

const ScheduleHeader = () => {
  const { setIsCreateNewSchedule = () => {} } = useContext(ScheduleContext);
  const { updateBreadCrumb = () => {} } = useContext(SettingContext);

  const createNewSchedule = () => {
    setIsCreateNewSchedule(true);
    Utils.mixpanelTrack(MP_EVENT.ST_SCHEDULE_CREATE_CLICKED, {
      pageType: "Schedule Page"
    });
    updateBreadCrumb("Schedules - New");
  };

  return (
    <div className="headingWrapper">
      <div className="headingText">
        <span className="title">Schedules</span>
      </div>
      <div
        className={`buttonCont ${
          scheduleDataStore.scheduleDataLoading ? "disabled" : ""
        }`}
        onClick={createNewSchedule}
      >
        <span className="buttonText">New Schedule</span>
      </div>
    </div>
  );
};

const ScheduleTable = props => {
  const { setIsEditSchedule = () => {}, setEditScheduleId = () => {} } =
    useContext(ScheduleContext);
  const { updateBreadCrumb = () => {} } = useContext(SettingContext);
  const { item = {} } = props;

  const editSchedules = item => {
    setIsEditSchedule(true);
    setEditScheduleId(item);
    updateBreadCrumb(`Schedules - ${item.name}`);
  };

  return (
    <div
      className="individualScheduleWrapper"
      onClick={() => editSchedules(item)}
    >
      <ScheduleInformation {...item} />
    </div>
  );
};

const ScheduleInformation = props => {
  const {
    name = "",
    default: isDefault = false,
    dailyTimeRange = {},
    timezoneName = ""
  } = props;

  const getMatchedDate = (dailyTimeRange = {}) => {
    const tempArray = [];
    for (let key in dailyTimeRange) {
      tempArray.push(weekDays?.[key]?.short);
    }
    return tempArray.join(", ");
  };

  return (
    <>
      <div className="scheduleNameWrapper">
        <div className="scheduleName">{name}</div>
        {isDefault && <span className="defaultValue">Default</span>}
      </div>
      <div className="scheduleDates">{getMatchedDate(dailyTimeRange)}</div>
      <div className="scheduleTimeZone">{timezoneName}</div>
    </>
  );
};

const Schedules = () => {
  const [isCreateNewSchedule, setIsCreateNewSchedule] = useState(false);
  const [isEditSchedule, setIsEditSchedule] = useState(false);
  const [editScheduleId, setEditScheduleId] = useState("");

  const { updateBreadCrumb = () => {} } = useContext(SettingContext);

  const hideCbk = () => {
    updateBreadCrumb("Schedules");
    setIsCreateNewSchedule(false);
    setIsEditSchedule(false);
  };

  useEffect(() => {
    getGlobalTimeZones();
    scheduleDataStore.setScheduleDataLoading(true);
    scheduleDataStore.getScheduleData();
    Utils.mixpanelTrack(MP_EVENT.ST_SCHEDULE_VIEWED, {
      pageType: "Schedule Page"
    });
  }, []);

  return (
    <>
      {!isCreateNewSchedule && !isEditSchedule ? (
        <ScheduleContext.Provider
          value={{
            isCreateNewSchedule,
            setIsCreateNewSchedule,
            isEditSchedule,
            setIsEditSchedule,
            editScheduleId,
            setEditScheduleId
          }}
        >
          <div className="schedulesWrapper">
            <div className="schedulesCont">
              <ScheduleHeader />
              <div className="schedulesTable">
                {!scheduleDataStore.scheduleDataLoading ? (
                  scheduleDataStore.scheduleData?.data?.map((item, index) => {
                    return (
                      <ScheduleTable
                        key={`scheduleWindowSetting${index}`}
                        item={item}
                        index={index}
                      />
                    );
                  })
                ) : (
                  <SchedulesLoading />
                )}
              </div>
            </div>
          </div>
        </ScheduleContext.Provider>
      ) : isEditSchedule ? (
        <CreateOrEditSchedules
          hideCbk={hideCbk}
          item={editScheduleId}
          isFromEdit={true}
        />
      ) : isCreateNewSchedule ? (
        <CreateOrEditSchedules hideCbk={hideCbk} />
      ) : (
        <></>
      )}
    </>
  );
};

const ScheduleContext = createContext({});

export { Schedules, ScheduleContext };
export default observer(Schedules);
