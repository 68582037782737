import React, { useState, useEffect } from "react";
import { getCustomFieldTokens, TOKENS } from "Static/constant";
import { SENDER_TOKENS } from "Model/model";
import { settingsStoreData } from "Stores/settingsData";
import { userDetail } from "Stores/userDetail";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import SearchInputBox from "./SearchInputBox";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import PaywallUtils from "Utils/PaywallUtils";

const variableTab = [
  {
    name: "contact",
    field: TOKENS
  },
  {
    name: "sender",
    field: SENDER_TOKENS
  }
];

const TokenVariable = (props = {}) => {
  const {
    customFieldsCount = 0,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const { tokenCbk, closeCbk, pageType = "" } = props || {};

  const customFieldObj = settingsStoreData.customFields;
  const senderTokenObj = settingsStoreData.userProfileData;

  const { customFieldsEnabled = false } = userDetail?.userFeatureFlag || {};
  const [activeTab, setActiveTab] = useState("contact");
  const [activeTabList, setActiveTabList] = useState(TOKENS);
  const [clearSearchInput, setClearSearchInput] = useState(false);
  const [renderArray, setRenderArray] = useState([]);

  const checkCustomFieldTokenLabel = item => {
    return customFieldObj?.[item?.key] || item?.text || "";
  };

  const getPageModel = () => {
    if (pageType === "templates") {
      return "templatesCustomfieldToken";
    } else {
      return "sequencesCustomfieldToken";
    }
  };

  const handleCustomVariableClick = e => {
    e.preventDefault();
    closeCbk();
    let typeValue = getPageModel();
    PaywallUtils.upgradeUtilsFunction(typeValue, false, planName, inFreePlan);
  };

  const searchByValue = val => {
    const lowercasedValue = val?.toLowerCase() || "";
    if (lowercasedValue === "") {
      setRenderArray(activeTabList);
    } else {
      const filteredData = activeTabList.filter(item => {
        return item["value"]
          ?.toString()
          ?.toLowerCase()
          ?.includes(lowercasedValue);
      });
      setRenderArray(filteredData);
    }
  };

  useEffect(() => {
    settingsStoreData.getUserProfile();
    setRenderArray(activeTabList);
  }, [activeTab]);

  return (
    <div className="varibaleContainer">
      <div className="tokenVariables">
        <div className="sharpPointer" />
        <div className="variableTabWrapper">
          {variableTab?.map(tabItem => {
            const { name: tabName = "", field: tabField = {} } = tabItem;
            return (
              <div
                className={`tokenTitle ${
                  tabName === activeTab ? "activeTokenTitle" : ""
                }`}
                key={tabName}
                onClick={() => {
                  setActiveTabList(tabField);
                  setActiveTab(tabName);
                }}
              >
                {tabName}
              </div>
            );
          })}
        </div>
        <div className="optionWrapper">
          <SearchInputBox
            searchIconAlign="right"
            hasCloseIcon={false}
            valueCbk={searchByValue}
            type="text"
            placeholder="Search"
            clearSearchInput={clearSearchInput}
          />
          <div className="optionCont">
            {renderArray?.map((item, index) => {
              const { value = "", key = "" } = item || {};
              const isCustomField = value?.includes("customField");
              const dynamicClassName =
                key === "customvariables"
                  ? !customFieldsEnabled
                    ? "noCustomVariables"
                    : "customVariables"
                  : "";
              const isFreeUser = !customFieldsEnabled && isCustomField;

              // existing - 12 static fields  +  total number of custom fields in 30

              let customFieldCheck = index + 1 > customFieldsCount + 12;

              return (
                <div
                  className={`option ${isFreeUser ? "freeUser" : ""} ${
                    (activeTab === "sender" && !senderTokenObj?.[key]) ||
                    (activeTab === "contact" && customFieldCheck)
                      ? "optionDisabled"
                      : ""
                  }`}
                  key={value}
                  data-name={value}
                  id={value}
                  onClick={
                    dynamicClassName === "customVariables" || isFreeUser
                      ? handleCustomVariableClick
                      : () => {
                          setClearSearchInput(true);
                          setRenderArray(activeTabList);
                          tokenCbk(value);
                        }
                  }
                >
                  <span className={"tokenText"}>{`${checkCustomFieldTokenLabel(
                    item
                  )}`}</span>
                  <span className={"highlightYellow"}>{`${value}`}</span>
                  {customFieldCheck && (
                    <UpgradeIconTooltip
                      plan={PaywallUtils.upgradeUtilsFunction(
                        getPageModel(),
                        false,
                        planName,
                        inFreePlan,
                        {},
                        true
                      )}
                      type={getPageModel()}
                      showIcon={true}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="variableCont-mask" onClick={() => closeCbk(false)} />
    </div>
  );
};

export default TokenVariable;
export { TokenVariable };
