export const invalidCodeConfig = {
  errorCodes: [
    "testmode_decline",
    "transaction_not_allowed",
    "stop_payment_order",
    "stolen_card",
    "service_not_allowed",
    "security_violation",
    "revocation_of_authorization",
    "revocation_of_all_authorizations",
    "restricted_card",
    "reenter_transaction",
    "pickup_card",
    "pin_try_exceeded",
    "offline_pin_required",
    "online_or_offline_pin_required",
    "not_permitted",
    "no_action_taken",
    "new_account_information_available",
    "merchant_blacklist",
    "lost_card",
    "invalid_account",
    "generic_decline",
    "fraudulent",
    "expired_card",
    "do_not_try_again",
    "do_not_honor",
    "currency_not_supported",
    "card_not_supported",
    "call_issuer"
  ],
  errorMsg: "The card has been declined. Please try another card."
};

export const transactionDeclinedConfig = {
  errorCodes: [
    "try_again_later",
    "processing_error",
    "issuer_not_available",
    "invalid_pin",
    "invalid_number",
    "invalid_expiry_year",
    "invalid_expiry_month",
    "invalid_cvc",
    "incorrect_cvc",
    "incorrect_zip",
    "approve_with_id",
    "authentication_required"
  ],
  errorMsg: "Something went wrong. Please retry with the right card details."
};

export const insufficientFundsConfig = {
  errorCodes: [
    "withdrawal_count_limit_exceeded",
    "invalid_amount",
    "insufficient_funds",
    "card_velocity_exceeded"
  ],
  errorMsg:
    "The card has insufficient funds to complete the transaction. Please retry with a different card."
};
