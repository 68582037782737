/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable array-callback-return */
import React from "react";
import { ReactComponent as CircleFilled } from "Assets/svg/tick.svg";
import { ReactComponent as CircleEmpty } from "Assets/svg/circle-empty.svg";
import { withRouter } from "react-router-dom";
import Utils from "Utils/utils";
import { MP_EVENT, IntercomTour } from "Static/constant";
import { HeaderIcon } from "./StepsPopupHeader";

const PopupContent = props => {
  const { onBoardingFlagValues, remainingCount } = props;
  const { firstSequenceUrl } = onBoardingFlagValues;

  const isStepCompleted = stepid => {
    if (stepid) {
      const { isSequenceCreated, isContactsAdded, isEmailAccountConnected } =
        onBoardingFlagValues;
      switch (stepid) {
        case "emailAccount":
          return isEmailAccountConnected ? true : false;
        case "sequence":
          return isSequenceCreated ? true : false;
        case "contacts":
          return isContactsAdded ? true : false;
        default:
          return false;
      }
    }
  };

  const stepsArray = [
    {
      id: "emailAccount",
      name: "Connect your Email Account",
      iscompleted: isStepCompleted("emailAccount"),
      link: "/settings/account",
      videoId: "ro05LdcSBYo",
      mixpanel: MP_EVENT.ON_STEP_EMAIL_CONNECT
    },
    {
      id: "sequence",
      name: "Create your First Sequence",
      iscompleted: isStepCompleted("sequence"),
      link: "/sequences/all",
      videoId: "MyY8okkRpvA",
      mixpanel: MP_EVENT.ON_STEP_SEQUENCE
    },
    {
      id: "sequenceContacts",
      name: "Add Contacts to your Sequence",
      iscompleted: isStepCompleted("contacts"),
      videoId: "jLe4kTdiE_Q",
      link: firstSequenceUrl,
      mixpanel: MP_EVENT.ON_STEP_ADD_CONTACT
    }
  ];

  const redirectCbk = (event, link, videoId, id, iscompleted, mixpanel) => {
    Utils.preventDefaultFn(event);
    if (!iscompleted) {
      let flag = true; // to prevent contact from redirecting if no stage added

      if (id === "contacts" && !onBoardingFlagValues.isSequenceCreated) {
        flag = false;
      }

      if (link && props.history && flag) {
        Utils.showOnboardingStepsPopup(false);
        mixpanel && Utils.mixpanelTrack(mixpanel);
        props.history.push(link);
        showDriver(id, link);
      }
    }
  };

  const showDriver = (id, link) => {
    if (window?.Intercom?.booted && IntercomTour?.[id]) {
      if (props?.location?.pathname?.indexOf(link) !== -1) {
        Utils.initiateIntercom(id);
      } else {
        setTimeout(() => {
          Utils.initiateIntercom(id);
        }, 1200);
      }
      return;
    } else {
      if (props?.location?.pathname?.indexOf(link) !== -1) {
        Utils.initiateDriver(false, id);
      } else {
        setTimeout(() => {
          return Utils.initiateDriver(false, id);
        }, 3000);
      }
    }
  };

  // const youtubeVideoclosed = id => {
  //   Utils.initiateDriver(false, id);
  // };

  // const initiateDemoVideo = (videoId, id) => {
  //   if (videoId) {
  //     youtubeVideoPlayerState.setVideoId(videoId);
  //     youtubeVideoPlayerState.setVideoCloseCbk((e) => youtubeVideoclosed(id));
  //     youtubeVideoPlayerState.setShowPopup(true);
  //   }
  // };

  const getClassNameForSteps = (iscompleted, link) => {
    let className = "steps";
    if (iscompleted) {
      className += " filled";
    }
    if (link === null) {
      className += " disabled";
    }
    return className;
  };

  const getMousePointerAnimation = () => {
    if (window.tempFlag) {
      window.tempFlag = false;
      return (
        <HeaderIcon
          width={30}
          height={"auto"}
          style={{ margin: "0", position: "absolute", right: "40px" }}
        />
      );
    }
  };

  const stepHighlightAnimation = iscompleted => {
    if (!iscompleted) {
      const mousePointerAnimation = getMousePointerAnimation();
      return (
        <>
          {mousePointerAnimation}
          <span className="material-icons forwardArrow">arrow_forward</span>
        </>
      );
    }
  };

  return (
    <div className="popupContent">
      <div className="contentInnerCont">
        {stepsArray?.length > 0 &&
          (window.tempFlag = true) &&
          stepsArray.map((value, key) => {
            const { iscompleted, name, id, link, videoId, mixpanel } = value;
            return (
              <div
                className={getClassNameForSteps(iscompleted, link)}
                key={key}
                id={id}
                onClick={e =>
                  redirectCbk(e, link, videoId, id, iscompleted, mixpanel)
                }
              >
                <span className="circleIcon">
                  {iscompleted ? (
                    <CircleFilled />
                  ) : (
                    <span className="emptyCircle">
                      <CircleEmpty />
                    </span>
                  )}
                </span>
                <span className={`stepsText `}>{name}</span>
                {stepHighlightAnimation(iscompleted)}
              </div>
            );
          })}
      </div>
      <span className="onboardingCompleted">
        {remainingCount === 0 ? (
          <span className="awesome">
            You’re awesome
            <span className="smileyIcon">😀</span>
          </span>
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export { PopupContent };
export default withRouter(PopupContent);
