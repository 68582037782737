import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import DateUtils from "Utils/DateUtils";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import { OutboxUtils } from "Utils/OutboxUtils";

class OutboxStoreData {
  @observable filterObject = {};
  @observable tempFilterList = [];
  @observable filterList = [];
  @observable sequenceData = {
    totalPages: 0,
    currentPage: 0,
    totalElements: 0,
    campaignSequenceResponseList: []
  };
  @observable currentPage = 1;
  @observable activeFilter = { name: "replied", id: "REPLY_COUNT" };
  @observable activeDataInPopup = "";
  @observable outboxFilterData = "";
  @observable outboxPopupContent = "";
  @observable popupEmailContentLoader = true;
  @observable showOutboxReplyContent = false;
  @observable isReloadOutboxPage = false;
  @observable outboxCardPopup = false;
  @observable activeOutboxId = "";
  @observable unreadOutboxCount = 0;
  cancelOutboxCall = null;
  cancelFilterCall = null;
  cancelPopupContentCall = null;

  @action
  setFilterObject = (obj = {}) => {
    this.filterObject = { ...obj };
  };

  @action
  setCurrentPage = (number = 1) => {
    this.currentPage = number;
  };

  @action
  setTempFilterList = (list = []) => {
    this.tempFilterList = [...list];
  };

  @action
  setFilterList = (list = []) => {
    this.filterList = [...list];
  };

  @action
  setRelaodOutboxPage = (value = false) => {
    this.isReloadOutboxPage = value;
  };

  @action
  setActiveFilter = (obj = {}) => {
    this.activeFilter = obj;
  };

  @action
  setOutboxCardPopup = value => {
    this.outboxCardPopup = value;
  };

  @action
  setSequenceData = (value = {}) => {
    this.sequenceData = value;
  };

  @action
  setActiveDataInPopup = value => {
    this.activeDataInPopup = value;
  };

  @action
  setOutboxPopupContent = value => {
    this.outboxPopupContent = value;
  };

  @action
  setPopupEmailContentLoader = value => {
    this.popupEmailContentLoader = value;
  };

  @action
  setShowOutboxReplyContent = value => {
    this.showOutboxReplyContent = value;
  };

  @action
  cancelOutboxCallAction = value => {
    this.cancelOutboxCall = value;
  };

  @action
  cancelFilterCallAction = value => {
    this.cancelFilterCall = value;
  };

  @action
  cancelPopupContentCallAction = value => {
    this.cancelPopupContentCall = value;
  };

  @action
  setActiveOutboxId = value => {
    this.activeOutboxId = value;
  };

  @action
  setUnreadOutboxCount = value => {
    this.unreadOutboxCount = value;
  };

  @action
  setReset = () => {
    this.sequenceData = {
      totalPages: 0,
      currentPage: 0,
      totalElements: 0,
      campaignSequenceResponseList: []
    };
    this.filterList = [];
    this.tempFilterList = [];
    this.currentPage = 1;
    this.activeFilter = { name: "replied", id: "REPLY_COUNT" };
  };

  @action
  markAsRead = async (taskId, getReloadOutboxCardData) => {
    try {
      const config = {
        url: URLS.markOutboxMailAsRead,
        method: "POST",
        data: {
          taskId
        }
      };
      const res = await makeApi(config);
      if (res?.data) {
        if (res?.data?.readStatus === "READ") {
          this.getOutboxUnreadCount();
          getReloadOutboxCardData();
        }
      }
    } catch (err) {
      console.error("Error in marking outbox mail as read", err);
    }
  };

  @action
  getOutboxUnreadCount = async () => {
    try {
      const config = {
        url: `${URLS.unreadCount}`,
        method: "GET"
      };
      const res = await makeApi(config);
      if (res?.data) {
        this.setUnreadOutboxCount(res?.data?.unreadCount);
      }
    } catch (e) {
      console.error("Outbox Unread Count Error", e);
    }
  };

  @action
  getOutboxData = async data => {
    try {
      const config = {
        url: URLS.outbox,
        method: "POST",
        data
      };
      OutboxUtils.cancelPreviousOutboxRequest();
      const res = await makeApi(config, this.cancelOutboxCallAction);
      if (res?.status === 200) {
        return res?.data;
      } else {
        Utils.showApiFailureResponse(res);
      }
    } catch (err) {
      console.error("Error in getting outbox data", err);
    }
  };

  @action
  getOutboxFilterData = async data => {
    try {
      const config = {
        url: URLS.outboxFilter,
        method: "POST",
        data
      };
      OutboxUtils.cancelPreviousOutboxFilterRequest();
      const res = await makeApi(config, this.cancelFilterCallAction);
      if (res?.data) {
        return res?.data;
      } else {
        Utils.showApiFailureResponse(res);
      }
    } catch (err) {
      console.error("Error in getting outbox filter data", err);
    }
  };

  @action
  getOutboxPopupContent = async emailId => {
    try {
      const config = {
        url: `${URLS.outboxPopupContent}/${emailId}`,
        method: "GET"
      };
      OutboxUtils.cancelPopupEmailContentRequest();
      const res = await makeApi(config, this.cancelPopupContentCallAction);
      if (res?.data) {
        this.setPopupEmailContentLoader(false);
        this.setOutboxPopupContent(res?.data);
        return res?.data;
      } else {
        this.setPopupEmailContentLoader(true);
      }
    } catch (e) {
      console.error("Onboarding Started API Failed", e);
    }
  };

  @action
  getSequenceData = async (data = {}) => {
    if (data && Object.keys(data)?.length > 0) {
      const config = {
        url: URLS.listAllTriggerSequences,
        method: "POST",
        data
      };
      let response = await makeApi(config);
      if (response?.data) {
        this.setSequenceData(response?.data);
        return response?.data;
      } else {
        Utils.showApiFailureResponse(response);
        return {};
      }
    }
  };

  @action
  getFiltersPayload = (actionType = "", isFilterCard = false) => {
    const { toAddress = [] } = outboxStoreData?.filterObject || {};
    const { name = "", id = "" } = outboxStoreData?.activeFilter || {};
    let payload = {};
    if (!["cancel", "clear"].includes(actionType)) {
      let memberIds = outboxStoreData.getIds("owners", "memberId");
      let fromAddress = outboxStoreData.getFromAddress();
      let campaignId = outboxStoreData.getIds("sequences", "id");
      let emailType = outboxStoreData.getIds("emailType", "id");
      let tagIds = outboxStoreData.getIds("tags", "id");
      let listIds = outboxStoreData.getIds("listIds", "id");
      let dates = outboxStoreData.getDateFilterPayload();
      if (memberIds?.length > 0) {
        payload.memberIds = memberIds;
      }
      if (fromAddress) {
        payload.fromAddress = fromAddress;
      }
      if (campaignId?.length > 0) {
        payload.campaignId = campaignId;
      }
      if (emailType?.length > 0) {
        payload.emailType = emailType;
      }
      if (tagIds?.length > 0) {
        payload.tagIds = tagIds;
      }
      if (listIds?.length > 0) {
        payload.listIds = listIds;
      }
      if (toAddress?.length > 0) {
        payload.toAddress = toAddress;
      }
      if (dates && Object.keys(dates)?.length) {
        payload = { ...payload, ...dates };
      }
    } else {
      if (userDetail?.userInfo?.memberId) {
        payload.memberIds = [userDetail?.userInfo?.memberId];
      }
    }
    if (name === "delivered") {
      if (!isFilterCard) {
        payload.statuses = [];
        payload.filterKey = "SENT_COUNT";
      }
      payload.all = false;
    } else if (["scheduled", "failed"].includes(name?.toLowerCase())) {
      if (!isFilterCard) {
        payload.statuses = name === "scheduled" ? ["ACTIVE"] : ["ERROR"];
      }
      payload.all = false;
    } else if (name === "all") {
      if (!isFilterCard) {
        payload.statuses = [];
      }
      payload.all = true;
    } else {
      if (!isFilterCard) {
        payload.statuses = [];
        payload.filterKey = id;
      }
      payload.all = false;
    }
    return payload;
  };

  @action
  getIds = (property = "", key = "id") => {
    let arr = [];
    if (property && key) {
      let list = outboxStoreData?.filterObject?.[property] || [];
      if (list?.length > 0) {
        list.forEach(item => {
          if (item?.checked && item[key]) {
            arr.push(item[key]);
          }
        });
      }
      if (
        property?.toLowerCase() === "owners" &&
        !arr?.length &&
        userDetail?.userInfo?.memberId
      ) {
        return [userDetail?.userInfo?.memberId];
      }
    }
    return arr;
  };

  @action
  getFromAddress = () => {
    let emailAddress = "";
    let list = outboxStoreData?.filterObject?.fromAddress || [];
    if (list?.length > 0) {
      list.forEach(item => {
        if (item?.checked) {
          emailAddress = item?.email || "";
        }
      });
    }
    return emailAddress;
  };

  @action
  getDateFilterPayload = () => {
    if (
      outboxStoreData?.filterList?.length > 0 &&
      outboxStoreData?.filterList?.includes("dates")
    ) {
      let list = outboxStoreData?.filterObject?.dates || [];
      if (list?.length > 0) {
        let tempObj = {};
        list.forEach(item => {
          const { fieldName = "", fromDate = "", toDate = "" } = item || {};
          if (fieldName && fromDate && toDate) {
            tempObj[fieldName?.toLowerCase()] = {
              fromDate: DateUtils.getTimeStamp(fromDate, "start"),
              toDate: DateUtils.getTimeStamp(toDate, "end")
            };
          }
        });
        return tempObj;
      }
    }
    return "";
  };
}

export const outboxStoreData = new OutboxStoreData();
