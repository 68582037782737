import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import customListState from "./state";
import AutoCompleteDropdown from "Components/common/AutoCompleteDropdown";
import Utils from "Utils/utils";
import { createEditListPopupState } from "./createEditListPopup";
import { dataSearchState } from "Pages/Dashboard/dataSearch/state";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { popupLoaderState } from "../PopupLoader";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";

const CustomListBodyLoader = () => {
  return Array.from({ length: 10 }, (value, index) => (
    <li className="dropdownOptions optionLoader" key={`${index}`}>
      <>
        <div className="customListLeftSection">
          <span
            className="updatedName linear-background"
            style={{ width: "150px" }}
          />
          <span
            className="createdDate linear-background"
            style={{ width: "150px" }}
          />
        </div>
        <div className="customListRightSection">
          <span
            className="totalContactInList linear-background"
            style={{ width: "20px" }}
          />
          <span
            className="contactsField linear-background"
            style={{ width: "90px" }}
          />
        </div>
      </>
    </li>
  ));
};

const CustomListBody = observer(props => {
  const { name = "", count = 0, createTs = "" } = props || {};
  const createdDate = Utils.formatDate(createTs);
  return (
    <>
      <div className="customListLeftSection">
        <span className="updatedName">{name}</span>
        <span className="createdDate">
          {name === "All" ? "Default List" : `Created on ${createdDate}`}
        </span>
      </div>
      <div className="customListRightSection">
        <span className="totalContactInList">{count}</span>
        <span className="contactsField">Contacts</span>
      </div>
    </>
  );
});

const CreateNewList = observer(props => {
  const { setShowDropdown = () => {} } = props || {};

  const handleCreateNewList = () => {
    setShowDropdown(false);
    createEditListPopupState.setListName("");
    createEditListPopupState.setAction("create");
    createEditListPopupState.setShowPopup(true);
    Utils.mixpanelTrack(MXP_EVENT.CREATE_NEW_LIST_CLICKED, {
      pageType: PAGE_TYPE.discover
    });
  };

  return (
    <div className="createNewListBtn" onClick={handleCreateNewList}>
      <i className="material-icons">add</i>
      <span className="btnText">Create new list</span>
    </div>
  );
});

const CustomList = observer(props => {
  const {
    hasAddNewList = true,
    hasEdit = true,
    hasDelete = true,
    HeaderChildren = "",
    validateOnHeaderClick = "",
    isFromContactPage = false
  } = props || {};

  const [showDropdown, setShowDropdown] = useState(false);

  const customListCbk = async item => {
    if (isFromContactPage) {
      customListState.setActiveList(item);
    } else {
      popupLoaderState.setData({
        message: "Adding contacts to your list. Please wait...",
        type: "addContacts"
      });
      popupLoaderState.setShowPopup(true);
      await dataSearchState.addContactToList(item);
      dataSearchState.setSelectedContactData([]);
      popupLoaderState.setShowPopup(false);
      customListState.setActiveList(item);
    }
  };

  const handleEditCustomList = () => {
    createEditListPopupState.setListName(customListState?.activeList?.name);
    createEditListPopupState.setAction("edit");
    createEditListPopupState.setShowPopup(true);
  };

  const handleDeleteCustomList = () => {
    confirmationPopupState.setPopupValues({
      content:
        "Are you sure you want to delete the list? Contacts in this list are still accessible from 'All Contacts'.",
      actionBtnText: "Yes",
      cancelBtnText: "Cancel",
      loadingMsg: "Please wait...",
      cancelCbk: () => confirmationPopupState.setShowPopup(false),
      callback: () => {
        customListState.deleteContactList(customListState?.activeList);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  useEffect(() => {
    customListState.getContactList();
  }, []);

  return (
    <section className="customListWrapper">
      <div className="customListContainer">
        <AutoCompleteDropdown
          HeaderChildren={HeaderChildren}
          validateOnHeaderClick={validateOnHeaderClick}
          optionList={customListState?.totalContactList}
          defaultValue={customListState?.activeList?.name || "All"}
          cbk={customListCbk}
          loader={!customListState?.totalContactList}
          showIcon={true}
          icon={
            <i className="material-icons-outlined customListIcon">
              format_list_bulleted
            </i>
          }
          updateListBody={true}
          Body={CustomListBody}
          loaderComponent={<CustomListBodyLoader />}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          hasAddNewList={hasAddNewList}
        >
          {hasAddNewList && (
            <CreateNewList
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
            />
          )}
        </AutoCompleteDropdown>
        {hasEdit &&
          customListState?.activeList?.name &&
          customListState?.activeList?.name !== "All" && (
            <i
              className="material-icons list-icon editIcon"
              onClick={handleEditCustomList}
            >
              edit
            </i>
          )}
        {hasDelete &&
          customListState?.activeList?.name &&
          customListState?.activeList?.name !== "All" && (
            <i
              className="material-icons list-icon deleteIcon"
              onClick={handleDeleteCustomList}
            >
              delete
            </i>
          )}
      </div>
    </section>
  );
});

export { CustomList };
export default CustomList;
