import React, { memo } from "react";
import Utils from "Utils/utils";
import {
  QUICK_SELECT_SCHEDULE_HOUR_LIST,
  SCHEDULE_SELECT_VALUE
} from "Pages/Dashboard/settings/Calendar/Constants";
import { calendarState } from "../../../../State";

export const QuickSelectScheduleHour = memo((props = {}) => {
  const { quickSelect = "", updateInputState = () => {} } = props || {};

  const handleAction = (event = "") => {
    let actionType = Utils.performActionByClosestDom(event, "li", "data-key");
    if (actionType) {
      calendarState.setIsCustomWeek(true);
      const newObj = Utils.createObjWithoutRef(
        SCHEDULE_SELECT_VALUE[actionType]
      );
      updateInputState("workingHours", newObj, actionType);
    }
  };

  return (
    <div className="quickSelectScheduleHour">
      <div className="labelTitle">Quick Select</div>
      <ul
        className="quickSelectScheduleHourList"
        onClick={e => handleAction(e)}
      >
        {QUICK_SELECT_SCHEDULE_HOUR_LIST?.map(item => {
          const { id = "", name = "" } = item || {};
          return (
            <li
              className={`scheduleHourOption ${
                quickSelect === id ? "active" : ""
              }`}
              data-key={id}
              key={id}
            >
              <span className="material-icons-outlined scheduleIcon">
                schedule
              </span>
              <span className="scheduleIntervals">{name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default QuickSelectScheduleHour;
