/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import ConstantObj from "Static/constant";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import JumpBallLoader from "./JumpBallLoader";

class YoutubeVideoPlayerState {
  @observable showPopup = false;

  @observable videoId = null;
  @observable videoCloseCbk = null;

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setVideoId = val => {
    this.videoId = val;
  };

  @action
  setVideoCloseCbk = val => {
    this.videoCloseCbk = val;
  };
}

const youtubeVideoPlayerState = new YoutubeVideoPlayerState();

const YoutubeVideoPlayer = () => {
  const videoId = youtubeVideoPlayerState.videoId;
  const videoCloseCbk = youtubeVideoPlayerState.videoCloseCbk;
  const [isVideoloaded, setVideoLoaded] = useState(false);

  const options = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 1,
      rel: 0,
      modestbranding: 1,
      iv_load_policy: 0
    }
  };

  const videoReady = event => {
    setVideoLoaded(true);
  };

  const closeVideo = () => {
    youtubeVideoPlayerState.setShowPopup(false);
    videoCloseCbk();
  };

  useEffect(() => {
    return () => {
      setVideoLoaded(false);
    };
  }, []);

  return youtubeVideoPlayerState.showPopup ? (
    <div className="youtubePlayer">
      <div
        className={`youtubeVideoCont ${!isVideoloaded ? "loadingVideo" : ""}`}
      >
        <YouTube
          videoId={videoId}
          id={videoId}
          className="youtubeVideo  customzoomIn"
          opts={options}
          onReady={e => videoReady(e)}
          containerClassName="videoParent"
          origin={ConstantObj.CLIENT_URL}
        />
        {isVideoloaded && (
          <div
            className="btn closeButton animated customzoomIn"
            onClick={e => closeVideo()}
          >
            Close
          </div>
        )}
        {!isVideoloaded && <ShowLoader />}
      </div>
      <div className="onboarding-popup-mask"></div>
    </div>
  ) : (
    ""
  );
};

const ShowLoader = () => {
  return (
    <div className="popupWrapper onBoardingCompletedPopupWrapper videoLoaderPopup">
      <div className="onboardingCompletedPopup">
        <div className="popup-wrap animatedFast slideInDownTranslate onBoardingcompletedInnerCont">
          <JumpBallLoader />
        </div>
      </div>
    </div>
  );
};

export { YoutubeVideoPlayer, youtubeVideoPlayerState };
export default observer(YoutubeVideoPlayer);
