import React from "react";
import { observer } from "mobx-react-lite";
import DateUtils from "Utils/DateUtils";
import { ReactComponent as TimerIcon } from "Assets/svg/timer.svg";
import { ReactComponent as ScheduledIconDate } from "Assets/svg/outbox/scheduledIconDate.svg";
import trackingStore from "./trackingStore";

const SessionDetails = observer(() => {
  const SessionDetailCard = observer((props = {}) => {
    const {
      pageUrl = "",
      startTime = "",
      durationInSecs = "",
      index = ""
    } = props || {};

    const { date: sessionDate = "" } =
      DateUtils.getFormattedDateAndTime(startTime);

    return (
      <div className="sessionDetailCard">
        <div className="serialNo">{index + 1}</div>
        <div className="sessionDate">
          <ScheduledIconDate />
          <span className="startDate">{sessionDate}</span>
        </div>
        <div className="sessionStartTime">
          <TimerIcon />
          <span className="startTime">
            {DateUtils.getTimeFromTimestamp(startTime)}
          </span>
        </div>
        <div className="sessionLink">{pageUrl}</div>
        <div className="sessionDuration">
          {durationInSecs
            ? DateUtils.getTimeFromSeconds(durationInSecs)
            : "00:00:00"}
        </div>
      </div>
    );
  });

  const SessionDetailCardLoader = ({ length = 6 }) => {
    return Array.from({ length }, (value, index) => (
      <div className="sessionDetailCard">
        <div className="serialNo linear-background" />
        <div className="sessionDate linear-background" />
        <div className="sessionStartTime linear-background" />
        <div className="sessionLink linear-background" />
        <div className="sessionDuration linear-background" />
      </div>
    ));
  };

  return (
    <div className="sessionDetails">
      <div className="headerCont">Session Details</div>
      {!trackingStore?.dataloader ? (
        trackingStore?.sessionDetails?.pageDetails?.length > 0 ? (
          trackingStore?.sessionDetails?.pageDetails.map((item, index) => {
            return <SessionDetailCard {...item} index={index} />;
          })
        ) : (
          <div className="emptySessionDetail">No data found</div>
        )
      ) : (
        <SessionDetailCardLoader />
      )}
    </div>
  );
});

export { SessionDetails };
export default SessionDetails;
