import React, { useState, useEffect } from "react";
import { Calendar } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

export const CalendarDatePicker = (props = {}) => {
  const {
    inputValue = {},
    setInputValue = () => {},
    getFreeSlotByDate = () => {}
  } = props || {};

  const { event = {} } = inputValue || {};

  const [date, setDate] = useState(inputValue?.date || new Date() || "");

  const handleAction = (dateValue = new Date()) => {
    if (dateValue) {
      setDate(dateValue);
      setInputValue({
        ...inputValue,
        ...{ date: dateValue, description: "", slotDateTime: "", guests: [] }
      });
      getFreeSlotByDate(dateValue);
    }
  };

  const calcWeekends = (startDate, endDate) => {
    if (startDate > endDate) return false;
    let activeDayList =
      (event?.workingHours && Object.keys(event?.workingHours)) || [];

    let date = startDate;
    let dates = [];

    while (date < endDate) {
      let day = new Date(date).getDay();
      if (!activeDayList.includes(day === 0 ? "7" : day?.toString())) {
        dates.push(new Date(date));
      }
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  let disabledDates = calcWeekends(
    new Date(),
    addDays(new Date(), parseInt(event?.preBookDays) || 1)
  );

  const getMaxDate = () => {
    if (event?.preBookDays) {
      return addDays(new Date(), parseInt(event?.preBookDays));
    }
    return new Date();
  };

  useEffect(() => {
    setDate(inputValue?.date);
  }, [event]);

  useEffect(() => {
    if (inputValue?.date) {
      getFreeSlotByDate(inputValue?.date);
    }
  }, [inputValue?.date]);

  return (
    <Calendar
      date={date}
      showSelectionPreview={true}
      onChange={handleAction}
      direction="horizontal"
      minDate={new Date()}
      maxDate={getMaxDate()}
      moveRangeOnFirstSelection={{ startDate: date, endDate: getMaxDate() }}
      disabledDates={disabledDates}
    />
  );
};

export default CalendarDatePicker;
