/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeApi, URLS } from "Utils/apiURL";

const ZohoOauth = () => {
  const [isConnected, setConnected] = useState(false);

  const getZohoAuthAccountsInfo = () => {
    try {
      const url = new URL(window.location.href);
      const code = url?.searchParams.get("code");
      const stateVal = url?.searchParams.get("state");
      const accountsServer = url?.searchParams.get("accounts-server");
      !accountsServer && !code
        ? url?.href?.indexOf("access_denied") !== -1
          ? closeWindow()
          : ""
        : "";
      return { accountsServer, code, stateVal };
    } catch (e) {
      closeWindow();
      return null;
    }
  };

  const connectZohoApp = async () => {
    const {
      code = "",
      accountsServer = "",
      stateVal = ""
    } = getZohoAuthAccountsInfo();
    try {
      if (code) {
        const sessionStateVal = sessionStorage?.getItem("zohoOAuthState");
        let authenticateResponse = {};
        if (+sessionStateVal === +stateVal) {
          const config = {
            url: `${URLS.connectZoho}?code=${code}&accounts-server=${accountsServer}`,
            method: "POST"
          };
          authenticateResponse = await makeApi(config);
        }
        const resCode =
          authenticateResponse?.data?.responseCode?.toString() || "500";
        opener?.postMessage({
          code: resCode,
          type: "ZOHO_CONNECT_OAUTH"
        });
        if (+resCode === 200) {
          setConnected(true);
          setTimeout(() => {
            closeWindow();
          }, 1000);
        } else {
          closeWindow();
        }
      } else {
        closeWindow();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const closeWindow = () => {
    window?.close();
  };

  useEffect(() => {
    connectZohoApp();
  }, []);

  return (
    <div className="pageClass">
      <div className="pageCont">
        <div className="connectAppSection">
          {isConnected ? (
            <div>Successfully Connected</div>
          ) : (
            <div>
              <div>Please wait while we connect your Zoho account</div>
              <div style={{ marginTop: "10px" }}> Connecting... </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZohoOauth;
export { ZohoOauth };
