import React from "react";
// import PdfFile from "Assets/png/pdfAtt.png";
// import ImgFile from "Assets/png/imgAtt.png";
import Utils from "Utils/utils";
import AspFile from "Assets/extension/asp.png";
import CssFile from "Assets/extension/css.png";
import CsvFile from "Assets/extension/csv.png";
import DocFile from "Assets/extension/doc.png";
import ExeFile from "Assets/extension/exe.png";
import GifFile from "Assets/extension/gif.png";
import GitFile from "Assets/extension/git.png";
import HtmlFile from "Assets/extension/html.png";
import JpgFile from "Assets/extension/jpg.png";
import JsFile from "Assets/extension/js.png";
import JsonFile from "Assets/extension/json.png";
import MdFile from "Assets/extension/md.png";
import Mp4File from "Assets/extension/mp4.png";
import PdfFile from "Assets/extension/pdf.png";
import PhpFile from "Assets/extension/php.png";
import PngFile from "Assets/extension/png.png";
import PptFile from "Assets/extension/ppt.png";
import PsdFile from "Assets/extension/psd.png";
import RarFile from "Assets/extension/rar.png";
import ShFile from "Assets/extension/sh.png";
import SqlFile from "Assets/extension/sql.png";
import SvgFile from "Assets/extension/svg.png";
import TiffFile from "Assets/extension/tiff.png";
import TxtFile from "Assets/extension/txt.png";
import WavFile from "Assets/extension/wav.png";
import XlsFile from "Assets/extension/xls.png";
import XmlFile from "Assets/extension/xml.png";
import FileImg from "Assets/extension/file.png";

const PreviewFileAttached = ({ attachments = [] }) => {
  const getFileType = (fileName = "") => {
    switch (Utils.getFileExtensionType(fileName)) {
      case "pdf":
        return PdfFile;
      case "png":
        return PngFile;
      case "jpeg":
        return JpgFile;
      case "jpg":
        return JpgFile;
      case "svg":
        return SvgFile;
      case "xls":
        return XlsFile;
      case "xlsx":
        return XlsFile;
      case "xml":
        return XmlFile;
      case "wav":
        return WavFile;
      case "txt":
        return TxtFile;
      case "tiff":
        return TiffFile;
      case "sql":
        return SqlFile;
      case "sh":
        return ShFile;
      case "rar":
        return RarFile;
      case "psd":
        return PsdFile;
      case "ppt":
        return PptFile;
      case "php":
        return PhpFile;
      case "js":
        return JsFile;
      case "json":
        return JsonFile;
      case "md":
        return MdFile;
      case "webm":
        return Mp4File;
      case "html":
        return HtmlFile;
      case "asp":
        return AspFile;
      case "css":
        return CssFile;
      case "csv":
        return CsvFile;
      case "mp4":
        return Mp4File;
      case "doc":
        return DocFile;
      case "docx":
        return DocFile;
      case "exe":
        return ExeFile;
      case "gif":
        return GifFile;
      case "git":
        return GitFile;
      default:
        return FileImg;
    }
  };

  return (
    attachments?.length > 0 && (
      <div className="attachmentFileList">
        {attachments.map((item, index) => {
          return (
            <div
              className="fileCard"
              id={item.id}
              key={`${index + 1}PreviewFileList`}
            >
              <div className="fileIcon">
                <img src={getFileType(item?.attachmentName || "")} alt="" />
                {/* <SvgIcon /> */}
              </div>
              <div className="fileDetails">
                <p className="fileName" title={item?.attachmentName || ""}>
                  {item?.attachmentName || ""}
                </p>
                <p className="fileSize">
                  {Utils.getFileSizeAndType(item?.attachmentSizeInKB || "")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default PreviewFileAttached;
export { PreviewFileAttached };
