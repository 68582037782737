import React from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import BreadCrumbComponent from "Components/common/breadcrumb";
import { FreeTrial } from "Components/common/FreeTrial";
import { KnowMoreButton } from "Components/common/KnowMoreButton";
import Button from "Components/common/Button";
import AddContact from "Pages/Dashboard/contacts/AddContact";

export const HeaderLayout = observer(props => {
  const {
    redirectUrl = "",
    breadcrumbArr = [],
    className = "",
    btnType = "",
    pageType = "",
    addContactBtn = false,
    callbackAction = () => {},
    hideLearnMore = false
  } = props || {};

  const { userFeatureFlag = {}, userInfo = {} } = userDetail || {};
  const { noOfContactsEligible = 0, noOfContactsUsed = 0 } =
    userFeatureFlag || {};

  const remainingContactCount = () => {
    return noOfContactsEligible - noOfContactsUsed;
  };

  const buttonActionList = {
    calls: {
      id: "makeCallBtn",
      name: "makeCallBtn",
      btnText: "Make a Call",
      className: "makeCallBtn",
      loader: false,
      type: "button",
      click: callbackAction
    }
  };

  return (
    <section className="headerSection">
      <span className="wrapFlex">
        {(breadcrumbArr?.length && (
          <BreadCrumbComponent breadCrumbValue={breadcrumbArr} />
        )) ||
          ""}
      </span>
      <div className={`add-new-dropdown ${className}PageHeader`}>
        <FreeTrial {...props} />
        {redirectUrl && !hideLearnMore && (
          <KnowMoreButton redirectUrl={redirectUrl} />
        )}
        {btnType && <Button {...buttonActionList[btnType]} />}
        {addContactBtn && (
          <AddContact
            validContactUpload={remainingContactCount()}
            checkEligibleContactCount={remainingContactCount()}
            pageType
            isFromScheduleSeq={false}
          />
        )}
      </div>
    </section>
  );
});

export default HeaderLayout;
