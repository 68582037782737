import React, { useState } from "react";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import { ReactComponent as LinkedinIcon } from "./svg/linkedIn.svg";
import { ReactComponent as LinkedinPremiumIcon } from "./svg/liPremiumIcon.svg";
import { ReactComponent as EmptyLinkedInIcon } from "./svg/emptyLinkedIn.svg";
import userDetail from "Stores/userDetail";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import PaywallUtils from "Utils/PaywallUtils";
import linkedInUtils from "./common/linkedInUtils";

const ConnectLinkedInButton = observer((props = {}) => {
  const {
    inFreePlan = false,
    planName = "",
    liAutomationEnabled = false
  } = userDetail?.userFeatureFlag || {};

  const [tooltipType, setTooltipType] = useState("");

  const PaywallForButton = observer(props => {
    const { type = "" } = props || {};

    return (
      !liAutomationEnabled && (
        <span className="wrapLockIcon">
          <UpgradeIconTooltip
            plan={PaywallUtils.getPlanName(planName, inFreePlan)}
            type={"linkedInSettings"}
            enableTooltip={true}
            alignTooltip="topRight"
            customToggle={true}
            toggleCbkVal={tooltipType === type}
          />
        </span>
      )
    );
  });

  const linkedInBtnProps = {
    id: "connectLinkedInBtn",
    name: "connectLinkedInBtn",
    btnText: "Connect with LinkedIn",
    type: "button",
    className: "connectLinkedInBtn",
    customIcon: true,
    isLeftSideIcon: true,
    LeftIconName: LinkedinIcon,
    isRightSideIcon: true,
    RightIconName: <PaywallForButton type="linkedin" />,
    click: linkedInUtils.handleButtonClick
  };

  const salesNavigatorBtnProps = {
    id: "connectSNLinkedInBtn",
    name: "connectSNLinkedInBtn",
    btnText: "Connect with Sales Navigator",
    type: "button",
    className: "connectSNLinkedInBtn",
    customIcon: true,
    isLeftSideIcon: true,
    LeftIconName: LinkedinPremiumIcon,
    isRightSideIcon: true,
    RightIconName: <PaywallForButton type="snlinkedin" />,
    click: linkedInUtils.handleSalesNavigatorButtonClick
  };

  return (
    <div className="liConnectWrapper">
      <EmptyLinkedInIcon />
      <h6>Add your LinkedIn account</h6>
      <div className="liButtonWrapper">
        <Button
          {...linkedInBtnProps}
          onMouseEnter={() => setTooltipType("linkedin")}
          onMouseLeave={() => setTooltipType("")}
        />
        <Button
          {...salesNavigatorBtnProps}
          onMouseEnter={() => setTooltipType("snlinkedin")}
          onMouseLeave={() => setTooltipType("")}
        />
      </div>
    </div>
  );
});

export { ConnectLinkedInButton };
export default ConnectLinkedInButton;
