/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

class InputBoxPopupState {
  @observable showPopup = false;

  defaultPopupValue = {
    title: "",
    inputBoxArr: [],
    actionBtnText: "",
    callback() {}
  };

  @observable popupValues = this.defaultPopupValue;

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setPopupValues = val => {
    this.popupValues = val;
  };
}

const inputBoxPopupState = new InputBoxPopupState();

const InputBoxPopup = () => {
  const defaultInputState = {
    folderName: "",
    sequenceName: "",
    sequenceType: "",
    cloneSeqName: ""
  };
  const [inputVal, setInputVal] = useState(defaultInputState);

  const changeInputVal = e => {
    const { currentTarget } = e;
    const { value, id } = currentTarget;
    const stateObj = { ...inputVal };
    stateObj[id] = value;
    // showErrorToolTip(false);
    setInputVal(stateObj);
  };

  const submitAction = e => {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    const isErrorShown = document.getElementsByClassName("errorShown").length;
    if (isErrorShown === 0) {
      // showErrorToolTip(false);
      inputBoxPopupState.popupValues.callback(inputVal);
      inputBoxPopupState.setShowPopup(false);
      setInputVal(defaultInputState);
    }
  };

  return inputBoxPopupState.showPopup ? (
    <div className="popupWrapper">
      <div className="confirmation-popup common-popup">
        <form className="popup-wrap" onSubmit={submitAction} autoComplete="off">
          {inputBoxPopupState.popupValues.title && (
            <div className="title">{inputBoxPopupState.popupValues.title}</div>
          )}
          {inputBoxPopupState.popupValues.inputBoxArr &&
          inputBoxPopupState.popupValues.inputBoxArr.length
            ? inputBoxPopupState.popupValues.inputBoxArr.map(
                (inputObj, key) => {
                  return (
                    <div key={key} className="popup-content-wrap">
                      {inputObj.type === "select" ? (
                        <select
                          id={inputObj.id}
                          onChange={e => changeInputVal(e)}
                          value={inputVal[inputObj.id]}
                          required={true}
                        >
                          <option> -- Select Step --</option>
                          {inputObj.options &&
                            inputObj.options.map((option, idx) => {
                              return (
                                <option
                                  disabled={option.inActive}
                                  key={idx}
                                  value={option.name}
                                >
                                  {option.name}
                                </option>
                              );
                            })}
                        </select>
                      ) : (
                        <input
                          type={inputObj.type}
                          id={inputObj.id}
                          className="input-box"
                          placeholder={inputObj.placeholder}
                          value={inputVal[inputObj.id]}
                          onChange={e => changeInputVal(e)}
                          autoComplete="new-password"
                          required
                          onBlur={
                            inputObj.onblurCallback
                              ? e => inputObj.onblurCallback(inputVal, e)
                              : () => {}
                          }
                        />
                      )}
                      <div
                        className="common-tooltip-error profileErrorMsg"
                        id={`err${inputObj.id}`}
                      >
                        <span className="top-arrow" />
                        {inputObj.errorMsg ? inputObj.errorMsg : ""}
                      </div>
                    </div>
                  );
                }
              )
            : ""}
          <div className="popup-action-wrap">
            <button type="submit" className="popup-action-btn positive-btn">
              {inputBoxPopupState.popupValues.actionBtnText}
            </button>
            <div
              className="popup-action-btn cancelBtn"
              onClick={() => inputBoxPopupState.setShowPopup(false)}
            >
              Cancel
            </div>
          </div>
        </form>
        <div
          className="popup-close"
          title="Click here to close"
          onClick={() => inputBoxPopupState.setShowPopup(false)}
        >
          <i className="material-icons">close</i>
        </div>
      </div>
      <div
        className="common-popup-mask"
        onClick={() => inputBoxPopupState.setShowPopup(false)}
      />
    </div>
  ) : (
    ""
  );
};

export { InputBoxPopup, inputBoxPopupState };
export default observer(InputBoxPopup);
