import React from "react";

const DeleteRow = props => {
  const {
    deleteCbk = () => {},
    parentIndex = "",
    childIndex = "",
    className = "deleteTrigger"
  } = props || {};
  return (
    <i
      className={`material-icons ${className}`}
      onClick={() => deleteCbk(parentIndex, childIndex)}
    >
      delete
    </i>
  );
};

export default DeleteRow;
export { DeleteRow };
