import React, { useContext, useState } from "react";
import Button from "Components/common/Button";
import CheckBoxComponent from "Components/common/CheckBox";
import emailPreferenceStore from "../EmailPreferences/Store";
import { ReactComponent as DownArrow } from "Assets/svg/donw-arrow.svg";
import { EmailReportsContext } from "../EmailPreferences";

export const DropdownWithCheckBox = (props = {}) => {
  const {
    notificationInterval = {},
    group = "EMAIL",
    enabled = false,
    type = "",
    toAddress = [],
    id = ""
  } = props || {};

  const { emailPreference = {}, setEmailPreference = () => {} } =
    useContext(EmailReportsContext) || {};

  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({ ...notificationInterval });

  const isAllIntervalSelected = () => {
    const { daily = false, weekly = false, monthly = false } = options || {};
    return daily && weekly && monthly;
  };

  const compareTwoIntervals = () => {
    if (
      options?.daily === notificationInterval?.daily &&
      options?.weekly === notificationInterval?.weekly &&
      options?.monthly === notificationInterval?.monthly
    ) {
      return true;
    }
    if (!options.daily && !options.weekly && !options.monthly) {
      return true;
    }
    return false;
  };

  const handleChange = (value = false, id = "") => {
    if (id === "all") {
      const newOptions = {
        ...options,
        daily: value,
        weekly: value,
        monthly: value
      };
      setOptions(newOptions);
    } else {
      setOptions({ ...options, [id]: value });
    }
  };

  const toggleDropdown = () => {
    if (enabled) {
      if (showDropdown) {
        setOptions(notificationInterval);
      }
      setShowDropdown(!showDropdown);
    }
  };

  const handleClose = () => {
    if (!loading) {
      setShowDropdown(false);
      setOptions(notificationInterval);
    }
  };

  const optionsList = [
    {
      id: "all",
      name: "All",
      checked: isAllIntervalSelected(),
      cbk: handleChange
    },
    {
      name: "Daily",
      id: "daily",
      checked: options?.daily,
      cbk: handleChange
    },
    {
      name: "Monthly",
      id: "monthly",
      checked: options?.monthly,
      cbk: handleChange
    },
    {
      name: "Weekly",
      id: "weekly",
      checked: options?.weekly,
      cbk: handleChange
    }
  ];

  const getPayload = () => {
    return {
      enabled,
      group,
      type,
      toAddress,
      notificationInterval: {
        ...notificationInterval,
        ...options
      },
      id
    };
  };

  const handleSave = async () => {
    const payload = getPayload();
    setLoading(true);
    const updateEditPreference = (obj = {}) => {
      if (obj && Object.keys(obj)?.length) {
        let tempEmailPreference = { ...emailPreference };
        tempEmailPreference[type] = { ...tempEmailPreference[type], ...obj };
        setEmailPreference(tempEmailPreference);
      } else {
        setOptions(notificationInterval);
      }
      setLoading(false);
      setShowDropdown(false);
    };
    await emailPreferenceStore.editNotification(payload, updateEditPreference);
  };

  const getLabel = () => {
    const obj = { ...options };
    let result = "";
    for (const key in obj) {
      if (key && obj?.[key]) {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        result += capitalizedKey + ", ";
      }
    }
    return result ? result?.slice(0, -2) : "Select";
  };

  const buttonList = [
    {
      id: "cancelBtn",
      name: "cancelBtn",
      btnText: "Cancel",
      type: "button",
      className: `cancelBtn`,
      click: handleClose
    },
    {
      id: "saveBtn",
      name: "saveBtn",
      btnText: "Save",
      type: "button",
      className: `saveBtn ${loading ? "loading" : ""}`,
      click: handleSave,
      loader: loading,
      disabled: compareTwoIntervals()
    }
  ];

  return (
    <div className={`dropdownContainer ${!enabled ? "disabled" : ""}`}>
      <div className={`dropdown ${showDropdown ? "on" : ""}`}>
        <div
          className={`dropdownLabel ${!enabled ? "disabled" : ""}`}
          onClick={toggleDropdown}
        >
          {getLabel()}
          <span className={`arrow ${showDropdown ? "activeArrow" : ""}`}>
            <DownArrow />
          </span>
        </div>
        {showDropdown && (
          <div className="dropdownList">
            {optionsList.map(item => {
              const {
                name = "",
                id = "",
                checked = false,
                cbk = () => {}
              } = item || {};
              return (
                <label className="dropValue" key={id}>
                  <CheckBoxComponent
                    name={name}
                    id={id}
                    checked={checked}
                    cbk={cbk}
                  />
                </label>
              );
            })}
            <div className="dropdownFooter">
              {buttonList?.map((item, index) => (
                <Button key={index} {...item} />
              ))}
            </div>
          </div>
        )}
      </div>
      {showDropdown && (
        <div className="emailOverlayMask" onClick={handleClose} />
      )}
    </div>
  );
};

export default DropdownWithCheckBox;
