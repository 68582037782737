import React from "react";

const ProgressBarLine = () => {
  return (
    <div className="progressBarSlide">
      <span style={{ width: "100%" }}></span>
    </div>
  );
};

export default ProgressBarLine;
export { ProgressBarLine };
