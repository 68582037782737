import React from "react";
import UploadContactIcon from "Assets/png/contactUpload.png";
import DoNotContactDomainUploadIcon from "Assets/png/blockedDomain.png";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { toasterState } from "../toaster";

const CsvUpload = observer(props => {
  const {
    csvUploadCbk,
    settingsFlag = false,
    keyText = "contact"
  } = props || {};

  const changeInputVal = e => {
    const file = e?.target?.files?.[0];
    const allowedFormats = [".csv", ".xls", ".txt"];
    if (file) {
      const fileExtension = "." + file?.name?.split(".").pop().toLowerCase();
      if (allowedFormats.includes(fileExtension)) {
        Utils.preventDefaultFn(e);
        submitAction(e);
      } else {
        toasterState.setToastMsg(
          "Invalid file format. Please select a CSV, Excel, or text file.",
          "failure"
        );
      }
    }
  };

  const submitAction = value => {
    csvUploadCbk(value);
  };

  const downloadLink = () => {
    const link = document.createElement("a");
    const content = `First Name,Last Name,Email Address,Job Title,Phone Number,Mobile Number,Other Number,Company Name,Industry,Street,City,State,Country,Zip code,Website,LinkedIn URL,Twitter,Custom Field 1,Custom Field 2,Custom Field 3
    Surender,Nadarajan,surender@salesgear.io,CEO,650-410-8496,650-410-8496,650-410-8496,Salesgear,Technology,300 Delaware Ave Suite 210-A,Wilmington,Delaware,USA,19801,www.salesgear.io,https://www.linkedin.com/in/surender-nadarajan-a28b24180/,https://twitter.com/im_suren,Custom Text 1,Custom Text 2,Custom Text 3`;
    const blob = new Blob(["\ufeff", content]);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "sample.csv");
    link.click();
  };

  const imageObj = {
    contact: UploadContactIcon,
    doNotContact: DoNotContactDomainUploadIcon
  };

  return (
    <div className="uploadWrapper">
      <div className="fileUploadContainer">
        <div className="imageCont">
          <img src={imageObj[keyText]} alt="" />
        </div>
        <div className="fileUploadWrapper">
          <div className="fileUploadCont">
            <span>Drag and drop or click </span>
            <span className="hereTxt">here</span>
            <input
              className="fileuploadInput"
              id="fileUpload"
              type="file"
              accept=".csv, .xls, .txt"
              onChange={e => changeInputVal(e)}
            />
          </div>
        </div>
      </div>
      {settingsFlag && (
        <div className="sampleFile">
          You can only upload a CSV file upto 100 domains
        </div>
      )}
      {!settingsFlag && (
        <div className="sampleFile">
          To download a sample CSV file, click
          <span className="clickHereLink" onClick={downloadLink}>
            here
          </span>
        </div>
      )}
    </div>
  );
});

export default CsvUpload;
export { CsvUpload };
