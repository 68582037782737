/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import Lottie from "react-lottie";
import PhoneRinging from "Utils/LottieFiles/phoneRinging.json";
import Verified from "Utils/LottieFiles/verified.json";
import { URLS } from "Utils/apiURL";
import dialerStoreData from "Stores/DialerData";
import dialerServiceStore from "Stores/DialerServiceStore";
import CountDownTimer from "Components/common/CountDownTimer";
import { toasterState } from "Components/common/toaster";

const defaultInfoMessage =
  "We are verifying your phone number. Please wait while we process your request.";
const defaultTimerInSecs = 40;
const retryTimerInSecs = 20;

class VerifyNumberPopupState {
  @observable showPopup = false;
  @observable phoneNumber = "";
  @observable code = "";

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setPhoneNumber = (val = "") => {
    this.phoneNumber = val;
  };

  @action
  setCode = (val = "") => {
    this.code = val;
  };
}

const verifyNumberPopupState = new VerifyNumberPopupState();

const VerifyNumberPopup = observer((props = {}) => {
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);
  const [timerInSecs, setTimerInSecs] = useState(defaultTimerInSecs);
  const [infoMessage, setInfoMessage] = useState(defaultInfoMessage);

  const closeAction = () => {
    setVerifying(false);
    setVerified(false);
    setTimerInSecs(defaultTimerInSecs);
    setInfoMessage(defaultInfoMessage);
    verifyNumberPopupState.setCode("");
    verifyNumberPopupState.setPhoneNumber("");
    verifyNumberPopupState.setShowPopup(false);
  };

  const constructVerifiedUrl = (phNumber = "") => {
    if (phNumber) {
      phNumber = phNumber
        .replace(" ", "")
        .replace("--", "")
        .replace("(", "")
        .replace(")", "");
      return `${URLS.isPhoneNumberVerified}/${phNumber}`;
    }
  };

  const isNumberVerified = async () => {
    if (verifyNumberPopupState?.phoneNumber) {
      const url = constructVerifiedUrl(verifyNumberPopupState?.phoneNumber);
      return await dialerStoreData.isCalledIdVerified(url);
    }
  };

  const timerCompleteCbk = async () => {
    setVerifying(true);
    let verificationStatus = await isNumberVerified();
    verificationStatus = verificationStatus?.toLowerCase() || "";
    if (verificationStatus === "success") {
      setVerifying(false);
      setVerified(true);
      await dialerServiceStore.getDialerPhoneList(true);
    } else if (verificationStatus === "failed") {
      closeAction();
      toasterState.setToastMsg(
        "Failed to verify your phone number. Please try again later.",
        "fail"
      );
    } else {
      setInfoMessage(
        "Your phone number is being verified; this may take a few more seconds."
      );
      setTimerInSecs(retryTimerInSecs);
      setVerifying(false);
      setVerified(false);
    }
  };

  useEffect(() => {
    if (verified) {
      setTimeout(() => {
        closeAction();
      }, 3000);
    }
  }, [verified]);

  return verifyNumberPopupState?.showPopup ? (
    <div
      className={`verifyNumberPopup popupWrapper ${
        verifying ? "verifying" : verified ? "verified" : ""
      }`}
    >
      <div className="confirmation-popup common-popup">
        <div className="verifyNumberPopupWrapper popup-wrap animated customzoomIn">
          <div className="newNumberCont">
            {verified && <CloseIcon closeAction={closeAction} />}
            {!verified && (
              <>
                <div className="popupHeader">
                  <div className="popupTitle">Verify a Phone Number</div>
                  <CloseIcon closeAction={closeAction} />
                </div>
                <div className="popupBody">
                  <div className="phoneRining">
                    <PhoneRiningAnimation />
                  </div>
                  <div className="callingTextCont">
                    <span className="salesgearCallingText">
                      Salesgear is calling :{" "}
                      {verifyNumberPopupState.phoneNumber}
                    </span>

                    <div className="codeCont">
                      <span className="codeTxt">Your verification code</span>
                      {verifyNumberPopupState.code ? (
                        <span className="code">
                          {verifyNumberPopupState.code}
                        </span>
                      ) : (
                        <span className="code linear-background"></span>
                      )}
                    </div>
                    <div className="infoCont">
                      <span className="infoText">{infoMessage}</span>
                    </div>
                    {verifying ? (
                      <span className="plsWaitText">
                        Verification in Progress
                      </span>
                    ) : (
                      <div className="countDownCont">
                        <CountDownTimer
                          onCompleteCbk={timerCompleteCbk}
                          seconds={timerInSecs}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {verified && <VerifiedAnimation />}
          </div>
        </div>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

const CloseIcon = props => {
  const { closeAction } = props;
  return (
    <div
      className="popup-close"
      title="Click here to close"
      onClick={closeAction}
    >
      <i className="material-icons">close</i>
    </div>
  );
};

const PhoneRiningAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: PhoneRinging,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Lottie
      options={defaultOptions}
      width={100}
      height={"auto"}
      style={{ margin: "0 auto" }}
      speed={1.5}
    />
  );
};

const VerifiedAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Verified,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="verifiedMsg">
      <Lottie
        options={defaultOptions}
        width={125}
        height={100}
        style={{ margin: "0 auto" }}
        speed={1.5}
      />
      <div className="verifiedMessageCont">
        <span className="verifiedText">
          You have successfully verified and added{" "}
          <span className="verifiedNumber">
            {verifyNumberPopupState.phoneNumber}
          </span>
        </span>
      </div>
    </div>
  );
};

export default VerifyNumberPopup;
export { VerifyNumberPopup, verifyNumberPopupState };
