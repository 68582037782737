import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import BarChartLoader from "Utils/LottieFiles/BarChart.json";
import NoActivityFound from "Pages/Dashboard/reports/NoActivityFound";
import Chart from "react-apexcharts";
import connectedInboxStore from "./store";

const series = [
  {
    name: "Emails Landed in Inbox",
    data: []
  },
  {
    name: "Emails Saved from Spam",
    data: []
  }
];

const options = {
  chart: {
    type: "bar",
    height: 350,
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  colors: ["#28AD56", "#F59064"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  yaxis: {
    labels: {
      formatter: value => {
        return Math.floor(value);
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      horizontal: false
    }
  },
  legend: {
    position: "top",
    horizontalAlign: "right"
  },
  fill: {
    opacity: 1
  }
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: BarChartLoader
};

const EmailSentPerDayChart = observer((props = {}) => {
  const {
    activeDateFilter = "",
    dateObj = {},
    warmingData = {},
    chartDateObj = {}
  } = props || {};
  const { emailMetricsByDate = [] } = warmingData || {};
  const { inboxCount, spamCount, totalCount } =
    connectedInboxStore?.deliverabilityStats || {};
  const [chartData, setChartData] = useState(series);
  const [chartOptions, setChartOptions] = useState(options);

  const dayCountOnActiveFilter = {
    today: 0,
    week: 6, // Doesn't include current day of the week
    month: 29, // Doesn't include current day of the month
    yesterday: 1
  };

  const getCustomDayCount = (value = {}) => {
    const { filterFromDate, filterToDate } = value || {};
    return Utils.getDateDiffNumber(filterFromDate, filterToDate);
  };

  const getDaysFromDateObj = (value = {}) => {
    if (activeDateFilter?.toLowerCase() !== "customdate") {
      return dayCountOnActiveFilter[activeDateFilter];
    } else {
      return getCustomDayCount(value);
    }
  };

  const getChartData = () => {
    if (emailMetricsByDate?.length > 0) {
      return emailMetricsByDate.map(metric => {
        const {
          emailLandedInInbox = 0,
          emailLandedInSpam = 0,
          metricsDate = ""
        } = metric;
        return {
          emailLandedInInbox,
          emailLandedInSpam,
          metricsDate
        };
      });
    }
  };

  const constructChartData = () => {
    const data = getChartData() || [];
    if (data) {
      const tempData = [...chartData];
      tempData.map(item => (item.data = []));
      const tempXaxisDate = [];
      data?.length > 0 &&
        data.forEach(item => {
          tempData[0].data.push(Math.ceil(item?.emailLandedInInbox) || 0);
          tempData[1].data.push(Math.ceil(item?.emailLandedInSpam) || 0);
          tempXaxisDate.push(item.metricsDate);
        });
      const { filterFromDate, filterToDate } = chartDateObj || {};
      const days = getDaysFromDateObj(chartDateObj);
      let startDate = Utils.formatDateHypen(filterFromDate);
      let endDate = Utils.formatDateHypen(filterToDate);
      const hasStartDate = tempXaxisDate.indexOf(startDate) !== -1;
      const hasEndDate = tempXaxisDate.indexOf(endDate) !== -1;
      data?.length > 0 &&
        days > 1 &&
        !hasStartDate &&
        tempXaxisDate.push(startDate);
      data?.length > 0 &&
        days > 1 &&
        !hasEndDate &&
        tempXaxisDate.splice(0, 0, endDate);
      tempData.map(item => {
        data?.length > 0 && days > 1 && !hasStartDate && item.data.push(0);
        data?.length > 0 &&
          days > 1 &&
          !hasEndDate &&
          item.data.splice(0, 0, 0);
      });
      setChartOptions({
        ...options,
        xaxis: {
          type: "datetime",
          categories:
            (days > 1 && tempXaxisDate) ||
            tempXaxisDate.map(item => Utils.formatDate(item)),
          labels: {
            datetimeFormatter: { month: "dd MMM" }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: (days > 20 && "40%") || "20%"
          }
        }
      });
    }
  };

  useEffect(() => {
    constructChartData();
  }, [dateObj, warmingData]);

  return (
    <div className="emailSentCountCont">
      <h4>Warmup emails sent per day</h4>
      {!connectedInboxStore?.warmupLoader &&
      emailMetricsByDate?.length > 0 &&
      (inboxCount || spamCount) ? (
        <div className="emailSentCountChart">
          <Chart
            options={chartOptions}
            series={chartData}
            type="bar"
            height={350}
          />
        </div>
      ) : !connectedInboxStore?.warmupLoader ? (
        <NoActivityFound hasNoActivitySVG={false} />
      ) : (
        <div className="chartLoader">
          <Lottie
            options={defaultOptions}
            width={200}
            height={250}
            style={{ margin: "0 auto" }}
            speed={1}
          />
        </div>
      )}
    </div>
  );
});

export default EmailSentPerDayChart;
export { EmailSentPerDayChart };
