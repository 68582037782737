/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import SequenceTemplateData from "Stores/sequence/SequenceTemplateData";
import Accordian from "Components/common/Accordian";
import { ReactComponent as EmailSvg } from "Assets/svg/email.svg";
import { ReactComponent as PhoneSvg } from "Assets/svg/call.svg";
import { ReactComponent as LnSvg } from "Assets/svg/liIcon.svg";
import { ReactComponent as CustomTaskSvg } from "Assets/svg/customTask.svg";

const StagesCard = () => {
  const [loader, setLoader] = useState(true);
  const [selectedStages, setSelectedStages] = useState([]);

  const getStageIcon = (value = "email") => {
    switch (value) {
      case "email":
        return <EmailSvg />;
      case "phone":
        return <PhoneSvg />;
      case "linkedin":
        return <LnSvg />;
      case "customTask":
        return <CustomTaskSvg />;
      default:
        return <EmailSvg />;
    }
  };

  const getStagesList = steps => {
    setLoader(true);
    if (steps) {
      let filterStages = [];
      for (const [key, value] of Object.entries(steps)) {
        filterStages.push(value);
      }
      setSelectedStages(filterStages);
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  const getDuration = (index, daysLater) => {
    return index === 1
      ? "Today"
      : `${daysLater} Day${daysLater > 1 ? "s" : ""} Later`;
  };

  const getDescText = item => {
    let contentBody = "";
    const { type, notes = "", content = "" } = item;
    switch (type) {
      case "email":
        if (content) {
          contentBody = document
            .createRange()
            .createContextualFragment(content);
        }
        return Utils.addSpaceInHtmlToStringConversion(contentBody);
      case "linkedin":
        return notes;
      default:
        break;
    }
  };

  const getSubject = item => {
    const { subject, type, linkedInTaskType } = item;
    if (type === "linkedin") {
      return Utils.convertTaskTypeEnumToString(linkedInTaskType);
    } else {
      return subject;
    }
  };

  const getContentForType = item => {
    const { type, notes = "", content = "" } = item;
    switch (type) {
      case "email":
        return content;
      case "linkedin":
        return notes;
      default:
        break;
    }
  };

  const contentReadyOnly = item => {
    return getContentForType(item).replace(
      'contenteditable="true"',
      'contenteditable="false"'
    );
  };

  useEffect(() => {
    getStagesList(SequenceTemplateData.selectedPredefinedTemplate.steps);
  }, []);

  useEffect(() => {
    getStagesList(SequenceTemplateData.selectedPredefinedTemplate.steps);
  }, [SequenceTemplateData.selectedPredefinedTemplate]);

  return (
    <div className="stageStepList">
      {(!loader &&
        selectedStages?.length > 0 &&
        selectedStages.map((item, idx) => {
          let totalVariant =
            (item && Object.keys(item["variants"])?.length) || 0;
          return Array.from({ length: totalVariant }, (value, index) => (
            <div className="stageCard" key={`stageDetailInfo${index + 1}`}>
              <div className="stageInfo">
                <div className="stageTypeInfoWrapper">
                  <span className="iconType">
                    {getStageIcon(item["variants"][index + 1].type)}
                  </span>
                  <span className="stageTitle">
                    {`Step ${idx + 1} - ${Utils.firstLetterCapitialize(
                      item["variants"][index + 1].type
                    )}`}
                  </span>
                </div>
                <div className="stageDurtion">
                  {getDuration(
                    index + 1,
                    item["variants"][index + 1].daysLater
                  )}
                </div>
              </div>
              <Accordian
                title={getSubject(item["variants"][index + 1])}
                desc={getDescText(item["variants"][index + 1])}
                contentHtml={contentReadyOnly(item["variants"][index + 1])}
              />
            </div>
          ));
        })) ||
        ""}
      {loader &&
        Array.from({ length: 5 }, (value, index) => (
          <div className="stageCard" key={`stageStepList${index + 1}`}>
            <div className="stageInfo loaderSection">
              <div className="stageTypeInfoWrapper">
                <span className="iconType linear-background"></span>
                <span className="stageTitle linear-background"></span>
              </div>
              <div className="stageDurtion linear-background"></div>
            </div>
            <div className="accordianLoader">
              <div className="subjectTitle linear-background"></div>
              <div className="descBtnWrap">
                <p className="desc linear-background"></p>
                <span className="collapseBtn linear-background"></span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default observer(StagesCard);
export { StagesCard };
