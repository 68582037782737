import React, { useState } from "react";
import { observer } from "mobx-react";
import { ReactComponent as FilterIcon } from "Assets/svg/filter.svg";
import { contactTimelineFilter } from "Static/constant";

const FilterBy = props => {
  const { actionCbk = () => {}, activeFilter = "" } = props || {};
  const [showFilterList, setShowFilterList] = useState(false);

  return (
    <div className={`filterByContactWrapper`}>
      <div
        className={`filterContactTagBtn`}
        onClick={() => setShowFilterList(!showFilterList)}
      >
        <FilterIcon />
        <div className="filterTextWrapper">
          <span>Filter by</span>
          <i className="material-icons dropdownFilterIcon">
            {showFilterList ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </i>
        </div>
      </div>
      {showFilterList && (
        <>
          <div className="filterTagDropdown">
            {(contactTimelineFilter?.length > 0 &&
              contactTimelineFilter?.map((item, index) => {
                const { value = "", label = "" } = item || {};
                return (
                  <div
                    className={`dropdownItem ${
                      activeFilter === value ? "active" : ""
                    }`}
                    onClick={() => actionCbk(value)}
                    key={`${value}-${index}`}
                  >
                    {label}
                  </div>
                );
              })) ||
              ""}
          </div>
          <div
            className="filterTagOverlay"
            onClick={() => setShowFilterList(false)}
          />
        </>
      )}
    </div>
  );
};

export { FilterBy };
export default observer(FilterBy);
