import React from "react";
import { COLOR_CODES } from "Pages/Dashboard/settings/Calls/Constant";
import ActionIcons from "./ActionIcons";
import CallsUtils from "Utils/CallsUtils";

const StageCards = ({ stageList = [] }) => {
  return stageList.map(item => {
    const {
      id = "",
      color = "",
      displayName = "",
      editable = false,
      deleteAllowed = false,
      displayEnabled = false
    } = item || {};

    let tempCode = CallsUtils.getStageColorCodes([...stageList]);
    let colorCodes = [...COLOR_CODES].filter(ele => {
      if (
        tempCode?.length < 1 ||
        (tempCode?.length > 0 && !tempCode.includes(ele)) ||
        ele === color
      ) {
        return ele;
      }
    });

    return (
      <div
        key={id}
        className={`callStageCard ${
          !editable && !deleteAllowed ? "nonEditable" : ""
        } ${!displayEnabled ? "nonActive" : ""}`}
      >
        <div className="wrapStage">
          <span className="colorCode" style={{ background: color }} />
          <span className="stageName">{displayName}</span>
        </div>
        <ActionIcons {...item} colorCodes={colorCodes} />
      </div>
    );
  });
};

export default StageCards;
