import { observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import makeApi, { URLS } from "../Utils/apiURL";

class SlackData {
  @observable slackData = "";
  @observable slackChannels = [];
  @observable nextCursor = "";

  @action
  setNextCursor(value = "") {
    this.nextCursor = value;
  }

  @action
  setSlackData(value) {
    this.slackData = value;
  }

  @action
  setSlackChannels(value) {
    this.slackChannels = value;
  }

  @action
  getSlackData = async () => {
    try {
      const config = {
        url: URLS.getSlackAccountDetails,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response?.data?.responseCode === 200) {
        this.setSlackData(response.data);
      } else {
        this.setSlackData("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  getSlackChannels = async (cbk = () => {}) => {
    if (this.nextCursor !== null) {
      try {
        const config = {
          url: `${URLS.getSlackChannels}${slackDataStore?.nextCursor}`,
          method: "GET"
        };
        let response = await makeApi(config);
        if (response?.data?.responseCode === 200) {
          const { channelList = [], nextCursor = "" } = response.data || {};
          if (this.slackChannels?.length <= 1) {
            this.setSlackChannels([...channelList]);
          } else {
            const slackChannelList = [...slackDataStore?.slackChannels];
            if (slackChannelList?.length > 1) {
              slackChannelList.splice(-1, 1);
            }
            this.setSlackChannels([...slackChannelList, ...channelList]);
          }
          if (nextCursor) {
            this.setNextCursor(nextCursor);
          } else {
            this.setNextCursor(null);
          }
        }
        cbk();
      } catch (err) {
        console.error(err);
      }
    }
  };

  @action
  updateMemberConfig = async data => {
    try {
      const config = {
        url: URLS.updateMemberConfig,
        method: "PUT",
        data
      };
      let response = await makeApi(config);
      if (response?.data?.responseCode === 200) {
        this.setSlackData(response?.data);
        toasterState.setToastMsg("Successfully updated", "success");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "failure"
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  removeSlackAccount = async () => {
    try {
      const config = {
        url: URLS.removeSlackAccount,
        method: "DELETE"
      };
      let res = await makeApi(config);
      if (res?.data?.responseCode === 200) {
        await this.getSlackData();
        toasterState.setToastMsg(
          "Slack account disconnected successfully",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Slack failed to remove error", err);
    }
  };

  @action
  resetSlackChannelList = () => {
    this.nextCursor = "";
    this.slackChannels = [];
  };
}

const slackDataStore = new SlackData();
export { SlackData, slackDataStore };
export default SlackData;
