import React from "react";

const Header = (props = {}) => {
  const { name = "", childComponent = <></> } = props || {};

  return (
    <div className="headerLayout">
      <div className="titleSection">{name}</div>
      {childComponent && <div className="actionSection">{childComponent}</div>}
    </div>
  );
};

export default Header;
