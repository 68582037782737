import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { triggerState } from "Pages/Dashboard/settings/Triggers/TriggerState";
import dialerStoreData from "Stores/DialerData";
import CallsUtils from "Utils/CallsUtils";
import FilterDropdown from "./FilterDropdown";
import SelectSequence from "./SelectSequence";
import InputBox from "Components/common/InputTextBox";
import SelectTags from "./SelectTags";
import UpdateStage from "./UpdateStage";

const TriggerConditionInputValue = observer(props => {
  const {
    type = "",
    change = () => {},
    parentIndex = "",
    childIndex = "",
    defaultValue = "",
    contactStageList = []
  } = props || {};

  let isSequence = ["ACTIVE_IN_SEQUENCE", "COMPLETED_IN_SEQUENCE"].includes(
    type
  );
  let hasTags = ["CONTACT_TAGS"].includes(type);
  let contactStage = ["STAGE_ID"].includes(type);
  let isCallOutcome = ["CALL_OUTCOME"].includes(type);
  let isHasVisitedWebsite = ["HAS_VISITED_WEBSITE"].includes(type);
  let isNumberInput = [
    "SENT_COUNT",
    "OPEN_COUNT",
    "CLICK_COUNT",
    "REPLY_COUNT",
    "WEBSITE_VISITED_COUNT"
  ].includes(type);
  let isEmailInput = ["EMAIL_ADDRESS"].includes(type);
  let inputType = isNumberInput ? "number" : isEmailInput ? "email" : "text";

  const [activeDropdownIndex, setActiveDropdownIndex] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);

  const handleChange = (key, value) => {
    let number = 0;
    if (isNumberInput) {
      const numberRegex = /^[0-9]+$/;
      if (value.match(numberRegex)) {
        number = value;
      } else {
        number = 0;
      }
    }
    setInputValue(number);
    setActiveDropdownIndex(false);
    change(
      isSequence
        ? "sequenceObject"
        : hasTags
        ? "tagValue"
        : isCallOutcome
        ? "callOutcomeObject"
        : contactStage
        ? "contactStage"
        : isHasVisitedWebsite
        ? "hasVisitedWebsite"
        : "value",
      isNumberInput ? number : value,
      parentIndex,
      childIndex
    );
  };

  const inputObject = {
    name: `${inputType}${parentIndex}${childIndex}`,
    id: `${inputType}${parentIndex}${childIndex}`,
    type: inputType,
    value: inputValue || "",
    change: handleChange,
    label: "Value",
    text: "Value",
    minValue: 0
  };

  let options = CallsUtils.getLogOutcomeDropdown(
    dialerStoreData?.stageList,
    handleChange
  );

  const booleanOptions = [
    { value: "True", key: "TRUE", id: "true", method: handleChange },
    { value: "False", key: "FALSE", id: "false", method: handleChange }
  ];

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      {hasTags && (
        <SelectTags
          index={`value-${parentIndex}-${childIndex}`}
          showDropdown={
            activeDropdownIndex === `value-${parentIndex}-${childIndex}`
          }
          activeDropdownIndex={activeDropdownIndex}
          setActiveDropdownIndex={setActiveDropdownIndex}
          label="Value"
          referenceId="conditionsValue"
          change={handleChange}
          defaultValue={defaultValue}
        />
      )}
      {contactStage && (
        <UpdateStage
          index={`value-${parentIndex}-${childIndex}`}
          showDropdown={
            activeDropdownIndex === `value-${parentIndex}-${childIndex}`
          }
          activeDropdownIndex={activeDropdownIndex}
          setActiveDropdownIndex={setActiveDropdownIndex}
          label="Value"
          referenceId="conditionsValue"
          change={handleChange}
          defaultValue={defaultValue}
          contactStageList={contactStageList}
        />
      )}
      {isSequence && (
        <div
          className="sequenceDropdown"
          key={`selectedSequenceConditions-${parentIndex}-${childIndex}`}
        >
          <SelectSequence
            index={`value-${parentIndex}-${childIndex}`}
            options={triggerState?.sequenceList || []}
            showDropdown={
              activeDropdownIndex === `value-${parentIndex}-${childIndex}`
            }
            activeDropdownIndex={activeDropdownIndex}
            setActiveDropdownIndex={setActiveDropdownIndex}
            label="Value"
            referenceId="conditionsValue"
            change={handleChange}
            defaultValue={defaultValue}
          />
        </div>
      )}
      {isCallOutcome && (
        <FilterDropdown
          options={options}
          showDropdown={
            activeDropdownIndex === `value-${parentIndex}-${childIndex}`
          }
          setActiveDropdownIndex={setActiveDropdownIndex}
          defaultValue={defaultValue}
          label="Value"
          referenceId="callOutcomeOptions"
          index={`value-${parentIndex}-${childIndex}`}
        />
      )}
      {isHasVisitedWebsite && (
        <FilterDropdown
          options={booleanOptions}
          showDropdown={
            activeDropdownIndex === `value-${parentIndex}-${childIndex}`
          }
          setActiveDropdownIndex={setActiveDropdownIndex}
          defaultValue={defaultValue}
          label="Value"
          referenceId="hasVisitedOptions"
          index={`value-${parentIndex}-${childIndex}`}
        />
      )}
      {!hasTags &&
        !isSequence &&
        !isCallOutcome &&
        !contactStage &&
        !isHasVisitedWebsite && (
          <div className="inputValue">
            <InputBox {...inputObject} />
          </div>
        )}
    </>
  );
});

export default TriggerConditionInputValue;
export { TriggerConditionInputValue };
