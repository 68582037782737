import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";

class ScheduleDataStore {
  @observable scheduleData = [];
  @observable scheduleDataLoading = true;
  @observable defaultData = {};

  @action
  setScheduleData = value => {
    this.scheduleData = value;
  };

  @action
  setScheduleDataLoading = value => {
    this.scheduleDataLoading = value;
  };

  @action
  setDefaultData = value => {
    this.defaultData = value;
  };

  @action
  getDefaultScheduleData = scheduleWindowId => {
    const tempData = this?.scheduleData?.data || [];
    const filteredData = tempData?.filter(
      item => item.id === scheduleWindowId
    )?.[0];
    this.setDefaultData({ ...filteredData });
  };

  @action
  getScheduleData = async () => {
    try {
      const config = {
        url: URLS.saveScheduleTimeWindow,
        method: "GET"
      };
      const data = await makeApi(config);
      this.setScheduleDataLoading(false);
      this.setScheduleData(data);
    } catch (e) {
      console.error("Failed to get scheduled data", e);
    }
  };
}

const scheduleDataStore = new ScheduleDataStore();
export { ScheduleDataStore, scheduleDataStore };
export default scheduleDataStore;
