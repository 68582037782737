/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import { observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import userDetail from "Stores/userDetail";

class TemplatesStoreData {
  @observable folderList = "";
  @observable showCreateNewFolder = false;
  @observable rowLoader = false;
  @observable folderId = "";
  @observable folderName = "";
  @observable templateData = "";
  @observable templateList = "";
  @observable templateId = "";
  @observable templateFileList = "";
  @observable tempTemplateList = "";
  @observable overviewSelectedTemplate = "";
  @observable editorInsertCounter = 0;

  cancelTemplateCall = null;

  @action
  cancelTemplateCallAction = value => {
    this.cancelTemplateCall = value;
  };

  @action
  setEditorInsertCounter = (value = 0) => {
    this.editorInsertCounter = value;
  };

  @action
  setFolderList = value => {
    this.folderList = value;
  };

  @action
  setRowLoader = value => {
    this.rowLoader = value;
  };

  @action
  setTemplateList = value => {
    this.templateList = value;
  };

  @action
  setTempTemplateList = (value = []) => {
    this.tempTemplateList = value;
  };

  @action
  setOverviewSelectedTemplate = (value = {}) => {
    this.overviewSelectedTemplate = value;
  };

  @action
  getAllFolderTemplates = async () => {
    try {
      const config = {
        url: URLS.getAllTemplatesFolder,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.data?.length > 0) {
        this.folderList = res.data;
      } else {
        this.folderList = [];
      }
    } catch (err) {
      console.error(err, "Get All Templates Folder Error");
    }
  };

  @action
  getFolderDetails = id => {
    if (id !== undefined && id !== "all") {
      if (this.folderList.length > 0) {
        this.folderList.map(res => {
          if (res.id === id) {
            this.folderName = res.folderName;
          }
        });
      }
    }
  };

  @action
  getAllTemplates = async (data, cbk) => {
    try {
      const config = {
        url: URLS.getAllTemplates,
        method: "POST",
        data
      };

      Utils.cancelPreviousTemplateRequest();
      let res = await makeApi(config, this.cancelTemplateCallAction);
      if (res?.data?.statusCode !== 404) {
        this.templateData = res?.data || {};
        if (res?.data?.totalTemplates > 0) {
          this.templateList = res.data.templates;
          cbk && cbk(data);
        } else {
          cbk && cbk();
          this.templateList = [];
        }
      } else if (res?.data?.statusCode === 404) {
        cbk && cbk();
        this.templateData = res.data;
        this.templateList = [];
      } else {
        cbk && cbk();
        this.templateData = {};
        this.templateList = [];
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  addNewTemplates = data => {
    if (this.templateList !== "") {
      const existingTemplates = JSON.parse(JSON.stringify(this.templateList));
      existingTemplates.unshift(data);
      this.templateList = existingTemplates;
    } else {
      this.templateList = [data];
    }

    this.templateData.totalPages += 1;
    if (this.templateList.length > 9) {
      this.templateList.pop();
    }
  };

  @action
  saveTemplates = async (data = {}, cbk = () => {}) => {
    const { attachments } = data;
    try {
      const config = {
        url: URLS.createTemplate,
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (res?.status === 200 && res?.data?.length > 0) {
        this.addNewTemplates(res?.data?.[0]);
        Utils.mixpanelTrack(MP_EVENT.TP_ADDED, {
          templateName: res?.data?.[0]?.name || "",
          pageType: "Templates",
          attachmentUsed: Utils.arrayHasObject(attachments)
        });
        cbk && cbk(false, res?.data?.[0]);
        !cbk && (TemplateDataStore.rowLoader = cbk);
        userDetail.userFeatureFlag.noOfTemplatesUsed += 1;
        this.setOverviewSelectedTemplate({});
        toasterState.setToastMsg(`Template created successfully`, "success");
        return;
      } else if (res?.response?.status === 426) {
        Utils.showUpgradePopup("templates", true, true);
      } else {
        Utils.showApiFailureResponse(res);
      }
      this.setOverviewSelectedTemplate({});
      cbk && cbk(false);
    } catch (err) {
      console.error(err);
    }
  };

  @action
  updateTemplateList = data => {
    if (this.templateList !== "") {
      let foundIndex = this.templateList.findIndex(x => x.id === data.id);
      const existingData = JSON.parse(JSON.stringify(this.templateList));
      existingData[foundIndex] = data;
      this.templateList = existingData;
    } else {
      this.templateList = [data];
    }
  };

  @action
  updateTemplates = async (data = {}, cbk = () => {}) => {
    const { attachments = [] } = data || {};
    try {
      const config = {
        url: URLS.updateTemplate,
        method: "PUT",
        data
      };
      let res = await makeApi(config);
      if (res?.status === 200 && res?.data?.length > 0) {
        this.updateTemplateList(res.data[0]);
        Utils.mixpanelTrack(MP_EVENT.TP_UPDATED, {
          templateName: res.data[0].name,
          pageType: "Templates",
          attachmentUsed: Utils.arrayHasObject(attachments)
        });
        toasterState.setToastMsg("Template updated successfully", "success");
        fileAttachmentStore.setAddFileAttachmentList("");
        fileAttachmentStore.clearAttachmentList();
        cbk && cbk(true, data);
      } else if (res?.response?.status === 403) {
        toasterState.setToastMsg(
          "You don't have editing access to this template",
          "failure"
        );
        cbk && cbk(false);
      } else {
        toasterState.setToastMsg("Template update failed", "failure");
        cbk && cbk(false);
      }
      this.setOverviewSelectedTemplate({});
    } catch (err) {
      console.error(err);
    }
  };

  @action
  deleteTemplateFromList = (id = "") => {
    let foundIndex = this.templateList.findIndex(x => x.id === id);
    const existingTemplates = JSON.parse(JSON.stringify(this.templateList));
    existingTemplates.splice(foundIndex, 1);
    this.templateList = existingTemplates;
  };

  @action
  deleteTemplate = async (cbk, buttonLoadingCbk) => {
    try {
      const config = {
        url: URLS.deleteTemplate ? URLS.deleteTemplate + this.templateId : "",
        method: "DELETE"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        cbk && buttonLoadingCbk && cbk(buttonLoadingCbk);
        this.deleteTemplateFromList(this.templateId);
        userDetail.userFeatureFlag.noOfTemplatesUsed -= 1;
        toasterState.setToastMsg(`Template deleted successfully`, "success");
      } else if (res?.response?.status === 403) {
        cbk && buttonLoadingCbk && cbk(buttonLoadingCbk);
        toasterState.setToastMsg(
          "You don't have deleting access to this template",
          "failure"
        );
      } else {
        toasterState.setToastMsg(`Template deletion failed`, "fail");
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  updateTemplateFolderName = async (data, editedFolderName) => {
    try {
      const config = {
        url: URLS.updateTemplateFolderName,
        method: "PUT",
        data
      };
      let res = await makeApi(config);
      const { status } = res;
      if (res?.data?.folderName && status === 200) {
        toasterState.setToastMsg("Folder name updated successfully", "success");
        return this.updateFolderName(res.data);
      } else if (res && res.response && res.response.status === 409) {
        toasterState.setToastMsg(
          `${editedFolderName} is already taken. Please choose a different name`,
          "failure"
        );
      } else {
        Utils.showApiFailureResponse(res);
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  getTemplatesWithFolder = async () => {
    try {
      const config = {
        url: URLS.getTemplatesWithFolder ? URLS.getTemplatesWithFolder : "",
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.data) {
        this.templateFileList = res.data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  updateFolderName = respData => {
    const { folderName, id } = respData;
    this.folderList &&
      this.folderList.map(value => {
        if (value?.id === id) {
          value.folderName = folderName;
        }
      });
    return this.folderList;
  };

  @action
  getSharedTemplates = async (data, cbk) => {
    try {
      const config = {
        url: URLS.getSharedTemplates ? URLS.getSharedTemplates : "",
        method: "POST",
        data
      };
      Utils.cancelPreviousTemplateRequest();
      let res = await makeApi(config, this.cancelTemplateCallAction);
      if (res?.data?.statusCode === 200 || res?.data?.status === 200) {
        this.templateData = res?.data || {};
        if (res?.data?.totalTemplates > 0) {
          this.templateList = res.data.templates;
          cbk(true, res.data.templates);
        } else {
          this.templateList = [];
          cbk(false);
        }
      } else {
        cbk(false);
        this.templateData = {};
        this.templateList = [];
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  deleteTemplateFolder = async (folderId, cbk, loadingCbk) => {
    try {
      const config = {
        url: URLS.deleteTemplateFolder
          ? `${URLS.deleteTemplateFolder}/${folderId}`
          : "",
        method: "DELETE"
      };
      let res = await makeApi(config);
      if (res?.data?.requestStatus) {
        cbk(folderId, loadingCbk);
      } else {
        toasterState.setToastMsg(
          "Failed deleting the folder and its templates",
          "failure"
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  getTemplatesBySorting = async (data, cbk) => {
    try {
      const config = {
        url: URLS.sortByTemplateName,
        method: "POST",
        data
      };
      const res = await makeApi(config);
      if (res?.data?.templates?.length) {
        cbk(data.direction, true, res.data.templates);
      } else {
        Utils.showApiFailureResponse(res);
        cbk(data.direction, false);
      }
    } catch (err) {
      console.error("Error in sorting templates", err);
    }
  };
}

const TemplateDataStore = new TemplatesStoreData();
export { TemplateDataStore, TemplatesStoreData };
export default TemplateDataStore;
