import React, { useState } from "react";
import Tooltip from "Components/common/CustomTooltip";

export const InputTextBox = props => {
  const {
    id = "",
    keyValue = "",
    name = "",
    type = "",
    value = "",
    text = "",
    err = "",
    dynamicText = "",
    className = "",
    showDom = true,
    isPassword = false,
    readOnly = false,
    autoFocus = false,
    mandatory = false,
    placeHolder = "",
    required = false,
    defaultKey = false,
    inputBoxRef = null,
    minValue = 0,
    parentIndex = "",
    childIndex = "",
    tabIndex = 0,
    infoText = "",
    showInfoIcon = false,
    change = () => {},
    cbk = () => {},
    onFocus = () => {},
    showPasswordAction = () => {},
    onBlur = () => {},
    onSelectCapture = () => {}
  } = props || {};

  const [showToolTip, setShowToolTip] = useState(false);

  const changeValue = async event => {
    change &&
      change(
        defaultKey ? keyValue : id,
        event.currentTarget.value,
        parentIndex,
        childIndex
      );
  };

  return (
    showDom && (
      <div className={`inputWrapper ${id || ""}`}>
        <div
          className={`inputCont ${isPassword ? "wrapInputIcon" : ""} ${
            (readOnly && "readOnlyInput") || ""
          }`}
        >
          <input
            id={id}
            placeholder={placeHolder}
            autoFocus={autoFocus}
            autoComplete="off"
            name={name}
            className={`inputBox ${className} ${
              (isPassword && value?.password?.length) || value?.length
                ? "filled"
                : ""
            }`}
            type={isPassword && value?.showPassword ? "text" : type}
            value={isPassword ? value?.password : value || ""}
            readOnly={readOnly}
            min={minValue}
            required={typeof required !== undefined ? required : true}
            ref={inputBoxRef}
            tabIndex={tabIndex}
            onChange={e => changeValue(e)}
            onKeyUp={e => cbk && cbk(e)}
            onFocus={e => onFocus && onFocus(e)}
            onBlur={e => onBlur(e)}
            onSelectCapture={e => onSelectCapture(e)}
          />
          {!readOnly && <span className="focusBorder"></span>}
          {text && (
            <label htmlFor={id} className={`inputLabel`}>
              {text}
              {mandatory && <span className="required">*</span>}
              {showInfoIcon && (
                <span className="infoIcon">
                  <i
                    className="material-icons"
                    onMouseOver={() => setShowToolTip(true)}
                    onMouseLeave={() => setShowToolTip(false)}
                  >
                    info_outline
                  </i>
                  {showToolTip && <Tooltip text={infoText} />}
                </span>
              )}
            </label>
          )}
          {isPassword && value?.password && (
            <span
              className="material-icons-outlined eyeIcon"
              onClick={showPasswordAction}
            >
              {value?.showPassword ? "visibility" : "visibility_off"}
            </span>
          )}
        </div>
        <span
          className="errMsgText"
          dangerouslySetInnerHTML={{
            __html: err || ""
          }}
        />
        {dynamicText && (
          <div
            className="dynamicText"
            dangerouslySetInnerHTML={{
              __html: dynamicText || ""
            }}
          />
        )}
      </div>
    )
  );
};

export default InputTextBox;
