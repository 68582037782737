import { observable, action } from "mobx";
import userDetail from "Stores/userDetail";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import filterComponentState from "../../tasks/FilterComponent/filterComponentState";
import DashboardState from "../mainState";
import DateUtils from "Utils/DateUtils";

let cancelTableRequest = null;
let cancelFilterRequest = null;

class TaskModuleState {
  @observable taskData = {};
  @observable filter = null;
  @observable totalPages = 0;
  @observable currentPage = 1;
  @observable taskDataLoading = true;
  @observable activeTab = "total";
  @observable taskFilterData = [];
  @observable activeStatusTab = "due";
  @observable filterLoading = true;

  @action
  setFilter = value => {
    this.filter = value;
  };

  @action
  setTaskData = value => {
    this.taskData = value;
  };

  @action
  setTotalPages = value => {
    this.totalPages = value;
  };

  @action
  setCurrentPage = value => {
    this.currentPage = value;
  };

  @action
  setTaskDataLoading = value => {
    this.taskDataLoading = value;
  };

  @action
  setActiveTab = value => {
    this.activeTab = value;
  };

  @action
  setFilterLoading = value => {
    this.filterLoading = value;
  };

  @action
  getTaskData = async (
    filter,
    pageNum,
    resetFilter,
    isLazyLoad,
    filterByDate = this.dateFilterValue,
    status = this.statusFilterValue
  ) => {
    try {
      const { memberId = "", team = false } = userDetail?.userInfo || {};
      resetFilter && this.setFilter(null);
      filter && this.setFilter(filter);
      let pageNo = pageNum ? pageNum : 1;
      const data = {
        pageNo,
        limit: 10,
        taskType:
          this.filter === "email"
            ? ["email", "manualEmail"]
            : this.filter
            ? [this.filter]
            : null,
        timeZone: Utils.getCurrentUserTimeZone(),
        status: status?.status || "ACTIVE",
        sequenceIds:
          filterComponentState.filterComponentData["sequenceIds"] || [],
        tagIds: filterComponentState.filterComponentData["tagIds"] || [],
        contactStage:
          filterComponentState.filterComponentData["contactStage"] || [],
        contactFields:
          filterComponentState.filterComponentData["contactFields"] || []
      };
      if (DashboardState?.memberId?.length > 0) {
        data.memberIds = DashboardState.memberId;
      } else {
        if (!team) {
          data.memberIds = [memberId];
        }
      }
      if (this.activeStatusTab === "due") {
        data.dateFilter = {
          ...data.dateFilter,
          toDate: DateUtils.getTimeStamp(new Date(), "end")
        };
      }
      if (filterByDate) {
        data.dateFilter = {
          ...(filterByDate.filterFromDate && {
            fromDate: DateUtils.getTimeStamp(
              filterByDate.filterFromDate,
              "start"
            )
          }),
          ...(filterByDate.filterToDate && {
            toDate: DateUtils.getTimeStamp(filterByDate.filterToDate, "end")
          })
        };
        data.timeZone = filterByDate.timeZone || Utils.getCurrentUserTimeZone();
      }
      filterComponentState.setFilterComponentData({
        ...filterComponentState.filterComponentData,
        dateFilter: data?.dateFilter
      });
      let config = {
        url: URLS.getTaskFilter || "",
        method: "POST",
        data
      };
      if (cancelTableRequest !== null) {
        cancelTableRequest("cancel");
        cancelTableRequest = null;
      }
      const advCancelCallback = value => {
        cancelTableRequest = value;
      };
      let response = await makeApi(config, advCancelCallback);
      taskModuleState.setTaskDataLoading(false);
      if (response?.data) {
        response.data["limit"] = this.limit;
        const {
          currentPage = 1,
          totalPages = 1,
          tasks = []
        } = response?.data || {};
        this.currentPage = currentPage;
        this.totalPages = totalPages;
        if (isLazyLoad) {
          const duplicateTaskData = JSON.parse(JSON.stringify(this.taskData));
          if (duplicateTaskData?.tasks && tasks?.length) {
            duplicateTaskData.currentPage = currentPage;
            const taskArr = [...duplicateTaskData.tasks, ...tasks];
            duplicateTaskData.tasks = taskArr;
            this.taskData = duplicateTaskData;
          }
        } else {
          this.taskData = response.data;
        }
      } else Utils.showApiFailureResponse(response);
    } catch (error) {
      console.error("All Contact error ", error);
    }
  };

  @action
  getTaskFilterData = async (params = {}) => {
    try {
      const { memberId = "", team = false } = userDetail?.userInfo || {};
      const config = {
        url: URLS.getTaskAnalytics || "",
        method: "POST"
      };
      if (params) {
        config.data = params || {};
      }
      config.data = {
        ...config.data,
        status: "ACTIVE",
        timeZone: Utils.getCurrentUserTimeZone(),
        pageNo: 1,
        sequenceIds:
          filterComponentState.filterComponentData["sequenceIds"] || [],
        tagIds: filterComponentState.filterComponentData["tagIds"] || [],
        contactStage:
          filterComponentState.filterComponentData["contactStage"] || [],
        contactFields:
          filterComponentState.filterComponentData["contactFields"] || []
      };
      if (DashboardState?.memberId?.length > 0) {
        config.data.memberIds = DashboardState.memberId;
      } else {
        if (!team) {
          config.data.memberIds = [memberId];
        }
      }
      if (this.activeStatusTab === "due") {
        config.data = {
          ...config.data,
          toDate: DateUtils.getTimeStamp(new Date(), "end")
        };
      }
      if (cancelFilterRequest !== null) {
        cancelFilterRequest("cancel");
        cancelFilterRequest = null;
      }
      const advCancelCallback = value => {
        cancelFilterRequest = value;
      };
      let res = await makeApi(config, advCancelCallback);
      this.setFilterLoading(false);
      if (res?.data) {
        this.taskFilterData = res.data.analytics;
      } else Utils.showApiFailureResponse(res);
    } catch (error) {
      console.error("task filter Data error ", error);
    }
  };
}

const taskModuleState = new TaskModuleState();
export { taskModuleState };
export default taskModuleState;
