/* eslint-disable import/no-unresolved */
import React from "react";
import { withRouter } from "react-router-dom";
/* eslint-disable import/no-unresolved */

const HeaderNotification = props => {
  const { className = "", message = "" } = props;
  return (
    <div className="headerGlobalNotification">
      <div
        className={`message ${className}`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: message || ""
        }}
      />
    </div>
  );
};

export { HeaderNotification };
export default withRouter(HeaderNotification);
