/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import DialerServiceData from "Stores/DialerServiceStore";
import { Utils } from "Utils/utils";
import { ReactComponent as PhoneIcon } from "Assets/svg/call.svg";
import DialerCancelLogPopup from "./DialerCancelLogPopup";
import DialerKeypadOnCalling from "./DialerKeypadOnCalling";

let currentSeconds = 0;
let startInterval = null;

const DialerCallStatusAndTimer = (props = {}) => {
  const { recordingEnabled = false } = userDetail.userFeatureFlag || {};

  const {
    contact,
    phone,
    activeConnection = "",
    isRecordingActivated = false
  } = props || {};
  const { firstName = "", lastName = "", name = "" } = contact || {};
  const [showCancelLogPopup, setCancelLogPopup] = useState(false);
  const [callStatus, setCallStatus] = useState(
    DialerServiceData.dialerCallStatus
  );
  const [seconds, setSeconds] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [hours, setHours] = useState("00");

  const closeCancelLogPopup = () => {
    setCancelLogPopup(false);
  };

  const startAndStopCallDuration = value => {
    if (value === "ended") {
      clearInterval(startInterval);
      return;
    }
    startInterval = setInterval(() => {
      currentSeconds++;
      setSeconds(getNumber(currentSeconds % 60));
      if (currentSeconds % 60 === 0) {
        let min = getNumber(parseInt(currentSeconds / 60));
        setMinutes(getNumber(parseInt(min % 60)));
        setHours(getNumber(parseInt(currentSeconds / 3600)));
      }
    }, 1000);
  };

  const getCallStatus = () => {
    switch (DialerServiceData.dialerCallStatus) {
      case "calling":
        setCallStatus("calling");
        break;
      case "connected":
        setCallStatus(DialerServiceData.dialerCallStatus);
        startAndStopCallDuration("connected");
        break;
      case "ended":
        currentSeconds = 0;
        setCallStatus("ended");
        startAndStopCallDuration("ended");
        break;
      default:
        startAndStopCallDuration("ended");
        setCallStatus(DialerServiceData.dialerCallStatus);
        break;
    }
  };

  const getNumber = number => {
    if (number < 10) {
      return "0" + number;
    } else {
      return number;
    }
  };

  const getRecordingText = () => {
    if (DialerServiceData?.dialerOption?.call && activeConnection) {
      if (!isRecordingActivated && !DialerServiceData?.dialerOption?.record) {
        return isRecordingActivated ? "Recording Paused" : "";
      } else {
        return DialerServiceData?.dialerOption?.record
          ? "Recording"
          : "Recording Paused";
      }
    }
    return "";
  };

  useEffect(() => {
    getCallStatus();
  }, [DialerServiceData.dialerCallStatus]);

  useEffect(() => {
    return () => {
      setCallStatus("ended");
      startAndStopCallDuration("ended");
      setSeconds("00");
      setMinutes("00");
      setHours("00");
    };
  }, []);

  return (
    <>
      <div className="daillingNumberCard">
        <div
          className={`contactNameInputWrap ${
            DialerServiceData.dialerIvrKeypad ? "ivrKeypadEnabled" : ""
          }`}
        >
          <p className={`callStatus ${callStatus}`}>{callStatus}</p>
          {Utils.getFullName(firstName, lastName, name, "contactName")}
          <p className="contactNumber">{phone}</p>
          <div className={`timerSection ${callStatus}`}>
            {(callStatus === "connected" ||
              callStatus === "ended" ||
              callStatus === "disconnected") && (
              <>
                <span className={callStatus}>
                  <PhoneIcon />
                </span>
                {(currentSeconds >= 3598 || hours > 0) && (
                  <>
                    <span className="hours">{hours}</span>
                    <span className="colon">:</span>
                  </>
                )}
                <span className="minutes">{minutes}</span>
                <span className="colon">:</span>
                <span className="seconds">{seconds}</span>
              </>
            )}
          </div>
          {(recordingEnabled || activeConnection) && (
            <div className="recordingState">
              {getRecordingText() && <span className="redDot" />}
              <span className="recordingText">{getRecordingText()}</span>
            </div>
          )}
        </div>
        {DialerServiceData.dialerIvrKeypad && (
          <DialerKeypadOnCalling activeConnection={activeConnection} />
        )}
      </div>
      {showCancelLogPopup && (
        <DialerCancelLogPopup hide={closeCancelLogPopup} />
      )}
    </>
  );
};

export default observer(DialerCallStatusAndTimer);
export { DialerCallStatusAndTimer };
