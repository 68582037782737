/* eslint-disable no-unused-expressions */
// getContactList
import { action, observable } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import { createEditListPopupState } from "./createEditListPopup";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { dataSearchState } from "Pages/Dashboard/dataSearch/state";
import {
  getContactPurchasePayload,
  isShowUpgradePopup,
  showUpgradePopup
} from "../DataSearchUtils";
import { handlePurchaseResponse } from "../DataSearchUtils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import Utils from "Utils/utils";

class CustomListState {
  @observable totalContactList = null;
  @observable activeList = "";

  @action
  setActiveList = value => {
    this.activeList = value;
  };

  @action
  showErrorMessages = statusCode => {
    createEditListPopupState.setShowPopup(false);
    createEditListPopupState.setButtonLoader(false);
    if (statusCode === 409) {
      toasterState.setToastMsg("List name already exists.", "failure");
    } else if (statusCode === 403) {
      toasterState.setToastMsg(
        "You don't have edit access to this list.",
        "failure"
      );
    } else {
      toasterState.setToastMsg(
        "Failed to update list name. Please try again later",
        "failure"
      );
    }
  };

  @action
  getContactList = async () => {
    try {
      const config = {
        url: URLS.getContactList,
        method: "POST",
        data: {
          limit: 250,
          pageNo: 1
        }
      };
      let res = await makeApi(config);
      if (res?.data) {
        const { listResponse = {} } = res?.data || {};
        const { statusCode = "", list = [] } = listResponse || {};
        if (statusCode === 200) {
          this.totalContactList = list;
        }
      }
    } catch (err) {
      console.error("Error in fetching contact list", err);
    }
  };

  @action
  createContactList = async listName => {
    try {
      const config = {
        url: URLS.createContactList,
        method: "POST",
        data: this.getCreateListPayload(listName)
      };
      let res = await makeApi(config);
      if (res?.data?.statusCode === 200) {
        const { listResponse = {} } = res?.data || {};
        if (listResponse && Object.keys(listResponse)?.length > 0) {
          const { statusCode = "" } = listResponse || {};
          if (statusCode === 409) {
            toasterState.setToastMsg("List name already exists.", "failure");
          } else if (statusCode === 403) {
            toasterState.setToastMsg(
              "You don't have edit access to this list.",
              "failure"
            );
          } else {
            toasterState.setToastMsg(
              "Failed to update list name. Please try again later",
              "failure"
            );
          }
        } else {
          this.getContactList();
          handlePurchaseResponse(res.data, listName);
        }
      } else {
        const stringStatusCode = res?.data?.statusCode?.toString();
        if (isShowUpgradePopup(stringStatusCode)) {
          showUpgradePopup(stringStatusCode);
          this.getContactList();
        }
      }
      Utils.mixpanelTrack(MXP_EVENT.LIST_CREATED_ADDED_PERFORMED, {
        pageType: PAGE_TYPE.discover,
        listName,
        searchCriteria: config?.data || {},
        success: res?.data?.statusCode === 200
      });
    } catch (err) {
      console.error("Error in creating contact list", err);
    }
  };

  @action
  editContactList = async (listId, updatedListName) => {
    try {
      const config = {
        url: `${URLS.editContactList}/${listId}/${updatedListName}`,
        method: "PUT"
      };
      let res = await makeApi(config);
      if (res?.data) {
        const { listResponse = {} } = res?.data || {};
        const {
          statusCode = "",
          success = false,
          list = []
        } = listResponse || {};
        if (statusCode === 200 && success) {
          this.getContactList();
          customListState.setActiveList(list?.[0]);
          createEditListPopupState.setShowPopup(false);
          createEditListPopupState.setButtonLoader(false);
          toasterState.setToastMsg("List name updated successfully.");
        } else {
          this.showErrorMessages(statusCode);
        }
      }
    } catch (err) {
      console.error("Error in editing contact list", err);
    }
  };

  @action
  deleteContactList = async list => {
    const { name = "", id = "" } = list || {};
    try {
      const config = {
        url: `${URLS.deleteContactList}/${id}`,
        method: "DELETE"
      };
      let res = await makeApi(config);
      if (res?.data) {
        const { listResponse = {} } = res?.data || {};
        const { statusCode = "", success = false } = listResponse || {};
        confirmationPopupState.setShowPopup(false);
        if (statusCode === 200 && success) {
          this.getContactList();
          customListState.setActiveList([]);
          toasterState.setToastMsg(`${name} list deleted successfully.`);
        } else {
          this.showErrorMessages(statusCode);
        }
      }
    } catch (err) {
      console.error("Error in deleting contact list", err);
    }
  };

  getCreateListPayload = listName => {
    const ids = [];
    dataSearchState?.selectedContactData?.map(id => {
      ids.push(id);
    });
    return {
      listName,
      purchasePayload: getContactPurchasePayload(ids, null, "email")
    };
  };
}

const customListState = new CustomListState();
export { customListState };
export default customListState;
