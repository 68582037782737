import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImportNow } from "../common/ImportNow";
import AddTags from "../common/AddTags";
import AutoImport from "../common/AutoImport";
import UpdateContacts from "../common/UpdateContacts";
import { salesforceConnectedData } from "./SalesforceConnectedData";
import { AddContactWithCondition } from "./AddContactWithCondition";
import FieldMapping from "./FieldMapping";

const Contacts = observer(props => {
  const { contact = {}, fieldMappingRef = null } = props || {};
  const { resumeDate = "" } = salesforceConnectedData?.salesForceData || {};
  const [dropdownWidth, setDropdownWidth] = useState("25%");

  const findWidthOfDropdown = () => {
    return (
      document.querySelector(".crmListView .filterDropdownWrap")?.offsetWidth ||
      "270"
    );
  };

  useEffect(() => {
    setDropdownWidth(findWidthOfDropdown());
  }, []);

  return (
    <div className="contactsWrapper">
      <div className="autoImportSettings">
        <AddContactWithCondition
          config={salesforceConnectedData.sfContactConfig}
          setConfig={salesforceConnectedData.setSfContactConfig}
          fields={salesforceConnectedData?.sfContactFields}
          crmName="Salesforce"
          type="CONTACTS"
        />
        <AutoImport
          config={salesforceConnectedData.sfContactConfig}
          setConfig={salesforceConnectedData.setSfContactConfig}
          data={salesforceConnectedData?.salesForceData}
          crmName="Salesforce"
          hasConditions={true}
        />
        <AddTags
          type="CONTACTS"
          dropdownWidth={dropdownWidth}
          config={salesforceConnectedData?.sfContactConfig}
          selectedTags={salesforceConnectedData?.sfContactTags}
          setSelectedTags={salesforceConnectedData.setSfContactTags}
        />
        <UpdateContacts
          config={salesforceConnectedData.sfContactConfig}
          setConfig={salesforceConnectedData.setSfContactConfig}
          crmName="Salesforce"
          hasConditions={true}
        />
        <ImportNow
          listId={salesforceConnectedData?.sfContactConfig?.sourceId}
          type="CONTACT"
          lastSynced={contact?.lastSynced}
          listChanged={salesforceConnectedData?.sfLeadConfig?.listChanged}
          crmName="Salesforce"
          hasConditions={true}
          resumeDate={resumeDate}
        />
      </div>
      <div className="fieldMapping" ref={fieldMappingRef}>
        <FieldMapping
          config={salesforceConnectedData.sfContactConfig}
          setConfig={salesforceConnectedData.setSfContactConfig}
          mappingConfig={contact?.fields}
          type="CONTACTS"
          fields={salesforceConnectedData?.sfContactFields}
          crmName="Salesforce"
        />
      </div>
    </div>
  );
});

export { Contacts };
export default Contacts;
