import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import Utils from "Utils/utils";

export const FilterByCardTab = props => {
  const {
    filterList = [],
    filterStats = {},
    loader = false,
    currentTab = "",
    pageType = "",
    filterCbk = () => {}
  } = props || {};

  const history = useHistory();

  const filteredValue = (event = "") => {
    Utils.preventDefaultFn(event);
    let listElem = event.target.closest("li") || "";
    if (listElem) {
      let name = listElem.getAttribute("data-name") || "";
      let id = listElem.getAttribute("data-id") || "";
      if (name) {
        Utils.trackFilterMixpanel(name, "Calls");
        clearInterval(window.scrollInterval);
        filterCbk(name, id);
      }
    }
  };

  return (
    <ul
      className={`filterByCard ${pageType}`}
      id="filterByCard"
      onClick={e => filteredValue(e)}
    >
      {!loader
        ? filterList?.length > 0 && (
            <>
              {filterList.map(item => {
                const {
                  id = "",
                  name = "",
                  displayName = "",
                  displayEnabled = false
                } = item || {};
                if (displayEnabled) {
                  return (
                    <li
                      key={id}
                      className={`filterCard ${
                        currentTab === name ? "active" : ""
                      }`}
                      data-name={name}
                      data-id={id}
                      id={id}
                    >
                      <div className="wrapFilterValue">
                        <span className="filterCount">
                          {filterStats?.[id || "total"] || 0}
                        </span>
                        <span className="filterName">{displayName}</span>
                      </div>
                    </li>
                  );
                }
              })}
              <li
                key="redirectToCallStaging"
                className="filterCard plusContactCard"
                onClick={e => {
                  Utils.preventDefaultFn(e);
                  history.push("/settings/dialer/callDisposition");
                }}
              >
                +
              </li>
            </>
          )
        : Array.from({ length: filterList?.length || 10 }, (value, index) => (
            <li
              className="filterCardLoader linear-background"
              key={`filterCardLoader${index}`}
            />
          ))}
    </ul>
  );
};

const MemoizedFilterByCardTab = memo(FilterByCardTab);
export default FilterByCardTab;
export { MemoizedFilterByCardTab };
