import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";

class TeamInvitedStore {
  @observable teamInvitedData = [];

  @action
  setTeamInvitedData = value => {
    this.teamInvitedData = value;
  };

  @action
  getInvitedDetails = async cbk => {
    const config = {
      url: URLS.getInvitedDetails,
      method: "GET"
    };
    const response = await makeApi(config);
    if (response && response.data) {
      this.setTeamInvitedData(response.data);
    }
  };

  @action
  acceptInvite = async (email, inviteToken) => {
    const config = {
      url: URLS.acceptTeamInvite,
      method: "POST",
      data: {
        email,
        inviteToken
      }
    };
    return await makeApi(config);
  };

  @action
  rejectInvite = async () => {
    const config = {
      url: URLS.rejectTeamInvite,
      method: "GET"
    };
    return await makeApi(config);
  };
}

const teamInvitedStore = new TeamInvitedStore();
export { TeamInvitedStore, teamInvitedStore };
export default TeamInvitedStore;
