/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import TemplateDataStore from "Stores/Template";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import Utils from "Utils/utils";
import { TEST_EMAIL_CONNECT_INBOX_MSG } from "Static/constant";
import QuilEditor from "Components/common/quillEditor";
import PreviewEditorTemplate from "Components/common/PreviewEditorTemplate";
import InputTagComponent from "Components/common/InputTag";
import ButtonComponent from "Components/common/Button";
import SequenceUtils from "Utils/SequenceUtils";
import { writingAssistantStoreData } from "Stores/WritingAssistantData";
import { testEmailPopupState } from "../sequences/Stages/TestEmailPopup";

const CreateUpdateTemplate = observer(props => {
  const {
    title = "",
    value = "",
    setSelectedTemplateFn = () => {},
    cbk = () => {},
    openPopup = () => {}
  } = props || {};

  const [selectedTemplate, setSelectedTemplate] = useState(value);
  const [templateTitle, setTemplateTitle] = useState(title);
  const [templateName, setTemplateName] = useState(
    selectedTemplate?.name || ""
  );
  const [attachments, setAttachments] = useState(
    selectedTemplate?.attachments || []
  );
  const [templateSubject, setTemplateSubject] = useState(
    selectedTemplate?.subject || ""
  );
  const [templateNameErr, setTemplateNameErr] = useState("");
  const [templateSubjectErr, setTemplateSubjectErr] = useState("");
  const [bodyOnchangeValue, setBodyOnChangeValue] = useState("");
  const [folderId, setFolderId] = useState(TemplateDataStore.folderId);
  const [subjectFocus, setSubjectFocus] = useState(false);
  const [subjectCursorPosition, setSubjectCursorPosition] = useState(0);
  const [btnLoader, setBtnLoader] = useState(false);

  const createTemplate = () => {
    let templateNameValidate = Utils.validateStringLength(templateName, 1);
    let templateSubjectValidate = Utils.validateStringLength(
      templateSubject,
      1
    );
    if (templateNameValidate && templateSubjectValidate) {
      const hasInvalidTokens = SequenceUtils.getInvalidTokensPrompt(
        templateSubject,
        bodyOnchangeValue
      );
      if (!hasInvalidTokens) {
        setBtnLoader(true);
        if (templateTitle === "New") {
          !fileAttachmentStore.fileUploadingLoader && saveTemplate();
        } else {
          !fileAttachmentStore.fileUploadingLoader && updateTemplate();
        }
      }
    } else if (!templateNameValidate) {
      setTemplateNameErr("Please enter a valid name");
    } else if (!templateSubjectValidate) {
      setTemplateSubjectErr("Please enter a valid subject");
    }
  };

  const changeInput = (name, inputType) => {
    TemplateDataStore.setEditorInsertCounter(
      TemplateDataStore.editorInsertCounter + 1
    );
    switch (inputType) {
      case "templateName":
        setTemplateNameErr("");
        setTemplateName(name);
        break;
      case "templateSubject":
        setTemplateSubjectErr("");
        setTemplateSubject(name);
        break;
      default:
        break;
    }
  };

  const stopBtnloader = (value, data) => {
    TemplateDataStore.setEditorInsertCounter(0);
    if (!value && data) {
      setTemplateTitle("Update");
      setSelectedTemplate(data);
    }
    setBtnLoader(false);
    cbk();
  };

  const saveTemplate = () => {
    let payload = {};
    if (folderId !== "" && folderId !== "all") {
      payload.folderId = folderId;
    }
    payload.parent = "";
    payload.templates = [
      {
        name: templateName,
        subject: templateSubject,
        content: bodyOnchangeValue
      }
    ];
    // Adding attachments to the payload
    if (attachments?.length) {
      payload.templates[0].attachments = attachments;
    }
    TemplateDataStore.saveTemplates(payload, stopBtnloader);
  };

  const updateTemplate = () => {
    const { id, createTs, updateTs, status, memberId, shareType, accessType } =
      selectedTemplate;
    const payload = {
      id,
      name: templateName,
      subject: templateSubject,
      content: bodyOnchangeValue,
      createTs,
      updateTs,
      folderId: selectedTemplate.folderId,
      memberId,
      status,
      shareType,
      accessType,
      attachments
    };
    fileAttachmentStore.clearAttachmentList();
    TemplateDataStore.updateTemplates(payload, stopBtnloader);
  };

  const processAttachments = (uploadedAttachments = []) => {
    const attachmentArray = attachments || [];
    uploadedAttachments.map(ele => {
      if (ele?.attachments) {
        const {
          id = "",
          attachmentName = "",
          attachmentSizeInKB = "",
          attachmentUrl = ""
        } = ele?.attachments || {};
        if (id && attachmentName) {
          const tempObj = {
            id,
            attachmentName,
            attachmentSizeInKB,
            attachmentUrl
          };
          attachmentArray.push(tempObj);
        }
      }
    });
    const uniqueAttachments = getUniqueAttachments(attachmentArray);
    if (uniqueAttachments) {
      setAttachments(JSON.parse(JSON.stringify(uniqueAttachments)));
      return uniqueAttachments;
    }
  };

  const getAttachments = () => {
    const uploadedAttachments = JSON.parse(
      JSON.stringify(fileAttachmentStore.fileAttachmentList)
    );
    if (templateTitle === "New") {
      if (uploadedAttachments?.length) {
        processAttachments(uploadedAttachments);
      } else {
        setAttachments([]);
      }
    } else {
      processAttachments(uploadedAttachments);
    }
  };

  const getUniqueAttachments = attachmentsArray => {
    if (attachmentsArray && attachmentsArray.length) {
      const flags = [],
        output = [],
        l = attachmentsArray.length;
      for (let i = 0; i < l; i++) {
        if (flags[attachmentsArray[i].id]) continue;
        flags[attachmentsArray[i].id] = true;
        output.push(attachmentsArray[i]);
      }
      return output;
    }
  };

  const submitForm = e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      createTemplate();
    }
  };

  const bodyOnchangeEvnt = (inputValue = "") => {
    subjectFocus && setSubjectFocus(false);
    setBodyOnChangeValue(inputValue);
    writingAssistantStoreData.setBodyContent(inputValue);
    TemplateDataStore.setEditorInsertCounter(
      TemplateDataStore?.editorInsertCounter + 1
    );
  };

  const handleFocus = (val = false) => {
    setSubjectFocus(val);
  };

  const setSubjectStateFromEditor = (value = "") => {
    let subjectText =
      templateSubject.substr(0, subjectCursorPosition) +
      value +
      templateSubject.substr(subjectCursorPosition, templateSubject.length);
    setTemplateSubject(subjectText);
    Utils.focusInputBox("templateSubject");
  };

  const setSubjectMousePosition = el => {
    if (el && el.target) {
      setSubjectCursorPosition(el.target.selectionStart);
    }
  };

  const getSharedBadge = () => {
    return (
      selectedTemplate?.shareType &&
      Utils.isShared(selectedTemplate.shareType) && (
        <span className="sharedBadge">{selectedTemplate.shareType}</span>
      )
    );
  };

  const getEntityObj = () => {
    return {
      pageType: "templates",
      entity: selectedTemplate,
      updateState: setSelectedTemplateFn
    };
  };

  const getTotalUploadAttachmentSize = (attachments = []) => {
    let totalSize = 0;
    if (attachments?.length > 0) {
      attachments.map(item => {
        if (item?.attachmentSizeInKB > 0) {
          totalSize += item?.attachmentSizeInKB || 0;
        }
      });
      const sizeInMb = totalSize / 1024;
      fileAttachmentStore.setTotalSizeUploaded(sizeInMb);
    }
  };

  const sendTestEmail = (sendTestEmailLoader = () => {}) => {
    const templateSubjectValidate = Utils.validateStringLength(
      templateSubject,
      1
    );

    if (
      templateSubjectValidate &&
      Utils.contentBodyHasTextOrImg(bodyOnchangeValue)
    ) {
      sendTestEmailLoader(true);
      const payload = {
        subject: Utils.replaceTokenWithValue(templateSubject, false),
        attachmentId: Utils.getAttachmentIds(attachments),
        content: Utils.replaceTokenWithValue(bodyOnchangeValue, false),
        fromInboxId: testEmailPopupState?.fromAddress?.id || "",
        toAddress: testEmailPopupState?.toAddress?.value || ""
      };
      Utils.sendTestEmail(
        payload,
        {
          pageType: "Templates",
          emailType: "Templates"
        },
        () => {
          testEmailPopupState.setFromAddress({});
          testEmailPopupState.setToAddress({});
          testEmailPopupState.setShowPopup(false);
          sendTestEmailLoader(false);
          TemplateDataStore.setEditorInsertCounter(0);
          openPopup(true);
        },
        props,
        () => {
          cbk(false);
        }
      );
    }
  };

  const sendTestEmailInit = () => {
    if (fileAttachmentStore && !fileAttachmentStore?.fileUploadingLoader) {
      if (Utils.isAccountConnected()) {
        testEmailPopupState.setDefaultValues({
          ...selectedTemplate,
          subject: templateSubject,
          contentBody: Utils.contentReadyOnly(bodyOnchangeValue),
          isFromTemplates: true,
          backBtnCbk: openPopup,
          name: templateName,
          title: templateTitle === "New" ? "New" : "Edit",
          attachments
        });
        testEmailPopupState.setCbk(sendTestEmail);
        cbk(false);
        testEmailPopupState.setShowPopup(true);
      } else {
        Utils.showAddAccountPopup(props, TEST_EMAIL_CONNECT_INBOX_MSG, () =>
          cbk(false)
        );
      }
    }
  };

  const sendEmailBtnEnabled = () => {
    const templateSubjectValidate = Utils.validateStringLength(
      templateSubject,
      1
    );
    if (
      templateSubjectValidate &&
      Utils.contentBodyHasTextOrImg(bodyOnchangeValue)
    ) {
      return false;
    }
    return true;
  };

  const handleSubject = () => {
    setSubjectFocus(true);
    writingAssistantStoreData.setShowSubjectLineGenerator(true);
  };

  const updateEditorContent = ({ subject = "", content = "" }) => {
    setBodyOnChangeValue(content);
    subjectFocus && setSubjectFocus(false);
    templateSubjectErr && setTemplateSubjectErr("");
    subject && setTemplateSubject(subject);
    setSelectedTemplate({ ...selectedTemplate, content, subject });
    TemplateDataStore.setEditorInsertCounter(
      TemplateDataStore.editorInsertCounter + 1
    );
  };

  useEffect(() => {
    if (value?.attachments) {
      setAttachments(value.attachments);
      getTotalUploadAttachmentSize(value.attachments);
    }
  }, [value]);

  useEffect(() => {
    setFolderId(TemplateDataStore.folderId);
    writingAssistantStoreData.setBodyContent(selectedTemplate?.content || "");
    return () => {
      TemplateDataStore.setEditorInsertCounter(0);
    };
  }, []);

  useEffect(() => {
    getAttachments();
  }, [fileAttachmentStore.fileAttachmentList]);

  const previewDom = Utils.getContentWithSignature(bodyOnchangeValue);

  return (
    <>
      <div className="create-new-template-content">
        <div className="templete-edit">
          <h6>
            {`${templateTitle} Template`}
            {getSharedBadge()}
          </h6>
          <div name="saveNewTemplate" className="template-form">
            <div className="input-group">
              <InputTagComponent
                inputFocus={true}
                name="templateName"
                text="Template Name"
                value={templateName}
                change={changeInput}
                submit={submitForm}
                err={templateNameErr}
                fieldReq={true}
              />
              <InputTagComponent
                inputFocus={false}
                name="templateSubject"
                text="Template Subject"
                value={templateSubject}
                change={changeInput}
                submit={submitForm}
                err={templateSubjectErr}
                fieldReq={true}
                position={setSubjectMousePosition}
                focus={handleSubject}
                bodyContent={bodyOnchangeValue}
                enableSubjectLineGenerator={true}
              />
            </div>
            <div className="quil-editor" id="templateQuilEditor">
              <QuilEditor
                bodyOnChange={bodyOnchangeEvnt}
                prefilledValue={selectedTemplate?.content}
                setsubjectFocus={handleFocus}
                isSubjectFocused={subjectFocus}
                setSubject={setSubjectStateFromEditor}
                attachments={attachments}
                entityObj={getEntityObj()}
                pageType="templates"
                type="templates"
                stageType="templates"
                showRewriteAI={true}
                updateContentCbk={updateEditorContent}
              />
            </div>
          </div>
        </div>
        <PreviewEditorTemplate
          subject={templateSubject}
          contentBody={Utils.contentReadyOnly(previewDom?.outerHTML)}
          attachments={attachments || []}
          currentStage={false}
          titleText="Preview Template"
        />
      </div>
      <div className="template-btn">
        <div className="btnWidth">
          <ButtonComponent
            id="sendTestEmailTemplateBtn"
            name="sendTestEmailTemplateBtn"
            type="text"
            className={`sendTestEmailTemplateBtn  ${
              sendEmailBtnEnabled() ? "disableSentEmailBtn" : ""
            }`}
            btnText="Send Test Email"
            icon={false}
            loader={false}
            iconName=""
            click={e => {
              e.preventDefault();
              sendTestEmailInit();
            }}
          />
        </div>
        <div className="btnWidth">
          <ButtonComponent
            id={templateTitle === "New" ? "saveTemplate" : "updateTemplate"}
            name={templateTitle === "New" ? "saveTemplate" : "updateTemplate"}
            type="text"
            className={`${btnLoader ? "loading" : ""} `}
            btnText={
              templateTitle === "New" ? "Save Template" : "Update Template"
            }
            icon={false}
            loader={btnLoader}
            iconName=""
            click={e => {
              e.preventDefault();
              createTemplate();
            }}
          />
        </div>
      </div>
    </>
  );
});

export default withRouter(CreateUpdateTemplate);
export { CreateUpdateTemplate };
