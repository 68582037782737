/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import { decorate, observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import { makeApi, URLS } from "Utils/apiURL";
import { dialerStoreData } from "Stores/DialerData";

let cancelTokenRequest = null;

export const defaultDialerOption = {
  mute: false,
  record: false,
  call: false,
  notes: false,
  keypad: true
};

class DialerServiceStore {
  // Observables
  dialerTokenObj = null;
  dialerDefaultNo = null;
  dialerOption = { ...defaultDialerOption };
  dialerPhoneList = null;
  dialerCallLogs = false;
  dialerCallNotes = "";
  dialerCallStatus = "";
  dialerBtnLoader = false;
  dialerCallProgress = false;
  dialerIvrKeypad = false;
  callRecordingEnabled = true;
  purchasedNumbers = [];
  deviceStatus = "";

  // Cancel Request Function

  cancelTokenHttpRequest = () => {
    if (cancelTokenRequest !== null) {
      cancelTokenRequest("cancel");
      cancelTokenRequest = null;
    }
  };

  cancelTokenCallBack = value => {
    cancelTokenRequest = value;
  };

  // Action
  setDialerTokenObj = value => {
    this.dialerTokenObj = value;
  };

  setDialerCallLogs = value => {
    this.dialerCallLogs = value;
  };

  setDialerOption = (obj = {}) => {
    this.dialerOption = { ...this.dialerOption, ...obj };
  };

  setDialerCallNotes = value => {
    this.dialerCallNotes = value;
  };

  setDialerCallStatus = value => {
    this.dialerCallStatus = value;
  };

  setDialerDefaultNo = value => {
    this.dialerDefaultNo = value;
  };

  setDialerStartTimer = value => {
    this.dialerCallProgress = value;
  };

  setDialerIvrKeypad = value => {
    this.dialerIvrKeypad = value;
  };

  setCallRecordingEnabled = value => {
    this.callRecordingEnabled = value;
  };

  setPurchasedNumbers = value => {
    this.purchasedNumbers = value;
  };

  setDeviceStatus = (value = "") => {
    this.deviceStatus = value;
  };

  getDailerToken = async () => {
    try {
      const config = {
        url: URLS.getDailerToken,
        method: "GET"
      };
      this.cancelTokenHttpRequest();
      let res = await makeApi(config, this.cancelTokenCallBack);
      if (res?.data && Object.keys(res.data).length > 0) {
        this.dialerTokenObj = res.data;
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "failure"
        );
      }
    } catch (err) {
      console.error("Getting Dailer Token Error", err);
    }
  };

  getDailerToken = async () => {
    try {
      const config = {
        url: URLS.getDailerToken ? URLS.getDailerToken : "",
        method: "GET"
      };
      this.cancelTokenHttpRequest();
      let res = await makeApi(config, this.cancelTokenCallBack);
      if (res?.data && Object.keys(res.data).length > 0) {
        this.dialerTokenObj = res.data;
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "failure"
        );
      }
    } catch (err) {
      console.error("Getting Dailer Token Error", err);
    }
  };

  getDialerPhoneList = async (isInit = true) => {
    try {
      const config = {
        url: URLS.getMyPhoneNumbers,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.data && Object.keys(res.data).length > 0) {
        const { defaultPhNumber = "purchaseNumber", phoneNumbers = [] } =
          res.data || {};
        this.dialerDefaultNo = defaultPhNumber || "purchaseNumber";
        this.setDialerPhoneList(phoneNumbers || []);
        dialerStoreData.setMyPhoneNumbers(res?.data);
        this.updatePurchasedNumber(phoneNumbers);
      } else {
        if (isInit) {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "failure"
          );
        }
      }
    } catch (err) {
      console.error("Getting Dailer Phone List Error", err);
    }
  };

  updateDefaultPhoneNumber = async data => {
    try {
      let config = {
        url: URLS.updateDefaultPhoneNumber,
        method: "PUT",
        data
      };
      let res = await makeApi(config);
      if (res?.data?.status === "200") {
        toasterState.setToastMsg(
          "Default Phone number updated successfully",
          "success"
        );
        this.dialerDefaultNo = data.ph_number;
      } else {
        dialerStoreData.showResponseMessage(res);
      }
      this.dialerBtnLoader = false;
    } catch (err) {
      console.error("Update default phone number error ", err);
    }
  };

  setDialerPhoneList = list => {
    if (list) {
      const tempArr = [];
      list.map(value => {
        if (value && value.number) {
          tempArr.push(value.number);
        }
      });
      this.dialerPhoneList = tempArr;
    }
  };

  updatePurchasedNumber = numberList => {
    const purchasedList = [];
    numberList?.length > 0 &&
      numberList.map(number => {
        const { numberType = "" } = number || {};
        if ("Purchased" === numberType) {
          purchasedList.push(number);
        }
      });
    this.setPurchasedNumbers(purchasedList);
  };
}

decorate(DialerServiceStore, {
  dialerTokenObj: observable,
  dialerOption: observable,
  dialerPhoneList: observable,
  dialerCallLogs: observable,
  dialerCallNotes: observable,
  dialerDefaultNo: observable,
  dialerCallStatus: observable,
  dialerBtnLoader: observable,
  dialerCallProgress: observable,
  dialerIvrKeypad: observable,
  deviceStatus: observable,
  setDialerTokenObj: action,
  getDailerToken: action,
  setDialerOption: action,
  getDialerPhoneList: action,
  updateDefaultNumber: action,
  setDialerCallLogs: action,
  setDialerCallNotes: action,
  setDialerCallStatus: action,
  setDialerDefaultNo: action,
  setDialerStartTimer: action,
  setDialerIvrKeypad: action,
  setDialerPhoneList: action,
  setDeviceStatus: action
});

const DialerServiceData = new DialerServiceStore();
export default DialerServiceData;
export { DialerServiceData };
