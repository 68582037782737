import { decorate, observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import userDetail, { brandUrlObj } from "Stores/userDetail";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";

class CustomUrlStore {
  // Store
  brandUrlData = "";
  brandUrlCname = "";

  // API Call
  getBrandUrlCname = async () => {
    try {
      const config = {
        url: URLS.getBrandUrlCname
      };
      let res = await makeApi(config);
      if (res?.data) {
        this.brandUrlCname = res.data;
        return res.data;
      }
      return "";
    } catch (e) {
      console.error(e, "Getting Brand Url Cname Error");
    }
  };

  verifyBrandUrl = async (domain, cname, cbk) => {
    try {
      const config = {
        url: `${URLS.verifyBrandUrl}?domain=${domain}&cname=${cname}`,
        method: "POST"
      };
      let res = await makeApi(config);
      let respCode = res?.data || false;
      if (+respCode === 200) {
        let resData = await this.getBrandUrlData();
        Utils.mixpanelTrack(MXP_EVENT.CUSTOM_URL_ADDED);
        cbk(true, resData);
      } else if (respCode === 501 || respCode === 502) {
        cbk(false);
        toasterState.setToastMsg(
          "Custom URL verification failed. Please verify the DNS record and try again",
          "fail"
        );
      } else {
        cbk(false);
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (e) {
      cbk(false);
      console.error(e, "Verify Brand Url Error");
    }
  };

  getBrandUrlData = async () => {
    try {
      const config = {
        url: URLS.getBrandUrlData
      };
      let res = await makeApi(config);
      if (res?.data && Object.keys(res.data)?.length > 0) {
        this.brandUrlData = res.data;
        userDetail.setBrandUrlInfo(res.data);
        return res?.data;
      } else {
        this.brandUrlData = "";
        userDetail.setBrandUrlInfo("");
      }
      return false;
    } catch (e) {
      console.error(e, "Getting Brand Url Data Error");
    }
  };

  deleteBrandUrl = async () => {
    try {
      const config = {
        url: URLS.deleteBrandUrl,
        method: "DELETE"
      };
      let res = await makeApi(config);
      if (res?.data) {
        Utils.mixpanelTrack(MXP_EVENT.CUSTOM_URL_REMOVED);
        this.brandUrlData = "";
        userDetail.setBrandUrlInfo(brandUrlObj);
        toasterState.setToastMsg(
          "Custom URL removed. Salesgear's default URL will be used going forward.",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (e) {
      console.error(e, "Deleting Brand Url Error");
    }
  };
}

decorate(CustomUrlStore, {
  brandUrlData: observable,
  brandUrlCname: observable,
  getBrandUrlCname: action,
  verifyBrandUrl: action,
  getBrandUrlData: action,
  deleteBrandUrlData: action
});

const customUrlStoreData = new CustomUrlStore();
export default new CustomUrlStore();
export { customUrlStoreData };
