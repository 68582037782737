import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { ReactComponent as SpamCountIcon } from "Assets/Settings/spamCount.svg";
import Button from "Components/common/Button";

const btnProps = {
  id: "warmingEnableBtn",
  name: "warmingEnableBtn",
  icon: false,
  type: "button",
  className: "warmingEnableBtn"
};

const LinkedInAccountStatus = observer(({ account }) => {
  const unwrappedAccount = account ? toJS(account) : null;
  const status = unwrappedAccount?.status;

  return (
    <div className="linkedinStatus">
      {status.toLowerCase() !== "active" ? (
        <div className="linkedInwarmingEnabledOrDisabledBtn errorBtn">
          <Button
            {...btnProps}
            btnText="Connection Failed"
            className={"warmingError"}
            loader={false}
            svg={<SpamCountIcon />}
          />
        </div>
      ) : (
        <div className="linkedinAccountStatus">Active</div>
      )}
    </div>
  );
});

export { LinkedInAccountStatus };
export default LinkedInAccountStatus;
