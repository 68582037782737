import React from "react";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import Utils from "Utils/utils";
import { linkedInCookiesState } from "Stores/LinkedInCookies";

const LinkedInPopupHeader = ({ closeIconCbk = () => {} }) => {
  return (
    <div className="linkedInHeader">
      <h5 className="title">Connect LinkedIn Account</h5>
      <i className="material-icons commonPopupCloseIcon" onClick={closeIconCbk}>
        close
      </i>
    </div>
  );
};

const LinkedInPopupFooter = observer((props = {}) => {
  const { hasButton = false, btnProps = {}, additionalDom } = props || {};

  return (
    <div className="linkedInFooter">
      {linkedInCookiesState?.isPremiumAccount ? (
        <div className="notifySmallText">
          Please make sure you are currently logged into a valid{" "}
          <span
            onClick={() =>
              Utils.windowRedirect("https://www.linkedin.com/sales/home")
            }
            className="highlightLink"
          >
            Sales Navigator
          </span>{" "}
          account.
        </div>
      ) : (
        <div className="notifySmallText">
          Make sure you are currently logged into{" "}
          <span
            onClick={() => Utils.windowRedirect("https://www.linkedin.com")}
            className="highlightLink"
          >
            LinkedIn
          </span>{" "}
          with the account you want to connect.
        </div>
      )}
      {hasButton && <Button {...btnProps} />}
      {additionalDom}
    </div>
  );
});

export { LinkedInPopupHeader, LinkedInPopupFooter };
