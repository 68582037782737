import React, { useState } from "react";
import { Utils } from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import CustomTooltip from "Components/common/CustomTooltip";
import { ReactComponent as PhoneIcon } from "Assets/svg/call.svg";
import { ReactComponent as SequencesIcon } from "Assets/svg/sequence.svg";
import { ReactComponent as TaskIcon } from "Assets/svg/task.svg";

const ContactInfo = props => {
  const actionIconList = [
    {
      id: "addToSequences",
      name: "Sequences",
      icon: <SequencesIcon />,
      class: "iconBtnUpcoming"
    },
    {
      id: "addToTasks",
      name: "Tasks",
      icon: <TaskIcon />,
      class: "iconBtnUpcoming"
    },
    {
      id: "addDialerCall",
      name: "Call",
      icon: <PhoneIcon />,
      class: "iconBtnEnabled"
    }
  ];

  const {
    contact = {},
    actionIcon = false,
    cbkDialer = () => {},
    showRoundedCircle = false
  } = props || {};

  const {
    firstName = "",
    lastName = "",
    email = "",
    jobTitle = "",
    company = "",
    name = ""
  } = contact || {};

  const [tpIndex, setTpIndex] = useState("");

  const openFeatureAction = name => {
    setTpIndex("");
    if (name === "Call") {
      cbkDialer();
    } else {
      toasterState.setToastMsg("Coming soon", "upcoming");
    }
  };

  const showActionBtnTp = value => {
    actionIconList.map((item, index) => {
      if (item.name === value) {
        setTpIndex(index);
      }
      return item;
    });
  };

  return (
    email && (
      <div className="contactInfoList">
        {showRoundedCircle && (
          <span className="roundedLetter">
            {Utils.getFirstLetter(firstName, lastName, name)}
          </span>
        )}
        {Utils.getFullName(firstName, lastName, name, "contactFullName", email)}
        <p className="titleCompanyWrap">
          {jobTitle && <span className="title">{jobTitle}</span>}
          {jobTitle && company && <span className="at">@</span>}
          {company && <span className="company">{company}</span>}
        </p>
        {actionIcon && (
          <div className="featureActionIconsWrap">
            {actionIconList.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={`iconBtnRounded ${item.class}`}
                  onClick={() => openFeatureAction(item.name)}
                  onMouseOver={() => showActionBtnTp(item.name)}
                  onMouseLeave={() => setTpIndex("")}
                >
                  {item.icon}
                  {index === tpIndex && (
                    <CustomTooltip text={item.name} dialer={true} />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    )
  );
};

export default ContactInfo;
export { ContactInfo };
