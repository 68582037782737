/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { observer } from "mobx-react";

const listElements = [
  {
    title: "Creating a Sequence",
    description: "",
    videoId: "https://www.youtube.com/embed/bGFZZM2Ehig"
  },
  {
    title: "Customize a predefined Sequence",
    description: "",
    videoId: "https://www.youtube.com/embed/_N9_231IKLI"
  },
  {
    title: `Salesgear for Gmail`,
    description: "",
    videoId: "https://www.youtube.com/embed/XNntY_CTB-4"
  },
  {
    title: "Add contacts to Sequence",
    description: "",
    videoId: "https://www.youtube.com/embed/K4s9dQTCEd8"
  },
  {
    title: "Private and Shared Sequences",
    description: "",
    videoId: "https://www.youtube.com/embed/Tudh1grWvuQ"
  },
  {
    title: "Add contacts to Sequence from Salesforce",
    description: "",
    videoId: "https://www.youtube.com/embed/okObCMMXPk0"
  },
  {
    title: "Add contacts to Sequence from Pipedrive",
    description: "",
    videoId: "https://www.youtube.com/embed/O-jNwhue_vw"
  },
  {
    title: "Add contacts to Sequence from Zoho",
    description: "",
    videoId: "https://www.youtube.com/embed/PNENxzEN4ys"
  },
  {
    title: "Add contacts to Sequence from Hubspot",
    description: "",
    videoId: "https://www.youtube.com/embed/dmFZ0-V0GTQ"
  },
  {
    title: "Add contacts to Sequence from Gmail",
    description: "",
    videoId: "https://www.youtube.com/embed/QET03fLl0w0"
  },
  {
    title: "Add multiple contacts from Salesforce",
    description: "",
    videoId: "https://www.youtube.com/embed/u6pQmU9qtbs"
  },
  {
    title: "Clone a Sequence",
    description: "",
    videoId: "https://www.youtube.com/embed/Ed0I6Ucy4P8"
  },
  {
    title: "Pause and Resume Sequence",
    description: "",
    videoId: "https://www.youtube.com/embed/8MuRAPxLgpU"
  },
  {
    title: "Use templates in Sequence",
    description: "",
    videoId: "https://www.youtube.com/embed/xd1raccdyMo"
  },
  {
    title: "Export contacts from Sequence contact tab",
    description: "",
    videoId: "https://www.youtube.com/embed/U3n8rkq7N7k&"
  },
  {
    title: "Connected Inbox using Google Oauth",
    description: "",
    videoId: "https://www.youtube.com/embed/qujoxGjczP8"
  },
  {
    title: "Connecting with Salesforce",
    description: "",
    videoId: "https://www.youtube.com/embed/IzlhhgxycC0"
  },
  {
    title: "Add Email Signature",
    description: "",
    videoId: "https://www.youtube.com/embed/goR6egV2X7s"
  },
  {
    title: `Add contacts to Salesgear`,
    description: "",
    videoId: "https://www.youtube.com/embed/Ja8z8-GqEfk"
  },
  {
    title: "Add to Sequence from Contact section",
    description: "",
    videoId: "https://www.youtube.com/embed/0yTUqbCug7Y"
  },
  {
    title: "Create a task from Contact section",
    description: "",
    videoId: "https://www.youtube.com/embed/wMEfYkk4Lo4"
  },
  {
    title: "Opt out a contact",
    description: "",
    videoId: "https://www.youtube.com/embed/lsOqFlSqnWU"
  },
  {
    title: "Invite a team member",
    description: "",
    videoId: "https://www.youtube.com/embed/8wXNDiomV4s"
  }
];

const getItemDom = (el, index) => {
  return (
    <div key={"values" + index} className="listElement">
      <div className="listTitle">{el.title}</div>
      <div className="listDesc">{el.description}</div>
      <div className="listVideo">
        <iframe
          src={
            el.videoId +
            "?rel=0&showinfo=0&showsearch=0&modestbranding=1&showinfo=0"
          }
          frameBorder="0"
          title={el.title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
        />
      </div>
    </div>
  );
};

const TutorialSlider = props => {
  const [items, setItems] = useState(listElements);

  const search = e => {
    if (e && e.target) {
      const tempArr = [];
      const searchString = e.target.value.toLowerCase();
      listElements.filter(ele => {
        if (ele.title.toLowerCase().includes(searchString)) {
          tempArr.push(ele);
        }
      });
      setItems(tempArr);
    }
  };

  return (
    <section className="tutorialMain slide-in">
      <div className="headerWrapper">
        <div className="header">
          <div className="title">How it works? </div>
          <span className="closeIcon" onClick={() => props.close()}>
            <i className="material-icons">close</i>
          </span>
        </div>
        <div className="searchWrapper">
          <div className="searchInnerCont">
            <input
              type="text"
              className="textbox"
              onInput={e => {
                search(e);
              }}
            />
            <i className="material-icons">search</i>
          </div>
        </div>
      </div>
      <div className="content" id="contentWrapper">
        {items && items.length > 0 ? (
          items.map((el, index) => {
            if (el && el.title) {
              return getItemDom(el, index);
            }
          })
        ) : (
          <div className="noResult">No results found</div>
        )}
      </div>
    </section>
  );
};

export default observer(TutorialSlider);
export { TutorialSlider };
