import React, { useState } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import InboxImage from "../InboxImage";
import FormActionButton from "./FormActionButton";
import AccountEmailLimitConfiguration from "./AccountEmailLimitConfiguration";
import QuilEditor from "Components/common/quillEditor";
import makeApi, { URLS } from "Utils/apiURL";
import { fetchConnectedEmails } from "Utils/commonAPI";
import { toasterState } from "Components/common/toaster";
import connectedInboxStore from "../store";

const Configure = observer(props => {
  const {
    configureData = {},
    setConfigureData = () => {},
    setShowConfigure = () => {},
    defaultValue = {}
  } = props || {};

  const {
    dailyLimit = 100,
    interval = 120,
    signature = "",
    type = "",
    email = "",
    smtp = {}
  } = configureData || {};

  const [maximumEmailsPerDay, setMaximumEmailsPerDay] = useState(dailyLimit);
  const [minimumDelayBtwEmails, setMinimumDelayBtwEmails] = useState(interval);
  const [emailSignature, setEmailSignature] = useState(signature);
  const [maximumEmailsError, setMaximumEmailsError] = useState("");
  const [minimumDelayError, setMinimumDelayError] = useState("");
  const [loader, setLoader] = useState(false);

  const changeMaximumEmailsPerDay = (value = 0) => {
    setMaximumEmailsPerDay(+parseInt(value));
  };

  const changeMinimumDelayBtwEmails = (value = 0) => {
    setMinimumDelayBtwEmails(+parseInt(value));
  };

  const validateEmailLimit = () => {
    if (
      maximumEmailsPerDay <= userDetail?.userFeatureFlag?.dailySendLimit &&
      maximumEmailsPerDay > 0
    ) {
      setMaximumEmailsError("");
      return true;
    } else {
      setMaximumEmailsError(
        `You can only send up-to ${userDetail?.userFeatureFlag?.dailySendLimit} emails per day from this email account. Please contact your account administration to increase your daily email limit.`
      );
      return false;
    }
  };

  const validateDelayBetweenEmails = () => {
    if (minimumDelayBtwEmails >= 15 && minimumDelayBtwEmails <= 9999) {
      setMinimumDelayError("");
      return true;
    } else {
      setMinimumDelayError(
        "The delay between emails should be between 15 and 9999"
      );
      return false;
    }
  };

  const getInboxArrayList = (inboxes = [], newInboxObject = {}) => {
    let tempList = [...inboxes];
    if (tempList?.length) {
      tempList.map((item, index) => {
        if (item?.email === newInboxObject?.email) {
          tempList[index] = newInboxObject;
        }
      });
    }
    return tempList;
  };

  const updateAccountData = async (data = {}) => {
    let tempAccountList = getInboxArrayList(
      connectedInboxStore?.accountData,
      data
    );
    let tempConnectedInboxes = getInboxArrayList(
      userDetail.connectedInboxes,
      data
    );
    userDetail.setConnectedInboxes(tempConnectedInboxes);
    connectedInboxStore.setAccountData(tempAccountList);
    await fetchConnectedEmails();
  };

  const updateConfiguration = async () => {
    if (validateEmailLimit() && validateDelayBetweenEmails()) {
      setLoader(true);
      const payload = {
        ...configureData,
        signature: emailSignature,
        dailyLimit: maximumEmailsPerDay,
        interval: minimumDelayBtwEmails,
        insertSignature: true
      };
      try {
        const config = {
          url: URLS.updateConnectedInbox,
          method: "POST",
          data: payload
        };
        const { data } = await makeApi(config);
        const { status } = data || {};
        if (status === "success") {
          toasterState.setToastMsg("Successfully updated", "success");
          updateAccountData(payload);
          setShowConfigure(false);
          setConfigureData(defaultValue);
        } else if (status === "unauthorized") {
          toasterState.setToastMsg("UNAUTHORIZED Access", "fail");
        } else if (status === "forbidden") {
          validateEmailLimit();
          validateDelayBetweenEmails();
          toasterState.setToastMsg(
            "Update failed. Please try again later.",
            "fail"
          );
        } else if (status === "error") {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "failure"
          );
        }
        setLoader(false);
      } catch (err) {
        console.error("Update on connected inbox failed", err);
      }
    } else {
      setLoader(false);
    }
  };

  const cancel = () => {
    setShowConfigure(false);
    setConfigureData(defaultValue);
  };

  const updateAction = event => {
    event.preventDefault();
    event.keyCode === 13 && updateConfiguration();
  };

  const inputList = [
    {
      type: "number",
      id: "maximumEmailsPerDay",
      changeCbk: changeMaximumEmailsPerDay,
      min: 1,
      max: 1000,
      value: maximumEmailsPerDay,
      name: "maximumEmailsPerDay",
      placeholder: "Enter a value between 1 to 1000",
      label: "Max emails per day",
      subText: `Maximum number of sequence emails that can be sent from this email account per day. To ensure your organization's domain stays healthy, we recommend you set this limit to less than 200`,
      errMsg: maximumEmailsError,
      submitCbk: updateAction,
      loader: false
    },
    {
      type: "number",
      id: "minimumDelayBtwEmails",
      changeCbk: changeMinimumDelayBtwEmails,
      min: 15,
      max: 9999,
      value: minimumDelayBtwEmails,
      name: "minimumDelayBtwEmails",
      placeholder: "Enter a value between 15 and 9999",
      label: "Min delay between emails",
      subLabel: "(in seconds)",
      subText: `Minimum delay between emails sent from your sequences.`,
      errMsg: minimumDelayError,
      submitCbk: updateAction,
      loader: false
    }
  ];

  const handleEditorChange = value => value && setEmailSignature(value);

  return (
    <div className="configureInboxSetting">
      <div className="wrapConfigureBox">
        <div className="configHeader">
          <InboxImage type={type} />
          <span className="emailAddress">
            {type === "smtp" ? smtp?.email : email}
          </span>
        </div>
        <div className="configBody">
          <AccountEmailLimitConfiguration inputList={inputList} />
          <div className="signatureBox">
            <h3 className="title">Signature</h3>
            <QuilEditor
              bodyOnChange={handleEditorChange}
              prefilledValue={signature}
            />
          </div>
        </div>
      </div>
      <FormActionButton
        cancel={cancel}
        save={updateConfiguration}
        loader={loader}
      />
    </div>
  );
});

export default Configure;
export { Configure };
