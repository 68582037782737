/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import AddEditCustomUrlPopup from "./AddEditCustomUrlPopup";
import EmptyCustomUrl from "./EmptyCustomUrl";
import CustomUrlDataTable from "./CustomUrlDataTable";
import { customUrlStoreData } from "Stores/Setting/CustomUrlStore";
import { deleteCustomURLState } from "./DeleteCustomUrlPopup";
import FeatureDemoVideo from "Components/common/FeatureDemoVideo";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import PaywallUtils from "Utils/PaywallUtils";

const CustomURL = () => {
  /* Flag Variiables */
  const { team } = userDetail.userInfo;

  const {
    inFreePlan = false,
    brandedUrlEnabled = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  /* State Variables */
  // const [brandedUrlFlag, setBrandedUrlFlag] = useState(brandedUrlEnabled);
  const [loading, setLoading] = useState(true);
  const [customUrlData, setCustomUrlData] = useState(
    customUrlStoreData?.brandUrlData
  );
  const [cnameKey, setCnameKey] = useState(customUrlStoreData.brandUrlCname);
  const [showAddEditPopup, setAddEditPopup] = useState(false);
  const [popupType, setPopupType] = useState(false);

  const upgradeCustomURL = () => {
    PaywallUtils.upgradeUtilsFunction("customURL", false, planName, inFreePlan);
  };

  const showAddUrlBtn = () => {
    return !loading && !customUrlData;
  };

  const editCustomUrl = () => {
    if (Utils.isAdmin()) {
      setCnameKey(customUrlData.cname);
      setPopupType(true);
      setAddEditPopup(true);
    }
  };

  const conformDeleteBrandUrl = () => {
    if (Utils.isAdmin()) {
      deleteCustomURLState.setShowPopup(true);
    }
  };

  const addCustomUrl = () => {
    if (Utils.isAdmin()) {
      setPopupType(false);
      setAddEditPopup(true);
    }
  };

  const closePopup = value => {
    setAddEditPopup(value);
  };

  const getCustomUrlDataFromApi = async () => {
    let res = await customUrlStoreData.getBrandUrlData();
    if (res && Object.keys(res).length > 0) {
      setCustomUrlData(res);
    }
  };

  const getCustomUrlCnameFromApi = async () => {
    let cname = await customUrlStoreData.getBrandUrlCname();
    if (cname) {
      setCnameKey(cname);
    }
  };

  const getInit = async () => {
    if (!brandedUrlEnabled) {
      upgradeCustomURL();
    } else {
      if (team) {
        await getCustomUrlDataFromApi();
        await getCustomUrlCnameFromApi();
      } else {
        Utils.showUpgradeSettingsPopup("customUrl-admin", true, false);
      }
    }
    setLoading(false);
  };

  const getCustomUrlData = data => {
    setCustomUrlData(data);
  };

  useEffect(() => {
    setCustomUrlData(customUrlStoreData.brandUrlData);
  }, [customUrlStoreData.brandUrlData]);

  useEffect(() => {
    if (!brandedUrlEnabled) {
      upgradeCustomURL();
    }
    getInit();
  }, []);

  return (
    <>
      {showAddEditPopup && (
        <AddEditCustomUrlPopup
          closeCbk={closePopup}
          isEdit={popupType}
          data={customUrlData}
          cnameKey={cnameKey}
          setDataCbk={getCustomUrlData}
        />
      )}
      <div className={`customUrlSection ${brandedUrlEnabled ? "" : "blurBg"}`}>
        <h6 className="titleHeader">Custom URL</h6>
        <div className="customUrlBody">
          {showAddUrlBtn() && (
            <>
              <EmptyCustomUrl showAddPopup={addCustomUrl} />
              <FeatureDemoVideo
                name={"Custom Url"}
                linkText={"How to setup custom url in Salesgear"}
                type={"customUrl"}
              />
            </>
          )}
          {customUrlData && Object.keys(customUrlData).length > 0 && (
            <CustomUrlDataTable
              edit={editCustomUrl}
              remove={conformDeleteBrandUrl}
              data={customUrlData}
            />
          )}
          {loading && customUrlData === "" && (
            <>
              <div className="customUrlRowLoader">
                <span className="domainName linear-background" />
                <div className="wrapActionIcon">
                  <span className="linear-background" />
                  <span className="linear-background" />
                </div>
              </div>
              <div className="customUrlInfoTextLoader">
                <span className="icon linear-background" />
                <span className="text linear-background" />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(CustomURL);
export { CustomURL };
