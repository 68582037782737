/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";

const SearchInputBox = observer(props => {
  const {
    valueCbk = () => {},
    type = "text",
    placeholder = "",
    clearSearchInput,
    className = "",
    searchBoxRef = null,
    hasSearchIcon = true,
    style = {},
    setShowTagList = () => {},
    hasCloseIcon = true,
    searchIconAlign = "left",
    clearSearchCbk = () => {},
    disabled = false,
    hideFocus = false,
    discoverPage = false
  } = props || {};

  const [inputText, setInputText] = useState("");
  const [showCloseIcon, setCloseIcon] = useState(false);
  const [errMsg, setErrmsg] = useState(false);

  const resetErrMsg = () => {
    if (errMsg) {
      setErrmsg(false);
    }
  };

  const changeInput = event => {
    resetErrMsg();
    setInputText(event.currentTarget.value);
    if (type === "text") {
      valueCbk(event.currentTarget.value);
    }
    if (event.currentTarget.value.length < 1) {
      setCloseIcon(false);
    } else {
      setCloseIcon(true);
    }
  };

  const searchContact = () => {
    if (!disabled) {
      if (inputText.length > 0) {
        setCloseIcon(true);
      }
      if (type === "email") {
        if (Utils.emailValidate(inputText)) {
          valueCbk(inputText);
        } else {
          setErrmsg(true);
        }
      } else {
        valueCbk(inputText);
      }
    }
  };

  const submitForm = e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      searchContact();
    }
  };

  const clearInputSearch = () => {
    setInputText("");
    type === "text" && valueCbk("");
    setCloseIcon(false);
    resetErrMsg();
    clearSearchCbk && clearSearchCbk();
  };

  useEffect(() => {
    if (clearSearchInput) {
      setInputText("");
      setCloseIcon(false);
    }
  }, [clearSearchInput]);

  useEffect(() => {
    if (!hideFocus) {
      searchBoxRef && searchBoxRef.current && searchBoxRef.current.focus();
    }
  }, []);

  return (
    <div className={`searchInputBtn ${disabled ? "disabled" : ""}`}>
      {hasSearchIcon === true && searchIconAlign === "left" && (
        <i className="material-icons searchIcon" onClick={searchContact}>
          search
        </i>
      )}
      <span
        className={`${
          discoverPage ? " searchTextBox spacingSearch" : "searchTextBox"
        }`}
      >
        <span className="searchInputBoxWrapper">
          <input
            name="search"
            id="searchEmailContact"
            autoComplete="off"
            value={inputText}
            type={type}
            placeholder={placeholder}
            onKeyUp={e => submitForm(e)}
            onChange={e => changeInput(e)}
            className={className}
            ref={searchBoxRef}
            style={style}
            onFocus={() => {
              setShowTagList(true);
            }}
          />
        </span>
        {hasSearchIcon === true && searchIconAlign === "right" && (
          <i className={`material-icons searchIcon`} onClick={searchContact}>
            search
          </i>
        )}
        {hasCloseIcon && showCloseIcon && (
          <i
            className="material-icons searchCloseIcon"
            onClick={clearInputSearch}
          >
            close
          </i>
        )}
      </span>
      <span className="errMsg">{errMsg}</span>
    </div>
  );
});

export default SearchInputBox;
