import { months, days } from "./constants";

const LiveFeedUtils = {};

LiveFeedUtils.isYesterday = inputDate => {
  if (inputDate) {
    const yesterdayDate = new Date(Date.now() - 86400000);
    return (
      yesterdayDate?.getDate() === inputDate?.getDate() &&
      yesterdayDate?.getMonth() === inputDate?.getMonth() &&
      yesterdayDate?.getFullYear() === inputDate?.getFullYear()
    );
  }
  return false;
};

LiveFeedUtils.isToday = inputDate => {
  if (inputDate) {
    const today = new Date();
    return (
      inputDate?.getDate() === today?.getDate() &&
      inputDate?.getMonth() === today?.getMonth() &&
      inputDate?.getFullYear() === today?.getFullYear()
    );
  }
  return false;
};

LiveFeedUtils.formatDate = (value, replaceAtByComma = false) => {
  const date = new Date(value);
  const mon = months[date.getMonth()];
  const dateValue = date.getDate();
  const hours = ((date.getHours() + 11) % 12) + 1;
  const mins = date.getMinutes();
  const meridiem = date.getHours() >= 12 ? "PM" : "AM";
  let convertedHoursText = hours === 12 && meridiem === "AM" ? "00" : hours;
  if (LiveFeedUtils.isYesterday(date)) {
    return `Yesterday${replaceAtByComma ? "," : " at"} ${convertedHoursText}:${
      mins > 9 ? mins : "0" + mins
    } ${meridiem}`;
  } else if (LiveFeedUtils.isToday(date)) {
    return `Today${replaceAtByComma ? "," : " at"} ${convertedHoursText}:${
      mins > 9 ? mins : "0" + mins
    } ${meridiem}`;
  } else {
    return `${mon} ${dateValue}${
      replaceAtByComma ? "," : " at"
    } ${convertedHoursText}:${mins > 9 ? mins : "0" + mins} ${meridiem}`;
  }
};

LiveFeedUtils.addZeroForSingleDigit = value => {
  return +value > 9 ? value : `0${value}`;
};

LiveFeedUtils.formatedFullDateWithHypen = (date = "") => {
  const dateValue = new Date(date);
  const currentDate = LiveFeedUtils.addZeroForSingleDigit(dateValue?.getDate());
  const month = LiveFeedUtils.addZeroForSingleDigit(dateValue?.getMonth() + 1);
  const year = dateValue.getFullYear();
  return `${year}-${month}-${currentDate}`;
};

LiveFeedUtils.getDateDifferenceFromCurrentDate = date => {
  const currentDateInHours = new Date().getTime();
  const inputDateInHours = new Date(
    LiveFeedUtils.formatedFullDateWithHypen(date)
  ).getTime();
  const timeDiff = inputDateInHours - currentDateInHours;
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export { LiveFeedUtils };
export default LiveFeedUtils;
