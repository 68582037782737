import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { linkedInReplyState } from "../state";
import LinearProgressLoader from "Components/LinearProgressLoader";
import {
  LinkedInPopupHeader,
  LinkedInPopupFooter
} from "../common/LinkedInPopupHeaderFooter";

const ConnectLinkedInLoading = observer(() => {
  const [progress, setProgress] = useState(0);

  const closeIconCbk = () => {
    linkedInReplyState.setLinkedInConnectionLoading(false);
  };

  useEffect(() => {
    if (linkedInReplyState?.linkedInConnectionLoading) {
      setTimeout(() => {
        if (progress < 100) {
          setProgress(progress + 10);
        }
      }, 1000);
    }
  });

  return (
    linkedInReplyState?.linkedInConnectionLoading && (
      <div className="linkedInNotConnectedWrapper">
        <div className="card">
          <LinkedInPopupHeader closeIconCbk={closeIconCbk} />
          <div className="linkedInBody">
            <p className="noLinkedInText">Looking for LinkedIn account...</p>
            <LinearProgressLoader progress={progress} showTitle={false} />
          </div>
          <LinkedInPopupFooter />
        </div>
        <div className="common-popup-mask" onClick={closeIconCbk} />
      </div>
    )
  );
});

export { ConnectLinkedInLoading };
export default ConnectLinkedInLoading;
