/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { SEQUENCE_STAGE_ICON } from "Model/model";
import ConstantObj from "Static/constant";
import Utils from "Utils/utils";
import { sequenceStoreData } from "Stores/sequenceData";
import { toasterState } from "./toaster";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import AddStageDropDown from "Pages/Dashboard/sequences/SequencePage/AddStageDropDown";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import PaywallUtils from "Utils/PaywallUtils";

let globalStepName;

const AddStepBtn = props => {
  const {
    taskEnabled = false,
    planName = "",
    inFreePlan = false
  } = userDetail?.userFeatureFlag || {};
  const [showNewDropDown, setNewDropDown] = useState(false);
  const {
    seqId = "",
    callback = () => {},
    flag = "",
    stepLength = 0,
    daysFromStart = 1,
    disabled = false,
    cbk = () => {},
    isTaskCreation = false
  } = props || {};

  const newBtnAction = async () => {
    setNewDropDown(true);
  };

  const addStepAction = (event, stepName) => {
    Utils.preventDefaultFn(event);
    globalStepName = stepName;
    flag === "sequence" ? checkSeqStatus() : addStepCbk();
  };

  const addStepCbk = () => {
    globalStepName && callback(globalStepName);
  };

  const checkSeqStatus = () => {
    const { hasModifyAccess, message, sequenceId } =
      sequenceStoreData.sequenceStatus || {};
    if (sequenceId && sequenceId === seqId) {
      hasModifyAccess
        ? addStepCbk()
        : toasterState.setToastMsg(message, "failure");
    } else {
      checkSeqStatusOnDemand();
    }
  };

  const checkSeqStatusOnDemand = () => {
    Utils.checkSeqStatus(
      "addNewStep",
      seqId,
      addStepCbk,
      "You cannot add a step to the sequence that has active recipients.",
      false,
      false
    );
  };

  const dropdownPosition = () => {
    const targetDom = document.querySelector(".addStepsButton");
    if (targetDom) {
      if (showNewDropDown) {
        setTimeout(() => {
          targetDom.classList.add("down");
        }, 10);
      } else {
        setTimeout(() => {
          targetDom.classList.remove("down");
        }, 10);
      }
    }
  };

  const isValidStep = stepId => {
    if (stepId && flag) {
      return !(
        flag === "task" && ["email", "phone", "customTask"].includes(stepId)
      );
    }
  };

  const lockFeature = value => {
    return value !== "email" && !taskEnabled;
  };

  const toggleAction = (e, stepObj) => {
    const { id = "" } = stepObj || {};
    setNewDropDown(false);
    if (id === "email" || taskEnabled) {
      addStepAction(e, id);
    } else {
      PaywallUtils.upgradeUtilsFunction(
        "sequenceAddTaskToSequence",
        true,
        planName,
        inFreePlan
      );
    }
  };

  return (
    <>
      <div
        className={`addNewBtnCont ${disabled ? "disabledAddStepBtn" : ""} `}
        onClick={isTaskCreation ? cbk : newBtnAction}
      >
        <div className="addNewButton btn">
          {flag === "sequence" ? "Add Step" : "Add Task"}
          {flag === "sequence" && (
            <i className="material-icons arrowIcon">keyboard_arrow_down</i>
          )}
        </div>
      </div>
      {showNewDropDown && !isTaskCreation && (
        <AddStageDropDown
          showDropDownMenu={showNewDropDown}
          setShowDropDownMenu={setNewDropDown}
          cbk={cbk}
          isTaskCreation={isTaskCreation}
          className={"addStepDropdown"}
        />
      )}
    </>
  );
};

const SubMenu = props => {
  const { id } = props;
  return (
    <div className="linkedInSubMenu downCont">
      {id === "linkedin" ? <LinkedInComponent {...props} /> : ""}
    </div>
  );
};

const LinkedInComponent = props => {
  const { cbk, toggleLinkedInOptions } = props;
  const submenuOptions = ConstantObj.linkedinInSubmenu;
  return submenuOptions?.length > 0
    ? submenuOptions.map(function (optionObj, idx) {
        const { value = "", key = "", subKey = "" } = optionObj || {};
        if (value && key) {
          return (
            <div
              className={`stepCont`}
              key={idx}
              id={key}
              onClick={e => {
                toggleLinkedInOptions(false);
                cbk(e, subKey);
              }}
            >
              <div className="addstepBtn">{value}</div>
            </div>
          );
        } else return "";
      })
    : "";
};

export default observer(AddStepBtn);
export { AddStepBtn, LinkedInComponent };
