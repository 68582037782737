import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Button from "Components/common/Button";

let initialCounter = 30;
const ResendButton = observer(props => {
  const {
    loader = false,
    handleClick = () => {},
    noOfClicks = 1,
    className = "",
    isLoggedIn = false
  } = props || {};
  const [counter, setCounter] = useState(30);
  const isFromLoggedInUser = isLoggedIn;

  const btnObj = {
    id: "resendBtn",
    name: "resendBtn",
    className: `${className ? className : ""} ${
      loader ? "loading" : "signupBtn btn-new btn-rounded btn-orange btn-text"
    } ${
      (counter === 0 && noOfClicks <= 2) || isFromLoggedInUser ? "" : "disabled"
    }`,
    type: "button",
    click: e => {
      e.preventDefault();
      ((counter === 0 && noOfClicks <= 2) || isFromLoggedInUser) &&
        handleClick(e);
    },
    loader,
    btnText: "Resend"
  };

  useEffect(() => {
    if (noOfClicks <= 2 && noOfClicks !== 0) {
      setCounter(noOfClicks * initialCounter);
    } else {
      setCounter(0);
    }
  }, [noOfClicks]);

  useEffect(() => {
    if (noOfClicks !== 0) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, noOfClicks]);

  return (
    <div className={"resendBtnContainer"}>
      {!isFromLoggedInUser && (
        <p className="message">
          Next Verification email will be sent in <b>{`${counter}s`}</b>
        </p>
      )}
      <Button {...btnObj} />
    </div>
  );
});

export default ResendButton;
