/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import CheckBox from "Components/common/CheckBox";
import taskStoreData from "Stores/taskData";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import filterComponentState from "./FilterComponent/filterComponentState";
import userDetail from "Stores/userDetail";
import DropDownMenu from "Components/common/DropDownMenu";
import PaginationComponent from "Components/common/PaginationComponent";
import Utils from "Utils/utils";
import TaskUtils from "Utils/TaskUtils";
import TokenUtils from "Utils/TokenUtils";
import { URLS, makeApi } from "Utils/apiURL";
import { SINGLE_TASK_TOAST_MESSAGE } from "Model/model";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import {
  TASK_TABLE_HEADER as taskTableHeader,
  taskTypeIcon
} from "Static/constant";
import { ReactComponent as DeleteIcon } from "Assets/svg/delete.svg";
import { ReactComponent as TaskIcon } from "Assets/svg/task.svg";
const selectedOption = "";

const TaskTable = withRouter(
  observer((props = {}) => {
    const {
      taskData = {},
      activeTab = "",
      logState = {},
      accountId = "",
      loading = false,
      hasAccountFilter = false,
      selectedTask = null,
      taskTypeList = [],
      pageType = "",
      showTaskTypeDropdown = false,
      setTaskData = () => {},
      missingTokensPromptCbk = () => {},
      handleInputCheckboxAction = () => {},
      reloadAccountFilterAnalytics = () => {},
      setShowTaskTypeDropdown = () => {},
      openContactCard = () => {},
      tableLoadingFn = () => {},
      filterLoadingFn = () => {},
      singleTaskAction = () => {},
      selectedOptions = [],
      setSelectedOptions = () => {}
    } = props || {};

    const history = useHistory();

    const isBlockedDomain = (email = "") => {
      let tempEmail = email?.split("@") || [];
      if (
        tempEmail?.length > 1 &&
        userDetail?.blockedDomainList.includes(tempEmail?.[1])
      ) {
        confirmationPopupState.setPopupValues({
          content:
            "You cannot execute tasks/calls for contacts with ‘do not contact’ stage",
          needCancelBtn: false,
          actionBtnText: "Ok",
          callback: () => {
            confirmationPopupState.setShowPopup(false);
          }
        });
        confirmationPopupState.setShowPopup(true);
        return true;
      }
      return false;
    };

    const deleteTask = async (_, taskId = "", opt = {}) => {
      showDropDownMenu(null, taskId, false);
      const deleteTaskCbk = async (buttonLoadingFn = () => {}) => {
        if (await checkTeamSubscriptionActive()) {
          const config = {
            url: URLS.deleteTask,
            method: "DELETE",
            data: { id: taskId }
          };
          const response = await makeApi(config);
          const respStatus = response && response.status;
          buttonLoadingFn(false);
          confirmationPopupState.setShowPopup(false);
          if (respStatus) {
            if (respStatus && respStatus === 200) {
              toasterState.setToastMsg(
                SINGLE_TASK_TOAST_MESSAGE["success"][opt?.key],
                "success"
              );
              taskStoreData.getTaskFilterData();
              taskStoreData.getTaskData();
              tableLoadingFn(true);
              filterLoadingFn(true);
              if (pageType?.toUpperCase() === "ACCOUNT") {
                reloadAccountFilterAnalytics();
              }
            } else {
              toasterState.setToastMsg(
                SINGLE_TASK_TOAST_MESSAGE["fail"][opt?.key],
                "success"
              );
            }
          } else {
            Utils.showApiFailureResponse(response);
          }
        }
      };
      confirmationPopupState.setPopupValues({
        content: "Are you sure you want to delete 1 selected task?",
        actionBtnText: "Yes",
        callback: deleteTaskCbk,
        loadingMsg: "Deleting task. Please wait..."
      });
      confirmationPopupState.setShowPopup(true);
    };

    const handleEditTaskCbk = async (taskData = {}, loaderCbk = () => {}) => {
      if (taskData && Object.keys(taskData)?.length) {
        try {
          const payload = TaskUtils.getPayload(taskData, true);
          const config = {
            url: URLS.createTask || "",
            method: "POST",
            data: payload
          };
          const response = await makeApi(config);
          const respData = response?.data;
          if (respData) {
            if (respData?.task && respData?.responseCode === 200) {
              toasterState.setToastMsg(
                SINGLE_TASK_TOAST_MESSAGE["success"]["EDIT_TASK_DUE_DATE"],
                "success"
              );
              taskStoreData.getTaskFilterData();
              taskStoreData.getTaskData();
              tableLoadingFn(true);
              filterLoadingFn(true);
              if (pageType?.toUpperCase() === "ACCOUNT") {
                reloadAccountFilterAnalytics();
              }
              loaderCbk();
            } else {
              if (
                respData?.responseCode === 406 &&
                respData?.missingFields?.length
              ) {
                loaderCbk(true);
                missingTokensPromptCbk(respData?.missingFields);
              } else {
                toasterState.setToastMsg(
                  SINGLE_TASK_TOAST_MESSAGE["fail"]["EDIT_TASK_DUE_DATE"],
                  "success"
                );
                loaderCbk();
              }
            }
          } else {
            Utils.showApiFailureResponse(response);
            loaderCbk();
          }
        } catch (e) {
          toasterState.setToastMsg("Task creation failed", "failure");
          loaderCbk();
        }
      }
    };

    const openEditTaskPopup = async (currentTask = {}, taskId = "") => {
      showDropDownMenu(null, taskId, false);
      if (await checkTeamSubscriptionActive()) {
        const { task = {}, contact = {} } = currentTask || {};
        const {
          subject = "",
          content = "",
          note = "",
          taskDue = "",
          taskType = "",
          linkedInTaskType = "",
          touchPoint = {}
        } = task || {};
        const notesString = getNotes(note, touchPoint);
        const { email = "" } = contact || {};
        if (!isBlockedDomain(email)) {
          const popupValues = {
            pageSource: "Tasks",
            isEdit: true,
            inputVal: {
              subject,
              content,
              notes: notesString,
              ...touchPoint
            },
            email,
            notes: notesString,
            btnText: ["email", "manualEmail"]?.includes(taskType)
              ? "Next"
              : "Update Task",
            isTaskCreation: true,
            dueDateString: new Date(taskDue),
            linkedInTaskType,
            timeZone: Utils.getCurrentUserTimeZone(),
            stageType: taskType === "email" ? "manualEmail" : taskType,
            createCbk: handleEditTaskCbk
          };
          addStagesPopupState.setPopupValues({
            ...addStagesPopupState.popupValues,
            ...popupValues,
            taskId
          });
          addStagesPopupState.setShowPopup(true);
        }
      }
    };

    const handleSingleDropdownAction = (_, taskId = "", opt = {}) => {
      showDropDownMenu(null, taskId, false);
      singleTaskAction(opt?.key, taskId);
    };

    const dropdownOptions = [
      {
        icon: {
          ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">edit</i>',
          style: { width: "15px", height: "15px" }
        },
        value: "Edit",
        key: "EDIT_TASK_DUE_DATE",
        type: "task",
        method: openEditTaskPopup
      },
      {
        icon: {
          ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">skip_next</i>',
          style: { width: "15px", height: "15px" }
        },
        value: "Skip",
        type: "task",
        key: "SKIP_TASK",
        method: handleSingleDropdownAction
      },
      {
        icon: {
          ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">check_circle</i>',
          style: { width: "15px", height: "15px" }
        },
        value: "Complete",
        type: "task",
        key: "COMPLETE_TASK",
        method: handleSingleDropdownAction
      },
      {
        icon: {
          ele: '<i style="font-size: 14px" class="material-icons-outlined list-icon">delete</i>',
          style: { width: "15px", height: "15px" }
        },
        value: "Delete",
        type: "task",
        key: "DELETE_TASK",
        method: deleteTask
      }
    ];

    const paginate = (pageNoObj = {}) => {
      if (!loading) {
        const pageNo = pageNoObj?.selected + 1 || 1;
        tableLoadingFn(true);
        const dateFilterValue = taskStoreData?.dateFilterValue || null;
        const statusFilterValue = taskStoreData?.statusFilterValue || null;
        taskStoreData.getTaskData(
          accountId,
          ["total"]?.includes(activeTab) ? null : activeTab,
          pageNo,
          ["total"]?.includes(activeTab) ? true : null,
          null,
          20,
          dateFilterValue,
          statusFilterValue
        );
      }
    };

    const showDropDownMenu = (e, taskId = "", arg = false) => {
      Utils.preventDefaultFn(e);
      let taskList = taskData?.tasks || [];
      const updatedContactList = [...taskList].map(e => {
        const { task = {} } = e || {};
        if (task?.id === taskId) {
          if (e) {
            e.positionY = e?.pageY;
          }
          e.showTaskDropDown = arg ? false : !e?.showTaskDropDown;
        }
        return e;
      });
      taskData.tasks = updatedContactList;
      setTaskData(taskData);
    };

    const LoadingComponent = ({ status = "" }) => {
      return (
        <>
          {Array.from({ length: 10 }, (value, index) => (
            <div
              className={`taskTableBodyLoader ${
                status?.toUpperCase() === "ACTIVE" ? "selectCheckbox" : ""
              } ${
                ["skipped", "completed"].includes(
                  taskStoreData?.activeStatusTab
                )
                  ? "hideMoreOptions"
                  : ""
              }`}
              key={`taskTableBodyLoader${index}`}
            >
              {taskStoreData?.statusFilterValue?.status?.toUpperCase() ===
                "ACTIVE" && (
                <div className="checkboxBtn">
                  <span className="checkBoxSection linear-background" />
                </div>
              )}
              <div className="contactInfo">
                <span className="fullName linear-background" />
                <span className=" jobTitleCompany linear-background" />
              </div>
              <div className="taskType flexItem">
                <span className="circle linear-background" />
                <span className="line linear-background" />
              </div>
              <div className="taskNotesWrapper">
                <div className="taskNotes">
                  <span className="lineOne linear-background" />
                  <span className="lineTwo linear-background" />
                </div>
                <div className="taskStageSequenceLoader flexItem">
                  <div className="taskStage">
                    <span className="linear-background" />
                  </div>
                  <div className="taskSequence">
                    <span className="linear-background" />
                  </div>
                </div>
              </div>
              <div className="taskDueDate">
                <span className="linear-background" />
              </div>
              {!["skipped", "completed"].includes(
                taskStoreData?.activeStatusTab
              ) && (
                <div className="taskMoreOptions">
                  <span className="linear-background" />
                </div>
              )}
            </div>
          ))}
        </>
      );
    };

    const getNotes = (note = "", touchPoint = {}) => {
      const { notes = "" } = touchPoint || {};
      let tempString = note || notes || "";
      return (tempString && tempString.replace(/\r?\n/g, " ")) || "--";
    };

    const handleContactSlider = (event, contactObj = {}) => {
      event.preventDefault();
      event.stopPropagation();
      openContactCard(contactObj);
    };

    let isCheckboxEnabled =
      ["due", "upcoming"].includes(
        taskStoreData?.activeStatusTab?.toLowerCase()
      ) && taskStoreData?.statusFilterValue?.status?.toUpperCase() === "ACTIVE";

    useEffect(() => {
      taskStoreData.setSelectedTaskPage(taskData?.currentPage);
    }, [taskData]);

    useEffect(() => {
      if (!accountId) {
        tableLoadingFn(true);
        taskStoreData.getTaskData(accountId, null, 1, true, null, 20, {
          status: "ACTIVE"
        });
      }
    }, []);

    return (
      <div
        className={`tasksTableCont ${
          accountId && pageType?.toUpperCase() === "ACCOUNT"
            ? `accountTasksTable ${
                !hasAccountFilter ||
                (hasAccountFilter && !loading && taskData?.totalTasks < 1)
                  ? "adjustFilterHeight"
                  : ""
              }`
            : ""
        } ${taskData?.totalPages > 1 ? "" : "hidePagination"}`}
      >
        <div
          className={`tasksTableContBody ${
            loading || taskData?.totalPages > 1 ? "" : "noPagination"
          }`}
        >
          <div
            className={`taskHeader ${
              isCheckboxEnabled ? "selectCheckbox" : ""
            } ${
              ["skipped", "completed"].includes(taskStoreData?.activeStatusTab)
                ? "hideMoreOptions"
                : ""
            }`}
          >
            {isCheckboxEnabled && (
              <div className="checkboxBtn">
                {!loading && taskData?.totalTasks > 0 && (
                  <CheckBox
                    key="selectAll"
                    id="all"
                    checked={logState?.selectAll}
                    cbk={handleInputCheckboxAction}
                    allContactsChecked={logState?.allContactsChecked}
                  />
                )}
              </div>
            )}
            {taskTableHeader.map(item => {
              const { className = "", name = "", key = "" } = item || {};
              return (
                <div className={className} key={key}>
                  {name}
                  {key?.toLowerCase() === "tasktype" && (
                    <AccountTaskTypeDropdown
                      selectedTask={selectedTask}
                      taskTypeList={taskTypeList}
                      showTaskTypeDropdown={showTaskTypeDropdown}
                      setShowTaskTypeDropdown={setShowTaskTypeDropdown}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  )}
                </div>
              );
            })}
          </div>
          {!loading ? (
            taskData?.tasks?.length > 0 ? (
              <>
                {taskData.tasks.map((dataMap, key) => {
                  if (dataMap && Object.keys(dataMap).length) {
                    let {
                      task = {},
                      contact = {},
                      showTaskDropDown = false,
                      positionY = "",
                      checked = false
                    } = dataMap || {};
                    let {
                      firstName = "",
                      company = "",
                      lastName = "",
                      name = "",
                      email = "",
                      jobTitle = ""
                    } = contact || {};
                    let {
                      taskDue = "",
                      note = "",
                      taskType = "",
                      id = "",
                      touchPoint = "",
                      order = "",
                      sequenceName = "",
                      customTask = ""
                    } = task || {};

                    const dueDateObj = Utils.getDueDateToRender(
                      touchPoint?.scheduleDate || taskDue
                    );
                    const notesString = getNotes(note, touchPoint);
                    const companyDomain = email.replace(/.*@/, "");
                    let isCheckboxEnabled =
                      ["due", "upcoming"].includes(
                        taskStoreData?.activeStatusTab?.toLowerCase()
                      ) &&
                      taskStoreData?.statusFilterValue?.status?.toUpperCase() ===
                        "ACTIVE";
                    return (
                      <div
                        key={key}
                        className={`taskTableBody ${id} ${
                          isCheckboxEnabled ? "selectCheckbox" : ""
                        } ${
                          ["skipped", "completed"].includes(
                            taskStoreData?.activeStatusTab
                          )
                            ? "hideMoreOptions"
                            : ""
                        }`}
                        onClick={() => {
                          taskStoreData.setCurrentTaskId(id);
                          accountId &&
                            pageType?.toUpperCase() === "ACCOUNT" &&
                            taskStoreData.setAccountId(accountId);
                          history.push(`/tasks/start/${id}`, { key: key + 1 });
                        }}
                      >
                        {isCheckboxEnabled && (
                          <div className="checkboxBtn">
                            {taskData?.totalTasks > 0 && (
                              <CheckBox
                                key="singleSelectCallLogCheckbox"
                                id={id}
                                checked={checked}
                                cbk={handleInputCheckboxAction}
                              />
                            )}
                          </div>
                        )}
                        <div className="contactInfo">
                          <span
                            className="fullName"
                            onClick={event => {
                              handleContactSlider(event, contact);
                            }}
                          >
                            {Utils.getName(firstName, lastName, name, email)}
                          </span>
                          <span className="jobTitleCompany">{`${
                            jobTitle ? jobTitle : ""
                          } @${company || companyDomain || ""}`}</span>
                        </div>
                        <div className="taskType">
                          <span className={`taskTypeWrapper ${taskType}`}>
                            <span className="taskTypeIcon">
                              {taskTypeIcon[taskType]?.icon}
                            </span>
                            <span className="taskTypeLabel">
                              {taskTypeIcon[taskType]?.label}
                            </span>
                          </span>
                        </div>
                        <div className="taskNotesWrapper">
                          <div className="taskNotes">
                            <span className="notes" title={notesString}>
                              {notesString}
                            </span>
                          </div>
                          <div className="taskStageSequence flexItem">
                            <div className="taskSequence">
                              {sequenceName && (
                                <div className="sequenceBadge">
                                  <span className="name" title={sequenceName}>
                                    {sequenceName}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="taskStage">
                              {order > 0 && (
                                <span className="stageNumber">{`Step ${order}`}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="taskDueDate"
                          style={{
                            color:
                              taskStoreData?.statusFilterValue?.status ===
                              "ACTIVE"
                                ? dueDateObj.color
                                : "#666666"
                          }}
                        >
                          {taskStoreData?.statusFilterValue?.status ===
                          "ACTIVE" ? (
                            <>
                              <i className="material-icons">schedule</i>
                              <span>{dueDateObj.dueDate}</span>
                            </>
                          ) : (
                            <span>{"--"}</span>
                          )}
                        </div>
                        {(!["skipped", "completed"].includes(
                          taskStoreData?.activeStatusTab
                        ) && (
                          <div className="taskMoreOptions">
                            <i
                              className="material-icons-outlined icon-hover-btn moreOptionIcon"
                              onClick={e => showDropDownMenu(e, id)}
                              title="More Options"
                            >
                              more_vert
                            </i>
                            {showTaskDropDown && (
                              <>
                                <DropDownMenu
                                  options={dropdownOptions}
                                  selected={selectedOption}
                                  referenceId={id}
                                  direction="left"
                                  parentPosition={positionY}
                                  contact={dataMap}
                                />
                                <div
                                  className="dropdownMask"
                                  onClick={e => showDropDownMenu(e, id, false)}
                                />
                              </>
                            )}
                          </div>
                        )) ||
                          ""}
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <div className="no-task">
                <span className="taskIcon">
                  <TaskIcon />
                </span>
                <span className="noTaskText">No Tasks found</span>
              </div>
            )
          ) : (
            <LoadingComponent
              status={taskStoreData?.statusFilterValue?.status}
            />
          )}
        </div>
        <PaginationComponent
          callback={paginate}
          loading={loading}
          totalPages={taskData?.totalPages || 0}
          currentPage={taskData?.currentPage || 1}
        />
      </div>
    );
  })
);

const EmptyTasks = () => {
  return (
    <div className="emptyTasksSection">
      <span className="taskIcon">
        <TaskIcon />
      </span>
      <span className="text">No Tasks Found</span>
    </div>
  );
};

const AccountTaskTypeDropdown = ({
  taskTypeList = [],
  selectedTask = "",
  showTaskTypeDropdown = false,
  setShowTaskTypeDropdown = () => {},
  selectedOptions,
  setSelectedOptions
}) => {
  const arrangeValue = {
    total: "All",
    email: "Manual Email",
    linkedin: "LinkedIn",
    phone: "Phone",
    customTask: "CustomTask"
  };
  const convertFn = () => {
    const selectedOptionsLength = Object?.keys(selectedOptions)?.filter(
      key => selectedOptions[key]
    );
    const totalOptionsLength = Object?.keys(selectedOptions)?.length;
    let displayText = "";
    if (selectedOptionsLength?.length === totalOptionsLength) {
      displayText = "All";
    } else if (selectedOptionsLength?.length > 1) {
      displayText =
        arrangeValue[selectedOptionsLength[0]] +
        "," +
        "<span style='color: #3953FB'>" +
        "+" +
        (selectedOptionsLength?.length - 1) +
        "</span>";
    } else if (selectedOptionsLength?.length === 1) {
      displayText = arrangeValue[selectedOptionsLength[0]];
    } else {
      displayText = "All";
    }
    return displayText;
  };

  return (
    <div
      className="accountTaskTypeDropdown"
      onClick={e => {
        Utils.preventDefaultFn(e);
        setShowTaskTypeDropdown(true);
      }}
    >
      <div className="selectedTaskTypeValue">
        {(
          <div
            className="selctedCount"
            dangerouslySetInnerHTML={{
              __html: convertFn() || ""
            }}
          />
        ) || "All"}
      </div>
      <i
        className={`material-icons-outlined ${
          showTaskTypeDropdown ? "tranformArrow" : ""
        }`}
      >
        keyboard_arrow_down
      </i>
      {showTaskTypeDropdown && (
        <>
          <DropDownMenu
            options={taskTypeList}
            referenceId={"accountTaskTypeList"}
            multiSelect={true}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
          <div
            className="dropdownMask"
            onClick={e => {
              Utils.preventDefaultFn(e);
              setShowTaskTypeDropdown(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export { TaskTable };
export default TaskTable;
