import { observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import makeApi, { URLS } from "Utils/apiURL";

const defaultConditionsObject = {
  fieldName: "",
  operator: "EQUALS",
  value: "",
  name: ""
};

class SalesforceConnectedData {
  @observable salesForceData = "";
  @observable SfSettingPreference = {};
  @observable sfOwnersList = "";
  @observable sfLeadConfig = {};
  @observable sfContactConfig = {};
  @observable sfOwnerConfig = {};
  @observable disableSaveButton = false;
  @observable sfLeadTags = [];
  @observable sfContactTags = [];
  @observable sfLeadFields = [];
  @observable sfContactFields = [];
  @observable sfLeadConditions = [defaultConditionsObject];
  @observable sfContactConditions = [defaultConditionsObject];
  @observable apiLimitPerDay = 1;

  @action
  setSalesforceData(obj) {
    this.salesForceData = { ...this.salesForceData, ...obj };
  }

  @action
  setSfSettingPreference = value => {
    this.SfSettingPreference = value;
  };

  @action
  setSfOwnersList = value => {
    this.sfOwnersList = value;
  };

  @action
  setSfLeadConfig = value => {
    this.sfLeadConfig = value;
  };

  @action
  setSfContactConfig = value => {
    this.sfContactConfig = value;
  };

  @action
  setSfOwnerConfig = value => {
    this.sfOwnerConfig = value;
  };

  @action
  setDisableSaveButton = value => {
    this.disableSaveButton = value;
  };

  @action
  setSfLeadTags = value => {
    this.sfLeadTags = value;
  };

  @action
  setSfContactTags = value => {
    this.sfContactTags = value;
  };

  @action
  setSfLeadConditions = value => {
    this.sfLeadConditions = value;
  };

  @action
  setSfContactConditions = value => {
    this.sfContactConditions = value;
  };

  @action
  setApiLimitPerDay = value => {
    this.apiLimitPerDay = value;
  };

  @action
  getSalesForceOauthData = async () => {
    try {
      const config = {
        url: URLS.getSalesforceAccountDetails,
        method: "GET"
      };
      let response = await makeApi(config);
      response?.data && this.setSalesforceData(response?.data);
    } catch (err) {
      console.error(err);
    }
  };

  @action
  saveAndUpdateSfSettingPreference = async (data, cbk) => {
    try {
      const config = {
        url: URLS.saveAndUpdateSfSettingPreference,
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (res?.data) {
        toasterState.setToastMsg("Successfully Updated", "success");
        cbk(true);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
        cbk(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  removeSfAccount = async cbk => {
    try {
      const config = {
        url: URLS.removeSfAccount,
        method: "DELETE"
      };
      let res = await makeApi(config);
      if (res?.data) {
        await this.getSalesForceOauthData();
        toasterState.setToastMsg(
          "Salesforce account disconnected successfully",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
      cbk(false);
    } catch (err) {
      console.error("SFDC failed to remove error", err);
    }
  };

  @action
  getAllOwners = async () => {
    try {
      const config = {
        url: URLS.getAllSfOwners,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.setSfOwnersList(res?.data);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Salesforce Owners listing error", err);
    }
  };

  @action
  getAllSfFields = async type => {
    try {
      const config = {
        url: `${URLS.getAllSfFields}/${type}`,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        if (type === "LEAD") {
          this.sfLeadFields = res?.data;
        } else {
          this.sfContactFields = res?.data;
        }
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Salesforce Owners listing error", err);
    }
  };
}

const salesforceConnectedData = new SalesforceConnectedData();
export { SalesforceConnectedData, salesforceConnectedData };
export default SalesforceConnectedData;
