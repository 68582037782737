import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import Button from "Components/common/Button";

class ExportToSalesforcePopupState {
  @observable showPopup = false;
  @observable btnLoader = false;
  @observable defaultObj = {};
  @observable selectedKey = "lead";

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
  };

  @action
  setBtnLoader = (value = false) => {
    this.btnLoader = value;
  };

  @action
  setDefaultObj = (value = {}) => {
    this.defaultObj = value;
  };

  @action
  setSelectedKey = (value = "") => {
    this.selectedKey = value;
  };
}

const exportToSalesforcePopupState = new ExportToSalesforcePopupState();

const ExportToSalesforcePopup = observer(() => {
  const handleExportAction = () => {
    exportToSalesforcePopupState.setBtnLoader(true);
    exportToSalesforcePopupState.defaultObj.cbk(
      exportToSalesforcePopupState?.selectedKey
    );
  };

  const closePopup = () => {
    exportToSalesforcePopupState.setSelectedKey("lead");
    exportToSalesforcePopupState.setShowPopup(false);
  };

  const handleSelectAction = (value = "") => {
    exportToSalesforcePopupState.setSelectedKey(value);
  };

  const exportBtn = {
    id: "exportBtn",
    name: "exportBtn",
    btnText: "Export",
    type: "button",
    loader: exportToSalesforcePopupState?.btnLoader || false,
    className: `exportToSalesforceBtn ${
      exportToSalesforcePopupState?.btnLoader ? "loading" : ""
    }`,
    click: handleExportAction
  };

  return exportToSalesforcePopupState.showPopup ? (
    <div className="exportToSalesforcePopup">
      <div
        className={`card animated customzoomIn ${
          exportToSalesforcePopupState?.btnLoader ? "disableClick" : ""
        }`}
      >
        <div className="cardHeader">
          <div className="textWrapper">
            <div className="title">{`Export to Salesforce`}</div>
          </div>
          <i className="material-icons close" onClick={closePopup}>
            close
          </i>
        </div>
        <div className="cardBody">
          <div className="exportListWrapper">
            <div className="title">{`Select export as`}</div>
            <div className="exportList">
              {exportConfig.map(member => (
                <div
                  className={`options ${
                    member?.key === exportToSalesforcePopupState?.selectedKey
                      ? "activeMember"
                      : ""
                  }`}
                >
                  <div className="radioBtnCont">
                    <div className="radioButtonCont">
                      <input
                        type="radio"
                        name="addToListRadioCheckbox"
                        id={member?.key}
                        onChange={() => handleSelectAction(member?.key)}
                        checked={
                          member?.key ===
                          exportToSalesforcePopupState?.selectedKey
                        }
                        className="radioBtn"
                      />
                      <span className="contentClass" />
                    </div>
                  </div>
                  <label className="teamMemberName" htmlFor={member?.key}>
                    {member?.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <Button {...exportBtn} />
        </div>
      </div>
      <div className="overlay" />
    </div>
  ) : (
    ""
  );
});

const exportConfig = [
  {
    name: "Lead",
    key: "lead"
  },
  {
    name: "Contact",
    key: "contact"
  }
];

export default ExportToSalesforcePopup;
export { ExportToSalesforcePopup, exportToSalesforcePopupState };
