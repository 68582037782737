import React, { useState } from "react";
import { ReactComponent as LinkedinIcon } from "Assets/svg/icn_in.svg";
import { ReactComponent as TwitterIcon } from "Assets/svg/icn_tw.svg";
import { ReactComponent as FacebookIcon } from "Assets/svg/fb_rounded.svg";
import { ReactComponent as WebsiteIcon } from "Assets/svg/icn_web.svg";
import { ReactComponent as PhoneNumberIcon } from "Assets/contactSlider/phoneNumber.svg";
import { Utils } from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import { CustomTooltip } from "Components/common/CustomTooltip";

const SocialLinks = props => {
  const {
    companyDomain = "",
    linkedIn = "",
    facebook = "",
    twitter = "",
    companyNumber = "",
    showOnlyLinkedInAndTwitter = false,
    id = ""
  } = props || {};

  const [showTooltip, setShowTooltip] = useState({});
  const redirect = url => {
    Utils.windowRedirect(Utils.removeHttpFromString(url), "_blank", true, true);
  };

  const copy = (val = "", msg = "") => {
    if (val) {
      Utils.copyToClipboard(val);
      toasterState.setToastMsg(msg, "success");
    }
  };

  return (
    <div className="socialCont">
      {showOnlyLinkedInAndTwitter ? (
        <>
          <span
            onClick={() => redirect(linkedIn)}
            className={!linkedIn ? "disabled" : ""}
            style={{ marginRight: "6px" }}
          >
            <LinkedinIcon />
          </span>
          <span
            onClick={() => redirect(twitter)}
            className={!twitter ? "disabled d-none" : ""}
            style={{ marginRight: "6px" }}
          >
            <TwitterIcon />
          </span>
        </>
      ) : (
        <>
          <span
            onClick={() => redirect(companyDomain)}
            className={!companyDomain ? "disabled" : ""}
            style={{ marginRight: "6px" }}
          >
            <WebsiteIcon className="websiteIcon" />
          </span>
          <span
            onClick={() => redirect(linkedIn)}
            className={!linkedIn ? "disabled d-none" : ""}
            style={{ marginRight: "6px" }}
          >
            <LinkedinIcon />
          </span>
          <span
            onClick={() => redirect(facebook)}
            className={!facebook ? "disabled d-none" : ""}
            style={{ marginRight: "6px" }}
          >
            <FacebookIcon />
          </span>
          <span
            onClick={() => redirect(twitter)}
            className={!twitter ? "disabled d-none" : ""}
            style={{ marginRight: "6px" }}
          >
            <TwitterIcon />
          </span>
          <span
            onClick={() =>
              copy(companyNumber?.number, "Company number copied successfully.")
            }
            className={!companyNumber?.number ? "disabled d-none" : ""}
            style={{ marginRight: "6px" }}
            onMouseEnter={() => setShowTooltip({ id, value: true })}
            onMouseLeave={() => setShowTooltip({ id, value: false })}
          >
            <PhoneNumberIcon className="phoneIcon" />
            {companyNumber?.number &&
              showTooltip?.id === id &&
              showTooltip?.value && (
                <CustomTooltip text={companyNumber?.number} />
              )}
          </span>
        </>
      )}
    </div>
  );
};

export default SocialLinks;
export { SocialLinks };
