import { observable, action } from "mobx";
import { reCaptchaState } from "Components/common/captcha";

class CaptchaStore {
  @observable showCaptcha = false;
  @observable captchaToken = "";

  @action
  setCaptcha(value = false) {
    this.showCaptcha = value;
  }

  @action
  executeCaptcha(args = () => {}) {
    reCaptchaState.executeCaptcha(args);
  }
}

const captchaStoreValue = new CaptchaStore();
export { CaptchaStore, captchaStoreValue };
export default CaptchaStore;
