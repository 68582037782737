import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";

let cancelReportDataRequest = null;
let cancelChartDataRequest = null;
let cancelSequenceInsightRequest = null;

export const defaultCustomDate = [
  {
    startDate: new Date(),
    endDate: null,
    key: "selection"
  }
];

export const defaultSortObj = { sortParam: "SENT_COUNT", direction: "DESC" };

class ReportStore {
  @observable summaryData = null;
  @observable emailInsightData = null;
  @observable sequenceInsightData = null;
  @observable summaryLoader = true;
  @observable emailInsightLoader = true;
  @observable sequenceInsightLoader = true;
  @observable currentPage = 0;
  @observable sortObj = defaultSortObj;
  @observable customDate = defaultCustomDate;
  @observable filterPayload = "yesterday";

  @action
  setSummaryLoader = value => {
    this.summaryLoader = value;
  };

  @action
  setEmailInsightLoader = value => {
    this.emailInsightLoader = value;
  };

  @action
  setSequenceInsightLoader = value => {
    this.sequenceInsightLoader = value;
  };

  @action
  setCustomDate = value => {
    this.customDate = value;
  };

  @action
  setFilterPayload = value => {
    this.filterPayload = value;
  };

  @action
  setCurrentPage = value => {
    this.currentPage = value;
  };

  @action
  setSortObj = value => {
    this.sortObj = { ...this.sortObj, ...value };
  };

  @action
  getSummaryData = async data => {
    try {
      const config = {
        url: URLS.getTeamReport,
        method: "POST",
        data
      };
      if (cancelReportDataRequest !== null) {
        cancelReportDataRequest("cancel");
        cancelReportDataRequest = null;
      }
      const cancelReqCallback = value => {
        cancelReportDataRequest = value;
      };
      let res = await makeApi(config, cancelReqCallback);
      Utils.mixpanelTrack(MXP_EVENT.REPORT_SUMMARY_USED, {
        filterCriteria: data
      });
      if (res?.data && Object.keys(res.data).length > 0) {
        this.summaryData = res.data;
      } else {
        this.summaryData = !res?.message ? [] : null;
      }
    } catch (err) {
      console.error("Get Report Error", err);
    }
  };

  @action
  getEmailInsightData = async (data = {}) => {
    try {
      const config = {
        url: URLS.reportEmailInsights,
        method: "POST",
        data
      };
      if (cancelChartDataRequest !== null) {
        cancelChartDataRequest("cancel");
        cancelChartDataRequest = null;
      }
      const cancelReqCallback = value => {
        cancelChartDataRequest = value;
      };
      let res = await makeApi(config, cancelReqCallback);
      Utils.mixpanelTrack(MXP_EVENT.REPORT_EMAIL_INSIGHTS_USED, {
        filterCriteria: data
      });
      if (res?.data && Object.keys(res.data).length > 0) {
        this.emailInsightData = res.data;
      } else {
        this.emailInsightData = !res?.message ? [] : null;
      }
    } catch (err) {
      console.error("Get Report Error", err);
    }
  };

  @action
  getSequenceInsightsData = async (data = {}) => {
    try {
      const config = {
        url: URLS.reportSequenceInsights,
        method: "POST",
        data
      };
      if (cancelSequenceInsightRequest !== null) {
        cancelSequenceInsightRequest("cancel");
        cancelSequenceInsightRequest = null;
      }
      const cancelReqCallback = value => {
        cancelSequenceInsightRequest = value;
      };
      let res = await makeApi(config, cancelReqCallback);
      Utils.mixpanelTrack(MXP_EVENT.REPORT_SEQUENCE_INSIGHTS_USED, {
        filterCriteria: data
      });
      if (res?.data && Object.keys(res.data).length > 0) {
        this.sequenceInsightData = res.data;
      } else {
        this.sequenceInsightData = !res?.message ? [] : null;
      }
    } catch (err) {
      console.error("Get Report Error", err);
    }
  };
}

const reportStore = new ReportStore();
export default ReportStore;
export { reportStore };
