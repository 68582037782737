import React from "react";
import { observer } from "mobx-react";
import DropDownMenu from "Components/common/DropDownMenu";
import Utils from "Utils/utils";

const ContactsMoreOptions = observer((props = {}) => {
  const {
    hasTeamMember = false,
    showActionsDropdown = false,
    expandMenu = () => {},
    getSelectedCount = () => {},
    updateAccountOwner = () => {},
    setShowActionsDropdown = () => {}
  } = props || {};

  const ownerOptions = [
    {
      icon: {
        ele: '<i style="font-size: 15px;cursor:pointer" class="material-icons-outlined">person_pin</i>',
        style: { width: "15px", height: "15px" }
      },
      value: "Update Owner",
      key: "updateAccountOwner",
      method: updateAccountOwner
    }
  ];

  const getAccountsDropdownOptions = () => {
    return hasTeamMember ? ownerOptions : [];
  };

  return (
    <div className="moreOptionBtn">
      <i
        className={`material-icons ${getSelectedCount() > 0 ? "enabled" : ""}`}
        onClick={expandMenu}
      >
        more_vert
      </i>
      {showActionsDropdown && getSelectedCount() > 0 && (
        <>
          <DropDownMenu
            options={getAccountsDropdownOptions()}
            referenceId="accountMoreOption"
          />
          <div
            className="moreOptionOverlay"
            onClick={event => {
              Utils.preventDefaultFn(event);
              setShowActionsDropdown(false);
            }}
          />
        </>
      )}
    </div>
  );
});

export { ContactsMoreOptions };
export default ContactsMoreOptions;
