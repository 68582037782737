import { action, observable } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";

let calendarNetworkRequest = {
  eventsList: null,
  freeSlots: null,
  bookMeetingByContactCard: null
};

const setCalendarBookMeetingNetRequest = (id = "") => {
  if (id && calendarNetworkRequest?.[id] !== null) {
    calendarNetworkRequest[id]("cancel");
    calendarNetworkRequest[id] = null;
  }
};

const cancelReqCallback = (id = "", value = "") => {
  id && (calendarNetworkRequest[id] = value);
};

class CalendarBookMeetingState {
  @observable showPopup = false;
  @observable eventsData = null;
  @observable slots = null;
  @observable contact = "";
  @observable currentPage = 1;
  @observable pageType = "";

  @action
  setPageType = (val = "") => {
    this.pageType = val;
  };

  @action
  setEventsData = (obj = {}) => {
    this.eventsData = obj;
  };

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
  };

  @action
  setContact = (obj = {}) => {
    this.contact = obj;
  };

  @action
  setCurrentPage = (value = 1) => {
    this.currentPage = value;
  };

  @action
  setSlots = (val = []) => {
    this.slots = val;
  };

  @action
  resetState = () => {
    this.showPopup = false;
    this.eventsData = null;
    this.contact = "";
    this.currentPage = 1;
    this.pageType = "";
  };

  @action
  getCalendarEventsList = async (
    data = { pageNo: 1, limit: 10 },
    cbk = () => {}
  ) => {
    const config = {
      url: URLS.listActiveCalendarEvent,
      method: "POST",
      data
    };
    setCalendarBookMeetingNetRequest("eventsList");
    const cancel = value => cancelReqCallback("eventsList", value);
    let res = await makeApi(config, cancel);
    if (res?.message !== "cancel") {
      if (
        res?.data &&
        Object.keys(res?.data).length > 0 &&
        res?.data?.code === 200
      ) {
        let list = [
          ...(this.eventsData?.list || []),
          ...(res?.data?.list || [])
        ];
        this.setEventsData({ ...res.data, list });
      } else {
        this.setEventsData({});
      }
      cbk();
    }
  };

  @action
  getCalendarFreeSlots = async (data = {}, cbk = () => {}) => {
    const config = {
      url: URLS.getFreeSlots,
      method: "POST",
      data
    };
    setCalendarBookMeetingNetRequest("freeSlots");
    const cancel = value => cancelReqCallback("freeSlots", value);
    let res = await makeApi(config, cancel);
    if (res?.message !== "cancel") {
      if (res?.data?.slots?.length > 0) {
        this.setSlots(res?.data?.slots);
      } else {
        this.setSlots([]);
      }
      cbk();
    }
  };

  @action
  bookMeeting = async (data = {}, callback = () => {}) => {
    const config = {
      url: URLS.bookMeetingByContactCard,
      method: "POST",
      data
    };
    setCalendarBookMeetingNetRequest("freeSlots");
    const cancel = value => cancelReqCallback("freeSlots", value);
    let res = await makeApi(config, cancel);
    if (res?.message !== "cancel") {
      if (res?.data) {
        toasterState.setToastMsg("Meeting booked successfully.", "success");
        callback(true);
      } else {
        callback(false);
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  };
}

const calendarBookMeetingState = new CalendarBookMeetingState();
export { calendarBookMeetingState };
