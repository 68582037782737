import { observable, action } from "mobx";
import userDetail from "Stores/userDetail";
import { tagsStoreData } from "Stores/TagsData";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { toasterState } from "Components/common/toaster";

let cancelFilterRequest = null;
let cancelTableRequest = null;
let cancelValidateContactRequest = null;
let cancelContactSequence = null;

export const defaultFilterObject = {
  tags: [...(tagsStoreData?.tagsData || [])],
  owners: [...(userDetail?.userInfo?.hasTeamMember || [])],
  contactFields: [
    {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    }
  ],
  metrics: [
    {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    }
  ],
  dates: [
    {
      fieldName: "",
      isSet: false,
      isNotSet: false,
      fromDate: new Date(),
      toDate: null,
      operator: "BETWEEN"
    }
  ]
};

export const defaultFilterPayload = {
  filters: {},
  pageNo: 1,
  limit: 10
};

class SequenceContactData {
  @observable contactData = "";
  @observable contactFilterData = [];
  @observable totalContacts = null;
  @observable filter = null;
  @observable searchContactFilter = null;
  @observable currentPage = 1;
  @observable filteredTags = [];
  @observable updateContactEmail = "";
  @observable breadcrumbValue = "";
  @observable sequenceList = [];
  @observable sequenceTotalPages = 1;
  @observable sequenceCurrentPageNo = 1;
  @observable contactFilterObject = { ...defaultFilterObject };
  @observable contactMetricsObject = [];
  @observable filterPayload = { ...defaultFilterPayload };
  @observable filteredList = [];
  @observable resetTeam = false;
  @observable topFilter = {};
  @observable overallPayload = {};
  @observable isOverviewToContactsPage = false;
  @observable hubspotExportStatus = {};
  @observable sfExportStatus = {};

  @action
  setHubspotExportStatus = (value = {}) => {
    this.hubspotExportStatus = value;
  };

  @action
  setSfExportStatus = (value = {}) => {
    this.sfExportStatus = value;
  };

  @action
  setFilter = (value = "") => {
    this.filter =
      value && !["OPENCOUNT", "CLICKCOUNT"]?.includes(value) ? value : null;
  };

  @action
  setResetTeam = (value = "") => {
    this.resetTeam = value;
  };

  @action
  setSearchContactFilter = (value = "") => {
    this.searchContactFilter = value;
  };

  @action
  setValidateContactData = (value = "") => {
    this.validateContactData = value;
  };

  @action
  setTotalContactValue = (value = 0) => {
    this.totalContacts = value;
  };

  @action
  setCurrentPage = (value = 1) => {
    this.currentPage = value;
  };

  @action
  setUpdateContactEmail = (value = "") => {
    this.updateContactEmail = value;
  };

  @action
  setSequenceList = (value = []) => {
    this.sequenceList = value;
  };

  @action
  setSequenceTotalPages = (value = 0) => {
    this.sequenceTotalPages = value;
  };

  @action
  setSequenceCurrentPageNo = (value = 1) => {
    this.sequenceCurrentPageNo = value;
  };

  @action
  setContactFilterObject = (obj = {}) => {
    this.contactFilterObject = obj;
  };

  @action
  setContactMetricsObject = (obj = {}) => {
    this.contactMetricsObject = obj;
  };

  @action
  setFilterPayload = (obj = {}) => {
    this.filterPayload = obj;
  };

  @action
  setTopFilter = (val = {}) => {
    this.topFilter = val;
  };

  @action
  setOverallPayload = (val = {}) => {
    this.overallPayload = val;
  };

  @action
  updateContactRowData = (contact = {}) => {
    if (this.contactData?.contacts?.length > 0) {
      this.contactData.contacts = this.contactData.contacts.map(item => {
        if (item?.id === contact?.id) {
          item.phone = contact.phone;
        }
        return item;
      });
    }
  };

  @action
  setFilteredTags = (val = []) => {
    this.filteredTags = val;
  };

  @action
  setBreadCrumbValue = (name = "") => {
    this.breadcrumbValue = name;
  };

  @action
  setFilteredList = (list = []) => {
    this.filteredList = list;
  };

  @action
  setIsOverviewToContactsPage = (value = false) => {
    this.isOverviewToContactsPage = value;
  };

  checkAndCancelTableRequest = () => {
    if (cancelTableRequest !== null) {
      cancelTableRequest("cancel");
      cancelTableRequest = null;
    }
  };

  cancelTableCallback = (value = "") => {
    cancelTableRequest = value;
  };

  checkAndCancelFilterRequest = () => {
    if (cancelFilterRequest !== null) {
      cancelFilterRequest("cancel");
      cancelFilterRequest = null;
    }
  };

  cancelFilterCallback = (value = "") => {
    cancelFilterRequest = value;
  };

  checkAndCancelValidateContactRequest = () => {
    if (cancelValidateContactRequest !== null) {
      cancelValidateContactRequest("cancel");
      cancelValidateContactRequest = null;
    }
  };

  cancelValidateContactCallback = (value = "") => {
    cancelValidateContactRequest = value;
  };

  @action
  addSingleContact = async (payload = {}) => {
    try {
      const config = {
        url: URLS.addSingleContact,
        method: "POST",
        data: payload
      };
      let res = await makeApi(config);
      if (res?.data?.responseCode === 200 && res?.data?.contact) {
        return res.data.contact;
      } else if (res?.response?.status === 409) {
        return "duplicate";
      } else {
        return false;
      }
    } catch (error) {
      console.error("Add contact error ", error);
    }
  };

  @action
  getContactBySalesforceId = async (id = "") => {
    try {
      const config = {
        url: URLS.getContactFromSalesforceId.replace("<<salesforceId>>", id)
      };
      let res = await makeApi(config);
      if (res?.data?.contact && Object.keys(res.data.contact)?.length > 0) {
        return res.data.contact;
      }
      return null;
    } catch (error) {
      console.error("get salesforce contact error ", error);
    }
  };

  @action
  validateDailyLimitForContacts = async (data = {}) => {
    try {
      const config = {
        url: URLS.validateDailyLimitForContacts,
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (res?.data) {
        return res.data;
      }
      Utils.showApiFailureResponse(res);
      return false;
    } catch (error) {
      console.error("Validate limit for contact ", error);
    }
  };

  @action
  getContactSequenceData = async (folderName = "all") => {
    const urlPath =
      (folderName === "all" && "all/memberId") ||
      (folderName === "shared" && folderName) ||
      `folder/${folderName}`;
    try {
      const config = {
        url: URLS.getSelectedSeqData.replace(
          "<<seqID>>",
          `${urlPath}?source=WEB`
        ),
        method: "GET"
      };
      if (cancelContactSequence !== null) {
        cancelContactSequence("cancel");
        cancelContactSequence = null;
      }
      const cancelReqCallback = (value = "") => {
        cancelContactSequence = value;
      };
      let res = await makeApi(config, cancelReqCallback);
      if (res?.data) {
        return res.data || [];
      } else Utils.showApiFailureResponse(res);
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  @action
  listSequences = async (data = {}) => {
    try {
      const config = {
        url: URLS.listAllTriggerSequences,
        method: "POST",
        data
      };
      let response = await makeApi(config);
      if (response?.data) {
        const { totalPages = 0, currentPage = 0 } = response?.data || {};
        if (currentPage === 1) {
          this.setSequenceList([
            ...response?.data?.campaignSequenceResponseList
          ]);
        } else if (currentPage <= totalPages) {
          this.setSequenceList([
            ...this.sequenceList,
            ...response?.data?.campaignSequenceResponseList
          ]);
        }
        this.setSequenceTotalPages(response?.data?.totalPages);
      } else {
        Utils.showApiFailureResponse(response);
      }
    } catch (err) {
      console.error("Creating a new step erro", err);
    }
  };

  @action
  filterContactData = async (data = {}, cbk = () => {}) => {
    try {
      const config = {
        url: URLS.getAllContacts,
        method: "POST",
        data
      };
      this.checkAndCancelTableRequest();
      let response = await makeApi(config, this.cancelTableCallback);
      if (response?.data) {
        response.data["limit"] = data?.limit || 0;
        this.contactData = response?.data || {};
        if (response?.data?.totalContacts) {
          this.setTotalContactValue(response.data.totalContacts);
        }
      } else Utils.showApiFailureResponse(response);
      cbk && cbk(false);
    } catch (err) {
      console.error("Getting filter contacts error", err);
    }
  };

  @action
  filterContactAnalyticsData = async (data = {}, cbk = () => {}) => {
    try {
      const config = {
        url: URLS.getContactFilter,
        method: "POST",
        data
      };
      this.checkAndCancelFilterRequest();
      let response = await makeApi(config, this.cancelFilterCallback);
      if (response?.data?.contactAnlaytics) {
        this.contactFilterData = response.data.contactAnlaytics;
        cbk && cbk(false);
      } else {
        Utils.showApiFailureResponse(response);
      }
    } catch (error) {
      console.error("Filter Data error ", error);
    }
  };

  @action
  getContactUploadStatus = async (seqId = "") => {
    if (seqId) {
      const config = {
        url: `${URLS.getContactUploadStatus}/${seqId}`,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response) {
        return response?.data || {};
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
    }
  };
}

const sequenceContactStoreData = new SequenceContactData();
export { sequenceContactStoreData, SequenceContactData };
export default sequenceContactStoreData;
