import React from "react";
import { observable } from "mobx";
import { ReactComponent as DashboardIcon } from "./Icons/dashboard.svg";
import { ReactComponent as DiscoverIcon } from "./Icons/discover.svg";
import { ReactComponent as SequenceIcon } from "./Icons/sequence.svg";
import { ReactComponent as CallsIcon } from "./Icons/calls.svg";
import { ReactComponent as ContactIcon } from "./Icons/contact.svg";
import { ReactComponent as ReportsIcon } from "./Icons/reports.svg";
import { ReactComponent as TaskIcon } from "./Icons/task.svg";
import { ReactComponent as TemplateIcon } from "./Icons/template.svg";
import { ReactComponent as WebTrackingIcon } from "./Icons/webTracking.svg";
import { ReactComponent as AccountsIcon } from "./Icons/accounts.svg";
import { outboxStoreData } from "Pages/Dashboard/outbox/outboxStore";

export const getSideBarList = () =>
  observable([
    {
      path: "/dashboard",
      name: "dashboardNew",
      title: "Dashboard",
      icon: <DashboardIcon />
    },
    {
      path: "/inbox",
      name: "inbox",
      title: "Inbox",
      icon: (
        <div className="inboxIconWrapper">
          <i className="material-icons-outlined inboxIcon">mail</i>
          {outboxStoreData?.unreadOutboxCount > 0 && (
            <span
              className={`count ${
                outboxStoreData?.unreadOutboxCount > 9
                  ? "litteleMoreDigits"
                  : outboxStoreData?.unreadOutboxCount > 99
                  ? "moreDigits"
                  : ""
              }`}
            >
              {outboxStoreData?.unreadOutboxCount > 99
                ? "99+"
                : outboxStoreData?.unreadOutboxCount}
            </span>
          )}
        </div>
      )
    },
    {
      path: "/sequences",
      name: "sequences",
      title: "Sequences",
      icon: <SequenceIcon />
    },
    {
      path: "/templates",
      name: "templates",
      title: "Templates",
      icon: <TemplateIcon />
    },
    {
      path: "/contacts",
      name: "contacts",
      title: "Contacts",
      icon: <ContactIcon />
    },
    {
      path: "/accounts",
      name: "accounts",
      title: "Accounts",
      icon: <AccountsIcon />
    },
    {
      path: "/tasks",
      name: "tasks",
      title: "Tasks",
      icon: <TaskIcon />
    },
    {
      path: "/calls",
      name: "calls",
      title: "Calls",
      icon: <CallsIcon />
    },
    {
      path: "/discover",
      name: "discover",
      title: "Discover",
      icon: <DiscoverIcon />
    },
    {
      path: "/website-tracking",
      name: "tracking",
      title: "Web Tracking",
      icon: <WebTrackingIcon />
    },
    {
      path: "/reports",
      name: "reports",
      title: "Reports",
      icon: <ReportsIcon />
    }
  ]);
