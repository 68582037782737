import React from "react";
import { observer } from "mobx-react";
import { bulkAddTagsPopupState } from "./BulkOperations/BulkAddTagsPopup";

const SelectedTagList = observer(props => {
  const {
    selectedList = [],
    store = {},
    searchBoxRef = null,
    updateRemovedTag = () => {}
  } = props;

  const onCloseIconClick = item => {
    if (!bulkAddTagsPopupState?.loader) {
      const updatedFilteredData = store.filteredData.filter(
        filterData => filterData !== item
      );
      store.setFilteredData(updatedFilteredData);
      searchBoxRef && searchBoxRef.current && searchBoxRef.current.focus();
      updateRemovedTag(item);
    }
  };

  return (
    <div className="selectedTagWrapper">
      {selectedList?.length > 0 &&
        selectedList?.map(item => {
          return (
            <div className="tagItem" key={item}>
              <span className="selectedTag">{item}</span>
              <i
                className="material-icons selectedTagCloseIcon"
                onClick={() => onCloseIconClick(item)}
              >
                close
              </i>
            </div>
          );
        })}
    </div>
  );
});
export default SelectedTagList;
export { SelectedTagList };
