import React from "react";
import ButtonComponent from "Components/common/Button";
import InboxFailImg from "Assets/png/InboxFailure.png";
import BgDottedImg from "Assets/png/bgDotted.png";

const ConnectInboxFailurePopup = props => {
  const { closeCbk, meetingCbk } = props;

  return (
    <>
      <div className="overlayMaskInbox"></div>
      <div className="connectInboxFailureSection">
        <div className="pop-card animated customzoomIn">
          <i className="material-icons" onClick={e => closeCbk(false)}>
            close
          </i>
          <div className="contentSpaceAlign">
            <h6>Sorry, we couldn't verify your email account</h6>
            <p>
              Would you like to schedule a meeting with our
              <br /> product specialist to assist you here?
            </p>
            <div className="btnSection">
              <ButtonComponent
                id="noThanks"
                name="noThanks"
                btnText="No, thanks"
                icon={false}
                iconName=""
                type="button"
                className="no-thanks"
                loader={false}
                click={e => closeCbk(false)}
              />
              <ButtonComponent
                id="talkToProductSpecialist"
                name="talkToProductSpecialist"
                btnText="Talk to Product Specialist"
                icon={false}
                iconName=""
                type="button"
                className="proceedBtn"
                loader={false}
                click={e => meetingCbk()}
              />
            </div>
          </div>
          <div className="imgLapping">
            <img src={InboxFailImg} alt="" className="failureImg" />
            <img src={BgDottedImg} alt="" className="bgDotImg" />
          </div>
        </div>
      </div>
    </>
  );
};

export { ConnectInboxFailurePopup };
export default ConnectInboxFailurePopup;
