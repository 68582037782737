import React from "react";
import ContactInputTag from "./ContactInputTag";
import Utils from "Utils/utils";

const PersonalInformation = props => {
  const {
    inputVal,
    changeInputVal,
    isEditContact,
    tokenList,
    errMsg = "",
    isContactPage = false
  } = props || {};

  return (
    <div className="info">
      <h5 className="sectionTitle">Personal Information</h5>
      <div className="sectionInputTagsCont">
        <div className="rowBreaker">
          <ContactInputTag
            name="firstName"
            id="firstName"
            value={inputVal.firstName}
            text="First name"
            onBlurEvent={changeInputVal}
            required={
              isContactPage
                ? false
                : Utils.findIsRequiredFieldForSingleContactSequence(
                    "firstName",
                    tokenList
                  )
            }
          />
          <ContactInputTag
            name="lastName"
            id="lastName"
            value={inputVal.lastName}
            text="Last name"
            onBlurEvent={changeInputVal}
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "lastName",
              tokenList
            )}
          />
          <ContactInputTag
            name="email"
            value={inputVal.email}
            type="email"
            id="email"
            text="Email address"
            onBlurEvent={changeInputVal}
            required={true}
            readOnly={isEditContact ? true : false}
            errMsg={errMsg}
          />
          <ContactInputTag
            name="jobTitle"
            id="jobTitle"
            value={inputVal.jobTitle}
            text="Job title"
            onBlurEvent={changeInputVal}
            required={
              isContactPage
                ? false
                : Utils.findIsRequiredFieldForSingleContactSequence(
                    "jobTitle",
                    tokenList
                  )
            }
          />
        </div>
        <div className="rowBreaker">
          <ContactInputTag
            name="phone"
            id="phone"
            onBlurEvent={changeInputVal}
            value={
              inputVal.phone && inputVal.phone.length ? inputVal.phone : ""
            }
            text="Phone number"
            type="tel"
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "phonenumber",
              tokenList
            )}
          />
          <ContactInputTag
            name="mobilePhone"
            id="mobilePhone"
            onBlurEvent={changeInputVal}
            value={
              inputVal.mobilePhone && inputVal.mobilePhone.length
                ? inputVal.mobilePhone
                : ""
            }
            text="Mobile number"
            type="tel"
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "mobilenumber",
              tokenList
            )}
          />
          <ContactInputTag
            name="otherPhone"
            id="otherPhone"
            onBlurEvent={changeInputVal}
            value={
              inputVal.otherPhone && inputVal.otherPhone.length
                ? inputVal.otherPhone
                : ""
            }
            text="Other number"
            type="tel"
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "otherPhone",
              tokenList
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
export { PersonalInformation };
