const domainHealthDetailedInfoObj = {
  authenticationStatus: {
    header: "Authentication Status",
    body: [
      {
        title: "SPF",
        name: "spf",
        abbreviatedForm: "Sender Policy Framework",
        isTitleBold: true,
        isVerifyBtn: true,
        accordionIndex: 0
      },
      {
        title: "DKIM",
        name: "dkim",
        abbreviatedForm: "DomainKeys Identified Mail",
        isTitleBold: true,
        isVerifyBtn: true,
        accordionIndex: 1
      },
      {
        title: "DMARC",
        name: "dmarc",
        abbreviatedForm: "Domain-based Message Authentication",
        isTitleBold: true,
        isVerifyBtn: true,
        accordionIndex: 2
      }
    ]
  },
  profileUpdate: {
    header: "Profile Update",
    body: [
      {
        title: "Profile Name",
        name: "profileName",
        content: [],
        isTitleBold: true,
        hasArrowIcon: false,
        accordionIndex: 3,
        cursor: false
      },
      {
        title: "Profile Picture",
        name: "profilePicture",
        content: [],
        isTitleBold: true,
        hasArrowIcon: false,
        accordionIndex: 4,
        cursor: false
      }
    ]
  },
  deliverability: {
    header: "Deliverability",
    body: [
      {
        title: "MX Exchange",
        name: "mxExchange",
        content: [],
        isTitleBold: true,
        accordionIndex: 5
      },
      // {
      //   title: "Email Test",
      //   content: [],
      //   isTitleBold: true,
      //   isComingSoon: true,
      //   accordionIndex: 6,
      //   cursor: false
      // },
      {
        title: "Spam Listing",
        content: [],
        isTitleBold: true,
        isComingSoon: true,
        accordionIndex: 7,
        cursor: false
      }
    ]
  }
};

const spfContentObj = {
  content: [
    "Sign in to your domain registrar.",
    "Access DNS management.",
    "Add a TXT record with “@” or “your domain” as the host.",
    "Use this value: “v=spf1 include:spf.yourhost.com ~all” (replace “yourhost.com” with your actual host).",
    "Save the changes."
  ],
  registeredContent: [{ steps: "Status :", content: "SPF Record Found" }]
};

const dmarcContentObj = {
  content: [
    "Sign in to your email service provider or domain registrar,",
    "Access the DNS management page.",
    "Generate a DKIM key pair.",
    "Add a TXT record with a host name in the format of “selector._domainkey.yourdomain.com” (replace “selector” and “yourdomain.com” with your specific values).",
    "Use the generated DKIM key as the value.",
    "Save the changes."
  ],
  registeredContent: [
    { steps: "Status :", content: "DMARC Configured" },
    { steps: "Policy :", content: "p=none" }
  ]
};

const dkimContentObj = {
  content: [
    "Sign in to your email service provider or domain registrar.",
    "Access the DNS management page.",
    "Generate a DKIM key pair.",
    "Add a TXT record with a host name in the format of “selector._domainkey.yourdomain.com” (replace “selector” and “yourdomain.com” with your specific values).",
    "Use the generated DKIM key as the value.",
    "Save the changes."
  ],
  registeredContent: [
    { steps: "Status :", content: "DKIM Configured" },
    { steps: "Selector :", content: "default" }
  ]
};

const mxContentObj = {
  content: [
    "Sign in to your email service provider or domain registrar.",
    "Access the DNS management page.",
    "Look for the MX (Mail Exchange) record settings.",
    "Add a new MX record or modify existing records if necessary.",
    "Enter the required details for the MX record, such as priority and destination.",
    "Save the changes to apply the updated MX record."
  ],
  registeredContent: [
    { steps: "Status :", content: "MX Record Setup Completed" }
  ]
};

export const GMAIL_INBOX_DHC_OBJ = {
  SPF: [
    {
      step: "Step 1",
      content: "Add your purchased domain to Google Workspace and verify it."
    },
    {
      step: "Step 2",
      content: `Once the domain is verified, you have to activate Gmail. Go to <a href='https://admin.google.com/u/2/ac/domains/manage' target='_blank' rel='noopener noreferrer'>https://admin.google.com/u/2/ac/domains/manage</a> to activate Gmail.`
    },
    {
      step: "Step 3",
      content: `Click on the domain for which you want to activate Gmail. Click on "Activate Gmail".`
    },
    {
      step: "Step 4",
      content: `The “Activate Gmail” popup will appear where you have to choose a method to activate Gmail.`
    },
    {
      step: "Step 5",
      content: `Select the “Set up MX record” option and click “Next”. The activation will take a few minutes.`
    },
    {
      step: "Step 6",
      content: `Once you verify and activate Gmail, Google will automatically create MX records and SPF in the DNS settings of your domain provider.`
    },
    {
      step: "Step 7",
      content: `Check your SPF records, there should be only one with the following details -`,
      list: [
        {
          key: "Type",
          value: "TXT"
        },
        {
          key: "Host",
          value: "@"
        },
        {
          key: "Value",
          value: "v=spf1 include:_spf.google.com ~all"
        }
      ],
      text: "If you find any additional SPF records, delete them.Ensure there is only one with specific details."
    }
  ],
  DKIM: [
    {
      step: "Step 1",
      content: `Go to <a href="https://admin.google.com/u/2/ac/apps/gmail/authenticateemail" target='_blank' rel='noopener noreferrer'>this page</a> in your Google Admin account.`
    },
    {
      step: "Step 2",
      content: `Select the domain for which you want to set up DKIM and click on “Generate new record”.`
    },
    {
      step: "Step 3",
      content: `Now, go to your domain hosting service and create a new DNS record as follows-`,
      list: [
        {
          key: "Type",
          value: "TXT"
        },
        {
          key: "Name",
          value: "This is the ‘DNS Host name’ from Google Workspace"
        },
        {
          key: "Value",
          value: "This is the ‘TXT record value"
        }
      ]
    },
    {
      step: "Step 4",
      content: `Once the DKIM record is created, come back to <a href="https://admin.google.com/u/2/ac/apps/gmail/authenticateemail" target='_blank' rel='noopener noreferrer'>this page in Google workspace</a> and click ‘Start authentication’.`
    }
  ],
  DMARC: [
    {
      step: "Step 1",
      content: `Create a DMARC record using a DMARC record generator like <a href="https://easydmarc.com/tools/dmarc-record-generator" target='_blank' rel='noopener noreferrer'>EasyDMARC</a>.`
    },
    {
      step: "Step 2",
      content: `Now add a new record in the DNS settings of your domain provider -`,
      list: [
        {
          key: "Type",
          value: "TXT"
        },
        {
          key: "Name",
          value: "_dmarc"
        },
        {
          key: "Value",
          value:
            "Copy the DMARC value generated from EasyDmarc tool and paste it here."
        }
      ]
    }
  ],
  MXEXCHANGE: {
    title: "MX records",
    text: "Google will automatically create MX records in your domain provider’s DNS settings if the domain is verified."
  }
};

export const OUTLOOK_INBOX_DHC_OBJ = {
  SPF: [
    {
      step: "Step 1",
      content: "Add your purchased domain to Microsoft and verify it."
    },
    {
      step: "Step 2",
      content: `Once your domain is verified, Microsoft will automatically create MX records, SPF, and CNAME DNS records in your domain provider.`
    },
    {
      step: "Step 3",
      content:
        "Check your domain provider and locate these records in your domain’s DNS settings. Your SPF record should be as follows -",
      list: [
        {
          key: "Type",
          value: "TXT"
        },
        {
          key: "Host",
          value: "@"
        },
        {
          key: "Value",
          value: "v=spf1 include:spf.protection.outlook.com ~all"
        }
      ],
      text: `If you find any additional SPF records, delete them.`
    }
  ],
  DKIM: [
    {
      step: "Step 1",
      content:
        "Go to Microsoft admin → Security → Policies & rules → Threat policies."
    },
    {
      step: "Step 2",
      content:
        "On the Threat policies page, select ‘Email authentication settings."
    },
    {
      step: "Step 3",
      content: "Select ‘DKIM’ tab."
    },
    {
      step: "Step 4",
      content: `Double click on the domain for which you want to generate DKIM.`
    },
    {
      step: "Step 5",
      content: `Slide the toggle to Enable. You will see a pop-up window, click on the "Create DKIM keys" button.`
    },
    {
      step: "Step 6",
      content: `You will get the “Publish CNAMEs” pop up with the newly generated DKIM records.`
    },
    {
      step: "Step 7",
      content: `Go to your domain provider and create a DNS record with these values. This completes the DKIM setup.`
    }
  ],
  DMARC: [
    {
      step: "Step 1",
      content: `Create a DMARC record using a DMARC record generator like <a href="https://easydmarc.com/tools/dmarc-record-generator" target='_blank' rel='noopener noreferrer'>EasyDMARC</a>.`
    },
    {
      step: "Step 2",
      content: `Now add a new record in the DNS settings of your domain provider -`,
      list: [
        {
          key: "Type",
          value: "TXT"
        },
        {
          key: "Name",
          value: "_dmarc"
        },
        {
          key: "Value",
          value:
            "Copy the DMARC value generated from EasyDmarc tool and paste it here."
        }
      ]
    }
  ],
  MXEXCHANGE: {
    title: "MX records",
    text: "Microsoft will automatically create MX records in your domain provider’s DNS settings if the domain is verified."
  }
};

export default domainHealthDetailedInfoObj;
export {
  domainHealthDetailedInfoObj,
  spfContentObj,
  dmarcContentObj,
  dkimContentObj,
  mxContentObj
};
