import React, { memo, useEffect, useRef, useState } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import Utils from "Utils/utils";
import emailPreferenceStore from "../EmailPreferences/Store";
import userDetail from "Stores/userDetail";
import SearchByContactKeyword from "Pages/Dashboard/contacts/SearchByContactKeyword";

class AddEmailPreferencesState {
  @observable showPopup = false;
  @observable type = "";
  @observable btnLoader = false;
  @observable callback = () => {};

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
  };

  @action
  setBtnLoader = (value = false) => {
    this.btnLoader = value;
  };

  @action
  setType = (value = "") => {
    this.type = value;
  };

  @action
  setCallback = (func = () => {}) => {
    this.callback = func;
  };

  @action
  resetState = () => {
    this.showPopup = false;
    this.type = "";
    this.btnLoader = false;
    this.callback = () => {};
  };
}

const addEmailPreferencesState = new AddEmailPreferencesState();

const AddEmailPreferencesPopup = observer((props = {}) => {
  const { email = "", hasTeamMember = false } = userDetail?.userInfo || {};
  const {
    enabled = false,
    group = "EMAIL",
    notificationInterval = {},
    type = "",
    id = "",
    toAddress = [],
    reloadPage = () => {}
  } = props || {};

  const [optList, setOptList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(toAddress || []);
  const [showDropdown, setShowDropdown] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isEmailClose, setIsEmailClose] = useState(false);

  const searchInputRef = useRef(null);

  const closePopup = () => {
    if (!addEmailPreferencesState?.btnLoader) {
      addEmailPreferencesState.resetState();
    }
  };

  const getEditEmailPayload = () => {
    return {
      enabled,
      notificationInterval,
      group,
      type,
      toAddress: selectedContact?.map(person => ({
        name: person?.name || "",
        email: person?.email || ""
      })),
      id
    };
  };

  const handleUpdateAction = async () => {
    if (selectedContact?.length > 0) {
      addEmailPreferencesState.setBtnLoader(true);
      let payload = getEditEmailPayload();
      await emailPreferenceStore.editNotification(payload);
      reloadPage(false);
    }
  };

  const handleSearchText = (event = "") => {
    Utils.preventDefaultFn(event);
    const currentTargetVal = event?.currentTarget?.value || "";
    setKeyword(currentTargetVal);
    setIsEmailClose(!currentTargetVal?.length < 1);
    if (currentTargetVal) {
      let tempOpt = hasTeamMember ? [...hasTeamMember] : [];
      tempOpt = tempOpt.filter(item => {
        const { email = "" } = item || {};
        const notSelectedContact = !selectedContact?.some(
          selectedItem => selectedItem?.email === item?.email
        );
        if (
          email?.toLowerCase()?.includes(currentTargetVal?.toLowerCase()) &&
          notSelectedContact
        ) {
          return item;
        }
      });
      setOptList(tempOpt);
    } else {
      setOptList(hasTeamMember || []);
      setKeyword(currentTargetVal);
    }
    setShowDropdown(true);
  };

  const handleSelectContact = (item = {}) => {
    setSelectedContact([...selectedContact, item]);
    setShowDropdown(false);
    setKeyword("");
    setIsEmailClose(false);
  };

  const isValidEmail = (email = "") => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const addNewContact = () => {
    const isValid = isValidEmail(keyword);
    if (isValid) {
      const isDuplicate = selectedContact.some(
        selectedItem => selectedItem.email === keyword
      );
      if (!isDuplicate) {
        const newContact = { email: keyword, name: "" };
        setSelectedContact([...selectedContact, newContact]);
        setKeyword("");
        setInvalidEmail(false);
      } else {
        setInvalidEmail(true);
      }
    } else {
      setInvalidEmail(true);
    }
    setIsEmailClose(false);
  };

  const removeContact = (emailToRemove = "") => {
    const updatedSelectedContact = [...selectedContact]?.filter(
      item => item?.email !== emailToRemove
    );
    setSelectedContact(updatedSelectedContact);
  };

  const closeAction = () => {
    setShowDropdown(false);
    setKeyword("");
    setInvalidEmail(false);
    setIsEmailClose(false);
  };

  const clearSearch = () => {
    searchInputRef.current.focus();
    setIsEmailClose(false);
    setKeyword("");
  };

  const searchBykeywordObj = {
    isEmailClose: isEmailClose,
    placeHolder: "Add Contact",
    searchKeyword: keyword,
    inputRef: searchInputRef,
    handleSearchAction: handleSearchText,
    clearSearch: clearSearch
  };

  const buttonList = [
    {
      id: "contactCancelBtn",
      name: "contactCancelBtn",
      btnText: "Cancel",
      type: "button",
      className: `contactCancelBtn`,
      click: closePopup
    },
    {
      id: "saveContactBtn",
      name: "saveContactBtn",
      btnText: "Save",
      type: "button",
      className: `saveContactBtn ${
        addEmailPreferencesState?.btnLoader ? "loading" : ""
      }`,
      click: handleUpdateAction,
      loader: addEmailPreferencesState?.btnLoader,
      disabled: selectedContact?.length === 0 || false
    }
  ];

  useEffect(() => {
    return () => {
      setKeyword("");
    };
  }, []);

  return (
    (addEmailPreferencesState?.showPopup && (
      <div className="addEmailPreferencesPopup">
        <div
          className={`card animated customzoomIn ${
            false ? "disableClick" : ""
          }`}
        >
          <div className="cardHeader">
            <div className="textWrapper">
              <div className="title">Add Recipient</div>
              <p className="desc">
                The {type === "TEAM_USAGE_EMAIL" ? "Team" : "User"} Activity
                Summary email will be sent to the following email addresses.
              </p>
            </div>
            <i className="material-icons close" onClick={closePopup}>
              close
            </i>
          </div>
          <div className="cardBody">
            {selectedContact?.length < 5 && (
              <SearchByContactKeyword {...searchBykeywordObj} />
            )}
            <div className="allContactOpList">
              {keyword?.trim() && showDropdown && (
                <DropListComp
                  optList={optList}
                  selectedContact={selectedContact}
                  keyword={keyword}
                  handleSelectContact={handleSelectContact}
                  invalidEmail={invalidEmail}
                  addNewContact={addNewContact}
                  closeAction={closeAction}
                />
              )}
              {selectedContact?.map(item => (
                <div className="addedContactList" key={item?.email}>
                  <div className="contactOpName">{item?.email}</div>
                  {item?.email !== email && (
                    <i
                      className="material-icons close"
                      onClick={() => removeContact(item?.email)}
                    >
                      close
                    </i>
                  )}
                </div>
              ))}
            </div>
            <div className="btnFooter">
              {buttonList?.map((item, index) => (
                <Button key={index} {...item} />
              ))}
            </div>
          </div>
        </div>
        <div className="overlay" />
      </div>
    )) || <></>
  );
});

const TeamMemberLoader = memo(() => {
  return Array.from({ length: 10 }, (value, index) => (
    <div className="addedContactList" key={index}>
      <div className="contactOpName linear-background" />
      <i className="close linear-background" />
    </div>
  ));
});

const DropListComp = ({
  optList = [],
  selectedContact = [],
  keyword = "",
  handleSelectContact = () => {},
  invalidEmail = false,
  addNewContact = () => {},
  closeAction = () => {}
}) => {
  const filteredOptList = optList?.filter(
    item =>
      !selectedContact?.some(
        selectedItem => selectedItem?.email === item?.email
      ) && item?.email.toLowerCase().includes(keyword.toLowerCase())
  );

  return (
    <div className="contactCompleteDropdown">
      <div className="drop-down-menu animatedFast animatedDropDown">
        <ul className="dropdownList">
          {filteredOptList?.map(item => (
            <li key={item?.email} onClick={() => handleSelectContact(item)}>
              {item?.email}
            </li>
          ))}

          {!optList.some(item =>
            item?.email.toLowerCase().includes(keyword.toLowerCase())
          ) && (
            <>
              {invalidEmail ? (
                <span className="invalidEmail">Invalid email address</span>
              ) : (
                !invalidEmail && (
                  <li onClick={addNewContact}>Add "{keyword}"</li>
                )
              )}
            </>
          )}
        </ul>
      </div>
      <div className="dropdownMask" onClick={closeAction} />
    </div>
  );
};

export default AddEmailPreferencesPopup;
export { addEmailPreferencesState };
