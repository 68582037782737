/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React from "react";
import { observer } from "mobx-react";

const PreviewTags = props => {
  const { selectedTags, removeTag } = props;
  return (
    <div className="previewTagsCont">
      {selectedTags &&
        selectedTags.map((obj, index) => {
          const { value, id } = obj;
          return (
            <div key={`selectedTag-${index}`} className="selectedTag">
              <span className="selecteTagValue">{value}</span>
              <i className="material-icons" onClick={e => removeTag(id)}>
                close
              </i>
            </div>
          );
        })}
    </div>
  );
};

export { PreviewTags };
export default observer(PreviewTags);
