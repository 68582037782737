import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { QuilEditor } from "Components/common/quillEditor";
import Utils from "Utils/utils";
import AccountEditNotesEditorActions from "./AccountEditNotesEditorActions";
import { AccountsNotesContext, editAccountNotesState } from "./Main";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";

const AccountNotesEditor = () => {
  const {
    accountId = "",
    state = {},
    setState = () => {},
    getAccountNotes = () => {}
  } = useContext(AccountsNotesContext);
  const bodyOnchangeEvnt = (content = "") => {
    setState({ ...state, text: content });
  };

  const updateAccountItemFn = (id = "") => {
    return state?.accountNotesData?.forEach((item, index) => {
      if (item?.id === id) {
        state.accountNotesData[index].text = state?.text || "";
        state.accountNotesData[index].title = state?.inputVal?.title || "";
      }
    });
  };

  const buildApiConfig = () => {
    const commonData = {
      title: state?.inputVal?.title,
      text: state?.text
    };

    if (state?.editNotesItem?.id && editAccountNotesState?.isEdit) {
      return {
        url: URLS.updateAccountNotes,
        method: "POST",
        data: {
          id: state?.editNotesItem?.id,
          ...commonData
        }
      };
    } else {
      return {
        url: URLS.addAccountNotes,
        method: "POST",
        data: {
          referenceId: accountId,
          type: "ACCOUNT",
          ...commonData
        }
      };
    }
  };

  const handleSuccessfulResponse = (response = "") => {
    const isEditMode =
      (state?.editNotesItem?.id && editAccountNotesState?.isEdit) || false;

    const updatedAccountNotesData = isEditMode
      ? state?.accountNotesData
      : [response?.data?.notes, ...state?.accountNotesData];

    const updatedPageNo =
      updatedAccountNotesData?.length < 15
        ? state?.notesCurrentPageNo + 1
        : state?.notesCurrentPageNo;

    const pageValidateCheck = state?.notesTotalPage > state?.notesCurrentPageNo;

    const trimmedAccountNotesData =
      !isEditMode && pageValidateCheck && state?.notesCurrentPageNo === 1
        ? updatedAccountNotesData.slice(0, -1)
        : updatedAccountNotesData;

    const updateTotalPage =
      state?.notesCurrentPageNo === 1 &&
      state?.notesTotalPage * 15 > updatedAccountNotesData?.length;

    !isEditMode &&
      updateTotalPage &&
      getAccountNotes(state?.notesCurrentPageNo, false);

    updateAccountItemFn(isEditMode ? state?.editNotesItem?.id : "");
    setState({
      ...state,
      editNotesItem: response?.data?.notes || {},
      accountNotesData: trimmedAccountNotesData,
      notesCurrentPageNo: updatedPageNo,
      buttonLoader: false
    });
    editAccountNotesState.setIsEdit(false);
    editAccountNotesState.setNotesNewUser(true);
  };

  const handleErrorResponse = () => {
    editAccountNotesState.setShowEdit(false);
    editAccountNotesState.setIsAddBtn(false);
    setState({ ...state, buttonLoader: false });
    editAccountNotesState.setIsEdit(false);
  };

  const submitAction = async ev => {
    Utils.preventDefaultFn(ev);
    setState({ ...state, buttonLoader: true });
    const config = buildApiConfig();
    const response = await makeApi(config);
    if (+response?.status === 200 || +response?.data?.responseCode === 200) {
      toasterState.setToastMsg(
        `Account Notes has been ${
          editAccountNotesState?.isEdit ? "Updated" : "Added"
        } successfully`,
        "success"
      );

      editAccountNotesState.setShowEdit(false);
      editAccountNotesState.setIsAddBtn(false);

      handleSuccessfulResponse(response);
    } else {
      handleErrorResponse();
    }
  };

  useEffect(() => {
    setState({
      ...state,
      inputVal: editAccountNotesState.isEdit
        ? {
            ...state?.editNotesItem
          }
        : { inputVal: {} }
    });
  }, [state?.editNotesItem]);

  return (
    <div className="quil-editor" id="accountNotesEditor">
      <AccountEditNotesEditorActions
        state={state}
        setState={setState}
        save={submitAction}
      />
      <QuilEditor
        prefilledValue={state?.inputVal?.text || ""}
        bodyOnChange={bodyOnchangeEvnt}
        placeholder="Type here..."
      />
    </div>
  );
};

export default observer(AccountNotesEditor);
