import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import { CAPTCHA_TOKEN } from "Static/constant";

const recaptchaRef = React.createRef();

let propsMethod = "";
class ReCaptcha extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = { propsMethod: '' };
  }

  onChange(value) {
    if (propsMethod) {
      propsMethod(value);
      propsMethod = "";
    }
  }

  executeCaptcha(args) {
    if (args && recaptchaRef && recaptchaRef.current) {
      propsMethod = args;
      recaptchaRef.current.execute();
    }
  }

  render() {
    return <ReCAPTCHA
      ref={recaptchaRef}
      size="invisible"
      badge="bottomleft"
      sitekey={CAPTCHA_TOKEN}
      onChange={this.onChange}
    />
  }
}

const reCaptchaState = new ReCaptcha();

export default ReCaptcha;
export { ReCaptcha, reCaptchaState };