import React, { useState, useContext, useEffect } from "react";
import { SendingWindowContext } from "./SendingWindow";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import TimeRangeDropdown from "./TimeRangeDropdown";
import { observer } from "mobx-react";

const AmPmPanel = props => {
  const { defaultValue = "AM", cbk = () => {} } = props;
  const [toggle, setToggle] = useState(defaultValue?.toLowerCase() === "am");

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    cbk(toggle ? "AM" : "PM");
  }, [toggle]);

  return (
    <div className="amPmPanel">
      <span className="toggleHeader" onClick={() => handleToggle()}>
        <span className="title">{toggle ? "AM" : "PM"}</span>
      </span>
    </div>
  );
};

const NumberTypeContainer = props => {
  const {
    handleIncrement = () => {},
    handleDecrement = () => {},
    time = "from"
  } = props;

  const expandList = [
    {
      iconName: "expand_less",
      iconClassName: "expandLessIcon",
      className: "lessButton",
      method: handleIncrement
    },
    {
      iconName: "expand_more",
      iconClassName: "expandMoreIcon",
      className: "moreButton",
      method: handleDecrement
    }
  ];

  return (
    <div className="quantity">
      {expandList?.map((item, index) => {
        const {
          iconName = "",
          iconClassName = "",
          className = "",
          method = () => {}
        } = item || {};
        return (
          <button
            key={index + iconClassName}
            className={className}
            onClick={() => method(time)}
          >
            <i className={`material-icons ${iconClassName}`}>{iconName}</i>
          </button>
        );
      })}
    </div>
  );
};

const TimeRangePanel = observer(props => {
  const {
    addTime = [],
    setAddTime = () => {},
    finalScheduledWindow = {},
    setFinalScheduledWindow = {},
    closeAction = () => {},
    scheduleSendingWindowCbk = () => {},
    index = 0
  } = useContext(SendingWindowContext);

  const { defaultFromTime, defaultToTime, timeRangeIndex = 0 } = props;
  const [fromTime, setFromTime] = useState(defaultFromTime);
  const [toTime, setToTime] = useState(defaultToTime);
  const [showFromDropdown, setShowFromDropdown] = useState(false);
  const [showToDropdown, setShowToDropdown] = useState(false);

  const handleTimeChange = (event, time, units) => {
    let updatedValue = parseInt(event?.currentTarget?.value);
    const maxValue = units === "hours" ? 12 : 59;
    const minValue = units === 0;
    updatedValue =
      updatedValue > maxValue
        ? maxValue
        : updatedValue < minValue
        ? minValue
        : event?.currentTarget?.value;

    time === "from"
      ? units === "hours"
        ? setFromTime({ ...fromTime, hours: updatedValue })
        : setFromTime({ ...fromTime, minutes: updatedValue })
      : units === "hours"
      ? setToTime({ ...toTime, hours: updatedValue })
      : setToTime({ ...toTime, minutes: updatedValue });
  };

  const fromTimeCbk = value => {
    setFromTime({ ...fromTime, metrics: value });
  };

  const toTimeCbk = value => {
    setToTime({ ...toTime, metrics: value });
  };

  const handleCloseAction = (event, index, timeRangeIndex) => {
    Utils.preventDefaultFn(event);
    closeAction(event, index, timeRangeIndex);
  };

  const handleSubmitAction = (event, index, timeRangeIndex) => {
    Utils.preventDefaultFn(event);
    const newArr = [...addTime];
    const finalFromTime = `${fromTime.hours}:${fromTime?.minutes} ${fromTime.metrics}`;
    const finalToTime = `${toTime.hours}:${toTime?.minutes} ${toTime.metrics}`;
    const { hours: updatedFromTime, hoursInNumber: updatedFromTimeInNumber } =
      Utils.getFormattedTime(finalFromTime);
    const { hours: updatedToTime, hoursInNumber: updatedToTimeInNumber } =
      Utils.getFormattedTime(finalToTime);
    const updatedValue = `${finalFromTime} - ${finalToTime}`;
    if (+updatedFromTimeInNumber < +updatedToTimeInNumber) {
      const temp = Utils.createObjWithoutRef(finalScheduledWindow);
      if (temp[index + 1]) {
        temp[index + 1][timeRangeIndex] = {
          fromTime: updatedFromTime,
          toTime: updatedToTime
        };
      } else {
        temp[index + 1] = [
          {
            fromTime: updatedFromTime,
            toTime: updatedToTime
          }
        ];
      }
      const validateArr = Utils.createObjWithoutRef(temp[index + 1]);
      if (!isTimeOverlaps(validateArr)) {
        setFinalScheduledWindow({
          ...temp
        });
        scheduleSendingWindowCbk({
          ...temp
        });
        newArr[index]["timeRange"][timeRangeIndex] = { range: updatedValue };
        setAddTime(newArr);
        closeAction(event, index, timeRangeIndex);
      } else {
        toasterState.setToastMsg(
          "Times overlap with another set of time slot",
          "fail"
        );
      }
    } else {
      toasterState.setToastMsg(
        "Oops! You cannot set working hours past 12 AM for a selected day.",
        "fail"
      );
    }
  };

  const isTimeOverlaps = timeArr => {
    for (let i = 0; i < timeArr.length - 1; i++) {
      for (let j = i + 1; j < timeArr.length; j++) {
        if (Utils.isTimeOverlaps(timeArr[i], timeArr[j])) {
          return true;
        }
      }
    }
  };

  return (
    <div className="timeRangeWrapper">
      <div className="timeRangeCont">
        <div className="fromPanel">
          <div className="timeWrapper">
            <div className="timeIconCont">
              <i className="timeIcon material-icons">access_time</i>
            </div>
            <section className="timeContWrapper">
              <div className="timeCont">
                <input
                  className="timeRange"
                  type="number"
                  min="1"
                  max="12"
                  value={fromTime.hours}
                  onChange={e => handleTimeChange(e, "from", "hours")}
                />
                {":"}
                <input
                  className="timeRange"
                  type="number"
                  min="0"
                  max="59"
                  value={fromTime.minutes}
                  onChange={e => handleTimeChange(e, "from", "minutes")}
                />
                <i
                  className="material-icons dropdownFilterIcon"
                  onClick={() => setShowFromDropdown(!showFromDropdown)}
                >
                  {showFromDropdown
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"}
                </i>
              </div>
              {showFromDropdown && (
                <TimeRangeDropdown
                  time={fromTime}
                  setTime={setFromTime}
                  setShowDropdown={setShowFromDropdown}
                />
              )}
            </section>
          </div>
          <AmPmPanel defaultValue={fromTime?.metrics} cbk={fromTimeCbk} />
        </div>
        <span className="textSeperator">To</span>
        <div className="toPanel">
          <div className="timeWrapper">
            <div className="timeIconCont">
              <i className="timeIcon material-icons">access_time</i>
            </div>
            <section className="timeContWrapper">
              <div className="timeCont">
                <input
                  className="timeRange"
                  type="number"
                  min="1"
                  max="12"
                  value={toTime.hours}
                  onChange={e => handleTimeChange(e, "to", "hours")}
                />
                {":"}
                <input
                  className="timeRange"
                  type="number"
                  min="0"
                  max="59"
                  value={toTime.minutes}
                  onChange={e => handleTimeChange(e, "to", "minutes")}
                />
                <i
                  className="material-icons dropdownFilterIcon"
                  onClick={() => setShowToDropdown(!showToDropdown)}
                >
                  {showToDropdown ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                </i>
              </div>
              {showToDropdown && (
                <TimeRangeDropdown
                  time={toTime}
                  setTime={setToTime}
                  setShowDropdown={setShowToDropdown}
                />
              )}
            </section>
          </div>
          <AmPmPanel defaultValue={toTime?.metrics} cbk={toTimeCbk} />
        </div>
        <div className="actionButtons">
          <i
            className="material-icons closeIcon"
            onClick={event => handleCloseAction(event, index, timeRangeIndex)}
          >
            close
          </i>
          <span className="verticalSeperator" />
          <i
            className="material-icons tickIcon"
            onClick={event => handleSubmitAction(event, index, timeRangeIndex)}
          >
            done
          </i>
        </div>
      </div>
    </div>
  );
});

export { TimeRangePanel, NumberTypeContainer };
export default TimeRangePanel;
