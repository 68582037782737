export const HEADERS = [
  {
    name: "contactsAddedCount",
    displayName: "Contacts Added",
    enabled: true
  },
  {
    name: "contactsAddedViaSequencesCount",
    displayName: "Contacts Added Via Sequences",
    enabled: true
  },
  {
    name: "emailsSentCount",
    displayName: "Emails Sent",
    enabled: true
  },
  {
    name: "emailsOpenCount",
    displayName: "Emails Open",
    enabled: true
  },
  {
    name: "emailsClickCount",
    displayName: "Emails Click",
    enabled: true
  },
  {
    name: "emailsReplyCount",
    displayName: "Emails Reply",
    enabled: true
  },
  {
    name: "emailsBounceCount",
    displayName: "Emails Bounce",
    enabled: true
  },
  {
    name: "emailsOptedOutCount",
    displayName: "Emails Opted Out",
    enabled: true
  },
  {
    name: "callsMadeCount",
    displayName: "Calls Made",
    enabled: true
  },
  {
    name: "callsLoggedCount",
    displayName: "Calls Logged",
    enabled: true
  },
  {
    name: "callsViaSequenceCount",
    displayName: "Calls Via Sequence",
    enabled: true
  },
  {
    name: "callsAnswered",
    displayName: "Calls Answered",
    enabled: true
  },
  {
    name: "callsNotAnswered",
    displayName: "Calls Not Answered",
    enabled: true
  },
  {
    name: "totalTimeOnCall",
    displayName: "Total Time On Call",
    enabled: true
  },
  {
    name: "avgCallDurationInSecs",
    displayName: "Avg Call Duration",
    enabled: true
  },
  {
    name: "meetingBookedCount",
    displayName: "Meeting Booked",
    enabled: true
  },
  {
    name: "linkedInTaskCompletedCount",
    displayName: "LinkedIn Task Completed",
    enabled: true
  },
  {
    name: "otherTaskCompletedCount",
    displayName: "Other Task Completed",
    enabled: true
  },
  {
    name: "customTaskCompletedCount",
    displayName: "Custom Task Completed",
    enabled: true
  },
  {
    name: "manualEmailCompletedCount",
    displayName: "Manual Email Completed",
    enabled: true
  },
  {
    name: "phoneCallTasksCompletedCount",
    displayName: "PhoneCall Tasks Completed",
    enabled: true
  },
  {
    name: "skippedTaskCount",
    displayName: "Skipped Tasks Count",
    enabled: true
  },
  {
    name: "openRate",
    displayName: "Open Rate",
    enabled: true
  },
  {
    name: "clickRate",
    displayName: "Click Rate",
    enabled: true
  },
  {
    name: "replyRate",
    displayName: "Reply Rate",
    enabled: true
  },
  {
    name: "bounceRate",
    displayName: "Bounce Rate",
    enabled: true
  },
  {
    name: "linkedInMessagesSent",
    displayName: "LinkedIn Messages Sent",
    enabled: true
  },
  {
    name: "linkedinRepliesReceived",
    displayName: "LinkedIn Replies Received",
    enabled: true
  },
  {
    name: "linkedinConnectionRequestsSent",
    displayName: "LinkedIn Connections Sent",
    enabled: true
  },
  {
    name: "linkedinConnectionRequestsAccepted",
    displayName: "LinkedIn Connections Accepted",
    enabled: true
  }
];

export const NAVIGATION_TAB_LIST = [
  { value: "User", id: "user", path: "user", active: true },
  {
    value: "Team",
    id: "team",
    path: "team"
  }
];

export const SortParamObj = {
  contactsAddedCount: "CONTACTS_ADDED_COUNT",
  "Contacts Added Via Sequences": "CONTACTS_ADDED_VIA_SEQUENCE_COUNT",
  "Emails Sent": "EMAIL_SENT_COUNT",
  "Emails Open": "EMAIL_OPEN_COUNT",
  "Open Rate": "EMAIL_OPEN_COUNT",
  "Emails Click": "EMAIL_CLICK_COUNT",
  "Click Rate": "EMAIL_CLICK_COUNT",
  "Emails Reply": "EMAIL_REPLY_COUNT",
  "Reply Rate": "EMAIL_REPLY_COUNT",
  "Emails Bounce": "EMAIL_BOUNCE_COUNT",
  "Bounce Rate": "EMAIL_BOUNCE_COUNT",
  "Emails Opted Out": "EMAILS_OPTED_OUT_COUNT",
  "Calls Made": "CALLS_MADE_COUNT",
  "Calls Via Sequence": "CALLS_VIA_SEQUENCE",
  "Calls Logged": "CALLS_LOGGED_COUNT",
  "Total Time On Call": "TOTAL_TIME_ON_CALL",
  "Meeting Booked": "MEETING_BOOKED_COUNT",
  "LinkedIn Task Completed": "LINKEDIN_TASK_COMPLETED_COUNT",
  "Other Task Completed": "OTHER_TASK_COMPLETED_COUNT",
  "Custom Task Completed": "CUSTOM_TASK_COMPLETED_COUNT",
  "Manual Email Completed": "MANUAL_EMAIL_COMPLETED_COUNT",
  "PhoneCall Tasks Completed": "PHONE_CALL_TASK_COMPLETED_COUNT",
  "Skipped Tasks Count": "SKIPPED_TASK_COMPLETED_COUNT"
};
