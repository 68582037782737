import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { settingsStoreData } from "Stores/settingsData";
import SingleSelectDropdown from "Pages/Dashboard/settings/Calendar/Pages/Events/CreateEvent/Components/SingleSelectDropdown";
import InputTextBox from "Components/common/InputTextBox";
import { conditionsOperatorObject } from "Static/constant";
import ActionButton from "./ActionButton";
import DropdownButton from "./DropdownButton";
import SingleDropdown from "./SingleDropdown";
import { textOperatorList, booleanOptions } from "./FilterConstants";

export const ContactMetricsFilter = observer(props => {
  const {
    label = "",
    keyValue = "",
    activeFilter = "",
    data = [],
    errMsg = "",
    setErrMsg = () => {},
    setActiveFilter = () => {},
    setAction = () => {},
    selectedFilterCbk = () => {},
    customConditionFieldnameList = [],
    singleFieldWithoutDropdown = false
  } = props || {};

  let toggleDropdown = activeFilter === keyValue;

  const [activeDropdownIndex, setActiveDropdownIndex] = useState(false);
  const [fieldNameObject, setFieldNameObject] = useState({});

  const getConditionFieldsObject = () => {
    let tempObj = {};
    let tempArr = customConditionFieldnameList?.length
      ? customConditionFieldnameList
      : conditionFieldnameList;
    tempArr?.length > 0 &&
      [...tempArr].map(item => {
        tempObj[item?.key] = item?.value;
      });
    setFieldNameObject({ ...fieldNameObject, ...tempObj });
  };

  const validateFieldNameAlreadyExist = (newFieldName = "") => {
    let count = 0;
    if (data?.length > 1) {
      data.some(element => {
        if (element?.fieldName === newFieldName) {
          count++;
        }
      });
    }
    return !count > 0;
  };

  const handleAction = (refId = "", item = "", index = "") => {
    if (refId === "fieldName") {
      if (!validateFieldNameAlreadyExist(item?.key)) {
        return;
      }
      selectedFilterCbk("add", keyValue);
    }
    errMsg && setErrMsg();
    const { key = "", subKey = "", value = "" } = item || {};
    let tempData = [...data];
    tempData[index]["value"] = ["hasVisitedWebsite"].includes(
      item?.key?.toLowerCase()
    )
      ? true
      : "";
    tempData[index][refId] = refId === "fieldName" ? key : subKey || "";
    tempData[index]["label"] = value || "";
    setActiveDropdownIndex(false);
    setAction(keyValue, tempData);
  };

  const setFilterData = (tempData = {}, value = "", index = "") => {
    if (keyValue?.toLowerCase() === "contacts") {
      tempData[index]["fieldName"] = "contactsCount";
      tempData[index]["label"] = "Number of Contacts";
    }
    tempData[index]["value"] = value || "";
    return tempData;
  };

  const handleInputAction = (id = "", value = "", index = "") => {
    errMsg && setErrMsg();
    let tempData = [...data];
    setAction(keyValue, setFilterData(tempData, value, index));
  };

  const getCustomOperatorList = (fieldName = "") => {
    if (fieldName?.toLowerCase() === "websitevisitedurl") {
      return [...textOperatorList].map(item => ({
        ...item,
        method: handleAction
      }));
    } else if (fieldName?.toLowerCase() === "hasvisitedwebsite") {
      return operatorList?.slice(0, 1);
    } else {
      return operatorList;
    }
  };

  const operatorList = [
    {
      value: "<span class='conditionSymbol'>&equals;</span> (Equals)",
      subKey: "EQUALS",
      key: "equalCond",
      method: handleAction
    },
    {
      value: "<span class='conditionSymbol'>&gt;</span> (Greater than)",
      subKey: "GREATER_THAN",
      key: "greaterCond",
      method: handleAction
    },
    {
      value: "<span class='conditionSymbol'>&lt;</span> (Less than)",
      subKey: "LESS_THAN",
      key: "lesserCond",
      method: handleAction
    }
  ];

  const conditionFieldnameList = [
    {
      value: "Reply Count",
      subKey: "REPLIED",
      key: "replyCount",
      method: handleAction
    },
    {
      value: "Open Count",
      subKey: "OPENED",
      key: "openCount",
      method: handleAction
    },
    {
      value: "Click Count",
      subKey: "CLICKED",
      key: "clickCount",
      method: handleAction
    },
    {
      value: "Sent Count",
      subKey: "SENT",
      key: "sentCount",
      method: handleAction
    },
    {
      value: "Calls Made",
      subKey: "CALLMADE",
      key: "callsMade",
      method: handleAction
    },
    {
      value: "Calls Logged",
      subKey: "CALLLOGGED",
      key: "callsLogged",
      method: handleAction
    }
  ];

  const getConditionFieldnameList = () => {
    if (!customConditionFieldnameList?.length) {
      return conditionFieldnameList;
    } else {
      return customConditionFieldnameList.map(item => ({
        ...item,
        method: handleAction
      }));
    }
  };

  const resetOrAddNewItem = (isPush = false) => {
    let newObj = {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    };
    if (isPush) {
      return newObj;
    }
    selectedFilterCbk("remove", keyValue);
    setAction(keyValue, [newObj]);
  };

  const removeItem = index => {
    let tempData = [...data];
    let len = tempData?.length;
    if (len) {
      if (len === 1) {
        resetOrAddNewItem();
      } else {
        tempData.splice(index, 1);
        tempData.filter(item => item?.fieldName)?.length < 1 &&
          selectedFilterCbk("remove", keyValue);
        setAction(keyValue, tempData);
      }
      errMsg && setErrMsg();
    }
  };

  const addItem = () => {
    let tempData = [...data];
    tempData.push(resetOrAddNewItem(true));
    errMsg && setErrMsg();
    setAction(keyValue, tempData);
  };

  const resetItem = () => {
    errMsg && setErrMsg();
    data?.filter(item => item?.fieldName)?.length && resetOrAddNewItem();
  };

  const dropdownOptions = {
    label: label,
    count: data?.filter(item => item?.fieldName)?.length || 0,
    keyValue: keyValue,
    toggleDropdown: toggleDropdown,
    callback: setActiveFilter
  };

  const dropDownObj = {
    name: "Has Visited Website Dropdown",
    id: "hasVisitedWebsiteDropdown",
    type: "select",
    options: [...booleanOptions],
    text: "Has Visited Website Dropdown",
    showLabel: false
  };

  useEffect(() => {
    getConditionFieldsObject();
  }, [settingsStoreData?.customFields]);

  return (
    <div className="conditionField">
      <DropdownButton {...dropdownOptions} />
      {toggleDropdown && (
        <div className="dropdownSearchBox">
          <span className="horizontalBorderLine" />
          {(data?.length && (
            <div className="conditionalFieldCard">
              {data.map((item, index) => {
                const {
                  fieldName = "",
                  operator = "EQUALS",
                  value = ""
                } = item || {};
                const conditionalFieldProps = {
                  isDropdownAllowed: true,
                  options: getConditionFieldnameList(),
                  defaultValue: fieldNameObject?.[fieldName] || "",
                  label: "Select field",
                  referenceId: "fieldName",
                  parentIndex: index,
                  hasCustomFieldValue: true,
                  showDropdown: activeDropdownIndex === `fieldName-${index}`,
                  setActiveDropdownIndex: setActiveDropdownIndex
                };

                const fieldOperatorProps = {
                  isDropdownAllowed: true,
                  options: getCustomOperatorList(fieldName),
                  defaultValue: ["hasvisitedwebsite"].includes(
                    fieldName?.toLowerCase()
                  )
                    ? conditionsOperatorObject?.["EQUALS"]
                    : conditionsOperatorObject?.[operator] || "",
                  showDropdown:
                    !["hasvisitedwebsite"].includes(fieldName?.toLowerCase()) &&
                    activeDropdownIndex === `operator-${index}`,
                  setActiveDropdownIndex: setActiveDropdownIndex,
                  parentIndex: index,
                  referenceId: "operator"
                };

                const inputProps = {
                  name: "number",
                  type: ["websitevisitedurl"].includes(fieldName?.toLowerCase())
                    ? "text"
                    : "number",
                  value: value,
                  change: handleInputAction,
                  parentIndex: index,
                  placeHolder: "Value"
                };

                return (
                  <div
                    className="conditionalField"
                    key={`conditionalField${index}`}
                  >
                    {!singleFieldWithoutDropdown && (
                      <div className="fieldnameDropdown">
                        <SingleDropdown {...conditionalFieldProps} />
                        <i
                          className="material-icons-outlined deleteIcon"
                          onClick={() => removeItem(index)}
                        >
                          delete
                        </i>
                      </div>
                    )}
                    {(fieldName || singleFieldWithoutDropdown) && (
                      <div
                        className={`fieldOperatorValue ${
                          !fieldName && !singleFieldWithoutDropdown
                            ? "disableInput"
                            : ""
                        }`}
                      >
                        <SingleDropdown {...fieldOperatorProps} />
                        {!["hasvisitedwebsite"].includes(
                          fieldName?.toLowerCase()
                        ) ? (
                          <InputTextBox {...inputProps} />
                        ) : (
                          <SingleSelectDropdown
                            {...dropDownObj}
                            change={(param1, param2) => {
                              handleInputAction(param1, param2, index);
                            }}
                            value={value || dropDownObj?.options?.[0] || ""}
                            key={fieldName}
                            displayName="displayName"
                            timezone={true}
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )) ||
            ""}
          {!singleFieldWithoutDropdown && (
            <ActionButton resetCbk={resetItem} addCbk={addItem} />
          )}
          {errMsg === keyValue && (
            <span className="errMessage">Please enter a value</span>
          )}
        </div>
      )}
    </div>
  );
});

export default ContactMetricsFilter;
