import React from "react";
import {
  Switch,
  Redirect,
  Route,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import Events from "Pages/Dashboard/settings/Calendar/Pages/Events/Main";
import Configuration from "Pages/Dashboard/settings/Calendar/Pages/Configuration/Main";

const CalendarRouting = withRouter(() => {
  let { path = "" } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}/events`}
        component={props => <Events {...props} />}
      />
      <Route
        exact
        path={`${path}/configuration`}
        component={props => <Configuration {...props} />}
      />
      <Redirect from={path} to={`${path}/events`} />
    </Switch>
  );
});

export default CalendarRouting;
export { CalendarRouting };
