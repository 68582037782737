import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import TaskBulkSuccessImg from "Assets/png/taskBulkSuccess.png";
import MarkAsCompleteSuccessImg from "Assets/png/successMarkAsComplete.png";
import MarkAsCompleteFailedImg from "Assets/png/failedMarkAsComplete.png";
import InboxReconnectedImg from "Assets/png/signupSuccess.png";
class SuccessPopupState {
  defaultPopupObj = {
    text: "",
    title: "",
    type: "",
    failedText: ""
  };

  @observable showPopup = false;
  @observable popupValues = this.defaultPopupObj;

  @action
  setPopup = value => {
    this.showPopup = value;
  };

  @action
  setPopupValues = value => {
    this.popupValues = value;
  };
}

const successPopupState = new SuccessPopupState();

const SuccessPopup = () => {
  const imgList = {
    task: TaskBulkSuccessImg,
    successMarkAsComplete: MarkAsCompleteSuccessImg,
    failedMarkAsComplete: MarkAsCompleteFailedImg,
    inboxReconnected: InboxReconnectedImg
  };

  const {
    text = "",
    type = "",
    title = "",
    failedText = ""
  } = successPopupState?.popupValues;

  const successAction = () => {
    successPopupState.setPopup(false);
    successPopupState.setPopupValues(successPopupState.defaultPopupObj);
  };

  return (
    (successPopupState.showPopup && type && (
      <div className="successPopup">
        <div className="card animated customzoomIn">
          <img src={imgList[type]} alt="" />
          {title && <h6>{title}</h6>}
          {text && (
            <p className="text" dangerouslySetInnerHTML={{ __html: text }} />
          )}
          {failedText && (
            <p
              className="failedCountText"
              dangerouslySetInnerHTML={{ __html: failedText }}
            />
          )}
          <Button
            id="successPopupBtn"
            name="signupsuccessPopupBtnBtn"
            btnText="Ok"
            type="button"
            click={e => successAction(e)}
          />
        </div>
        <div className="successPopupOverlay" />
      </div>
    )) ||
    ""
  );
};

export default observer(SuccessPopup);
export { successPopupState, SuccessPopup };
