import React from "react";
import {
  Switch,
  Redirect,
  Route,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import CallsOverview from "Pages/Dashboard/Calls/CallsOverview";
import CallsAnalytics from "Pages/Dashboard/Calls/CallsAnalytics";

const CallsTabRouting = () => {
  let { path = "" } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/overview`} component={CallsOverview} />
      <Route exact path={`${path}/analytics`} component={CallsAnalytics} />
      <Redirect from={path} to={`${path}/overview`} />
    </Switch>
  );
};

export default withRouter(CallsTabRouting);
export { CallsTabRouting };
