import React, { useContext } from "react";
import { AccountsListingContext } from "../AccountsList";
import { ReactComponent as FilterIcon } from "Assets/svg/filter.svg";

const FilterBtn = () => {
  const {
    accountsNavbarObj = {},
    accountsTableObj = {},
    showAccountFilter = false,
    filterList = [],
    setShowAccountFilter = () => {}
  } = useContext(AccountsListingContext) || {};

  const { navBarLoading = false } = accountsNavbarObj || {};
  const { tableArr = [], tableLoading = false } = accountsTableObj || {};

  const toggleFilter = () => {
    !navBarLoading && !tableLoading && setShowAccountFilter(!showAccountFilter);
  };
  return (
    <>
      <div
        className={`toggleFilterBtn ${tableArr?.length > 0 ? "" : "disabled"} ${
          filterList?.length > 0 ? "" : "filterUsed"
        }`}
        onClick={toggleFilter}
      >
        <FilterIcon />
        <span className="text">Filter</span>
        {filterList?.length > 0 && (
          <span className="count">{filterList?.length}</span>
        )}
      </div>
    </>
  );
};

export default FilterBtn;
