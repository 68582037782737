import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { conditionsOperatorObject } from "Static/constant";
import DropdownButton from "./DropdownButton";
import ActionButton from "./ActionButton";
import InputTextBox from "Components/common/InputTextBox";
import SingleSelectDropdown from "Pages/Dashboard/settings/Calendar/Pages/Events/CreateEvent/Components/SingleSelectDropdown";
import SingleDropdown from "./SingleDropdown";
import ContactAutoCompleteDropdown from "Components/PreviewPopup/ContactAutoCompleteDropdown";
import {
  employeeCountDropdown,
  revenueRange
} from "Pages/Dashboard/Accounts/AccountConstants";
import { settingsStoreData } from "Stores/settingsData";
import userDetail from "Stores/userDetail";

export const ContactFieldFilter = observer(props => {
  const {
    label = "",
    keyValue = "",
    activeFilter = "",
    data = [],
    errMsg = "",
    setErrMsg = () => {},
    setActiveFilter = () => {},
    setAction = () => {},
    selectedFilterCbk = () => {},
    hasDropdown = true,
    disableAddButton = false,
    customConditionalFieldList = []
  } = props || {};

  const { customFieldsCount = 0 } = userDetail?.userFeatureFlag || {};

  let toggleDropdown = hasDropdown ? activeFilter === keyValue : true;

  const [activeDropdownIndex, setActiveDropdownIndex] = useState(false);
  const [fieldNameObject, setFieldNameObject] = useState({});
  const [disabledContactFields, setDisabledContactFields] = useState([]);
  let customFieldData = [];

  const validateFieldNameAlreadyExist = (newFieldName = "") => {
    let count = 0;
    if (data?.length > 1) {
      data.some(element => {
        if (element?.fieldName === newFieldName) {
          count++;
        }
      });
    }
    return !count > 0;
  };

  const disableConditionFields = data => {
    let arr = [];
    if (data?.length) {
      data.map(item => {
        arr.push(item?.fieldName);
        return arr;
      });
    }
    setDisabledContactFields(arr.filter(Boolean));
  };

  const handleAction = (refId = "", item = "", index = "") => {
    if (refId === "fieldName") {
      if (!validateFieldNameAlreadyExist(item?.key)) {
        return;
      }
      selectedFilterCbk("add", keyValue);
    }
    errMsg && setErrMsg();
    const { key = "", subKey = "", value = "" } = item || {};
    let tempData = [...data];
    const keyLower = item?.key?.toLowerCase();
    tempData[index]["value"] = ["employeecount", "revenue"].includes(keyLower)
      ? keyLower === "employeecount"
        ? "0 - 25"
        : "$0 to $1M"
      : "";
    tempData[index][refId] = refId === "fieldName" ? key : subKey || "";
    tempData[index]["label"] = value || "";
    setActiveDropdownIndex(false);
    setAction(keyValue, tempData);
    disableConditionFields(tempData);
  };

  const handleInputAction = (id = "", value = "", index = 0) => {
    errMsg && setErrMsg();
    let tempData = [...data];
    tempData[index]["value"] = value || "";
    setAction(keyValue, tempData);
  };

  const operatorList = [
    {
      value: "<span class='conditionSymbol'>&equals;</span> (Equals)",
      subKey: "EQUALS",
      key: "equalCond",
      method: handleAction
    },
    {
      value: "<span class='conditionSymbol'>&#8717;</span> (Contains)",
      subKey: "CONTAINS",
      key: "containCond",
      method: handleAction
    },
    {
      value: "<span class='conditionSymbol'>&ne;</span> (Not equals)",
      subKey: "NOT_EQUALS",
      key: "notEqualCond",
      method: handleAction
    }
  ];

  const getCustomContactFields = () => {
    Array.from({ length: customFieldsCount }, (value, index) =>
      customFieldData.push({
        value: `Custom Field ${index + 1}`,
        subKey: `CUSTOM_FIELD_${index + 1}`,
        key: `customField${index + 1}`,
        method: handleAction
      })
    );
    return customFieldData;
  };

  const constructCustomFieldsWithValue = () => {
    const customContactFields = getCustomContactFields();
    return customContactFields.map(item => {
      item.value = settingsStoreData?.customFields?.[item?.key] || item?.value;
      item.hasCustomFieldValue = settingsStoreData?.customFields?.[item?.key]
        ? true
        : false;
      item.disabled = disabledContactFields?.includes(item?.key);
      return item;
    });
  };

  const conditionFieldnameList = [
    {
      value: "First Name",
      subKey: "FIRST_NAME",
      key: "firstName",
      method: handleAction,
      disabled: disabledContactFields?.includes("firstName")
    },
    {
      value: "Last Name",
      subKey: "LAST_NAME",
      key: "lastName",
      method: handleAction,
      disabled: disabledContactFields?.includes("lastName")
    },
    {
      value: "Email Address",
      subKey: "EMAIL_ADDRESS",
      key: "email",
      method: handleAction,
      disabled: disabledContactFields?.includes("email")
    },
    {
      value: "Job Title",
      subKey: "JOB_TITLE",
      key: "jobTitle",
      method: handleAction,
      disabled: disabledContactFields?.includes("jobTitle")
    },
    {
      value: "Phone Number",
      subKey: "PHONE_NUMBER",
      key: "phone",
      method: handleAction,
      disabled: disabledContactFields?.includes("phone")
    },
    {
      value: "Mobile Number",
      subKey: "MOBILE_NUMBER",
      key: "mobilePhone",
      method: handleAction,
      disabled: disabledContactFields?.includes("mobilePhone")
    },
    {
      value: "Other Number",
      subKey: "OTHER_NUMBER",
      key: "otherPhone",
      method: handleAction,
      disabled: disabledContactFields?.includes("otherPhone")
    },
    {
      value: "Company",
      subKey: "COMPANY",
      key: "company",
      method: handleAction,
      disabled: disabledContactFields?.includes("company")
    },
    {
      value: "Industry",
      subKey: "INDUSTRY",
      key: "industry",
      method: handleAction,
      disabled: disabledContactFields?.includes("industry")
    },
    {
      value: "Street",
      subKey: "STREET",
      key: "street",
      method: handleAction,
      disabled: disabledContactFields?.includes("street")
    },
    {
      value: "City",
      subKey: "CITY",
      key: "city",
      method: handleAction,
      disabled: disabledContactFields?.includes("city")
    },
    {
      value: "State",
      subKey: "STATE",
      key: "state",
      method: handleAction,
      disabled: disabledContactFields?.includes("state")
    },
    {
      value: "Country",
      subKey: "COUNTRY",
      key: "country",
      method: handleAction,
      disabled: disabledContactFields?.includes("country")
    },
    {
      value: "Zip Code",
      subKey: "ZIP_CODE",
      key: "zipCode",
      method: handleAction,
      disabled: disabledContactFields?.includes("zipCode")
    },
    {
      value: "Website",
      subKey: "WEBSITE",
      key: "website",
      method: handleAction,
      disabled: disabledContactFields?.includes("website")
    },
    {
      value: "LinkedIn URL",
      subKey: "LINKEDIN_URL",
      key: "linkedinId",
      method: handleAction,
      disabled: disabledContactFields?.includes("linkedinId")
    },
    {
      value: "Facebook",
      subKey: "FACEBOOK",
      key: "facebook",
      method: handleAction,
      disabled: disabledContactFields?.includes("facebook")
    },
    {
      value: "Twitter",
      subKey: "TWITTER",
      key: "twitter",
      method: handleAction,
      disabled: disabledContactFields?.includes("twitter")
    },
    ...constructCustomFieldsWithValue()
  ];

  const getConditionFieldsObject = () => {
    let tempObj = {};
    let tempConditionFieldArr = customConditionalFieldList?.length
      ? customConditionalFieldList
      : conditionFieldnameList;
    tempConditionFieldArr?.length > 0 &&
      tempConditionFieldArr.map(item => {
        tempObj[item?.key] = item?.value;
      });
    setFieldNameObject({ ...fieldNameObject, ...tempObj });
  };

  const getCustomConditonalFields = () => {
    return [...customConditionalFieldList].map(item => ({
      ...item,
      disabled: disabledContactFields?.includes(item?.key),
      method: handleAction
    }));
  };

  const resetOrAddNewItem = (isPush = false) => {
    let newObj = {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    };
    if (isPush) {
      return newObj;
    }
    selectedFilterCbk("remove", keyValue);
    setAction(keyValue, [newObj]);
    disableConditionFields([newObj]);
  };

  const removeItem = index => {
    let tempData = [...data];
    let len = tempData?.length;
    if (len) {
      if (len === 1) {
        resetOrAddNewItem();
      } else {
        tempData.splice(index, 1);
        tempData.filter(item => item?.fieldName)?.length < 1 &&
          selectedFilterCbk("remove", keyValue);
        setAction(keyValue, tempData);
        disableConditionFields(tempData);
      }
      errMsg && setErrMsg();
    }
  };

  const addItem = () => {
    let tempData = [...data];
    tempData.push(resetOrAddNewItem(true));
    errMsg && setErrMsg();
    setAction(keyValue, tempData);
  };

  const resetItem = () => {
    errMsg && setErrMsg();
    data?.filter(item => item?.fieldName)?.length && resetOrAddNewItem();
  };

  const employeeCountObj = {
    name: "employeeCount",
    id: "employeeCount",
    type: "select",
    options: [...employeeCountDropdown],
    text: "No Of Employees",
    showLabel: false
  };

  const revenueObj = {
    name: "revenue",
    id: "revenue",
    type: "select",
    options: [...revenueRange],
    text: "Revenue",
    showLabel: false
  };

  useEffect(() => {
    if (data?.length === 1 && !data?.fieldName & !data?.value) {
      disableConditionFields(data);
    }
  }, [data]);

  useEffect(() => {
    getConditionFieldsObject();
  }, [settingsStoreData?.customFields]);

  return (
    <div className="conditionField">
      {hasDropdown && (
        <DropdownButton
          label={label}
          count={data?.filter(item => item?.fieldName)?.length || 0}
          keyValue={keyValue}
          toggleDropdown={toggleDropdown}
          callback={setActiveFilter}
        />
      )}
      {toggleDropdown && (
        <div className="dropdownSearchBox">
          <span className="horizontalBorderLine" />
          {(data?.length && (
            <div className="conditionalFieldCard">
              {data.map((item, index) => {
                const {
                  fieldName = "",
                  operator = "EQUALS",
                  value = ""
                } = item || {};
                let dropdownProps =
                  fieldName?.toLowerCase() === "employeecount"
                    ? employeeCountObj
                    : revenueObj;
                return (
                  <div
                    className="conditionalFieldWrap"
                    key={`conditionalField${index}`}
                  >
                    <span className="horizontalBorderLine" />
                    <div className="conditionalField">
                      <div className="fieldnameDropdown">
                        <SingleDropdown
                          isDropdownAllowed={true}
                          options={
                            customConditionalFieldList?.length
                              ? getCustomConditonalFields()
                              : conditionFieldnameList
                          }
                          defaultValue={fieldNameObject?.[fieldName] || ""}
                          label="Select field"
                          referenceId="fieldName"
                          parentIndex={index}
                          hasCustomFieldValue={true}
                          showDropdown={
                            activeDropdownIndex === `fieldName-${index}`
                          }
                          setActiveDropdownIndex={setActiveDropdownIndex}
                        />
                        <i
                          className="material-icons-outlined deleteIcon"
                          onClick={() => removeItem(index)}
                        >
                          delete
                        </i>
                      </div>
                      {fieldName && (
                        <div
                          className={`fieldOperatorValue ${
                            !fieldName ? "disableInput" : ""
                          }`}
                        >
                          <SingleDropdown
                            isDropdownAllowed={true}
                            options={operatorList}
                            defaultValue={
                              conditionsOperatorObject?.[operator] || ""
                            }
                            showDropdown={
                              !["employeecount", "revenue"].includes(
                                fieldName?.toLowerCase()
                              ) && activeDropdownIndex === `operator-${index}`
                            }
                            setActiveDropdownIndex={setActiveDropdownIndex}
                            parentIndex={index}
                            referenceId="operator"
                          />
                          {!["employeecount", "revenue"].includes(
                            fieldName?.toLowerCase()
                          ) ? (
                            <>
                              <InputTextBox
                                name="text"
                                type="text"
                                value={value}
                                change={handleInputAction}
                                parentIndex={index}
                                placeHolder="Value"
                              />
                              {["email", "email_address"]?.includes(
                                fieldName?.toLowerCase()
                              ) && (
                                <ContactAutoCompleteDropdown
                                  inputText={value}
                                  selectedCallbk={(contact = {}) =>
                                    handleInputAction(
                                      keyValue,
                                      contact?.email,
                                      index
                                    )
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <SingleSelectDropdown
                              {...dropdownProps}
                              change={(param1, param2) => {
                                handleInputAction(param1, param2, index);
                              }}
                              value={value || dropdownProps?.options?.[0] || ""}
                              key={fieldName}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )) ||
            ""}
          <ActionButton
            resetCbk={resetItem}
            addCbk={addItem}
            disableAddButton={disableAddButton}
          />
          {errMsg === keyValue && (
            <span className="errMessage">Please enter a value</span>
          )}
        </div>
      )}
    </div>
  );
});

export default ContactFieldFilter;
