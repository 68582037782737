/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { sequenceStoreData } from "Stores/sequenceData";
import { observer } from "mobx-react";
import { useDrop } from "react-dnd";
import Utils from "Utils/utils";
import { NavLink } from "react-router-dom";
import { URLS, makeApi } from "Utils/apiURL";
import { MP_EVENT } from "Static/constant";
import { toasterState } from "Components/common/toaster";
import SequenceFolderList from "./SequenceFolderList";
import CreateFolder from "Components/common/CreateFolder";
import FolderLoader from "Components/common/FolderLoader";

const FolderComponent = props => {
  const { redirectToMySequence } = props;
  const [newFolderName, setNewFolderName] = useState("New Folder");
  const [editedFolderName, setEditedFolderName] = useState("");
  const [folderData, setFolderData] = useState(
    sequenceStoreData.allFolderResponse
  );
  const [showCreateFolderLoader, setCreateFolderLoader] = useState(false);

  const saveNewFolder = async event => {
    try {
      Utils.preventDefaultFn(event);
      if (newFolderName?.length) {
        setCreateFolderLoader(true);

        const config = {
          url: URLS.createNewSeqFolder,
          method: "POST",
          data: {
            folderName: newFolderName,
            parent: null
          }
        };

        let response = await makeApi(config);
        if (response?.data) {
          setNewFolderName("New Folder");
          const constructedFolders = [
            { folderName: response.data.folderName, id: response.data.id },
            ...folderData
          ];
          sequenceStoreData.setFolderResponse(constructedFolders);
          toasterState.setToastMsg("Folder created successfully", "success");
          setCreateFolderLoader(false);
          props.setCreateFolder(false);
        } else {
          setCreateFolderLoader(false);
          props.setCreateFolder(true);
          toasterState.setToastMsg(
            `${newFolderName} is already taken. Please choose a different name`,
            "fail"
          );
        }
      }
    } catch (err) {
      console.error("Sequence Folder creation error", err);
    }
  };

  const newFolderNameAction = event => {
    if (event !== undefined && event !== null) {
      const { value } = event.currentTarget;
      setNewFolderName(value);
    }
  };

  const newFolderNameFocus = e => {
    if (e) e.target.select();
  };

  const newFolderNameCancelAction = event => {
    Utils.preventDefaultFn(event);
    props.setCreateFolder(!props.showcreateFolder);
    setNewFolderName("New Folder");
  };

  const constructFolderDataWithFlags = folderRes => {
    // set all flags as false by default
    if (folderRes) {
      folderRes.map(value => {
        if (value) {
          value.showEditIcon = false;
          value.showDeleteIcon = false;
          value.isEdit = false;
        }
      });
      setFolderData(JSON.parse(JSON.stringify(folderRes)));
    }
  };

  const editFolderNameAction = event => {
    if (event !== undefined && event !== null) {
      const { value } = event.currentTarget;
      setEditedFolderName(value);
    }
  };

  const cancelEditFolder = (event, id) => {
    Utils.preventDefaultFn(event);
    updateEditInputFlag(id);
    setEditedFolderName("");
  };

  const updateEditInputFlag = (id, updatedData) => {
    if (folderData) {
      const tempData = updatedData ? updatedData : folderData;
      tempData.map(value => {
        if (value?.id === id) {
          if (value.isEdit) {
            value.isEdit = false;
          } else {
            value.isEdit = true;
          }
        }
      });
      setFolderData(JSON.parse(JSON.stringify(tempData)));
    }
  };

  const updateEditIconFlag = (id, flag, updatedData) => {
    if (folderData) {
      const tempData = updatedData ? updatedData : folderData;
      tempData.map(value => {
        if (value?.id === id) {
          if (flag === "enter") {
            value.showEditIcon = true;
            value.showDeleteIcon = true;
          } else if (flag === "leave") {
            value.showEditIcon = false;
            value.showDeleteIcon = false;
          }
        }
      });
      setFolderData(JSON.parse(JSON.stringify(tempData)));
    }
  };

  const editAction = (e, id, name) => {
    Utils.preventDefaultFn(e);
    if (id && name) {
      setEditedFolderName(name);
      updateEditInputFlag(id);
    }
  };

  const deleteFolder = (e, id, name) => {
    Utils.preventDefaultFn(e);
    if (id && name) {
      toasterState.setToastMsg("Deleting folder. Please wait...", "info");
      sequenceStoreData.deleteSequenceFolder(id, redirectToMySequence);
    }
  };

  const toggleEditIcon = (e, id, flag) => {
    Utils.preventDefaultFn(e);
    id && flag && updateEditIconFlag(id, flag);
  };

  const saveEditedFolder = async (e, id) => {
    Utils.preventDefaultFn(e);
    setCreateFolderLoader(true);
    const url = `${URLS.updateSequenceFolderName}/${editedFolderName}/${id}`;
    if (url) {
      const updatedData = await sequenceStoreData.updateSequenceFolderName(
        url,
        editedFolderName
      );
      setCreateFolderLoader(false);
      updatedData && constructFolderDataWithFlags(updatedData);
    }
  };

  useEffect(() => {
    if (sequenceStoreData?.allFolderResponse) {
      props.setShowFolderLoader(false);
      constructFolderDataWithFlags(sequenceStoreData.allFolderResponse);
    }
  }, [sequenceStoreData.allFolderResponse]);

  return !props.showFolderLoader ? (
    <div className="folderCont">
      <div className="folderListCont">
        <MySequenceFolder />
        <SharedSequneceFolder />
        <div className="dynamicFoldersWrapper">
          {props.showcreateFolder && (
            <CreateFolder
              saveNewFolder={saveNewFolder}
              newFolderNameFocus={newFolderNameFocus}
              newFolderNameAction={newFolderNameAction}
              newFolderNameCancelAction={newFolderNameCancelAction}
              newFolderName={newFolderName}
              folderLoader={showCreateFolderLoader}
            />
          )}
          <SequenceFolderList
            folderData={folderData}
            editFolder={editAction}
            savedEditFolder={saveEditedFolder}
            newFolderNameFocus={newFolderNameFocus}
            editFolderNameAction={editFolderNameAction}
            cancelEditFolder={cancelEditFolder}
            editedFolderName={editedFolderName}
            toggleEditIcon={toggleEditIcon}
            folderLoader={showCreateFolderLoader}
            deleteFolder={deleteFolder}
          />
        </div>
      </div>
    </div>
  ) : (
    <FolderLoader loadingNumber={15} />
  );
};

const MySequenceFolder = observer(() => {
  const removeSequenceFromList = (obj = "") => {
    const { id = "", isSharedFolder = false } = obj || {};
    sequenceStoreData.setTempSequenceData(sequenceStoreData.sequenceData);
    let tempList = [...(sequenceStoreData?.sequenceData || [])];
    if (isSharedFolder) {
      return tempList;
    }
    return tempList.filter(item => {
      if (item?.id !== id) {
        return item;
      }
    });
  };

  const moveSequenceToAnotherFolder = async (
    item = {},
    showUndoButton = true
  ) => {
    !showUndoButton && toasterState.close();
    if (item?.id && item?.folderId) {
      let removedList = showUndoButton
        ? removeSequenceFromList(item)
        : sequenceStoreData.tempSequenceData;
      const config = {
        url: URLS.moveSequenceToAnotherFolder
          .replace("<<sequenceId>>", item.id)
          .replace("<<folderId>>", showUndoButton ? null : item?.folderId)
      };
      const res = await makeApi(config);
      const { status = "", success = false } = res?.data || {};
      Utils.mixpanelTrack(MP_EVENT.SQ_DRAG_DROP_PERFORMED, {
        pageType: "Sequences"
      });
      if (+status === 200 && success) {
        sequenceStoreData.sequenceData = removedList;
        !showUndoButton && sequenceStoreData.setTempSequenceData("");
        toasterState.setPopupValues({
          showUndoButton,
          callback: () => {
            showUndoButton && moveSequenceToAnotherFolder(item, false);
          }
        });
        let name = showUndoButton ? "My Sequences" : item?.folderName;
        let msg = showUndoButton
          ? `to ${name} successfully.`
          : `back to ${name}`;
        toasterState.setToastMsg(`Sequence moved ${msg}`, "success");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: "moveSequence",
    drop: moveSequenceToAnotherFolder,
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <NavLink
      to="/sequences/all"
      strict
      activeClassName="selected"
      className={`folder ${isOver ? "dropOver" : ""}`}
      data-name="My Sequences"
      ref={dropRef}
    >
      <i className="material-icons-outlined">folder</i>
      <span className="folderName">My Sequences</span>
      {isOver && (
        <span className="material-icons-outlined dropHereText sequence">
          drag_handle
        </span>
      )}
    </NavLink>
  );
});

const SharedSequneceFolder = () => {
  return (
    <NavLink
      to="/sequences/shared"
      exact
      activeClassName="selected"
      className="folder"
      data-id="shared"
      data-name="Shared Sequences"
    >
      <span className="sharedSeqFolder"></span>
      <span className="folderName">Shared Sequences</span>
    </NavLink>
  );
};

export default observer(FolderComponent);
export { FolderComponent };
