import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import CheckBoxComponent from "Components/common/CheckBox";

const DropDownMenu = observer((props = {}) => {
  const {
    selected = "",
    contact = "",
    isSubmenu,
    isCustomValue = false,
    hasCustomFieldValue: hasCustomText = false,
    parentIndex = "",
    childIndex = "",
    referenceId = "",
    onMouseEnter = () => {},
    multiSelect = false,
    selectedOptions = [],
    setSelectedOptions = () => {}
  } = props || {};

  const [options, setOptions] = useState([]);
  const [direction, setDirection] = useState("right");
  const [parentPosition, setParentPosition] = useState(null);

  useEffect(() => {
    if (props.options) {
      setOptions(props.options);
      setDirection(props.direction);
      setParentPosition(props.parentPosition);
    }
  }, [props]);

  const hasReachedWindowBottom = () => {
    return parentPosition && window.innerHeight - parentPosition < 200;
  };

  const getDirectionStyle = () => {
    let style = {};
    if (hasReachedWindowBottom()) {
      style["bottom"] = "100%";
    }
    switch (direction) {
      case "center":
        style["right"] = 0;
        style["left"] = 0;
        break;
      case "right":
        style["left"] = "20px";
        style["right"] = "unset";
        break;
      case "left":
        style["right"] = "20px";
        style["left"] = "unset";
        break;
      case "topLeft":
        style["top"] = 0;
        style["right"] = "20px";
        style["left"] = "unset";
        style["bottom"] = "unset";
        break;
      case "alignLeft":
        style["left"] = "0";
        break;
      case "bottomUp":
        style["right"] = "230px";
        break;
      case "bottomAlign":
        style["top"] = "-230px";
        break;
      case "topAlign":
        style["top"] = "195px";
      default:
        break;
    }
    return style;
  };

  const getSubmenuDirectionStyle = () => {
    switch (direction) {
      case "center":
        return { left: "100%" };
      case "right":
        return { right: "100%" };
      case "left":
        return { left: "100%" };
      default:
        return {};
    }
  };

  const getAnimationClass = () => {
    if (props?.animationClass) {
      return props.animationClass;
    } else {
      return hasReachedWindowBottom()
        ? "animatedDropDownReverse"
        : "animatedDropDown";
    }
  };

  const showSubmenuOption = (key, value) => {
    const updatedOptions = options.map(e => {
      if (e.key === key) {
        e.showSubmenu = value;
      }
      return e;
    });
    setOptions(updatedOptions);
  };

  const getSVGIcon = icon => {
    if (icon.ele) {
      if (typeof icon.ele == "string") {
        return (
          <span
            className="icon-holder"
            style={icon.style ? icon.style : {}}
            dangerouslySetInnerHTML={{ __html: icon.ele || "" }}
          />
        );
      }
      return (
        <span
          className="icon-holder svg-wrapper"
          style={icon.style ? icon.style : {}}
        >
          {icon.ele}
        </span>
      );
    } else {
      return "";
    }
  };

  const getImgIcon = icon => {
    return <img className="dropdownImg" src={icon} alt="" />;
  };

  const dropdownCbkmethod = (e, item = {}) => {
    if (e) Utils.preventDefaultFn(e);
    if (item?.key?.toLowerCase() === "call") {
      item?.method && item.method(contact, item, parentIndex, childIndex);
    } else if (item?.type?.toLowerCase() === "task") {
      item?.method &&
        item.method(contact, referenceId, item, parentIndex, childIndex);
    } else {
      item?.method && item.method(referenceId, item, parentIndex, childIndex);
    }
  };

  return (
    <div
      className={`drop-down-menu animatedFast ${getAnimationClass()}`}
      style={isSubmenu ? getSubmenuDirectionStyle() : getDirectionStyle()}
      onMouseEnter={onMouseEnter}
    >
      <ul className="dropdownList">
        {options?.length > 0 &&
          options?.map((item, index) => {
            const {
              key = "",
              icon = "",
              img = "",
              value = "",
              showUpgrade = false,
              submenu = "",
              showSubmenu = false,
              closeDropdown = () => {},
              type = "",
              name = "",
              plan = "basicPlan",
              hasCustomFieldValue = false,
              disabled = false,
              subLabel = "",
              hasSubLabel = false,
              arrowRight = false,
              method = () => {},
              checked = false
            } = item || {};

            return (
              <li
                key={`menu_${index}`}
                id={key}
                onClick={
                  disabled || multiSelect
                    ? () => {}
                    : e => dropdownCbkmethod(e, item)
                }
                className={`option ${disabled ? "disabled" : ""}`}
                onMouseEnter={() => showSubmenuOption(key, true)}
                onMouseLeave={() => showSubmenuOption(key, false)}
              >
                <div className="listItem">
                  <span className="listItemWrap">
                    {icon && getSVGIcon(icon)}
                    {img && getImgIcon(img)}
                    <>
                      {multiSelect && (
                        <CheckBoxComponent
                          name={isCustomValue ? name : value}
                          id={key}
                          checked={checked}
                          cbk={method}
                        />
                      )}
                      {!multiSelect && (
                        <span
                          className="itemName"
                          dangerouslySetInnerHTML={{
                            __html: isCustomValue ? name : value
                          }}
                        />
                      )}
                    </>
                    {hasCustomFieldValue && hasCustomText && (
                      <span className="customText">Custom</span>
                    )}
                    {hasSubLabel && (
                      <span className="itemSubLabel" title={subLabel}>
                        {subLabel}
                      </span>
                    )}
                    {showUpgrade && type && (
                      <UpgradeIconTooltip
                        type={type}
                        closeDropdown={closeDropdown}
                        showIcon={true}
                        plan={plan}
                      />
                    )}
                  </span>
                  {type === "DIALER" && (
                    <span className="iconArrow starIcon">
                      <i
                        className={
                          selected === value
                            ? "material-icons"
                            : "material-icons-outlined"
                        }
                      >
                        grade
                      </i>
                    </span>
                  )}
                  {(submenu || arrowRight) && (
                    <span className="iconArrow">
                      <i className="material-icons">keyboard_arrow_right</i>
                    </span>
                  )}
                  {submenu && showSubmenu && (
                    <DropDownMenu
                      options={submenu}
                      isSubmenu={true}
                      referenceId={referenceId}
                      direction={direction}
                    />
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
});

export default DropDownMenu;
export { DropDownMenu };
