import React, { useState } from "react";
import { ReactComponent as Icon } from "Assets/svg/filterByStep.svg";
import { CustomTooltip } from "Components/common/CustomTooltip";

const FilterByStep = (props = {}) => {
  const {
    showStepsList = false,
    setShowStepsList = () => {},
    resetContactCbk = () => {}
  } = props || {};

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="filterByStep">
      <div
        className="filterByStepBtn"
        onClick={() => {
          setShowStepsList(!showStepsList);
          resetContactCbk();
        }}
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Icon />
        {showTooltip && <CustomTooltip text="Filter by Sequence Step" />}
      </div>
    </div>
  );
};

export default FilterByStep;
export { FilterByStep };
