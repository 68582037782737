import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import {
  customContactFields,
  salesgearContactFields,
  salesgearFieldMapping,
  getCustomFieldsCRM
} from "static/constant";
import { settingsStoreData } from "Stores/settingsData";
import { ReactComponent as OutboundIcon } from "Assets/svg/Outbound.svg";
import { ReactComponent as InboundIcon } from "Assets/svg/Inbound.svg";
import { ReactComponent as BothDirectionsIcon } from "Assets/svg/BothDirections.svg";
import FilterDropdown from "../../Triggers/AddEditTrigger/FilterDropdown";
import AddItem from "../../Triggers/AddEditTrigger/AddItem";
import AutoCompleteDropdown from "Components/common/AutoCompleteDropdown";
import userDetail from "Stores/userDetail";

// Refer SG-838
export const PIPEDRIVE_RESTRICT_DIRECTION = "contact_deal_stage";

const TableHeader = () => {
  const tableHeader = [
    {
      name: "Pipedrive People Field",
      key: "crmContactFields",
      style: { width: "33.3%" }
    },
    {
      name: "Salesgear Contact Field",
      key: "salesgearContactFields",
      style: { width: "33.3%" }
    },
    {
      name: "Sync (Direction)",
      key: "syncFirection",
      style: { width: "33.3%" }
    },
    { style: { width: "20px" } }
  ];

  return (
    <thead>
      <tr>
        {tableHeader?.map(item => {
          const { name = "", key = "", style = {} } = item || {};
          return (
            <th className="textDark" key={key} style={style}>
              {name}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const SyncDirection = observer(props => {
  const {
    activeDropdownIndex = "",
    setActiveDropdownIndex = () => {},
    currentSyncDirection = "INBOUND",
    defaultSyncDirection = "INBOUND",
    index = 0,
    className = "",
    crmFieldRestrictAccess = true,
    config = {},
    setConfig = () => {}
  } = props;

  const syncDirectionList = [
    {
      value: "OUTBOUND",
      name: "Outbound",
      Icon: OutboundIcon,
      hasIcon: true
    },
    {
      value: "INBOUND",
      name: "Inbound",
      Icon: InboundIcon,
      hasIcon: true
    },
    {
      value: "BOTH",
      name: "Both Directions",
      Icon: BothDirectionsIcon,
      hasIcon: true
    }
  ];

  const mappedSyncDirection = {
    OUTBOUND: "Outbound",
    INBOUND: "Inbound",
    BOTH: "Both Directions",
    OUTBOUND_ICON: <OutboundIcon />,
    INBOUND_ICON: <InboundIcon />,
    BOTH_ICON: <BothDirectionsIcon />
  };

  const SyncDirectionIcon =
    mappedSyncDirection?.[`${currentSyncDirection}_ICON`] ||
    mappedSyncDirection?.[`${defaultSyncDirection}_ICON`];

  const syncDirectionAction = item => {
    const indexValue = Number(activeDropdownIndex.split("-")?.[1]);
    const fields = [...config?.fields];
    fields[indexValue - 1] = {
      ...config?.fields?.[indexValue - 1],
      syncDirection: item?.value
    };
    setConfig({
      ...config,
      fields
    });
    setActiveDropdownIndex("");
  };

  const getDefaultValue = () => {
    return (
      <>
        <span className="selectedSyncDirectionIcon">{SyncDirectionIcon}</span>
        <span className="selectedSyncDirection">
          {mappedSyncDirection?.[currentSyncDirection] ||
            mappedSyncDirection?.[defaultSyncDirection]}
        </span>
      </>
    );
  };

  return (
    <FilterDropdown
      label="Select"
      isCustomValue={true}
      className={className}
      hasDefaultChildren={true}
      hasCustomFieldValue={true}
      options={syncDirectionList}
      index={`keysync-${index + 1}`}
      customCbk={syncDirectionAction}
      accessToEditField={crmFieldRestrictAccess}
      setActiveDropdownIndex={setActiveDropdownIndex}
      showDropdown={activeDropdownIndex === `keysync-${index + 1}`}
      defaultValue={getDefaultValue()}
    />
  );
});

const TableBody = observer(props => {
  const {
    mappingConfig = [],
    type = "CONTACTS",
    config = [],
    setConfig = () => {},
    fields: currentFields = {},
    crmName = ""
  } = props || {};

  const { customFieldsCount = 0 } = userDetail?.userFeatureFlag || {};

  const [activeDropdownIndex, setActiveDropdownIndex] = useState("");
  const [crmOptionList, setCrmOptionList] = useState([]);
  const [deleteIconIndex, setDeleteIconIndex] = useState("");
  const [updatedMappingConfig, setUpdatedMappingConfig] =
    useState(mappingConfig);
  const [disabledSgFields, setDisabledSgFields] = useState([]);
  const [disabledCrmFields, setDisabledCrmFields] = useState([]);

  const getCustomFields = (item = {}, index = "", type = "") => {
    const indexValue = Number(index?.split("-")[1]);
    const fields = [...config?.fields];
    fields[indexValue - 1] = {
      ...config?.fields?.[indexValue - 1],
      ...(type === "salesgearField"
        ? { sgField: item?.value }
        : {
            crmField: item?.value,
            dataType: item?.dataType,
            crmFieldLabel: item?.name
          })
    };
    setUpdatedMappingConfig(fields);
    type === "salesgearField"
      ? setDisabledSgFields([...disabledSgFields, item?.value])
      : setDisabledCrmFields([...disabledCrmFields, item?.value]);
    setConfig({
      ...config,
      fields
    });
    setActiveDropdownIndex("");
  };

  const customCrmFieldCbk = (item = {}, index = "") => {
    getCustomFields(item, index, "crmField");
  };

  const customSgFieldCbk = (item = {}, index = "") => {
    getCustomFields(item, index, "salesgearField");
  };

  const handleDeleteMapping = index => {
    const fields = [...config?.fields];
    const { crmField = "", sgField = "" } = fields?.[index] || {};
    fields.splice(index, 1);
    setConfig({
      ...config,
      fields
    });
    setUpdatedMappingConfig(fields);
    setDisabledSgFields(disabledSgFields?.filter(item => item !== sgField));
    setDisabledCrmFields(disabledCrmFields?.filter(item => item !== crmField));
  };

  const addCbk = () => {
    const fields = [...config?.fields];
    const updatedFieldMapping = [
      ...fields,
      {
        crmField: "",
        sgField: "",
        syncDirection: "INBOUND",
        syncEnabled: false,
        dataType: "varchar"
      }
    ];
    setUpdatedMappingConfig(updatedFieldMapping);
    setConfig({
      ...config,
      fields: updatedFieldMapping
    });
  };

  const getAlreadyFilledValue = () => {
    setDisabledSgFields(config?.fields?.map(item => item?.sgField));
    setDisabledCrmFields(config?.fields?.map(item => item?.crmField));
  };

  const getFieldMappingConfig = () => {
    let tempCustomField = getCustomFieldsCRM(customFieldsCount);
    let tempConfig = {};
    tempCustomField?.length > 0 &&
      tempCustomField.map(item => {
        tempConfig = {
          ...tempConfig,
          [item.value]:
            settingsStoreData?.customFields?.[item?.value] || item?.name
        };
      });
    return { ...salesgearFieldMapping, ...tempConfig };
  };

  const constructCrmFields = () => {
    const updatedCrmOptions = currentFields?.fieldList?.map(item => {
      return {
        value: item?.crmField,
        name: item?.crmFieldLabel,
        disabled: disabledCrmFields?.includes(item?.crmField),
        dataType: item?.dataType
      };
    });
    setCrmOptionList(updatedCrmOptions);
  };

  const constructSalesgearContactFields = () => {
    return (
      (salesgearContactFields?.length > 0 &&
        salesgearContactFields.map(item => {
          item.disabled = disabledSgFields?.includes(item?.value);
          return item;
        })) ||
      []
    );
  };

  const constructCustomFieldsWithValue = () => {
    let tempContactFields = getCustomFieldsCRM(customFieldsCount);
    return (
      (tempContactFields?.length > 0 &&
        tempContactFields.map(item => {
          item.name =
            settingsStoreData?.customFields?.[item?.value] || item?.name;
          item.hasCustomFieldValue = settingsStoreData?.customFields?.[
            item?.value
          ]
            ? true
            : false;
          item.disabled = disabledSgFields?.includes(item?.value);
          return item;
        })) ||
      []
    );
  };

  useEffect(() => {
    config?.fields && getAlreadyFilledValue();
  }, [config?.fields]);

  useEffect(() => {
    constructCrmFields();
  }, [currentFields, disabledCrmFields]);

  return (
    <tbody>
      {updatedMappingConfig?.length > 0 &&
        updatedMappingConfig.map((item, index) => {
          const currentConfig = config?.fields?.[index];
          const {
            sgField: defaultSgField = "",
            syncDirection: defaultSyncDirection = ""
          } = item || {};
          let {
            sgField: currentSgField = "",
            crmFieldLabel: currentCrmFieldLabel = "",
            syncDirection: currentSyncDirection = "",
            dataType: currentDataType = ""
          } = currentConfig || {};
          const mappedFields = getFieldMappingConfig();
          const sgOptionList = [
            { value: "", name: "None" },
            ...constructSalesgearContactFields(),
            ...constructCustomFieldsWithValue()
          ];
          const crmFieldRestrictAccess =
            currentCrmFieldLabel === PIPEDRIVE_RESTRICT_DIRECTION ||
            !["varchar"]?.includes(currentDataType?.toLowerCase());
          currentSyncDirection =
            crmName === "Pipedrive"
              ? !crmFieldRestrictAccess
                ? currentSyncDirection
                : "INBOUND"
              : currentSyncDirection;

          return (
            <tr
              className="pipedriveFieldMapping"
              key={`${defaultSgField}-${Date.now()}-${index + 1}`}
              onMouseEnter={() => setDeleteIconIndex(index)}
              onMouseLeave={() => setDeleteIconIndex("")}
            >
              <td className="crmField" style={{ width: "33.3%" }}>
                <AutoCompleteDropdown
                  optionList={crmOptionList}
                  defaultValue={currentCrmFieldLabel}
                  cbk={customCrmFieldCbk}
                  index={`keyCrmField-${index + 1}`}
                  loader={!crmOptionList}
                  crm={true}
                />
              </td>
              <td className="sgField">
                <AutoCompleteDropdown
                  optionList={sgOptionList}
                  defaultValue={
                    currentSgField === ""
                      ? currentSgField
                      : mappedFields?.[currentSgField] ||
                        mappedFields?.[defaultSgField]
                  }
                  cbk={customSgFieldCbk}
                  index={`keySgField-${index + 1}`}
                  loader={!sgOptionList}
                  crm={true}
                />
              </td>
              <td className="syncDirectionField" style={{ width: "33.3%" }}>
                <SyncDirection
                  type={type}
                  index={index}
                  defaultSyncDirection={defaultSyncDirection}
                  currentSyncDirection={currentSyncDirection}
                  activeDropdownIndex={activeDropdownIndex}
                  setActiveDropdownIndex={setActiveDropdownIndex}
                  className={`${crmFieldRestrictAccess ? "disabled" : ""}`}
                  crmFieldRestrictAccess={!crmFieldRestrictAccess}
                  config={config}
                  setConfig={setConfig}
                />
              </td>
              <td style={{ width: "20px" }}>
                <i
                  className="material-icons deleteIcon"
                  style={{
                    display: deleteIconIndex === index ? "flex" : "none"
                  }}
                  onClick={() => handleDeleteMapping(index)}
                >
                  delete
                </i>
              </td>
            </tr>
          );
        })}
      <tr>
        <td>
          <AddItem addCbk={addCbk} name="Add" />
        </td>
      </tr>
    </tbody>
  );
});

const FieldMapping = observer(props => {
  const { mappingConfig = [], type = "CONTACTS", ...remainingProps } = props;

  return (
    <div className="fieldMappingWrapper">
      <h6 className="headingText">Field mapping</h6>
      <div className="fieldMappingTable tableView">
        <table>
          <TableHeader type={type} {...remainingProps} />
          <TableBody
            mappingConfig={mappingConfig}
            type={type}
            {...remainingProps}
          />
        </table>
      </div>
    </div>
  );
});

export { FieldMapping };
export default FieldMapping;
