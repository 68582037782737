import React from "react";
import { observer } from "mobx-react";
import { VariantToggle } from "Pages/Dashboard/sequences/SequencePage/VariantToggle";

const UpdateContacts = observer(props => {
  const {
    config = {},
    setConfig = () => {},
    crmName = "",
    hasConditions = false
  } = props || {};

  const isSalesforce = crmName?.toLowerCase() === "salesforce" && hasConditions;

  const updateContactAction = (event, value) => {
    setConfig({
      ...config,
      updateContact: value
    });
  };

  return (
    <div
      className={`sectionMarginBottom updateContactsWrapper ${
        config?.sourceId || isSalesforce ? "" : "disabled"
      }`}
    >
      <div className="flexColumn">
        <div className="updateContactsCont">
          <span className="headingText">Update Contacts</span>
          <VariantToggle
            action={updateContactAction}
            isChecked={config?.updateContact}
          />
        </div>
        <span className="headingSubText">
          Enable to update existing contacts in Salesgear.
        </span>
      </div>
    </div>
  );
});

export { UpdateContacts };
export default UpdateContacts;
