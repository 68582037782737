/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import CheckBox from "Components/common/CheckBox";
import Button from "Components/common/Button";
import SearchInputBox from "Components/common/SearchInputBox";
import { dataSearchState } from "../state";

const DropDownMenu = props => {
  const {
    className = "",
    filteredList = [],
    setFilteredList = () => {},
    options = [],
    handleSelectActionCbk = "",
    handleSubmenuSelectActionCbk = "",
    itemWithSubmenu,
    hasSubMenu,
    hasSubMenuAccordion = false,
    fromSearch = false
  } = props || {};

  const [openSubmenuIndexes, setOpenSubmenuIndexes] = useState([]);

  const handleSelectAction = (checked = "", uId = "", name = "", item) => {
    if (handleSubmenuSelectActionCbk && hasSubMenu) {
      handleSubmenuSelectActionCbk(checked, uId, name, item);
    } else if (handleSelectActionCbk) {
      handleSelectActionCbk(checked, uId, name);
    } else {
      let tempData = [...options];
      tempData = tempData.map(item => {
        const { id = "" } = item || {};
        if (id === uId) {
          item.checked = checked;
        }
        return item;
      });
      setFilteredList(tempData);
    }
  };

  const handleOpenCloseAcc = index => {
    const temp = [...openSubmenuIndexes];
    if (openSubmenuIndexes?.includes(index)) {
      const submenuIndex = openSubmenuIndexes.indexOf(index);
      temp.splice(submenuIndex, 1);
    } else {
      temp.push(index);
    }
    setOpenSubmenuIndexes(temp);
  };

  return (
    <div className={`dropdownList ${className}`}>
      {filteredList?.length > 0 &&
        filteredList?.map((item, index) => {
          const {
            id = "",
            value = "",
            checked = false,
            show = false,
            showSubmenu = false,
            submenu = [],
            dotColor = ""
          } = item || {};

          if (!openSubmenuIndexes?.includes(index)) {
            let expandSubmenu =
              !checked &&
              submenu?.length &&
              submenu?.filter(item => item.checked && item)?.length > 0;
            expandSubmenu && handleOpenCloseAcc(index);
          }

          if (typeof show === "string" || show) {
            return (
              <div className="dropdownItems" key={id + index}>
                <div
                  className={`flexCenter ${
                    id === "selectAll" ? "selectAll" : ""
                  }`}
                >
                  {hasSubMenuAccordion && id !== "selectAll" && (
                    <i
                      className="material-icons rightArrowIcon"
                      onClick={() => handleOpenCloseAcc(index)}
                    >
                      {openSubmenuIndexes?.includes(index) || fromSearch
                        ? "keyboard_arrow_down"
                        : "keyboard_arrow_right"}
                    </i>
                  )}
                  <CheckBox
                    name={value}
                    id={id}
                    dotColor={dotColor}
                    checked={checked}
                    cbk={handleSelectAction}
                    hasSubMenu={hasSubMenu}
                    itemWithSubmenu={itemWithSubmenu}
                  />
                </div>
                {showSubmenu &&
                  (openSubmenuIndexes?.includes(index) || fromSearch) && (
                    <DropDownMenu
                      className="subMenu"
                      filteredList={submenu}
                      options={submenu}
                      handleSubmenuSelectActionCbk={
                        handleSubmenuSelectActionCbk
                      }
                      hasSubMenu={true}
                      itemWithSubmenu={item}
                    />
                  )}
              </div>
            );
          }
        })}
    </div>
  );
};

const MultiSelectDropdownList = observer(props => {
  const {
    options = [],
    id = "",
    className = "",
    dropdownPositionStyle = {},
    showDropdown: showDropdownMenu = false,
    showSearchPanel = true,
    handleSelectActionCbk,
    handleSubmenuSelectActionCbk,
    setTableLoading = () => {},
    handleApplySelectionCbk,
    searchFilterCbk,
    hasSubMenuAccordion = false,
    setActiveIndex = () => {},
    resetDropdownToInitialState = () => {},
    resetFilterOptions = () => {},
    discoverPage = false
  } = props || {};

  const searchBoxRef = useRef(null);
  const [filteredList, setFilteredList] = useState(options);
  const [showDropdown, setShowDropdown] = useState(false);
  const [clearSearchInput, setClearSearchInput] = useState(false);
  const [fromSearch, setFromSearch] = useState(false);

  const searchFilterList = (value = "") => {
    if (searchFilterCbk) {
      setFromSearch(true);
      searchFilterCbk(value);
    } else {
      const lowercasedValue = value?.toLowerCase() || "";
      if (!lowercasedValue) {
        let temp = options?.map(item => {
          item.show = false;
          return item;
        });
        setFilteredList(temp);
      } else {
        let count = 0;
        const filteredData =
          options?.length > 0 &&
          options?.filter(item => {
            let val = item?.value
              ?.toString()
              ?.toLowerCase()
              ?.includes(lowercasedValue);
            val === false && count++;
            item["show"] = val;
            return item;
          });
        setFilteredList(filteredData);
      }
    }
  };

  const clearSearchCbk = () => {
    setClearSearchInput(true);
    setFilteredList(options);
    setFromSearch(false);
  };

  const handleApplySelectionButton = () => {
    setShowDropdown(false);
    setActiveIndex("");
    if (handleApplySelectionCbk) {
      handleApplySelectionCbk();
    } else {
      const updatedFilteredList = filteredList
        ?.map(item => {
          if (item.checked) {
            return item?.id;
          }
        })
        ?.filter(Boolean);
      if (id !== "team") {
        dataSearchState.setFilterComponentData({
          ...dataSearchState.filterComponentData,
          [id]: updatedFilteredList
        });
      }
    }
    setFromSearch(false);
    setTableLoading(true);
    resetDropdownToInitialState();
    if (id !== "team") {
      dataSearchState.setCurrentPage(1);
      dataSearchState.setIsFromSeoPage(false);
      dataSearchState.searchPageData();
    }
  };

  const resetState = () => {
    const temp = [...filteredList];
    temp.map(item => {
      if (id !== "team") {
        item.checked = dataSearchState.filterComponentData?.[id]?.includes(
          item?.id
        );
      }
      return item;
    });
    setFilteredList([...temp]);
    setShowDropdown(false);
    setClearSearchInput(true);
    setActiveIndex("");
    resetDropdownToInitialState();
    resetFilterOptions();
    setFromSearch(false);
  };

  const resetTrial = () => {
    const temp = [...filteredList];
    temp.map(item => {
      if (id !== "team") {
        item.checked = dataSearchState.filterComponentData?.[id]?.includes(
          item?.id
        );
      }
      return item;
    });
    setFilteredList([...temp]);
  };

  useEffect(() => {
    if (id !== "team") {
      resetState();
      dataSearchState.setClearAll(false);
    }
  }, [dataSearchState.clearAll]);

  useEffect(() => {
    if (discoverPage) {
      resetTrial();
    }
  }, [dataSearchState.filterComponentData]);

  useEffect(() => {
    if (options?.length > 0 && ["industry", "team"].includes(id)) {
      setFilteredList([...options]);
    }
  }, [options]);

  useEffect(() => {
    setShowDropdown(showDropdownMenu);
  }, [showDropdownMenu]);

  useEffect(() => {
    if (showDropdown) {
      let dropdownElement = document.getElementById("dropdownListWrapper");
      dropdownElement.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [showDropdown]);

  return (
    showDropdown && (
      <>
        <div
          className={`dropdownListWrapper ${className}`}
          style={dropdownPositionStyle}
          id="dropdownListWrapper"
        >
          {showSearchPanel && (
            <SearchInputBox
              valueCbk={searchFilterList}
              type="text"
              placeholder="Search"
              searchBoxRef={searchBoxRef}
              clearSearchInput={clearSearchInput}
              clearSearchCbk={clearSearchCbk}
            />
          )}
          <DropDownMenu
            options={options}
            filteredList={filteredList}
            setFilteredList={setFilteredList}
            handleSelectActionCbk={handleSelectActionCbk}
            handleSubmenuSelectActionCbk={handleSubmenuSelectActionCbk}
            hasSubMenuAccordion={hasSubMenuAccordion}
            fromSearch={fromSearch}
          />
          <Button
            id="applySelection"
            name="applySelection"
            type="button"
            className="applySelectionBtn"
            btnText="Apply"
            icon={false}
            loader={false}
            click={handleApplySelectionButton}
          />
        </div>
        {id !== "team" && (
          <div
            className="dropdownOverlay"
            onClick={() => {
              resetState();
            }}
          />
        )}
      </>
    )
  );
});

const CustomMultiSelect = observer(props => {
  return <MultiSelectDropdownList {...props} />;
});

export { CustomMultiSelect };
export default CustomMultiSelect;
