import { action, observable } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import userDetail from "Stores/userDetail";
import DashboardState from "../mainState";

let cancelActivityInsights = null;

class ReportsModuleState {
  @observable activityInsightData = null;
  @observable filterPayload = "yesterday";
  @observable activityInsightLoader = true;
  @observable activeActivityInsightTab = "7d";

  @action
  setFilterPayload = value => {
    this.filterPayload = value;
  };

  @action
  setActivityInsightLoader = value => {
    this.activityInsightLoader = value;
  };

  @action
  setActiveActivityInsightTab = value => {
    this.activeActivityInsightTab = value;
  };

  @action
  getActivityInsightsData = async (data = {}) => {
    try {
      const { memberId = "", team = false } = userDetail?.userInfo || {};
      if (DashboardState?.memberId?.length > 0) {
        data.memberIds = DashboardState.memberId;
      } else {
        if (!team) {
          data.memberIds = [memberId];
        }
      }
      const config = {
        url: URLS.getActivityInsights,
        method: "POST",
        data
      };

      if (cancelActivityInsights !== null) {
        cancelActivityInsights("cancel");
        cancelActivityInsights = null;
      }
      const cancelReqCallback = value => {
        cancelActivityInsights = value;
      };
      let res = await makeApi(config, cancelReqCallback);
      if (res?.data && Object.keys(res?.data).length > 0) {
        this.activityInsightData = res?.data;
      } else {
        this.activityInsightData = !res?.message ? [] : null;
      }
    } catch (err) {
      console.error("Get Report Error", err);
    }
  };
}

const reportsModuleState = new ReportsModuleState();
export { reportsModuleState };
export default reportsModuleState;
