export const TASK_FILTER_LIST = [
  {
    name: "Sequences",
    id: "sequenceIds",
    action: "autoCompleteDropdown"
  },
  {
    name: "Tags",
    id: "tagIds",
    action: "autoCompleteDropdown"
  },
  {
    name: "Contact Fields",
    id: "contactFields",
    action: "contactFields"
  },
  {
    name: "Contact Stages",
    id: "contactStage",
    action: "autoCompleteDropdown"
  }
];
