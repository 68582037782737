import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ReportsRouting from "Pages/Dashboard/reports_v2/ReportsRouting";
import TabNavigation from "Components/common/TabNavigation";
import FilterByDateTab from "Components/common/FilterByDateTab";
import UserDropdown from "Components/common/UserDropdown";
import { REPORTS_NAVIGATION } from "Static/constant";
import userDetail from "Stores/userDetail";
import DateUtils from "Utils/DateUtils";
import { USER_SELECT_DROPDOWN_LIST as userObjList } from "Static/constant";
import Utils from "Utils/utils";
import reportsStore from "Pages/Dashboard/reports_v2/reportsStore";
import SwitchReportsVersion from "./SwitchReportsVersion";

const ReportsBody = observer(() => {
  const {
    team = false,
    hasTeamMember = false,
    isTeamOwner = false,
    memberId = ""
  } = userDetail?.userInfo || {};
  const { noOfContactsAddedInTeam = false, newReportsEnabled = false } =
    userDetail?.userFeatureFlag || {};
  const [teamList, setTeamList] = useState("");
  const [teamLoader, setTeamLoader] = useState(true);

  const getFilterByDate = async (value = {}) => {
    const { filterFromDate = "", filterToDate = "" } = value || {};
    let fromDate = DateUtils.getTimeStamp(filterFromDate, "start") || "";
    let toDate = DateUtils.getTimeStamp(filterToDate, "end") || "";
    let dateFilter = { fromDate, toDate };
    reportsStore.setSortType("ASC");
    reportsStore.setSortParam("contactsAddedCount");
    reportsStore.setDateFilter(dateFilter);
    reportsStore.setPageLimit(10);
    reportsStore.setPageNo(1);
    reportsStore.setLoading(true);
    await reportsStore.fetchReportsData({}, reportsStore?.tabType);
    reportsStore.setLoading(false);
  };

  const getTeamList = async () => {
    setTeamLoader(true);
    const resData = await Utils.userTeamList();
    userDetail.setUserInfo({
      ...userDetail?.userInfo,
      hasTeamMember: resData?.length ? resData : false,
      teamList: resData
    });
    setTeamList(resData);
    setTeamLoader(false);
  };

  const selectedUserCbk = (value = "") => {
    if (value) {
      let tempArr = value || "";
      if (typeof value === "string") {
        if (value?.toLowerCase() === "you") {
          tempArr = new Array(memberId);
        } else if (value?.toLowerCase() === "all") {
          tempArr = [];
        }
      }
      reportsStore.setSortType("ASC");
      reportsStore.setSortParam("contactsAddedCount");
      reportsStore.setMemberIds(tempArr);
      reportsStore.setPageNo(1);
      reportsStore.setPageLimit(10);
    }
  };

  useEffect(() => {
    if (newReportsEnabled) {
      const timeoutId = setTimeout(() => {
        reportsStore.setNewVersionReportsFlag(true);
      }, 5000);
      getTeamList();
      reportsStore.setActiveDateFilter("month");
      return () => {
        clearTimeout(timeoutId);
        reportsStore.setNewVersionReportsFlag(false);
        reportsStore.setDateFilter(DateUtils.getMonthTimeStamp());
        reportsStore.setActiveDateFilter("month");
        reportsStore.resetReports();
      };
    }
  }, []);

  return (
    <div className="reportsBody">
      {reportsStore?.newVersionReportsFlag && <SwitchReportsVersion />}
      <div className="reportsHeaderCont">
        <TabNavigation tabList={REPORTS_NAVIGATION} activeTab={"activity"} />
        <div className="rightCont">
          <div className="filterDropdown">
            <UserDropdown
              team={team}
              teamList={teamList}
              teamLoader={teamLoader}
              selectedUserCbk={selectedUserCbk}
              isContactsAdded={noOfContactsAddedInTeam > 0}
              userFilterList={Utils.filterByUserTeamFilterList(userObjList)}
            />
          </div>
          <FilterByDateTab
            activeDueTab={reportsStore?.activeDateFilter}
            setActiveDueTab={reportsStore.setActiveDateFilter}
            cbk={getFilterByDate}
            isTaskPage={false}
            pageType="reports"
          />
        </div>
      </div>
      <ReportsRouting />
    </div>
  );
});

export default ReportsBody;
export { ReportsBody };
