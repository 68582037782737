import React from "react";
import { ReactComponent as DownArrow } from "Assets/svg/donw-arrow.svg";
import { toasterState } from "Components/common/toaster";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import Utils from "Utils/utils";

const StageRulesDropDown = props => {
  const {
    index = 0,
    activeIndex = 0,
    setActiveIndex = () => {},
    contactStages = [],
    setSelectedRules = () => {},
    selectedRules = {},
    currentRuleId = "",
    currentRule = "",
    freePlanCheck = false
  } = props || {};

  const handleAction = (event = "") => {
    if (event) {
      let id = Utils.performActionByClosestDom(event, "li", "data-key");
      if (id) {
        if (freePlanCheck) {
          upgradePopupState.setPopupType("stageRules");
          upgradePopupState.setPopup(true);
        } else {
          let existingStageCheck =
            id !== "notSet"
              ? Object.keys(selectedRules).some(item => {
                  return selectedRules[item] === id;
                })
              : "";
          if (!existingStageCheck || id === "notSet") {
            setActiveIndex(-1);
            setSelectedRules({ ...selectedRules, [currentRule]: id });
          } else {
            toasterState.setToastMsg(
              "Oops! Looks like this stage is already set for another rule. Please select another stage.",
              "failure"
            );
          }
        }
      }
    }
  };

  const displayStageName = (id = "") => {
    let value = "not set";
    if (id) {
      contactStages.forEach(element => {
        if (id === element?.id) {
          value = element?.displayName;
        }
      });
    }
    return value;
  };

  return (
    <>
      <div
        className="dropDownWithText"
        onClick={() => setActiveIndex(index === activeIndex ? -1 : index)}
      >
        <div className="wrapIconValue">
          <div
            className={`value ${
              displayStageName(currentRuleId) !== "not set" ? "active" : ""
            }`}
          >
            {displayStageName(currentRuleId)}
          </div>
          <div
            className={`arrow ${index === activeIndex ? "activeArrow" : ""}`}
          >
            <DownArrow />
          </div>
        </div>
        <DropdownBox
          contactStages={contactStages}
          index={index}
          currentRuleId={currentRuleId}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          handleAction={handleAction}
        />
      </div>
    </>
  );
};

const DropdownBox = ({
  contactStages = {},
  currentRuleId = "",
  index = 0,
  activeIndex = "",
  setActiveIndex = () => {},
  handleAction = () => {}
}) => {
  const positionObj = {
    top: `${index < 3 ? "32px" : "-200px"}`
  };

  let stageRuleList = [
    { id: "notSet", displayName: "Not Set" },
    ...contactStages
  ];

  return (
    index === activeIndex && (
      <>
        <ul
          className="srDropdown"
          style={positionObj}
          onClick={e => handleAction(e)}
        >
          {stageRuleList?.length > 0 &&
            stageRuleList.map(item => {
              const { id = "", displayName = "" } = item || {};
              return (
                <li
                  className={`srOption ${currentRuleId === id ? "active" : ""}`}
                  key={displayName}
                  data-key={id}
                >
                  {displayName}
                </li>
              );
            })}
        </ul>
        <div className="dropdownOverlay" onClick={() => setActiveIndex(-1)} />
      </>
    )
  );
};

export default StageRulesDropDown;
