import React from "react";
import {
  Switch,
  Redirect,
  Route,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import ReportSummary from "Pages/Dashboard/reports/ReportSummary";
import ReportsInsights from "Pages/Dashboard/reports/ReportsInsights";

const ReportTabRouting = () => {
  let { path = "" } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/summary`} component={ReportSummary} />
      <Route exact path={`${path}/insights`} component={ReportsInsights} />
      <Redirect from={path} to={`${path}/summary`} />
    </Switch>
  );
};

export default withRouter(ReportTabRouting);
export { ReportTabRouting };
