export const reportSummaryHeader = [
  {
    name: "Team Member",
    className: "teamMember",
    id: "teamMember"
  },
  {
    name: "Contacts Added",
    className: "contactsAdded",
    id: "contactsAdded"
  },
  {
    name: "Emails Sent",
    className: "emailsSent",
    id: "emailsSent"
  },
  {
    name: "Replies",
    className: "replyRate",
    id: "replyRate"
  },
  {
    name: "Calls Made",
    className: "callsMade",
    id: "callsMade"
  },
  {
    name: "Calls Connected",
    className: "callsConnected",
    id: "callsConnected"
  },
  {
    name: "Tasks Completed",
    className: "tasksCompleted",
    id: "tasksCompleted",
    subTitleClassName: "tasksCompletedSubSection"
  }
];
