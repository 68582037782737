/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import userDetail from "Stores/userDetail";
import TrialExpiredImage from "Assets/png/trialExpired.png";
import ButtonComponent from "Components/common/Button";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";

const UpgradeCard = props => {
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [pageType, setPageType] = useState("");
  const [darkOverlay, setDarkOverlay] = useState(false);

  const getContent = (page, show, overlay, count) => {
    switch (page) {
      case "sequences":
        setTitle(
          "Please upgrade to one of our premium plans to <br /> create more sequences"
        );
        break;
      case "templates":
        setTitle(
          "Please upgrade to one of our premium plans to <br /> create more templates"
        );
        break;
      case "contacts":
        if (count < 2) {
          setTitle(
            "Please upgrade to one of our premium plans to <br /> add more contacts"
          );
        } else {
          setTitle(
            `Based on your current plan, you will exceed the limit of ${count} contacts. Upgrade to add unlimited contacts`
          );
        }
        break;
      case "reports-admin":
        setTitle(
          "Please upgrade to one of our premium plans to <br /> get access to team performance report"
        );
        break;
      case "reports":
        setTitle(
          "Please upgrade to one of our premium plans to <br /> get access to team performance report"
        );
        break;
      case "task":
        setTitle(
          "Please upgrade to one of our premium plans to <br /> create and manage your tasks"
        );
        setDarkOverlay(true);
        break;
      case "team":
        setTitle(
          "Please upgrade to one of our premium plans to <br /> add more seats"
        );
        break;
      case "pause-resume":
        setTitle(
          "Please upgrade to one of our premium plans to continue using sequences"
        );
        break;
      default:
        break;
    }
    setShowOverlay(overlay);
    setShowUpgrade(show);
    Utils.mixpanelTrack(MP_EVENT.UPGRADE_POPUP_SHOWN, {
      triggerType: page,
      pageType: Utils.getPageType() || ""
    });
  };

  const upgradeToPlan = () => {
    if (!Utils.isAdmin()) {
      Utils.showMessagePopup();
    } else {
      setLoader(true);
      Utils.mixpanelTrack(MP_EVENT.UPGRADE_POPUP_CLICKED, {
        triggerType: pageType
      });
      pageType === "team" && hideUpgrade();
      props.history.push("/pricing");
    }
  };

  const hideUpgrade = () => {
    if (showOverlay) {
      userDetail.showUpgradePopup = false;
    }
  };

  useEffect(() => {
    if (userDetail.showUpgradePopup) {
      const { page, show, overlay, count = 0 } = userDetail.upgradePopup;
      setPageType(page);
      getContent(page, show, overlay, count);
    }
  }, [userDetail.showUpgradePopup, userDetail.userFeatureFlag.page]);

  return (
    <>
      {showUpgrade && (
        <>
          <div className="upgradePopupSection">
            <div className="upgradeCard animated customzoomIn">
              <div className="upgradeImg">
                <img src={TrialExpiredImage} alt="" />
              </div>
              <div className="upgradeBody">
                <div
                  className="upgradeTitle"
                  dangerouslySetInnerHTML={{
                    __html: "Oops, your trial has ended!" || ""
                  }}
                ></div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: title || ""
                  }}
                ></p>
                <div className="upgradeContentBody">
                  <ButtonComponent
                    id="upgradeBtnCard"
                    name="upgradeBtnCard"
                    btnText={"Upgrade"}
                    icon={false}
                    iconName=""
                    type="button"
                    className={loader ? "loading" : ""}
                    loader={loader}
                    click={e => {
                      e.preventDefault();
                      upgradeToPlan();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showOverlay && (
        <div className="overlayUpgradeMask" onClick={hideUpgrade}></div>
      )}
    </>
  );
};

export default withRouter(UpgradeCard);
