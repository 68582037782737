import React, { useState, useEffect, useRef } from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { tagsStoreData } from "Stores/TagsData";
import { Utils } from "Utils/utils";
import { Button } from "Components/common/Button";
import CommonPopup from "../../CommonPopup";
import SearchInputBox from "../../SearchInputBox";
import CheckBoxComponent from "../../CheckBox";
import SelectedTagList from "../SelectedTagList";
import { toasterState } from "Components/common/toaster";

class BulkAddTagsPopupState {
  @observable showPopup = false;
  @observable filteredData = [];
  @observable defaultValues = {};
  @observable buttonLoader = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setFilteredData(val) {
    this.filteredData = val;
  }

  @action
  setDefaultValues(val) {
    this.defaultValues = val;
  }

  @action
  setButtonLoader(val) {
    this.buttonLoader = val;
  }
}

const bulkAddTagsPopupState = new BulkAddTagsPopupState();

const BulkAddTagsPopup = observer((props = {}) => {
  const searchBoxRef = useRef(null);
  const [tagList, setTagList] = useState([]);
  const [renderArray, setRenderArray] = useState([]);
  const [showTagList, setShowTagList] = useState(false);
  const [clearSearchInput, setClearSearchInput] = useState(false);

  const closeIconCbk = () => {
    bulkAddTagsPopupState.setShowPopup(false);
    resetData();
  };

  const updateData = (list = [], tempRenderArray = []) => {
    setRenderArray(tempRenderArray?.length > 0 ? tempRenderArray : list);
    setTagList(list);
    searchBoxRef && searchBoxRef.current && searchBoxRef.current.focus();
  };

  const updateRemovedTag = removedItem => {
    const updatedTagList = Utils.createObjWithoutRef(tagList);
    updatedTagList?.length > 0 &&
      updatedTagList.map(
        item => item.value === removedItem && (item.checked = false)
      );
    updatedTagList && updateData(updatedTagList);
  };

  const updateTagCheckbox = (value, id, name) => {
    const newArr = Utils.createObjWithoutRef(tagList);
    const tempRenderArray = Utils.createObjWithoutRef(renderArray);
    if (newArr?.length > 0) {
      newArr.map(item => item?.id === id && (item.checked = value));
    }
    if (tempRenderArray?.length > 0) {
      tempRenderArray.map(item => item?.id === id && (item.checked = value));
    }
    newArr?.length > 0 && updateData(newArr, tempRenderArray);
    if (value === true) {
      bulkAddTagsPopupState.setFilteredData([
        ...bulkAddTagsPopupState.filteredData,
        name
      ]);
    } else {
      updateRemovedTag(name);
      const updatedFilteredData =
        (bulkAddTagsPopupState?.filteredData?.length > 0 &&
          bulkAddTagsPopupState?.filteredData.filter(
            filterData => filterData !== name
          )) ||
        [];
      bulkAddTagsPopupState.setFilteredData(updatedFilteredData);
    }
  };

  const onChangeSearchBox = inputValue => {
    if (!bulkAddTagsPopupState?.loader) {
      setShowTagList(true);
      const lowercasedValue = inputValue?.toLowerCase();
      setClearSearchInput(false);
      if (lowercasedValue) {
        const filteredData =
          tagList?.length &&
          tagList.filter(item => {
            return item["value"]
              ?.toString()
              ?.toLowerCase()
              ?.includes(lowercasedValue);
          });
        if (!filteredData?.length) {
          setRenderArray([
            { id: inputValue, value: inputValue, newOption: true }
          ]);
        } else {
          setRenderArray(filteredData);
        }
      } else {
        setRenderArray(tagList);
      }
    }
  };

  const getFilteredData = resData => {
    if (bulkAddTagsPopupState?.filteredData?.length > 0) {
      resData
        .map(item => {
          item.checked = bulkAddTagsPopupState?.filteredData?.includes(
            item.value
          );
          return item;
        })
        .filter(Boolean);
    }
    return resData;
  };

  const isDuplicateOption = (newOptionValue, tagList) => {
    let isDuplicateOption = false;
    const optionsList = tagList;
    optionsList.map(obj => {
      const { value = "" } = obj;
      if (value && newOptionValue && newOptionValue?.trim() === value) {
        isDuplicateOption = true;
      }
    });
    return isDuplicateOption;
  };

  const addNewOption = (id, value) => {
    if (id && value) {
      const tempObj = { id, value, checked: true, newOption: false };
      if (!isDuplicateOption(value, tagList)) {
        const optionsList = tagList;
        optionsList.unshift(tempObj);
        setClearSearchInput(true);
        bulkAddTagsPopupState.setFilteredData([
          ...bulkAddTagsPopupState?.filteredData,
          value
        ]);
        updateData(optionsList);
      }
    }
  };

  const handleUpdateAddTags = () => {
    bulkAddTagsPopupState.setButtonLoader(true);
    bulkAddTagsPopupState.defaultValues.cbk(resetData);
  };

  const HeaderChildren = () => {
    const contactsCount = bulkAddTagsPopupState?.defaultValues?.contactsCount;
    return (
      <div className="commonPopupHeader">
        <div className="flexRow">
          <h4 className="commonPopupHeading">Add Tags</h4>
          {contactsCount > 0 &&
            !bulkAddTagsPopupState?.defaultValues?.isContactCard && (
              <div className="contactsCount">{`${contactsCount} ${
                contactsCount > 1 ? "contacts" : "contact"
              } selected`}</div>
            )}
        </div>
        <i
          className="material-icons commonPopupCloseIcon"
          onClick={closeIconCbk}
        >
          close
        </i>
      </div>
    );
  };

  const resetData = () => {
    const updatedTagList = Utils.createObjWithoutRef(tagList);
    updatedTagList?.length > 0 &&
      updatedTagList.map(item => (item.checked = false));
    updateData(updatedTagList);
    bulkAddTagsPopupState.setFilteredData([]);
  };

  useEffect(() => {
    const data = Utils.createObjWithoutRef(tagsStoreData?.tagsData);
    const response = getFilteredData(data);
    response && updateData(response);
  }, [tagsStoreData.tagsData]);

  return (
    bulkAddTagsPopupState?.showPopup && (
      <CommonPopup
        className="bulkAddTagsPopup"
        headerTitle="Add Tags"
        closeIconCbk={closeIconCbk}
        HeaderChildren={HeaderChildren}
        hasHeaderChildren={true}
      >
        <div className="descriptionMsg">
          Please select/create tags that you would like to add to{" "}
          <span
            className={`${
              bulkAddTagsPopupState?.defaultValues?.contactsCount === 1
                ? "contactName"
                : ""
            }`}
          >
            {bulkAddTagsPopupState?.defaultValues?.contactsCount === 1
              ? bulkAddTagsPopupState?.defaultValues?.name
              : "to the selected contacts."}
          </span>
        </div>
        <div className={`inputContainer`}>
          <span className="tagTitle">Enter your tags name</span>
          <div className="searchFilterWrapper">
            <SearchInputBox
              valueCbk={onChangeSearchBox}
              type="text"
              text="Enter your tags name"
              hasSearchIcon={false}
              hasCloseIcon={false}
              className="inputFilterSearch"
              clearSearchInput={clearSearchInput}
              searchBoxRef={searchBoxRef}
              setShowTagList={setShowTagList}
              placeholder=""
            />

            {showTagList && renderArray?.length > 0 && (
              <div className={`tagList`}>
                {renderArray?.length > 0 &&
                  renderArray.map((item, index) => {
                    const {
                      id = "",
                      value = "",
                      checked = false,
                      newOption = false
                    } = item || {};
                    return (
                      <div className="tagItem" key={id + index}>
                        {newOption ? (
                          <span
                            className="newOption"
                            onClick={e => addNewOption(id, value)}
                          >
                            Add
                            <span className="newOptionValue">{value}</span>
                          </span>
                        ) : (
                          <CheckBoxComponent
                            key={id}
                            name={
                              value
                                ? value
                                : Utils.getFullNameFromUserInfo(item)
                            }
                            id={id}
                            checked={checked}
                            cbk={updateTagCheckbox}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
        {bulkAddTagsPopupState?.filteredData?.length > 0 && (
          <div className="title">Tags</div>
        )}
        <SelectedTagList
          selectedList={bulkAddTagsPopupState?.filteredData}
          store={bulkAddTagsPopupState}
          searchBoxRef={searchBoxRef}
          updateRemovedTag={updateRemovedTag}
        />

        <Button
          type="submit"
          btnText="Add tags"
          className={`${
            bulkAddTagsPopupState?.filteredData?.length ? "" : "disabled"
          } positive-btn  updateButton ${
            bulkAddTagsPopupState?.buttonLoader ? "loading" : ""
          }`}
          click={
            bulkAddTagsPopupState?.filteredData?.length
              ? handleUpdateAddTags
              : () => {}
          }
          loader={bulkAddTagsPopupState?.buttonLoader}
        />
        {showTagList && (
          <div
            className="transparentOverlay"
            onClick={() => {
              setShowTagList(false);
            }}
          ></div>
        )}
      </CommonPopup>
    )
  );
});

export { BulkAddTagsPopup, bulkAddTagsPopupState };
export default BulkAddTagsPopup;
