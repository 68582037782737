import React from "react";
import LinkIcon from "Assets/png/link.png";
import Button from "Components/common/Button";

const EmptyCustomUrl = props => {
  const { showAddPopup } = props;

  return (
    <div className="emptyCustomUrlWrapper">
      <div className="emptyCustomUrlCont">
        <div className="imageCont">
          <img src={LinkIcon} alt="Add Link"></img>
        </div>
        <Button
          id="addCustomUrlBtn"
          name="addCustomUrlBtn"
          btnText="+ Add Custom URL"
          icon={false}
          iconName=""
          type="button"
          className="addCustomUrlBtn"
          loader={false}
          click={showAddPopup}
        />
        <p className="cutomParaText">
          Custom URLs allow users to personalize unsubscribe links, open and
          click-tracking links, and match the organization's brand.
        </p>
      </div>
    </div>
  );
};

export default EmptyCustomUrl;
export { EmptyCustomUrl };
