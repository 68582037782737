import React, { useEffect } from "react";
import { observer } from "mobx-react";
import dialerStoreData from "Stores/DialerData";
import StageCards from "./StageCards";
import CardsLoader from "./CardsLoader";
import CreateNewStage from "./CreateNewStage";

const Main = observer(() => {
  useEffect(() => {
    dialerStoreData.getCallStageList();
    return () => {
      dialerStoreData.setResetStage();
    };
  }, []);

  return (
    <div className="callDispostionSection">
      <div className="callStageCardList">
        {dialerStoreData.stageLoader ? (
          <CardsLoader length={10} />
        ) : (
          <>
            {dialerStoreData?.stageList?.length > 0 && (
              <StageCards stageList={dialerStoreData?.stageList} />
            )}
            {dialerStoreData?.singleStageLoader && <CardsLoader length={1} />}
            <CreateNewStage stageList={dialerStoreData?.stageList} />
          </>
        )}
      </div>
    </div>
  );
});

export default Main;
