/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { observer } from "mobx-react";
import KnowMoreButton from "Components/common/KnowMoreButton";
import Button from "Components/common/Button";
import { googleOAuthPopupState } from "../googleOAuthPopup";
import InboxImage from "../InboxImage";
import { OAuthScreenConfig } from "./googleOAuthConfig";
import CopyContent from "./CopyContent";
import AppPassword from "./AppPasswordScreen";
import {
  WARM_REDIRECT_URL,
  WARMING_CLIENT_ID,
  WARMING_AUTH_SCOPES
} from "Static/constant";
import { toasterState } from "Components/common/toaster";
import AppPasswordCredentials from "./AppPasswordCredentials";
import connectedInboxStore from "../store";
import InitialScreen from "./InitialScreen";

const OAuthMethod = observer(() => {
  const openGoogleAuth = async () => {
    let gmailAuthScope = WARMING_AUTH_SCOPES;
    const randomNum = Math.floor(Date.now() / 1000);
    localStorage.setItem(
      "inboxID_random_sg",
      `${randomNum}_${googleOAuthPopupState?.inboxData?.id}`
    );
    const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${WARMING_CLIENT_ID}&scope=${gmailAuthScope}&access_type=offline&response_type=code&redirect_uri=${WARM_REDIRECT_URL}&state=${randomNum}_${googleOAuthPopupState?.inboxData?.id}&login_hint=${googleOAuthPopupState?.inboxData?.email}&hd=*`;
    const w = 600;
    const h = 800;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
    window.open(authUrl, "Salesgear", params);
    const oauthCbk = async (ev = {}) => {
      if (ev?.data?.type === "GOOGLE_WARM_INBOX") {
        handleGoogleOauthCbk(ev?.data?.code?.toString());
        window.removeEventListener("message", oauthCbk);
      }
    };
    window.addEventListener("message", oauthCbk);
  };

  const handleGoogleOauthCbk = async (cbkCode = "") => {
    let tempScreenType = "one";
    if (cbkCode === "200") {
      if (connectedInboxStore?.inboxScreentype === "one") {
        connectedInboxStore.getAccountDetails(false, true);
      } else {
        connectedInboxStore.setInboxScreenType("two");
        tempScreenType = "two";
      }
      const warmingCommunityMsgShown =
        await connectedInboxStore.getWarmingCommunityMsgData();
      if (warmingCommunityMsgShown) {
        connectedInboxStore.enableOrDisableWarming(
          googleOAuthPopupState?.inboxData,
          tempScreenType
        );
        googleOAuthPopupState.setShowPopup(false);
        googleOAuthPopupState.setScreenNo("one");
        googleOAuthPopupState.setConnectConfig("oAuth");
        connectedInboxStore.setFirstTimeEnableWarming(false);
      } else {
        googleOAuthPopupState.setScreenNo("");
        connectedInboxStore.setFirstTimeEnableWarming(true);
      }
    } else {
      googleOAuthPopupState.setShowPopup(false);
      googleOAuthPopupState.setScreenNo("one");
      googleOAuthPopupState.setConnectConfig("oAuth");
      if (cbkCode === "412") {
        toasterState.setToastMsg(
          "Please connect the same email account",
          "failure"
        );
      } else if (cbkCode === "413") {
        toasterState.setToastMsg(
          `Oops! You cannot connect personal email addresses.`,
          "fail"
        );
      } else if (cbkCode === "409") {
        toasterState.setToastMsg("Account already connected", "failure");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  };

  const clickLoginBtn = () => {
    openGoogleAuth();
  };

  const btnProps = [
    {
      id: "back",
      name: "backBtn",
      icon: false,
      type: "button",
      className: "backBtn",
      loader: false,
      btnText: "Back",
      click: () => {
        googleOAuthPopupState.setScreenNo("one");
        googleOAuthPopupState.setConnectConfig("oAuth");
      }
    },
    {
      id: "loginBtn",
      name: "loginBtn",
      icon: false,
      type: "button",
      className: "loginBtn",
      loader: false,
      btnText: "Login",
      click: () => clickLoginBtn()
    }
  ];
  return (
    <>
      <div className="initialScreenBody">
        <ol>
          {OAuthScreenConfig.map((item, index) => {
            const {
              subLabel = false,
              content = "",
              subLabelContent = "",
              hasCopyContent = false
            } = item || {};
            return (
              <li key={index}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                {subLabel && (
                  <div
                    className="subLabelContent"
                    dangerouslySetInnerHTML={{ __html: `(${subLabelContent})` }}
                  />
                )}
                {hasCopyContent && <CopyContent copyData={WARMING_CLIENT_ID} />}
              </li>
            );
          })}
        </ol>
        {/* <div className="tutorialLink">
          <KnowMoreButton text="Show me how" redirectUrl="" />
        </div> */}
      </div>
      <div className="oAuthFooter">
        <div className="actionBtns">
          {btnProps.map((item, index) => {
            return <Button {...item} key={index} />;
          })}
        </div>
      </div>
    </>
  );
});

const ConnectMethod = observer(() => {
  const closeAction = () => {
    googleOAuthPopupState.setShowPopup(false);
    googleOAuthPopupState.setScreenNo("one");
    googleOAuthPopupState.setAppPasswordScreen("one");
    googleOAuthPopupState.setConnectConfig("oAuth");
  };

  return (
    <>
      <div className="cardHeader OAuthPopupHeader">
        <InboxImage type="google" />
        {googleOAuthPopupState?.connectConfig === "oAuth" && (
          <span>Allow Salesgear to access your Google workspace</span>
        )}
        {googleOAuthPopupState?.connectConfig === "appPassword" && (
          <span>Enable 2-step verification & generate App password</span>
        )}
        <div
          className="OAuthPopupClose"
          title="Click here to close"
          onClick={closeAction}
        >
          <i className="material-icons">close</i>
        </div>
      </div>
      <div className="cardBody OAuthPopupBody">
        {googleOAuthPopupState?.connectConfig === "oAuth" && <OAuthMethod />}
        {googleOAuthPopupState?.connectConfig === "appPassword" &&
          (googleOAuthPopupState?.appPasswordScreen === "one" ? (
            <InitialScreen />
          ) : googleOAuthPopupState?.appPasswordScreen === "two" ? (
            <AppPassword />
          ) : (
            <AppPasswordCredentials />
          ))}
      </div>
    </>
  );
});

export { ConnectMethod };
export default ConnectMethod;
