import React from "react";
import Button from "Components/common/Button";

const FormActionButton = props => {
  const { cancel = () => {}, save = () => {}, loader = false } = props;

  const cancelTrigger = () => cancel();
  const createTrigger = () => save();

  const buttonList = [
    {
      id: "triggerBackBtn",
      name: "triggerBackBtn",
      btnText: "Back",
      type: "button",
      className: `triggerBackBtn`,
      click: cancelTrigger
    },
    {
      id: "saveTrigger",
      name: "saveTrigger",
      btnText: "Save",
      type: "button",
      className: `saveTrigger ${loader ? "loading" : ""}`,
      click: createTrigger,
      loader
    }
  ];

  return (
    <div className="formInputBtnAction">
      {buttonList?.map((item, index) => (
        <Button key={`triggerAction${index}`} {...item} />
      ))}
    </div>
  );
};

export default FormActionButton;
export { FormActionButton };
