import React, { useState } from "react";
import Tooltip from "Components/common/CustomTooltip";

export const ActionIcon = (props = {}) => {
  const { id = "", iconName = "", type = "", title = "" } = props || {};

  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <span
      className="actionIconTooltip"
      onMouseOver={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      <i
        className="material-icons-outlined actionIcon"
        id={id}
        data-type={type}
      >
        {iconName}
      </i>
      {showToolTip && <Tooltip text={title} />}
    </span>
  );
};

export default ActionIcon;
