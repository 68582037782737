import React from "react";

const CustomRadioButton = (props = {}) => {
  const {
    name = "",
    id = "",
    value = "",
    defaultChecked = false,
    disable = false,
    radioOnchange = () => {},
    checked = false
  } = props || {};

  const radioInputObj = {
    type: "radio",
    name: name,
    id: id,
    onChange: e => {
      !disable && radioOnchange(e, id);
    },
    className: "radioBtn",
    disable: disable,
    checked: checked
  };

  return (
    <div className="radioBtnCont">
      <div className="radioButtonCont">
        <input {...radioInputObj} />
        <span className="contentClass" />
        <label htmlFor={id}>{value}</label>
      </div>
    </div>
  );
};

export default CustomRadioButton;
