import React, { useEffect } from "react";
import { slackNotificationState } from "./CreateSlackNotification";

const CreateSlackTriggerPopup = props => {
  const {
    actionsArray = [],
    change = () => {},
    index = 0,
    defaultValue = "",
    triggerType = ""
  } = props || {};

  const indexValue = index?.split("-")[1];

  const triggerCbk = (
    triggerTaskData = "",
    setLoaderCbk = () => {},
    btnType = ""
  ) => {
    let tempList = [...actionsArray];
    if (btnType?.toLowerCase() === "save") {
      tempList[indexValue - 1] = {
        actionType: "SLACK_NOTIFICATION",
        slackMessage: triggerTaskData
      };
    } else {
      if (defaultValue === "" || triggerTaskData !== "") {
        tempList[indexValue - 1].actionType = "";
      }
    }
    change("actions", tempList);
    setLoaderCbk();
    slackNotificationState.setShowPopup(false);
  };

  const commonPopupValues = {
    pageSource: "Triggers",
    isFromTriggers: true,
    triggerCbk: triggerCbk
  };

  const handleTriggerEdit = () => {
    const actionList = actionsArray[indexValue - 1];
    if (defaultValue === "") {
      slackNotificationState.setPopupValues({
        ...commonPopupValues
      });
    } else {
      slackNotificationState.setPopupValues({
        ...commonPopupValues,
        slackMessage: actionList?.slackMessage
      });
    }
    slackNotificationState.setShowPopup(true);
  };

  useEffect(() => {
    if (defaultValue === "") {
      slackNotificationState.setPopupValues({
        ...commonPopupValues,
        triggerType: triggerType
      });
      slackNotificationState.setShowPopup(true);
    }
  }, []);

  return (
    <div className="slackValue" onClick={handleTriggerEdit}>
      {defaultValue && <div className="labelValue">View Message</div>}
    </div>
  );
};

export { CreateSlackTriggerPopup };
export default CreateSlackTriggerPopup;
