import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { TabNavigation } from "../common/TabNavigation";
import ContactsTab from "./Contacts";
import OwnersTab from "../common/Owners";
import { pipedriveStore } from "./PipedriveStore";
import ActivityTab from "./ActivityTab";

const PipedriveTabFields = observer(props => {
  const {
    className = "",
    crmSettings = {},
    constructPipedriveConfig = () => {}
  } = props || {};
  const [activeTab, setActiveTab] = useState("activity");
  const [previousTab, setPreviousTab] = useState(activeTab);
  let location = useLocation(),
    history = useHistory(),
    hashValue = location?.hash;
  let hashUrlArr = ["#activity", "#contacts", "#owners"];

  const tabList = [
    { value: "Activity", id: "activity", path: "activity", active: true },
    {
      value: "Contacts",
      id: "contacts",
      path: "contacts"
    },
    {
      value: "Owners",
      id: "owners",
      path: "owners"
    }
  ];

  const compareFields = (oldField = {}, newField = {}) => {
    let hasChange = false;
    if (oldField?.length !== newField?.length) {
      hasChange = true;
      return hasChange;
    } else {
      for (let i = 0, len = oldField.length; i < len; i++) {
        const {
          crmField: oldCrmField = "",
          sgField: oldSgField = "",
          syncDirection: oldSyncDirection = ""
        } = oldField[i] || {};
        const {
          crmField: newCrmField = "",
          sgField: newSgField = "",
          syncDirection: newSyncDirection = ""
        } = newField[i] || {};
        if (oldCrmField === newCrmField) {
          if (
            oldSgField !== newSgField ||
            oldSyncDirection !== newSyncDirection
          ) {
            hasChange = true;
            return hasChange;
          }
        } else {
          hasChange = true;
          return hasChange;
        }
      }
    }
    return hasChange;
  };

  const checkForChanges = (oldData = {}, newData = {}) => {
    let hasChange = false;
    const oldDataTags = oldData?.tags || [];
    const newDataTags = newData?.tags || [];
    if (
      oldData?.autoSyncEnabled !== newData?.autoSyncEnabled ||
      oldData?.sourceId !== newData?.sourceId ||
      oldData?.updateContact !== newData?.updateContact ||
      JSON.stringify(oldData?.pollingInterval) !==
        JSON.stringify(newData?.pollingInterval) ||
      compareFields(oldData?.fields, newData?.fields) ||
      JSON.stringify(oldDataTags?.sort()) !==
        JSON.stringify(newDataTags?.sort())
    ) {
      hasChange = true;
      return hasChange;
    }
    return hasChange;
  };

  const handleTabNavigation = (id, cbkVal = false) => {
    if (cbkVal) {
      setActiveTab(id);
    } else {
      const cbkValue = getActiveTab(id);
      cbkValue && setActiveTab(id);
    }
    history.push(`#${id}`);
  };

  const confirmationCbk = activeTab => {
    const { config: { contact = {} } = {} } = crmSettings || {};
    setActiveTab(activeTab);
    setPreviousTab(activeTab);
    confirmationPopupState.setShowPopup(false);
    pipedriveStore.setPipedriveContactConfig(contact);
    pipedriveStore.setPipedriveContactTags([...(contact?.tags || [])]);
    handleTabNavigation(activeTab, true);
  };

  const getActiveTab = activeTab => {
    let hasChange = false;
    if (previousTab !== activeTab) {
      const payload = constructPipedriveConfig();
      const { config: { contact: payloadContact = {} } = {} } = payload || {};
      const { config: { contact = {} } = {} } = crmSettings || {};

      if (previousTab === "contacts") {
        hasChange = checkForChanges(contact, payloadContact);
      }
      if (hasChange) {
        confirmationPopupState.setPopupValues({
          content:
            "Are you sure you want to switch tab ?<br>  Any changes made will be lost.",
          actionBtnText: "Ok",
          callback: () => {
            confirmationCbk(activeTab);
          }
        });
        confirmationPopupState.setShowPopup(true);
        return false;
      } else {
        setActiveTab(activeTab);
        setPreviousTab(activeTab);
        return true;
      }
    }
  };

  useEffect(() => {
    const updatedHashValue = hashValue.includes("?")
      ? hashValue?.split("?")?.[0]
      : hashValue;
    if (hashUrlArr?.includes(updatedHashValue)) {
      setActiveTab(updatedHashValue.replace("#", ""));
    }
  }, []);

  useEffect(() => {
    if ("contacts"?.includes(activeTab)) {
      pipedriveStore.getAllPipedriveFields();
    }
  }, [activeTab]);

  return (
    <div className={`crmWrapper ${className}`}>
      <div className="tabFieldsWrapper">
        <TabNavigation
          tabList={tabList}
          handleTabNavigation={handleTabNavigation}
          activeTab={activeTab}
        />
      </div>
      {activeTab === "contacts" && (
        <ContactsTab
          contact={Utils.createObjWithoutRef(crmSettings?.config?.contact)}
        />
      )}
      {activeTab === "owners" && (
        <OwnersTab
          crmName="Pipedrive"
          ownersList={pipedriveStore?.pipedriveOwnersList}
          setOwnersList={pipedriveStore?.setPipedriveOwnersList}
          config={pipedriveStore?.pipedriveOwnerConfig}
          setConfig={pipedriveStore?.setPipedriveOwnerConfig}
          getAllOwners={pipedriveStore?.getAllOwners}
        />
      )}
      {activeTab === "activity" && <ActivityTab />}
    </div>
  );
});

export { PipedriveTabFields };
export default PipedriveTabFields;
