import React from "react";
import { Switch, Redirect, Route, withRouter } from "react-router-dom";
import AccountsList from "Pages/Dashboard/Accounts/AccountsList";
import AccountDetailedView from "Pages/Dashboard/Accounts/AccountDetailedView";

const AccountsRouting = () => {
  return (
    <Switch>
      <Route path="/accounts/:accountId" component={AccountDetailedView} />
      <Route exact path="/accounts" component={AccountsList} />
      <Redirect from="/accounts" to="/accounts" />
    </Switch>
  );
};

export default withRouter(AccountsRouting);
