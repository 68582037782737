import React from "react";
import { SVG_ICON } from "./svgIcon";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const MAIL_STATUS_CONFIG = {
  sent: {
    svg: SVG_ICON?.sentTrack,
    color: "#00d2ad"
  },
  opened: {
    svg: SVG_ICON?.openTrack,
    color: "#0177ff"
  },
  clicked: {
    svg: SVG_ICON?.clickTrack,
    color: "#ffcc3f"
  },
  replied: {
    svg: SVG_ICON?.replyTrack,
    color: "#ff8236"
  },
  received_email: {
    svg: SVG_ICON?.replyTrack,
    color: "#ff8236"
  },
  bounced: {
    svg: SVG_ICON?.bouncedTrack,
    color: "#545864"
  },
  optout: {
    svg: SVG_ICON?.optedOutTrack,
    color: "#ff2b66"
  },
  opted_out: {
    svg: SVG_ICON?.optedOutTrack,
    color: "#ff2b66"
  },
  meeting_booked: {
    svg: SVG_ICON?.meetingBooked,
    color: "#272056"
  },
  li_message_sent: {
    svg: SVG_ICON?.sentTrack,
    color: "#0a66c2"
  },
  li_connection_req_sent: {
    svg: SVG_ICON?.liCrSent,
    color: "#FF772F"
  },
  li_connection_req_accepted: {
    svg: SVG_ICON?.liCrAccepted,
    color: "#7F56D9"
  },
  li_replied: {
    svg: SVG_ICON?.liReplied,
    color: "#3953fb"
  }
};

export const DROPDOWN_LIST = [
  {
    key: "unopened",
    label: "Not Opened"
  },
  {
    key: "opens",
    label: "Opens"
  },
  {
    key: "clicks",
    label: "Clicks"
  },
  {
    key: "replied",
    label: "Replied"
  },
  {
    key: "bounced",
    label: "Bounced"
  },
  {
    key: "optedOut",
    label: "Opted Out"
  }
];
