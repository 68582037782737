import React from "react";
import ConstantObj from "Static/constant";
import ChangeShareType from "Components/common/ChangeShareType";

const CloneTemplate = (props = {}) => {
  const {
    cloneTemplate,
    focusOnInput,
    changeOnTemplateName,
    cancelTemplate,
    templateName,
    radioOnchangeAction,
    showCreateSeqLoader,
    templateShareType = ""
  } = props || {};

  return (
    templateShareType && (
      <ChangeShareType
        save={cloneTemplate}
        seqNameFocus={focusOnInput}
        sequenceNameAction={changeOnTemplateName}
        seqNameCancelAction={cancelTemplate}
        sequenceShareType={
          templateShareType === "PRIVATE"
            ? ConstantObj.sequenceType.private
            : ConstantObj.sequenceType.shared.type
        }
        sequenceAccessType={
          templateShareType === "PRIVATE"
            ? ConstantObj.sequenceType.shared.rw
            : templateShareType || ConstantObj.sequenceType.shared.rw
        }
        radioOnchangeAction={radioOnchangeAction}
        showCreateSeqLoader={showCreateSeqLoader}
        seqName={templateName || "New Template"}
        placeHolder={"Template Name"}
        isClone={true}
      />
    )
  );
};

export default CloneTemplate;
export { CloneTemplate };
