import React from "react";
import Utils from "Utils/utils";

export const CustomSingleDrodown = props => {
  const {
    options = [],
    sequenceId = "",
    parentIndex = "",
    loader = false,
    handleSequenceAction = () => {},
    handleScrollEnd = () => {}
  } = props || {};

  const changeAction = (event, item = {}) => {
    event && Utils.preventDefaultFn(event);
    handleSequenceAction(parentIndex, item);
  };

  return loader ? (
    <CustomDropdownLoader />
  ) : (
    <div className={`drop-down-menu animatedFast`}>
      <ul className="dropdownList" onScroll={event => handleScrollEnd(event)}>
        {options?.length ? (
          options?.map((item, index) => {
            const {
              id = "",
              name = "",
              email = "",
              key = "",
              shareType = "",
              accessType = "",
              owner = {},
              memberId = "",
              Icon = <></>,
              hasIcon = false,
              disabled = false
            } = item || {};
            return (
              <li
                key={`menu_${index}`}
                id={key}
                onClick={disabled ? () => {} : e => changeAction(e, item)}
                className={`option ${
                  sequenceId === id ? "sequenceSelected" : ""
                } ${disabled ? "disabled" : ""}`}
                title={name}
              >
                <div className="listItem">
                  <div className="listItemWrap">
                    {hasIcon && Icon && (
                      <span className="itemIcon">
                        <Icon />
                      </span>
                    )}
                    <span className="itemName">
                      {Utils.getEntityName(
                        shareType,
                        accessType,
                        owner,
                        name || email,
                        memberId
                      )}
                    </span>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <span className="noSequencesMsg">No sequence found</span>
        )}
      </ul>
    </div>
  );
};

const CustomDropdownLoader = () => {
  return (
    <div className={`drop-down-menu`}>
      <ul className="dropdownList">
        {Array.from({ length: 10 }, (value, index) => (
          <li className="option optionLoader" key={`${index}`}>
            <div className="listItem linear-background" />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomSingleDrodown;
