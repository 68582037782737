import React, { useRef } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { calendarBookMeetingState } from "../State";
import DateUtils from "Utils/DateUtils";

export const EventsList = observer((props = {}) => {
  const {
    eventsList = {},
    inputValue = "",
    eventLoader = false,
    eventPageLoader = false,
    setEventPageLoader = () => {},
    setSlotLoader = () => {},
    setInputValue = () => {},
    showGuestInput = false
  } = props || {};

  const eventsListRef = useRef(null);

  const handleAction = (event, item = {}) => {
    Utils.preventDefaultFn(event);
    calendarBookMeetingState.setSlots(null);
    setSlotLoader(true);
    setInputValue({
      ...inputValue,
      ...{
        ["event"]: item,
        date: new Date(),
        description: "",
        guests: []
      }
    });
  };

  const handleScroll = () => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      clientHeight = 0
    } = eventsListRef?.current;
    if (
      !eventLoader &&
      !eventPageLoader &&
      scrollHeight - scrollTop - 10 < clientHeight &&
      eventsList.currentPage < eventsList.totalPages
    ) {
      setEventPageLoader(true);
      calendarBookMeetingState.getCalendarEventsList(
        {
          pageNo: eventsList.currentPage + 1,
          limit: 10
        },
        () => {
          setEventPageLoader(false);
        }
      );
    }
  };

  return (
    <div
      className="eventsListSection"
      onScroll={() => {
        !showGuestInput && handleScroll();
      }}
      ref={eventsListRef}
    >
      {!eventLoader ? (
        eventsList?.list?.length > 0 ? (
          <ul className="eventsList">
            {!showGuestInput ? (
              <>
                {eventsList.list.map(item => {
                  const { id = "", name = "Demo", duration = "" } = item || {};
                  return (
                    <li
                      className={`eventOption ${
                        id === inputValue?.event?.id ? "active" : ""
                      }`}
                      key={id}
                      data-key={id}
                      onClick={e => handleAction(e, item)}
                    >
                      <div className="name">{name}</div>
                      <div className="duration">
                        <span className="material-icons-outlined scheduleIcon">
                          schedule
                        </span>
                        <span className="time">{duration} mins</span>
                      </div>
                    </li>
                  );
                })}
                {eventPageLoader &&
                  Array.from({ length: 10 }, (value, index) => (
                    <li
                      className="eventOption"
                      key={`eventOptionLoader${index}`}
                    >
                      <div className="name linear-background" />
                      <div className="duration">
                        <span className="material-icons-outlined scheduleIcon linear-background" />
                        <span className="time linear-background" />
                      </div>
                    </li>
                  ))}
              </>
            ) : (
              <li
                className={`eventOption selectedEvent`}
                key={`${inputValue?.event?.id}Active`}
                data-key={`${inputValue?.event?.id}Active`}
              >
                <div className="name">{inputValue?.event?.name}</div>
                <div className="meetingDuration">
                  <i className="material-icons-outlined">schedule</i>
                  <span className="text">
                    {DateUtils.getTimeByDurationDiff(
                      inputValue?.slotDateTime,
                      inputValue?.event?.duration,
                      inputValue?.timeZone
                    )}
                    ,
                    <br />
                    {DateUtils.getDateFullFormat(inputValue?.date)}
                  </span>
                </div>
                <div className="timezoneInfo">
                  <i className="material-icons-outlined">public</i>
                  <span className="text">{inputValue?.timeZone}</span>
                </div>
              </li>
            )}
          </ul>
        ) : (
          <EmptyEvent />
        )
      ) : (
        <EventsListLoader count={10} />
      )}
    </div>
  );
});

const EventsListLoader = (props = {}) => {
  const { count = "" } = props || {};
  return (
    <ul className="eventsListLoader">
      {Array.from({ length: count }, (value, index) => (
        <li className="eventOption" key={`eventOption${index}`}>
          <div className="name linear-background" />
          <div className="duration">
            <span className="material-icons-outlined scheduleIcon  linear-background" />
            <span className="time linear-background" />
          </div>
        </li>
      ))}
    </ul>
  );
};

const EmptyEvent = () => {
  return (
    <div className="noEventFound">
      <div className="text">No events found</div>
    </div>
  );
};

export default EventsList;
