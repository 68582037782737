import React from "react";
import InputBox from "Components/common/InputTextBox";

const TriggerName = props => {
  const { name = "", change = () => {}, errorMsg = "" } = props || {};

  const handleChange = (id, value) => {
    change(id, value);
  };

  const inputObj = {
    name: "triggerName",
    id: "name",
    type: "text",
    value: name || "",
    change: handleChange,
    err: errorMsg,
    label: "Name",
    text: "Name"
  };

  return (
    <div className="triggerName">
      <InputBox {...inputObj} />
    </div>
  );
};

export default TriggerName;
export { TriggerName };
