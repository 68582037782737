import React from "react";
import Lottie from "react-lottie";
import { userDetail } from "Stores/userDetail";
import Button from "Components/common/Button";
import ExportAnimation from "Utils/LottieFiles/exportAnimation.json";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: ExportAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

class ExportEmailNotifyState {
  @observable showPopup = false;
  @observable showText = "contacts";

  @action
  setShowText = (val = "") => {
    this.showText = val;
  };

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };
}

const exportEmailNotifyState = new ExportEmailNotifyState();

const ExportEmailNotify = () => {
  const { email } = (userDetail && userDetail.userInfo) || "";
  const hide = () => {
    exportEmailNotifyState.setShowText("contacts");
    exportEmailNotifyState.setShowPopup(false);
  };

  const getPopupText = () => {
    if (
      ["hubspotexport"].includes(
        exportEmailNotifyState.showText?.toLowerCase() || ""
      )
    ) {
      return `Contacts are currently being exported in the background. This process may take a few to several minutes, depending on the total number of contacts selected.`;
    } else {
      return (
        <>
          {" "}
          We will email you at <span className="blueText">{email}</span>{" "}
          {`with
      the link to the ${exportEmailNotifyState?.showText} when it is ready. Usually, this
      takes a few minutes.`}
        </>
      );
    }
  };

  return exportEmailNotifyState.showPopup ? (
    <div className="emailNotifyPopup">
      <div className="popupOverlay"></div>
      <div className="emailNotifyPopupCard animated customzoomIn">
        <div className="emailNotifyPopupTitle">
          <h6 className="emailNotifyTitleSm">We are processing your export</h6>
        </div>
        <Lottie
          options={{
            ...defaultOptions,
            animationData: ExportAnimation
          }}
          width={200}
          height={250}
          style={{ margin: "0 auto" }}
          speed={0.5}
        />
        <div className="emailNotifyPopupBody">
          <p className="popupText">{getPopupText()}</p>
          <div className="popupBtn">
            <Button
              type="text"
              name="csvExpPrcessBtn"
              id="csvExpPrcessBtn"
              btnText="Ok"
              className="blueConfirmBtn"
              click={hide}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export { ExportEmailNotify, exportEmailNotifyState };
export default observer(ExportEmailNotify);
