import React from "react";
import {
  Switch,
  Redirect,
  Route,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import AccountOverview from "Pages/Dashboard/Accounts/Overview/Main";
import AccountsNotes from "Pages/Dashboard/Accounts/Notes/Main";
import AccountTasks from "Pages/Dashboard/Accounts/Tasks/Main";
import AccountContacts from "Pages/Dashboard/Accounts/Contacts/Main";

const AccountsDetailedRouting = () => {
  let { path = "" } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/overview`} component={AccountOverview} />
      <Route exact path={`${path}/tasks`} component={AccountTasks} />
      <Route exact path={`${path}/contacts`} component={AccountContacts} />
      <Route exact path={`${path}/notes`} component={AccountsNotes} />
      <Redirect from={path} to={`${path}/overview`} />
    </Switch>
  );
};

export default withRouter(AccountsDetailedRouting);
