import React, { useContext, useRef } from "react";
import { ReactComponent as RightArrow } from "../images/svg/rightArrow.svg";
import LiveFeedUtils from "../utils";
import { LiveFeedMainContext } from "../main";
import { LiveFeedContext } from "./LiveFeed";

const AccordionContent = () => {
  const accordionContentRef = useRef(null);
  const { index, trackedEmailStatus = [] } = useContext(LiveFeedContext);

  const { currentAccordion, MAIL_STATUS_CONFIG } =
    useContext(LiveFeedMainContext);

  return (
    <div
      className="accordionContent"
      ref={accordionContentRef}
      style={{
        maxHeight: `${
          currentAccordion?.id === index && currentAccordion?.accordionOpen
            ? accordionContentRef?.current?.scrollHeight
            : 0
        }px`
      }}
    >
      {trackedEmailStatus?.length > 0 &&
        trackedEmailStatus?.map((item, index) => {
          const {
            eventType = "",
            createTs = "",
            additionalInfo = {}
          } = item || {};
          const { url = "" } = additionalInfo || {};
          const updatedEventType = eventType?.toLowerCase() || "";

          return (
            updatedEventType !== "sent" && (
              <div
                className="contentWrapper"
                key={`${item?.eventType}-${index}`}
              >
                <div className="status">
                  <span
                    className="roundedIcon"
                    style={{
                      backgroundColor:
                        MAIL_STATUS_CONFIG[updatedEventType].color
                    }}
                  >
                    {MAIL_STATUS_CONFIG[updatedEventType].svg}
                  </span>
                  <span
                    className={`statusText ${
                      updatedEventType === "clicked" ? "addHyphen" : ""
                    }`}
                  >
                    {updatedEventType}
                  </span>
                  {updatedEventType === "clicked" && url && (
                    <>
                      <a
                        className="clickedLink"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url}
                      </a>
                      <span
                        className="rightArrowWrapper"
                        onClick={() =>
                          window.open(url, "_blank", "noopener noreferrer")
                        }
                      >
                        <RightArrow />
                      </span>
                    </>
                  )}
                </div>
                <span className="dateTime">
                  {LiveFeedUtils.formatDate(createTs, true)}
                </span>
              </div>
            )
          );
        })}
    </div>
  );
};

export { AccordionContent };
export default AccordionContent;
