/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import Button from "Components/common/Button";
import { Utils } from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import makeApi, { URLS } from "Utils/apiURL";
import { userDetail } from "Stores/userDetail";
import { checkTeamStatus, getFeatureFlagWithTeam } from "Utils/commonAPI";
import { captchaStoreValue } from "Stores/captcha";

const TeamInviteLogin = props => {
  const { email, inviteToken } = userDetail.userTeamInfo;

  const [userPassword, setUserPassword] = useState(""),
    [emailErr, setEmailErr] = useState(""),
    [passwordErr, setPasswordErr] = useState(""),
    [errMsg, setErrMsg] = useState(""),
    [loader, setLoader] = useState(false);

  const userData = {};

  const checkValidTeamPage = () => {
      if (!email) {
        props.history.push("/login");
      }
    },
    acceptTeamInvitation = async () => {
      try {
        const config = {
          url: URLS.acceptTeamInvite,
          method: "POST",
          data: {
            email,
            inviteToken
          }
        };
        let res = await makeApi(config);
        if (res?.data?.status === "success") {
          props.history.push("/teamInvite/success");
        } else {
          userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
          userData.email = email.trim();
          userData.team = await checkTeamStatus();
          userData.userLoggedIn = true;
          userDetail.setUserInfo(userData);
          toasterState.setToastMsg(
            `Something went wrong. Please contact us at support@salesgear.io`,
            "fail"
          );
          props.fn(true);
          props.history.push("/dashboard");
        }
      } catch (err) {
        console.error("Team invitation signup accept err", err);
      }
    },
    login = async token => {
      try {
        let isEmailValid = Utils.emailValidate(email.trim());
        let isPasswordValid = Utils.validateStringLength(userPassword, 6);
        if (isEmailValid && isPasswordValid) {
          const config = {
            url: URLS.loginUser,
            data: { email: email.trim(), password: userPassword, token },
            method: "POST"
          };
          let response = await makeApi(config);
          switch (response && response.data && response.data.success) {
            case "true":
              await acceptTeamInvitation();
              break;
            default:
              setErrMsg("Please check your email address or password");
              setLoader(false);
              break;
          }
        } else if (!isEmailValid) {
          setLoader(false);
          setEmailErr("Please enter a valid email address");
        } else if (!isPasswordValid) {
          setLoader(false);
          setPasswordErr("Password should have at least 6 characters");
        } else {
          setErrMsg("Please check your email address or password");
          setLoader(false);
          setTimeout(() => {
            setErrMsg("");
          }, 2000);
        }
      } catch (err) {
        console.error(err);
        setLoader(false);
        setErrMsg("Something went wrong");
      }
    },
    submitForm = e => {
      e.preventDefault();
      if (e.keyCode === 13) {
        executeCaptcha(e);
      }
    },
    passwordChange = e => {
      setUserPassword(e.currentTarget.value);
      setPasswordErr("");
      setErrMsg("");
    };

  const executeCaptcha = e => {
    if (e) {
      Utils.removeInputRequired(e);
    }
    let isEmailValid = Utils.emailValidate(email.trim());
    let isPasswordValid = Utils.validateStringLength(userPassword, 6);
    if (isEmailValid && isPasswordValid) {
      setLoader(true);
      captchaStoreValue.executeCaptcha(validateCaptchaFromAPi);
    }
  };

  const validateCaptchaFromAPi = async token => {
    if (token) {
      login(token);
    }
  };

  useEffect(() => {
    checkValidTeamPage();
  }, []);

  return (
    <div id="teamLogin" className="teamLogin">
      <h6>Log in to continue</h6>
      <div id="teamLoginForm" name="teamLoginForm">
        <div className="teamEmailPopulate">
          <label htmlFor="emailSignUp">Email Address</label>
          <p className="email">{email !== null ? email : ""}</p>
          <span className="errMsgText">{emailErr}</span>
        </div>
        <div className={`input-form ${userPassword.length ? "filled" : ""}`}>
          <input
            id="passwordSignIn"
            name="passwordSignIn"
            type="password"
            defaultValue={userPassword}
            onChange={e => passwordChange(e)}
            onKeyUp={e => submitForm(e)}
            autoComplete="off"
            autoFocus
            required
          />
          <span className="highlight"></span>
          <span className="bar"></span>
          <label htmlFor="passwordSignIn">Password</label>
          <span className="err-msg-text">{passwordErr}</span>
          <span className="err-msg-text">{errMsg}</span>
        </div>
        <div className="form-button">
          <Button
            id="loginBtn"
            name="loginBtn"
            btnText="Log In"
            icon={true}
            iconName="keyboard_backspace"
            className={loader ? "loading" : "loginBtn rotateForwardIcon"}
            loader={loader}
            type="button"
            click={e => {
              e.preventDefault();
              executeCaptcha(e);
            }}
          />
        </div>
      </div>
      <div className="info-text-forgot">
        <Link to="/forgotPassword">Forgot Password?</Link>
      </div>
      <div className="info-text">
        <span>Don't have an account?&nbsp;</span>
        <Link to="/teamInvite/signup">Sign Up</Link>
      </div>
    </div>
  );
};

export default withRouter(observer(TeamInviteLogin));
