import React, { memo } from "react";
import Button from "Components/common/Button";

const CreateEventActionBtn = memo((props = {}) => {
  const {
    loader = false,
    disabled = false,
    cancelEvent = () => {},
    createEvent = () => {}
  } = props || {};

  const btnActionList = [
    {
      id: "cancelCreateEventBtn",
      name: "cancelCreateEventBtn",
      btnText: "Cancel",
      type: "button",
      className: `cancelCreateEventBtn`,
      click: cancelEvent
    },
    {
      id: "createEventActionBtn",
      name: "createEventActionBtn",
      btnText: "Save",
      type: "button",
      className: `createEventActionBtn ${loader ? "loading" : ""}`,
      click: createEvent,
      loader,
      disabled
    }
  ];

  return (
    <div className="createEventActionBtnGroup">
      {btnActionList?.map(item => (
        <Button key={item?.id} {...item} />
      ))}
    </div>
  );
});

export default CreateEventActionBtn;
export { CreateEventActionBtn };
