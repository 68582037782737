/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { makeApi, URLS } from "Utils/apiURL";

const OutlookOauth = () => {
  const [connectSuccess, setConnectSuccess] = useState(false);

  const getAuthCode = () => {
    try {
      const url = new URL(window.location.href);
      return url.searchParams.get("code");
    } catch (e) {
      return null;
    }
  };

  const getAuthState = () => {
    try {
      const url = new URL(window.location.href);
      return url.searchParams.get("state");
    } catch (e) {
      return null;
    }
  };

  const connectOutlookApp = async () => {
    const code = getAuthCode();
    const state = getAuthState();
    let defaultUrl = "";
    let defaultType = "MICROSOFT_CONNECT_INBOX";
    let inboxId = "";
    if (state) {
      inboxId = state?.split("_")?.pop();
    }
    if (state?.includes("calendar")) {
      defaultUrl = `${URLS.connectCalendar}?code=${code}`;
    } else if (state?.includes("outlookAddScope")) {
      defaultUrl = `${URLS.getScopeDetails}?code=${code}&inboxId=${inboxId}`;
      defaultType = "MICROSOFT_ADDSCOPE_INBOX";
    } else if (state?.includes("reconnectInbox")) {
      defaultUrl = `${URLS.connectOutlook}?code=${code}&inboxId=${inboxId}&reconnectInboxId=${inboxId}`;
    } else {
      defaultUrl = `${URLS.connectOutlook}?code=${code}`;
    }
    if (code) {
      const config = {
        url: defaultUrl,
        method: "GET"
      };
      const res = await makeApi(config);
      const { data = "" } = res || {};
      if ([200, "200"].includes(data) || data === true) {
        if (inboxId) {
          const config = {
            url: `${URLS.getDomainHealth}?inboxId=${inboxId}`,
            method: "GET"
          };
          makeApi(config);
        }
        setConnectSuccess(true);
        window.opener.postMessage({
          code: 200,
          type: defaultType
        });
        window.close();
      } else if ([409, "409"].includes(data)) {
        window.opener.postMessage({
          code: 409,
          type: defaultType
        });
        window.close();
      } else {
        window.opener.postMessage({
          code: 500,
          type: defaultType
        });
        window.close();
      }
    }
  };

  useEffect(() => {
    connectOutlookApp();
  }, []);

  return (
    <div className="pageClass">
      <div className="pageCont">
        <div className="connectAppSection">
          {connectSuccess ? (
            <div>Successfully Connected</div>
          ) : (
            <>
              <div>Please wait while we connect your Microsoft account</div>
              <div style={{ marginTop: "10px" }}> Connecting... </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(OutlookOauth);
export { OutlookOauth };
