import { decorate, observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";

class TeamStore {
  userFormObj = {
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    role: ""
  };

  // Observables
  teamData = null;
  seatCount = null;
  teamTotalSeats = null;

  // Action
  getTeamMember = async (data = { pageNo: 1, limit: 500 }) => {
    try {
      const config = {
        url: URLS.getTeam,
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (res?.data && Object.keys(res.data)?.length > 0) {
        this.teamData = res.data;
        this.setSeatCount(res?.data?.team || "");
      } else {
        this.teamData = {};
      }
    } catch (err) {
      console.error("Get team member", err);
    }
  };

  addUser = async (data, cbk) => {
    try {
      const config = {
        url: URLS.inviteUser,
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (res?.data?.responseCode) {
        switch (res.data.responseCode) {
          case 200:
            if (res?.data?.individualTeamMember) {
              this.updateTeamMember(res.data.individualTeamMember);
              this.setSeatCount(res.data.individualTeamMember.team);
            }
            Utils.mixpanelTrack(MP_EVENT.TM_INVITED, {
              pageType: "Team"
            });
            toasterState.setToastMsg(
              "We've sent the invitation on your behalf",
              "success"
            );
            cbk && cbk(true, "userAdded");
            break;
          case 202:
            Utils.showUpgradeSettingsPopup("team", true, true);
            cbk && cbk(true);
            break;
          case 203:
            cbk && cbk(false, "differentTeam");
            break;
          case 204:
            toasterState.setToastMsg(
              "User already has an account. Please contact us at support@salesgear.io to add this user to your team plan",
              "warning"
            );
            cbk && cbk(true);
            break;
          case 205:
            toasterState.setToastMsg(
              "This user is part of a different team plan",
              "warning"
            );
            cbk && cbk(true);
            break;
          case 206:
            cbk && cbk(false, "sameTeam");
            break;
          case 207:
            cbk && cbk(false, "sameTeam");
            break;
          case 209:
            toasterState.setToastMsg(
              "Please enter a valid business email address",
              "failure"
            );
            cbk && cbk(true);
            break;
          default:
            toasterState.setToastMsg("Something went wrong", "fail");
            cbk && cbk(true);
            break;
        }
      } else {
        Utils.showApiFailureResponse(res);
        cbk && cbk(true);
      }
    } catch (err) {
      cbk && cbk(true);
      toasterState.setToastMsg("Something went wrong", "fail");
      console.error("Add team user", err);
    }
  };

  editUser = async (data, cbk) => {
    try {
      const config = {
        url: URLS.editTeamMember,
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (
        res?.data?.responseCode === 200 &&
        res?.data?.individualTeamMember?.member
      ) {
        this.setTeamDataMemberUpdate(res.data.individualTeamMember.member);
        toasterState.setToastMsg(
          "Your changes were saved successfully",
          "success"
        );
      } else {
        Utils.showApiFailureResponse(res);
      }
      cbk && cbk();
    } catch (err) {
      cbk && cbk();
      console.error("Edit team user", err);
    }
  };

  deactiveUser = async (email, name, cbk) => {
    try {
      const config = {
        url: URLS.deactiveTeamUser,
        method: "POST",
        data: [{ email }]
      };
      let res = await makeApi(config);
      if (
        res?.data?.responseCode === 200 &&
        res?.data?.individualTeamMember !== null
      ) {
        this.setActiveMemberStatus(email, "INACTIVE");
        this.teamData.team = res.data.individualTeamMember.team;
        this.setSeatCount(res.data.individualTeamMember.team);
        toasterState.setToastMsg(
          `${name} has been deactivated from your team`,
          "success"
        );
        cbk && cbk("INACTIVE");
      } else {
        Utils.showApiFailureResponse(res);
        cbk && cbk();
      }
    } catch (err) {
      cbk && cbk();
      console.error(err, "Deactivation email failed");
    }
  };

  activeUser = async (email, name, cbk) => {
    try {
      const config = {
        url: URLS.activeTeamUser,
        method: "POST",
        data: { email }
      };
      let res = await makeApi(config);
      if (res?.data?.responseCode) {
        switch (res.data.responseCode) {
          case 200:
            this.setActiveMemberStatus(email, "ACTIVE");
            if (res?.data?.individualTeamMember?.team) {
              this.teamData.team = res.data.individualTeamMember.team;
              this.setSeatCount(res.data.individualTeamMember.team);
            } else {
              this.seatCount = this.seatCount + 1;
            }
            toasterState.setToastMsg(
              `${name} has been added back to your team`,
              "success"
            );
            cbk && cbk("ACTIVE");
            break;
          case 202:
            Utils.showUpgradeSettingsPopup("team", true, true);
            cbk && cbk();
            break;
          case 203:
            toasterState.setToastMsg(
              "This user is part of a different team plan",
              "warning"
            );
            cbk && cbk();
            break;
          case 204:
            toasterState.setToastMsg(
              "User already has an account. Please contact us at support@salesgear.io to add this user to your team plan",
              "warning"
            );
            cbk && cbk();
            break;
          case 205:
            toasterState.setToastMsg(
              "This user is part of a different team plan",
              "warning"
            );
            cbk && cbk();
            break;
          case 206:
            toasterState.setToastMsg(
              "User is already part of your team plan",
              "warning"
            );
            cbk && cbk();
            break;
          case 207:
            toasterState.setToastMsg(
              "User is already part of your team plan",
              "warning"
            );
            cbk && cbk();
            break;
          default:
            toasterState.setToastMsg(
              "Something went wrong. Please try again",
              "failure"
            );
            cbk && cbk();
            break;
        }
      }
    } catch (err) {
      cbk && cbk();
      console.error(err, "Activation email failed");
    }
  };

  resendInvite = async (email, name, cbk) => {
    try {
      const config = {
        url: URLS.resendTeamInvite,
        method: "POST",
        data: { email, name }
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        toasterState.setToastMsg(
          "We've sent the invitation on your behalf",
          "success"
        );
      } else if (res?.response?.status === 509) {
        Utils.showContactsUploadLimitReached();
      } else {
        Utils.showApiFailureResponse(res);
      }
      cbk && cbk();
    } catch (err) {
      cbk && cbk();
      console.error(err, "Resend invtation failed");
    }
  };

  updateTeamMember = (data, toAssign) => {
    if (this.teamData?.members?.length > 0) {
      if (toAssign) {
        this.teamData.members = data.member;
      } else {
        this.teamData.members.push(data.member);
      }
      this.teamData.team = data.team;
    }
  };

  setSeatCount = (team = "") => {
    if (team !== null) {
      const { noOfSeatsEligible, noOfSeatsUsed } = team;
      this.teamTotalSeats = Math.abs(noOfSeatsEligible);
      this.seatCount = Math.abs(noOfSeatsUsed);
    }
  };

  setTeamDataMemberUpdate = data => {
    const { members = [] } = this.teamData || {};
    let foundIndex = members.findIndex(user => user?.id === data?.id);
    members[foundIndex] = data;
  };

  setActiveMemberStatus = (email = "", status) => {
    const { members = [] } = this.teamData || {};
    let foundIndex = members.findIndex(user => user?.email === email);
    members[foundIndex] = { ...members[foundIndex], status };
  };

  cancelInvite = async (email, name, cbk) => {
    try {
      const config = {
        url: URLS.cancelTeamInvite ? URLS.cancelTeamInvite : "",
        method: "POST",
        data: { email }
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        if (res?.data?.team && res?.data?.member) {
          this.updateTeamMember(res.data, true);
          this.setSeatCount(res.data.team);
        }
        cbk && cbk("CANCEL");
        toasterState.setToastMsg(
          `The invitation sent to ${name} is cancelled`,
          "success"
        );
      } else {
        cbk && cbk();
        Utils.showApiFailureResponse(res);
      }
    } catch (err) {
      cbk && cbk();
      console.error(err, "Cancel invtation failed");
    }
  };

  inviteRejectedUser = async (email, name, cbk) => {
    try {
      const config = {
        url: URLS.inviteRejectedMember,
        method: "POST",
        data: { firstName: name, email }
      };
      let res = await makeApi(config);
      if (res?.data?.responseCode) {
        const respCode = res.data.responseCode;
        if (respCode === 200) {
          this.setActiveMemberStatus(email, "INVITED");
          this.setSeatCount(res.data.individualTeamMember.team);
          toasterState.setToastMsg(
            "We've sent the invitation on your behalf",
            "success"
          );
        } else {
          this.showInviteResponse(respCode);
        }
        cbk && cbk("ACTIVE");
      }
    } catch (e) {
      cbk && cbk(true);
      toasterState.setToastMsg("Something went wrong", "fail");
      console.error("Reinvite rejected user", e);
    }
  };

  showInviteResponse = respCode => {
    switch (respCode) {
      case 202:
        Utils.showUpgradeSettingsPopup("team", true, true);
        break;
      case 203:
        toasterState.setToastMsg(
          "User associated with different team plan",
          "failure"
        );
        break;
      case 204:
        toasterState.setToastMsg(
          "User already has an account. Please contact us at support@salesgear.io to add this user to your team plan",
          "warning"
        );
        break;
      case 206:
        toasterState.setToastMsg("User already part of your team", "failure");
        break;
      default:
        toasterState.setToastMsg("Something went wrong", "fail");
        break;
    }
  };
}

decorate(TeamStore, {
  userFormObj: observable,
  seatCount: observable,
  teamData: observable,
  getTeam: action,
  setTeamData: action,
  addUser: action,
  updateTeamMember: action,
  setSeatCount: action,
  setTeamDataMemberUpdate: action,
  resendInvite: action,
  setActiveMemberStatus: action
});

export default new TeamStore();
