import React from "react";

const ContactTableLoader = props => {
  const { count = 10 } = props;
  return (
    <>
      {Array.from({ length: count }, (value, index) => (
        <div className="contact contactTableLoader" key={`contact ${index}`}>
          <div className="ctSelectAll dataContainer">
            <span className="linear-background"></span>
          </div>
          <div className="nameCont dataContainer" title="Contact">
            <span
              className="linear-background darkText"
              style={{ width: "50%" }}
            ></span>
            <span
              className="linear-background darkText"
              style={{ width: "70%" }}
            ></span>
          </div>
          <div className="titleCont dataContainer" title="Title">
            <span
              className="linear-background darkText"
              style={{ width: "50%" }}
            ></span>
            <span
              className="linear-background darkText"
              style={{ width: "70%" }}
            ></span>
          </div>
          <div className="companyCont dataContainer" title="Company">
            <span
              className="linear-background darkText"
              style={{ width: "50%" }}
            ></span>
            <span
              className="linear-background darkText"
              style={{ width: "70%" }}
            ></span>
          </div>
          <div className="emailCont dataContainer" title="Email & Social">
            <span
              className="linear-background darkText"
              style={{ width: "50%" }}
            ></span>
            <span
              className="linear-background darkText"
              style={{ width: "70%" }}
            ></span>
          </div>
          <div className="mobileCont dataContainer" title="Mobile/Direct Dial">
            <span
              className="linear-background darkText"
              style={{ width: "50%" }}
            ></span>
            <span
              className="linear-background darkText"
              style={{ width: "70%" }}
            ></span>
          </div>
        </div>
      ))}
    </>
  );
};

export default ContactTableLoader;
export { ContactTableLoader };
