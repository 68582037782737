import React, { useState } from "react";
import { ReactComponent as YoutubeIcon } from "Assets/svg/youtube.svg";
import Utils from "Utils/utils";
import { MP_EVENT, featureVideoLinkObj, IntercomTour } from "Static/constant";
import YoutubeTutorial from "Components/common/YoutubeTutorial";

const FeatureDemoVideo = props => {
  const { name, linkText, type } = props;

  const [showVideo, setVideo] = useState(false);

  const closeVideo = () => {
    setVideo(false);
    if (window?.Intercom?.booted && IntercomTour?.[type]) {
      return Utils.initiateIntercom(type);
    } else {
      setTimeout(() => {
        return Utils.initiateDriver("demo", type);
      }, 500);
    }
  };

  const showVideoPopup = () => {
    if (type) {
      setVideo(true);
      Utils.mixpanelTrack(MP_EVENT.TUTORIAL_VIDEO_OPENED, {
        type,
        pageType: Utils.getPageType() || ""
      });
    }
  };

  return (
    <>
      <div className="featureDemoContentCard" onClick={showVideoPopup}>
        <YoutubeIcon />
        <span className="featureName">{name}</span>
        <span className="featureVideoLink">{linkText}</span>
      </div>
      {showVideo && (
        <YoutubeTutorial
          videoId={featureVideoLinkObj[type]}
          closeCbk={closeVideo}
        />
      )}
    </>
  );
};

export default FeatureDemoVideo;
export { FeatureDemoVideo };
