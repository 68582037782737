import { action, observable, toJS } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import { createContactStagePopupState } from "./CreateContactStagePopup";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";

const showErrMsg = (responseCode = 0) => {
  +responseCode === 400
    ? toasterState.setToastMsg("Stage name already exists", "failure")
    : toasterState.setToastMsg(
        "Something went wrong. Please try again later.",
        "failure"
      );
};

class ContactStagesState {
  @observable contactStageData = {};
  @observable contactStageDataLoading = true;
  @observable currentEditedField = "";
  @observable currentEditedFieldLoader = false;
  @observable contactStageSingleDataLoading = false;
  @observable contactStageRules = [];

  @action
  setContactStageRules = value => {
    this.contactStageRules = value;
  };

  @action
  setCurrentEditedField = val => {
    this.currentEditedField = val;
  };

  @action
  setCurrentEditedFieldLoader = val => {
    this.currentEditedFieldLoader = val;
  };

  @action
  setContactStageData = val => {
    this.contactStageData = val;
  };

  @action
  setContactStageSingleDataLoading = val => {
    this.contactStageSingleDataLoading = val;
  };

  @action
  getContactStageData = async () => {
    const config = {
      url: URLS.getContactStages,
      method: "GET"
    };
    let response = await makeApi(config);
    this.contactStageDataLoading = false;
    if (response?.data) {
      this.setContactStageData(response?.data);
      this.setCurrentEditedFieldLoader(false);
      this.setCurrentEditedField("");
      this.setContactStageSingleDataLoading(false);
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "failure"
      );
    }
  };

  @action
  getStageRules = async () => {
    const config = {
      url: URLS.getRules,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response?.data?.rules) {
      let tempStageRules = Object.values(response?.data?.rules) || [];
      this.setContactStageRules(tempStageRules);
    }
  };

  @action
  createNewStage = async stageName => {
    const config = {
      url: URLS.getContactStages,
      method: "POST",
      data: {
        stageName
      }
    };
    let response = await makeApi(config);
    createContactStagePopupState.setStageName("");
    createContactStagePopupState.setButtonLoader(false);
    createContactStagePopupState.setShowPopup(false);
    if (response?.data) {
      const { responseCode = "" } = response?.data || {};
      if (+responseCode === 200) {
        this.setContactStageSingleDataLoading(true);
        Utils.mixpanelTrack(MXP_EVENT.CONTACT_STAGE_CREATED, {
          pageType: "contact stages",
          stageName
        });
        this.getContactStageData();
      } else {
        showErrMsg(responseCode);
      }
    }
  };

  @action
  updateVisibility = async (stageId, stageName, displayEnabled) => {
    const config = {
      url: URLS.updateVisibility,
      method: "POST",
      data: {
        stageId,
        displayEnabled: !displayEnabled
      }
    };
    let response = await makeApi(config);
    if (response?.data) {
      const { responseCode = "" } = response?.data || {};
      if (+responseCode === 200) {
        this.getContactStageData();
        toasterState.setToastMsg(
          `${stageName} stage ${
            !displayEnabled ? "will" : "will not"
          } be visible on the contacts section`,
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
    }
  };

  @action
  deleteAction = async (stageName, stageId) => {
    const config = {
      url: URLS.deleteStage,
      method: "POST",
      data: {
        stageId
      }
    };
    let response = await makeApi(config);
    if (response?.data) {
      const { responseCode = "" } = response?.data || {};
      confirmationPopupState.setShowPopup(false);
      if (+responseCode === 200) {
        this.getContactStageData();
        Utils.mixpanelTrack(MXP_EVENT.CONTACT_STAGE_DELETED, {
          pageType: "contact stages",
          stageName
        });
        toasterState.setToastMsg(
          "Contact stage deleted successfully",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
    }
  };

  @action
  updateStageName = async (stageId, stageName) => {
    const config = {
      url: URLS.updateStageName,
      method: "POST",
      data: {
        stageId,
        stageName
      }
    };
    const response = await makeApi(config);
    if (response?.data) {
      const { responseCode = "" } = response?.data || {};
      confirmationPopupState.setShowPopup(false);
      if (+responseCode === 200) {
        Utils.mixpanelTrack(MXP_EVENT.CONTACT_STAGE_UPDATED, {
          pageType: "contact stages",
          stageName
        });
        this.getContactStageData();
        toasterState.setToastMsg("Stage name updated successfully", "success");
      } else {
        this.setCurrentEditedFieldLoader(false);
        this.setCurrentEditedField("");
        showErrMsg(responseCode);
      }
    }
  };
}

const contactStagesState = new ContactStagesState();

export { contactStagesState };
export default contactStagesState;
