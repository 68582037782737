import React from "react";
import { observer } from "mobx-react-lite";
import trackingStore from "./trackingStore";
import NavTaskButton from "../Dashboard/tasks/PerformTask/NavTaskButton";

const VisitorsDetailedInfoHeader = observer((props = {}) => {
  const { setScrollBlock = () => {} } = props || {};
  const checkPrevButtonEnabled = () => {
    return !(trackingStore?.currentUser + 1 === 1);
  };

  const checkNextButtonEnabled = () => {
    return !(
      trackingStore?.currentUser + 1 ===
      trackingStore?.visitorsData?.length
    );
  };

  const getNextVistior = (type = "") => {
    let userNo =
      type === "next"
        ? trackingStore?.currentUser + 1
        : type === "previous"
        ? trackingStore?.currentUser - 1
        : trackingStore?.currentUser;
    trackingStore.setCurrentUser(userNo);
    setScrollBlock(type === "next" ? "end" : "start");
    if (trackingStore?.visitorsData?.length > 0 && userNo >= 0) {
      trackingStore.setSessionDetails(trackingStore?.visitorsData[userNo]);
    }
  };

  return (
    <div className="detailedInfoHeaderSection">
      <div
        className="backButton"
        onClick={() => trackingStore.setViewDetailedVisitorsInfo(false)}
      >
        <i class="material-icons">keyboard_backspace</i>
      </div>
      <div className="domainInfoAndNavigationCont">
        <div className="domainInfo">
          <span className="pageHeading">Pageviews</span>
          <span className="domainName">
            {trackingStore?.sessionDetails?.domain}
          </span>
        </div>
        <div className="countAndNavigation">
          {trackingStore?.visitorsData?.length > 1 && (
            <div className="pageCount">{`${trackingStore?.currentUser + 1} of ${
              trackingStore?.visitorsData?.length
            } User`}</div>
          )}
          <NavTaskButton
            setSelectedTaskFn={getNextVistior}
            isPreviousBtnEnabled={checkPrevButtonEnabled()}
            isNextBtnEnabled={checkNextButtonEnabled()}
            pageType="TRACKING"
          />
        </div>
      </div>
    </div>
  );
});

export { VisitorsDetailedInfoHeader };
export default VisitorsDetailedInfoHeader;
