import React from "react";

const NewTooltip = (props = {}) => {
  const {
    text = "",
    crmIcon = false,
    className = "",
    heading = "",
    dynamicDom = false,
    children = <></>,
    alignRight = false,
    closeIconCbk = () => {}
  } = props || {};

  const onClickCloseIcon = () => {
    closeIconCbk();
  };

  return (
    <div className={`newTooltip ${className}`}>
      <div className={`sharpPointer  ${alignRight ? "alignRight" : ""}`} />
      <div
        className={`tooltipBody  ${alignRight ? "alignRight" : ""} ${
          crmIcon ? "crmToolTipBody" : ""
        }`}
      >
        <div className="tooltipHeading">{heading}</div>
        {text && !dynamicDom && (
          <div
            className="tooltipText"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        {dynamicDom && <div className="tooltipText">{children}</div>}
      </div>
      <i className="material-icons tooltipCloseIcon" onClick={onClickCloseIcon}>
        close
      </i>
    </div>
  );
};

export default NewTooltip;
export { NewTooltip };
