/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import PhoneInput from "react-phone-number-input";
import DialerServiceData from "Stores/DialerServiceStore";
import { dialerLogsPopupState } from "./DialerLogsAfterCallEndPopup";
import { numberWithLetter } from "Static/constant";
import { ReactComponent as PhoneDeleteIcon } from "Assets/svg/phoneDelete.svg";
import DialerDigits from "./DialerDigits";
import DialerCallStatusAndTimer from "./DialerCallStatusAndTimer";

const DialerKeypad = props => {
  const {
    contact,
    cbkNoUpdate,
    activeConnection = "",
    isRecordingActivated = false,
    selectedNumber = ""
  } = props || {};
  const { firstName, lastName, name, phone, mobilePhone, otherPhone } =
    contact || {};

  const newPhoneArr =
    [...(phone || []), ...(mobilePhone || []), ...(otherPhone || [])]
      ?.flat(Infinity)
      ?.filter(element => {
        return element && !["[]", "[ ]"].includes(element);
      }) || [];

  const getPhoneNumber = useCallback(() => {
    if (selectedNumber?.length > 0) {
      return selectedNumber;
    } else {
      return newPhoneArr?.[0] || dialerLogsPopupState?.dialerNumber || "";
    }
  }, [mobilePhone, phone, otherPhone, selectedNumber]);

  const dialInputBox = useRef();

  const [phoneNo, setPhoneNo] = useState(getPhoneNumber());
  const [dialerOption, setDialerOption] = useState({
    ...DialerServiceData?.dialerOption
  });

  const changeValue = (value = "") => {
    setPhoneNo(value?.trim() || "");
    cbkNoUpdate(value?.trim() || "");
  };

  const clearNumber = () => {
    if (phoneNo?.trim() && phoneNo?.length > 0) {
      let value = phoneNo.trim().slice(0, phoneNo.trim().length - 1);
      setPhoneNo(value.trim());
      dialInputBox.current.focus();
    }
  };

  const getDigit = value => {
    if (!activeConnection && !DialerServiceData.dialerIvrKeypad) {
      let number = phoneNo + value.toString().trim();
      setPhoneNo(number);
      dialInputBox.current.focus();
      cbkNoUpdate(number);
    } else {
      let numStr = value?.trim()?.toString();
      numStr && activeConnection.sendDigits(numStr);
    }
  };

  useEffect(() => {
    setDialerOption({ ...dialerOption, ...DialerServiceData?.dialerOption });
  }, [DialerServiceData.dialerOption]);

  useEffect(() => {
    setPhoneNo(getPhoneNumber());
  }, [mobilePhone, phone, otherPhone, selectedNumber]);

  return (
    <div className="DailpadSection">
      {!dialerOption.call && (
        <>
          <div className="contactNameInputWrap">
            <div className="inputRemoveBtnWrap">
              <PhoneInput
                defaultCountry="US"
                flags={flags}
                id="dialInputBox"
                ref={dialInputBox}
                placeholder="Enter Number to call"
                value={phoneNo}
                onChange={changeValue}
              />
              <div className="removeBtn" onClick={clearNumber}>
                <PhoneDeleteIcon />
              </div>
            </div>
          </div>
          <div className="keypadSection">
            {numberWithLetter.map(key => {
              return (
                <DialerDigits
                  key={`phoneKeyTouchPad${key.number}`}
                  number={key.number}
                  letter={key.letter}
                  cbk={getDigit}
                />
              );
            })}
          </div>
        </>
      )}
      {dialerOption.call && (
        <DialerCallStatusAndTimer
          contact={contact}
          phone={phoneNo}
          activeConnection={activeConnection}
          isRecordingActivated={isRecordingActivated}
          cbk={getDigit}
        />
      )}
    </div>
  );
};

export default observer(DialerKeypad);
export { DialerKeypad };
