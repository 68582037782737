import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import { calendarBookMeetingState } from "./State";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import TextArea from "Components/common/TextArea";
import EventsList from "./Components/EventsList";
import CalendarDatePicker from "./Components/CalendarDatePicker";
import TimeZoneSelector from "./Components/TimeZoneSelector";
import SlotsAvailable from "./Components/SlotsAvailable";
import DateUtils from "Utils/DateUtils";
import EmailInputBox from "./Components/EmailInputBox";
import { getGlobalTimeZones } from "Utils/commonAPI";
import { toasterState } from "Components/common/toaster";

let defaultInputValue = {
  event: {},
  email: "",
  description: "",
  slotDateTime: "",
  date: "",
  guests: [],
  contactId: "",
  timeZone: "",
  timezoneName: ""
};

const Main = observer(() => {
  const [inputValue, setInputValue] = useState(defaultInputValue);
  const [eventLoader, setEventLoader] = useState(true);
  const [eventPageLoader, setEventPageLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [slotLoader, setSlotLoader] = useState(false);
  const [eventsList, setEventsList] = useState(
    calendarBookMeetingState.eventsData
  );
  const [showGuestInput, setShowGuestInput] = useState(false);
  const [timezoneErr, setTimezoneErr] = useState("");
  const [email, setEmail] = useState("");
  const [backupEmail, setBackupEmail] = useState("");

  const handleStateChange = (key = "", value = "") => {
    setInputValue({ ...inputValue, [key]: value });
  };

  const isBtnDisabled = () => {
    const {
      slotDateTime = "",
      email = "",
      date = "",
      contactId = ""
    } = inputValue || {};
    return ["globalheader"].includes(
      calendarBookMeetingState?.pageType?.toLowerCase()
    )
      ? !(slotDateTime && date)
      : !(slotDateTime && email && date && contactId);
  };

  const resetValue = () => {
    calendarBookMeetingState.resetState();
    setEventLoader(true);
    setShowGuestInput(false);
    setBtnLoader(false);
    setEventsList(null);
    setSlotLoader(true);
    setInputValue(Utils.createObjWithoutRef(defaultInputValue));
  };

  const getFreeSlotByDate = () => {
    if (!eventPageLoader && inputValue?.event?.id && inputValue?.date) {
      setSlotLoader(true);
      calendarBookMeetingState.getCalendarFreeSlots(
        {
          eventId: inputValue?.event?.id,
          date: DateUtils.getYearMonthDateBySymbol(inputValue?.date, "-")
        },
        () => setSlotLoader(false)
      );
    }
  };

  const backToEvents = () => {
    setShowGuestInput(false);
  };

  const moveToNextStep = () => {
    if (inputValue?.timeZone || inputValue?.timezoneName) {
      setShowGuestInput(true);
      setTimezoneErr("");
    } else {
      setTimezoneErr("Please select a timezone");
    }
  };

  const cancelBooking = () => {
    resetValue();
  };

  const addEmail = async () => {
    let isValidEmail = Utils.emailValidate(
      backupEmail?.trim()?.toLowerCase() || ""
    );
    if (backupEmail?.trim()) {
      let emailStatusObj =
        (isValidEmail &&
          (await Utils.validateEmailAddressStatus([backupEmail]))) ||
        "";
      let value = !isValidEmail
        ? "INVALID"
        : (emailStatusObj &&
            Object.keys(emailStatusObj)?.length > 0 &&
            ["BOUNCED", "OPTED_OUT"].includes(emailStatusObj?.[backupEmail]) &&
            emailStatusObj[backupEmail]) ||
          "";
      let tempList = inputValue?.guests ? [...inputValue?.guests] : [];
      const filteredEmailList =
        inputValue?.guests?.length > 0 &&
        inputValue.guests.filter(item => item?.email === backupEmail);

      if (!(filteredEmailList?.length > 0)) {
        tempList.push({
          email: email?.trim(),
          invalid: value
        });
      }
      setEmail("");
      return tempList;
    }
  };

  const scheduleEvent = async () => {
    setBtnLoader(true);
    const updatedGuests = await addEmail();
    setTimeout(() => {
      let invalidGuests = [];
      if (inputValue?.guests?.length > 0) {
        invalidGuests = inputValue.guests.filter(item => item?.invalid);
      }
      const isGlobalHeader = ["globalheader"].includes(
        calendarBookMeetingState?.pageType?.toLowerCase()
      );
      if (invalidGuests?.length > 0) {
        setBtnLoader(false);
        toasterState.setToastMsg(
          `Please enter a valid ${isGlobalHeader ? "" : "guest"} email`,
          "failure"
        );
      } else {
        const callback = (value = false) => {
          if (value && isGlobalHeader) {
            Utils.mixpanelTrack(MXP_EVENT.MEETING_BOOK_HEADER, {
              pageType: PAGE_TYPE.header
            });
          }
          setBtnLoader(false);
          value && resetValue();
        };
        const {
          event = "",
          slotDateTime = "",
          email = "",
          description = "",
          contactId = ""
        } = inputValue || {};
        let guestEmailIds = [];
        const temp =
          updatedGuests?.length > 0
            ? updatedGuests.forEach(item => {
                if (item?.email && !item?.invalid) {
                  guestEmailIds.push(item.email);
                }
              }) || []
            : [];
        let tempEmail = "";
        if (isGlobalHeader) {
          tempEmail = guestEmailIds?.shift();
        }
        let payload = {
          eventId: event?.id,
          email: isGlobalHeader ? tempEmail : email,
          description,
          slotDateTime,
          guests: guestEmailIds
        };
        if (!isGlobalHeader) {
          payload = { ...payload, contactId };
        }
        calendarBookMeetingState.bookMeeting(payload, callback);
      }
    }, 2000);
  };

  const btnActionList = [
    {
      id: "calendarBackBtn",
      name: "calendarBackBtn",
      btnText: "Back",
      type: "button",
      className: `calendarBackBtn`,
      click: backToEvents,
      show: showGuestInput
    },
    {
      id: "calendarScheduleEventBtn",
      name: "calendarScheduleEventBtn",
      btnText: "Book Meeting",
      type: "button",
      className: `calendarScheduleEventBtn ${btnLoader ? "loading" : ""}`,
      click: scheduleEvent,
      loader: btnLoader,
      disabled: isBtnDisabled(),
      show: showGuestInput
    }
  ];

  const nextBtnProps = {
    id: "calendarNextBtn",
    name: "calendarNextBtn",
    btnText: "Next",
    type: "button",
    className: `calendarNextBtn`,
    click: moveToNextStep,
    disabled: isBtnDisabled(),
    show: !showGuestInput
  };

  const getInit = () => {
    if (
      calendarBookMeetingState?.showPopup &&
      userDetail?.globalTimeZone?.length > 0
    ) {
      const timeZone =
        Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "UTC";
      const defaultTimeZone = userDetail?.globalTimeZone?.filter(
        item =>
          item?.name ===
          (timeZone === "Asia/Calcutta" ? "Asia/Kolkata" : timeZone)
      )?.[0]?.displayName;
      const { id: contactId = "", email = "" } =
        calendarBookMeetingState?.contact || {};
      setInputValue({
        ...inputValue,
        contactId,
        email,
        timeZone: timeZone === "Asia/Calcutta" ? "Asia/Kolkata" : timeZone,
        timezoneName: defaultTimeZone
      });
      calendarBookMeetingState.getCalendarEventsList();
    }
  };

  const computeEventsList = () => {
    if (calendarBookMeetingState?.eventsData) {
      let tempObj = {
        ...inputValue,
        event: {
          ...inputValue.event,
          ...calendarBookMeetingState.eventsData?.list?.[0]
        }
      };
      if (!eventPageLoader) {
        tempObj.date = new Date();
      }
      setInputValue(tempObj);
      setEventsList(calendarBookMeetingState.eventsData);
      setEventLoader(false);
      setEventPageLoader(false);
    }
  };

  const addGuestObj = {
    email,
    setEmail,
    backupEmail,
    setBackupEmail,
    emailList: inputValue?.guests || [],
    placeHolder: ["globalheader"].includes(
      calendarBookMeetingState?.pageType?.toLowerCase()
    )
      ? "Email"
      : "Add Guests",
    keyName: "guests",
    objKey: "guests",
    updateState: handleStateChange,
    pageType: "globalheader"
  };

  const descObj = {
    id: "calendarDescContent",
    placeholder: "Description",
    value: inputValue?.description || "",
    onChangeEvent: e => handleStateChange("description", e.target.value)
  };

  const timezoneInit = () => {
    if (userDetail?.globalTimeZone?.length > 0) {
      const timeZone =
        Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "UTC";
      const defaultTimeZone = userDetail?.globalTimeZone?.filter(
        item =>
          item?.name ===
          (timeZone === "Asia/Calcutta" ? "Asia/Kolkata" : timeZone)
      )?.[0]?.displayName;
      setInputValue({
        ...inputValue,
        timeZone: timeZone === "Asia/Calcutta" ? "Asia/Kolkata" : timeZone,
        timezoneName: defaultTimeZone
      });
    }
  };

  useEffect(() => {
    timezoneInit();
  }, [userDetail?.globalTimeZone]);

  useEffect(() => {
    getInit();
  }, [calendarBookMeetingState.showPopup]);

  useEffect(() => {
    computeEventsList();
  }, [calendarBookMeetingState.eventsData]);

  useEffect(() => {
    getGlobalTimeZones();
  }, []);

  return (
    (calendarBookMeetingState.showPopup && (
      <div className="bookMeetingPopup">
        <div className="card animated customzoomIn">
          <i className="material-icons closeIcon" onClick={cancelBooking}>
            close
          </i>
          <div
            className={`bookMeetingCont ${btnLoader ? "disableAction" : ""} ${
              showGuestInput ? "autoHeight" : ""
            }`}
          >
            <div className="sideMenuCont">
              <EventsList
                eventLoader={eventLoader}
                inputValue={inputValue}
                setInputValue={setInputValue}
                eventsList={eventsList}
                setSlotLoader={setSlotLoader}
                eventPageLoader={eventPageLoader}
                setEventPageLoader={setEventPageLoader}
                showGuestInput={showGuestInput}
              />
            </div>
            <div className="calendarContentBody">
              <div
                className={`calendarPopupCont ${
                  inputValue?.event?.id ? "enabled" : "disabled"
                } ${showGuestInput ? "oneColGrid" : ""}`}
              >
                {!showGuestInput ? (
                  <>
                    <div className="calendarFormInputCont">
                      <TimeZoneSelector
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        getFreeSlotByDate={getFreeSlotByDate}
                        updateInputState={handleStateChange}
                        err={timezoneErr}
                        setTimezoneErr={setTimezoneErr}
                      />
                      <div className="labelText">Select Date</div>
                      <CalendarDatePicker
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        getFreeSlotByDate={getFreeSlotByDate}
                      />
                    </div>
                    <div className="freeSlotCont">
                      <SlotsAvailable
                        slotLoader={slotLoader}
                        inputValue={inputValue}
                        updateInputState={handleStateChange}
                        nextBtnProps={nextBtnProps}
                      />
                    </div>
                  </>
                ) : (
                  <div className="addGuestDescCont">
                    <div className="addGuestCont">
                      <EmailInputBox {...addGuestObj} />
                    </div>
                    <TextArea {...descObj} />
                  </div>
                )}
              </div>
              {showGuestInput && btnActionList?.length > 0 && (
                <div className="calendarActionBtn">
                  {btnActionList?.map(item => (
                    <Button key={item?.id} {...item} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bgOverlay" />
      </div>
    )) ||
    ""
  );
});

export default Main;
