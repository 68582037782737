import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import Utils from "Utils/utils";
import reportsModuleState from "./state";

const activityInsightsAvgConfig = [
  {
    name: "email",
    id: "sentCount",
    color: "#00d2ad"
  },
  {
    name: "call",
    id: "totalCallsMade",
    color: "#0177ff"
  },
  {
    name: "task",
    id: "taskCompleted",
    color: "#ffcc3f"
  }
];

const ActivityInsightsAverage = observer(() => {
  const [analyticsAvg, setAnalyticsAvg] = useState({});

  const activeActivityInsightTabObj = {
    "7d": 7,
    today: 1,
    yesterday: 1,
    "30d": 30,
    thisMonth: 30,
    yesterday: 1,
    lastWeek: 7
  };

  const getAvgOfTempData = analyticsData => {
    let tempData = {};
    const days =
      activeActivityInsightTabObj[reportsModuleState?.activeActivityInsightTab];
    for (let item in analyticsData) {
      tempData[item] = Math.ceil(analyticsData?.[item] / days);
    }
    setAnalyticsAvg(tempData);
  };

  const constructAnalyticsAvg = () => {
    const data = reportsModuleState?.activityInsightData;
    let tempData = { sentCount: 0, totalCallsMade: 0, taskCompleted: 0 };
    if (data && Object.keys(data)?.length) {
      for (let item in data) {
        const {
          sentCount = 0,
          totalCallsMade = 0,
          taskCompleted = 0
        } = data?.[item];
        tempData["sentCount"] =
          tempData["sentCount"] + Math.ceil(sentCount) || 0;

        tempData["totalCallsMade"] =
          tempData["totalCallsMade"] + Math.ceil(totalCallsMade) || 0;

        tempData["taskCompleted"] =
          tempData["taskCompleted"] + Math.ceil(taskCompleted) || 0;
      }
    }
    getAvgOfTempData(tempData);
  };

  useEffect(() => {
    constructAnalyticsAvg();
  }, [reportsModuleState?.activityInsightData]);

  return (
    <div className="activityInsightsAvgWrapper">
      <section className="activityInsightsAvgHeader">
        <div className="activityInsightsAvgTitle">Average per day</div>
      </section>
      <div className="activityInsightsAvgSection">
        {!reportsModuleState?.activityInsightLoader
          ? activityInsightsAvgConfig?.map(item => {
              const value =
                analyticsAvg?.[item?.id] !== 1 ? `${item?.name}s` : item?.name;
              return (
                <div
                  className="activityInsightsAvg"
                  title={`${analyticsAvg?.[item?.id]} ${item?.name}`}
                  key={`${analyticsAvg?.[item?.id]} ${item?.name}`}
                >
                  <div
                    className="activityInsightsAvgTile"
                    style={{
                      backgroundColor: item?.color
                    }}
                  />
                  <span className="activityInsightsAvgText">{`${Utils.formatNumberToStringInUSFormat(
                    analyticsAvg?.[item?.id]
                  )} ${value}`}</span>
                </div>
              );
            })
          : Array.from({ length: 3 }, (value, index) => (
              <div className="borderLoader" key={`${value}-${index}`}>
                <div className="activityInsightsAvg">
                  <div className="activityInsightsAvgTile linear-background" />
                  <span className="activityInsightsText linear-background" />
                </div>
              </div>
            ))}
      </div>
    </div>
  );
});

export { ActivityInsightsAverage };
export default ActivityInsightsAverage;
