/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import userDetail from "Stores/userDetail";
import { SEQUENCE_STAGE_ICON } from "Model/model";
import ConstantObj from "Static/constant";
import { observer } from "mobx-react";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import PaywallUtils from "Utils/PaywallUtils";

const AddStageOverlay = observer((props = {}) => {
  const { stepLen = 0, daysFromStart = 1 } = props || {};
  const {
    taskEnabled = false,
    planName = "",
    inFreePlan = false
  } = userDetail?.userFeatureFlag || {};
  const [stepsArray, setStepsArray] = useState(ConstantObj.stepsArr);

  const toggleTooltip = (id, value) => {
    if (!taskEnabled && id !== "email") {
      let temp = [...stepsArray];
      temp = temp.map(item => {
        let tooltip = item?.id === id ? value : false;
        item.toolTip = tooltip;
        return item;
      });
      setStepsArray(temp);
    }
  };

  const toggleAction = (e, stepObj) => {
    const { id = "", sub = "" } = stepObj || {};
    if (id === "email" || taskEnabled) {
      let isTask = ["linkedin", "phone", "customTask"].includes(id);
      addStagesPopupState.setPopupValues({
        pageSource: "Sequences",
        btnText: isTask ? "Save" : "Next",
        currentStep: stepLen + 1,
        stageType: id,
        daysFromStart
      });
      addStagesPopupState.setShowPopup(true);
    }
  };

  const lockFeature = value => {
    return value !== "email" && !taskEnabled;
  };

  return (
    <div className="addStageOverlay">
      <div className="addStageBtn">
        <span className="btnText">Add Step</span>
        <div className="stageListCont">
          {(stepsArray?.length &&
            stepsArray.map((stepObj, idx) => {
              const {
                id = "",
                name = "",
                sub = "",
                className = "",
                toolTip = false
              } = stepObj || {};
              if (name && id) {
                return (
                  <div
                    className={className}
                    key={idx}
                    id={id}
                    onClick={e => toggleAction(e, stepObj)}
                    onMouseEnter={() => toggleTooltip(id, true)}
                    onMouseLeave={() => toggleTooltip(id, false)}
                  >
                    {!toolTip && SEQUENCE_STAGE_ICON[id]}
                    {toolTip && lockFeature(id) && (
                      <UpgradeIconTooltip
                        plan={PaywallUtils.getPlanName(planName, inFreePlan)}
                        type="sequenceAddTaskToSequence"
                        enableTooltip={true}
                        alignTooltip="rightBtm"
                      />
                    )}
                  </div>
                );
              }
            })) ||
            ""}
        </div>
      </div>
    </div>
  );
});

export default AddStageOverlay;
export { AddStageOverlay };
