import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as SettingsConfigIcon } from "Assets/svg/settingsConfig.svg";
import userDetail from "Stores/userDetail";
import StatusInfo from "./StatusInfo";

const CustomTrackingComponent = () => {
  const { domain = "" } = userDetail?.brandUrlInfo || {};
  const history = useHistory();

  const redirectToCustomURL = () => {
    history.push("/settings/customUrl");
  };

  return (
    <div className="customTrackingCont">
      <div className="customTrakingHeader">
        <h4>Custom Tracking</h4>
        {domain && <StatusInfo status={"All Good"} />}
      </div>
      {!domain && (
        <div className="configureCustomLink">
          <SettingsConfigIcon />
          <a className="customLink" onClick={redirectToCustomURL}>
            Configure It
          </a>
        </div>
      )}
    </div>
  );
};

export { CustomTrackingComponent };
export default CustomTrackingComponent;
