import React, { useEffect } from "react";
import { observer } from "mobx-react";
import DashboardState from "../mainState";
import taskModuleState from "./state";
import TaskDetail from "./TaskDetail";
import TaskFilter from "./TaskFilter";

const Main = observer(() => {
  useEffect(() => {
    taskModuleState.setFilterLoading(true);
    taskModuleState.setTaskDataLoading(true);
    taskModuleState.getTaskData(null, 1, true, null, null, {
      status: "ACTIVE"
    });
    taskModuleState.getTaskFilterData();
  }, [DashboardState?.memberId]);

  return (
    <section className="taskSectionWrapper">
      <div className="taskFilterWrapper">
        <span className="taskFilterHeading">Tasks Due</span>
        <TaskFilter />
      </div>
      <TaskDetail />
    </section>
  );
});

export { Main };
export default Main;
