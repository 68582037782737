import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { tagsStoreData } from "Stores/TagsData";
import CustomAccordion from "../../dataSearch/filterComponent/CustomAccordion";
import CustomAutoCompleteDropdown from "./CustomAutoCompleteDropdown";
import { TASK_FILTER_LIST } from "./filterComponentData";
import filterComponentState from "./filterComponentState";
import CustomContactField from "./CustomContactField";
import AddRemoveValue from "./AddRemoveValue";
import taskStoreData from "Stores/taskData";
import Button from "Components/common/Button";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";

const FilterHeader = observer(props => {
  const { resetState = () => {} } = props || {};

  return (
    <div className="filterComponentHeader">
      <span className="filterComponentTitle">Filters</span>
      <span
        className={`clearAllBtn ${
          filterComponentState.filterApplied ? "" : "disabled"
        }`}
        onClick={() => {
          resetState(true);
        }}
      >
        Clear all
      </span>
    </div>
  );
});

const FilterComponentAction = observer(props => {
  const {
    action = "",
    id = "",
    index = "",
    activeIndex = "",
    setActiveIndex = () => {},
    errMsg = "",
    setErrMsg = () => {}
  } = props;

  const placeholderObj = {
    sequenceIds: "Enter sequence name",
    contactStage: "Enter contact stage",
    tagIds: "Enter contact tags"
  };

  const optionListObj = {
    sequenceIds: filterComponentState.sequenceList,
    contactStage: filterComponentState.contactStages,
    tagIds: tagsStoreData.tagsData
  };

  const emptyTextObj = {
    sequenceIds: "There are no Sequences",
    contactStage: "There are no contact stages",
    tagIds: "There are no contact tags"
  };

  const getActionComponent = () => {
    switch (action) {
      case "autoCompleteDropdown":
        return (
          <CustomAutoCompleteDropdown
            {...props}
            id={id}
            optionList={optionListObj[id]}
            showDropdownMenu={index === activeIndex}
            setActiveIndex={setActiveIndex}
            placeHolderLabel={placeholderObj[id]}
            emptyText={emptyTextObj[id]}
          />
        );
      case "contactFields":
        return (
          <CustomContactField id={id} errMsg={errMsg} setErrMsg={setErrMsg} />
        );
    }

    return (
      <>
        <div className="filterComponentInputHeader">
          <div className="flexCenter">
            <span
              className={`material-icons ${
                filterComponentState?.filterComponentData?.[id]?.length >= 5
                  ? "disabled"
                  : ""
              }`}
            >
              control_point
            </span>
          </div>
        </div>
      </>
    );
  };
  return getActionComponent();
});

const AllFilters = observer(props => {
  const [activeIndex, setActiveIndex] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { tableLoadingFn = () => {}, filterLoadingFn = () => {} } = props || {};

  const resetState = (makeCalls = true) => {
    filterComponentState.setFilterComponentData({});
    filterComponentState.setSelectedFilteredList({});
    if (makeCalls) {
      tableLoadingFn(true);
      filterLoadingFn(true);
      taskStoreData.getTaskData();
      taskStoreData.getTaskFilterData();
    }
    let contactFields = {
      contactFields: [
        {
          fieldName: "",
          operator: "EQUALS",
          value: ""
        }
      ]
    };
    filterComponentState.setContactFieldsData({ ...contactFields });
  };

  const validateSelected = () => {
    let count = 0;
    const { contactFields = [] } = filterComponentState.contactFieldsData || {};
    if (contactFields?.length) {
      for (const obj of contactFields) {
        const { fieldName = "", operator = "EQUALS", value = "" } = obj || {};
        if (fieldName && operator && !value) {
          setErrMsg("contactFields");
          count++;
          break;
        }
      }
      return !(count > 0);
    }
    return true;
  };

  const applyFilter = async () => {
    if (validateSelected()) {
      const matchObj = {
        sequenceIds: "Sequence Filter",
        tagIds: "Tag Filter",
        contactStage: "Contact stage Filter",
        contactFields: "Contact fields Filter"
      };
      let properties = [];
      for (let key in filterComponentState.filterComponentData) {
        if (filterComponentState.filterComponentData?.[key]?.length) {
          properties.push(matchObj?.[key]);
        }
      }
      Utils.mixpanelTrack(MXP_EVENT.TASK_FILTER_CLICKED, {
        properties,
        pageType: "Tasks"
      });
      filterComponentState.setBtnLoading(true);
      tableLoadingFn(true);
      filterLoadingFn(true);
      taskStoreData.getTaskData();
      taskStoreData.getTaskFilterData();
    } else {
      setActiveIndex(2);
    }
  };

  const cancelFilter = () => {
    taskStoreData.setOpenFilter(false);
  };

  const actionList = [
    {
      className: "cancelFilter",
      method: cancelFilter,
      name: "Cancel",
      id: "cancelFilter"
    },
    {
      className: "applyFilter",
      method: applyFilter,
      name: "Apply Filter",
      id: "applyFilter",
      loader: filterComponentState.btnLoading
    }
  ];

  const getSequenceList = (pageNo = "") => {
    const payload = {
      pageNo: pageNo || filterComponentState?.sequenceCurrentPageNo,
      limit: 500,
      filters: {
        memberIds: [userDetail?.userInfo?.memberId]
      }
    };
    filterComponentState.listSequences(payload);
  };

  const initApiCalls = () => {
    filterComponentState.getContactStages();
    tagsStoreData.getAllTags();
  };

  const checkForFilterApplied = () => {
    let count = 0;
    if (
      filterComponentState.filterComponentData &&
      Object.keys(filterComponentState.filterComponentData)?.length
    ) {
      for (let key in filterComponentState?.filterComponentData) {
        const length = filterComponentState?.filterComponentData?.[key]?.length;
        if (length > 0) {
          return count + 1;
        }
      }
    }
    return count;
  };

  useEffect(() => {
    const count = checkForFilterApplied();
    filterComponentState.setFilterApplied(count > 0);
  }, [filterComponentState.filterComponentData]);

  useEffect(() => {
    getSequenceList();
  }, [filterComponentState.sequenceCurrentPageNo]);

  useEffect(() => {
    initApiCalls();
    return () => {
      taskStoreData.setOpenFilter(false);
      filterComponentState.setSequenceList([]);
    };
  }, []);

  return (
    <section className="filterComponentWrapper">
      <FilterHeader resetState={resetState} />
      <div className="filterComponentBody">
        {TASK_FILTER_LIST.map((item, index) => {
          const { name = "", action = "", id = "" } = item || {};
          return (
            <section className="filterCompContainer" key={id}>
              <div className="filterComponentBodyHeader">
                <CustomAccordion
                  title={name}
                  action={action}
                  index={index}
                  setActiveIndex={setActiveIndex}
                  activeIndex={activeIndex}
                  setMaxHeight={id === "contactFields"}
                  filterCount={
                    filterComponentState?.filterComponentData?.[id]?.length
                  }
                >
                  {
                    <>
                      <FilterComponentAction
                        {...item}
                        activeIndex={activeIndex}
                        index={index}
                        setActiveIndex={setActiveIndex}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                      />
                      <AddRemoveValue
                        defaultValue={
                          filterComponentState?.selectedFilteredList?.[id] || []
                        }
                        id={id}
                        store={filterComponentState}
                      />
                    </>
                  }
                </CustomAccordion>
              </div>
            </section>
          );
        })}
      </div>
      <div className="filterAction">
        {(actionList?.length > 0 &&
          actionList.map((item, index) => {
            const {
              className = "",
              name = "",
              id = "",
              method = () => {},
              loader = false
            } = item || {};
            return (
              <Button
                className={`${className} ${loader ? "loading" : ""}`}
                click={method}
                key={`${id}Action${index}`}
                loader={loader}
                btnText={name}
              ></Button>
            );
          })) ||
          ""}
      </div>
    </section>
  );
});

export { AllFilters };
export default AllFilters;
