import React, { useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import userDetail from "Stores/userDetail";
import { Utils } from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { ReactComponent as InstallIcon } from "Assets/svg/installBtnCRM.svg";
import { ReactComponent as InstalledIcon } from "Assets/svg/installedBtnCRM.svg";
import YoutubeTutorial from "Components/common/YoutubeTutorial";

const InstallButton = observer(props => {
  const history = useHistory();
  const { inFreePlan = false, isFeatureEnabled = false } =
    userDetail.userFeatureFlag || {};

  const isPluginAlreadyInstalled = userDetail?.pluginInstalled;

  const [showVideo, setVideo] = useState(false);

  const {
    data: { youtubeLink = "", title = "" }
  } = props;
  const hasUpgradeButton = inFreePlan || !isFeatureEnabled;

  const handleInstallClick = () => {
    if (!inFreePlan) {
      Utils.mixpanelTrack(MP_EVENT.PLUGIN_INSTALL_CLICKED, {
        type: title,
        pageType: Utils.getPageType() || ""
      });
      window.open(
        "https://chrome.google.com/webstore/detail/email-tracker-extension-f/nbmmhcmajjdfjobnmgnkbdhfpnbhpkef",
        "_blank",
        "noopener"
      );
    }
  };

  const handleUpgradeButton = () => {
    if (!Utils.isAdmin()) {
      Utils.showMessagePopup(
        `Please contact your account administrator to upgrade the ${title} CRM Integration`
      );
    } else {
      history && history.push("/pricing");
    }
  };

  const closeVideo = () => {
    setVideo(false);
  };

  return hasUpgradeButton ? (
    <div className="upgradeButton btn" onClick={handleUpgradeButton}>
      Upgrade
    </div>
  ) : (
    <>
      <div
        className={`installbtnCont btn ${!inFreePlan ? "" : "disabled"}`}
        onClick={isPluginAlreadyInstalled ? "" : handleInstallClick}
      >
        {isPluginAlreadyInstalled ? <InstalledIcon /> : <InstallIcon />}
        <div className="install">
          {isPluginAlreadyInstalled ? "Installed" : "Install"}
        </div>
      </div>
      {!inFreePlan && isPluginAlreadyInstalled && (
        <>
          {showVideo && (
            <YoutubeTutorial videoId={youtubeLink} closeCbk={closeVideo} />
          )}
        </>
      )}
    </>
  );
});

export default InstallButton;
export { InstallButton };
