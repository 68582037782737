import React from "react";

const SortIcon = props => {
  const { sequenceLength, sortIconStatus, cbk, sortParam, isActive } = props;
  return (
    (sequenceLength > 1 && (
      <i
        className={`material-icons ${
          sortParam === isActive ? "activeSort" : ""
        } ${sortIconStatus === "ASC" ? "sortAscending" : "sortDescending"}`}
        onClick={() => {
          cbk(sortParam, sortIconStatus);
        }}
      >
        sort
      </i>
    )) ||
    ""
  );
};

export default SortIcon;
export { SortIcon };
