import React from "react";
import { observer } from "mobx-react";
import ActivityInsights from "./ActivityInsights";
import ReportsFilter from "./ReportsFilter";
import reportsModuleState from "./state";
import ActivityInsightsAnalytics from "./ActivityInsightsAnalytics";
import ActivityInsightsAverage from "./ActivityInsightsAverage";
import ActivityInsightsUtils from "./utils";

const Main = observer(props => {
  const { firstDate = "", lastDate = "" } =
    ActivityInsightsUtils.getInsightsAnalyticsPayload(
      reportsModuleState?.activeActivityInsightTab
    );
  const updatedFirstDate = ActivityInsightsUtils.getDateFormat(firstDate);
  const updatedLastDate = ActivityInsightsUtils.getDateFormat(lastDate);

  return (
    <div className="reportsModuleWrapper">
      <div className="reportsHeader">
        <div className="flexCenter">
          <span className="reportsTitle">Activity</span>
          <span className="reportsDate">{`(from ${updatedFirstDate} to ${updatedLastDate})`}</span>
        </div>
        <ReportsFilter />
      </div>
      <div className="activityInsightsHeader">
        <ActivityInsightsAnalytics />
        <ActivityInsightsAverage />
      </div>
      <ActivityInsights />
    </div>
  );
});

export { Main };
export default Main;
