import React, { useEffect, useState } from "react";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import SingleSelectDropdown from "Pages/Dashboard/settings/Calendar/Pages/Events/CreateEvent/Components/SingleSelectDropdown";
import InputTextBox from "Components/common/InputTextBox";
import Button from "Components/common/Button";
import { toasterState } from "Components/common/toaster";
import { employeeCountDropdown, revenueRange } from "../AccountConstants";

class EditAccountPopupState {
  @observable showPopup = false;
  @observable account = false;
  @observable accountUpdated = false;

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
  };

  @action
  setAccount = (val = {}) => {
    this.account = val;
  };

  @action
  setAccountUpdated = (val = "") => {
    this.accountUpdated = val;
  };
}

const editAccountPopupState = new EditAccountPopupState();

const EditAccountInfoPopup = () => {
  const [state, setState] = useState({
    buttonLoader: false,
    inputVal: { ...editAccountPopupState?.account }
  });
  const changeInputVal = (id = "", data = "") => {
    if (id) {
      const stateObj = { ...state?.inputVal };
      stateObj[id?.toLowerCase() === "phonenumber" ? "phone" : id] = data || "";
      setState({ ...state, inputVal: stateObj });
    }
  };

  const getEditAccountPayload = () => {
    if (state.inputVal && Object.keys(state.inputVal).length) {
      const account = {};
      account.linkedinId = Utils.removeHttpFromString(
        state?.inputVal?.linkedinId || null
      );
      if (state.inputVal?.phone?.length) {
        account.phone = [state?.inputVal?.phone || ""];
      }
      account.id = state?.inputVal?.id || null;
      account.employeeCount = state?.inputVal?.employeeCount || null;
      account.revenue = state?.inputVal?.revenue || null;
      account.industry = state?.inputVal?.industry || null;
      account.address =
        [
          {
            id: 1,
            city: state?.inputVal?.city || null,
            state: state?.inputVal?.state || null,
            country: state?.inputVal?.country || null
          }
        ] || null;
      account.twitter = Utils.removeHttpFromString(
        state?.inputVal?.twitter || null
      );
      return account;
    }
  };

  const handleUpdateAccountResponse = (response = {}) => {
    const responseCode = response?.code || "";
    if (+responseCode === 200) {
      toasterState.setToastMsg(
        "Account has been updated successfully",
        "success"
      );
      const accountdata = response?.account;
      editAccountPopupState.setAccountUpdated(true);
      editAccountPopupState.setAccount(accountdata);
    } else if (responseCode === 403) {
      toasterState.setToastMsg(
        "You don't have editing access to this Account",
        "failure"
      );
    } else {
      toasterState.setToastMsg("Failed to updated account", "failure");
    }
    closeAction();
  };

  const closeAction = () => {
    setState({ ...state, buttonLoader: false });
    editAccountPopupState.setShowPopup(false);
  };

  const submitAction = async ev => {
    Utils.preventDefaultFn(ev);
    setState({ ...state, buttonLoader: true });
    const payload = getEditAccountPayload();
    const config = {
      url: URLS.updateAccount,
      method: "POST",
      data: payload
    };
    const response = await makeApi(config);
    if (response?.data) {
      handleUpdateAccountResponse(response?.data);
    } else {
      closeAction();
    }
  };

  const inputObj = [
    {
      name: "industry",
      id: "industry",
      value: state?.inputVal?.industry || "",
      text: "Industry",
      change: changeInputVal
    },
    {
      name: "employeeCount",
      id: "employeeCount",
      type: "select",
      options: employeeCountDropdown,
      value: state?.inputVal?.employeeCount || "",
      text: "No Of Employees",
      change: changeInputVal
    },
    {
      name: "revenue",
      id: "revenue",
      type: "select",
      value: state?.inputVal?.revenue || "",
      options: revenueRange,
      text: "Revenue",
      change: changeInputVal
    },
    {
      name: "phoneNumber",
      id: "phoneNumber",
      value: state?.inputVal?.phone || "",
      text: "Phone Number",
      change: changeInputVal
    },
    {
      name: "city",
      id: "city",
      value: state?.inputVal?.city || "",
      text: "City",
      change: changeInputVal
    },
    {
      name: "state",
      id: "state",
      value: state?.inputVal?.state || "",
      text: "State",
      change: changeInputVal
    },
    {
      name: "country",
      id: "country",
      value: state?.inputVal?.country || "",
      text: "Country",
      change: changeInputVal
    },
    {
      name: "domain",
      id: "domain",
      value: state?.inputVal?.domain || "",
      text: "Website",
      change: changeInputVal,
      readOnly: true,
      tabIndex: -1
    },
    {
      name: "linkedinId",
      id: "linkedinId",
      value: state?.inputVal?.linkedinId || "",
      text: "LinkedIn",
      change: changeInputVal
    },
    {
      name: "twitter",
      id: "twitter",
      value: state?.inputVal?.twitter || "",
      text: "Twitter",
      change: changeInputVal
    }
  ];

  const getUpdateAccount = () => {
    if (
      editAccountPopupState?.account &&
      Object?.keys(editAccountPopupState?.account)?.length
    ) {
      const { linkedInUrl: linkedinId = null, address = [] } =
        editAccountPopupState?.account || {};
      const { city = null, state = null, country = null } = address?.[0] || {};
      setState({
        ...state,
        inputVal: {
          ...editAccountPopupState?.account,
          city,
          state,
          country,
          linkedinId
        }
      });
    }
  };

  const buttonList = [
    {
      id: "accountEditCancelBtn",
      name: "accountEditCancelBtn",
      btnText: "Cancel",
      type: "button",
      className: `cancelEditAccountBtn`,
      click: closeAction
    },
    {
      id: "updateAccount",
      name: "updateAccount",
      btnText: "Update",
      type: "button",
      className: `updateAccountBtn ${state?.buttonLoader ? "loading" : ""}`,
      click: submitAction,
      loader: state?.buttonLoader
    }
  ];

  useEffect(() => {
    getUpdateAccount();
  }, [editAccountPopupState?.account]);

  return editAccountPopupState?.showPopup ? (
    <>
      <div className="editAccountSection">
        <div className="card animated customzoomIn">
          <div className="cardTitle">
            <h4>Edit Account</h4>
            <i className="material-icons closeBtn" onClick={closeAction}>
              close
            </i>
          </div>
          <div className="cardBody">
            {inputObj?.map((item, index) => {
              return ["select"]?.includes(item?.type?.toLowerCase()) ? (
                <SingleSelectDropdown
                  {...item}
                  displayName={"displayName"}
                  key={`editAccount${index}`}
                />
              ) : (
                <InputTextBox {...item} key={`editAccount${index}`} />
              );
            })}
          </div>
          <div className="cardFooter">
            {buttonList?.map(item => (
              <Button key={item?.id} {...item} />
            ))}
          </div>
        </div>
      </div>
      <div className="overlayAccountMask" />
    </>
  ) : (
    ""
  );
};

export { EditAccountInfoPopup, editAccountPopupState };
export default observer(EditAccountInfoPopup);
