import React, { useEffect, useState } from "react";
import { seqTemplatePopupState } from "./SeqTemplatePopupState";

const ProgressBar = props => {
  const { cbk = () => {} } = props || {};
  const [active, setActive] = useState(seqTemplatePopupState?.progressBarState);

  const handleProgressBarClick = index => {
    cbk(index);
  };

  useEffect(() => {
    setActive(seqTemplatePopupState?.progressBarState);
  }, [seqTemplatePopupState?.progressBarState]);

  return (
    <section className="progressBarWrapper">
      {Array.from({ length: 2 }, (value, index) => {
        return (
          <>
            <div
              className={`progressCircle ${
                active === index + 1 ? "active" : ""
              }`}
              onClick={() => handleProgressBarClick(index + 1)}
              key={`${index}-${index + 1}`}
            >
              {index + 1}
            </div>
            {index + 1 !== 2 && <div className="progressLine" />}
          </>
        );
      })}
    </section>
  );
};

export { ProgressBar };
export default ProgressBar;
