import globalCallState from "Stores/GlobalCallState";
import PaywallUtils from "Utils/PaywallUtils";
import { MXP_EVENT } from "Static/MixPanelEvents";
import userDetail from "Stores/userDetail";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import DialerServiceData from "Stores/DialerServiceStore";
import Utils from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import calendarState from "../Pages/Dashboard/settings/Calendar/State";
import { calendarBookMeetingState } from "Components/ContactCard/CalendarBookMeeting/State";

export const openDialerCard = async (pageType = "calls") => {
  const {
    dialerEnabled = false,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  if (!dialerEnabled) {
    PaywallUtils.upgradeUtilsFunction("dialer", false, planName, inFreePlan);
  } else {
    if (await checkTeamSubscriptionActive()) {
      if (dialerEnabled !== "") {
        if (dialerEnabled) {
          if (DialerServiceData?.dialerDefaultNo !== "purchaseNumber") {
            Utils.mixpanelTrack(MXP_EVENT.MAKE_CALL_CLICKED, {
              pageType
            });
            await DialerServiceData.getDailerToken();
            DialerServiceData.setDialerCallLogs(false);
            globalCallState.setPopupValues({
              ...globalCallState.popupValues,
              ...{
                pageType,
                isFromContactSection: true
              }
            });
            globalCallState.setShowCallSlider(true);
          } else {
            Utils.showUpgradeSettingsPopup("dialerPurchase", true, true);
          }
        } else {
          PaywallUtils.upgradeUtilsFunction(
            "callContact",
            false,
            planName,
            inFreePlan
          );
        }
      } else {
        toasterState.setToastMsg("Please wait...", "info");
      }
    }
  }
};

export const openCalendarPopup = async (
  history,
  pageType = "",
  contactInfo = []
) => {
  const {
    inFreePlan = false,
    planName = "",
    calendarIntegrationEnabled = false
  } = userDetail?.userFeatureFlag || {};
  if (Utils.isAccountConnected()) {
    const primaryInbox = userDetail?.connectedInboxes?.[0] || {};
    if (primaryInbox?.type === "smtp") {
      toasterState.setToastMsg(
        "Connect a Google or Microsoft as default inbox to access calendar",
        "fail"
      );
    } else {
      let calendarInboxConnected =
        await calendarState.getCalendarConnnectedAccount();
      if (calendarInboxConnected?.email) {
        if (calendarIntegrationEnabled) {
          calendarBookMeetingState.setPageType(pageType);
          if (contactInfo?.length) {
            calendarBookMeetingState.setContact(contactInfo?.[0]);
          }
          calendarBookMeetingState.setShowPopup(true);
        } else {
          PaywallUtils.upgradeUtilsFunction(
            "calendarUpgrade",
            false,
            planName,
            inFreePlan
          );
        }
      } else {
        toasterState.setToastMsg(
          "Please disconnect your default inbox and reconnect it to access calendar",
          "fail"
        );
      }
    }
  } else {
    Utils.showAddAccountPopup(
      { history },
      "Please connect your email account to access calendar"
    );
  }
};
