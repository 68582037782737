import React, { useState, useEffect, useContext, useReducer } from "react";
import { observer } from "mobx-react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { accountsState } from "../AccountsState";
import { userDetail } from "Stores/userDetail";
import taskStoreData from "Stores/taskData";
import { toasterState } from "Components/common/toaster";
import globalCallState from "Stores/GlobalCallState";
import { AccountsContext } from "../Main";
import InsertContactCard from "Components/ContactCard/InsertContactCard";
import FilterByDateTab from "Components/common/FilterByDateTab";
import DateFilterLoader from "Components/common/DateFilterLoader";
import TaskTable from "Pages/Dashboard/tasks/TaskTable";
import FilterNavBar from "../FilterNavBar";
import Button from "Components/common/Button";
import Utils from "Utils/utils";
import DateUtils from "Utils/DateUtils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { ReactComponent as StartIcon } from "Assets/svg/resume.svg";
import TaskUtils from "Utils/TaskUtils";
import { PAST_BULK_TASKS_TYPE, PRESENT_BULK_TASKS_TYPE } from "Model/model";
import BulkTasksResponseMessageHandler from "../../tasks/BulkTasksResponseMessageHandler";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import BulkActionButton from "../../tasks/BulkActionButton";
import ContactSelectedCount from "Components/common/ContactSelectedCount";
import filterComponentState from "../../tasks/FilterComponent/filterComponentState";
import EditBulkTasks from "../../tasks/EditBulkTasks";

const defaultFilterObj = [
  {
    name: "Due",
    id: "due",
    count: 0
  },
  {
    name: "Upcoming",
    id: "upcoming",
    count: 0
  },
  {
    name: "Completed",
    id: "completed",
    count: 0
  },
  {
    name: "Skipped",
    id: "skipped",
    count: 0
  }
];

const initialState = {
  selectAll: false,
  selectedTaskIds: [],
  allTasksSelected: false,
  showEditTaskPopup: false,
  notes: "",
  noteFocused: false,
  taskDueString: new Date()
};

const logReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_LOG_STATE":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const Main = withRouter(
  observer(() => {
    const { accountId = "" } = useParams();
    const history = useHistory();
    const { updateBreadCumb = () => {} } = useContext(AccountsContext);

    const [filterTabData, setFilterTabData] = useState([...defaultFilterObj]);
    const [filterTabLoading, setFilterTabLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [selectedTask, setSelectedTask] = useState(null);
    const [activeDateTab, setActiveDateTab] = useState("allTask");
    const [activeTab, setActiveTab] = useState("due");
    const [btnLoader, setBtnLoader] = useState(false);
    // Contact card state
    const [selectedContact, setSelecedContact] = useState({});
    const [showContactCard, setShowContactCard] = useState(false);
    const [slideAnim, setSlideAnim] = useState(false);
    const [showTaskTypeDropdown, setShowTaskTypeDropdown] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [logState, dispatch] = useReducer(logReducer, initialState);
    const [taskData, setTaskData] = useState({});
    const [editTasksLoader, setEditTasksLoader] = useState(false);

    const updateLogState = (newState = {}) => {
      dispatch({
        type: "UPDATE_LOG_STATE",
        payload: newState
      });
    };

    // Make API REQUEST
    const getCommonPayloadObject = (isInit = false) => {
      let payload = {
        timeZone: Utils.getCurrentUserTimeZone(),
        status: taskStoreData?.statusFilterValue?.status,
        memberIds: [userDetail?.userInfo?.memberId],
        accountId: [accountId]
      };
      if (isInit || ["due"]?.includes(activeTab)) {
        payload.dateFilter = {
          toDate: DateUtils.getTimeStamp(new Date(), "end")
        };
      }
      if (
        !isInit &&
        taskStoreData?.dateFilterValue &&
        Object.keys(taskStoreData?.dateFilterValue)?.length > 0
      ) {
        const { filterFromDate = null, filterToDate = null } =
          taskStoreData?.dateFilterValue || {};
        payload.dateFilter = {
          ...(filterFromDate && {
            fromDate: DateUtils.getTimeStamp(filterFromDate, "start")
          }),
          ...(filterToDate && {
            toDate: DateUtils.getTimeStamp(filterToDate, "end")
          })
        };
      }
      payload.taskType =
        (["total"]?.includes(taskStoreData?.filter) || !taskStoreData?.filter
          ? null
          : ["email", "manualemail"].includes(taskStoreData.filter)
          ? ["email", "manualEmail"]
          : TaskUtils.getTasktypeFilter(taskStoreData?.filter)) || null;
      return payload;
    };

    const getAnalyticsPayload = (isInit = false) => {
      return { ...getCommonPayloadObject(isInit), pageNo: 1 };
    };

    const getAnalyticsFilterData = async (isInit = false) => {
      !filterTabLoading && setFilterTabLoading(true);
      let payload = getAnalyticsPayload(isInit);
      let response = await accountsState.getAccountTasksFilterAnalytics(
        payload
      );
      if (
        response?.message?.message === "cancel" ||
        response?.message === "cancel"
      ) {
        console.error("Request cancelled");
      } else {
        const { status = "", data = {} } = response || {};
        if (
          +status === 200 &&
          data?.analyticsV2 &&
          Object.keys(data?.analyticsV2)?.length > 0
        ) {
          let tempList = [...filterTabData].map(item => {
            item.count =
              data?.analyticsV2?.[`${item?.id?.toLowerCase()}Count`] || 0;
            return item || {};
          });
          setFilterTabData(tempList);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later.",
            "fail"
          );
        }
        setFilterTabLoading(false);
      }
    };

    const getTablePayload = (isInit = false) => {
      let payload = {
        ...getCommonPayloadObject(isInit),
        limit: 20,
        pageNo: 1
      };
      return payload;
    };

    const getTableData = async (isInit = false) => {
      setTableLoading(true);
      let payload = getTablePayload(isInit);
      let response = await accountsState.getAccountTasksList(payload);
      if (
        response?.message?.message === "cancel" ||
        response?.message === "cancel"
      ) {
        console.error("Request cancelled");
      } else {
        const { status = "", data = {} } = response || {};
        if (+status === 200 && data && Object.keys(data)?.length > 0) {
          taskStoreData.setTaskData(data);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later.",
            "fail"
          );
        }
        setTableLoading(false);
      }
    };

    const reloadTasksPage = (isInit = false) => {
      getAnalyticsFilterData(isInit);
      getTableData(isInit);
    };

    const startAllTasks = () => {
      accountId && taskStoreData.setAccountId(accountId);
      setBtnLoader(true);
      setTimeout(() => {
        setBtnLoader(false);
        history.push("/tasks/start/");
      }, 100);
    };

    const handleTabFilterAction = (event = "") => {
      event && Utils.preventDefaultFn(event);
      if (event) {
        let listElem = event?.target?.closest("li") || "";
        if (listElem) {
          let key = listElem?.getAttribute("data-key") || "";
          if (key) {
            let today = new Date();
            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            let filterPayload = {
              status: ["due", "upcoming"].includes(key?.toLowerCase())
                ? "ACTIVE"
                : key?.toUpperCase(),
              key,
              newTimeStamp: new Date()
            };
            if (key?.toLowerCase() === "upcoming") {
              filterPayload = {
                ...filterPayload,
                filterFromDate: Utils.convertFromDateToLocalString(tomorrow)
              };
            }
            if (key?.toLowerCase() === "due") {
              filterPayload = {
                ...filterPayload,
                filterToDate: Utils.convertToDateToLocalString(today)
              };
            }
            if (["upcoming", "due"]?.includes(key?.toLowerCase())) {
              const { filterFromDate = null, filterToDate = null } =
                filterPayload || {};
              taskStoreData.setDateFilterValue(
                filterFromDate || filterToDate ? filterPayload : null
              );
            } else {
              taskStoreData.setDateFilterValue(null);
            }
            taskStoreData.setStatusFilterValue(filterPayload);
            taskStoreData.setStatus(key);
            taskStoreData.setActiveStatusTab(key);
            setActiveTab(key);
          }
          accountsState.setReloadTasksTable(true);
        }
      }
    };

    const getFilterByDate = (dateObj = "") => {
      taskStoreData.setDateFilterValue(
        dateObj?.filterFromDate || dateObj?.filterToDate
          ? { ...dateObj, filterApplied: true }
          : null
      );
      accountsState.setReloadTasksPage(true);
    };

    const handleTaskTypeAction = (value = false, id = "") => {
      let updatedSelectedOptions = { ...selectedOptions };

      if (id?.toLowerCase() === "total") {
        TASK_TYPE_LIST.forEach(task => {
          updatedSelectedOptions[task?.id] = value ? true : false;
        });
      } else {
        updatedSelectedOptions = { ...updatedSelectedOptions, [id]: value };

        if (!value) {
          updatedSelectedOptions.total = false;
        } else {
          const allSelected = TASK_TYPE_LIST.every(task => {
            return (
              task?.id?.toLowerCase() === "total" ||
              updatedSelectedOptions[task?.id]
            );
          });
          updatedSelectedOptions.total = allSelected;
        }
      }
      const selectedArr = Object?.keys(updatedSelectedOptions)?.filter(
        key => updatedSelectedOptions[key]
      );

      setSelectedOptions(updatedSelectedOptions);
      setSelectedTask(id);
      taskStoreData.setFilter(selectedArr);
    };

    // Contact Card
    const openContactCard = (contact = {}) => {
      if (contact && Object.keys(contact)?.length) {
        setSlideAnim(true);
        setShowContactCard(true);
        setSelecedContact(contact);
      }
    };

    const closeContactCard = () => {
      if (globalCallState.popupValues?.isContactCard) {
        globalCallState.setPopupValues({
          ...globalCallState.popupValues,
          isContactCard: false
        });
      }
      setSlideAnim(false);
      setTimeout(() => {
        setShowContactCard(false);
      }, 300);
    };

    //End Contact card

    // object goes here
    const tabFilterObj = {
      type: "accountTasks",
      filterArr: [...filterTabData],
      navBarLoading: filterTabLoading,
      activeTab: taskStoreData?.activeStatusTab || "due",
      filterAccountsCbk: handleTabFilterAction
    };

    const startAllTaskBtnObj = {
      id: "startAllAccountTasks",
      name: "startAllAccountTasks",
      btnText: `Start ${taskStoreData?.taskData?.totalTasks} Task${
        taskStoreData?.taskData?.totalTasks > 1 ? "s" : ""
      }`,
      svg: <StartIcon />,
      type: "button",
      className: `startAllAccountTasks ${filterTabLoading ? "loading" : ""}`,
      click: startAllTasks,
      loader: btnLoader
    };

    const contactCardObj = {
      pageType: "Account Tasks Page",
      reloadTaskPage: true,
      contact: selectedContact,
      isContactSlider: true,
      closeCbk: closeContactCard,
      handleSuccessCallback: () =>
        getAnalyticsFilterData(
          taskStoreData?.dateFilterValue?.filterApplied ? false : true
        ),
      cardType: "account_tasks",
      isReloadCbk: reloadTasksPage
    };

    const TASK_TYPE_LIST = [
      {
        value: "All",
        key: "total",
        id: "total",
        method: handleTaskTypeAction,
        checked: selectedOptions?.total
      },
      {
        value: "Manual Email",
        key: "email",
        id: "email",
        method: handleTaskTypeAction,
        checked: selectedOptions?.email
      },
      {
        value: "LinkedIn",
        key: "linkedin",
        id: "linkedin",
        method: handleTaskTypeAction,
        checked: selectedOptions?.linkedin
      },
      {
        value: "Phone Call",
        key: "phone",
        id: "phone",
        method: handleTaskTypeAction,
        checked: selectedOptions?.phone
      },
      {
        value: "Custom",
        key: "customTask",
        id: "customTask",
        method: handleTaskTypeAction,
        checked: selectedOptions?.customTask
      }
    ];
    // End

    const getInit = async (isInit = false) => {
      const initialOptions = {};
      TASK_TYPE_LIST.forEach(task => {
        initialOptions[task?.id] = false;
      });
      setSelectedOptions(initialOptions);
      Utils.mixpanelTrack(MXP_EVENT.ACCOUNTS.TASKS_TAB_VIEWED, {
        pageType: PAGE_TYPE["accounts"]
      });
      updateBreadCumb("tasks");
      reloadTasksPage(isInit);
    };

    const updateTasksState = (data = {}) => {
      if (data && Object.keys(data)?.length) {
        setTableLoading(false);
        filterComponentState.setBtnLoading(false);
        let tempData = { ...data };
        if (tempData?.tasks?.length > 0) {
          tempData = tempData.tasks.map(item => {
            item.checked = false;
            return item;
          });
        }
        setTaskData(data);
      }
    };

    const toggleRecord = (type = "", value = false) => {
      let tempData = { ...taskData } || {};
      let totalLen = tempData?.tasks?.length || 0;
      let count = 0;
      let ids = [];
      if (tempData?.tasks?.length > 0) {
        tempData = tempData?.tasks?.map(item => {
          if (type?.toLowerCase() === "all") {
            item.checked = value;
          } else if (item?.task?.id === type) {
            item.checked = value;
          }
          if (item?.checked) {
            ids.push(item?.task?.id);
            count += 1;
          }
          return item;
        });
      }
      setTaskData({ ...taskData, ...tempData });
      return { allChecked: +totalLen === +count, ids }; // verifying all records are selected return boolen value
    };

    // Bulk Task Operations
    const handleResponsePrompt = async (response = {}, type = "") => {
      const {
        background = false,
        totalCount = 0,
        bulkOperationResult = {},
        activeJob = false
      } = response || {};
      let content = "";
      if (activeJob && !bulkOperationResult) {
        content = `All the task${
          totalCount > 1 ? "s" : ""
        } are being inprogress, it may take few to several minutes to process.`;
      } else if (background) {
        content = `All the task${totalCount > 1 ? "s" : ""} are being ${
          PAST_BULK_TASKS_TYPE[type]
        }, it may take few to several minutes based on the number of task${
          totalCount > 1 ? "s" : ""
        } selected.`;
      } else if (
        bulkOperationResult &&
        Object.keys(bulkOperationResult)?.length > 0
      ) {
        content = (
          <div className="retryResponse">
            <BulkTasksResponseMessageHandler
              {...bulkOperationResult}
              type={type}
            />
          </div>
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "fail"
        );
      }
      confirmationPopupState?.showPopup &&
        confirmationPopupState.setShowPopup(false);
      if (content) {
        confirmationPopupState.setPopupValues({
          content,
          dynamicDom: true,
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => confirmationPopupState.setShowPopup(false)
        });
        confirmationPopupState.setShowPopup(true);
      }
    };

    const getBulkTasksPayload = (type = "", taskId = "") => {
      let payoad = {
        all: logState?.allTasksSelected || false,
        source: "ACCOUNTS",
        type,
        filter: {},
        accountId: [accountId],
        totalCount: taskId
          ? 1
          : (logState?.allTasksSelected
              ? taskStoreData?.taskData?.totalTasks
              : logState?.selectedTaskIds?.length) || 0
      };
      if (!logState?.allTasksSelected) {
        payoad.filter.taskIds = taskId
          ? [taskId]
          : logState?.selectedTaskIds || [];
      }
      if (type?.toUpperCase() === "EDIT_TASK_DUE_DATE") {
        const { taskDueString = new Date(), notes = "" } = logState || {};
        payoad.data = {
          taskDueString: Utils.createScheduleDate(taskDueString),
          notes,
          timeZone: Utils.getCurrentUserTimeZone()
        };
      }
      return payoad;
    };

    const performBulkAction = async (type = "", taskId = "") => {
      if (type && (logState?.selectedTaskIds?.length > 0 || taskId)) {
        let payload = getBulkTasksPayload(type, taskId);
        let res = await taskStoreData.performBulkTasksAction(payload);
        const { success = false, background = false } = res?.data || {};
        Utils.mixpanelTrack(MXP_EVENT.TASK_BULK_ACTION_PERFORMED, {
          actionType: type,
          pageType: PAGE_TYPE.task,
          payload,
          bulkAction: taskId ? "Single" : "Bulk",
          status: success ? "SUCESS" : "FAILED"
        });
        editTasksLoader && setEditTasksLoader(false);
        updateLogState({ ...initialState });
        if (success) {
          if (!background) {
            setFilterTabLoading(true);
            setTableLoading(true);
            await getTableData();
            await getAnalyticsFilterData(
              taskStoreData?.dateFilterValue?.filterApplied ? false : true
            );
          }
          handleResponsePrompt(res?.data, type, taskId);
        } else {
          confirmationPopupState.setShowPopup(false);
          toggleRecord("all", false);
          toasterState.setToastMsg(
            "Something went wrong. Please try again later.",
            "fail"
          );
        }
      }
    };

    const getSingleTaskEditDueNotesObject = (selectedTaskIds = []) => {
      if (selectedTaskIds?.length == 1) {
        let { task = {} } =
          [...taskData?.tasks].find(
            item => item?.task?.id === selectedTaskIds?.[0]
          ) || {};
        let { touchPoint = {}, taskDue = new Date(), note = "" } = task || {};
        return {
          taskDueString: new Date(
            touchPoint?.scheduleDate || taskDue || new Date()
          ),
          notes: note || touchPoint?.notes || ""
        };
      }
      return {};
    };

    const handleBulkActionConfirmPrompt = async (type = "", taskId = "") => {
      const { selectedTaskIds = [], allTasksSelected = false } = logState || {};
      if (type && (selectedTaskIds?.length > 0 || taskId)) {
        if (type?.toUpperCase() === "EDIT_TASK_DUE_DATE") {
          let existingValue =
            getSingleTaskEditDueNotesObject(selectedTaskIds) || {};
          updateLogState({
            ...logState,
            showEditTaskPopup: true,
            ...existingValue
          });
        } else {
          confirmationPopupState.setPopupValues({
            content: `Are you sure you want to ${
              PRESENT_BULK_TASKS_TYPE[type]
            } ${
              taskId || selectedTaskIds?.length === 1
                ? "1 selected Task"
                : `${
                    allTasksSelected
                      ? taskStoreData?.taskData?.totalTasks
                      : selectedTaskIds?.length
                  } selected Task${selectedTaskIds?.length > 1 ? "s" : ""}`
            }?`,
            actionBtnText: "Yes",
            loadingMsg: "Please wait...",
            callback: () => {
              performBulkAction(type, taskId);
            }
          });
          confirmationPopupState.setShowPopup(true);
        }
      }
    };

    //checkbox Action
    const handleInputCheckboxAction = (
      value = false,
      type = "",
      allTasksSelected = false
    ) => {
      let { allChecked = false, ids = [] } = toggleRecord(type, value);
      updateLogState({
        ...logState,
        selectedTaskIds: ids,
        allTasksSelected: allTasksSelected === "" ? false : allTasksSelected,
        selectAll: type?.toLowerCase() === "all" ? value : allChecked
      });
    };

    const resetSelectedCheckbox = (reloadPage = false) => {
      toggleRecord("all", false);
      updateLogState({ ...initialState });
    };

    useEffect(() => {
      getAnalyticsFilterData(
        taskStoreData?.dateFilterValue?.filterApplied ? false : true
      );
      getTableData();
    }, [taskStoreData?.filter]);

    useEffect(() => {
      if (accountsState?.reloadTasksTable) {
        getAnalyticsFilterData(
          taskStoreData?.dateFilterValue?.filterApplied ? false : true
        );
        getTableData();
        accountsState.setReloadTasksTable(false);
      }
    }, [accountsState?.reloadTasksTable]);

    useEffect(() => {
      if (accountsState?.reloadTasksPage) {
        reloadTasksPage();
        accountsState.setReloadTasksPage(false);
      }
    }, [accountsState?.reloadTasksPage]);

    //bulk use effect start

    useEffect(() => {
      updateLogState({ ...initialState });
      updateTasksState(taskStoreData?.taskData || {});
    }, [taskStoreData.taskData]);

    useEffect(() => {
      if (logState?.selectedTaskIds?.length > 0) {
        updateLogState({ ...initialState });
      }
    }, [taskStoreData?.activeStatusTab, activeTab]);

    //end

    useEffect(() => {
      getInit(true);
      return () => {
        resetSelectedCheckbox();
        setActiveTab("due");
        setActiveDateTab("allTask");
        setSelectedTask(null);
        setBtnLoader(false);
        setFilterTabData({});
        setTableLoading(true);
        setFilterTabLoading(true);
        setEditTasksLoader(false);
        updateLogState({ ...initialState });
      };
    }, []);

    return (
      <div className="accountTasksCont">
        <InsertContactCard
          animate={slideAnim}
          show={showContactCard}
          data={contactCardObj}
        />
        {logState?.showEditTaskPopup && (
          <EditBulkTasks
            logState={logState}
            initialState={initialState}
            editTasksLoader={editTasksLoader}
            updateLogState={updateLogState}
            action={performBulkAction}
            setEditTasksLoader={setEditTasksLoader}
          />
        )}
        <div className="accountTasks">
          <FilterNavBar {...tabFilterObj} />
          <div className="actionFilterCont">
            {logState?.selectedTaskIds?.length <= 0 ? (
              <>
                {!tableLoading &&
                  !filterTabLoading &&
                  ["due", "upcoming"].includes(activeTab?.toLowerCase()) &&
                  taskStoreData?.taskData?.totalTasks > 0 && (
                    <Button {...startAllTaskBtnObj} />
                  )}
                {(filterTabLoading || tableLoading) &&
                  ["due", "upcoming"].includes(activeTab?.toLowerCase()) && (
                    <div className="startAllTaskloader linear-background" />
                  )}
                {activeTab?.toLowerCase() === "due" && (
                  <div className={!filterTabLoading ? "d-flex" : "d-none"}>
                    <FilterByDateTab
                      pageType="ACCOUNT_TASK"
                      activeDueTab={activeDateTab}
                      isTaskPage={true}
                      setActiveDueTab={(val = "") => {
                        setActiveDateTab(val);
                        taskStoreData.setActiveDueTab(val);
                      }}
                      cbk={getFilterByDate}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="bulkActionCont">
                {!btnLoader && logState?.selectedTaskIds?.length > 0 && (
                  <ContactSelectedCount
                    selectedCount={logState?.selectedTaskIds?.length || 0}
                    totalCount={taskStoreData?.taskData?.totalTasks}
                    loading={btnLoader}
                    allContactSelected={logState?.allTasksSelected}
                    setAllContactSelected={val =>
                      handleInputCheckboxAction(val, "all", val)
                    }
                    resetCbk={resetSelectedCheckbox}
                    selectedType="Task"
                    pageType="task"
                  />
                )}
                <BulkActionButton
                  action={handleBulkActionConfirmPrompt}
                  logState={logState}
                  updateLogState={updateLogState}
                />
              </div>
            )}
            {filterTabLoading && ["due"].includes(activeTab?.toLowerCase()) && (
              <DateFilterLoader />
            )}
          </div>
          <TaskTable
            taskData={taskStoreData?.taskData}
            pageType="ACCOUNT"
            loading={tableLoading}
            tableLoadingFn={setTableLoading}
            filterLoadingFn={setFilterTabLoading}
            activeTab={taskStoreData.filter}
            openContactCard={openContactCard}
            accountId={accountId}
            logState={logState}
            reloadAccountFilterAnalytics={() =>
              getAnalyticsFilterData(
                taskStoreData?.dateFilterValue?.filterApplied ? false : true
              )
            }
            hasAccountFilter={["due", "upcoming"].includes(
              activeTab?.toLowerCase()
            )}
            showTaskTypeDropdown={showTaskTypeDropdown}
            setShowTaskTypeDropdown={setShowTaskTypeDropdown}
            selectedTask={
              selectedTask?.toLowerCase() === "total" ? "All" : selectedTask
            }
            taskTypeList={[...TASK_TYPE_LIST]}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            handleInputCheckboxAction={handleInputCheckboxAction}
            singleTaskAction={handleBulkActionConfirmPrompt}
          />
        </div>
      </div>
    );
  })
);

export default Main;
