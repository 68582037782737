import React, { useState, useEffect, useRef } from "react";

const LinearProgressLoader = ({
  count = 0,
  progress = 0,
  showTitle = true
}) => {
  const [displayProgress, setDisplayProgress] = useState(0);
  const intervalRef = useRef(null);

  const updateProgress = () => {
    const step = (progress - displayProgress) / 100; // Calculate the step increment
    const updateInterval = 100; // Update every 100 ms
    clearInterval(intervalRef.current); // Clear any existing interval
    intervalRef.current = setInterval(() => {
      setDisplayProgress(prevProgress => {
        if (prevProgress < progress) {
          return Math.min(prevProgress + step, progress);
        } else {
          clearInterval(intervalRef.current);
          return progress;
        }
      });
    }, updateInterval);
  };

  useEffect(() => {
    updateProgress();
    return () => clearInterval(intervalRef.current); // Cleanup interval on unmount
  }, [progress]);

  useEffect(() => {
    clearInterval(intervalRef.current);
  }, []);

  return (
    <div className="progressContainer">
      <div className="progressMessage">
        {displayProgress <= 0 && showTitle && (
          <h2>Initializing your contact upload.</h2>
        )}
        {displayProgress > 0 && showTitle && (
          <h2>{`Uploading contacts in progress... ${displayProgress.toFixed(
            1
          )}%`}</h2>
        )}
      </div>
      <div className="linearProgressBar">
        <div className="backgroundProgress"></div>
        <div
          className={`linearProgress ${
            displayProgress > 10 ? "applyTransition" : ""
          }`}
          style={{ width: `${displayProgress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default LinearProgressLoader;
