import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { AccountsContext } from "../Main";
import ContactTimeline from "Pages/Dashboard/contacts/ContactTimeline/ContactTimeline";
import { accountsState } from "../AccountsState";

const Main = observer(() => {
  const { accountId = "" } = useParams();
  const { updateBreadCumb = () => {} } = useContext(AccountsContext);

  useEffect(() => {
    updateBreadCumb("overview");
  }, []);

  return (
    <div className="accountsOverview">
      <ContactTimeline isTaskPage={true} accountId={accountId} />
    </div>
  );
});

export default Main;
export { Main };
