import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import DateUtils from "Utils/DateUtils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import FilterByDateTab from "Components/common/FilterByDateTab";
import { trackingStore } from "./trackingStore";

const ViewComponent = observer(() => {
  const getFilterByDate = (value = {}) => {
    const { filterFromDate = "", filterToDate = "" } = value || {};
    let fromDate = DateUtils.getTimeStamp(filterFromDate, "start") || "";
    let toDate = DateUtils.getTimeStamp(filterToDate, "end") || "";
    let dateFilter = { fromDate, toDate };
    trackingStore.setDateFilter(dateFilter);
    Utils.mixpanelTrack(MXP_EVENT.WEBSITE_TRACKING_DATE_FILTER_APPLIED, {
      pageType: PAGE_TYPE["tracking"]
    });
    trackingStore.setCurrentPage(1);
  };

  const setActiveDateFilter = (value = "") => {
    if (!["alltask"].includes(value?.toLowerCase())) {
      trackingStore.setActiveDateTab(value);
    }
  };

  return (
    <div className="viewSection">
      <div className="viewTitle">Pageviews</div>
      <FilterByDateTab
        activeDueTab={trackingStore?.activeDateTab}
        setActiveDueTab={setActiveDateFilter}
        cbk={getFilterByDate}
        isTaskPage={false}
        disableFilter={!trackingStore?.isWebsiteTrackingEnabled}
        pageType="websitetracking"
      />
    </div>
  );
});

export { ViewComponent };
export default ViewComponent;
