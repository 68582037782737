import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import DialerServiceData from "Stores/DialerServiceStore";
import contactStoreData from "Stores/contactData";
import dialerStoreData from "Stores/DialerData";
import DropDownMenu from "Components/common/DropDownMenu";
import TextArea from "Components/common/TextArea";
import ToggleBtnType from "Components/common/ToggleBtnType";
import Button from "Components/common/Button";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import { callsStore } from "Stores/Calls";
import {
  dialerLogsPopupState,
  defaultDialerLogSettings
} from "./DialerLogsAfterCallEndPopup";
import Utils from "Utils/utils";
import CallsUtils from "Utils/CallsUtils";
import { MXP_EVENT } from "Static/MixPanelEvents";

const DialerLogsAfterCallEnd = observer(props => {
  const {
    contactName = "",
    phoneNumber = "",
    isContactHasNo,
    callSid = "",
    logContactDetail: logContact,
    isFromTask = false,
    disableToggle = false,
    logBtnLoader = false,
    submitCallback = () => {},
    markAsCompleteFunctionality = () => {},
    closeDialer = () => {}
  } = props || {};

  const [loader, setLoader] = useState(logBtnLoader);
  const [callOutcome, setCallOutcome] = useState({ value: "Select" });
  const [notes, setNotes] = useState(DialerServiceData.dialerCallNotes);
  const [showDropdownMenu, setDropdownMenu] = useState(false);
  const [logContactDetail, setLogContactDetail] = useState(logContact);
  const [err, setErr] = useState("");

  const getCallOutcome = (_, item = { value: "Select" }) => {
    err && setErr("");
    setCallOutcome(item);
    setDropdownMenu(false);
  };

  const showDropdown = () => {
    setDropdownMenu(true);
  };

  const hideCallOutcomeDropdown = () => {
    setDropdownMenu(false);
  };

  const changeInputVal = e => {
    setNotes(e.currentTarget.value);
  };

  const getLogToggle = value => {
    setLogContactDetail(value);
  };

  const getPayload = () => {
    return {
      callDispositionId: callOutcome?.id,
      notes,
      callSid,
      logContactDetail
    };
  };

  const resetAndCloseDialer = () => {
    setLoader(false);
    DialerServiceData.setDialerCallLogs(false);
    closeDialer(false);
  };

  const markAsComplete = async () => {
    if (callOutcome?.id) {
      if (loader) {
        return;
      }
      const data = getPayload();
      setLoader(true);
      await markAsCompleteFunctionality({
        ...data,
        ...{ callSid, logContactDetail }
      });
      setLoader(false);
    } else {
      setErr("Please select a call outcome");
    }
  };

  const updateCallLogs = async () => {
    if (dialerLogsPopupState?.dialerLogSettings?.id) {
      if (callOutcome?.id) {
        setLoader(true);
        if (loader) {
          return;
        }
        const config = {
          url: URLS?.bulkLogCalls || "",
          method: "POST",
          data: {
            filter: {
              ids: [dialerLogsPopupState?.dialerLogSettings?.id]
            },
            notes,
            dispositionIdToUpdate: callOutcome?.id
          }
        };
        let res = await makeApi(config);
        if (res?.data?.status === "success") {
          toasterState.setToastMsg("Call log updated successfully", "success");
          callsStore.setIsLogUpdated(true);
          resetAndCloseDialer();
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
        dialerLogsPopupState.setDialerLogSettings({
          ...defaultDialerLogSettings
        });
        setLoader(false);
      } else {
        setErr("Please select a call outcome");
      }
    }
  };

  const callLogsFormSubmit = async () => {
    if (callSid) {
      if (callOutcome?.id) {
        setLoader(true);
        if (loader) {
          return;
        }
        const data = getPayload();
        const config = {
          url: URLS.dialerLogCall,
          method: "POST",
          data
        };
        let res = await makeApi(config);
        if (res?.data?.status === "success") {
          logContactDetail &&
            res?.data?.contacts?.contacts?.[0] &&
            contactStoreData.updateContactRowData(
              res.data.contacts.contacts[0]
            );
          toasterState.setToastMsg("Call log updated successfully", "success");
          disableToggle && callsStore.setIsLogUpdated(true);
          resetAndCloseDialer();
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
        dialerLogsPopupState.setDialerLogSettings({
          ...defaultDialerLogSettings
        });
        setLoader(false);
      } else {
        setErr("Please select a call outcome");
      }
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
  };

  const getManualCallLogPayLoad = () => {
    return {
      contactId: dialerLogsPopupState?.contactData?.id,
      callDispositionId: callOutcome?.id,
      notes
    };
  };

  const manualLogCall = async () => {
    if (callOutcome?.id) {
      setLoader(true);
      const data = getManualCallLogPayLoad();
      const config = {
        url: URLS.manualLogCall,
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (+res?.status === 200) {
        toasterState.setToastMsg(
          "Call logged successfully for the contact",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
      dialerLogsPopupState.setShowLogCallPopup(false);
      dialerLogsPopupState.setActionType("");
      setLoader(false);
    } else {
      setErr("Please select a call outcome");
    }
  };

  const handleClick = () => {
    if (callOutcome?.id) {
      if (!loader) {
        Utils.mixpanelTrack(MXP_EVENT.LOGGED_CALL_MADE, {
          type: dialerLogsPopupState?.actionType || "SINGLE_CONTACT"
        });
        if (["BULK_CONTACTS"].includes(dialerLogsPopupState?.actionType)) {
          submitCallback("LOG_CALL", {
            callDispositionId: callOutcome?.id,
            notes
          });
        } else if (
          ["SINGLE_CONTACT"].includes(dialerLogsPopupState?.actionType)
        ) {
          manualLogCall();
        } else if (isFromTask) {
          markAsComplete();
        } else if (dialerLogsPopupState?.showEditCallLogs) {
          updateCallLogs();
        } else {
          callLogsFormSubmit();
        }
      }
    } else {
      setErr("Please select a call outcome");
    }
  };

  const textAreaObj = {
    id: "callNotes",
    labelText: "Call Notes",
    value: notes,
    onChangeEvent: changeInputVal
  };

  const buttonObj = {
    id: "logCallBtn",
    name: "logCallBtn",
    btnText: "Log Call",
    icon: false,
    iconName: "",
    type: "button",
    className: `logCallBtn ${loader ? "loading" : ""}`,
    loader: loader,
    click: handleClick
  };

  const toogleObj = {
    firstText: "",
    defaultValue: logContactDetail,
    cbk: getLogToggle,
    lastText: "Yes"
  };

  let options = CallsUtils.getLogOutcomeDropdown(
    dialerStoreData?.stageList,
    getCallOutcome
  );

  useEffect(() => {
    setLoader(logBtnLoader);
  }, [logBtnLoader]);

  useEffect(() => {
    setNotes(DialerServiceData?.dialerCallNotes || "");
  }, [DialerServiceData.dialerCallNotes]);

  return (
    <div className="dsLogAfterEndSection">
      <div className="wrapInputLabel">
        <p className="label">Call Outcome</p>
        <div className="callOutcomeBtn" onClick={showDropdown}>
          <span
            className="text"
            style={{
              opacity: callOutcome?.value?.toLowerCase() === "select" ? 0.7 : 1
            }}
          >
            {callOutcome?.value}
          </span>
          <i className="material-icons">
            {!showDropdownMenu ? "keyboard_arrow_down" : "keyboard_arrow_up"}
          </i>
          {err && <span className="errMsgText">{err}</span>}
        </div>
        {showDropdownMenu && (
          <>
            <DropDownMenu options={options} direction="center" />
            <div
              className="overlayCallOutcomeBg"
              onClick={hideCallOutcomeDropdown}
            />
          </>
        )}
      </div>
      <div className="callNotesWrap">
        <TextArea {...textAreaObj} />
      </div>
      {isContactHasNo &&
        !disableToggle &&
        !dialerLogsPopupState?.actionType &&
        !dialerLogsPopupState?.showEditCallLogs && (
          <div className="saveContactLogNo">
            <p>
              Would you like to save{" "}
              <span className="contactNo">{phoneNumber}</span> number
              {contactName && contactName !== "--" && (
                <span className="contactName"> to {contactName}</span>
              )}
              ?
            </p>
            <ToggleBtnType {...toogleObj} />
          </div>
        )}
      <div className="logCallBtnWrapper">
        <Button {...buttonObj} />
      </div>
    </div>
  );
});

export default DialerLogsAfterCallEnd;
export { DialerLogsAfterCallEnd };
