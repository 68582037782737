import React from "react";

const InputTag = props => {
  const {
    id = "",
    type = "text",
    placeholder = "",
    name = "",
    isReadOnly = false,
    onBlurEvent = () => {},
    value = "",
    keyUp = () => {}
  } = props || {};

  return (
    <input
      id={id}
      type={type}
      className="inputFieldCont"
      placeholder={placeholder}
      required
      name={name}
      readOnly={isReadOnly ? true : false}
      onChange={e => onBlurEvent(id, e.target.value)}
      value={value}
      onKeyUp={e => keyUp(e)}
    />
  );
};

export default InputTag;
export { InputTag };
