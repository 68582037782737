/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ReportEmailInsights from "./ReportEmailInsights";
import ReportSequenceInsights from "./ReportSequenceInsights";

const ReportsInsights = () => {
  return (
    <div className="reportInsights">
      <div className="wrapInsightRow">
        <ReportEmailInsights />
        <ReportSequenceInsights />
      </div>
    </div>
  );
};

export default ReportsInsights;
export { ReportsInsights };
