/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import Lottie from "react-lottie";
import { callsStore } from "Stores/Calls";
import { userDetail } from "Stores/userDetail";
import Utils from "Utils/utils";
import EmptyReport from "Pages/Dashboard/reports/EmptyReport";
import NoActivityFound from "Pages/Dashboard/reports/NoActivityFound";
import BarChartLoader from "Utils/LottieFiles/BarChart.json";

const options = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  yaxis: {
    labels: {
      formatter: value => {
        return Math.floor(value);
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      horizontal: false
    }
  },
  legend: {
    position: "top",
    horizontalAlign: "right"
  },
  fill: {
    opacity: 1
  }
};

const CallDataInsights = observer(({ stageActiveList = [] }) => {
  let location = useLocation();

  let contactAdded = userDetail?.userFeatureFlag?.noOfContactsAddedInTeam;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BarChartLoader
  };

  let series = [];
  let colors = [];

  let iteration = [...stageActiveList].forEach(ele => {
    const { displayName: name = "", color = "" } = ele || {};
    colors.push(color);
    series.push({ name, data: [] });
  });

  const [loader, setLoader] = useState(callsStore.inputValues.analyticsLoader);
  const [chartData, setChartData] = useState(series);
  const [chartOptions, setChartOptions] = useState(options);

  const constructChartData = (list = []) => {
    if (list) {
      const tempData = [...chartData];
      tempData.map(item => (item.data = []));
      const tempXaxisDate = [];
      list.forEach(item => {
        const { date = "", outcome = {} } = item || {};
        let tempList = [...stageActiveList].forEach((item, index) => {
          tempData[index]["data"].push(
            Math.ceil(outcome[item?.displayName] || 0)
          );
        });
        tempXaxisDate.push(date);
      });
      const {
        fromDate = "",
        to = "",
        days = ""
      } = callsStore?.filterPayload || {};
      let startDate = Utils.formatDateHypen(fromDate);
      let endDate = Utils.formatDateHypen(to);
      const hasStartDate = tempXaxisDate.indexOf(startDate) !== -1;
      const hasEndDate = tempXaxisDate.indexOf(endDate) !== -1;
      list?.length &&
        days > 1 &&
        !hasStartDate &&
        tempXaxisDate.push(startDate);
      list?.length &&
        days > 1 &&
        !hasEndDate &&
        tempXaxisDate.splice(0, 0, endDate);
      tempData.map(item => {
        list?.length && days > 1 && !hasStartDate && item.data.push(0);
        list?.length && days > 1 && !hasEndDate && item.data.splice(0, 0, 0);
      });
      setChartOptions({
        ...options,
        xaxis: {
          type: days > 1 ? "datetime" : "category",
          categories:
            (days > 1 && tempXaxisDate) ||
            tempXaxisDate.map(item => Utils.formatDate(item)),
          labels: {
            datetimeFormatter: { month: "dd MMM" }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: (days > 20 && "40%") || "20%"
          }
        },
        colors
      });
      setChartData(tempData);
      callsStore.setInputValues({
        teamSummaryLoader: false
      });
    }
  };

  useEffect(() => {
    constructChartData(callsStore.inputValues.analyticsData);
  }, [callsStore.inputValues.analyticsData]);

  useEffect(() => {
    setLoader(callsStore.inputValues.analyticsLoader);
  }, [callsStore.inputValues.analyticsLoader]);

  return (
    <div className="chartCard">
      <h4 className="cardTitle">Insights</h4>
      <div className="chartContent">
        {(!loader &&
          contactAdded &&
          chartData?.length &&
          chartData[0]?.data?.length && (
            <div className="stackedBarChart">
              <Chart
                options={chartOptions}
                series={chartData}
                type="bar"
                height={350}
              />
            </div>
          )) ||
          ""}
        {loader && (
          <div className="chartLoader">
            <Lottie
              options={defaultOptions}
              width={200}
              style={{ margin: "0 auto" }}
              speed={1}
            />
          </div>
        )}
        {!loader && !contactAdded && (
          <EmptyReport
            pathname={location?.pathname}
            className="emptyEmailInsight"
          />
        )}
        {(!loader && chartData?.length && !chartData[0]?.data?.length && (
          <NoActivityFound />
        )) ||
          ""}
      </div>
    </div>
  );
});

export default CallDataInsights;
export { CallDataInsights };
