import React from "react";

const OutboxCardLoader = () => {
  return (
    <div className="outboxCardWrapper outboxCardLoading">
      <div className="recipientInfoSection">
        <div className="recipientInfo">
          <span className="icon linear-background" />
          <div className="flexCenter linear-background" />
          <div className="recipientEmail linear-background" />
        </div>
      </div>
      <div className="emailInformation">
        <div className="subject">
          <span className="icon linear-background" />
          <div className="flexCenter linear-background" />
        </div>
      </div>
      <div className="sequenceSectionPanel">
        <p className="sequenceName linear-background"></p>
      </div>
      <div className="scheduledDate">
        <span className="icon linear-background" />
        <div className="flexCenter linear-background" />
      </div>
      <div className="scheduledTime">
        <span className="icon linear-background" />
        <div className="flexCenter linear-background" />
      </div>
      <div className="contactOwner">
        <span className="linear-background"></span>
      </div>
    </div>
  );
};

export { OutboxCardLoader };
export default OutboxCardLoader;
