import React, { useState } from "react";
import contactStoreData from "Stores/contactData";
import ContactUploadErrorIcon from "Assets/png/contactUploadError.png";
import Button from "Components/common/Button";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

const defaultPopupValues = {
  fieldResponse: {},
  close: () => {},
  skipAndAddContactCbk: () => {},
  setSkipLoader: false,
  data: {},
  back: () => {},
  title: "",
  isCustomTask: false
};

class MissingFieldsValidationPopupState {
  @observable showPopup = false;
  @observable popupValues = defaultPopupValues;

  @action
  setShowPopup = value => {
    this.showPopup = value;
  };

  @action
  setPopupValues = obj => {
    this.popupValues = { ...this.popupValues, ...obj };
  };

  @action
  resetPopup = () => {
    this.showPopup = false;
    this.popupValues = defaultPopupValues;
  };
}

const missingFieldsValidationPopupState =
  new MissingFieldsValidationPopupState();

const MissingFieldsValidationPopup = observer(() => {
  const {
    fieldResponse = {},
    close = () => {},
    data = {},
    skipAndAddContactCbk = () => {},
    title = "Add Contact",
    isCustomTask = false,
    back = () => {}
  } = missingFieldsValidationPopupState.popupValues || {};

  const {
    missingFields = [],
    uploadedCount = 0,
    validCount = 0
  } = fieldResponse || {};

  const [skipLoader, setSkipLoader] = useState(false);

  const constructAllFieldsMissingDom = () => {
    const missingFieldsLen = missingFields?.length || 0;
    if (missingFieldsLen) {
      const fieldText = missingFieldsLen > 1 ? "fields are" : "field is";
      return (
        <div className="allFieldsMissingCont">
          <div className="messageCont">
            <div className="message">
              The following mandatory {fieldText} missing
            </div>
            {getMissingFieldsDom(missingFields)}
          </div>
        </div>
      );
    }
  };

  const getMissingFieldsDom = (missingFields = []) => {
    const missingFieldsLen = missingFields.length;
    return (
      <div className="missingFieldsCont">
        {missingFields &&
          missingFields.map((value, index) => {
            return (
              <span key={`missingField ${index}`} className="missingField">
                {missingFieldsLen > 1 && (
                  <span className="indexValue"> {index + 1}.&nbsp;</span>
                )}
                <span className="value">{value}</span>
                {index + 1 !== missingFieldsLen ? (
                  <span className="delimitter">|</span>
                ) : (
                  ""
                )}
              </span>
            );
          })}
      </div>
    );
  };

  const partialiFieldsMissingDom = () => {
    const missingFieldsLen = missingFields?.length || 0;
    const contactsText = uploadedCount > 1 ? "contacts" : "contact";
    const fieldText = missingFieldsLen > 1 ? "fields" : "field";
    const invalidContactCount = Math.abs(uploadedCount - validCount);
    let customTaskMsg = `You have uploaded ${uploadedCount} ${contactsText} and don't have the following mandatory ${fieldText}`;
    let msg = `You have <b>uploaded ${uploadedCount} ${contactsText}</b> ${
      missingFieldsLen > 0
        ? `and <b>${invalidContactCount} of them</b> don't have the following mandatory ${fieldText}`
        : `with <b>${invalidContactCount}</b> duplicate ${
            invalidContactCount > 1 ? "entries" : "entry"
          }`
    }`;

    return (
      <div className="partialFieldsMissingCont">
        <div className="messageCont">
          <div
            className="message"
            dangerouslySetInnerHTML={{
              __html: isCustomTask ? customTaskMsg : msg
            }}
          />
          {getMissingFieldsDom(missingFields)}
        </div>
      </div>
    );
  };

  const getMessage = () => {
    if (fieldResponse && Object.keys(fieldResponse)?.length) {
      return validCount === 0
        ? constructAllFieldsMissingDom()
        : partialiFieldsMissingDom();
    }
  };

  const getSkipContactBtn = () => {
    const {
      validEmails = "",
      missingFields = [],
      uploadedCount = 0,
      validCount = 0
    } = fieldResponse || {};
    const invalidContactCount = Math.abs(uploadedCount - validCount);
    const missingFieldsLen = missingFields?.length || 0;

    const cancelUpload = () => {
      confirmationPopupState.setPopupValues({
        content:
          "Are you sure you want to cancel this upload?<br> Any changes made will be lost.",
        actionBtnText: "Yes",
        callback: close
      });
      confirmationPopupState.setShowPopup(true);
    };

    const skipAndUpload = async () => {
      setSkipLoader(true);
      const { contacts = [], payload = {} } = data || {};
      const { contactIds = [] } = payload || {};
      const dataObj = data || {};
      let newContacts =
        (contacts?.length &&
          contacts.filter(item => validEmails.indexOf(item.email) !== -1)) ||
        [];
      let newContactIds =
        (newContacts?.length &&
          newContacts
            .filter(item => validEmails.indexOf(item.email) !== -1)
            .map(obj => obj.id)) ||
        [];
      if (!contactIds || contactIds?.length === 0) {
        delete dataObj.payload.filter;
        dataObj.payload.totalCount = newContactIds?.length || 0;
      }
      dataObj.contacts = newContacts;
      dataObj.payload.contactIds = newContactIds;
      let resData = await contactStoreData.validateDailyLimitForContacts(
        dataObj.payload
      );
      resData && skipAndAddContactCbk({ ...dataObj, ...resData });
      setSkipLoader(false);
    };

    return (
      <>
        <div className="cancelText">
          You can{" "}
          <span className="canceLink" onClick={e => cancelUpload(e)}>
            cancel
          </span>{" "}
          the upload, add the missing fields and upload again
        </div>
        <div className="orText">(or)</div>
        <div className="btnCont">
          <Button
            id="skipAndUploadContact"
            name="skipAndUploadContact"
            type="button"
            btnText={`Skip the ${
              missingFieldsLen > 0
                ? `contact${
                    invalidContactCount > 1 ? "s" : ""
                  } with missing fields`
                : `duplicate contact${invalidContactCount > 1 ? "s" : ""}`
            } and proceed`}
            className={`skipAndUploadContact ${skipLoader ? "loading" : ""}`}
            loader={skipLoader}
            click={skipAndUpload}
          />
        </div>
      </>
    );
  };

  const getOkContactBtn = () => {
    let textPlural = (missingFields?.length > 1 && "s") || "";

    return (
      <>
        <div className="reuploadMsg">
          Please update your Contact{textPlural} with the missing field
          {textPlural} and try again
        </div>
        <div className="btnCont">
          <Button
            id="reUploadContact"
            name="reUploadContact"
            type="button"
            className="reUploadContact"
            btnText="Ok"
            loader={false}
            click={close}
          />
        </div>
      </>
    );
  };

  return (
    (missingFieldsValidationPopupState.showPopup && (
      <div className="fieldValidatorMsgPopup">
        <div className="fieldValidatorMsgCard">
          <h3>{title}</h3>
          <div className="cardContent">
            <img
              src={ContactUploadErrorIcon}
              alt=""
              className="contactErrorIcon"
            />
            <div className="errorMessage">
              {getMessage()}
              {fieldResponse && (
                <div className="bottomCont">
                  {validCount > 0 ? getSkipContactBtn() : getOkContactBtn()}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="fieldValidatorOverlay" />
      </div>
    )) ||
    ""
  );
});

export default MissingFieldsValidationPopup;
export { MissingFieldsValidationPopup, missingFieldsValidationPopupState };
