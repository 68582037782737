import React, { useContext } from "react";
import { TemplatesContext } from "./TemplateModule";
import NoTemplateIcon from "Assets/Templates/no_template.png";
import { ReactComponent as TemplateIcon } from "Assets/svg/templates.svg";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import FeatureDemoVideo from "Components/common/FeatureDemoVideo";
import { DriverMaster } from "Components/driver/Driver";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const CreateNewTemplate = props => {
  const { isTemplatesCreationAllowed } = useContext(TemplatesContext);
  const {
    planName = "",
    inFreePlan = false,
    noOfTemplatesEligible = 0
  } = userDetail?.userFeatureFlag;

  const { cbk = () => {} } = props || {};

  const triggerCreateTemplatePopup = () => {
    DriverMaster.killDriver();
    if (!isTemplatesCreationAllowed) {
      PaywallUtils.upgradeUtilsFunction(
        "templates",
        false,
        planName.toLowerCase(),
        inFreePlan,
        { templateCount: noOfTemplatesEligible }
      );
    } else {
      isTemplatesCreationAllowed && cbk("New");
    }
  };

  return (
    <div className="empty-templates">
      <img src={NoTemplateIcon} alt="No Templates Found" />
      <h6>Create your first template</h6>
      <div className="createBtn">
        <button
          id="newTemplate"
          name="newTemplate"
          type="button"
          onClick={triggerCreateTemplatePopup}
        >
          <TemplateIcon />
          <span className="btn-name">New Template</span>
        </button>
      </div>
      <FeatureDemoVideo
        name={"Create Template"}
        linkText={"How to Create template on Salesgear"}
        type={"templates"}
      />
    </div>
  );
};

export default CreateNewTemplate;
export { CreateNewTemplate };
