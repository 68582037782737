import React from "react";
import { ReactComponent as ExportIcon } from "Assets/svg/exportCsv.svg";
import { ReactComponent as SequenceIcon } from "Assets/svg/sequence.svg";

const IconDescription = () => {
  return (
    <div className="iconDescriptionWrapper">
      <div className="exportIconWithDesc">
        <span className="descText" style={{ marginRight: "10px" }}>
          Use this icon at the top right to export contacts.
        </span>
        <div className="arrow">
          <div className="line"></div>
          <div className="leftPoint"></div>
        </div>
        <div className="exportIconWrapper">
          <ExportIcon className="exportIcon" />
        </div>
      </div>
      <div className="addToSeqWithDesc">
        <span className="descText">
          Use this icon at the top right to add contacts to sequences to send
          automated emails and make phone call.
        </span>
        <div className="arrow">
          <div className="upLine"></div>
          <div className="upPoint"></div>
        </div>
        <div className="addToSequenceWrapper">
          <SequenceIcon />
        </div>
      </div>
      <div className="addToListIconWithDesc">
        <div className="addToListWrapper">
          <i className="material-icons addToListIcon">playlist_add</i>
        </div>
        <div className="arrow">
          <div className="line"></div>
          <div className="rightPoint"></div>
        </div>
        <span className="descText" style={{ marginLeft: "10px" }}>
          Use this icon at the top right to add the contacts to a list.
        </span>
      </div>
    </div>
  );
};

export { IconDescription };
export default IconDescription;
