import React from "react";
import { observer } from "mobx-react";
import { createCallStagePopupState } from "./CreateCallStagePopup";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { COLOR_CODES } from "Pages/Dashboard/settings/Calls/Constant";
import CallsUtils from "Utils/CallsUtils";

const CreateNewStage = observer(({ stageList = [] }) => {
  const handleCreateNewStage = () => {
    if (stageList?.length >= 15) {
      confirmationPopupState.setPopupValues({
        content: `Oops! You can only create a maximum of 15 call states.`,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      let tempCode = CallsUtils.getStageColorCodes([...stageList]);
      let colorCodes = [...COLOR_CODES].filter(item => {
        if (
          tempCode?.length < 1 ||
          (tempCode?.length > 0 && !tempCode.includes(item))
        ) {
          return item;
        }
      });
      createCallStagePopupState.setPopupValues({
        colorCodes: [...new Set([...colorCodes])]
      });
      createCallStagePopupState.setShowPopup(true);
    }
  };

  return (
    <div
      className="callStageCard createNewStageCard"
      onClick={handleCreateNewStage}
    >
      <div className="stageName">Create new state</div>
      <div className="actionIcons">
        <span className="iconBg">
          <i className="material-icons">add</i>
        </span>
      </div>
    </div>
  );
});

export default CreateNewStage;
