import React, { useState } from "react";
import DropdownMenu from "Components/common/DropDownMenu";
import { ReactComponent as FilterIcon } from "Assets/svg/filter.svg";

export const FilterDropdownByTeamMemberStatus = (props = {}) => {
  const { callback = () => {} } = props || {};
  const [showDropdown, setShowDropdown] = useState(false);

  const handleFilterAction = (ref, item) => {
    setShowDropdown(false);
    callback(item.name);
  };

  const dropdownOptions = [
    {
      value: "All",
      key: "all",
      name: "ALL",
      method: handleFilterAction
    },
    {
      value: "Active",
      key: "active",
      name: "ACTIVE",
      method: handleFilterAction
    },
    {
      value: "Invited",
      key: "invited",
      name: "INVITED",
      method: handleFilterAction
    }
    // {
    //   value: "Inactive",
    //   key: "inactive",
    //   name: "INACTIVE",
    //   method: handleFilterAction
    // },
    // {
    //   value: "Rejected",
    //   key: "rejected",
    //   name: "REJECTED",
    //   method: handleFilterAction
    // }
  ];

  return (
    <div className="filterDropdownByTeamMemberStatus">
      <div
        className="filterDropdownBtn"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <FilterIcon />
        <span className="text">Filter by</span>
        <i className="material-icons filterIcon">
          {showDropdown ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </div>
      {showDropdown && (
        <>
          <DropdownMenu
            options={dropdownOptions}
            referenceId="filterByTeamMemberStatus"
          />
          <div
            className="filterDropdownOverlay"
            onClick={() => setShowDropdown(false)}
          />
        </>
      )}
    </div>
  );
};

export default FilterDropdownByTeamMemberStatus;
