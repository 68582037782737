import React, { memo } from "react";

const CardsLoader = memo(({ length = 10 }) => {
  return Array.from({ length }, (value, index) => (
    <div className="callStageCard callStageCardLoader" key={index}>
      <div className="stageName linear-background" />
      <div className="actionIcons">
        {index >= 2 && <div className="iconBg linear-background" />}
        <div className="iconBg linear-background" />
      </div>
    </div>
  ));
});

export default CardsLoader;
