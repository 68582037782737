const filterComponentData = [
  {
    name: "Company Name",
    id: "companyDomain",
    action: "autoCompleteMultiSelectDropdown"
  },
  {
    name: "Industry",
    id: "industry",
    action: "multiSelectDropdown",
    hasAccordion: false
  },
  {
    name: "Location",
    id: "location",
    action: "autoCompleteMultiSelectDropdown"
  },
  {
    name: "Company head count",
    id: "employeeCount",
    action: "multiSelectDropdown",
    hasAccordion: false,
    options: [
      {
        id: "0 - 25",
        value: "0 to 25",
        show: true
      },
      {
        id: "25 - 100",
        value: "25 to 100",
        show: true
      },
      {
        id: "100 - 250",
        value: "100 to 250",
        show: true
      },
      {
        id: "250 - 1000",
        value: "250 to 1K",
        show: true
      },
      {
        id: "1K - 10K",
        value: "1K to 10K",
        show: true
      },
      {
        id: "10K - 50K",
        value: "10K to 50K",
        show: true
      },
      {
        id: "50K - 100K",
        value: "50K to 100K",
        show: true
      },
      {
        id: "> 100K",
        value: "100K+",
        show: true
      }
    ]
  },
  {
    name: "Company Revenue",
    id: "revenue",
    action: "multiSelectDropdown",
    hasAccordion: false,
    options: [
      {
        id: "$0 - 1M",
        value: "$0 to $1M",
        show: true
      },
      {
        id: "$1 - 10M",
        value: "$1M to $10M",
        show: true
      },
      {
        id: "$10 - 50M",
        value: "$10M to $50M",
        show: true
      },
      {
        id: "$50 - 100M",
        value: "$50M to $100M",
        show: true
      },
      {
        id: "$100 - 250M",
        value: "$100M to $250M",
        show: true
      },
      {
        id: "$250 - 500M",
        value: "$250M to $500M",
        show: true
      },
      {
        id: "$500 - 1B",
        value: "$500M to $1B",
        show: true
      },
      {
        id: "> $1B",
        value: "$1B+",
        show: true
      }
    ]
  },
  {
    name: "Keyword",
    id: "keywords",
    action: "autoCompleteMultiSelectDropdown"
  },
  {
    name: "Title",
    id: "title",
    action: "inputBox",
    hasAccordion: false
  },
  {
    name: "Department",
    id: "department",
    action: "multiSelectDropdown",
    hasAccordion: false,
    showSearchPanel: false,
    options: [
      {
        id: "Engineering",
        value: "Engineering",
        show: true
      },
      {
        id: "Finance & Administration",
        value: "Finance & Administration",
        show: true
      },
      {
        id: "Human Resources",
        value: "Human Resources",
        show: true
      },
      {
        id: "IT & IS",
        value: "IT & IS",
        show: true
      },
      { id: "Marketing", value: "Marketing", show: true },
      { id: "Operations", value: "Operations", show: true },
      { id: "Sales", value: "Sales", show: true },
      { id: "Support", value: "Support", show: true },
      { id: "Other", value: "Other", show: true }
    ]
  },
  {
    name: "Level",
    id: "level",
    action: "multiSelectDropdown",
    hasAccordion: false,
    showSearchPanel: false,
    options: [
      { id: "C-Level", value: "C-Level", show: true },
      { id: "VP-Level", value: "VP-Level", show: true },
      { id: "Director-Level", value: "Director-Level", show: true },
      { id: "Manager-Level", value: "Manager-Level", show: true },
      { id: "Staff", value: "Staff", show: true },
      { id: "Other", value: "Other", show: true }
    ]
  },
  {
    name: "Contact Name",
    id: "name",
    action: "inputBox",
    hasAccordion: false
  },
  {
    name: "Email Delivery Quality",
    id: "confidenceScore",
    action: "multiSelectDropdown",
    hasAccordion: false,
    showSearchPanel: false,
    options: [
      {
        id: 95,
        value: "High",
        show: true,
        checked: false,
        dotColor: "#3953fb"
      },
      {
        id: 85,
        value: "Medium",
        show: true,
        checked: false,
        dotColor: "#A0ACFB"
      },
      {
        id: 75,
        value: "Low",
        show: true,
        checked: false,
        dotColor: "#d3d3d3"
      }
    ]
  },
  {
    name: "Skip contacts already owned",
    id: "excludeTeamOwns",
    action: "toggle",
    hasAccordion: false
  }
];

export { filterComponentData };
export default filterComponentData;
