import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";

class SequenceTemplateData {
  @observable
  seqTemplateClicked = false;

  @observable
  selectedPredefinedTemplate = null;

  @observable
  predefinedTemplateList = null;

  @action
  setSeqTemmplateClicked = value => {
    this.seqTemplateClicked = value;
  };

  @action
  setSelectedPredefinedTemplate = value => {
    this.selectedPredefinedTemplate = value;
  };


  @action
  getSeqPredefinedTemplate = async () => {
    try {
      const config = {
        url: URLS.getDefaultSeqTemplates ? URLS.getDefaultSeqTemplates : "",
      };
      let res = await makeApi(config);
      if(res && res.data) {
        this.predefinedTemplateList = res.data;
      } else {
        this.predefinedTemplateList = [];
      }
    } catch(e) {
      console.error("Getting predefined sequence template error", e);
    }
  }
}

export default new SequenceTemplateData();
const sequenceTemplateStore = new SequenceTemplateData();
export { sequenceTemplateStore };
