import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import useDebounce from "Components/common/hooks/useDebounce";
import CheckBox from "Components/common/CheckBox";
import Button from "Components/common/Button";
import SearchInputBox from "Components/common/SearchInputBox";
import { verifyBulkActionPerformedAndAllow } from "../DataSearchUtils";
import { dataSearchState } from "../state";

const AutoCompleteMultiSelectDropdown = observer(props => {
  const {
    id = "",
    name = "",
    showSearchPanel = true,
    setTableLoading = () => {},
    setActiveIndex = () => {},
    showApplybtn = false
  } = props || {};

  const searchBoxRef = useRef(null);
  const [searchValue, setSearchValue] = useState(null);
  const [searchLoader, setSearchLoader] = useState(true);
  const debouncedValue = useDebounce(searchValue, 600);
  const [filteredList, setFilteredList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [clearSearchInput, setClearSearchInput] = useState(false);

  const mergeById = (firstArr = [], secondArr = []) =>
    firstArr?.length > 0 &&
    firstArr.map(firstItem => {
      const updatedSecondItem = secondArr.find(
        secondItem => secondItem.id === firstItem.id && secondItem
      ) || [...secondArr];
      return {
        ...updatedSecondItem,
        ...firstItem
      };
    });

  const mergeFilterList = data => {
    const updatedAlreadySelectedFilteredList =
      (dataSearchState?.selectedFilteredList?.[id]?.length > 0 &&
        dataSearchState?.selectedFilteredList?.[id]) ||
      [];
    var mergedObj = mergeById(data, updatedAlreadySelectedFilteredList);
    return mergedObj;
  };

  const updateKeywordResponse = (tempResponse = []) => {
    if (tempResponse?.length > 0) {
      return tempResponse.map(item => ({
        name: item,
        id: item,
        group: "keywords",
        searchText: [item]
      }));
    }
  };

  const searchFilterList = async () => {
    setSearchLoader(true);
    setSearchValue(debouncedValue);
    const performAction = async () => {
      setShowDropdown(true);
      setClearSearchInput(false);
      const lowercasedValue = debouncedValue?.toLowerCase();
      const { data: responseData = [] } =
        await dataSearchState.searchFilterWithAPI(lowercasedValue, id);
      const tempResponseData =
        id?.toLowerCase() === "keywords"
          ? updateKeywordResponse(responseData)
          : responseData;
      const updatedMergeList =
        dataSearchState?.selectedFilteredList?.[id]?.length > 0
          ? mergeFilterList(tempResponseData)
          : tempResponseData;
      setFilteredList(updatedMergeList);
      setSearchLoader(false);
    };
    if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
      setClearSearchInput(false);
      verifyBulkActionPerformedAndAllow(performAction, () =>
        setClearSearchInput(true)
      );
    } else {
      performAction();
    }
  };

  const handleSelectAction = (checked = "", uId = "") => {
    let tempData = [...filteredList];
    tempData = tempData.map(item => {
      const { id = "" } = item || {};
      if (id === uId) {
        item.checked = checked;
      }
      return item;
    });
    setFilteredList(tempData);
  };

  const handleAddCustomItem = () => {
    let tempFilteredList = [
      ...[...(filteredList || [])],
      {
        checked: true,
        id: searchValue,
        name: searchValue,
        group: "keywords",
        searchText: [searchValue]
      }
    ];
    setFilteredList(tempFilteredList);
    handleApplySelectionButton(tempFilteredList);
  };

  const handleApplySelectionButton = (tempFilteredList = []) => {
    setShowDropdown(false);
    setClearSearchInput(true);
    let tempData = tempFilteredList?.length
      ? tempFilteredList
      : [...filteredList];
    const updatedFilteredData = tempData?.filter(item => item?.checked);
    const updatedAlreadySelectedFilteredList =
      (dataSearchState?.selectedFilteredList?.[id]?.length > 0 &&
        dataSearchState?.selectedFilteredList?.[id]) ||
      [];

    const checkedForChanges = (filteredList = [], newData = []) => {
      let result = filteredList.filter(o1 => {
        return newData.some(o2 => {
          return o1?.id === o2?.id; // return the ones with equal id
        });
      });
      return result;
    };

    const getMergedData = (arr1 = [], arr2 = []) => {
      const mergedData = arr1.map(data => ({
        ...data,
        ...arr2.find(newData => newData?.id === data?.id)
      }));
      return mergedData;
    };

    const updatedNewData = checkedForChanges(
      tempFilteredList?.length ? tempFilteredList : filteredList,
      updatedAlreadySelectedFilteredList
    );

    const mergedData = getMergedData(
      updatedAlreadySelectedFilteredList,
      updatedNewData
    );

    const updatedSelectedFilteredList =
      updatedAlreadySelectedFilteredList?.length > 0
        ? [...updatedFilteredData, ...mergedData]
        : [...updatedFilteredData];

    const newData = [
      ...new Set(updatedSelectedFilteredList.map(d => d?.id))
    ].map(id => {
      return {
        ...updatedSelectedFilteredList.filter(d => d?.id === id)?.[0]
      };
    });
    dataSearchState.setSelectedFilteredList({
      ...dataSearchState.selectedFilteredList,
      [id]: [...newData?.filter(item => item?.checked)]
    });

    const updatedFilteredList = newData
      ?.map(item => {
        if (item.checked) {
          return id === "companyDomain"
            ? item?.primaryDomain
            : { [item?.group]: item?.searchText };
        }
      })
      ?.filter(Boolean);

    if (id === "companyDomain") {
      dataSearchState.setFilterComponentData({
        ...dataSearchState.filterComponentData,
        [id]: updatedFilteredList
      });
    } else {
      const finalList = updatedFilteredList.reduce((acc, curr) => {
        const key = Object.keys(curr)?.[0];
        const found = acc.find(i => i?.[key]);
        if (!found) {
          acc.push(curr);
        } else {
          found[key] = [...found[key], ...curr[key]];
        }
        return acc;
      }, []);
      dataSearchState.setFilterComponentData({
        ...dataSearchState.filterComponentData,
        ...Object.assign({}, ...finalList)
      });
    }
    setTableLoading(true);
    dataSearchState.setCurrentPage(1);
    dataSearchState.setIsFromSeoPage(false);
    dataSearchState.searchPageData();
  };

  const resetState = () => {
    setShowDropdown(false);
    setClearSearchInput(true);
    setFilteredList([]);
    setActiveIndex("");
    setSearchValue("");
  };

  const controlApplyBtnVisiblity = () => {
    return showApplybtn && showDropdown && filteredList?.length > 0;
  };

  const checkApplyCustomBtn = () => {
    return !filteredList?.length > 0 &&
      searchValue &&
      ["keywords"].includes(id?.toLowerCase())
      ? "customBtn"
      : "";
  };

  const triggerSearchUsingDebounce = () => {
    if (!debouncedValue) {
      setShowDropdown(false);
    }
    if (searchValue !== null) {
      searchFilterList();
    }
  };

  useEffect(() => {
    triggerSearchUsingDebounce();
  }, [debouncedValue]);

  return (
    <>
      <span className="horizontalBorderLine" />
      <div className="autoCompleteDropdownListWrapper">
        {showSearchPanel && (
          <div className="searchInputBoxWrap">
            <SearchInputBox
              valueCbk={setSearchValue}
              type="text"
              placeholder={`Search ${name}`}
              searchBoxRef={searchBoxRef}
              clearSearchInput={clearSearchInput}
              searchIconAlign={"right"}
              hasCloseIcon={false}
            />
          </div>
        )}
        {showDropdown && (
          <>
            <div
              className={`dropdownListWrapper ${id} ${checkApplyCustomBtn()}`}
            >
              <div className="dropdownList">
                {filteredList?.length > 0
                  ? filteredList?.map((item, index) => {
                      const {
                        id: itemId = "",
                        name = "",
                        checked = false,
                        primaryDomain = ""
                      } = item || {};
                      return (
                        <div className="dropdownItems" key={itemId + index}>
                          <CheckBox
                            key={itemId}
                            name={
                              id === "companyDomain" ? (
                                <div className="companyDomainWrapper">
                                  <span className="companyName">{name}</span>
                                  <span className="primaryDomain">
                                    {primaryDomain}
                                  </span>
                                </div>
                              ) : (
                                name
                              )
                            }
                            nameAsHTML={id === "companyDomain"}
                            id={itemId}
                            checked={checked}
                            cbk={handleSelectAction}
                          />
                        </div>
                      );
                    })
                  : debouncedValue &&
                    !searchLoader &&
                    ["keywords"].includes(id?.toLowerCase()) && (
                      <div
                        className="addCustomItem"
                        onClick={handleAddCustomItem}
                      >
                        Add <span className="customName">{searchValue}</span>
                      </div>
                    )}
              </div>
              {controlApplyBtnVisiblity() && (
                <Button
                  id="applySelection"
                  name="applySelection"
                  type="button"
                  className="applySelectionBtn"
                  btnText="Apply"
                  click={() => handleApplySelectionButton()}
                />
              )}
            </div>
            <div
              className="dropdownOverlay"
              onClick={() => {
                resetState();
              }}
            />
          </>
        )}
      </div>
    </>
  );
});

export { AutoCompleteMultiSelectDropdown };
export default AutoCompleteMultiSelectDropdown;
