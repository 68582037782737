/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { reportSummaryHeader } from "./ReportConstant";
import Utils from "Utils/utils";
import { reportStore } from "Stores/Reports";
import { userDetail } from "Stores/userDetail";
import ReportsTableLoader from "./ReportsTableLoader";
import EmptyReport from "./EmptyReport";
import NoActivityFound from "./NoActivityFound";

const ReportSummary = observer(() => {
  const { calendarEnabled = false } = userDetail?.userInfo || {};
  let {
    noOfContactsAddedInTeam: contactAdded = 0,
    calendarIntegrationEnabled = false
  } = userDetail?.userFeatureFlag || {};

  let location = useLocation();
  const [reports, setReportList] = useState(true);
  const [loader, setLoader] = useState(true);

  const getFullName = value => {
    const { firstName, lastName } = value;
    return (
      (firstName && lastName && `${firstName} ${lastName}`) ||
      firstName ||
      lastName ||
      "--"
    );
  };

  const highLightMe = (email, length) => {
    return (
      (length > 1 && email === userDetail?.userInfo?.email && "highLightMe") ||
      ""
    );
  };

  const hasUserContactAdded = () => {
    return (
      reports?.length &&
      reports.filter(
        item =>
          item?.contactsAdded > 0 ||
          item?.emailsSent > 0 ||
          item?.noOfCampaignsGotReply > 0 ||
          item?.taskCompleted > 0 ||
          item?.linkedInTaskCompleted > 0 ||
          item?.phoneStageResponse ||
          item?.meetingBookedCount > 0
      ).length
    );
  };

  const getTotalCallsMade = (obj = {}) => {
    let totalCount = 0;
    if (obj && Object.keys(obj)?.length > 0) {
      Object.values(obj).map(val => {
        totalCount += val || 0;
      });
    }
    return totalCount;
  };

  const getConnectedCalls = (obj = {}) => {
    return obj ? obj?.["Connected"] || obj?.["connected"] || 0 : 0;
  };

  const constructReportSummaryData = () => {
    if (reportStore.summaryData !== null) {
      setReportList(reportStore.summaryData);
      reportStore.setSummaryLoader(false);
    }
  };

  useEffect(() => {
    constructReportSummaryData();
  }, [reportStore.summaryData]);

  useEffect(() => {
    setLoader(reportStore.summaryLoader);
  }, [reportStore.summaryLoader]);

  useEffect(() => {
    setLoader(true);
    reportStore.setSummaryLoader(true);
  }, [location?.pathname]);

  return (
    <div className="reportsTableCard">
      <div className="reportsSummaryTableSection">
        <div className="tableHeader">
          {reportSummaryHeader.map(item => {
            const {
              name = "",
              className = "",
              id = "",
              subTitle = "",
              subTitleClassName = ""
            } = item || {};
            return (
              <div className={className} key={id}>
                {name}
                {subTitle && (
                  <span className={subTitleClassName}>{subTitle}</span>
                )}
              </div>
            );
          })}
        </div>
        <div className="tableBody">
          {(!loader &&
            contactAdded &&
            reports?.length &&
            hasUserContactAdded() &&
            reports.map((item, index) => {
              const {
                email = "",
                contactsAdded = 0,
                emailsSent = 0,
                meetingBookedCount = 0,
                noOfCampaignsGotReply = 0,
                taskCompleted = 0,
                phoneStageResponse = {}
              } = item || {};

              let totalCalls = getTotalCallsMade(phoneStageResponse);
              let connectedCount = getConnectedCalls(phoneStageResponse);
              return (
                <div
                  className={`tableRow ${highLightMe(
                    item.email,
                    reports.length
                  )}`}
                  key={`reportTeamUser${index}`}
                >
                  <div className="teamMember">
                    <p className="fullName">{getFullName(item)}</p>
                    <h6 className="emailAddress">{email}</h6>
                  </div>
                  <div className="contactsAdded">
                    {Utils.formatNumberToStringInUSFormat(contactsAdded)}
                  </div>
                  <div className="emailsSent">
                    {Utils.formatNumberToStringInUSFormat(emailsSent)}
                  </div>
                  <div className="replyRate">
                    {Utils.formatNumberToStringInUSFormat(
                      noOfCampaignsGotReply
                    )}
                  </div>
                  <div className="callsMade">
                    {Utils.formatNumberToStringInUSFormat(totalCalls)}
                  </div>
                  <div className="callsConnected">
                    {Utils.formatNumberToStringInUSFormat(connectedCount)}
                  </div>
                  <div className="tasksCompleted">
                    {Utils.formatNumberToStringInUSFormat(taskCompleted)}
                  </div>
                </div>
              );
            })) ||
            ""}
          {!loader && !contactAdded && (
            <EmptyReport pathname={location?.pathname} />
          )}
          {(!loader && contactAdded && !hasUserContactAdded() && (
            <NoActivityFound />
          )) ||
            ""}
          {loader && (
            <ReportsTableLoader
              calendarEnabled={calendarEnabled && calendarIntegrationEnabled}
              count={7}
              isSummary={true}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default ReportSummary;
export { ReportSummary };
