import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { USER_SELECT_DROPDOWN_LIST as userObjList } from "Static/constant";
import UserDropdown from "Components/common/UserDropdown";
import Utils from "Utils/utils";

const UserFilterDropdown = observer(props => {
  const { noOfContactsAddedInTeam } = userDetail?.userFeatureFlag || {};

  const [teamLoader, setTeamLoader] = useState(true);
  const [teamList, setTeamList] = useState("");
  const [userFilterList, setUserFilterList] = useState(userObjList);

  const getTeamList = async () => {
    const resData = await Utils.userTeamList();
    userDetail.setUserInfo({
      ...userDetail?.userInfo,
      hasTeamMember: resData?.length ? resData : false
    });
    setTeamList(resData);
    setTeamLoader(false);
  };

  const resetUserFilterOption = () => {
    const newArr = [...userFilterList];
    newArr.map(item => (item.checked = newArr?.id === "you" ? true : false));
    setUserFilterList(newArr);
  };

  useEffect(() => {
    getTeamList();
    return () => {
      resetUserFilterOption();
    };
  }, []);

  return (
    <UserDropdown
      teamList={teamList}
      userFilterList={Utils.filterByUserTeamFilterList(userFilterList)}
      teamLoader={teamLoader}
      isContactsAdded={noOfContactsAddedInTeam > 0}
      {...props}
    />
  );
});

export { UserFilterDropdown };
export default UserFilterDropdown;
