import React, { useContext } from "react";
import { AccountsListingContext } from "../AccountsList";

const HeaderAction = ({ clearAllFilter = () => {} }) => {
  const { filterList = [] } = useContext(AccountsListingContext);

  return (
    <div className="filterHeader">
      <span className="name">Filter</span>
      <span
        className={`clearBtn ${(filterList?.length === 0 && "disabled") || ""}`}
        onClick={clearAllFilter}
      >
        Clear all
      </span>
    </div>
  );
};

export default HeaderAction;
