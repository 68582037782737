import React, { useState, useEffect, useContext } from "react";
import CalenderAccount from "./Components/CalendarAccount";
import Utils from "Utils/utils";
import { observer } from "mobx-react";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import ConfigurationPageLoader from "./Components/ConfigurationPageLoader";
import { calendarState } from "../../State";
import userDetail from "Stores/userDetail";
import { CalendarContext } from "Pages/Dashboard/settings/Calendar/Main";
import EmptyPageWithNoInbox from "./Components/EmptyPageWithNoInbox";

const Main = observer(() => {
  const { isTrialUser = () => {}, isFreeUser = () => {} } =
    useContext(CalendarContext);

  const [connectedAccount, setConnectedAccount] = useState(
    calendarState?.connectedAccount || ""
  );
  const [loader, setLoader] = useState("all");

  const initMxpEvent = () => {
    Utils.mixpanelTrack(MXP_EVENT.ST_CLR_CONFIG_VIEWED, {
      pageType: `${PAGE_TYPE["setting"]} - Calender Configuration`
    });
  };

  const getCalendarConnectAccountDetails = async () => {
    initMxpEvent();
    setLoader("all");
    calendarState.getCalendarConnnectedAccount();
  };

  const handleBrandingToggleRequest = (_, value = false, id = "") => {
    if (isTrialUser() && isFreeUser()) {
      setLoader("toggle");
      calendarState.updateBrandingToggle({ id, showBranding: value });
    }
  };

  useEffect(() => {
    getCalendarConnectAccountDetails();
    return () => {
      setLoader("all");
      setConnectedAccount("");
    };
  }, []);

  useEffect(() => {
    if (calendarState?.connectedAccount !== null) {
      setConnectedAccount(calendarState.connectedAccount);
      setLoader("");
    }
  }, [calendarState.connectedAccount]);

  return (
    <div className="configurationCont">
      {loader === "all" ? (
        <ConfigurationPageLoader />
      ) : connectedAccount?.email ? (
        <CalenderAccount
          {...connectedAccount}
          handleToggleAction={handleBrandingToggleRequest}
          loader={loader}
        />
      ) : (
        <EmptyPageWithNoInbox
          primaryInbox={userDetail?.connectedInboxes?.[0] || ""}
        />
      )}
    </div>
  );
});

export default Main;
