/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Utils from "Utils/utils";
import { withRouter, useParams } from "react-router-dom";
import BreadCrumbComponent from "Components/common/breadcrumb";
import ButtonComponent from "Components/common/Button";
import DropdownMenuComponent from "Components/common/DropDownMenu";
import { ReactComponent as SequenceIcon } from "Assets/svg/sequence.svg";
import FolderComponent from "./SequencePage/SequenceFolder";
import SequenceProperties from "./SequencePage/sequenceProperties";
import NoSequenceComponent from "./SequencePage/NoSequence";
import SequenceTable from "./SequencePage/SequenceTable";
import { sequenceStoreData } from "Stores/sequenceData";
import { URLS, makeApi } from "Utils/apiURL";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { SequenceTableLoading } from "./SequencePage/SequenceTableLoading";
import NoSharedEntity from "Components/common/NoSharedEntity";
import { userDetail } from "Stores/userDetail";
import DriverMaster from "Components/driver/Driver";
import ContactCard from "Components/ContactCard/ContactCard";
import PricingDiscountBanner from "Components/common/PricingDiscountBanner";
import { FreeTrial } from "Components/common/FreeTrial";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { SequenceUtils } from "Utils/SequenceUtils";
import KnowMoreButton from "Components/common/KnowMoreButton";
import { seqTemplatePopupState } from "./SequenePredefinedTemplate/SeqTemplatePopupState";
import globalCallState from "Stores/GlobalCallState";
import PaywallUtils from "Utils/PaywallUtils";
import { toJS } from "mobx";
import PageLimit from "Components/common/PageLimit";
import PaginationComponent from "Components/common/PaginationComponent";
import { useCallback } from "react";

const defaultBreadValue = [{ name: "Sequences", link: "/sequences/all" }];

let isFolder = false;
let folderIdFromURL = "";
let locationId = "";
let propsMatchParam;
let isShared = false;

const Sequence = props => {
  const sequenceContactCardRef = useRef();
  let { id: seqId, sharedId } = useParams();

  if (typeof seqId === "undefined") {
    seqId = sharedId;
  }

  const {
    noOfSequencesEligible = 0,
    noOfSequencesUsed = 0,
    inFreePlan = false,
    taskEnabled = false,
    planName = ""
  } = userDetail.userFeatureFlag || {};

  const [showAddDropdown, setAddDropdown] = useState(false);
  const [breadcrumbArr, setBreadCrumbArr] = useState(defaultBreadValue);
  const [showFolderLoader, setShowFolderLoader] = useState(true);
  const [showNoSequence, setNoSequence] = useState(false);
  const [showCreateSeq, setCreateSeq] = useState(false);
  const [showCreateSeqPopup, setCreateSeqPopup] = useState(false);
  const [showcreateFolder, setCreateFolder] = useState(false);
  const [showSequenceProp, setSequenceProp] = useState(false);
  const [sequenceTableLoading, setSequenceTableLoading] = useState(true);
  const [sequenceData, setSequenceData] = useState([]);
  const [newSeqName, setNewSeqName] = useState("New Sequence");
  const [showNoSharedSequence, setNosharedSequence] = useState(false);
  const [showSharedBadge, setShowSharedBadge] = useState(false);
  const [showContactCard, setShowContactCard] = useState(false);
  const [slideAnim, setSlideAnim] = useState(false);
  const [selectedContact, setSelecedContact] = useState({});
  const [productDiscount, setProductDiscount] = useState(
    Utils.showProductDiscountBanner()
  );
  const [sortIconStatus, setSortIconStatus] = useState("ASC");
  const [activeSort, setActiveSort] = useState("CREATE_TS");
  const [breadcrumbLoading, setBreadcrumbLoading] = useState(false);
  const [selectedTemplateSequenceData, setSelectedTemplateSequence] = useState(
    []
  );

  const checkEligibleSequenceCount = () => {
    let count = noOfSequencesEligible - noOfSequencesUsed;
    return count > 0;
  };

  const constructPropsMatchParam = (propsVal = props) => {
    propsMatchParam =
      propsVal && propsVal.match && propsVal.match.params
        ? propsVal.match.params
        : {};
    if (propsMatchParam) {
      if (propsMatchParam.id && propsMatchParam.folderId) {
        isFolder = true;
        folderIdFromURL = propsMatchParam.folderId;
        locationId = propsMatchParam.id;
        isShared = false;
        setSequenceProp(true);
      } else if (propsMatchParam.folderId) {
        isFolder = true;
        folderIdFromURL = propsMatchParam.folderId;
        locationId = "";
        isShared = false;
        setSequenceProp(false);
      } else if (propsMatchParam.id || propsMatchParam.sharedId) {
        locationId = propsMatchParam.id || propsMatchParam.sharedId;
        setSequenceProp(true);
        folderIdFromURL = "";
        isFolder = false;
        if (propsMatchParam.sharedId) {
          isShared = true;
        }
      } else if (props.shared) {
        locationId = "";
        isShared = true;
        isFolder = false;
        folderIdFromURL = "";
        setSequenceProp(false);
      } else {
        locationId = propsMatchParam.id ? propsMatchParam.id : "";
        isFolder = false;
        isShared = false;
        folderIdFromURL = "";
        setSequenceProp(false);
      }
    }
  };

  const checkSequenceLeft = () => {
    return noOfSequencesEligible - noOfSequencesUsed > 0;
  };

  const enableDropdownMenu = () => {
    DriverMaster.killDriver();
    if (checkSequenceLeft) {
      setAddDropdown(true);
    }
  };

  const hideDropdownMenu = () => {
    setAddDropdown(false);
  };

  const setBreadCrumbData = curBreadValue => {
    setBreadCrumbArr([...curBreadValue]);
  };

  const openCreateSequencePopup = async () => {
    DriverMaster.killDriver();
    if (await checkTeamSubscriptionActive()) {
      if (checkEligibleSequenceCount()) {
        setNoSequence(false);
        setAddDropdown(false);
        setCreateSeqPopup(true);
      } else {
        setAddDropdown(false);
        if (!upgradePopupState.showPopup) {
          PaywallUtils.upgradeUtilsFunction(
            "newSequence",
            false,
            planName,
            inFreePlan,
            { sequenceCount: noOfSequencesEligible }
          );
        }
      }
    }
  };

  const createNewFolderBtn = async () => {
    setAddDropdown(false);
    if (await checkTeamSubscriptionActive()) {
      setCreateFolder(true);
    }
  };

  const dropdownOptions = [
    {
      icon: {
        ele: <SequenceIcon />,
        style: { width: "14px", height: "14px" }
      },
      value: "New Sequence",
      key: "ceateNewSequence",
      method: openCreateSequencePopup,
      showUpgrade: inFreePlan && !checkEligibleSequenceCount(),
      closeDropdown: setAddDropdown,
      type: "newSequence"
    },
    {
      icon: {
        ele: '<i style="font-size: 16px" class="material-icons-outlined list-icon">create_new_folder</i>',
        style: {}
      },
      value: "New Folder",
      key: "createNewSequenceFolder",
      method: createNewFolderBtn
    }
  ];

  const constructSequenceData = response => {
    const respData = response;
    let finalData = [];
    if (response.length) {
      respData.map(value => {
        let sequenceData = {};
        if (value?.name) {
          const {
            id,
            folderId,
            clickCount,
            contactsAdded,
            name,
            openCount,
            replyCount,
            steps,
            status,
            sentCount,
            accessType,
            shareType,
            owner,
            memberId,
            createTs,
            scheduleWindowId = "",
            firstStageIsReply = false,
            inboxIds = [],
            settings,
            excludeContactEnabled,
            pauseStatus = "",
            resumeStatus = "",
            emailValidationEnabled = false,
            stopEmailOnMeetingBooked = false,
            stopEmailOnReplyReceived = false,
            stopEmailOnLinkedInReplyReceived = false
          } = value || {};
          let background = SequenceUtils.getSequenceStatus(value);
          sequenceData = {
            id,
            folderId,
            clickCount,
            contactsAdded,
            name,
            openCount,
            replyCount,
            sentCount,
            status,
            accessType,
            shareType,
            owner,
            memberId,
            createTs,
            scheduleWindowId,
            inboxIds,
            firstStageIsReply,
            settings,
            steps: steps || {},
            excludeContactEnabled,
            pauseStatus,
            resumeStatus,
            background,
            emailValidationEnabled,
            stopEmailOnMeetingBooked,
            stopEmailOnReplyReceived,
            stopEmailOnLinkedInReplyReceived
          };
          finalData.push(sequenceData);
        }
      });
      if (finalData.length) {
        constructBreadCrumbData(finalData);
        setSequenceData(finalData);
        setNoSequence(false);
        setNosharedSequence(false);
        setSequenceTableLoading(false);
        sequenceStoreData.setPaginationDataLoading(false); //Not needed I guess
      } else if (finalData.length === 0) {
        setSequenceData([]);
        setNoSequence(true);
        setSequenceTableLoading(false);
        sequenceStoreData.setPaginationDataLoading(false); //Not needed I guess
      }
    } else {
      setNoSequence(true);
      setSequenceData([]);
      setSequenceTableLoading(false);
      sequenceStoreData.setPaginationDataLoading(false); //Not needed I guess
    }
  };

  const getFolderSequenceResponse = (folderId, externalPayload = null) => {
    folderId ? sequenceStoreData.setFolderId(folderId) : "";
    sequenceStoreData.setIsSharedSeqFolder(false);
    const folderRespCbk = (folderData = []) => {
      if (folderData?.length) {
        sequenceStoreData.setSequenceData(folderData);
        SequenceUtils.addActiveSequenceIdToLocalStorage(folderData);
        constructSequenceData(folderData, false);
        setNoSequence(false);
      } else {
        setNoSequence(true);
        setSequenceData([]);
        setSequenceProp(false);
        setSequenceTableLoading(false);
        sequenceStoreData.setPaginationDataLoading(false);
      }
    };
    if (externalPayload && Object.keys(externalPayload).length > 0) {
      Utils.getFolderSequenceResponse(folderId, folderRespCbk, externalPayload);
    } else {
      sequenceStoreData.setDynamicCurrentPage(1);
      sequenceStoreData.setDynamicPageLimit(15);
      sequenceStoreData.setPageLimit(15);
      sequenceStoreData.setCurrentPage(1);
      const payload = {
        ...sequenceStoreData.filterPayload,
        pageNo: sequenceStoreData.dynamicCurrentPage,
        limit: sequenceStoreData.dynamicPageLimit,
        folderId: sequenceStoreData.folderId
      };

      Utils.getFolderSequenceResponse(folderId, folderRespCbk, payload);
    }
  };

  const getFolderDetailForBreadcrumb = () => {
    let selectedFolderName = "",
      selectedFolderId = "";
    if (isShared) {
      selectedFolderName = "Shared Sequences";
      selectedFolderId = "shared";
    } else {
      const selectedFolderDetails =
        sequenceStoreData?.allFolderResponse?.length > 0
          ? sequenceStoreData?.allFolderResponse?.filter(
              item => item?.id === folderIdFromURL
            )[0]
          : {};
      selectedFolderName = selectedFolderDetails?.folderName || "My Sequences";
      selectedFolderId = selectedFolderDetails?.id || "all";
    }
    return { selectedFolderName, selectedFolderId };
  };

  const constructBreadCrumbData = store => {
    let curBreadValue = JSON.parse(JSON.stringify(defaultBreadValue));
    const { selectedFolderId = "", selectedFolderName = "" } =
      getFolderDetailForBreadcrumb();
    if (selectedFolderName) {
      curBreadValue.push({
        name: selectedFolderName,
        link: "/sequences/" + selectedFolderId
      });
    }
    if (store?.length && locationId !== "") {
      const storeData = JSON.parse(JSON.stringify(store));
      const storeLength = storeData?.length;
      let cureSeqName;

      for (let i = 0; i < storeLength; i++) {
        if (storeData[i]?.id === locationId && storeData?.[i]?.name) {
          cureSeqName = storeData[i].name;
        }
      }
      curBreadValue.push({ name: cureSeqName, link: "" });
    }
    if (curBreadValue?.length) {
      setBreadCrumbData(curBreadValue);
    }

    setBreadcrumbLoading(false);
  };

  //Replica
  const getSharedSeqResponse = async (externalPayload = null) => {
    sequenceStoreData.setPaginationDataLoading(true);
    let response;
    if (externalPayload && Object.keys(externalPayload).length > 0) {
      response = await getSharedSeqDataFromApi(externalPayload);
      sequenceStoreData.setPaginationDataLoading(false);
    } else {
      sequenceStoreData.setDynamicCurrentPage(1);
      sequenceStoreData.setDynamicPageLimit(15);
      sequenceStoreData.setPageLimit(15);
      sequenceStoreData.setCurrentPage(1);
      const payload = {
        ...sequenceStoreData.filterPayload,
        pageNo: sequenceStoreData.dynamicCurrentPage,
        limit: sequenceStoreData.dynamicPageLimit,
        sharedFolder: true
      };

      response = await getSharedSeqDataFromApi(payload);
      sequenceStoreData.setPaginationDataLoading(false);
    }

    sequenceStoreData.setIsSharedSeqFolder(true);
    sequenceStoreData.setFolderId("");

    const { sequences, totalPages, totalSequences, currentPage } =
      response.data;
    if (totalSequences) {
      sequenceStoreData.totalSequences = totalSequences;
    }
    if (currentPage) {
      sequenceStoreData.currentPage = currentPage;
    }

    if (totalPages) {
      sequenceStoreData.setTotalPage(totalPages);
    }
    if (sequences?.length) {
      sequenceStoreData.setSequenceData(sequences);
      setSequenceTableLoading(false);
      sequenceStoreData.setPaginationDataLoading(false);
      constructSequenceData(sequences);
      SequenceUtils.addActiveSequenceIdToLocalStorage(sequences);
    } else if (response?.data?.length === 0) {
      sequenceStoreData.setSequenceData([]);
      setNosharedSequence(true);
    } else {
      sequenceStoreData.setSequenceData([]);
      Utils.showApiFailureResponse(response);
    }
  };

  //Replica
  const getSharedSeqDataFromApi = (externalPayload = null) => {
    const config = {
      url: `${URLS.getMySequenceData}`,
      method: "POST",
      data: externalPayload
    };

    Utils.cancelPreviousSequenceRequest();
    return makeApi(config, sequenceStoreData.cancelSequenceCallAction);
  };

  const showAllSequenceData = (externalPayload = null) => {
    sequenceStoreData.setPaginationDataLoading(true);
    sequenceStoreData.setFolderId("");
    sequenceStoreData.setIsSharedSeqFolder(false);
    setSequenceProp(false);
    makeAllSequenceDataAPi(externalPayload);

    if (externalPayload && Object.keys(externalPayload).length > 0) {
      makeAllSequenceDataAPi(externalPayload);
    } else {
      sequenceStoreData.setDynamicCurrentPage(1);
      sequenceStoreData.setDynamicPageLimit(15);
      sequenceStoreData.setPageLimit(15);
      sequenceStoreData.setCurrentPage(1);
      const payload = {
        ...sequenceStoreData.filterPayload,
        pageNo: sequenceStoreData.dynamicCurrentPage,
        limit: sequenceStoreData.dynamicPageLimit
      };
      makeAllSequenceDataAPi(payload);
    }
  };

  const makeAllSequenceDataAPi = (payload = null) => {
    setSequenceTableLoading(true);
    sequenceStoreData.setPaginationDataLoading(true);

    const callback = () => {
      constructAllSeqData();
      setSequenceTableLoading(false);
      sequenceStoreData.setPaginationDataLoading(false);
    };
    sequenceStoreData.getAllSequenceData(callback, payload);
  };

  const constructAllSeqData = () => {
    let duplicateStoreData = [];
    if (sequenceStoreData?.sequenceData)
      duplicateStoreData = JSON.parse(
        JSON.stringify(sequenceStoreData.sequenceData)
      );
    if (duplicateStoreData.length) {
      setNoSequence(false);
      constructSequenceData(duplicateStoreData);
      if (showSequenceProp && locationId) {
        constructBreadCrumbData(duplicateStoreData, locationId);
      }
    } else if (duplicateStoreData.length === 0) {
      setNoSequence(true);
      setSequenceData([]);
    }
  };

  const checkAndUpdate = () => {
    const folderDetailsObj = Utils.getFolderDetails();
    const folderName = folderDetailsObj.folderName;
    const folderId = folderDetailsObj.folderId;
    if (folderName && folderId) {
      getFolderSequenceResponse(folderId);
    } else makeAllSequenceDataAPi(true);
  };

  const openContactCard = (contact = {}) => {
    if (contact && Object.keys(contact)?.length) {
      setSlideAnim(true);
      setShowContactCard(true);
      setSelecedContact(contact);
    }
  };

  const closeContactCard = () => {
    if (globalCallState.popupValues?.isContactCard) {
      globalCallState.setPopupValues({
        ...globalCallState.popupValues,
        isContactCard: false
      });
    }
    setSlideAnim(false);
    setTimeout(() => {
      setShowContactCard(false);
    }, 300);
  };

  const redirectToMySequence = value => {
    if (value) {
      props.history.push("/sequences/all");
    }
  };

  const sortSequence = (sortParam, toSort) => {
    setSequenceTableLoading(true);
    sequenceStoreData.setPaginationDataLoading(true);
    setActiveSort(sortParam);
    sequenceStoreData.setDynamicCurrentPage(1);
    const payload = {
      showReplySequence: true,
      sortDirection: toSort,
      pageNo: sequenceStoreData.dynamicCurrentPage,
      limit: sequenceStoreData.pageLimit,
      sortParam
    };

    if (folderIdFromURL) {
      payload.folderId = folderIdFromURL;
    } else if (isShared) {
      payload.sharedFolder = true;
    }
    const resCbk = (direction, value, dataList) => {
      if (value && direction === "ASC") {
        setSortIconStatus("DESC");
      } else if (value && direction === "DESC") {
        setSortIconStatus("ASC");
      }
      if (value) {
        setSequenceData(dataList);
      }
      setSequenceTableLoading(false);
      sequenceStoreData.setPaginationDataLoading(false);
    };
    sequenceStoreData.getSequenesBySorting(payload, resCbk);
  };

  const updateSequenceDataOnMoved = () => {
    let tempSeqLen = sequenceStoreData?.tempSequenceData?.length;
    let seqLen = sequenceStoreData?.sequenceData?.length;
    setSequenceData(sequenceStoreData.sequenceData);
    if (tempSeqLen && !seqLen) {
      setNoSequence(true);
    } else if (!tempSeqLen && seqLen) {
      setNoSequence(false);
    }
  };

  const reloadSequenceContactPage = (keyType = "", obj = {}) => {
    if (
      keyType?.toLowerCase() === "sequence_contact" &&
      obj?.sequenceId === seqId
    ) {
      sequenceStoreData.setReloadSequenceContactPage(true);
    }
  };

  const initCalls = async () => {
    await seqTemplatePopupState.getDomain();
  };

  useEffect(() => {
    const selectedTemplateSequence = toJS(
      sequenceStoreData.selectedTemplateSequence
    );
    if (Array.isArray(selectedTemplateSequence)) {
      setSelectedTemplateSequence(selectedTemplateSequence);
    }
  }, [sequenceStoreData.selectedTemplateSequence]);

  useEffect(() => {
    if (Array.isArray(selectedTemplateSequenceData)) {
      setSequenceData(prev => [...toJS(prev), ...selectedTemplateSequenceData]);
    }
  }, [selectedTemplateSequenceData]);

  useEffect(() => {
    constructBreadCrumbData(sequenceData);
  }, [sequenceData]);

  useEffect(() => {
    setShowSharedBadge(false);
    constructPropsMatchParam();
    constructBreadCrumbData(sequenceData);
    if (folderIdFromURL && !seqId) {
      setSequenceTableLoading(true);
      sequenceStoreData.setPaginationDataLoading(true);
      getFolderSequenceResponse(folderIdFromURL);
    } else if (isShared) {
      setSequenceTableLoading(true);
      sequenceStoreData.setPaginationDataLoading(true);
      getSharedSeqResponse();
    } else if (folderIdFromURL === "" && locationId === "") {
      sequenceStoreData.setPaginationDataLoading(true);
      showAllSequenceData();
    } else if (folderIdFromURL === "" && locationId) {
      makeAllSequenceDataAPi();
    }
    setSortIconStatus("ASC");
    setActiveSort("CREATE_TS");
    // To hide the new folder or seq creation input box when page navigates.
    setNoSequence(false);
    setNosharedSequence(false);
    setCreateFolder(false);
    setCreateSeq(false);
  }, [
    props.match.params.id,
    props.match.params.folderId,
    props.shared,
    props.match.params.sharedId
  ]);

  useEffect(() => {
    setProductDiscount(Utils.showProductDiscountBanner());
  }, [Utils.showProductDiscountBanner()]);

  useEffect(() => {
    constructBreadCrumbData(sequenceData);
  }, [sequenceStoreData?.allFolderResponse]);

  useEffect(() => {
    updateSequenceDataOnMoved();
  }, [sequenceStoreData.sequenceData]);

  useEffect(() => {
    initCalls();
  }, []);

  return (
    <div className={`sequencePage pageClass`}>
      <div className="sequenceCont pageCont">
        <div className="headerSection">
          <div className="breadCrumbLeftSection">
            <BreadCrumbComponent breadCrumbValue={breadcrumbArr} />
            {showSharedBadge && <SharedLabel />}
          </div>
          <div className="headerBtnContainer">
            <FreeTrial {...props} />
            <KnowMoreButton
              redirectUrl={
                "https://support.salesgear.io/en/articles/5645156-how-to-create-a-sequence-in-salesgear"
              }
            />
            {!seqId && !isShared && (
              <AddNewBtnComponent
                enableDropdownMenuAction={enableDropdownMenu}
                toShowDropDown={showAddDropdown}
                dropdownOptions={dropdownOptions}
                hideDropdownMenu={hideDropdownMenu}
                {...props}
              />
            )}
          </div>
        </div>
        {!productDiscount && <PricingDiscountBanner />}
        <div className="sequenceSectionWrapper">
          <section
            className={`${
              seqId ? "sequenceDetailSection" : "sequenceSection"
            } ${!productDiscount ? "productDiscount" : ""}`}
          >
            {seqId ? (
              <div className="rightSection">
                {showContactCard && (
                  <div
                    className={`contactCardSection ${
                      slideAnim ? "slideInFromBottom" : "slideOutToBottom"
                    } ${
                      !userDetail.pluginInstalled
                        ? "installPluginExtBannerShown"
                        : ""
                    }`}
                    id="contactCard"
                    key="contactCard"
                    ref={sequenceContactCardRef}
                  >
                    <ContactCard
                      cardType="sequence_contact"
                      page="Sequence Contacts Page"
                      contact={selectedContact}
                      closeCbk={closeContactCard}
                      isContactSlider={true}
                      isReloadCbk={reloadSequenceContactPage}
                    />
                  </div>
                )}
                {showSequenceProp && (
                  <SequenceProperties
                    {...props}
                    selectedContactCard={openContactCard}
                    setShowSharedBadge={setShowSharedBadge}
                    showUpgrade={inFreePlan && !checkEligibleSequenceCount()}
                    inFreePlan={inFreePlan}
                    taskEnabled={taskEnabled}
                  />
                )}
              </div>
            ) : (
              <DndProvider backend={HTML5Backend}>
                <div className={`${seqId ? "hideLeftSection" : "leftSection"}`}>
                  <FolderComponent
                    setCreateFolder={setCreateFolder}
                    setShowFolderLoader={setShowFolderLoader}
                    showcreateFolder={showcreateFolder}
                    showFolderLoader={showFolderLoader}
                    redirectToMySequence={redirectToMySequence}
                  />
                </div>
                <div className="rightSection">
                  {showNoSequence ? (
                    <NoSequenceComponent
                      showSequenceProp={showSequenceProp}
                      newSequenceBtnAction={openCreateSequencePopup}
                      showUpgrade={inFreePlan && !checkEligibleSequenceCount()}
                    />
                  ) : showNoSharedSequence ? (
                    <NoSharedEntity title={"No shared sequences found"} />
                  ) : sequenceTableLoading ? (
                    <>
                      <SequenceTableLoading />
                      <SequenceTableFooter
                        currentPage={sequenceStoreData?.currentPage}
                        totalPages={sequenceStoreData?.totalPage}
                        pageLimit={sequenceStoreData?.pageLimit}
                        showAllSequenceData={showAllSequenceData}
                        makeAllSequenceDataAPi={makeAllSequenceDataAPi}
                        constructAllSeqData={constructAllSeqData}
                        getFolderSequenceResponse={getFolderSequenceResponse}
                        sequenceTableLoading={sequenceTableLoading}
                        setSequenceTableLoading={setSequenceTableLoading}
                        getSharedSeqResponse={getSharedSeqResponse}
                      />
                    </>
                  ) : (
                    <>
                      <SequenceTable
                        data={sequenceData}
                        folderIdFromURL={folderIdFromURL}
                        setSequenceData={setSequenceData}
                        setNoSequence={setNoSequence}
                        setCreateSeq={setCreateSeq}
                        setCreateSeqPopup={setCreateSeqPopup}
                        showCreateSeq={showCreateSeq}
                        showCreateSeqPopup={showCreateSeqPopup}
                        newSeqName={newSeqName}
                        checkAndUpdate={checkAndUpdate}
                        setNewSeqName={setNewSeqName}
                        setShowSharedBadge={setShowSharedBadge}
                        showUpgrade={
                          inFreePlan && !checkEligibleSequenceCount()
                        }
                        isShared={isShared}
                        sortSequence={sortSequence}
                        sortIconStatus={sortIconStatus}
                        activeSort={activeSort}
                        inFreePlan={inFreePlan}
                        taskEnabled={taskEnabled}
                      />
                      <SequenceTableFooter
                        showAllSequenceData={showAllSequenceData}
                        getFolderSequenceResponse={getFolderSequenceResponse}
                        setSequenceTableLoading={setSequenceTableLoading}
                        getSharedSeqResponse={getSharedSeqResponse}
                      />
                    </>
                  )}
                </div>
              </DndProvider>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

const SequenceTableFooter = (props = {}) => {
  const {
    showAllSequenceData,
    getFolderSequenceResponse,
    setSequenceTableLoading,
    getSharedSeqResponse
  } = props || {};

  const [footerClass, setFooterClass] = useState("metricsTableFooter");

  const getSequencesByLimit = (limit = 10) => {
    sequenceStoreData.setPageLimit(limit);
    sequenceStoreData.setCurrentPage(1);
    setSequenceTableLoading(true);
    sequenceStoreData.setPaginationDataLoading(true);

    let payload;
    if (sequenceStoreData.folderId) {
      payload = {
        ...sequenceStoreData.filterPayload,
        limit: sequenceStoreData.pageLimit,
        pageNo: sequenceStoreData.currentPage,
        folderId: sequenceStoreData.folderId
      };
      getFolderSequenceResponse(sequenceStoreData.folderId, payload);
    } else if (sequenceStoreData.isSharedSeqFolder) {
      payload = {
        ...sequenceStoreData.filterPayload,
        limit: sequenceStoreData.pageLimit,
        pageNo: sequenceStoreData.currentPage,
        sharedFolder: true
      };
      getSharedSeqResponse(payload);
    } else {
      payload = {
        ...sequenceStoreData.filterPayload,
        limit: sequenceStoreData.pageLimit,
        pageNo: sequenceStoreData.currentPage
      };
      showAllSequenceData(payload);
    }
  };
  const pageLimitObj = {
    limitPerPage: sequenceStoreData?.pageLimit || 11,
    text: "Showing",
    totalCount: sequenceStoreData?.totalSequences || 0,
    cbk: getSequencesByLimit,
    background: true,
    customLimit: [15, 25, 50, 100]
  };

  const paginate = pageNoObj => {
    sequenceStoreData.setPaginationDataLoading(true);
    let pageNo = pageNoObj?.selected + 1 || 1;
    sequenceStoreData.setCurrentPage(pageNo);
    sequenceStoreData.setSequenceData("");
    setSequenceTableLoading(true);

    let payload;
    if (sequenceStoreData.folderId) {
      payload = {
        ...sequenceStoreData.filterPayload,
        pageNo: sequenceStoreData.currentPage,
        limit: sequenceStoreData.pageLimit,
        folderId: sequenceStoreData.folderId
      };
      getFolderSequenceResponse(sequenceStoreData.folderId, payload);
    } else if (sequenceStoreData.isSharedSeqFolder) {
      payload = {
        ...sequenceStoreData.filterPayload,
        pageNo: sequenceStoreData.currentPage,
        limit: sequenceStoreData.pageLimit,
        sharedFolder: true
      };
      getSharedSeqResponse(payload);
    } else {
      payload = {
        ...sequenceStoreData.filterPayload,
        limit: sequenceStoreData.pageLimit,
        pageNo: sequenceStoreData.currentPage
      };
      showAllSequenceData(payload);
    }
  };

  useEffect(() => {
    setFooterClass(
      sequenceStoreData.totalSequences > 10
        ? "metricsTableFooter with-border"
        : "metricsTableFooter"
    );
  }, [sequenceStoreData.totalSequences]);
  return (
    <div className={footerClass}>
      <PaginationComponent
        totalPages={sequenceStoreData.totalPage || 1}
        callback={paginate}
        currentPage={sequenceStoreData.currentPage || 1}
        loading={sequenceStoreData.paginationDataLoading}
      />
      <PageLimit {...pageLimitObj} />
    </div>
  );
};

const AddNewBtnComponent = props => {
  return (
    <div className="add-new-dropdown">
      <ButtonComponent
        id="addNewSequence"
        name="addNewSequence"
        type="button"
        className=""
        btnText="Add New"
        icon={true}
        loader={false}
        iconName="keyboard_arrow_down"
        click={props.enableDropdownMenuAction}
      />
      <>
        {props.toShowDropDown ? (
          <>
            <DropdownMenuComponent
              options={props.dropdownOptions}
              referenceId=""
            />
            <div
              className="dropdown-overlay"
              onClick={props.hideDropdownMenu}
            />
          </>
        ) : (
          ""
        )}
      </>
    </div>
  );
};

const SharedLabel = () => {
  return (
    <div className="sharedLabel">
      <span className="sharedBadge">SHARED</span>
    </div>
  );
};

export default withRouter(observer(Sequence));
export { Sequence };
