import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import filterComponentState from "Pages/Dashboard/tasks/FilterComponent/filterComponentState";
import userDetail from "./userDetail";
import { DateUtils } from "Utils/DateUtils";
import TaskUtils from "Utils/TaskUtils";

let cancelFilterRequest = null;
let cancelTableRequest = null;

class TaskData {
  @observable taskData = [];
  @observable taskFilterData = [];
  @observable totalTasks = localStorage.getItem("totalTasks");
  @observable currentPage = 0;
  @observable limit = 20;
  @observable filter = null;
  @observable dateFilterValue = {
    filterToDate: Utils.convertToDateToLocalString(new Date())
  };
  @observable statusFilterValue = { status: "ACTIVE" };
  @observable activeDueTab = "allTask";
  @observable activeStatusTab = "due";
  @observable status = "due";
  @observable selectedTaskPage = 1;
  @observable totalPages = 0;
  @observable openFilter = false;
  @observable updatedFromAddressObj = {};
  @observable taskAttachments = [];
  @observable removedAttachmentId = "";
  @observable accountId = "";
  @observable currentTaskId = localStorage.getItem("currentTaskId");
  @observable taskIds = JSON.parse(localStorage.getItem("taskIdsArr")) || [];

  @action
  setTotalTasks = (value = "") => {
    this.totalTasks = value;
  };

  @action
  setTaskIds = (value = "") => {
    this.taskIds = value;
  };

  @action
  setCurrentTaskId = value => {
    this.currentTaskId = value;
    localStorage.setItem("currentTaskId", value);
  };

  @action
  setFilter = value => {
    this.filter = value;
  };

  @action
  setStatus = value => {
    this.status = value;
  };

  @action
  setLimit = value => {
    this.limit = value;
  };

  @action
  setTotalTaskValue = value => {
    this.totalTasks = value;
  };

  @action
  setCurrentPage = value => {
    this.currentPage = value;
  };

  @action
  setDateFilterValue = value => {
    this.dateFilterValue = value;
  };

  @action
  setStatusFilterValue = value => {
    this.statusFilterValue = value;
  };

  @action
  setActiveDueTab = value => {
    this.activeDueTab = value;
  };

  @action
  setActiveStatusTab = value => {
    this.activeStatusTab = value;
  };

  @action
  setTaskData = value => {
    this.taskData = value;
  };

  @action
  setSelectedTaskPage = value => {
    this.selectedTaskPage = value;
  };

  @action
  setTotalPages = value => {
    this.totalPages = value;
  };

  @action
  setOpenFilter = value => {
    this.openFilter = value;
  };

  @action
  setUpdatedFromAddressObj = (val = {}) => {
    this.updatedFromAddressObj = val;
  };

  @action
  setTaskAttachments = (list = []) => {
    this.taskAttachments = [...list];
  };

  @action
  setRemovedAttachmentId = (id = "") => {
    this.removedAttachmentId = id;
  };

  @action
  setAccountId = (val = "") => {
    this.accountId = val;
  };

  @action
  setResetState = () => {
    this.setDateFilterValue({
      filterToDate: Utils.convertToDateToLocalString(new Date())
    });
    this.setStatusFilterValue({ status: "ACTIVE" });
    this.setStatus("due");
    this.setActiveStatusTab("due");
    this.setFilter(null);
    this.setActiveDueTab("allTask");
    this.setTaskData("");
    this.setAccountId("");
    this.setCurrentTaskId("");
    this.setTaskIds([]);
    localStorage.removeItem("taskIdsArr");
    localStorage.removeItem("currentTaskId");
    localStorage.removeItem("totalTasks");
    localStorage.removeItem("selectedTaskNo");
  };

  @action
  getTaskFilterPayload = (
    accountId = "",
    filter,
    pageNum,
    resetFilter,
    isLazyLoad,
    limit = 20,
    filterByDate = this.dateFilterValue,
    status = this.statusFilterValue,
    showText = "",
    fetchType = ""
  ) => {
    resetFilter && this.setFilter(null);
    filter && this.setFilter(filter);
    let pageNo = pageNum || 1;
    let data = {
      taskType: this.filter ? TaskUtils.getTasktypeFilter(this.filter) : null,
      timeZone: Utils.getCurrentUserTimeZone(),
      status: status?.status,
      memberIds: [userDetail?.userInfo?.memberId],
      sequenceIds:
        filterComponentState.filterComponentData["sequenceIds"] || [],
      tagIds: filterComponentState.filterComponentData["tagIds"] || [],
      contactStage:
        filterComponentState.filterComponentData["contactStage"] || [],
      contactFields:
        filterComponentState.filterComponentData["contactFields"] || []
    };
    if (accountId || this.accountId) {
      data.accountId = [accountId || this.accountId];
    }
    if (fetchType !== "ids") {
      data = { ...data, pageNo, limit: 20 };
    }
    if (this.activeStatusTab === "due") {
      data.dateFilter = {
        ...data.dateFilter,
        toDate: DateUtils.getTimeStamp(new Date(), "end")
      };
    }

    if (filterByDate) {
      data.dateFilter = {
        ...(filterByDate.filterFromDate && {
          fromDate: DateUtils.getTimeStamp(filterByDate.filterFromDate, "start")
        }),
        ...(filterByDate.filterToDate && {
          toDate: DateUtils.getTimeStamp(filterByDate.filterToDate, "end")
        })
      };
      data.timeZone = filterByDate.timeZone || Utils.getCurrentUserTimeZone();
    }
    filterComponentState.setFilterComponentData({
      ...filterComponentState.filterComponentData,
      dateFilter: data?.dateFilter
    });
    return data;
  };

  @action
  getTaskIdsFromSelectedPage = async (
    accountId = "",
    filter,
    pageNum,
    resetFilter,
    isLazyLoad,
    limit = 20,
    filterByDate = this.dateFilterValue,
    status = this.statusFilterValue,
    showText = ""
  ) => {
    try {
      let config = {
        url: URLS.getTaskIds,
        method: "POST",
        data: taskStoreData.getTaskFilterPayload(
          accountId,
          filter,
          pageNum,
          resetFilter,
          isLazyLoad,
          limit,
          filterByDate,
          status,
          showText,
          "ids"
        )
      };
      let response = await makeApi(config);
      if (response?.data) {
        this.setTaskIds(response?.data?.taskIds || []);
        this.setTotalTasks(response?.data?.taskIds?.length || 0);
        localStorage.setItem("totalTasks", this.totalTasks);
        localStorage.setItem("taskIdsArr", JSON.stringify(this.taskIds));
      } else Utils.showApiFailureResponse(response);
    } catch (error) {
      console.error("All Contact error ", error);
    }
  };

  @action
  getPerformTaskData = async (
    accountId = "",
    filter,
    pageNum,
    resetFilter,
    isLazyLoad,
    limit = 20,
    filterByDate = this.dateFilterValue,
    status = this.statusFilterValue,
    showText = "",
    fetchType = ""
  ) => {
    try {
      let config = {
        url: URLS.getPerformTaskData?.replace(
          "<<taskId>>",
          taskStoreData?.currentTaskId
        ),
        method: "GET",
        data: taskStoreData.getTaskFilterPayload(
          accountId,
          filter,
          pageNum,
          resetFilter,
          isLazyLoad,
          limit,
          filterByDate,
          status,
          showText,
          fetchType
        )
      };
      if (cancelTableRequest !== null) {
        cancelTableRequest("cancel");
        cancelTableRequest = null;
      }
      const advCancelCallback = value => {
        cancelTableRequest = value;
      };
      let response = await makeApi(config, advCancelCallback);
      if (response?.data) {
        response.data["limit"] = this.limit;
        this.setTaskData(response?.data);
      } else Utils.showApiFailureResponse(response);
    } catch (error) {
      console.error("All Contact error ", error);
    }
  };

  @action
  getTaskData = async (
    accountId = "",
    filter,
    pageNum,
    resetFilter,
    isLazyLoad,
    limit = 5,
    filterByDate = this.dateFilterValue,
    status = this.statusFilterValue,
    showText = "",
    fetchType = ""
  ) => {
    try {
      let config = {
        url: URLS.getTaskFilter,
        method: "POST",
        data: taskStoreData.getTaskFilterPayload(
          accountId,
          filter,
          pageNum,
          resetFilter,
          isLazyLoad,
          limit,
          filterByDate,
          status,
          showText,
          fetchType
        )
      };
      if (cancelTableRequest !== null) {
        cancelTableRequest("cancel");
        cancelTableRequest = null;
      }
      const advCancelCallback = value => {
        cancelTableRequest = value;
      };
      let response = await makeApi(config, advCancelCallback);
      if (response?.data) {
        response.data["limit"] = this.limit;
        this.setTaskData(response?.data);
      } else Utils.showApiFailureResponse(response);
    } catch (error) {
      console.error("All Contact error ", error);
    }
  };

  @action
  getTaskFilterData = async () => {
    try {
      const config = {
        url: URLS.getTaskAnalytics,
        method: "POST"
      };
      config.data = {
        ...config.data,
        status: taskStoreData?.statusFilterValue?.status,
        timeZone: Utils.getCurrentUserTimeZone(),
        memberIds: [userDetail?.userInfo?.memberId],
        pageNo: 1,
        sequenceIds:
          filterComponentState.filterComponentData["sequenceIds"] || [],
        tagIds: filterComponentState.filterComponentData["tagIds"] || [],
        contactStage:
          filterComponentState.filterComponentData["contactStage"] || [],
        contactFields:
          filterComponentState.filterComponentData["contactFields"] || []
      };
      if (this.activeStatusTab === "due") {
        config.data = {
          ...config.data,
          dateFilter: {
            ...config.data.dateFilter,
            toDate: DateUtils.getTimeStamp(new Date(), "end")
          }
        };
      }

      if (this.dateFilterValue) {
        config.data.dateFilter = {
          ...(this.dateFilterValue.filterFromDate && {
            fromDate: DateUtils.getTimeStamp(
              this.dateFilterValue.filterFromDate,
              "start"
            )
          }),
          ...(this.dateFilterValue.filterToDate && {
            toDate: DateUtils.getTimeStamp(
              this.dateFilterValue.filterToDate,
              "end"
            )
          })
        };
        config.data.timeZone =
          this.dateFilterValue.timeZone || Utils.getCurrentUserTimeZone();
      }
      if (cancelFilterRequest !== null) {
        cancelFilterRequest("cancel");
        cancelFilterRequest = null;
      }
      const advCancelCallback = value => {
        cancelFilterRequest = value;
      };
      let res = await makeApi(config, advCancelCallback);
      if (res?.data) {
        this.taskFilterData = res?.data?.analytics;
        this.totalTasks = res?.data?.total;
      } else Utils.showApiFailureResponse(res);
    } catch (error) {
      console.error("task filter Data error ", error);
    }
  };

  @action
  getBulkTasksPayload = () => {
    const { sequenceIds, tagIds, contactStage, contactFields } =
      filterComponentState?.filterComponentData || {};
    let data = {
      taskType: this.filter ? this.filter : null,
      pageNo: this.currentPage,
      limit: this.limit,
      timeZone: Utils.getCurrentUserTimeZone(),
      status: this.statusFilterValue?.status,
      memberIds: [userDetail?.userInfo?.memberId],
      sequenceIds,
      tagIds,
      contactStage,
      contactFields
    };

    if (this.activeStatusTab?.toLowerCase() === "due") {
      data.dateFilter = {
        ...data.dateFilter,
        toDate: DateUtils.getTimeStamp(new Date(), "end")
      };
    }

    if (this.dateFilterValue) {
      data.dateFilter = {
        ...(this.dateFilterValue?.filterFromDate && {
          fromDate: DateUtils.getTimeStamp(
            this.dateFilterValue?.filterFromDate,
            "start"
          )
        }),
        ...(this.dateFilterValue?.filterToDate && {
          toDate: DateUtils.getTimeStamp(
            this.dateFilterValue?.filterToDate,
            "end"
          )
        })
      };
      data.timeZone =
        this.dateFilterValue?.timeZone || Utils.getCurrentUserTimeZone();
    }
    filterComponentState.setFilterComponentData({
      ...filterComponentState.filterComponentData,
      dateFilter: data?.dateFilter
    });
    return data;
  };

  @action
  performBulkTasksAction = async (data = {}) => {
    if (!data?.filter?.taskIds?.length > 0) {
      let payload = this.getBulkTasksPayload();
      data.filter.tasksPayload = payload || {};
    }
    const config = {
      url: URLS.bulkTasksOperation,
      method: "POST",
      data
    };
    return await makeApi(config);
  };
}

const taskStoreData = new TaskData();
export { taskStoreData, TaskData };
export default taskStoreData;
