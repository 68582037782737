import React, { useContext } from "react";
import { AccountsNotesContext, editAccountNotesState } from "./Main";
import Utils from "Utils/utils";

const AccountNotesList = () => {
  const {
    state = {},
    setState = () => {},
    searchKeyword = ""
  } = useContext(AccountsNotesContext);

  const arrangeData =
    searchKeyword && state?.accountNotesSearchData
      ? state?.accountNotesSearchData
      : state?.accountNotesData || [];

  const setEditItemFn = (item = {}) => {
    setState({ ...state, editNotesItem: item });
    editAccountNotesState.setShowEdit(false);
  };
  return (
    <>
      {arrangeData?.map((item, index) => (
        <div
          className="entireList"
          onClick={() => setEditItemFn(item)}
          key={index}
        >
          <div
            className={`accountNotesTitle ${
              item?.id === state?.editNotesItem?.id ? "active" : ""
            }`}
          >
            {item?.title}
          </div>
          <div className="accountNotesCreatedBy">
            <div className="notesCreatedBy">
              Created by {item?.createdBy?.firstName}
            </div>
            <div className="notesCreatedDate">
              {item?.updateTs && Utils.formatDate(item?.updateTs)}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AccountNotesList;
