/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import userDetail from "Stores/userDetail";

const DialerCredits = props => {
  const { dialerCredits = 0 } = props;
  const [loader, setLoader] = useState(true);
  const [credits, setCredits] = useState(dialerCredits);

  const addCreditsToDialer = () => {
    props.history.push("/settings/dialer/general");
  };

  useEffect(() => {
    setCredits(userDetail.userFeatureFlag?.dialerCredits?.toFixed(4) || 0);
    setLoader(false);
  }, [userDetail.userFeatureFlag.dialerCredits]);

  return (
    <>
      <div className="dsPhoneCreditsSection">
        <div className="creditsWrap">
          <span className="text">Phone Credits Available:</span>
          {!loader && (
            <span
              className="creditsRemaining addCreditsBtn"
              onClick={addCreditsToDialer}
            >
              ${credits}
            </span>
          )}
          {loader && (
            <span className="creditsRemainingLoader linear-background">
              Loading...
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(observer(DialerCredits));
export { DialerCredits };
