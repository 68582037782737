/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import taskStoreData from "Stores/taskData";
import PerformTaskOperation from "./PerformTaskOperation";
import BreadCrumb from "Components/common/breadcrumb";
import ContactCard from "Components/ContactCard/ContactCard";
import ContactTimeline from "Pages/Dashboard/contacts/ContactTimeline/ContactTimeline";
import { ContactTimelineStore } from "Pages/Dashboard/contacts/ContactTimeline/ContactTimelineStore";
import Utils from "Utils/utils";
import IconType from "Pages/Dashboard/contacts/ContactTimeline/IconType";
import NavTaskButton from "./NavTaskButton";

const PerformTaskComponent = ({}) => {
  const breadcrumbArr = [{ name: "Tasks", link: "/tasks" }];
  const [taskListState, setTaskListState] = useState([]);
  const [selectedTask, setSelectedTask] = useState({});
  const [contactData, setContactData] = useState("");
  const [selectedTaskNumber, setSelectedTaskNumber] = useState(
    localStorage.getItem("selectedTaskNo") || 1
  );
  const [loading, setLoading] = useState(true);
  const [isNextBtnEnabled, setNextBtnEnabled] = useState(true);
  const [isPreviousBtnEnabled, setIsPreviousBtnEnabled] = useState(true);
  const [taskLoading, setTaskLoading] = useState(false);
  const [contactCardLoader, setContactCardLoader] = useState(false);
  const [timelineLoader, setTimelineLoader] = useState(false);
  const [triggerTimeline, setTriggerTimeline] = useState(false);
  const [taskDetails, setTaskDetails] = useState({});

  const location = useLocation();
  const history = useHistory();

  const constructTaskList = (
    taskDataFromStore,
    isPrevOrNextEnabled = false
  ) => {
    if (taskDataFromStore?.tasks) {
      const tasksArr = taskDataFromStore?.tasks || [];
      const renderData = [];
      tasksArr.map((taskObj, index) => {
        if (taskObj) {
          const {
            company,
            email,
            firstName,
            lastName,
            phone,
            mobilePhone,
            jobTitle,
            linkedinUrl,
            linkedinId,
            stage
          } = taskObj?.contact || {};
          const {
            sequenceEmail = false,
            inboxId = "",
            fromAddress = ""
          } = taskObj || {};
          setTaskDetails({
            sequenceEmail,
            inboxId,
            existingfromAddress: fromAddress
          });
          const signature = taskObj?.signature || "";
          let {
            id,
            customTask,
            note = "",
            taskDue,
            taskType = "",
            linkedInTaskType,
            contactId,
            status,
            content = "",
            subject = "",
            eligibleToSkipStage = false,
            trackerId = "",
            attachments = [],
            sequenceId = ""
          } = taskObj?.task || {};

          if (
            taskObj?.task?.touchPoint &&
            Object.keys(taskObj.task.touchPoint).length > 0
          ) {
            const { notes = "", type = "" } = taskObj.task.touchPoint || {};
            note = notes;
            taskType = type;
            content = taskObj?.task?.touchPoint?.content || "";
            subject = taskObj?.task?.touchPoint?.subject || "";
            attachments = taskObj?.task?.touchPoint?.attachments || [];
            linkedInTaskType =
              taskObj?.task?.touchPoint?.linkedInTaskType || "";
            trackerId = taskObj?.task?.touchPoint?.trackerId || "";
          }
          const tempObj = {
            id,
            note,
            customTask,
            taskDue,
            taskType,
            jobTitle,
            status,
            stage,
            linkedinUrl,
            linkedInTaskType,
            linkedinId,
            firstName,
            email,
            contactId,
            company,
            lastName,
            phone,
            mobilePhone,
            subject,
            content,
            eligibleToSkipStage,
            trackerId,
            fromAddress,
            signature,
            attachments,
            sequenceId
          };
          renderData.push(tempObj);
        }
      });
      if (isPrevOrNextEnabled) {
        return renderData;
      } else {
        setTaskListState(renderData);
        setLoadingFn(false);
      }
    }
  };

  const setLoadingFn = value => {
    setLoading(value);
  };

  const getTaskStatus = (status = "", taskDue = "", id = "") => {
    let tempStatus = status?.toLowerCase();
    if (["completed", "skipped"].includes(tempStatus)) {
      return (
        <div className="completedBtn">
          <i className="material-icons tickIcon">check</i>
          <span className="statusCont">{tempStatus}</span>
        </div>
      );
    } else {
      let tempTaskDue = Utils.getDueDateToRender(taskDue);
      return (
        <span
          className="taskDue"
          id={`taskdue${id}`}
          style={{ color: tempTaskDue?.color }}
        >
          {tempTaskDue?.dueDate}
        </span>
      );
    }
  };

  const fetchNextOrPrevTaskId = (taskNumber = "") => {
    if (taskStoreData?.taskIds?.length) {
      if (+taskNumber >= 1 && +taskNumber <= taskStoreData?.taskIds?.length) {
        if (+taskNumber >= taskStoreData?.taskIds?.length) {
          setNextBtnEnabled(false);
          setIsPreviousBtnEnabled(true);
        } else if (+taskNumber === 1) {
          setNextBtnEnabled(true);
          setIsPreviousBtnEnabled(false);
        }
        taskStoreData.setCurrentTaskId(
          taskStoreData?.taskIds?.[+taskNumber - 1]
        );
      }
    }
  };

  const showTask = async (showText = "", taskNum = 1, isLazyload = false) => {
    let taskNumber =
      showText === "complete"
        ? +selectedTaskNumber + 1
        : showText === "next"
        ? +selectedTaskNumber + 1
        : showText === "previous"
        ? +selectedTaskNumber - 1
        : selectedTaskNumber || 1;
    if (["previous", "next", "complete"].includes(showText?.toLowerCase())) {
      if (+taskNumber <= taskStoreData?.totalTasks && +taskNumber >= 1)
        setSelectedTaskNumber(taskNumber);
    }
    fetchNextOrPrevTaskId(taskNumber);
    const dateFilterValue = taskStoreData?.dateFilterValue;
    const statusFilterValue = taskStoreData?.statusFilterValue;
    setContactCardLoader(true);
    setTimelineLoader(true);
    setTaskLoading(true);
    await taskStoreData.getPerformTaskData(
      "",
      null,
      taskNumber,
      null,
      isLazyload,
      1,
      dateFilterValue,
      statusFilterValue,
      showText,
      true
    );
    let tempTaskData = constructTaskList(taskStoreData.taskData, true);
    if (tempTaskData?.length > 0) {
      setSelectedTask(tempTaskData[0]);
      setContactData(tempTaskData[0]);
      setTriggerTimeline(true);
      tempTaskData?.[0]?.id && history.push(tempTaskData[0]?.id);
      taskStoreData.setCurrentTaskId(tempTaskData[0]?.id);
    } else if (tempTaskData?.length === 0) {
      showTask("", taskStoreData?.totalTasks);
      setSelectedTaskNumber(taskStoreData?.totalTasks);
      setNextBtnEnabled(false);
    }
    setLoadingFn(false);
    setTaskLoading(false);
  };

  const fetchPerformTaskData = async () => {
    const dateFilterValue = taskStoreData?.dateFilterValue;
    const statusFilterValue = taskStoreData?.statusFilterValue;
    await taskStoreData.getTaskIdsFromSelectedPage(
      "",
      null,
      "",
      null,
      "",
      1,
      dateFilterValue,
      statusFilterValue,
      "",
      true
    );
  };

  const setSelectedTaskFn = (navigationType = "") => {
    if (navigationType === "complete") {
      showTask("complete");
      taskStoreData.setUpdatedFromAddressObj({});
    } else if (navigationType === "next") {
      showTask("next");
      taskStoreData.setUpdatedFromAddressObj({});
    } else if (navigationType === "previous") {
      showTask("previous");
      taskStoreData.setUpdatedFromAddressObj({});
    } else if (location?.state?.key) {
      setTaskLoading(true);
      fetchPerformTaskData();
      let tempTaskNumber =
        (taskStoreData?.selectedTaskPage - 1) * 20 + location?.state?.key;
      showTask("", tempTaskNumber);
      setSelectedTaskNumber(tempTaskNumber);
      if (tempTaskNumber === taskStoreData?.totalTasks) {
        setNextBtnEnabled(false);
      }
    } else {
      showTask("", 1);
    }
  };

  const reloadTaskPage = () => {
    taskStoreData.getPerformTaskData(
      "",
      null,
      selectedTaskNumber,
      null,
      false,
      1
    );
  };

  const markSelectedTaskFn = (currentTaskId, inputValue) => {
    taskListState.map(value => {
      if (value?.id === currentTaskId) {
        value.status = "COMPLETED";
        if (inputValue?.subject) {
          value.subject = inputValue.subject;
        }
        if (inputValue?.bodyContent) {
          value.bodyContent = inputValue.bodyContent;
        }
        if (inputValue?.attachments?.length) {
          value.attachments = inputValue.attachments;
        }
      }
      return taskListState;
    });
    const temp = taskStoreData.taskData;
    temp?.tasks?.length > 0 &&
      temp.tasks.map(item => {
        if (item?.task?.id === currentTaskId) {
          item.task.status = "COMPLETED";
          if (inputValue?.bodyContent) {
            item.task.touchPoint = { content: inputValue?.bodyContent };
          }
        }
        return temp;
      });
    taskStoreData.setTaskData(temp);
    setTaskListState(taskListState);
    setSelectedTask({
      ...selectedTask,
      content: inputValue?.bodyContent,
      subject: inputValue?.subject,
      attachments: inputValue?.attachments,
      status: "COMPLETED"
    });
  };

  const markSkippedTaskFn = (currentTaskId, inputValue) => {
    taskListState.map(value => {
      if (value && value.id === currentTaskId) {
        value.status = "SKIPPED";
      }
      return taskListState;
    });
    setTaskListState(taskListState);
  };

  const checkPrevAndNextEnabled = () => {
    if (selectedTaskNumber === 1 && taskStoreData?.totalTasks === 1) {
      setIsPreviousBtnEnabled(false);
      setNextBtnEnabled(false);
    } else if (selectedTaskNumber === 1) {
      setIsPreviousBtnEnabled(false);
      setNextBtnEnabled(true);
    } else if (selectedTaskNumber >= taskStoreData?.totalTasks) {
      setNextBtnEnabled(false);
      setIsPreviousBtnEnabled(true);
    } else {
      setIsPreviousBtnEnabled(true);
      setNextBtnEnabled(true);
    }
  };

  const getTaskType = () => {
    return ["linkedin", "phone", "email", "customtask"].includes(
      selectedTask?.taskType?.toLowerCase() || ""
    )
      ? selectedTask?.taskType
      : selectedTask?.taskType?.toLowerCase() === "manualemail"
      ? "email"
      : false;
  };

  const getTaskName = () => {
    return ["customtask"].includes(selectedTask?.taskType?.toLowerCase())
      ? "Custom Task"
      : selectedTask?.taskType;
  };

  const reloadPerformTask = (keyType = "", obj = {}) => {
    ContactTimelineStore.setReloadTaskTimelineActivity(true);
    if (
      selectedTask?.contactId === obj?.contactId &&
      (selectedTask?.sequenceId === obj?.sequenceId ||
        selectedTask?.sequenceId === obj?.scheduledSeqId) &&
      ["PAUSED", "PAUSED_OOTO", "REMOVED", "SKIP", "COMPLETED"]?.includes(
        keyType?.toUpperCase()
      )
    ) {
      setSelectedTaskFn(isNextBtnEnabled ? "next" : "");
    }
  };

  useEffect(() => {
    localStorage.setItem("selectedTaskNo", selectedTaskNumber);
  }, [selectedTaskNumber]);

  const backToTaskPage = () => {
    taskStoreData.setResetState();
    history.push("/tasks");
  };

  useEffect(() => {
    localStorage.setItem("selectedTaskNo", selectedTaskNumber);
  }, [selectedTaskNumber]);

  useEffect(() => {
    checkPrevAndNextEnabled();
  }, [selectedTaskNumber, taskStoreData?.totalTasks]);

  useEffect(() => {
    if (taskStoreData?.taskData?.length === 0) {
      taskStoreData.getTaskData("", null, 1, true, true, 20, {
        status: "ACTIVE"
      });
    }
    setSelectedTaskFn();
    localStorage.removeItem("selectedTaskNo");
    return () => {
      taskStoreData.setResetState();
      taskStoreData.setTaskAttachments([]);
    };
  }, []);

  const taskHeaderLoader = () => {
    return (
      <>
        <div className="detailedTaskInfo">
          <div className="detailedTaskInfoLoader">
            <div className="iconLoader linear-background" />
            <div className="taskTypeLoader linear-background" />
            <div className="taskDueLoader linear-background" />
          </div>
        </div>
        <div className="navigationAndTaskCount">
          <div className="numberOfTaskCont linear-background" />
          <div className="navTaskBtnCont">
            <div className="navToPrev linear-background" />
            <div className="navToNext linear-background" />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="performTask pageClass">
      <div className="performTaskCont pageCont">
        <section className="headerSection">
          <BreadCrumb breadCrumbValue={breadcrumbArr} />
        </section>

        <section className="taskSection pageLayout">
          <div className="contentLayout">
            <div className="performTaskPanel">
              <div className="taskHeaderSection">
                <BackToTaskPage backToTaskPage={backToTaskPage} />
                <div className="taskDetailNavHeader">
                  {loading && taskHeaderLoader()}
                  {!loading && (
                    <>
                      <div className="detailedTaskInfo">
                        <div className="taskTypeWithIcon">
                          <IconType taskType={getTaskType()} />
                          <div className="taskName">{getTaskName()}</div>
                        </div>
                        {getTaskStatus(
                          selectedTask?.status,
                          selectedTask?.taskDue,
                          selectedTask?.id
                        )}
                      </div>
                      <div className="navigationAndTaskCount">
                        <div className="numberOfTaskCont">
                          <div className="selectedTaskNumber">
                            {selectedTaskNumber || 1}
                            &nbsp;of&nbsp;
                          </div>
                          <div className="totalTask">
                            {taskStoreData?.totalTasks || 1}&nbsp;
                            {taskStoreData?.totalTasks > 1 ? "Tasks" : "Task"}
                          </div>
                        </div>
                        <NavTaskButton
                          setSelectedTaskFn={setSelectedTaskFn}
                          isPreviousBtnEnabled={isPreviousBtnEnabled}
                          isNextBtnEnabled={isNextBtnEnabled}
                          id={selectedTask?.id}
                          pageType="PERFORM_TASK"
                          taskType={selectedTask?.taskType?.toLowerCase()}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="taskBody">
                <ContactCard
                  contact={{
                    id: selectedTask?.contactId || contactData?.contactId
                  }}
                  reloadTaskPage={true}
                  isContactSlider={false}
                  handleSuccessCallback={reloadTaskPage}
                  contactCardLoader={contactCardLoader}
                  setContactCardLoader={setContactCardLoader}
                  setTriggerTimeline={setTriggerTimeline}
                  pageType="performTask"
                  cardType="perform_task"
                  isReloadCbk={reloadPerformTask}
                  triggerContactCard={triggerTimeline}
                />
                <ContactTimeline
                  isTaskPage={true}
                  contactIdFromTaskPage={
                    selectedTask?.contactId || contactData?.contactId
                  }
                  timelineLoader={timelineLoader}
                  setTimelineLoader={setTimelineLoader}
                  triggerTimeline={triggerTimeline}
                  setTriggerTimeline={setTriggerTimeline}
                />
                <PerformTaskOperation
                  data={selectedTask}
                  loading={loading}
                  setSelectedTaskFn={setSelectedTaskFn}
                  markSelectedTaskFn={markSelectedTaskFn}
                  markSkippedTaskFn={markSkippedTaskFn}
                  selectedTaskNumber={selectedTaskNumber}
                  isNextBtnEnabled={isNextBtnEnabled}
                  isPreviousBtnEnabled={isPreviousBtnEnabled}
                  setTaskLoading={setTaskLoading}
                  taskDetails={taskDetails}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const BackToTaskPage = ({ backToTaskPage = () => {} }) => {
  return (
    <div className="backButton">
      <span className="backIconToolTipWrapper" onClick={backToTaskPage}>
        <i className="material-icons">keyboard_backspace</i>
      </span>
    </div>
  );
};

export default withRouter(observer(PerformTaskComponent));
export { PerformTaskComponent };
