import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import domainHealthCenterStore from "./store";
import InboxListLoader from "./InboxListLoader";
import { toasterState } from "Components/common/toaster";

const AuthenticationStatus = (props = {}) => {
  let pendingCount = 0;
  const { domainHealth = [] } = props || {};
  const { dnsRecords = {} } = domainHealth || {};

  if (!dnsRecords?.mxRecord?.length > 0) {
    pendingCount = pendingCount + 1;
  }
  if (!dnsRecords?.spfRecord) {
    pendingCount = pendingCount + 1;
  }
  if (!dnsRecords?.dmarcRecord) {
    pendingCount = pendingCount + 1;
  }
  if (!dnsRecords?.dkim) {
    pendingCount = pendingCount + 1;
  }

  return (
    <span
      className={`authentication ${pendingCount > 0 ? "pending" : "completed"}`}
    >
      {pendingCount > 0 ? `${pendingCount} Pending` : "Completed"}
    </span>
  );
};

const ProfileNameAndPicture = props => {
  const { picture = "", userName = "" } = props || {};
  const elements = [
    {
      elementclassName: "profilePicture",
      iconClassName: picture ? "doneColor" : "errorColor",
      iconName: picture ? "done" : "error_outline"
    },
    {
      elementclassName: "profileName",
      iconClassName: userName ? "doneColor" : "errorColor",
      iconName: userName ? "done" : "error_outline"
    }
  ];

  return (
    <>
      {elements.map((element, index) => {
        const {
          elementclassName = "",
          iconClassName = "",
          iconName = ""
        } = element || {};
        return (
          <span className={elementclassName} key={index}>
            <i className={`material-icons-outlined ${iconClassName}`}>
              {iconName}
            </i>
          </span>
        );
      })}
    </>
  );
};

const InboxListHeader = () => {
  const inboxHeader = [
    { className: "inboxName", label: "Inbox" },
    { className: "authentication", label: "Authentication" },
    { className: "profilePicture", label: "Profile Picture" },
    { className: "profileName", label: "Profile Name" }
  ];

  return (
    <div className="inboxListHeader">
      {inboxHeader.map(item => (
        <div className={item?.className} key={item?.label}>
          {item?.label}
        </div>
      ))}
    </div>
  );
};

const NoInboxConnected = () => {
  const history = useHistory();

  return (
    <div className="noInboxConnectedWrapper">
      <span
        className="connectEmailAccounts"
        onClick={() => history.push("/settings/account")}
      >
        Connect your email accounts
      </span>
      <span className="connectInboxContent">
        {" "}
        to scan for authentication errors and discover best practices for better
        email deliverability.
      </span>
    </div>
  );
};

const InboxListBody = observer((props = {}) => {
  const { updatedInboxList = [], setUpdatedInboxList = () => {} } = props || {};

  const handleRefreshClick = async (event = "", id = "", index = "") => {
    if (id) {
      domainHealthCenterStore.setLoadRefreshAccountId(index);
      Utils.preventDefaultFn(event);
      const config = {
        url: `${URLS.getDomainHealth}?inboxId=${id}`,
        method: "GET"
      };
      let response = await makeApi(config);
      if (+response?.status === 200) {
        domainHealthCenterStore.setLoadRefreshAccountId("");
        const { data = {} } = response || {};
        const temp = [...updatedInboxList];
        temp[index].domainHealth = data;
        setUpdatedInboxList(temp);
        domainHealthCenterStore.getDomainHealthScore();
      } else {
        toasterState.setToastMsg("Something went wrong", "failure");
      }
    }
  };

  const redirectToDetailedDomainHealthInfo = (item = {}) => {
    const {
      email = "",
      picture = "",
      userName = "",
      id = "",
      domainHealth = [],
      type = ""
    } = item || {};
    const { dnsRecords = {} } = domainHealth || {};
    domainHealthCenterStore.setDomainHealthDetailedInfo(true);
    const tempDnsRecords =
      dnsRecords && Object.keys(dnsRecords)?.length > 0
        ? dnsRecords
        : {
            spfRecord: "",
            mxRecord: [],
            dkim: "",
            dmarcRecord: ""
          };
    domainHealthCenterStore.setDnsRecords({
      ...tempDnsRecords,
      email,
      picture,
      userName,
      id,
      type
    });
  };

  return updatedInboxList?.length > 0 ? (
    updatedInboxList.map((item, index) => {
      const { email = "", id = "", domainHealth = {} } = item || {};

      return domainHealthCenterStore.loadRefreshAccountId === index ? (
        <InboxListLoader length={1} key={index} />
      ) : (
        <div
          className="inboxItem"
          key={index}
          onClick={() => redirectToDetailedDomainHealthInfo(item)}
        >
          <span className="inboxName" title={email}>
            {email}
          </span>
          <AuthenticationStatus domainHealth={domainHealth} />
          <ProfileNameAndPicture {...item} />
          <span
            className="refreshIcon material-icons"
            onClick={e => handleRefreshClick(e, id, index)}
          >
            refresh
          </span>
        </div>
      );
    })
  ) : !domainHealthCenterStore?.allConnectedAccounts?.length ? (
    <NoInboxConnected />
  ) : (
    <div className="noInboxFound">No Inboxes found</div>
  );
});

export { InboxListHeader, InboxListBody };
