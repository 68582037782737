import React from "react";
import { ReactComponent as CalendarIcon } from "./Icons/calendar.svg";
import { ReactComponent as PhoneCallIcon } from "./Icons/phoneCall.svg";
import { ReactComponent as EmailIcon } from "./Icons/email.svg";
import { ReactComponent as PricingIcon } from "./Icons/pricing.svg";

const iconList = [
  // {
  //   name: "calendar",
  //   icon: <CalendarIcon />,
  //   title: "Book Meeting"
  // },
  {
    name: "phoneCall",
    icon: <PhoneCallIcon />,
    title: "Make a call"
  },
  {
    name: "email",
    icon: <EmailIcon />,
    title: "Send Email"
  },
  {
    name: "pricing",
    icon: <PricingIcon />,
    title: "Pricing"
  }
];

export { iconList };
