import { observable, action } from "mobx";
import Utils from "Utils/utils";
import { getFeatureFlagWithTeam } from "Utils/commonAPI";
import userDetail from "Stores/userDetail";
import {
  createCallStagePopupState,
  defaultPopupValues
} from "Pages/Dashboard/settings/Calls/Disposition/CreateCallStagePopup";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import { makeApi, URLS } from "Utils/apiURL";
import { MP_EVENT } from "Static/constant";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { RECHARGE_ERR_MSG } from "Model/model";

const showErrMsg = (responseCode = 0) => {
  +responseCode === 409
    ? toasterState.setToastMsg("Call state already exists", "failure")
    : toasterState.setToastMsg(
        "Something went wrong. Please try again later.",
        "failure"
      );
};

class DialerStore {
  cancelAvailableNumbersCall = null;

  @observable myPhoneNumbers = null;
  @observable stageLoader = true;
  @observable stageList = null;
  @observable singleStageLoader = false;
  @observable currentEditedField = "";
  @observable currentEditedFieldLoader = false;

  @action
  cancelAvailableNumbersCallAction = value => {
    this.cancelAvailableNumbersCall = value;
  };

  @action
  setMyPhoneNumbers = value => {
    this.myPhoneNumbers = value;
  };

  @action
  setStageLoader = (value = false) => {
    this.stageLoader = value;
  };

  @action
  setStageList = (list = []) => {
    this.stageList = list;
  };

  @action
  setCurrentEditedField = (name = "") => {
    this.currentEditedField = name;
  };

  @action
  setCurrentEditedFieldLoader = (value = false) => {
    this.currentEditedFieldLoader = value;
  };

  @action
  setSingleStageLoader = (val = false) => {
    this.singleStageLoader = val;
  };

  @action
  setResetStage = () => {
    this.stageLoader = true;
    this.singleStageLoader = false;
    this.currentEditedField = "";
    this.currentEditedFieldLoader = false;
  };

  @action
  getMyPhoneNumbers = async () => {
    try {
      let config = {
        url: URLS.getMyPhoneNumbers,
        method: "GET"
      };
      let response = await makeApi(config);
      response?.data && this.setMyPhoneNumbers(response.data);
    } catch (err) {
      console.error("Dialer Data error ", err);
    }
  };

  @action
  updateDefaultPhoneNumber = async data => {
    try {
      let config = {
        url: URLS.updateDefaultPhoneNumber,
        method: "PUT",
        data
      };
      let response = await makeApi(config);
      if (response && response.data && response.data.status === "200") {
        toasterState.setToastMsg(
          "Default Phone number updated successfully",
          "success"
        );
        this.updateDefaultNumberInStore(data.ph_number);
      } else {
        this.showResponseMessage(response);
      }
    } catch (err) {
      console.error("Update default phone number error ", err);
    }
  };

  @action
  deletePhoneNumber = async (url, number) => {
    if (url) {
      try {
        let config = {
          url: url,
          method: "DELETE"
        };
        let response = await makeApi(config);
        if (response && response.data && response.data.status === "200") {
          const updatedDefaultNumber = response.data.defaultPhNumber;
          this.getDialerFeatureFlags();
          toasterState.setToastMsg(
            "Phone number deleted successfully",
            "success"
          );
          this.removeDeletedNumberInStore(number, updatedDefaultNumber);
        } else {
          this.showResponseMessage(response);
        }
      } catch (err) {
        console.error("Delete phone number error ", err);
      }
    }
  };

  @action
  updateDefaultNumberInStore = updatedNumber => {
    const storeData = JSON.parse(JSON.stringify(this.myPhoneNumbers));
    if (storeData && storeData.defaultPhNumber) {
      storeData.defaultPhNumber = updatedNumber;
      this.setMyPhoneNumbers(storeData);
    }
  };

  @action
  getAvailableNumbers = async url => {
    if (url) {
      try {
        let config = {
          url: url,
          method: "GET"
        };
        this.cancelPreviousRequest();
        let response = await makeApi(
          config,
          this.cancelAvailableNumbersCallAction
        );
        if (response && response.data && response.data.status === "200") {
          return response.data.phoneNumbers;
        } else {
          this.showResponseMessage(response);
          return false;
        }
      } catch (err) {
        console.error("Fetching availale phone numbers error ", err);
      }
    }
  };

  @action
  purchaseNumber = async (data, buyCredits) => {
    try {
      let config = {
        url: URLS.purchaseNumber,
        method: "POST",
        data
      };
      let response = await makeApi(config);
      const { data: responseData = {} } = response || {};
      const { status = "", phoneNumbers = "" } = responseData || {};
      if (+status === 200 && phoneNumbers) {
        const purchasedNumber = phoneNumbers;
        this.getDialerFeatureFlags();
        toasterState.setToastMsg(
          "Phone number purchased successfully",
          "success"
        );
        dialerStoreData.getMyPhoneNumbers();
        Utils.mixpanelTrack(MP_EVENT.PHONE_NUMBER_PURCHASED);
        this.updateMyNumbersInStore(purchasedNumber);
      } else {
        this.showResponseMessage(response, buyCredits);
      }
    } catch (err) {
      console.error("Update default phone number error ", err);
    }
  };

  @action
  cancelPreviousRequest = () => {
    if (dialerStoreData.cancelAvailableNumbersCall !== null) {
      dialerStoreData.cancelAvailableNumbersCall("cancel");
      dialerStoreData.cancelAvailableNumbersCall = null;
    }
  };

  @action
  removeDeletedNumberInStore = (deletedNumber, updatedDefaultNumber) => {
    if (deletedNumber) {
      const storeData = JSON.parse(JSON.stringify(this.myPhoneNumbers));
      if (storeData && storeData.phoneNumbers) {
        const phoneNumbers = storeData.phoneNumbers;
        if (phoneNumbers) {
          const remainigElem = [];
          // eslint-disable-next-line array-callback-return
          phoneNumbers.map(value => {
            if (value && value.number && value.number !== deletedNumber) {
              remainigElem.push(value);
            }
          });
          storeData.phoneNumbers = remainigElem;
          storeData.defaultPhNumber = updatedDefaultNumber;
          this.setMyPhoneNumbers(storeData);
        }
      }
    }
  };

  @action
  updateMyNumbersInStore = purchasedNumber => {
    if (purchasedNumber) {
      const storeData = JSON.parse(JSON.stringify(this.myPhoneNumbers));
      if (storeData && storeData.defaultPhNumber) {
        storeData.phoneNumbers.push(purchasedNumber);
      } else {
        storeData.phoneNumbers = [purchasedNumber];
        storeData.defaultPhNumber = purchasedNumber;
      }
      this.setMyPhoneNumbers(storeData);
    }
  };

  @action
  verifyCallerId = async url => {
    try {
      let config = {
        url: url,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response && response.data) {
        return response.data;
      } else {
        Utils.showApiFailureResponse(response);
        return false;
      }
    } catch (err) {
      console.error("Verify caller Id error", err);
    }
  };

  @action
  isCalledIdVerified = async url => {
    try {
      let config = {
        url: url,
        method: "GET"
      };
      let response = await makeApi(config);
      return response?.data?.status || "";
    } catch (err) {
      console.error("Verify caller Id error", err);
    }
    return "";
  };

  @action
  purchaseCredits = async data => {
    try {
      let config = {
        url: URLS.purchaseDialerCredits,
        method: "POST",
        data
      };
      let response = await makeApi(config);
      if (response?.data?.responseCode === 200) {
        this.getDialerFeatureFlags();
        toasterState.setToastMsg(
          "Credits added to your account successfully",
          "success"
        );
      } else {
        this.showResponseMessage(response);
      }
    } catch (err) {
      console.error("Update default phone number error ", err);
    }
  };

  @action
  getDialerFeatureFlags = async () => {
    userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
  };

  @action
  checkAndCreateTwilioSubAccount = async () => {
    try {
      let config = {
        url: URLS.checkAndCreateTwilioSubaccount,
        method: "POST"
      };
      await makeApi(config);
    } catch (err) {
      console.error("Creating twilio sub account error ", err);
    }
  };

  @action
  purchaseCreditsWithoutSubscription = async data => {
    try {
      let config = {
        url: URLS.buyCreditWithoutSubscription,
        method: "POST",
        data
      };
      let response = await makeApi(config);
      if (response?.data?.responseCode === 200) {
        this.getDialerFeatureFlags();
        toasterState.setToastMsg(
          "Credits added to you account successfully",
          "success"
        );
      } else {
        this.showResponseMessage(response);
      }
    } catch (err) {
      console.error("Recharge wallet for phone number failed", err);
    }
  };

  @action
  showResponseMessage = (response, buyCredits = () => {}) => {
    let code = response?.data?.responseCode || response?.data?.status;
    if (code) {
      let content = RECHARGE_ERR_MSG[code] || "";
      if (content) {
        let isAddCredits = code?.toString() === "427";
        confirmationPopupState.setPopupValues({
          content: content,
          actionBtnText: isAddCredits ? "Add credits" : "Ok",
          cancelBtnText: "Cancel",
          needCancelBtn: isAddCredits || false,
          dynamicContent: true,
          callback: () =>
            isAddCredits
              ? buyCredits()
              : confirmationPopupState.setShowPopup(false)
        });
        confirmationPopupState.setShowPopup(true);
      }
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "failure"
      );
    }
  };

  @action
  getCallStageList = async (allowLoader = true) => {
    allowLoader && this.setStageLoader(true);
    let config = {
      url: URLS.getCallStageList
    };
    let response = await makeApi(config);
    if (allowLoader) {
      this.setCurrentEditedFieldLoader(false);
      this.setCurrentEditedField("");
      this.setSingleStageLoader(false);
    }
    let tempList = response?.data || [];
    if (tempList?.length > 0) {
      this.setStageList(tempList);
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "failure"
      );
    }
    allowLoader && this.setStageLoader(false);
    return response?.data || [];
  };

  @action
  createNewCallStage = async (data = {}) => {
    this.setSingleStageLoader(true);
    let config = {
      url: URLS.createNewCallStage,
      method: "POST",
      data
    };
    let response = await makeApi(config);
    createCallStagePopupState.setButtonLoader(false);
    createCallStagePopupState.setShowPopup(false);
    createCallStagePopupState.setPopupValues(defaultPopupValues);
    const { responseCode = "" } = response?.data || {};
    if (+responseCode === 200) {
      Utils.mixpanelTrack(MXP_EVENT.CALL_STAGE_CREATED, {
        pageType: "Settings - Call Stages",
        data
      });
      this.getCallStageList();
      toasterState.setToastMsg(
        "New call state created successfully.",
        "success"
      );
    } else {
      showErrMsg(responseCode);
    }
    this.setSingleStageLoader(false);
  };

  @action
  updateCallStage = async (data = {}, type = "") => {
    this.setCurrentEditedFieldLoader(true);
    let config = {
      url: URLS.updateCallStage,
      method: "POST",
      data
    };
    let response = await makeApi(config);
    if (type === "update") {
      createCallStagePopupState.setButtonLoader(false);
      createCallStagePopupState.setShowPopup(false);
      createCallStagePopupState.setPopupValues(defaultPopupValues);
    }
    if (response?.data) {
      const { responseCode = "" } = response?.data || {};
      if (+responseCode === 200) {
        Utils.mixpanelTrack(MXP_EVENT.CALL_STAGE_UPDATED, {
          pageType: "Settings - Call Stages",
          data
        });
        this.getCallStageList();
        toasterState.setToastMsg("Call state updated successfully.", "success");
      } else {
        showErrMsg(responseCode);
      }
    }
    this.setCurrentEditedField("");
    this.setCurrentEditedFieldLoader(false);
  };

  @action
  updateCallStageVisiblity = async (data = {}) => {
    let config = {
      url: URLS.updateCallStageVisiblity,
      method: "POST",
      data
    };
    let response = await makeApi(config);
    if (response?.data) {
      const { responseCode = "" } = response?.data || {};
      if (+responseCode === 200) {
        Utils.mixpanelTrack(MXP_EVENT.CALL_STAGE_UPDATED, {
          pageType: "Settings - Call Stages",
          data
        });
        this.getCallStageList();
        toasterState.setToastMsg(
          `${data?.name} state ${
            data?.displayEnabled ? "will" : "will not"
          } be visible on the calls section`,
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
    }
  };

  @action
  deleteCallStage = async (data = {}) => {
    let config = {
      url: URLS.deleteCallStage,
      method: "POST",
      data
    };
    let response = await makeApi(config);
    if (response?.data) {
      const { responseCode = "" } = response?.data || {};
      confirmationPopupState.setShowPopup(false);
      if (+responseCode === 200) {
        this.getCallStageList();
        Utils.mixpanelTrack(MXP_EVENT.CALL_STAGE_DELETED, {
          pageType: "Settings - Call Stages",
          data
        });
        toasterState.setToastMsg("Call state deleted successfully.", "success");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
    }
  };
}

const dialerStoreData = new DialerStore();
export { dialerStoreData, DialerStore };
export default dialerStoreData;
