import React, { useState } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import InputBox from "Components/Register/InputBox";
import Button from "Components/common/Button";
import { Utils } from "Utils/utils";
import VerifiedImg from "Assets/png/verified.png";

import makeApi, { URLS } from "Utils/apiURL";
import { captchaStoreValue } from "Stores/captcha";

const ResetPasswordComponent = props => {
  let { id = "", code = "" } = useParams();

  const [newPassword, setNewPassword] = useState({
    password: "",
    showPassword: false
  });
  const [confirmNewPassword, setConfirmNewPassword] = useState({
    password: "",
    showPassword: false
  });
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isResetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const newPasswordChange = value => {
    setNewPassword(value);
    setErrMsg("");
    setPasswordErr("");
  };

  const confirmNewPasswordChange = value => {
    setConfirmNewPassword(value);
    setErrMsg("");
    setConfirmPasswordErr("");
  };

  const submitForm = e => {
    e.preventDefault();
    e.keyCode === 13 && executeCaptcha(e);
  };

  const resetPassword = async token => {
    setLoader(true);
    if (token) {
      try {
        const config = {
          url: URLS.resetPasswordUser,
          method: "POST",
          data: {
            code,
            id,
            password: newPassword?.password || "",
            token
          }
        };
        let res = await makeApi(config);
        res?.data?.isResetPasswordSuccess
          ? setResetPasswordSuccess(true)
          : setErrMsg("Something went wrong");
        setLoader(false);
      } catch (err) {
        setLoader(false);
        console.error(err, "Reset password failed");
      }
    }
  };

  const inputBoxObjList = [
    {
      name: "createNewPassword",
      id: "createNewPassword",
      type: "password",
      value: newPassword,
      changeAction: value =>
        newPasswordChange({ ...newPassword, password: value }),
      keyupAction: submitForm,
      errMsg: passwordErr,
      label: "Create New password",
      className: "password",
      isPassword: true,
      placeholder: "Create New password",
      showPasswordAction: () =>
        setNewPassword({
          ...newPassword,
          showPassword: !newPassword?.showPassword
        }),
      focus: true
    },
    {
      name: "password",
      id: "password",
      type: "password",
      value: confirmNewPassword,
      changeAction: value =>
        confirmNewPasswordChange({ ...confirmNewPassword, password: value }),
      keyupAction: submitForm,
      errMsg: confirmPasswordErr || errMsg,
      label: "Confirm New password",
      className: "password",
      isPassword: true,
      placeholder: "Confirm New password",
      showPasswordAction: () =>
        setConfirmNewPassword({
          ...confirmNewPassword,
          showPassword: !confirmNewPassword?.showPassword
        })
    }
  ];

  const executeCaptcha = e => {
    e && Utils.removeInputRequired(e);
    let isPasswordValid = Utils.validateStringLength(
      newPassword?.password || "",
      6
    );
    let isConfirmPasswordValid = Utils.validateStringLength(
      confirmNewPassword?.password || "",
      6
    );
    let isPasswordMatched = Utils.validateMatchPassword(
      newPassword?.password || "",
      confirmNewPassword?.password || ""
    );
    if (isPasswordValid && isConfirmPasswordValid && isPasswordMatched) {
      captchaStoreValue.executeCaptcha(resetPassword);
    } else {
      !isPasswordValid
        ? setPasswordErr("Password is required")
        : !isConfirmPasswordValid
        ? setConfirmPasswordErr("Password is required")
        : !isPasswordMatched
        ? setErrMsg("Password did not match")
        : setErrMsg("Something went wrong");
    }
  };

  return (
    <section
      className={`formInputBox ${loader ? "pointerUserSelectNone" : ""}`}
    >
      {isResetPasswordSuccess ? (
        <div className="resetPasswordSuccess">
          <img src={VerifiedImg} alt="Email" className="verifiedImg" />
          <h3 className="verified-title">Password successfully updated!</h3>
          <p className="descText">
            You have successfully created your new password. Please click the
            login link below to continue.
          </p>
          <Link
            to="/login"
            className="loginRedirectBtn btn-new btn-rounded btn-orange btn-text btn-arrow"
          >
            Log In
          </Link>
        </div>
      ) : (
        <>
          <h3>Reset Password</h3>
          <p className="descText">
            Almost there! Now let's set up your new password.
          </p>
          {inputBoxObjList.map(item => (
            <InputBox {...item} key={item.id} />
          ))}
          <Button
            id="resetPasswordBtn"
            name="resetPasswordBtn"
            btnText="Reset password"
            className={
              loader
                ? "loading"
                : "resetPasswordBtn btn-new btn-rounded btn-orange btn-text btn-arrow"
            }
            loader={loader}
            type="button"
            click={e => {
              e.preventDefault();
              executeCaptcha(e);
            }}
          />
        </>
      )}
    </section>
  );
};

export default withRouter(ResetPasswordComponent);
