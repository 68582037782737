/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { observable, action } from "mobx";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import ConstantObj from "Static/constant";
import Button from "Components/common/Button";
import Utils from "Utils/utils";
import { tagsStoreData } from "Stores/TagsData";
import PersonalInformation from "./contact/PersonalInformation";
import CompanyInformation from "./contact/CompanyInformation";
import ContactNotes from "./contact/ContactNotes";
import LocationInformation from "./contact/LocationInformation";
import SocialLinks from "./contact/SocialLinks";
import ContactTags from "./contact/ContactTags";
import ContactCustomFields from "./contact/ContactCustomFields";
import { SequenceUtils } from "Utils/SequenceUtils";
import { userDetail } from "Stores/userDetail";

class AddSingleContactPopupState {
  @observable showPopup = false;
  @observable submitCallback = () => {};
  @observable successCallback = () => {};
  @observable sequenceData = {};
  @observable tokenList = [];
  @observable isContactPage = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setIsContactPage = value => {
    this.isContactPage = value;
  };

  @action
  setSequenceData = val => {
    this.sequenceData = val;
  };

  @action
  setTokenList = val => {
    this.tokenList = val;
  };

  @action
  setSubmitCallback = val => {
    this.submitCallback = val;
  };

  @action
  setSuccessCallback = val => {
    this.successCallback = val;
  };
}

const addSingleContactPopupState = new AddSingleContactPopupState();

const AddSingleContactPopup = observer(() => {
  const history = useHistory();

  const { emailValidationCredits = 0, emailValidationEnabled = false } =
    userDetail.userFeatureFlag || {};

  const [inputVal, setInputVal] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);
  const [showTagDropDown, setShowTagDropDown] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState(inputVal.contactTags);
  const [errMsg, setErrMsg] = useState("");
  const [isBlockedDomain, setIsBlockedDomain] = useState(false);

  const changeInputVal = (id, data, flag) => {
    if (flag === "customFields") {
      const stateObj = { ...inputVal };
      const customFields = stateObj["customFields"];
      if (customFields) {
        customFields[id] = data;
        setInputVal(stateObj);
      } else {
        const tempObj = {};
        tempObj[id] = data;
        stateObj.customFields = tempObj;
        setInputVal(stateObj);
      }
    } else {
      const stateObj = { ...inputVal };
      if (id === "email") {
        errMsg && setErrMsg("");
        checkBlockedDomain(data);
      }
      stateObj[id] = data;
      setInputVal(stateObj);
    }
  };

  const checkBlockedDomain = (email = "") => {
    setIsBlockedDomain(false);
    let splitdomain = email?.split("@") || [];
    if (
      splitdomain?.length > 1 &&
      userDetail?.blockedDomainList?.includes(splitdomain?.[1])
    ) {
      setIsBlockedDomain(true);
    }
  };

  const processContactAndAddToSequence = () => {
    setButtonLoader(true);
    const payload = getAddContactPayload();
    addSingleContactPopupState.submitCallback(
      payload,
      addSingleContactPopupState.sequenceData,
      addSingleContactPopupState.successCallback
    );
    closeAction();
  };

  const submitAction = async ev => {
    if (ev?.target?.checkValidity()) {
      ev.preventDefault(); // Necessary to prevent submiting form in url
      if (
        ConstantObj.emailRegex.test(ev?.target["email"]?.value) &&
        !isBlockedDomain
      ) {
        if (addSingleContactPopupState?.sequenceData?.emailValidationEnabled) {
          if (
            emailValidationEnabled &&
            emailValidationCredits < 1 &&
            !addSingleContactPopupState?.isContactPage
          ) {
            SequenceUtils.promptEmailValidationNoCredits(
              1, // count
              history,
              processContactAndAddToSequence,
              closeAction,
              "sequence"
            );
            return;
          }
        }
        processContactAndAddToSequence();
      } else {
        if (isBlockedDomain) {
          setErrMsg("Entered domain is blocked");
        } else {
          setErrMsg("Please enter a valid email address");
        }
      }
    } else {
      ev?.target?.reportValidity();
    }
  };

  const getAddContactPayload = () => {
    if (inputVal && Object.keys(inputVal).length) {
      const payload = {};
      const contact = {};
      if (selectedTags?.length) {
        const extractedTagsArray = Utils.extractTagNameFromList(selectedTags);
        payload.contactTags = extractedTagsArray;
      }
      contact.firstName = inputVal.firstName || null;
      contact.lastName = inputVal.lastName || null;
      contact.email = inputVal.email || null;
      contact.linkedinId = Utils.removeHttpFromString(
        inputVal.linkedinId || null
      );
      if (inputVal?.mobilePhone?.length) {
        contact.mobilePhone = [inputVal.mobilePhone];
      }
      if (inputVal?.otherPhone?.length) {
        contact.otherPhone = [inputVal.otherPhone];
      }
      if (inputVal?.phone?.length) {
        contact.phone = [inputVal.phone];
      }
      contact.company = inputVal.company || null;
      contact.jobTitle = inputVal.jobTitle || null;
      contact.industry = inputVal.industry || null;
      contact.street = inputVal.street || null;
      contact.city = inputVal.city || null;
      contact.state = inputVal.state || null;
      contact.country = inputVal.country || null;
      contact.zipCode = inputVal.zipCode || null;
      contact.website = inputVal.website || null;
      contact.facebook = inputVal.facebook || null;
      contact.twitter = inputVal.twitter || null;
      contact.contactNotes = inputVal.contactNotes || null;
      contact.customFields =
        Utils.addNullForEmptyObject(inputVal.customFields) || null;
      payload.contacts = [contact];
      return payload;
    }
  };

  const closeAction = () => {
    errMsg && setErrMsg("");
    setInputVal({});
    setButtonLoader(false);
    setShowTagDropDown(false);
    setSelectedTags({});
    addSingleContactPopupState.setShowPopup(false);
  };

  const CloseIcon = () => {
    return (
      <div
        className="popup-close"
        title="Click here to close"
        onClick={closeAction}
      >
        <i className="material-icons">close</i>
      </div>
    );
  };

  const addSelectedTagsInTagList = (tagList, existingTags) => {
    if (existingTags && existingTags.length) {
      tagList &&
        tagList.map(obj => {
          const { value } = obj;
          if (value && existingTags.includes(value)) {
            obj.checked = true;
          }
        });
    }
    setTagList(tagList);
  };

  useEffect(() => {
    if (tagsStoreData.tagsData) {
      addSelectedTagsInTagList(
        Utils.createObjWithoutRef(tagsStoreData.tagsData),
        null
      );
    }
  }, [tagsStoreData.tagsData]);

  return addSingleContactPopupState.showPopup ? (
    <div className="popupWrapper editContactPopup">
      <div className="confirmation-popup common-popup">
        <form
          className="popup-wrap animated customzoomIn "
          onSubmit={e => {
            submitAction(e);
          }}
          autoComplete="off"
        >
          {showTagDropDown && (
            <div
              className="transparentOverlay"
              onClick={e => setShowTagDropDown(false)}
            ></div>
          )}
          <div className="title">
            <div className="titleName">Add Contact</div>
          </div>
          <CloseIcon />
          <div className="popContainer popup-content-wrap">
            <PersonalInformation
              changeInputVal={changeInputVal}
              inputVal={inputVal}
              tokenList={addSingleContactPopupState.tokenList}
              blockedDomainList={userDetail?.blockedDomainList}
              errMsg={errMsg}
              isContactPage={addSingleContactPopupState?.isContactPage || false}
            />
            <div className="companyAndNotes">
              <CompanyInformation
                changeInputVal={changeInputVal}
                inputVal={inputVal}
                tokenList={addSingleContactPopupState.tokenList}
                isContactPage={
                  addSingleContactPopupState?.isContactPage || false
                }
              />
              <ContactNotes
                changeInputVal={changeInputVal}
                inputVal={inputVal}
              />
            </div>
            <LocationInformation
              changeInputVal={changeInputVal}
              inputVal={inputVal}
              tokenList={addSingleContactPopupState.tokenList}
            />
            <SocialLinks
              changeInputVal={changeInputVal}
              inputVal={inputVal}
              tokenList={addSingleContactPopupState.tokenList}
            />
            <ContactTags
              tagsList={tagList}
              setTagList={setTagList}
              showTagDropDown={showTagDropDown}
              setShowTagDropDown={setShowTagDropDown}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
            <ContactCustomFields
              changeInputVal={changeInputVal}
              customFieldsValue={inputVal.customFields || {}}
              tokenList={addSingleContactPopupState.tokenList}
            />
          </div>
          <div className="popup-action-wrap">
            <Button
              type="submit"
              btnText="Upload"
              className={`positive-btn  ${buttonLoader ? "loading" : ""}`}
              // click={e => submitAction(e)}
              loader={buttonLoader}
              needExtraDom={false}
            />
          </div>
        </form>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

export { AddSingleContactPopup, addSingleContactPopupState };
export default AddSingleContactPopup;
