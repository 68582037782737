/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useRef,
  createContext,
  useContext
} from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import SearchInputBox from "../SearchInputBox";
import CheckBoxComponent from "../CheckBox";
import { FilterValueSection, DoneButton } from "./FilterSection";
import SelectedTagList from "./SelectedTagList";
import ContactFilteredFacets from "./ContactFilteredFacets";

class FilterByOwnerState {
  @observable filteredData = [];
  @observable filteredId = [];

  @action
  setFilteredData(val) {
    this.filteredData = val;
  }
  @action
  setFilteredId(val) {
    this.filteredId = val;
  }
}
const filterByOwnerState = new FilterByOwnerState();
const FilterContext = createContext({});

const BodySection = props => {
  const {
    showTagList,
    setShowTagList,
    renderArray,
    clearSearchInput,
    searchBoxRef,
    contactTableLoading,
    getFullName,
    onChangeSearchBox,
    updateRemovedTag,
    updateTagCheckbox,
    store = filterByOwnerState
  } = useContext(FilterContext);

  return (
    <div className="bodyContainer">
      <div className="inputContainer">
        <SelectedTagList
          selectedList={store.filteredData}
          store={store}
          searchBoxRef={searchBoxRef}
          updateRemovedTag={updateRemovedTag}
        />
        <div className="searchFilterWrapper">
          <SearchInputBox
            valueCbk={onChangeSearchBox}
            type="text"
            placeholder={""}
            hasSearchIcon={false}
            hasCloseIcon={false}
            className="inputFilterSearch"
            clearSearchInput={clearSearchInput}
            searchBoxRef={searchBoxRef}
            setShowTagList={setShowTagList}
            style={{
              width: store?.filteredData?.length === 0 && "100%"
            }}
          />
          {showTagList && renderArray?.length > 0 && (
            <div className={`tagList ${contactTableLoading ? "fade" : ""}`}>
              {renderArray.map((item, index) => {
                const { id, checked } = item || {};
                return (
                  <div className="tagItem" key={id + index}>
                    <CheckBoxComponent
                      key={id}
                      name={getFullName(item)}
                      id={id}
                      checked={checked}
                      cbk={updateTagCheckbox}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FilterByContactOwner = observer(props => {
  const {
    filterContactsByTags = () => {},
    filterAnalyticsByTags = () => {},
    openOwnerFilter = false,
    setOpenTagFilter = () => {},
    setOpenOwnerFilter = () => {},
    store = filterByOwnerState
  } = props;

  const searchBoxRef = useRef(null);
  const [tagList, setTagList] = useState([]);
  const [showTagList, setShowTagList] = useState(false);
  const [renderArray, setRenderArray] = useState([]);
  const [clearSearchInput, setClearSearchInput] = useState(false);
  const [clearAllFilter, setClearAllFilter] = useState(false);

  const updateData = list => {
    setRenderArray(list);
    setTagList(list);
    setShowTagList(false);
    searchBoxRef && searchBoxRef.current && searchBoxRef.current.focus();
  };

  const getFullName = user => {
    const { firstName = null, lastName = null, email = null } = user;
    return (
      (firstName && lastName && `${firstName} ${lastName}`) ||
      firstName ||
      lastName ||
      email
    );
  };

  const onChangeSearchBox = inputValue => {
    setShowTagList(true);
    const lowercasedValue = inputValue?.toLowerCase();
    setClearSearchInput(false);
    if (lowercasedValue) {
      const filteredData = tagList?.filter(item => {
        const updatedItem = getFullName(item);
        return updatedItem?.toString().toLowerCase().includes(lowercasedValue);
      });
      setRenderArray(filteredData);
    }
  };

  const updateRemovedTag = removedItem => {
    const updatedTagList = Utils.createObjWithoutRef(tagList);
    updatedTagList?.length > 0 &&
      updatedTagList.map(item => {
        const updatedItem = getFullName(item);
        updatedItem === removedItem && (item.checked = false);
        return item;
      });
    const filteredItem = updatedTagList?.filter(item => item.checked === true);
    filteredItem?.length === 0 && setClearAllFilter(true);
    updatedTagList && updateData(updatedTagList);
  };

  const updateTagCheckbox = (value, id, name) => {
    const newArr = Utils.createObjWithoutRef(tagList);
    if (newArr?.length > 0) {
      newArr.map(item => item.id === id && (item.checked = value));
    }
    newArr && updateData(newArr);
    if (value === true) {
      store.setFilteredData([...store.filteredData, name]);
    } else {
      updateRemovedTag(name);
      const updatedFilteredData = store.filteredData.filter(
        filterData => filterData !== name
      );
      store.setFilteredData(updatedFilteredData);
    }
    setClearSearchInput(true);
  };

  const clearSelectedValue = () => {
    store.setFilteredData([]);
    filterContactsByTags([]);
    filterAnalyticsByTags([]);
    resetRenderArray();
    setClearAllFilter(true);
  };

  const resetRenderArray = () => {
    renderArray.map(item => (item.checked = false));
    renderArray && updateData(renderArray);
  };

  const submitAction = () => {
    const checkedValue = tagList
      ?.map(item => item.checked && getFullName(item))
      .filter(Boolean);
    const checkedTagId = tagList
      ?.map(item => item.checked && item.memberId)
      .filter(Boolean);
    store.setFilteredData(checkedValue);
    store.setFilteredId(checkedTagId);
    filterContactsByTags(checkedTagId);
    filterAnalyticsByTags(checkedTagId);
    setOpenOwnerFilter(false);
    setClearAllFilter(false);
  };

  const getFilteredData = resData => {
    if (store?.filteredData?.length > 0) {
      return resData
        .map(item => {
          item.checked = store?.filteredData?.includes(getFullName(item));
          return item;
        })
        .filter(Boolean);
    }
    return resData;
  };

  const getTeamList = async () => {
    const resData = await Utils.userTeamList();
    userDetail.setUserInfo({
      ...userDetail.userInfo,
      hasTeamMember: resData?.length ? resData : false
    });
    const response = getFilteredData(resData);
    setTagList(response);
    setRenderArray(response);
  };

  const escapeButtonClick = event => {
    event.keyCode === 27 && setOpenTagFilter(false);
  };

  useEffect(() => {
    store?.filteredData?.length === 0 && resetRenderArray();
  }, [store.filteredData]);

  useEffect(() => {
    getTeamList();
    window && window.addEventListener("keydown", escapeButtonClick);
    return () =>
      window && window.removeEventListener("keydown", escapeButtonClick);
  }, []);

  return store?.filteredData?.length > 0 && openOwnerFilter === false ? (
    <ContactFilteredFacets
      heading={"owner"}
      searchBoxRef={searchBoxRef}
      clearSelectedValue={clearSelectedValue}
      store={store}
      disableFilter={
        !userDetail?.userInfo?.team || !userDetail?.userInfo?.hasTeamMember
      }
      {...props}
    />
  ) : (
    openOwnerFilter && (
      <>
        <FilterContext.Provider
          value={{
            renderArray,
            setRenderArray,
            clearSearchInput,
            setClearSearchInput,
            showTagList,
            setShowTagList,
            searchBoxRef,
            openOwnerFilter,
            setOpenOwnerFilter,
            getFullName,
            setOpenTagFilter,
            onChangeSearchBox,
            updateRemovedTag,
            updateTagCheckbox,
            ...props
          }}
        >
          <div className="filterWrapper">
            <FilterValueSection
              filterSelectionValue={"Contact Owners"}
              clearSelectedValue={clearSelectedValue}
              enableClearAll={store?.filteredData?.length > 0}
            />
            <BodySection />
            <DoneButton
              submitAction={submitAction}
              enableClearAll={clearAllFilter || store?.filteredData?.length > 0}
            />
          </div>
        </FilterContext.Provider>
        <div
          className="filterTagOverlay"
          onClick={() => {
            setOpenOwnerFilter(false);
          }}
        />
      </>
    )
  );
});

export default FilterByContactOwner;
export { FilterByContactOwner, filterByOwnerState, FilterContext };
