import Utils from "Utils/utils";
import { contactUploadingToSequencePopupState } from "Components/ContactCard/ContactUploadingToSequence";

const ContactCardFunction = {};

ContactCardFunction.getUserLetterIcon = (item = {}) => {
  const { firstName = "", lastName = "", name = "", email = "" } = item || {};
  return firstName && lastName
    ? `${firstName.charAt(0)}${lastName.charAt(0)}`
    : name?.substring(0, 2) ||
        firstName?.substring(0, 2) ||
        lastName?.substring(0, 2) ||
        email?.substring(0, 2) ||
        "";
};

ContactCardFunction.getName = (item = {}) => {
  const { firstName = "", lastName = "", name = "", email = "" } = item || {};
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : name || firstName || lastName || email?.split("@")?.[0] || "--";
};

ContactCardFunction.getContactName = (item = {}) => {
  const { firstName = "", lastName = "", name = "", email = "" } = item || {};
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : name || firstName || lastName || email || "Someone";
};

ContactCardFunction.getOwnerName = (owner = {}) => {
  const { firstName = "", lastName = "", email = "" } = owner || {};
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName || lastName || email || "--";
};

ContactCardFunction.uploadingContact = (type = "Sequence") => {
  contactUploadingToSequencePopupState.setPopupValues({
    msg: `We are processing your contacts. Please wait.`,
    title: `Add Contact to ${type}`
  });
  contactUploadingToSequencePopupState.setShowPopup(true);
};

ContactCardFunction.resetUploadingFunc = () => {
  contactUploadingToSequencePopupState.resetPopup();
};

// Task Action button
ContactCardFunction.constructBulkTaskPayload = (data = {}) => {
  const {
    dueDateString: date = "",
    content = "",
    subject = "",
    note = "",
    timeZone = "",
    attachments = [],
    type = "",
    contactId = "",
    linkedInTaskType = ""
  } = data || {};
  const payload = {
    contactIds: [contactId],
    customEmailData: {
      content,
      subject,
      attachments
    },
    dueDateString: Utils.createScheduleDate(date),
    timeZone,
    note,
    type: type === "manualEmail" ? "email" : type
  };
  if (type?.toLowerCase() === "linkedin" && linkedInTaskType) {
    payload.linkedInTaskType = linkedInTaskType;
  }
  return payload;
};

export { ContactCardFunction };
