import React from "react";
import { observer } from "mobx-react";
import CreateUpdateTemplate from "Pages/Dashboard/templates/CreateUpdateTemplate";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import TemplateDataStore from "Stores/Template";
import { testEmailPopupState } from "../sequences/Stages/TestEmailPopup";

const TemplatePopup = observer((props = {}) => {
  let {
    title = "",
    value = "",
    setSelectedTemplateFn = () => {},
    ...remainingProps
  } = props;

  const closeTemplateCbk = () => {
    if (!fileAttachmentStore.fileUploadingLoader) {
      fileAttachmentStore.setAddFileAttachmentList("");
      fileAttachmentStore.clearAttachmentList();
      TemplateDataStore.setOverviewSelectedTemplate({
        title: "New",
        value: ""
      });
      TemplateDataStore.setEditorInsertCounter(0);
      testEmailPopupState.setDefaultValues({});
      props.cbk(false);
    }
  };

  const confirmCloseTemplate = () => {
    if (TemplateDataStore.editorInsertCounter > 1) {
      confirmationPopupState.setPopupValues({
        title: "Are you sure?",
        content:
          "Are you sure you want to close this template? <br/>The changes you made will be lost.",
        actionBtnText: "Yes",
        callback: closeTemplateCbk
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      closeTemplateCbk();
    }
  };

  return (
    <div className="create-new-templates-section">
      <div className="overlay-popup"></div>
      <div className="create-new-templates animated customzoomIn">
        <div className="popup-close" title="Click here to close">
          <i
            className="material-icons"
            onClick={confirmCloseTemplate}
            title="Click here to close"
          >
            close
          </i>
        </div>
        <div className="create-new-template-body">
          <CreateUpdateTemplate
            title={title}
            value={value}
            cbk={closeTemplateCbk}
            setSelectedTemplateFn={setSelectedTemplateFn}
            {...remainingProps}
          />
        </div>
      </div>
    </div>
  );
});

export default TemplatePopup;
