import React, { createContext, useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { userDetail } from "Stores/userDetail";
import { makeApi, URLS } from "Utils/apiURL";
import LiveFeed from "./Components/LiveFeed";
import LiveFeedLoading from "./Components/LiveFeedLoading";
import NoActivityFound from "./Components/NoActivityFound";
import FilterByEvent from "./Components/FilterByEvent";
import DashboardState from "../mainState";
import { DROPDOWN_LIST, MAIL_STATUS_CONFIG } from "./constants";
import LiveFeedState from "./state";

const mappingForFilter = {
  unopened: "UN_OPEN",
  opens: "VIEWS",
  clicks: "CLICK",
  replied: "REPLY",
  bounced: "BOUNCE",
  optedOut: "OPTOUT"
};

const LiveFeedMainContext = createContext({});

const defaultResponseData = { feedList: [], totalPages: 0 };

const mapObjFields = {
  unopened: "Not Opened",
  opens: "Opens",
  clicks: "Clicks",
  replied: "Replied",
  bounced: "Bounced",
  optedOut: "Opted Out"
};

let cancelRequest = null;

const checkAndCancelRequest = () => {
  if (cancelRequest !== null) {
    cancelRequest("cancel");
    cancelRequest = null;
  }
};

const cancelApiRequest = value => {
  cancelRequest = value;
};

export const Main = props => {
  const { gmailMapper } = props || {};

  const { memberId = "", team = false } = userDetail?.userInfo || {};

  const [dropdownOptions, setDropdownOptions] = useState(DROPDOWN_LIST);
  const [filterList, setFilterList] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentAccordion, setCurrentAccordion] = useState({});
  const [responseData, setResponseData] = useState(defaultResponseData);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const feedWrapperRef = useRef(null);

  const initiateMakeApi = (pageNo, filterApplied = false, mergeResult) => {
    const mappedFilterList =
      filterList?.map(item => mappingForFilter[item]) || [];
    if (mappedFilterList?.length) {
      getLiveFeedData(
        { stage: mappedFilterList },
        pageNo,
        filterApplied,
        mergeResult
      );
    } else {
      getLiveFeedData({}, pageNo, filterApplied, mergeResult);
    }
  };

  const mergeDistinctKeyValues = (array = []) => {
    const output = [];
    array?.length > 0 &&
      array.forEach(function (item) {
        var existing = output.filter(function (v, i) {
          return v?.date == item?.date;
        });
        if (existing?.length) {
          var existingIndex = output?.indexOf(existing?.[0]);
          output[existingIndex].result = output[existingIndex].result.concat(
            item.result
          );
        } else {
          output.push(item);
        }
      });
    return output;
  };

  const getMergedResult = (result, filterApplied = false) => {
    let mergedResultArr = result?.feedList;
    if (!filterApplied) {
      mergedResultArr = [...responseData.feedList, ...result.feedList];
    }
    const list = mergeDistinctKeyValues(mergedResultArr);
    return { totalPages, feedList: list };
  };

  const getLiveFeedData = async (
    postData,
    pageNo,
    filterApplied,
    mergeResult = true
  ) => {
    const updatedPostData = {
      pageNo,
      limit: 15,
      ...postData
    };
    if (DashboardState?.memberId?.length > 0) {
      updatedPostData.filterByMemberIds = DashboardState.memberId;
    } else {
      if (!team) {
        updatedPostData.filterByMemberIds = [memberId];
      }
    }
    const config = {
      url: URLS.getLiveFeedData,
      method: "POST",
      data: updatedPostData
    };
    checkAndCancelRequest();
    let response = await makeApi(config, cancelApiRequest);
    if (response) {
      if (response?.message === "cancel") {
        setResponseData(responseData);
      } else {
        let result = response?.data;
        if (result?.feedList?.length > 0) {
          mergeResult === false
            ? setResponseData(result)
            : setResponseData(getMergedResult(result, filterApplied));
        } else {
          setResponseData(defaultResponseData);
        }
        result?.totalPages && setTotalPages(result.totalPages);
        result?.currentPage && setPageNumber(result?.currentPage);
        LiveFeedState.setLiveFeedLoading(false);
      }
    } else {
      setResponseData(defaultResponseData);
      LiveFeedState.setLiveFeedLoading(false);
    }
  };

  const handleAccordionClick = id => {
    setCurrentAccordion({
      id,
      accordionOpen:
        id !== currentAccordion.id ? true : !currentAccordion?.accordionOpen
    });
  };

  const handleClose = value => {
    const newList = filterList.filter(item => item !== value);
    setFilterList(newList);
  };

  const handleScroll = () => {
    if (feedWrapperRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = feedWrapperRef.current;
      if (scrollTop + clientHeight + 2 >= scrollHeight) {
        if (pageNumber < totalPages) {
          const newPageNumber = pageNumber + 1;
          initiateMakeApi(newPageNumber);
        }
      }
    }
  };

  const loadLiveFeed = mergeResult => {
    LiveFeedState.setLiveFeedLoading(true);
    initiateMakeApi(1, null, mergeResult);
  };

  const handleRefresh = () => {
    loadLiveFeed(false);
  };

  useEffect(() => {
    LiveFeedState.setLiveFeedLoading(true);
    initiateMakeApi(1, true);
  }, [DashboardState.memberId]);

  useEffect(() => {
    if (filterList) {
      setPageNumber(1);
      LiveFeedState.setLiveFeedLoading(true);
      initiateMakeApi(1, true);
    }
  }, [filterList]);

  useEffect(() => {
    loadLiveFeed();
  }, []);

  return (
    <LiveFeedMainContext.Provider
      value={{
        dropdownOptions,
        setDropdownOptions,
        filterList,
        setFilterList,
        showDropdown,
        setShowDropdown,
        handleAccordionClick,
        currentAccordion,
        setCurrentAccordion,
        MAIL_STATUS_CONFIG,
        getLiveFeedData,
        gmailMapper
      }}
    >
      <section className="liveFeedSection">
        <div className="liveFeedHeaderWrapper">
          <div className="liveFeedHeader">
            <span className="liveFeedTitle">Live Feed</span>
            <div className="filterWrapper">
              <i className="material-icons refreshIcon" onClick={handleRefresh}>
                refresh
              </i>
              <FilterByEvent />
            </div>
          </div>
          <div className="tagSectionWrapper">
            {filterList?.length > 0 &&
              filterList?.map((item, index) => (
                <div className="tagWrapper" key={`${item}-${index}`}>
                  <span className="tagText">{mapObjFields[item]}</span>
                  <span
                    className="material-icons closeIcon"
                    onClick={() => handleClose(item)}
                  >
                    close
                  </span>
                </div>
              ))}
          </div>
        </div>
        {LiveFeedState?.isLiveFeedLoading ? (
          <LiveFeedLoading />
        ) : (
          <div
            className="liveFeedWrapper"
            onScroll={() => handleScroll()}
            ref={feedWrapperRef}
          >
            {responseData?.feedList?.length > 0 ? (
              responseData?.feedList?.map((responseItem, responseIndex) => (
                <LiveFeed
                  responseItem={responseItem}
                  responseIndex={responseIndex}
                  key={responseIndex}
                />
              ))
            ) : (
              <NoActivityFound filters={filterList} />
            )}
          </div>
        )}
      </section>
    </LiveFeedMainContext.Provider>
  );
};

export { LiveFeedMainContext };
export default observer(Main);
