/* eslint-disable array-callback-return */
import React from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import Button from "Components/common/Button";
import Lottie from "react-lottie";
import Verified from "Utils/LottieFiles/verified.json";

class CustomUrlSuccessPopupState {
  @observable showPopup = false;
  @observable callBack = e => {};

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setCallBack = val => {
    this.callBack = val;
  };

  @action
  setPopupValues = obj => {
    this.popupValues = obj;
  };
}

const customUrlSuccessPopupState = new CustomUrlSuccessPopupState();

const CustomUrlSuccessPopup = observer(() => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Verified,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const closeAction = () => {
    customUrlSuccessPopupState.setShowPopup(false);
  };

  return customUrlSuccessPopupState.showPopup ? (
    <div className="customUrlsuccess popupWrapper">
      <div className="confirmation-popup common-popup">
        <div className="customUrlSuccessPopupWrapper popup-wrap animated customzoomIn">
          <Lottie
            options={defaultOptions}
            width={125}
            height={100}
            style={{ margin: "0 auto" }}
            speed={1.5}
          />
          <h4 className="textTitle">Custom URL Verified</h4>
          <p className="text">
            All new emails sent via Salesgear will use this URL for unsubscribe,
            open and click tracking URLs
          </p>
          <Button
            id="successCustomUrlBtn"
            name="successCustomUrlBtn"
            btnText="Done"
            icon={false}
            iconName=""
            type="button"
            className="successCustomUrlBtn"
            loader={false}
            click={closeAction}
          />
        </div>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

export default CustomUrlSuccessPopup;
export { CustomUrlSuccessPopup, customUrlSuccessPopupState };
