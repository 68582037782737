import React from "react";
import { observer } from "mobx-react";
import { NoTemplates } from "./NoTemplates";
import FolderAndFileLoader from "./FolderAndFileLoader";

const TemplatesList = props => {

  const { loading, file, insertTemplateCbk, createTemplate} = props;

  const showTemplates = () => {
    return file && file.length > 0;
  };  

  return (
    <>
      {!loading && (
        <div className="templatesList">
          {showTemplates() &&
            file.map(item => {
              const { id, name } = item;
              return (
                <div
                  className="templatesDetails"
                  data-name={name}
                  data-id={id}
                  key={id}
                  onClick={() => insertTemplateCbk(item)}
                >
                  <span className="material-icons-outlined folderIcon">
                    description
                  </span>
                  <span className="folderName name">{name}</span>
                </div>
              );
            })}
          {!showTemplates() && <NoTemplates add={createTemplate} />}
        </div>
      )}
      {loading && <FolderAndFileLoader count={10} />}
    </>
  );
};

export default observer(TemplatesList);
export { TemplatesList };
