import React, { useState, useEffect } from "react";
import { ReactComponent as SearchIcon } from "Assets/svg/search.svg";

const SearchInputByKeyword = props => {
  const {
    id = "",
    placeholder = "Search here",
    handleSearch = () => {}
  } = props || {};

  const [searchKeyword, setSearchKeyword] = useState("");
  const [showClose, setShowClose] = useState(false);

  const handleChange = e => {
    let value = e.currentTarget.value;
    setSearchKeyword(value);
    setShowClose(value ? true : false);
    handleSearch(value?.trim());
  };

  const resetState = () => {
    setSearchKeyword("");
    setShowClose(false);
  };

  const clearSearch = () => {
    resetState();
    handleSearch("");
  };

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  return (
    <div className={`searchWrapper ${id}`} id={id}>
      <div className="searchInput">
        <span className="searchImage">
          <SearchIcon />
        </span>
        <span className="searchTextBox">
          <input
            name="search"
            id="searchEmailContact"
            autoComplete="off"
            value={searchKeyword}
            type="text"
            placeholder={placeholder}
            onChange={e => handleChange(e)}
            autoFocus={true}
            required
          />
          {showClose && (
            <i className="material-icons" onClick={clearSearch}>
              close
            </i>
          )}
        </span>
      </div>
    </div>
  );
};

export default SearchInputByKeyword;
export { SearchInputByKeyword };
