import React from "react";
import UserLaptopImg from "Assets/Pricing/UserLaptop.png";
import DesignBg from "Assets/Pricing/DesignBg.png";

const PricingTableContent = () => {
  return (
    <div className="pricingLeftContent">
      <div className="layerBg">
        <img src={DesignBg} alt="" />
      </div>
      <h2 className="title">
        Super{" "}
        <span className="gradIconText">
          <span className="gradText">charge</span>
          <i className="material-icons">flash_on</i>
        </span>
        <br />
        your team’s <br />
        productivity
      </h2>
      <p className="descPolicy">No Contracts. No Obligations. Cancel Anytime.</p>
      <div className="userLayer">
        <img src={UserLaptopImg} alt="" />
      </div>
    </div>
  );
};

export default PricingTableContent;
