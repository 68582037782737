import React from "react";
import ContactInputTag from "./ContactInputTag";
import Utils from "Utils/utils";

const LocationInformation = props => {
  const { inputVal, changeInputVal, tokenList } = props;

  return (
    <div className="info">
      <h5 className="sectionTitle">Location Information</h5>
      <div className="sectionInputTagsCont">
        <div className="rowBreaker">
          <ContactInputTag
            name="street"
            id="street"
            value={inputVal.street}
            text="Street"
            onBlurEvent={changeInputVal}
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "street",
              tokenList
            )}
          />
          <ContactInputTag
            name="city"
            id="city"
            value={inputVal.city}
            text="City"
            onBlurEvent={changeInputVal}
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "city",
              tokenList
            )}
          />
          <ContactInputTag
            name="state"
            value={inputVal.state}
            type="text"
            id="state"
            text="State"
            onBlurEvent={changeInputVal}
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "state",
              tokenList
            )}
          />
          <ContactInputTag
            name="country"
            id="country"
            value={inputVal.country}
            text="Country"
            onBlurEvent={changeInputVal}
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "country",
              tokenList
            )}
          />
        </div>
        <div className="rowBreaker">
          <ContactInputTag
            name="zipCode"
            id="zipCode"
            onBlurEvent={changeInputVal}
            value={inputVal.zipCode}
            text="Zip code"
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "zipCode",
              tokenList
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationInformation;
export { LocationInformation };
