import React from "react";
import { ReactComponent as GoogleSvg } from "Assets/svg/inbox/google.svg";
import { ReactComponent as MicrosoftSvg } from "Assets/svg/inbox/microsoft.svg";

const InboxImage = props => {
  const { type = "" } = props || {};
  return (
    <span className="imgType">
      {(type && (
        <>
          {["gmail", "google", "gauth", "gmail_oath"].includes(type) && (
            <GoogleSvg />
          )}
          {["outlook", "microsoft", "outlook_oauth", "outlook_oath"].includes(
            type
          ) && <MicrosoftSvg />}
          {["smtp", "smtp_oauth"].includes(type) && (
            <span className="material-icons-outlined">email</span>
          )}
        </>
      )) ||
        ""}
    </span>
  );
};

export default InboxImage;
export { InboxImage };
