/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext
} from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import contactStoreData from "Stores/contactData";
import ContactTable from "./contactTable";
import ContactFilterByOption from "Pages/Dashboard/contacts/ContactFilter/ContactFilterByOption";
import Utils from "Utils/utils";
import SequenceUtils from "Utils/SequenceUtils";
import userDetail from "Stores/userDetail";
import { toasterState } from "Components/common/toaster";
import FilterComponent from "./filterComponent";
import { MP_EVENT } from "Static/constant";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import ExportBtn from "Components/common/ExportBtn";
import AddToSequenceButton from "Components/common/AddToSequenceButton";
import PageLimit from "Components/common/PageLimit";
import { exportProcessingState } from "Components/popup/ExportProcessing";
import { exportEmailNotifyState } from "Components/popup/ExportEmailNotify";
import ContactCard from "Components/ContactCard/ContactCard";
import { addSequenceInContactPopupState } from "./addSeqInContactPopup";
import ContactUploading from "./ContactToSequence/ContactUploading";
import FieldValidatorMsgPopup from "./ContactToSequence/FieldValidatorMsgPopup";
import ContactSelectedCount from "Components/common/ContactSelectedCount";
import TaskButton from "./TaskButton";
import ContactMoreOption from "Components/common/ContactFilter/ContactMoreOption";
import SearchByContactKeyword from "./SearchByContactKeyword";
import { settingsStoreData } from "Stores/settingsData";
import { addBulkContactPopupState } from "Components/common/bulkContactPopup/AddBulkContactPopup";
import { bulkAddTagsPopupState } from "Components/common/ContactFilter/BulkOperations/BulkAddTagsPopup";
import { bulkRemoveTagsPopupState } from "Components/common/ContactFilter/BulkOperations/BulkRemoveTagsPopup";
import { tagsStoreData } from "Stores/TagsData";
import { updateContactOwnerState } from "Components/common/UpdateContactOwner";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { filterByOwnerState } from "Components/common/ContactFilter/FilterByContactOwner";
import { filterByTagsState } from "Components/common/ContactFilter/FilterByContactTags";
import { makeApi, URLS } from "Utils/apiURL";
import { ContactStateContext } from "Pages/Dashboard/contacts/main";
import { ReactComponent as FilterIcon } from "Assets/svg/filter.svg";
import { ContactUtils } from "Utils/ContactUtils";
import customListState from "Pages/Dashboard/dataSearch/CustomList/state";
import AddContactsToList from "./AddContactsToList";
import globalCallState from "Stores/GlobalCallState";
import DialerLogsAfterCallEndPopup, {
  dialerLogsPopupState
} from "Components/DailerService/DialerLogsAfterCallEndPopup";
import { exportToSalesforcePopupState } from "Components/common/ExportToSalesforcePopup";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";

const TeamDataFilterContext = createContext({});

const ContactModule = withRouter(
  observer(() => {
    const contactCardRef = useRef();
    const history = useHistory();

    let defaultSelectAllTeamMemberObject = [
      {
        id: "selectAll",
        value: "Select All",
        show: true
      }
    ];

    const {
      tableLoading,
      setTableLoading,
      contactLimit,
      setContactLimit,
      activeTab,
      setActiveTab,
      isContactUploading,
      setContactUploading
    } = useContext(ContactStateContext);

    const {
      userFeatureFlag = {},
      pluginInstalled = false,
      installNowPluginClicked = false,
      userInfo = {}
    } = userDetail || {};
    const {
      noOfContactsEligible = 0,
      noOfContactsUsed = 0,
      exportEnabled = false,
      taskEnabled = false,
      emailValidationCredits = 0,
      emailValidationEnabled = false
    } = userFeatureFlag || {};

    const { team = false, hasTeamMember = false } = userDetail?.userInfo || {};
    const [filterLoading, setFilterLoading] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [isEmailClose, setEmailClose] = useState(false);
    const [isSearchPerformed, setSearchPerformed] = useState(false);
    const [exportAllContactSelected, setExportAllContactSelected] =
      useState(false);
    const [exportData, setExportData] = useState([]);
    const [totalContactCount, setTotalContactsCount] = useState(0);
    const [contactData, setContactData] = useState({});
    const [totalPages, setTotalPages] = useState();
    const [showContactCard, setShowContactCard] = useState(false);
    const [selectedContact, setSelecedContact] = useState({});
    const [slideAnim, setSlideAnim] = useState(false);
    const [showContactUploadingLoader, setContactUploadingLoader] =
      useState(false);
    const [uploadingText, setUploadingText] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [fieldErrorDetails, setFieldErrorDetails] = useState("");
    const [dailyLimitContactData, setDailyLimitContactData] = useState("");
    const [allContactSelected, setAllContactSelected] = useState(false);
    const [showContactFilter, setContactFilter] = useState(true);
    const [filterArr, setFilterArr] = useState([]);
    const [teamFilterDropDownData, setTeamFilterDropDownData] = useState([]);
    const [teamCount, setTeamCount] = useState(0);
    const [contactFilterData, setContactFilterData] = useState([]);
    const [entireContactFilterData, setEntireContactFilterData] = useState([]);
    const [stageId, setStageId] = useState(null);
    const [selectedContactObj, setSelectedContactObj] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("");
    const [selectedContactsNameList, setSelectedContactsNameList] = useState(
      []
    );
    const [logBtnLoader, setLogBtnLoader] = useState(false);
    const [showExportTypeDropdown, setShowExportTypeDropdown] = useState(false);
    const location = useLocation();

    const {
      hubspotIntegrationEnabled = false,
      salesforceIntegrationEnabled = false
    } = userDetail.userFeatureFlag || {};

    const setTableLoadingFn = value => {
      setTableLoading(value);
    };

    const remainingContactCount = () => {
      return noOfContactsEligible - noOfContactsUsed;
    };

    const setFilterLoadingFn = value => {
      setFilterLoading(value);
      value && resetAllStateValue();
    };

    const getSearchedKey = value => {
      value && setActiveTab(value);
    };

    const handleSearchAction = event => {
      Utils.preventDefaultFn(event);
      setSearchKeyword(event.currentTarget.value);
      setEmailClose(!event.currentTarget.value.length < 1);
    };

    const toggleContactsCheckbox = (val = false) => {
      if (
        contactData &&
        Object.keys(contactData)?.length &&
        contactData?.contacts?.length
      ) {
        const tempContacts = [...contactData.contacts];
        tempContacts.map(item => {
          item.isSelected = val;
        });
        return tempContacts;
      }
    };

    const resetAllStateValue = () => {
      setExportData([]);
      setSelecedContact([]);
      toggleContactsCheckbox(false);
      setExportAllContactSelected(false);
    };

    const searchContact = e => {
      const payload = {
        ...contactStoreData.filterPayload,
        searchTerm:
          searchKeyword?.trim() ||
          contactStoreData?.updateContactEmail?.trim() ||
          "",
        listId: customListState?.activeList?.id || "",
        pageNo: 1
      };
      contactStoreData.setCurrentPage(1);
      (searchKeyword?.length || contactStoreData?.updateContactEmail?.length) &&
        setEmailClose(true);
      e && Utils.preventDefaultFn(e);
      setSearchPerformed(true);
      payload.stageId ? setActiveTab(payload.stageId) : setActiveTab("total");
      resetAllStateValue();
      setTableLoadingFn(true);
      setFilterLoadingFn(true);
      document.getElementById("searchEmailContact").blur();
      ContactUtils.contactFilterByPayload(
        payload,
        setTableLoadingFn,
        setFilterLoadingFn,
        true
      );
    };

    const clearSearch = () => {
      setSearchKeyword("");
      setEmailClose(false);
      contactStoreData.setSearchContactFilter("total");
      document.getElementById("searchEmailContact").focus();
      contactStoreData.setCurrentPage(1);
      if (isSearchPerformed) {
        contactStoreData.totalContacts = 0;
        setSearchPerformed(false);
        resetAllStateValue();
        setTableLoadingFn(true);
        setFilterLoadingFn(true);
        setActiveTab("total");
        let payload = {
          filter: {
            stage: ""
          },
          stage: "",
          pageNo: 1,
          searchTerm: "",
          listId: customListState?.activeList?.id
        };
        contactStoreData.setFilterPayload(payload);
        ContactUtils.contactFilterByPayload(
          payload,
          setTableLoadingFn,
          setFilterLoadingFn,
          true
        );
      }
    };

    const submitForm = e => {
      e.preventDefault();
      if (e.keyCode === 13) {
        searchContact(e);
      }
    };

    const setContactsCount = data => {
      if (data?.totalContacts) {
        setTotalContactsCount(data.totalContacts);
      }
    };

    const handleExportFail = () => {
      clearSelectedContacts();
      exportProcessingState.setShowPopup(false);
      toasterState.setToastMsg("Contact export failed", "failure");
    };

    const handleExportResponse = downloadLink => {
      if (!downloadLink) {
        handleExportFail();
      } else {
        exportProcessingState.completeAnimation();
        setTimeout(() => {
          Utils.downloadExportedContact(downloadLink);
          exportProcessingState.setShowPopup(false);
          clearSelectedContacts();
          toasterState.setToastMsg(
            "Contacts have been exported successfully.",
            "success"
          );
          Utils.mixpanelTrack(MP_EVENT.CONTACT_EXPORTED, {
            pageType: "Contacts"
          });
        }, 1000);
      }
    };

    const handleExportCsvResponse = (res = {}) => {
      if (res) {
        const { status = "", type = "", contactData = [] } = res || {};
        if (status === 200 && type === "limit_reached") {
          Utils.mixpanelTrack(MP_EVENT.CONTACT_EXPORTED, {
            pageType: "Contacts",
            type: "Limit Reached"
          });
          exportProcessingState.completeAnimation();
          exportProcessingState.setShowPopup(false);
          exportEmailNotifyState.setShowPopup(true);
          clearSelectedContacts();
        } else if (contactData?.length > 0) {
          Utils.constructExportToCsvData(contactData, handleExportResponse);
        } else {
          handleExportFail();
        }
      }
    };

    const getExportSelectedPayload = () => {
      if (exportData) {
        const payloadObj = {
          contactList: exportData,
          totalCount: exportData?.length || 0,
          listId: customListState?.activeList?.id
        };
        return payloadObj;
      }
    };

    const checkFilterPayload = (filter = {}) => {
      if (filter === null || filter === undefined) {
        return true;
      }
      return Object.values(filter).every(value => {
        return !(
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === "string" && value !== "") ||
          (typeof value === "object" && Object.keys(value).length > 0)
        );
      });
    };

    const getExportAllPayload = () => {
      if (activeTab) {
        const { filter = {}, searchTerm = "" } =
          contactStoreData.filterPayload || {};
        const payload = {
          totalCount: totalContactCount,
          all:
            activeTab?.toLowerCase() === "total" && checkFilterPayload(filter),
          search: {
            stageId,
            searchTerm,
            filter,
            listId: customListState?.activeList?.id
          }
        };
        return payload;
      }
      return null;
    };

    const exportToSalesforceCbk = async (selectedEntity = "") => {
      const data = getBulkPerformActionPayload({}, "SALESFORCE_CONTACT_EXPORT");
      data.data = { crmEntityType: selectedEntity?.toUpperCase() };
      exportToSalesforcePopupState.setShowPopup(false);
      exportToSalesforcePopupState.setSelectedKey("lead");
      exportProcessingState.setShowPopup(true);
      const config = {
        url: URLS.exportContactsToHubspot,
        method: "POST",
        data
      };
      const response = await makeApi(config);
      exportToSalesforcePopupState.setBtnLoader(false);
      exportProcessingState.handleSalesforceExportResponse(
        response,
        clearSelectedContacts,
        "salesforce",
        selectedEntity
      );
    };

    const checkForLeadOrContact = async (
      selectedEntity = "",
      type = "salesforce"
    ) => {
      if (selectedEntity) {
        const {
          salesforceContactNotMapped = false,
          salesforceLeadNotMapped = false
        } = contactStoreData?.sfExportStatus;
        resetPopupState();
        if (
          (selectedEntity?.toLowerCase() === "lead" &&
            salesforceLeadNotMapped) ||
          (selectedEntity?.toLowerCase() === "contact" &&
            salesforceContactNotMapped)
        ) {
          crmExportNotMapped(type?.toLowerCase(), `${selectedEntity}s`);
        } else {
          exportToSalesforceCbk(selectedEntity);
        }
      }
    };

    const exportHubspotCbk = async () => {
      exportProcessingState.setShowPopup(true);
      const data = getBulkPerformActionPayload({}, "HUBSPOT_CONTACT_EXPORT");
      const config = {
        url: URLS.exportContactsToHubspot,
        method: "POST",
        data
      };
      const response = await makeApi(config);
      exportProcessingState.handleHubspotExportResponse(
        response,
        clearSelectedContacts,
        "hubspot"
      );
    };

    const CrmExportNotMappedContent = ({
      type = "",
      selectedEntity = "contacts"
    }) => {
      return (
        <div className="crmExportNotMapped">
          <div className="initText">
            {`Set up your field mappings to export ${selectedEntity} from Salesgear to
          ${type}.`}
          </div>
          <div className="noteText">
            Note: Only fields marked for ‘2-way sync’ or ‘outbound’ will be
            exported. Fields set as ‘inbound’ will not be exported.
          </div>
        </div>
      );
    };

    const CrmExportNotConnectedContent = ({ type = "" }) => {
      return (
        <div className="crmExportNotConnectedWrapper">
          <div className="initText">
            {`Connect your ${type} CRM to export contacts seamlessly.`}
          </div>
        </div>
      );
    };

    const resetPopupState = () => {
      exportToSalesforcePopupState.setBtnLoader(false);
      exportToSalesforcePopupState.setSelectedKey("lead");
      exportToSalesforcePopupState.setShowPopup(false);
      confirmationPopupState.setShowPopup(false);
    };

    const crmExportNotMapped = (
      type = "hubspot",
      selectedEntity = "contacts"
    ) => {
      confirmationPopupState.setPopupValues({
        content: (
          <CrmExportNotMappedContent
            type={type}
            selectedEntity={selectedEntity}
          />
        ),
        actionBtnText: "Configure field mapping",
        cancelCbk: () => {
          resetPopupState();
        },
        callback: () => {
          localStorage.setItem("goBackUrl", location.pathname);
          history.push(
            `/settings/crm/${type}?source=contact#${selectedEntity}`
          );
        },
        dynamicDom: true
      });
      confirmationPopupState.setShowPopup(true);
    };

    const crmExportNotConnected = (type = "hubspot") => {
      confirmationPopupState.setPopupValues({
        content: <CrmExportNotConnectedContent type={type} />,
        actionBtnText: `Connect ${type}`,
        cancelCbk: () => {
          resetPopupState();
        },
        callback: () => {
          localStorage.setItem("goBackUrl", location.pathname);
          history.push(`/settings/crm/${type}?source=contact`);
        },
        dynamicDom: true
      });
      confirmationPopupState.setShowPopup(true);
    };

    const exportCbk = async (type = "") => {
      const { isHubspotConnected = false, hubspotContactNotMapped = false } =
        contactStoreData?.hubspotExportStatus || {};
      const { isSfConnected = false } = contactStoreData?.sfExportStatus || {};

      if (["hubspot", "hubspotcontactexport"].includes(type?.toLowerCase())) {
        if (!hubspotIntegrationEnabled) {
          upgradePopupState.setPopupValues({ type: "exportHubspotUpgrade" });
          upgradePopupState.setPopup(true);
        } else if (!isHubspotConnected) {
          crmExportNotConnected(type?.toLowerCase());
        } else if (hubspotContactNotMapped) {
          crmExportNotMapped(type?.toLowerCase());
        } else {
          exportHubspotCbk();
        }
      } else if (
        ["salesforce", "salesforcecontactexport"].includes(type?.toLowerCase())
      ) {
        if (!salesforceIntegrationEnabled) {
          upgradePopupState.setPopupValues({ type: "exportSalesforceUpgrade" });
          upgradePopupState.setPopup(true);
        } else if (!isSfConnected) {
          crmExportNotConnected(type?.toLowerCase());
        } else {
          exportToSalesforcePopupState.setDefaultObj({
            cbk: checkForLeadOrContact
          });
          exportToSalesforcePopupState.setShowPopup(true);
        }
      } else if (
        !isHubspotConnected ||
        !isSfConnected ||
        ["csv"].includes(type?.toLowerCase() || "")
      ) {
        let payload = allContactSelected
          ? getExportAllPayload()
          : getExportSelectedPayload();
        exportProcessingState.setShowPopup(true);
        if (payload && Object.keys(payload)?.length) {
          const response = await Utils.exportContact(payload);
          if (response?.data) {
            handleExportCsvResponse(response?.data);
          } else {
            handleExportFail();
          }
        }
      }
    };

    const clearSelectedContacts = () => {
      const tempContacts = toggleContactsCheckbox(false);
      setContactData({ ...contactData, contacts: tempContacts });
      resetAllStateValue();
    };

    const constructContactRenderData = contactStoreValue => {
      // console.log(JSON.stringify(contactStoreValue, null, 2));
      if (contactStoreValue && Object.keys(contactStoreValue).length) {
        setExportData([]);
        setSelecedContact([]);
        setTotalPages(contactStoreValue.totalPages);
        setTableLoading(false);
        setContactData(contactStoreValue);
      }
    };

    const getAllContactCbxSelected = value => {
      setExportAllContactSelected(value);
    };

    const openContactCard = (contact = {}) => {
      if (contact && Object.keys(contact)?.length) {
        setSlideAnim(true);
        setShowContactCard(true);
        setSelecedContact(contact);
      }
    };

    const closeContactCard = () => {
      if (globalCallState.popupValues?.isContactCard) {
        globalCallState.setPopupValues({
          ...globalCallState.popupValues,
          isContactCard: false
        });
      }
      setSlideAnim(false);
      setTimeout(() => {
        setShowContactCard(false);
      }, 300);
    };

    const adjustHeight = () => {
      if (totalContactCount > 10 && totalPages > 1) {
        return "adjustPaginationSpace";
      }
      return "adjustTableSpace";
    };

    const getSeqFilterPayload = () => {
      if (activeTab) {
        const { filter = {}, searchTerm = "" } =
          contactStoreData.filterPayload || {};
        return {
          stageId,
          searchTerm,
          filter
        };
      }
      return {};
    };

    const getBulkPerformActionPayload = (filteredData, type = "") => {
      if (type) {
        const { filter = {}, searchTerm = "" } =
          contactStoreData.filterPayload || {};
        let payloadObj = {
          source: "CONTACT",
          type: type,
          totalCount: !allContactSelected
            ? exportData?.length
            : totalContactCount,
          all: activeTab?.toLowerCase() === "total",
          search: {
            stageId,
            searchTerm,
            filter,
            listId: customListState?.activeList?.id
          },

          filter: {
            ...(!allContactSelected && { contactIds: exportData })
          }
        };
        if (["ADD_TAG", "REMOVE_TAG"].includes(type)) {
          payloadObj.data = {
            contactTags: filteredData
          };
        } else if (["CONTACT_OWNER_UPDATE"].includes(type)) {
          payloadObj.newContactOwnerId = filteredData;
        }
        return payloadObj;
      }
      return false;
    };

    const reloadContactsPage = async () => {
      clearSelectedContacts();
      let payload = {
        ...contactStoreData.filterPayload,
        pageNo: contactStoreData?.currentPage
      };
      contactStoreData.setFilterPayload(payload);
      ContactUtils.contactFilterByPayload(
        payload,
        setTableLoadingFn,
        setFilterLoadingFn,
        true
      );
    };

    const updateCbk = async (success = true, state) => {
      success && reloadContactsPage();
      state.setButtonLoader(false);
      state.setShowPopup(false);
      state.setFilteredData([]);
    };

    const showConfirmationPopupForBulkActions = (
      background,
      type = "",
      actionType = ""
    ) => {
      if (background) {
        confirmationPopupState.setPopupValues({
          content: `${type} are being updated; this may take a few to several minutes.`,
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => confirmationPopupState.setShowPopup(false)
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        toasterState.setToastMsg(
          `${type} have been ${actionType} successfully`,
          "success"
        );
      }
    };

    // Add TAGS
    const addTagsCbk = async (resetTagcbk = () => {}) => {
      const data = getBulkPerformActionPayload(
        bulkAddTagsPopupState?.filteredData,
        "ADD_TAG"
      );
      if (data) {
        const config = {
          url: URLS.performContactsBulkOperation,
          method: "POST",
          data
        };
        const response = await makeApi(config);
        resetTagcbk();
        if (response) {
          if (response?.data) {
            const {
              success = false,
              background = false,
              activeJob = false
            } = response?.data || {};
            if (activeJob) {
              clearSelectedContacts();
              await updateCbk(false, bulkAddTagsPopupState);
              SequenceUtils.showActiveJobPopupForBulkActions();
            } else if (success) {
              await updateCbk(true, bulkAddTagsPopupState);
              tagsStoreData.getAllTags();
              showConfirmationPopupForBulkActions(background, "Tags", "added");
            }
          } else {
            updateCbk(false, bulkAddTagsPopupState);
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
        }
      }
    };

    // Add/Remove from List
    const showAddRemoveListConfirmationPopup = (
      type = "",
      background = false,
      bulkOperationResult = {},
      totalCount = 0,
      isAddToList = false
    ) => {
      if (background) {
        confirmationPopupState.setPopupValues({
          content: `${isAddToList ? "Adding" : "Removing"} contacts ${
            isAddToList ? "to" : "from"
          } the list; this may take a few to several minutes depending on the number of contacts selected.`, // You can view the progress here.
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => confirmationPopupState.setShowPopup(false)
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        const { success = 0, failed = 0 } = bulkOperationResult;
        let failedPlural = failed > 1 ? "s" : "";
        let successPlural = success > 1 ? "s" : "";
        if (!failed && !success) {
          toasterState.setToastMsg(
            `Failed to update ${totalCount} contact${
              totalCount > 1 ? "s" : ""
            } as you do not have permission to do so.`,
            "fail"
          );
        } else if (!failed) {
          toasterState.setToastMsg(
            `Successfully ${
              isAddToList ? "added" : "removed"
            } ${success} contact${successPlural} ${
              isAddToList ? "to" : "from"
            } the list.`,
            "success"
          );
        } else if (!success) {
          toasterState.setToastMsg(
            `Failed to ${
              isAddToList ? "add" : "remove"
            } ${failed} contact${failedPlural} ${
              isAddToList ? "to" : "from"
            } the list.`,
            "fail"
          );
        } else {
          let content = `Successfully ${
            isAddToList ? "added" : "removed"
          } ${success} contact${successPlural} ${
            isAddToList ? "to" : "from"
          } the list. Failed to update ${failed} contact${failedPlural} as you do not have permission to do so.`;
          confirmationPopupState.setPopupValues({
            content,
            actionBtnText: "Ok",
            needCancelBtn: false,
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        }
      }
    };

    const getAddRemoveListPayload = (type = "", selectedListId = "") => {
      if (type) {
        const payloadObj = {
          source: "CONTACT",
          type,
          totalCount: !allContactSelected
            ? exportData?.length
            : totalContactCount,
          filter: {
            ...(!allContactSelected && { contactIds: exportData })
          },
          search: {
            ...(allContactSelected && {
              ...getSeqFilterPayload()
            }),
            listId: customListState?.activeList?.id
          },
          data: {
            listId:
              type === "ADD_TO_LIST"
                ? selectedListId
                : customListState?.activeList?.id
          }
        };
        return payloadObj;
      }
      return "";
    };

    const addRemoveListCbk = async (
      type = "",
      selectedListId = "",
      cbk = () => {}
    ) => {
      if (type) {
        const payload = getAddRemoveListPayload(type, selectedListId);
        if (payload) {
          let isAddToList = type === "ADD_TO_LIST";
          Utils.mixpanelTrack(
            MXP_EVENT[
              isAddToList
                ? "CT_ADD_TO_LIST_CLICKED"
                : "CT_REMOVE_FROM_LIST_CLICKED"
            ],
            {
              pageType: PAGE_TYPE["contact"],
              searchCriteria: payload
            }
          );
          const config = {
            url: URLS.performContactsBulkOperation,
            method: "POST",
            data: payload
          };
          const response = await makeApi(config);
          if (response?.data) {
            const {
              success = false,
              background = false,
              bulkOperationResult = {},
              activeJob = false
            } = response?.data;
            if (activeJob) {
              clearSelectedContacts();
              isAddToList ? cbk() : confirmationPopupState.setShowPopup(false);
              SequenceUtils.showActiveJobPopupForBulkActions();
            } else if (success) {
              await reloadContactsPage();
              customListState.getContactList();
              isAddToList ? cbk() : confirmationPopupState.setShowPopup(false);
              showAddRemoveListConfirmationPopup(
                type,
                background,
                bulkOperationResult,
                payload.totalCount,
                isAddToList
              );
              return;
            }
          } else {
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
          Utils.mixpanelTrack(
            MXP_EVENT[
              isAddToList
                ? "CT_ADD_TO_LIST_PERFORMED"
                : "CT_REMOVE_FROM_LIST_PERFORMED"
            ],
            {
              pageType: PAGE_TYPE["contact"],
              searchCriteria: payload,
              success: response?.data?.success || ""
            }
          );
          isAddToList ? cbk() : confirmationPopupState.setShowPopup(false);
        }
      }
    };

    // Bulk Operation Payload
    const getBulkOperationContactPayload = (type = "", phoneCall = {}) => {
      if (type) {
        const payloadObj = {
          source: "CONTACT",
          type,
          totalCount: !allContactSelected
            ? exportData?.length
            : totalContactCount,
          filter: {
            ...(!allContactSelected && { contactIds: exportData })
          },
          search: {
            ...(allContactSelected && {
              ...getSeqFilterPayload()
            }),
            listId: customListState?.activeList?.id
          }
        };
        if (type === "LOG_CALL") {
          payloadObj.phoneCall = phoneCall;
        }
        return payloadObj;
      }
      return "";
    };

    // DELETE/OPT OUT/BULK CALL LOG CONTACT(S) RESPONSE POPUP
    const handleResponseForBulkOperation = (
      background = false,
      bulkOperationResult = {},
      type = "",
      totalCount = 0
    ) => {
      let actionTypePastText =
        type === "DELETE_CONTACT"
          ? "deleted"
          : type === "LOG_CALL"
          ? "logged"
          : "opted out";
      let actionTypePresentText =
        type === "DELETE_CONTACT"
          ? "delete"
          : type === "LOG_CALL"
          ? "log calls"
          : "opt out";
      confirmationPopupState.setShowPopup(false);
      if (background) {
        let text =
          type === "LOG_CALL"
            ? "Calls are being logged for selected contacts"
            : `Contacts are being ${actionTypePastText}`;
        confirmationPopupState.setPopupValues({
          content: `${text}; this may take a few to several minutes.`, // You can view the progress here.
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => confirmationPopupState.setShowPopup(false)
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        const {
          total = 0,
          success = 0,
          failed = 0,
          error = {}
        } = bulkOperationResult || {};
        let content = "";
        if (total) {
          let errorKeys = Object.keys(error);
          const successText = `${
            type === "LOG_CALL" ? "Calls performed for " : ""
          }${success} contact${success > 1 ? "s" : ""} ${
            success > 1 ? "were" : "was"
          } ${actionTypePastText} successfully. `;
          const failureText = `Failed to ${actionTypePresentText} ${failed} contact${
            failed > 1 ? "s" : ""
          }${
            errorKeys.includes("401") && +failed === +error?.[401]?.length
              ? " as you do not have permission to do so"
              : ""
          }. `;
          content += success > 0 ? successText : "";
          content += success > 0 && failed > 0 ? "<br/>" : "";
          content += failed > 0 ? failureText : "";
          content +=
            totalCount > total
              ? `${totalCount - total} contact${
                  totalCount - total > 1 ? "s" : ""
                } ${
                  totalCount - total > 1 ? "have" : "has"
                } already been ${actionTypePastText}.`
              : "";
        } else {
          content = `${totalCount} contact${totalCount ? "s" : ""} ${
            totalCount > 1 ? "have" : "has"
          } already been ${actionTypePastText}.`;
        }
        confirmationPopupState.setPopupValues({
          content,
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => confirmationPopupState.setShowPopup(false)
        });
        confirmationPopupState.setShowPopup(true);
      }
    };

    const performBulkOperationAction = async (type = "", phoneCall = {}) => {
      if (type) {
        if (type === "LOG_CALL") {
          setLogBtnLoader(true);
        }
        const payload = getBulkOperationContactPayload(type, phoneCall);
        if (payload) {
          const config = {
            url: URLS.performContactsBulkOperation,
            method: "POST",
            data: payload
          };
          const response = await makeApi(config);
          if (type === "LOG_CALL") {
            dialerLogsPopupState.setShowLogCallPopup(false);
            setLogBtnLoader(false);
          }
          if (response?.data) {
            const {
              success = false,
              background = false,
              bulkOperationResult = {},
              activeJob = false
            } = response?.data || {};
            if (activeJob) {
              clearSelectedContacts();
              confirmationPopupState.setShowPopup(false);
              SequenceUtils.showActiveJobPopupForBulkActions();
            } else if (success) {
              await reloadContactsPage();
              customListState.getContactList();
              handleResponseForBulkOperation(
                background,
                bulkOperationResult,
                type,
                payload.totalCount
              );
            }
          } else {
            confirmationPopupState.setShowPopup(false);
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
        }
      } else {
        confirmationPopupState.setShowPopup(false);
      }
    };

    // Remove Tag
    const removeTagsCbk = async (filteredId = "", resetTagcbk = () => {}) => {
      const data = getBulkPerformActionPayload(filteredId, "REMOVE_TAG");
      if (data) {
        const config = {
          url: URLS.performContactsBulkOperation,
          method: "POST",
          data
        };
        const response = await makeApi(config);
        resetTagcbk();
        if (response) {
          if (response?.data) {
            const {
              success = false,
              background = false,
              activeJob = false
            } = response?.data || {};
            if (activeJob) {
              clearSelectedContacts();
              await updateCbk(false, bulkRemoveTagsPopupState);
              SequenceUtils.showActiveJobPopupForBulkActions();
            } else if (success) {
              await updateCbk(true, bulkRemoveTagsPopupState);
              tagsStoreData.getAllTags();
              showConfirmationPopupForBulkActions(
                background,
                "Tags",
                "removed"
              );
            }
          } else {
            updateCbk(false, bulkRemoveTagsPopupState);
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
        }
      }
    };

    // Add to sequence
    const getContactToSequencePayload = () => {
      let payload = {
        totalCount: totalContactCount,
        all: activeTab?.toLowerCase() === "total"
      };
      if (allContactSelected && !isSearchPerformed) {
        if (activeTab) {
          const {
            filter = {},
            stage = "",
            searchTerm = ""
          } = contactStoreData.filterPayload || {};
          payload.search = {
            stageId,
            stage,
            searchTerm,
            filter,
            listId: customListState?.activeList?.id
          };
        }
      } else {
        payload.contactIds = exportData;
      }
      return payload;
    };

    const startUploadingContact = () => {
      let pluralCheck = allContactSelected
        ? "s"
        : (exportData?.length > 1 && "s") || "";
      setUploadingText(`We are processing your contacts. Please wait.`);
      setContactUploadingLoader(true);
    };

    const validateDailyLimitForContacts = async (payload, title) => {
      let res = await contactStoreData.validateDailyLimitForContacts(payload);
      if (res && Object.keys(res).length) {
        let dataObj = res;
        dataObj.payload = payload;
        dataObj.title = title;
        setDailyLimitContactData(dataObj);
        const { contacts = [], fieldValidationResponse = {} } = res || "";
        const { missingFields = [], validCount = 0 } = fieldValidationResponse;
        if (
          fieldValidationResponse &&
          (missingFields.length > 0 || !validCount)
        ) {
          fieldValidationResponse.contacts = contacts;
          setFieldErrorDetails(fieldValidationResponse);
          setContactUploadingLoader(false);
          setUploadingText("");
          setShowMessage(true);
        } else {
          setContactUploadingLoader(false);
          setUploadingText("");
          startSequence(dataObj);
          clearSelectedContacts();
        }
      }
    };

    const checkAndAddContactToSequence = (objProp = {}) => {
      const { title = "", payload = {}, sequenceId = "" } = objProp || {};
      payload.sequenceId = sequenceId;
      payload.listId = customListState.activeList?.id;
      startUploadingContact();
      validateDailyLimitForContacts(payload, title);
    };

    const addToSequenceAction = () => {
      if (hasContactChecked()) {
        const payload = getContactToSequencePayload();
        Utils.reloadFeatureFlag();
        const validateContactBeforeAddingToSequence = (
          data = {},
          sequenceId = "",
          currentSequence = {}
        ) => {
          if (currentSequence?.emailValidationEnabled) {
            let count = payload?.contactIds?.length
              ? payload?.contactIds?.length
              : payload?.totalCount || 0;
            if (emailValidationEnabled && emailValidationCredits < count) {
              SequenceUtils.promptEmailValidationNoCredits(
                count - emailValidationCredits,
                history,
                () =>
                  checkAndAddContactToSequence({
                    ...data,
                    payload,
                    sequenceId
                  }),
                resetAllStateValue,
                "contact"
              );
              return;
            }
          }
          checkAndAddContactToSequence({ ...data, payload, sequenceId });
        };
        addSequenceInContactPopupState.setCallback(
          validateContactBeforeAddingToSequence
        );
        addSequenceInContactPopupState.setShowPopup(true);
      }
    };

    const closeFieldValidatorPopup = () => {
      setShowMessage(false);
      setFieldErrorDetails("");
      setDailyLimitContactData("");
      contactStoreData.setValidateContactData("");
      resetAllStateValue();
    };

    const skipAndAddContactToSequence = async data => {
      setDailyLimitContactData(data);
      setShowMessage(false);
      startSequence(data);
      clearSelectedContacts();
    };

    const getContactsByLimit = limit => {
      console.log("getContactsByLimit is invoked frfom contactModeule.js");
      setTableLoadingFn(true);
      setContactLimit(limit);
      if (isSearchPerformed) {
        setFilterLoadingFn(true);
        setSearchPerformed(false);
        resetAllStateValue();
      }
      const payload = {
        ...contactStoreData.filterPayload,
        limit,
        pageNo: 1,
        searchTerm: searchKeyword?.trim() || "",
        listId: customListState?.activeList?.id
      };
      contactStoreData.setFilterPayload(payload);
      ContactUtils.contactFilterByPayload(
        payload,
        setTableLoadingFn,
        setFilterLoadingFn,
        isSearchPerformed
      );
    };

    const hasContactChecked = () => {
      return (exportData?.length && true) || false;
    };

    const resetToSelectedCheckbox = () => {
      let count = 0;
      contactData?.contacts?.length &&
        contactData.contacts.map(value => {
          value.isSelected = exportData?.indexOf(value?.id) !== -1;
          value.isSelected && count++;
        });
      setContactData(contactData);
      setAllContactSelected(false);
      setExportAllContactSelected(contactData?.contacts?.length === count);
    };

    const selectAndDeselectCheckbox = value => {
      toggleContactsCheckbox(value);
      setExportAllContactSelected(value);
    };

    const checkAndAddTeamMemberList = async () => {
      const resData = await Utils.userTeamList();
      userDetail.setUserInfo({
        ...userInfo,
        hasTeamMember: resData?.length ? resData : false
      });
    };

    const startSequence = async data => {
      let settingsData = settingsStoreData?.settingsData || {};
      const { title = "", payload = {}, contacts = [] } = data || {};
      const { steps = {}, sequenceId = "", filters = {} } = payload || {};
      let contactTags = filters?.tagIds || [];
      const contactsArr = Utils.getBulkSequencePayload(contacts, true);
      const response = await Utils.scheduleSequenceForContacts(
        settingsData,
        contactsArr,
        sequenceId,
        contactTags
      );
      const {
        data: { responseCode = 500 } = {},
        response: { status = 500 } = {}
      } = response || {};
      if (+responseCode === 200) {
        Utils.mixpanelTrack(MP_EVENT.SQ_STARTED, {
          sequenceName: title,
          emailCount: contacts.length,
          pageType: "Contact"
        });
        toasterState.setToastMsg("Sequence successfully scheduled", "success");
      } else if (+responseCode === 105) {
        SequenceUtils.showNoInboxconnectedPopup();
      } else if (+responseCode === 509) {
        Utils.showContactsUploadLimitReached();
      } else if (status === 426) {
        Utils.showUpgradePopup("contacts", true, true);
      } else {
        Utils.showApiFailureResponse(response);
      }
      addBulkContactPopupState.setFileName("");
    };

    const resetTagsStoreData = () => {
      if (tagsStoreData?.tagsData?.length > 0) {
        let tagsList = [...tagsStoreData.tagsData].map(item => {
          item.checked = false;
          return item;
        });
        tagsStoreData.setTagsData([...tagsList]);
      }
    };

    const resetTeamMemberData = () => {
      if (hasTeamMember?.length > 0) {
        let teamMemberList = [...hasTeamMember].map(item => {
          item.checked = false;
          return item;
        });
        userDetail.setUserInfo({
          ...userDetail.userInfo,
          hasTeamMember: [...teamMemberList]
        });
      }
    };

    const constructTeamMemberList = (teamFilterDataResponse = []) => {
      let temp = [...teamFilterDataResponse];
      const arrList = [...defaultSelectAllTeamMemberObject];
      temp.forEach(item => {
        const { group = {}, members = [] } = item || {};
        if (members?.length > 0) {
          arrList.push({
            name: group?.name,
            id: group?.id,
            value: group?.name,
            show: true,
            showSubmenu: true,
            submenu: members?.map(item => {
              const { memberId = "" } = item || {};
              return {
                name: Utils.getFullNameFromUserInfo(item),
                value: Utils.getFullNameFromUserInfo(item),
                show: true,
                id: memberId
              };
            })
          });
        }
      });
      return arrList;
    };

    const constructContactStageList = (contactStageResponse = []) => {
      let temp = [...contactStageResponse];
      const arrList = [
        {
          name: "Total",
          id: "total",
          iconClass: "block",
          count: 0,
          iconType: true,
          upperCaseName: "TOTAL"
        }
      ];
      temp?.length > 0 &&
        temp.forEach(item => {
          if (item?.displayEnabled) {
            arrList.push({
              name: item?.displayName,
              id: item?.id,
              iconClass: "block",
              count: 0,
              iconType: true,
              upperCaseName: item?.name
            });
          }
        });
      arrList.push({
        name: "No stage",
        id: "NO_STAGE",
        iconClass: "block",
        count: 0,
        iconType: true,
        upperCaseName: "NO_STAGE"
      });
      return arrList;
    };

    const constructEntireContactFilterData = (contactStageResponse = []) => {
      let temp = [...contactStageResponse];
      const arrListTemp = [];
      temp?.length > 0 &&
        temp.forEach(item => {
          if (item?.displayName.toLowerCase() != "do not contact") {
            arrListTemp.push({
              name: item?.displayName,
              id: item.id,
              iconClass: "block",
              count: 0,
              iconType: true,
              upperCaseName: item?.name
            });
          }
        });
      return arrListTemp;
    };

    const constructTeamDataInFilter = async () => {
      const config = {
        url: URLS.getTeamData,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response?.status === 200 && response?.data?.groups) {
        setTeamFilterDropDownData(
          constructTeamMemberList(response?.data?.groups)
        );
      } else {
        setTeamFilterDropDownData([]);
      }
    };

    const constructContactStages = async () => {
      const config = {
        url: URLS.getContactStage,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response?.status === 200 && response?.data) {
        setContactFilterData(constructContactStageList(response?.data));
        setEntireContactFilterData(
          constructEntireContactFilterData(response?.data)
        );
      } else {
        setContactFilterData([]);
      }
    };

    // UPDATE CONTACT OWNER
    const singleUpdateContactOwner = async (data = {}) => {
      const config = {
        url: URLS.updateContactOwner,
        method: "POST",
        data
      };
      const response = await makeApi(config);
      updateContactOwnerState.setBtnLoader(false);
      if (response?.data?.status === "success") {
        reloadContactsPage();
        setTableLoadingFn(true);
        toasterState.setToastMsg(
          `Contact owner updated successfully`,
          "success"
        );
      } else if (response?.response?.status === 401) {
        toasterState.setToastMsg(
          "Oops! You don't have access to update the contact owner.",
          "fail"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    };

    const bulkUpdateContactOwner = async (ownerId = "") => {
      const data = getBulkPerformActionPayload(ownerId, "CONTACT_OWNER_UPDATE");
      if (ownerId && data) {
        const config = {
          url: URLS.performContactsBulkOperation,
          method: "POST",
          data
        };
        const response = await makeApi(config);
        updateContactOwnerState.setBtnLoader(false);
        if (response?.data) {
          const {
            success = false,
            background = false,
            activeJob = false
          } = response?.data || {};
          if (activeJob) {
            clearSelectedContacts();
            SequenceUtils.showActiveJobPopupForBulkActions();
          } else if (success) {
            if (background) {
              clearSelectedContacts();
            } else {
              clearSelectedContacts();
              reloadContactsPage();
              setTableLoadingFn(true);
            }
            showConfirmationPopupForBulkActions(
              background,
              "Contact owner",
              "updated"
            );
          } else {
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "fail"
          );
        }
      } else {
        updateContactOwnerState.setBtnLoader(false);
      }
    };

    const updateContactOwner = (ownerId = "", type = "", contactId = "") => {
      if (ownerId) {
        if (type?.toLowerCase() === "bulk") {
          bulkUpdateContactOwner(ownerId);
        } else if (type?.toLowerCase() === "single" && contactId) {
          singleUpdateContactOwner({ contactId, ownerId });
        }
      } else {
        updateContactOwnerState.setBtnLoader(false);
      }
    };

    const handleBulkContactsLogCallAction = async (phoneCall = {}) => {
      setLogBtnLoader(true);
      await performBulkOperationAction("LOG_CALL", phoneCall);
      setLogBtnLoader(false);
    };

    // UseOutsideClick(contactCardRef, () => {
    //   if (
    //     showContactCard &&
    //     !addStagesPopupState.showPopup &&
    //     !addStagesPopupState.showStepEmailEditorPopup
    //   ) {
    //     setSlideAnim(false);
    //     setTimeout(() => {
    //       setShowContactCard(false);
    //     }, 300);
    //   }
    // });

    const checkForNotMapped = (data, platform, configType) => {
      return (
        data?.[platform]?.filter(
          item =>
            +item?.status === 101 &&
            item?.configType?.toLowerCase() === configType
        )?.length > 0
      );
    };

    const verifyCrmConnectionForExportInContacts = async () => {
      const config = {
        url: URLS.checkCrmConnectionForExport,
        method: "GET"
      };
      const response = await makeApi(config);
      if (+response?.status === 200) {
        const respData = response?.data || {};
        const isHubspotConnected = "HUBSPOT" in respData;
        const isSfConnected = "SALESFORCE" in respData;
        const salesforceLeadNotMapped = checkForNotMapped(
          respData,
          "SALESFORCE",
          "lead"
        );
        const salesforceContactNotMapped = checkForNotMapped(
          respData,
          "SALESFORCE",
          "contact"
        );
        const hubspotContactNotMapped = checkForNotMapped(
          respData,
          "HUBSPOT",
          "contact"
        );
        contactStoreData.setHubspotExportStatus({
          ...contactStoreData.hubspotExportStatusExportStatus,
          hubspotContactNotMapped,
          isHubspotConnected
        });
        contactStoreData.setSfExportStatus({
          ...contactStoreData.sfExportStatus,
          salesforceLeadNotMapped,
          salesforceContactNotMapped,
          isSfConnected
        });
      }
    };

    useEffect(() => {
      setFilterArr(contactStoreData.filteredList);
    }, [contactStoreData.filteredList]);

    useEffect(() => {
      constructContactRenderData(contactStoreData.contactData);
      setContactsCount(contactStoreData.contactData);
      resetAllStateValue();
      adjustHeight();
    }, [contactStoreData.contactData]);

    useEffect(() => {
      if (addSequenceInContactPopupState.resetAllCheckedContacts) {
        resetAllStateValue();
        addSequenceInContactPopupState.setResetAllCheckedContacts(false);
      }
    }, [addSequenceInContactPopupState.resetAllCheckedContacts]);

    useEffect(() => {
      resetAllStateValue();
      adjustHeight();
    }, [activeTab]);

    useEffect(() => {
      if (selectedFilter !== "" && selectedFilter === false) {
        setContactFilter(false);
        setSelectedFilter(true);
      }
    }, [selectedFilter]);

    useEffect(() => {
      settingsStoreData.getCustomFields();
      verifyCrmConnectionForExportInContacts();
      constructContactStages();
      if (!contactStoreData?.updateContactEmail) {
        setTableLoading(true);
        setFilterLoadingFn(true);
        checkAndAddTeamMemberList();
      }
      return () => {
        setTeamFilterDropDownData([]);
        setActiveTab("total");
        contactStoreData.setSearchContactFilter("total");
        contactStoreData.setFilteredTags([]);
        contactStoreData.setHubspotExportStatus({});
        contactStoreData.setSfExportStatus({});
        resetTagsStoreData();
        resetTeamMemberData();
        contactStoreData.setSequenceList([]);
        contactStoreData.setSequenceCurrentPageNo(1);
      };
    }, []);

    useEffect(() => {
      allContactSelected &&
        (exportData?.length === 0 || !exportData) &&
        setAllContactSelected(false);
      contactData?.contacts?.length === exportData?.length &&
        setExportAllContactSelected(true);
    }, [exportData]);

    useEffect(() => {
      if (contactStoreData?.updateContactEmail) {
        searchContact(null, contactStoreData?.updateContactEmail);
        setSearchKeyword(contactStoreData?.updateContactEmail);
        setEmailClose(true);
      }
    }, [contactStoreData.updateContactEmail]);

    return (
      <>
        {dialerLogsPopupState?.showLogCallPopup && (
          <DialerLogsAfterCallEndPopup
            submitCallback={performBulkOperationAction}
            logBtnLoader={logBtnLoader}
          />
        )}
        {showContactUploadingLoader && (
          <ContactUploading msg={uploadingText} title="Add to sequence" />
        )}
        {showMessage && (
          <FieldValidatorMsgPopup
            fieldResponse={fieldErrorDetails}
            close={closeFieldValidatorPopup}
            skipAndAddContactCbk={skipAndAddContactToSequence}
            setSkipLoader={false}
            data={dailyLimitContactData}
            title="Add to sequence"
          />
        )}
        <section className="bodySection">
          {showContactCard && (
            <div
              className={`contactCardSection ${
                slideAnim ? "slideInFromBottom" : "slideOutToBottom"
              } ${
                !pluginInstalled && !installNowPluginClicked
                  ? "installPluginExtBannerShown"
                  : ""
              }`}
              id="contactCard"
              ref={contactCardRef}
              key="contactCard"
            >
              <ContactCard
                cardType="contacts"
                pageType="Contacts Page"
                contact={selectedContact}
                closeCbk={closeContactCard}
                isContactSlider={true}
                contactData={contactData}
                totalPages={totalPages}
                setContactData={setContactData}
                isReloadCbk={reloadContactsPage}
                isReload={true}
              />
            </div>
          )}
          <div
            className={`layoutBody ${
              showContactFilter ? "showContactFilter" : ""
            }`}
          >
            <div className="contactDataList">
              <div className="filterContents seq-contact-nav-section">
                <FilterComponent
                  tableLoadingFn={setTableLoadingFn}
                  tableLoading={tableLoading}
                  filterLoading={filterLoading}
                  filterLoadingFn={setFilterLoadingFn}
                  setSearchedKeyFn={getSearchedKey}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  contactTagsList={contactStoreData.filteredTags}
                  contactLimit={contactLimit}
                  searchKeyword={searchKeyword}
                  contactFilterData={contactFilterData}
                  setContactFilterData={setContactFilterData}
                  team={team}
                  setStageId={setStageId}
                />
              </div>
              <div
                className={`headerContents ${
                  hasContactChecked() && !tableLoading ? "selectedContact" : ""
                }`}
              >
                {exportData?.length > 0 && !tableLoading ? (
                  <ContactSelectedCount
                    selectedCount={exportData?.length || 0}
                    totalCount={totalContactCount}
                    loading={tableLoading}
                    allContactSelected={allContactSelected}
                    setAllContactSelected={setAllContactSelected}
                    resetCbk={resetAllStateValue}
                    selectAllCbk={selectAndDeselectCheckbox}
                  />
                ) : (
                  <SearchByContactKeyword
                    searchContact={searchContact}
                    searchKeyword={searchKeyword}
                    submitForm={submitForm}
                    handleSearchAction={handleSearchAction}
                    clearSearch={clearSearch}
                    isEmailClose={isEmailClose}
                    contactLimit={contactLimit}
                  />
                )}
                <div className="wrapExportFilterIcon">
                  {totalContactCount > 0 && (
                    <>
                      <PageLimit
                        limitPerPage={contactLimit}
                        text="Showing"
                        cbk={getContactsByLimit}
                        totalCount={totalContactCount}
                      />
                      <AddToSequenceButton
                        selectedEntityCount={exportData?.length || 0}
                        cbk={addToSequenceAction}
                        loading={tableLoading}
                        checked={hasContactChecked()}
                      />
                      <ExportBtn
                        selectedEntityCount={exportData?.length || 0}
                        cbk={exportCbk}
                        loading={tableLoading}
                        isExportEnabled={exportEnabled}
                        checked={hasContactChecked()}
                        pageType="contacts"
                        showExportTypeDropdown={showExportTypeDropdown}
                        setShowExportTypeDropdown={setShowExportTypeDropdown}
                      />
                      <TaskButton
                        contactIds={exportData}
                        loading={tableLoading}
                        checked={hasContactChecked()}
                        allContactSelected={allContactSelected}
                        activeTab={activeTab}
                        tagIds={filterByTagsState.filteredId}
                        memberIds={filterByOwnerState.filteredId}
                        resetCbk={resetAllStateValue}
                        isTaskEnabled={taskEnabled}
                        totalCount={totalContactCount}
                        searchKeyword={searchKeyword}
                        stageId={stageId}
                        contactIdsWithStage={selectedContactObj}
                        pageType="Contacts Page"
                      />
                      <AddContactsToList
                        contactIds={exportData}
                        loading={tableLoading}
                        addToListCbk={addRemoveListCbk}
                      />
                    </>
                  )}
                  {totalContactCount > 0 && (
                    <div
                      className={`toggleContactFilterByOption ${
                        totalContactCount > 0 ? "" : "disabled"
                      } ${filterArr?.length > 0 ? "" : "filterUsed"}`}
                      onClick={() =>
                        setContactFilter(
                          selectedFilter === false ? false : !showContactFilter
                        )
                      }
                    >
                      <FilterIcon />
                      <span className="text">Filter</span>
                      {filterArr?.length > 0 && (
                        <span className="count">{filterArr?.length}</span>
                      )}
                    </div>
                  )}
                  <ContactMoreOption
                    contactIds={exportData}
                    contactData={contactData}
                    selectedContactsNameList={selectedContactsNameList}
                    stage={activeTab?.toUpperCase() || ""}
                    tableLoading={tableLoading}
                    filterLoading={filterLoading}
                    resetContactCbk={resetAllStateValue}
                    contactCount={totalContactCount}
                    allContactSelected={allContactSelected}
                    checked={hasContactChecked()}
                    activeTab={activeTab}
                    selectedEntityCount={exportData?.length || 0}
                    addTagsCbk={addTagsCbk}
                    removeTagsCbk={removeTagsCbk}
                    performBulkOperationCbk={performBulkOperationAction}
                    removeFromListCbk={addRemoveListCbk}
                    updateContactOwnerCbk={updateContactOwner}
                  />
                </div>
              </div>
              <div className="contactDetailsCont">
                <div className="contactDetailsInnerCont seq-contacts-section">
                  <div
                    className={`seq-contacts-table-section ${adjustHeight()}`}
                  >
                    <ContactTable
                      history={history}
                      loading={tableLoading}
                      filterLoadingFn={setFilterLoadingFn}
                      tableLoadingFn={setTableLoadingFn}
                      contactUploading={isContactUploading}
                      exportAllContactSelected={exportAllContactSelected}
                      setExportAllContactSelected={getAllContactCbxSelected}
                      setExportData={setExportData}
                      setSelectedContactObj={setSelectedContactObj}
                      contactData={contactData}
                      totalPages={totalPages}
                      setContactData={setContactData}
                      selectedContactCard={openContactCard}
                      activeTab={activeTab}
                      contactTagsList={contactStoreData.filteredTags}
                      validContactUpload={remainingContactCount() > 0}
                      checkEligibleContactCount={remainingContactCount()}
                      setContactUploading={setContactUploading}
                      contactLimit={contactLimit}
                      allContactSelected={allContactSelected}
                      resetToSelectedCheckboxCbk={resetToSelectedCheckbox}
                      searchKeyword={searchKeyword}
                      filterEnabled={showContactFilter}
                      setSearchKeyword={setSearchKeyword}
                      setEmailClose={setEmailClose}
                      setActiveTab={setActiveTab}
                      contactFilterData={contactFilterData}
                      stageId={stageId}
                      entireContactFilterData={entireContactFilterData}
                      updateContactOwnerCbk={updateContactOwner}
                      selectedFilter={selectedFilter}
                      setSelectedContactsNameList={setSelectedContactsNameList}
                    />
                  </div>
                </div>
              </div>
            </div>
            {showContactFilter && (
              <TeamDataFilterContext.Provider
                value={{
                  defaultSelectAllTeamMemberObject,
                  teamFilterDropDownData,
                  setTeamFilterDropDownData,
                  teamCount,
                  setTeamCount
                }}
              >
                <ContactFilterByOption
                  filterArr={filterArr}
                  searchTerm={searchKeyword}
                  activeTab={activeTab?.toLowerCase() || ""}
                  setActiveTab={setActiveTab}
                  limit={contactLimit}
                  filterLoadingFn={setFilterLoadingFn}
                  tableLoadingFn={setTableLoadingFn}
                  setContactFilter={setContactFilter}
                  stageId={stageId}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  constructTeamDataInFilter={constructTeamDataInFilter}
                />
              </TeamDataFilterContext.Provider>
            )}
          </div>
        </section>
      </>
    );
  })
);

export default ContactModule;
export { TeamDataFilterContext };
