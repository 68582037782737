import React from "react";
import contactStoreData from "Stores/contactData";
import { toasterState } from "Components/common/toaster";
import { DONT_CONTACT_DOMAIN_MESSAGE } from "Static/constant";

const ContactUtils = {};

ContactUtils.getFilterStage = (activeTab = "") => {
  const tabList = {
    opencount: "OPENED",
    clickcount: "CLICKED"
  };
  let stage =
    activeTab?.toUpperCase() === "TOTAL" ? null : activeTab?.toUpperCase();
  return tabList[stage?.toLowerCase()]?.toUpperCase() || stage || null;
};

ContactUtils.setLocalStorageWithExpiry = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
};

ContactUtils.contactFilterByPayload = (
  payload = {},
  tableLoaderCbk = () => {},
  filterLoaderCbk = () => {},
  analyticsCall = false,
  storeInLocal = true
) => {
  contactStoreData.setFilterPayload(payload);
  storeInLocal &&
    ContactUtils.setLocalStorageWithExpiry(
      "sgContactFilterData",
      contactStoreData.contactFilterObject,
      2592000000 // Expiry in 30 days
    );
  console.log(
    "ContactUtils.contactFilterByPayload is invoked for filterContactData"
  );
  contactStoreData.filterContactData(payload, tableLoaderCbk);
  analyticsCall &&
    contactStoreData.filterContactAnalyticsData(payload, filterLoaderCbk);
};

ContactUtils.isDontContactDomain = (stage = "", type = "") => {
  if (["do not contact"].includes(stage?.toLowerCase())) {
    toasterState.setToastMsg(
      DONT_CONTACT_DOMAIN_MESSAGE?.[type] ||
        "Something went wrong. Please try again later",
      "failure"
    );
    return false;
  }
  return true;
};

ContactUtils.getTagNameDom = (tag = []) => {
  const showHiddenTags = () => {
    let newTag = [];
    if (tag?.length > 1) {
      tag.map((item, index) => {
        if (index !== 0) {
          newTag.push(item);
        }
      });
    }
    return newTag.join();
  };
  return tag?.length > 0 ? (
    <>
      <p className="tagNameTable">{tag?.[0] || ""}</p>
      {(tag?.length > 1 && (
        <p className="moreTags" title={showHiddenTags()}>
          +{tag.length - 1} more
        </p>
      )) ||
        ""}
    </>
  ) : (
    "--"
  );
};

export { ContactUtils };
