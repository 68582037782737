/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { makeApi, URLS } from "Utils/apiURL";

const GoogleWarmOAuth = observer(() => {
  const [isConnected, setConnected] = useState(false);

  const getAuthCodeAndInboxId = () => {
    try {
      const url = new URL(window.location.href);
      const code = url?.searchParams.get("code") || "";
      const inboxId = url?.searchParams.get("state") || "";
      const randomInboxId = localStorage.getItem("inboxID_random_sg") || "";
      !code
        ? url?.href?.indexOf("access_denied") !== -1
          ? closeWindow()
          : ""
        : "";
      if (inboxId === randomInboxId) {
        localStorage.removeItem("inboxID_random_sg");
        const updatedInboxId = inboxId?.split("_")?.pop() || "";
        return { code, inboxId: updatedInboxId };
      } else {
        closeWindow();
      }
      return { code };
    } catch (e) {
      closeWindow();
      return null;
    }
  };

  const connectGoogleApp = async () => {
    const { code = "", inboxId = "" } = getAuthCodeAndInboxId() || {};
    if (code) {
      const config = {
        url: `${URLS.connectInboxInWarming}?code=${code}&inboxId=${inboxId}`,
        method: "GET"
      };
      const res = await makeApi(config);
      const resCode = res?.data?.code?.toString() || "500";
      opener?.postMessage({ code: resCode, type: "GOOGLE_WARM_INBOX" });
      if (resCode === "200") {
        setConnected(true);
        setTimeout(() => {
          closeWindow();
        }, 1000);
      } else {
        closeWindow();
      }
    } else {
      closeWindow();
    }
  };

  const closeWindow = () => {
    window?.close();
  };

  useEffect(() => {
    connectGoogleApp();
  }, []);

  return (
    <div className="pageClass">
      <div className="pageCont">
        <div className="connectAppSection">
          {isConnected ? (
            <span>Your account has been successfully connected</span>
          ) : (
            <>
              <div>Please wait while we warm your google account</div>
              <div style={{ marginTop: "10px" }}> Connecting... </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default GoogleWarmOAuth;
export { GoogleWarmOAuth };
