/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { fileAttachmentStore } from "Stores/FileAttachmentStore";
import writingAssistantStoreData from "Stores/WritingAssistantData";

const pagetypeObject = {
  templates: "#templateQuilEditor",
  sequences: "#sequenceAutomaticQuilEditor",
  scheduleSequence: "#reactEditor.previewWrapper",
  contactTimeline: "#emailComposeEditor"
};

const editorHtAdjustClass = {
  templates: {
    single: "tempSingleRowHt",
    double: "tempMultiRowHt",
    full: "fullHt"
  },
  sequences: {
    single: "seqSingleRowHt",
    double: "seqMultiRowHt",
    full: "fullHt"
  },
  scheduleSequence: {
    single: "schSeqHt50",
    double: "schSeqHt90",
    full: "schSeqHt12"
  },
  contactTimeline: {
    single: "tempSingleRowHt",
    double: "tempMultiRowHt",
    full: "fullHt"
  }
};

const FileAttachmentList = observer(
  ({ attachments = [], entityObj = {}, fromTaskPage = false }) => {
    const removeAttachment = async (id = "") => {
      if (id) {
        const {
          pageType = "",
          updateState = () => {},
          entity = {}
        } = entityObj || {};
        let tempList = [];
        if (attachments?.length > 0) {
          attachments.map(item => {
            if (item?.id !== id) {
              tempList.push(item);
            }
          });
        }
        let isTemplatePage = pageType?.toLowerCase() === "templates";
        isTemplatePage && (entity.attachments = tempList);
        updateState(
          JSON.parse(JSON.stringify(isTemplatePage ? entity : tempList))
        );
        removeTotalSizeInStore(id);
        await fileAttachmentStore.removeFileAttachment({ id });
        removeAttachmentInStore(id);
        if (attachments?.length > 0) {
          changeEditorBodyHeight(attachments - 1);
        }
        // Chat GPT icon height adjust
        writingAssistantStoreData.setAttachmentUpdated(true);
      }
    };

    const removeTotalSizeInStore = (removedAttachmentId = "") => {
      const uploadedFile = JSON.parse(
        JSON.stringify(fileAttachmentStore.fileAttachmentList)
      );
      let totalSizeUploaded = JSON.parse(
        JSON.stringify(fileAttachmentStore.totalSizeUploaded)
      );
      if (uploadedFile?.length) {
        uploadedFile.map(ele => {
          if (ele?.attachments) {
            const { id = "", attachmentSizeInKB = "" } = ele?.attachments || {};
            if (id === removedAttachmentId) {
              const size = attachmentSizeInKB;
              const sizeInMb = size / 1024;
              totalSizeUploaded -= Math.ceil(sizeInMb);
            }
          }
        });
      }
      fileAttachmentStore.setTotalSizeUploaded(totalSizeUploaded);
    };

    const removeAttachmentInStore = (id = "") => {
      const uploadedAttachments = JSON.parse(
        JSON.stringify(fileAttachmentStore.fileAttachmentList)
      );
      if (uploadedAttachments?.length > 0) {
        const tempArr = [];
        uploadedAttachments.map(value => {
          if (value) {
            const { attachments = {} } = value || {};
            if (attachments?.id !== id) {
              tempArr.push(value);
            }
          }
        });
        if (tempArr?.length) {
          fileAttachmentStore.setFileAttachmentList(
            JSON.parse(JSON.stringify(tempArr))
          );
        } else {
          fileAttachmentStore.clearAttachmentList();
        }
      }
    };

    const domContainsClass = (
      hasElement,
      singleAttCls,
      mulitAttCls,
      fullHt
    ) => {
      if (hasElement.classList.contains(singleAttCls)) {
        hasElement.classList.remove(singleAttCls);
      }
      if (hasElement.classList.contains(mulitAttCls)) {
        hasElement.classList.remove(mulitAttCls);
      }
      if (hasElement.classList.contains(fullHt)) {
        hasElement.classList.remove(fullHt);
      }
    };

    const updateEditorHeight = (
      attList,
      hasElement,
      singleAttCls,
      mulitAttCls,
      fullHt,
      pageCount = 2
    ) => {
      if (hasElement) {
        domContainsClass(hasElement, singleAttCls, mulitAttCls, fullHt);
        if (attList?.length > 0 && attachments?.length <= pageCount) {
          hasElement.classList.add(singleAttCls);
        } else if (attList?.length > pageCount) {
          hasElement.classList.add(mulitAttCls);
        } else if (!attList || attList?.length < 1) {
          hasElement.classList.add(fullHt);
        }
      }
    };

    const changeEditorBodyHeight = (attList = []) => {
      const { pageType = "" } = entityObj || {};
      let addHtClass = document.querySelector(
        `${pagetypeObject[pageType]} > div.quill > div.ql-container.ql-snow > div.ql-editor`
      );
      const {
        single = "",
        double = "",
        full = ""
      } = editorHtAdjustClass[pageType];
      let countPerRow = ["templates", "contactTimeline"].includes(pageType)
        ? 3
        : 2;
      updateEditorHeight(
        attList,
        addHtClass,
        single,
        double,
        full,
        countPerRow
      );
    };

    useEffect(() => {
      if (!fromTaskPage) {
        changeEditorBodyHeight(attachments);
        // Chat Gpt icon height adjust
        writingAssistantStoreData.setAttachmentUpdated(true);
      }
    }, [attachments]);

    return (
      (!fromTaskPage && attachments?.length > 0 && (
        <div className="attFileList" id="editorFileAttachment">
          {attachments.map((item, index) => {
            return (
              <div
                className="attFileCard"
                key={`fileAttachmentEditor${index + 1}`}
              >
                <span className="attName">{item?.attachmentName || ""}</span>
                <span className="attSize">
                  ({Utils.getFileSizeAndType(item?.attachmentSizeInKB || "")})
                </span>
                <span
                  className="material-icons removeAttFile"
                  onClick={() => removeAttachment(item?.id)}
                >
                  close
                </span>
              </div>
            );
          })}
        </div>
      )) ||
      ""
    );
  }
);

export default FileAttachmentList;
export { FileAttachmentList };
