import React, { useContext } from "react";
import { RewriteAiContext } from "./RewriteAi";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { ReactComponent as Smiley1 } from "Assets/svg/WritingAssistant/1.svg";
import { ReactComponent as Smiley2 } from "Assets/svg/WritingAssistant/2.svg";
import { ReactComponent as Smiley3 } from "Assets/svg/WritingAssistant/3.svg";
import { ReactComponent as Smiley4 } from "Assets/svg/WritingAssistant/4.svg";
import { ReactComponent as Smiley5 } from "Assets/svg/WritingAssistant/5.svg";

const AiContentTags = () => {
  const {
    loader = false,
    emotions = [],
    rewriteContent = () => {}
  } = useContext(RewriteAiContext);

  const emotionsSvg = {
    shorten: <Smiley1 />,
    creative: <Smiley2 />,
    casual: <Smiley3 />,
    professional: <Smiley4 />,
    witty: <Smiley5 />
  };

  const handleClickAction = (event = "") => {
    if (event) {
      let keyIndex = Utils.performActionByClosestDom(event, "li", "data-key");
      let prompt = emotions?.[keyIndex]?.prompt || "";
      Utils.mixpanelTrack(MXP_EVENT.CHAT_GPT_AI_TAGS_CLICKED);
      prompt && rewriteContent(false, prompt);
    }
  };

  return (
    <div className="aiContentTags">
      <span className="contentLabel">Rewrite with AI:</span>
      {(emotions?.length > 0 && (
        <ul
          className="contentTagsList"
          onClick={e => {
            Utils.preventDefaultFn(e);
            handleClickAction(e);
          }}
        >
          {emotions.map((item, index) => {
            const { id = "" } = item || {};
            return (
              <li
                className="tagItem"
                key={id?.toLowerCase()}
                data-key={index}
                style={{ cursor: loader ? "unset" : "pointer" }}
              >
                <span className="tagSvg">{emotionsSvg?.[id] || ""}</span>
                <span className="tagName">
                  {Utils.captializeFirstCharacter(id)}
                </span>
              </li>
            );
          })}
        </ul>
      )) ||
        ""}
    </div>
  );
};

export default AiContentTags;
