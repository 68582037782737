import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toasterState } from "Components/common/toaster";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import userDetail from "Stores/userDetail";
import { ReactComponent as ForwardArrowSvg } from "Assets/svg/arrow-forward.svg";
import { Button } from "Components/common/Button";
import StageRulesDropDown from "./StageRulesDropDown";
import makeApi, { URLS } from "Utils/apiURL";

const stageRuleObj = {
  CONTACT_CREATED: ["Whenever a contact is created", "Set contact stage to"],
  ADDED_TO_SEQUENCE: [
    "Whenever a contact is added to a sequence",
    "Set contact stage to"
  ],
  COMPLETED_WITH_REPLY: [
    "Whenever a contact is completed in a sequence with a reply",
    "Set contact stage to"
  ],
  COMPLETED_WITHOUT_REPLY: [
    "Whenever a contact is completed in a sequence without a reply",
    "Set contact stage to"
  ],
  EMAIL_PHONE: [
    "Whenever the first email is sent or first call is made to a contact",
    "Set contact stage to"
  ],
  MEETING_BOOKED: [
    "Whenever a meeting is booked by a contact",
    "Set contact stage to"
  ]
};

const defaultStageRules = {
  CONTACT_CREATED: "",
  ADDED_TO_SEQUENCE: "",
  COMPLETED_WITH_REPLY: "",
  COMPLETED_WITHOUT_REPLY: "",
  EMAIL_PHONE: "",
  MEETING_BOOKED: ""
};

const StageRules = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [contactStages, setContactStages] = useState([]);
  const [selectedRules, setSelectedRules] = useState(defaultStageRules);
  const [loader, setLoader] = useState(false);
  const [stageNameLoader, setStageNameLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);

  const history = useHistory();
  const { inFreePlan = false, planName = "" } =
    userDetail?.userFeatureFlag || {};
  let freePlanCheck = inFreePlan || planName?.toLowerCase() == "free";

  const saveApiCall = async () => {
    if (freePlanCheck) {
      upgradePopupState.setPopupValues({ type: "stageRules" });
      upgradePopupState.setPopup(true);
    } else {
      setSaveLoader(true);
      const config = {
        url: URLS.saveRule,
        method: "POST",
        data: { rules: selectedRules }
      };
      let response = await makeApi(config);
      if (response?.data?.responseCode === 200) {
        toasterState.setToastMsg(
          "Contact stage rules saved successfully",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "failure"
        );
      }
      setSaveLoader(false);
    }
  };

  const buttonList = [
    {
      id: "cancel",
      name: "cancel",
      btnText: "Cancel",
      type: "button",
      className: "cancelButton",
      click: () => history.push("/settings/stages")
    },
    {
      id: "save",
      name: "save",
      btnText: "Save",
      type: "button",
      className: `saveButton ${saveLoader ? "loading" : ""}`,
      click: saveApiCall,
      loader: saveLoader
    }
  ];

  const getContactStages = async temp => {
    setStageNameLoader(true);
    const config = {
      url: URLS.getContactStages,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response?.data?.length > 0) {
      let filterContactStages = response?.data.filter(
        item => !["BOUNCED", "OPTED_OUT", "DO_NOT_CONTACT"].includes(item.name)
      );
      setContactStages(filterContactStages);
    }
    setStageNameLoader(false);
  };

  const getContactStageRules = async () => {
    setLoader(true);
    const config = {
      url: URLS.getRules,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response?.data?.rules) {
      setSelectedRules({ ...selectedRules, ...response?.data?.rules });
    }
    getContactStages();
    setLoader(false);
  };

  useEffect(() => {
    getContactStageRules();
    return () => {
      setActiveIndex(-1);
    };
  }, []);

  const stageRuleLoader = () => {
    let classArr = ["rule", "iconArrow", "defaultText", "stageDropdown"];
    return Array.from({ length: 6 }, (value, index) => (
      <div
        className="ruleSetRow"
        style={{ minHeight: "53px" }}
        key={`stageRuleLoader${index}`}
      >
        {classArr.map((element, index) => {
          return (
            <span
              key={`classArr${index}`}
              className={`${element} linear-background`}
            />
          );
        })}
      </div>
    ));
  };

  return (
    <div className="contactStageRules">
      <div className="headerContent">
        Automatically update a contact's stage when any one of the following
        event occurs.
      </div>
      <div
        className="stageRulesContentBody"
        style={{ overflowY: activeIndex >= 0 ? "unset" : "auto" }}
      >
        {!stageNameLoader &&
          !loader &&
          Object.keys(selectedRules)?.length > 0 &&
          Object.keys(selectedRules).map((element, index) => {
            return (
              <div className="ruleSetRow" key={`stageRule${index + 1}`}>
                <div className="rule">{stageRuleObj?.[element]?.[0] || ""}</div>
                <div className="iconArrow">
                  <ForwardArrowSvg />
                </div>
                <div className="defaultText">
                  {stageRuleObj?.[element]?.[1] || ""}
                </div>
                <div className="stageDropdown">
                  <StageRulesDropDown
                    index={index}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    contactStages={contactStages}
                    selectedRules={selectedRules}
                    setSelectedRules={setSelectedRules}
                    currentRuleId={selectedRules[element]}
                    currentRule={element}
                    freePlanCheck={freePlanCheck}
                  />
                </div>
              </div>
            );
          })}
        {(stageNameLoader || loader) && stageRuleLoader()}
      </div>
      {!stageNameLoader && !loader && (
        <div className="buttonActionList">
          {buttonList.map(item => (
            <Button {...item} key={`${item?.id}Btn`} />
          ))}
        </div>
      )}
    </div>
  );
};

export default StageRules;
