import React, { useState, useEffect } from "react";
import { toasterState } from "Components/common/toaster";

const AddInboxButton = (props = {}) => {
  const {
    inboxLoaderId = false,
    id = "",
    label = "",
    cbk = () => {},
    checked = false,
    cbkData = "",
    disabled = false
  } = props || {};

  const [tickMark, setTickMark] = useState(checked);

  const setInboxAdded = e => {
    if (!inboxLoaderId) {
      setTickMark(!tickMark);
      cbk(!tickMark, id, label, cbkData);
    }
  };

  useEffect(() => {
    setTickMark(checked);
  }, [checked]);

  return (
    <div
      className={`addInboxButtonWrapper ${checked ? "enabled" : ""}`}
      onClick={checked ? () => {} : e => setInboxAdded(e)}
    >
      {label && (
        <label className="addInboxLabel" htmlFor={label}>
          {label}
        </label>
      )}
      <div className={`addInboxCont  ${checked ? "enabled" : ""}`} id={id}>
        {checked ? (
          <span className="addedInboxLabel">Added</span>
        ) : (
          <div
            className={`addInboxButton ${disabled ? "disabled" : ""}`}
            id={id}
          >
            {inboxLoaderId === id ? (
              <span className="addedInboxLabel loadingBot loaderThreeDotWrapper">
                Adding
                <span className="loaderText">
                  {Array.from({ length: 3 }, (_, index) => (
                    <span className="loaderDot" key={index}>
                      .
                    </span>
                  ))}
                </span>
              </span>
            ) : (
              <i className="material-icons addIcon">add</i>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { AddInboxButton };
export default AddInboxButton;
