import React, { useState, memo } from "react";
import Utils from "Utils/utils";
import DropdownButton from "Pages/Dashboard/contacts/ContactFilter/DropdownButton";

export const ConnectInboxDropdown = (props = {}) => {
  const {
    label = "",
    keyValue = "",
    data = [],
    activeFilter = "",
    selected = [],
    setActiveFilter = () => {}
  } = props || {};

  let toggleDropdown = activeFilter === keyValue;

  const [showInsetDropdown, setShowInsetDropdown] = useState(false);

  const btnLabel = {
    label,
    count: selected?.length,
    keyValue,
    toggleDropdown,
    callback: setActiveFilter
  };
  return (
    <div className="connectInboxDropdown">
      <DropdownButton {...btnLabel} />
      {toggleDropdown &&
        (data?.length > 0 ? (
          <div className="insetDropdown">
            <span className="horizontalBorderLine" />
            <InsetDropdownButton
              {...props}
              showInsetDropdown={showInsetDropdown}
              setShowInsetDropdown={setShowInsetDropdown}
            />
            {showInsetDropdown && (
              <Dropdown
                {...props}
                setShowInsetDropdown={setShowInsetDropdown}
              />
            )}
          </div>
        ) : (
          <div className="noDataAvailable">No data available</div>
        ))}
    </div>
  );
};

const InsetDropdownButton = ({
  showInsetDropdown = false,
  data = [],
  selected = [],
  keyValue = "",
  placeholder = "",
  setShowInsetDropdown = () => {},
  setAction = () => {}
}) => {
  let hasValue = selected?.length > 0;

  const { userName = "", email = "" } = selected?.[0] || {};

  const clearAction = (event = "") => {
    if (event) {
      Utils.preventDefaultFn(event);
      let tempData = [...data];
      tempData = tempData.map(item => {
        item.checked = false;
        return item;
      });
      setAction(keyValue, tempData);
    }
  };

  const toggleDropdown = (event = "") => {
    Utils.preventDefaultFn(event);
    setShowInsetDropdown(!showInsetDropdown);
  };

  return (
    <div
      className={`dropdownSelectedOpt ${hasValue ? "" : "noneSelected"}`}
      onClick={e => toggleDropdown(e)}
    >
      <span className={`selectedItem ${hasValue ? "" : "selectPlacholder"}`}>
        {hasValue
          ? email && (
              <span className="email" title={email}>
                {email}
              </span>
            )
          : placeholder}
      </span>
      {(hasValue > 0 && (
        <i
          className={`material-icons clearItemIcon ${
            showInsetDropdown ? "rotate" : ""
          }`}
          onClick={e => clearAction(e)}
        >
          clear
        </i>
      )) ||
        ""}
      <i
        className={`material-icons arrowIcon ${
          showInsetDropdown ? "rotate" : ""
        }`}
      >
        keyboard_arrow_down
      </i>
    </div>
  );
};

const Dropdown = ({
  data = [],
  keyValue = [],
  setShowInsetDropdown = () => {},
  setAction = () => {}
}) => {
  const handleSelectAction = (event = "") => {
    if (event) {
      let uId = Utils.performActionByClosestDom(event, "li", "data-key");
      if (uId) {
        let tempData = [...data];
        tempData = tempData.map(item => {
          const { id = "", checked = false } = item || {};
          item.checked = id === uId ? (checked ? false : true) : false;
          return item;
        });
        setAction(keyValue, tempData);
        setShowInsetDropdown(false);
      }
    }
  };

  return (
    <div className="ddBox animatedFast animatedDropDown">
      <ul className="ddList" onClick={e => handleSelectAction(e)}>
        {data.map(item => {
          const {
            id = "",
            email = "",
            userName = "",
            checked = false
          } = item || {};
          return (
            <li
              data-key={id}
              className={`ddItem ${checked ? "active" : ""}`}
              key={id}
            >
              {userName && <span className="name">{userName}</span>}
              {email && (
                <span className="email" title={email}>
                  {email}
                </span>
              )}
            </li>
          );
        })}
      </ul>
      <div className="ddOverlay" onClick={() => setShowInsetDropdown(false)} />
    </div>
  );
};

export default ConnectInboxDropdown;
