/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import RegisterHeader from "Pages/Register/RegisterHeader";
import RegisterFooter from "Pages/Register/RegisterFooter";
import TeamContentBody from "Pages/Register/TeamOnboard/TeamContentBody";
import WaveLineImg from "Assets/Register/line-wave-bg.png";
import { checkTeamStatus, getFeatureFlagWithTeam } from "Utils/commonAPI";
import { userDetail, inviteTeam } from "Stores/userDetail";

const TeamInviteAcceptSuccess = props => {
  let adminName =
    localStorage.getItem("inviteTeam") && userDetail.userTeamInfo.adminName;

  const resetInviteMember = async () => {
    if (localStorage.getItem("inviteTeam")) {
      let isTeam = await checkTeamStatus();
      checkUserHasTeam(isTeam);
    } else {
      props.history.push("/sequences/all");
    }
  },
    checkUserHasTeam = async team => {
      userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
      const userData = {};
      userData.team = team;
      userData.email = userDetail.userTeamInfo.email;
      userData.name = userDetail.userTeamInfo.name;
      userData.userLoggedIn = true;
      setTimeout(() => {
        localStorage.removeItem("inviteTeam");
        userDetail.setUserInfo(userData);
        userDetail.setUserTeamInfo(inviteTeam);
        props.history.push("/sequences/all");
      }, 3000);
    };

  useEffect(() => {
    resetInviteMember();
  }, [resetInviteMember]);

  return (
    <section id="userRegister">
      <div className="container">
        <RegisterHeader />
        <div className="register-body teamBg">
          <TeamContentBody />
          <div className="register-panel">
            <div className="teamThanksSection">
              <div className="iconShadow">
                <i className="material-icons success">check_circle</i>
                <span className="shadow"></span>
              </div>
              <div className="thankYouMsgText">
                You have successfully joined {adminName}'s team
              </div>
              <div className="spinnerLoader">
                <i className="fa fa-spinner fa-spin"></i>
              </div>
              <p className="infoText">Setting up your account</p>
            </div>
          </div>
        </div>
        <RegisterFooter teamPath={true} />
        <img className="bg-wave-line" src={WaveLineImg} alt="" />
      </div>
    </section>
  );
};

export default withRouter(observer(TeamInviteAcceptSuccess));
