import React from "react";
import Lottie from "react-lottie";
import CreditCard from "Utils/LottieFiles/creditCard.json";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

class TransactionAnimationState {
  @observable showPopup = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };
}

const transactionAnimationState = new TransactionAnimationState();

const TransactionAnimation = observer(() => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CreditCard,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return transactionAnimationState.showPopup ? (
    <div className="transactionProgressPopup popupWrapper">
      <div className="confirmation-popup common-popup">
        <div className="transactionProgressWrapper popup-wrap animated customzoomIn">
          <div className="animationCont">
            <div className="cardAnimation">
              <Lottie
                options={defaultOptions}
                width={100}
                height={100}
                style={{ margin: "0 auto" }}
                speed={1.5}
              />
            </div>
            <div className="desc">
              <div className="waitText">Please wait...</div>
              <div className="processingText">
                We are processing your transaction
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

export default TransactionAnimation;
export { TransactionAnimation, transactionAnimationState };
