import React from "react";

const TeamMemberTableLoader = () => {
  const columnList = ["fullName", "emailAddress", "jobTitle", "role"];
  return (
    <div className="teamMemberSection">
      <div className="headerPadding">
        <div className="tableHeader loadingData" />
      </div>
      <div className="tableBody">
        {Array.from({ length: 5 }, (item, index) => (
          <div className="tableRowLoader" key={`${Date.now()}${index}`}>
            {columnList.map(item => (
              <div className={item} key={`${item}${index}`}>
                <span className="linear-background" />
              </div>
            ))}
            <div className="moreIcons">
              <div className="makeOwnerIcon">
                <span className="linear-background" />
              </div>
              <div className="removeMemberIcon">
                <span className="linear-background" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { TeamMemberTableLoader };
export default TeamMemberTableLoader;
