import React from "react";
import PaginationComponent from "Components/common/PaginationComponent";
import MoreOptionSlide from "Components/common/MoreOptionSlide";
import VariantToggle from "Pages/Dashboard/sequences/SequencePage/VariantToggle";
import Utils from "Utils/utils";
import EmptyTrigger from "./EmptyTrigger";
import FeatureDemoVideo from "Components/common/FeatureDemoVideo";
import { actionObject } from "Static/constant";

const OveriewTable = props => {
  const {
    data = {},
    remove = () => {},
    edit = () => {},
    clone = () => {},
    toggleTrigger = () => {},
    loader = false,
    paginate = () => {},
    addTrigger = () => {},
    triggersEnabled = false
  } = props || {};

  const {
    totalElements = 0,
    triggers = [],
    totalPages = 0,
    currentPage = 0
  } = data || {};

  const moreOptions = [
    {
      icon: "create",
      name: "Edit",
      key: "edit",
      method: edit
    },
    {
      icon: "file_copy",
      name: "Clone",
      key: "clone",
      method: clone
    },
    {
      icon: "delete",
      name: "Delete",
      key: "delete",
      method: remove
    }
  ];

  const triggerMessageObject = {
    CONTACT_CREATED: "a contact is created",
    CONTACT_UPDATED: "a contact is updated, update the contact details",
    EMAIL_DELIVERED: "an email is delivered to the contact",
    EMAIL_OPENED: "an email is opened by the contact",
    EMAIL_CLICKED: "a contact clicks on a link",
    EMAIL_REPLIED: "a contact replies",
    CONTACT_OPTEDOUT: "a contact opts out",
    EMAIL_BOUNCED: "a contact bounces",
    CALL_LOGGED: "a call is logged for a contact",
    WEBSITE_VISITED: "a website is visited by a contact"
  };

  const actionTypeMessage = (actions = []) => {
    if (actions?.length) {
      return actions
        ?.slice(0, actions?.length > 5 ? actions?.length - 3 : 3)
        ?.map(action => {
          if (action?.actionType?.toUpperCase() === "CREATE_TASK") {
            return `create a  ${action?.task?.type} task`;
          } else if (action?.actionType?.toUpperCase() === "ADD_TO_SEQUENCE") {
            return `add them to the "${action?.sequenceName}" sequence,`;
          } else if (
            action?.actionType?.toUpperCase() === "SLACK_NOTIFICATION"
          ) {
            return "send a Slack notification";
          } else {
            return actionObject[action?.actionType];
          }
        })
        ?.join(" and ");
    }
  };

  const splitAndCheckLength = (task = "") => {
    return task?.split(" and ")?.map(part => part?.length) || 0;
  };

  const remainingActions = (actions = []) => {
    const remainingValue = splitAndCheckLength(actionTypeMessage(actions));
    if (actions?.length) {
      return actions?.length - remainingValue?.length;
    } else return 0;
  };

  return (
    <>
      {totalElements > 0 ? (
        <>
          <div
            className={`overviewCardList ${
              totalPages > 1 ? "showPagination" : ""
            }`}
          >
            {(totalElements > 0 &&
              triggers?.length &&
              triggers.map((item, index) => {
                const { trigger = {}, owner = {} } = item || {};
                const {
                  id = "",
                  name = "",
                  status = "",
                  updateTs = "",
                  toggleLoading = false,
                  triggerType = "",
                  actions = []
                } = trigger || {};
                const {
                  firstName = "",
                  lastName = "",
                  email = ""
                } = owner || {};
                return (
                  <div
                    className="overviewCard"
                    key={id}
                    onClick={e => edit(e, index)}
                  >
                    <div
                      className="toggleTrigger"
                      onClick={e => Utils.preventDefaultFn(e)}
                    >
                      <VariantToggle
                        action={e =>
                          !toggleLoading ? toggleTrigger(e, id, item) : () => {}
                        }
                        isChecked={status === "ACTIVE"}
                        loading={toggleLoading}
                      />
                    </div>
                    <div className="triggerTitle">
                      <div className="name">{name}</div>
                      <div className="subText">
                        {triggerType
                          ? `Whenever ${
                              triggerMessageObject?.[triggerType]
                            }, ${actionTypeMessage(actions)} ${
                              actions?.length > 3
                                ? `+ ${remainingActions(actions)} other actions`
                                : ""
                            }`
                          : ""}
                      </div>
                    </div>
                    <div className="date">
                      {Utils.getDateFromString(updateTs)}
                    </div>
                    <div className="contactOwner">
                      <span className="circleOwner">
                        {Utils.getOwnerInitial({
                          firstName,
                          lastName,
                          email
                        })}
                      </span>
                    </div>
                    <MoreOptionSlide
                      dropdownOptions={moreOptions}
                      id={item}
                      index={index}
                      isFromOverview={true}
                    />
                  </div>
                );
              })) ||
              ""}
          </div>
          {(totalPages > 1 && (
            <PaginationComponent
              callback={paginate}
              loading={loader}
              totalPages={totalPages}
              currentPage={currentPage || 1}
            />
          )) ||
            ""}
        </>
      ) : (
        <>
          <EmptyTrigger
            triggersEnabled={triggersEnabled}
            createCbk={addTrigger}
          />
          <FeatureDemoVideo
            name={"Create Triggers"}
            linkText={"How to create new triggers"}
            type={"triggers"}
          />
        </>
      )}
    </>
  );
};

export default OveriewTable;
export { OveriewTable };
