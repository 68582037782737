import React from "react";

const DialerButtonLoader = () => {
  return (
    <div className="dialerButtonLoader">
      {Array.from({ length: 5 }, (value, index) => (
        <div
          key={`dialerBtnLoader${index}`}
          className={`linear-background ${index === 2 ? "callLoader" : ""}`}
        ></div>
      ))}
    </div>
  );
};

export default DialerButtonLoader;
export { DialerButtonLoader };
