import React, { useState } from "react";
import Utils from "Utils/utils";
import DropDownMenu from "Components/common/DropDownMenu";
import { ReactComponent as Downarrow } from "Assets/svg/donw-arrow.svg";

const SiblingButton = (props = {}) => {
  const { cbk = () => {} } = props || {};
  const [showDropDown, setShowDropDown] = useState(false);

  const skipButtonAction = () => {
    toggleDropDown(null, false);
    cbk();
  };

  const toggleDropDown = (e, value) => {
    Utils.preventDefaultFn(e);
    setShowDropDown(value);
  };

  const dropdownOptions = [
    {
      icon: "",
      value: "Skip Step",
      key: "skipTask",
      method: skipButtonAction
    }
  ];

  return (
    <div
      className="siblingBtn"
      id="siblingBtn"
      onClick={e => toggleDropDown(e, true)}
    >
      <span className="downArrow">
        <Downarrow />
      </span>
      {showDropDown && (
        <>
          <DropDownMenu
            options={dropdownOptions}
            animationClass="animatedDropDownReverse"
          />
          <div
            className="dropdownMask"
            onClick={e => toggleDropDown(e, false)}
          />
        </>
      )}
    </div>
  );
};

export default SiblingButton;
export { SiblingButton };
