/* eslint-disable array-callback-return */
import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import contactStoreData from "./contactData";

class TagsData {
  @observable tagsData = [];

  @action
  setTagsData(value) {
    this.tagsData = value;
  }

  @action
  getAllTags = async () => {
    try {
      const config = {
        url: URLS.getContactTags,
        method: "GET"
      };
      const res = await makeApi(config);
      if (res?.data?.length) {
        this.constructTagsData(res.data);
      }
    } catch (err) {
      console.error("Error in fetching tags", err);
    }
  };

  @action
  constructTagsData = tagsList => {
    if (tagsList?.length) {
      const tempArr = [];
      tagsList.map(obj => {
        const { name = "", id = "" } = obj || {};
        if (name && id) {
          tempArr.push({ value: name, id });
        }
      });
      contactStoreData.setContactFilterObject({
        ...contactStoreData.contactFilterObject,
        tags: tempArr
      });
      this.setTagsData(tempArr);
    }
  };
}

const tagsStoreData = new TagsData();
export { tagsStoreData, TagsData };
export default TagsData;
