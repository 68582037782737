import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import PaywallUtils from "Utils/PaywallUtils";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { customUrlStoreData } from "Stores/Setting/CustomUrlStore";
import userDetail from "Stores/userDetail";
import { ReactComponent as GoogleIcon } from "Assets/svg/googleIcon.svg";
import { ReactComponent as MicrosoftIcon } from "Assets/svg/microsoftIcon.svg";
import { ReactComponent as SmtpIcon } from "Assets/svg/smtpIcon.svg";
import InboxListWithSearchAndFilter from "./InboxListWithSearchAndFilter";
import CustomTrackingComponent from "./CustomTrackingComponent";
import DomainHealthDetailedInfo from "./DomainHealthDetailedInfo";
import DomainHealthScore from "./DomainHealthScore";
import domainHealthCenterStore from "./store";

const DomainHealthCenter = observer(() => {
  const { email = "", type = "" } = domainHealthCenterStore?.dnsRecords || {};
  const {
    inFreePlan = false,
    planName = "",
    domainHealthCenterEnabled = false
  } = userDetail?.userFeatureFlag || {};
  const [updatedInboxList, setUpdatedInboxList] = useState([]);

  const getBrandedUrl = async () => {
    await customUrlStoreData.getBrandUrlData();
  };

  const oAuthObj = {
    outlook_oauth: <MicrosoftIcon className="microsoftIcon" />,
    gmail_oath: <GoogleIcon className="googleIcon" />,
    smtp: <SmtpIcon className="smtpIcon" />
  };

  const upgradeCheckAndMixPanelEvents = () => {
    if (!domainHealthCenterEnabled) {
      PaywallUtils.upgradeUtilsFunction(
        "domainHealthCenter",
        false,
        planName,
        inFreePlan
      );
      Utils.mixpanelTrack(MXP_EVENT.DOMAIN_HEALTH_CENTER_UPGRADE_POPUP, {
        pageType: "Domain Health Center"
      });
    }
    Utils.mixpanelTrack(MXP_EVENT.DOMAIN_HEALTH_CENTER_VISITED, {
      pageType: "Domain Health Center"
    });
  };

  const setPageVisitedLocalStorageValue = () => {
    let value = localStorage.getItem("domainHealthCenterVisited");
    if (!value) {
      localStorage.setItem("domainHealthCenterVisited", true);
    }
  };

  useEffect(() => {
    setUpdatedInboxList(domainHealthCenterStore?.allConnectedAccounts);
  }, [domainHealthCenterStore?.allConnectedAccounts]);

  useEffect(() => {
    setPageVisitedLocalStorageValue();
    getBrandedUrl();
    domainHealthCenterStore.setCurrentPage(1);
    domainHealthCenterStore.fetchAllConnectedEmails();
    domainHealthCenterStore.getDomainHealthScore();
    upgradeCheckAndMixPanelEvents();
    return () => {
      domainHealthCenterStore.setDomainHealthDetailedInfo(false);
    };
  }, []);

  return (
    <section
      className={`domainHealthCenterWrapper ${
        domainHealthCenterEnabled ? "" : "blurBg"
      }`}
    >
      <div className="domainHealthCenterCont">
        <div className="headerCont">
          <h6 className="titleHeader">Domain Health Center</h6>
          {domainHealthCenterStore?.domainHealthDetailedInfo && (
            <div className="emailCont">
              {oAuthObj?.[type] || <></>}
              <span className="emailTxt">{email}</span>
            </div>
          )}
        </div>
        {!domainHealthCenterStore?.domainHealthDetailedInfo ? (
          <div className="layoutSection">
            <div className="leftLayout">
              <InboxListWithSearchAndFilter
                updatedInboxList={updatedInboxList}
                setUpdatedInboxList={setUpdatedInboxList}
              />
            </div>
            <div className="rightLayout">
              <DomainHealthScore
                updatedInboxList={updatedInboxList}
                setUpdatedInboxList={setUpdatedInboxList}
              />
              <CustomTrackingComponent />
              {/* <EmailTestComponent /> */}
            </div>
          </div>
        ) : (
          <DomainHealthDetailedInfo />
        )}
      </div>
    </section>
  );
});

export { DomainHealthCenter };
export default DomainHealthCenter;
