/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { userDetail, inviteTeam } from "Stores/userDetail";
import { checkTeamStatus, getFeatureFlagWithTeam } from "Utils/commonAPI";

const TeamInviteSuccess = props => {
  let adminName =
    (localStorage.getItem("inviteTeam") && userDetail.userTeamInfo.adminName) ||
    "Admin";

  const resetInviteMember = async () => {
      if (localStorage.getItem("inviteTeam")) {
        let isTeam = await checkTeamStatus();
        checkUserHasTeam(isTeam);
      } else {
        props.history.push("/login");
      }
    },
    checkUserHasTeam = async team => {
      const userData = {};
      userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
      userData.team = team;
      userData.email = userDetail.userTeamInfo.email;
      userData.name = userDetail.userTeamInfo.name;
      userData.userLoggedIn = true;
      setTimeout(() => {
        localStorage.removeItem("inviteTeam");
        userDetail.setUserInfo(userData);
        userDetail.setUserTeamInfo(inviteTeam);
        props.fn(true);
        props.history.push("/dashboard");
      }, 3000);
    };

  useEffect(() => {
    resetInviteMember();
  }, []);

  return (
    <div className="registerFormInput">
      <div className="teamThanksSection">
        <div className="iconShadow">
          <i className="material-icons success">check_circle</i>
          <span className="shadow"></span>
        </div>
        <div className="thankYouMsgText">
          You have successfully joined {adminName}'s team
        </div>
        <div className="spinnerLoader">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
        <p className="infoText">Setting up your account</p>
      </div>
    </div>
  );
};

export default TeamInviteSuccess;
