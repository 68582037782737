import { observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import makeApi, { URLS } from "Utils/apiURL";

class HubspotStore {
  @observable hubspotData = "";
  @observable hubspotContactsList = "";
  @observable hubspotOwnersList = "";
  @observable hubspotContactConfig = {};
  @observable hubspotOwnerConfig = {};
  @observable hubspotContactTags = [];
  @observable disableSaveButton = false;
  @observable hubspotFields = [];
  @observable hubspotPreferences = {};
  @observable hubspotOffset = "";
  @observable hasMoreFlag = true;

  @action
  setHubspotOffset = (value = "") => {
    this.hubspotOffset = value;
  };

  @action
  setHasMoreFlag = (value = false) => {
    this.hasMoreFlag = value;
  };

  @action
  setHubspotData = value => {
    this.hubspotData = value;
  };

  @action
  setHubspotContactsList = value => {
    this.hubspotContactsList = value;
  };

  @action
  setHubspotOwnersList = value => {
    this.hubspotOwnersList = value;
  };

  @action
  setHubspotContactConfig = value => {
    this.hubspotContactConfig = value;
  };

  @action
  setHubspotOwnerConfig = value => {
    this.hubspotOwnerConfig = value;
  };

  @action
  setDisableSaveButton = value => {
    this.disableSaveButton = value;
  };

  @action
  setHubspotContactTags = value => {
    this.hubspotContactTags = value;
  };

  @action
  setHubspotPreferences = value => {
    this.hubspotPreferences = value;
  };

  @action
  getHubspotData = async () => {
    try {
      const config = {
        url: URLS.getHubspotAccountDetails,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response?.data?.responseCode === 200) {
        this.setHubspotData(response.data);
      } else {
        this.setHubspotData("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  removeHubspotAccount = async () => {
    try {
      const config = {
        url: URLS.connectOrDisconnectHubspot,
        method: "DELETE"
      };
      let res = await makeApi(config);
      if (res?.data?.responseCode === 200) {
        await this.getHubspotData();
        toasterState.setToastMsg(
          "Hubspot account disconnected successfully",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Hubspot failed to remove", err);
    }
  };

  @action
  getContactsListView = async (cbk = () => {}) => {
    try {
      if (this.hasMoreFlag) {
        const config = {
          url: URLS.getHubspotContactsList,
          method: "POST",
          data: { offset: hubspotStore?.hubspotOffset, limit: 100 }
        };
        let res = await makeApi(config);
        if (res?.status === 200) {
          if (this.hubspotContactsList?.length <= 1) {
            this.setHubspotContactsList([
              {
                name: "All Contacts",
                id: "all",
                email: null
              },
              ...res?.data?.lists
            ]);
          } else {
            const hubspotContactsList = [...hubspotStore.hubspotContactsList];
            if (hubspotContactsList?.length > 1) {
              hubspotContactsList.splice(-1, 1);
            }
            this.setHubspotContactsList([
              ...hubspotContactsList,
              ...res?.data?.lists
            ]);
          }
          this.setHubspotOffset(res?.data?.offset);
          this.setHasMoreFlag(res?.data?.hasMore);
          cbk();
        }
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Hubspot Contacts listing error", err);
    }
  };

  @action
  getAllOwners = async () => {
    try {
      const config = {
        url: URLS.getAllHubspotOwners,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.hubspotOwnersList = res?.data;
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Hubspot Owners listing error", err);
    }
  };

  @action
  getAllHubspotFields = async () => {
    try {
      const config = {
        url: `${URLS.getAllHubspotFields}`,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.hubspotFields = res?.data;
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Hubspot Owners listing error", err);
    }
  };

  @action
  resetHubSpotList = () => {
    this.hubspotContactsList = [];
    this.hubspotOffset = "";
    this.hasMoreFlag = true;
  };
}

const hubspotStore = new HubspotStore();
export { hubspotStore };
