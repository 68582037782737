import React from "react";

export const SinglePurchaseLoader = () => {
  return (
    <>
      <span
        className="linear-background"
        style={{ width: "50%", marginBottom: "8px" }}
      />
      <span className="linear-background" style={{ width: "70%" }} />
    </>
  );
};

export default SinglePurchaseLoader;
