import React from "react";
import SequenceIcon from "Assets/png/Shared.png";

const NoSharedEntity = props => {
  const { title } = props;
  return (
    <div className="noSharedSeqContainer emptySequence">
      <img
        src={SequenceIcon}
        className="noSharedSeqIcon"
        alt="No Shared Sequence"
      />
      <span className="noSharedSequence">{title}</span>
    </div>
  );
};

export default NoSharedEntity;
export { NoSharedEntity as NoSharedSequence };
