import React from "react";
import { observable, action } from "mobx";
import GoogleBtnImg from "Assets/Settings/googleWhite.png";
import { observer } from "mobx-react";

class GmailMessagePopupState {
  @observable showPopup = false;
  @observable connectBtnAction = () => {};
  @observable popupValues = {
    title: "",
    subTitle: ""
  };

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setConnectBtnAction = val => {
    this.connectBtnAction = val;
  };

  @action
  setPopupValues = val => {
    this.popupValues = val;
  };
}

const gmailMessagePopupState = new GmailMessagePopupState();

const GmailMessagePopup = observer(() => {
  const {
    subTitle = "",
    title = "",
    additionalLink = ""
  } = gmailMessagePopupState?.popupValues || {};

  const PopupHeader = () => {
    return (
      <section className="popupHeader">
        <div className="title">
          {subTitle && (
            <i className="material-icons errorIcon">error_outline</i>
          )}
          {title}
        </div>
        <i
          className="material-icons closeIcon"
          onClick={() => gmailMessagePopupState?.setShowPopup(false)}
        >
          close
        </i>
        {subTitle && (
          <div
            className="subTitle"
            dangerouslySetInnerHTML={{ __html: subTitle }}
          />
        )}
        {additionalLink && (
          <div
            className="additionalLink"
            dangerouslySetInnerHTML={{ __html: additionalLink }}
          />
        )}
      </section>
    );
  };

  const PopupBody = () => {
    return (
      <div className="popupBody">
        <video
          style={{ width: "100%", height: "100%" }}
          preload="auto"
          muted={true}
          loop={true}
          autoPlay={true}
          poster="http://cdn.salesgear.io/images/poster/gmail_oauth_steps_poster.png"
          src="https://cdn.salesgear.io/videos/gmail_ouath_steps.mp4"
        />
      </div>
    );
  };

  const GmailConnectCTA = () => {
    return (
      <div className="popupFooter">
        <img
          id="googleAuthBtn"
          className="googleAuthBtn"
          src={GoogleBtnImg}
          alt="Sign in with Google"
          onClick={gmailMessagePopupState?.connectBtnAction}
        />
      </div>
    );
  };

  return gmailMessagePopupState?.showPopup ? (
    <>
      <div className="gmailMessagePopup">
        <div
          className={`gmailMessagePopupCont animated customzoomIn ${
            subTitle?.length > 0 ? "grid-row-4" : "grid-row-3"
          }`}
        >
          <PopupHeader />
          <PopupBody />
          <GmailConnectCTA />
        </div>
      </div>
      <div className="popupMask"></div>
    </>
  ) : (
    ""
  );
});

export default GmailMessagePopup;
export { GmailMessagePopup, gmailMessagePopupState };
