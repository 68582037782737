import React from "react";

const PricingTableLoader = props => {
  const { noOfColumns = 3 } = props;
  return Array.from({ length: noOfColumns || 3 }, (value, index) => (
    <div className="pricingCardLoader" key={`pricingPlanLoader${index + 1}`}>
      <div className="planInfo">
        <div className="imgCircleWrap" />
        <div className="planName linear-background" />
        <div className="planPrice planPriceLoader">
          <span className="dollar linear-background" />
          <span className="price linear-background" />
          <span className="seatText linear-background" />
        </div>
        <div className="currentPlanBtn linear-background" />
        <div className="noCardReqLoader">
          <div className="linear-background" />
        </div>
      </div>
      <div className="planFeatureCard">
        <p className="planCombineText linear-background" />
        <div className="planFeatureList">
          {Array.from(
            { length: index === 0 ? 10 : index === 1 ? 10 : 3 },
            (value, idx) => (
              <li className="planItem" key={`featureIndex${index + 1}${idx}`}>
                <span className="icon linear-background" />
                <p className="linear-background" />
              </li>
            )
          )}
        </div>
        <div className="creditsInfoLoader">
          <div className="dataHeading linear-background" />
          {Array.from({ length: 2 }, (value, idx) => (
            <div className="planItem" key={`${value}-${idx}`}>
              <span className="icon linear-background" />
              <div className="linear-background" />
            </div>
          ))}
        </div>
      </div>
    </div>
  ));
};

export default PricingTableLoader;
