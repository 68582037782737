import React from "react";
import { observer } from "mobx-react";
import { withRouter, NavLink, useRouteMatch } from "react-router-dom";
import Button from "./Button";
import { editAccountNotesState } from "Pages/Dashboard/Accounts/Notes/Main";

const TabNavigation = observer((props = {}) => {
  let { url } = useRouteMatch();

  const { tabList = [] } = props || {};

  const addNotesFn = () => {
    editAccountNotesState.setShowEdit(true);
    editAccountNotesState.setIsAddBtn(true);
    editAccountNotesState.setIsEdit(false);
  };

  const addNotes = {
    id: "addAccountNotes",
    name: "addAccountNotes",
    btnText: "Add Notes",
    type: "button",
    className: "addAccountNotesBtn",
    click: addNotesFn
  };

  return (
    <div className="tabNavigationBody">
      {(tabList?.length && (
        <ul className="tabNavigation">
          {tabList.map(item => {
            const { id = "", path = "", name = "" } = item || {};
            return (
              <NavLink
                className={`tabItem`}
                activeClassName="active"
                id={id}
                key={id}
                to={`${url}/${path}`}
              >
                {name}
              </NavLink>
            );
          })}
        </ul>
      )) ||
        ""}
      {!editAccountNotesState?.isAddBtn &&
        editAccountNotesState?.notesNewUser && (
          <div className="addNotesButton">
            <Button {...addNotes} />
          </div>
        )}
    </div>
  );
});

export default withRouter(TabNavigation);
export { TabNavigation };
