import React, { useState } from "react";
import InputTextBox from "Components/common/InputTextBox";
import { START_BUFFER_DURATION } from "Pages/Dashboard/settings/Calendar/Constants";
import SingleSelectDropdown from "./SingleSelectDropdown";
import Tooltip from "Components/common/CustomTooltip";

const unitMapper = {
  MINUTES: "Minutes",
  HOURS: "Hours",
  DAYS: "Days"
};

const StartBuffer = props => {
  const { inputValue, updateInputState = () => {} } = props || {};
  const [errMsg, setErrMsg] = useState("");
  const [showToolTip, setShowToolTip] = useState(false);

  const getVal = () => {
    const { label = "HOURS", value = 0 } = inputValue?.startBuffer || {};
    return { label: unitMapper[label], value: value?.toString() }; // toString() added as InputTextBox 'filled' class added based
    // on string length
  };

  const durationInputCbk = (key = "", newVal = "") => {
    const { label = "" } = inputValue?.startBuffer || {};
    let errorOccurred = validateData(label, newVal);
    if (errorOccurred) {
      updateInputState(key, newVal, null, errorOccurred);
    } else {
      updateInputState(key, newVal);
      setErrMsg("");
    }
  };

  const bufferIntervalCbk = (key = "", val = "", uniqueId = "") => {
    const { value = "" } = inputValue?.startBuffer || {};
    let errorOccurred = validateData(val, value);
    updateInputState(key, val, uniqueId, errorOccurred);
    if (!errorOccurred) {
      setErrMsg("");
    }
  };

  const validateData = (label = "", newVal = "") => {
    let errorOccurred = false;
    if (label.toLowerCase() === "hours") {
      if (+newVal > 8765) {
        errorOccurred = true;
        setErrMsg("Hours should not be greater than 8765");
      }
    } else if (label.toLowerCase() === "minutes") {
      if (+newVal > 525900) {
        errorOccurred = true;
        setErrMsg("Minutes should not be greater than 525900");
      }
    } else if (label.toLowerCase() === "days") {
      if (+newVal > 365) {
        errorOccurred = true;
        setErrMsg("Days should not be greater than 365");
      }
    }
    return errorOccurred;
  };

  const inputProps = {
    name: "startBuffDuration",
    id: "startBuffDuration",
    text: "Duration",
    type: "number",
    value: getVal().value || "",
    change: durationInputCbk,
    mandatory: false,
    err: errMsg,
    tabIndex: -1
  };

  const dropdownProps = {
    name: "units",
    type: "select",
    options: START_BUFFER_DURATION,
    text: "Units",
    id: "startBuffDurationInterval",
    value: getVal().label || "",
    mandatory: false,
    change: bufferIntervalCbk,
    timeZone: true,
    tabIndex: -1
  };

  return (
    <div className="startBeforeWrapper">
      <div className="labelTitle">
        <span>Invitees can't schedule before</span>
        <span
          className="workingHoursInfoIcon"
          onMouseOver={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
        >
          <i className="material-icons">info_outline</i>
          {showToolTip && (
            <Tooltip text="Specify the minimum amount of time in advance that a contact can book a meeting." />
          )}
        </span>
      </div>

      <div className="durationDetails">
        <InputTextBox {...inputProps} />
        <SingleSelectDropdown {...dropdownProps} />
      </div>
    </div>
  );
};

export { StartBuffer };
export default StartBuffer;
