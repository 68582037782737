import React from "react";
import SecondaryCheckbox from "Components/common/SecondaryCheckbox";
import CustomTooltip from "Components/common/CustomTooltip";

const TriggerProspect = props => {
  const { checked = false, change = () => {} } = props;

  const handleChange = value => {
    change("executeOnce", value);
  };

  const inputObj = {
    label: "Trigger only once per contact",
    id: "triggerProspect",
    cbk: handleChange,
    checked
  };

  return (
    <div className="TriggerProspect">
      <SecondaryCheckbox key={inputObj?.id} {...inputObj} />
      <span className="wrapInfoIconTp">
        <i className="material-icons infoIcon">info_outline</i>
        <CustomTooltip text="This trigger will fire only once for each contact that matches the below conditions." />
      </span>
    </div>
  );
};

export default TriggerProspect;
export { TriggerProspect };
