/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Link, withRouter, useLocation } from "react-router-dom";
import InputBox from "Components/Register/InputBox";
import Button from "Components/common/Button";
import { Utils } from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import makeApi, { URLS } from "Utils/apiURL";
import { captchaStoreValue } from "Stores/captcha";
import { loginValidate } from "Utils/commonAPI";

const LoginComponent = observer((props = {}) => {
  let location = useLocation();
  let queryParams = Utils.getQueryParams(location.search);
  let pluginUser = queryParams?.get("source")?.includes("pluginLogin");

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState({
    password: "",
    showPassword: false
  });
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loader, setLoader] = useState(false);

  const checkUserHasTeam = async () => {
    return await loginValidate();
  };

  const checkPluginLogin = () => {
    if (pluginUser) {
      Utils.mixpanelTrack("User Login", {
        userEmail,
        pageType: `Chrome Salesgear Login`,
        source: "PLUGIN"
      });
      props.history.push(`/pluginSuccess${location.search}`);
    }
  };

  const login = async token => {
    try {
      let isEmailValid = Utils.emailValidate(userEmail?.trim() || "");
      let isPasswordValid = userPassword?.password;
      if (isEmailValid && isPasswordValid) {
        const config = {
          url: URLS.loginUser,
          data: {
            email: userEmail?.trim() || "",
            password: userPassword?.password || "",
            token
          },
          method: "POST"
        };
        let response = await makeApi(config);
        Utils.mixpanelTrack(MP_EVENT.LOGIN_CLICKED, {
          pageType: "Login",
          userEmail
        });
        switch (response?.data?.success) {
          case "true":
            await checkUserHasTeam();
            checkPluginLogin();
            break;
          default:
            setErrMsg("Please check your email address or password");
            setLoader(false);
            break;
        }
      } else if (!isEmailValid) {
        setEmailErr("Please enter a valid email address");
        setLoader(false);
      } else if (!isPasswordValid) {
        setPasswordErr("Password is required");
        setLoader(false);
      }
    } catch (err) {
      console.error(err);
      setLoader(false);
      setErrMsg("Something went wrong");
    }
  };

  const submitForm = async e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      executeCaptcha(e);
    }
  };

  const emailChange = value => {
    setUserEmail(value?.trim()?.toLowerCase() || "");
    setEmailErr("");
    setErrMsg("");
  };

  const passwordChange = valueObj => {
    setUserPassword(valueObj);
    setPasswordErr("");
    setErrMsg("");
  };

  const executeCaptcha = e => {
    e && Utils.removeInputRequired(e);
    let isEmailValid = Utils.emailValidate(userEmail?.trim() || "");
    let isPasswordValid = userPassword?.password;
    if (isEmailValid && isPasswordValid) {
      setLoader(true);
      captchaStoreValue.executeCaptcha(validateCaptchaFromAPi);
    } else if (!isEmailValid) {
      setEmailErr("Please enter a valid email address");
      setLoader(false);
    } else if (!isPasswordValid) {
      setPasswordErr("Password is required");
      setLoader(false);
    }
  };

  const validateCaptchaFromAPi = async token => {
    token && login(token);
  };

  const inputBoxObjList = [
    {
      name: "workEmail",
      id: "workEmail",
      type: "email",
      value: userEmail,
      changeAction: emailChange,
      keyupAction: submitForm,
      errMsg: emailErr,
      label: "Work Email",
      isPassword: false,
      placeholder: `eg: john@salesgear.io`,
      focus: true
    },
    {
      name: "password",
      id: "password",
      type: "password",
      value: userPassword,
      changeAction: value =>
        passwordChange({ ...userPassword, password: value }),
      keyupAction: submitForm,
      errMsg: passwordErr || errMsg,
      label: "Password",
      className: "password",
      isPassword: true,
      placeholder: "Password",
      showPasswordAction: () =>
        setUserPassword({
          ...userPassword,
          showPassword: !userPassword?.showPassword
        })
    }
  ];

  useEffect(() => {
    Utils.mixpanelTrack(MP_EVENT.LOGIN_VISITED, {
      pageType: "Login"
    });
  }, []);

  return (
    <section
      className={`formInputBox ${loader ? "pointerUserSelectNone" : ""}`}
    >
      <h3>Welcome Back!</h3>
      <form autoComplete="on" id="loginform">
        {inputBoxObjList.map(item => (
          <InputBox {...item} key={item.id} />
        ))}
      </form>
      <div className="forgotPasswordLink">
        <Link to={`/forgotPassword?${queryParams?.toString()}`}>
          Forgot Password?
        </Link>
      </div>
      <Button
        id="loginBtn"
        name="loginBtn"
        btnText="Log In"
        className={
          loader
            ? "loading"
            : "loginBtn btn-new btn-rounded btn-orange btn-text btn-arrow"
        }
        loader={loader}
        type="button"
        click={e => {
          e.preventDefault();
          executeCaptcha(e);
        }}
      />
    </section>
  );
});

export default withRouter(LoginComponent);
