import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { dataSearchState } from "../state";
import CustomMultiSelect from "./CustomMultiSelect";

const CustomDropdownWithSubmenu = observer(props => {
  const { discoverPage = false } = props || {};
  const [options, setOptions] = useState([]);
  const [initialOptions, setInitialOptions] = useState([]);

  const constructIndustriesData = () => {
    const temp = dataSearchState?.industriesList;
    const arrList = [
      {
        id: "selectAll",
        value: "Select All",
        show: true
      }
    ];
    temp.map(item => {
      const { name = "", mapped_industries = [] } = item || {};
      arrList.push({
        id: name,
        value: name,
        show: true,
        showSubmenu: true,
        submenu: mapped_industries?.map(item => {
          return { name: item, value: item, show: true, id: item };
        })
      });
      setInitialOptions([...arrList]);
      setOptions(arrList);
    });
  };

  const handleSelectActionCbk = (checked, uId, name) => {
    let tempData = [...options];
    if (uId === "selectAll") {
      tempData = tempData.map(item => {
        item.checked = checked;
        item?.submenu?.length > 0 &&
          item.submenu.map(submenuItem => (submenuItem.checked = checked));
        return item;
      });
    } else {
      tempData = tempData.map(item => {
        const { id = "" } = item || {};
        if (id === uId) {
          item.checked = checked;
          item?.submenu?.length > 0 &&
            item.submenu.map(submenuItem => (submenuItem.checked = checked));
        }
        return item;
      });
      const isAllSelected =
        tempData?.length > 0 &&
        tempData.filter(
          selectedItem =>
            selectedItem?.id !== "selectAll" && selectedItem?.checked
        );
      const selectAllData = tempData[0]?.id === "selectAll";
      if (selectAllData) {
        tempData[0].checked = isAllSelected?.length === tempData?.length - 1;
      }
    }
    setOptions(tempData);
  };

  const handleSubmenuSelectActionCbk = (
    checked,
    uId,
    name,
    itemWithSubmenu
  ) => {
    let tempData = [...options];
    tempData = tempData.map(item => {
      const { id = "" } = item || {};
      if (id === itemWithSubmenu?.id) {
        item.submenu.map(submenuItem => {
          if (submenuItem?.id === uId) {
            submenuItem.checked = checked;
          }
        });
        const isAllSubmenuSelected = item.submenu.filter(
          selectedItem => selectedItem?.checked
        );
        item.checked = isAllSubmenuSelected?.length === item?.submenu?.length;
      }
      return item;
    });
    const isAllSelected =
      tempData?.length > 0 &&
      tempData.filter(
        selectedItem =>
          selectedItem?.id !== "selectAll" && selectedItem?.checked
      );
    tempData[0].checked = isAllSelected?.length === tempData?.length - 1;
    setOptions(tempData);
  };

  const searchFilterCbk = value => {
    const lowercasedValue = value?.toLowerCase();
    if (lowercasedValue === "") {
      resetDropdownToInitialState();
    } else {
      const filteredData =
        options?.length > 0 &&
        options?.filter(item => {
          if (item?.id !== "selectAll") {
            let val = item?.value
              ?.toString()
              ?.toLowerCase()
              ?.includes(lowercasedValue);
            item["show"] = val;
            item.submenu.length > 0 &&
              item.submenu.filter(subMenuItem => {
                let subMenuVal = subMenuItem?.value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(lowercasedValue);
                subMenuItem.show = subMenuVal;
                if (subMenuVal) {
                  item["show"] = subMenuVal;
                }
              });
            return item;
          }
        });
      setOptions(filteredData);
    }
  };

  const handleApplySelectionCbk = () => {
    let industries = [];
    let sectors = [];
    options?.length > 0 &&
      options.map(item => {
        if (item?.checked && item?.id !== "selectAll") {
          industries.push(item?.id);
        } else {
          item?.submenu?.length > 0 &&
            item.submenu
              .map(subItem => subItem?.checked && sectors.push(subItem?.id))
              .filter(Boolean);
        }
      });
    dataSearchState.setFilterComponentData({
      ...dataSearchState.filterComponentData,
      industry: industries,
      sector: sectors
    });
    resetDropdownToInitialState();
  };

  const resetFilterOptions = () => {
    const temp = [...options];
    temp.map(item => {
      item.show = true;
      item.checked = dataSearchState.filterComponentData?.[
        "industry"
      ]?.includes(item?.id);

      item.submenu?.length > 0 &&
        item.submenu.map(submenuItem => {
          submenuItem.show = true;
          submenuItem.checked =
            dataSearchState.filterComponentData?.["industry"]?.includes(
              item?.id
            ) ||
            dataSearchState.filterComponentData?.["sector"]?.includes(
              submenuItem?.id
            );
          return submenuItem;
        });
      return item;
    });
  };

  const resetOptions = () => {
    const temp = initialOptions;
    initialOptions.map(item => {
      item.checked = false;
      item.submenu?.length > 0 &&
        item.submenu.map(submenuItem => (submenuItem.checked = false));
      return item;
    });
    setOptions(temp);
  };

  const resetDropdownToInitialState = () => {
    const temp = initialOptions;
    initialOptions.map(item => {
      item.show = true;
      item.submenu?.length > 0 &&
        item.submenu.map(submenuItem => (submenuItem.show = true));
      return item;
    });
    setOptions(temp);
  };

  useEffect(() => {
    constructIndustriesData();
  }, [dataSearchState.industriesList]);

  useEffect(() => {
    dataSearchState.getIndustriesData();
  }, []);

  useEffect(() => {
    if (discoverPage) {
      resetFilterOptions();
    }
  }, [dataSearchState.filterComponentData]);

  useEffect(() => {
    dataSearchState.clearAll && resetOptions();
    dataSearchState.setClearAll(false);
  }, [dataSearchState.clearAll]);

  return (
    <CustomMultiSelect
      options={options}
      handleSelectActionCbk={handleSelectActionCbk}
      handleSubmenuSelectActionCbk={handleSubmenuSelectActionCbk}
      handleApplySelectionCbk={handleApplySelectionCbk}
      searchFilterCbk={searchFilterCbk}
      hasSubMenuAccordion={true}
      resetDropdownToInitialState={resetDropdownToInitialState}
      resetFilterOptions={resetFilterOptions}
      {...props}
    />
  );
});

export { CustomDropdownWithSubmenu };
export default CustomDropdownWithSubmenu;
