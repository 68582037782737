/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import taskStoreData from "Stores/taskData";
import { observer } from "mobx-react";
import { ReactComponent as LinkedinIcon } from "Assets/svg/linkedin.svg";
import { ReactComponent as TaskIcon } from "Assets/svg/task.svg";
import { ReactComponent as PhoneIcon } from "Assets/svg/call.svg";
import { ReactComponent as CustomTaskIcon } from "Assets/svg/customTask.svg";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";

const FilterComponentTasks = props => {
  const {
    filterLoadingFn = () => {},
    setActiveTab = () => {},
    tableLoadingFn = () => {},
    cbk = () => {},
    filterLoading = true,
    activeTab = ""
  } = props || {};
  const [taskFilteredData, setTaskFilteredData] = useState([]);

  const getEmailCount = (manualEmail, email) => {
    let count = 0;
    if (typeof manualEmail != "undefined") {
      count += parseInt(manualEmail);
    }
    if (typeof email != "undefined") {
      count += parseInt(email);
    }
    return count;
  };

  const constructFilterRenderData = filteredDataArray => {
    filterLoadingFn(false);
    // setActiveTab("total");
    if (filteredDataArray && filteredDataArray.length > 0) {
      const filterData = filteredDataArray.reduce(
        (obj, item) => Object.assign(obj, { [item.taskType]: item.count }),
        {}
      );
      const {
        email = 0,
        linkedin = 0,
        manualEmail = 0,
        phone = 0,
        customTask = 0
      } = filterData;
      cbk(filterData);
      setTaskFilteredData([
        {
          name: "All",
          id: "total",
          iconClass: "person_add",
          count: filterData.total,
          iconType: "svg",
          svg: <TaskIcon />
        },
        {
          name: "Manual Email",
          id: "email",
          iconClass: "email",
          count: getEmailCount(manualEmail, email),
          iconType: "icon"
        },
        {
          name: "LinkedIn Task",
          id: "linkedin",
          iconClass: "reply",
          count: linkedin ? linkedin : 0,
          iconType: "svg",
          svg: <LinkedinIcon />
        },
        {
          name: "Phone Call",
          id: "phone",
          iconClass: "phone",
          count: phone ? phone : 0,
          iconType: "svg",
          svg: <PhoneIcon />
        },
        {
          name: "Custom Task",
          id: "customTask",
          iconClass: "customTask",
          count: customTask ? customTask : 0,
          iconType: "svg",
          svg: <CustomTaskIcon />
        }
      ]);
    }
  };

  const filterAction = (filterId = "", count = 0) => {
    Utils.mixpanelTrack(MXP_EVENT.TASK_FILTER_CLICKED, {
      taskCount: count,
      taskType: filterId,
      pageType: "Tasks"
    });
    taskStoreData.setFilter(filterId);
    setActiveTab(filterId);
    cbk(count);
    tableLoadingFn(true);
    const dateFilterValue = taskStoreData?.dateFilterValue || null;
    const statusFilterValue = taskStoreData?.statusFilterValue || null;
    taskStoreData.getTaskData(
      "",
      filterId?.toLowerCase() === "total" ? null : filterId,
      null,
      filterId?.toLowerCase() === "total" ? true : null,
      null,
      20,
      dateFilterValue || null,
      statusFilterValue
    );
  };

  useEffect(() => {
    constructFilterRenderData(taskStoreData.taskFilterData);
  }, [taskStoreData.taskFilterData]);

  useEffect(() => {
    taskStoreData.getTaskFilterData();
  }, []);

  return (
    <div className="filterWrapper seq-contact-list">
      {!filterLoading && taskFilteredData?.length
        ? taskFilteredData.map((value, key) => {
            return (
              <div
                className={`filterCont seq-contact-card ${
                  activeTab === value.id ? "active" : ""
                }`}
                key={key}
                id={value.id}
                onClick={e => {
                  filterAction(value.id, value.count);
                }}
              >
                <div className="filterOption">
                  <div className="filterValueCont">
                    <span
                      className={`filterValue ${
                        activeTab === value.id ? "active" : ""
                      }`}
                    >
                      {value.count}
                    </span>
                    <span className="filterLabel">{value.name}</span>
                  </div>
                  {value.iconType === "svg" && (
                    <span className={`svgIcon ${value.iconClass}`}>
                      {value.svg}
                    </span>
                  )}
                  {value.iconType !== "svg" && (
                    <i className="material-icons-outlined ">
                      {value.iconClass}
                    </i>
                  )}
                </div>
              </div>
            );
          })
        : Array.from({ length: 5 }, (value, index) => (
            <li
              className="contactfilter linear-background"
              key={`seqContactsNavLoader${index}`}
            ></li>
          ))}
    </div>
  );
};

export { FilterComponentTasks };
export default observer(FilterComponentTasks);
