import React, { useState } from "react";
import Constant from "Static/constant";
import DropDownMenu from "Components/common/DropDownMenu";

const TaskTypeDropdown = props => {
  const {
    handleChange = () => {},
    defaultValue = "",
    label = "",
    id = "",
    stageType = "",
    labelClassName = "",
    options: defaultOptions = []
  } = props || {};

  const [showDropdown, setDropdown] = useState(false);

  const handleAction = (id, item) => {
    setDropdown(false);
    handleChange(item);
  };

  const getOptions = () => {
    const filteredOptions =
      stageType?.toLowerCase() === "linkedinauto"
        ? [...defaultOptions].filter(
            option => option?.id !== "interactWithPost"
          )
        : defaultOptions;
    return filteredOptions.map(item => {
      return { ...item, method: handleAction };
    });
  };

  return (
    (defaultOptions?.length && (
      <div className="taskTypeDropdown">
        <>
          <label
            className={labelClassName}
            htmlFor={id}
            onClick={() => setDropdown(!showDropdown)}
          >
            {label}
          </label>
          <div
            className="activeDropdownValue"
            onClick={() => setDropdown(!showDropdown)}
          >
            <span className="activeValue">
              {Constant.taskSubject[defaultValue?.toLowerCase()]}
            </span>
            <i className="material-icons-outlined">expand_more</i>
          </div>
        </>
        {showDropdown && (
          <>
            <DropDownMenu
              options={getOptions()}
              referenceId="taskTypeDropdown"
            />
            <div className="dropdownMask" onClick={() => setDropdown(false)} />
          </>
        )}
      </div>
    )) ||
    ""
  );
};

export default TaskTypeDropdown;
export { TaskTypeDropdown };
