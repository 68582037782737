import React from "react";
import Button from "Components/common/Button";

const FormActionButton = props => {
  const {
    className = "",
    saveBtnText = "Save",
    backBtnText = "Back",
    loader = false,
    disabled = false,
    cancel = () => {},
    save = () => {}
  } = props;

  const cancelConfigure = () => cancel();
  const updateConfigure = () => save();

  const buttonList = [
    {
      id: "cancelConfigure",
      name: "cancelConfigure",
      btnText: backBtnText,
      type: "button",
      className: `cancelConfigure`,
      method: cancelConfigure,
      loading: false
    },
    {
      id: "updateConfigure",
      name: "updateConfigure",
      btnText: saveBtnText,
      type: "button",
      className: `updateConfigure ${loader ? "loading" : ""} ${className}`,
      method: updateConfigure,
      loading: loader,
      disabled: disabled
    }
  ];
  return (
    <div className="configFooter">
      {buttonList?.map(item => {
        const {
          id = "",
          name = "",
          btnText = "",
          type = "",
          className = "",
          method = () => {},
          loading = false,
          disabled = false
        } = item || {};
        return (
          <Button
            key={id}
            id={id}
            name={name}
            btnText={btnText}
            type={type}
            className={className}
            click={method}
            loader={loading}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default FormActionButton;
export { FormActionButton };
