/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { observer } from "mobx-react";
import Routes from "Routes/routes";
import ReCaptcha from "Components/common/captcha";
import { captchaStoreValue } from "Stores/captcha";
import { userDetail } from "Stores/userDetail";
import { INTERCOM_CHAT_API_KEY, WEBINAR_EVENT_DATE } from "Static/constant";
import { DateUtils } from "Utils/DateUtils";
import "./Utils/chromeExtValidator";
import {
  initIntercomChat,
  initIntercomShowHideOption
} from "Utils/Scripts/intercom";
import EventRegistrationBanner from "Components/common/Banner/EventRegistrationBanner";

const App = observer(() => {
  let isEventExpired = DateUtils.isEventExpired(WEBINAR_EVENT_DATE);

  const [eventRegistered, setEventRegistered] = useState(
    localStorage.getItem("outboundStrategyStartupMultiple") === "true" ||
      userDetail.eventRegistered ||
      false
  );

  localStorage.removeItem("newUserSignUp");

  const loadIntercomChat = () => {
    const {
      name = "",
      email = "",
      userLoggedIn = "",
      firstName = "",
      lastName = "",
      intercomEncryptKey = ""
    } = userDetail?.userInfo || {};
    if (userLoggedIn !== "") {
      let data = {
        app_id: INTERCOM_CHAT_API_KEY,
        custom_launcher_selector: "#my_custom_link"
      };
      if (email) {
        data = {
          ...data,
          name,
          email,
          first_name: firstName || lastName,
          user_hash: intercomEncryptKey?.toLowerCase() || ""
        };
      }
      const {
        inFreePlan = false,
        trialEnabled = false,
        email: hasEmailFlag = "",
        createTs = ""
      } = userDetail?.userFeatureFlag || {};
      if (hasEmailFlag) {
        const userPlanType =
          inFreePlan && !trialEnabled
            ? "Free User"
            : !inFreePlan && !trialEnabled
            ? "Paid User"
            : trialEnabled && !inFreePlan
            ? "Trial user"
            : "--";
        data.plan = userPlanType;
        data.signup_date = createTs;
      }
      window.intercomSettings = data;
      initIntercomChat(INTERCOM_CHAT_API_KEY);
      initIntercomShowHideOption();
    }
  };

  useEffect(() => {
    loadIntercomChat();
  }, [userDetail.userFeatureFlag, userDetail?.userInfo]);

  return (
    <div
      className={`mainContent  ${
        !isEventExpired && !eventRegistered ? "showEventBanner" : ""
      }`}
    >
      {captchaStoreValue.showCaptcha && <ReCaptcha />}
      {!isEventExpired && !eventRegistered && (
        <EventRegistrationBanner setEventRegistered={setEventRegistered} />
      )}
      <Router>
        <Routes />
      </Router>
    </div>
  );
});

export default App;
