import React from "react";
import NoSequenceIcon from "Assets/Sequence/sequence_no_data.png";
import { ReactComponent as SequenceIcon } from "Assets/svg/sequence.svg";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import FeatureDemoVideo from "Components/common/FeatureDemoVideo";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const NoSequenceComponent = (props = {}) => {
  const {
    showSequenceProp,
    newSequenceBtnAction,
    showUpgrade = "dalse"
  } = props;

  const {
    noOfSequencesEligible = 0,
    noOfSequencesUsed = 0,
    inFreePlan = false,
    planName = ""
  } = userDetail.userFeatureFlag || {};

  return (
    <div className="emptySequence">
      <img src={NoSequenceIcon} alt="" />
      <h6>Create your first sequence</h6>
      {!showSequenceProp && (
        <div className="createBtn">
          <button
            id="newSequence"
            name="newSequence"
            type="button"
            onClick={newSequenceBtnAction}
          >
            <SequenceIcon />
            <span className="btn-name">New Sequence</span>
          </button>
          {showUpgrade && (
            <span className="freemiumIconTooltip">
              <UpgradeIconTooltip
                plan={PaywallUtils.getPlanName(planName, inFreePlan)}
                type="newSequence"
                enableTooltip={true}
                alignTooltip="btmRight"
                showIcon={true}
              />
            </span>
          )}
        </div>
      )}
      <FeatureDemoVideo
        name={"Create Sequence"}
        linkText={`How to Create sequence on Salesgear`}
        type={"sequence"}
      />
    </div>
  );
};

export default NoSequenceComponent;
export { NoSequenceComponent };
