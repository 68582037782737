import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { ReactComponent as PhoneDeleteIcon } from "Assets/svg/phoneDelete.svg";
import DialerDigits from "./DialerDigits";
import { numberWithLetter } from "Static/constant";

const DialerKeypadOnCalling = props => {
  const dialIvrInputBox = useRef();
  const [ivrIpPhone, setIvrIpPhone] = useState("");
  const { activeConnection = "" } = props || {};

  const dialInputKeyCall = value => {
    let numStr = value?.[value?.length - 1]?.toString()?.trim();
    numStr && activeConnection.sendDigits(numStr);
  };

  const changeValue = e => {
    let value = e.currentTarget.value.trim();
    setIvrIpPhone(value);
    dialInputKeyCall(value);
  };

  const getDigit = value => {
    let numStr = value?.toString()?.trim();
    setIvrIpPhone(ivrIpPhone + numStr);
    dialIvrInputBox.current.focus();
    numStr && activeConnection.sendDigits(numStr);
  };

  const clearNumber = () => {
    if (ivrIpPhone && ivrIpPhone.trim() && ivrIpPhone.length > 0) {
      let value = ivrIpPhone.trim().slice(0, ivrIpPhone.trim().length - 1);
      setIvrIpPhone(value.trim());
      dialIvrInputBox.current.focus();
    }
  };

  useEffect(() => {
    return () => {
      setIvrIpPhone("");
    };
  }, []);

  return (
    <div className="dialerKeyPadOnCalling">
      <div className="inputRemoveBtnWrap">
        <input
          id="dialIvrInputBox"
          ref={dialIvrInputBox}
          type="text"
          placeholder="Enter Number to call"
          value={ivrIpPhone}
          onChange={e => changeValue(e)}
          autoFocus
          required
        />
        <div className="removeBtn" onClick={clearNumber}>
          <PhoneDeleteIcon />
        </div>
      </div>
      <div className="keypadSection">
        {numberWithLetter.map(key => {
          return (
            <DialerDigits
              key={`phoneKeyTouchPad${key.number}`}
              number={key.number}
              letter={key.letter}
              cbk={getDigit}
            />
          );
        })}
      </div>
    </div>
  );
};

export default observer(DialerKeypadOnCalling);
export { DialerKeypadOnCalling };
