const EditorUtils = {};

EditorUtils.containsBR = (element = "", tagName = "BR") => {
  if (element) {
    if (element?.tagName === tagName) {
      return true;
    }

    for (let i = 0; i < element.childNodes.length; i++) {
      const childNode = element.childNodes[i];
      if (
        childNode.nodeType === Node.ELEMENT_NODE &&
        EditorUtils.containsBR(childNode)
      ) {
        return true;
      }
    }
  }
  return false;
};

EditorUtils.replaceEmptyContentHtmlWithBreakTag = (text = "") => {
  if (text) {
    let tempDom = document.createElement("div");
    tempDom.setAttribute("id", "replaceEmptyBlockWithBreak");
    tempDom.innerHTML = text || "";
    let contentList = tempDom?.querySelectorAll(
      "#replaceEmptyBlockWithBreak .ql-editor > *"
    );

    let updatedContentDom = "";
    if (contentList?.length > 0) {
      for (let i in contentList) {
        if (contentList[i]?.innerHTML) {
          if (
            !contentList[i]?.innerText?.trim() &&
            !EditorUtils.containsBR(contentList[i], "BR") &&
            !contentList[i]?.querySelectorAll("img")
          ) {
            contentList[i].innerHTML = "<br />";
          }
          updatedContentDom += contentList[i]?.outerHTML;
        }
      }
    }

    let contentHtml = tempDom?.querySelector(
      "#replaceEmptyBlockWithBreak .ql-editor"
    );
    if (updatedContentDom) {
      contentHtml.innerHTML = updatedContentDom || "";
      return contentHtml?.outerHTML;
    }
    return text;
  }
  return text;
};

export { EditorUtils };
