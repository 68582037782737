import React, { useState } from "react";
import { observer } from "mobx-react";
import { SEQ_BACKGROUND_MSG } from "Model/model";
import SequenceUtils from "Utils/SequenceUtils";
import PaywallUtils from "Utils/PaywallUtils";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import CustomTooltip from "Components/common/CustomTooltip";

const CustomToggle = observer(props => {
  const {
    status = "",
    seqData = {},
    showSequenceToggleBtnLoad = false,
    sequenceToggleBtn,
    checkBoxToggleAction = () => {}
  } = props || {};

  const [showToggleTp, setToggleTp] = useState(false);

  return (
    <div
      className={`rightStatus toogleSequence ${
        status === "" ? "activeStatus" : "inactiveStatus"
      }`}
    >
      <div className={"statusText"}>
        {status === "" ? "Active" : "Inactive"}
      </div>
      <div
        className={`toogleButton ${showSequenceToggleBtnLoad ? "loading" : ""}`}
        onMouseOver={() => setToggleTp(true)}
        onMouseLeave={() => setToggleTp(false)}
      >
        <input
          type="checkbox"
          className="toggleCheckbox"
          checked={sequenceToggleBtn}
          onChange={checkBoxToggleAction}
        />
        <div className="knobs" />
        <div className="layer" />
      </div>
      {showToggleTp && (
        <CustomTooltip
          text={
            seqData?.background?.status && showSequenceToggleBtnLoad
              ? SEQ_BACKGROUND_MSG?.[
                  seqData?.background?.status?.toLowerCase()
                ] || ""
              : !sequenceToggleBtn
              ? "Activate Sequence"
              : "Disable sequence"
          }
        />
      )}
    </div>
  );
});

const ActivatedSequence = observer(props => {
  const {
    status = "",
    seqData = {},
    showSequenceToggleBtnLoad = false,
    sequenceToggleBtn,
    inFreePlan = false,
    planName = "",
    steps = {},
    taskEnabled = false
  } = props || {};

  return (
    <div
      className={`activateSequenceWrapper ${
        seqData?.background?.status && showSequenceToggleBtnLoad
          ? "activated"
          : "pauseResume"
      } ${status === "" ? "activeStatus" : "inactiveStatus"}`}
    >
      <div className="leftToggleContent">
        <div className="toggleText">
          {status?.toLowerCase() === "paused"
            ? "Activate the toggle to start scheduling contacts for the steps below."
            : "Contacts added to the sequence are being scheduled for the steps below."}
        </div>
        {steps &&
          SequenceUtils.hasTask(steps) &&
          !taskEnabled &&
          sequenceToggleBtn && (
            <div className="wrapThunderIcon">
              <UpgradeIconTooltip
                plan={PaywallUtils.getPlanName(planName, inFreePlan)}
                type="pauseResumeSequence"
                enableTooltip={true}
                alignTooltip="topLeft"
              />
            </div>
          )}
      </div>
      <CustomToggle {...props} />
    </div>
  );
});

export { ActivatedSequence };
export default ActivatedSequence;
