import React from "react";
import { observer } from "mobx-react";
import InputBox from "Components/Register/InputBox";
import { salesforceConnectedData } from "./SalesforceConnectedData";

const APILimit = observer(props => {
  const { apiLimitErr = "", setApiLimitErr = () => {} } = props || {};
  const { apiCallsUsed = 0 } = salesforceConnectedData?.salesForceData || {};

  const handleInputChange = value => {
    salesforceConnectedData.setApiLimitPerDay(value);
  };

  const handleInputBlur = value => {
    const updatedValue = Number(value);
    if (updatedValue < 1 || updatedValue > 15000) {
      setApiLimitErr("Max limit can be 15000 only");
    } else {
      setApiLimitErr("");
    }
  };

  const inputObject = {
    name: "apiLimitTextBox",
    id: "apiLimitTextBox",
    type: "text",
    value: salesforceConnectedData?.apiLimitPerDay || "",
    changeAction: handleInputChange,
    blurAction: handleInputBlur,
    label: "",
    text: "",
    minValue: 1,
    maxValue: 15000,
    placeholder: "Enter your API Limit",
    errMsg: apiLimitErr
  };

  return (
    <div className="APILimitWrapper">
      <h5 className="headingText">API Limit</h5>
      <div className="globalAPIUsageWrapper">
        <div className="headingSubText">
          Number of API calls that can be made should be between 1 and 15000
        </div>
        <div className="apiLimitInputBoxWrap">
          <InputBox className="apiLimitInputBox" {...inputObject} />
          <div className="apiValues">{`(${apiCallsUsed || 0} out of ${
            salesforceConnectedData?.apiLimitPerDay
          })`}</div>
        </div>
      </div>
    </div>
  );
});

export { APILimit };
export default APILimit;
