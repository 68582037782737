import React from "react";
import { observer } from "mobx-react";
import { Link, useHistory } from "react-router-dom";
import { settingsStoreData } from "Stores/settingsData";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { REQUEST_DEMO_CALENDAR_LINK } from "Static/constant";
import Utils from "Utils/utils";
import InboxUtils from "Utils/InboxUtils";
import { emailPreviewContentPopupState } from "Components/PreviewPopup/EmailPreviewContentPopup";
import { toasterState } from "Components/common/toaster";
import Button from "Components/common/Button";
import { openCalendarPopup, openDialerCard } from "../commonUtils";
import { ReactComponent as SalesgearLogoIcon } from "./Icons/salesgearLogo.svg";
import { iconList } from "./icons";
import { MemoizedProfile } from "./Profile";
import "./styles.scss";

const Header = observer(() => {
  const history = useHistory();

  const btnProps = {
    id: "requestDemoBtn",
    name: "requestDemoBtn",
    btnText: "Request Demo",
    icon: false,
    iconName: "",
    type: "button",
    className: "globalRequestDemoBtn",
    loader: false,
    click: () => handleHeaderIconsClick("requestDemo")
  };

  const openOneOffEmail = async () => {
    if (Utils.isAccountConnected()) {
      let fromEmail = InboxUtils.getPrimaryInboxEmailWithoutSmtp();
      if (fromEmail) {
        let bccEmail = [];
        let toEmail = "";
        if (
          settingsStoreData?.settingsData?.insertBcc &&
          settingsStoreData?.settingsData?.bccEmail?.length > 0
        ) {
          bccEmail.push({ email: settingsStoreData?.settingsData?.bccEmail });
        }
        let contentDom = Utils.insertSignature(fromEmail, true);
        let validatedEmailObject = {};
        let emailStatusResponse = await Utils.validateEmailAddressStatus([
          ...Utils.getEmailList({ bcc: bccEmail }),
          toEmail
        ]);
        if (emailStatusResponse && Object.keys(emailStatusResponse)?.length) {
          validatedEmailObject = Utils.addEmailStatus(
            {
              bcc: bccEmail || [],
              to: [{ email: toEmail }]
            },
            emailStatusResponse
          );
        }
        emailPreviewContentPopupState.setPopupValues({
          ...emailPreviewContentPopupState.popupValues,
          pageType: "globalHeader",
          isFromGlobalHeader: true
        });
        let emailDataObj = {
          ...emailPreviewContentPopupState.popupValues.emailData,
          from: fromEmail,
          ...validatedEmailObject,
          content: Utils.addEmptySpaceAboveThreadEmail(contentDom)
        };
        let localStateObj = {
          ...emailPreviewContentPopupState.editorInputValue.localState,
          showBccInputAddress: bccEmail?.length > 0,
          editorInsertCounter: 0
        };
        let tempObj = {
          emailType: "ONE_OFF_EMAIL",
          ...emailPreviewContentPopupState.popupValues,
          ...{ emailData: emailDataObj },
          ...{ localState: localStateObj }
        };
        emailPreviewContentPopupState.setEditorInputValue(tempObj);
        emailPreviewContentPopupState.setshowEditorComposePopup(true);
        emailPreviewContentPopupState.setShowPopup(true);
      } else {
        toasterState.setToastMsg(
          "Oops! you cannot send one-off emails from an inbox connected via SMTP.",
          "fail"
        );
      }
    } else {
      Utils.showAddAccountPopup(
        { history },
        "Please connect your email account to send emails",
        () => emailPreviewContentPopupState.setResetPopup()
      );
    }
  };

  const handleHeaderIconsClick = (name = "") => {
    switch (name) {
      case "phoneCall": {
        Utils.mixpanelTrack(MXP_EVENT.CALL_CLICK_HEADER, {
          pageType: PAGE_TYPE.header
        });
        openDialerCard("globalHeader");
        break;
      }
      case "email": {
        Utils.mixpanelTrack(MXP_EVENT.EMAIL_CLICK_HEADER, {
          pageType: PAGE_TYPE.header
        });
        openOneOffEmail();
        break;
      }
      case "calendar": {
        Utils.mixpanelTrack(MXP_EVENT.CALENDER_CLICK_HEADER, {
          pageType: PAGE_TYPE.header
        });
        openCalendarPopup(history, "globalHeader");
        break;
      }
      case "requestDemo": {
        Utils.mixpanelTrack(MXP_EVENT.REQUEST_DEMO_HEADER, {
          pageType: PAGE_TYPE.header
        });
        Utils.windowRedirect(REQUEST_DEMO_CALENDAR_LINK, "_blank", true);
        break;
      }
      case "pricing": {
        Utils.mixpanelTrack(MXP_EVENT.PRICING_CLICK_HEADER, {
          pageType: PAGE_TYPE.header
        });
        history.push("/pricing");
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <header className="sgHeader">
      <div className="leftSection">
        <Link to="/">
          <div className="sideBarlogo">
            <SalesgearLogoIcon />
          </div>
        </Link>
        <div id="chmlnSearch"></div>
      </div>
      <div className="rightSection">
        <Button {...btnProps} />
        {iconList.map(item => {
          const { name = "", icon = <></>, title = "" } = item || {};
          return (
            <div
              className={`itemIcon ${name}`}
              key={name}
              onClick={() => handleHeaderIconsClick(name)}
              title={title}
            >
              {icon}
            </div>
          );
        })}
        <MemoizedProfile />
      </div>
    </header>
  );
});

export { Header };
export default Header;
