import React from "react";

const InputBox = props => {
  const {
    name = "",
    id = "",
    type = "",
    placeholder = "",
    value = "",
    className = "",
    errMsg = "",
    label = "",
    isPassword = false,
    focus = false,
    errMsgClass = "",
    descText = "",
    minValue = "",
    maxValue = "",
    showPasswordAction = () => {},
    changeAction = () => {},
    keyupAction = () => {},
    blurAction = () => {},
    inputBoxRef = null,
    required = false,
    readOnly = false,
    labelClassName = ""
  } = props || {};

  return (
    <div className="inputBoxSection">
      <label className={labelClassName} htmlFor={name}>
        {label}
        {required && <sup>*</sup>}
      </label>
      <div className={`${isPassword ? "wrapInputIcon" : "inputBox"}`}>
        <input
          id={id}
          name={name}
          type={isPassword && value?.showPassword ? "text" : type}
          value={isPassword ? value?.password : value}
          onChange={e => changeAction(e?.currentTarget?.value, id)}
          onKeyUp={e => keyupAction(e, id)}
          onBlur={e => blurAction(e?.currentTarget?.value, id)}
          placeholder={placeholder}
          required
          className={className}
          autoFocus={focus}
          min={minValue}
          max={maxValue}
          autoComplete={name}
          ref={inputBoxRef}
          readOnly={readOnly}
        />
        {isPassword && (
          <span
            className="material-icons-outlined"
            onClick={() => showPasswordAction()}
          >
            {value?.showPassword ? "visibility" : "visibility_off"}
          </span>
        )}
        {descText && <span className="descLabelText">{descText}</span>}
      </div>
      {errMsg && (
        <span
          className={`errMsgText ${errMsgClass}`}
          dangerouslySetInnerHTML={{
            __html: errMsg || ""
          }}
        />
      )}
    </div>
  );
};

export default InputBox;
