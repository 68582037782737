import React, { useEffect } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import ContactAdminIcon from "Assets/png/contactAdmin.png";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";

class MessagePopupState {
  @observable showPopup = false;
  @observable message = "";
  @observable callback = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setMessage = val => {
    this.message = val;
  };

  @action
  setCallback = val => {
    this.callback = val;
  };
}

const messagePopupState = new MessagePopupState();

const MessagePopup = observer(() => {
  const handleAction = event => {
    event && Utils.preventDefaultFn(event);
    messagePopupState.setMessage("");
    messagePopupState.setShowPopup(false);
    if (messagePopupState?.callback) {
      messagePopupState.setCallback(false);
      upgradePopupState.setPopup(true);
    }
  };

  return (
    (messagePopupState.showPopup && (
      <>
        <div className="adminPopupMask" />
        <div className="contactAdminPopup animated customzoomIn">
          <div className="adminPopupWrapper">
            <div className="popupContent">
              <div
                className="popupTitle"
                dangerouslySetInnerHTML={{
                  __html:
                    messagePopupState.message ||
                    "Please contact your account administrator to upgrade" ||
                    ""
                }}
              />
              <div className="contentBody">
                <div
                  className="okBtn btn"
                  onClick={event => handleAction(event)}
                >
                  Ok
                </div>
              </div>
            </div>
            <div className="popupImg">
              <img
                src={ContactAdminIcon}
                alt="Contact your admin"
                className="contactAdminImg"
              />
            </div>
          </div>
        </div>
      </>
    )) ||
    ""
  );
});

export default MessagePopup;
export { MessagePopup, messagePopupState };
