/* eslint-disable array-callback-return */
import { decorate, observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";

class PricingStore {
  paymentFormObj = {
    address1: "",
    city: "",
    state: "",
    country: "",
    phone: "",
    seats: null,
    annual: false,
    annualDiscount: null,
    price: null,
    annualPrice: null,
    initTeamSeat: null
  };

  // observable
  activePlanData = [];
  paymentFormInfo = this.paymentFormObj;
  selectedPlan = null;

  // action
  setFilter = value => {
    this.filter = value;
  };

  setTotalContactValue = value => {
    this.totalContacts = value;
  };

  setSelectedPlan = plan => {
    if (plan?.length > 0) {
      plan.map(item => {
        if (item.name === "PRO") {
          this.selectedPlan = item;
          const { price, annualPrice, annualDiscount } = item;
          this.setPaymentFormInfo({
            price,
            annualPrice,
            annualDiscount
          });
        }
      });
    }
  };

  setPaymentFormInfo(obj) {
    this.paymentFormInfo = { ...this.paymentFormInfo, ...obj };
  }

  getActivePlanDetails = async () => {
    try {
      let config = {
        url: URLS.getActiveSubscriptionPlans,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res && res.data && res.data.length > 0) {
        this.activePlanData = res.data;
        this.setSelectedPlan(res.data);
      } else Utils.showApiFailureResponse(res);
    } catch (err) {
      console.error("Get ActivePlan Details error ", err);
    }
  };

  payment = async (data, cbk) => {
    try {
      const config = {
        url: URLS.payment ? URLS.payment : "",
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (res?.data?.success) {
        cbk(true);
      } else {
        cbk(false, res?.data);
      }
    } catch (err) {
      console.error("Payment Failure", err);
    }
  };
}

decorate(PricingStore, {
  activePlanData: observable,
  paymentFormInfo: observable,
  setFilter: action,
  setTotalContactValue: action,
  getActivePlanDetails: action,
  setPaymentFormInfo: action,
  payment: action
});

export default new PricingStore();
