import React from "react";
import {ReactComponent as DeleteSvg } from "Assets/svg/bin.svg";

const DeleteIcon = props => {
  const { action, id, itemValue, title, spaceRight = 0 } = props;

  return (
    <span
      className="deleteActionIcon"
      title={title}
      onClick={e => {
        action(e, id, itemValue);
      }}
      style={{marginRight: spaceRight + 'px'}}
    >
      <DeleteSvg />
    </span>
  );
};

export default DeleteIcon;
export { DeleteIcon };
