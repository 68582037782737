import React from "react";
import { observer } from "mobx-react";
import { writingAssistantStoreData } from "Stores/WritingAssistantData";

export const EmptySubjectLine = observer(props => {
  const { action = () => {}, loader = false } = props;
  return (
    writingAssistantStoreData.isInvalidSubjectLine && (
      <div className="dropdownList emptySubjectLine">
        Unable to generate recommended subject lines for your email content.{" "}
        <span
          className="reloadBtnLin"
          onClick={() => (!loader ? action("tryagain") : () => {})}
        >
          Please try again.
        </span>
      </div>
    )
  );
});

export default EmptySubjectLine;
