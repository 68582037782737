/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom";
import "./app/Utils/Scripts/mixPanel";
import "./index.scss";
import "./app/Utils/utils";
import App from "./app/App";
import Utils from "./app/Utils/utils";

Utils.checkAddNonceAndCspTag();

ReactDOM.render(<App />, document.getElementById("root"));
