/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react";
import PopupHeader from "./StepsPopupHeader";
import PopupContent from "./StepsPopupContent";

const OnBoardingStepsPopup = props => {
  const { remainingCount, toggleStepsPopup } = props;
  return (
    <div
      className={`onboardingStepsPopup ${
        remainingCount === 0 ? "headeWhiteBg" : ""
      }`}
    >
      <div
        className="popup-close"
        title="Click here to close"
        onClick={() => toggleStepsPopup()}
      >
        <i className="material-icons">close</i>
      </div>
      <PopupHeader {...props} />
      <PopupContent {...props} />
    </div>
  );
};

export default observer(OnBoardingStepsPopup);
export { OnBoardingStepsPopup };
