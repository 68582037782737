import React from "react";

const NoActivityFound = () => {
  return (
    <div className="noActivityFound">
      <p className="textDesc">
        No activities found for the selected date range
      </p>
    </div>
  );
};

export default NoActivityFound;
export { NoActivityFound };
