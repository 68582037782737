import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import Utils from "Utils/utils";
import DateUtils from "Utils/DateUtils";
import { ReactComponent as TimerIcon } from "Assets/svg/timer.svg";
import { ReactComponent as CompanyIcon } from "Assets/svg/companyIcon.svg";
import trackingStore from "./trackingStore";

const VistorCard = observer((props = {}) => {
  const {
    totalDurationInSecs = "",
    sessionId = "",
    countryDetails = {},
    cardRef = {},
    companyName = "",
    scrollBlock = false,
    setScrollBlock = () => {}
  } = props || {};

  const { code = "", name = "" } = countryDetails || {};
  let isSelectedCard = trackingStore?.sessionDetails?.sessionId === sessionId;

  const focusSelectedCard = () => {
    if (cardRef?.current && isSelectedCard && scrollBlock) {
      cardRef.current.scrollIntoView({
        behavior: "smooth",
        block: scrollBlock
      });
    }
    setScrollBlock("");
  };

  const setSessionDetails = () => {
    if (trackingStore?.visitorsData.length > 0) {
      trackingStore.visitorsData.forEach((item, index) => {
        if (item?.sessionId === sessionId) {
          trackingStore.setCurrentUser(index);
        }
      });
      trackingStore.setSessionDetails(props);
    }
  };

  useEffect(() => {
    focusSelectedCard();
  }, [scrollBlock]);

  return (
    <div
      className={`vistorDetailCard ${isSelectedCard ? "highlight" : ""}`}
      ref={cardRef}
      onClick={setSessionDetails}
    >
      <div className="visitorName">{Utils.getVisitorFullName(props)}</div>
      <div className="vistorCardBody">
        <div className="companyAndTotalDuration">
          <div className="companyDetails">
            <CompanyIcon />
            <span className="name">{companyName || "Anonymous"}</span>
          </div>
          <div className="totalDuration">
            <TimerIcon />
            <span className="duration">
              {totalDurationInSecs
                ? DateUtils.getTimeFromSeconds(totalDurationInSecs)
                : "00:00:00"}
            </span>
          </div>
        </div>
        <div className="countryDetails">
          <span className="countryCodeCont">
            <span
              className={`countryCode fi fi-${code?.toLowerCase() || "us"} fis`}
            />
          </span>
          <span className="countryName">
            {name?.toUpperCase() || "UNITED STATES"}
          </span>
        </div>
      </div>
    </div>
  );
});

const VisitorDetails = observer((props = {}) => {
  const divRefs = Array.from({ length: 10 }, () => useRef(null));
  const { scrollBlock = false, setScrollBlock = () => {} } = props || {};

  const VisitorCardLoader = ({ length = 6 }) => {
    return Array.from({ length }, (value, index) => (
      <div className="vistorDetailCard">
        <div className="visitorName">
          <div className="name linear-background"></div>
        </div>
        <div className="vistorCardBody">
          <div className="companyAndTotalDuration">
            <div className="companyDetails linear-background" />
            <div className="totalDuration linear-background">
              <span className="duration linear-background" />
            </div>
          </div>
          <div className="countryDetails">
            <div className="countryName linear-background"></div>
          </div>
        </div>
      </div>
    ));
  };

  return !trackingStore?.dataloader ? (
    <div className="visitorsDetails">
      {trackingStore?.visitorsData?.length > 0 &&
        trackingStore?.visitorsData?.map((item, index) => {
          return (
            <VistorCard
              cardRef={divRefs[index]}
              key={index}
              {...item}
              scrollBlock={scrollBlock}
              setScrollBlock={setScrollBlock}
            />
          );
        })}
    </div>
  ) : (
    <div className="visitorsDetails">
      <VisitorCardLoader />
    </div>
  );
});

export { VisitorDetails };
export default VisitorDetails;
