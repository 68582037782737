/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import JumpBallLoader from "Components/common/JumpBallLoader";
import { dealifyPopupStore } from "./DealifyPopup";

const LoggedInDealify = props => {
  useEffect(() => {
    dealifyPopupStore.showPopup = true;
    props.history.push("/dashboard");
  }, []);

  return <JumpBallLoader />;
};

export { LoggedInDealify };
export default withRouter(LoggedInDealify);
