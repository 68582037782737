import { observer } from "mobx-react";
import React from "react";

const CustomNumberInput = observer((props = {}) => {
  const {
    type = "number",
    id = "",
    changeCbk = () => {},
    min = "",
    max = "",
    name = "",
    value = "",
    label1 = "",
    label2 = "",
    placeholder = "",
    errMsg = "",
    readOnly = false,
    submitCbk = () => {},
    className = "",
    restrictSettings = false
  } = props;

  return (
    <>
      <div className="numberInputBoxWrapper">
        <label>{label1}</label>
        <input
          id={id}
          type={type}
          name={name}
          min={min}
          value={value}
          className={className}
          max={max}
          placeholder={placeholder}
          onChange={e => changeCbk(e.currentTarget.value)}
          readOnly={readOnly}
          onKeyUp={e => submitCbk(e)}
          disabled={restrictSettings}
        />
        <label>{label2}</label>
      </div>
      {errMsg && <span className="errMsgText">{errMsg}</span>}
    </>
  );
});

export { CustomNumberInput };
export default CustomNumberInput;
