import React from "react";
import { observer } from "mobx-react";
import PaginationComponent from "Components/common/PaginationComponent";
import { InboxListBody, InboxListHeader } from "./InboxListHeadAndBody";
import SearchAndFilter from "./SearchAndFilter";
import InboxListLoader from "./InboxListLoader";
import domainHealthCenterStore from "./store";
import PageLimit from "Components/common/PageLimit";

const InboxList = observer((props = {}) => {
  const { updatedInboxList = [] } = props || {};

  const paginate = (pageNoObj = {}) => {
    let pageNo = pageNoObj?.selected ? pageNoObj?.selected + 1 : 1;
    domainHealthCenterStore.setCurrentPage(pageNo);
    domainHealthCenterStore.setConnectedAccountsLoading(true);
    domainHealthCenterStore.fetchAllConnectedEmails();
  };

  const handleActionByLimit = (limit = 10) => {
    domainHealthCenterStore.setPageLimit(limit);
    domainHealthCenterStore.setConnectedAccountsLoading(true);
    domainHealthCenterStore.fetchAllConnectedEmails();
  };

  return (
    <section
      className={`inboxListSection ${
        domainHealthCenterStore?.totalPages > 1 ? "hasPagination" : ""
      }`}
    >
      <InboxListHeader />
      <div className="inboxListBody">
        {domainHealthCenterStore?.connectedAccountsLoading ? (
          <InboxListLoader length={10} />
        ) : (
          <InboxListBody updatedInboxList={updatedInboxList} />
        )}
      </div>
      {domainHealthCenterStore?.totalPages > 1 && (
        <div className="inboxListPageAndLimit">
          <PaginationComponent
            callback={paginate}
            loading={domainHealthCenterStore?.connectedAccountsLoading}
            totalPages={domainHealthCenterStore?.totalPages}
            currentPage={domainHealthCenterStore?.currentPage}
          />
          <PageLimit
            limitPerPage={domainHealthCenterStore.pageLimit}
            text="Showing"
            cbk={handleActionByLimit}
            totalCount={domainHealthCenterStore?.totalCount}
          />
        </div>
      )}
    </section>
  );
});

const InboxListWithSearchAndFilter = observer((props = {}) => {
  const { updatedInboxList = [], setUpdatedInboxList = () => {} } = props || {};

  return (
    <>
      <SearchAndFilter
        updatedInboxList={updatedInboxList}
        setUpdatedInboxList={setUpdatedInboxList}
      />
      <InboxList updatedInboxList={updatedInboxList} />
    </>
  );
});

export { InboxListWithSearchAndFilter };
export default InboxListWithSearchAndFilter;
