import React, { useContext } from "react";
import Button from "Components/common/Button";
import AddTeamMemberImg from "../img/addTeamMember.png";
import teamState from "../state";
import { SettingContext } from "Pages/Dashboard/settings/Settings";

const EmptyAddMembers = props => {
  const { updateBreadCrumb = () => {} } = useContext(SettingContext);
  const { handleAddTeamMembers = () => {} } = props || {};

  const btnProps = {
    id: "addTeamMemberBtn",
    name: "addTeamMemberBtn",
    btnText: "Add members",
    type: "button",
    className: "addTeamMemberBtn",
    click: handleAddTeamMembers
  };

  const handleBackBtn = () => {
    updateBreadCrumb(`Users & Teams`);
    teamState.setShowTeamMemberPage(false);
  };

  return (
    <div className="addTeamMemberWrapper">
      <div className="teamHeaderSection">
        <div className="backBtn" onClick={handleBackBtn}>
          <span className="material-icons forwardArrow">arrow_forward</span>
        </div>
      </div>
      <img
        className="addTeamMemberImg"
        src={AddTeamMemberImg}
        alt="Add Team member"
      />
      <Button {...btnProps} />
    </div>
  );
};

export { EmptyAddMembers };
export default EmptyAddMembers;
