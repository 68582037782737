import React from "react";
import { Helmet } from "react-helmet";

const HeadTag = ({ pathname = "" }) => {
  let extractedPagename =
    pathname?.split("/")?.[1]?.toLowerCase() || "dashboard";
  let pagename = ["login", "signup", "pricing"].includes(extractedPagename)
    ? extractedPagename
    : "dashboard";

  const HEAD_OBJ = {
    dashboard: {
      title: "Salesgear",
      keywords: "Simplfiy outbound sales",
      desc: "All in one sales engagement platform to streamline  and simplify outbound sales. Our productivity suite simplifies sales engagement, outreach automation, and personalized follow-ups to help you achieve more in less time"
    },
    pricing: {
      title: "Explore Salesgear pricing options",
      keywords: "Salesgear pricing",
      desc: "Discover Salesgear pricing options and choose a plan that suits your sales needs and budget"
    },
    login: {
      title: "Effortless Salesgear login at your convenience",
      keywords: "Salesgear login",
      desc: "Access your Salesgear login account easily and empower your sales outreach with our user-friendly platform"
    },
    signup: {
      title: "Sign up with Salesgear for all your outbound sales needs",
      keywords: "Sign up with Salesgear",
      desc: "Want to start your successful outbound sales journey? Sign up with Salesgear now"
    }
  };

  let pageObj = HEAD_OBJ[pagename] || HEAD_OBJ["dashboard"];

  return (
    pageObj &&
    Object.keys(pageObj)?.length > 0 && (
      <Helmet>
        <title>{pageObj["title"]}</title>
        <meta name="description" content={pageObj["desc"]} />
        <meta name="keywords" content={pageObj["keywords"]} />
        <meta property="og:title" content={pageObj["title"]} />
        <meta property="og:description" content={pageObj["desc"]} />
        <meta property="twitter:title" content={pageObj["title"]} />
        <meta property="twitter:description" content={pageObj["desc"]} />
      </Helmet>
    )
  );
};

export default HeadTag;
