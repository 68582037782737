import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import userDetail from "Stores/userDetail";
import Button from "Components/common/Button";
import { Utils } from "Utils/utils";
import InputBox from "Components/Register/InputBox";
import { MP_EVENT, WEBSITE_URL } from "Static/constant";
import makeApi, { URLS } from "Utils/apiURL";
import { captchaStoreValue } from "Stores/captcha";
import { checkTeamStatus, getFeatureFlagWithTeam } from "Utils/commonAPI";
import DealifyPopup, { dealifyPopupStore } from "./DealifyPopup";

const Dealify = props => {
  const userData = {};
  let location = useLocation();

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState({
    password: "",
    showPassword: false
  });
  const [couponCode, setCouponCode] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [couponCodeErr, setCouponCodeErr] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const timeZoneValidation = Utils.validateEUTimeZone();

  const changeTeamStatus = async () => {
    const config = {
      url: URLS.changeTeamStatus,
      method: "GET"
    };
    await makeApi(config);
  };

  const createTeam = async () => {
    const config = {
      url: `${URLS?.initializeTeam}?code=${couponCode}`,
      method: "POST"
    };
    let res = await makeApi(config);
    if (res?.data?.teamMember) {
      await changeTeamStatus();
      userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
      userData.team = await checkTeamStatus();
      userData.email = userEmail?.trim()?.toLowerCase();
      userData.userLoggedIn = true;
      userDetail.setUserInfo(userData);
    }
  };

  const constructRedirectUrl = () => {
    let queryParams = Utils.getQueryParams(location.search);
    let queryValue = queryParams.get("redirect")
      ? location.search
      : "&redirect=dashboard";
    return { url: `/signupSuccess${queryValue}`, query: queryValue };
  };

  const signup = async token => {
    try {
      let isEmailValid = Utils.emailValidate(
        userEmail?.trim()?.toLowerCase() || ""
      );
      let isPasswordValid = Utils.validateStringLength(
        userPassword?.password || "",
        6
      );
      if (isEmailValid && isPasswordValid) {
        const config = {
          url: URLS.signupUser,
          method: "POST",
          data: {
            email: userEmail?.trim()?.toLowerCase() || "",
            password: userPassword?.password || "",
            emailPreferences: true,
            source: "WEB",
            token,
            mixpanelInfo: {
              pageType: "WEB"
            },
            userTimezone: {
              zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
              zone_value: Utils.getCurrentUserTimeZone()
            }
          }
        };
        let response = await makeApi(config);
        switch (response?.data?.toString()) {
          case "true":
            localStorage.setItem("isOnboarding", true);
            await createTeam();
            Utils.mixpanelTrack("User Sign Up", {
              email: userEmail,
              pageType: "Dealify Register ",
              source: "WEB"
            });
            let { url, query } = constructRedirectUrl();
            localStorage.setItem("newUserSignUp", true);
            props.history.push(url, query);
            break;
          case "limit":
            setErrMsg(`We have noticed too many registrations from this domain. Please
            <a target="_blank" rel="noopener noreferrer" href="mailto:support@salesgear.io">contact support</a> for further help`);
            setLoader(false);
            break;
          case "business":
            setEmailErr("Please enter a valid work email address");
            setLoader(false);
            break;
          case "invalid":
            setEmailErr("Please enter a valid email address");
            setLoader(false);
            break;
          case "false":
            setLoader(false);
            dealifyPopupStore.showPopup = true;
            break;
          default:
            Utils.mixpanelTrack(MP_EVENT.SIGN_UP_FAILED, {
              pageType: window.location.pathname
            });
            setLoader(false);
            setEmailErr("Something went wrong");
            break;
        }
      } else {
        setLoader(false);
        setErrMsg("Please check your email address or password");
      }
    } catch (err) {
      setLoader(false);
      console.error("Signup Error", err);
    }
  };

  const submitForm = e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      Utils.removeInputRequired(e);
      executeCaptcha();
    }
  };
  const emailChange = value => {
    setUserEmail(value?.trim()?.toLowerCase());
    emailErr && setEmailErr("");
    errMsg && setErrMsg("");
  };

  const passwordChange = value => {
    setUserPassword(value);
    passwordErr && setPasswordErr("");
    errMsg && setErrMsg("");
  };

  const couponCodeChange = value => {
    setCouponCode(value);
    couponCodeErr && setCouponCodeErr("");
    errMsg && setErrMsg("");
  };

  const TermsAndContAndPrivacy = () => {
    return (
      <>
        By signing up, you are accepting our
        <a
          href={`${WEBSITE_URL}/terms-of-service`}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Terms&nbsp;
        </a>{" "}
        and
        <a
          href={`${WEBSITE_URL}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Privacy Policy.
        </a>
      </>
    );
  };

  const getCouponCodeStatus = async () => {
    const dealifyCouponCodeUrl = URLS?.dealifyCheckCouponCode.replace(
      "<<couponCode>>",
      couponCode
    );
    const config = {
      url: dealifyCouponCodeUrl,
      method: "GET"
    };

    const { data: { status: couponCodeStatus = "" } = {} } = await makeApi(
      config
    );
    return couponCodeStatus.toLowerCase();
  };

  const executeCaptcha = async e => {
    e && Utils.removeInputRequired(e);
    let isEmailValid = Utils.emailValidate(
      userEmail?.trim()?.toLowerCase() || ""
    );
    let isPasswordValid = Utils.validateStringLength(
      userPassword?.password || "",
      6
    );
    const couponCodeStatus =
      couponCode !== ""
        ? await getCouponCodeStatus()
        : setCouponCodeErr("Please provide a valid coupon code");
    const isValidCouponCode = couponCodeStatus === "active";
    if (isEmailValid && isPasswordValid && isValidCouponCode) {
      setLoader(true);
      captchaStoreValue.executeCaptcha(validateCaptchaFromAPI);
    } else if (!isEmailValid) {
      setEmailErr("Please enter a valid email address");
      setLoader(false);
    } else if (!isPasswordValid) {
      setPasswordErr("Password must be at least 6 characters");
      setLoader(false);
    } else if (!isValidCouponCode) {
      if (couponCodeStatus === "expired") {
        setCouponCodeErr(
          "The Dealify code has expired. Please provide an unused code and try again"
        );
      } else if (couponCodeStatus === "invalid") {
        setCouponCodeErr(
          "The dealify code is not correct. Please provide a valid code and try again"
        );
      }
      setLoader(false);
    } else {
      setLoader(false);
      setErrMsg("Please check your email address or password");
    }
  };

  const validateCaptchaFromAPI = async token => {
    token && signup(token);
  };

  const dealifyInputFormObj = [
    {
      name: "workEmail",
      id: "workEmail",
      type: "email",
      value: userEmail,
      changeAction: emailChange,
      keyupAction: submitForm,
      errMsg: emailErr,
      label: "Work Email",
      isPassword: false,
      placeholder: `eg: john@salesgear.io`,
      focus: true
    },
    {
      name: "password",
      id: "password",
      type: "password",
      value: userPassword,
      changeAction: value =>
        passwordChange({ ...userPassword, password: value }),
      keyupAction: submitForm,
      errMsg: passwordErr,
      label: "Password",
      className: "password",
      isPassword: true,
      placeholder: "Password",
      showPasswordAction: () =>
        setUserPassword({
          ...userPassword,
          showPassword: !userPassword?.showPassword
        })
    },
    {
      name: "dealifyCouponCode",
      id: "dealifyCouponCode",
      type: "text",
      value: couponCode,
      changeAction: couponCodeChange,
      keyupAction: submitForm,
      errMsg: couponCodeErr || errMsg,
      label: "Dealify Coupon Code",
      isPassword: false,
      placeholder: "eg: ADCDE!@#123",
      errMsgClass: "btnErrText"
    }
  ];

  return (
    <>
      <section
        id="dealifySignup"
        className={`formInputBox ${loader ? "pointerUserSelectNone" : ""}`}
      >
        <h3>Sign Up</h3>
        {dealifyInputFormObj.map(item => (
          <InputBox {...item} key={item.id} />
        ))}
        {!timeZoneValidation && (
          <div className="termsAndPolicyText">{TermsAndContAndPrivacy()}</div>
        )}
        <Button
          id="dealifySignupBtn"
          name="dealifySignupBtn"
          btnText="Claim your deal"
          type="button"
          className={
            loader
              ? "loading"
              : "dealifySignupBtn btn-new btn-rounded btn-orange btn-text btn-arrow"
          }
          loader={loader}
          click={e => {
            executeCaptcha(e);
          }}
        />
        <p className="dealifyRedirectPopup">
          <span className="descText">Already have an account? </span>
          <span
            className="loginLink"
            onClick={() => {
              dealifyPopupStore.showPopup = true;
            }}
          >
            Log In
          </span>
        </p>
      </section>
      <DealifyPopup />
    </>
  );
};

export { Dealify };
export default Dealify;
