/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { makeApi, URLS } from "Utils/apiURL";

const GoogleOAuth = observer(() => {
  const [isConnected, setConnected] = useState(false);

  const getAuthCode = () => {
    try {
      const url = new URL(window.location.href);
      const code = url?.searchParams.get("code");
      !code
        ? url?.href?.indexOf("access_denied") !== -1
          ? closeWindow()
          : ""
        : "";
      return code;
    } catch (e) {
      closeWindow();
      return null;
    }
  };

  const getAuthState = () => {
    try {
      const url = new URL(window.location.href);
      const state = url?.searchParams.get("state");
      return state;
    } catch (e) {
      closeWindow();
      return null;
    }
  };

  const connectGoogleApp = async () => {
    const code = getAuthCode();
    const state = getAuthState();
    let inboxId = "";
    if (state) {
      inboxId = state?.split("_")?.pop();
    }
    if (code) {
      let reconnectParam = state?.includes("reconnectInbox")
        ? `&reconnectInboxId=${inboxId}`
        : "";
      let apiUrlParam = state?.toLowerCase()?.includes("calendar")
        ? URLS.connectCalendar
        : URLS.connectGauth;
      const config = {
        url: `${apiUrlParam}?code=${code}${reconnectParam}`,
        method: "GET"
      };
      const res = await makeApi(config);
      const resCode = res?.data?.toString() || "500";
      opener?.postMessage({ code: resCode, type: "GOOGLE_CONNECT_INBOX" });
      if (resCode === "200") {
        if (inboxId) {
          const config = {
            url: `${URLS.getDomainHealth}?inboxId=${inboxId}`,
            method: "GET"
          };
          makeApi(config);
        }
        setConnected(true);
        setTimeout(() => {
          closeWindow();
        }, 1000);
      } else {
        closeWindow();
      }
    } else {
      closeWindow();
    }
  };

  const closeWindow = () => {
    window?.close();
  };

  useEffect(() => {
    connectGoogleApp();
  }, []);

  return (
    <div className="pageClass">
      <div className="pageCont">
        <div className="connectAppSection">
          {isConnected ? (
            <div>
              <span>Successfully Connected</span>
            </div>
          ) : (
            <div>
              <div>Please wait while we connect your google account</div>
              <div style={{ marginTop: "10px" }}> Connecting... </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default GoogleOAuth;
export { GoogleOAuth };
