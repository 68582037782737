/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { sequenceRenderState } from "./sequenceProperties";
import ContactOwnerAnalyticsDropdown from "./ContactOwnerAnalyticsDropdown";
import { ReactComponent as DownArrow } from "Assets/svg/donw-arrow.svg";

const ContactOwnerButton = props => {
  const { name, arrow, icon } = props || {};

  const { selected, team } = sequenceRenderState?.contactOwner || {};
  const [selectedUser, setSeletedUser] = useState(selected);
  const [showFilter, setFilter] = useState(false);

  const getHiddenTagCount = value => {
    let count =
      team && value?.length > 0 && value?.length > 2 && value.length - 2;
    if (count) {
      return count;
    }
  };

  useEffect(() => {
    setSeletedUser(sequenceRenderState.contactOwner.selected);
  }, [sequenceRenderState.contactOwner.selected]);

  return (
    <>
      <div className="contactOwnedFilterSection">
        <button className="filterTagBtn" onClick={e => setFilter(!showFilter)}>
          <span className="contactIcon">{icon}</span>
          <div className="titleText">{name}</div>
          {selectedUser && selectedUser.length > 0 && (
            <span className="tagSelected">
              :
              <ul>
                {selectedUser.map((key, index) => {
                  if (index < 2) {
                    return <li key={key + index}>{key}</li>;
                  }
                })}
              </ul>
            </span>
          )}
          {selectedUser &&
            selectedUser.length > 0 &&
            selectedUser.length > 2 && (
              <span className="countPlus">
                +{getHiddenTagCount(selectedUser)}
              </span>
            )}
          {arrow && (
            <span className="arrowIcon">
              <DownArrow />
            </span>
          )}
        </button>
        {showFilter && <ContactOwnerAnalyticsDropdown team={team} />}
        {showFilter && (
          <div className="overlayMask" onClick={e => setFilter(!showFilter)} />
        )}
      </div>
    </>
  );
};

export default observer(ContactOwnerButton);
