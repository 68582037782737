import React from "react";

const DialerLoadingCards = props => {
  const { cards } = props;
  return (
    <div className="cardWrapper loadingCards">
      {Array.from({ length: cards }, (value, index) => (
        <div className={`card card${index + 1}`} key={`loadingcards${index}`}>
          <div
            className="phoneNumberCardheader"
            style={{ height: "auto", marginBottom: "10px" }}
          >
            <span
              className="linear-background"
              style={{ width: "100%", height: "15px", borderRadius: "10px" }}
            ></span>
          </div>
          <div
            className="actionBtns"
            style={{
              width: "calc(100% - 20px)",
              right: "0px",
              marginTop: "30px"
            }}
          >
            <span
              className="linear-background"
              style={{
                width: "50%",
                height: "15px",
                borderRadius: "10px",
                float: "right"
              }}
            ></span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DialerLoadingCards;
export { DialerLoadingCards };
