import React, { useState, createContext, useEffect } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { Utils } from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import TriggersOverview from "./Overview/TriggersOverview";
import CreateEditTriggerForm from "./AddEditTrigger/CreateEditTriggerForm";
import { triggerState } from "./TriggerState";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import PaywallUtils from "Utils/PaywallUtils";
import { slackDataStore } from "Stores/SlackDataStore";

const TriggersContext = createContext({});

const Triggers = observer(() => {
  const {
    triggersEnabled = false,
    noOfContactsUsed = 0,
    noOfSequencesUsed = 0,
    planName = "",
    inFreePlan = false
  } = userDetail?.userFeatureFlag || {};

  const [showAddTrigger, setShowAddTrigger] = useState(false);
  const [showOverview, setShowOverview] = useState(true);
  const [loader, setLoader] = useState(false);

  const addTrigger = () => {
    setShowOverview(false);
    setShowAddTrigger(true);
  };

  const hideAddTrigger = () => {
    triggerState.setIsEdit(false);
    triggerState.setResetInputValue();
    setShowOverview(true);
    setShowAddTrigger(false);
  };

  Utils.mixpanelTrack(MP_EVENT.TR_PAGE_VIEWED, {
    pageType: "Setting Triggers"
  });

  const upgradeFunc = () => {
    PaywallUtils.upgradeUtilsFunction("triggers", false, planName, inFreePlan, {
      triggerType: "Add Trigger"
    });
  };

  useEffect(() => {
    if (!triggersEnabled) {
      upgradeFunc();
    }
    slackDataStore.getSlackData();
  }, []);

  return (
    <TriggersContext.Provider
      value={{
        loader,
        setLoader
      }}
    >
      <div className={`triggersSection ${triggersEnabled ? "" : "blurBg"}`}>
        {showOverview && (
          <TriggersOverview
            setShowOverview={setShowOverview}
            setShowAddTrigger={setShowAddTrigger}
            addTrigger={addTrigger}
            triggersEnabled={triggersEnabled}
            noOfContactsUsed={noOfContactsUsed}
            noOfSequencesUsed={noOfSequencesUsed}
          />
        )}
        {showAddTrigger && (
          <CreateEditTriggerForm
            cancel={hideAddTrigger}
            loader={loader}
            setLoader={setLoader}
          />
        )}
      </div>
    </TriggersContext.Provider>
  );
});

export default Triggers;
export { Triggers, TriggersContext };
