// Presentational component
import React from "react";
import Button from "Components/common/Button";
import { settingsStoreData } from "Stores/settingsData";

const ConnectOrDisconnectCard = props => {
  const {
    isConnected = false,
    removeLoader = false,
    addOrRemoveAccount = () => {},
    connectedEmail = "",
    icon = "",
    type = ""
  } = props;

  let btnAttributeValue = isConnected ? "disconnectBtn" : "connectBtn";

  return (
    <>
      <div className="card">
        <img src={icon} alt={type} />
        <div className="headingBlock">
          <h6 className="subHeading">{type}</h6>
          <div className="wrapIconText">
            {isConnected && <i className="material-icons">check_circle</i>}
            <span className="text">
              {isConnected ? `Connected ` : "Not connected"}
              {connectedEmail ? (
                <>
                  <span>to </span>
                  <span className="highlightConnectedAccount">
                    {connectedEmail}
                  </span>
                </>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
        <div
          className={`${
            settingsStoreData?.fromContacts && !isConnected
              ? "highlightConnectBtn"
              : ""
          }`}
        >
          <Button
            id={btnAttributeValue}
            name={btnAttributeValue}
            btnText={isConnected ? "Disconnect" : "Connect"}
            type="button"
            className={`${btnAttributeValue} ${removeLoader ? "loading" : ""} `}
            loader={removeLoader}
            click={e => {
              e.preventDefault();
              settingsStoreData.setFromContacts(false);
              addOrRemoveAccount(e);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ConnectOrDisconnectCard;
export { ConnectOrDisconnectCard };
