import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Chart from "react-apexcharts";
import connectedInboxStore from "./store";

const options = {
  chart: {
    type: "donut",
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  labels: ["Emails landed in inbox", "Emails saved from spam"],
  dataLabels: {
    enabled: false
  },
  colors: ["#28AD56", "#F59064"],

  stroke: {
    show: true,
    width: 1, // Width of the border
    colors: ["#ccc"] // Gray color for the border
  },
  responsive: [
    {
      breakpoint: 480,
      height: 150,
      chart: {
        width: 150
      },
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ]
};

const DeliverablityGraph = observer(() => {
  const [chartData, setChartData] = useState([]);
  const { inboxCount, spamCount, totalCount } =
    connectedInboxStore?.deliverabilityStats || {};

  const handleDonutChartData = () => {
    let tempData = [inboxCount || 0, spamCount || 0];
    setChartData(tempData);
  };

  useEffect(() => {
    if (inboxCount || spamCount) {
      handleDonutChartData();
    }
  }, [connectedInboxStore?.deliverabilityStats]);

  return inboxCount || spamCount ? (
    <div className="deliverablityGraph">
      <Chart options={options} series={chartData} type="donut" height={160} />
    </div>
  ) : (
    <div className="noActivityInDonutGraph">No activity found</div>
  );
});

export default DeliverablityGraph;
export { DeliverablityGraph };
