import { observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import makeApi, { URLS } from "Utils/apiURL";

class PipedriveStore {
  @observable pipedriveData = "";
  @observable pipedriveContactsList = "";
  @observable pipedriveOwnersList = "";
  @observable pipedriveContactConfig = {};
  @observable pipedriveOwnerConfig = {};
  @observable pipedriveContactTags = [];
  @observable disableSaveButton = false;
  @observable pipedriveFields = [];
  @observable pipedrivePreferences = {};

  @action
  setPipedriveData = value => {
    this.pipedriveData = value;
  };

  @action
  setPipedriveContactsList = value => {
    this.pipedriveContactsList = value;
  };

  @action
  setPipedriveOwnersList = value => {
    this.pipedriveOwnersList = value;
  };

  @action
  setPipedriveContactConfig = value => {
    this.pipedriveContactConfig = value;
  };

  @action
  setPipedriveOwnerConfig = value => {
    this.pipedriveOwnerConfig = value;
  };

  @action
  setDisableSaveButton = value => {
    this.disableSaveButton = value;
  };

  @action
  setPipedriveContactTags = value => {
    this.pipedriveContactTags = value;
  };

  @action
  setPipedrivePreferences = value => {
    this.pipedrivePreferences = value;
  };

  @action
  getPipedriveData = async () => {
    try {
      const config = {
        url: URLS.getPipedriveAccountDetails,
        method: "GET"
      };
      let response = await makeApi(config);
      if (response?.data?.responseCode === 200) {
        this.setPipedriveData(response.data);
      } else {
        this.setPipedriveData("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  removePipedriveAccount = async () => {
    try {
      const config = {
        url: URLS.connectOrDisconnectPipedrive,
        method: "DELETE"
      };
      let res = await makeApi(config);
      if (res?.data?.responseCode === 200) {
        await this.getPipedriveData();
        toasterState.setToastMsg(
          "Pipedrive account disconnected successfully",
          "success"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Pipedrive failed to remove", err);
    }
  };

  @action
  getContactsListView = async () => {
    try {
      const config = {
        url: URLS.getPipedriveContactsList,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.setPipedriveContactsList([
          {
            name: "All Contacts",
            id: "all",
            email: null
          },
          ...res.data
        ]);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Pipedrive Contacts listing error", err);
    }
  };

  @action
  getAllOwners = async () => {
    try {
      const config = {
        url: URLS.getAllPipedriveOwners,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.pipedriveOwnersList = res?.data;
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Pipedrive Owners listing error", err);
    }
  };

  @action
  getAllPipedriveFields = async () => {
    try {
      const config = {
        url: `${URLS.getAllPipedriveFields}`,
        method: "GET"
      };
      let res = await makeApi(config);
      if (res?.status === 200) {
        this.pipedriveFields = res?.data;
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (err) {
      console.error("Pipedrive Owners listing error", err);
    }
  };
}

const pipedriveStore = new PipedriveStore();
export { pipedriveStore };
