import React from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { ReactComponent as ConfigureInboxIcon } from "Assets/Settings/configureInbox.svg";
import { ReactComponent as DeleteIcon } from "Assets/Settings/deleteInboxIcon.svg";
import { linkedInReplyState } from "./state";
import LinkedInProfileCard from "./common/LinkedInProfileCard";
import LinkedInMetrics from "./common/LinkedInMetrics";
import ConnectedLinkedInLoader from "./common/ConnectedLinkedInLoader";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import ReconnectLinkedInButton from "./ReconnectLinkedInButton";
import LinkedInAccountStatus from "./common/LinkedinAccountStatus";

const ConnectedLinkedInList = observer(() => {
  const handleConfigureAction = data => {
    linkedInReplyState.setConfigureData(data);
    linkedInReplyState.setShowConfigure(true);
  };

  const deleteLinkedInAccount = item => {
    linkedInReplyState.deleteLinkedInAccounts(item?.id);
  };

  const deleteCbk = item => {
    confirmationPopupState.setPopupValues({
      content:
        "Are you sure you want to remove this LinkedIn Account? All the scheduled tasks from this account will be suspended.",
      actionBtnText: "Yes",
      cancelBtnText: "No",
      callback: () => {
        deleteLinkedInAccount(item);
      },
      loadingMsg: "Deleting account. Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  return (
    <div className="connectedLinkedInList">
      {!linkedInReplyState?.linkedInDataLoading
        ? linkedInReplyState?.linkedInAccounts?.accounts?.length > 0 &&
          linkedInReplyState?.linkedInAccounts?.accounts.map((item, index) => {
            return (
              <div
                className={`connectedLinkedInCard ${
                  item?.status?.toLowerCase() === "deleted"
                    ? "linkedInAccountDeleted"
                    : ""
                }`}
                onClick={() => handleConfigureAction(item)}
              >
                <LinkedInProfileCard {...item} />
                <LinkedInMetrics {...item} />
                {item?.status.toLowerCase() !== "active" ? (
                  <div className="statusBtn">
                    <LinkedInAccountStatus account={item} />
                  </div>
                ) : (
                  <></>
                )}
                <div className="btnInboxActions">
                  <span className="reconnectbtn">
                    {item?.status.toLowerCase() !== "active" ? (
                      <ReconnectLinkedInButton className="reconnectComponent" />
                    ) : (
                      <></>
                    )}
                  </span>
                  <span
                    className="configureBtn"
                    onClick={() => handleConfigureAction(item)}
                  >
                    <ConfigureInboxIcon />
                  </span>
                  <span
                    className="deleteInboxIcon"
                    onClick={e => {
                      Utils.preventDefaultFn(e);
                      deleteCbk(item, index);
                    }}
                  >
                    <DeleteIcon />
                  </span>
                </div>
              </div>
            );
          })
        : linkedInReplyState?.linkedInDataLoading && (
            <ConnectedLinkedInLoader count={7} />
          )}
    </div>
  );
});

export { ConnectedLinkedInList };
export default ConnectedLinkedInList;
