/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import TemplatesFolder from "./TemplatesFolder";
import TemplatesStoreData, { TemplateDataStore } from "Stores/Template";
import TemplatesList from "./TemplatesList";
import { observer } from "mobx-react";

const TemplateCard = (props = {}) => {
  const {
    close = () => {},
    createTemplate = () => {},
    insertTemplateCbk = () => {}
  } = props || {};

  const [folderLoading, setFolderLoading] = useState(true);
  const [fileLoading, setFileLoading] = useState(true);
  const [folder, setFolder] = useState("");
  const [file, setFile] = useState("");
  const [folderId, setFolderId] = useState("all");
  const [sharedTemplates, setSharedTemplates] = useState({});

  const getFoldersList = () => {
    if (TemplatesStoreData && TemplatesStoreData.templateFileList !== "") {
      const folderData = checkAndAddSharedFolder(
        TemplatesStoreData.templateFileList
      );
      setFolder(folderData);
      setFile(
        TemplatesStoreData.templateFileList.length > 0
          ? TemplatesStoreData.templateFileList[0].templates
          : []
      );
      setFolderLoading(false);
      setFileLoading(false);
    }
  };

  const checkAndAddSharedFolder = data => {
    if (data && data.length > 0) {
      return getSharedFolder(data);
    } else {
      data = [{ folder: null }];
      return getSharedFolder(data);
    }
  };

  const getSharedFolder = data => {
    const sharedFolder = {
      folder: {
        id: "sharedTemplates",
        shortName: "sharedTemplates",
        folderName: "Shared Templates"
      },
      templates: sharedTemplates
    };
    data.splice(1, 0, sharedFolder);
    return data;
  };

  const getFolderTemplateFile = (index, id) => {
    setFolderId(id);
    setFileLoading(true);
    setFile(folder[index].templates);
    setFileLoading(false);
  };

  const sharedTemplatesCbk = (flag, data) => {
    if (data) {
      setSharedTemplates(data);
    } else {
      setSharedTemplates(null);
    }
  };

  const getSharedTempaltes = async () => {
    const payload = { limit: 250, pageNo: 1 };
    await TemplateDataStore.getSharedTemplates(payload, sharedTemplatesCbk);
  };

  const getDataFromApi = async () => {
    await getSharedTempaltes();
    TemplatesStoreData.getTemplatesWithFolder();
  };

  useEffect(() => {
    getFoldersList();
  }, [TemplatesStoreData.templateFileList]);

  useEffect(() => {
    setFolderLoading(true);
    setFileLoading(true);
    getDataFromApi();
  }, []);

  return (
    <>
      <div className="templatesCardList">
        <span className="tooltipPointer"></span>
        <div className="leftSection">
          <TemplatesFolder
            loading={folderLoading}
            folder={folder}
            changeFolder={getFolderTemplateFile}
          />
        </div>
        <div className="rightSection">
          <TemplatesList
            loading={fileLoading}
            file={file}
            insertTemplateCbk={insertTemplateCbk}
            createTemplate={() => createTemplate(folderId)}
          />
        </div>
      </div>
      <div className="overlayTemplateCardBg" onClick={close}></div>
    </>
  );
};

export default observer(TemplateCard);
export { TemplateCard };
