import React, { useState, useEffect, createContext } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { accountsState } from "./AccountsState";
import HeaderLayout from "Components/LoggedInLayout/HeaderLayout";
import AccountsRouting from "Routes/AccountsRouting";

const defaultBreadcrumbValue = [{ name: "Accounts", link: "/accounts" }];

const AccountsContext = createContext({});

const Accounts = observer(
  withRouter((props = {}) => {
    let location = useLocation();

    const [breadcrumbArr, setBreadCrumbArr] = useState([
      ...defaultBreadcrumbValue
    ]);

    const updateBreadCumb = (value = "") => {
      if (value) {
        setBreadCrumbArr([
          ...defaultBreadcrumbValue,
          {
            name: Utils.captializeFirstCharacter(value),
            link: location?.pathname
          }
        ]);
      }
    };

    const getInit = async () => {
      accountsState.setAccountName("");
      const { data = [] } = await accountsState.getAccountStages();
      accountsState.setAccountStages(data || []);
    };

    useEffect(() => {
      getInit();
      return () => {
        accountsState.resetAccountListing();
      };
    }, []);

    useEffect(() => {
      if (location?.pathname === "/accounts") {
        setBreadCrumbArr([...defaultBreadcrumbValue]);
      }
    }, [location]);

    return (
      <AccountsContext.Provider
        value={{
          updateBreadCumb
        }}
      >
        <div className="accountsPage pageClass">
          <HeaderLayout
            className="accounts"
            breadcrumbArr={breadcrumbArr}
            {...props}
          />
          <div className="accountsBody">
            <AccountsRouting />
          </div>
        </div>
      </AccountsContext.Provider>
    );
  })
);

export default Accounts;
export { Accounts, AccountsContext };
