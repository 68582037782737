import Utils from "./utils";
import { MP_EVENT } from "Static/constant";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { comingSoonPopupState } from "Components/common/ComingSoonPopup";

const TemplateUtils = {};

TemplateUtils.insertTemplateConfirmationPrompt = (cbk = () => {}) => {
  confirmationPopupState.setPopupValues({
    content:
      "This would replace the email subject and body with the selected template.<br>Please confirm to proceed.",
    actionBtnText: "Yes",
    callback: cbk
  });
  confirmationPopupState.setShowPopup(true);
};

export default TemplateUtils;
export { TemplateUtils };
