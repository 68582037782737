import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import DateUtils from "Utils/DateUtils";
import { makeApi, URLS } from "Utils/apiURL";
import { trackingStore } from "./trackingStore";

const visitorDetailList = [
  {
    type: "Visits",
    name: "visits",
    value: 0,
    key: "totalVisits"
  },
  {
    type: "Unique Visitors",
    name: "uniqueVisits",
    value: 0,
    key: "uniqueVisitor"
  },
  {
    type: "Avg. Visit Duration",
    name: "avgVisits",
    value: 0,
    key: "avgDurationInSecs"
  }
];

const VisitAnalyticsComponent = observer((props = {}) => {
  const { websiteVisitorTrackingEnabled = false } = props || {};

  const [loader, setLoader] = useState(false);
  const [visitorsData, setvisitorsData] = useState(visitorDetailList);
  const [analyticsDate, setAnalyticsDate] = useState("");

  const VisitAnalyticsLoader = (name = "") => {
    return (
      <div className={`visitCont ${name}`} key={name}>
        <div className="visitTitleAndTime">
          <div className="visitTitle linear-background" />
          <div className="visitTime linear-background" />
        </div>
        <div className="visitTimeInMin linear-background" />
      </div>
    );
  };

  const getVisitorsAnalytics = async () => {
    setLoader(true);
    let data = { ...trackingStore?.dateFilter };
    const config = {
      url: URLS.getVisitorsAnalytics,
      method: "POST",
      data
    };
    let response = await makeApi(config);
    if (+response?.status === 200) {
      handleApiResponse(response?.data);
    }
    setLoader(false);
  };

  const handleApiResponse = (response = {}) => {
    // mapping uniqueVisitor,totalVisits,avgDurationInSecs values from response to tempVisitorsData
    let tempVisitorsData = visitorsData || [];
    if (tempVisitorsData?.length > 0) {
      tempVisitorsData.map(item => {
        return (item.value = response?.[item?.key] || 0);
      });
    }
    setvisitorsData([...tempVisitorsData]);
  };

  const getAnalyticsDate = () => {
    const { fromDate = "", toDate } = trackingStore?.dateFilter || {};
    if (fromDate || toDate) {
      let tempFromDate = DateUtils.getDateMonthAndYear(fromDate);
      let tempToDate = DateUtils.getDateMonthAndYear(toDate);
      setAnalyticsDate(
        `${tempFromDate} ${
          tempFromDate !== tempToDate ? `- ${tempToDate}` : ""
        }`
      );
    } else {
      setAnalyticsDate("");
    }
  };

  useEffect(() => {
    if (
      trackingStore?.isWebsiteTrackingEnabled &&
      websiteVisitorTrackingEnabled
    ) {
      getVisitorsAnalytics();
      getAnalyticsDate();
    }
  }, [trackingStore?.dateFilter, trackingStore?.isWebsiteTrackingEnabled]);

  return (
    <div className="visitAnalyticList">
      {visitorsData.map((item, index) => {
        return loader ? (
          <VisitAnalyticsLoader name={item?.name} key={index} />
        ) : (
          <div key={index} className={`visitCont ${item?.name}`}>
            <div className="visitTitleAndTime">
              <div className="visitTitle">{item?.type}</div>
              <div className="visitTime">{analyticsDate}</div>
            </div>
            <div className={`visitTimeInMin ${item?.name}`}>
              {["avgVisits"].includes(item?.name)
                ? item?.value
                  ? `${DateUtils.getMinutesFromSeconds(item?.value)} mins`
                  : "0"
                : item?.value}
            </div>
          </div>
        );
      })}
    </div>
  );
});

export { VisitAnalyticsComponent };
export default VisitAnalyticsComponent;
