import React from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import InboxImage from "./InboxImage";
import ChooseToConnectScreen from "./PopupScreens/ChooseToConnectScreen";
import ConnectMethod from "./PopupScreens/ConnectMethodScreen";
import connectedInboxStore from "./store";
import FirstTimeWarming from "./FirstTimeWarming";

class GoogleOAuthPopupState {
  @observable showPopup = false;
  @observable screenNo = "one";
  @observable connectConfig = "oAuth";
  @observable inboxData = "";
  @observable appPasswordScreen = "";

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
  };

  @action
  setScreenNo = (val = "") => {
    this.screenNo = val;
  };

  @action
  setConnectConfig = (val = "") => {
    this.connectConfig = val;
  };

  @action
  setInboxData = (val = {}) => {
    this.inboxData = val;
  };

  @action
  setAppPasswordScreen = (val = "") => {
    this.appPasswordScreen = val;
  };
}

const googleOAuthPopupState = new GoogleOAuthPopupState();

const GoogleOAuthPopup = observer(() => {
  const closeAction = () => {
    googleOAuthPopupState.setShowPopup(false);
    googleOAuthPopupState.setScreenNo("one");
    googleOAuthPopupState.setConnectConfig("oAuth");
  };

  return (
    googleOAuthPopupState.showPopup && (
      <>
        <section className="popupWrapper googleOAuthPopupWrapper">
          <div className="card common-popup">
            {googleOAuthPopupState?.screenNo === "one" && (
              <>
                <div className="cardHeader OAuthPopupHeader">
                  <InboxImage type="google" />
                  <span>Connect Your Google Account</span>
                  <div
                    className="OAuthPopupClose"
                    title="Click here to close"
                    onClick={closeAction}
                  >
                    <i className="material-icons">close</i>
                  </div>
                </div>
                <div className="cardBody OAuthPopupBody">
                  <ChooseToConnectScreen />
                </div>
              </>
            )}
            {googleOAuthPopupState?.screenNo === "two" && <ConnectMethod />}
            {connectedInboxStore?.firstTimeEnableWarming && (
              <FirstTimeWarming />
            )}
          </div>
        </section>
        <div className="googleOAuthPopupOverlay" />
      </>
    )
  );
});

export { GoogleOAuthPopup, googleOAuthPopupState };
export default GoogleOAuthPopup;
