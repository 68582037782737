import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { triggerState } from "Pages/Dashboard/settings/Triggers/TriggerState";
import FilterDropdown from "./FilterDropdown";

const SelectSequence = observer(props => {
  const {
    actionsArray = [],
    activeDropdownIndex = "",
    setActiveDropdownIndex = () => {},
    change = () => {},
    referenceId = "",
    defaultValue = "",
    options = []
  } = props || {};

  const selectBoxRef = useRef(null);

  const [selectedItem, setSelectedItem] = useState({});

  const filterCbk = item => {
    if (referenceId === "conditionsValue") {
      change("value", item);
    } else {
      let tempList = [...actionsArray];
      const indexValue = activeDropdownIndex.split("-")[1];
      tempList[indexValue - 1].sequenceId = item?.id;
      tempList[indexValue - 1].sequenceName = item?.name;
      change("actions", tempList);
    }
    setSelectedItem(item);
    setActiveDropdownIndex(null);
  };

  const handleScrollEnd = () => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      clientHeight = 0
    } = selectBoxRef?.current;
    if (
      scrollHeight - scrollTop - 10 < clientHeight &&
      triggerState.sequenceCurrentPageNo < triggerState.sequenceTotalPages
    ) {
      const newPageNumber = triggerState.sequenceCurrentPageNo + 1;
      triggerState.setSequenceCurrentPageNo(newPageNumber);
    }
  };

  useEffect(() => {
    setSelectedItem({});
  }, [triggerState?.inputValue?.contactOwner]);

  return (
    <div className="selectedSeqWrapper">
      <FilterDropdown
        options={options}
        isCustomValue={true}
        customCbk={filterCbk}
        refValue={selectBoxRef}
        handleScrollEnd={handleScrollEnd}
        {...props}
        defaultValue={selectedItem?.name || defaultValue}
        emptyText={"There are no Shared sequences"}
      />
    </div>
  );
});

export { SelectSequence };
export default SelectSequence;
