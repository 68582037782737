import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { calendarState } from "Pages/Dashboard/settings/Calendar/State";
import ActionIcon from "./ActionIcon";
import InputTextBox from "Components/common/InputTextBox";
import Utils from "Utils/utils";
import { toasterState } from "Components/common/toaster";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import {
  BASE_URL_ACTION_ICON_LIST,
  CALENDAR_BASEURL_REGEX
} from "Pages/Dashboard/settings/Calendar/Constants";

const CalendarPrimaryUrl = observer((props = {}) => {
  let pathUrlRef = useRef(null);

  const [domainUrl, setDomainUrl] = useState("");
  const [pathUrl, setPathUrl] = useState("");
  const [tempPathUrl, setTempPathUrl] = useState("");
  const [showEditInput, setShowEditInput] = useState(false);
  const [loader, setLoader] = useState(true);
  const [err, setErr] = useState("");

  const editAndFocusInput = () => {
    setShowEditInput(true);
    pathUrlRef && pathUrlRef.current && pathUrlRef.current.focus();
  };

  const saveCalendarBaseUrl = () => {
    if (!pathUrl?.trim()) {
      setErr("Please enter a value");
      return;
    } else if (!CALENDAR_BASEURL_REGEX.test(pathUrl)) {
      setErr("Please use only '-', '_', '~', '(', ')', '@' special characters");
      return;
    }
    let id = calendarState?.connectedAccount?.id;
    if (id) {
      setLoader(true);
      let tempUrl = `https://${domainUrl}/${pathUrl}`;
      calendarState.editCalendarBaseUrl({ id, url: tempUrl }, status => {
        setLoader(false);
        !status && editAndFocusInput();
      });
    }
  };

  const handleSubmitAction = (event = "") => {
    if (Utils.isSubmitKeyAction(event)) {
      saveCalendarBaseUrl();
    }
  };

  const handleInputChange = (_, value) => {
    err && setErr("");
    setPathUrl(value);
  };

  const inputObj = {
    name: `editCalendarDomainPath`,
    id: `editCalendarDomainPath`,
    type: "text",
    value: pathUrl,
    inputBoxRef: pathUrlRef,
    cbk: handleSubmitAction,
    change: handleInputChange,
    err,
    placeHolder: `Enter a base url`
  };

  const copyAction = () => {
    if (calendarState?.connectedAccount?.url) {
      Utils.copyToClipboard(calendarState?.connectedAccount?.url);
      Utils.mixpanelTrack(MXP_EVENT.ST_CLR_BASE_URL_COPIED, {
        pageType: `${PAGE_TYPE["setting"]} - Calender Base URL`
      });
      toasterState.setToastMsg("Calendar URL copied successfully.", "success");
    }
  };

  const handleIconAction = (event = "") => {
    let actionType =
      Utils.performActionByClosestDom(event, "i", "data-type")?.toLowerCase() ||
      "";
    switch (actionType) {
      case "edit":
        editAndFocusInput();
        break;
      case "copy":
        copyAction();
      default:
        break;
    }
  };

  const computeStateValue = () => {
    if (calendarState?.connectedAccount !== null) {
      const { url = "" } = calendarState.connectedAccount || {};
      if (url) {
        let tempUrl = url.replace(/(^\w+:|^)\/\//, "");
        if (tempUrl) {
          let baseUrlList = tempUrl.split("/");
          if (baseUrlList?.length >= 2) {
            setDomainUrl(baseUrlList?.[0]);
            setPathUrl(baseUrlList?.[1]);
            setTempPathUrl(baseUrlList?.[1]);
          }
        }
      } else {
        setDomainUrl("");
        setPathUrl("");
        setTempPathUrl("");
      }
      setLoader(false);
      setShowEditInput(false);
    }
  };

  const closeIcon = () => {
    setShowEditInput(false);
    setPathUrl(tempPathUrl);
  };

  useEffect(() => {
    setLoader(true);
    calendarState.getCalendarConnnectedAccount();
    return () => {
      setLoader(true);
      setDomainUrl("");
      setPathUrl("");
      setShowEditInput("");
      setErr("");
    };
  }, []);

  useEffect(() => {
    computeStateValue();
  }, [calendarState.connectedAccount]);

  return (
    <div className="calendarPrimaryUrl">
      {!loader && domainUrl && (
        <>
          <span className="staticDomain">{domainUrl} /</span>
          <div className={`pathValue ${showEditInput ? "editPathValue" : ""}`}>
            {showEditInput ? (
              <>
                <InputTextBox {...inputObj} />
                <i
                  className="material-icons-outlined clearIcon"
                  onClick={closeIcon}
                >
                  close
                </i>
                <i
                  className="material-icons-outlined saveIcon"
                  onClick={saveCalendarBaseUrl}
                >
                  check
                </i>
              </>
            ) : (
              <span className="staticPath">{pathUrl}</span>
            )}
          </div>
          <span
            className="baseUrlActionIcon"
            onClick={e => handleIconAction(e)}
          >
            {BASE_URL_ACTION_ICON_LIST?.map(item => (
              <ActionIcon {...item} key={item?.id} />
            ))}
          </span>
        </>
      )}
      {loader && <span className="baseUrlLoader linear-background" />}
    </div>
  );
});

export default CalendarPrimaryUrl;
