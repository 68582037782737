import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import { ReactComponent as VersionTwoIcon } from "Assets/svg/versionTwoIcon.svg";
import { ReactComponent as SmileyIcon } from "Assets/svg/smileyIcon.svg";
import { ReactComponent as CloseSvg } from "Assets/svg/close.svg";

const SwitchReportsVersion = (props = {}) => {
  const history = useHistory();
  const [isNewVersion, setIsNewVersion] = useState(
    history?.location?.pathname?.includes("reports-v2") ? true : false
  );
  
  let displayText = isNewVersion
    ? "Prefer the familiar look? Enjoy the classic experience with our original reports."
    : "Unlock deeper insights with Reports 2.0! Explore a wider range of data points for enhanced analysis.";

  const btnObj = {
    id: "redirectionBtn",
    name: "redirectionBtn",
    btnText: isNewVersion
      ? "Switch To Classic Reports"
      : "Switch To Reports 2.0",
    type: "button",
    className: `redirectionBtn ${isNewVersion ? "versionOneBtn" : ""}`,
    click: e => {
      Utils.preventDefaultFn(e);
      setIsNewVersion(!isNewVersion);
      isNewVersion ? history.push("/reports") : history.push("/reports-v2");
    }
  };

  const borderStyle = isNewVersion ? "1px solid #DDDDDD" : "1px solid #28AD56";

  return (
    <div className="reportsSwitchVersionCont">
      <div className="switchVersionInnerCont" style={{ border: borderStyle }}>
        <div className="displayTextCont">
          {isNewVersion ? <SmileyIcon /> : <VersionTwoIcon />}
          <div className="displayText">{displayText}</div>
        </div>
        <div className="btnCont">
          <Button {...btnObj} />
        </div>
      </div>
    </div>
  );
};

export default SwitchReportsVersion;
export { SwitchReportsVersion };
