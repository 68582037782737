import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

const getFailureResponseMsg = (
  bulkReponse = {},
  crmResponseType = "contact"
) => {
  const {
    failure = 0,
    mandatoryFieldMissing = 0,
    duplicate = 0
  } = bulkReponse || {};

  const leadOrContact =
    crmResponseType?.toLowerCase() === "lead" ? "lead" : "contact";

  return (
    <div>
      {mandatoryFieldMissing > 0 && (
        <span>{`${mandatoryFieldMissing} ${
          mandatoryFieldMissing > 1 ? `${leadOrContact}s` : leadOrContact
        } are missing mandatory fields`}</span>
      )}
      {mandatoryFieldMissing > 0 && <br />}
      {duplicate > 0 && (
        <span>{`${duplicate} ${
          duplicate > 1 ? `${leadOrContact}s are` : `${leadOrContact} is`
        } duplicated.`}</span>
      )}
      {duplicate > 0 && <br />}
      {failure > 0 && (
        <span>{`${failure} ${
          failure > 1 ? `${leadOrContact}s` : leadOrContact
        } failed due to an unknown error`}</span>
      )}
    </div>
  );
};

const MessageResponseHandler = observer(props => {
  const {
    bulkResponse = {},
    crmType = "hubspot",
    crmResponseType = "contact"
  } = props || {};
  const {
    success = 0,
    failure = 0,
    mandatoryFieldMissing = 0,
    duplicate = 0
  } = bulkResponse || {};

  const showMoreTextRef = React.createRef(null);
  const [showMoreText, setShowMoreText] = useState(false);
  const [showMoreHeight, setShowMoreHeight] = useState(false);

  let successMsg = "";
  let failureMsg = "";

  const totalFailureCount = failure + mandatoryFieldMissing + duplicate;
  const leadOrContact =
    crmResponseType?.toLowerCase() === "lead" ? "lead" : "contact";

  if (success > 0) {
    successMsg = `${success} ${
      success > 1 ? `${leadOrContact}s` : `${leadOrContact}`
    } exported to your ${crmType} CRM successfully.`;
  }
  if (totalFailureCount > 0) {
    const responseMsg = getFailureResponseMsg(bulkResponse, crmResponseType);
    failureMsg = (
      <>
        <span>{`Failed to export ${totalFailureCount} ${
          totalFailureCount > 1 ? `${leadOrContact}s` : leadOrContact
        }. `}</span>
        <span
          className="linkHighLight"
          onClick={() => {
            setShowMoreText(!showMoreText);
          }}
        >
          {showMoreText ? "Show less" : "Show more"}.
        </span>
        {showMoreText && (
          <>
            <div className="failureResponseMsg textLeft" ref={showMoreTextRef}>
              <div
                className="leftBorder"
                style={{ height: `${showMoreHeight}px` }}
              />
              {responseMsg}
            </div>
          </>
        )}
      </>
    );
  }

  useEffect(() => {
    setShowMoreHeight(showMoreTextRef?.current?.clientHeight);
  }, [showMoreTextRef]);

  return (
    <>
      {successMsg}
      {successMsg && <br />}
      {failureMsg}
    </>
  );
});

export { MessageResponseHandler };
