import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import { MP_EVENT } from "Static/constant";
import CrackerCelebrateRightImg from "Assets/Pricing/crackerCelebrate.png";
import CrackerCelebrateLeftImg from "Assets/Pricing/crackerCelebrateLeft.png";
import PricingDiscountBgImg from "Assets/Pricing/pricingDiscountBannerBg.png";
import Utils from "Utils/utils";

const PricingDiscountBanner = props => {
  let location = useLocation();

  const redirectToPricing = () => {
    if (!Utils.isAdmin()) {
      Utils.showMessagePopup();
    } else {
      Utils.mixpanelTrack(MP_EVENT.PRICING_DISCOUNT_BTN_CLICKED, {
        pageType: location.pathname
      });
      props.history.push("/pricing");
    }
  };

  return (
    <div className="pricingDiscountBanner">
      <img src={PricingDiscountBgImg} alt="" className="bgOverlay" />
      <div className="wrapContentImgBanner">
        <img src={CrackerCelebrateRightImg} alt="" className="celCapLeft" />
        <div className="wrapTextAndDiscountpercent">
          <div className="mediumText">Launch week!</div>
          <div className="discountWrap">
            <span className="number">50</span>
            <div className="percentOffWrap">
              <span className="symbol">%</span>
              <span className="offText">off</span>
            </div>
          </div>
          <div className="smallText">on first month subscription</div>
        </div>
        <div className="discountBtnWrap" onClick={redirectToPricing}>
          <span className="btnText">Get your discount</span>
          <span className="material-icons forwardArrow">arrow_forward</span>
        </div>
        <img src={CrackerCelebrateLeftImg} alt="" className="celCapRight" />
      </div>
    </div>
  );
};

export default withRouter(PricingDiscountBanner);
export { PricingDiscountBanner };
