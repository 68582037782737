import React from "react";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { userDetail } from "Stores/userDetail";
import { Utils } from "Utils/utils";
import WelcomePopupLottie from "Utils/LottieFiles/welcomePopupLottie.json";
import { Button } from "Components/common/Button";

class InitialWelcomePopupState {
  @observable showPopup = false;
  @observable restrictOnboardingPopup = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setRestrictOnboardingPopup = val => {
    this.restrictOnboardingPopup = val;
  };
}

const initialWelcomePopupState = new InitialWelcomePopupState();

const InitialWelcomePopup = props => {
  const {
    userInfo: {
      name: userName = "",
      firstName: userFirstName = "",
      lastName: userLastName = ""
    } = {}
  } = userDetail;
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: WelcomePopupLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const handleLetsGoBtnClick = () => {
    history.push("/settings/account");
    initialWelcomePopupState.setShowPopup(false);
    initialWelcomePopupState.setRestrictOnboardingPopup(true);
    if (window?.Intercom?.booted) {
      setTimeout(() => {
        return Utils.initiateIntercom("emailAccount");
      }, 1200);
    } else {
      setTimeout(() => {
        return Utils.initiateDriver(false, "emailAccount");
      }, 3000);
    }
  };

  return (
    initialWelcomePopupState.showPopup && (
      <section className="initialWelcomePopupWrapper">
        <div className="card">
          <header className="welcomePopupHeader">
            <h3 className="userName">{`Hey ${
              userName
                ? `${userFirstName || userLastName || userName || ""}!`
                : "there!"
            } Welcome aboard!`}</h3>
            <p className="popupText">
              We are thrilled to have you joining our 1,000+ sales superstars
              using Salesgear to generate more leads and hit their quota
              consistently.
            </p>
          </header>
          <Lottie
            options={defaultOptions}
            width={400}
            height={300}
            speed={1.5}
          />
          <footer className="welcomePopupFooter">
            <p className="popupText">
              Let's quickly get you started with Salesgear by sending your first
              email in less than 5 minutes.{" "}
            </p>
            <Button
              id={"letsGoBtn"}
              name="letsGoBtn"
              type="button"
              className="letsGoBtn"
              btnText="Let's go"
              icon={true}
              loader={false}
              iconName="arrow_right_alt"
              click={handleLetsGoBtnClick}
            />
          </footer>
        </div>
        <div className="common-popup-mask" />
      </section>
    )
  );
};

export { InitialWelcomePopup, initialWelcomePopupState };
export default observer(InitialWelcomePopup);
