import React, { useState } from "react";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import InputTextBox from "Components/common/InputTextBox";
import Button from "Components/common/Button";
import { captchaStoreValue } from "Stores/captcha";

const ChangePasswordComponent = props => {
  const { closeCbk } = props;

  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldpasswordErr, setOldPasswordErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [loader, setLoader] = useState(false);

  const bindPasswordInput = (id, value) => {
    if (id === "newPassword") {
      setNewPassword(value);
      setPasswordErr("");
    } else if (id === "oldPassword") {
      setOldPassword(value);
      setOldPasswordErr("");
    } else {
      setConfirmPassword(value);
      setConfirmPasswordErr("");
    }
  };

  const updatePasswordWithCaptcha = () => {
    captchaStoreValue.executeCaptcha(updatePassword);
  };

  const updatePassword = async captchaToken => {
    try {
      setLoader(true);
      let isNewPassword = Utils.validateStringLength(newPassword, 6);
      let isOldPassword = Utils.validateStringLength(oldPassword, 6);
      let isPasswordMatch = Utils.validateMatchPassword(
        newPassword,
        confirmPassword
      );
      let isOldNewPasswordSame = Utils.compareTwoString(
        oldPassword,
        newPassword
      );
      if (
        !isOldNewPasswordSame &&
        isNewPassword &&
        isOldPassword &&
        isPasswordMatch
      ) {
        const config = {
          url: URLS.updateUserPassword
            ? URLS.updateUserPassword + "?token=" + captchaToken
            : "",
          method: "POST",
          data: { oldPassword, newPassword }
        };
        let res = await makeApi(config);
        if (res && res.data && res.status === 200) {
          setLoader(false);
          closeCbk();
          toasterState.setToastMsg("Successfully Updated", "success");
        } else if (res.response.status === 400) {
          setLoader(false);
          setOldPasswordErr("Please check your password");
        } else {
          setLoader(false);
          toasterState.setToastMsg(
            "Something went wrong. Please try again later.",
            "fail"
          );
        }
      } else if (isOldNewPasswordSame) {
        setPasswordErr("Your new password cannot be same as old password");
        setLoader(false);
      } else if (!isOldPassword) {
        setOldPasswordErr("Password must be at least 6 characters");
        setLoader(false);
      } else if (!isNewPassword) {
        setPasswordErr("Password must be at least 6 characters");
        setLoader(false);
      } else if (!isPasswordMatch) {
        setConfirmPasswordErr("Password doesn't match");
        setLoader(false);
      }
    } catch (error) {
      console.error("Getting User Profile Data error ", error);
    }
  };

  const changePasswordSubmit = event => {
    Utils.preventDefaultFn(event);
    if (event.keyCode === 13) {
      updatePasswordWithCaptcha();
    }
  };

  return (
    <>
      <div className="changePasswordSection">
        <div className="card animated customzoomIn">
          <div className="cardTitle">
            <h4>Change Password</h4>
            <i
              className="material-icons closeBtn"
              onClick={e => {
                closeCbk(e);
              }}
            >
              close
            </i>
          </div>
          <div className="cardBody">
            <div className="popupBody">
              <InputTextBox
                name="oldPassword"
                id="oldPassword"
                text="Current Password"
                type="password"
                value={oldPassword}
                change={bindPasswordInput}
                err={oldpasswordErr}
                cbk={e => changePasswordSubmit(e)}
                readOnly={false}
              />
              <InputTextBox
                name="newPassword"
                id="newPassword"
                text="New Password"
                type="password"
                value={newPassword}
                change={bindPasswordInput}
                err={passwordErr}
                cbk={e => changePasswordSubmit(e)}
                readOnly={false}
              />
              <InputTextBox
                name="confirmPassword"
                id="confirmPassword"
                text="Confirm Password"
                type="password"
                value={confirmPassword}
                change={bindPasswordInput}
                err={confirmPasswordErr}
                cbk={e => changePasswordSubmit(e)}
                readOnly={false}
              />
              <div className="updateBtn">
                <Button
                  id="changePassword"
                  name="changePassword"
                  btnText="Update"
                  icon={false}
                  iconName=""
                  type="button"
                  className={`changePassword ${loader ? "loading" : ""}`}
                  loader={loader}
                  click={e => {
                    updatePasswordWithCaptcha(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overlayExpMask changePassword"></div>
    </>
  );
};

export default ChangePasswordComponent;
