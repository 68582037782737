/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { MP_EVENT, WEBSITE_URL } from "Static/constant";
import RocketImg from "Assets/png/rocket.png";

const EventRegistrationBanner = observer((props = {}) => {
  const { setEventRegistered = () => {} } = props || {};

  const hideBanner = (value = false) => {
    setEventRegistered(true);
    localStorage.setItem("outboundStrategyStartupMultiple", value);
    userDetail.setEventRegistered(true);
  };

  const registerNow = () => {
    Utils.mixpanelTrack(MP_EVENT.EVENT_PROMOTIONAL_BANNER_CLICKED, {
      propertyType:
        "Outbound Strategy for Startup Multiple 3 Million Dollar Deals",
      type: "Web App"
    });
    hideBanner(true);
    window.open(
      `${WEBSITE_URL}/events/outbound-strategy-for-startup-multiple-3-million-dollar-deals`,
      "_blank",
      "noopener"
    );
  };

  return (
    userDetail?.userInfo?.userLoggedIn !== "" && (
      <div id="eventRegistrationBanner" className="eventRegistrationBanner">
        <img src={RocketImg} alt="" />
        <div className="eventText">
          Don't Miss Out: Outbound Strategy that Helped Close Multiple $3M deals
          on 30th May
        </div>
        <div className="registerNow" onClick={registerNow}>
          Save my spot
        </div>
        <span
          className="material-icons closeBanner"
          onClick={() => hideBanner(false)}
        >
          close
        </span>
      </div>
    )
  );
});

export default EventRegistrationBanner;
export { EventRegistrationBanner };
