import React from "react";

export const ChatGptLoader = () => {
  return (
    <div className="rewriteAiInputCard animatedFast animatedDropDownReverse generateAiLoader">
      <div className="inputSearchBotWrapper">
        <div className="loadingBot loaderThreeDotWrapper">
          Writing assistant writing in progress
          <span className="loaderText">
            {Array.from({ length: 3 }, (_, index) => (
              <span className="loaderDot" key={index}>
                .
              </span>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatGptLoader;
