import React, { useEffect } from "react";
import { observer } from "mobx-react";
import PricingStore from "Stores/Pricing";
import Utils from "Utils/utils";
import PricingRouting from "Routes/PricingRouting";
import { MP_EVENT } from "Static/constant";

const PricingModule = observer(() => {
  const getSeatCount = async () => {
    let res = await Utils.getAllTeam();
    if (res?.data?.member?.length) {
      let count = 0;
      let { member = [] } = res.data || {};
      member.map(item => {
        item.status !== "INACTIVE" && count++;
      });
      PricingStore.setPaymentFormInfo({
        seats: count,
        initTeamSeat: count
      });
    } else {
      PricingStore.setPaymentFormInfo({
        seats: 1,
        initTeamSeat: 1
      });
    }
  };

  useEffect(() => {
    Utils.mixpanelTrack(MP_EVENT.PRICING_PAGE_VIEWED, {
      pageType: "Pricing"
    });
    getSeatCount();
  }, []);

  return (
    <div className="pricingSection">
      <PricingRouting />
    </div>
  );
});

export default PricingModule;
