import React from "react";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import ContactStageCardList from "./ContactStageCardList";
import TabNavigation from "Components/common/TabNavigation";
import StageRules from "./StageRules";

const SETTINGS_TAB_NAVIGATION = [
  { name: "Contact Stages", id: "contactStages", path: "all" },
  {
    name: "Stage Rules",
    id: "contactStageRules",
    path: "rules"
  }
];

const ContactStageRouting = () => {
  let { path = "" } = useRouteMatch();

  return (
    <div className="contactStageAndRules">
      <TabNavigation tabList={SETTINGS_TAB_NAVIGATION} />
      <Switch>
        <Route exact path={`${path}/all`} component={ContactStageCardList} />
        <Route exact path={`${path}/rules`} component={StageRules} />
        <Redirect from={path} to={`${path}/all`} />
      </Switch>
    </div>
  );
};

export default ContactStageRouting;
