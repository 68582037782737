import React from "react";
import Button from "Components/common/Button";
import ContactBookImg from "Assets/png/contactBookTick.png";
import ContactTickImg from "Assets/png/contactTick.png";
import ContactCrossImg from "Assets/png/contactCross.png";

const ExportSuccessCard = props => {
  const { cbk, data } = props;
  const { total = 0, success = 0, duplicate = 0, failure = 0 } = data;

  return (
    <>
      <div className="sfExportSuccessSection">
        <div className="card animated customzoomIn">
          <h6>Export to Salesforce</h6>
          <div className="imgAlign">
            <img src={ContactBookImg} alt="" />
          </div>
          <div className="expContactInfoBox" data-total={total}>
            {success > 0 && (
              <div className="expDetailInfo">
                <img src={ContactTickImg} alt="" />
                <div className="text">Successfully added Contacts</div>
                <div className="count success">
                  {success > 9 ? success : `0${success}`}
                </div>
              </div>
            )}
            {duplicate > 0 && (
              <div className="expDetailInfo">
                <img src={ContactCrossImg} alt="" />
                <div className="text">Duplicate Contacts</div>
                <div className="count failed">
                  {duplicate > 9 ? duplicate : `0${duplicate}`}
                </div>
              </div>
            )}
            {failure > 0 && (
              <div className="expDetailInfo">
                <img src={ContactCrossImg} alt="" />
                <div className="text">Failed Contacts</div>
                <div className="count failed">
                  {failure > 9 ? failure : `0${failure}`}
                </div>
              </div>
            )}
          </div>
          <Button
            id="doneBtn"
            name="doneBtn"
            btnText="Done"
            icon={false}
            iconName=""
            type="button"
            className="doneBtn"
            loader={false}
            click={cbk}
          />
        </div>
      </div>
      <div className="overlayExpMask"></div>
    </>
  );
};

export default ExportSuccessCard;
export { ExportSuccessCard };
