/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter, useParams } from "react-router-dom";
import RegisterHeader from "Pages/Register/RegisterHeader";
import RegisterFooter from "Pages/Register/RegisterFooter";
import { toasterState } from "Components/common/toaster";
import WaveLineImg from "Assets/Register/line-wave-bg.png";
import { URLS, makeApi } from "Utils/apiURL";
import JumpBallLoader from "Components/common/JumpBallLoader";
import { userDetail } from "Stores/userDetail";

const TeamInviteAccept = props => {
  let { id = "" } = useParams();

  const getFullName = (firstName = "", lastName = "", email = "") => {
    return firstName && lastName ? `${firstName} ${lastName}` : email;
  };

  const acceptTeamInvitation = async (email, inviteToken) => {
    try {
      const config = {
        url: URLS.acceptTeamInvite,
        method: "POST",
        data: {
          email,
          inviteToken
        }
      };
      let res = await makeApi(config);
      if (res?.data?.status === "success") {
        props.history.push("/teamInvite/success");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please contact us at support@salesgear.io",
          "fail"
        );
        props.history.push("/sequences");
      }
    } catch (err) {
      console.error("Team invitation signup accept err", err);
    }
  };

  const validateTeamInviteToken = async () => {
    const config = {
      url: URLS.validateInviteToken,
      method: "POST",
      data: { inviteToken: id }
    };
    let res = await makeApi(config);
    if (res?.data) {
      const {
        valid,
        email,
        newUser,
        firstName,
        lastName,
        adminFirstName,
        adminLastName,
        adminEmail
      } = res.data;
      if (valid && adminEmail) {
        let name = getFullName(firstName, lastName, email);
        let adminName = getFullName(adminFirstName, adminLastName, adminEmail);
        let teamInvite = {
          name,
          email,
          adminName,
          adminEmail,
          inviteToken: id,
          newUser
        };
        localStorage.setItem("inviteTeam", JSON.stringify(teamInvite));
        userDetail.setUserTeamInfo(teamInvite);
        await acceptTeamInvitation(email, id);
      } else {
        props.history.push("/sequences");
      }
    } else {
      props.history.push("/sequences");
    }
  };

  useEffect(() => {
    validateTeamInviteToken();
  }, []);

  return (
    <section id="userRegister">
      <div className="container">
        <RegisterHeader />
        <div className="register-body teamPageLoader">
          <JumpBallLoader color="white" />
        </div>
        <RegisterFooter teamPath={true} />
        <img className="bg-wave-line" src={WaveLineImg} alt="" />
      </div>
    </section>
  );
};

export default withRouter(observer(TeamInviteAccept));
