import React from "react";
import { ReactComponent as ActionSvg } from "Assets/svg/triggers/settingAction.svg";
import { ReactComponent as ConditionsSvg } from "Assets/svg/triggers/conditions.svg";

const LabelText = props => {
  const { name = "", id = "", error = "" } = props || {};

  const svgIcon = {
    actions: <ActionSvg />,
    conditions: <ConditionsSvg />
  };

  return (
    <div className="labelText">
      {svgIcon[id] || ""}
      <span className="titleText">{name}</span>
      {error && <span className="labelErr">{error}</span>}
    </div>
  );
};

export default LabelText;
