import React from "react";

const ReportsTableLoader = props => {
  const {
    count = 0,
    isInsight = false,
    isSummary = false,
    calendarEnabled = false
  } = props;
  return (
    <>
      {Array.from({ length: count }, (value, index) => (
        <div className="tableRowLoader" key={index}>
          {!isInsight && (
            <div className="teamMember">
              <p className="fullName linear-background" />
              <h6 className="emailAddress linear-background">&nbsp;</h6>
            </div>
          )}
          {isInsight && (
            <div className="sequenceName">
              <span className="linear-background" />
            </div>
          )}
          <div className="contactsAdded">
            <span className="linear-background" />
          </div>
          <div className="emailsSent">
            <span className="linear-background" />
          </div>
          {isInsight && (
            <div className="openRate">
              <span className="linear-background" />
            </div>
          )}
          {isInsight && (
            <div className="clickRate">
              <span className="linear-background" />
            </div>
          )}
          <div className="replyRate">
            <span className="linear-background" />
          </div>
          {isInsight && (
            <div className="bouncesCount">
              <span className="linear-background" />
            </div>
          )}
          {/* <div className="optOutsCount">
            <span className="linear-background" />
          </div> */}
          {isSummary && (
            <div className="callsMade">
              <span className="linear-background" />
            </div>
          )}
          {isSummary && (
            <div className="callsConnected">
              <span className="linear-background" />
            </div>
          )}
          <div className="tasksCompleted">
            <span className="linear-background" />
          </div>
        </div>
      ))}
    </>
  );
};

export default ReportsTableLoader;
export { ReportsTableLoader };
