import React from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { linkedInReplyState } from "../state";
import { ReactComponent as LinkedinIcon } from "../svg/linkedIn.svg";
import {
  LinkedInPopupHeader,
  LinkedInPopupFooter
} from "../common/LinkedInPopupHeaderFooter";

const LinkedInNotConnectedPopup = observer(() => {
  const handleButtonClick = () => {
    Utils.windowRedirect("https://www.linkedin.com");
  };

  const closeIconCbk = () => {
    linkedInReplyState.setShowLinkedInNotConnectedPopup(false);
  };

  const btnProps = {
    id: "linkedInSigninBtn",
    name: "linkedInSigninBtn",
    btnText: "Sign in",
    type: "button",
    className: "linkedInSigninBtn",
    customIcon: true,
    isLeftSideIcon: true,
    LeftIconName: LinkedinIcon,
    click: handleButtonClick
  };

  return (
    linkedInReplyState.showLinkedInNotConnectedPopup && (
      <div className="linkedInNotConnectedWrapper">
        <div className="card">
          <LinkedInPopupHeader closeIconCbk={closeIconCbk} />
          <div className="linkedInBody">
            <p className="noLinkedInText">{linkedInReplyState.linkedInMsg}</p>
          </div>
          <LinkedInPopupFooter hasButton={true} btnProps={btnProps} />
        </div>
        <div className="common-popup-mask" onClick={closeIconCbk} />
      </div>
    )
  );
});

export { LinkedInNotConnectedPopup };
export default LinkedInNotConnectedPopup;
