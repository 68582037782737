import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import UserDropdown from "Components/common/UserDropdown";
import SearchInputByKeyword from "Components/common/SearchInputByKeyword";
import { USER_SELECT_DROPDOWN_LIST as userObjList } from "Static/constant";
import Utils from "Utils/utils";
import domainHealthCenterStore from "./store";

const SearchAndFilter = observer((props = {}) => {
  const { updatedInboxList = [], setUpdatedInboxList = () => {} } = props || {};
  const {
    team = false,
    hasTeamMember = false,
    isTeamOwner = false,
    memberId = ""
  } = userDetail?.userInfo || {};
  const { noOfContactsAddedInTeam } = userDetail?.userFeatureFlag || {};
  const [teamList, setTeamList] = useState("");
  const [teamLoader, setTeamLoader] = useState(true);
  const [userFilterList, setUserFilterList] = useState(userObjList);

  const getSelectedUserPayload = data => {
    return typeof data === "object"
      ? data?.length && data
      : data?.length && data?.toLowerCase() === "you"
      ? new Array(memberId)
      : "";
  };

  const makeFilterRequest = (user = {}) => {
    const payload = {};
    const selectedIds = getSelectedUserPayload(user);
    if (!team) {
      payload.memberIds = [memberId];
    } else if (selectedIds?.length) {
      payload.memberIds = selectedIds;
    }
    return payload;
  };

  const getTeamList = async () => {
    const resData = await Utils.userTeamList();
    userDetail.setUserInfo({
      ...userDetail?.userInfo,
      hasTeamMember: resData?.length ? resData : false
    });
    setTeamList(resData);
    setTeamLoader(false);
  };

  const resetUserFilterOption = () => {
    const newArr = [...userFilterList];
    newArr.map(item => (item.checked = newArr?.id === "you"));
    setUserFilterList(newArr);
    domainHealthCenterStore.setUserFilter({});
  };

  const handleSearchByKeyword = (searchEmail = "") => {
    if (searchEmail) {
      const temp = domainHealthCenterStore?.allConnectedAccounts.filter(item =>
        item?.email?.includes(searchEmail)
      );
      setUpdatedInboxList(temp);
    } else {
      setUpdatedInboxList(domainHealthCenterStore?.allConnectedAccounts);
    }
  };

  const selectedUserCbk = value => {
    const payload = makeFilterRequest(value);
    domainHealthCenterStore.setUserFilter(payload);
    domainHealthCenterStore.setCurrentPage(1);
    domainHealthCenterStore.setConnectedAccountsLoading(true);
    domainHealthCenterStore.fetchAllConnectedEmails();
    domainHealthCenterStore.getDomainHealthScore();
  };

  useEffect(() => {
    getTeamList();
    return () => {
      resetUserFilterOption();
    };
  }, []);

  return (
    <div className="searchAndFilterWrapper">
      <SearchInputByKeyword
        id="searchInbox"
        placeholder="Search Inbox"
        handleSearch={handleSearchByKeyword}
      />
      {((team && hasTeamMember?.length > 1) || isTeamOwner) && (
        <UserDropdown
          team={team}
          teamList={teamList}
          teamLoader={teamLoader}
          selectedUserCbk={selectedUserCbk}
          isContactsAdded={noOfContactsAddedInTeam > 0}
          userFilterList={Utils.filterByUserTeamFilterList(userFilterList)}
        />
      )}
    </div>
  );
});

export { SearchAndFilter };
export default SearchAndFilter;
