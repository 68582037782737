/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import DialerServiceData from "Stores/DialerServiceStore";
import DropDownMenu from "Components/common/DropDownMenu";
import { toasterState } from "Components/common/toaster";

const DialerDefaultNumber = props => {
  const { calling = false } = props;
  const [loader, setLoader] = useState(true);
  const [phoneNoList, setPhoneNoList] = useState([]);
  const [defaultNo, setDefaultNo] = useState("");
  const [showDropdownMenu, setDropdownMenu] = useState(false);

  const showDropdownPhoneList = () => {
    setDropdownMenu(true);
  };

  const hideDefaultNoList = () => {
    setDropdownMenu(false);
  };

  const updateDefaultNo = (referenceId, item) => {
    toasterState.setToastMsg("Please wait...", "info");
    DialerServiceData.dialerBtnLoader = true;
    DialerServiceData.updateDefaultPhoneNumber({ ph_number: item.value });
    // setDefaultNo(item.value);
    setLoader(true);
    setDropdownMenu(false);
  };

  const getDialerDefaultNo = () => {
    if (DialerServiceData.dialerDefaultNo) {
      setDefaultNo(DialerServiceData.dialerDefaultNo);
      setLoader(false);
    }
  };

  const constructDropdownList = phoneList => {
    const newPhoneList = [];
    phoneList?.length &&
      phoneList.map((item, index) => {
        let newObj = { icon: "", method: updateDefaultNo };
        newObj.value = item;
        newObj.key = `phoneNumber${index + 1}`;
        newPhoneList.push(newObj);
      });
    setPhoneNoList(newPhoneList);
  };

  const purchaseNumberRedirect = () => {
    props.history.push("/settings/dialer/general");
  };

  useEffect(() => {
    if (
      DialerServiceData.dialerPhoneList &&
      DialerServiceData.dialerPhoneList.length > 0
    ) {
      constructDropdownList(DialerServiceData.dialerPhoneList);
    }
  }, [DialerServiceData.dialerPhoneList]);

  useEffect(() => {
    setLoader(DialerServiceData.dialerBtnLoader);
  }, [DialerServiceData.dialerBtnLoader]);

  useEffect(() => {
    getDialerDefaultNo();
  }, [DialerServiceData.dialerDefaultNo]);

  useEffect(() => {
    DialerServiceData.getDialerPhoneList(true);
  }, []);

  return (
    <div className="dsDefaultNoSection">
      <div className="dsWrapDefautContact">
        <div className="text">From:</div>
        {!loader && (
          <>
            {defaultNo && defaultNo !== "purchaseNumber" && (
              <div
                onClick={() => {
                  !calling && showDropdownPhoneList();
                }}
                className="number"
              >
                <span>{defaultNo}</span>
                {!calling && (
                  <i className="material-icons">
                    {!showDropdownMenu
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_up"}
                  </i>
                )}
              </div>
            )}
            {defaultNo === "purchaseNumber" && (
              <div onClick={purchaseNumberRedirect} className="purchaseNumber">
                Purchase Number
              </div>
            )}
          </>
        )}
        {loader && <span className="defaultNoLoader linear-background"></span>}
        {showDropdownMenu && (
          <>
            <DropDownMenu
              options={phoneNoList}
              selected={defaultNo}
              direction="center"
              type="DIALER"
            />
            <div className="overlayDefaultNo" onClick={hideDefaultNoList} />
          </>
        )}
      </div>
    </div>
  );
};
export default withRouter(observer(DialerDefaultNumber));
export { DialerDefaultNumber };
