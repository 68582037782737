import React, { useState } from "react";
import { ReactComponent as ThunderBlueIcon } from "Assets/svg/thunderBlue.svg";
import { PAYWALL_CONTENT } from "Model/model";
import UpgradeTooltip from "../Paywall/UpgradeTooltip";

const NumberInputBox = props => {
  const {
    type = "number",
    id = "",
    changeCbk = () => {},
    min = "",
    max = "",
    name = "",
    value = "",
    label = "",
    subLabel = "",
    placeholder = "",
    subText = "",
    errMsg = "",
    readOnly = false,
    submitCbk = () => {},
    className = "",
    loader = true,
    restrictSettings = false,
    upgradePlanType = "",
    appendPercentage = false
  } = props;
  const [showUpgradeTp, setShowUpgradeTp] = useState();

  return (
    <div
      className={`numberInputBox ${readOnly ? "pointerUserSelectNone" : ""} ${
        loader ? "numberInputBoxLoader" : ""
      } ${id}`}
    >
      {(!loader && (
        <>
          <label htmlFor={name}>
            {label}
            {subLabel && (
              <span className="numberInputBoxSubLabel">{subLabel}</span>
            )}
          </label>
          {subText && <p className="subTextForLabel">{subText}</p>}
          <div
            className="numberInputBoxWrapper"
            onMouseEnter={() => setShowUpgradeTp(true)}
            onMouseLeave={() => setShowUpgradeTp(false)}
          >
            <input
              id={id}
              type={type}
              name={name}
              min={min}
              value={value}
              className={className}
              max={max}
              placeholder={placeholder}
              onChange={e => changeCbk(e.currentTarget.value)}
              readOnly={readOnly}
              onKeyUp={e => submitCbk(e)}
              disabled={restrictSettings}
            />
            {appendPercentage && <span className="percentageSymbol">%</span>}
            {restrictSettings && <ThunderBlueIcon className="thunderSvg" />}
            {showUpgradeTp && restrictSettings && (
              <UpgradeTooltip {...PAYWALL_CONTENT[upgradePlanType]} />
            )}
          </div>
          {errMsg && <span className="errMsgText">{errMsg}</span>}
        </>
      )) || (
        <>
          <div className="labelLoader linear-background" />
          <div className="inputBoxLoader linear-background" />
        </>
      )}
    </div>
  );
};

export default NumberInputBox;
