import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import PaginationComponent from "Components/common/PaginationComponent";
import { trackingStore } from "./trackingStore";
import VisitorsDataTable from "./VisitorsDataTable";

const VisitorsTableHeader = () => {
  const visitorDataHeaders = [
    "User",
    "Company",
    "Location",
    "Date",
    "Time",
    "Duration",
    "Session Details"
  ];

  return (
    <thead>
      <tr
        className={`vistorsTableHeaders ${
          trackingStore?.isWebsiteTrackingEnabled ? "" : "disabled"
        }`}
      >
        {visitorDataHeaders.map((item, index) => {
          return (
            <th
              className={`${
                ["session details"].includes(item?.toLowerCase())
                  ? "sessionDetailHeader"
                  : ""
              }`}
              key={`${item}-${index}`}
            >
              {item}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const VisitorsTableLoader = ({ length = 8 }) => {
  const visitorsInfo = [
    "name",
    "domain",
    "country",
    "startDate",
    "startTime",
    "time",
    "nextIcon"
  ];
  return Array.from({ length }, (value, index) => (
    <tbody>
      <tr className="vistorsTableBodyRow bodyloader" key={index}>
        {visitorsInfo.map((item, index) => {
          return <td key={index} className={`${item} linear-background`}></td>;
        })}
      </tr>
    </tbody>
  ));
};

const VistorsDataComponent = observer((props = {}) => {
  const { websiteVisitorTrackingEnabled = false } = props || {};

  const paginate = pageNoObj => {
    let pageNo = pageNoObj?.selected + 1 || 1;
    trackingStore.setCurrentPage(pageNo);
  };

  const getVistorsTableData = async () => {
    let data = {
      ...trackingStore.dateFilter,
      pageNo: trackingStore?.currentPage,
      limit: 10
    };
    await trackingStore.getTableData(data);
  };

  useEffect(() => {
    if (
      trackingStore?.isWebsiteTrackingEnabled &&
      websiteVisitorTrackingEnabled
    ) {
      getVistorsTableData();
    }
  }, [
    trackingStore?.isWebsiteTrackingEnabled,
    trackingStore?.currentPage,
    trackingStore?.dateFilter
  ]);

  return (
    <div className="visitorsDataSection">
      <div
        className={`tabletitle ${
          trackingStore?.isWebsiteTrackingEnabled ? "" : "disabled"
        }`}
      >
        Visitors Data
      </div>
      <div className="visitorsDataTable">
        <table>
          <VisitorsTableHeader />
          <tbody className="vistorsTableBody">
            <VisitorsDataTable />
          </tbody>
          {trackingStore?.dataloader &&
            trackingStore?.isWebsiteTrackingEnabled && <VisitorsTableLoader />}
        </table>
        {trackingStore?.isWebsiteTrackingEnabled && (
          <PaginationComponent
            callback={paginate}
            totalPages={trackingStore?.totalPages || 1}
            currentPage={trackingStore?.currentPage || 1}
            loading={trackingStore?.dataloader}
          />
        )}
      </div>
    </div>
  );
});

export default VistorsDataComponent;
export { VistorsDataComponent };
