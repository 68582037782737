import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import PaywallUtils from "Utils/PaywallUtils";
import DateUtils from "Utils/DateUtils";
import { LEARN_MORE_LINK } from "Static/constant";
import userDetail from "Stores/userDetail";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import HeaderLayout from "Components/LoggedInLayout/HeaderLayout";
import { trackingStore } from "./trackingStore";
import ViewComponent from "./ViewComponent";
import VisitAnalyticsComponent from "./VisitAnalyticsComponent";
import VistorsDataComponent from "./VisitorsDataComponent";
import VisitorsDetailedInfoHeader from "./visitorsDetailedInfoHeader";
import VisitorDetails from "./visitorDetails";
import SessionDetails from "./sessionDetails";

const Tracking = observer(() => {
  const {
    inFreePlan = false,
    planName = "",
    websiteVisitorTrackingEnabled = false
  } = userDetail?.userFeatureFlag || {};
  const [scrollBlock, setScrollBlock] = useState(false);

  const headerObj = {
    redirectUrl: LEARN_MORE_LINK.calls,
    breadcrumbArr: [
      {
        name: "Website Tracking",
        link: "/website-tracking",
        hasBetaBadge: true
      }
    ]
  };

  const checkWebsiteTrackingEnabled = () => {
    if (!websiteVisitorTrackingEnabled) {
      PaywallUtils.upgradeUtilsFunction(
        "websiteTracking",
        false,
        planName,
        inFreePlan
      );
    }
    Utils.mixpanelTrack(MXP_EVENT.WEBSITE_TRACKING_PAGE_VISITED, {
      pageType: PAGE_TYPE["tracking"]
    });
  };

  useEffect(() => {
    checkWebsiteTrackingEnabled();
    return () => {
      trackingStore.setCurrentPage(1);
      trackingStore.setActiveDateTab("today");
      trackingStore.setDateFilter(DateUtils.getTodayTimeStamp());
      trackingStore.setCustomDate("Custom");
      trackingStore.setViewDetailedVisitorsInfo(false);
    };
  }, []);

  return (
    <div
      className={`tracking ${websiteVisitorTrackingEnabled ? "" : "blurBg"}`}
    >
      <HeaderLayout {...headerObj} hideLearnMore={true} />
      <div className="bodySection">
        {!trackingStore?.viewDetailedVisitorsInfo && (
          <div className="visitorsInfoSection">
            <ViewComponent />
            <VisitAnalyticsComponent
              websiteVisitorTrackingEnabled={websiteVisitorTrackingEnabled}
            />
            <VistorsDataComponent
              websiteVisitorTrackingEnabled={websiteVisitorTrackingEnabled}
            />
          </div>
        )}
        {trackingStore?.viewDetailedVisitorsInfo && (
          <div className="visitorsDetailedInfoSection">
            <VisitorsDetailedInfoHeader
              scrollBlock={scrollBlock}
              setScrollBlock={setScrollBlock}
            />
            <div className="visitorsDetailedInfoBody">
              <VisitorDetails
                scrollBlock={scrollBlock}
                setScrollBlock={setScrollBlock}
              />
              <SessionDetails />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default Tracking;
export { Tracking };
