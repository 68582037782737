import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { toasterState } from "Components/common/toaster";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import makeApi, { URLS } from "Utils/apiURL";
import Button from "Components/common/Button";
import OveriewTable from "./OveriewTable";
import OverviewTableLoader from "./OverviewTableLoader";
import { TriggersContext } from "Pages/Dashboard/settings/Triggers/Triggers";
import { triggerState } from "Pages/Dashboard/settings/Triggers/TriggerState";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { KnowMoreButton } from "Components/common/KnowMoreButton";
import PaywallUtils from "Utils/PaywallUtils";

const TriggersOverview = observer(props => {
  const {
    setShowOverview = () => {},
    setShowAddTrigger = () => {},
    triggersEnabled = false,
    planName = "",
    inFreePlan = false
  } = props || {};

  const { loader = false, setLoader = () => {} } = useContext(TriggersContext);

  const limit = 10;

  const [data, setData] = useState("");
  const [pageNo, setPageNo] = useState(1);

  const paginate = pageNoObj => {
    if (!loader) {
      setLoader(true);
      const payload = {
        limit,
        pageNo: pageNoObj.selected + 1 || 1
      };
      setPageNo(pageNoObj?.selected + 1 || 1);
      getTriggersData(payload);
    }
  };

  const getTriggersData = async payload => {
    try {
      setLoader(true);
      const config = {
        url: URLS.listAllTriggers,
        method: "POST",
        data: payload
      };
      let response = await makeApi(config);
      if (response?.data) {
        setData(response.data);
      } else {
        Utils.showApiFailureResponse(response);
      }
      setLoader(false);
    } catch (e) {
      console.error("Listing all triggers error", e);
    }
  };

  const addTrigger = () => {
    Utils.mixpanelTrack(MP_EVENT.ADD_TR_CLICKED, {
      pageType: "Setting Triggers"
    });
    triggerState.setEditInputData({});
    if (triggersEnabled) {
      triggerState.setIsEdit(false);
      setShowOverview(false);
      setShowAddTrigger(true);
    } else {
      upgradeTriggerFunction("Add Trigger");
    }
  };

  const setTableData = (id, item, loading = false) => {
    if (data?.triggers?.length > 0) {
      let tempData = [...data?.triggers];
      tempData = tempData.map(obj => {
        if (obj.trigger.id === id) {
          obj.trigger.toggleLoading = loading;
        }
        return obj;
      });
      setData({ ...data, triggers: tempData });
    }
  };

  const constructPayload = (payload, type) => {
    const {
      trigger = {},
      triggerConditions = {},
      conditionGroups = {}
    } = payload || {};
    if (type === "clone") {
      let count = Utils.copyCount(trigger?.name || "", "copy", "-");
      trigger.status = "IN_ACTIVE";
      trigger.name =
        count === 1
          ? trigger?.name?.replace(`Copy`, `Copy(${count})`)
          : count > 1
          ? trigger?.name?.replace(`Copy(${count - 1})`, `Copy(${count})`)
          : `Copy - ${trigger.name}`;
      delete trigger.id;
      delete conditionGroups.id;
      delete triggerConditions.id;
    } else if (type === "status") {
      trigger.status = trigger?.status === "ACTIVE" ? "IN_ACTIVE" : "ACTIVE";
    }
    return {
      triggers: trigger,
      groups: [
        {
          triggerConditions,
          conditionGroups
        }
      ]
    };
  };

  const updateTrigger = async (id, payload) => {
    try {
      const config = {
        url: URLS.updateTrigger,
        method: "PUT",
        data: constructPayload(payload, "status")
      };
      let response = await makeApi(config);
      if (response?.data) {
        toasterState.setToastMsg("Trigger updated successfully ", "success");
        setTableData(id, response.data);
      } else {
        Utils.showApiFailureResponse(response);
      }
    } catch (e) {
      console.error("Listing all triggers error", e);
    }
  };

  const toggleTrigger = (event, id, item) => {
    Utils.preventDefaultFn(event);
    if (triggersEnabled) {
      setTableData(id, item, true);
      updateTrigger(id, item);
    } else {
      upgradeTriggerFunction("Activate Trigger");
    }
  };

  const editTrigger = (e, index) => {
    Utils.preventDefaultFn(e);
    setShowOverview(false);
    setShowAddTrigger(true);
    triggerState.setEditInputData(data?.triggers?.[index]);
    triggerState.setIsEdit(true);
  };

  const cloneTrigger = async (e, payload) => {
    if (triggersEnabled) {
      try {
        setLoader(true);
        const config = {
          url: URLS.createTrigger,
          method: "POST",
          data: constructPayload(payload, "clone")
        };
        let response = await makeApi(config);
        if (response?.data) {
          getTriggersData({
            limit,
            pageNo
          });
          toasterState.setToastMsg("Trigger cloned successfully ", "success");
        } else {
          Utils.showApiFailureResponse(response);
          setLoader(false);
        }
      } catch (e) {
        console.error("Duplicating trigger failed", e);
      }
    } else {
      upgradeTriggerFunction("cloneTrigger");
    }
  };

  const upgradeTriggerFunction = (triggerTypeValue = "") => {
    PaywallUtils.upgradeUtilsFunction("triggers", false, planName, inFreePlan, {
      triggerType: triggerTypeValue
    });
  };

  const deleteTrigger = async (event, payload) => {
    Utils.preventDefaultFn(event);

    const deleteConfirmationCbk = async () => {
      try {
        const id = payload?.trigger?.id || "";
        if (id) {
          setLoader(true);
          let url = URLS.deleteTrigger.replace("<<triggerId>>", id);
          const config = {
            url,
            method: "DELETE"
          };
          let response = await makeApi(config);
          confirmationPopupState.setShowPopup(false);
          if ([200, "200"].includes(response?.status)) {
            getTriggersData({
              limit,
              pageNo
            });
            toasterState.setToastMsg(
              "Trigger deleted successfully ",
              "success"
            );
          } else {
            Utils.showApiFailureResponse(response);
            setLoader(false);
          }
        } else {
          toasterState.setToastMsg("No such trigger found", "fail");
        }
      } catch (e) {
        console.error("Duplicating trigger failed", e);
      }
    };

    confirmationPopupState.setPopupValues({
      title: "Are you sure?",
      content: "Are you sure you want to delete this trigger?",
      actionBtnText: "Yes",
      loadingMsg: "Please wait...",
      callback: deleteConfirmationCbk
    });
    confirmationPopupState.setShowPopup(true);
  };

  useEffect(() => {
    getTriggersData({
      limit,
      pageNo
    });
    return () => {
      setLoader(true);
    };
  }, []);

  return (
    <>
      <div className="overviewTab">
        <div className="overviewHeader">
          <h6 className="titleHeader">Triggers</h6>
          {!loader && data?.triggers && (
            <div className="flexEnd">
              <KnowMoreButton
                redirectUrl={
                  "https://support.salesgear.io/en/articles/5697463-an-overview-of-triggers-in-salesgear"
                }
              />
              <Button
                id="addTrigger"
                name="addTrigger"
                btnText="Add Trigger"
                type="button"
                className={`addTrigger`}
                click={addTrigger}
              />
            </div>
          )}
        </div>
        <div className="overviewTableSection">
          {!loader && data?.triggers && (
            <OveriewTable
              data={data}
              loader={loader}
              edit={editTrigger}
              clone={cloneTrigger}
              remove={deleteTrigger}
              toggleTrigger={toggleTrigger}
              paginate={paginate}
              addTrigger={addTrigger}
              triggersEnabled={triggersEnabled}
            />
          )}
          {loader && <OverviewTableLoader />}
        </div>
      </div>
    </>
  );
});

export default TriggersOverview;
export { TriggersOverview };
