import GoogleBtnImg from "Assets/Settings/googleWhite.png";
import MicrosoftBtnImg from "Assets/Settings/microsoft.png";

export const CALENDAR_BASEURL_REGEX = /^[A-Za-z0-9-_~()@]+$/;

export const CALENDAR_NAVIGATION = [
  {
    name: "Events",
    id: "calendarEvents",
    path: "events"
  },
  {
    name: "Configuration",
    id: "calendarConfiguration",
    path: "configuration"
  }
];

export const EVENT_ACTION_ICON_LIST = [
  {
    id: "deleteEvent",
    iconName: "delete",
    type: "delete",
    title: "Delete"
  },
  {
    id: "cloneEvent",
    iconName: "file_copy",
    type: "clone",
    title: "Clone"
  },
  {
    id: "editEvent",
    iconName: "edit",
    type: "edit",
    title: "Edit"
  },
  {
    id: "copyEventLink",
    iconName: "link",
    type: "link",
    title: "Click to copy URL"
  }
];

export const INBOX_ACCOUNT_LIST = [
  {
    id: "googleAuthBtn",
    className: "googleAuthBtn",
    src: GoogleBtnImg,
    alt: "Sign in with Google",
    inboxType: "gmail"
  },
  {
    id: "outlookAuthBtn",
    className: "outlookAuthBtn",
    src: MicrosoftBtnImg,
    alt: "Sign in with Microsoft",
    inboxType: "outlook"
  }
];

export const BASE_URL_ACTION_ICON_LIST = [
  {
    id: "copyCalendarDomain",
    iconName: "copy",
    type: "copy",
    title: "Click to copy URL"
  },
  {
    id: "editCalendarDomain",
    iconName: "edit",
    type: "edit",
    title: "Edit"
  }
];

export const REMINDER_IN_MINS = ["10", "15", "20", "30", "45", "60"];

export const MEETING_DURATION = ["15", "20", "30", "45", "60"];

export const PRE_BOOK_DAYS_AVAILABLE = ["7", "30", "60"];

export const START_BUFFER_DURATION = [
  { name: "Minutes", value: "MINUTES" },
  { name: "Hours", value: "HOURS" },
  { name: "Days", value: "DAYS" }
];

export const CALENDAR_MSG_TEXT = {};

export const meetingBufferDropdownOption = [
  {
    name: "5 min",
    value: "5"
  },
  {
    name: "10 min",
    value: "10"
  },
  {
    name: "15 min",
    value: "15"
  },
  {
    name: "30 min",
    value: "30"
  },
  {
    name: "45 min",
    value: "45"
  },
  {
    name: "1 hr",
    value: "60"
  },
  {
    name: "1hr 30min",
    value: "90"
  },
  {
    name: "2 hrs",
    value: "120"
  },
  {
    name: "2hrs 30min",
    value: "150"
  }
];

export const QUICK_SELECT_SCHEDULE_HOUR_LIST = [
  {
    name: "09:00 AM to 05:00 PM (Mon - Fri)",
    id: "weekDays",
    days: 5
  },
  {
    name: "09:00 AM to 05:00 PM (Mon - Sun)",
    id: "fullWeek",
    days: 7
  },
  {
    name: "12:00 AM to 11:45 PM (Mon - Sun)",
    id: "fullWeekHalfDayAndNight",
    days: 7
  },
  { name: "Custom", id: "customWeek", days: 5 }
];

export const SCHEDULE_SELECT_VALUE = {
  weekDays: {
    1: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    2: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    3: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    4: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    5: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ]
  },
  fullWeek: {
    1: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    2: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    3: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    4: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    5: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    6: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    7: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ]
  },
  fullWeekHalfDayAndNight: {
    1: [
      {
        fromTime: "00:00",
        toTime: "23:45"
      }
    ],
    2: [
      {
        fromTime: "00:00",
        toTime: "23:45"
      }
    ],
    3: [
      {
        fromTime: "00:00",
        toTime: "23:45"
      }
    ],
    4: [
      {
        fromTime: "00:00",
        toTime: "23:45"
      }
    ],
    5: [
      {
        fromTime: "00:00",
        toTime: "23:45"
      }
    ],
    6: [
      {
        fromTime: "00:00",
        toTime: "23:45"
      }
    ],
    7: [
      {
        fromTime: "00:00",
        toTime: "23:45"
      }
    ]
  },
  customWeek: {
    1: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    2: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    3: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    4: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ],
    5: [
      {
        fromTime: "09:00",
        toTime: "17:00"
      }
    ]
  }
};
