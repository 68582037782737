import React from "react";
import Lottie from "react-lottie";
import {observer} from "mobx-react";
import {observable, action} from "mobx";
import AddContactsToListLottie from "Utils/LottieFiles/CustomListLoader";
import BulkUploader from "Utils/LottieFiles/BulkUploader";

const animation = {
  addContacts: AddContactsToListLottie,
  bulkUpload: BulkUploader
};

class PopupLoaderState {
  @observable showPopup = false;
  @observable data = {message: "", type: "", speed: 1.5};

  @action
  setData = val => {
    this.data = val;
  };

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };
}

const popupLoaderState = new PopupLoaderState();

const PopupLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation[popupLoaderState?.data?.type],
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    popupLoaderState?.showPopup && (
      <section className="addtoListPopupWrapper">
        <div className="card">
          <span className="addContactToListTitle">
            {popupLoaderState?.data?.message}
          </span>
          <Lottie
            options={defaultOptions}
            width={"fit-content"}
            height={"fit-content"}
            speed={popupLoaderState?.data?.speed || 1.5}
          />
        </div>
        <div className="common-popup-mask" />
      </section>
    )
  );
};

export {PopupLoader, popupLoaderState};
export default observer(PopupLoader);
