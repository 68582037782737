import React from "react";
import { observer } from "mobx-react";
import { VariantToggle } from "Pages/Dashboard/sequences/SequencePage/VariantToggle";
import TimeIntervalForPolling from "./TimeIntervalForPolling";

const AutoImport = observer(props => {
  const {
    config = {},
    setConfig = () => {},
    data = {},
    crmName = "zoho",
    hasConditions = false
  } = props;

  const isSalesforce = crmName?.toLowerCase() === "salesforce" && hasConditions;

  const autoImportAction = (_, value) => {
    if (config?.sourceId || isSalesforce) {
      setConfig({
        ...config,
        autoSyncEnabled: value
      });
    }
  };

  return (
    <div
      className={`autoImport sectionMarginBottom ${
        config?.sourceId || isSalesforce ? "" : "disabled"
      }`}
    >
      <div className="flexColumn">
        <div className="autoImportLabel">
          <span className="headingText">Enable Polling</span>
          <VariantToggle
            action={autoImportAction}
            isChecked={config?.autoSyncEnabled}
          />
        </div>
        <span className="headingSubText">
          {`Periodically check ${crmName} CRM for any updates on ${
            crmName === "Pipedrive" ? "people" : "contacts every:"
          }`}
        </span>
      </div>
      <TimeIntervalForPolling
        config={config}
        setConfig={setConfig}
        data={data}
      />
    </div>
  );
});

export { AutoImport };
export default AutoImport;
