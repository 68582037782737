/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import ButtonComponent from "Components/common/Button";

class ConfirmationPopupState {
  @observable showPopup = false;

  defaultPopupValue = {
    title: "",
    content: "",
    actionBtnText: "Confirm",
    loadingMsg: "",
    cancelBtnText: "Cancel",
    callback: () => {},
    dynamicDom: false,
    loading: false,
    id: ""
  };

  @observable popupValues = { ...this.defaultPopupValue };

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = { ...this.defaultPopupValue };
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setPopupValues = (val = {}) => {
    this.popupValues = val;
  };
}
const confirmationPopupState = new ConfirmationPopupState();

const ConfirmationPopup = () => {
  const [loading, setLoading] = useState(false);
  const submitLoadingMsg = confirmationPopupState.popupValues.loadingMsg;
  const cancelLoadingMsg = confirmationPopupState.popupValues.cancelLoadingMsg;
  const buttonId = confirmationPopupState?.popupValues?.id || "";

  const [loadingMsg, setLoadingMsg] = useState("");

  const confirmClick = () => {
    setLoadingMsg(submitLoadingMsg);
    confirmationPopupState.popupValues.callback(setLoadingFn);
    submitLoadingMsg
      ? setLoadingFn(true)
      : confirmationPopupState.setShowPopup(false);
  };

  const setLoadingFn = value => {
    setLoading(value);
  };

  const closePopupWithAnimation = target => {
    const ele = target.closest('[data-id = "notify-popup"]');
    if (ele) {
      ele.classList.remove("slideInDownTranslate");
      ele.classList.add("slideInUpTranslate");
    }
    setTimeout(() => {
      confirmationPopupState.setShowPopup(false);
    }, 380);
  };

  const cancelAction = e => {
    setLoadingMsg(cancelLoadingMsg);
    const { target } = e;
    if (confirmationPopupState.popupValues.cancelCbk) {
      confirmationPopupState.popupValues.cancelCbk(setLoadingFn);
      cancelLoadingMsg ? setLoadingFn(true) : closePopupWithAnimation(target);
    } else {
      closePopupWithAnimation(target);
    }
  };

  useEffect(() => {
    !confirmationPopupState.showPopup && setLoadingFn(false);
  }, [confirmationPopupState.showPopup]);

  useEffect(() => {
    const { loading: loader = false, loadingMsg: text = "Please wait..." } =
      confirmationPopupState?.popupValues || {};
    if (loader) {
      setLoading(loader);
      setLoadingMsg(text);
    }
  }, [confirmationPopupState?.popupValues?.loading]);

  return confirmationPopupState.showPopup ? (
    <div className="popupWrapper confirmationPopupWrapper">
      <div className="confirmation-popup common-popup">
        <div
          data-id="notify-popup"
          className="popup-wrap animatedFast slideInDownTranslate confirmationPopup"
        >
          {!loading ? (
            confirmationPopupState.popupValues.dynamicDom ? (
              <div className="popup-content-wrap">
                {confirmationPopupState.popupValues.content}
              </div>
            ) : (
              <div
                className="popup-content-wrap"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: confirmationPopupState.popupValues.content || ""
                }}
              />
            )
          ) : (
            <div className="popup-content-wrap loaderWrapper animatedButtoon">
              <span className="loadMessage">{loadingMsg}</span>
              <ButtonComponent
                id=""
                name=""
                type="button"
                className="loading"
                btnText=""
                icon={false}
                loader={true}
                iconName="keyboard_arrow_down"
              />
            </div>
          )}
          {!loading ? (
            <div className="popup-action-wrap">
              {confirmationPopupState.popupValues &&
                (confirmationPopupState.popupValues.needCancelBtn ===
                  undefined ||
                  confirmationPopupState.popupValues.needCancelBtn ===
                    true) && (
                  <div
                    className="popup-action-btn cancelBtn"
                    onClick={e => cancelAction(e)}
                  >
                    {confirmationPopupState?.popupValues?.cancelBtnText ||
                      "Cancel"}
                  </div>
                )}
              <div
                className="popup-action-btn positive-btn"
                onClick={confirmClick}
                id={buttonId || ""}
              >
                {confirmationPopupState.popupValues.actionBtnText}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="confirmation-popup-mask" />
    </div>
  ) : (
    ""
  );
};

export { ConfirmationPopup, confirmationPopupState };
export default observer(ConfirmationPopup);
