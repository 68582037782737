import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { confirmationPopupState } from "Components/common/confirmationPopup";

export const ScheduleLaterMessagePrompt = withRouter(() => {
  const history = useHistory();

  const handleRedirect = () => {
    confirmationPopupState.setShowPopup(false);
    history.push("/inbox");
  };

  return (
    <div>
      The email has been scheduled successfully.{" "}
      <div class="infoPromptContent">
        You can view and modify the scheduled emails anytime from the{" "}
        <span className="linkBtn" onClick={handleRedirect}>
          inbox
        </span>
        .
      </div>
    </div>
  );
});

export default ScheduleLaterMessagePrompt;
