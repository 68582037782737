import { action, observable } from "mobx";

class UsersAndTeamsState {
  @observable activeTab = "users";

  @action
  setActiveTab = val => {
    this.activeTab = val;
  };
}

const usersAndTeamsState = new UsersAndTeamsState();

export { usersAndTeamsState, UsersAndTeamsState };
export default usersAndTeamsState;
