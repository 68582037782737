export const DIALER_SETTINGS_TAB_NAV = [
  { name: "General", id: "generalTab", path: "general" },
  {
    name: "Call Disposition",
    id: "callDispositionTab",
    path: "callDisposition"
  }
];

export const COLOR_CODES = [
  "#82E0AAFC",
  "#D2B4DE",
  "#D98880",
  "#7FB3D5",
  "#EC7063",
  "#808B96",
  "#2C3E50",
  "#E59866",
  "#CD6155",
  "#A569BD",
  "#5499C7",
  "#45B39D",
  "#F8C471",
  "#99A3A4",
  "#273746"
];
