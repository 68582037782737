import React, { useState, useEffect, createContext, useRef } from "react";
import { observer } from "mobx-react";
import {
  writingAssistantStoreData,
  defaultInputValue
} from "Stores/WritingAssistantData";
import WritingAssistantUtils from "Utils/WritingAssistantUtils";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";
import RewriteAiInputCard from "./RewriteAiInputCard";
import WandButton from "./WandButton";
import ScoreBot from "./ScoreBot";
import ChatGptLoader from "./ChatGptLoader";
import { toasterState } from "Components/common/toaster";

const RewriteAiContext = createContext({});

const RewriteAi = observer(
  ({
    fromTaskPage = false,
    editorRef = null,
    pageType = "",
    updateContentCbk = () => {}
  }) => {
    const {
      writingAssistantEnabled = false,
      inFreePlan = false,
      planName = ""
    } = userDetail?.userFeatureFlag || {};

    const inputBoxRef = useRef(null);
    let bottomStyle = 40;

    const [showCard, setShowCard] = useState(false);
    const [text, setText] = useState("");
    const [loader, setLoader] = useState(false);
    const [prompts, setPrompts] = useState(writingAssistantStoreData.prompts);
    const [emotions, setEmotions] = useState(
      writingAssistantStoreData.emotions
    );
    const [promptsLoader, setPromptLoader] = useState(
      writingAssistantStoreData.promptsLoader
    );
    const [scoreLoader, setScoreLoader] = useState(false);
    const [showReadabilityScore, setShowReadabilityScore] = useState(false);
    const [score, setScore] = useState({});
    const [previousContent, setPreviousContent] = useState("");
    const [staticHeight, setStaticHeight] = useState(bottomStyle);
    const [typewriteEffect, setTypewriteEffect] = useState(false);

    const resetState = () => {
      setShowCard(false);
      setText("");
      setLoader(false);
      resetPromots();
      setPromptLoader(false);
      if (!fromTaskPage) {
        writingAssistantStoreData.setAttachmentUpdated(false);
        writingAssistantStoreData.setInputValue({ ...defaultInputValue });
      }
    };

    const constructContentText = (options = "", dom = "<br>") => {
      let content = "";
      let subject = "";
      if (options?.length > 0) {
        const regex =
          /^[\n\r]*(?:Subject Line|Subject|Line):\s*([\s\S]*?)\s*[\n\r]+([\s\S]*)/gm;
        const match = regex.exec(options);
        if (match?.length > 0) {
          subject = match?.[1];
        }
        let finalContent = match?.[2] || options;
        const transformDoubleBracesToSingle = finalContent
          ?.replaceAll("\n\n", "\n")
          ?.replace(/\{{2,}/g, "{")
          ?.replace(/\}{2,}/g, "}");
        const transformAllSingleToDoubleBraces = transformDoubleBracesToSingle
          ?.replace(/[}]/g, "}}")
          ?.replace(/[{]/g, "{{");
        content = transformAllSingleToDoubleBraces
          ?.replace(/^\s+|\s+$/g, "")
          ?.replace(/(?:\r\n|\r|\n)/g, "<div><br ></div>");
      }
      return { content, subject };
    };

    const handleErrorResponseMessage = (code = "", data = {}) => {
      const contentObj = {
        411: "Oops! No results found. Please try again with a different prompt.",
        412: "Oops! You've reached your request limit. Please try again later.",
        429: "Oops! You're making too many requests. Please wait & try again later.",
        430: "Oops! You've reached the OpenAI request limits. Please wait & try again later.",
        500: "Something went wrong. Please try again.",
        501: "Something went wrong. Please try again."
      };
      let responseCode =
        (code
          ? code
          : !data || (data && Object.keys(data)?.length < 1 && 411)) || 500;
      toasterState.setToastMsg(contentObj[responseCode || 500], "fail");
    };

    const getAiContent = async (
      payload = {},
      insertSignature = false,
      isRewrite = false
    ) => {
      if (payload && Object.keys(payload)?.length > 0) {
        setLoader(true);
        let res = await writingAssistantStoreData.generateContent(payload);
        let validResponse =
          res?.data?.options?.length > 0 ||
          (res?.data?.options && Object.keys(res?.data?.options)?.length > 0);
        if (validResponse) {
          let inputValue = constructContentText(res?.data?.options, "<br >");
          inputValue.insertSignature = insertSignature; // ONE OFF EMAIL => insert signature
          inputValue.isRewrite = isRewrite; // Rewrite exisitng content
          if (fromTaskPage) {
            writingAssistantStoreData.setTaskBodyContent(res?.data?.options);
          } else {
            writingAssistantStoreData.setInputValue(inputValue);
            writingAssistantStoreData.setBodyContent(res?.data?.options);
          }
          writingAssistantStoreData.setTypewriteAnimation(true);
          Utils.mixpanelTrack(MXP_EVENT.CHAT_GPT_AI_GENERATED);
          updateContentCbk && updateContentCbk(inputValue, "rewrite");
        } else if (res?.response?.status === 401) {
          Utils.showLoggedOutPopup();
        } else if (res?.response?.status === 403) {
          WritingAssistantUtils.upgradePopup(inFreePlan, planName);
        } else {
          handleErrorResponseMessage(res?.response?.status, res?.data);
        }
        setLoader(false);
      }
    };

    const generateContent = async () => {
      if (text?.trim()?.length > 0) {
        Utils.mixpanelTrack(MXP_EVENT.CHAT_GPT_AI_HELP_ME_WRITE_CLICKED);
        getAiContent(
          {
            prompt: text
          },
          true, // ONE OFF EMAIL => insert signature
          false // Rewrite existing content
        );
        setText("");
        resetPromots();
      }
    };

    const handleSubmit = (event = "") => {
      Utils.isSubmitKeyAction(event) && generateContent();
    };

    const rewriteContent = (isGenerateScore = false, prompt = "") => {
      let content =
        (pageType?.toLowerCase() === "task"
          ? writingAssistantStoreData?.taskBodyContent
          : writingAssistantStoreData?.bodyContent) || "";
      let newLineContent = WritingAssistantUtils.replaceDomWithLineSeperator(
        content,
        "\n"
      );
      let plainText = WritingAssistantUtils.convertToPlainText(
        newLineContent,
        "/n"
      );
      let newContent = WritingAssistantUtils.addDotToString(
        plainText?.replaceAll("/n", "\n") || plainText || ""
      );
      newContent = newContent?.replace(/ +(?= )/g, "");
      let contentLength =
        WritingAssistantUtils.getContentWordLength(newContent);
      Utils.mixpanelTrack(MXP_EVENT.CHAT_GPT_AI_SCORE_REWRITE_AI_CLICKED);
      if (contentLength > 10) {
        if (isGenerateScore) {
          return newContent;
        } else {
          let payload = { content: newContent };
          prompt && (payload.prompt = prompt);
          getAiContent(payload, false, true);
        }
      } else {
        WritingAssistantUtils.showEmptyContentConfirmPrompt(
          "Please add more content to your email body to let AI generate different variations of your email."
        );
        return;
      }
    };

    const generateScore = async () => {
      if (writingAssistantEnabled) {
        Utils.mixpanelTrack(MXP_EVENT.CHAT_GPT_AI_SCORE_CLICKED);
        let content = rewriteContent(true);
        if (content === previousContent) {
          setShowReadabilityScore(true);
          return;
        }
        showReadabilityScore && setShowReadabilityScore(false);
        if (content) {
          !scoreLoader && setScoreLoader(true);
          let res = await writingAssistantStoreData.generateScore({ content });
          if (res?.data?.options) {
            setPreviousContent(content);
            let tempScoreObj =
              JSON.parse(res?.data?.options?.replaceAll("\n", "")) || {};
            setScore(tempScoreObj);
            setShowReadabilityScore(true);
          } else if (res?.response?.status === 401) {
            Utils.showLoggedOutPopup();
          } else if (res?.response?.status === 403) {
            WritingAssistantUtils.upgradePopup(inFreePlan, planName);
          } else {
            handleErrorResponseMessage(res?.response?.status || 500);
          }
          setScoreLoader(false);
        }
      } else {
        WritingAssistantUtils.upgradePopup(inFreePlan, planName);
      }
    };

    const openChatGptSearchBox = () => {
      Utils.mixpanelTrack(MXP_EVENT.CHAT_GPT_AI_CLICKED);
      if (!loader && !scoreLoader) {
        if (writingAssistantEnabled) {
          setShowCard(true);
        } else {
          WritingAssistantUtils.upgradePopup(inFreePlan, planName);
        }
      }
    };

    const resetPromots = () => {
      if (prompts?.length > 0) {
        let tempList = [...prompts].map(item => {
          item.show = false;
          return item;
        });
        setPrompts(tempList);
      }
    };

    const adjustChatGptHeight = () => {
      if (!fromTaskPage) {
        setTimeout(() => {
          const chatGptDom = document.getElementById("chatGptEditorSection");
          if (chatGptDom) {
            let attachmentHeight =
              document.getElementById("editorFileAttachment")?.clientHeight ||
              0;
            setStaticHeight(bottomStyle + attachmentHeight);
            writingAssistantStoreData.setAttachmentUpdated(false);
          }
        }, 50);
      }
    };

    const handleRewriteAIAction = () => {
      setShowCard(true);
      rewriteContent(false);
      setShowReadabilityScore(false);
    };

    const removeChatGptEditorScrollClass = (editorRef = "") => {
      if (editorRef) {
        let hasNormalClass = editorRef.root.classList.contains(
          "chatGptEnabledNormal"
        );
        let hasLargeClass = editorRef.root.classList.contains(
          "chatGptEnabledLarge"
        );
        let hasSmallClass = editorRef.root.classList.contains(
          "chatGptEnabledSmall"
        );
        if (hasLargeClass) {
          editorRef.root.classList.remove("chatGptEnabledLarge");
        } else if (hasSmallClass) {
          editorRef.root.classList.remove("chatGptEnabledSmall");
        } else if (hasNormalClass) {
          editorRef.root.classList.remove("chatGptEnabledNormal");
        }
        return true;
      }
    };

    const chatGptEditorScrollClass = (editorRef = "") => {
      if (editorRef) {
        let height = 0;
        if (!fromTaskPage) {
          let attachmentHeight =
            document.getElementById("editorFileAttachment")?.clientHeight || 0;
          height += attachmentHeight;
        }
        let value = height > 50 ? "Large" : height > 0 ? "Small" : "Normal";
        if (showCard && !typewriteEffect && !loader) {
          let success = removeChatGptEditorScrollClass(editorRef);
          success && editorRef.root.classList.add(`chatGptEnabled${value}`);
        } else {
          removeChatGptEditorScrollClass(editorRef);
        }
      }
    };

    useEffect(() => {
      adjustChatGptHeight();
      return () => {
        resetState();
        setShowReadabilityScore(false);
        setScore({});
        setPreviousContent("");
        setStaticHeight(bottomStyle);
      };
    }, []);

    useEffect(() => {
      chatGptEditorScrollClass(editorRef);
    }, [
      typewriteEffect,
      loader,
      showCard,
      writingAssistantStoreData?.attachmentUpdated
    ]);

    useEffect(() => {
      writingAssistantStoreData?.attachmentUpdated && adjustChatGptHeight();
    }, [writingAssistantStoreData?.attachmentUpdated]);

    useEffect(() => {
      if (!writingAssistantStoreData?.promptsLoader) {
        setPrompts([...(writingAssistantStoreData?.prompts || [])]);
        setEmotions([...(writingAssistantStoreData?.emotions || [])]);
        setPromptLoader(writingAssistantStoreData?.promptsLoader || false);
      }
    }, [writingAssistantStoreData?.promptsLoader]);

    useEffect(() => {
      if (typewriteEffect !== writingAssistantStoreData.hideChatGpt) {
        setTypewriteEffect(writingAssistantStoreData.hideChatGpt);
      }
    }, [writingAssistantStoreData?.hideChatGpt]);

    return (
      !typewriteEffect && (
        <div
          className="chatGptWrapper"
          style={{
            bottom: `${staticHeight}px`
          }}
        >
          <RewriteAiContext.Provider
            value={{
              showCard,
              setShowCard,
              text,
              setText,
              loader,
              handleSubmit,
              setLoader,
              generateContent,
              rewriteContent,
              prompts,
              setPrompts,
              promptsLoader,
              resetPromots,
              emotions,
              resetState,
              inputBoxRef,
              generateScore,
              scoreLoader,
              setScoreLoader,
              score,
              setScore,
              showReadabilityScore,
              setShowReadabilityScore,
              handleRewriteAIAction
            }}
          >
            {!showCard ? (
              <div className="iconBtnWrapper">
                <ScoreBot />
                <WandButton
                  action={openChatGptSearchBox}
                  scoreLoader={scoreLoader}
                />
              </div>
            ) : loader ? (
              <ChatGptLoader />
            ) : (
              <RewriteAiInputCard />
            )}
          </RewriteAiContext.Provider>
        </div>
      )
    );
  }
);

export default RewriteAi;
export { RewriteAiContext };
