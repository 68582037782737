import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import DateUtils from "Utils/DateUtils";
import SearchInputByKeyword from "Components/common/SearchInputByKeyword";
import FilterByDateTab from "Components/common/FilterByDateTab";
import ConnectedInboxLoader from "./ConnectedInboxLoader";
import ConnectedInboxList from "./ConnectedInboxList";
import connectedInboxStore from "./store";
import FilterByWarming from "./FilterByWarming";
import { USER_SELECT_DROPDOWN_LIST as userObjList } from "Static/constant";
import UserDropdown from "Components/common/UserDropdown";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";

const ConnectedInbox = observer((props = {}) => {
  const {
    team = false,
    hasTeamMember = false,
    isTeamOwner = false,
    memberId = ""
  } = userDetail?.userInfo || {};
  const { noOfContactsAddedInTeam } = userDetail?.userFeatureFlag || {};
  const [teamList, setTeamList] = useState("");
  const [teamLoader, setTeamLoader] = useState(true);
  const [userFilterList, setUserFilterList] = useState(userObjList);

  const handleSearchByKeyword = (searchEmail = "") => {
    connectedInboxStore.setFilterChanged(true);
    connectedInboxStore.setSearchTerm(searchEmail);
    connectedInboxStore.setPageNo(1);
    connectedInboxStore.setPageLimit(10);
    connectedInboxStore.getAccountDetails(
      false,
      connectedInboxStore.filterChanged
    );
  };

  const getFilterByDate = (value = {}) => {
    const { filterFromDate = "", filterToDate = "" } = value || {};
    let fromDate = DateUtils.getTimeStamp(filterFromDate, "start") || "";
    let toDate = DateUtils.getTimeStamp(filterToDate, "end") || "";
    let dateFilter = { fromDate, toDate };
    connectedInboxStore.setFilterChanged(true);
    connectedInboxStore.setDateFilter(dateFilter);
  };

  const setActiveDateFilter = (value = "") =>
    connectedInboxStore.setActiveDateTab(
      value?.toLowerCase() === "alltask" ? "today" : value
    );

  const resetUserFilterOption = () => {
    const newArr = [...userFilterList];
    newArr.map(item => (item.checked = item?.id?.toLowerCase() === "all"));
    setUserFilterList(newArr);
  };

  const getTeamList = async () => {
    const resData = await Utils.userTeamList();
    userDetail.setUserInfo({
      ...userDetail?.userInfo,
      hasTeamMember: resData?.length ? resData : false
    });
    setTeamList(resData);
    setTeamLoader(false);
  };

  const getSelectedUserPayload = data => {
    return typeof data === "object"
      ? data?.length && data
      : data?.length && data?.toLowerCase() === "you"
      ? new Array(memberId)
      : "";
  };

  const makeFilterRequest = (user = {}) => {
    const payload = {};
    const selectedIds = getSelectedUserPayload(user);
    connectedInboxStore.setFilterChanged(true);
    if (Object?.keys(user)?.length) {
      if (!team) {
        payload.memberIds = [memberId];
      } else if (selectedIds?.length) {
        payload.memberIds = selectedIds;
      }
    }
    return payload;
  };

  const selectedUserCbk = (value = "") => {
    if (value) {
      let payload = makeFilterRequest(value);
      connectedInboxStore.setMembersFilter(payload || {});
    }
  };

  useEffect(() => {
    getTeamList();
    return () => {
      resetUserFilterOption();
    };
  }, []);

  return (
    <>
      {(connectedInboxStore?.isInboxConnected ||
        connectedInboxStore?.loading) &&
        !connectedInboxStore?.showSmtpViaWarming && (
          <div className="connectedInboxHeader">
            <SearchInputByKeyword
              id="searchInbox"
              placeholder="Search Inbox"
              handleSearch={handleSearchByKeyword}
            />
            {Utils.isAdmin() && (
              <div className="filterInbox">
                <UserDropdown
                  team={team}
                  teamList={teamList}
                  teamLoader={teamLoader}
                  selectedUserCbk={selectedUserCbk}
                  isContactsAdded={noOfContactsAddedInTeam > 0}
                  userFilterList={Utils.filterByUserTeamFilterList(userObjList)}
                />
              </div>
            )}
            <div
              className={`${
                Utils.isAdmin()
                  ? "connectedInboxWrapperIsAdmin"
                  : "connectedInboxWrapper"
              }`}
            >
              <FilterByDateTab
                activeDueTab={connectedInboxStore?.activeDateTab}
                setActiveDueTab={setActiveDateFilter}
                cbk={getFilterByDate}
                isTaskPage={false}
                pageType="connectedInbox"
              />
              <FilterByWarming />
            </div>
          </div>
        )}
      {connectedInboxStore?.loading ? (
        <ConnectedInboxLoader count={7} />
      ) : (
        connectedInboxStore?.isInboxConnected && (
          <ConnectedInboxList {...props} />
        )
      )}
    </>
  );
});

export { ConnectedInbox, ConnectedInboxLoader };
export default ConnectedInbox;
