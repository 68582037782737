import React from "react";
import { observer } from "mobx-react";
import KnowMoreButton from "Components/common/KnowMoreButton";
import Button from "Components/common/Button";
import { appPasswordScreenConfig } from "./googleOAuthConfig";
import { googleOAuthPopupState } from "../googleOAuthPopup";

const AppPassword = observer(() => {
  const clickLoginBtn = () => {
    googleOAuthPopupState.setAppPasswordScreen("three");
  };

  const btnProps = [
    {
      id: "back",
      name: "backBtn",
      icon: false,
      type: "button",
      className: "backBtn",
      loader: false,
      btnText: "Back",
      click: () => {
        googleOAuthPopupState.setAppPasswordScreen("one");
      }
    },
    {
      id: "proceedBtn",
      name: "proceedBtn",
      icon: false,
      type: "button",
      className: "proceedBtn",
      loader: false,
      btnText: "Next",
      click: () => clickLoginBtn()
    }
  ];
  return (
    <>
      <div className="initialScreenBody">
        <ol>
          {appPasswordScreenConfig.map((item, index) => {
            const {
              subLabel = false,
              content = "",
              subLabelContent = ""
            } = item || {};
            return (
              <li key={index}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                {subLabel && (
                  <div
                    className="subLabelContent"
                    dangerouslySetInnerHTML={{ __html: `(${subLabelContent})` }}
                  />
                )}
              </li>
            );
          })}
        </ol>
        {/* <div className="tutorialLink">
          <KnowMoreButton text="Show me how" redirectUrl="" />
        </div> */}
      </div>
      <div className="oAuthFooter">
        <div className="actionBtns">
          {btnProps.map((item, index) => {
            return <Button {...item} key={index} />;
          })}
        </div>
      </div>
    </>
  );
});

export { AppPassword };
export default AppPassword;
