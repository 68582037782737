import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import { PAYWALL_CONTENT } from "Model/model";
import { HUBSPOT_OAUTH_URL } from "Static/constant";
import hubspotIcon from "Assets/png/hubspot.png";
import { ReactComponent as ThunderOrangeIcon } from "Assets/svg/thunderOrange.svg";
import { toasterState } from "Components/common/toaster";
import UpgradeTooltip from "Components/Paywall/UpgradeTooltip";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import FormActionButton from "../../ConnectInbox/Configure/FormActionButton";
import ConnectOrDisconnectCard from "../ConnectOrDisconnectCard";
import HubspotTabFields from "./HubspotTabFields";
import { tagsStoreData } from "Stores/TagsData";
import { hubspotStore } from "./HubspotStore";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { settingsStoreData } from "Stores/settingsData";
import NewTooltip from "Components/common/NewTooltip";

const defaultFunc = () => {};
const HubspotConfigure = observer(props => {
  const {
    hideCbk = () => {},
    isFeatureEnabled = true,
    showAdminPopup = defaultFunc,
    planName = "",
    inFreePlan = false
  } = props;
  const { hubspotData: { hubConnected = false, crmSettings = {} } = {} } =
    hubspotStore;
  const { instanceUrl = "" } = crmSettings || {};
  const updatedHubspotConnected =
    hubConnected && crmSettings && Object.keys(crmSettings).length > 0;

  const [removeAccountLoader, setRemoveAccountLoader] = useState(false);
  const [showUpgradeTp, setShowUpgradeTp] = useState(false);
  const [saveChangesLoader, setSaveChangesLoader] = useState(false);
  const [goBackUrl, setGoBackUrl] = useState("");
  const location = useLocation();
  const history = useHistory();

  const handleRedirection = (interval = 1000) => {
    setTimeout(() => {
      if (settingsStoreData?.saveFromContacts) {
        settingsStoreData.setSaveFromContacts(false);
        settingsStoreData.setFromContacts(false);
        Utils.showRedirection(goBackUrl, 5, history);
      }
    }, interval);
  };

  const openHubspotOauthWindow = () => {
    if (HUBSPOT_OAUTH_URL) {
      const w = 600;
      const h = 800;
      const left = window.screen.width / 2 - w / 2;
      const top = window.screen.height / 2 - h / 2;
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${left},top=${top}`;
      window.open(HUBSPOT_OAUTH_URL, "Hubspot Login", params);
      const oauthCbk = async ev => {
        if (ev?.data?.type === "HUBSPOT_CONNECT_OAUTH") {
          window.removeEventListener("message", oauthCbk);
          if (ev?.data?.code?.toString() === "200") {
            toasterState.setToastMsg(
              "Hubspot account connected successfully",
              "success"
            );
            toasterState.setToasterTimeout(1000);
            Utils.mixpanelTrack(MXP_EVENT.CRM_CONNECTED, {
              type: "Hubspot"
            });
            hubspotStore.getHubspotData();
          } else {
            toasterState.setToastMsg(
              "Something went wrong. Please try again later",
              "fail"
            );
          }
          handleRedirection(1500);
        }
      };
      window.addEventListener("message", oauthCbk);
    }
  };

  const removeAccount = () => {
    const cbk = async () => {
      setRemoveAccountLoader(true);
      await hubspotStore.removeHubspotAccount();
      setRemoveAccountLoader(false);
    };

    if (Utils.isAdmin()) {
      confirmationPopupState.setPopupValues({
        content: "Are you sure you want to disconnect your HubSpot CRM?",
        actionBtnText: "Yes",
        cancelBtnText: "No",
        callback: () => {
          cbk();
        }
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      showAdminPopup("hubspot");
    }
  };

  const makeAction = () => {
    !updatedHubspotConnected ? openHubspotOauthWindow() : removeAccount();
  };

  const removeUnwantedFields = (config = {}, setConfig = () => {}) => {
    const fields = [...config?.fields];
    fields?.length > 0 &&
      fields.map((item, index) => {
        if (!item?.sgField && !item?.crmField) {
          fields.splice(index, 1);
        }
        return fields;
      });
    setConfig({ ...config, fields });
    return {
      ...config,
      fields
    };
  };

  const constructHubspotConfig = (isFromSave = false) => {
    const extractedContactTags =
      Utils.extractTagNameFromList(hubspotStore.hubspotContactTags) || [];
    const updatedHubspotContactConfig = isFromSave
      ? removeUnwantedFields(
          hubspotStore?.hubspotContactConfig,
          hubspotStore.setHubspotContactConfig
        )
      : hubspotStore?.hubspotContactConfig;
    const payload = {
      config: {
        contact: {
          ...updatedHubspotContactConfig,
          tags: extractedContactTags
        }
      },
      owner: hubspotStore?.hubspotOwnerConfig,
      preference: hubspotStore?.hubspotPreferences
    };
    return payload;
  };

  const saveHubspotConfigure = async () => {
    if (Utils.isAdmin()) {
      const saveChangesBtnDisabled =
        !(updatedHubspotConnected && isFeatureEnabled) ||
        hubspotStore.disableSaveButton;
      if (!saveChangesBtnDisabled) {
        setSaveChangesLoader(true);
        const payload = constructHubspotConfig(true);
        try {
          const config = {
            url: URLS.updateHubspotConfig,
            method: "POST",
            data: payload
          };
          let res = await makeApi(config);
          if (res?.data) {
            tagsStoreData.getAllTags();
            hubspotStore.setHubspotData(res?.data);
            setSaveChangesLoader(false);
            toasterState.setToasterTimeout(1000);
            toasterState.setToastMsg(
              "Hubspot Integration settings updated successfully.",
              "success"
            );
          } else {
            Utils.showApiFailureResponse(res);
          }
          handleRedirection();
        } catch (err) {
          console.error("Hubspot save error", err);
        }
      }
    } else {
      showAdminPopup();
    }
  };

  useEffect(() => {
    const {
      config: { contact = {} } = {},
      owner = [],
      syncClickEnabled = false,
      syncOpenEnabled = false,
      syncPhoneCallEnabled = true,
      syncReplyEnabled = false,
      syncSentEnabled = false,
      syncLiAcceptRequest = false,
      syncLiSendRequest = false,
      syncLiMessageSent = false,
      syncLiReplyReceived = false,
      syncCustomTask = false
    } = crmSettings || {};
    if (contact && Object.keys(contact).length > 0) {
      const contactConfig = Utils.createObjWithoutRef(contact);
      hubspotStore.setHubspotContactConfig(contactConfig);
    }
    hubspotStore.setHubspotOwnerConfig(owner);
    hubspotStore.setHubspotPreferences({
      syncClickEnabled,
      syncOpenEnabled,
      syncPhoneCallEnabled,
      syncReplyEnabled,
      syncSentEnabled,
      syncLiAcceptRequest,
      syncLiSendRequest,
      syncLiMessageSent,
      syncLiReplyReceived,
      syncCustomTask
    });
  }, [crmSettings?.config]);

  useEffect(() => {
    let queryParams = Utils.getQueryParams(location.search);
    let isFromContacts = queryParams.get("source") === "contact" || false;
    if (isFromContacts) {
      settingsStoreData.setFromContacts(true);
      settingsStoreData.setSaveFromContacts(true);
      const tempGoBackUrl = localStorage.getItem("goBackUrl") || "/contacts";
      setGoBackUrl(tempGoBackUrl);
      localStorage.removeItem("goBackUrl");
    }
  }, []);

  return (
    <div className="crmConfigurePage hubspot">
      {settingsStoreData?.fromContacts && (
        <NewTooltip
          className={
            !updatedHubspotConnected ? "crmNotConnected" : "crmConnected"
          }
          closeIconCbk={() => settingsStoreData.setFromContacts(false)}
          heading={"Action Required"}
          text={
            !updatedHubspotConnected
              ? `Click to connect your Hubspot CRM and start syncing contacts.`
              : `Set field sync direction to ‘Outbound’ or ‘2-Way Sync’ for fields you want to export to Salesforce`
          }
        />
      )}
      <ConnectOrDisconnectCard
        type="HubSpot"
        icon={hubspotIcon}
        addOrRemoveAccount={makeAction}
        connectedEmail={instanceUrl?.replace("https://", "")}
        isConnected={updatedHubspotConnected}
        removeLoader={removeAccountLoader}
      />
      {updatedHubspotConnected && (
        <div
          className="activityLogWrapper"
          onMouseEnter={() => !isFeatureEnabled && setShowUpgradeTp(true)}
          onMouseLeave={() => !isFeatureEnabled && setShowUpgradeTp(false)}
        >
          <HubspotTabFields
            className={`${!isFeatureEnabled ? "disabledConfigure" : ""}`}
            crmSettings={crmSettings}
            constructHubspotConfig={constructHubspotConfig}
          />
          {!isFeatureEnabled && <ThunderOrangeIcon className="thunderOrange" />}

          {showUpgradeTp &&
            !isFeatureEnabled &&
            (inFreePlan ||
              ["pro", "basic"].includes(planName?.toLowerCase())) && (
              <UpgradeTooltip
                {...PAYWALL_CONTENT[
                  inFreePlan || planName?.toLowerCase() === "basic"
                    ? "hubspotSettings"
                    : "hubspotSettingsUnlimited"
                ]}
              />
            )}
        </div>
      )}
      <FormActionButton
        cancel={() => hideCbk()}
        save={saveHubspotConfigure}
        saveBtnText="Save changes"
        loader={saveChangesLoader}
        disabled={
          !(updatedHubspotConnected && isFeatureEnabled) ||
          hubspotStore.disableSaveButton
        }
      />
      {settingsStoreData?.fromContacts && (
        <div
          className="highlightContactExportOverlay"
          onClick={() => settingsStoreData.setFromContacts(false)}
        />
      )}
    </div>
  );
});

export { HubspotConfigure };
export default HubspotConfigure;
