import { observable, action } from "mobx";
import { toasterState } from "Components/common/toaster";
import { makeApi, URLS } from "Utils/apiURL";

class FileAttachmentStore {
  @observable totalSizeUploaded = 0;
  @observable taskTotalFileSizeUploaded = 0;
  @observable fileAttachmentList = [];
  @observable fileUploadingLoader = false;
  @observable totalFileUploading = 0;
  @observable taskTotalFileUploading = 0;
  @observable performTaskFileAttachments = [];
  @observable taskFileUploadingLoader = false;

  @action
  setFileAttachmentList = (value = []) => {
    this.fileAttachmentList = value;
  };

  @action
  clearAttachmentList = () => {
    this.fileAttachmentList = [];
  };

  @action
  setTotalSizeUploaded = (value = 0) => {
    this.totalSizeUploaded = value;
  };

  @action
  setTaskTotalFileSizeUploaded = (value = 0) => {
    this.taskTotalFileSizeUploaded = value;
  };

  @action
  setFileUploadingLoader = (value = false) => {
    this.fileUploadingLoader = value;
  };

  @action
  setTaskFileUploadingLoader = (value = false) => {
    this.taskFileUploadingLoader = value;
  };

  @action
  setTotalFileUploading = (value = 0) => {
    this.totalFileUploading = value;
  };

  @action
  setTaskTotalFileUploading = (value = 0) => {
    this.taskTotalFileUploading = value;
  };

  @action
  setPerformTaskFileAttachments = (list = []) => {
    this.performTaskFileAttachments = [...list];
  };

  @action
  setAddFileAttachmentList = (list = [], fromTaskPage = false) => {
    if (list?.length) {
      let tempList = JSON.parse(
        JSON.stringify(
          fromTaskPage
            ? this.performTaskFileAttachments
            : this.fileAttachmentList
        )
      );
      if (fromTaskPage) {
        this.setPerformTaskFileAttachments([...tempList, ...list]);
      } else {
        this.setFileAttachmentList([...tempList, ...list]);
      }
    }
  };

  @action
  uploadFileAttachment = async (data = {}, fromTaskPage = false) => {
    const config = {
      url: URLS.fileAttachmentUpload,
      method: "POST",
      data
    };
    let res = await makeApi(config);
    if (res?.data) {
      this.setAddFileAttachmentList(res?.data, fromTaskPage);
      toasterState.setToastMsg("File uploaded successfully", "success");
    } else if (res?.response?.status === 417) {
      toasterState.setToastMsg(
        "Sorry, this file type is not supported here. Please try again with different file",
        "failure"
      );
    } else {
      toasterState.setToastMsg("File Upload Failed", "failure");
    }
    if (fromTaskPage) {
      this.setTaskFileUploadingLoader(false);
      this.setTaskTotalFileUploading(0);
    } else {
      this.setFileUploadingLoader(false);
      this.setTotalFileUploading(0);
    }
  };

  @action
  removeFileAttachment = async (data = {}) => {
    try {
      const config = {
        url: URLS.fileAttachmentUpload,
        method: "DELETE",
        data
      };
      let res = await makeApi(config);
      return res?.data?.status === 200;
    } catch (e) {
      console.error(e, "Remove file error");
    }
  };
}

// export default new FileAttachmentStore();
const fileAttachmentStore = new FileAttachmentStore();
export default FileAttachmentStore;
export { fileAttachmentStore, FileAttachmentStore };
