import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { DateUtils } from "Utils/DateUtils";
import { zohoDataStore } from "./ZohoStoreData";
import { Utils } from "Utils/utils";

let pageNo = 1,
  limit = 20;

const dataModel = {
  syncLog: { createdCount: 0, updatedCount: 0, errorCount: 0 },
  errorLog: {
    errorLogs: [],
    totalElements: 0,
    totalPages: 0,
    currentPage: 0
  }
};

const SyncLogs = observer(() => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(dataModel);

  const loadInitialData = async () => {
    let data = await zohoDataStore.getSyncLogs(pageNo, limit);
    // If the response is empty, updating to default data model to avoid destructuring errors
    data = {
      ...data,
      ...(!data.syncLog && { syncLog: dataModel?.syncLog }),
      ...(!data.errorLog && { errorLog: dataModel?.errorLog })
    };
    setData(data);
    setLoading(false);
  };

  const formatDateAndTime = date => {
    if (date) {
      const { date: formattedDate = "", time: formattedTime = "" } =
        DateUtils.getFormattedDateAndTimeForCrm(date) || {};
      return `${formattedDate}, ${formattedTime}`;
    }
    return "--";
  };

  useEffect(() => {
    loadInitialData();

    return () => {
      pageNo = 1;
    };
  }, []);

  return (
    <div className="syncLogWrapper">
      <div className="contactStatWrapper">
        {loading ? (
          <Loader />
        ) : (
          <>
            <ContactStats {...data} formatDateAndTime={formatDateAndTime} />
            <ErrorLogs
              data={data}
              setData={setData}
              formatDateAndTime={formatDateAndTime}
            />
          </>
        )}
      </div>
    </div>
  );
});

const ContactStats = ({
  syncLog: { createdCount = 0, updatedCount = 0, errorCount = 0 } = {},
  formatDateAndTime = () => {},
  lastSynced = ""
} = {}) => {
  const data = [
    {
      name: "New Contacts",
      classVal: "createdCont",
      value: Utils.formatNumberToString(createdCount)
    },
    {
      name: "Updated Contacts",
      classVal: "updatedCont",
      value: Utils.formatNumberToString(updatedCount)
    },
    {
      name: "Failed Contacts",
      classVal: "failedCont",
      value: Utils.formatNumberToString(errorCount)
    }
  ];

  return (
    <div className="contactStatContainer">
      <div className="dateContainer">
        <span>Last synced on {formatDateAndTime(lastSynced)}</span>
      </div>
      <div className="countWrapper">
        {data?.map((data = {}) => {
          const { value = 0, name = "", classVal = "" } = data;
          return (
            <div key={`stats${name}`} className={`countCont ${classVal}`}>
              <span className="count">{value}</span>
              <span className="statName">{name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ErrorLogs = ({
  formatDateAndTime = () => {},
  data = {},
  setData = () => {},
  data: {
    errorLog: { errorLogs = [], totalPages = 0, totalElements = 0 } = {}
  } = {}
}) => {
  const errorObj = {
    400: "Bad request; Please contact support@salesgear.io to get this resolved",
    202: "Invalid data; contact support@salesgear.io to get this resolved",
    404: "Invalid request URL; contact support@salesgear.io to get this resolved",
    401: "Unauthorized access to certain Zoho CRM scopes",
    403: "Permission denied to update data",
    500: "Unexpected server error"
  };

  const isBottomOfContainer = e => {
    const { scrollHeight = 0, scrollTop = 0, clientHeight = 0 } = e?.target;
    return scrollHeight - scrollTop === clientHeight;
  };

  const paginate = async e => {
    if (isBottomOfContainer(e) && pageNo < totalPages) {
      const paginatedResult = await zohoDataStore.getSyncLogs(++pageNo, limit);
      if (paginatedResult?.errorLog?.errorLogs) {
        const newData = [
          ...data.errorLog.errorLogs,
          ...paginatedResult.errorLog.errorLogs
        ];
        const state = Utils.createObjWithoutRef(data);
        state.errorLog.errorLogs = newData;
        setData(state);
      }
    }
  };

  const getSyncDirection = contactPull => {
    return contactPull ? "Inbound" : "Outbound";
  };

  const getMessage = (entityType, contactPull) => {
    let message = "Contact";
    if (entityType == "LEAD") {
      message = "Lead";
    }
    if (contactPull) {
      message += " Sync Failed";
    } else {
      message += " Field Update Failed";
    }
    return message;
  };

  return (
    <div className="errorLogWrapper" onScroll={paginate}>
      {totalElements > 0 && (
        <>
          {errorLogs.map((log = {}, idx) => {
            const {
              createTs = null,
              errorMessage = "",
              errorCode = "",
              entityType = "",
              contactPull
            } = log;

            return (
              <div key={`error${idx}`} className="log">
                <span className="date">{formatDateAndTime(createTs)}</span>
                <span className="syncDirection">
                  {getSyncDirection(contactPull)}
                </span>
                <span className="message">
                  {getMessage(entityType, contactPull)}
                </span>
                <span className="reason">
                  {errorObj[+errorCode] || errorMessage?.replaceAll("_", " ")}
                </span>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

const Loader = () => {
  return (
    <>
      <div className="loaderContainer">
        <div className="dateContainer">
          <span className="loaderAnimation linear-background"></span>
        </div>
        <div className="countWrapper">
          <span className="loaderAnimation linear-background"></span>
        </div>
      </div>
      <div className="errorLogLoader">
        {Array.from({ length: 15 }, (value, index) => (
          <div key={"syncLogLoader_" + index} className="erroLogLoader">
            <span className="dateColumn linear-background"></span>
            <span className="loaderAnimation linear-background"></span>
            <span className="loaderAnimation linear-background"></span>
            <span className="loaderAnimation linear-background"></span>
          </div>
        ))}
      </div>
    </>
  );
};

export { SyncLogs };
export default SyncLogs;
