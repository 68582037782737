import React from "react";
import ContactInputTag from "./ContactInputTag";
import Utils from "Utils/utils";

const CompanyInformation = props => {
  const {
    inputVal,
    changeInputVal,
    tokenList,
    isContactPage = false
  } = props || {};

  return (
    <div className="info companyInfo">
      <h5 className="sectionTitle">Company Information</h5>
      <div className="sectionInputTagsCont">
        <div className="rowBreaker">
          <ContactInputTag
            name="company"
            id="company"
            value={inputVal.company}
            text="Company"
            onBlurEvent={changeInputVal}
            required={
              isContactPage
                ? false
                : Utils.findIsRequiredFieldForSingleContactSequence(
                    "companyName",
                    tokenList
                  )
            }
          />
          <ContactInputTag
            name="industry"
            id="industry"
            value={inputVal.industry}
            text="Industry"
            onBlurEvent={changeInputVal}
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "industry",
              tokenList
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
export { CompanyInformation };
