/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from "react";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import { fetchConnectedEmails } from "Utils/commonAPI";
import { observer } from "mobx-react";
import { defaultEmailType } from "Static/outboxConstant";
import userDetail from "Stores/userDetail";
import { outboxStoreData } from "./outboxStore";
import { tagsStoreData } from "Stores/TagsData";
import customListState from "Pages/Dashboard/dataSearch/CustomList/state";
import { Utils } from "Utils/utils";
import { DateUtils } from "Utils/DateUtils";
import { MP_EVENT } from "Static/constant";
import HeaderSection from "./HeaderSection";
import OutboxCardList from "./OutboxCardList";
import TopFilterContents from "./TopFilterContents";
import FilterComponent from "./FilterComponent";
import OutboxFilterByOption from "./OutboxFilterByOption";
import { hideIntercomChat } from "Utils/Scripts/intercom";

export const defaultInputValues = {
  limit: 10,
  filterLoading: true,
  dataLoading: true,
  outboxData: {},
  filterObject: {}
};

const OutboxContext = createContext({});

const Outbox = observer(() => {
  const location = useLocation();
  const history = useHistory();

  const defaultFilterObject = {
    owners:
      Utils.createObjWithoutRef(userDetail?.userInfo?.hasTeamMember) || [],
    fromAddress: Utils.createObjWithoutRef(userDetail?.connectedInboxes) || [],
    toAddress: [],
    sequences:
      Utils.createObjWithoutRef(
        outboxStoreData?.sequenceData?.campaignSequenceResponseList
      ) || [],
    emailType: defaultEmailType,
    dates: [
      {
        fieldName: "",
        fromDate: new Date(),
        toDate: null,
        operator: "BETWEEN"
      }
    ],
    tags: Utils.createObjWithoutRef(tagsStoreData?.tagsData) || [],
    listIds: Utils.createObjWithoutRef(customListState?.totalContactList) || []
  };

  const [inputValues, setInputValues] = useState(defaultInputValues);
  const [sequenceLoader, setSequenceLoader] = useState(false);
  const [data, setData] = useState(defaultFilterObject);
  const [showFilter, setShowFilter] = useState(false);
  const [seqContactNavFilter, setSeqContactNavFilter] = useState({});
  const [allEmailsSelectedCheckbox, setAllEmailsSelectedCheckbox] =
    useState(false);
  const [allEmailsSelectedInView, setAllEmailsSelectedInView] = useState(false);

  const handleStateChange = (key = "", value = "", type = "state") => {
    if (key) {
      if (type === "state") {
        let tempObj = key === "all" ? value : { [key]: value };
        setInputValues({ ...inputValues, ...tempObj });
      } else if (type === "filter") {
        setData({ ...data, [key]: value });
      }
    }
  };

  const getPayload = (actionType = "") => {
    const { limit = 10 } = inputValues || {};
    let { name = "" } = outboxStoreData?.activeFilter || {};
    return {
      pageNo: outboxStoreData?.currentPage || 1,
      limit,
      sortDirection: ["scheduled", "all"].includes(name) ? "ASC" : "DESC",
      sortParam: ["scheduled", "all"].includes(name)
        ? "SCHEDULED_DATE"
        : ["replied"].includes(name)
        ? "REPLY_DATE"
        : "SENT_DATE",
      filters: outboxStoreData.getFiltersPayload(actionType)
    };
  };

  const getSelectSeqIdFromSeqContactNavigation = () => {
    let filterList = [...outboxStoreData.filterList];
    if (!filterList.includes("sequences")) {
      let campaignId = seqContactNavFilter?.filters?.campaignId || [];
      if (campaignId?.length > 0) {
        let sequences =
          data?.sequences?.length > 0
            ? data?.sequences
            : outboxStoreData?.sequenceData?.campaignSequenceResponseList || [];
        if (sequences?.length > 0) {
          let tempObj = { ...data };
          filterList.push("sequences");
          tempObj.sequences = [...sequences].map(item => {
            if (item?.id === campaignId?.[0]) {
              item.checked = true;
            }
            return item;
          });
          setData({ ...tempObj });
          outboxStoreData.setTempFilterList(filterList);
          outboxStoreData.setFilterList(filterList);
          outboxStoreData.setFilterObject({ ...tempObj });
        }
      }
    }
    setSeqContactNavFilter({});
  };

  const selectSeqContNavActiveFilter = ({
    memberIds = [],
    campaignId = [],
    toAddress = []
  }) => {
    let filterList = [...outboxStoreData.filterList];
    let tempObj = { ...data };
    if (toAddress?.length > 0) {
      filterList.push("toAddress");
      tempObj.toAddress = toAddress || [];
    }
    if (memberIds?.length > 0 && tempObj?.owners?.length > 0) {
      filterList.push("owners");
      tempObj.owners = [...tempObj.owners].map(item => {
        if (item?.memberId === memberIds?.[0]) {
          item.checked = true;
        }
        return item;
      });
    }
    let sequences =
      data?.sequences?.length > 0
        ? data?.sequences
        : outboxStoreData?.sequenceData?.campaignSequenceResponseList || [];
    if (sequences?.length > 0 && campaignId?.length > 0) {
      filterList.push("sequences");
      tempObj.sequences = [...sequences].map(item => {
        if (item?.id === campaignId?.[0]) {
          item.checked = true;
        }
        return item;
      });
    }
    setData({ ...tempObj });
    outboxStoreData.setTempFilterList(filterList);
    outboxStoreData.setFilterList(filterList);
    outboxStoreData.setFilterObject({ ...tempObj });
  };

  const getUpdatedDataResponse = (dataResponse = {}) => {
    const { result = [] } = dataResponse || {};
    return result?.length > 0
      ? {
          ...dataResponse,
          result: result?.map(item => ({ ...item, isChecked: false }))
        }
      : dataResponse;
  };

  const getOutboxData = async (actionType = "") => {
    console.log("getOutboxData is invoked");
    handleStateChange("all", {
      filterLoading: true,
      dataLoading: true
    });
    let filterPayload = {};
    let dataPayload = {};
    let isNavFromSeqContact = false;
    if (
      location?.state?.payload?.filters &&
      Object.keys(location?.state?.payload?.filters)?.length > 0
    ) {
      setSeqContactNavFilter(location?.state?.payload);
      isNavFromSeqContact = true;
      let payload = location?.state?.payload || {};
      selectSeqContNavActiveFilter(payload?.filters);
      filterPayload = payload?.filters || {};
      dataPayload = {
        ...payload,
        ...{ filters: { ...(payload?.filters || {}), statuses: ["ACTIVE"] } }
      };
      history.replace("/inbox", {});
    } else {
      filterPayload = outboxStoreData.getFiltersPayload(actionType, true) || {};
      dataPayload = getPayload(actionType);
    }
    Utils.mixpanelTrack(MP_EVENT.OUTBOX_FILTER, {
      pageType: "Inbox",
      filterCriteria: dataPayload?.filters || {}
    });
    const [filterResponse, dataResponse] = await Promise.all([
      await outboxStoreData.getOutboxFilterData({
        filters: filterPayload
      }),
      await outboxStoreData.getOutboxData(dataPayload)
    ]);
    const tempDataResponse = getUpdatedDataResponse(dataResponse);
    handleStateChange("all", {
      filterLoading: false,
      dataLoading: false,
      filterObject: filterResponse,
      outboxData: tempDataResponse
    });
    if (isNavFromSeqContact) {
      getSelectSeqIdFromSeqContactNavigation();
      setShowFilter(true);
    }
  };

  const resetFilterSelected = () => {
    outboxStoreData.setActiveOutboxId("");
    let tempObj = { ...data };
    let filterList = [...outboxStoreData?.tempFilterList];
    if (filterList?.length > 0) {
      filterList.forEach(key => {
        if (key === "dates") {
          tempObj[key] = [
            {
              fieldName: "",
              fromDate: new Date(),
              toDate: null,
              operator: "BETWEEN"
            }
          ];
        } else if (key === "toAddress") {
          tempObj[key] = [];
        } else {
          tempObj[key] = [...tempObj[key]].map(item => {
            item.checked = false;
            return item;
          });
        }
      });
    }
    outboxStoreData.setFilterObject({ ...tempObj });
    setData({ ...tempObj });
    return true;
  };

  // Sequence Dropdown
  const getSequenceList = async (pageNo = 1) => {
    if (!sequenceLoader) {
      setSequenceLoader(true);
      const payload = {
        pageNo: pageNo || 1,
        limit: 500,
        filters: {
          memberIds: [userDetail?.userInfo?.memberId]
        }
      };
      let seqObjResponse = await outboxStoreData.getSequenceData(payload);
      const { totalPages = 0, currentPage = 0 } = seqObjResponse || {};
      if (totalPages > 0 && currentPage < totalPages) {
        getSequenceList(currentPage + 1);
      } else {
        setSequenceLoader(false);
      }
      return true;
    }
  };

  const getReloadOutboxCardData = async () => {
    console.log("getReloadOutboxCardData is invoked");
    const { dataLoading = false, filterLoading = false } = inputValues || {};
    if (!(dataLoading && filterLoading)) {
      const dataResponse = await outboxStoreData.getOutboxData(getPayload());
      handleStateChange("outboxData", dataResponse, "state");
    }
  };

  const getInit = async () => {
    tagsStoreData.getAllTags();
    outboxStoreData.getOutboxUnreadCount();
    customListState.getContactList();
    fetchConnectedEmails();
    await getSequenceList();
    getOutboxData();
    hideIntercomChat();
  };

  const showFilterBtn = () => {
    const {
      outboxData = {},
      filterObject = {},
      filterLoading = false,
      dataLoading = false
    } = inputValues || {};
    const { totalElements = 0 } = outboxData || {};
    const { count = 0 } = filterObject || {};
    let value =
      userDetail?.userInfo?.team ||
      (!dataLoading &&
        !filterLoading &&
        (totalElements > 0 ||
          outboxStoreData?.filterList?.length > 0 ||
          count > 0));
    return value;
  };

  useEffect(() => {
    getReloadOutboxCardData();
  }, [outboxStoreData?.isReloadOutboxPage]);

  useEffect(() => {
    const { dataLoading = false, filterLoading = false } = inputValues || {};
    !(dataLoading && filterLoading) && getOutboxData();
  }, [inputValues?.limit, outboxStoreData?.activeFilter]);

  useEffect(() => {
    handleStateChange("listIds", customListState?.totalContactList, "filter");
  }, [customListState?.totalContactList]);

  useEffect(() => {
    handleStateChange("tags", tagsStoreData?.tagsData, "filter");
  }, [tagsStoreData?.tagsData]);

  useEffect(() => {
    handleStateChange("fromAddress", userDetail?.connectedInboxes, "filter");
  }, [userDetail?.connectedInboxes]);

  useEffect(() => {
    handleStateChange(
      "sequences",
      [
        ...data?.sequences,
        ...outboxStoreData.sequenceData.campaignSequenceResponseList
      ],
      "filter"
    );
  }, [outboxStoreData.sequenceData]);

  useEffect(() => {
    handleStateChange("owners", userDetail?.userInfo?.hasTeamMember, "filter");
  }, [userDetail?.userInfo?.hasTeamMember]);

  useEffect(() => {
    getInit();
    return () => {
      setSequenceLoader(false);
      setShowFilter(false);
      setInputValues(Utils.createObjWithoutRef(defaultInputValues));
      let value = resetFilterSelected();
      outboxStoreData.setReset();
      outboxStoreData.setActiveOutboxId("");
    };
  }, []);

  return (
    <div className="outboxPage">
      <HeaderSection />
      <OutboxContext.Provider
        value={{
          inputValues,
          sequenceLoader,
          showFilter,
          data,
          defaultFilterObject,
          allEmailsSelectedCheckbox,
          allEmailsSelectedInView,
          setData,
          handleStateChange,
          setShowFilter,
          setInputValues,
          setAllEmailsSelectedCheckbox,
          setAllEmailsSelectedInView,
          getReloadOutboxCardData
        }}
      >
        <div
          className={`outboxWrapper ${showFilter ? "showOutboxFilter" : ""}`}
        >
          <div
            className={`outboxContentWrapper  ${
              showFilterBtn() ? "" : "showEmptyTable"
            }`}
          >
            <TopFilterContents />
            {showFilterBtn() && <FilterComponent />}
            <OutboxCardList getOutboxData={getOutboxData} />
          </div>
          {showFilter && (
            <OutboxFilterByOption
              applyFilterCbk={getOutboxData}
              resetFilterCbk={resetFilterSelected}
            />
          )}
        </div>
      </OutboxContext.Provider>
    </div>
  );
});

export { Outbox, OutboxContext };
export default withRouter(Outbox);
