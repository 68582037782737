import React from "react";
import { observer } from "mobx-react";
import SubjectLineGenerator from "Components/WritingAssistant/SubjectLineGenerator";

const InputTag = props => {
  const {
    name,
    text,
    type,
    value,
    err,
    fieldReq,
    enableSubjectLineGenerator = false,
    bodyContent = "",
    change = () => {},
    submit = () => {},
    focus = () => {},
    position = () => {}
  } = props;

  const changeCbk = e => {
    change(e.currentTarget.value, name);
  };

  const submitCbk = e => {
    submit(e);
  };

  return (
    <div className="input-form">
      <input
        id={name}
        autoComplete="off"
        name={name}
        type={type}
        value={value}
        onChange={e => change(e.currentTarget.value, name)}
        onKeyUp={e => submit(e)}
        onFocus={e => focus(e)}
        onSelectCapture={e => position(e)}
        required
      />
      <span className="highlight"></span>
      <span className="bar"></span>
      <label htmlFor={name}>
        {text}
        {fieldReq && <sup>*</sup>}
      </label>
      <span className="err-msg-text">{err}</span>
      {enableSubjectLineGenerator && (
        <SubjectLineGenerator
          type="templates"
          content={bodyContent}
          replaceSubjectCbk={text => change(text, name)}
        />
      )}
    </div>
  );
};

export default observer(InputTag);
