import React from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import { TemplateDataStore } from "Stores/Template";
import CreateFolder from "Components/common/CreateFolder";
import EditActionIcon from "Components/common/EditIcon";
import DeleteActionIcon from "Components/common/DeleteIcon";
import { useDrop } from "react-dnd";
import Utils from "Utils/utils";
import { URLS, makeApi } from "Utils/apiURL";
import { MP_EVENT } from "Static/constant";
import { toasterState } from "Components/common/toaster";

const TemplateFolderList = observer((props = {}) => {
  const {
    folderData,
    editFolder,
    savedEditFolder,
    newFolderNameFocus,
    editFolderNameAction,
    cancelEditFolder,
    editedFolderName,
    folderLoader,
    toggleEditIcon,
    deleteFolder
  } = props || {};

  return (
    folderData.length > 0 &&
    folderData.map((value, index) => {
      const { id, isEdit } = value;
      return isEdit ? (
        <CreateFolder
          saveNewFolder={savedEditFolder}
          newFolderNameFocus={newFolderNameFocus}
          newFolderNameAction={editFolderNameAction}
          newFolderNameCancelAction={cancelEditFolder}
          newFolderName={editedFolderName}
          folderLoader={folderLoader}
          id={id}
          key={"editFolder" + index}
        />
      ) : (
        <TemplateFolderRecord
          key={index}
          {...value}
          {...{ index, toggleEditIcon, deleteFolder, editFolder }}
        />
      );
    })
  );
});

const TemplateFolderRecord = (props = {}) => {
  const {
    id = "",
    folderName = "",
    hover = false,
    index = 0,
    toggleEditIcon = () => {},
    deleteFolder = () => {},
    editFolder = () => {}
  } = props || {};

  const removeTemplateFromList = (obj = "") => {
    const { id = "", isSharedFolder = false } = obj || {};
    TemplateDataStore.setTempTemplateList(TemplateDataStore.templateList);
    let tempList = [...(TemplateDataStore?.templateList || [])];
    if (isSharedFolder) {
      return tempList;
    }
    return tempList.filter(item => item.id !== id && item);
  };

  const moveTemplateToAnotherFolder = async (
    item = {},
    showUndoButton = true
  ) => {
    !showUndoButton && toasterState.close();
    if (item?.id && item?.folderId !== id) {
      let removedList = showUndoButton
        ? removeTemplateFromList(item)
        : TemplateDataStore.tempTemplateList;
      const config = {
        url: URLS.moveTemplateToAnotherFolder
          .replace("<<templateId>>", item.id)
          .replace("<<folderId>>", showUndoButton ? id : item?.folderId)
      };
      const res = await makeApi(config);
      const { status = "", success = false } = res?.data || {};
      Utils.mixpanelTrack(MP_EVENT.TP_DRAG_DROP_PERFORMED, {
        pageType: "Templates"
      });
      if (+status === 200 && success) {
        TemplateDataStore.templateData.templates = removedList;
        TemplateDataStore.setTemplateList(removedList);
        !showUndoButton && TemplateDataStore.setTempTemplateList("");
        toasterState.setPopupValues({
          showUndoButton,
          callback: () => {
            moveTemplateToAnotherFolder(item, false);
          }
        });
        let name = showUndoButton ? folderName : item?.folderName;
        let msg = showUndoButton
          ? `to ${name} successfully.`
          : `back to ${name}`;
        toasterState.setToastMsg(`Template moved ${msg}`, "success");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: "moveTemplate",
    drop: moveTemplateToAnotherFolder,
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <li
      id={id}
      className={`template-folder-box ${hover ? "hovered" : ""} ${
        isOver ? "dropOver" : ""
      }`}
      key={id}
      ref={dropRef}
    >
      <NavLink
        to={id}
        className="template-folder-name"
        onMouseEnter={e => toggleEditIcon(e, id, "enter")}
        onMouseLeave={e => toggleEditIcon(e, id, "leave")}
      >
        <i className="material-icons-outlined">folder</i>
        <span className="template-folder-text">{folderName}</span>
        {hover && (
          <div className="wrapActionIcon">
            <EditActionIcon
              action={editFolder}
              id={id}
              itemValue={folderName}
              title="Edit Folder Name"
              spaceRight={0}
            />
            <DeleteActionIcon
              action={deleteFolder}
              id={id}
              itemValue={folderName}
              title="Delete Folder"
              spaceRight={0}
            />
          </div>
        )}
        {isOver && (
          <span className="material-icons-outlined dropHereText template">
            drag_handle
          </span>
        )}
      </NavLink>
    </li>
  );
};

export default TemplateFolderList;
export { TemplateFolderList };
