import React from "react";

const AccountEmailInsights = ({ AccountEmailInsightsData = {} }) => {
  const {
    contactCount = 0,
    emailSent = 0,
    callsMade = 0,
    tasksCompleted = 0,
    tasksInDue = 0
  } = AccountEmailInsightsData?.accountOverallInsights || {};

  const data = [
    {
      title: "Contacts Added",
      value: contactCount || 0,
      color: "#28ad56",
      background: "#28AD5629"
    },
    {
      title: "Emails Sent",
      value: emailSent || 0,
      color: "#28ad56",
      background: "#28AD5629"
    },
    {
      title: "Calls Made",
      value: callsMade || 0,
      color: "#28ad56",
      background: "#28AD5629"
    },
    {
      title: "Tasks Completed",
      value: tasksCompleted || 0,
      color: "#28ad56",
      background: "#28AD5629"
    },
    {
      title: "Tasks Due",
      value: tasksInDue || 0,
      color: "#f59064",
      background: "#f9dec1"
    }
  ];

  return (
    <div className="accountEmailInsights">
      {data?.map((item, index) => (
        <div key={index} className="accountEmailInsightsHeader">
          <div className="accountEmailInsightsTitle">{item?.title}</div>
          <div
            className="accountEmailInsightsBadge"
            style={{ color: item?.color, background: item?.background }}
          >
            {item?.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccountEmailInsights;
export { AccountEmailInsights };
