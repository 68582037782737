import React from "react";
import SecondaryCheckbox from "Components/common/SecondaryCheckbox";

const ActionDomainProspect = ({ checked = false, change = () => {} }) => {
  const handleChange = (value = false) => {
    change("executeForAccount", value);
  };

  const inputObj = {
    label: "Apply the following actions to all contacts in the same Account.",
    id: "actionDomainProspect",
    cbk: handleChange,
    checked
  };

  return (
    <div className="TriggerProspect">
      <SecondaryCheckbox key={inputObj?.id} {...inputObj} />
    </div>
  );
};

export default ActionDomainProspect;
export { ActionDomainProspect };
