import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as UnfoldMoreIcon } from "Assets/svg/WritingAssistant/UnfoldMore.svg";
import Utils from "Utils/utils";
import { MXP_EVENT } from "Static/MixPanelEvents";
import { RewriteAiContext } from "./RewriteAi";
import SearchInputByKeyword from "Components/common/SearchInputByKeyword";

export const DefaultPrompt = () => {
  const {
    loader = false,
    prompts = [],
    inputBoxRef = null,
    promptsLoader = false,
    setPrompts = () => {},
    resetPromots = () => {},
    setText = () => {}
  } = useContext(RewriteAiContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [list, setList] = useState(prompts);

  const handleSearch = (key = "") => {
    let tempList = [];
    if (key?.trim() && prompts?.length > 0) {
      [...prompts].forEach(item => {
        let promptText = item?.prompt?.toLowerCase() || "";
        let title = item?.id?.toLowerCase() || "";
        if (
          (promptText && ~promptText.indexOf(key?.toLowerCase())) ||
          (title && ~title.indexOf(key?.toLowerCase()))
        ) {
          tempList.push(item);
        }
      });
    } else {
      tempList = [...prompts];
    }
    setList(tempList);
  };

  const handleClick = ({ id = "", prompt = "" }) => {
    if (id && prompt && prompts?.length > 0) {
      Utils.mixpanelTrack(MXP_EVENT.CHAT_GPT_AI_PROMPT_CLICKED);
      setText(prompt);
      let tempList = [...prompts].map(item => {
        item.show =
          item?.id?.toLowerCase()?.trim() === id?.toLowerCase()?.trim() ||
          false;
        return item;
      });
      setPrompts(tempList);
      setList(tempList);
      setTimeout(
        () => inputBoxRef && inputBoxRef.current && inputBoxRef.current.focus(),
        100
      );
    }
    setShowDropdown(false);
  };

  useEffect(() => {
    return () => {
      resetPromots();
    };
  }, []);

  return (
    <div className="defaultPrompt">
      <div
        className="wrapperFoldIcon"
        style={{ cursor: loader ? "unset" : "pointer" }}
        onClick={e => {
          Utils.preventDefaultFn(e);
          !loader && setShowDropdown(!showDropdown);
        }}
      >
        <UnfoldMoreIcon />
      </div>
      {showDropdown && (
        <>
          <div
            className={`promptDropdown animatedFast ${
              showDropdown ? "animatedDropDownReverse" : ""
            }`}
          >
            <SearchInputByKeyword
              id="defaultPromptSearch"
              placeholder="Search popular ChatGPT prompts"
              handleSearch={handleSearch}
            />
            <div className="dropdownWrapper">
              {promptsLoader ? (
                <div className="spinnerLoader">
                  <i className="fa fa-spinner fa-spin"></i>
                  <span className="text">Loading</span>
                </div>
              ) : list?.length > 0 ? (
                <ul className="tempDropdown">
                  {list.map((item, index) => {
                    const { id = "", prompt = "", show = false } = item || {};
                    return (
                      <li
                        className={show ? "active" : ""}
                        key={`defaultPrompt${index}`}
                        onClick={e => {
                          Utils.preventDefaultFn(e);
                          handleClick(item);
                        }}
                      >
                        <div className="tempDropdownItem">
                          <div className="text">{id}</div>
                          <div className="desc" title={prompt}>
                            {prompt}
                          </div>
                        </div>
                        <i className="material-icons-outlined arrowForward">
                          arrow_forward
                        </i>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className="emptyResult">No data found</div>
              )}
            </div>
          </div>
          <div
            className="bgOverlay"
            onClick={e => {
              Utils.preventDefaultFn(e);
              setShowDropdown(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default DefaultPrompt;
