import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import EmailInsightsComponent from "Components/common/EmailInsights";
import UpdateContactStageDropdown from "Components/ContactCard/UpdateContactStageDropdown";
import { accountsState } from "../AccountsState";
import AccountEmailInsights from "./AccountEmailInsights";
import { ReactComponent as LogoHolder } from "Assets/svg/logoholder.svg";
import { editAccountPopupState } from "./EditAccountInfoPopup";
import AccountSocialLink from "Components/ContactCard/AccountSocialLink";

const AccountsInfo = observer(({ accountId = "" }) => {
  const [accountInsights, setAccountInsights] = useState([]);
  const [accountEmailInsights, setAccountEmailInsights] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    domain = "",
    name = "",
    linkedInUrl = "",
    twitter = ""
  } = accountEmailInsights?.account || {};

  const getAccountInsightsDetails = async () => {
    try {
      let config = {
        url: `${URLS.getAccountInsights}/${accountId}`,
        method: "GET"
      };
      let response = await makeApi(config);
      if (+response?.status === 200) {
        setAccountInsights(response?.data);
      } else {
        setAccountInsights([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Get AccountInsights error ", err);
    }
  };

  const getAccountEmailInsightsDetails = async () => {
    try {
      let config = {
        url: `${URLS.getAccountEmailInsights}/${accountId}`,
        method: "GET"
      };
      let response = await makeApi(config);
      if (+response?.status === 200) {
        setAccountEmailInsights(response?.data);
      } else {
        setAccountEmailInsights([]);
      }
    } catch (err) {
      console.error("Get AccountEmaiolInsights error ", err);
    }
  };

  const reloadAccountCard = () => {
    getAccountInsightsDetails();
    getAccountEmailInsightsDetails();
  };

  useEffect(() => {
    if (editAccountPopupState?.accountUpdated) {
      getAccountEmailInsightsDetails();
      editAccountPopupState.setAccountUpdated(false);
    }
  }, [editAccountPopupState.accountUpdated]);

  useEffect(() => {
    getAccountInsightsDetails();
    getAccountEmailInsightsDetails();
  }, []);

  return (
    <>
      {loading ? (
        <AccountInfoLoader />
      ) : (
        <div className="accountInfo">
          <div className="accountInsights">
            <AccountHeader
              domain={domain}
              name={name}
              linkedInUrl={linkedInUrl}
              twitter={twitter}
            />
            <AccountInsights
              accountId={accountId}
              reloadAccountCard={reloadAccountCard}
              accountEmailInsights={accountEmailInsights}
            />
          </div>
          <AccountEmailInsights
            AccountEmailInsightsData={accountInsights || []}
          />
        </div>
      )}
    </>
  );
});

const AccountHeader = observer((props = {}) => {
  const {
    domain = "",
    name = "",
    linkedInUrl = "",
    twitter = ""
  } = props || {};

  const getDomain = (website = "", name = "") => {
    let tempDomain = name
      ? name
      : website
      ? website.split(".")[0] || "--"
      : "--";
    if (tempDomain) {
      let updatedDomain = Utils.captializeFirstCharacter(tempDomain);
      accountsState.setAccountName(updatedDomain);
      return updatedDomain || "--";
    } else {
      return "--";
    }
  };

  return (
    <div className="accountInfoHeader">
      <div className="domainName">{getDomain(domain, name)}</div>
      <div className="accountSocialLinks">
        <AccountSocialLink
          domain={domain}
          twitter={twitter}
          linkedInUrl={linkedInUrl}
        />
      </div>
    </div>
  );
});

const AccountInsights = observer((props = {}) => {
  const {
    accountId = "",
    accountEmailInsights = {},
    reloadAccountCard = () => {}
  } = props || {};

  const {
    stage = "",
    industry = "",
    phone = "",
    employeeCount = "",
    address = []
  } = accountEmailInsights?.account || {};
  const { city = "", state = "", country = "" } = address?.[0] || {};

  const {
    sentCount = 0,
    openCount = 0,
    clickCount = 0,
    replyCount = 0
  } = accountEmailInsights?.accountEmailInsights || {};

  const emailInsights = {
    sentCount,
    openCount,
    clickCount,
    replyCount
  };

  const [accountStage, setAccountStage] = useState(stage);

  const formattedAddress =
    [city, state, country]
      .filter(
        item => item !== null && item !== undefined && item?.trim() !== ""
      )
      .join(", ")
      .trim() || "";

  const accountInsightsArr = [
    {
      name: "Industry",
      id: "industry",
      value: industry || ""
    },
    {
      name: "No Of Employees",
      id: "empcount",
      value: employeeCount || ""
    },
    {
      name: "Phone Number",
      id: "phone",
      value: phone || ""
    },
    {
      name: "Address",
      id: "address",
      value: formattedAddress
    },
    { name: "Stage", value: "" }
  ];

  const extractPhoneNumbersFromContact = (obj = {}) => {
    if (obj?.phone?.length) {
      obj.phone = obj.phone[0] || "";
    }
    if (obj?.otherPhone?.length) {
      obj.otherPhone = obj.otherPhone[0] || "";
    }
    return obj;
  };

  const handleEditClick = () => {
    let updatedItem = extractPhoneNumbersFromContact({
      ...accountEmailInsights?.account
    });
    editAccountPopupState.setAccount(updatedItem || {});
    editAccountPopupState.setShowPopup(true);
  };

  return (
    <>
      {accountInsightsArr?.map((item, index) => {
        return (
          <div key={index} className="accountInfoListContainer">
            <div className="accountListTitle">{item?.name}</div>
            {["stage"].includes(item?.name?.toLowerCase()) ? (
              <div className="accountDropdown">
                <UpdateContactStageDropdown
                  stage={accountStage || stage}
                  setStage={setAccountStage}
                  contactStages={accountsState?.accountStages || []}
                  reloadCbk={reloadAccountCard}
                  pageType="accounts"
                  entityId={accountId}
                />
              </div>
            ) : (
              <div className="accountListItem">{item?.value || "--"}</div>
            )}
          </div>
        );
      })}
      <div className="accountEmailInsightsList">
        <div className="accountEmailInsigtsTitle">Email Insights</div>
        {emailInsights && Object.keys(emailInsights)?.length ? (
          <EmailInsightsComponent emailInsight={emailInsights} />
        ) : (
          "--"
        )}
      </div>
      <div className="editWrapper" onClick={handleEditClick}>
        Edit Account
      </div>
    </>
  );
});

const AccountInfoLoader = ({ count = 5 }) => {
  return (
    <div className="accountInfo">
      <div className="accountInsights">
        <div className="accountInfoHeader">
          <div className="accountInfoLogo linear-background" />
          <div className="accountInfoWebsite linear-background" />
        </div>
        {Array.from({ length: count }, (value, index) => (
          <div className="accountInfoBodyLoader" key={index}>
            <div className="accountInsightsLoader linear-background" />
            <div className="accountInsightsLoader linear-background" />
          </div>
        ))}
        <div className="emailInsightLoader">
          <div className="emailInsightTitleLoader linear-background" />
          <div className="emailInsightCountLoader linear-background" />
        </div>
        <div className="accountEditLoader_">
          <div className="accountEditLoader linear-background" />
        </div>
      </div>
      <div className="accountEmailInsights">
        {Array.from({ length: count }, (value, index) => (
          <div key={index} className="accountEmailInsightsLoader">
            <div className="accountEmailInsightsTitleLoader linear-background" />
            <div className="accountEmailInsightsBadgeLoader linear-background" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountsInfo;
export { AccountsInfo };
