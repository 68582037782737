import React from "react";

const TitleBadge = props => {
  const { title, showDesc = false, children = <></> } = props || {};

  return (
    <div className="TitleBadge">
      <h4 className="title">{title}</h4>
      {showDesc && <div className="descText">{children}</div>}
    </div>
  );
};

export default TitleBadge;
export { TitleBadge };
