import React, { useState } from "react";
import { observer } from "mobx-react";
import VariantToggle from "Pages/Dashboard/sequences/SequencePage/VariantToggle";
import { hubspotStore } from "./HubspotStore";

const ActivityTab = observer(() => {
  const [badgeIndex, setBadgeIndex] = useState("");
  const activityCheckList = [
    {
      key: "emailSent",
      id: "emailSent",
      value: hubspotStore?.hubspotPreferences?.syncSentEnabled,
      text: "Email sent"
    },
    {
      key: "emailOpened",
      id: "emailOpened",
      value: hubspotStore?.hubspotPreferences?.syncOpenEnabled,
      text: "Email opened"
    },
    {
      key: "emailReplied",
      id: "emailReplied",
      value: hubspotStore?.hubspotPreferences?.syncReplyEnabled,
      text: "Email replied"
    },
    {
      key: "emailClicked",
      id: "emailClicked",
      value: hubspotStore?.hubspotPreferences?.syncClickEnabled,
      text: "Link clicked in an email"
    },
    {
      key: "phoneCalls",
      id: "phoneCalls",
      value: hubspotStore?.hubspotPreferences?.syncPhoneCallEnabled,
      text: "Phone calls"
    },
    {
      key: "liRequestSent",
      id: "liRequestSent",
      value: hubspotStore?.hubspotPreferences?.syncLiSendRequest,
      text: "LinkedIn connection request sent"
    },
    {
      key: "liRequestAccepted",
      id: "liRequestAccepted",
      value: hubspotStore?.hubspotPreferences?.syncLiAcceptRequest,
      text: "LinkedIn connection request accepted"
    },
    {
      key: "liMessageSent",
      id: "liMessageSent",
      value: hubspotStore?.hubspotPreferences?.syncLiMessageSent,
      text: "LinkedIn message sent"
    },
    {
      key: "liMessageReceived",
      id: "liMessageReceived",
      value: hubspotStore?.hubspotPreferences?.syncLiReplyReceived,
      text: "LinkedIn reply received"
    },
    {
      key: "customTask",
      id: "customTask",
      value: hubspotStore?.hubspotPreferences?.syncCustomTask,
      text: "Custom Task"
    }
  ];

  const mappedConfig = {
    phoneCalls: "syncPhoneCallEnabled",
    emailClicked: "syncClickEnabled",
    emailReplied: "syncReplyEnabled",
    emailOpened: "syncOpenEnabled",
    emailSent: "syncSentEnabled",
    liRequestSent: "syncLiSendRequest",
    liRequestAccepted: "syncLiAcceptRequest",
    liMessageSent: "syncLiMessageSent",
    liMessageReceived: "syncLiReplyReceived",
    customTask: "syncCustomTask"
  };

  const setCheckedValue = (e, value, id) => {
    hubspotStore.setHubspotPreferences({
      ...hubspotStore?.hubspotPreferences,
      [mappedConfig?.[id]]: value
    });
  };

  return (
    <div className="activityWrapper">
      <h5 className="headingText">Activity Logging</h5>
      <p className="descText">
        Select the events that you want to log to your HubSpot CRM
      </p>
      <div className="checkListBox">
        {activityCheckList?.length &&
          activityCheckList?.map((item, index) => {
            const { id = "", text = "", value = "", disabled = false } = item;
            return (
              <div
                key={`${id}-${text}`}
                className="activityCheckListWrapper"
                onMouseEnter={() => setBadgeIndex(index)}
                onMouseLeave={() => setBadgeIndex("")}
              >
                <div
                  className={`variantToggleWrapper ${
                    disabled ? "disabled" : ""
                  }`}
                >
                  <VariantToggle
                    action={setCheckedValue}
                    isChecked={value}
                    id={id}
                  />
                  <span className="toggleText">{text}</span>
                </div>
                {badgeIndex === index && disabled && (
                  <span
                    className="comingSoonBadge"
                    style={{ display: "block" }}
                  >
                    Coming Soon
                  </span>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
});

export { ActivityTab };
export default ActivityTab;
