import React, { useState } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import GoogleBtnImg from "Assets/Settings/googleWhite.png";
import MicrosoftBtnImg from "Assets/Settings/microsoft.png";
import { connectedEmailUtils } from "./ConnectedEmailUtils";

const ConnectEmailButton = observer((props = {}) => {
  const {
    showEmailConfigure: showConfigure = false,
    showSmtpImapConnect = false,
    setSmtpImapConnect = () => {}
  } = props || {};
  const [showDropdown, setShowDropdown] = useState(false);

  const inboxOptions = [
    {
      id: "gmailInbox",
      text: "Sign in with Google",
      type: "gmail",
      img: GoogleBtnImg
    },
    {
      id: "microsoftInbox",
      text: "Sign in with Microsoft",
      type: "outlook",
      img: MicrosoftBtnImg
    },
    {
      id: "smtpImapInbox",
      text: "Connect SMTP/IMAP",
      type: "smtp/imap"
    }
  ];

  const handleChange = event => {
    Utils.preventDefaultFn(event);
    setShowDropdown(false);
    let optDom = event.target.closest("li") || "";
    if (optDom) {
      let type = optDom.getAttribute("inbox-type") || "";
      if (type) {
        type === "outlook" && connectedEmailUtils.microsoftAuth();
        type === "gmail" && connectedEmailUtils.openGmailConnectPopup();
        type === "smtp/imap" && setSmtpImapConnect(true);
      }
    }
  };

  return (
    !showConfigure &&
    !showSmtpImapConnect && (
      <div className="dropdownBtn">
        <Button
          id="connectInboxDropdownBtn"
          name="connectInboxDropdownBtn"
          btnText="Connect Inbox"
          type="button"
          className={`connectInboxDropdownBtn`}
          icon={true}
          iconName="keyboard_arrow_down"
          click={() => setShowDropdown(!showDropdown)}
        />
        {showDropdown && (
          <>
            <ul
              className="inboxTypeOptions"
              onClick={event => handleChange(event)}
            >
              {inboxOptions.map(item => {
                const { id = "", text = "", type = "", img = "" } = item || {};
                return (
                  <li
                    className={`option ${id}`}
                    key={id}
                    id={id}
                    inbox-type={type}
                  >
                    {type !== "smtp/imap" ? (
                      <img src={img} alt={text} />
                    ) : (
                      <>
                        <span className="material-icons-outlined smtpImapIcon">
                          email
                        </span>
                        <span className="btnCta">{text}</span>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
            <div
              className="inboxTypeOptionsOverlay"
              onClick={() => setShowDropdown(false)}
            />
          </>
        )}
      </div>
    )
  );
});

export { ConnectEmailButton };
export default ConnectEmailButton;
