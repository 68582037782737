export const settingsSteps = [
  {
    element: "#addAccount , #googleAuthBtn , #outlookAuthBtn",
    popover: {
      title: "Connect Email Account",
      description:
        "Connect your email account with Salesgear. This will allow us to send emails on your behalf.",
      position: "left"
    }
  }
];

export const createNewSequenceSteps = [
  {
    element: "#addNewSequence",
    popover: {
      title: "Create a Sequence",
      description: "Click here to create your first sequence.",
      position: "left"
    }
  }
];

export const addContactSteps = [
  {
    element: "#addContactBtn",
    popover: {
      title: "Add Contacts to a Sequence",
      description: "Now, let’s add contacts to the sequence you just created.",
      position: "left"
    }
  }
];

export const addNewContactsSteps = [
  {
    element: "#addContactBtnEmpty",
    popover: {
      title: "Add contacts to Salesgear",
      description: "Now, lets start adding your prospect list into Salesgear",
      position: "left"
    }
  }
];

export const addStagesSteps = [
  {
    element: "#addStages",
    popover: {
      title: "Add Steps",
      description: "Create the first step of your sequence.",
      position: "left"
    }
  }
];

export const emailAccountErrorSteps = [
  {
    element: "#connectErrSection",
    popover: {
      title: "Couldn't verify your email account",
      description: `Please follow the instructions here and try again. Write to us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> if you need any assistance in connecting your email account.`,
      position: "left",
      closeBtnText: "Ok"
    }
  }
];

export const createNewTemplateSteps = [
  {
    element: "#addNewTemplates",
    popover: {
      title: "Create a Template",
      description: "Click here to create your first template.",
      position: "left"
    }
  }
];
