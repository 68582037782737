import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import EmptySubjectLine from "./EmptySubjectLine";
import SubjectLineTitleReload from "./SubjectLineTitleReload";
import SubjectLineDropdownList from "./SubjectLineDropdownList";
import GenerateSubjectLineButton from "./GenerateSubjectLineButton";
import { writingAssistantStoreData } from "Stores/WritingAssistantData";
import WritingAssistantUtils from "Utils/WritingAssistantUtils";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";

const SubjectLineGenerator = observer(props => {
  const { content = "", replaceSubjectCbk = () => {}, type = "" } = props || {};

  const {
    writingAssistantEnabled = false,
    inFreePlan = false,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(writingAssistantStoreData.loader);
  const [showDropdown, setShowDropdown] = useState(
    writingAssistantStoreData.subjectLinesList?.length
  );

  const getSubjectLine = (source = "") => {
    const mixpanelProperty = {
      source,
      pageType: type
    };
    Utils.mixpanelTrack(MP_EVENT.WA_SLG_CLICKED, mixpanelProperty);
    if (writingAssistantEnabled && !writingAssistantStoreData?.loader) {
      if (writingAssistantStoreData?.bodyContent?.length || content?.length) {
        let bodyContent =
          writingAssistantStoreData?.bodyContent?.trim() ||
          content?.trim() ||
          "";
        let hasContent =
          WritingAssistantUtils.convertToPlainText(bodyContent)?.trim() || "";
        if (hasContent?.length) {
          writingAssistantStoreData.setLoader(true);
          WritingAssistantUtils.getContent(bodyContent, "\n", mixpanelProperty);
        } else {
          WritingAssistantUtils.showEmptyContentConfirmPrompt();
        }
      } else {
        WritingAssistantUtils.showEmptyContentConfirmPrompt();
      }
    } else {
      WritingAssistantUtils.upgradePopup(inFreePlan, planName);
    }
  };

  const replaceSubject = value => {
    if (!writingAssistantStoreData.loader) {
      replaceSubjectCbk(value);
      setShowDropdown(writingAssistantStoreData.subjectLinesList?.length);
      writingAssistantStoreData.setShowSubjectLineGenerator(false);
      Utils.mixpanelTrack(MP_EVENT.WA_SUBJECT_SELECTED, {
        pageType: type
      });
    }
  };

  const closeSubectLine = () => {
    if (!writingAssistantStoreData.loader) {
      writingAssistantStoreData.setShowSubjectLineGenerator(false);
      writingAssistantStoreData.isInvalidSubjectLine && setShowDropdown(false);
    }
  };

  const alignRightForTemplates = () => {
    return showDropdown && type === "templates" ? 0 : "unset";
  };

  useEffect(() => {
    if (
      writingAssistantStoreData?.subjectLinesList?.length ||
      writingAssistantStoreData.isInvalidSubjectLine
    ) {
      setShowDropdown(true);
      setData(writingAssistantStoreData.subjectLinesList);
    }
  }, [
    writingAssistantStoreData.subjectLinesList,
    writingAssistantStoreData.isInvalidSubjectLine
  ]);

  useEffect(() => {
    setLoader(writingAssistantStoreData.loader);
  }, [writingAssistantStoreData.loader]);

  return (
    (writingAssistantStoreData.showSubjectLineGenerator && (
      <>
        <div
          className="subjectLineGenerator"
          style={{ right: alignRightForTemplates() }}
        >
          {!showDropdown && (
            <GenerateSubjectLineButton
              writingAssistantEnabled={writingAssistantEnabled}
              loader={loader}
              action={getSubjectLine}
              type={type}
            />
          )}
          {showDropdown && (
            <div className="recommendedSubjectLines">
              <SubjectLineTitleReload action={getSubjectLine} loader={loader} />
              {!writingAssistantStoreData.isInvalidSubjectLine ? (
                <SubjectLineDropdownList data={data} action={replaceSubject} />
              ) : (
                <EmptySubjectLine action={getSubjectLine} loader={loader} />
              )}
            </div>
          )}
        </div>
        <span
          className="subjectLineOverlay"
          onClick={() => closeSubectLine()}
        />
      </>
    )) ||
    ""
  );
});

export default SubjectLineGenerator;
export { SubjectLineGenerator };
