import React, { useState, useEffect } from "react";

const Accordian = props => {
  const { title, desc, contentHtml = "" } = props;

  const [activeClass, setActiveState] = useState("");
  const [prevHeight, setHeightState] = useState("0px");
  const [btnText, setBtnTextState] = useState("view");

  const toggleAccordion = () => {
    setActiveState(activeClass === "" ? "active" : "");
    setHeightState(activeClass === "active" ? "0px" : `200px`);
    setBtnTextState(activeClass === "active" ? "view" : "close");
  };

  const resetValue = () => {
    setActiveState("");
    setHeightState("0px");
    setBtnTextState("view");
  };

  const getBoldTitle = () => {
    return btnText === "view" ? "" : "boldBlueText";
  };

  const showOnView = () => {
    return btnText === "view" ? true : false;
  };

  const getPrevModeMargin = () => {
    return btnText === "view" ? "" : "prevMode";
  };

  useEffect(() => {
    resetValue();
  }, [props]);

  return (
    <div className="accordianSection">
      <div className={`accordian ${showOnView() ? "" : "showTitle"}`}>
        <div className={`subjectTitle ${getBoldTitle()}`} title={title}>
          {title}
        </div>
        <div className="descBtnWrap">
          {showOnView() && <p className="desc">{desc}</p>}
          <span className="collapseBtn" onClick={toggleAccordion}>
            {btnText}
          </span>
        </div>
      </div>
      <div
        className={`accordianContent ${getPrevModeMargin()}`}
        style={{ maxHeight: `${prevHeight}` }}
      >
        <div
          className="accordionText"
          dangerouslySetInnerHTML={{ __html: contentHtml }}
        />
      </div>
    </div>
  );
};

export default Accordian;
