/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import BreadCrumbComponent from "Components/common/breadcrumb";
import ButtonComponent from "Components/common/Button";
import { TemplateDataStore } from "Stores/Template";
import { TemplatesContext } from "./TemplateModule";
import { ReactComponent as TemplateIcon } from "Assets/svg/templates.svg";
import TemplatePopupComponent from "Pages/Dashboard/templates/TemplatePopup";
import DropdownMenuComponent from "Components/common/DropDownMenu";
import { withRouter, useLocation } from "react-router-dom";
import { FreeTrial } from "Components/common/FreeTrial";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { KnowMoreButton } from "Components/common/KnowMoreButton";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const TemplateHeaderComponent = observer(props => {
  const location = useLocation();

  const { isTemplatesCreationAllowed } = useContext(TemplatesContext);
  const {
    noOfTemplatesEligible = 0,
    noOfTemplatesUsed = 0,
    inFreePlan,
    planName = ""
  } = userDetail?.userFeatureFlag || {};

  const defaultBreadValue = [{ name: "Templates", link: "/templates/all" }];
  const [breadcrumbArr, setBreadCrumbArr] = useState(defaultBreadValue);
  const [showAddDropdown, setAddDropdown] = useState(false);
  const [showTemplatePopup, setTemplatePopup] = useState(false);
  const [isSharedTemplatesPage, setIsSharedTemplatesPage] = useState(false);

  const createNewFolder = async () => {
    setAddDropdown(false);
    if (await checkTeamSubscriptionActive()) {
      TemplateDataStore.showCreateNewFolder = true;
    }
  };

  const showCreateTemplatePopup = async () => {
    setAddDropdown(false);
    if (await checkTeamSubscriptionActive()) {
      if (isTemplatesCreationAllowed) {
        setTemplatePopup(true);
      } else {
        PaywallUtils.upgradeUtilsFunction(
          "templates",
          false,
          planName,
          inFreePlan,
          { templateCount: noOfTemplatesEligible }
        );
      }
    }
  };

  const updateBreadCrumb = () => {
    if (TemplateDataStore?.folderName !== "") {
      TemplateDataStore.getFolderDetails(TemplateDataStore.folderId);
      let folderPath = defaultBreadValue;
      folderPath.push({
        name: TemplateDataStore.folderName,
        link: "/templates/" + TemplateDataStore.folderName
      });
      folderPath?.length && setBreadCrumbArr([...folderPath]);
    }
  };

  const dropdownOptions = [
      {
        icon: {
          ele: <TemplateIcon />,
          style: { width: "16px", height: "16px" }
        },
        value: "New Template",
        key: "ceateNewTemplate",
        method: showCreateTemplatePopup,
        showUpgrade: !isTemplatesCreationAllowed,
        closeDropdownAction: setAddDropdown,
        type: "newSequence"
      },
      {
        icon: {
          ele: '<i style="font-size: 16px" class="material-icons-outlined list-icon">create_new_folder</i>',
          style: {}
        },
        value: "New Folder",
        key: "createNewTemplateFolder",
        method: createNewFolder
      }
    ],
    selectedOption = "";

  useEffect(() => {
    updateBreadCrumb();
  }, [TemplateDataStore.folderName, TemplateDataStore.folderId]);

  useEffect(() => {
    setIsSharedTemplatesPage(location?.pathname?.indexOf("/shared") !== -1);
  }, [location]);

  return (
    <>
      <div className="headerSection">
        <BreadCrumbComponent breadCrumbValue={breadcrumbArr} />
        {!isSharedTemplatesPage && (
          <div className="headerBtnContainer">
            <FreeTrial {...props} />
            <KnowMoreButton
              redirectUrl={
                "https://support.salesgear.io/en/collections/3172709-templates"
              }
            />
            <div className="add-new-dropdown">
              <ButtonComponent
                id="addNewTemplates"
                name="addNewTemplates"
                type="button"
                className=""
                btnText="Add New"
                icon={true}
                loader={false}
                iconName="keyboard_arrow_down"
                click={() => setAddDropdown(true)}
              />
              <>
                {showAddDropdown ? (
                  <>
                    <DropdownMenuComponent
                      options={dropdownOptions}
                      selected={selectedOption}
                      referenceId=""
                    />
                    <div
                      className="dropdown-overlay"
                      onClick={() => setAddDropdown(false)}
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            </div>
          </div>
        )}
      </div>
      {showTemplatePopup && (
        <TemplatePopupComponent
          cbk={() => setTemplatePopup(false)}
          title="New"
          value={TemplateDataStore?.overviewSelectedTemplate}
          openPopup={() => setTemplatePopup(true)}
        />
      )}
    </>
  );
});

export default withRouter(TemplateHeaderComponent);
