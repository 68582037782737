import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Utils } from "Utils/utils";
import { MP_EVENT, triggerTypeObject } from "Static/constant";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import Title from "./Title";
import FilterDropdown from "./FilterDropdown";
import { triggerState } from "Pages/Dashboard/settings/Triggers/TriggerState";
import TriggerName from "./TriggerName";
import TriggerProspect from "./TriggerProspect";
import FormActionButton from "./FormActionButton";
import TriggerAction from "./TriggerAction";
import TriggerConditions from "./TriggerConditions";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { tagsStoreData } from "Stores/TagsData";
import CustomTooltip from "Components/common/CustomTooltip";
import trackingStore from "Pages/Tracking/trackingStore";
import { ReactComponent as WarningField } from "Assets/svg/warning_filed.svg";

const defaultGroupObject = {
  triggerConditions: {
    conditions: []
  },
  conditionGroups: {
    operator: "AND"
  }
};

const CreateEditTriggerForm = observer(props => {
  const { cancel = () => {} } = props || {};
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState("");
  const [nameError, setNameError] = useState("");
  const [triggerTypeErr, setTriggerTypeErr] = useState("");
  const [triggerConditionsErr, setTriggerConditionsErr] = useState("");
  const [triggerActionsErr, setTriggerActionsErr] = useState("");
  const [contactOwnerIndex, setContactOwnerIndex] = useState("");
  const [contactStageList, setContactStageList] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  const setPayloadForContactOwner = value => {
    let tempList = [];

    const contactOwner =
      (value?.key?.toLowerCase() !== "shared" && {
        fieldName: "CONTACT_OWNER",
        value: value?.memberId,
        operator: "EQUALS"
      }) ||
      {};

    if (triggerState?.inputValue?.groups?.length) {
      tempList = triggerState.inputValue.groups.map(item => {
        const contactOwnerIndex =
          item?.triggerConditions?.conditions?.findIndex(
            item => item?.fieldName === "CONTACT_OWNER"
          );
        if (value?.memberId) {
          if (contactOwnerIndex !== -1) {
            item.triggerConditions.conditions[contactOwnerIndex].value =
              value?.memberId;
          } else {
            item.triggerConditions.conditions = [
              ...item.triggerConditions.conditions,
              contactOwner
            ];
          }
        } else {
          item?.triggerConditions?.conditions &&
            item.triggerConditions.conditions.splice(contactOwnerIndex, 1);
        }
        return item;
      });
    } else {
      tempList = [defaultGroupObject];

      tempList.map(item => {
        if (value?.memberId) {
          item.triggerConditions.conditions = [
            ...item.triggerConditions.conditions,
            contactOwner
          ];
        } else {
          item?.triggerConditions?.conditions &&
            item.triggerConditions.conditions.splice(contactOwnerIndex, 1);
        }
        return item;
      });
    }
    triggerState.setInputValue({
      ...triggerState?.inputValue,
      groups: tempList
    });
  };

  const handleInputChange = (key, value) => {
    let tempObj = { ...triggerState?.inputValue };
    if (key === "contactOwner") {
      let tempActions = tempObj?.actions || [];
      let tempConditions = tempObj?.conditions || [];
      let prevContactOwner = tempObj?.contactOwner?.value || "Shared";
      if (prevContactOwner?.toLowerCase() !== value?.value?.toLowerCase()) {
        tempObj = {
          ...tempObj,
          actions:
            tempActions?.length > 0
              ? tempActions?.map(item => {
                  return ["ADD_TO_SEQUENCE", "COMPLETE_SEQUENCE"]?.includes(
                    item?.actionType
                  )
                    ? { ...item, sequenceId: "", sequenceName: "", value: "" }
                    : item;
                })
              : [],
          conditions:
            tempConditions?.length > 0
              ? tempConditions?.map(item => {
                  return [
                    "ACTIVE_IN_SEQUENCE",
                    "COMPLETED_IN_SEQUENCE"
                  ]?.includes(item?.actionType)
                    ? { ...item, sequenceId: "", sequenceName: "" }
                    : item;
                })
              : []
        };
      }
      const cbk = (cbkValue = false) => {
        if (cbkValue) {
          triggerState.setSequenceCurrentPageNo(1);
          triggerState.setSequenceList([]);
          triggerState.setSequenceReload(true);
          tempObj[key] = {
            value: value?.memberId,
            label: value?.subKey
          };
          setPayloadForContactOwner(value);
        }
      };
      value?.subKey && setContactOwnerIndex("");
      const updatedVal =
        triggerState?.contactOwner?.filter(
          item => item?.memberId === tempObj?.contactOwner?.value
        )?.[0]?.value || triggerState?.contactOwner?.[0]?.value;
      checkForContactOwnerUpdate(updatedVal, cbk);
      setTriggerTypeErr("");
    } else if (key === "triggerType") {
      if (
        tempObj[key] !== value?.subKey &&
        ["CONTACT_OPTEDOUT", "EMAIL_BOUNCED", "WEBSITE_VISITED"]?.includes(
          value?.subKey
        )
      ) {
        const cbk = cbkValue => {
          if (cbkValue) {
            triggerState.setSequenceCurrentPageNo(1);
            triggerState.setDisabledActionFields(value?.subKey);
            tempObj[key] = value.subKey;
          }
        };
        checkForTriggerTypeUpdate(cbk);
      } else {
        tempObj[key] = value.subKey;
        triggerState.setDisabledActionFields("");
      }
      value?.subKey && setActiveDropdownIndex("");
      setTriggerTypeErr("");
    } else {
      tempObj[key] = value;
      key === "name" &&
        (value ? setNameError("") : setNameError("Please enter a valid name."));
      key === "actions" &&
        (value
          ? setTriggerActionsErr("")
          : setTriggerActionsErr("Please enter a valid trigger name"));
      key === "groups" &&
        (value
          ? setTriggerConditionsErr("")
          : setTriggerConditionsErr("Please enter a valid trigger name"));
    }
    triggerState.setInputValue(tempObj);
  };

  const triggerTypeList = [
    {
      value: "Contact created",
      subKey: "CONTACT_CREATED",
      key: "contactCreated",
      method: handleInputChange
    },
    {
      value: "Contact updated",
      subKey: "CONTACT_UPDATED",
      key: "contactUpdated",
      method: handleInputChange
    },
    {
      value: "Contact Opted out",
      subKey: "CONTACT_OPTEDOUT",
      key: "contactOptedOut",
      method: handleInputChange
    },
    {
      value: "Contact Bounced",
      subKey: "EMAIL_BOUNCED",
      key: "emailBounced",
      method: handleInputChange
    },
    {
      value: "Email delivered to the contact",
      subKey: "EMAIL_DELIVERED",
      key: "emailDeliveredToTheContact",
      method: handleInputChange
    },
    {
      value: "Email opened by the contact",
      subKey: "EMAIL_OPENED",
      key: "emailOpenedByTheContact",
      method: handleInputChange
    },
    {
      value: "Clicked on a link by the contact",
      subKey: "EMAIL_CLICKED",
      key: "clickedOnALinkByTheContact",
      method: handleInputChange
    },
    {
      value: "Reply Received (Email)",
      subKey: "EMAIL_REPLIED",
      key: "emailReplied",
      method: handleInputChange
    },
    {
      value: "Reply Received (LinkedIn)",
      subKey: "LINKEDIN_REPLIED",
      key: "linkedInReplied",
      method: handleInputChange
    },
    {
      value: "Reply Received (Email or LinkedIn)",
      subKey: "EMAIL_LINKEDIN_REPLY_RECEIVED",
      key: "repliedByTheContact",
      method: handleInputChange
    },
    {
      value: "Call logged for a contact",
      subKey: "CALL_LOGGED",
      key: "callLoggedForAContact",
      method: handleInputChange
    },
    {
      value: "Website visited",
      subKey: "WEBSITE_VISITED",
      key: "websitevisited",
      method: handleInputChange
    },
    {
      value: "LinkedIn connection accepted",
      subKey: "LI_CONNECTION_REQUEST_ACCEPTED",
      key: "connectionaccepted",
      method: handleInputChange
    }
  ];

  const validateConditions = () => {
    let valid = true;
    if (triggerState?.inputValue?.groups?.length) {
      triggerState.inputValue.groups.map(item => {
        const { triggerConditions = {} } = item || {};
        const { conditions = [] } = triggerConditions || {};
        if (conditions?.length) {
          conditions.map(list => {
            const { fieldName = "", operator = "", value = "" } = list || {};
            if (
              fieldName !== "CONTACT_OWNER" &&
              (!fieldName ||
                !operator ||
                (!["IS_SET", "IS_NOT_SET"]?.includes(operator) && !value))
            ) {
              valid = false;
            }
          });
        }
      });
    }
    return valid;
  };

  const validateActions = () => {
    let valid = true;
    if (triggerState?.inputValue?.actions?.length) {
      triggerState.inputValue.actions.map(item => {
        const {
          actionType = "",
          sequenceId = "",
          task = "",
          contact = {},
          slackMessage = ""
        } = item || {};
        if (!actionType) {
          valid = false;
        }
        if (
          ["COMPLETE_SEQUENCE", "ADD_TO_SEQUENCE"].includes(actionType) &&
          !sequenceId
        ) {
          valid = false;
        }
        if (
          actionType?.toUpperCase() === "CREATE_TASK" &&
          (!task || !Object.keys(task)?.length)
        ) {
          valid = false;
        }
        if (
          actionType?.toUpperCase() === "SLACK_NOTIFICATION" &&
          !slackMessage
        ) {
          valid = false;
        }
        if (actionType === "UPDATE_CONTACT") {
          if (!contact || (contact && !Object.keys(contact)?.length)) {
            valid = false;
          } else if (contact?.length) {
            contact.map(item => {
              const key = Object.keys(item)[0];
              const value = item[key];
              if (!(key && value)) {
                valid = false;
              }
            });
          }
        }
      });
    } else {
      valid = false;
    }
    return valid;
  };
  const checkEmptyValidation = () => {
    let valid = true;
    if (!triggerState?.inputValue?.name) {
      setNameError("Please enter a valid name.");
      valid = false;
      setTimeout(() => {
        setNameError("");
      }, 5000);
    }
    if (!triggerState?.inputValue?.triggerType) {
      setTriggerTypeErr("Please select a valid trigger.");
      valid = false;
      setTimeout(() => {
        setTriggerTypeErr("");
      }, 5000);
    }
    if (!validateConditions()) {
      setTriggerConditionsErr("Please add valid conditions");
      valid = false;
      setTimeout(() => {
        setTriggerConditionsErr("");
      }, 5000);
    }
    if (!validateActions()) {
      setTriggerActionsErr("Please add valid actions");
      valid = false;
      setTimeout(() => {
        setTriggerActionsErr("");
      }, 5000);
    }
    return valid;
  };
  const transformActionsContact = inputVal => {
    const actions = inputVal?.actions;
    actions?.length &&
      actions.map(item => {
        if (item?.actionType === "UPDATE_CONTACT") {
          var transformedContact = item?.contact?.reduce((obj, item) => {
            const key = item && Object.keys(item)[0];
            if (key?.includes("customField")) {
              obj["customFields"] = {
                ...obj["customFields"],
                [key]: item?.[key]
              };
            } else {
              obj[key] = item[key];
            }
            return obj;
          }, {});
          item.contact = transformedContact;
        }
        return item;
      });
    return inputVal;
  };
  const saveTrigger = async () => {
    const validFields = checkEmptyValidation();
    if (validFields) {
      let updatedInputVal = Utils.createObjWithoutRef(triggerState?.inputValue);
      delete updatedInputVal["groups"];
      delete updatedInputVal["contactOwner"];
      updatedInputVal = transformActionsContact(updatedInputVal);
      let isEdit = triggerState.isEdit;
      try {
        setLoader(true);
        const config = {
          url: isEdit ? URLS.updateTrigger : URLS.createTrigger,
          method: isEdit ? "PUT" : "POST",
          data: {
            triggers: {
              id: triggerState.editInputData?.trigger?.id,
              ...updatedInputVal
            },
            groups: triggerState?.inputValue?.groups?.length
              ? triggerState?.inputValue?.groups
              : [defaultGroupObject]
          }
        };
        let response = await makeApi(config);
        if (response?.data) {
          !isEdit &&
            Utils.mixpanelTrack(MP_EVENT.TR_CREATED, {
              pageType: "Setting Triggers"
            });
          toasterState.setToastMsg(
            `Trigger ${isEdit ? "updated" : "created"} successfully`,
            "success"
          );
          cancel();
          triggerState.setIsEdit(false);
          triggerState.setResetInputValue();
        }
        setLoader(false);
      } catch (e) {
        console.error("Create or Updating trigger failed", e);
      }
    }
  };

  const getFullName = (user = {}) => {
    const { firstName = null, lastName = null, email = null } = user || {};
    return (
      (firstName && lastName && `${firstName} ${lastName}`) ||
      firstName ||
      lastName ||
      email
    );
  };

  const resetSequenceValuesInConditionsAndActions = (
    cbk = () => {},
    isFromTriggerType = false
  ) => {
    const confirmationCbk = () => {
      cbk(true);
      let tempList = [...triggerState?.inputValue?.groups];
      if (tempList?.length) {
        tempList.map(item => {
          const { triggerConditions = {} } = item || {};
          const { conditions = [] } = triggerConditions || {};
          if (conditions?.length) {
            triggerConditions.conditions = conditions.filter(
              (list, index) =>
                !["ACTIVE_IN_SEQUENCE", "COMPLETED_IN_SEQUENCE"].includes(
                  list?.fieldName
                )
            );
          }
        });
      }
      let actionsList = triggerState?.inputValue?.actions;
      if (actionsList?.length) {
        actionsList = actionsList.filter(item => {
          return !["ADD_TO_SEQUENCE", "COMPLETE_SEQUENCE"].includes(
            item?.actionType
          );
        });
      }
      triggerState.setInputValue({
        ...triggerState?.inputValue,
        groups: tempList,
        actions: actionsList
      });
    };

    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to change the trigger ${
        isFromTriggerType ? "type" : "owner"
      }? This will reset the sequence related conditions and actions`,
      actionBtnText: "Yes",
      callback: confirmationCbk,
      cancelCbk: () => {
        cbk(false);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  const checkSequenceExistsInCondition = () => {
    let valid = false;
    if (triggerState?.inputValue?.groups?.length) {
      triggerState.inputValue.groups.map(item => {
        const { triggerConditions = {} } = item || {};
        const { conditions = [] } = triggerConditions || {};
        if (conditions?.length) {
          conditions.map(list => {
            const { fieldName = "" } = list || {};
            if (
              ["ACTIVE_IN_SEQUENCE", "COMPLETED_IN_SEQUENCE"].includes(
                fieldName
              )
            ) {
              valid = true;
              return true;
            }
          });
        }
      });
    }
    return valid;
  };

  const checkSequenceExistsInAction = () => {
    let valid = false;
    if (triggerState?.inputValue?.actions?.length) {
      triggerState.inputValue.actions.map(item => {
        const { actionType = "" } = item || {};
        if (["COMPLETE_SEQUENCE", "ADD_TO_SEQUENCE"].includes(actionType)) {
          valid = true;
          return true;
        }
      });
    }
    return valid;
  };

  const checkForContactOwnerUpdate = (value, cbk = () => {}) => {
    const hasSequenceCondition = checkSequenceExistsInCondition();
    const hasSequenceAction = checkSequenceExistsInAction();
    if (
      value?.toLowerCase() !== "shared" &&
      (hasSequenceCondition || hasSequenceAction)
    ) {
      resetSequenceValuesInConditionsAndActions(cbk);
    } else {
      cbk(true);
    }
  };

  const checkForTriggerTypeUpdate = cbk => {
    const hasSequenceCondition = checkSequenceExistsInCondition();
    const hasSequenceAction = checkSequenceExistsInAction();
    if (hasSequenceCondition || hasSequenceAction) {
      resetSequenceValuesInConditionsAndActions(cbk, true);
    } else {
      cbk(true);
    }
  };

  const getSequenceValues = () => {
    const data = {
      pageNo: triggerState?.sequenceCurrentPageNo,
      limit: 500,
      filters: {
        memberIds: [triggerState?.inputValue?.contactOwner?.value].filter(
          Boolean
        )
      }
    };
    triggerState.listSequences(data);
  };

  const transformContactStages = data => {
    let updatedData =
      data?.length > 0 &&
      data.map(item => {
        return (
          item?.name?.toLowerCase() !== "bounced" && {
            ...item,
            value: item?.displayName
          }
        );
      });
    return updatedData.filter(Boolean);
  };

  const constructContactStages = async () => {
    const config = {
      url: URLS.getContactStages,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response?.status === 200 && response?.data) {
      const updatedData = transformContactStages(response?.data);
      setContactStageList([...updatedData]);
    } else {
      setContactStageList([]);
    }
  };

  const getContactTagValues = async () => {
    await tagsStoreData.getAllTags();
  };

  const transformContactOwnerList = data => {
    let updatedData = data?.map(item => {
      const ownerName = getFullName(item);
      return {
        value: ownerName,
        subKey: ownerName,
        key: ownerName,
        method: handleInputChange,
        memberId: item?.memberId
      };
    });
    updatedData = [
      {
        value: "Shared",
        subKey: "Shared",
        key: "shared",
        method: handleInputChange
      },
      ...updatedData
    ];
    triggerState.setContactOwner(updatedData);
  };

  const transformModelForInputVal = () => {
    if (
      triggerState.editInputData &&
      Object.keys(triggerState.editInputData).length
    ) {
      const { trigger, triggerConditions, conditionGroups } =
        triggerState.editInputData;

      const initialData = {
        name: trigger?.name,
        status: trigger?.status,
        triggerType: trigger?.triggerType,
        actions: trigger?.actions,
        groups: [{ triggerConditions, conditionGroups }],
        executeOnce: trigger?.executeOnce,
        executeForAccount: trigger?.executeForAccount || false,
        contactOwner: triggerConditions?.conditions?.filter(
          item => item?.fieldName === "CONTACT_OWNER"
        )?.[0]
      };
      triggerState.setInputValue(initialData);
    }
  };

  const unmountCbk = () => {
    setLoader(false);
    triggerState.setSequenceCurrentPageNo(1);
    triggerState.setSequenceList([]);
    triggerState.setResetInputValue();
    triggerState.setIsEdit(false);
  };

  const getTeamList = async () => {
    const resData = await Utils.getAllMembersInTeam();
    transformContactOwnerList(resData);
  };

  const getDisabledActionFields = () => {
    if (
      ["CONTACT_OPTEDOUT", "EMAIL_BOUNCED", "WEBSITE_VISITED"].includes(
        triggerState?.inputValue?.triggerType
      )
    ) {
      triggerState.setDisabledActionFields(
        triggerState?.inputValue?.triggerType
      );
    }
  };

  useEffect(() => {
    triggerState?.sequenceReload && getSequenceValues();
  }, [triggerState?.sequenceReload]);

  useEffect(() => {
    getSequenceValues();
  }, [
    triggerState.sequenceCurrentPageNo,
    triggerState?.inputValue?.contactOwner
  ]);

  useEffect(() => {
    getTeamList();
    constructContactStages();
    transformModelForInputVal();
    getContactTagValues();
    getDisabledActionFields();
    return () => {
      unmountCbk();
    };
  }, []);

  return (
    <div className="createEditTrigger">
      <Title isEdit={false} />
      <div className="formInputFields">
        <TriggerName
          name={triggerState?.inputValue?.name}
          change={handleInputChange}
          errorMsg={nameError}
        />
        <FilterDropdown
          options={triggerState?.contactOwner}
          showDropdown={contactOwnerIndex === "0"}
          setActiveDropdownIndex={setContactOwnerIndex}
          defaultValue={
            triggerState?.contactOwner?.filter(
              item =>
                item?.memberId === triggerState?.inputValue?.contactOwner?.value
            )?.[0]?.value || triggerState?.contactOwner?.[0]?.value
          }
          label="Trigger Owner"
          referenceId="contactOwner"
          index="0"
        />
        <div className="websiteTrackingNotEnabled">
          <FilterDropdown
            options={triggerTypeList}
            showDropdown={activeDropdownIndex === "0"}
            setActiveDropdownIndex={setActiveDropdownIndex}
            defaultValue={
              triggerState?.inputValue?.triggerType
                ? triggerTypeObject[triggerState?.inputValue?.triggerType]
                : ""
            }
            label="Trigger"
            referenceId="triggerType"
            index="0"
            errorMsg={triggerTypeErr}
          />
          {!trackingStore?.websiteTrackingInstalled &&
            triggerState?.inputValue?.triggerType?.toUpperCase() ===
              "WEBSITE_VISITED" && (
              <div
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <span className="svgIcon crossIcon">
                  <WarningField />
                </span>
                {showTooltip && (
                  <CustomTooltip dynamicDom={true}>
                    Website tracking is currently disabled; this trigger may not
                    work. Please &nbsp;
                    <span
                      onClick={() => {
                        history.push("/settings/website-tracking");
                      }}
                    >
                      configure website tracking
                    </span>
                    &nbsp; to use this trigger.
                  </CustomTooltip>
                )}
              </div>
            )}
        </div>
        <TriggerProspect
          checked={triggerState?.inputValue?.executeOnce}
          change={handleInputChange}
        />
        <TriggerConditions
          groups={triggerState?.inputValue?.groups}
          change={handleInputChange}
          error={triggerConditionsErr}
          defaultGroupObject={defaultGroupObject}
          contactStageList={contactStageList}
        />
        <TriggerAction
          actionsArray={triggerState?.inputValue?.actions}
          change={handleInputChange}
          error={triggerActionsErr}
          contactStageList={contactStageList}
          triggerType={triggerState?.inputValue?.triggerType}
        />
      </div>
      <FormActionButton cancel={cancel} save={saveTrigger} loader={loader} />
    </div>
  );
});

export default CreateEditTriggerForm;
export { CreateEditTriggerForm };
