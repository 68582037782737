import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { toasterState } from "Components/common/toaster";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import DropdownMenu from "Components/common/DropDownMenu";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { ReactComponent as DownArrow } from "Assets/svg/donw-arrow.svg";

export const SequenceList = observer(
  ({
    contact = {},
    contactId = "",
    list = "",
    redirect = () => {},
    reloadCbk = () => {}
  }) => {
    let history = useHistory();
    const [showDropdown, setShowDropdown] = useState("");
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [skipConfirmation, setSkipConfirmation] = useState("");

    const handleConfirmPromptClose = () => {
      confirmationPopupState?.showPopup &&
        confirmationPopupState.setShowPopup(false);
    };

    // REMOVE
    const removeSequenceContact = async (
      scheduledSeqId = "",
      sequenceId = ""
    ) => {
      if (sequenceId && contactId) {
        const config = {
          url: URLS.removeContactFromSequence,
          method: "POST",
          data: { sequenceId, contactId }
        };
        const response = await makeApi(config);
        const { responseCode = 0 } = response?.data || {};
        confirmationPopupState.setShowPopup(false);
        if (+responseCode === 200) {
          toasterState.setToastMsg(
            "Contact has been removed successfully",
            "success"
          );
          reloadCbk("REMOVED", { sequenceId, contactId, scheduledSeqId });
        } else {
          toasterState.setToastMsg(
            "Failed to remove the contact. Please try again later",
            "failure"
          );
        }
      } else {
        handleConfirmPromptClose();
      }
    };

    const removeContactFromSequence = async (obj = "") => {
      setShowDropdown(false);
      if (obj?.sequenceId && contactId) {
        confirmationPopupState.setPopupValues({
          content: `<span style="color: #343A40;">Are you sure you want to remove this contact from <b>${obj?.name}</b> sequence?</span><br/><span style='color: #8D8D8D;font-size: 14px;'>All the scheduled emails & tasks for this contact will be removed from this sequence.</span>`,
          actionBtnText: "Yes",
          cancelBtnText: "Cancel",
          cancelCbk: () => confirmationPopupState.setShowPopup(false),
          callback: () =>
            removeSequenceContact(obj.scheduledSeqId, obj.sequenceId),
          loadingMsg: "Removing. Please wait..."
        });
        confirmationPopupState.setShowPopup(true);
      }
    };

    // COMPLETE
    const replaceUrl = (url = "", sequenceId = "") => {
      return url
        .replace("<<seqId>>", sequenceId)
        .replace("<<contactId>>", contactId);
    };

    const completeSequenceContact = async (
      scheduledSeqId = "",
      sequenceId = ""
    ) => {
      if (scheduledSeqId) {
        let url = URLS.completeScheduledSequenceContact;
        if (url) {
          url = url.replace("<<seqId>>", scheduledSeqId);
          const config = {
            url,
            method: "PUT"
          };
          const response = await makeApi(config);
          const { requestStatus = false } = response?.data;
          confirmationPopupState.setShowPopup(false);
          if (requestStatus) {
            toasterState.setToastMsg(
              "Contact has been marked completed successfully",
              "success"
            );
            reloadCbk("COMPLETED", { sequenceId, contactId, scheduledSeqId });
          } else {
            toasterState.setToastMsg(
              "Failed to mark the contact as complete. Please try again later",
              "failure"
            );
          }
        }
      } else {
        handleConfirmPromptClose();
      }
    };

    const markAsCompleteFromSequence = (obj = {}) => {
      setShowDropdown(false);
      if (obj?.scheduledSeqId) {
        confirmationPopupState.setPopupValues({
          content: `<span style="color: #343A40;">Are you sure you want to complete this contact in <b>${obj?.name}</b> sequence?</span><br/><span style='color: #8D8D8D;font-size: 14px;'>All the scheduled emails & tasks for this contact will be suspended in this sequence.</span>`,
          actionBtnText: "Yes",
          callback: () =>
            completeSequenceContact(obj.scheduledSeqId, obj.sequenceId),
          loadingMsg: "Completing. Please wait..."
        });
        confirmationPopupState.setShowPopup(true);
      }
    };

    // SKIP
    const checkIsEligibleForSkipStage = async (sequenceId = "") => {
      if (sequenceId && contactId) {
        let url = URLS.checkIsEligibleForSkipStage;
        if (url) {
          url = replaceUrl(url, sequenceId);
          const config = {
            url,
            method: "GET"
          };
          const response = await makeApi(config);
          const { isEligibleToScheduleNextTask = false } = response?.data || {};
          if (!isEligibleToScheduleNextTask) {
            confirmationPopupState.setShowPopup(false);
            confirmationPopupState.setPopupValues({
              content: (
                <PromptMessageHandler
                  text="Failed to skip the task for this contact."
                  moreText="The next step in the sequence is missing a subject line. Please configure the next step email as a new thread."
                />
              ),
              actionBtnText: "Okay",
              needCancelBtn: false,
              callback: () => confirmationPopupState.setShowPopup(false),
              dynamicDom: true
            });
            confirmationPopupState.setShowPopup(true);
          } else {
            skipSequenceStageContact(sequenceId);
          }
          setSkipConfirmation(true);
        }
      }
    };

    const skipSequenceStageContact = async (
      scheduledSeqId = "",
      sequenceId = ""
    ) => {
      if (sequenceId && contactId) {
        let url = URLS.skipSequenceStageContact;
        if (url) {
          url = replaceUrl(url, sequenceId);
          const config = {
            url,
            method: "POST"
          };
          const response = await makeApi(config);
          confirmationPopupState.setShowPopup(false);
          const { nextTask = false, success = false } = response?.data || {};
          if (response?.status === 200) {
            if (success) {
              toasterState.setToastMsg(
                nextTask
                  ? "Step skipped and next step in sequence scheduled"
                  : "Step skipped and sequence completed",
                "success"
              );
              reloadCbk("SKIP", { sequenceId, contactId, scheduledSeqId });
            } else {
              toasterState.setToastMsg(
                "Failed to move the contact to next step. Please try again later",
                "failure"
              );
            }
          } else {
            Utils.showApiFailureResponse(response);
          }
        }
      }
    };

    const confirmSkipStageInASequence = (obj = {}) => {
      setShowDropdown(false);
      if (obj?.sequenceId && contactId) {
        confirmationPopupState.setPopupValues({
          content: skipConfirmation
            ? "Skipping this task will stop the sequence for this contact as the next step in this sequence is a threaded email. Please confirm to proceed."
            : `<span style="color: #343A40;">Are you sure you want to skip this contact to next step in <b>${obj?.name}</b> sequence?</span>`,
          actionBtnText: skipConfirmation ? "Confirm" : "Yes",
          callback: () => {
            if (skipConfirmation) {
              skipSequenceStageContact(obj.scheduledSeqId, obj.sequenceId);
            } else {
              checkIsEligibleForSkipStage(obj.scheduledSeqId, obj.sequenceId);
            }
          },
          loadingMsg: "Skipping. Please wait..."
        });
        setSkipConfirmation("");
        confirmationPopupState.setShowPopup(true);
      }
    };

    // PAUSE
    const handlePauseContact = async (scheduledSeqId = "", sequenceId = "") => {
      if (scheduledSeqId) {
        if (await checkTeamSubscriptionActive()) {
          const config = {
            url: URLS.pauseContactSeq?.replace("<<seqId>>", scheduledSeqId),
            method: "PUT"
          };
          let res = await makeApi(config);
          confirmationPopupState?.showPopup &&
            confirmationPopupState.setShowPopup(false);
          if (res?.data) {
            const { requestStatus = "" } = res?.data || {};
            if (requestStatus) {
              reloadCbk("PAUSED", { sequenceId, contactId, scheduledSeqId });
              toasterState.setToastMsg(
                "Contact has been paused successfully",
                "success"
              );
            } else {
              toasterState.setToastMsg(
                "Failed to pause the contact from the sequence",
                "failure"
              );
            }
          } else {
            Utils.showApiFailureResponse(res);
          }
        } else {
          handleConfirmPromptClose();
        }
      } else {
        handleConfirmPromptClose();
      }
    };

    const pauseContactInSequence = (obj = {}) => {
      setShowDropdown(false);
      if (obj?.scheduledSeqId && contactId) {
        confirmationPopupState.setPopupValues({
          content: `<span style="color: #343A40;">Are you sure you want to pause this contact in <b>${obj?.name}</b> sequence?</span>`,
          actionBtnText: "Yes",
          callback: () =>
            handlePauseContact(obj.scheduledSeqId, obj.sequenceId),
          loadingMsg: "Pausing. Please wait..."
        });
        confirmationPopupState.setShowPopup(true);
      }
    };

    // RESUME

    const handleResumeContact = async (
      scheduledSeqId = "",
      sequenceId = ""
    ) => {
      if (scheduledSeqId && contactId) {
        const config = {
          url: URLS.resumeContactSeq.replace("<<seqId>>", scheduledSeqId),
          method: "PUT"
        };
        let res = await makeApi(config);
        confirmationPopupState?.showPopup &&
          confirmationPopupState.setShowPopup(false);
        if (res?.data) {
          const { inboxError = {}, respCode = "" } = res?.data || {};
          if (
            +respCode === 200 &&
            (!inboxError || (inboxError && !Object.keys(inboxError)?.length))
          ) {
            reloadCbk("RESUMED", { sequenceId, contactId, scheduledSeqId });
            toasterState.setToastMsg(
              "Contact has been activated successfully",
              "success"
            );
          } else if (+respCode === 407) {
            toasterState.setToastMsg(
              "Failed to resume contact. Contact's scheduled inbox is unavailable.",
              "failure"
            );
          } else if (+respCode === 403) {
            toasterState.setToastMsg(
              "Failed to resume contact. You don't have access to this contact.",
              "failure"
            );
          } else if (inboxError && Object.keys(inboxError)?.length) {
            const email = Object.keys(inboxError)?.[0];
            confirmationPopupState.setPopupValues({
              content: `<span style="color: #343A40;">Failed to activate the contact as <a class="emailLink" href="mailto:${email}">${email}</a> is not connected to this sequence.</span>`,
              actionBtnText: "Ok",
              callback: () => {
                confirmationPopupState.setShowPopup(false);
                history && history.push("#settings");
              },
              needCancelBtn: false
            });
            confirmationPopupState.setShowPopup(true);
          } else {
            toasterState.setToastMsg(
              "Failed to resume contact from the sequence",
              "failure"
            );
          }
        } else {
          Utils.showApiFailureResponse(res);
        }
      } else {
        handleConfirmPromptClose();
      }
    };

    const OOOPopupContent = ({ rescheduleDate = "" }) => {
      return (
        <>
          <div>Are you sure you want to resume this contact?</div>
          {rescheduleDate && (
            <div style={{ fontSize: "13px", marginTop: "4px" }}>
              {`This contact is tracked as ‘Out of office’ and scheduled to auto-resume on ${Utils.getFormattedDateToDisplay(
                rescheduleDate
              )}. Resuming now will activate it immediately.`}
            </div>
          )}
        </>
      );
    };

    const resumeOOOContactCbk = async (
      scheduledSeqId = "",
      sequenceId = ""
    ) => {
      if (scheduledSeqId && contactId) {
        const config = {
          url: URLS.resumeContactSeq.replace("<<seqId>>", scheduledSeqId),
          method: "PUT"
        };
        let res = await makeApi(config);
        confirmationPopupState.setShowPopup(false);
        if (res?.data) {
          if (res?.data?.status) {
            reloadCbk("RESUMED", { sequenceId, contactId, scheduledSeqId });
            toasterState.setToastMsg(
              "Contact has been activated successfully",
              "success"
            );
          } else {
            toasterState.setToastMsg(
              "Failed to resume contact from the sequence",
              "failure"
            );
          }
        } else {
          Utils.showApiFailureResponse(res);
        }
      }
    };

    const resumeContactInSequence = (obj = {}) => {
      setShowDropdown(false);
      if (obj?.scheduledSeqId && contactId) {
        if (
          ["PAUSED_OOTO"].includes(obj?.contactStatusInSequence?.toUpperCase())
        ) {
          confirmationPopupState.setPopupValues({
            content: <OOOPopupContent {...obj} />,
            actionBtnText: "Yes",
            cancelBtnText: "Cancel",
            cancelCbk: () => confirmationPopupState.setShowPopup(false),
            callback: () =>
              resumeOOOContactCbk(obj?.scheduledSeqId, obj?.sequenceId),
            dynamicDom: true
          });
          confirmationPopupState.setShowPopup(true);
        } else {
          confirmationPopupState.setPopupValues({
            content: `<span style="color: #343A40;">Are you sure you want to resume this contact in <b>${obj?.name}</b> sequence?</span>`,
            actionBtnText: "Yes",
            callback: () =>
              handleResumeContact(obj?.scheduledSeqId, obj?.sequenceId),
            loadingMsg: "Resuming. Please wait..."
          });
          confirmationPopupState.setShowPopup(true);
        }
      }
    };

    // END

    const pauseObj = {
      value: "Pause in sequence",
      key: "pauseContactFromSequence",
      method: pauseContactInSequence,
      type: "pauseContactInSequence"
    };

    const resumeObj = {
      value: "Resume in sequence",
      key: "resumeContactFromSequence",
      method: resumeContactInSequence,
      type: "resumeContactFromSequence"
    };

    const removeObj = {
      value: "Remove",
      key: "removeContactFromSequence",
      method: removeContactFromSequence,
      type: "removeContactFromSequence"
    };

    const markAsCompleteObj = {
      value: "Mark as complete",
      key: "markAsCompleteFromSequence",
      method: markAsCompleteFromSequence
    };

    const skipObj = {
      value: "Skip to next step",
      key: "skipToNextTaskInSequence",
      method: confirmSkipStageInASequence
    };

    const getDropdownOptions = (status = "") => {
      switch (status?.toUpperCase()) {
        case "PENDING":
          return [removeObj];
        case "PAUSED_OOTO":
        case "PAUSED":
          return [resumeObj, removeObj, markAsCompleteObj];
        case "ACTIVE":
          return [pauseObj, removeObj, markAsCompleteObj, skipObj];
        default:
          break;
      }
    };

    useEffect(() => {
      return () => {
        setShowDropdown("");
      };
    }, []);

    return (
      (
        <>
          {list?.length &&
            list.map((item, idx) => {
              const {
                sequenceId = "",
                folderId = "",
                shareType = "",
                name = "",
                contactStatusInSequence = ""
              } = item || {};
              let allowDropdown = ["PAUSED", "ACTIVE", "PENDING"].includes(
                contactStatusInSequence?.toUpperCase()
              );
              let options = getDropdownOptions(
                contactStatusInSequence?.toUpperCase()
              );
              if ((!showMoreButton && idx < 2) || showMoreButton) {
                return (
                  <>
                    <div
                      className="chip"
                      title={name || ""}
                      key={`${sequenceId}Chip${idx}`}
                      onClick={e => {
                        Utils.preventDefaultFn(e);
                        allowDropdown && setShowDropdown(sequenceId);
                      }}
                    >
                      <span
                        className="seqName"
                        onClick={e => {
                          Utils.preventDefaultFn(e);
                          redirect(sequenceId, folderId, shareType);
                        }}
                      >
                        {name}
                      </span>
                      {allowDropdown && options?.length > 0 && (
                        <>
                          <span
                            className={`arrow ${
                              sequenceId === showDropdown ? "activeArrow" : ""
                            }`}
                          >
                            <DownArrow />
                          </span>
                          {sequenceId === showDropdown && (
                            <>
                              <DropdownMenu
                                options={options}
                                referenceId={item}
                              />
                              <div
                                className="dropdownOverlay"
                                onClick={e => {
                                  Utils.preventDefaultFn(e);
                                  setShowDropdown("");
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                );
              }
            })}
          {list?.length > 2 && (
            <div
              className="chip cursorPointer"
              onClick={() => setShowMoreButton(!showMoreButton)}
            >
              <span className="expanBtnText">
                {!showMoreButton ? `${list?.length - 2}+ more` : "less"}
              </span>
            </div>
          )}
        </>
      ) || "--"
    );
  }
);

const PromptMessageHandler = ({ text = "", moreText = "" }) => {
  const showMoreTextRef = React.createRef(null);

  const [showMoreText, setShowMoreText] = useState(false);
  const [showMoreHeight, setShowMoreHeight] = useState(false);

  useEffect(() => {
    setShowMoreHeight(showMoreTextRef?.current?.clientHeight);
  }, [showMoreTextRef]);

  return (
    <div>
      <span>{text} </span>
      <span
        className="linkHighLight"
        onClick={() => {
          setShowMoreText(!showMoreText);
        }}
      >
        show more
      </span>
      {showMoreText && (
        <>
          <div
            className="leftBorder"
            style={{ height: `${showMoreHeight}px` }}
          />
          <div className="failureResponseMsg" ref={showMoreTextRef}>
            {moreText}
          </div>
        </>
      )}
    </div>
  );
};

export default SequenceList;
