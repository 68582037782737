/* eslint-disable no-sequences */
import React from "react";
import { ReactComponent as LikeIcon } from "Assets/svg/heart.svg";

const RegisterFooter = props => {
  const { resetPath, teamPath, activatePath } = props;

  return (
    <div className="footer">
      <div
        className={`container footer-block ${
          resetPath || teamPath || activatePath ? "center-grid" : ""
        }`}
      >
        <div
          className={`empty-block ${
            resetPath || teamPath || activatePath ? "d-none" : ""
          }`}
        >
          &nbsp;
        </div>
        <div className="algin-center">
          <span>Built with</span>
          <LikeIcon />
          <span>by salesgear.io</span>
        </div>
      </div>
    </div>
  );
};

export default RegisterFooter;
