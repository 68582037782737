import React, { useState, memo, useEffect } from "react";
import { makeApi, URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { ReactComponent as DownArrow } from "Assets/svg/donw-arrow.svg";
import { toasterState } from "Components/common/toaster";

export const UpdateContactStageDropdown = memo((props = {}) => {
  const {
    contactStages = [],
    stage = "",
    entityId = "",
    setStage = () => {},
    contactName = "",
    reloadCbk = () => {},
    pageType = ""
  } = props || {};

  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState("below");

  const updateContactStage = async (stageId = "", stageName = "") => {
    if (entityId && stageId) {
      const payload = {
        stageId,
        ...{ [pageType === "accounts" ? "accountId" : "contactId"]: entityId }
      };

      const config = {
        url: ["accounts"].includes(pageType)
          ? URLS?.updateAccountStage
          : URLS?.updateStageInDirectory,
        method: "POST",
        data: payload
      };
      const response = await makeApi(config);
      confirmationPopupState.setShowPopup(false);
      reloadCbk();
      handleApiResponse(response, stageName);
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later.",
        "fail"
      );
      confirmationPopupState.setShowPopup(false);
    }
  };

  const responseMessage = {
    200: "Contact stage updated successfully",
    403: "You don't have editing access to this contact",
    404: "Contact stage updation failed",
    500: "Contact stage updation failed"
  };

  const accountUpdateResponseMessage = {
    200: "Account stage updated successfully."
  };

  const getResponseMessage = (responseCode = "") => {
    return ["accounts"].includes(pageType)
      ? accountUpdateResponseMessage[responseCode] ||
          "Failed to update the Account stage."
      : responseMessage[responseCode] ||
          "Something went wrong. Please try again later.";
  };

  const handleApiResponse = (response = {}, stageName = {}) => {
    let responseCode = response?.status || "";
    if (+responseCode === 200) {
      setStage(stageName);
    } else {
      responseCode = response?.response?.status || "";
    }
    toasterState.setToastMsg(
      getResponseMessage(responseCode),
      +responseCode === 200 ? "success" : "fail"
    );
  };

  const updateContactStageConfirmPrompt = (stageId = "", stageName = "") => {
    confirmationPopupState.setPopupValues({
      content: (
        <>
          Are you sure you want to update{" "}
          {!["accounts"].includes(pageType) ? (
            <>
              <span className="highlightText">
                {contactName ? `${contactName}'s` : ""}
              </span>
              {contactName ? " contact " : "the contact's "}
            </>
          ) : (
            "the accounts's "
          )}
          stage to <span className="highlightText">{stageName}</span>?
        </>
      ),
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      loadingMsg: "Please wait...",
      callback: () => updateContactStage(stageId, stageName),
      dynamicDom: true
    });
    confirmationPopupState.setShowPopup(true);
  };

  const changeAction = (event = "") => {
    let stageId = Utils.performActionByClosestDom(event, "li", "data-key");
    let stageName = Utils.performActionByClosestDom(event, "li", "data-value");
    if (stageName) {
      setShowDropdown(false);
      updateContactStageConfirmPrompt(stageId, stageName);
    }
  };

  const handleShowDropDown = (e, showDropdown = false) => {
    Utils.preventDefaultFn(e);
    setShowDropdown(!showDropdown);
  };

  const handleDropdownPostion = () => {
    const targetDom = document.getElementById(entityId);
    const parentDom = document.querySelector(".accountsListTableBody");
    const targetOffset = targetDom ? targetDom.offsetTop + 16 : 0;
    const dropdownPosition =
      targetOffset && parentDom && targetOffset > parentDom.offsetHeight - 60
        ? "above"
        : "below";
    setDropdownPosition(dropdownPosition);
  };

  useEffect(() => {
    if (showDropdown) {
      handleDropdownPostion();
    }
  }, [showDropdown]);

  return (
    (contactStages?.length > 0 && (
      <span className="updateContactStageCont">
        <div
          className="selectedStage"
          onClick={e => handleShowDropDown(e, showDropdown)}
        >
          <span className="stageName">{stage}</span>
          <span className={`arrow ${showDropdown ? "activeArrow" : ""}`}>
            <DownArrow />
          </span>
        </div>
        {showDropdown && (
          <>
            <div
              className={`drop-down-menu animatedFast animatedDropDown ${dropdownPosition}`}
            >
              <ul className="dropdownList" onClick={changeAction}>
                {contactStages?.map(item => {
                  const { displayName = "", id = "" } = item || {};
                  if (
                    !["opted out", "do not contact", "bounced"].includes(
                      displayName?.toLowerCase()
                    )
                  ) {
                    return (
                      <li
                        id={id}
                        key={id}
                        data-key={id}
                        data-value={displayName}
                        className={`option ${
                          displayName === stage ? "disabled" : ""
                        }`}
                      >
                        <div className="listItem">
                          <span className="listItemWrap">
                            <span className="itemName">{displayName}</span>
                          </span>
                        </div>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            <div
              className="dropdownMask"
              onClick={e => handleShowDropDown(e, true)}
            />
          </>
        )}
      </span>
    )) ||
    "--"
  );
});

export default UpdateContactStageDropdown;
