import React from "react";
import ReactPaginate from "react-paginate";

const PaginationComponent = props => {
  const {
    totalPages = 0,
    loading = false,
    callback = () => {},
    currentPage = 1,
    forceRender = false // Added for data search page.
  } = props || {};

  return !loading && !forceRender ? (
    (totalPages > 1 && (
      <div
        className={`pagination-section ${currentPage} ${
          loading ? "loading" : ""
        }`}
      >
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          forcePage={currentPage - 1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={callback}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={`active ${forceRender}`}
        />
      </div>
    )) ||
      ""
  ) : (
    <div className="pagination-section">
      {Array.from({ length: 7 }, (value, index) => (
        <div
          className="pageNoLoader linear-background"
          key={`pageNoLoader${index + 1}`}
        />
      ))}
    </div>
  );
};

export default PaginationComponent;
