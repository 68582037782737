import React, { useState } from "react";
import { observer } from "mobx-react";
import CustomTooltip from "Components/common/CustomTooltip";
import { sequenceStoreData } from "Stores/sequenceData";
import ActivateToggle from "./ActivateToggle";

const ActivateSequence = observer(props => {
  const {
    status = "",
    showSequenceToggleBtnLoad = false,
    setShowActivateLoader = () => {},
    ...remainingProps
  } = props || {};

  const hasInActiveStatus = status?.toLowerCase() === "draft";
  const [showTooltip, setShowTooltip] = useState(false);

  const cbk = () => {
    setShowActivateLoader(true);
    sequenceStoreData.setSeqActivated(false);
  };

  return (
    <div className="activateSequenceWrapper inactiveStatus noRightPadding">
      <div className="leftToggleContent">
        <div className="toggleText">
          Activate the toggle to start scheduling contacts for the steps below.
        </div>
      </div>
      <div
        className={`rightStatus ${
          hasInActiveStatus ? "inactiveStatus" : "activeStatus"
        } toogleSequence ${
          sequenceStoreData?.seqActivated ? "firstContactAdded" : ""
        }`}
      >
        <div className="statusText">
          {hasInActiveStatus ? "Inactive" : "Active"}
        </div>
        <ActivateToggle
          cbk={cbk}
          setShowTooltip={setShowTooltip}
          showSequenceToggleBtnLoad={showSequenceToggleBtnLoad}
          {...remainingProps}
        />
        {showTooltip && !sequenceStoreData?.seqActivated && (
          <CustomTooltip text={"Activate sequence"} />
        )}
        {sequenceStoreData?.seqActivated && (
          <CustomTooltip
            className="firstContactAddedTooltip"
            text={"Enable the toggle to activate this sequence"}
          />
        )}
      </div>
    </div>
  );
});

const ActivateSequenceLoader = () => {
  return (
    <div className="activateSequenceLoader activateSequenceWrapper">
      <div className="flexWrapper">
        <div className="subject linear-background" />
      </div>
      <div className="rightStatus">
        <div className="statusText linear-background" />
        <div className="toggleBtnSection linear-background" />
      </div>
    </div>
  );
};

const ActivateSequenceToggle = observer(props => {
  const {
    status = "",
    showSequenceToggleBtnLoad = false,
    ...remainingProps
  } = props || {};
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="activateSeqToggle leftToggleContent toogleSequence">
      <ActivateToggle
        setShowTooltip={setShowTooltip}
        showSequenceToggleBtnLoad={showSequenceToggleBtnLoad}
        {...remainingProps}
      />
      {showTooltip && <CustomTooltip text={"Activate sequence"} />}
    </div>
  );
});

export { ActivateSequence, ActivateSequenceLoader, ActivateSequenceToggle };
export default ActivateSequence;
