/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { setTimeout } from "timers";

class ContactSelectedTagsListStore {
  @observable tagSelectedList = [];
  @observable removeCbk = () => {};
  @observable list = [];

  @action
  setList = list => {
    this.list = list;
  };

  @action
  setSelectedtagList = list => {
    this.tagSelectedList = list;
  };

  @action
  setRemoveCbk = cbk => {
    this.removeCbk = cbk;
  };

  @action
  setEmptyTagList = () => {
    this.tagSelectedList = [];
  };

  @action
  removeTagFromList = obj => {
    const selectedTagList = JSON.parse(JSON.stringify(this.tagSelectedList));
    if (selectedTagList?.length > 0) {
      selectedTagList.map((item, index) => {
        if (item?.id === obj?.id) {
          selectedTagList.splice(index, 1);
        }
      });
      this.updateRemovedTag(obj);
      this.setSelectedtagList(selectedTagList);
    }
  };

  @action
  updateRemovedTag = removedObj => {
    this.removeCbk(removedObj, this.list);
  };
}

const contactSelectedTagListStore = new ContactSelectedTagsListStore();

const ContactSelectedTagList = () => {
  const [tagList, setTagList] = useState([]);
  const [numberOfTagsSelected, setNumberOfTagsSelected] = useState(0);
  const [showMoreBtn, setShowMoreBtn] = useState(false);
  const [toggleShowMoreBtn, setToggleShowMore] = useState(false);

  const removeTag = item => {
    contactSelectedTagListStore.removeTagFromList(item);
  };

  const updateTagsSelectedCount = data => {
    let count = 0;
    data.map(value => {
      if (value && value.checked) {
        count++;
      }
    });
    setNumberOfTagsSelected(count);
  };

  const calcHeight = () => {
    const ele = document.getElementsByClassName("selectedTags");
    setTimeout(() => {
      if (ele.length) {
        if (ele[0].classList.contains("addShowMore")) {
          ele[0].classList.remove("addShowMore");
        }
        if (ele[0].offsetHeight > 38) {
          if (!toggleShowMoreBtn) {
            setShowMoreBtn(true);
            ele[0].classList.add("addShowMore");
          }
        } else {
          if (toggleShowMoreBtn) {
            setToggleShowMore(false);
          }
          ele[0].classList.add("max-all");
          setShowMoreBtn(false);
        }
      }
    }, 0);
  };

  const toggleTagsOverlay = value => {
    if (value) {
      setToggleShowMore(true);
      const ele = document.getElementsByClassName("selectedTags");
      ele[0].classList.remove("addShowMore");
    } else {
      setToggleShowMore(false);
      const ele = document.getElementsByClassName("selectedTags");
      ele[0].classList.add("addShowMore");
    }
  };

  useEffect(() => {
    setTagList(contactSelectedTagListStore.tagSelectedList);
    updateTagsSelectedCount(contactSelectedTagListStore.tagSelectedList);
    calcHeight();
  }, [contactSelectedTagListStore.tagSelectedList]);

  return tagList?.length > 0 && numberOfTagsSelected > 0 ? (
    <>
      <div
        className={`contactTagList ${toggleShowMoreBtn ? "showMoreTags" : ""}`}
      >
        {numberOfTagsSelected > 0 && (
          <div className="tagLabel">
            Tag{numberOfTagsSelected > 1 ? "s" : ""}
          </div>
        )}
        <ul className="selectedTags" id="selectedTagList">
          {tagList?.length > 0 &&
            tagList.map((item, index) => {
              const { id, value, checked } = item || {};
              if (checked) {
                return (
                  <li className="tagItem" key={id + index}>
                    <span className="tagName">{value}</span>
                    <i
                      className="material-icons"
                      onClick={() => removeTag(item)}
                    >
                      close
                    </i>
                  </li>
                );
              }
            })}
        </ul>
        {showMoreBtn &&
          (toggleShowMoreBtn === false ? (
            <>
              <div
                className="moreOptions showMore"
                onClick={() => toggleTagsOverlay(true)}
              >
                Show More
              </div>
            </>
          ) : (
            <div
              className="moreOptions show-less"
              onClick={() => toggleTagsOverlay(false)}
            >
              Show Less
            </div>
          ))}
      </div>
      {toggleShowMoreBtn && <span>&nbsp;</span>}
    </>
  ) : (
    <div>&nbsp;</div>
  );
};

export default observer(ContactSelectedTagList);
export { ContactSelectedTagList, contactSelectedTagListStore };
