import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import TemplateOverViewComponent from "Pages/Dashboard/templates/TemplateOverview";

const TemplatesRouting = props => {
  const { isTemplatesCreationAllowed = false } = props || {};
  return (
    <Switch>
      <Route
        path="/templates/all"
        component={TemplateOverViewComponent}
        isTemplatesCreationAllowed={isTemplatesCreationAllowed}
      />
      <Route
        exact
        path="/templates/shared"
        children={<TemplateOverViewComponent sharedTemplates />}
      />
      <Route
        path="/templates/:id"
        children={
          <TemplateOverViewComponent
            isTemplatesCreationAllowed={isTemplatesCreationAllowed}
          />
        }
      />
      <Redirect from="/templates" to="/templates/all" />
    </Switch>
  );
};

export default TemplatesRouting;
