/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from "react";
import { SecondaryCheckbox } from "Components/common/SecondaryCheckbox";

const MemberConfiguration = props => {
  const {
    memberDetails = {},
    setMemberDetails = () => {},
    setEnableSaveChangeBtn = () => {},
    isFeatureEnabled = false
  } = props || {};

  const updateMemberConfig = (checkBoxValue, eventType, label, email) => {
    const stateCopy = { ...memberDetails }; // Deep copy
    stateCopy &&
      Object.keys(stateCopy).map(member => {
        const { memberEmail } = stateCopy[member];
        if (memberEmail === email) {
          stateCopy[member][eventType] = checkBoxValue;
        }
      });
    setMemberDetails(stateCopy);
    setEnableSaveChangeBtn(true);
  };

  return (
    <section className="memberconfig">
      <div className="textCont">
        <span className="textDark">Configuration</span>
      </div>
      <div
        className={`tableView ${!isFeatureEnabled ? "disabledConfigure" : ""}`}
      >
        <table style={{ width: "100%" }}>
          <TableHeader />
          <TableBody
            members={memberDetails}
            cbk={updateMemberConfig}
            isFeatureEnabled={isFeatureEnabled}
          />
        </table>
      </div>
    </section>
  );
};

const TableHeader = () => {
  const tableHeader = [
    { name: "User Email", key: "userEmail" },
    {
      name: "Opened",
      key: "opened",
      style: { textAlign: "center" }
    },
    {
      name: "Clicked",
      key: "clicked",
      style: { textAlign: "center" }
    },
    {
      name: "Replied",
      key: "replied",
      style: { textAlign: "center" }
    }
  ];

  return (
    <thead className="tableHeaderWrapper">
      <tr>
        {tableHeader?.map(item => {
          const { name = "", key = "", style = {} } = item || {};
          return (
            <th className="textDark" key={key} style={style}>
              {name}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const TableBody = props => {
  const { members = {}, cbk = () => {} } = props || {};
  return (
    <tbody className="tableBodyWrapper">
      {members &&
        Object.keys(members).map(member => {
          const {
            memberEmail = "",
            reply = false,
            open = false,
            click = false
          } = members?.[member];
          return (
            <tr key={memberEmail}>
              <td className="email">{memberEmail}</td>
              <td className="open">
                <SecondaryCheckbox
                  id="open"
                  checked={open}
                  cbk={cbk}
                  cbkData={memberEmail}
                />
              </td>
              <td className="click">
                <SecondaryCheckbox
                  id="click"
                  checked={click}
                  cbk={cbk}
                  cbkData={memberEmail}
                />
              </td>
              <td className="reply">
                <SecondaryCheckbox
                  id="reply"
                  checked={reply}
                  cbk={cbk}
                  cbkData={memberEmail}
                />
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default MemberConfiguration;
export { MemberConfiguration };
