/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React from "react";
import ContactUploadErrorIcon from "Assets/png/contactUploadError.png";
import Button from "../Button";
import { confirmationPopupState } from "../confirmationPopup";

const BulkPopupMessage = props => {
  const { errorDetails, showSkipContacts, closeCbk, backToContactTable } =
    props;

  const getMessage = () => {
    if (errorDetails && Object.keys(errorDetails).length) {
      const { missingFields, validCount, uploadedCount, limitReachedMessage } =
        errorDetails;
      if (limitReachedMessage && limitReachedMessage.length) {
        return (
          <div
            className="accordionText"
            dangerouslySetInnerHTML={{ __html: limitReachedMessage }}
          />
        );
      } else if (validCount === 0) {
        return constructAllFieldsMissingDom(missingFields);
      } else {
        return partialiFieldsMissingDom(
          uploadedCount,
          validCount,
          missingFields
        );
      }
    }
  };

  const constructAllFieldsMissingDom = (missingFields = []) => {
    const missingFieldsLen = missingFields?.length;
    const fieldText = missingFieldsLen > 1 ? "fields are" : "field is";
    return (
      <div className="allFieldsMissingCont">
        <div className="messageCont">
          <div className="message">
            The following mandatory {fieldText} missing
          </div>
          {getMissingFieldsDom(missingFields)}
        </div>
      </div>
    );
  };

  const getMissingFieldsDom = (missingFields = []) => {
    const missingFieldsLen = missingFields?.length;
    return (
      <div className="missingFieldsCont">
        {missingFields &&
          missingFields.map((value, index) => {
            return (
              <span key={`missingField ${index}`} className="missingField">
                {missingFieldsLen > 1 && (
                  <span className="indexValue"> {index + 1}.&nbsp;</span>
                )}
                <span className="value">{value}</span>
                {index + 1 !== missingFieldsLen ? (
                  <span className="delimitter">|</span>
                ) : (
                  ""
                )}
              </span>
            );
          })}
      </div>
    );
  };

  const partialiFieldsMissingDom = (
    uploadedCount,
    validCount,
    missingFields
  ) => {
    const missingFieldsLen = missingFields?.length || 0;
    const contactsText = uploadedCount > 1 ? "contacts" : "contact";
    const fieldText = missingFieldsLen > 1 ? "fields" : "field";
    const invalidContactCount = Math.abs(uploadedCount - validCount);
    let msg = `You have <b>uploaded ${uploadedCount} ${contactsText}</b> ${
      missingFieldsLen > 0
        ? `and <b>${invalidContactCount} of them</b> don't have the following mandatory ${fieldText}`
        : `with <b>${invalidContactCount}</b> duplicate ${
            invalidContactCount > 1 ? "entries" : "entry"
          }`
    }`;
    return (
      <div className="partialFieldsMissingCont">
        <div className="messageCont">
          <div className="message" dangerouslySetInnerHTML={{ __html: msg }} />
          {getMissingFieldsDom(missingFields)}
        </div>
      </div>
    );
  };

  const getSkipContactBtn = () => {
    const {
      missingFields = [],
      validCount = 0,
      uploadedCount = 0
    } = errorDetails || {};
    const invalidContactCount = Math.abs(uploadedCount - validCount);
    const missingFieldsLen = missingFields?.length || 0;

    const skipAndUpload = () => {
      const { cbk, validEmails, originalContactPayload } = errorDetails || {};
      cbk(validEmails, originalContactPayload);
    };

    const cancelUpload = () => {
      const { closeAction } = errorDetails;
      confirmationPopupState.setPopupValues({
        content:
          "Are you sure you want to cancel this upload?<br> Any changes made will be lost.",
        actionBtnText: "Yes",
        callback: closeAction
      });
      confirmationPopupState.setShowPopup(true);
    };

    return (
      <>
        <div className="cancelText">
          You can{" "}
          <span className="canceLink" onClick={e => cancelUpload(e)}>
            cancel
          </span>{" "}
          the upload, add the missing fields and upload again
        </div>
        <div className="orText">(or)</div>
        <div className="btnCont">
          <Button
            id="backToContactTable"
            name="backToContactTable"
            type="button"
            className="backToContactTable"
            btnText="Back"
            icon={true}
            iconName="keyboard_backspace"
            loader={false}
            click={e => {
              backToContactTable(e);
            }}
          />
          <Button
            id="skipAndUploadContact"
            name="skipAndUploadContact"
            type="button"
            className="skipAndUploadContact"
            btnText={`Skip the ${
              missingFieldsLen > 0
                ? `contact${
                    invalidContactCount > 1 ? "s" : ""
                  } with missing fields`
                : `duplicate contact${invalidContactCount > 1 ? "s" : ""}`
            } and proceed`}
            loader={false}
            click={e => {
              skipAndUpload(e);
            }}
          />
        </div>
      </>
    );
  };

  const getOkContactBtn = () => {
    const { missingFields } = errorDetails || {};
    const missingFieldsLen = missingFields && missingFields.length;
    const fieldText = missingFieldsLen > 1 ? "fields" : "field";

    return (
      <>
        <div className="reuploadMsg">
          Please update your CSV with the missing {fieldText} and upload again
        </div>
        <div className="btnCont">
          <Button
            id="backToContactTable"
            name="backToContactTable"
            type="button"
            className="backToContactTable"
            btnText="Back"
            loader={false}
            icon={true}
            iconName="keyboard_backspace"
            click={e => {
              backToContactTable(e);
            }}
          />
          <Button
            id="reUploadContact"
            name="reUploadContact"
            type="button"
            className="reUploadContact"
            btnText="Ok"
            loader={false}
            click={e => {
              closeCbk();
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="bulkPopupMessage">
      <div className="imageCont">
        <img src={ContactUploadErrorIcon} alt="" className="contactErrorIcon" />
      </div>
      <div
        className={`errorMessage ${
          errorDetails.limitReachedMessage ? "limiReached" : ""
        }`}
      >
        {getMessage()}
      </div>
      {errorDetails && !errorDetails.limitReachedMessage && (
        <div className="bottomCont">
          {showSkipContacts ? getSkipContactBtn() : getOkContactBtn()}
        </div>
      )}
    </div>
  );
};

export { BulkPopupMessage };
export default BulkPopupMessage;
