import React from "react";

const CommonPopup = props => {
  const {
    headerTitle = "Heading",
    HeaderChildren = <></>,
    children = <></>,
    className = "",
    closeIconCbk = () => {},
    hasHeaderChildren = false
  } = props;

  return (
    <section className={`commonPopupWrapper ${className}`}>
      <div className="commonPopupContainer">
        {hasHeaderChildren ? (
          <HeaderChildren />
        ) : (
          <div className="commonPopupHeader">
            <h4 className="commonPopupHeading">{headerTitle}</h4>
            <i
              className="material-icons commonPopupCloseIcon"
              onClick={closeIconCbk}
            >
              close
            </i>
          </div>
        )}
        {children}
      </div>
      <div className="commonPopupOverlay" onClick={closeIconCbk} />
    </section>
  );
};

export { CommonPopup };
export default CommonPopup;
