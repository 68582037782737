import React, { useState } from "react";
import { observer } from "mobx-react";
import { initialSchedulePopupState } from "./InitialScheduleInfoPopup";
import Utils from "Utils/utils";
import SequenceUtils from "Utils/SequenceUtils";
import { toasterState } from "Components/common/toaster";

const ActivateToggle = observer((props = {}) => {
  const {
    showSequenceToggleBtnLoad = false,
    setShowTooltip = () => {},
    cbk = () => {},
    seqData = {},
    getSelectedSeqData = () => {},
    ...remainingProps
  } = props || {};

  const [toggleChecked, setToggleChecked] = useState(false);

  const checkBoxToggleAction = () => {
    if (!toggleChecked) {
      if (!Utils.isAccountConnected()) {
        Utils.showAddAccountPopup(
          props,
          "Please connect your email account before activating the sequences"
        );
      } else if (!SequenceUtils.hasSteps(seqData)) {
        toasterState.setToastMsg(
          "Please add at least one step to start the sequence.",
          "fail"
        );
      } else {
        initialSchedulePopupState.setDefaultValues(seqData);
        initialSchedulePopupState.setDefaultProps({
          ...remainingProps,
          sequence: seqData,
          sequenceId: seqData?.id
        });
        initialSchedulePopupState.setShowPopup(true);
        initialSchedulePopupState.setCbk(() => {
          cbk();
          setToggleChecked(!toggleChecked);
          getSelectedSeqData();
        });
      }
    }
  };

  return (
    <>
      <div
        className={`toogleButton ${showSequenceToggleBtnLoad ? "loading" : ""}`}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <input
          type="checkbox"
          className="toggleCheckbox"
          checked={toggleChecked}
          onChange={checkBoxToggleAction}
        />
        <div className="knobs" />
        <div className="layer" />
      </div>
    </>
  );
});

export { ActivateToggle };
export default ActivateToggle;
