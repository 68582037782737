import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { ReactComponent as DragAndDropIcon } from "Assets/svg/dragAndDropIcon.svg";
import CheckBox from "Components/common/CheckBox";
import Button from "Components/common/Button";

const CustomDragAndDrop = observer((props = {}) => {
  const { dataArr = [], cbk = () => {}, saveLoader = null } = props || {};

  const dragHeader = useRef(0);
  const draggedOverHeader = useRef(0);
  const [itemList, setItemList] = useState(dataArr);
  const [showDropDown, setShowDropDown] = useState(false);
  const [trackChanges, setTrackChanges] = useState(false);
  const [allCheckBoxSelected, setAllCheckBoxSelected] = useState(false);
  const [draggedId, setDraggedId] = useState("");

  const CheckAllDataIfEnabled = (tempList = []) => {
    return [...tempList].every(item => item.enabled);
  };

  const handleCheckboxAction = (value = false, type = "") => {
    setTrackChanges(true);
    let tempList = [];
    if (itemList?.length > 0) {
      if (type?.toLowerCase() === "selectall") {
        setAllCheckBoxSelected(value);
        tempList = [...itemList].map(item => ({ ...item, enabled: value }));
        setItemList(tempList);
      } else {
        tempList = [...itemList].map(item =>
          item?.name?.toLowerCase() === type?.toLowerCase()
            ? { ...item, enabled: value }
            : item
        );
        setItemList(tempList);
      }
      const allEnabled = CheckAllDataIfEnabled(tempList);
      setAllCheckBoxSelected(allEnabled);
    }
  };

  const handleSort = () => {
    setTrackChanges(true);
    setDraggedId(null);
    const itemToMove = itemList[dragHeader?.current];
    const itemToReplace = itemList[draggedOverHeader?.current];
    let updatedItemList = itemList.filter(
      (item, index) => index !== dragHeader?.current
    );
    const dropIndex = updatedItemList.indexOf(itemToReplace);
    updatedItemList.splice(
      dragHeader?.current < draggedOverHeader?.current
        ? dropIndex + 1
        : dropIndex,
      0,
      itemToMove
    );
    setItemList(updatedItemList);
  };

  const saveApiCall = async () => {
    if (trackChanges) {
      let tempHeaderList = [...itemList].filter(
        item => item?.name?.toLowerCase() !== "selectall"
      );
      await cbk(tempHeaderList);
    }
  };

  const handleDrag = (index = 0, name = "", eventType) => {
    if (eventType === "start") {
      dragHeader.current = index;
    } else if (eventType === "end") {
      draggedOverHeader.current = index;
    }
    setDraggedId(name);
  };

  const buttonList = [
    {
      id: "cancel",
      name: "cancel",
      btnText: "Cancel",
      type: "button",
      className: "cancelButton",
      click: e => {
        Utils.preventDefaultFn(e);
        setShowDropDown(false);
      }
    },
    {
      id: "save",
      name: "save",
      btnText: "Save",
      type: "button",
      className: `saveButton ${trackChanges ? "" : "disableBtn"} ${
        saveLoader ? "loading" : ""
      }`,
      click: saveApiCall,
      loader: saveLoader
    }
  ];

  useEffect(() => {
    const allEnabledCheck = CheckAllDataIfEnabled(dataArr);
    setAllCheckBoxSelected(allEnabledCheck);
    setItemList([...dataArr]);
    setTrackChanges(false);
  }, [dataArr, showDropDown]);

  useEffect(() => {
    if (!saveLoader) {
      setShowDropDown(false);
    }
  }, [saveLoader]);

  useEffect(() => {
    return () => {
      setTrackChanges(false);
    };
  }, []);

  return (
    <div
      className="customDragAndDropWrapper"
      onClick={() => setShowDropDown(true)}
    >
      <span className="dragAndDropIcon">
        <DragAndDropIcon />
      </span>
      <div className="bridgeCont"></div>
      {showDropDown && (
        <>
          <div className="dragAndDropCont">
            <div className="dragAndDropDropDown">
              {itemList?.length > 0 && (
                <CheckboxContainer
                  checked={allCheckBoxSelected}
                  handleCheckboxAction={handleCheckboxAction}
                />
              )}
              <DragAndDropBody
                itemList={itemList}
                draggedId={draggedId}
                handleCheckboxAction={handleCheckboxAction}
                handleDrag={handleDrag}
                handleSort={handleSort}
              />
            </div>
            <div className="btnCont">
              {buttonList.map(item => (
                <Button {...item} key={`${item?.id}Btn`} />
              ))}
            </div>
          </div>
          <div
            className="bgOverlay"
            onClick={e => {
              Utils.preventDefaultFn(e);
              setShowDropDown(false);
            }}
          />
        </>
      )}
    </div>
  );
});

export const DragAndDropBody = ({
  itemList,
  draggedId,
  handleCheckboxAction,
  handleDrag,
  handleSort
}) => {
  return (
    <div className="dragAndDropBody">
      {itemList.map((item, index) => (
        <div
          key={item.name}
          className={`itemWrapper ${
            draggedId === item.name ? "isDraggedIn" : ""
          }`}
          draggable={true}
          onDragStart={() => handleDrag(index, item.name, "start")}
          onDragEnter={() => handleDrag(index, item.name, "end")}
          onDragEnd={handleSort}
          onDragOver={e => e.preventDefault()}
          onDrop={e => e.preventDefault()}
        >
          {item.name.toLowerCase() !== "selectall" && (
            <span className="material-icons-outlined dragHolder reports">
              drag_indicator
            </span>
          )}
          <CheckBox
            key={`singleSelectCallLogCheckbox ${item.name}`}
            id={item.name}
            checked={item.enabled}
            cbk={handleCheckboxAction}
          />
          <span className="itemName">
            {Utils.getLinkedInDisplayName(item?.displayName, item?.name, true)}
          </span>
        </div>
      ))}
    </div>
  );
};

export const CheckboxContainer = ({ checked, handleCheckboxAction }) => {
  return (
    <div key="selectAll" className="allCheckboxCont">
      <CheckBox
        key={`allHeaderSelectCheckBox selectAll`}
        id="selectAll"
        checked={checked}
        cbk={handleCheckboxAction}
      />
      <span className="itemName">All</span>
    </div>
  );
};

export default CustomDragAndDrop;
export { CustomDragAndDrop };
