/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import onboardingStoreData from "Stores/onBoardingData";
import ButtonPoppingAudio from "Assets/animal_stick.mp3";
import { ReactComponent as RocketIcon } from "Assets/svg/start-up.svg";
import { initialWelcomePopupState } from "Components/common/InitialWelcomePopup";
import YoutubeVideoPlayer from "../common/YoutubeVideoPlayer";
import OnBoardingStepsPopup from "./OnBoardingStepsPopup";
import OnBoardingCompleted from "./OnBoardingCompleted";

const OnboardingComponent = observer(props => {
  const {
    onBoardingFlagValues,
    remainigFeatureCount,
    isOnboardingCompleted = false
  } = props;
  const [triggerOnboardingComplete, setOnboardingComplete] = useState(false);

  const checkAndTriggerOnboardingComplete = () => {
    if (remainigFeatureCount === 0 && !isOnboardingCompleted) {
      setTimeout(() => {
        setOnboardingComplete(true);
        Utils.showOnboardingStepsPopup(false);
      }, 3000);
    }
  };

  const setOnboardingCompletedInStore = () => {
    if (onboardingStoreData?.onBoardingFlags) {
      const tempFlags = JSON.parse(
        JSON.stringify(onboardingStoreData?.onBoardingFlags)
      );
      tempFlags.isOnboardingCompleted = true;
      onboardingStoreData.setOnBoardingFlags(tempFlags);
    }
  };

  const closeOnboardingCompletePopupCbk = async () => {
    setOnboardingComplete(false);
    setOnboardingCompletedInStore();
    Utils.getOnboardingData();
  };

  useEffect(() => {
    onboardingStoreData.setOnboardingEnabled(true);
  }, [remainigFeatureCount, isOnboardingCompleted]);

  useEffect(() => {
    if (onboardingStoreData?.showStepsPopup) {
      checkAndTriggerOnboardingComplete();
    }
  }, [onboardingStoreData.showStepsPopup]);

  useEffect(() => {
    return () => {
      initialWelcomePopupState.setRestrictOnboardingPopup(false);
    };
  }, []);

  return remainigFeatureCount > 0 || !isOnboardingCompleted ? (
    <div className="onboardingSection">
      <YoutubeVideoPlayer />
      {triggerOnboardingComplete && (
        <OnBoardingCompleted
          closeOnboardingCompletePopupCbk={closeOnboardingCompletePopupCbk}
        />
      )}
      <GettingStartedButton
        remainingCount={remainigFeatureCount}
        onBoardingFlagValues={onBoardingFlagValues}
      />
    </div>
  ) : (
    ""
  );
});

const GettingStartedButton = observer(props => {
  const { remainingCount, onBoardingFlagValues } = props;
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [showStepsPopup, setShowStepsPopup] = useState(
    onboardingStoreData.showStepsPopup
  );

  const toggleStepsPopup = e => {
    Utils.preventDefaultFn(e);
    initialWelcomePopupState.setRestrictOnboardingPopup(false);
    Utils.showOnboardingStepsPopup(!showStepsPopup);
  };

  const showStepsPopupWihtTimeout = () => {
    const {
      isEmailAccountConnected = false,
      isOnboardingCompleted = false,
      isContactsAdded = false,
      isSequenceCreated = false
    } = onBoardingFlagValues;
    setTimeout(() => {
      if (
        showStepsPopup &&
        isEmailAccountConnected &&
        !isOnboardingCompleted &&
        !isContactsAdded &&
        !isSequenceCreated
      ) {
        Utils.showOnboardingStepsPopup(true);
      } else {
        Utils.showOnboardingStepsPopup(!showStepsPopup);
      }
    }, 800);
  };

  useEffect(() => {
    setShowStepsPopup(onboardingStoreData?.showStepsPopup);
  }, [onboardingStoreData.showStepsPopup]);

  useEffect(() => {
    showStepsPopupWihtTimeout();
  }, [onBoardingFlagValues]);

  return (
    <div
      className="getStartedButtonCont animated bounce"
      onClick={e => toggleStepsPopup(e)}
    >
      {!audioPlayed && (
        <audio
          src={ButtonPoppingAudio}
          autoPlay
          onEnded={e => setAudioPlayed(true)}
        ></audio>
      )}
      <div className="getStartedWrapper">
        <span
          className={`rocketIcon ${
            showStepsPopup &&
            !initialWelcomePopupState.showPopup &&
            !initialWelcomePopupState.restrictOnboardingPopup
              ? "showSteps"
              : ""
          }`}
        >
          <RocketIcon />
        </span>
        {remainingCount > 0 && (
          <span className="remainingCount">{remainingCount}</span>
        )}
      </div>

      {showStepsPopup &&
        !initialWelcomePopupState.showPopup &&
        !initialWelcomePopupState.restrictOnboardingPopup && (
          <div className="stepsPopup">
            <OnBoardingStepsPopup
              remainingCount={remainingCount}
              onBoardingFlagValues={onBoardingFlagValues}
              toggleStepsPopup={toggleStepsPopup}
            />
            <div
              className="onboarding-popup-mask"
              onClick={e => toggleStepsPopup()}
            ></div>
          </div>
        )}
    </div>
  );
});

export default OnboardingComponent;
export { OnboardingComponent };
