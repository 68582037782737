import React, { useEffect, useState } from "react";
import DropdownButton from "./DropdownButton";
import ActionButton from "./ActionButton";
import SingleDropdown from "./SingleDropdown";
import { conditionsOperatorObject } from "Static/constant";
import contactStoreData from "Stores/contactData";

const ContactSequenceFilter = props => {
  const {
    label = "",
    keyValue = "",
    activeFilter = "",
    errMsg = "",
    data = [],
    sequenceLoader = false,
    sequenceList = [],
    setActiveFilter = () => {},
    setAction = () => {},
    selectedFilterCbk = () => {},
    handleScrollEnd = () => {},
    setErrMsg = () => {}
  } = props || {};

  let toggleDropdown = activeFilter === keyValue;

  const [activeDropdownIndex, setActiveDropdownIndex] = useState(false);

  const validateFieldNameAlreadyExist = (newFieldName = "") => {
    let count = 0;
    if (data?.length > 1) {
      data.some(element => {
        if (element?.fieldName === newFieldName) {
          count++;
        }
      });
    }
    return !count > 0;
  };

  const handleAction = (refId = "", item = "", index = "") => {
    if (refId === "fieldName") {
      if (!validateFieldNameAlreadyExist(item?.subKey)) {
        return;
      }
      selectedFilterCbk("add", keyValue);
    }
    errMsg && setErrMsg();
    let tempData = [...data];
    tempData[index][refId] = item?.subKey || "";
    setActiveDropdownIndex(false);
    setAction(keyValue, tempData);
  };

  const handleSequenceAction = (index = "", item = {}) => {
    errMsg && setErrMsg();
    let tempData = [...data];
    tempData[index]["sequenceId"] = item?.id || "";
    tempData[index]["sequenceName"] = item?.name || "";
    setActiveDropdownIndex(false);
    setAction(keyValue, tempData);
    errMsg && setErrMsg("");
  };

  const resetOrAddNewItem = (isPush = false) => {
    let newObj = { fieldName: "", sequenceId: "", sequenceName: "" };
    if (isPush) {
      return newObj;
    }
    errMsg && setErrMsg();
    selectedFilterCbk("remove", keyValue);
    setAction(keyValue, [newObj]);
  };

  const removeItem = index => {
    let tempData = [...data];
    let len = tempData?.length;
    if (len === 1) {
      resetOrAddNewItem();
    } else {
      tempData.splice(index, 1);
      tempData.filter(item => item?.fieldName)?.length < 1 &&
        selectedFilterCbk("remove", keyValue);
      setAction(keyValue, tempData);
    }
    errMsg && setErrMsg();
  };

  const addItem = () => {
    errMsg && setErrMsg();
    let tempData = [...data];
    tempData.push(resetOrAddNewItem(true));
    setAction(keyValue, tempData);
  };

  const resetItem = () => {
    errMsg && setErrMsg();
    data?.filter(item => item?.fieldName)?.length && resetOrAddNewItem();
  };

  const optionList = [
    {
      value: "Active in sequence",
      subKey: "ACTIVE_IN_SEQUENCE",
      key: "activeInASequence",
      method: handleAction
    },
    {
      value: "Completed in sequence",
      subKey: "COMPLETED_IN_SEQUENCE",
      key: "completedInASequence",
      method: handleAction
    },
    {
      value: "Added to sequence",
      subKey: "ADDED_TO_SEQUENCE",
      key: "addedToSequence",
      method: handleAction
    }
  ];

  const operatorList = [
    {
      value: "<span class='conditionSymbol'>&equals;</span> (Equals)",
      subKey: "EQUALS",
      key: "equalCond",
      method: handleAction
    },
    {
      value: "<span class='conditionSymbol'>&ne;</span> (Not equals)",
      subKey: "NOT_EQUALS",
      key: "notEqualCond",
      method: handleAction
    }
  ];

  const fieldnameObject = {
    ACTIVE_IN_SEQUENCE: "Active in sequence",
    COMPLETED_IN_SEQUENCE: "Completed in sequence",
    ADDED_TO_SEQUENCE: "Added to sequence"
  };

  useEffect(() => {
    if (errMsg) {
      let tempData = { ...contactStoreData.contactFilterObject };
      tempData.sequences = [
        {
          fieldName: "",
          sequenceId: "",
          sequenceName: ""
        }
      ];
      contactStoreData.setContactFilterObject(tempData);
    }
  }, [errMsg]);

  return (
    <div className="contactDateFilter">
      <DropdownButton
        label={label}
        count={data?.filter(item => item?.fieldName)?.length || 0}
        keyValue={keyValue}
        toggleDropdown={toggleDropdown}
        callback={setActiveFilter}
      />
      {toggleDropdown && (
        <div className="dropdownSearchBox">
          <span className="horizontalBorderLine" />
          {(data.length && (
            <div className="sequenceFilterCard">
              {data.map((item, index) => {
                const {
                  fieldName = "",
                  sequenceId = "",
                  sequenceName = "",
                  operator = "EQUALS"
                } = item || {};
                return (
                  <div
                    className="sequenceFilter"
                    key={`sequenceFilter${index}`}
                  >
                    <div className="fieldnameDropdown">
                      <SingleDropdown
                        isDropdownAllowed={true}
                        options={optionList}
                        defaultValue={fieldnameObject?.[fieldName] || ""}
                        label="Select"
                        referenceId="fieldName"
                        parentIndex={index}
                        hasCustomFieldValue={true}
                        showDropdown={
                          activeDropdownIndex === `fieldName-${index}`
                        }
                        setActiveDropdownIndex={setActiveDropdownIndex}
                      />
                      <i
                        className="material-icons-outlined deleteIcon"
                        onClick={() => removeItem(index)}
                      >
                        delete
                      </i>
                    </div>
                    {fieldName && (
                      <div
                        className={`fieldOperatorValue ${
                          !fieldName ? "disableInput" : ""
                        }`}
                      >
                        <SingleDropdown
                          isDropdownAllowed={true}
                          defaultValue={
                            conditionsOperatorObject?.[operator] || ""
                          }
                          showDropdown={
                            activeDropdownIndex === `operator-${index}`
                          }
                          setActiveDropdownIndex={setActiveDropdownIndex}
                          parentIndex={index}
                          referenceId="operator"
                          options={operatorList}
                        />
                        <div className="fieldNameSequenceDropdown">
                          <SingleDropdown
                            isDropdownAllowed={true}
                            className={"selectSequenceLabel"}
                            options={sequenceList}
                            defaultValue={(sequenceId && sequenceName) || ""}
                            sequenceId={sequenceId}
                            label="Select"
                            referenceId="sequenceName"
                            parentIndex={index}
                            hasCustomFieldValue={true}
                            showDropdown={
                              activeDropdownIndex === `sequenceName-${index}`
                            }
                            activeDropdownIndex={activeDropdownIndex}
                            setActiveDropdownIndex={setActiveDropdownIndex}
                            handleSequenceAction={handleSequenceAction}
                            handleScrollEnd={handleScrollEnd}
                            isCustomDropdown={true}
                            sequenceLoader={sequenceLoader}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )) ||
            ""}
          <ActionButton resetCbk={resetItem} addCbk={addItem} />
          {errMsg === keyValue && (
            <span className="errMessage">Please select a value</span>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactSequenceFilter;
