/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { sequenceRenderState } from "./sequenceProperties";
import RadioBtnComponent from "Components/common/radioBtn";
import CheckBoxComponent from "Components/common/CheckBox";
import { CONTACT_OWNED_FILTER_ITEMS } from "Static/constant";

const ContactOwnerAnalyticsDropdown = props => {
  const { team } = props;

  const dropdownItems = CONTACT_OWNED_FILTER_ITEMS;

  const [radioBtnOptions, setRadioBtnOptions] = useState(dropdownItems);
  const [teamList, setTeamList] = useState(team);
  const [showTeamUserDropdown, setTeamUserDropdown] = useState(false);

  const initDropdownValue = () => {
    radioBtnOptions.map(item => {
      if (item.id === "selectOwner" && item["checked"]) {
        const { idArr } = sequenceRenderState.contactOwner;
        if (teamList.length > 0 && idArr.length > 0) {
          let newArr = [...teamList];
          teamList.map((item, index) => {
            if (idArr.indexOf(item.id) !== -1) {
              item.checked = true;
              newArr[index] = item;
            }
          });
          setTeamList(newArr);
        }
        setTeamUserDropdown(true);
      }
    });
  };

  const resetCheckedUser = () => {
    const newArr = [...teamList];
    if (teamList && teamList.length > 0) {
      teamList.map((item, index) => {
        if (item.checked) {
          item.checked = false;
          newArr[index] = item;
        }
      });
    }
    setTeamList(newArr);
  };

  const setCheckedValue = (event, id) => {
    resetCheckedUser();
    const newArr = [...radioBtnOptions];
    radioBtnOptions.map((item, index) => {
      if (item.id === id) {
        item["checked"] = true;
      } else {
        item["checked"] = false;
      }
      newArr[index] = item;
    });
    if (id === "selectOwner") {
      setTeamUserDropdown(true);
    } else {
      if (sequenceRenderState.contactOwner.all !== (id === "all")) {
        sequenceRenderState.setContactOwnerValue({
          selected: [id],
          team: teamList,
          idArr: [],
          all: id === "all" ? true : false
        });
      }
      setTeamUserDropdown(false);
    }
    setRadioBtnOptions(newArr);
  };

  const getOwnerName = value => {
    if (value && Object.keys(value).length > 0) {
      const { firstName = "", lastName = "", email } = value;
      if (firstName && lastName) {
        return firstName[0] + lastName[0];
      } else {
        return email[0] + email[1];
      }
    }
  };

  const getSelectedUser = async (value, id) => {
    const newArr = [...teamList];
    const idArr = [];
    const selected = [];
    if (teamList && teamList.length > 0) {
      teamList.map((item, index) => {
        if (item.memberId === id) {
          item.checked = value;
          newArr[index] = item;
        }
        if (item.checked) {
          idArr.push(item.memberId);
          selected.push(getOwnerName(item));
        }
      });
      sequenceRenderState.setContactOwnerValue({
        idArr: idArr.length > 0 ? idArr : [],
        selected: idArr.length > 0 ? selected : ["you"],
        team: newArr,
        all: idArr.length > 0 ? "" : false
      });
      setTeamList(newArr);
    }
  };

  const getFullName = user => {
    const { firstName, lastName, email } = user;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return email;
  };

  useEffect(() => {
    initDropdownValue();
  }, []);

  return (
    <div className="dropdownSection">
      <div className="dropdownContent">
        <div className="singleSelectSection">
          {radioBtnOptions &&
            radioBtnOptions.length > 0 &&
            radioBtnOptions.map((item, index) => (
              <RadioBtnComponent
                key={item.id}
                name="contactOwnedAnalytics"
                id={item.id}
                value={item.value}
                defaultChecked={item.checked}
                radioOnchange={setCheckedValue}
              />
            ))}
        </div>
        {showTeamUserDropdown && (
          <div className="multiSelectSection">
            <div className="checkBoxDropdown">
              {teamList &&
                teamList.length > 0 &&
                teamList.map(user => (
                  <CheckBoxComponent
                    key={user.email}
                    name={getFullName(user)}
                    id={user.memberId}
                    checked={user.checked}
                    cbk={getSelectedUser}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ContactOwnerAnalyticsDropdown);
