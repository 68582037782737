import React from "react";
import SalesgearLogo from "Assets/logo/24.png";
import { ReactComponent as Maximize } from "assets/svg/maximize.svg";
import { ReactComponent as Minimize } from "assets/svg/minimize.svg";

const SliderHeader = props => {
  const {
    isCloseBtnEnabled = false,
    title = "Salesgear",
    logo = true,
    isBackArrow = false,
    backArrowCbk = () => {},
    closeDialerCard = () => {},
    setToggleDropDown = () => {},
    toggleDropdown = true
  } = props;

  return (
    <div className="dsHeader" key="dsHeader">
      <div className="titleCont">
        {isBackArrow && (
          <i className="material-icons backArrow" onClick={backArrowCbk}>
            keyboard_backspace
          </i>
        )}
        {logo && <SalesgearLogo />}
        <h6 className="title">{title}</h6>
      </div>

      <div className="iconHolder">
        <span
          className="svgIcon"
          onClick={() => {
            setToggleDropDown(!toggleDropdown);
          }}
        >
          {!toggleDropdown && <Maximize />}
          {toggleDropdown && <Minimize />}
        </span>
        {isCloseBtnEnabled && (
          <span onClick={e => closeDialerCard(e)}>
            <i className="material-icons">close</i>
          </span>
        )}
      </div>
    </div>
  );
};

export default SliderHeader;
export { SliderHeader };
