import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { sequenceContactData } from "Stores/sequence/Contact";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import StageHeader from "./StageHeader";
import StageVariants from "./StageVariants";

export function StepSortableItem({
  id = "",
  lastStepIndex = false,
  items = {},
  analyticStepLoader = false,
  setAnalyticStepLoader = () => {}
}) {
  let { parentProps = {}, icon = "", params = {} } = items || {};

  const { type = "", daysLaterCount = "" } = params || {};

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id,
    transition: {
      duration: 150, // milliseconds
      easing: "cubic-bezier(0.25, 1, 0.5, 1)"
    }
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: "move"
  };

  return (
    <div
      className="overviewDetails"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {type && (
        <>
          <span className={`stageIconWrap ${type}`}>{icon}</span>
          {+lastStepIndex > 0 && (
            <span
              className="verticalLine"
              style={{ opacity: isDragging ? 0 : 1 }}
            />
          )}
        </>
      )}
      <div className="stageCard">
        <StageHeader
          {...params}
          date={`Day ${daysLaterCount}`}
          daysFromStart={daysLaterCount}
          analyticStepLoader={analyticStepLoader}
          setAnalyticStepLoader={setAnalyticStepLoader}
        />
        <StageVariants
          {...params}
          {...parentProps}
          {...items}
          totalContacts={
            addStagesPopupState?.selectedSequence?.contactsAdded -
            addStagesPopupState?.selectedSequence.pendingCount
          }
          daysFromStart={daysLaterCount}
          sequenceContactSummary={sequenceContactData.contactsTabCount}
          analyticStepLoader={analyticStepLoader}
          setAnalyticStepLoader={setAnalyticStepLoader}
        />
      </div>
    </div>
  );
}
