import React from "react";
import { ReactComponent as EyeOpen } from "Assets/svg/eyeOpen.svg";

const HoverCard = () => {
  return (
    <div className="hoverCard">
      <div className="cardData animated customzoomIn">
        <span className="eyeIcon">
          <EyeOpen />
        </span>
        <span className="viewText">Click to view</span>
      </div>
    </div>
  );
};

export default HoverCard;
export { HoverCard };
