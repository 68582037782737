import React from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import DonutChart from "./DonutChart";
import LabelComponent from "./LabelComponent";

const SequenceGraphComponent = observer((props = {}) => {
  const { graphDataArr = [], loader = false } = props || {};

  const transFormDataArr = (
    dataArr = [],
    isRoundOff = false,
    totalCount = 0
  ) => {
    return [...dataArr].map(({ label, value, color }) => {
      let tempValue = totalCount > 0 ? (value / totalCount) * 100 : 0;
      return {
        label,
        value: isRoundOff
          ? tempValue
            ? `${tempValue?.toFixed(2)}%`
            : 0
          : tempValue,
        color
      };
    });
  };

  return (
    <div className="sequenceGraphCont">
      {!loader ? (
        graphDataArr?.length > 0 &&
        graphDataArr.map((item, index) => {
          const { header = "", totalCount = 0, dataArr = [] } = item || {};
          return (
            <div className="sequenceAnalyticsCard" key={index}>
              <div className="headerCont">
                <span className="headerName">{header}</span>
                <span className="totalCount">
                  {Utils.formatNumberToStringInUSFormat(totalCount) || 0}
                </span>
              </div>
              <div className="donutGraphSection">
                <DonutChart
                  data={transFormDataArr(dataArr, false, totalCount)}
                />
                <LabelComponent
                  labelArr={
                    header?.toLowerCase() === "emails"
                      ? transFormDataArr(dataArr, true, totalCount)
                      : dataArr
                  }
                />
              </div>
            </div>
          );
        })
      ) : (
        <SequenceGraphContLoader loader={loader} />
      )}
    </div>
  );
});

const SequenceGraphContLoader = ({ loader = false }) => {
  return Array.from({ length: 3 }).map((_, index) => (
    <div className="sequenceAnalyticsCard" key={index}>
      <div className="headerCont">
        <span className="headerNameLoader linear-background" />
        <span className="totalCountLoader linear-background" />
      </div>
      <div className="donutGraphSection">
        <DonutChart loader={loader} />
        <LabelComponent loader={loader} />
      </div>
    </div>
  ));
};

export default SequenceGraphComponent;
export { SequenceGraphComponent };
