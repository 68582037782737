import React from "react";

const UserProfileLoader = () => {
  return (
    <div className="userProfileLoader">
      {Array.from({ length: 4 }, (value, index) => (
        <div className="linear-background inputLoader" key={`profieLoader${index}`}></div>
      ))}
      <div className="linear-background checkBoxHeader"></div>
      <div className="checkboxLoader">
        <div className="linear-background checkBox"></div>
        <div className="linear-background checkBoxContent"></div>
      </div>

      <div className="linear-background saveBtnLoader"></div>
      <div className="linear-background deactivateBtnloader"></div>
    </div>
  );
};

export default UserProfileLoader;
