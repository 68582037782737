import React from "react";

const TagPopupHeader = props => {
  const { showOptions, closeAction, setShowOptions } = props;

  return (
    <>
      {showOptions && (
        <div
          className="transparentOverlay"
          onClick={e => setShowOptions(false)}
        ></div>
      )}
      <div className="title">Add Tags</div>
      <div
        className="popup-close"
        title="Click here to close"
        onClick={() => closeAction()}
      >
        <i className="material-icons">close</i>
      </div>
    </>
  );
};

export default TagPopupHeader;
export { TagPopupHeader };
