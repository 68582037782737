/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import Utils from "Utils/utils";
import CustomTooltip from "Components/common/CustomTooltip";
import { ReactComponent as SequenceIcon } from "Assets/svg/sequence.svg";

const AddToSequenceButton = props => {
  const {
    selectedEntityCount = 0,
    cbk = () => {},
    loading = false,
    checked = false
  } = props;

  const [showDescriptionTp, setShowDescriptionTp] = useState(false);

  const addToSequence = async event => {
    Utils.preventDefaultFn(event);
    checked && !loading && (await checkTeamSubscriptionActive()) && cbk();
  };

  return (
    <div
      className={`addToSequenceFeatureBtn ${
        selectedEntityCount ? "enabled" : ""
      }`}
      onClick={e => addToSequence(e)}
      onMouseEnter={() => setShowDescriptionTp(true)}
      onMouseLeave={() => setShowDescriptionTp(false)}
    >
      <SequenceIcon className="sequenceIcon" />
      {showDescriptionTp && (
        <CustomTooltip
          text={`${
            selectedEntityCount > 0
              ? "Add to sequence"
              : "Select one or more contacts to add to sequence."
          }`}
          className={`${selectedEntityCount > 0 ? "" : "bulkAddtoSequence"}`}
        />
      )}
    </div>
  );
};

export { AddToSequenceButton as AddToSequence };
export default observer(AddToSequenceButton);
