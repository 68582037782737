import React from "react";
import { withRouter } from "react-router-dom";
import SalesForceAction from "./salesforce/SalesForceAction";
import InstallButton from "./InstallButton";
import SlackAction from "./slack/SlackAction";
import ZohoAction from "./zoho/ZohoAction";
import ZapierAction from "./zapier/ZapierAction";
import HubspotAction from "./hubspot/HubspotAction";
import PipedriveAction from "./pipedrive/PipedriveAction";

const CRMOauthConnect = props => {
  const { data = "" } = props;

  switch (data?.key) {
    case "salesforce":
      return <SalesForceAction {...props} />;
    case "slack":
      return <SlackAction {...props} />;
    case "zoho":
      return <ZohoAction {...props} />;
    case "zapier":
      return <ZapierAction {...props} />;
    case "hubspot":
      return <HubspotAction {...props} />;
    case "pipedrive":
      return <PipedriveAction {...props} />;
    default:
      return (
        <div className="buttonCont">
          <InstallButton {...props} />
        </div>
      );
  }
};

export default withRouter(CRMOauthConnect);
export { CRMOauthConnect };
