/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import Button from "Components/common/Button";
import userDetail from "Stores/userDetail";
import CheckBoxComponent from "Components/common/CheckBox";
import makeApi, { URLS } from "Utils/apiURL";
import { settingsStoreData } from "Stores/settingsData";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import ContactAutoCompleteDropdown from "Components/PreviewPopup/ContactAutoCompleteDropdown";
import { PAYWALL_CONTENT } from "Model/model";
import { UpgradeTooltip } from "Components/Paywall/UpgradeTooltip";

const AutoBcc = observer(props => {
  const {
    autoBccEnabled = false,
    inFreePlan = false,
    planName = ""
  } = userDetail.userFeatureFlag || {};
  const [crmAutoBccFlag, setCrmAutoBccFlag] = useState(autoBccEnabled);
  const [emailAddr, setEmailAddr] = useState("");
  const [autoBccChecked, setAutoBccCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showUpgradeTp, setShowUpgradeTp] = useState(false);

  const getAutoBccCheck = value => {
    setAutoBccCheck(value);
  };

  const changeInputVal = (value = "") => {
    setEmailAddr(value?.trim() || "");
  };

  const saveBcc = async e => {
    e.preventDefault();
    try {
      if (crmAutoBccFlag && autoBccEnabled) {
        setLoader(true);
        const payload = {
          bccEmail: emailAddr,
          insertBcc: autoBccChecked
        };
        const config = {
          url: URLS.updateBcc ? URLS.updateBcc : "",
          method: "POST",
          data: payload
        };
        let response = await makeApi(config);
        if (response?.status === 200) {
          settingsStoreData.setSettingsData({
            ...settingsStoreData.settingsData,
            ...payload
          });
          toasterState.setToastMsg("Bcc saved", "success");
        } else Utils.showApiFailureResponse(response);
        setLoader(false);
      } else {
        Utils.showUpgradeSettingsPopup("settings", true, false);
      }
    } catch (error) {
      console.error("Save Sign error ", error);
    }
  };

  const constructSettingsData = () => {
    if (settingsStoreData.settingsData) {
      const duplicateStoreData = JSON.parse(
        JSON.stringify(settingsStoreData.settingsData)
      );
      duplicateStoreData.bccEmail && setEmailAddr(duplicateStoreData.bccEmail);
      duplicateStoreData.insertBcc &&
        setAutoBccCheck(duplicateStoreData.insertBcc);
    }
  };

  useEffect(() => {
    settingsStoreData.getSettingsData();
  }, []);

  useEffect(() => {
    if (settingsStoreData.settingsData) {
      const duplicateStoreData = JSON.parse(
        JSON.stringify(settingsStoreData.settingsData)
      );
      (duplicateStoreData.insertBcc !== undefined ||
        duplicateStoreData.bccEmail) &&
        constructSettingsData();
    }
  }, [settingsStoreData.settingsData]);

  useEffect(() => {
    setCrmAutoBccFlag(userDetail?.userFeatureFlag?.autoBccEnabled);
  }, [userDetail?.userFeatureFlag?.autoBccEnabled]);

  return (
    <>
      <form onSubmit={saveBcc} className={`autoBccWrapper`}>
        <div className="autoBccInnerCont">
          <div className="headerCont">
            <div className="headerTitle">
              Auto Bcc: (Sync sent emails to your CRM with Bcc)
            </div>
          </div>
          <div
            className={`bccBodyCont ${!autoBccEnabled ? "disabled" : ""}`}
            onMouseEnter={() => setShowUpgradeTp(true)}
            onMouseLeave={() => setShowUpgradeTp(false)}
          >
            <div className="inputCont">
              <input
                type="email"
                id="emailAddr"
                className="input-box"
                placeholder="Email Address"
                autoComplete="off"
                value={emailAddr}
                onChange={e => changeInputVal(e?.currentTarget?.value || "")}
                disabled={!autoBccEnabled}
              />
              <ContactAutoCompleteDropdown
                inputText={emailAddr}
                selectedCallbk={(contact = {}) =>
                  changeInputVal(contact?.email)
                }
              />
              {showUpgradeTp &&
                (["basic", "pro"].includes(planName?.toLowerCase()) ||
                  inFreePlan) &&
                !autoBccEnabled && (
                  <UpgradeTooltip
                    {...PAYWALL_CONTENT[
                      inFreePlan
                        ? "autoBccSettings"
                        : planName?.toLowerCase() === "basic"
                        ? "autoBccSettingsBasic"
                        : "autoBccSettingsPro"
                    ]}
                    {...props}
                  />
                )}
            </div>
            <div className="autoBcc">
              <CheckBoxComponent
                key="autoBcc"
                name="Add this address in BCC to all emails sent via Sequences"
                id="autoBcc"
                checked={autoBccChecked}
                cbk={e => getAutoBccCheck(e)}
              />
            </div>
            <div className="buttonCont">
              <Button
                id="saveBcc"
                name="saveBcc"
                btnText="Save Changes"
                icon={false}
                iconName=""
                type="submit"
                className={`addBccBtn ${loader ? "loading" : ""}`}
                loader={loader}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
});

export default withRouter(AutoBcc);
