import React, { useState } from "react";
import Utils from "Utils/utils";
import CustomTooltip from "Components/common/CustomTooltip";

const MoreOptionSlide = props => {
  const {
    dropdownOptions = [],
    id = "",
    stepNo = "",
    index = 0,
    isFromOverview = false
  } = props || {};
  const [showActionIcon, setActionIcon] = useState(false);

  return (
    (dropdownOptions?.length && (
      <div className="moreOptionSlide" onClick={e => Utils.preventDefaultFn(e)}>
        <span
          className="material-icons threeDot"
          onMouseEnter={() => setActionIcon(true)}
          onMouseLeave={() => setActionIcon(false)}
        >
          more_vert
          {showActionIcon && (
            <>
              <div className="dropdownOptions">
                <ul className="dropdown">
                  {dropdownOptions?.map(item => {
                    const {
                      key = "",
                      icon = "",
                      name = "",
                      method = () => {}
                    } = item || {};
                    return (
                      <li
                        key={key}
                        className={key}
                        onClick={e => {
                          key === "edit" && isFromOverview
                            ? method(e, index)
                            : method(e, id, stepNo);
                          setActionIcon(false);
                        }}
                      >
                        <i className="material-icons-outlined edit">{icon}</i>
                        <CustomTooltip text={name} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
        </span>
      </div>
    )) ||
    ""
  );
};

export default MoreOptionSlide;
export { MoreOptionSlide };
