/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import userDetail from "Stores/userDetail";
import SettingImg from "Assets/png/UpgradeBccCRM.png";
import CloudyImg from "Assets/png/Cloudy.png";
import DialerImg from "Assets/png/UpgradeDialer.png";
import CustomUrlImg from "Assets/png/customUrl.png";
import CustomFieldImg from "Assets/png/customFieldUpgrade.png";
import UpgradeTeamImg from "Assets/png/UpgradeTeam.png";
import ButtonComponent from "Components/common/Button";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import PricingStore from "Stores/Pricing";

const UpgradeCard = props => {
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [imageName, setImageName] = useState("");
  const [showUpgradeSettings, setShowUpgradeSettings] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [btnText, setBtnText] = useState("Upgrade");
  const [pageType, setPageType] = useState("");

  const getContent = (page, show, overlay) => {
    switch (page) {
      case "autoBcc":
        setTitle(
          "Please upgrade to one of our premium plans to enable auto Bcc to your CRM"
        );
        setImageName(SettingImg);
        break;
      case "customFields":
        setTitle(
          "Please upgrade to one of our premium plans to create your own personalization tokens"
        );
        setImageName(CustomFieldImg);
        break;
      case "dialer":
        setTitle(
          `Please upgrade to Pro plan to make phone calls from Salesgear`
        );
        setImageName(DialerImg);
        break;
      case "dialerUpgrade":
        setTitle("Please upgrade to Pro plan to add more dialer credits");
        setImageName(DialerImg);
        setBtnText("Upgrade");
        break;
      case "buyNewNumber":
        setTitle("Please upgrade to Pro plan to purchase new phone number");
        setImageName(DialerImg);
        setBtnText("Upgrade");
        break;
      case "dialerPurchase":
        setTitle("You must configure your dialer before making phone calls");
        setImageName(DialerImg);
        setBtnText("Configure Dialer");
        break;
      case "customUrl":
        setTitle(
          "Please upgrade to one of our premium plans to add custom tracking subdomain"
        );
        setImageName(CustomUrlImg);
        break;
      case "customUrl-admin":
        setTitle("Please contact your account administrator to add Custom URL");
        setImageName(CustomUrlImg);
        break;
      case "customVariable":
        setTitle(
          "Please upgrade to one of our premium plans to create your own personalization tokens"
        );
        setImageName(CustomFieldImg);
        break;
      case "team":
        setTitle(
          `Please write to us at support@salesgear.io <br>to add more seats.`
        );
        setImageName(UpgradeTeamImg);
        setBtnText("Ok");
        break;
      case "contactStage":
        setTitle(
          `Please write to us at support@salesgear.io <br>to add more stages.`
        );
        setImageName(UpgradeTeamImg);
        setBtnText("Ok");
        break;
      default:
        break;
    }
    setShowOverlay(overlay);
    setShowUpgradeSettings(show);
    Utils.mixpanelTrack(MP_EVENT.UPGRADE_POPUP_SHOWN, {
      triggerType: page,
      pageType: Utils.getPageType() || ""
    });
  };

  let proPlan = PricingStore?.activePlanData.filter(
    item => item.displayName === "Pro"
  )[0];

  const upgradeToPlan = event => {
    event && Utils.preventDefaultFn(event);
    setLoader(true);
    Utils.mixpanelTrack(MP_EVENT.UPGRADE_POPUP_CLICKED, {
      triggerType: pageType
    });
    hideUpgrade();
    if (pageType === "dialerUpgrade" || pageType === "buyNewNumber") {
      PricingStore.setPaymentFormInfo(proPlan);
      localStorage.setItem("proceedToPay", true);
      props.history.push("/pricing/payment");
    } else if (pageType === "dialerPurchase") {
      props.history.push("/settings/dialer/general");
    } else if (pageType === "dialer") {
      setShowUpgradeSettings(false);
      setShowOverlay(false);
      confirmationPopupState.setPopupValues({
        content: `Please write to us at <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> to upgrade your subscription to Pro plan`,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => {
          confirmationPopupState.setShowPopup(false);
        }
      });
      confirmationPopupState.setShowPopup(true);
    } else if (pageType === "team") {
      // only need to hide popup
    } else {
      setLoader(false);
      Utils.isAdmin() && props.history.push("/pricing");
    }
  };

  const hideUpgrade = () => {
    if (showOverlay) {
      userDetail.showUpgradeSettingsPopup = false;
    }
  };

  const upgradeSettingsPopup = () => {
    const { page, show, overlay, count = 0 } = userDetail.upgradeSettingsPopup;
    setPageType(page);
    getContent(page, show, overlay, count);
  };

  useEffect(() => {
    userDetail.showUpgradeSettingsPopup && upgradeSettingsPopup();
  }, [userDetail.showUpgradeSettingsPopup, userDetail.userFeatureFlag.page]);

  return (
    <>
      {showUpgradeSettings && (
        <div className="upgradePopupSection">
          <img
            className="cloudyImg"
            src={CloudyImg}
            alt=""
            onClick={hideUpgrade}
          />
          <div className="upgradeSettingsCard animated customzoomIn">
            <div className="upgradeBody">
              <div
                className="upgradeTitle"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {Utils.isAdmin() && (
                <div className="upgradeContentBody">
                  <ButtonComponent
                    id="upgradeBtnCard"
                    name="upgradeBtnCard"
                    btnText={btnText}
                    icon={false}
                    iconName=""
                    type="button"
                    className={loader ? "loading" : ""}
                    loader={loader}
                    click={event => {
                      upgradeToPlan(event);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="upgradeImg">
              <img src={imageName} alt="" />
            </div>
          </div>
        </div>
      )}
      {showOverlay && (
        <div className="overlayUpgradeMask" onClick={hideUpgrade}></div>
      )}
    </>
  );
};

export default withRouter(UpgradeCard);
