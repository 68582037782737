export const MXP_EVENT = {
  // Discover / Data Search
  SEARCH_SEC_VIEWED: "Search section viewed",
  SEARCH_PERFORMED: "Search performed",
  UPGRADE_POPUP_SHOWN: "Upgrade popup shown",
  UPGRADE_POPUP_CLICKED: "Upgrade popup clicked",
  CREATE_NEW_LIST_CLICKED: "Create new list clicked",
  LIST_CREATED: "List created",
  ADD_TO_LIST_CLICKED: "Add to list clicked",
  ADD_TO_LIST_PERFORMED: "Add to list performed",
  LIST_CREATED_ADDED_PERFORMED: "List created & added performed",
  EXPORT_CSV_CLICKED: "Export CSV clicked",
  EXPORT_CSV_PERFORMED: "Export CSV performed",
  BULK_PURCHASE_EMAIL_PHONE: "Bulk purchase email phone clicked",
  VIEW_EMAIL_SINGLE_PERFORMED: "View email single performed",
  VIEW_PHONE_SINGLE_PERFORMED: "View phone single performed",
  VIEW_EMAIL_SINGLE_CLICKED: "View email single clicked",
  VIEW_PHONE_SINGLE_CLICKED: "View phone single clicked",
  VIEW_EMAIL_BULK_CLICKED: "View email bulk clicked",
  VIEW_EMAIL_PHONE_BULK_CLICKED: "View email & phone bulk clicked",
  VIEW_EMAIL_BULK_PERFORMED: "View email bulk performed",
  VIEW_EMAIL_PHONE_BULK_PERFORMED: "View email & phone bulk performed",
  COPY_EMAIL_CLICKED: "Copy email clicked",
  COPY_PHONE_CLICKED: "Copy phone clicked",
  CLEAR_ALL_CLICKED: "Clear all clicked",
  INDUSTRY_SEARCH__DATA_REQUEST: "Industry search data request",
  COMPANY_DOMAIN_SEARCH_DATA_REQUEST: "Company domain search data request",
  LOCATION_SEARCH_DATA_REQUEST: "Location search data request",
  SKIP_CONTACTS_CLICKED: "Skip contacts clicked",
  SEARCH_FILTER_APPLIED: "Search filters applied",
  CHAT_US_CLICKED: "Chat us clicked",
  ADD_TO_SEQ_CLICKED: "Add to sequence clicked",
  ADD_TO_SEQ_PERFORMED: "Add to sequence performed",
  PAGE_LIMIT_UPDATED: "Page limit updated",
  PAGINATION_CLICKED: "Pagination clicked",
  PAGINATION_LIMIT_REACHED_POPUP_SHOWN: "Pagination limit reached popup shown",
  ADD_CONTACT_TO_SEQ: "Add contact to sequence",
  ADD_CREDITS_EMAIL_VALIDATION: "Add email validation credits",
  SEARCH_DELETED: " search deleted",
  LOAD_CLICKED: "Load search clicked",
  EDIT_SEARCH: "search edited and updated",
  SAVE_SEARCH: "search saved",
  CALL_CLICK_HEADER: "Header - Call Clicked",
  EMAIL_CLICK_HEADER: "Header - Email Clicked",
  CALENDER_CLICK_HEADER: "Header - Calender Clicked",
  MEETING_BOOK_HEADER: "Header - Meeting Booked",
  PRICING_CLICK_HEADER: "Header - Pricing Clicked",
  SUPPORT_CLICK_HEADER: "Header - Support Clicked",
  SETTINGS_CLICK_HEADER: "Header - Settings Clicked",
  LOGOUT_CLICK_HEADER: "Header - Logout Clicked",
  REQUEST_DEMO_HEADER: "Header - Request Demo Clicked",

  // Contacts
  CT_ADD_TO_LIST_POPUP_VIEWED: "Add to list popup viewed",
  CT_NEW_LIST_CREATED: "New list created",
  CT_ADD_TO_LIST_CLICKED: "Add to list clicked",
  CT_ADD_TO_LIST_PERFORMED: "Added to list performed",
  CT_REMOVE_FROM_LIST_CLICKED: "Remove from list clicked",
  CT_REMOVE_FROM_LIST_PERFORMED: "Remove from list performed",

  // CONTACT TIMELINE
  CT_TL_TP_INSERTED: "Contact timeline template inserted",

  // CALENDAR
  ST_CLR_CONFIG_VIEWED: "Calendar configuration viewed",
  ST_CLR_CONFIG_CONNECT_ACCOUNT_CLICK:
    "Calendar configuration connect account clicked",
  ST_CLR_EVENT_URL_COPIED: "Calendar event url copied",
  ST_CLR_BASE_URL_COPIED: "Calendar base url copied",
  ST_CLR_CREATE_EVENT_CLICKED: "Calendar create event clicked",

  // Team management
  TEAM_CREATED: "Custom Team Created",
  TEAM_MEMBER_ADDED: "Team Members Added",
  TEAM_DELETED: "Custom Team Deleted",
  MEMBER_AS_OWNER: "Made a Member as Team Owner",

  // Contact stages
  CONTACT_STAGE_CREATED: "Custom contact stage created",
  CONTACT_STAGE_RENAMED: "Contact stage Renamed",
  CONTACT_STAGE_DELETED: "Contact stage Deleted",
  CONTACT_STAGE_UPDATED: "Contact stage Updated",

  //Do not contact
  SINGLE_DOMAIN_BLOCKED: "single domain blocked",
  MULTIPLE_DOMAIN_BLOCKED: "multiple domain blocked",

  // One Off emails
  ONE_OFF_EMAIL_SENT: "One-off Email Sent",
  ONE_OFF_EMAIL_SENT_HEADER: "Header - Email Sent",
  REPLIED_FROM_TIMELINE: "Replied From Timeline",
  FORWARDED_FROM_TIMELINE: "Forwarded Email From Timeline",

  //Task Filters
  TASK_FILTER_CLICKED: "Task Filters Clicked",
  TASK_CREATED: "Task Created",
  TASK_BULK_ACTION_PERFORMED: "Task Bulk Action Performed",

  // Dialer
  DIALER_USED: "Dialer Used",
  GLOBAL_HEADER_DIALER_USED: "Header - Call Made",
  MAKE_CALL_CLICKED: "Make A Call Clicked",
  LOGGED_CALL_MADE: "Logged Call Made",
  CALLS_OVERVIEW_USED: "Calls Overview Used",
  CALLS_SUMMARY_USED: "Calls Summary Used",
  CALLS_ANALYTICS_USED: "Calls Analytics Used",

  // Sequences
  AB_TESTING_VARIATION_CREATED: "A/B Testing - Variation Created",

  // Reports
  REPORT_SUMMARY_USED: "Reports Summary Used",
  REPORT_EMAIL_INSIGHTS_USED: "Reports Email Insights Used",
  REPORT_SEQUENCE_INSIGHTS_USED: "Reports Sequence Insights Used",

  // Connect Inbox
  INBOX_ADDED: "Inbox Added",
  TEST_SMTP_CONNECTION_PERFORMED: "Test SMTP Connection Performed",
  TEST_IMAP_CONNECTION_PERFORMED: "Test IMAP Connection Performed",
  SAVED_SMTP_IMAP_CONNECTION: "Saved SMTP/IMAP Connection",

  // Custom DOMAIN
  CUSTOM_URL_ADDED: "Custom URL Added",
  CUSTOM_URL_REMOVED: "Custom URL Removed",

  // CRM Integration
  CRM_CONNECTED: "CRM Connected",

  //Website tracking
  WEBSITE_TRACKING_SETTINGS_VISITED: "Website tracking settings page visited",
  TRACKING_CODE_COPIED: "Website tracking settings page visited",
  WEBSITE_TRACKING_PAGE_VISITED: "Website tracking page visited",
  WEBSITE_TRACKING_DATE_FILTER_APPLIED: "Website tracking page filters applied",

  //Domain health center
  DOMAIN_HEALTH_CENTER_VISITED: "Domain health center page visited",
  DOMAIN_HEALTH_CENTER_UPGRADE_POPUP:
    "Domain health center upgrade popup shown",

  // Chat GPT
  CHAT_GPT_AI_CLICKED: "ChatGPT AI clicked",
  CHAT_GPT_AI_HELP_ME_WRITE_CLICKED:
    "ChatGPT AI - help me write button clicked",
  CHAT_GPT_AI_GENERATED: "ChatGPT AI - version generated",
  CHAT_GPT_AI_SCORE_REWRITE_AI_CLICKED: "ChatGPT AI - score rewrite AI clicked",
  CHAT_GPT_AI_SCORE_CLICKED: "ChatGPT AI - score clicked",
  CHAT_GPT_AI_PROMPT_CLICKED: "ChatGPT AI - prompt clicked",
  CHAT_GPT_AI_TAGS_CLICKED: "ChatGPT AI - content tag clicked",

  // Dialer Settings Stages
  CALL_STAGE_CREATED: "Custom call stage created",
  CALL_STAGE_RENAMED: "Call stage Renamed",
  CALL_STAGE_DELETED: "Call stage Deleted",
  CALL_STAGE_UPDATED: "Call stage Updated",

  // TASKS SETTINGS
  TASKS_BULK_OPERATION_VIEWED: "Tasks bulk operation viewed",

  // ACCOUNTS
  ACCOUNTS: {
    CONTACTS_TAB_VIEWED: "Accounts contacts tab viewed",
    TASKS_TAB_VIEWED: "Accounts tasks tab viewed"
  }
};

export const PAGE_TYPE = {
  discover: "Discover Page",
  contact: "Contacts Page",
  contactTimeline: "Contact Timeline Page",
  sequence: "Sequences Page",
  team: "Team Page",
  report: "Reports Page",
  call: "Calls Page",
  template: "Templates",
  setting: "Settings Page",
  tracking: "Website Tracking Page",
  header: "Global Header",
  task: "Tasks Page",
  accounts: "Accounts Page"
};
