/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import PricingStore from "Stores/Pricing";
import PricingTableContent from "./../PricingTableContent";
import { withRouter } from "react-router-dom";
import SeatsPurchaseBilling from "./SeatsPurchaseBilling";
import StripeBillingCard from "./StripeBillingCard";
import TrialDetails from "./TrialDetails";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";

const PricingPayment = props => {
  const { displayName = "", annual = false } = PricingStore.paymentFormInfo;
  const [planName, setPlanName] = useState(displayName);
  const [planType, setPlanType] = useState(annual);
  const [isTrialPurchase, setTrialPurchase] = useState(false);

  const backToPricingTable = () => {
    props.history.push("pricing");
  };

  const getTrialPurchaseFlag = () => {
    const trialPurchaseFlag = localStorage.getItem("trialPayment");
    if (trialPurchaseFlag) {
      setTrialPurchase(true);
      localStorage.removeItem("trialPayment");
    }
  };

  const getMXPlanType = () => {
    return isTrialPurchase ? `${displayName} Trial` : displayName;
  };

  useEffect(() => {
    const { displayName = "", annual = false } = PricingStore.paymentFormInfo;
    setPlanName(displayName);
    setPlanType(annual);
    getTrialPurchaseFlag();
  }, [PricingStore.paymentFormInfo]);

  useEffect(() => {
    Utils.mixpanelTrack(MP_EVENT.CHECKOUT_PAGE_VIEWED, {
      pageType: "Pricing",
      planType: getMXPlanType()
    });
  });

  return (
    <div className="pricingBody">
      <div className="pricingCard">
        <PricingTableContent />
        <div className="pricingContentBody">
          <div className="wrapPlanNameType">
            <div className="backBtn" onClick={backToPricingTable}>
              <i className="material-icons">keyboard_backspace</i>
              <span>Back</span>
            </div>
            {!isTrialPurchase && (
              <div className="planNameType">
                {planName} - {planType ? "Annual" : "Monthly"}
              </div>
            )}
          </div>

          <div className="pricingPaymentSection">
            {isTrialPurchase ? (
              <TrialDetails displayName={displayName} />
            ) : (
              <SeatsPurchaseBilling />
            )}
            <StripeBillingCard isTrialPurchase={isTrialPurchase} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(observer(PricingPayment));
export { PricingPayment };
