import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
const { observable, action } = require("mobx");

const transformContactStages = data => {
  let updatedData =
    data?.length > 0 &&
    data.map(item => {
      return {
        ...item,
        name: item?.displayName
      };
    });
  return updatedData.filter(Boolean);
};

const defaultContactFields = {
  contactFields: [
    {
      fieldName: "",
      operator: "EQUALS",
      value: ""
    }
  ]
};

class FilterComponentState {
  @observable selectedFilteredList = {};
  @observable filterComponentData = {};
  @observable sequenceList = [];
  @observable contactStages = [];
  @observable sequenceTotalPages = 0;
  @observable sequenceCurrentPageNo = 1;
  @observable contactFilterObject = [];
  @observable filteredContactList = [];
  @observable btnLoading = false;
  @observable filterApplied = false;
  @observable contactFieldsData = defaultContactFields;

  @action
  setSelectedFilteredList = value => {
    this.selectedFilteredList = value;
  };

  @action
  setContactStages = value => {
    this.contactStages = value;
  };

  @action
  setFilterComponentData = val => {
    this.filterComponentData = val;
  };

  @action
  setSequenceList = val => {
    this.sequenceList = val;
  };

  @action
  setSequenceTotalPages(value) {
    this.sequenceTotalPages = value;
  }

  @action
  setSequenceCurrentPageNo(value) {
    this.sequenceCurrentPageNo = value;
  }

  @action
  setContactFilterObject(value) {
    this.contactFilterObject = value;
  }

  @action
  setContactFieldsData = value => {
    this.contactFieldsData = value;
  };

  @action
  setFilteredContactList(value) {
    this.filteredContactList = value;
  }

  @action
  setBtnLoading(value) {
    this.btnLoading = value;
  }

  @action
  setFilterApplied(value) {
    this.filterApplied = value;
  }

  @action
  getContactStages = async () => {
    const config = {
      url: URLS.getContactStages,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response?.status === 200 && response?.data) {
      const updatedData = transformContactStages(response?.data);
      this.setContactStages([...updatedData]);
    } else {
      this.setContactStages([]);
    }
  };

  @action
  listSequences = async (data = {}) => {
    try {
      const config = {
        url: URLS.listAllTriggerSequences,
        method: "POST",
        data
      };
      let response = await makeApi(config);
      if (response?.data) {
        this.setSequenceList([
          ...this.sequenceList,
          ...response?.data?.campaignSequenceResponseList
        ]);
        this.setSequenceTotalPages(response?.data?.totalPages);
      } else {
        Utils.showApiFailureResponse(response);
      }
    } catch (err) {
      console.error("Creating a new step erro", err);
    }
  };
}

const filterComponentState = new FilterComponentState();
export { filterComponentState };
export default filterComponentState;
