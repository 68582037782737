import React, { useEffect } from "react";
import Button from "Components/common/Button";
import teamState from "./state";
import userDetail from "Stores/userDetail";
import { createTeamPopupState } from "./CreateTeamPopup";
import TeamTable from "./TeamTable";
import { observer } from "mobx-react";
import usersAndTeamsState from "../state";
import TeamMemberMain from "./TeamMember/TeamMemberTable";

const TeamModule = observer(() => {
  const { team = "" } = userDetail?.userInfo || {};

  const handleCreateTeam = () => {
    !teamState.tableLoading && createTeamPopupState.setShowPopup(true);
  };

  const createTeamBtnProps = {
    id: "createTeamBtn",
    name: "createTeamBtn",
    btnText: "Create Team",
    type: "button",
    className: "createTeamBtn",
    click: handleCreateTeam
  };

  useEffect(() => {
    teamState.getTeamData(true);
    return () => {
      teamState.setTableLoading(true);
      usersAndTeamsState.setActiveTab("users");
      teamState.setShowTeamMemberPage(false);
    };
  }, []);

  return (
    <section
      className={`${
        teamState?.showTeamMemberPage ? "teamMemberWrapper" : "groupWrapper"
      }`}
    >
      {teamState?.showTeamMemberPage ? (
        <TeamMemberMain />
      ) : (
        <>
          {team && !teamState.tableLoading && (
            <div className="teamHeaderSection">
              <Button {...createTeamBtnProps} />
            </div>
          )}
          <TeamTable handleCreateTeam={handleCreateTeam} />
        </>
      )}
    </section>
  );
});

export { TeamModule };
export default TeamModule;
