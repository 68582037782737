import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

const getFailureResponseMsg = (contactCounts = {}) => {
  const {
    failedErrorCount = 0,
    inboxNotFoundCount = 0,
    missingFieldsCount = 0,
    missingTokensCount = 0,
    contactBouncedCount = 0,
    contactOptedOutCount = 0,
    contactNotInFailedCount = 0,
    invalidSubject = 0,
    doNotContact = 0,
    nextStageNotFoundCount = 0,
    notInCompletedStatusCount = 0,
    stageAlreadyScheduledCount = 0,
    differentContactOwnerCount = 0
  } = contactCounts || {};

  const totalMissingVariables = missingFieldsCount + missingTokensCount;
  return (
    <div>
      {nextStageNotFoundCount > 0 && (
        <span>{`Next stage not found for ${nextStageNotFoundCount} ${
          nextStageNotFoundCount > 1 ? "contacts" : "contact"
        }`}</span>
      )}
      {nextStageNotFoundCount > 0 && <br />}
      {notInCompletedStatusCount > 0 && (
        <span>{`${notInCompletedStatusCount} ${
          notInCompletedStatusCount > 1 ? "contacts are" : "contact is"
        } not in completed/replied stage.`}</span>
      )}
      {notInCompletedStatusCount > 0 && <br />}
      {stageAlreadyScheduledCount > 0 && (
        <span>{`${stageAlreadyScheduledCount} ${
          stageAlreadyScheduledCount > 1
            ? "contacts stage are"
            : "contact stage is"
        } already in scheduled status.`}</span>
      )}
      {stageAlreadyScheduledCount > 0 && <br />}
      {differentContactOwnerCount > 0 && (
        <span>{`${differentContactOwnerCount} ${
          differentContactOwnerCount > 1 ? "contacts have" : "contact has"
        } different contact owner.`}</span>
      )}
      {differentContactOwnerCount > 0 && <br />}
      {totalMissingVariables > 0 && (
        <span>{`${totalMissingVariables} ${
          totalMissingVariables > 1 ? "contacts" : "contact"
        } has missing variables.`}</span>
      )}
      {totalMissingVariables > 0 && <br />}
      {contactOptedOutCount > 0 && (
        <span>{`${contactOptedOutCount} ${
          contactOptedOutCount > 1 ? "contacts" : "contact"
        } opted out.`}</span>
      )}
      {contactOptedOutCount > 0 && <br />}
      {contactBouncedCount > 0 && (
        <span>{`${contactBouncedCount} ${
          contactBouncedCount > 1 ? "contacts" : "contact"
        } bounced.`}</span>
      )}
      {contactBouncedCount > 0 && <br />}
      {contactNotInFailedCount > 0 && (
        <span>{`${contactNotInFailedCount} ${
          contactNotInFailedCount > 1 ? "contacts are" : "contact is"
        } not in failed status.`}</span>
      )}
      {contactNotInFailedCount > 0 && <br />}
      {inboxNotFoundCount > 0 && (
        <span>{`${inboxNotFoundCount} ${
          inboxNotFoundCount > 1 ? "contacts" : "contact"
        } - scheduled inbox is not available in the sequence.`}</span>
      )}
      {inboxNotFoundCount > 0 && <br />}
      {failedErrorCount > 0 && (
        <span>{`${failedErrorCount} ${
          failedErrorCount > 1 ? "contacts" : "contact"
        } - unknown error.`}</span>
      )}
      {invalidSubject > 0 && (
        <span>{`${invalidSubject} ${
          invalidSubject > 1 ? "contacts" : "contact"
        } has invalid subject line.`}</span>
      )}
      {doNotContact > 0 && (
        <span>{`${doNotContact} ${
          doNotContact > 1 ? "contacts are" : "contact is"
        } in 'do not contact' stage.`}</span>
      )}
      {failedErrorCount > 0 && <br />}
    </div>
  );
};

const getSplittedCount = (responseCode = {}) => {
  let failedErrorCount = 0;
  let missingFieldsCount = 0;
  let missingTokensCount = 0;
  let inboxNotFoundCount = 0;
  let contactBouncedCount = 0;
  let contactOptedOutCount = 0;
  let contactNotInFailedCount = 0;
  let invalidSubject = 0;
  let nextStageNotFoundCount = 0;
  let notInCompletedStatusCount = 0;
  let stageAlreadyScheduledCount = 0;
  let differentContactOwnerCount = 0;
  let doNotContact = 0;

  if (responseCode && Object.keys(responseCode)?.length > 0) {
    for (const property in responseCode) {
      const propertyLength = responseCode?.[property]?.length;
      if ([400, "400"].includes(property)) {
        missingFieldsCount = propertyLength;
      } else if ([401, "401"].includes(property)) {
        contactOptedOutCount = propertyLength;
      } else if ([402, "402"].includes(property)) {
        contactBouncedCount = propertyLength;
      } else if ([403, "403"].includes(property)) {
        missingTokensCount = propertyLength;
      } else if ([404, "404"].includes(property)) {
        inboxNotFoundCount = propertyLength;
      } else if ([405, "405"].includes(property)) {
        contactNotInFailedCount = propertyLength;
      } else if ([406, "406"].includes(property)) {
        invalidSubject = propertyLength;
      } else if ([408, "408"].includes(property)) {
        doNotContact = propertyLength;
      } else if ([500, "500", 501, "501"].includes(property)) {
        failedErrorCount = propertyLength;
      } else if ([201, "201"].includes(property)) {
        nextStageNotFoundCount = propertyLength;
      } else if ([202, "202"].includes(property)) {
        notInCompletedStatusCount = propertyLength;
      } else if ([203, "203"].includes(property)) {
        stageAlreadyScheduledCount = propertyLength;
      } else if ([204, "204"].includes(property)) {
        differentContactOwnerCount = propertyLength;
      }
    }
  }
  return {
    failedErrorCount,
    inboxNotFoundCount,
    missingFieldsCount,
    missingTokensCount,
    contactOptedOutCount,
    contactBouncedCount,
    contactNotInFailedCount,
    invalidSubject,
    doNotContact,
    nextStageNotFoundCount,
    notInCompletedStatusCount,
    stageAlreadyScheduledCount,
    differentContactOwnerCount
  };
};

const MessageResponseHandler = observer(props => {
  const { bulkOperationResult = {}, typeText = "" } = props || {};
  const { success = 0, failed = 0, error = {} } = bulkOperationResult || {};

  const showMoreTextRef = React.createRef(null);
  const [showMoreText, setShowMoreText] = useState(false);
  const [showMoreHeight, setShowMoreHeight] = useState(false);

  let successMsg = "";
  let failureMsg = "";

  if (success > 0) {
    successMsg = `${success} contact${success > 1 ? "s" : ""} ${
      ["reactivate"].includes(typeText?.toLowerCase()) ? "re" : ""
    }activated in the sequence successfully.`;
  }
  if (failed > 0) {
    const splittedCount = getSplittedCount(error);
    const responseMsg = getFailureResponseMsg(splittedCount);

    failureMsg = (
      <>
        <span>{`Failed to activate ${failed} ${
          failed > 1 ? "contacts" : "contact"
        }. `}</span>
        <span
          className="linkHighLight"
          onClick={() => {
            setShowMoreText(!showMoreText);
          }}
        >
          show more.
        </span>
        {showMoreText && (
          <>
            <div
              className="leftBorder"
              style={{ height: `${showMoreHeight}px` }}
            />
            <div className="failureResponseMsg" ref={showMoreTextRef}>
              {responseMsg}
            </div>
          </>
        )}
      </>
    );
  }

  useEffect(() => {
    setShowMoreHeight(showMoreTextRef?.current?.clientHeight);
  }, [showMoreTextRef]);

  return (
    <>
      {successMsg}
      {successMsg && <br />}
      {failureMsg}
    </>
  );
});

const MessageResponseHandlerForResume = observer(props => {
  const { resumeResponse = {} } = props || {};
  const {
    successCount: success = 0,
    failedCount: failed = 0,
    inboxError = {}
  } = resumeResponse || {};

  const email = inboxError && Object.keys(inboxError)?.[0];

  let successMsg = "";
  let failureMsg = "";

  if (success > 0) {
    successMsg = `${success} ${
      success > 1 ? "contacts" : "contact"
    } activated in the sequence successfully.`;
  }
  if (failed > 0) {
    failureMsg = (
      <>
        <span>{`Failed to activate ${failed} ${
          failed > 1 ? "contacts" : "contact"
        } as `}</span>
        <a className="emailLink" href={`mailto:${email}`}>
          {`${email}`}
        </a>
        <span>{` is not connected to this sequence.`}</span>
      </>
    );
  }

  return (
    <>
      {successMsg}
      {successMsg && <br />}
      {failureMsg}
    </>
  );
});

export { MessageResponseHandler, MessageResponseHandlerForResume };
