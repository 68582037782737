import React from "react";

const StageLoader = ({ line = false }) => {
  return (
    <div className="overviewDetails fadeInDown">
      <span className="stageIconWrap linear-background" />
      {line && <span className="verticalLineLoader" />}
      <div className="stageCardLoader">
        <div className="stageHeader">
          <div className="stepNo">
            <span className="text linear-background" />
            <span className="sharpPointer" />
          </div>
          <div className="stageType">
            <span className="text linear-background" />
            <span className="sharpPointer" />
          </div>
          <div className="stageDate">
            <span className="text linear-background" />
            <span className="sharpPointer" />
          </div>
          <div className="stageStatusBadgeWrap">
            {Array.from({ length: 5 }, (value, index) => (
              <span
                className="linear-background"
                key={`stageStatusValue${index}`}
              />
            ))}
          </div>
          <div className="stageMoreOptions">
            <span className="linear-background" />
          </div>
        </div>
        <div className="stageVariants">
          <div className="variantCard">
            <div className="index linear-background" />
            <div className="toggleBtnSection linear-background" />
            <div className="subject linear-background" />
            <div className="content linear-background" />
            <div className="stats">
              {Array.from({ length: 4 }, (value, index) => (
                <div className="statsCountTitleWrap" key={`statsIndex${index}`}>
                  <span className="statsCount linear-background" />
                  <span className="statsTitle linear-background" />
                </div>
              ))}
            </div>
            <div className="stageMoreOptions linear-background" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageLoader;
export { StageLoader };
