import React, { useEffect } from "react";
import { observer } from "mobx-react";
import taskModuleState from "./state";
import { taskTypeIcon } from "Static/constant";
import Utils from "Utils/utils";
import PaginationComponent from "Components/common/PaginationComponent";
import { ReactComponent as TaskIcon } from "Assets/svg/task.svg";
import TaskDetailLoader from "./TaskDetailLoader";

const TaskDetail = observer(props => {
  const getTaskTypeAndIcon = taskType => {
    return (
      <span className={`taskTypeWrapper ${taskType}`}>
        <span className="taskTypeIcon">{taskTypeIcon?.[taskType]?.icon}</span>
        <span className="taskTypeLabel">{taskTypeIcon?.[taskType]?.label}</span>
      </span>
    );
  };

  const getNotes = (note = "", touchPoint = {}) => {
    const { notes = "" } = touchPoint || {};
    let tempString = note || notes || "";
    return tempString?.replace(/\r?\n/g, " ") || "--";
  };

  const getTaskDue = dueDateObj => {
    return (
      <div
        className="taskDueDate"
        style={{
          color: dueDateObj.color
        }}
      >
        <>
          <i className="material-icons">schedule</i>
          <span>{dueDateObj.dueDate}</span>
        </>
      </div>
    );
  };

  const getContactOwner = (firstName, lastName, email) => {
    return (
      <div className="contactOwner">
        {Utils.getOwnerInitial({
          firstName,
          lastName,
          email
        })}
      </div>
    );
  };

  const paginate = pageNoObj => {
    if (!props?.loading) {
      const pageNo = pageNoObj.selected ? pageNoObj.selected + 1 : 1;
      taskModuleState.setTaskDataLoading(true);
      const dateFilterValue = taskModuleState.dateFilterValue;
      if (dateFilterValue) {
        if (taskModuleState?.activeTab === "total") {
          taskModuleState.getTaskData(
            null,
            pageNo,
            true,
            null,
            dateFilterValue
          );
        } else {
          taskModuleState.getTaskData(
            taskModuleState?.activeTab,
            pageNo,
            null,
            null,
            dateFilterValue
          );
        }
      } else {
        if (taskModuleState?.activeTab === "total") {
          taskModuleState.getTaskData(null, pageNo, true, null, null);
        } else {
          taskModuleState.getTaskData(null, pageNo, null, null, null);
        }
      }
    }
  };

  return (
    <>
      <div
        className={`taskSectionDetailWrapper ${
          taskModuleState?.taskData?.tasks?.length > 0 ? "" : "noTaskWrapper"
        }`}
      >
        {!taskModuleState?.taskDataLoading ? (
          taskModuleState?.taskData?.tasks?.length > 0 ? (
            taskModuleState?.taskData?.tasks?.map((dataMap, key) => {
              if (dataMap && Object.keys(dataMap).length) {
                let { task = {}, contact = {} } = dataMap;
                let {
                  firstName = "",
                  lastName = "",
                  email = "",
                  name = ""
                } = contact || {};
                let {
                  taskDue = "",
                  note = "",
                  taskType = "",
                  touchPoint = "",
                  id = ""
                } = task;

                const dueDateObj = Utils.getDueDateToRender(
                  touchPoint?.scheduleDate || taskDue
                );
                const notesString = getNotes(note, touchPoint);
                return (
                  <div className="taskSectionDetail" key={id}>
                    <div className="taskEmail">
                      {Utils.getName(firstName, lastName, name, email)}
                    </div>
                    {/* {getContactOwner(firstName, lastName, email)} */}
                    <div className="taskTypeIcon">
                      {getTaskTypeAndIcon(taskType)}
                    </div>
                    <div className="taskNotes">
                      <span className="notes" title={notesString}>
                        {notesString}
                      </span>
                    </div>
                    {getTaskDue(dueDateObj)}
                  </div>
                );
              }
            })
          ) : (
            <div className="no-task">
              <span className="taskIcon">
                <TaskIcon />
              </span>
              <span className="noTaskText"> No Tasks found</span>
            </div>
          )
        ) : (
          <TaskDetailLoader />
        )}
      </div>
      <PaginationComponent
        callback={paginate}
        loading={taskModuleState?.taskDataLoading}
        totalPages={taskModuleState?.totalPages}
        currentPage={taskModuleState?.currentPage || 1}
      />
    </>
  );
});

export { TaskDetail };
export default TaskDetail;
