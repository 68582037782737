import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import taskStoreData from "Stores/taskData";

export const PerformTaskFileAttachment = observer(() => {
  const [attachments, setAttachments] = useState(
    taskStoreData?.taskAttachments || []
  );

  useEffect(() => {
    setAttachments(taskStoreData?.taskAttachments || []);
  }, [taskStoreData?.taskAttachments]);

  return (
    <div className="taskAttachmentList">
      {attachments?.length > 0
        ? attachments.map(item => {
            const {
              attachmentSizeInKB = "",
              attachmentName = "",
              id = ""
            } = item || {};
            return (
              <div key={id} className="taskAttachmentItem">
                <span className="taskAttName">{attachmentName}</span>
                <span className="taskAttSize">
                  ({Utils.getFileSizeAndType(attachmentSizeInKB || "")})
                </span>
                <i
                  className="material-icons taskAttCloseIcon"
                  onClick={() => taskStoreData.setRemovedAttachmentId(id)}
                >
                  close
                </i>
              </div>
            );
          })
        : ""}
    </div>
  );
});

export default PerformTaskFileAttachment;
