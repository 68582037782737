import { action, observable } from "mobx";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import { addEmailPreferencesState } from "../Component/AddEmailPreferencesPopup";

class EmailPreferenceStore {
  @action
  editNotification = async (data = {}, cbk = () => {}) => {
    const config = {
      url: URLS.editEmailpreferences,
      method: "POST",
      data
    };
    let res = await makeApi(config);
    if (+res?.status === 200) {
      toasterState.setToastMsg(
        "Email Reports has been updated successfully",
        "success"
      );
      cbk(res?.data);
    } else {
      cbk({});
    }
    addEmailPreferencesState.setShowPopup(false);
    addEmailPreferencesState.setBtnLoader(false);
  };
}

const emailPreferenceStore = new EmailPreferenceStore();

export { emailPreferenceStore };
export default emailPreferenceStore;
