import { makeApi, URLS } from "Utils/apiURL";
import userDetail from "Stores/userDetail";
import DialerServiceData from "Stores/DialerServiceStore";
import { teamState } from "Pages/Dashboard/settings/UsersAndTeams/Teams/state";
import Utils from "./utils";
import InboxUtils from "./InboxUtils";
import customUrlStoreData from "Stores/Setting/CustomUrlStore";
import onboardingStoreData from "Stores/onBoardingData";
import dialerStoreData from "Stores/DialerData";

export const loginValidate = async () => {
  try {
    const config = {
      url: URLS.loginValidation,
      method: "GET"
    };
    const userObj = {};
    const response = await makeApi(config);
    if (response?.data) {
      const {
        isLoggedIn = false,
        email = "",
        hasteam = "",
        memberId = "",
        firstName = "",
        lastName = "",
        intercomEncryptKey = "",
        status = "",
        phone = "",
        calendarEnabled = false,
        unsubLinkDefaultDom = ""
      } = response?.data || {};
      let name = "";
      if (isLoggedIn) {
        userObj.isOnBoardingCompleted =
          await onboardingStoreData.getOnBoardingData();
        await dialerStoreData.getCallStageList(false);
        userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
        userObj.team = await checkTeamStatus();
        if (!userObj?.team) {
          userObj.isTeamOwner = await teamState.getTeamData();
        }
        const hasTeamMember = await Utils.userTeamList();
        userObj.hasTeamMember = hasTeamMember?.length ? hasTeamMember : false;
        name =
          (firstName && lastName && `${firstName} ${lastName}`) ||
          firstName ||
          lastName ||
          "";
      }
      userObj.email = email;
      userObj.name = name;
      userObj.userLoggedIn = isLoggedIn;
      userObj.hasTeam = hasteam;
      userObj.memberId = memberId;
      userObj.firstName = firstName || "";
      userObj.lastName = lastName || "";
      userObj.intercomEncryptKey = intercomEncryptKey || "";
      userObj.status = status || "";
      userObj.phone = phone || "";
      userObj.calendarEnabled = calendarEnabled || false;
      userObj.unsubLinkDefaultDom = unsubLinkDefaultDom || "";
      userDetail.setUserInfo(userObj);
      return true;
    } else {
      userObj.userLoggedIn = false;
      userDetail.setUserInfo(userObj);
      return false;
    }
  } catch (error) {
    console.error("loginValidate error ", error);
  }
};

export const fetchConnectedEmails = async () => {
  try {
    const config = {
      url: URLS.getConnectedInboxes,
      method: "GET"
    };
    const response = await makeApi(config);
    await onboardingStoreData.getOnBoardingData();
    const { status = "", data = {} } = response;
    if (data && Object.keys(data)?.length && [200, "200"].includes(status)) {
      const {
        inboxes = [],
        totalCount = 0,
        accessRevokedCount = 0
      } = data || {};
      const activeAccount = inboxes.filter(item => item.status === "ACTIVE");
      if (+accessRevokedCount === +totalCount) {
        InboxUtils.showConnectInboxBanner();
      }
      if (activeAccount?.length > 0) {
        InboxUtils.checkAndAddConnectInbox(activeAccount);
      } else {
        InboxUtils.checkAndAddConnectInbox([]);
      }
    }
  } catch (err) {
    console.error("Fetch Connected Email", err);
  }
};

export const fetchLinkedInAccounts = async () => {
  try {
    const config = {
      url: URLS.getConnectedLinkedInAccounts,
      method: "POST",
      data: {
        limit: 1,
        pageNo: 1
      }
    };
    const response = await makeApi(config);
    const { data = {} } = response;
    if (data && Object.keys(data)?.length) {
      const { accounts = [] } = data || {};
      const activeAccounts = accounts.filter(item => item.status === "ACTIVE");
      if (activeAccounts?.length > 0) {
        userDetail.setActiveLinkedInAccounts(activeAccounts);
      } else {
        userDetail.setActiveLinkedInAccounts([]);
      }
    }
  } catch (err) {
    console.error("Fetch Connected Linkedin Accounts", err);
  }
};

export const checkTeamStatus = async () => {
  try {
    const config = {
      url: URLS.checkTeamStatus ? URLS.checkTeamStatus : ""
    };
    let res = await makeApi(config);
    if (res?.data?.responseCode) {
      switch (res.data.responseCode) {
        case 200:
          return true;
        case 208:
          return false;
        default:
          return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    console.error("Team role check ", err);
  }
};

export const getFeatureFlagWithTeam = async (allowMultipleAPI = true) => {
  try {
    const config = {
      url: URLS.getFeatureFlagWithTeam
    };
    let res = await makeApi(config);
    if (res?.data) {
      const {
        team = {},
        teamSubscription = {},
        noOfContactsAddedInTeam = 0
      } = res?.data || {};

      const { dialerEnabled, brandedUrlEnabled, id: teamId } = team || {};
      let userFeatureFlag = { ...team, teamId, noOfContactsAddedInTeam };

      (teamSubscription || dialerEnabled) &&
        allowMultipleAPI &&
        DialerServiceData.getDialerPhoneList(false);
      if (teamSubscription) {
        const teamSubscription = res.data.teamSubscription;
        userFeatureFlag.subscriptionPlanId =
          teamSubscription.subscriptionPlanId || null;
        userFeatureFlag.planName = teamSubscription.planName || null;
        userFeatureFlag.isAnnual = teamSubscription.annual || null;
        userFeatureFlag.endDate = teamSubscription.endDate || null;
        userFeatureFlag.amount = teamSubscription.price || null;
        userFeatureFlag.trialEnabled = teamSubscription.trialEnabled || false;
        userFeatureFlag.trialUsed = teamSubscription.trialUsed || false;
        userFeatureFlag.status = teamSubscription.status.toLowerCase() || "";
        userFeatureFlag.lifeTimeDeal = teamSubscription?.lifeTimeDeal || false;
        userFeatureFlag.trialEndDate = teamSubscription.trialEndDate || false;
        userFeatureFlag.emailCreditsDailyLimit =
          teamSubscription?.emailCreditsDailyLimit || 0;
        userFeatureFlag.emailCreditsEligible =
          teamSubscription?.emailCreditsEligible || 0;
        userFeatureFlag.emailCreditsMonthlyLimit =
          teamSubscription?.emailCreditsMonthlyLimit || 0;
        userFeatureFlag.phoneCreditsAnnualLimit =
          teamSubscription?.phoneCreditsAnnualLimit || 0;
        userFeatureFlag.phoneCreditsDailyLimit =
          teamSubscription?.phoneCreditsDailyLimit || 0;
        userFeatureFlag.phoneCreditsEligible =
          teamSubscription?.phoneCreditsEligible || 0;
        userFeatureFlag.phoneCreditsMonthlyLimit =
          teamSubscription?.phoneCreditsMonthlyLimit || 0;
      }
      if (brandedUrlEnabled && allowMultipleAPI) {
        let urlRes = await customUrlStoreData.getBrandUrlData();
        urlRes &&
          Object.keys(urlRes).length > 0 &&
          userDetail.setBrandUrlInfo(urlRes);
      }
      return userFeatureFlag;
    }
    return {};
  } catch (err) {
    console.error("Get Feature Flag of Team", err);
  }
};

export const createFreeTrial = async (data = {}) => {
  try {
    const config = {
      url: URLS.createFreeTrial,
      method: "POST",
      data
    };
    return await makeApi(config);
  } catch (err) {
    console.error("Start free trail error", err);
  }
};

export const checkTeamSubscriptionActive = async () => {
  if (userDetail?.userFeatureFlag?.teamStatus?.toLowerCase() !== "suspended") {
    return true;
  } else {
    Utils.showAccountSuspendedPopup();
    return false;
  }
};

export const getStripeInformation = async () => {
  try {
    let config = {
      url: URLS.checkCustomerIdAvailability,
      method: "GET"
    };
    let response = await makeApi(config);
    if (response?.data) {
      const { responseCode = "" } = response?.data || {};
      userDetail.setUserStripeCode(responseCode);
    }
  } catch (err) {
    console.error("fetching stripe details error ", err);
  }
};

export const isTeamMemberOwner = async () => {
  try {
    const config = {
      url: URLS.getTeamGroups,
      method: "POST",
      data: {
        pageNo: 1,
        limit: 500
      }
    };
    let res = await makeApi(config);
    return res?.data?.groupList?.length > 0;
  } catch (err) {
    console.error("fetching stripe details error ", err);
  }
};

export const getGlobalTimeZones = async () => {
  try {
    const config = {
      url: URLS.listTimeZone,
      method: "GET"
    };
    const response = await makeApi(config);
    if (response) {
      const { data = [] } = response || {};
      userDetail.setGlobalTimeZone(data);
    }
  } catch (e) {
    console.error("Failed to get scheduled data", e);
  }
};
