import React from "react";

export const Title = props => {
  const { isEdit = "" } = props || {};
  return (
    <div className="textTitle">{`${isEdit ? "Edit " : "New "} Trigger`}</div>
  );
};

export default Title;
