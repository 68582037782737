/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import SequenceUtils from "Utils/SequenceUtils";
import ConactProcessImg from "Assets/png/ContactProcess.png";
import BgDotWave from "Assets/png/BgDotWave.png";
import ProgressBar from "Utils/LottieFiles/ProgressBar.json";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { exportEmailNotifyState } from "Components/popup/ExportEmailNotify";
import { toasterState } from "Components/common/toaster";
import { exportToSalesforcePopupState } from "../common/ExportToSalesforcePopup";
import { MessageResponseHandler } from "./MessageResponseHandler";

const defaultSpeed = 1;
class ExportProcessingState {
  @observable showPopup = false;
  @observable doComplete = false;

  @action
  completeAnimation = () => {
    this.doComplete = true;
  };

  @action
  setShowPopup = val => {
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.doComplete = false;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
    this.showPopup = val;
  };

  @action
  handleHubspotExportResponse = async (
    response = {},
    clearContactsCbk = () => {}
  ) => {
    if (+response?.status === 200 && response?.data) {
      exportProcessingState.completeAnimation();
      setTimeout(() => {
        const {
          bulkOperationResult = {},
          background = false,
          activeJob = false
        } = response?.data || {};
        if (activeJob) {
          clearContactsCbk();
          SequenceUtils.showActiveJobPopupForBulkActions(
            "Another export is already in progress. Please allow some more time for it to complete before starting a new export."
          );
        } else if (bulkOperationResult?.success > 0) {
          confirmationPopupState.setPopupValues({
            content: `${bulkOperationResult?.success} contact${
              bulkOperationResult?.success > 1 ? "s" : ""
            } exported to your HubSpot CRM successfully.`,
            actionBtnText: "Ok",
            needCancelBtn: false,
            loadingMsg: "Please wait...",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        } else if (bulkOperationResult?.failed > 0) {
          confirmationPopupState.setPopupValues({
            content: `Apologies, we encountered an issue while exporting contacts. Our team is here to help.Please reach out to <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer" >support@salesgear.io</a> or via the chat window below for assistance.`,
            actionBtnText: "Chat with us",
            needCancelBtn: false,
            callback: () => {
              confirmationPopupState.setShowPopup(false);
            },
            type: "chatWithUs",
            id: "my_custom_link"
          });
          confirmationPopupState.setShowPopup(true);
        } else if (background) {
          exportEmailNotifyState.setShowText("hubspotexport");
          exportEmailNotifyState.setShowPopup(true);
        }
        exportProcessingState.setShowPopup(false);
        clearContactsCbk();
      }, 1000);
    } else {
      exportProcessingState.setShowPopup(false);
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
  };

  @action
  handleSalesforceExportResponse = async (
    response = {},
    clearContactsCbk = () => {},
    crmType = "",
    crmResponseType = "contact"
  ) => {
    exportToSalesforcePopupState.setDefaultObj({});
    if (+response?.status === 200 && response?.data) {
      exportProcessingState.completeAnimation();
      setTimeout(() => {
        const {
          bulkOperationResult = {},
          background = false,
          activeJob = false
        } = response?.data || {};
        const bulkResponse = bulkOperationResult?.response || {};
        if (activeJob) {
          clearContactsCbk();
          SequenceUtils.showActiveJobPopupForBulkActions(
            "Another export is already in progress. Please allow some more time for it to complete before starting a new export."
          );
        } else if (background) {
          exportEmailNotifyState.setShowText("hubspotexport");
          exportEmailNotifyState.setShowPopup(true);
        } else {
          confirmationPopupState.setPopupValues({
            content: (
              <MessageResponseHandler
                bulkResponse={bulkResponse}
                crmType={crmType}
                crmResponseType={crmResponseType}
              />
            ),
            actionBtnText: "Ok",
            dynamicDom: true,
            needCancelBtn: false,
            loadingMsg: "Please wait...",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        }
        exportProcessingState.setShowPopup(false);
        clearContactsCbk();
      }, 1000);
    } else {
      exportProcessingState.setShowPopup(false);
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
  };
}

const exportProcessingState = new ExportProcessingState();

const ExportProcessing = () => {
  const [speed, setSpeed] = useState(defaultSpeed);
  window.timeout = null;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: ProgressBar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const setProgressSpeed = () => {
    window.timeout = setTimeout(() => {
      if (
        !exportProcessingState.doComplete &&
        exportProcessingState.showPopup
      ) {
        setSpeed(0.1);
      }
    }, 2500);
  };

  useEffect(() => {
    if (exportProcessingState.showPopup) {
      setProgressSpeed();
    } else {
      setSpeed(defaultSpeed);
    }
  }, [exportProcessingState.showPopup]);

  useEffect(() => {
    clearTimeout(window.timeout);
    setSpeed(10);
  }, [exportProcessingState.doComplete]);

  return exportProcessingState.showPopup ? (
    <div className="popup">
      <div className="popupOverlay"></div>
      <div className="popupCard expProcessWt animated customzoomIn">
        <div className="popupTitle">
          <h6 className="titleSm">We are processing your export</h6>
        </div>
        <div className="popupBody">
          <div className="progressBar">
            <Lottie options={defaultOptions} width={240} speed={speed} />
          </div>
        </div>
        <div className="imgWrap">
          <img src={ConactProcessImg} className="contactImg" alt="" />
          <img className="dotWaveBg" src={BgDotWave} alt="" />
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export { ExportProcessing, exportProcessingState };
export default observer(ExportProcessing);
