import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import DatePickerComponent from "Components/common/dateTimePicker";

export const ScheduleLaterSendEmailBtn = observer(
  ({
    sendEmailObj = {},
    isOutboxPage = false,
    enabled = false,
    scheduledDate = new Date(),
    handleDateChange = () => {}
  }) => {
    const [showDropdown, setShowDropDown] = useState(false);
    const [showOption, setShowOption] = useState("SCHEDULE");

    const toggleScheduleLaterDropdown = () => {
      if (!isOutboxPage && enabled) {
        setShowDropDown(!showDropdown);
        showDropdown && setShowOption("SCHEDULE");
      }
    };

    const getScheduleLaterDate = (key = "") => {
      let currentDate = new Date();
      switch (key) {
        case "ONE_HOUR":
          let timeToAdd = 1000 * 60 * 60;
          return new Date(currentDate.getTime() + timeToAdd);
        case "TOMORROW_8AM":
          currentDate.setHours(8, 0, 0, 0);
          currentDate.setDate(currentDate.getDate() + 1);
          return currentDate;
        case "TOMORROW_3PM":
          currentDate.setHours(15, 0, 0, 0);
          currentDate.setDate(currentDate.getDate() + 1);
          return currentDate;
        default:
          return currentDate;
      }
    };

    const DATE_OPTIONS = [
      {
        name: "Send 1 hour later",
        id: "send1HourLater",
        value: "ONE_HOUR"
      },
      {
        name: "Send tomorrow morning at 8 am",
        id: "sendTmrwAMorning",
        value: "TOMORROW_8AM"
      },
      {
        name: "Send tomorrow afternoon at 3pm",
        id: "sendTmrwAfternoon",
        value: "TOMORROW_3PM"
      },
      {
        name: "Select date and time",
        id: "selectNewDate",
        value: "SELECT_DATE"
      }
    ];

    const clearDropdown = () => {
      setShowOption("SCHEDULE");
      setShowDropDown(false);
    };

    const handleSelectAction = (event = "") => {
      let value = Utils.performActionByClosestDom(event, "li", "data-key");
      if (value?.toUpperCase() === "SELECT_DATE") {
        setShowOption("SCHEDULE_DATE_PICKER");
      } else {
        let scheduleLaterDate = getScheduleLaterDate(value);
        clearDropdown();
        handleDateChange(scheduleLaterDate);
      }
    };

    useEffect(() => {
      return () => {
        clearDropdown();
      };
    }, []);

    return (
      <div className="ScheduleLaterSendEmailBtn">
        <Button {...sendEmailObj} />
        {!isOutboxPage && !sendEmailObj?.loader && (
          <div className={`scheduleLaterBtn ${!enabled ? "disabled" : ""}`}>
            <div
              className="scheduleArrowBtn"
              onClick={toggleScheduleLaterDropdown}
            >
              <i
                className={`material-icons-outlined ${
                  showDropdown ? "rotateArrow" : ""
                }`}
              >
                keyboard_arrow_down
              </i>
            </div>
            {showDropdown && (
              <div className="scheduleLaterDropdown">
                {showOption?.toUpperCase() === "SCHEDULE" && (
                  <div
                    className="scheduleBtn"
                    onClick={e => {
                      Utils.preventDefaultFn(e);
                      setShowOption("SCHEDULE_OPTION");
                    }}
                  >
                    <i className="material-icons-outlined">schedule</i>
                    <span className="text">SCHEDULE</span>
                  </div>
                )}
                {showOption?.toUpperCase() === "SCHEDULE_OPTION" &&
                  DATE_OPTIONS?.length > 0 && (
                    <ul
                      className="selectScheduleDateOption"
                      id="selectScheduleDateOption"
                      onClick={handleSelectAction}
                    >
                      {[...DATE_OPTIONS]?.map(item => {
                        const { name = "", id = "", value = "" } = item || {};
                        return (
                          <li
                            className="scheduleDateItem"
                            key={id}
                            data-key={value}
                          >
                            {name}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                {showOption?.toUpperCase() === "SCHEDULE_DATE_PICKER" && (
                  <DatePickerComponent
                    getDateCbk={handleDateChange}
                    id="scheduleLaterDatePicker"
                    value={scheduledDate}
                    onClickOutside={clearDropdown}
                    inline={true}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default ScheduleLaterSendEmailBtn;
