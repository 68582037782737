import React from "react";
import Utils from "Utils/utils";

const ContactSelectedCount = props => {
  const {
    pageType = "",
    selectedCount = 0,
    totalCount = 0,
    loading = false,
    allContactSelected = false,
    enableSelectAll = true,
    setAllContactSelected = () => {},
    resetCbk = () => {},
    selectAllCbk = () => {},
    selectedType = "Contact"
  } = props || {};

  const changeAction = (value = false) => {
    setAllContactSelected(value);
    value
      ? !["task"]?.includes(pageType?.toLowerCase()) && selectAllCbk(true)
      : resetCbk();
  };

  return (
    <div className="contactSelectedCount">
      {(!loading && (
        <>
          {allContactSelected ? (
            <div className="allContactSelected">
              {`All ${selectedType}s selected.`}
              <span
                onClick={() => changeAction(false, true)}
                className="clearBtn"
              >
                Clear selection
              </span>
            </div>
          ) : (
            <>
              <span className="selectedCount">
                {selectedCount} {selectedType}
                {Utils.isPlural(selectedCount)} Selected.
              </span>
              {enableSelectAll && (
                <span className="totalCount" onClick={() => changeAction(true)}>
                  Select all {totalCount} {selectedType}
                  {Utils.isPlural(totalCount)} in this view.
                </span>
              )}
              {["discover"].includes(pageType?.toLowerCase()) && (
                <span onClick={() => changeAction(false)} className="clearBtn">
                  Clear selection
                </span>
              )}
            </>
          )}
        </>
      )) ||
        ""}
    </div>
  );
};

export default ContactSelectedCount;
export { ContactSelectedCount };
