import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import SequenceUtils from "Utils/SequenceUtils";
import Button from "Components/common/Button";
import RadioButton from "Components/common/radioBtn";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { accountsState } from "./AccountsState";
import { accountBulkActionsState } from "./AccountBulkActionsState";
import { toasterState } from "Components/common/toaster";

const BulkStageChangePopup = observer((props = {}) => {
  const { clearSelectedAccounts = () => {}, reloadAccountsPage = () => {} } =
    props || {};
  const {
    filteredStageId = "",
    accountCount = 0,
    accountIds = [],
    allAccountsSelectedInView = false
  } = accountBulkActionsState?.bulkStageUpdateObj || {};

  const [updateLoader, setUpdateLoader] = useState(false);
  const [selectedStageId, setSelectedStageId] = useState("");
  const [accountStages, setAccountStages] = useState(
    accountsState?.accountStages
  );

  const bulkStageChangeAction = async () => {
    setUpdateLoader(true);
    let response = await accountBulkActionsState.bulkStageChange(
      filteredStageId?.toLowerCase() === "total" ? "" : filteredStageId,
      selectedStageId,
      allAccountsSelectedInView ? [] : accountIds,
      allAccountsSelectedInView
        ? accountsState?.totalAccounts
        : accountIds?.length
    );
    const { status = "", data = {} } = response || {};
    const {
      background = false,
      activeJob = false,
      totalCount = 0,
      bulkOperationResult = {}
    } = data || {};
    const { success = "", failure = "" } = bulkOperationResult || {};
    if (+status === 200) {
      if (activeJob) {
        SequenceUtils.showActiveJobPopupForBulkActions();
      } else {
        if (background) {
          confirmationPopupState.setPopupValues({
            content:
              "Accounts are currently being updated. This process may take a few to several minutes, depending on the total number of accounts selected.",
            needCancelBtn: false,
            actionBtnText: "Ok",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        } else if (success > 0) {
          confirmationPopupState.setPopupValues({
            content: `${success} account${success > 1 ? "s" : ""} ${
              success > 1 ? "have" : "has"
            } been updated successfully`,
            needCancelBtn: false,
            actionBtnText: "Ok",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
          reloadAccountsPage();
        } else if (failure > 0) {
          confirmationPopupState.setPopupValues({
            content: ` Failed to update ${failure} account${
              failure > 1 ? "s" : ""
            } `,
            needCancelBtn: false,
            actionBtnText: "Ok",
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        }
      }
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
    clearSelectedAccounts(true);
    accountBulkActionsState.setShowBulkUpdateStagePopup(false);
    setUpdateLoader(false);
  };

  const buttonList = [
    {
      id: "cancel",
      name: "cancel",
      btnText: "Cancel",
      type: "button",
      className: "cancelButton",
      click: () => accountBulkActionsState.setShowBulkUpdateStagePopup(false)
    },
    {
      id: "update",
      name: "update",
      btnText: "Update",
      type: "button",
      className: `updateButton ${updateLoader ? "loading" : ""}`,
      click: bulkStageChangeAction,
      loader: updateLoader
    }
  ];

  const closePopup = () => {
    accountBulkActionsState.setShowBulkUpdateStagePopup(false);
    accountBulkActionsState.resetBulkStageUpdateObj();
  };

  const radioOnchangeAction = (e, id = "") => {
    let tempArr = [...accountStages].map(item => ({
      ...item,
      isChecked: id === item?.id
    }));
    setAccountStages(tempArr);
    setSelectedStageId(id);
  };

  const getContactCount = () => {
    return allAccountsSelectedInView ? "All" : accountCount;
  };

  useEffect(() => {
    let tempAccountStages = [...accountStages].map(item => ({
      ...item,
      isChecked: false
    }));
    setAccountStages(tempAccountStages);
  }, []);

  return (
    <div className="bulkAccountStageChangeComp">
      <div className="card animated customzoomIn">
        <div className="cardHeader">
          <div className="titleContainer">
            <div className="title">Update Account Stage</div>
            <div className="contactSelectedCont">{`${getContactCount()} accounts selected`}</div>
          </div>
          <span className="closeIcon">
            <i className="material-icons close" onClick={closePopup}>
              close
            </i>
          </span>
        </div>
        <div className="cardBody">
          <div className="accountListCont">
            {accountStages?.map(item => {
              const {
                displayName = "",
                id = "",
                isChecked = false
              } = item || {};
              return (
                <RadioButton
                  id={id}
                  value={displayName}
                  name="sequence"
                  defaultChecked={isChecked}
                  radioOnchange={radioOnchangeAction}
                />
              );
            })}
          </div>
          <div className="buttonActionList">
            {buttonList.map(item => (
              <Button {...item} key={`${item?.id}Btn`} />
            ))}
          </div>
        </div>
      </div>
      <div className="bgOverlay" />
    </div>
  );
});

export default BulkStageChangePopup;
export { BulkStageChangePopup };
