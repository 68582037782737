import React, { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "Assets/svg/deleteIcon.svg";
import CustomTooltip from "Components/common/CustomTooltip";
import makeApi, { URLS } from "Utils/apiURL";
import { observer } from "mobx-react";
import { toasterState } from "Components/common/toaster";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import userDetail from "Stores/userDetail";

const DoNotContactDomainRender = observer(props => {
  const { domainRenderList = [], setDomainRenderList = () => {} } = props;
  const [renderLoader, setRenderLoader] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const ContactStageCardListLoader = ({ length = 9 }) => {
    return Array.from({ length }, (value, index) => (
      <div className="contactStageCard contactStageCardLoader" key={index}>
        <div className="contactStageName  linear-background" />
        <div className="actionIcons">
          {index >= 2 && <div className="iconBackground linear-background" />}
          <div className="iconBackground linear-background" />
        </div>
      </div>
    ));
  };
  const handleApiCall = (checkText, domainName = "") => {
    let checkFlag = checkText === "get";
    let arr = [domainName];
    const config = {
      url: checkFlag ? URLS.addDomain : URLS.removeDomain,
      method: checkFlag ? "GET" : "POST",
      data: { domains: checkFlag ? [] : arr }
    };
    return makeApi(config);
  };

  const handlePopUp = (domainName, index) => {
    confirmationPopupState.setPopupValues({
      content: `Are you sure you want to remove the domain from the blocked domain list?`,
      actionBtnText: "Confirm",
      cancelBtnText: "Cancel",
      loadingMsg: "Please wait...",
      callback: () => {
        handleDeleteDomain(domainName, index);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };
  const handleDeleteDomain = async (domainName, index) => {
    let response = await handleApiCall("delete", domainName);
    confirmationPopupState.setShowPopup(false);
    if (response?.data?.status === true) {
      toasterState.setToastMsg("Domain removed successfully", "success");
      let temp = domainRenderList;
      temp = temp.filter(item => item !== domainName);
      setDomainRenderList(temp);
      userDetail.setBlockedDomainList(temp);
    } else {
      toasterState.setToastMsg(
        "Domain removal unsuccessfull try again later",
        "failure"
      );
    }
  };

  const fetchData = async () => {
    setRenderLoader(true);
    let response = await handleApiCall("get");
    if (response?.data) {
      setDomainRenderList([...response?.data]);
    }
    setRenderLoader(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (domainRenderList) {
      setDomainRenderList(domainRenderList);
    }
  }, [domainRenderList]);

  return (
    <div className="contactStageCardList topPostion">
      {renderLoader && <ContactStageCardListLoader length={9} />}
      {domainRenderList?.length > 0 &&
        domainRenderList.map((item, index) => {
          return (
            <div className="contactStageCard" key={index}>
              <span className="contactStageName">{item}</span>
              <span
                className="iconBackground deleteIcon"
                onMouseOver={() => setSelectedIndex(index)}
                onMouseLeave={() => setSelectedIndex(-1)}
                onClick={() => handlePopUp(item, index)}
              >
                <DeleteIcon />
                {index === selectedIndex && (
                  <CustomTooltip text={"Delete Domain"} />
                )}
              </span>
            </div>
          );
        })}
    </div>
  );
});

export { DoNotContactDomainRender };
export default DoNotContactDomainRender;
