import React from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import PreviewFileAttached from "Components/FileAttachment/PreviewFileAttached";
import { settingsStoreData } from "Stores/settingsData";

const PreviewEditorTemplate = observer(props => {
  const {
    subject = "",
    contentBody = "",
    attachments = [],
    currentStage = false,
    titleText = "",
    hasTitle = true
  } = props || {};

  const tempDom = document.createElement("div");
  tempDom.innerHTML = contentBody;
  let isValidContent = tempDom.innerText;
  const hasImgInTemplate = tempDom.getElementsByTagName("img");

  const htmlValue = Utils.replaceTokenWithValue(
    contentBody,
    true,
    false,
    settingsStoreData.customFields
  );

  return (
    <div className="preview-templete">
      {hasTitle && <h6 className="preview-title">{titleText}</h6>}
      <div
        className={`preview-body ${
          currentStage && currentStage > 1 ? "previewModeHtAdjust" : ""
        }`}
      >
        {(!subject || subject === "") &&
          (isValidContent === "" ||
            (isValidContent && isValidContent?.length <= 0)) &&
          (!hasImgInTemplate || hasImgInTemplate?.length <= 0) &&
          (!attachments || attachments?.length <= 0) && (
            <div className="preview-text">
              Your Template Preview Will appear here.
            </div>
          )}
        {subject && (
          <div className="preview-subject">
            <label>Sub:</label>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html:
                  Utils.replaceTokenWithValue(
                    subject,
                    true,
                    false,
                    settingsStoreData.customFields
                  ) || ""
              }}
            />
          </div>
        )}
        {((contentBody?.length !== 0 || attachments?.length > 0) && (
          <div className="preview-content-body" disabled>
            <div
              dangerouslySetInnerHTML={{
                __html: htmlValue || ""
              }}
            />
            {attachments && <PreviewFileAttached attachments={attachments} />}
          </div>
        )) ||
          ""}
      </div>
    </div>
  );
});

export default PreviewEditorTemplate;
export { PreviewEditorTemplate };
