import React, { useState, useEffect, useRef } from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { tagsStoreData } from "Stores/TagsData";
import { Utils } from "Utils/utils";
import { Button } from "Components/common/Button";
import CommonPopup from "../../CommonPopup";
import SearchInputBox from "../../SearchInputBox";
import CheckBoxComponent from "../../CheckBox";
import SelectedTagList from "../SelectedTagList";

class BulkRemoveTagsPopupState {
  @observable showPopup = false;
  @observable filteredData = [];
  @observable defaultValues = {};
  @observable buttonLoader = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setFilteredData(val) {
    this.filteredData = val;
  }

  @action
  setDefaultValues(val) {
    this.defaultValues = val;
  }

  @action
  setButtonLoader(val) {
    this.buttonLoader = val;
  }
}

const bulkRemoveTagsPopupState = new BulkRemoveTagsPopupState();

const BulkRemoveTagsPopup = observer(() => {
  const searchBoxRef = useRef(null);
  const [tagList, setTagList] = useState(null);
  const [renderArray, setRenderArray] = useState([]);
  const [showTagList, setShowTagList] = useState(false);
  const [clearSearchInput, setClearSearchInput] = useState(false);

  const closeIconCbk = () => {
    bulkRemoveTagsPopupState.setShowPopup(false);
    resetData();
  };

  const updateData = (list = [], tempRenderArray = []) => {
    setRenderArray(tempRenderArray?.length > 0 ? tempRenderArray : list);
    setTagList(list);
    searchBoxRef && searchBoxRef.current && searchBoxRef.current.focus();
  };

  const updateRemovedTag = removedItem => {
    setClearSearchInput(true);
    const updatedTagList = Utils.createObjWithoutRef(tagList);
    updatedTagList?.length > 0 &&
      updatedTagList.map(
        item => item?.value === removedItem && (item.checked = false)
      );
    updatedTagList && updateData(updatedTagList);
  };

  const updateTagCheckbox = (value, id, name) => {
    const newArr = Utils.createObjWithoutRef(tagList);
    const tempRenderArray = Utils.createObjWithoutRef(renderArray);
    if (newArr?.length > 0) {
      newArr.map(item => item.id === id && (item.checked = value));
    }
    if (tempRenderArray?.length > 0) {
      tempRenderArray.map(item => item?.id === id && (item.checked = value));
    }
    newArr?.length > 0 && updateData(newArr, tempRenderArray);
    if (value === true) {
      bulkRemoveTagsPopupState.setFilteredData([
        ...bulkRemoveTagsPopupState?.filteredData,
        name
      ]);
    } else {
      updateRemovedTag(name);
      const updatedFilteredData =
        bulkRemoveTagsPopupState?.filteredData?.length > 0 &&
        bulkRemoveTagsPopupState?.filteredData?.filter(
          filterData => filterData !== name
        );
      bulkRemoveTagsPopupState.setFilteredData(updatedFilteredData);
    }
  };

  const onChangeSearchBox = inputValue => {
    setShowTagList(true);
    const lowercasedValue = inputValue?.toLowerCase();
    setClearSearchInput(false);
    if (lowercasedValue && tagList?.length) {
      const filteredData = tagList?.filter(item => {
        return item["value"]
          ?.toString()
          .toLowerCase()
          .includes(lowercasedValue);
      });
      setRenderArray(filteredData);
    }
  };

  const getFilteredData = resData => {
    if (bulkRemoveTagsPopupState?.filteredData?.length > 0) {
      resData
        .map(item => {
          item.checked = bulkRemoveTagsPopupState?.filteredData?.includes(
            item.value
          );
          return item;
        })
        .filter(Boolean);
    }
    return resData;
  };

  const handleUpdateRemoveTags = () => {
    const checkedTagId = tagList
      .map(item => item?.checked && item?.id)
      .filter(Boolean);
    bulkRemoveTagsPopupState.setButtonLoader(true);
    bulkRemoveTagsPopupState.defaultValues.cbk(checkedTagId, resetData);
  };

  const HeaderChildren = () => {
    const contactsCount =
      bulkRemoveTagsPopupState?.defaultValues?.contactsCount;
    return (
      <div className="commonPopupHeader">
        <div className="flexRow">
          <h4 className="commonPopupHeading">Remove Tags</h4>
          {contactsCount > 0 && (
            <div className="contactsCount">{`${contactsCount} ${
              contactsCount > 1 ? "contacts" : "contact"
            } selected`}</div>
          )}
        </div>
        <i
          className="material-icons commonPopupCloseIcon"
          onClick={closeIconCbk}
        >
          close
        </i>
      </div>
    );
  };

  const resetData = () => {
    bulkRemoveTagsPopupState.setFilteredData([]);
    const updatedTagList = Utils.createObjWithoutRef(tagList);
    updatedTagList?.length > 0 &&
      updatedTagList.map(item => (item.checked = false));
    updateData(updatedTagList);
  };

  useEffect(() => {
    const data = Utils.createObjWithoutRef(tagsStoreData?.tagsData);
    const response = getFilteredData(data);
    response && updateData(response);
  }, [tagsStoreData.tagsData]);

  useEffect(() => {
    return () => {
      resetData();
    };
  }, []);

  return (
    bulkRemoveTagsPopupState?.showPopup && (
      <CommonPopup
        className="bulkAddTagsPopup"
        headerTitle="Remove Tags"
        closeIconCbk={closeIconCbk}
        hasHeaderChildren={true}
        HeaderChildren={HeaderChildren}
      >
        <div className="descriptionMsg">
          Please select/create tags that you would like to add to{" "}
          <span
            className={`${
              bulkRemoveTagsPopupState?.defaultValues?.contactsCount === 1
                ? "contactName"
                : ""
            }`}
          >
            {bulkRemoveTagsPopupState?.defaultValues?.contactsCount === 1
              ? bulkRemoveTagsPopupState?.defaultValues?.name
              : "to the selected contacts."}
          </span>
        </div>

        <div className="inputContainer">
          <span className="tagTitle">Enter your tags name</span>
          <div className="searchFilterWrapper">
            <SearchInputBox
              valueCbk={onChangeSearchBox}
              type="text"
              hasSearchIcon={false}
              hasCloseIcon={false}
              className="inputFilterSearch"
              clearSearchInput={clearSearchInput}
              searchBoxRef={searchBoxRef}
              setShowTagList={setShowTagList}
              placeholder=""
              needInitialFocus={false}
            />
            {showTagList && renderArray?.length > 0 && (
              <div className={`tagList`}>
                {renderArray.map((item, index) => {
                  const { id = "", value = "", checked = false } = item || {};
                  return (
                    <div className="tagItem" key={id + index}>
                      <CheckBoxComponent
                        key={id}
                        name={
                          value ? value : Utils.getFullNameFromUserInfo(item)
                        }
                        id={id}
                        checked={checked}
                        cbk={updateTagCheckbox}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {bulkRemoveTagsPopupState?.filteredData?.length > 0 && (
          <div className="title">Tags</div>
        )}
        <SelectedTagList
          selectedList={bulkRemoveTagsPopupState?.filteredData}
          store={bulkRemoveTagsPopupState}
          updateRemovedTag={updateRemovedTag}
        />
        <Button
          type="submit"
          btnText="Remove tags"
          className={`${
            bulkRemoveTagsPopupState?.filteredData?.length ? "" : "disabled"
          } positive-btn  updateButton ${
            bulkRemoveTagsPopupState?.buttonLoader ? "loading" : ""
          }`}
          click={
            bulkRemoveTagsPopupState?.filteredData?.length
              ? handleUpdateRemoveTags
              : () => {}
          }
          loader={bulkRemoveTagsPopupState?.buttonLoader}
        />
        {showTagList && (
          <div
            className="transparentOverlay"
            onClick={e => setShowTagList(false)}
          ></div>
        )}
      </CommonPopup>
    )
  );
});

export { BulkRemoveTagsPopup, bulkRemoveTagsPopupState };
export default BulkRemoveTagsPopup;
