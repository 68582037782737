import React from "react";

const TeamTableLoader = props => {
  const { count = 5, value = 0 } = props || {};

  const columnList = ["teamName", "members", "updatedOn", "createdOn", "owner"];

  return Array.from({ length: count }, (item, index) => (
    <div className="tableRowLoader" key={`${value}${index}`}>
      {columnList.map(item => (
        <div className={item} key={item}>
          <span className="linear-background" />
        </div>
      ))}
      <div className="moreIcons">
        <div className="editIcon">
          <span className="linear-background" />
        </div>
        <div className="deleteIcon">
          <span className="linear-background" />
        </div>
      </div>
    </div>
  ));
};

export default TeamTableLoader;
