import React from "react";
import UpgradeBadge from "./UpgradeBadge";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

export const UpgradeSequenceCard = props => {
  const { plan = "", badgeSize = "", type = "", text = "" } = props || {};
  const { planName = "", inFreePlan = false } =
    userDetail?.userFeatureFlag || {};

  const redirectToPricing = () => {
    Utils.mixpanelTrack(MP_EVENT.UPGRADE_SEQUENCE_CARD_CLICKED, {
      type: "card",
      pageType: "sequences",
      trigger: "Sequence card "
    });

    PaywallUtils.upgradeUtilsFunction(
      "newSequenceCard",
      false,
      planName,
      inFreePlan
    );
  };

  return (
    <div className="upgradeSequenceCard">
      <div className="card" onClick={redirectToPricing}>
        <UpgradeBadge plan={plan} badgeSize={badgeSize} />
        <span className="descText">Upgrade to {text}!</span>
      </div>
      <span className="upgradeBgOverlay" />
    </div>
  );
};

export default UpgradeSequenceCard;
