import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import ContactInfo from "./DialerContactInfo";
import DialerLogsAfterCallEnd from "./DialerLogsAfterCallEnd";
import globalCallState from "Stores/GlobalCallState";

export const defaultDialerLogSettings = {
  callSid: "",
  contactName: "",
  phoneNumber: "",
  isContactHasNo: false
};

class DialerLogsState {
  @observable showLogCallPopup = false;
  @observable contactData = {};
  @observable sequenceInfo = {};
  @observable dialerNumber = "";
  @observable dialerLogSettings = { ...defaultDialerLogSettings };
  @observable showEditCallLogs = false;
  @observable actionType = false;
  @observable totalContacts = 0;

  @action
  setShowLogCallPopup = (value = false) => {
    this.showLogCallPopup = value;
    if (!value) {
      this.actionType = "";
      this.contactData = {};
      this.sequenceInfo = {};
      this.dialerNumber = "";
      this.setDialerLogSettings({ ...defaultDialerLogSettings });
      this.showEditCallLogs = false;
      this.totalContacts = 0;
    }
  };

  @action
  setActionType = (value = "") => {
    this.actionType = value;
  };

  @action
  setContactData = (value = {}) => {
    this.contactData = value;
  };

  @action
  setSequenceInfo = (value = {}) => {
    this.sequenceInfo = value;
  };

  @action
  setDialerNumber = (value = "") => {
    this.dialerNumber = value;
  };

  @action
  setDialerLogSettings = (obj = {}) => {
    this.dialerLogSettings = { ...this.dialerLogSettings, ...obj };
  };

  @action
  setShowEditCallLogs(value = false) {
    this.showEditCallLogs = value;
  }

  @action
  setTotalContacts = (count = 0) => {
    this.totalContacts = count;
  };
}

const dialerLogsPopupState = new DialerLogsState();

const DialerLogsAfterCallEndPopup = observer((props = {}) => {
  const { additionalInfo = {} } = props || {};

  const { task: { order = 0, sequenceName = "" } = {} } = additionalInfo || {};

  const getSequencePhoneCallTask = (sequenceName, stageNumber) => {
    return (
      <div className="sequenceInfo">
        <span className="phoneCallStage">Step #{stageNumber}&nbsp;</span>
        {sequenceName && (
          <>
            <span className="of">of&nbsp;</span>
            <span className="phoneCallSequence">{sequenceName}</span>
          </>
        )}
      </div>
    );
  };

  const closePopup = () => {
    dialerLogsPopupState.setShowLogCallPopup(false);
    globalCallState.resetPopupValues();
  };

  return dialerLogsPopupState?.showLogCallPopup ? (
    <>
      <div className="dsCallLogsPopup">
        <div className="upgradeSettingsCard animated customzoomIn">
          <div className="headingWrapper">
            <span>Log call</span>
            <i className="material-icons closeIcon" onClick={closePopup}>
              close
            </i>
          </div>
          {(order &&
            sequenceName &&
            !dialerLogsPopupState?.actionType &&
            getSequencePhoneCallTask(sequenceName, order)) ||
            ""}
          {dialerLogsPopupState?.contactData?.id && (
            <ContactInfo contact={dialerLogsPopupState.contactData} />
          )}
          {dialerLogsPopupState?.totalContacts > 1 && (
            <div className="selectedContactsText">
              {dialerLogsPopupState?.totalContacts} contact
              {dialerLogsPopupState?.totalContacts > 1 ? "s" : ""} selected
            </div>
          )}
          <DialerLogsAfterCallEnd
            {...props}
            callSid={dialerLogsPopupState.dialerLogSettings.callSid}
          />
        </div>
      </div>
      <div className="dsCallLogsOverlay" />
    </>
  ) : (
    ""
  );
});

export { DialerLogsAfterCallEndPopup, dialerLogsPopupState };
export default DialerLogsAfterCallEndPopup;
