import React from "react";
import { observer } from "mobx-react";

const SequenceTableLoading = props => {
  return (
    <>
      <div className="sectionInnerCont">
        {/* <div className="sectionTitle headerStyle">
        <span className="txt-loading linear-background"></span>
      </div> */}
        <div className="tableCont">
          <div className="table" table-layout="fixed">
            <div className="tableHader seqTableLoader">
              {getHeaderLoadingRows()}
            </div>
            <div className="tableBody">{getLoadingRows(15)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const getLoadingRows = arg => {
  return (
    <div className="overflowWrapper">
      {Array.from({ length: arg }, (value, index) => (
        <div
          className="sequenceWrapper seqTableLoader"
          key={`sequenceWrapper${index}`}
        >
          <div className="toogleSequence">
            <div className="toogleButton">
              <input type="checkbox" className="toggleCheckbox" />
              <div className="layer"></div>
            </div>
          </div>
          <div className="dataRow sequenceDataRow">
            <div className="dataColumn sequenceDataColumn name">
              <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
            </div>
            <div className="dataColumn sequenceDataColumn contacts">
              <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
            </div>
            <div className="dataColumn sequenceDataColumn openRate percentage ">
              <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
            </div>
            <div className="dataColumn sequenceDataColumn clickRate percentage">
              <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
            </div>
            <div className="dataColumn sequenceDataColumn replyRate percentage">
              <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
            </div>
            <div className="dataColumn sequenceDataColumn updateTs">
              <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
            </div>
            <div className="column iconCont">
              <span className="linear-background iconLoader"></span>
              <span className="linear-background iconLoader"></span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const getHeaderLoadingRows = () => {
  return (
    <>
      <div className="column toggleBtnHeader">
        <div className="linear-background iconLoader"></div>
      </div>
      <div className="sequenceHeaderWrapper">
        <div className="column name">
          <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
        </div>
        <div className="column contacts">
          <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
        </div>
        <div className="column openRate">
          <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
        </div>
        <div className="column clickRate">
          <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
        </div>
        <div className="column replyRate">
          <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
        </div>
        <div className="column updateTs">
          <span className="d-block h-12p b-r-20 w-100 linear-background"></span>
        </div>
        <div className="column iconCont"></div>
      </div>
    </>
  );
};

export default observer(SequenceTableLoading);
export { SequenceTableLoading };
