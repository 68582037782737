/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import DialerServiceData from "Stores/DialerServiceStore";
import userDetail from "Stores/userDetail";
import DialerCallNotes from "./DialerCallNotes";
import { ReactComponent as NotesIcon } from "Assets/svg/notes.svg";
import { ReactComponent as DialerIcon } from "Assets/svg/dialer.svg";
import { ReactComponent as RecordIcon } from "Assets/svg/record.svg";
import { ReactComponent as PasueIcon } from "Assets/svg/pause.svg";
import { ReactComponent as PhoneIcon } from "Assets/svg/call.svg";

const DialerButton = (props = {}) => {
  const {
    cbk = () => {},
    activeConnection = "",
    isRecordingActivated = false,
    setRecordingActivated = () => {}
  } = props || {};
  const { recordingEnabled = false } = userDetail.userFeatureFlag || {};

  const [option, setOption] = useState({ ...DialerServiceData?.dialerOption });
  const [showKeypad, setKeypad] = useState(false);

  const toggleCallNotes = (value = false) => {
    DialerServiceData.setDialerOption({ ...option, notes: value });
  };

  const showRecordIcon = () => {
    if (option?.call && activeConnection) {
      if (!isRecordingActivated && !option?.record) {
        return <RecordIcon />;
      } else {
        return option?.record ? <PasueIcon /> : <RecordIcon />;
      }
    }
    return <RecordIcon />;
  };

  const showRecordClassName = () => {
    if (option?.call && activeConnection) {
      if (!option?.record && !isRecordingActivated) {
        return recordingEnabled ? "recordingDisabled" : "startRecording";
      } else {
        return option?.record ? "pauseRecording" : "resumeRecording";
      }
    }
    return "recordingDisabled";
  };

  const recordingCbk = () => {
    if (option?.call && activeConnection) {
      let value = "";
      if (!option?.record && !isRecordingActivated) {
        value = "startRecording";
      } else {
        value = option?.record ? "pauseRecording" : "resumeRecording";
      }
      value && cbk(value);
    }
  };

  const endCall = () => {
    const status =
      DialerServiceData?.dialerCallStatus?.toLowerCase() === "connected"
        ? "ended"
        : "disconnected";
    DialerServiceData.setDialerCallStatus(status);
    setTimeout(() => {
      setRecordingActivated(false);
      cbk("endCall");
    }, 1000);
  };

  const toggleDialPad = () => {
    DialerServiceData.setDialerIvrKeypad(!showKeypad);
    setKeypad(!showKeypad);
  };

  useEffect(() => {
    setOption({ ...option, ...DialerServiceData?.dialerOption });
  }, [DialerServiceData.dialerOption]);

  return (
    <div className="dialerBtnSection">
      <div
        className={`mike ${option?.call ? "enabled" : ""} ${
          option.mute ? "active" : ""
        }`}
        onClick={() => option?.call && cbk(option?.mute ? "unmute" : "mute")}
      >
        <span className="material-icons">
          {option?.mute ? "mic_off" : "mic"}
        </span>
      </div>
      <div
        className={`recordIcon ${showRecordClassName()}`}
        onClick={recordingCbk}
      >
        {showRecordIcon()}
      </div>
      <div
        className={option?.call ? "endCall" : "callUser"}
        onClick={() => (option?.call ? endCall() : cbk("call"))}
      >
        <PhoneIcon />
      </div>
      <div
        className={`dialMenu ${option?.call ? "" : "dialPadDisabled"} ${
          showKeypad || option?.keypad ? "keypadEnabled" : ""
        }`}
        onClick={() => toggleDialPad()}
      >
        <DialerIcon />
      </div>
      <div
        className={`notes ${option?.call ? "enabled" : ""} ${
          option?.notes ? "active" : ""
        }`}
        onClick={() => option?.call && toggleCallNotes(!option?.notes)}
      >
        <NotesIcon />
      </div>
      {option?.notes && option?.call && (
        <DialerCallNotes cbk={toggleCallNotes} />
      )}
    </div>
  );
};

export default observer(DialerButton);
export { DialerButton };
