import React from "react";
import ContactInputTag from "./ContactInputTag";
import Utils from "Utils/utils";

const SocialLinks = props => {
  const {
    inputVal = {},
    changeInputVal = () => {},
    tokenList = []
  } = props || {};

  return (
    <div className="info">
      <h5 className="sectionTitle">Social Links</h5>
      <div className="sectionInputTagsCont">
        <div className="rowBreaker">
          <ContactInputTag
            name="website"
            id="website"
            value={inputVal?.website || ""}
            text="Website"
            onBlurEvent={changeInputVal}
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "website",
              tokenList
            )}
          />
          <ContactInputTag
            name="linkedinId"
            id="linkedinId"
            onBlurEvent={changeInputVal}
            value={inputVal?.linkedinId || ""}
            text="LinkedIn URL"
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "linkedin",
              tokenList
            )}
          />
          <ContactInputTag
            name="facebook"
            value={inputVal?.facebook || ""}
            type="text"
            id="facebook"
            text="Facebook"
            onBlurEvent={changeInputVal}
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "facebook",
              tokenList
            )}
          />
          <ContactInputTag
            name="twitter"
            id="twitter"
            value={inputVal?.twitter || ""}
            text="Twitter"
            onBlurEvent={changeInputVal}
            required={Utils.findIsRequiredFieldForSingleContactSequence(
              "twitter",
              tokenList
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;
export { SocialLinks };
