import React from "react";
import VendisysImg from "Assets/clients/vendisys.png";
import AdaptImg from "Assets/clients/adapt.png";
import BlaxtairImg from "Assets/clients/blaxtair.png";
import LambdatestImg from "Assets/clients/lambdatest.png";

const LeftContent = (props = {}) => {
  const { isPlugin = false, isDealify = false } = props || {};
  const keylist = [
    "Reach prospects on the right channel",
    "Increase productivity with automated playbooks",
    "Convert inbound signups into meetings faster"
  ];

  const heading = "Hit your target with Salesgear";
  const dealifyHeading = "Claim your Salesgear Lifetime Deal";
  const pluginHeading =
    "Know when someone opens your email or clicks on a link";
  const pluginKeyList = [
    "Get real-time notifications for email opens and clicks",
    "Integrates with multiple Gmail inboxes",
    "Log your email activity to Salesforce"
  ];

  const title = isPlugin ? pluginHeading : isDealify ? dealifyHeading : heading;
  const bulletPoints = isPlugin ? pluginKeyList : keylist;
  const clientImg = [
    {
      name: "Lambdatest",
      imgSrc: LambdatestImg
    },
    {
      name: "Vendisys",
      imgSrc: VendisysImg
    },
    {
      name: "Adapt",
      imgSrc: AdaptImg
    },
    {
      name: "Blaxtair",
      imgSrc: BlaxtairImg
    }
  ];
  return (
    <div className="leftRegisterContent">
      <hr className="horizontal-line sm-width line-left" />
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <ul className="keylist">
        {bulletPoints.map((item, index) => (
          <li key={`keyList${index}`}>{item}</li>
        ))}
      </ul>
      <section className="clientSection">
        <hr className="horizontal-line sm-width" />
        <h4 className="text-center">
          High performing sales teams from fast growing companies trust us
        </h4>
        <div className="clientsLogo">
          {clientImg.map(item => {
            const { name = "", imgSrc = "" } = item || {};
            return <img src={imgSrc} alt={name} key={name} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default LeftContent;
