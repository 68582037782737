import { action, observable } from "mobx";

class dashboardState {
  @observable memberId = "";

  @action
  setMemberId = value => {
    this.memberId = value;
  };
}

const DashboardState = new dashboardState();

export { DashboardState };
export default DashboardState;
