import { observer } from "mobx-react";
import React from "react";
import RadioButton from "Components/common/radioBtn";
import ConstantObj from "Static/constant";

const sharedReadOnly = ConstantObj.sequenceType.shared.ro;
const sharedReadWrite = ConstantObj.sequenceType.shared.rw;
const privateSeq = ConstantObj.sequenceType.private;
const sharedSeq = ConstantObj.sequenceType.shared.type;

const ChangeShareType = observer((props = {}) => {
  const {
    sequenceAccessType,
    seqName,
    seqNameFocus,
    seqId,
    seqNameCancelAction,
    sequenceNameAction,
    showCreateSeqLoader,
    save,
    radioOnchangeAction,
    sequenceShareType,
    placeHolder,
    isClone = false
  } = props || {};

  return (
    <>
      <form
        className={
          "nameInputcont " +
          (showCreateSeqLoader ? "linear-background" : "animated zoomIn") +
          (seqName && seqName.length ? " filled" : "")
        }
        autoComplete="off"
        onSubmit={e => e.preventDefault()}
      >
        <div className="nameInputInnercont">
          <div className="inputWrapper">
            <input
              type="text"
              name="saveSeqInput"
              autoComplete="off"
              placeholder={placeHolder}
              id="newseqName"
              autoFocus
              onFocus={e => seqNameFocus(e)}
              defaultValue={seqName}
              onChange={e => sequenceNameAction(e)}
              className="nameInputBox"
            />
          </div>
          <RenderSequenceType
            sequenceAccessType={sequenceAccessType}
            radioOnchangeAction={radioOnchangeAction}
            sequenceShareType={sequenceShareType}
            isClone={isClone}
          />
          {!props.showCreateSeqLoader && (
            <div className="cancelWrapper">
              <div
                className="sequenceActionBtn save btn"
                onClick={e => save(e, seqId, seqName)}
              >
                Save
              </div>
              <div
                className="sequenceActionBtn cancelBtn"
                onClick={e => seqNameCancelAction(e, seqId)}
              >
                Cancel
              </div>
            </div>
          )}
        </div>
      </form>
      <div className="dropdownMask" />
    </>
  );
});

const RenderSequenceType = (props = {}) => {
  const {
    sequenceAccessType = "",
    sequenceShareType = "",
    isClone = false,
    radioOnchangeAction = () => {}
  } = props || {};

  const isReadWriteAccess = () => {
    return (
      sequenceAccessType === sharedReadWrite && sequenceShareType === sharedSeq
    );
  };

  const isReadOnlyAccess = () => {
    return sequenceAccessType === sharedReadOnly;
  };

  const isPrivateAccess = () => {
    return (
      sequenceAccessType === sharedReadWrite && sequenceShareType === privateSeq
    );
  };

  return (
    <div className="sequenceTypeCont">
      {(isClone || sequenceShareType !== sharedSeq) && (
        <RadioButton
          name="createSequence"
          id={ConstantObj.sequenceType.private}
          value="Private"
          defaultChecked={isPrivateAccess()}
          radioOnchange={radioOnchangeAction}
        />
      )}
      <RadioButton
        name="createSequence"
        id={ConstantObj.sequenceType.shared.ro}
        value="Shared Read Only"
        defaultChecked={isReadOnlyAccess()}
        radioOnchange={radioOnchangeAction}
      />
      <RadioButton
        name="createSequence"
        id={ConstantObj.sequenceType.shared.rw}
        value="Shared Read Write"
        defaultChecked={isReadWriteAccess()}
        radioOnchange={radioOnchangeAction}
      />
    </div>
  );
};

export default ChangeShareType;
export { ChangeShareType };
