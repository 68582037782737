const ArrayUtils = {};

ArrayUtils.removeBulkValueFromArray = (actualArr = [], removeArr = []) => {
  if (actualArr?.length && removeArr?.length) {
    return actualArr.filter(item => !removeArr.includes(item)) || [];
  }
  return actualArr;
};

ArrayUtils.isUniqueArray = (list = [], value = "") => {
  if (list?.length > 0 && value?.trim()?.length > 0) {
    return !list?.includes(value);
  }
};

ArrayUtils.isArrayOfStringsEqual = (arr1 = [], arr2 = []) => {
  arr1.sort();
  arr2.sort();
  // Convert arrays to strings and compare
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

export default ArrayUtils;
export { ArrayUtils };
