import React, { createContext } from "react";
import { HeaderSection } from "./HeaderSection";
import LiveFeedUtils from "../utils";

const LiveFeedContext = createContext({});

const LiveFeed = props => {
  const { responseItem = {}, responseIndex = 0 } = props || {};
  const { date = "", result = [] } = responseItem || {};

  const getDateAsString = (date = "", length = "short") => {
    return `${date?.toLocaleString("default", {
      month: length
    })} ${date?.getDate()}, ${date?.getUTCFullYear()}`;
  };

  const getDate = inputDate => {
    const diffDays = LiveFeedUtils.getDateDifferenceFromCurrentDate(inputDate);
    const date = new Date(inputDate);
    const dateAsStringInShort = getDateAsString(date);
    if (diffDays === -1) {
      return `Yesterday - ${dateAsStringInShort} `;
    } else if (diffDays === 0) {
      return `Today - ${dateAsStringInShort}`;
    } else {
      return getDateAsString(date, "long");
    }
  };

  return (
    <div className="singleFeed">
      <div className="date">{getDate(date)}</div>
      {result?.length > 0 &&
        result?.map((item, index) => (
          <LiveFeedContext.Provider
            key={`${responseIndex}-${index}`}
            value={{
              ...item,
              index: `${responseIndex}-${index}`
            }}
          >
            <div className="liveFeed">
              <HeaderSection />
            </div>
          </LiveFeedContext.Provider>
        ))}
    </div>
  );
};

export { LiveFeed, LiveFeedContext };
export default LiveFeed;
