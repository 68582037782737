import React from "react";

const FolderLoader = props => {
  const noOfLoader = props.loadingNumber ? props.loadingNumber : 1;
  return Array.from({ length: noOfLoader }, (value, index) => (
    <div className="newFolderLoader" key={"folderLoader" + index}>
      <span className="folderIcon linear-background"></span>
      <div className="folderName linear-background"></div>
    </div>
  ));
};

export default FolderLoader;
export { FolderLoader };
