import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import googleAdminIcon from "Assets/png/googleAdmin.png";
import Utils from "Utils/utils";
import Button from "Components/common/Button";

class ComingSoonPopupState {
  @observable showPopup = false;
  @observable popupDetails = {};

  @action
  setPopupDetails = value => {
    this.popupDetails = value;
  };

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };
}

const comingSoonPopupState = new ComingSoonPopupState();

const ComingSoonPopup = () => {
  const { descHtml } = comingSoonPopupState.popupDetails;

  const btnAction = e => {
    Utils.preventDefaultFn(e);
    comingSoonPopupState.setShowPopup(false);
  };

  return comingSoonPopupState.showPopup ? (
    <>
      <div className="comingSoonFeaturePopup">
        <div className="card animated customzoomIn">
          <div className="cardBody">
            <div className="comingSoonImg">
              <img src={googleAdminIcon} alt="Coming Soon" />
            </div>
            <h4>Coming Soon!</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: descHtml || ""
              }}
            ></p>
            <Button
              id="comingSoonBtn"
              name="comingSoonBtn"
              btnText="Ok"
              icon={false}
              iconName=""
              type="button"
              className="comingSoonBtn"
              loader={false}
              click={e => btnAction(e)}
            />
          </div>
        </div>
      </div>
      <div className="overlayComingSoonBg"></div>
    </>
  ) : (
    ""
  );
};

export { ComingSoonPopup, comingSoonPopupState };
export default observer(ComingSoonPopup);
