/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import {
  CAPTCHA_INIT_PAGES,
  CONNECTED_INBOX_TAB_NAVIGATION
} from "Static/constant";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import InboxUtils from "Utils/InboxUtils";
import DateUtils from "Utils/DateUtils";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { MP_EVENT, REQUEST_DEMO_CALENDAR_LINK } from "Static/constant";
import { captchaStoreValue } from "Stores/captcha";
import Header from "./Header";
import EmptyInbox from "./EmptyInbox";
import ConnectSmtpImap from "./ConnectSmtpImap";
import ConnectInboxFailurePopup from "Components/common/ConnectInboxFailurePopup";
import userDetail from "Stores/userDetail";
import Configure from "./Configure/Configure";
import FilterByDateTab from "Components/common/FilterByDateTab";
import PaginationComponent from "Components/common/PaginationComponent";
import PageLimit from "Components/common/PageLimit";
import TabNavigation from "../Crm/common/TabNavigation";
import EmailWarming from "./EmailWarming";
import ConnectedInbox from "./ConnectedInbox";
import connectedInboxStore from "./store";
import { connectedEmailUtils } from "../ConnectedAccounts/ConnectedEmailUtils";

const defaultConfigureData = {
  maximumEmailsPerDay: 100,
  minimumDelayBtwEmails: 120,
  signature: ""
};

const Inbox = observer((props = {}) => {
  const {
    showEmailConfigure: showConfigure = false,
    setShowEmailConfigure: setShowConfigure = () => {},
    showSmtpImapConnect = false,
    setSmtpImapConnect = () => {}
  } = props || {};
  let location = useLocation();
  const { noOfInboxesUsed = 0 } = userDetail?.userFeatureFlag || {};

  const [connectInboxFail, setConnectInboxFail] = useState(false);
  const [configureData, setConfigureData] = useState(defaultConfigureData);
  const [activeTab, setActiveTab] = useState("settings");
  const [activeDateFilter, setActiveDateFilter] = useState("month");
  const [dateObj, setDateObj] = useState(DateUtils.getMonthTimeStamp());
  const [chartDateObj, setChartDateObj] = useState(
    DateUtils.getMonthTimeStamp()
  );

  const deleteUrlObject = {
    gmail_oath: URLS.revokeGmail,
    outlook_oauth: URLS.revokeOutlook,
    smtp: URLS.revokeSmtp
  };

  const addLoaderToDeletingAccount = (index = "", value = false) => {
    if (index !== "") {
      const tempData = [...connectedInboxStore?.accountData];
      tempData[index].loader = value;
      connectedInboxStore.setAccountData(tempData);
    }
    return true;
  };

  const deleteConfirmPrompt = (data = {}, index = "") => {
    const { type = "", id = "" } = data || {};
    if (type && id && index !== "") {
      confirmationPopupState.setPopupValues({
        title: "Verify Account",
        content:
          "Are you sure you want to remove this inbox ?<br/>All the scheduled emails from this inbox will be suspended.",
        actionBtnText: "Proceed",
        callback: () => deleteAccount(deleteUrlObject[type], id, index),
        loadingMsg: "Deleting account. Please wait..."
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
  };

  const deleteAccount = async (accountTypeUrl, inboxId, index) => {
    try {
      addLoaderToDeletingAccount(index, true);
      let url = `${accountTypeUrl}?inboxId=${inboxId}`;
      const config = {
        url,
        method: "DELETE"
      };
      let response = await makeApi(config);
      if (response?.data?.responseCode === 200) {
        if (
          connectedInboxStore.accountData?.length <= 1 &&
          connectedInboxStore?.pageNo > 1
        ) {
          connectedInboxStore.setPageNo(connectedInboxStore?.pageNo - 1);
        }
        await connectedInboxStore.getAccountDetails();
        Utils.getOnboardingData();
        userDetail.setUserFeatureFlag({
          ...userDetail.userFeatureFlag,
          noOfInboxesUsed: noOfInboxesUsed - 1
        });
        confirmationPopupState.setShowPopup(false);
        connectedInboxStore.accountData?.length <= 1 &&
          InboxUtils.showConnectInboxBanner();
        toasterState.setToastMsg(
          "Inbox has been deleted successfully.",
          "success"
        );
      } else {
        addLoaderToDeletingAccount(index, false);
        confirmationPopupState.setShowPopup(false);
        toasterState.setToastMsg(
          "Failed. Unable to delete the selected inbox. Please contact support.",
          "failure"
        );
      }
    } catch (err) {
      console.error("Delete account error ", err);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const captchaInitialize = () => {
    captchaStoreValue.setCaptcha(
      CAPTCHA_INIT_PAGES.includes(location?.pathname)
    );
  };

  const calenderMeeting = () => {
    Utils.mixpanelTrack(MP_EVENT.ST_INBOX_FAIL_CLICK, {
      pageType: "Settings"
    });
    setConnectInboxFail(false);
    window.open(REQUEST_DEMO_CALENDAR_LINK, "_blank", "noopener");
  };

  const closeConnectionFail = () => {
    Utils.mixpanelTrack(MP_EVENT.ST_INBOX_FAIL_CLOSE, {
      pageType: "Settings"
    });
    setConnectInboxFail(false);
  };

  const reloadInboxAction = (allowMxpEventEntry = false) => {
    setSmtpImapConnect(false);
    connectedInboxStore.setLoading(true);
    connectedInboxStore.getAccountDetails(allowMxpEventEntry);
  };

  const handleTabNavigation = (id = "", cbkVal = false) => {
    setActiveTab(id);
  };

  const getFilterByDate = (value = {}) => {
    setChartDateObj(value);
    const { filterFromDate = "", filterToDate = "" } = value || {};
    let fromDate = DateUtils.getTimeStamp(filterFromDate, "start") || "";
    let toDate = DateUtils.getTimeStamp(filterToDate, "end") || "";
    let dateFilter = { fromDate, toDate };
    setDateObj(dateFilter);
  };

  const paginate = (pageNoObj = {}) => {
    let pageNo = pageNoObj?.selected + 1 || 1;
    connectedInboxStore.setPageNo(pageNo);
    connectedInboxStore.getAccountDetails(false, true);
  };

  const getInboxesByLimit = limit => {
    connectedInboxStore.setPageLimit(limit);
    connectedInboxStore.getAccountDetails(false, true);
  };

  useEffect(() => {
    connectedInboxStore.getAccountDetails(
      false,
      connectedInboxStore.filterChanged
    );
  }, [
    connectedInboxStore?.warmingFilter,
    connectedInboxStore?.dateFilter,
    connectedInboxStore?.membersFilter
  ]);

  useEffect(() => {
    if (activeTab?.toLowerCase() === "settings") {
      let tempDateObj = DateUtils.getMonthTimeStamp();
      setDateObj(tempDateObj);
      setChartDateObj(tempDateObj);
    }
  }, [activeTab]);

  useEffect(() => {
    captchaInitialize();
  }, [location.pathname]);

  useEffect(() => {
    if (!showConfigure) {
      connectedInboxStore.setInboxScreenType("one");
    }
  }, [showConfigure]);

  useEffect(() => {
    connectedInboxStore.setActiveDateTab("month");
    connectedInboxStore.setInboxScreenType("one");
    return () => {
      connectedInboxStore.resetConnectedInbox();
    };
  }, []);

  return (
    <div className="connectInboxSection">
      {!showSmtpImapConnect && (
        <>
          <Header
            showConfigure={showConfigure}
            setShowConfigure={setShowConfigure}
            configureData={configureData}
          />
          {connectInboxFail && (
            <ConnectInboxFailurePopup
              meetingCbk={calenderMeeting}
              closeCbk={closeConnectionFail}
            />
          )}
          {!showConfigure && (
            <div
              className={`connectedInboxBody ${
                connectedInboxStore?.totalPages > 1 ||
                connectedInboxStore?.totalCount > 10
                  ? "adjustHeight"
                  : ""
              }${connectedInboxStore?.showSmtpViaWarming ? "enableSmtp" : ""} `}
            >
              <>
                {!connectedInboxStore?.isInboxConnected &&
                  !connectedInboxStore?.loading && (
                    <EmptyInbox
                      gmailAuthCbk={connectedEmailUtils.openGmailConnectPopup}
                      microsoftAuthCbk={connectedEmailUtils.microsoftAuth}
                      smtpImapAuthCbk={() => setSmtpImapConnect(true)}
                    />
                  )}

                <ConnectedInbox
                  configureData={configureData}
                  setConfigureData={setConfigureData}
                  setShowConfigure={setShowConfigure}
                  defaultValue={defaultConfigureData}
                  deleteCbk={deleteConfirmPrompt}
                  key="inboxListCard"
                  setActiveTab={setActiveTab}
                  isInboxConnected={connectedInboxStore?.isInboxConnected}
                />
              </>
            </div>
          )}
          {!showConfigure && !connectedInboxStore?.showSmtpViaWarming && (
            <div className="inboxPaginationAndLimit">
              <PaginationComponent
                callback={paginate}
                totalPages={connectedInboxStore?.totalPages || 1}
                currentPage={connectedInboxStore?.pageNo || 1}
                loading={connectedInboxStore?.loading}
              />
              <PageLimit
                limitPerPage={connectedInboxStore?.pageLimit}
                text="Showing"
                cbk={getInboxesByLimit}
                totalCount={connectedInboxStore?.totalCount}
              />
            </div>
          )}
          {showConfigure && (
            <div className="configurationSection">
              <div className="navigationAndDateFilter">
                <TabNavigation
                  tabList={CONNECTED_INBOX_TAB_NAVIGATION}
                  handleTabNavigation={handleTabNavigation}
                  activeTab={activeTab}
                />
                {activeTab?.toLowerCase() === "warming" &&
                  !connectedInboxStore?.showSmtpViaWarming && (
                    <FilterByDateTab
                      activeDueTab={activeDateFilter}
                      setActiveDueTab={setActiveDateFilter}
                      cbk={getFilterByDate}
                      isTaskPage={false}
                      pageType="settings"
                    />
                  )}
              </div>
              {activeTab?.toLowerCase() === "settings" && (
                <Configure
                  configureData={configureData}
                  setConfigureData={setConfigureData}
                  setShowConfigure={setShowConfigure}
                  defaultValue={defaultConfigureData}
                />
              )}
              {activeTab?.toLowerCase() === "warming" && (
                <EmailWarming
                  activeDateFilter={activeDateFilter}
                  dateObj={dateObj}
                  configureData={configureData}
                  setActiveDateFilter={setActiveDateFilter}
                  setShowConfigure={setShowConfigure}
                  chartDateObj={chartDateObj}
                />
              )}
            </div>
          )}
        </>
      )}
      {showSmtpImapConnect && (
        <ConnectSmtpImap
          setSmtpImapConnect={setSmtpImapConnect}
          reloadInbox={reloadInboxAction}
        />
      )}
    </div>
  );
});

export default withRouter(Inbox);
export { Inbox };
