import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import domainHealthCenterStore from "./store";

const NoInboxConnected = () => {
  const history = useHistory();

  return (
    <div className="noInboxConnectedWrapperDS">
      <span
        className="connectEmailAccounts"
        onClick={() => history.push("/settings/account")}
      >
        Connect email accounts
      </span>
      <span className="connectInboxContent"> to view domain health score</span>
    </div>
  );
};

const DomainHealthScore = observer((props = {}) => {
  const totalScore = domainHealthCenterStore?.domainScoreData?.length || 0;
  const list = [
    {
      label: "SPF Setup",
      key: "spfRecord"
    },
    {
      label: "DKIM Setup",
      key: "dkim"
    },
    {
      label: "DMARC Setup",
      key: "dmarcRecord"
    },
    {
      label: "MX Exchange",
      key: "mxRecord"
    }
  ];
  const [scoreLoaderColor, setScoreLoaderColor] = useState({});

  const calculateScore = () => {
    let avgScore = totalScore * list?.length;
    let avgCount = 0;
    list.map(item => {
      const { count = 0 } = item || {};
      avgCount = avgCount + count;
    });
    return `${Math.ceil((avgCount / avgScore) * 100) || 0}%`;
  };

  const calculateValue = () => {
    let spfRecord = 0,
      dmarcRecord = 0,
      mxRecord = 0,
      dkim = 0;
    if (domainHealthCenterStore?.domainScoreData?.length > 0) {
      domainHealthCenterStore.domainScoreData.map(item => {
        const { domainHealth = [] } = item || {};
        const { dnsRecords = {} } = domainHealth || {};

        if (dnsRecords?.mxRecord?.length > 0) {
          mxRecord = mxRecord + 1;
        }
        if (dnsRecords?.spfRecord?.length > 0) {
          spfRecord = spfRecord + 1;
        }
        if (dnsRecords?.dmarcRecord?.length) {
          dmarcRecord = dmarcRecord + 1;
        }
        if (dnsRecords?.dkim?.length) {
          dkim = dkim + 1;
        }
      });
    }
    list.map(item => {
      if (item?.key === "spfRecord") {
        item.count = spfRecord;
      }
      if (item?.key === "mxRecord") {
        item.count = mxRecord;
      }
      if (item?.key === "dmarcRecord") {
        item.count = dmarcRecord;
      }
      if (item?.key === "dkim") {
        item.count = dkim;
      }
      return item;
    });
  };

  calculateValue();

  useEffect(() => {
    const tempColor =
      calculateScore() === "100%"
        ? `radial-gradient(closest-side, white 79%, transparent 80% 100%),
conic-gradient(#29D866 100%, transparent 0)`
        : calculateScore() === "0%"
        ? `radial-gradient(closest-side, white 79%, transparent 80% 100%),
conic-gradient(#dddddd 100%, transparent 0)`
        : `radial-gradient(closest-side, white 79%, transparent 80% 100%),
conic-gradient(#29D866 ${calculateScore()}, #dddddd 0)`;
    const loaderColor = { background: tempColor };
    setScoreLoaderColor({ ...loaderColor });
  }, [domainHealthCenterStore?.domainScoreData]);

  return (
    <section className="domainHealthScoreWrapper">
      <p className="domainHealthHeading">Domain Health Score</p>
      <div
        className={`domainHealthList ${
          !domainHealthCenterStore?.domainScoreData?.length &&
          !domainHealthCenterStore?.domainScoreDataLoading
            ? "noGrid"
            : ""
        }`}
      >
        {domainHealthCenterStore?.domainScoreDataLoading ? (
          <DomainHealthScoreLoader />
        ) : !domainHealthCenterStore?.domainScoreData?.length ? (
          <NoInboxConnected />
        ) : domainHealthCenterStore?.domainScoreData?.length > 0 ? (
          <>
            <div>
              {list.map(item => {
                const { label = "", count = "" } = item || {};
                const className =
                  count === totalScore
                    ? "green"
                    : count === 0
                    ? "red"
                    : totalScore - 1 === count
                    ? "blue"
                    : "yellow";

                return (
                  totalScore !== 0 && (
                    <div className="domainHealth" key={label}>
                      <div className="domainHealthLabel">{label}</div>
                      <div
                        className={`domainHealthValue ${className}`}
                      >{`${count} / ${totalScore}`}</div>
                    </div>
                  )
                );
              })}
            </div>
            <div className="scoreLoader" style={scoreLoaderColor}>
              {calculateScore()}
            </div>
          </>
        ) : (
          <div className="noInboxFound">No Inboxes found</div>
        )}
      </div>
    </section>
  );
});

const DomainHealthScoreLoader = () => {
  return (
    <>
      <div className="domainHealthList loading">
        <div>
          {Array.from({ length: 4 }, (item, index) => (
            <div className="domainHealth" key={index}>
              <div className="domainHealthLabel linear-background" />
              <div className="domainHealthValue linear-background" />
            </div>
          ))}
        </div>
      </div>
      <div className="scoreLoader linear-background" />
    </>
  );
};

export { DomainHealthScore };
export default DomainHealthScore;
