import React, { useState, useEffect } from "react";
import Button from "Components/common/Button";
import DatePicker from "Pages/Dashboard/sequences/Stages/DatePicker";
import ContactsSelectedCount from "Pages/Dashboard/sequences/Stages/ContactsSelectedCount";
import { toasterState } from "Components/common/toaster";
import NotesInput from "Pages/Dashboard/sequences/Stages/NotesInput";
import Utils from "Utils/utils";

const EditBulkTasks = ({
  initialState = {},
  editTasksLoader = false,
  setEditTasksLoader = () => {},
  logState = {},
  updateLogState = () => {},
  action = () => {}
}) => {
  const {
    selectedTaskIds = [],
    showEditTaskPopup = false,
    allTasksSelected = false,
    taskDueString = false,
    notes = ""
  } = logState || {};

  const [cursorPosition, setCursorPostion] = useState(0);

  const handleBulkEditAction = () => {
    const userInputDate = new Date(taskDueString);
    const currentDate = new Date();
    if (userInputDate > currentDate) {
      setEditTasksLoader(true);
      action("EDIT_TASK_DUE_DATE");
    } else {
      toasterState.setToastMsg(
        "Please provide selected date is after the current date and time",
        "info"
      );
    }
  };

  const handleDateChange = (pickedDate = new Date()) => {
    updateLogState({
      ...logState,
      taskDueString: new Date(pickedDate),
      noteFocused: false
    });
  };

  const handleChange = (value = "", isToken = false) => {
    let tempNotes = value || "";
    if (isToken) {
      tempNotes =
        notes?.substr(0, cursorPosition) +
        value +
        notes?.substr(cursorPosition, notes?.length);
    }
    updateLogState({ ...logState, notes: tempNotes, noteFocused: true });
    isToken && Utils.focusInputBox("taskNotes");
  };

  const noteInputObj = {
    notes,
    action: handleChange,
    pageType: "tasks",
    setCursorPostion
  };

  const btnList = [
    {
      id: "closeEditBulkTasksBtn",
      name: "closeEditBulkTasksBtn",
      btnText: "Cancel",
      className: "closeEditBulkTasksBtn",
      type: "button",
      click: () => updateLogState({ ...logState, showEditTaskPopup: false })
    },
    {
      id: "editBulkTasksButton",
      name: "editBulkTasksButton",
      btnText: "Edit",
      type: "button",
      loader: editTasksLoader,
      className: `editBulkTasksButton ${
        selectedTaskIds?.length > 0 ? "" : "disabled"
      } ${editTasksLoader ? "loading" : ""}`,
      click: handleBulkEditAction
    }
  ];

  useEffect(() => {
    return () => {
      setEditTasksLoader(false);
      setCursorPostion(0);
    };
  }, []);

  return (
    showEditTaskPopup && (
      <div className="editBulkTasksPopup">
        <div className="card animated customzoomIn">
          <div className="cardHeader">
            <div className="title">
              Edit Task{selectedTaskIds?.length > 1 ? "s" : ""}
            </div>
          </div>
          <div className={`cardBody ${editTasksLoader ? "loading" : ""}`}>
            <DatePicker
              label="Due On"
              date={new Date(taskDueString)}
              handleChange={handleDateChange}
            />
            <NotesInput {...noteInputObj} />
            <ContactsSelectedCount
              count={selectedTaskIds?.length}
              type="tasks"
              allSelected={allTasksSelected}
            />
          </div>
          <div className="cardFooter">
            {btnList.map(item => (
              <Button {...item} key={item.id} />
            ))}
          </div>
        </div>
        <div className="bgOverlay" />
      </div>
    )
  );
};

export default EditBulkTasks;
