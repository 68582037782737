import React, { useState, useEffect, createRef } from "react";
import { PAST_BULK_TASKS_TYPE, PRESENT_BULK_TASKS_TYPE } from "Model/model";

const getFailureResponseMsg = (tasksCount = {}, type = "") => {
  const {
    skipCount = 0,
    manualEmailCount = 0,
    phoneCallCount = 0
  } = tasksCount || {};

  let emailText =
    manualEmailCount > 0
      ? `${manualEmailCount} email${manualEmailCount > 1 ? "s" : ""}`
      : "";
  let phoneText =
    phoneCallCount > 0
      ? `${phoneCallCount} call${phoneCallCount > 1 ? "s" : ""} ` // extra whitespace
      : "";
  let combineText = manualEmailCount > 0 && phoneCallCount > 0 ? " and " : "";
  let failedText = `${emailText}${combineText}${phoneText} tasks failed to ${PAST_BULK_TASKS_TYPE[type]}`;

  return (
    <div>
      {skipCount > 0 && (
        <span>{`Failed to ${PRESENT_BULK_TASKS_TYPE[type]} ${skipCount} task${
          skipCount > 1 ? "s" : ""
        }`}</span>
      )}
      {skipCount > 0 && <br />}
      {(manualEmailCount > 0 || phoneCallCount > 0) && (
        <span>{failedText}</span>
      )}
      {skipCount > 0 && (
        <span className="noteText">
          Note: Calls and emails cannot be {PAST_BULK_TASKS_TYPE[type]} using
          this method.
        </span>
      )}
    </div>
  );
};

const getSplittedCount = (responseCode = {}) => {
  let skipCount = 0;
  let manualEmailCount = 0;
  let phoneCallCount = 0;

  if (responseCode && Object.keys(responseCode)?.length > 0) {
    for (const property in responseCode) {
      const propertyLength = responseCode?.[property]?.length || 0;
      if ([101, "101"].includes(property)) {
        skipCount = propertyLength;
      } else if ([102, "102"].includes(property)) {
        manualEmailCount = propertyLength;
      } else if ([103, "103"].includes(property)) {
        phoneCallCount = propertyLength;
      }
    }
  }

  return {
    skipCount,
    manualEmailCount,
    phoneCallCount
  };
};

export const BulkTasksResponseMessageHandler = ({
  success = 0,
  failed = 0,
  error = {},
  type = ""
}) => {
  const showMoreTextRef = createRef(null);
  const [showMoreText, setShowMoreText] = useState(false);
  const [showMoreHeight, setShowMoreHeight] = useState(false);

  let successMsg = "";
  let failureMsg = "";

  if (success > 0) {
    successMsg = `${success} task${success > 1 ? "s" : ""} have been ${
      PAST_BULK_TASKS_TYPE[type]
    } successfully.`;
  }

  if (failed > 0) {
    const splittedCount = getSplittedCount(error);
    const responseMsg = getFailureResponseMsg(splittedCount, type);
    failureMsg = (
      <>
        <span>{`Failed to ${PRESENT_BULK_TASKS_TYPE[type]} ${failed} task${
          failed > 1 ? "s" : ""
        }. `}</span>
        {(error && Object.keys(error)?.length > 0 && (
          <>
            <span
              className="linkHighLight"
              onClick={() => setShowMoreText(!showMoreText)}
            >
              {showMoreText ? "show less" : "show more"}.
            </span>
            {showMoreText && (
              <>
                <div
                  className="leftBorder"
                  style={{ height: `${showMoreHeight}px` }}
                />
                <div
                  className="failureResponseMsg textLeft"
                  ref={showMoreTextRef}
                >
                  {responseMsg}
                </div>
              </>
            )}
          </>
        )) ||
          ""}
      </>
    );
  }

  useEffect(() => {
    setShowMoreHeight(showMoreTextRef?.current?.clientHeight);
  }, [showMoreTextRef]);

  return (
    <>
      {successMsg}
      {successMsg && <br />}
      {failureMsg}
    </>
  );
};

export default BulkTasksResponseMessageHandler;
