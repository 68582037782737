import React from "react";

const DialerDigits = props => {
  const { number, letter, cbk } = props;
  return (
    <div
      className="phoneKey"
      id={`keyboard${number}`}
      onClick={() => cbk(number)}
      onDoubleClick={() => {
        if (!number) cbk(letter);
      }}
    >
      <div className="number">{number}</div>
      <div className="letter">{letter}</div>
    </div>
  );
};

export default DialerDigits;
export { DialerDigits };
