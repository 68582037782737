import { outboxStoreData } from "Pages/Dashboard/outbox/outboxStore";
import { DateUtils } from "Utils/DateUtils";

const OutboxUtils = {};

OutboxUtils.getEmailStatus = (status = "", scheduleCode = "") => {
  const updatedStatus = status?.toLowerCase();
  if (["sent", "replied"].includes(updatedStatus)) {
    return "delivered";
  } else if (updatedStatus === "active") {
    return scheduleCode === null || scheduleCode === "200"
      ? "scheduled"
      : ["100", "101", "102", "103", "104"]?.includes(scheduleCode)
      ? "delayed"
      : "";
  } else if (updatedStatus !== "error") {
    return updatedStatus;
  }
};

OutboxUtils.getDelayedTooltip = (
  scheduleCode = "",
  expectedDate = "",
  taskDue = "",
  props = {}
) => {
  const { date: updatedExpectedDate = "", time: updatedExpectedTime = "" } =
    DateUtils.getFormattedDateAndTime(expectedDate);
  const { date: updatedTaskDue = "", time: updatedTaskDueTime = "" } =
    DateUtils.getFormattedDateAndTime(taskDue);

  const expectedDateGTM = DateUtils.getTimeZone(expectedDate);
  const updatedDateGTM = DateUtils.getTimeZone(taskDue);

  const bindEvents = props => {
    setTimeout(() => {
      document.querySelector(".outboxEmailStatusWrapper .tooltipBody") &&
        document
          .querySelector(".outboxEmailStatusWrapper .tooltipBody")
          .addEventListener("click", e => {
            e.stopPropagation();
            e.stopImmediatePropagation();
            outboxStoreData.setActiveDataInPopup("");
            return 0;
          });
      document.querySelector(
        ".outboxEmailStatusWrapper .tooltipBody #linkToSchedules"
      ) &&
        document
          .querySelector(
            ".outboxEmailStatusWrapper .tooltipBody #linkToSchedules"
          )
          .addEventListener("click", e => {
            props &&
              props.history &&
              props.history.push("/settings/emailSettings");
            outboxStoreData.setActiveDataInPopup("");
          });
    }, 0);
  };

  const tempConfig = {
    100: {
      message: `Email was initially scheduled to be sent on ${updatedExpectedDate} at ${updatedExpectedTime} (${expectedDateGTM}) but has been rescheduled to ${updatedTaskDue} at ${updatedTaskDueTime} (${updatedDateGTM}) as your email account reached the daily email sending limit. You can configure your sending limits from <span id="linkToSchedules" className="settingsLink" ${bindEvents(
        props
      )}>here</span>`,
      reason: "Daily email limit"
    },
    101: {
      message: `Email was initially scheduled to be sent on ${updatedExpectedDate} at ${updatedExpectedTime} (${expectedDateGTM}) but has been rescheduled to ${updatedTaskDue} at ${updatedTaskDueTime} (${updatedDateGTM}) as the email was scheduled outside the email delivery window.`,
      reason: "Outside delivery window"
    },
    102: {
      message: `Email was initially scheduled to be sent on ${updatedExpectedDate} at ${updatedExpectedTime} (${expectedDateGTM}) but has been rescheduled to ${updatedTaskDue} at ${updatedTaskDueTime} (${updatedDateGTM}) as the connected email account was rate limited by the email provider.`,
      reason: "Rate limited by email provider"
    },
    103: {
      message: `Email was initially scheduled to be sent on ${updatedExpectedDate} at ${updatedExpectedTime} (${expectedDateGTM}) but has been rescheduled to ${updatedTaskDue} at ${updatedTaskDueTime} (${updatedDateGTM}) due to the internal system error. Please contact <a id="mailToLink" className="uniqueSupportMailLink" ${bindEvents()} href="mailto:support@salesgear.io">support@salesgear.io</a> for more information.`,
      reason: "Internal error"
    },
    104: {
      message: `Email was initially scheduled to be sent on ${updatedExpectedDate} at ${updatedExpectedTime} (${expectedDateGTM}) but has been rescheduled to ${updatedTaskDue} at ${updatedTaskDueTime} (${updatedDateGTM}) due to unknown error. Please contact <a id="mailToLink" className="uniqueSupportMailLink" ${bindEvents()} href="mailto:support@salesgear.io">support@salesgear.io</a> for more information.`,
      reason: "Internal error"
    }
  };
  return tempConfig[scheduleCode];
};

OutboxUtils.getSequenceURL = (data = {}) => {
  const { folderId = "", id: sequenceId = "", shareType = "" } = data || {};
  return sequenceId
    ? `/sequences/${
        shareType === "SHARED" ? "shared" : folderId || "all"
      }/${sequenceId}`
    : null;
};

OutboxUtils.cancelPreviousOutboxRequest = () => {
  if (outboxStoreData.cancelOutboxCall !== null) {
    outboxStoreData.cancelOutboxCall("cancel");
    outboxStoreData.cancelOutboxCall = null;
  }
};

OutboxUtils.cancelPreviousOutboxFilterRequest = () => {
  if (outboxStoreData.cancelFilterCall !== null) {
    outboxStoreData.cancelFilterCall("cancel");
    outboxStoreData.cancelFilterCall = null;
  }
};

OutboxUtils.cancelPopupEmailContentRequest = () => {
  if (outboxStoreData.cancelFilterCall !== null) {
    outboxStoreData.cancelFilterCall("cancel");
    outboxStoreData.cancelFilterCall = null;
  }
};

export { OutboxUtils };
export default OutboxUtils;
