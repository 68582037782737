import React, { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import useDebounce from "./hooks/useDebounce";
import TabNavigation from "Pages/Dashboard/settings/Crm/common/TabNavigation";
import ContactSelectedCount from "./ContactSelectedCount";
import SearchByContactKeyword from "Pages/Dashboard/contacts/SearchByContactKeyword";
import DropDownMenu from "Components/common/DropDownMenu";
import CheckBox from "./CheckBox";
import Utils from "Utils/utils";
import SequenceUtils from "Utils/SequenceUtils";
import CustomDragAndDrop from "Components/common/CustomDragAndDrop";
import ExportBtn from "Components/common/ExportBtn";
import PaginationComponent from "Components/common/PaginationComponent";
import PageLimit from "Components/common/PageLimit";
import userDetail from "Stores/userDetail";
import DateUtils from "Utils/DateUtils";
import { toasterState } from "./toaster";

const MetricsTable = observer((props = {}) => {
  const {
    tabList = [],
    singleTabName = "",
    isLoading = false,
    pageNo = 1,
    totalPages = 0,
    tabType = "",
    dateFilter = "",
    searchText = "",
    tableData = [],
    headers = [],
    sortType = "",
    sortParam = "",
    pageLimit = 10,
    saveLoader = false,
    totalCount = 0,
    entireReportsData = [],
    setTableData = () => {},
    cbk = () => {}
  } = props || {};

  const [activeTab, setActiveTab] = useState("user");
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [dropdownOptions, setDropDownOptions] = useState([]);
  const [allDataSelectedInView, setAllDataSelectedInView] = useState(false);
  const [allCheckBoxSelected, setAllCheckBoxSelected] = useState(false);
  const [intialDropDownOptions, setInitialDropDownOptions] = useState([]);
  const debouncedValue = useDebounce(searchKeyword, 700);
  const [isEmailClose, setEmailClose] = useState(false);
  const searchInputRef = useRef(null);

  const searchContact = () => {
    if (searchKeyword !== null) {
      setSearchKeyword(debouncedValue);
    }
    if (searchKeyword?.length) {
      setEmailClose(true);
    }
    searchInputRef.current.blur();
  };

  const handleSearchAction = (event = "") => {
    Utils.preventDefaultFn(event);
    const currenrTargetVal = event?.currentTarget?.value;
    setSearchKeyword(currenrTargetVal);
    setEmailClose(!currenrTargetVal.length < 1);
    if (currenrTargetVal?.length) {
      const searchResult = [...intialDropDownOptions].filter(obj => {
        return (
          obj?.id?.toLowerCase().includes(currenrTargetVal) ||
          obj?.value?.toLowerCase().includes(currenrTargetVal)
        );
      });
      setDropDownOptions(searchResult);
    } else {
      setDropDownOptions(intialDropDownOptions);
    }
  };

  const submitForm = (e = "") => {
    Utils.preventDefault(e);
    if (e?.keyCode === 13) {
      searchContact();
    }
  };

  const clearSearch = () => {
    setSearchKeyword("");
    setSearchKeyword("");
    setEmailClose(false);
    searchInputRef && searchInputRef.current.focus();
  };

  const searchBykeywordObj = {
    isEmailClose: isEmailClose,
    placeHolder: "Search Teams",
    searchKeyword: searchKeyword,
    inputRef: searchInputRef,
    searchContact: searchContact,
    submitForm: submitForm,
    handleSearchAction: handleSearchAction,
    clearSearch: clearSearch
  };

  const handleTabNavigation = (id, cbkVal = false) => {
    setActiveTab(id);
    cbk("tabnavigation", { tabType: id });
  };

  const handleExportResponse = (downloadLink = "") => {
    if (downloadLink) {
      Utils.downloadExportedContact(downloadLink);
      toasterState.setToastMsg(
        "The report has been exported successfully",
        "success"
      );
      setAllCheckBoxSelected(false);
      setAllDataSelectedInView(false);
      setTableData([...tableData].map(item => ({ ...item, enabled: false })));
    }
  };

  const filterSelectedData = () => {
    return allDataSelectedInView
      ? entireReportsData
      : [...tableData].filter(item => item?.enabled);
  };

  const exportCbk = () => {
    if (tabType?.toLowerCase() === "sequence") {
      Utils.constructSequenceReportExportToCsvData(
        filterSelectedData(),
        "Sequence",
        handleExportResponse
      );
    } else {
      Utils.constructReportExportToCsvData(
        filterSelectedData(),
        tabType?.toLowerCase() === "user" ? "Users" : "Teams",
        handleExportResponse
      );
    }
  };

  const dragAndDropSaveCbk = async (
    dynamicHeaderArr = [],
    setShowDropDown = () => {}
  ) => {
    cbk("saveloader", { saveLoader: true });
    await cbk("dragAndDropSave", { dynamicHeaderArr });
    setShowDropDown(false);
  };

  const handleOptionsClick = ({}, item = {}) => {};

  const getDropDownOptions = () => {
    if (userDetail?.userInfo?.teamList) {
      return [...userDetail?.userInfo?.teamList].map(obj => {
        return {
          key: obj.id,
          value: Utils.getName(obj?.firstName, obj?.lastName, "", obj?.email),
          method: handleOptionsClick
        };
      });
    }
  };

  const clearSelectedAccounts = (value = false) => {
    setAllCheckBoxSelected(!value);
    setAllDataSelectedInView(!value);
    setTableData([...tableData].map(item => ({ ...item, enabled: !value })));
  };

  const getSelectedCount = useCallback(() => {
    return [...tableData].reduce(
      (count, currentItem) => (currentItem?.enabled ? count + 1 : count),
      0
    );
  }, [tableData]);

  useEffect(() => {
    setAllCheckBoxSelected(false);
    setAllDataSelectedInView(false);
  }, [pageNo, pageLimit, sortParam, sortType, isLoading]);

  useEffect(() => {
    setInitialDropDownOptions(getDropDownOptions());
  }, [userDetail?.userInfo?.teamList]);

  return (
    <div className="metricsTableSection">
      <div className="metricsHeaderCont">
        {tabList?.length > 0 ? (
          <TabNavigation
            tabList={tabList}
            handleTabNavigation={handleTabNavigation}
            activeTab={activeTab}
          />
        ) : (
          <div className="singleTabName">{singleTabName}</div>
        )}
        <div className="selectedCountCont">
          {getSelectedCount() > 0 && (
            <ContactSelectedCount
              selectedCount={getSelectedCount()}
              totalCount={totalCount}
              loading={isLoading}
              allContactSelected={allDataSelectedInView}
              setAllContactSelected={setAllDataSelectedInView}
              resetCbk={() => clearSelectedAccounts(true)}
              selectAllCbk={() => clearSelectedAccounts(false)}
              selectedType={tabType?.toLowerCase()}
            />
          )}
        </div>
        <div className="rightSideCont">
          <div className="inputSectionCont">
            {/* as of now have commented search and export  */}
            {/* <SearchByContactKeyword {...searchBykeywordObj} />
            {searchKeyword && <DropDownMenu options={dropdownOptions} />} */}
          </div>
          <CustomDragAndDrop
            dataArr={headers}
            cbk={dragAndDropSaveCbk}
            saveLoader={saveLoader}
          />
          <ExportBtn
            selectedEntityCount={getSelectedCount()}
            cbk={exportCbk}
            loading={isLoading}
            isExportEnabled={true}
            checked={getSelectedCount() > 0}
            pageType="reports"
            tooltipText={`${tabType?.toLowerCase()}s`}
          />
        </div>
      </div>
      <MetricsTableBody
        isLoading={isLoading}
        headers={headers}
        tableData={tableData}
        cbk={cbk}
        sortParam={sortParam}
        sortType={sortType}
        activeTab={tabType}
        totalPages={totalPages}
        setTableData={setTableData}
        allCheckBoxSelected={allCheckBoxSelected}
        setAllCheckBoxSelected={setAllCheckBoxSelected}
        tabType={tabType}
      />
      {totalPages > 1 && (
        <MetricsTableFooter
          isLoading={isLoading}
          pageNo={pageNo}
          pageLimit={pageLimit}
          totalPages={totalPages}
          cbk={cbk}
          totalCount={totalCount}
        />
      )}
    </div>
  );
});

const MetricsTableBody = observer((props = {}) => {
  const {
    headers = [],
    tableData = [],
    isLoading = false,
    sortParam = "",
    sortType = "",
    activeTab = "",
    cbk = () => {},
    totalPages = 0,
    setTableData = () => {},
    allCheckBoxSelected = false,
    setAllCheckBoxSelected = () => {},
    tabType = ""
  } = props || {};

  const tableContainerRef = useRef(null);

  const onTableScroll = () => {
    const tableContainer = tableContainerRef?.current;
    if (tableContainer) {
      const rows = tableContainer.querySelectorAll("tr");
      rows.forEach(row => {
        const firstColumn = row.querySelector(
          ".tabType:first-child, .userNameAndEmail:first-child"
        );
        if (firstColumn) {
          if (tableContainer?.scrollLeft > 0) {
            firstColumn.style.boxShadow = "8px 0 10px 0 rgba(0,0,0,.05)";
          } else {
            firstColumn.style.boxShadow = "none";
          }
        }
      });
    }
  };

  return (
    <div className={`metricsTableBody ${totalPages > 1 ? "" : "noPagination"}`}>
      <div
        className={`tableContainer ${totalPages > 1 ? "" : "noPagination"}`}
        ref={tableContainerRef}
        onScroll={onTableScroll}
        style={{
          height:
            tableData?.length < 10 && tableData?.length !== 0 && "fit-content"
        }}
      >
        {!isLoading ? (
          tableData?.length > 0 ? (
            <table>
              <MetricHeadersList
                headerList={headers}
                sortParam={sortParam}
                sortType={sortType}
                tableData={tableData}
                cbk={cbk}
                activeTab={activeTab}
                setTableData={setTableData}
                allCheckBoxSelected={allCheckBoxSelected}
                setAllCheckBoxSelected={setAllCheckBoxSelected}
              />
              <tbody>
                <MetricBodyList
                  tableData={tableData}
                  headers={headers}
                  setTableData={setTableData}
                  tabType={tabType}
                  setAllCheckBoxSelected={setAllCheckBoxSelected}
                />
              </tbody>
            </table>
          ) : (
            <div className="emptyTableSection">
              <div className="text">{`No ${Utils.captializeFirstCharacter(
                activeTab
              )}s Found`}</div>
            </div>
          )
        ) : (
          <TableLoader
            headerList={headers}
            sortType={sortType}
            sortParam={sortParam}
            tableData={tableData}
            activeTab={activeTab}
          />
        )}
      </div>
    </div>
  );
});

const MetricBodyList = (props = {}) => {
  const {
    tableData = [],
    headers = [],
    tabType = "",
    setTableData = () => {},
    setAllCheckBoxSelected = () => {}
  } = props || {};

  const { email: userEmail = "" } = userDetail?.userInfo || {};
  const history = useHistory();

  const headerValueMapping = (name = "", value = 0) => {
    if (
      ["totaltimeoncall", "avgcalldurationinsecs"].includes(name?.toLowerCase())
    ) {
      if (value) {
        return DateUtils.getTimeFromSeconds(value);
      } else {
        return "00:00:00";
      }
    } else if (
      ["openrate", "bouncerate", "replyrate", "clickrate"].includes(
        name?.toLowerCase()
      )
    ) {
      return value || "0";
    } else if (["type"].includes(name?.toLowerCase())) {
      return Utils.captializeFirstCharacterAnsRestLowerCase(value) || "";
    } else if (["status"].includes(name?.toLowerCase())) {
      return Utils.captializeFirstCharacterAnsRestLowerCase(value) || "Active";
    } else if (["created"].includes(name?.toLowerCase())) {
      return DateUtils.getDateMonthAndYear(value);
    } else if (["owner"].includes(name?.toLowerCase())) {
      return Utils.getOwnerInitial(value);
    } else {
      return Utils.formatNumberToStringInUSFormat(value) || "0";
    }
  };

  const handleCheckboxAction = (value = false, type = "") => {
    let tempTableData = [...tableData];
    let allEnabled = true;
    setTableData(
      tempTableData.map(item => {
        const updatedItem =
          (tabType?.toLowerCase() === "user" && item?.memberId === type) ||
          (["team", "sequence"].includes(tabType?.toLowerCase()) &&
            item?.id === type)
            ? { ...item, enabled: value }
            : item;
        if (!updatedItem?.enabled) {
          allEnabled = false;
        }
        return updatedItem;
      })
    );
    setAllCheckBoxSelected(allEnabled);
  };

  const redirectToSequence = (
    seqId = "",
    folderId = "",
    sharedType = false,
    email = ""
  ) => {
    if (userEmail === email || sharedType) {
      history.push(
        SequenceUtils.createSequenceRedirectUrl(
          seqId,
          folderId,
          sharedType,
          email
        )
      );
    }
  };

  return (
    <>
      {tableData?.length &&
        tableData.map((item, rowIndex) => {
          const {
            user = {},
            id = "",
            folderId = "",
            type = "",
            owner = {}
          } = item;
          const { firstName = "", lastName = "", email = "" } = user || {};
          let rowId =
            tabType?.toLowerCase() === "user" ? item?.memberId : item?.id;
          let sharedType = type?.toLowerCase() === "shared";
          return (
            <tr key={rowIndex}>
              <th scope="row" key={`row_${rowIndex}`}>
                <div className={`userNameAndEmail ${email ? "isEmail" : ""}`}>
                  <CheckBox
                    id={rowId}
                    key={rowId}
                    checked={item?.enabled}
                    cbk={handleCheckboxAction}
                  />
                  <div
                    className={`nameAndEmailCont ${
                      tabType?.toLowerCase() === "sequence"
                        ? "inlineDisplay"
                        : ""
                    }`}
                  >
                    <div
                      className={`userName ${
                        tabType?.toLowerCase() === "sequence"
                          ? `sequenceName ${
                              userEmail === owner?.email || sharedType
                                ? "isHighlightSeqName"
                                : ""
                            } `
                          : ""
                      }`}
                      onClick={() =>
                        redirectToSequence(
                          id,
                          folderId,
                          sharedType,
                          owner?.email
                        )
                      }
                    >
                      {Utils.getOwnerName(user) || item?.name}
                    </div>
                    {tabType?.toLowerCase() === "user" && (
                      <div className="emailCont">
                        {(firstName || lastName) && <>{email}</>}
                      </div>
                    )}
                  </div>
                </div>
              </th>
              {[...headers].map(({ enabled, name }, colIndex) => {
                return (
                  enabled && (
                    <td key={`col_${colIndex}`}>
                      {name?.toLowerCase() === "owner" ? (
                        <span className="circleOwner">
                          {headerValueMapping(name, item[name])}
                        </span>
                      ) : (
                        headerValueMapping(name, item[name])
                      )}
                    </td>
                  )
                );
              })}
            </tr>
          );
        })}
    </>
  );
};

const MetricsTableFooter = observer((props = {}) => {
  const {
    isLoading = false,
    cbk = () => {},
    pageNo = 1,
    pageLimit = 10,
    totalPages = 1,
    totalCount = 0
  } = props || {};

  const paginate = (pageObj = {}) => {
    const selectedPageNo = pageObj?.selected + 1 || 1;
    cbk("setpage", { pageNo: selectedPageNo });
  };

  const getReportsByLimit = (limit = 10) => {
    cbk("setlimit", { pageLimit: limit });
    cbk("setpage", { pageNo: 1 });
  };

  const pageLimitObj = {
    limitPerPage: pageLimit || 10,
    text: "Showing",
    totalCount: totalCount,
    cbk: getReportsByLimit
  };

  return (
    <div className="metricsTableFooter">
      <PaginationComponent
        callback={paginate}
        loading={isLoading}
        totalPages={totalPages}
        currentPage={pageNo || 1}
      />
      <PageLimit {...pageLimitObj} />
    </div>
  );
});

const MetricHeadersList = observer((props = {}) => {
  const {
    headerList = [],
    sortParam = "",
    sortType = "",
    tableData = [],
    isLoading = false,
    cbk = () => {},
    activeTab = "",
    setTableData = () => {},
    allCheckBoxSelected = false,
    setAllCheckBoxSelected = () => {}
  } = props || {};

  const sortReportData = (key = "") => {
    if (tableData?.length) {
      cbk("setpage", { pageNo: 1 });
      cbk("setlimit", { pageLimit: 10 });
      let direction =
        sortParam?.toLowerCase() === key?.toLowerCase() && sortType === "DESC"
          ? "ASC"
          : "DESC";
      cbk("setsortparam", { sortParam: key });
      cbk("setsorttype", { sortType: direction });
    }
  };

  const handleAllCheckboxAction = (value = "", type = "") => {
    setAllCheckBoxSelected(value);
    let reportTableData = [...tableData];
    setTableData(reportTableData.map(item => ({ ...item, enabled: value })));
  };

  const checkboxObj = {
    id: "all",
    key: "all",
    checked: allCheckBoxSelected,
    cbk: handleAllCheckboxAction
  };

  const tableNameObj = {
    user: "Users",
    team: "Teams",
    sequence: "Sequence Name"
  };

  return (
    <thead>
      <tr>
        <th scope="row">
          <div
            className={`tabType ${
              activeTab?.toLowerCase() === "user" ? "userTab" : "teamTab"
            } `}
          >
            <CheckBox {...checkboxObj} />
            <div>{tableNameObj[activeTab?.toLowerCase()]}</div>
          </div>
        </th>
        {[...headerList].map(item => {
          const {
            name = "",
            displayName = "",
            isSort = true,
            enabled = false
          } = item || {};
          return (
            enabled && (
              <th scope="col" key={name}>
                <div className="nameAndEmailCont">
                  {Utils.getLinkedInDisplayName(displayName, name)}
                  {isSort && (
                    <>
                      <i
                        className={`material-icons sortIcon ${
                          sortParam?.toLowerCase() === name?.toLowerCase()
                            ? `activeSort ${
                                sortType === "ASC" ? "ascending" : ""
                              }`
                            : ""
                        } ${tableData?.length ? "" : "pointerUserSelectNone"}`}
                        onClick={() => sortReportData(name)}
                      >
                        sort
                      </i>
                    </>
                  )}
                </div>
              </th>
            )
          );
        })}
      </tr>
    </thead>
  );
});

const TableLoader = observer(
  ({
    count = 10,
    headerList = [],
    sortParam = "",
    sortType = "",
    tableData = [],
    activeTab = ""
  }) => {
    return (
      <div className="tableContainer reportsLoader">
        <table>
          <MetricHeadersList
            headerList={headerList}
            sortParam={sortParam}
            sortType={sortType}
            tableData={tableData}
            activeTab={activeTab}
          />
          <tbody>
            {Array.from({ length: count }, (value, index) => {
              return (
                <tr key={`${index}reportRowBodyLoader`}>
                  <th
                    className="nameAndEmailLoader"
                    key={`${index}RowDataLoader`}
                  >
                    <span className="linear-background " />
                  </th>
                  {[...headerList].map((ele, cIdx) => {
                    const { name = "" } = ele || {};
                    return (
                      <td
                        key={`${name}${index}${cIdx}RowDataLoader`}
                        className={name}
                      >
                        <span className="linear-background" />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
);

export default MetricsTable;
export { MetricsTable };
