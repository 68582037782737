/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {observer} from "mobx-react";
import {makeApi, URLS} from "Utils/apiURL";

const SalesForceOauth = () => {
  const [connectSuccess, setConnectSuccess] = useState(false);

  const getAuthCode = () => {
    try {
      const url = new URL(window.location.href);
      return url.searchParams.get("code");
    } catch (e) {
      return null;
    }
  };

  const connectSalesForceApp = async () => {
    const code = getAuthCode();
    try {
      if (code) {
        const config = {
          url: `${URLS.connectSalesForce}?code=${code}`,
          method: "POST"
        };
        const res = await makeApi(config);
        if (res) {
          setConnectSuccess(true);
          setTimeout(function () {
            window.opener.postMessage("windowRedirectSuccess");
            window.close();
          }, 1000);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    connectSalesForceApp();
  }, []);

  return (
    <div className="pageClass">
      <div className="pageCont">
        <div className="connectAppSection">
          {connectSuccess ? (
            <div>
              <span>Successfully Connected</span>
            </div>
          ) : (
            <div>
              <div>Please wait while we connect your Salesforce account</div>
              <div style={{marginTop: "10px"}}> Connecting.. </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(SalesForceOauth);
export {SalesForceOauth};
