/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as NotContactedIcon } from "Assets/svg/notContacted.svg";
import { ReactComponent as ContactedIcon } from "Assets/svg/contacted.svg";
import { ReactComponent as RepliedIcon } from "Assets/svg/replyRate.svg";
import Utils from "Utils/utils";
import contactStoreData from "Stores/contactData";
import { observer } from "mobx-react";
import { ContactUtils } from "Utils/ContactUtils";
import customListState from "Pages/Dashboard/dataSearch/CustomList/state";

const FilterComponent = props => {
  const {
    setActiveTab = () => {},
    activeTab = "",
    contactLimit = 10,
    filterLoading = false,
    filterLoadingFn = () => {},
    tableLoadingFn = () => {},
    setSearchedKeyFn = () => {},
    searchKeyword = "",
    contactFilterData = [],
    setContactFilterData = () => {},
    setStageId = () => {}
  } = props || {};

  const history = useHistory();

  const getFilterCount = (data, value) => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      data?.contacts?.length > 0 &&
      data?.contacts?.[0]?.stage
    ) {
      const activeStage = data?.contacts[0]?.stage || "";
      switch (value) {
        case "CONTACTED":
          let ct = Utils.compareTwoString(activeStage, value);
          ct && setActiveTab("contacted");
          return ct ? 1 : 0;
        case "NOT_CONTACTED":
          let nc = Utils.compareTwoString(activeStage, value);
          nc && setActiveTab("not_contacted");
          return nc ? 1 : 0;
        case "REPLIED":
          let reply = Utils.compareTwoString(activeStage, value);
          reply && setActiveTab("replied");
          return reply ? 1 : 0;
        case "BOUNCED":
          let bounced = Utils.compareTwoString(activeStage, value);
          bounced && setActiveTab("bounced");
          return bounced ? 1 : 0;
        case "OPTED_OUT":
          let opted = Utils.compareTwoString(activeStage, value);
          opted && setActiveTab("opted_out");
          return opted ? 1 : 0;
        case "TOTAL":
          setActiveTab("total");
          break;
        default:
          return 0;
      }
    } else {
      setActiveTab("total");
    }
    return 0;
  };

  const searchContactResult = async data => {
    setContactFilterData([
      {
        name: "All",
        id: "total",
        iconClass: "people_alt",
        count: data.totalContacts,
        iconType: true
      },
      {
        name: "Contacted",
        id: "contacted",
        iconClass: <NotContactedIcon />,
        count: getFilterCount(data, "CONTACTED"),
        iconType: false
      },
      {
        name: "Not Contacted",
        id: "not_contacted",
        iconClass: <ContactedIcon />,
        count: getFilterCount(data, "NOT_CONTACTED"),
        iconType: false
      },
      {
        name: "Bounced",
        id: "bounced",
        iconClass: "call_missed_outgoing",
        count: getFilterCount(data, "BOUNCED"),
        iconType: true
      },
      {
        name: "Opted Out",
        id: "opted_out",
        iconClass: "block",
        count: getFilterCount(data, "OPTED_OUT"),
        iconType: true
      },
      {
        name: "No stage",
        id: "nostage",
        iconClass: <RepliedIcon />,
        count: 0,
        iconType: false
      }
    ]);
  };

  const constructFilterRenderData = filterData => {
    if (filterData && Object.keys(filterData)?.length) {
      let temp = [...contactFilterData];
      temp.map(item => {
        item.count = filterData[item.upperCaseName];
      });
      setContactFilterData(temp);
      filterLoadingFn(false);
    }
  };

  const filterAction = value => {
    setActiveTab(value?.id);
    tableLoadingFn(true);
    contactStoreData.setCurrentPage(1);
    setSearchedKeyFn(value?.id);
    Utils.trackFilterMixpanelForContacts(value?.name, "Contacts");
    let filter = { ...contactStoreData.filterPayload.filter };
    setStageId(value?.name?.toLowerCase() === "total" ? null : value?.id);
    const payload = {
      filter,
      stageId: value?.name.toLowerCase() === "total" ? null : value?.id,
      limit: contactLimit,
      pageNo: 1,
      searchTerm: searchKeyword,
      listId: customListState?.activeList?.id || ""
    };
    ContactUtils.contactFilterByPayload(payload, tableLoadingFn);
  };

  const getStyles = () => {
    const contactFilterLength = contactFilterData?.length + 1;
    return {
      gridTemplateColumns:
        contactFilterData?.length > 0
          ? `repeat(${contactFilterLength}, calc(100% / ${
              contactFilterLength < 8 ? contactFilterLength : 8
            } - 10px))`
          : `repeat(8, 1fr)`
    };
  };

  useEffect(() => {
    if (Object.keys(contactStoreData.contactFilterData).length > 0) {
      constructFilterRenderData(contactStoreData.contactFilterData);
    }
  }, [contactStoreData.contactFilterData]);

  useEffect(() => {
    if (!contactStoreData?.updateContactEmail) {
      const payload = {
        ...contactStoreData.filterPayload,
        searchTerm: searchKeyword,
        listId: customListState?.activeList?.id || ""
      };
      contactStoreData.setFilterPayload(payload);
      contactStoreData.filterContactAnalyticsData(payload, filterLoadingFn);
      filterLoading && localStorage.setItem("scrollFilter", "true");
      contactStoreData.setUpdateContactEmail("");
    }
  }, []);

  useEffect(() => {
    if (contactStoreData.searchContactFilter !== null) {
      searchContactResult(contactStoreData.searchContactFilter);
    }
  }, [contactStoreData.searchContactFilter]);

  return (
    <div
      className="filterWrapper seq-contact-list"
      id="contactFilter"
      style={getStyles()}
    >
      {!filterLoading && contactFilterData?.length > 0
        ? contactFilterData.map((value, key) => {
            return (
              <div
                className={`filterCont seq-contact-card ${
                  activeTab === value.id ? "active" : ""
                }`}
                key={key}
                onClick={e => {
                  filterAction(value);
                }}
              >
                <div className="filterOption">
                  <div className="filterValueCont">
                    <span className="filterValue">
                      {value.count == null || value.count === undefined
                        ? 0
                        : Utils.formatNumberToString(value.count)}
                    </span>
                    <span className="filterLabel">{value.name}</span>
                  </div>
                </div>
              </div>
            );
          })
        : Array.from({ length: 8 }, (value, index) => (
            <li
              className="contactfilter linear-background"
              key={`seqContactsNavLoader${index}`}
            />
          ))}
      {contactFilterData?.length > 0 && (
        <div
          className="filterCont plusContactCard"
          onClick={() => history.push("/settings/stages")}
        >
          +
        </div>
      )}
    </div>
  );
};

export { FilterComponent };
export default observer(FilterComponent);
