import React from "react";

const StepSummary = (props = {}) => {
  const {
    stageType = "email",
    currentStep = "1",
    days = 0,
    daysFromStart = 1,
    linkedInTaskType = ""
  } = props || {};

  const currentStepNumber = parseInt(currentStep);
  const isPlural = (days > 1 && "s") || "";
  const previousStep = (currentStepNumber > 1 && currentStepNumber - 1) || 0;

  let daysText = `${
    days > 0 ? `within ${days} day${isPlural}` : ""
  } after step #${previousStep}`;

  const stepSummary = {
    email:
      currentStepNumber > 1
        ? `An email will be automatically sent on Day ${
            daysFromStart || 1
          } if there is no reply ${daysText}`
        : "An email will be automatically delivered immediately after a contact is added to this sequence.",
    manualEmail:
      currentStepNumber > 1
        ? `An email task will be due on Day ${
            daysFromStart || 1
          } if there is no reply ${daysText}`
        : "An email task will be due immediately after a contact is added to this sequence.",
    linkedin:
      currentStepNumber > 1
        ? `A LinkedIn task will be due on Day ${
            daysFromStart || 1
          } if there is no reply ${daysText}`
        : "A LinkedIn task will be due immediately after a contact is added to this sequence.",
    linkedinAuto: {
      LI_SEND_INMAIL:
        currentStepNumber > 1
          ? `A LinkedIn InMail will be sent on Day ${
              daysFromStart || 1
            } if there is no reply ${daysText}`
          : "A LinkedIn InMail will be sent immediately after a contact is added to this sequence",
      LI_SEND_MESSAGE:
        currentStepNumber > 1
          ? `A LinkedIn message will be sent on Day ${
              daysFromStart || 1
            } if there is no reply ${daysText}`
          : "A LinkedIn message will be sent immediately after a contact is added to this sequence",
      LI_SEND_CONNECTION_REQUEST:
        currentStepNumber > 1
          ? `A LinkedIn connection request will be sent on Day ${
              daysFromStart || 1
            } if there is no reply ${daysText}`
          : "A LinkedIn connection request will be sent immediately after a contact is added to this sequence",
      LI_VIEW_PROFILE:
        currentStepNumber > 1
          ? `A LinkedIn profile view will be made on Day ${
              daysFromStart || 1
            } if there is no reply ${daysText}`
          : "A LinkedIn profile view will be made immediately after a contact is added to this sequence"
    },
    customTask:
      currentStepNumber > 1
        ? `A custom task will be due on Day ${
            daysFromStart || 1
          } if there is no reply ${daysText}`
        : "A custom task will be due immediately after a contact is added to this sequence.",
    phone:
      currentStepNumber > 1
        ? `A call task will be due on Day ${
            daysFromStart || 1
          } if there is no reply ${daysText}`
        : "A call task will be due immediately after a contact is added to this sequence."
  };

  return (
    <div className="stepSummary">
      <h6>Step Summary</h6>
      <p>
        {stageType?.toLowerCase() === "linkedinauto"
          ? stepSummary?.[stageType]?.[linkedInTaskType]
          : stepSummary?.[stageType]}
      </p>
    </div>
  );
};

export default StepSummary;
export { StepSummary };
