import React from "react";

const Button = props => {
  const {
    id = "",
    name = "",
    btnText = "",
    className = "",
    type = "text",
    loader = false,
    icon = false,
    iconName = "",
    isLeftSideIcon = false,
    isRightSideIcon = false,
    svg = false,
    children,
    closeIcon,
    closeCbk,
    disabled = false,
    knobs = false,
    needExtraDom = true,
    show = true,
    iconCbk = () => {},
    click = () => {},
    customIcon = false,
    LeftIconName = <></>,
    RightIconName = <></>,
    ...remainingProps
  } = props || {};

  return (
    show && (
      <button
        id={id}
        name={name}
        type={type}
        className={`animatedButton ${className} ${disabled ? "disabled" : ""}`}
        onClick={click}
        {...remainingProps}
      >
        {svg && !loader && <span className="iconStart">{svg}</span>}
        {loader && <span className="load-text"></span>}
        {isLeftSideIcon && customIcon && <LeftIconName />}
        {!loader && isLeftSideIcon && (
          <span className="iconLeftCont" onClick={iconCbk}>
            <i className="material-icons-outlined leftIcon">{iconName}</i>
          </span>
        )}
        {!loader && needExtraDom && <span className="btnText">{btnText}</span>}
        {!loader && icon && (
          <span className="iconRight" onClick={iconCbk}>
            <i className="material-icons-outlined">{iconName}</i>
          </span>
        )}
        {isRightSideIcon && customIcon && RightIconName}
        {children}
        {closeIcon && (
          <i className="material-icons closeIcon" onClick={closeCbk}>
            close
          </i>
        )}
        {knobs && <div className="knobs"></div>}
        {!needExtraDom && btnText}
      </button>
    )
  );
};

export default Button;
export { Button };
