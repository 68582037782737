import React, { useState } from "react";
import { observer } from "mobx-react";
import { linkedInCookiesState } from "Stores/LinkedInCookies";
import Button from "Components/common/Button";
import { linkedInReplyState } from "../state";
import {
  LinkedInPopupFooter,
  LinkedInPopupHeader
} from "../common/LinkedInPopupHeaderFooter";
import { ReactComponent as LinkedinIcon } from "../svg/fullLinkedInImg.svg";
import { ReactComponent as MiniLogoIcon } from "../svg/linkedIn.svg";
import { ReactComponent as LiPremiumIcon } from "../svg/liPremiumIcon.svg";
import LinkedInSalesNavigatorIcon from "../png/LinkedInSalesNavigatorIcon.png";

const LinkedInDetailsCard = () => {
  const {
    fullName = "",
    companyName = "",
    title = "",
    followersCount = "",
    imgUrl = "",
    hasLinkedInIcon = true
  } = linkedInCookiesState?.profileData || {};

  return (
    <div className="linkedInDetailsCard active">
      <div className="absoluteIcon">
        {linkedInCookiesState?.isPremiumAccount ? (
          <img
            className="salesNavigatorIcon"
            src={LinkedInSalesNavigatorIcon}
            alt={"LinkedIn Sales Navigator"}
          />
        ) : (
          <LinkedinIcon />
        )}
      </div>
      <div className="leftLayout">
        {hasLinkedInIcon && imgUrl && (
          <div className="absoluteMiniIcon">
            {linkedInCookiesState?.isPremiumAccount ? (
              <LiPremiumIcon />
            ) : (
              <MiniLogoIcon />
            )}
          </div>
        )}
        {imgUrl && <img className="linkedInImg" src={imgUrl}></img>}
      </div>
      <div className="rightLayout">
        {fullName && (
          <span className="fullName">{fullName?.toLowerCase()}</span>
        )}
        {(title || companyName) && (
          <span className="jobDetails">{`${title ? title : ""}${
            title ? ` @` : ""
          }${companyName}`}</span>
        )}
        {followersCount && <span className="followers">{followersCount}</span>}
      </div>
    </div>
  );
};

const ActionButtons = observer(() => {
  const handleConnectButtonClick = () => {
    linkedInReplyState.setLinkedInConnectBtnLoading(true);
    linkedInReplyState.connectLinkedInAccount();
  };

  const handleCancelButtonClick = () => {
    linkedInReplyState.setShowLinkedInDetailsPopup(false);
  };

  const connectBtnProps = {
    id: "connectLinkedInBtn",
    name: "connectLinkedInBtn",
    btnText: "Connect",
    type: "button",
    className: `connectLinkedInBtn ${
      linkedInReplyState?.linkedInConnectBtnLoading ? "loading" : ""
    }`,
    click: handleConnectButtonClick,
    loader: linkedInReplyState?.linkedInConnectBtnLoading
  };

  const cancelBtnProps = {
    id: "cancelBtn",
    name: "cancelBtn",
    btnText: "Cancel",
    type: "button",
    className: "cancelLinkedInBtn",
    click: handleCancelButtonClick
  };

  return (
    <div className="btnContainer">
      <Button {...cancelBtnProps} />
      <Button {...connectBtnProps} />
    </div>
  );
});

const LinkedInDetailsPopup = observer(() => {
  const [activeCard, setActiveCard] = useState("");

  const closeIconCbk = () => {
    linkedInReplyState.setShowLinkedInDetailsPopup(false);
  };

  return (
    linkedInReplyState.showLinkedInDetailsPopup && (
      <div className="linkedInNotConnectedWrapper">
        <div className="card">
          <LinkedInPopupHeader closeIconCbk={closeIconCbk} />
          <div className="linkedInBody">
            <div className="linkedInDetailsList">
              <LinkedInDetailsCard
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            </div>
            <p className="diffAccountText">Looking for a different account?</p>
          </div>
          <LinkedInPopupFooter additionalDom={<ActionButtons />} />
        </div>
        <div className="common-popup-mask" onClick={closeIconCbk} />
      </div>
    )
  );
});

export { LinkedInDetailsPopup };
export default LinkedInDetailsPopup;
