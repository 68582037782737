import React from "react";
import Utils from "Utils/utils";
import { ReactComponent as TwitterX } from "Assets/svg/twitterX.svg";
import { ReactComponent as LinkedinIcon } from "Assets/svg/linkedin.svg";

export const AccountSocialLink = ({
  domain = "",
  linkedInUrl = "",
  twitter = "",
  websiteDomain = "",
  iconSize = ""
}) => {
  const SOCIAL_LINK = [
    {
      id: "website",
      icon: "link",
      className: "material-icons-outlined websiteIcon",
      url: domain || websiteDomain || ""
    },
    {
      id: "twitter",
      svg: <TwitterX className="twitterIcon" />,
      url: twitter || ""
    },
    {
      id: "linkedin",
      svg: <LinkedinIcon className="linkedinIcon" />,
      url: linkedInUrl || ""
    }
  ];

  const handleLIinkRedirectAction = (event = "") => {
    let urlLink = Utils.performActionByClosestDom(event, "li", "data-link");
    urlLink &&
      Utils.windowRedirect(
        Utils.removeHttpFromString(urlLink),
        "_blank",
        true,
        true
      );
  };

  return (
    <ul
      className={`accountSocialLinkCont ${iconSize || ""}`}
      onClick={handleLIinkRedirectAction}
    >
      {SOCIAL_LINK?.map(item => {
        const {
          id = "",
          icon = "",
          svg = <></>,
          url = "",
          className = ""
        } = item || {};
        return (
          url && (
            <li
              data-link={url || ""}
              key={id}
              className={`socialLinkItem ${!url ? "disabled" : ""}`}
            >
              {icon ? (
                <i className={className}>link</i>
              ) : (
                <span className={id}>{svg}</span>
              )}
            </li>
          )
        );
      })}
    </ul>
  );
};

export default AccountSocialLink;
