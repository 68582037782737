import React, { useRef } from "react";
import Button from "Components/common/Button";
import { editAccountNotesState } from "./Main";
import InputTextBox from "Components/common/InputTextBox";
import { observer } from "mobx-react";

const AccountEditNotesEditorActions = ({
  state = {},
  setState = () => {},
  save = () => {}
}) => {
  let loader = state?.buttonLoader || false;
  const inputBoxRef = useRef(null);

  const changeInputVal = (id = "", data = "") => {
    const stateObj = { ...state?.inputVal };
    stateObj[id] = data || "";
    setState({ ...state, inputVal: stateObj });
  };

  const cancelAccountEdit = () => {
    editAccountNotesState.setShowEdit(false);
    editAccountNotesState.setIsAddBtn(false);
    setState({ ...state, buttonLoader: false });
  };

  const buttonList = [
    {
      id: "accountNotesCancelBtn",
      name: "accountNotesCancelBtn",
      btnText: "Cancel",
      type: "button",
      className: `accountNotesCancelBtn`,
      click: cancelAccountEdit
    },
    {
      id: "saveAccountNotes",
      name: "SaveAccount",
      btnText: `${editAccountNotesState?.isEdit ? "Update" : "Save"}`,
      type: "button",
      className: `saveAccountNotesBtn ${loader ? "loading" : ""}`,
      click: save,
      disabled: state?.inputVal?.title ? false : true,
      loader
    }
  ];
  const inputObj = {
    name: "title",
    id: "title",
    value: state?.inputVal?.title || "",
    placeHolder: "Title",
    change: changeInputVal,
    autoFocus: true
  };

  return (
    <div className="editNotesAction">
      <InputTextBox {...inputObj} />
      <div className="editNotesActionBtn">
        {buttonList?.map((item, index) => (
          <Button key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default observer(AccountEditNotesEditorActions);
