import React from "react";

export const ContactsSelectedCount = ({
  allSelected = false,
  count = 0,
  type = "contacts"
}) => {
  return allSelected ? (
    <div className="contactsCountText">All {type} selected</div>
  ) : count ? (
    <div className="contactsCountText">{`${count} contact${
      count > 1 ? "s" : ""
    } selected`}</div>
  ) : (
    ""
  );
};

export default ContactsSelectedCount;
