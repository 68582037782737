import React, { useState, useEffect } from "react";
import HeaderLayout from "Components/LoggedInLayout/HeaderLayout";
import ReportsBody from "Pages/Dashboard/reports_v2/ReportsBody";

const defaultBreadcrumbValue = [{ name: "Reports", link: "/reports" }];

const ReportsModulev2 = () => {
  const [breadcrumbArr, setBreadCrumbArr] = useState([
    ...defaultBreadcrumbValue
  ]);

  return (
    <div className="reportsPage pageClass">
      <HeaderLayout className="reports" breadcrumbArr={breadcrumbArr} />
      <ReportsBody />
    </div>
  );
};

export default ReportsModulev2;
export { ReportsModulev2 };
