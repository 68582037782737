/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import CheckBox from "Components/common/CheckBox";
import { ReactComponent as Downarrow } from "Assets/svg/donw-arrow.svg";
import InputTextBox from "./InputTextBox";
import Utils from "Utils/utils";

const MultiSelectDropdownWithInput = observer(props => {
  const {
    options = [],
    setShowOptions = () => {},
    showOptions = true,
    setOptions = () => {},
    isContactCard = false
  } = props || {};

  const [renderArray, setRenderArray] = useState(options);
  const [inputValue, setInputValue] = useState("");

  const setCheckedValue = (checkedValue, selectedId) => {
    const optionsList = getOptions();
    if (optionsList?.length) {
      optionsList.map(value => {
        const { id } = value;
        if (selectedId && id && id === selectedId) {
          value.checked = checkedValue;
        }
      });
    }
    updateData(optionsList);
  };

  const multiSelectInputOnChange = (id, value) => {
    setInputValue(value);
    if (value && value.length > 0) {
      filterOptions(value);
    } else {
      setRenderArray(options);
      setShowOptions(true);
    }
  };

  const addNewOption = (id, value) => {
    if (id && value) {
      const tempObj = { id, value, checked: true };
      if (!isDuplicateOption(value)) {
        const optionsList = getOptions();
        optionsList.unshift(tempObj);
        updateData(optionsList);
        setShowOptions(false);
      }
    }
  };

  const getOptions = () => {
    return JSON.parse(JSON.stringify(options));
  };

  const filterOptions = toFilterString => {
    const loweredString = toFilterString?.toLowerCase()?.trim();
    const regexp = new RegExp(loweredString, "i");
    const tagsList = getOptions();
    const filteredArray = tagsList.filter(x => regexp.test(x.value));
    if (filteredArray.length === 0) {
      const id = loweredString.replace(/ /g, "");
      setRenderArray([
        {
          id,
          value: toFilterString,
          newOption: true
        }
      ]);
    } else {
      setRenderArray(filteredArray);
    }
  };

  const multiSelectInputFocus = () => {
    setShowOptions(true);
  };

  const handleDropdownClick = () => {
    setShowOptions(!showOptions);
  };

  const updateData = data => {
    setOptions(data);
    setRenderArray(data);
    setInputValue("");
  };

  const onKeyUpAction = e => {
    Utils.preventDefaultFn(e);
    if (e && e.keyCode === 13) {
      const { value } = e.target;
      if (value) {
        const id = value.toLowerCase().replace(/ /g, "");
        if (id) {
          addNewOption(id, value);
          document.getElementById("multiSelectInput") &&
            document.getElementById("multiSelectInput").blur();
        }
      }
    }
  };

  const isDuplicateOption = newOptionValue => {
    let isDuplicateOption = false;
    const optionsList = getOptions();
    optionsList.map(obj => {
      const { value } = obj;
      if (value && newOptionValue && newOptionValue.trim() === value) {
        isDuplicateOption = true;
      }
    });
    return isDuplicateOption;
  };

  useEffect(() => {
    setRenderArray(options);
  }, [options]);

  return (
    <>
      <div className="multiSelectDropDown">
        <div className="multiSelectInputCont">
          <InputTextBox
            name="multiSelectInput"
            id="multiSelectInput"
            type="text"
            value={inputValue}
            placeHolder={isContactCard ? "Add Tags" : ""}
            change={multiSelectInputOnChange}
            onFocus={multiSelectInputFocus}
            autoFocus={false}
            required={false}
            cbk={onKeyUpAction}
          />
          <span
            className={`downArrowBlack ${showOptions ? "top" : ""}`}
            onClick={handleDropdownClick}
          >
            <Downarrow />
          </span>
        </div>
        <div className="innerCont">
          {showOptions && (
            <div className="listCont">
              {renderArray && renderArray.length > 0 ? (
                renderArray.map((obj, index) => {
                  const { id, value, checked, newOption } = obj;
                  return (
                    <div className="tagOption" key={`${id}-${index}`}>
                      {newOption ? (
                        <span
                          className="newOption"
                          onClick={e => addNewOption(id, value)}
                        >
                          Add
                          <span className="newOptionValue">{value}</span>
                        </span>
                      ) : (
                        <CheckBox
                          key={`chbox-${id}-${index}`}
                          id={id}
                          name={value}
                          checked={checked}
                          cbk={setCheckedValue}
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <span className="noTags">No tags found</span>
              )}
            </div>
          )}
        </div>
      </div>
      {isContactCard && showOptions && (
        <div
          className="transparentOverlay"
          onClick={() => {
            setShowOptions(false);
            handleDropdownClick();
          }}
        />
      )}
    </>
  );
});

export default MultiSelectDropdownWithInput;
export { MultiSelectDropdownWithInput };
