/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import "react-telephone-input/css/default.css";
import PhoneNumberInput from "Components/common/PhoneNumberInput";

import Utils from "Utils/utils";

class AddMyOwnNumberPopupState {
  @observable showPopup = false;
  @observable callBack = e => {};

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setCallBack = val => {
    this.callBack = val;
  };

  @action
  setPopupValues = obj => {
    this.popupValues = obj;
  };
}

const addMyOwnNumberPopupState = new AddMyOwnNumberPopupState();

const AddMyOwnNumberPopup = observer(() => {
  const [phNumber, setPhNumber] = useState("");
  const [extensionCode, setExtensionCode] = useState("");

  const closeAction = () => {
    setPhNumber("");
    setExtensionCode("");
    addMyOwnNumberPopupState.setShowPopup(false);
  };

  const BtnAction = (e, key) => {
    Utils.preventDefaultFn(e);
    if (key) {
      addMyOwnNumberPopupState.callBack &&
        addMyOwnNumberPopupState.callBack(key, phNumber, extensionCode);
      closeAction();
    }
  };

  return addMyOwnNumberPopupState.showPopup ? (
    <div className="addMyOwnNumberPopup popupWrapper">
      <div className="confirmation-popup common-popup">
        <div className="ownNumberPopupWrapper popup-wrap animated customzoomIn">
          <div className="newNumberCont">
            <div className="popupHeader">
              <div className="popupTitle">Add Your Number</div>
              <div
                className="popup-close"
                title="Click here to close"
                onClick={closeAction}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
            <div className="popupBody">
              <div className="callMeCont">
                <span className="enterNumberText">Enter your Number</span>
                <div className="numberInputCont">
                  <PhoneNumberInput
                    setPhNumber={setPhNumber}
                    setExtensionCode={setExtensionCode}
                    extensionCode={extensionCode}
                    allowExtension={true}
                    inputProps={{ autoFocus: true }}
                  />
                  <div className="infoCont">
                    <span className="infoText">
                      <span className="infoIconTp">
                        <i className="material-icons-outlined">info</i>
                      </span>
                      We'll call you to enter a verification code
                    </span>
                  </div>
                  <div className="actionBtn">
                    <div
                      className="backCont btn"
                      onClick={e => BtnAction(e, "back")}
                    >
                      Back
                    </div>
                    <div
                      className={`callMeBtn btn ${phNumber ? "" : "disabled"}`}
                      onClick={e => BtnAction(e, "call")}
                    >
                      Call me
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

export default AddMyOwnNumberPopup;
export { AddMyOwnNumberPopup, addMyOwnNumberPopupState };
