/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { sequenceRenderState } from "./../SequencePage/sequenceProperties";
import { sequenceContactData } from "Stores/sequence/Contact";
import Utils from "Utils/utils";
import { ReactComponent as ClickRateIcon } from "Assets/svg/clickRate.svg";
import { ReactComponent as ReplyRateIcon } from "Assets/svg/replyRate.svg";
import sequenceContactStoreData from "./SequenceContactFilter/sequenceContactStore";

const SequenceContactsNav = props => {
  const {
    seqId = "",
    currentTab = "",
    cbk = () => {},
    uploadedContactCbk = () => {},
    getStepMetricData = () => {},
    getTotalCountForEachFilter = () => {},
    preventLoader = false
  } = props || {};

  const [loading, setLoading] = useState(true);
  const [seqContactNavItems, setSeqContactNavItems] = useState([]);

  const filterContactCbk = event => {
    let listElem = event.target.closest("li") || "";
    if (listElem) {
      let name = listElem.getAttribute("data-key") || "";
      if (name) {
        Utils.trackFilterMixpanel(name, "Sequences");
        clearInterval(window.scrollInterval);
        cbk(name);
        getStepMetricData(sequenceRenderState?.filterByStep, true);
        getTotalCountForEachFilter();
      }
    }
  };

  const sequenceContactTab = data => {
    const {
      contactUploadStatus = " ",
      contactsAdded = 0,
      completedCampaigns = 0,
      ongoing = 0,
      paused = 0,
      contactsOpened = 0,
      contactsClicked = 0,
      emailsFailed = 0,
      totalReplyCount = 0,
      emailsBounced = 0,
      optedOut = 0,
      pending = 0,
      meetingBookedCount = 0
    } = data || {};
    uploadedContactCbk(contactUploadStatus);
    setSeqContactNavItems([
      {
        name: "All",
        key: "Total",
        icon: "assignment_ind",
        count: Utils.formatNumberToString(contactsAdded)
      },
      {
        name: "Pending",
        key: "Pending",
        icon: "call_missed_outgoing",
        count: Utils.formatNumberToString(pending)
      },
      {
        name: "Ongoing",
        key: "ongoing",
        icon: "play_arrow",
        count: Utils.formatNumberToString(ongoing)
      },
      {
        name: "Completed",
        key: "Completed",
        icon: "check",
        count: Utils.formatNumberToString(completedCampaigns)
      },
      {
        name: "Meetings Booked",
        key: "meeting_booked",
        icon: "event",
        count: Utils.formatNumberToString(meetingBookedCount)
      },
      {
        name: "Paused",
        key: "Paused",
        icon: "pause",
        count: Utils.formatNumberToString(paused)
      },
      {
        name: "Opened",
        key: "openCount",
        icon: "visibility",
        count: Utils.formatNumberToString(contactsOpened)
      },
      {
        name: "Clicked",
        key: "clickCount",
        svg: ClickRateIcon,
        count: Utils.formatNumberToString(contactsClicked)
      },
      {
        name: "Failed",
        key: "failed",
        count: Utils.formatNumberToString(emailsFailed)
      },
      {
        name: "Replied",
        key: "replied",
        svg: ReplyRateIcon,
        count: Utils.formatNumberToString(totalReplyCount)
      },
      {
        name: "Bounced",
        key: "Bounced",
        icon: "call_missed_outgoing",
        count: Utils.formatNumberToString(emailsBounced)
      },
      {
        name: "Opted Out",
        key: "Opted_Out",
        icon: "not_interested",
        count: Utils.formatNumberToString(optedOut)
      }
    ]);
  };

  const getContactsTabCount = data => {
    if (data && Object.keys(data)?.length > 0) {
      sequenceContactTab(data);
      setLoading(false);
    }
  };

  const getAnalyticsByFilter = () => {
    const { filterByStep = null } = sequenceRenderState || {};
    const temp = { ...sequenceContactStoreData?.filterPayload };
    temp.filters = { ...temp?.filters, currentStep: filterByStep };
    sequenceContactStoreData.setFilterPayload(temp);
    const { all = false, idArr = [] } = sequenceRenderState?.contactOwner || {};
    if (idArr?.length > 0) {
      temp.filters.memberIds = idArr;
    } else {
      temp.filters.all = all;
    }

    let analyticsTopFilterPayload = {};
    let updatedFields = [];

    if (sequenceContactStoreData?.topFilter?.fields?.length > 0) {
      if (temp?.filters?.fields?.length) {
        updatedFields = temp?.filters?.fields.concat(
          sequenceContactStoreData?.topFilter?.fields
        );
        analyticsTopFilterPayload = {
          ...temp?.filters,
          fields: updatedFields
        };
      } else {
        analyticsTopFilterPayload = {
          ...temp?.filters,
          ...sequenceContactStoreData?.topFilter
        };
      }
    } else {
      analyticsTopFilterPayload = {
        ...temp?.filters,
        ...sequenceContactStoreData?.topFilter
      };
    }
    if (!sequenceContactStoreData?.isOverviewToContactsPage) {
      sequenceContactStoreData.setOverallPayload(analyticsTopFilterPayload);
    }
    sequenceContactData.getSeqAnalyticsTab(
      seqId,
      sequenceContactStoreData?.isOverviewToContactsPage
        ? {
            currentStep:
              sequenceContactStoreData.overallPayload?.filters?.currentStep ||
              null
          }
        : {
            ...temp?.filters
          }
    );
  };

  useEffect(() => {
    if (!sequenceContactStoreData?.isOverviewToContactsPage) {
      sequenceContactData.getSeqAnalyticsTab(seqId);
    }
    loading && localStorage.setItem("scrollFilter", "true");
  }, [seqId]);

  useEffect(() => {
    if (sequenceRenderState?.performingSearch) {
      setLoading(true);
    } else {
      setLoading(true);
      getAnalyticsByFilter();
    }
  }, [
    sequenceRenderState.contactOwner.all,
    sequenceRenderState.contactOwner.idArr,
    sequenceRenderState.filterByStep
  ]);

  useEffect(() => {
    !preventLoader && !loading && setLoading(true);
    getContactsTabCount(sequenceContactData.contactsTabCount);
  }, [sequenceContactData.contactsTabCount]);

  return (
    <div className="seq-contact-nav-section">
      <ul
        className="seq-contact-list"
        id="filter"
        onClick={e => filterContactCbk(e)}
      >
        {!loading
          ? seqContactNavItems?.length > 0 &&
            seqContactNavItems.map(item => {
              const { key = "", name = "", count = "" } = item || {};
              return (
                <li
                  key={name}
                  className={`seq-contact-card ${
                    currentTab?.toLowerCase() === key?.toLowerCase()
                      ? "active"
                      : ""
                  }`}
                  data-key={key}
                  id={key}
                >
                  <div className="seq-contact-body">
                    <div className="seq-contact-content">
                      <h6
                        className={
                          currentTab?.toLowerCase() === key?.toLowerCase()
                            ? "activeNo"
                            : ""
                        }
                      >
                        {count}
                      </h6>
                      <p>{name}</p>
                    </div>
                  </div>
                </li>
              );
            })
          : Array.from({ length: 11 }, (value, index) => (
              <li
                className="linear-background"
                key={`seqContactsNavLoader${index}`}
              />
            ))}
      </ul>
    </div>
  );
};

export default observer(SequenceContactsNav);
