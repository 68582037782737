import React from "react";

const ContactCardLoaderComponent = (props = {}) => {
  const objLoader = {
    contact: 11,
    company: 2,
    location: 5,
    social: 4,
    customField: 3
  };

  return (
    <div className="contactInfoCard">
      <div className="contactDetails">
        {/* <div className="contactIcon linear-background" /> */}
        <div className="flexCenter justifyCenter">
          <h4 className="contactName linear-background"></h4>
          <div className="contactSocialIconLink">
            <span className="linkedinIcon linear-background" />
            <span className="websiteIcon linear-background" />
          </div>
        </div>
        <div className="contactJobCompany">
          <div className="linear-background" />
        </div>

        <div className="contactContactedInfo">
          <div className="first contactDate">
            <p className="linear-background"></p>
            <h6 className="linear-background"></h6>
          </div>
          <div className="last contactDate">
            <p className="linear-background"></p>
            <h6 className="linear-background"></h6>
          </div>
        </div>
        <div className="actionIcon">
          {Array.from({ length: 6 }, (value, index) => (
            <div
              key={`actionBtnIcon${index}`}
              className="actionBtnIcon linear-background"
            />
          ))}
        </div>
      </div>
      <div className="contactActivityInfo">
        <div className="wrapContactActivity">
          {Object.keys(objLoader).map((item, index) => (
            <div key={index}>
              <div
                className="collapsibleLoader"
                style={{ borderBottom: "1px solid #d8d8d8" }}
              >
                <span className="linear-background title" />
                <span className="linear-background icon" />
              </div>
              {Array.from({ length: objLoader[item] }, (value, index) => (
                <div className="gridCol" key={`contactCard${item}${index}`}>
                  <div className="icon">
                    <span className="linear-background name" />
                  </div>
                  <div className="flexCenter">
                    <span className="contactEmail linear-background" />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { ContactCardLoaderComponent };
export default ContactCardLoaderComponent;
