/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { userDetail } from "Stores/userDetail";
import { Utils } from "Utils/utils";
import CrmTypes from "./CrmTypes";
import {
  OTHER_INTEGRATION_TYPES,
  CRM_INTEGRATION_TYPES,
  CRM_INTEGRATION_TYPES_UNLIMITED,
  OTHER_INTEGRATION_TYPES_UNLIMITED
} from "Model/model";
import { zohoDataStore } from "./zoho/ZohoStoreData";
import { hubspotStore } from "./hubspot/HubspotStore";
import { slackDataStore } from "Stores/SlackDataStore";
import { pipedriveStore } from "./pipedrive/PipedriveStore";
import { salesforceConnectedData } from "./salesforce/SalesforceConnectedData";

const CrmIntegration = observer(() => {
  const history = useHistory();

  const { inFreePlan = false, planName = "" } =
    userDetail.userFeatureFlag || {};

  const openSfConfigure = () => {
    history.push("/settings/crm/salesforce");
  };

  const openZohoConfigure = () => {
    if (Utils.isAdmin()) {
      history.push("/settings/crm/zoho");
    } else {
      showAdminPopup("zoho");
    }
  };

  const openSlackConfigure = () => {
    if (Utils.isAdmin()) {
      history.push("/settings/crm/slack");
    } else {
      showAdminPopup("slack");
    }
  };

  const openHubspotConfigure = () => {
    if (Utils.isAdmin()) {
      history.push("/settings/crm/hubspot");
    } else {
      showAdminPopup("hubspot");
    }
  };

  const openPipedriveConfigure = () => {
    if (Utils.isAdmin()) {
      history.push("/settings/crm/pipedrive");
    } else {
      showAdminPopup("pipedrive");
    }
  };

  const showAdminPopup = (key = "slack") => {
    let msg = `Please contact your account administrator to configure ${key} configuration.`;
    Utils.showMessagePopup(msg);
  };

  const getIntegrationType = (type = "") => {
    return inFreePlan || planName?.toLowerCase() === "basic"
      ? type === "crm"
        ? CRM_INTEGRATION_TYPES
        : OTHER_INTEGRATION_TYPES
      : type === "crm"
      ? CRM_INTEGRATION_TYPES_UNLIMITED
      : OTHER_INTEGRATION_TYPES_UNLIMITED;
  };

  // Presentational components
  const CRMIntegration = observer(() => {
    return (
      <section className="wrapper">
        <div className="headingText">
          <span className="title">CRM Integrations</span>
        </div>
        <div className="crmType">
          <CrmTypes
            typeList={getIntegrationType("crm")}
            sfConfigureCbk={openSfConfigure}
            zohoConfigureCbk={openZohoConfigure}
            hubspotConfigureCbk={openHubspotConfigure}
            pipedriveConfigureCbk={openPipedriveConfigure}
            isSalesforceConnected={
              salesforceConnectedData?.salesForceData?.salesforceConnected ||
              false
            }
            isZohoConnected={zohoDataStore?.zohoData?.zohoConnected || false}
            isHubspotConnected={
              hubspotStore?.hubspotData?.hubConnected || false
            }
            isPipedriveConnected={
              pipedriveStore?.pipedriveData?.pipedriveConnected || false
            }
          />
        </div>
      </section>
    );
  });

  const OtherIntegration = observer(() => {
    return (
      <section className="wrapper " style={{ paddingTop: "20px" }}>
        <div className="headingText">
          <span className="title">Others</span>
        </div>
        <div className="crmType">
          <CrmTypes
            typeList={getIntegrationType("other")}
            slackConfigureCbk={openSlackConfigure}
            isSlackConnected={
              slackDataStore?.slackData?.slackConnected || false
            }
          />
        </div>
      </section>
    );
  });

  return (
    <div className="crmIntegration">
      <div className="crmInnerCont">
        <div className="headingText mainHeading">
          <span className="title">Integrations</span>
        </div>
        <CRMIntegration />
        <OtherIntegration />
      </div>
    </div>
  );
});

export default CrmIntegration;
export { CrmIntegration };
