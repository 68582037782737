import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import AutoBcc from "Pages/Dashboard/settings/AutoBcc";
import UserBilling from "Pages/Dashboard/settings/UserBilling";
import Unsubscribe from "Pages/Dashboard/settings/Unsubscribe";
import CustomURL from "Pages/Dashboard/settings/CustomUrl/CustomUrl";
import CustomFields from "Pages/Dashboard/settings/CustomFields";
import SafetySettings from "Pages/Dashboard/settings/SafetySettings";
import Schedules from "Pages/Dashboard/settings/Schedules/Schedules";
import CsvImports from "Pages/Dashboard/settings/CsvImports";
import Triggers from "Pages/Dashboard/settings/Triggers/Triggers";
import CreditsUsage from "Pages/Dashboard/settings/CreditsUsage";
import UsersAndTeams from "Pages/Dashboard/settings/UsersAndTeams/index";
import Calendar from "Pages/Dashboard/settings/Calendar/Main";
import DoNotContactDomain from "Pages/Dashboard/settings/DoNotContactDomain/DoNotContactDomain";
import ContactStageRouting from "Pages/Dashboard/settings/ContactStages/ContactStageRouting";
import WebsiteTracking from "Pages/Dashboard/settings/WebsiteTracking/main";
import DomainHealthCenter from "Pages/Dashboard/settings/DomainHealthCenter/main";
import DialerModule from "Pages/Dashboard/settings/Calls/Main";
import TasksOperations from "Pages/Dashboard/settings/TasksOperations";
import ProfileAndEmailPreferences from "Pages/Dashboard/settings/ProfileAndEmailPreferences";
import CrmRouting from "Pages/Dashboard/settings/Crm/CrmRouting";
import ConnectedAccounts from "Pages/Dashboard/settings/ConnectedAccounts/main";

const SettingsRouting = props => {
  const {
    team = "",
    isTeamOwner = false,
    calendarEnabled = false
  } = userDetail?.userInfo || {};

  return (
    <Switch>
      <Route
        path="/settings/account"
        render={() => <ConnectedAccounts {...props} />}
      />
      {(team || isTeamOwner) && (
        <Route
          path={`/settings/team`}
          render={() => <UsersAndTeams {...props} />}
        />
      )}
      <Route path="/settings/unsubscribe" render={() => <Unsubscribe />} />
      <Route
        path="/settings/schedules"
        render={() => <Schedules {...props} />}
      />
      {team && (
        <Route
          path="/settings/imports"
          render={() => <CsvImports {...props} />}
        />
      )}
      <Route
        path="/settings/bulk-actions"
        render={() => <TasksOperations {...props} />}
      />
      {team && <Route path="/settings/triggers" render={() => <Triggers />} />}
      <Route path="/settings/crm" component={CrmRouting} />
      <Route path="/settings/billing" render={() => <UserBilling />} />
      {team && (
        <Route path="/settings/creditUsage" render={() => <CreditsUsage />} />
      )}
      <Route path="/settings/autoBcc" render={() => <AutoBcc />} />
      <Route path="/settings/dialer" render={() => <DialerModule />} />
      <Route path="/settings/customUrl" render={() => <CustomURL />} />
      <Route path="/settings/customFields" render={() => <CustomFields />} />
      <Route path="/settings/stages" render={() => <ContactStageRouting />} />
      <Route
        path="/settings/general"
        render={() => <ProfileAndEmailPreferences />}
      />

      {team && (
        <Route
          path="/settings/doNotContact"
          render={() => <DoNotContactDomain />}
        />
      )}
      <Route path="/settings/emailSettings" render={() => <SafetySettings />} />
      {calendarEnabled && (
        <Route
          path="/settings/calendar"
          render={props => <Calendar {...props} />}
        />
      )}
      <Route
        path="/settings/website-tracking"
        render={props => <WebsiteTracking {...props} />}
      />
      <Route
        path="/settings/domain-health-center"
        render={props => <DomainHealthCenter {...props} />}
      />
      <Redirect from="/" to="/settings/general" />
    </Switch>
  );
};

export default withRouter(observer(SettingsRouting));
