import React, { useState } from "react";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import CustomTooltip from "Components/common/CustomTooltip";
import { bulkAddTagsPopupState } from "Components/common/ContactFilter/BulkOperations/BulkAddTagsPopup";

const AddRemoveTags = (props = {}) => {
  const {
    tagsArr = [],
    email = "",
    contactName = "",
    reloadCbk = () => {}
  } = props || {};

  const [updatedTagsArr, setUpdatedTagsArr] = useState(tagsArr);

  const getPayLoad = (contactTags = []) => {
    return {
      contacts: [{ email }],
      contactTags
    };
  };

  const addTagsCbk = async (resetTagCbk = () => {}) => {
    let tempTagsList = [
      ...new Set([...updatedTagsArr, ...bulkAddTagsPopupState?.filteredData])
    ];
    await handleAddRemoveTags(tempTagsList);
    resetTagCbk();
  };

  const removeTags = (tagName = "") => {
    if (updatedTagsArr?.length > 0) {
      let removeTagsArr = updatedTagsArr.filter(item => item !== tagName);
      handleAddRemoveTags(removeTagsArr, true);
    }
  };

  const handleAddRemoveTags = async (selectedTags = [], isDelete = false) => {
    bulkAddTagsPopupState.setButtonLoader(true);
    const config = {
      url: URLS?.addRemoveTags,
      method: "POST",
      data: getPayLoad(selectedTags)
    };
    const response = await makeApi(config);
    handleApiResponse(response, isDelete);
    bulkAddTagsPopupState.setButtonLoader(false);
    bulkAddTagsPopupState.setShowPopup(false);
    reloadCbk();
  };

  const handleApiResponse = (response = {}, isDelete = false) => {
    if (response?.data) {
      const { status = "", contact = [] } = response?.data || {};
      if (status?.toLowerCase() === "success" && +response?.status === 200) {
        let contactTags = contact?.[0]?.contactTags || [];
        setUpdatedTagsArr(contactTags);
        let message = `Tag${isDelete ? "" : "s"} ${
          isDelete ? "deleted" : "updated"
        } successfully`;
        toasterState.setToastMsg(message, "success");
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "fail"
        );
      }
    }
  };

  return (
    <div className="tagsWrapper">
      <AddTagButton addTagsCbk={addTagsCbk} contactName={contactName} />
      <SelectedTagList
        updatedTagsArr={updatedTagsArr}
        removeTags={removeTags}
        tagsArr={tagsArr}
      />
    </div>
  );
};

const AddTagButton = (props = {}) => {
  const { addTagsCbk = () => {}, contactName = "" } = props || {};
  const [showToolTip, setShowToolTip] = useState(false);

  const handleClose = () => {
    setShowToolTip(false);
    bulkAddTagsPopupState.setDefaultValues({
      contactsCount: 1,
      name: contactName,
      cbk: addTagsCbk,
      isContactCard: true
    });
    bulkAddTagsPopupState.setShowPopup(true);
  };

  return (
    <div
      className="addContactTagBtn"
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      <i className="material-icons" onClick={handleClose}>
        add
      </i>
      {showToolTip && <CustomTooltip text="Add Tags" />}
    </div>
  );
};

const SelectedTagList = (props = {}) => {
  const { updatedTagsArr = [], removeTags = () => {} } = props || {};

  const [showMoreButton, setShowMoreButton] = useState(false);

  return updatedTagsArr?.length > 0 ? (
    <div
      className={`tagsList ${
        updatedTagsArr?.length > 1 ? "multiple" : "single"
      }`}
    >
      {updatedTagsArr?.map((item, index) => {
        if ((!showMoreButton && index < 4) || showMoreButton) {
          return (
            <div className="tagItem" key={item}>
              <span className="name" title={item}>
                {item}
              </span>
              <i
                className="material-icons close"
                onClick={() => removeTags(item)}
              >
                close
              </i>
            </div>
          );
        }
      })}
      {updatedTagsArr?.length > 4 && (
        <div
          className="highlightedButton"
          onClick={() => setShowMoreButton(!showMoreButton)}
        >
          <u>{!showMoreButton ? "show more" : "show less"}</u>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default AddRemoveTags;
