import React, { useEffect, useState } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import NotesInput from "Pages/Dashboard/sequences/Stages/NotesInput.js";
import Button from "Components/common/Button";
import Utils from "Utils/utils";

const defaultPopupValue = {
  slackMessage: "",
  createCbk: () => {},
  triggerCbk: () => {}
};
class SlackNotificationState {
  @observable showPopup = false;
  @observable popupValues = { ...defaultPopupValue };

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
    if (!value) {
      this.popupValues = { ...defaultPopupValue };
    }
  };

  @action
  setPopupValues(obj) {
    this.popupValues = { ...this.popupValues, ...obj };
  }

  @action
  setResetValue() {
    this.popupValues = { ...defaultPopupValue };
  }
}

const slackNotificationState = new SlackNotificationState();

const CreateSlackNotification = observer(() => {
  const {
    slackMessage: note,
    pageSource,
    isFromTriggers,
    triggerType = ""
  } = slackNotificationState?.popupValues || {};

  const defaultObj = {
    CONTACT_CREATED: "{{firstName}} contact was created by {{userName}}",
    CONTACT_UPDATED:
      "{{firstName}} was updated by {{userName}}\n{{fieldName}}: {{oldValue}} || {{newValue}}",
    CONTACT_OPTEDOUT: "{{firstName}} opted out from all the sequences.",
    EMAIL_BOUNCED: "{{firstName}} was bounced",
    EMAIL_DELIVERED: "{{email}} was sent an email from {{userName}}",
    EMAIL_OPENED:
      "{{firstName}} opened your email from sequence {{sequenceName}}.",
    EMAIL_CLICKED:
      "{{firstName}} clicked a link in your email sent from the sequence {{sequenceName}}.\nLink: {{linkClicked}}",
    CALL_LOGGED:
      "{{userName}} logged a call for {{firstName}}.\nCall outcome: {{callOutcome}}\nCall Notes: {{callNotes}}",
    EMAIL_REPLIED: "{{firstName}} replied to an email.",
    WEBSITE_VISITED:
      "{{firstName}} visited your website.\nPage visited: {{visitedPageURL}}"
  };

  const template = defaultObj?.[triggerType] || "";

  const [notes, setNotes] = useState(template || note);
  const [noteInputCursorPostion, setNoteInputCursorPostion] = useState(0);
  const [isNoteInputFocussed, setNoteInputFocussed] = useState(false);
  const [loader, setLoader] = useState(false);

  const changeNotes = (value = "", isToken = false) => {
    setNoteInputFocussed(true);
    let tempNotes = value;
    if (isToken) {
      tempNotes =
        notes?.substr(0, noteInputCursorPostion) +
        value +
        notes?.substr(noteInputCursorPostion, notes?.length);
    }
    setNotes(tempNotes);
    slackNotificationState.popupValues.notes = tempNotes;
    slackNotificationState.setPopupValues({
      ...slackNotificationState?.popupValues,
      slackMessage: tempNotes
    });
    isToken && Utils.focusInputBox("sendSlackNotificationPopup");
  };

  const closePopup = () => {
    setLoader(false);
    setNoteInputFocussed(false);
    slackNotificationState.setShowPopup(false);
    setNotes("");
  };

  const triggerCloseCbk = () => {
    slackNotificationState.popupValues.triggerCbk("", () => {}, "cancel");
    closePopup();
  };

  const handleAction = () => {
    !isNoteInputFocussed && setNoteInputFocussed(false);
    if (isFromTriggers) {
      slackNotificationState.popupValues.triggerCbk(notes, closePopup, "save");
    }
  };

  const buttonList = [
    {
      id: "slackNotificationCancelBtn",
      name: "slackNotificationCancelBtn",
      btnText: "Cancel",
      type: "button",
      className: `slackNotificationCancelBtn`,
      click: triggerCloseCbk
    },
    {
      id: "saveSlackNotificationBtn",
      name: "saveSlackNotificationBtn",
      btnText: "Save",
      type: "button",
      className: `saveSlackNotificationBtn ${loader ? "loading" : ""}`,
      click: handleAction,
      loader: loader
    }
  ];

  const notesObj = {
    notes: notes,
    action: changeNotes,
    setCursorPostion: setNoteInputCursorPostion,
    pageType: pageSource
  };
  const updateStateOnChange = () => {
    const { slackMessage = "" } = slackNotificationState?.popupValues || {};
    setNotes(slackMessage);
  };

  useEffect(() => {
    updateStateOnChange();
  }, [slackNotificationState?.popupValues]);

  useEffect(() => {
    const newTemplate = defaultObj?.[triggerType] || "";
    if (triggerType) {
      setNotes(newTemplate);
    }
  }, [triggerType]);

  return (
    (slackNotificationState.showPopup && (
      <div className="addSlackNotificationPopup">
        <div
          className={`card animated customzoomIn ${
            loader ? "disableClick" : ""
          }`}
        >
          <div className="cardHeader">
            <div className="textWrapper">
              <h4 className="title">Send slack notification</h4>
              <p className="desc">
                A slack notification will be sent to your slack, every time a
                contact matches this condition.
              </p>
            </div>
            <i className="material-icons close" onClick={triggerCloseCbk}>
              close
            </i>
          </div>
          <div className="cardBody">
            <NotesInput {...notesObj} />
          </div>
          <div className="btnFooter">
            {buttonList?.map((item, index) => (
              <Button key={index} {...item} />
            ))}
          </div>
        </div>

        <div className="overlay" />
      </div>
    )) ||
    ""
  );
});

export default CreateSlackNotification;
export { slackNotificationState };
