import React from "react";

const AddItem = props => {
  const {
    addCbk = () => {},
    name = "",
    index = "",
    className = ""
  } = props || {};

  return (
    <div className={`addNewItem ${className}`} onClick={() => addCbk(index)}>
      <i className="material-icons">add</i>
      <span className="btnText">{name}</span>
    </div>
  );
};

export default AddItem;
export { AddItem };
