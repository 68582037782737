import React, { useState, useRef, useEffect } from "react";
import Utils from "Utils/utils";
import SearchInputBox from "Components/common/SearchInputBox";
import Tooltip from "Components/common/CustomTooltip";

const SingleSelectDropdown = props => {
  const {
    id = "",
    suffixLabel = "",
    readOnly = false,
    mandatory = false,
    search = false,
    options = [],
    timeZone = false,
    value = "",
    text = "",
    err = "",
    showInfoIcon = "",
    infoText = "",
    tabIndex = 0,
    change = () => {},
    disabled = false,
    displayName = "name",
    showLabel = true,
    defaultLabel = ""
  } = props || {};

  const searchBoxRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [data, setData] = useState(options);
  const [clearSearch, setClearSearch] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);

  const toggleDropdown = () => {
    if (!readOnly) {
      showInfoIcon && showToolTip && setShowToolTip(false);
      let dropdownValue = !showDropdown;
      setShowDropdown(dropdownValue);
      if (dropdownValue && search) {
        searchBoxRef && searchBoxRef.current && searchBoxRef.current.focus();
      }
    }
  };

  const changeAction = (event = "") => {
    let dataValue = Utils.performActionByClosestDom(event, "li", "data-key");
    let uniqueValue = Utils.performActionByClosestDom(
      event,
      "li",
      "data-value"
    );
    if (dataValue) {
      if (displayName === "displayName") {
        change(id, uniqueValue, dataValue);
      } else {
        change(id, dataValue, uniqueValue);
      }
      setClearSearch(true);
      setShowDropdown(false);
    }
    setData(options);
  };

  const performSearch = (value = "") => {
    let tempOpt = [...options];
    if (value?.toLowerCase()?.trim()) {
      tempOpt = tempOpt.filter(item =>
        (timeZone ? item?.[displayName] : item)
          ?.toString()
          ?.toLowerCase()
          ?.trim()
          ?.includes(value?.toLowerCase())
      );
    }
    setData(tempOpt);
  };

  useEffect(() => {
    return () => {
      setShowDropdown(false);
      setData([]);
      setClearSearch(false);
    };
  }, []);

  return (
    <div
      className={`singleSelectDropdown ${disabled ? "disabled" : ""}`}
      id={id}
      tabIndex={tabIndex}
    >
      {text && (
        <label
          className={`selectOption ${readOnly ? "readOnly" : ""} ${
            value ? "selectedValue" : ""
          } ${showDropdown ? "activeDropdown" : ""}`}
          onClick={toggleDropdown}
        >
          <div className="selectLabel">
            {showLabel && (
              <div className="labelName">
                {text}
                {mandatory && <span className="required">*</span>}
                {showInfoIcon && (
                  <span className="infoIcon">
                    <i
                      className="material-icons"
                      onMouseOver={() => setShowToolTip(true)}
                      onMouseLeave={() => setShowToolTip(false)}
                    >
                      info_outline
                    </i>
                    {showToolTip && <Tooltip text={infoText} />}
                  </span>
                )}
              </div>
            )}
            <div className="activeValue" title={`${value} ${suffixLabel}`}>
              {value ? `${value} ${suffixLabel}` : defaultLabel}
            </div>
          </div>
          {!readOnly && (
            <>
              <i className="material-icons-outlined arrowUpDown">
                {showDropdown ? "expand_less" : "expand_more"}
              </i>
              {showDropdown && <span className="focusBorder" />}
            </>
          )}
        </label>
      )}
      {showDropdown && (
        <div className="dropdownOptions animatedFast">
          {(options?.length > 0 && (
            <>
              {search && (
                <SearchInputBox
                  valueCbk={performSearch}
                  type="text"
                  placeholder="Search"
                  searchBoxRef={searchBoxRef}
                  clearSearchInput={clearSearch}
                />
              )}
              <ul
                className="selectOptionDropdown"
                onClick={e => changeAction(e)}
              >
                {data?.map((item, index) => {
                  const finalValue =
                    displayName === "displayName" ? item?.name : item?.value;
                  let itemValue = timeZone ? item?.[displayName] : item;
                  let uniqueValue = timeZone ? finalValue : item;
                  return (
                    <li
                      key={index}
                      data-key={itemValue}
                      data-value={uniqueValue}
                      className={`option ${
                        (value === itemValue && "selected") || ""
                      }`}
                      title={`${itemValue} ${suffixLabel}`}
                    >
                      {`${itemValue} ${suffixLabel}`}
                    </li>
                  );
                })}
              </ul>
            </>
          )) ||
            ""}
          {data?.length < 1 && <div className="emptyOption">No data found</div>}
        </div>
      )}
      {showDropdown && (
        <div
          className="selectOptionOverlay"
          onClick={() => setShowDropdown(false)}
        />
      )}
      {err && <span className="errMsgText">{err}</span>}
    </div>
  );
};

export default SingleSelectDropdown;
export { SingleSelectDropdown };
