/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import Lottie from "react-lottie";
import { Utils } from "Utils/utils";
import Button from "Components/common/Button";
import Logo from "Assets/website/logo.png";
import LiveFeedBrowserImg from "Assets/png/liveFeedBrowser.png";
import { ReactComponent as PuzzlePieceIcon } from "Assets/svg/puzzlePieceIcon.svg";
import PluginPinPopupImg from "Assets/png/pluginSuccess.png";
import TickAnim from "Utils/LottieFiles/tickOrangeAnim.json";
import GreenTick from "Assets/png/greentick.png";
import { ReactComponent as RightArrow } from "Assets/svg/rightArrowBlue.svg";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: TickAnim,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const pluginContent = [
  "Get real-time notifications for email opens and clicks",
  "Integrates with multiple Gmail inboxes ",
  "Log your email activity"
];

const PinPluginPopup = (props = {}) => {
  const { handleClick = () => {} } = props || {};
  return (
    <div className="pinPluginPopupWrapper">
      <span className="sharpPointer"></span>
      <div className="greenTickImageWrapper">
        <img className="greenTick" src={GreenTick} alt="green tick" />
      </div>
      <div className="textContent">
        <p className="adaptInstalledText">
          Salesgear for chrome has <br /> installed successfully.
        </p>
        Click the
        <PuzzlePieceIcon />
        icon to pin Salesgear <br /> for easy access
      </div>
      <div className="imageWrapper">
        <img
          className="pluginPinImg"
          src={PluginPinPopupImg}
          alt="plugin pin"
        />
      </div>
      <div className="buttonWrapper">
        <button className="pluginPinOkButton" onClick={handleClick}>
          <span>Ok, Done</span>
          <RightArrow />
        </button>
      </div>
    </div>
  );
};

const PluginSuccess = () => {
  let location = useLocation();
  let queryParams = Utils.getQueryParams(location.search);
  let isNewUser = queryParams.get("type") === "signup" || false;

  const [showPluginPopup, setShowPluginPopup] = useState(true);
  const [btnText, setBtnText] = useState("");
  const [urlPath, setUrlPath] = useState("");

  const createUrlPath = (
    name = "Gmail",
    url = "https://mail.google.com/mail/u/0/"
  ) => {
    setBtnText(name);
    setUrlPath(url);
  };

  const getQueryParamsRedirect = () => {
    switch (queryParams.get("pageType")) {
      case "salesforce":
        createUrlPath("Salesforce", queryParams.get("url"));
        break;
      case "pipedrive":
        createUrlPath("Pipedrive", queryParams.get("url"));
        break;
      case "zoho":
        createUrlPath("Zoho", queryParams.get("url"));
        break;
      case "hubspot":
        createUrlPath("Hubspot", queryParams.get("url"));
        break;
      default:
        createUrlPath();
        break;
    }
  };

  const navToUrlPathFromPlugin = () => {
    window.open(urlPath, "_blank", "noopener");
  };

  const setUpMyAccount = () => {
    // window.parent.postMessage(
    //   { data: "message", type: "OPEN_LIVE_FEED_SETTING" },
    //   "*"
    // );
    window.open("https://mail.google.com/mail/u/0/", "_self", "noopener");
  };

  useEffect(() => {
    getQueryParamsRedirect(location);
  }, []);

  return (
    <section id="pluginSuccessPage" className="pluginSuccessPage">
      <div className="container">
        <div className="pluginSuccessBody">
          <Lottie
            options={defaultOptions}
            width={160}
            height={160}
            style={{ margin: "0 auto" }}
          />
          {isNewUser && (
            <div className="signupPluginContent">
              <p className="textDesc">
                You have successfully activated your
                <br />
                <b>Salesgear account</b>
              </p>
              <Button
                id="setUpMyAccount"
                name="setUpMyAccount"
                btnText={`Go to your Inbox`}
                icon={true}
                iconName="keyboard_backspace"
                type="button"
                className="setUpMyAccount rotateForwardIcon"
                loader={false}
                click={() => setUpMyAccount()}
              />
              <img
                src={LiveFeedBrowserImg}
                alt=""
                className="liveFeedBrowserImg"
              />
            </div>
          )}
          {!isNewUser && (
            <div className="loginPluginContent">
              <p className="textDesc">
                Accomplish more with Salesgear for <b>{btnText}</b>
              </p>
              <ul>
                {pluginContent?.map((item, index) => (
                  <li key={`pluginContent${index}`}>{item}</li>
                ))}
              </ul>
              {btnText && (
                <div className="navToPluginUrl">
                  <Button
                    id="goToUrlPathFromPlugin"
                    name="goToUrlPathFromPlugin"
                    btnText={`Go to your ${btnText}`}
                    type="button"
                    className="goToUrlPathFromPlugin rotateForwardIcon"
                    icon={true}
                    iconName="keyboard_backspace"
                    click={navToUrlPathFromPlugin}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showPluginPopup && (
        <PinPluginPopup handleClick={() => setShowPluginPopup(false)} />
      )}
    </section>
  );
};

export default withRouter(observer(PluginSuccess));
export { PluginSuccess };
