import React, { useState, useRef } from "react";
import CheckBox from "Components/common/CheckBox";
import DropdownButton from "Pages/Dashboard/contacts/ContactFilter/DropdownButton";
import Utils from "Utils/utils";
import { ContactCardFunction } from "Components/ContactCard/ContactCardFunction";

export const MutliSelectDropdown = (props = {}) => {
  const {
    label = "",
    keyValue = "",
    data = [],
    activeFilter = "",
    selected = [],
    setAction = () => {},
    setActiveFilter = () => {}
  } = props || {};

  let toggleDropdown = activeFilter === keyValue;

  const inputRef = useRef(null);

  const [showInsetDropdown, setShowInsetDropdown] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [list, setList] = useState([...data]);

  const btnLabel = {
    label,
    count: selected?.length,
    keyValue,
    toggleDropdown,
    callback: setActiveFilter
  };

  const handleReset = () => {
    let tempList = [...list].map(item => {
      item.checked = false;
      return item;
    });
    setAction(keyValue, tempList);
  };

  const getLabelNameText = (item = {}) => {
    const { value = "", name = "" } = item || {};
    return keyValue === "owners"
      ? ContactCardFunction.getName(item)
      : keyValue === "tags"
      ? value
      : name;
  };

  const focusInputBox = () => {
    inputRef && inputRef.current && inputRef.current.focus();
  };

  return (
    <div className="multiSelectOption" id={keyValue}>
      <DropdownButton {...btnLabel} />
      {toggleDropdown &&
        (data?.length > 0 ? (
          <div className="insetDropdown">
            <span className="horizontalBorderLine" />
            <SearchInputDropdown
              {...props}
              searchInput={searchInput}
              list={list}
              showInsetDropdown={showInsetDropdown}
              setList={setList}
              setSearchInput={setSearchInput}
              setShowInsetDropdown={setShowInsetDropdown}
              getLabelNameText={getLabelNameText}
              inputRef={inputRef}
              focusInputBox={focusInputBox}
            />
            {showInsetDropdown ? (
              <Dropdown
                {...props}
                list={list}
                showInsetDropdown={showInsetDropdown}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                setShowInsetDropdown={setShowInsetDropdown}
                getLabelNameText={getLabelNameText}
                focusInputBox={focusInputBox}
              />
            ) : (
              (selected?.length > 0 && (
                <div className="selectedItemsCont">
                  <SelectedItemList
                    {...props}
                    list={list}
                    getLabelNameText={getLabelNameText}
                  />
                  <i
                    className="material-icons-outlined clearAll"
                    onClick={handleReset}
                  >
                    clear_all
                  </i>
                </div>
              )) || <></>
            )}
          </div>
        ) : (
          <div className="noDataAvailable">No data available</div>
        ))}
    </div>
  );
};

const SearchInputDropdown = ({
  keyValue = "",
  data = [],
  showInsetDropdown = false,
  placeholder = "",
  searchInput = "",
  inputRef = "",
  setShowInsetDropdown = () => {},
  getLabelNameText = () => {},
  setSearchInput = () => {},
  setList = () => {},
  focusInputBox = () => {}
}) => {
  const dropdownRef = useRef(null);

  const filterBySearchText = (value = "") => {
    let tempList = [...data].map(item => {
      let name = getLabelNameText(item);
      item.hide = value
        ? name?.toString()?.toLowerCase().includes(value?.toLowerCase())
          ? false
          : true
        : false;
      return item;
    });
    setList(tempList);
  };

  const handleInputChange = (text = "") => {
    setSearchInput(text?.trimStart() || "");
    filterBySearchText(text?.trimStart() || "");
  };

  const clearSearchInput = (event = "") => {
    Utils.preventDefaultFn(event);
    setSearchInput("");
    filterBySearchText("");
    focusInputBox();
  };

  const inputObj = {
    type: "text",
    className: "searchInput",
    onChange: e => handleInputChange(e.currentTarget.value),
    value: searchInput,
    placeholder: placeholder
  };

  const scrollIntoDropdownView = () => {
    setTimeout(() => {
      if (["sequences", "emailType", "tags", "listIds"].includes(keyValue)) {
        dropdownRef &&
          dropdownRef.current &&
          dropdownRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
          });
      }
    }, 150);
  };

  const handleToggleAction = () => {
    if (!showInsetDropdown) {
      setShowInsetDropdown(true);
      scrollIntoDropdownView();
    }
  };

  return (
    <div
      className={`searchValueInputBox ${
        searchInput?.length > 0 ? "textEntered" : ""
      }`}
      onClick={handleToggleAction}
      ref={dropdownRef}
    >
      <input {...inputObj} ref={inputRef} />
      {searchInput?.length > 0 && (
        <i
          className="material-icons clearIpIcon"
          onClick={e => clearSearchInput(e)}
        >
          close
        </i>
      )}
      <i
        className={`material-icons arrowIcon ${
          showInsetDropdown ? "rotate" : ""
        }`}
      >
        keyboard_arrow_down
      </i>
    </div>
  );
};

const Dropdown = ({
  keyValue = "",
  data = [],
  list = [],
  loader = false,
  searchInput = "",
  setShowInsetDropdown = () => {},
  getLabelNameText = () => {},
  setAction = () => {},
  setSearchInput = () => {},
  focusInputBox = () => {}
}) => {
  let isSequencesType = keyValue?.toLowerCase() === "sequences";
  let dataLength = list?.length || 0;
  let count = 0;

  const handleSelectAction = (value = "", uId = "") => {
    if (uId) {
      let tempList = [...data].map(item => {
        const { id = "" } = item || {};
        if (id === uId) {
          item.checked = value;
        }
        item.hide = false;
        return item;
      });
      focusInputBox();
      searchInput && setSearchInput("");
      setAction(keyValue, tempList);
    }
  };

  const closeDropdown = () => {
    setShowInsetDropdown(false);
  };

  return (
    <div className="ddBox animatedFast animatedDropDown">
      <ul className="ddList">
        {(data?.length > 0 &&
          data.map((item, index) => {
            const { id = "", checked = false, hide = false } = item || {};
            let label = getLabelNameText(item);
            hide && (count += 1);
            let isLastIndex = dataLength - 1 === index;
            let isAllHide = +count === +dataLength;
            return isLastIndex && isAllHide ? (
              <li className={`ddItem noResultsFound`} key={id}>
                No results found
              </li>
            ) : (
              !hide && id && (
                <li className={`ddItem`} key={id}>
                  <CheckBox
                    name={label}
                    id={id}
                    checked={checked}
                    hasCustomDom={isSequencesType}
                    dom={isSequencesType ? <SequenceLabel {...item} /> : <></>}
                    cbk={handleSelectAction}
                  />
                </li>
              )
            );
          })) ||
          ""}
        {loader && (
          <li className="ddItem ddLoader" key={`${keyValue}Loader`}>
            <div className="spinnerLoader">
              <i className="fa fa-spinner fa-spin"></i>
              <span className="text">Loading</span>
            </div>
          </li>
        )}
      </ul>
      {/* <Button {...applyBtn} /> */}
      <div className="ddOverlay" onClick={closeDropdown} />
    </div>
  );
};

const SelectedItemList = ({
  count = 3,
  keyValue = "",
  selected = [],
  data = [],
  setAction = () => {},
  getLabelNameText = () => {}
}) => {
  const [expandCollapse, setExpandCollapse] = useState(false);

  const removeSelectedItem = (event = "") => {
    if (event) {
      let uId = Utils.performActionByClosestDom(event, "i", "data-key");
      if (uId) {
        let tempList = [...data].map(item => {
          const { id = "" } = item || {};
          if (id === uId) {
            item.checked = false;
          }
          return item;
        });
        setAction(keyValue, tempList);
      }
    }
  };

  return (
    <ul className="selectedList" onClick={e => removeSelectedItem(e)}>
      {selected.map((item, index) => {
        const { id = "" } = item || {};
        if (index < count || expandCollapse) {
          let label = getLabelNameText(item);
          return (
            <li className="chip" key={id}>
              <span className="name" title={label}>
                {label}
              </span>
              <i className="material-icons removeIcon" data-key={id}>
                close
              </i>
            </li>
          );
        }
      })}
      {selected?.length > count && (
        <div
          className="expandCollapse"
          onClick={() => setExpandCollapse(!expandCollapse)}
        >
          <span className="count">
            {expandCollapse ? "less" : `+${selected?.length - count}`}
          </span>
          <i
            className={`material-icons arrowIcon ${
              expandCollapse ? "rotate" : ""
            }`}
          >
            keyboard_arrow_down
          </i>
        </div>
      )}
    </ul>
  );
};

const SequenceLabel = ({
  shareType = "",
  accessType = "",
  owner = {},
  name = "",
  memberId = "",
  folderName = ""
}) => {
  return Utils.getEntityNameUpdated(
    shareType,
    accessType,
    owner,
    name,
    memberId,
    folderName || "My Sequences"
  );
};

export default MutliSelectDropdown;
