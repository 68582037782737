import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { COLOR_CODES } from "Pages/Dashboard/settings/Calls/Constant";
import dialerStoreData from "Stores/DialerData";
import { Button } from "Components/common/Button";
import InputTextBox from "Components/common/InputTextBox";
import Utils from "Utils/utils";
import DropDownMenu from "Components/common/DropDownMenu";

export const defaultPopupValues = {
  id: "",
  name: "",
  mapTo: "Not Answered",
  readOnly: false,
  type: "new",
  btnText: "Create",
  colorCodes: [...COLOR_CODES]
};

class CreateCallStagePopupState {
  @observable showPopup = false;
  @observable buttonLoader = false;
  @observable popupValues = { ...defaultPopupValues };

  @action
  setShowPopup = (val = false) => {
    this.showPopup = val;
  };

  @action
  setPopupValues = (obj = {}) => {
    this.popupValues = { ...obj };
  };

  @action
  setButtonLoader = (val = false) => {
    this.buttonLoader = val;
  };
}

const createCallStagePopupState = new CreateCallStagePopupState();

const CreateCallStagePopup = observer(() => {
  const inputBoxRef = useRef();

  const {
    id = "",
    name = "",
    mapTo = "Not Answered",
    readOnly = false,
    type = "new",
    btnText = "Create",
    color = "",
    colorCodes = [...COLOR_CODES]
  } = createCallStagePopupState?.popupValues || {};

  const [showDropdown, setDropdown] = useState(false);
  const [err, setErr] = useState("");
  const [colorErr, setColorErr] = useState("");

  const handleChange = (_, val = "") => {
    err && setErr("");
    createCallStagePopupState.setPopupValues({
      ...createCallStagePopupState?.popupValues,
      name: val
    });
  };

  const handleDropdownAction = (_, item) => {
    createCallStagePopupState.setPopupValues({
      ...createCallStagePopupState?.popupValues,
      mapTo: item?.value
    });
    setDropdown(false);
  };

  const handleUpdateAction = (event = "") => {
    Utils.preventDefaultFn(event);
    if (name?.trim()?.length > 0) {
      if (color) {
        createCallStagePopupState.setButtonLoader(true);
        let payload = {
          name: name?.trim() || "",
          mapTo,
          color
        };
        if (type === "new") {
          dialerStoreData.createNewCallStage(payload);
        } else {
          dialerStoreData.updateCallStage(
            {
              id,
              ...payload
            },
            "update"
          );
        }
      } else {
        setColorErr("Please select a color");
      }
    } else {
      setErr("Please enter a call state name");
    }
  };

  const handleCreateStage = (event = "") => {
    Utils.isSubmitKeyAction(event) && handleUpdateAction();
  };

  const close = () => {
    err && setErr("");
    createCallStagePopupState.setPopupValues(defaultPopupValues);
    createCallStagePopupState.setShowPopup(false);
  };

  const inputProps = {
    name: "createStageInputBox",
    id: "createStageInputBox",
    text: "Name",
    type: "text",
    value: name,
    change: handleChange,
    err,
    cbk: handleCreateStage,
    readOnly,
    inputBoxRef: inputBoxRef
  };

  const btnProps = {
    id: "createStageButton",
    name: "createStageButton",
    btnText,
    type: "button",
    className: `createStageButton ${
      createCallStagePopupState?.buttonLoader ? "loading" : ""
    }`,
    loader: createCallStagePopupState?.buttonLoader,
    click: e => handleUpdateAction(e),
    disabled: name && color && mapTo ? false : true
  };

  const dropdownOptions = [
    {
      value: "Not Answered",
      key: "notAnswered",
      method: handleDropdownAction
    },
    {
      value: "Answered",
      key: "answered",
      method: handleDropdownAction
    }
  ];

  const handleDropdown = () => {
    setDropdown(!showDropdown);
  };

  const handleColorUpdate = (ele = "") => {
    if (ele) {
      colorErr && setColorErr("");
      createCallStagePopupState.setPopupValues({
        ...createCallStagePopupState.popupValues,
        color: ele
      });
    }
  };

  useEffect(() => {
    if (createCallStagePopupState?.showPopup) {
      inputBoxRef && inputBoxRef.current && inputBoxRef.current.focus();
    } else {
      setDropdown(false);
    }
  }, [createCallStagePopupState?.showPopup]);

  return (
    createCallStagePopupState?.showPopup && (
      <section className="createCallStagePopup">
        <div className="card animated customzoomIn">
          <div className="popupHeader">
            <div className="title">Call Disposition</div>
            <i className="material-icons commonPopupCloseIcon" onClick={close}>
              close
            </i>
          </div>
          <hr className="seperator" />
          {colorCodes?.length > 0 && (
            <div className="colorCodes">
              <label>Choose Color</label>
              <div className="codeList">
                {colorCodes?.map(ele => {
                  return (
                    <span
                      key={ele}
                      style={{ background: ele }}
                      className={`boxSquare ${color === ele ? "active" : ""}`}
                      onClick={() => handleColorUpdate(ele)}
                    />
                  );
                })}
              </div>
              {colorErr && (
                <span
                  className="errMsgText"
                  dangerouslySetInnerHTML={{
                    __html: colorErr || ""
                  }}
                />
              )}
            </div>
          )}
          <InputTextBox {...inputProps} />
          <div className="callMapToDropdown">
            <>
              <label onClick={handleDropdown}>Map to</label>
              <div className="activeDropdownValue" onClick={handleDropdown}>
                <span className="activeValue">{mapTo || "Not Answered"}</span>
                <i className="material-icons-outlined">
                  {showDropdown ? "expand_less" : "expand_more"}
                </i>
              </div>
            </>
            {showDropdown && (
              <>
                <DropDownMenu options={dropdownOptions} />
                <div
                  className="dropdownOverlay"
                  onClick={() => setDropdown(false)}
                />
              </>
            )}
          </div>
          <Button {...btnProps} />
        </div>
        <div className="common-popup-mask" />
      </section>
    )
  );
});

export { CreateCallStagePopup, createCallStagePopupState };
export default CreateCallStagePopup;
