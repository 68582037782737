import React, { useState } from "react";
import { observer } from "mobx-react";
import DropDownMenu from "Components/common/DropDownMenu";
import { ReactComponent as FilterIcon } from "Assets/svg/filter.svg";
import connectedInboxStore from "./store";

const FilterHeader = observer((props = {}) => {
  const {
    showDropdown = false,
    setShowDropdown = () => {},
    filterName = ""
  } = props || {};

  return (
    <div className="filterBtn" onClick={() => setShowDropdown(!showDropdown)}>
      <FilterIcon />
      <div className="filterTextWrapper">
        <span className="filterTxt">{filterName}</span>
        <i className="material-icons dropdownFilterIcon">
          {showDropdown ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
      </div>
    </div>
  );
});

const FilterByWarming = observer(() => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterName, setFilterName] = useState("All");

  const handleFilterAction = (ref = {}, item = {}) => {
    setShowDropdown(false);
    connectedInboxStore.setPageNo(1);
    connectedInboxStore.setFilterChanged(true);
    const filterTypes = {
      filterWarmingEnabled: false,
      filterWarmingDisabled: false,
      filterByError: false
    };
    if (item?.name === "ALL") {
      setFilterName("All");
      connectedInboxStore.setWarmingFilter({});
    }
    if (item?.name === "WARMING_ENABLED") {
      setFilterName("Warming In Progress");
      connectedInboxStore.setWarmingFilter({
        ...filterTypes,
        filterWarmingEnabled: true
      });
    }
    if (item?.name === "WARMING_DISABLED") {
      setFilterName("Warming Disabled");
      connectedInboxStore.setWarmingFilter({
        ...filterTypes,
        filterWarmingDisabled: true
      });
    }
    if (item?.name === "ERRORS") {
      setFilterName("Connection Failed");
      connectedInboxStore.setWarmingFilter({
        ...filterTypes,
        filterByError: true
      });
    }
  };

  const dropdownOptions = [
    {
      value: "All",
      key: "All",
      name: "ALL",
      method: handleFilterAction
    },
    {
      value: "Warming In Progress",
      key: "warmingEnabled",
      name: "WARMING_ENABLED",
      method: handleFilterAction
    },
    {
      value: "Warming Disabled",
      key: "warnimgDisabled",
      name: "WARMING_DISABLED",
      method: handleFilterAction
    },
    {
      value: "Connection Failed",
      key: "errors",
      name: "ERRORS",
      method: handleFilterAction
    }
  ];

  return (
    <div className="filterHeaderWrapper">
      <FilterHeader
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        filterName={filterName}
      />
      {showDropdown && (
        <>
          <DropDownMenu
            options={dropdownOptions}
            referenceId="filterByTeamMemberStatus"
          />
          <div
            className="filterDropdownOverlay"
            onClick={() => setShowDropdown(false)}
          />
        </>
      )}
    </div>
  );
});

export { FilterByWarming };
export default FilterByWarming;
