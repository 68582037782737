/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ButtonComponent from "Components/common/Button";
import TeamStore from "Stores/Team";

const TeamHeaderComponent = props => {
  const { loader, cbk } = props;
  const [seatsAvailable, setSeatsAvailable] = useState(TeamStore.seatCount),
    [totalSeats, setTotalSeats] = useState(TeamStore.teamTotalSeats);

  useEffect(() => {
    TeamStore.teamTotalSeats && setTotalSeats(TeamStore.teamTotalSeats);
  }, [TeamStore.teamTotalSeats]);

  useEffect(() => {
    TeamStore.seatCount && setSeatsAvailable(TeamStore.seatCount);
  }, [TeamStore.seatCount]);

  return (
    <div className="teamHeaderSection">
      <div className="teamHeading">
        {seatsAvailable !== null && totalSeats !== null && (
          <span className="seatCount">
            <span className="highlightSeat">{`${seatsAvailable} seat${
              seatsAvailable > 1 ? "s" : ""
            }`}</span>{" "}
            occupied out of{" "}
            <span className="highlightSeat">{`${
              totalSeats >= 100000 ? 50 : totalSeats
            }`}</span>
          </span>
        )}
      </div>
      {!loader && (
        <ButtonComponent
          id="addNewTeam"
          name="addNewTeam"
          type="button"
          className="addNewTeamBtn"
          btnText="Add User"
          icon={false}
          loader={false}
          iconName="keyboard_arrow_down"
          click={cbk}
        />
      )}
    </div>
  );
};

export default withRouter(TeamHeaderComponent);
