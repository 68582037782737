import React from "react";
import { observer } from "mobx-react";
import { withRouter, Link } from "react-router-dom";

const BreadCrumb = props => {
  const defaultArrayValue = [{ name: "Home", link: "/" }];
  const { breadCrumbValue = defaultArrayValue, breadcrumbLoading = false } =
    props;
  const propsValueLen = breadCrumbValue?.length;

  return (
    <div className="breadCrumbcont">
      {breadcrumbLoading
        ? Array.from({ length: 2 }, (value, index) => {
            return (
              <div className="breadCrumbInnerCont" key={index}>
                <span className="seperator">{index > 0 ? "/" : ""}</span>
                <span className="breadValue linear-background"></span>
              </div>
            );
          })
        : breadCrumbValue?.length > 0 &&
          breadCrumbValue?.map((breadValue, key) => {
            const { hasBetaBadge = false } = breadValue || {};
            return (
              <div
                className={`breadCrumbInnerCont ${
                  hasBetaBadge ? "betaBadgeWrap" : ""
                }`}
                key={key}
              >
                <span className="seperator">{key > 0 ? "/" : ""}</span>
                <Link
                  to={breadValue.link}
                  className={
                    propsValueLen === key + 1
                      ? "text-path breadValue"
                      : "breadValue"
                  }
                  data-link={breadValue.link}
                >
                  {breadValue.name}
                  {/* onClick={e => breadCrumbAction(e)}>{breadValue.name} */}
                </Link>
                {hasBetaBadge && <span className="betaBadge">BETA</span>}
              </div>
            );
          })}
    </div>
  );
};

export default withRouter(observer(BreadCrumb));
export { BreadCrumb };
