/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import LogoutHeader from "Pages/LogoutPricing/LogoutHeader";
import LeftContent from "./LeftContent";
import { observer } from "mobx-react";
import { Utils } from "Utils/utils";
import RegisterRouting from "Routes/RegisterRouting";
import TeamContentBody from "./TeamOnboard/TeamContentBody";

const RegisterModule = observer(props => {
  let location = useLocation();
  let queryParams = Utils.getQueryParams(location.search);
  let loginPage = Utils.checkLocationPathname(location.pathname, "/login");
  let signupPage = Utils.checkLocationPathname(location.pathname, "/signup");
  let teamPage = Utils.checkLocationPathname(location.pathname, "/teamInvite/");
  let pluginLoginPage =
    loginPage && queryParams?.get("source")?.includes("plugin");
  let pluginSignupPage =
    signupPage && queryParams?.get("source")?.includes("plugin");
  let dealifySignupPage = Utils.checkLocationPathname(
    location.pathname,
    "/dealify-signup"
  );
  let redirectLoginUrl = `${"/login"}${location?.search}` || "/login";
  let redirectSignupUrl = `${"/signup"}${location?.search}` || "/signup";

  return (
    <>
      <LogoutHeader
        isRegisterPage={true}
        isDealify={dealifySignupPage}
        isLogin={loginPage}
        isSignup={signupPage}
        isPluginLogin={pluginLoginPage}
        isPluginSignup={pluginSignupPage}
        redirectLoginUrl={redirectLoginUrl}
        redirectSignupUrl={redirectSignupUrl}
        isTeamPage={teamPage}
      />
      <main className={`contentViewBody`}>
        {teamPage ? (
          <TeamContentBody />
        ) : (
          <LeftContent
            isPlugin={pluginLoginPage || pluginSignupPage}
            isDealify={dealifySignupPage}
          />
        )}
        <div className={`registerBox ${teamPage ? "whiteBox" : ""}`}>
          <div className="registerForm">
            <RegisterRouting {...props} />
          </div>
        </div>
      </main>
    </>
  );
});

export default withRouter(RegisterModule);
