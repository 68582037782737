/* eslint-disable import/no-unresolved */
import React from "react";
/* eslint-disable import/no-unresolved */
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { ReactComponent as TickIcon } from "Assets/svg/tick.svg";
import { ReactComponent as ErrorIcon } from "Assets/svg/error.svg";
import { ReactComponent as WarningIcon } from "Assets/svg/warning.svg";
import { TOASTER_COLOR } from "Static/constant";

const defaultPopupValues = {
  showUndoButton: false,
  callback: () => {}
};

class ToasterState {
  @observable toastMsg = "";

  isNegativeMessage = false;
  isWarningMessage = false;
  isInfoMessagae = false;
  isUpcomingMessage = false;

  @observable bgcolor = "#28ad56";
  @observable toasterTimeout = 3500;

  @observable showStatusPopup = false;
  @observable popupValues = defaultPopupValues;

  @action
  setToasterTimeout = value => {
    this.toasterTimeout = value;
  };

  @action
  setPopupValues = (obj = {}) => {
    this.popupValues = obj;
  };

  @action
  setToastMsg = (customTxt, msgType) => {
    let str = "";
    if (customTxt !== null && customTxt !== undefined) {
      if (str !== "") str += " <br />";
      str += customTxt;
    }
    this.showStatusPopup = true;
    this.toastMsg = str;
    const thisObj = this;
    if (msgType && msgType.length) {
      switch (msgType.toLowerCase()) {
        case "success":
          this.isNegativeMessage = false;
          this.isWarningMessage = false;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = false;
          this.bgcolor = TOASTER_COLOR.SUCCESS;
          break;
        case "warning":
          this.isNegativeMessage = false;
          this.isWarningMessage = true;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = false;
          this.bgcolor = TOASTER_COLOR.WARNING;
          break;
        case "info":
          this.isNegativeMessage = false;
          this.isWarningMessage = false;
          this.isInfoMessagae = true;
          this.isUpcomingMessage = false;
          this.bgcolor = TOASTER_COLOR.INFO;
          break;
        case "fail" || "failure":
          this.isNegativeMessage = true;
          this.isWarningMessage = false;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = false;
          this.bgcolor = TOASTER_COLOR.DANGER;
          break;
        case "upcoming" || "coming_soon":
          this.isNegativeMessage = false;
          this.isWarningMessage = false;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = true;
          this.bgcolor = TOASTER_COLOR.UPCOMING;
          break;
        default:
          this.isWarningMessage = false;
          this.bgcolor = TOASTER_COLOR.DANGER;
          this.isNegativeMessage = true;
          this.isInfoMessagae = false;
          this.isUpcomingMessage = false;
          break;
      }
    }

    if (window.timeoutVar !== undefined) clearTimeout(window.timeoutVar);
    setTimeout(function () {
      thisObj.showStatusPopup = true;
      thisObj.toastMsg = str;
      window.timeoutVar = setTimeout(function () {
        thisObj.close();
      }, thisObj.toasterTimeout || 3500);
    }, 100);
  };

  @action
  close = () => {
    this.showStatusPopup = false;
    this.isNegativeMessage = false;
    this.isWarningMessage = false;
    this.isInfoMessagae = false;
    this.isUpcomingMessage = false;
    this.toastMsg = "";
    this.bgcolor = "#28ad56";
    this.toasterTimeout = 3500;
    if (this.popupValues.showUndoButton) {
      this.popupValues = defaultPopupValues;
    }
  };
}
const toasterState = new ToasterState();

const Toaster = observer(() => {
  return (
    <>
      <div
        style={{
          backgroundColor: toasterState.bgcolor
        }}
        className={`common-toast-wrapper ${
          toasterState.showStatusPopup ? "show" : ""
        } ${toasterState.isUpcomingMessage ? "upcomingToase" : ""}`}
      >
        <div className="messageCont">
          {toasterState.isNegativeMessage ? (
            <span className="crossIcon svgIcon">
              <ErrorIcon />
            </span>
          ) : toasterState.isWarningMessage ? (
            <span className="svgIcon tickIcon">
              <WarningIcon />
            </span>
          ) : toasterState.isInfoMessagae ? (
            <span className="svgIcon infoIcon">
              <i className="material-icons">info</i>
            </span>
          ) : toasterState.isUpcomingMessage ? (
            ""
          ) : (
            <span className="svgIcon tickIcon">
              <TickIcon />
            </span>
          )}
          <span
            className="message" // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: toasterState.toastMsg || ""
            }}
          />
          {toasterState.popupValues.showUndoButton && (
            <>
              <span
                className="undoButton"
                onClick={() => {
                  toasterState.popupValues.callback();
                  toasterState.close();
                }}
              >
                Undo
              </span>
              <span
                className="closeToaster material-icons"
                onClick={toasterState.close}
              >
                close
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export { Toaster, toasterState };
export default Toaster;
