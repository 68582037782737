import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { calendarState } from "Pages/Dashboard/settings/Calendar/State";
import { CreateEventActionBtn } from "./Components/CreateEventActionBtn";
import SingleSelectDropdown from "./Components/SingleSelectDropdown";
import QuickSelectScheduleHour from "./Components/QuickSelectScheduleHour";
import ScheduleWorkingHour from "./Components/ScheduleWorkingHours";
import InputTextBox from "Components/common/InputTextBox";
import Utils from "Utils/utils";
import { CalendarContext } from "Pages/Dashboard/settings/Calendar/Main";
import {
  PRE_BOOK_DAYS_AVAILABLE,
  MEETING_DURATION,
  REMINDER_IN_MINS
} from "Pages/Dashboard/settings/Calendar/Constants";
import { SettingContext } from "Pages/Dashboard/settings/Settings";
import MeetingBuffer from "./Components/MeetingBuffer";
import StartBuffer from "./Components/StartBuffer";
import userDetail from "Stores/userDetail";

const defaultCreateEventInput = {
  name: "",
  duration: "",
  reminderInMins: "",
  timeZone: "",
  preBookDays: "",
  meetingUrl: "",
  workingHours: {},
  quickSelect: "",
  meetingBuffer: {},
  startBuffer: { label: "HOURS", value: "" }
};

export const Main = observer(() => {
  const { setCreateEvent = () => {} } = useContext(CalendarContext);
  const { updateBreadCrumb = () => {} } = useContext(SettingContext);

  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState(
    calendarState?.editEventInputValue ||
      Utils.createObjWithoutRef(defaultCreateEventInput)
  );
  const [errMsg, setErrMsg] = useState([]);

  const cancelEvent = () => {
    if (calendarState?.editEventInputValue) {
      calendarState.setEditEventInputValue("");
    } else {
      Utils.createObjWithoutRef(defaultCreateEventInput);
    }
    setCreateEvent(false);
    updateBreadCrumb("Calendar Events");
  };

  const inputKeyList = [
    "name",
    "duration",
    "reminderInMins",
    "timeZone",
    "preBookDays",
    "workingHours",
    "meetingBuffer",
    "startBuffer"
  ];

  const hasInvalidInputValue = () => {
    let tempErrList = [];
    inputKeyList.forEach(ele => {
      let value = inputValue?.[ele] || "";
      if (ele === "meetingBuffer") {
        let eventValue = false;
        if (!value?.beforeEventEnabled && !value?.afterEventEnabled) {
          eventValue = true;
        } else if (value?.beforeEventEnabled && value?.afterEventEnabled) {
          eventValue = value?.beforeEventInMins && value?.afterEventInMins;
        } else if (value?.beforeEventEnabled) {
          eventValue = value?.beforeEventInMins;
        } else if (value?.afterEventEnabled) {
          eventValue = value?.afterEventInMins;
        }
        if (!eventValue) {
          tempErrList.push(ele);
        }
      } else if (ele == "startBuffer") {
        value.inputError && tempErrList.push(ele);
      } else if (!value) {
        tempErrList.push(ele);
      } else if (ele === "workingHours" && Object.keys(value)?.length < 1) {
        tempErrList.push(ele);
      }
    });
    return tempErrList?.length > 0;
  };

  const createEvent = () => {
    if (!hasInvalidInputValue()) {
      setLoader(true);
      const callback = (status = "") => {
        setLoader(false);
        if (status) {
          calendarState.setEditEventInputValue("");
          setInputValue(Utils.createObjWithoutRef(defaultCreateEventInput));
          setCreateEvent(false);
          updateBreadCrumb("Calendar Events");
        }
      };
      let payload = { ...inputValue };
      let isEditEvent = calendarState?.editEventInputValue?.id || "";
      // Edit Event passing Id
      if (isEditEvent) {
        payload.id = calendarState.editEventInputValue.id;
      }
      calendarState.createCalendarEvent(
        payload,
        isEditEvent ? "edit" : "create",
        callback
      );
    }
  };

  const updateInputState = (
    key = "",
    value = "",
    uniqueId = "",
    startBufferErrorOccurred = false
  ) => {
    errMsg && setErrMsg("");
    let tempObj = { [key]: value };
    if (["timeZone"].includes(key)) {
      tempObj.timezoneName = uniqueId;
    } else if (["workingHours"].includes(key)) {
      tempObj.quickSelect = uniqueId || "";
    } else if (key.indexOf("startBuffDuration") != -1) {
      // startBuffer handled specially as the data model is different
      tempObj.startBuffer = getStartBufferData(key, value, uniqueId);
      tempObj.startBuffer.inputError = startBufferErrorOccurred;
    }
    setInputValue({ ...inputValue, ...tempObj });
  };

  const getStartBufferData = (key = "", newVal = "", uniqueId = "") => {
    const startBuffer = {};
    const { label, value } = inputValue?.startBuffer || {};
    if (key === "startBuffDurationInterval") {
      // Label change
      startBuffer["label"] = uniqueId;
      startBuffer["value"] = value; // adding existing value to startBuffer Object
    } else {
      // value change
      startBuffer["label"] = label; // adding existing value to startBuffer Object
      startBuffer["value"] = newVal;
    }
    return startBuffer;
  };

  const formInputOptions = [
    {
      name: "name",
      id: "name",
      text: "Event Name",
      type: "text",
      value: inputValue?.name || "",
      change: updateInputState,
      mandatory: true,
      tabIndex: -1
    },
    {
      name: "duration",
      type: "select",
      options: MEETING_DURATION,
      text: "Meeting Duration",
      suffixLabel: "mins",
      id: "duration",
      value: inputValue?.duration || "",
      mandatory: true,
      change: updateInputState,
      tabIndex: -1
    },
    {
      name: "reminderInMins",
      type: "select",
      options: REMINDER_IN_MINS,
      suffixLabel: "mins",
      text: "Set Reminder Before",
      id: "reminderInMins",
      value: inputValue?.reminderInMins || "",
      mandatory: true,
      change: updateInputState,
      tabIndex: -1
    },
    {
      name: "timeZone",
      type: "select",
      search: true,
      options: userDetail?.globalTimeZone,
      timeZone: true,
      text: "Timezone",
      id: "timeZone",
      value: inputValue?.timezoneName || "",
      mandatory: true,
      change: updateInputState,
      tabIndex: -1
    },
    {
      name: "preBookDays",
      type: "select",
      options: PRE_BOOK_DAYS_AVAILABLE,
      text: "No Of Available Days For Meeting",
      id: "preBookDays",
      value: inputValue?.preBookDays || "",
      mandatory: true,
      suffixLabel: "days",
      showInfoIcon: true,
      infoText: "Range of dates into the future when you can accept meetings.",
      change: updateInputState,
      tabIndex: -1
    },
    {
      name: "meetingUrl",
      id: "meetingUrl",
      text: "Video Conferencing Link (optional)",
      type: "text",
      value: inputValue?.meetingUrl || "",
      change: updateInputState,
      mandatory: false,
      tabIndex: -1,
      showInfoIcon: true,
      infoText:
        "Include an external video conferencing link such as a Zoom link if you'd like."
    },
    {
      name: "urlSuffix",
      id: "urlSuffix",
      text: "Calendar URL Suffix",
      type: "text",
      value: inputValue?.urlSuffix || "",
      readOnly: false,
      change: updateInputState,
      mandatory: true,
      showDom: calendarState?.editEventInputValue,
      tabIndex: -1
    }
  ];

  useEffect(() => {
    return () => {
      calendarState.setIsCustomWeek(false);
    };
  }, []);

  return (
    <div className="createEventFormSection">
      <div className="createEventInputCont">
        <div className="row fromInputSection">
          {formInputOptions.map(item => {
            const { type = "", id = "" } = item || {};
            return (
              <div className="formInputBox col-12 col-sm-6 col-lg-4" key={id}>
                {type === "select" ? (
                  <SingleSelectDropdown {...item} displayName={"displayName"} />
                ) : (
                  <InputTextBox {...item} />
                )}
              </div>
            );
          })}
        </div>
        <div className="selectScheduleHours">
          <ScheduleWorkingHour
            {...inputValue}
            updateInputState={updateInputState}
          />
          <QuickSelectScheduleHour
            {...inputValue}
            updateInputState={updateInputState}
          />
        </div>
        <div className="bufferCont">
          <div className="addTimeBeforeOrAfterEvent">
            <MeetingBuffer
              inputValue={inputValue}
              updateInputState={updateInputState}
            />
          </div>
          <div className="startBuffer">
            <StartBuffer
              inputValue={inputValue}
              updateInputState={updateInputState}
            />
          </div>
        </div>
      </div>
      <CreateEventActionBtn
        loader={loader}
        createEvent={createEvent}
        cancelEvent={cancelEvent}
        disabled={hasInvalidInputValue()}
      />
    </div>
  );
});

export default Main;
