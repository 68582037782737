import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { makeApi, URLS } from "Utils/apiURL";
import DateUtils from "Utils/DateUtils";
import Utils from "Utils/utils";
import InboxUtils from "Utils/InboxUtils";
import connectedInboxStore from "./store";
import CustomTooltip from "Components/common/CustomTooltip";
import ToggleBtnType from "Components/common/ToggleBtnType";
import DeliverablityGraph from "./DeliverablityGraph";

const DeliverablityAnalytics = observer((props = {}) => {
  const { warmingData = {}, inboxId = "" } = props || {};
  const {
    emailMetricsByDate = [],
    warmingEnabled = false,
    status = ""
  } = warmingData || {};

  const DeliverabilityStatsLoader = () => {
    const spanItems = Array.from({ length: 4 }, (_, index) => (
      <div className="deliverablityStatsCont" key={index}>
        <span className="leftCont linear-background" />
        <span className="rightCont linear-background" />
      </div>
    ));

    return <div className="deliverablityStats loader">{spanItems}</div>;
  };

  const HealthScoreLoader = () => {
    const classNames = ["headerCont", "scoreCont", "lastCheckedCont"];
    return (
      <div className="healthScore">
        {classNames.map((className, index) => (
          <div key={index} className={`${className} linear-background`} />
        ))}
      </div>
    );
  };

  const DeliverablityGraphLoader = () => {
    return (
      <div className="donutLoader">
        <div className="donutGraphLoader linear-background" />
        <div className="donutLabel">
          <div className="inboxLabel linear-background" />
          <div className="spamLabel linear-background" />
        </div>
      </div>
    );
  };

  return (
    <div className="deliverablityAnalyticsSection">
      <DeliverablityAnalyticsHeader
        warmingEnabled={warmingEnabled}
        inboxId={inboxId}
        status={status}
        warmingData={warmingData}
      />
      <div className="deliverablityAnalyticsBody">
        {!connectedInboxStore?.warmupLoader ? (
          <>
            <DeliverablityGraph />
            <DeliverablityStats />
            <HealthScore warmingData={warmingData} />
          </>
        ) : (
          <>
            <DeliverablityGraphLoader />
            <DeliverabilityStatsLoader />
            <HealthScoreLoader />
          </>
        )}
      </div>
    </div>
  );
});

const DeliverablityStats = () => {
  const statsArr = [
    "Deliverability",
    "Warmup emails sent",
    "Emails landed in inbox",
    "Emails saved from spam",
    "Warmup emails received"
  ];

  const statsDetailsMapping = {
    Deliverability: "deliverability",
    "Warmup emails sent": "total",
    "Emails landed in inbox": "inbox",
    "Emails saved from spam": "spam",
    "Warmup emails received": ""
  };

  const [statsDetails, setStatsDetails] = useState(
    statsArr.map(name => ({
      name,
      value: "-"
    }))
  );

  const updateStatsDetails = () => {
    const {
      inboxPercentage,
      totalCount,
      inboxCount,
      spamCount,
      receivedCount
    } = connectedInboxStore?.deliverabilityStats || {};

    const tempStatsDetails = statsDetails.map(({ name }) => {
      return {
        name,
        value:
          name.toLowerCase() === "deliverability"
            ? inboxPercentage === 0
              ? "--"
              : `${inboxPercentage}%`
            : name?.toLowerCase() === "warmup emails sent"
            ? totalCount || "--"
            : name?.toLowerCase() === "emails landed in inbox"
            ? inboxCount || "--"
            : name?.toLowerCase() === "emails saved from spam"
            ? spamCount || "--"
            : name?.toLowerCase() === "warmup emails received"
            ? receivedCount || "--"
            : "--"
      };
    });
    setStatsDetails(tempStatsDetails);
  };

  useEffect(() => {
    updateStatsDetails();
  }, [connectedInboxStore?.deliverabilityStats]);

  return (
    <div className="deliverablityStats">
      {statsDetails.map(({ name, value }, index) => (
        <div className="deliverablityStatsCont" key={index}>
          <span className="leftCont">{name}</span>
          <span className={`rightCont ${statsDetailsMapping[name]}`}>
            {value}
          </span>
        </div>
      ))}
    </div>
  );
};

const HealthScore = (props = {}) => {
  const { warmingData = "" } = props || {};
  const { domainHealth = "" } = warmingData || {};
  const { date = "", dnsRecords = {} } = domainHealth || {};
  const history = useHistory();

  let healthScore =
    Utils.roundToDecimalOrWhole(
      InboxUtils.getDomainHealthScore(4, dnsRecords),
      1
    ) || 0;

  return (
    <div className="healthScore">
      <h4
        className="headerCont"
        onClick={() => {
          history.push("/settings/domain-health-center");
        }}
      >
        <span className="headerName">Domain Health Score</span>
      </h4>
      <div className="scoreCont">
        {healthScore > 0 ? `${healthScore}/10` : "--"}
      </div>
      {date && (
        <div className="lastCheckedCont">
          last checked {DateUtils.getDateMonthAndYear(date)}
        </div>
      )}
    </div>
  );
};

const DeliverablityAnalyticsHeader = observer((props = {}) => {
  const {
    inboxId = "",
    warmingEnabled = false,
    warmingData = {},
    status = ""
  } = props || {};

  const { emailWarming = {} } = warmingData || {};
  const [toolTip, setToolTip] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [toogleLoader, setToogleLoader] = useState(false);

  const getToggleType = async (value = "") => {
    if (!connectedInboxStore?.warmingLoaderId) {
      connectedInboxStore.handleWarmingAction(
        "",
        warmingData,
        warmingEnabled,
        "two"
      );
    }
  };

  const checkErrorInbox = () => {
    setShowSaveButton(
      status?.toLowerCase() !== "active" || emailWarming?.warmingError
        ? false
        : true
    );
  };

  useEffect(() => {
    setToogleLoader(connectedInboxStore?.warmingLoaderId ? true : false);
  }, [connectedInboxStore?.warmingLoaderId]);

  useEffect(() => checkErrorInbox(), [status]);

  return (
    <div className="deliverablityHeaderAndEnableWarming">
      <div className="deliverablityHeader">
        <h4 className="deliverablityTitle">Deliverability</h4>
        <span className="deliverablityType">(Inbox vs Spam)</span>
      </div>
      {showSaveButton && (
        <div
          className="warmingToogleCont"
          onMouseEnter={() => setToolTip(true)}
          onMouseLeave={() => setToolTip(false)}
        >
          <ToggleBtnType
            firstText="Enable Warming"
            defaultValue={warmingEnabled}
            cbk={getToggleType}
            enableLoading={toogleLoader}
          />
          {toolTip && (
            <CustomTooltip
              text={
                warmingEnabled
                  ? "Stop warming this email account"
                  : "Start warming this email account"
              }
              className="toogleToolTip"
            />
          )}
        </div>
      )}
    </div>
  );
});

export default DeliverablityAnalytics;
export { DeliverablityAnalytics };
