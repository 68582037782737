import React from "react";
import Utils from "Utils/utils";
import { observer } from "mobx-react";
import { linkedInCookiesState } from "Stores/LinkedInCookies";
import linkedInUtils from "./common/linkedInUtils";

const ReconnectLinkedInButton = observer(() => {
  return (
    <div className="btnInboxActions">
      <span
        className="reconnectBtn"
        onClick={e => {
          Utils.preventDefaultFn(e);
          linkedInCookiesState.isPremiumAccount
            ? linkedInUtils.handleSalesNavigatorButtonClick()
            : linkedInUtils.handleButtonClick();
        }}
      >
        Reconnect
      </span>
    </div>
  );
});

export default ReconnectLinkedInButton;
