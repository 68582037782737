import React, { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react";
import CustomMultiSelect from "Pages/Dashboard/dataSearch/filterComponent/CustomMultiSelect";
import contactStoreData from "Stores/contactData";

const FilterDropDownTeamInContacts = observer(props => {
  const {
    updateSelectedCbk = () => {},
    teamFilterDropDownData = [],
    setTeamFilterDropDownData = () => {},
    setTeamCount = () => {}
  } = props || {};

  const [options, setOptions] = useState(teamFilterDropDownData);

  const handleSelectActionCbk = (checked, uId, name) => {
    let tempData = [...options];
    if (tempData?.length > 0) {
      tempData = tempData.map(item => {
        const { id = "" } = item || {};
        if (id === uId || uId?.toLowerCase() === "selectall") {
          item.checked = checked;
          item?.submenu?.length > 0 &&
            item.submenu.map(submenuItem => {
              submenuItem.checked = checked;
              return submenuItem;
            });
        }
        return item;
      });
      const isAllSelected =
        tempData?.length > 0 &&
        tempData.filter(
          selectedItem =>
            selectedItem?.id?.toLowerCase() !== "selectall" &&
            selectedItem?.checked
        );
      tempData[0].checked = isAllSelected?.length === tempData?.length - 1;
    }
    setTeamFilterDropDownData(tempData);
    setOptions(tempData);
  };

  const handleSubmenuSelectActionCbk = (
    checked,
    uId,
    name,
    itemWithSubmenu
  ) => {
    let tempData = [...options];
    tempData = tempData.map(item => {
      const { id = "" } = item || {};
      if (id === itemWithSubmenu?.id) {
        item.submenu.map(submenuItem => {
          if (submenuItem?.id === uId) {
            submenuItem.checked = checked;
          }
        });
        const isAllSubmenuSelected = item.submenu.filter(
          selectedItem => selectedItem?.checked
        );
        item.checked = isAllSubmenuSelected?.length === item?.submenu?.length;
      }
      return item;
    });
    const isAllSelected =
      tempData?.length > 0 &&
      tempData.filter(
        selectedItem =>
          selectedItem?.id !== "selectAll" && selectedItem?.checked
      );
    tempData[0].checked = isAllSelected?.length === tempData?.length - 1;
    setTeamFilterDropDownData(tempData);
    setOptions(tempData);
  };

  const searchFilterCbk = value => {
    const lowercasedValue = value?.toLowerCase();
    if (lowercasedValue === "") {
      resetDropdownToInitialState();
    } else {
      const tempData = [...options];
      const filteredData =
        tempData?.length > 0 &&
        tempData?.filter(item => {
          if (item?.id !== "selectAll") {
            let val = item?.value
              ?.toString()
              ?.toLowerCase()
              ?.includes(lowercasedValue);
            item["show"] = val;
            item.submenu?.length > 0 &&
              item.submenu.filter(subMenuItem => {
                let subMenuVal = subMenuItem?.value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(lowercasedValue);
                subMenuItem.show = subMenuVal;
                if (subMenuVal) {
                  item["show"] = subMenuVal;
                }
              });
            return item;
          }
        });
      setOptions(filteredData);
    }
  };

  const handleApplySelectionCbk = () => {
    let selectedTeam = [];
    let selectedTeamMembers = [];
    let tempOptions = [...options];
    tempOptions?.length > 0 &&
      options.map(item => {
        if (item?.id !== "selectAll" && item?.submenu?.length > 0) {
          item.submenu
            .map(subItem => {
              if (subItem?.checked) {
                !selectedTeamMembers.includes(subItem?.id) &&
                  selectedTeamMembers.push(subItem?.id);
                !selectedTeam.includes(item?.name) &&
                  selectedTeam.push(item.name);
              }
            })
            .filter(Boolean);
        }
      });
    setTeamCount(selectedTeam?.length);
    updateSelectedCbk(selectedTeamMembers, selectedTeam);
  };

  const resetOptions = () => {
    const temp = [...teamFilterDropDownData];
    if (temp?.length) {
      temp.map(item => {
        item.checked = false;
        item.submenu?.length > 0 &&
          item.submenu.map(submenuItem => (submenuItem.checked = false));
        return item;
      });
    }
    setOptions(temp);
  };

  const resetDropdownToInitialState = () => {
    const temp = [...teamFilterDropDownData];
    if (temp?.length) {
      temp.map(item => {
        item.show = true;
        item.submenu?.length > 0 &&
          item.submenu.map(submenuItem => (submenuItem.show = true));
        return item;
      });
    }
    setOptions(temp);
  };

  useEffect(() => {
    contactStoreData?.resetTeam && resetOptions();
  }, [contactStoreData?.resetTeam]);

  return (
    <CustomMultiSelect
      options={options}
      handleSelectActionCbk={handleSelectActionCbk}
      handleSubmenuSelectActionCbk={handleSubmenuSelectActionCbk}
      handleApplySelectionCbk={handleApplySelectionCbk}
      searchFilterCbk={searchFilterCbk}
      hasSubMenuAccordion={true}
      {...props}
    />
  );
});

export { FilterDropDownTeamInContacts };
export default FilterDropDownTeamInContacts;
