import React from "react";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { DateUtils } from "Utils/DateUtils";
import { calendarBookMeetingState } from "../State";
import Button from "Components/common/Button";

export const SlotsAvailable = observer((props = {}) => {
  const {
    slotLoader = false,
    inputValue = {},
    updateInputState = () => {},
    nextBtnProps = {}
  } = props || {};

  const { event = {} } = inputValue || {};

  const bookTimeSlot = (event = "") => {
    let actionType = Utils.performActionByClosestDom(event, "li", "data-key");
    if (actionType) {
      updateInputState("slotDateTime", +actionType);
    }
  };

  return slotLoader ? (
    <SlotsAvailableLoader />
  ) : (
    <>
      <div className="slotsAvailablelist">
        <div className="collapsible active">Select Time Slot</div>
        <div className="collapsiveContentBody">
          {calendarBookMeetingState?.slots !== null &&
          calendarBookMeetingState.slots?.length > 0 ? (
            <ul className="slotsAvailable" onClick={e => bookTimeSlot(e)}>
              {calendarBookMeetingState.slots.map((item, index) => {
                const { startTimeInMillis = "" } = item || {};
                return (
                  <div
                    className="flexWrapper"
                    key={`slotsAvailable${index}-${startTimeInMillis}`}
                  >
                    <li
                      key={startTimeInMillis + index}
                      className={`timeSlot ${
                        +inputValue?.slotDateTime === +startTimeInMillis
                          ? "active"
                          : ""
                      }`}
                      data-key={startTimeInMillis}
                    >
                      {DateUtils.getTimeByMerdianWithEndTime(
                        startTimeInMillis,
                        inputValue?.event?.duration,
                        inputValue?.timeZone
                      )}
                    </li>
                    {+inputValue?.slotDateTime === +startTimeInMillis && (
                      <div className="calendarActionBtn nextBtn">
                        <Button key={item?.id} {...nextBtnProps} />
                      </div>
                    )}
                  </div>
                );
              })}
            </ul>
          ) : (
            <div className="noSlotAvailable">
              No slots available.
              <br /> Please check other dates.
            </div>
          )}
        </div>
      </div>
    </>
  );
});

const SlotsAvailableLoader = () => {
  return (
    <div className="slotsAvailablelist">
      <div className="collapsible active">Select Time Slot</div>
      <div className="collapsiveContentBody">
        <ul className="slotsAvailable">
          {Array.from({ length: 35 }, (value, index) => (
            <li
              className="timeSlot linear-background"
              key={`slotsAvailable${index}`}
            >
              <span className="linear-background" />
              {` - `}
              <span className="linear-background" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SlotsAvailable;
