import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import DropdownButton from "./DropdownButton";
import { FilterDropDownTeamInContacts } from "Components/common/FilterDropDownTeamInContacts";
import contactStoreData from "Stores/contactData";

const ContactTeamFilter = props => {
  const {
    keyValue = "",
    activeFilter = "",
    teamCount,
    teamFilterDropDownData = [],
    setTeamCount = () => {},
    setActiveFilter = () => {},
    updateSelectedCbk = () => {}
  } = props || {};

  let toggleDropdown = activeFilter === keyValue;

  useEffect(() => {
    if (contactStoreData?.resetTeam) {
      setTeamCount(0);
    }
  }, [contactStoreData?.resetTeam]);

  return (
    <div className="conditionField">
      <DropdownButton
        label="Team"
        count={teamCount}
        keyValue={keyValue}
        toggleDropdown={toggleDropdown}
        callback={setActiveFilter}
      />
      {teamFilterDropDownData?.length > 1 && (
        <FilterDropDownTeamInContacts
          {...props}
          className="contactTeamDropDown"
          showDropdown={toggleDropdown}
          id={"team"}
          updateSelectedCbk={updateSelectedCbk}
          resetDropDownSize={setActiveFilter}
        />
      )}
      {toggleDropdown && teamFilterDropDownData?.length < 2 && (
        <div className="emptyDropdown">No teams available</div>
      )}
    </div>
  );
};

export default ContactTeamFilter;
