import React from "react";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import { ReactComponent as WritingAssistantIcon } from "Assets/svg/WritingAssistant/WritingAssistantIcon.svg";
import { ReactComponent as SubjectlineIcon } from "Assets/svg/WritingAssistant/sublineAi.svg";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

export const GenerateSubjectLineButton = props => {
  const {
    writingAssistantEnabled = false,
    loader = false,
    action = () => {},
    type = ""
  } = props || {};

  const { inFreePlan = false, planName = "" } =
    userDetail?.userFeatureFlag || {};

  return (
    <div className="generatorBtn">
      <WritingAssistantIcon />
      <div
        className={`generateSubjectLineBtn ${loader ? "disableBtn" : ""}`}
        onClick={() => (!loader ? action("subject") : () => {})}
      >
        <SubjectlineIcon />
        <span className="text">Generate Subject Line</span>
        {loader && <i className="fa fa-spinner fa-spin subjectLineLoader"></i>}
        {!writingAssistantEnabled && (
          <UpgradeIconTooltip
            plan={PaywallUtils.getPlanName(planName, inFreePlan)}
            type="AIWriting"
            showIcon={true}
          />
        )}
      </div>
    </div>
  );
};

export default GenerateSubjectLineButton;
