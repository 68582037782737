import React from "react";

const InboxListLoader = ({ length = 5 }) => {
  return Array.from({ length }, (value, index) => (
    <div className="inboxItem" key={index}>
      <span className="inboxName linear-background" />
      <span className="authentication linear-background" />
      <span className="profilePicture">
        <div className="linear-background" />
      </span>
      <span className="profileName">
        <div className="linear-background" />
      </span>
      <span className="refreshIcon linear-background" />
    </div>
  ));
};

export { InboxListLoader };
export default InboxListLoader;
