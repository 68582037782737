import React, { useState, useEffect } from "react";
import { URLS, makeApi } from "Utils/apiURL";
import Utils from "Utils/utils";
import SequenceUtils from "Utils/SequenceUtils";
import { ReactComponent as ReactivateIcon } from "Assets/svg/reactivate.svg";
import DropdownMenu from "Components/common/DropDownMenu";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { toasterState } from "Components/common/toaster";
import { MessageResponseHandler } from "../SequencePage/MessageResponseHandler";

const SequenceMoreOption = props => {
  const {
    contact = {},
    count = "",
    updateContact = () => {},
    seqId: sequenceId = "",
    getTableContacts = () => {},
    activeTab = ""
  } = props || {};
  const {
    sequenceId: scheduledSequenceId = "",
    emailAddress = "",
    status = "",
    contactId = "",
    code = ""
  } = contact || {};
  let showOptions =
    ["PAUSED", "ONGOING", "ACTIVE", "FAILED", "COMPLETED", "REPLIED"].includes(
      status?.toUpperCase()
    ) && code?.toLowerCase() !== "inbox_not_found";
  const [showDropdown, setShowDropdown] = useState(false);
  const [skipConfirmation, setSkipConfirmation] = useState("");

  const completeSequenceContact = async () => {
    try {
      let url = URLS.completeScheduledSequenceContact;
      if (url) {
        url = url.replace("<<seqId>>", scheduledSequenceId);
        const config = {
          url,
          method: "PUT"
        };
        const response = await makeApi(config);
        const { requestStatus = false } = response?.data;
        confirmationPopupState.setShowPopup(false);
        if (requestStatus) {
          toasterState.setToastMsg(
            "Contact has been marked completed successfully",
            "success"
          );
          updateContact(true);
        } else {
          toasterState.setToastMsg(
            "Failed to mark the contact as complete. Please try again later",
            "failure"
          );
        }
      }
    } catch (err) {
      console.err(
        err,
        "Mark as complete for scheduled sequence contact has failed"
      );
    }
  };

  const replaceUrl = url => {
    return url
      .replace("<<seqId>>", sequenceId)
      .replace("<<contactId>>", contactId);
  };

  const skipSequenceStageContact = async () => {
    try {
      let url = URLS.skipSequenceStageContact;
      if (url) {
        url = replaceUrl(url);
        const config = {
          url,
          method: "POST"
        };
        const response = await makeApi(config);
        confirmationPopupState.setShowPopup(false);
        const { nextTask = false, success = false } = response?.data || {};
        if (response?.status === 200) {
          if (success) {
            toasterState.setToastMsg(
              nextTask
                ? "Step skipped and next step in sequence scheduled"
                : "Step skipped and sequence completed",
              "success"
            );
            updateContact(true);
          } else {
            toasterState.setToastMsg(
              "Failed to move the contact to next step. Please try again later",
              "failure"
            );
          }
        } else {
          Utils.showApiFailureResponse(response);
        }
      }
    } catch (err) {
      console.err(err, "Skip step failed error");
    }
  };

  const errorCodesObj = {
    401: "Failed. Opted out contacts cannot be added to the sequence.",
    402: "Failed. Bounced contacts cannot be added to the sequence.",
    403: "Failed. Update the missing variable in the contact and try again.",
    408: "Failed. Contact is in do not contact stage",
    500: "Oops! Something went wrong. Please contact support for assistance.",
    501: "Failed. Something went wrong . Please try again later",
    201: "Oops! There is no next step for this contact",
    202: "Oops! This contact is not in completed/replied state",
    203: "Oops! This contact is already active in the sequence",
    204: "Oops! This contact has a different contact owner."
  };

  const getReactivatePayload = () => {
    const payloadObj = {
      source: "SEQUENCE",
      type: "REACTIVATE_CONTACTS",
      totalCount: 1,
      filter: {
        contactIds: contactId ? [contactId] : [],
        sequenceId,
        scheduledIds: scheduledSequenceId ? [scheduledSequenceId] : []
      }
    };
    return payloadObj;
  };

  const handleSuccessResponse = (response = {}) => {
    const { success = false, bulkOperationResult = {} } = response?.data || {};
    confirmationPopupState.setShowPopup(false);
    if (success) {
      const {
        success = 0,
        error = {},
        activeJob = false
      } = bulkOperationResult || {};
      if (activeJob) {
        SequenceUtils.showActiveJobPopupForBulkActions();
      }
      if (success) {
        toasterState.setToastMsg(
          "Contact reactivated in the sequence successfully",
          "success"
        );
        updateContact(true);
      } else {
        const keys = Object.keys(error) || [];
        if (keys?.length > 0) {
          const firstKey = keys[0];
          toasterState.setToastMsg(errorCodesObj?.[firstKey], "failure");
        }
      }
    } else {
      toasterState.setToastMsg(
        "Something went wrong, Please try againg later",
        "failure"
      );
    }
  };

  const reactivateContact = async () => {
    const data = getReactivatePayload();
    const config = {
      url: URLS.performContactsBulkOperation,
      method: "POST",
      data
    };
    const response = await makeApi(config);
    if (response?.data) {
      handleSuccessResponse(response);
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
  };

  const retryFailedContact = async () => {
    try {
      let url = URLS.retryFailedContact;
      if (url) {
        url = url.replace("<<seqId>>", scheduledSequenceId);
        const config = {
          url,
          method: "POST",
          data: {
            id: scheduledSequenceId
          }
        };
        const response = await makeApi(config);
        confirmationPopupState.setShowPopup(false);
        const { statusCode } = response?.data || {};
        if (response?.status === 200) {
          if (statusCode === 200) {
            toasterState.setToastMsg("Contact added successfully", "success");
            updateContact(true);
          } else {
            getTableContacts(activeTab);
            toasterState.setToastMsg(
              errorCodesObj?.[statusCode] ||
                "Failed to add contact to the stage. Please try again later",
              "failure"
            );
          }
        } else {
          Utils.showApiFailureResponse(response);
        }
      }
    } catch (err) {
      console.err(err, "Retry failed contact failed error");
    }
  };

  const checkIsEligibleForSkipStage = async () => {
    if (sequenceId && contactId) {
      try {
        let url = URLS.checkIsEligibleForSkipStage;
        if (url) {
          url = replaceUrl(url);
          const config = {
            url,
            method: "GET"
          };
          const response = await makeApi(config);
          const { isEligibleToScheduleNextTask = false } = response?.data || {};
          isEligibleToScheduleNextTask
            ? skipSequenceStageContact()
            : confirmationPopupState.setShowPopup(false);
          setSkipConfirmation(true);
        }
      } catch (err) {
        console.err(err, "Skip step eligible check failed error");
      }
    }
  };

  const confirmSkipStageInASequence = () => {
    setShowDropdown(false);
    confirmationPopupState.setPopupValues({
      content: skipConfirmation
        ? "Skipping this task will stop the sequence for this contact as the next step in this sequence is a threaded email. Please confirm to proceed."
        : "Are you sure you want to skip this step?",
      actionBtnText: skipConfirmation ? "Confirm" : "Yes",
      callback: skipConfirmation
        ? skipSequenceStageContact
        : checkIsEligibleForSkipStage,
      loadingMsg: "Skipping steps. Please wait..."
    });
    setSkipConfirmation("");
    confirmationPopupState.setShowPopup(true);
  };

  const confirmCompleteSequence = () => {
    setShowDropdown(false);
    confirmationPopupState.setPopupValues({
      title: "Are you sure",
      content:
        "Are you sure you want to mark as complete?<br/><span style='color: #4C4C4C;font-size: 14px;'>All scheduled emails and tasks for this contact will be suspended.</span>",
      actionBtnText: "Yes",
      callback: completeSequenceContact,
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const confirmRetryFailedContact = () => {
    setShowDropdown(false);
    confirmationPopupState.setPopupValues({
      title: "Are you sure",
      content: "Are you sure you want to retry this contact?",
      actionBtnText: "Yes",
      callback: retryFailedContact,
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const confirmReactivateContact = () => {
    setShowDropdown(false);
    confirmationPopupState.setPopupValues({
      content: "Are you sure you want to reactivate this contact?",
      actionBtnText: "Yes",
      callback: reactivateContact,
      loadingMsg: "Please wait..."
    });
    confirmationPopupState.setShowPopup(true);
  };

  const retryBlockduetoDoNotContact = () => {
    setShowDropdown(false);
    confirmationPopupState.setPopupValues({
      title: "Are you sure",
      content:
        "Failed to retry the contact in this sequence. Reason: Contact's stage is 'do not contact'.",
      needCancelBtn: false,
      actionBtnText: "Ok",
      callback: closePopup
    });
    confirmationPopupState.setShowPopup(true);
  };

  const closePopup = () => {
    confirmationPopupState.setShowPopup(false);
  };

  const updatedDropdownOptions = [
    !["FAILED", "COMPLETED", "REPLIED"].includes(status?.toUpperCase()) && {
      icon: {
        ele: '<span class="checkCircleOutlineIcon material-icons-outlined">check_circle_outline</span>'
      },
      value: "Mark as complete",
      key: "markAsComplete",
      method: confirmCompleteSequence
    },
    ["ONGOING", "ACTIVE"].includes(status?.toUpperCase()) && {
      icon: {
        ele: '<span class="skipNextIcon material-icons-outlined">skip_next</span>'
      },
      value: "Skip to next step",
      key: "checkIsEligibleForSkipStage",
      method: confirmSkipStageInASequence
    },
    ["FAILED"].includes(status?.toUpperCase()) &&
      [
        "inbox_not_found",
        "do_not_contact",
        "email_send_limit",
        "contact_not_eligible",
        "li_inbox_not_found",
        "li_error"
      ].includes(code?.toLowerCase()) && {
        icon: {
          ele: '<span class="failedIcon material-icons-outlined">refresh</span>'
        },
        value: "Retry",
        key: "retryFailedContact",
        method: confirmRetryFailedContact
      },
    ["COMPLETED", "REPLIED"].includes(status?.toUpperCase()) && {
      icon: {
        ele: <ReactivateIcon className="reactivateIcon" />
      },
      value: "Reactivate",
      key: "reactivate",
      method: confirmReactivateContact
    }
  ].filter(Boolean);

  useEffect(() => {
    skipConfirmation && confirmSkipStageInASequence();
  }, [skipConfirmation]);

  return (
    <div className="moreOption">
      {updatedDropdownOptions?.length > 0
        ? showOptions &&
          scheduledSequenceId && (
            <>
              <i
                className="material-icons"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                more_vert
              </i>
              {showDropdown && (
                <>
                  <DropdownMenu
                    options={updatedDropdownOptions}
                    referenceId={emailAddress}
                    direction={count <= 1 ? "topLeft" : ""}
                  />
                  <div
                    className="dropdownOverlay"
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                </>
              )}
            </>
          )
        : ""}
    </div>
  );
};

export default SequenceMoreOption;
