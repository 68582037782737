/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react";
import Discover from "Assets/png/discover.png";
import Lottie from "react-lottie";
import SwirlArrow from "Utils/LottieFiles/swirlArrow.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: SwirlArrow,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const NoFilterApplied = () => {
  return (
    <div className="noFilters">
      <div className="wrapLottieText">
        <div className="lottie">
          <Lottie options={defaultOptions} height="auto" speed={1.5} />
        </div>
        <div className="lottieText">
          <div className="text">
            Discover emails and phone numbers of{" "}
            <span className="themeBlue">200M+ </span> contacts from{" "}
            <span className="themeBlue">25M+ </span>
            companies.
          </div>
        </div>
      </div>
      <img
        className="recordTableLoader"
        src={Discover}
        alt="Discover emails and phone numbers of 200M+ contacts from 25M+ companies."
      />
    </div>
  );
};

export { NoFilterApplied };
export default observer(NoFilterApplied);
